import React from 'react';
import { Container, Flex, Image, Li, Link, MarginBox, Ol, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Language } from 'utils/i18n/Language';

export const TermsAndConditionsBE = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex align={'center'} direction={'column'}>
          <Text type={'h5_bold'}>CONDITIONS D&apos;UTILISATION RPARTSTORE</Text>
          {Language.frenchBelgium.name}
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Les présentes conditions d&apos;utilisation (ci-après &quot;<b>Conditions d&apos;utilisation</b>&quot;)
            régissent l&apos;accès et l&apos;utilisation du RpartStore, mis à disposition par Renault Belgium Luxembourg
            S.A., sise Bergensesteenweg 281, 1620 Drogenbos et inscrite au registre du commerce de la Chambre de
            Commerce sous le numéro 0403 463 679 (ci-après &quot;<b>Renault</b>&quot;)
          </Paragraph>
          <Paragraph>
            L&apos;utilisateur doit lire attentivement les présentes conditions d&apos;utilisation avant d&apos;utiliser
            le service.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'1.'} title={'La Plateforme'} />
          <Paragraph>
            Le site web RpartStore (ci-après la &quot;<b>Plateforme</b>&quot;) a été mis en place pour faciliter la mise
            en relation entre des vendeurs professionnels (ci-après les &quot;<b>Vendeurs</b>&quot;) souhaitant proposer
            des pièces et accessoires (ci-après les &quot;<b>Produits</b>&quot;) à la vente via un site web à des
            acheteurs professionnels (ci-après les &quot;<b>Acheteurs</b>&quot;), conformément aux présentes Conditions
            d&apos;utilisation. La Plateforme est exclusivement destinée à un usage professionnel.
          </Paragraph>
          <Paragraph>
            La Plateforme est accessible via le lien suivant :{' '}
            <Link to={'https://rpartstore.renault.com/'} external newTab>
              https://www.rpartstore.renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'2.'} title={'Hébergement'} />
          <Paragraph>
            La Plateforme agit en tant qu&apos;intermédiaire technique (service d&apos;hébergement). La Plateforme
            fournit aux Vendeurs et aux Acheteurs (collectivement les &quot;<b>Utilisateurs</b>&quot;) des services
            techniques de stockage et de transmission de données.
          </Paragraph>
          <Paragraph>
            Le rôle de la Plateforme est purement technique et se limite à faciliter le contact entre les Vendeurs et
            les Acheteurs.
          </Paragraph>
          <Paragraph>
            La Plateforme ne garantit pas et n&apos;est pas responsable des accords, échanges, offres et communications
            diverses effectués par l&apos;intermédiaire de la Plateforme.
          </Paragraph>
          <Paragraph>
            La Plateforme n&apos;est en aucun cas un vendeur ou un revendeur des Produits proposés par les Vendeurs via
            la Plateforme.
          </Paragraph>
          <Paragraph>
            Les accords concernant les Produits proposés par les Vendeurs sont toujours conclus directement entre le
            Vendeur et l&apos;Acheteur, en dehors de la Plateforme. Le Vendeur peut simplement générer des contacts
            commerciaux (<i>lead form</i>) par le biais de la Plateforme. Le prix final des Produits est déterminé par
            le Vendeur en dehors de la Plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'3.'} title={"Objet des présentes conditions d'utilisation"} />
          <Paragraph>
            Les présentes Conditions d&apos;utilisation ont pour objet de définir les conditions d&apos;accès et
            d&apos;utilisation de la Plateforme par les Utilisateurs.
          </Paragraph>
          <Paragraph>
            La Plateforme a pour objet d&apos;établir une relation numérique directe entre Vendeurs et Acheteurs afin de
            générer des contacts commerciaux entre eux.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'4.'} title={"Conditions d'accès à la plate-forme pour les acheteurs"} />
          <Paragraph>
            L&apos;accès à la Plateforme peut être obtenu par :
            <Ul>
              <Li>
                <Text type={'text'}>Le Vendeur demande à l&apos;Acheteur de devenir membre via la page Membres;</Text>
              </Li>
              <Li>
                <Text type={'text'}>la validation par les Vendeurs de l&apos;accès des Acheteurs à la Plateforme.</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Le Mini-site d&apos;adhésion est une application e-business qui permet aux Vendeurs de demander
            l&apos;adhésion à RpartStore pour leurs Acheteurs. L&apos;application est accessible depuis RenaultNet et
            RpartStore.
          </Paragraph>
          <Paragraph>
            L&apos;accès au site Membership Mini se fait en contactant : Renault Parts{' '}
            <Link to={'mailto:rparts@renault.be'} external newTab>
              rparts@renault.be
            </Link>
            . L&apos;accès est accordé au vendeur qui en fait la demande à condition qu&apos;il soit référencé
            individuellement dans le réseau Renault.
          </Paragraph>
          <Paragraph>
            Le site Membership Mini permet d&apos;accélérer le processus d&apos;adhésion et permet aux Vendeurs
            d&apos;effectuer des demandes d&apos;adhésion pour des clients, quelle que soit la nature de
            l&apos;utilisateur dans Tresor (environnement back office du Groupe Renault) et RpartStore (client inconnu
            dans Tresor, nouvelle adhésion, nouvel utilisateur).
          </Paragraph>
          <Paragraph>
            Le Vendeur peut effectuer des demandes d&apos;adhésion pour ses clients conformément aux dispositions du
            présent article 4 des présentes Conditions Générales d&apos;Utilisation. Si le client n&apos;est pas
            identifié dans la base de données de Tresor, une demande de création/modification est automatiquement
            envoyée à l&apos;administrateur de Tresor concerné.
          </Paragraph>
          <Paragraph>
            Le vendeur peut modifier les coordonnées du client et suivre l&apos;évolution de la demande d&apos;adhésion.
            Le vendeur ne peut pas modifier une demande d&apos;adhésion en cours, mais peut demander qu&apos;elle soit
            clôturée. Le centre d&apos;appel se chargera alors de la clôture finale.
          </Paragraph>
          <Paragraph>L&apos;application de l&apos;article 6:227b du Code civil néerlandais est exclue.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'5.'} title={'Produits autorisés sur la plate-forme'} />
          <Paragraph>
            La Plateforme sélectionne les Produits qui peuvent être commandés via la Plateforme. Les Acheteurs ne
            peuvent pas demander ou obliger les Vendeurs à proposer via la Plateforme d&apos;autres produits que les
            Produits préalablement sélectionnés par la Plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'6.'}
            title={"Création de l'espace Acheteur - Confidentialité des données de connexion"}
          />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Les étapes :
            </Text>
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  Réception d&apos;un e-mail du Vendeur confirmant que la demande de création d&apos;un Espace Acheteur
                  a été traitée. L&apos;e-mail indique également le délai de traitement de la demande, qui varie entre
                  24 et 72 heures ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  L&apos;Acheteur recevra ensuite par email les informations nécessaires (ID / Mot de passe) pour se
                  connecter au RpartStore ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Le Vendeur recevra également une notification indiquant que son client a désormais accès au
                  RpartStore.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  En cas d&apos;incident technique sur l&apos;application, le Vendeur sera notifié par email.
                </Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            L&apos;utilisation de l&apos;Espace Acheteurs est strictement limitée à l&apos;utilisateur désigné par le
            Vendeur.
          </Paragraph>
          <Paragraph>
            L&apos;Acheteur s&apos;engage à conserver confidentiels ses identifiants de connexion et à ne pas les
            divulguer, sous quelque forme et à quelque personne que ce soit, et en cas d&apos;utilisation frauduleuse ou
            non autorisée, à les annuler et à signaler immédiatement les faits au service client via l&apos;adresse
            email de Renault Parts{' '}
            <Link to={'mailto:renaultparts@renault.be'} external newTab>
              renaultparts@renault.be
            </Link>
            . L&apos;Acheteur est informé que toute divulgation de ses identifiants à un tiers relève de sa seule
            responsabilité.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'7.'} title={'Relation personnalisée'} />
          <Paragraph>
            Les Acheteurs n&apos;ont pas accès à tous les Vendeurs admis sur la Plateforme. Une relation est créée entre
            un Acheteur et un Vendeur. Si plusieurs relations s&apos;appliquent, une nouvelle demande doit être faite à
            cet effet.
          </Paragraph>
          <Paragraph>
            Sur la Plate-forme, l&apos;Acheteur trouve le Vendeur correspondant au canal physique habituel utilisé par
            l&apos;Acheteur ; la relation avec votre Vendeur ne change pas.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'8.'} title={'Paiement'} />
          <Paragraph>
            Seuls des contacts commerciaux peuvent être établis par l&apos;intermédiaire de la plate-forme. Il
            n&apos;est pas possible d&apos;effectuer des paiements par l&apos;intermédiaire de la plate-forme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'9.'} title={'Disponibilité de la Plateforme'} />
          <Paragraph>
            Dans la mesure où les lois et règlements applicables le permettent, Renault n&apos;assume aucune
            responsabilité quant à l&apos;utilisation de la Plateforme par les Utilisateurs. Renault ne donne aucune
            garantie, expresse ou implicite, sur la rapidité et/ou les performances de la Plateforme ou sur
            l&apos;utilisation par les Utilisateurs des données et/ou informations accessibles via la Plateforme.
          </Paragraph>
          <Paragraph>
            En particulier, Renault ne garantit pas que la Plateforme sera exempte d&apos;erreurs, de défauts ou
            d&apos;imperfections, ni qu&apos;elle ne fera pas l&apos;objet de dommages ou d&apos;attaques, notamment de
            virus, pouvant entraîner des dysfonctionnements, des interruptions de service, des temps d&apos;arrêt ou des
            pertes de données ou d&apos;informations.
          </Paragraph>
          <Paragraph>
            L&apos;accès à la Plateforme peut être temporairement et périodiquement suspendu pour des raisons
            techniques, que les équipes techniques s&apos;efforceront de résoudre dans les meilleurs délais.
          </Paragraph>
          <Paragraph>
            Renault n&apos;est pas responsable
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  du non-respect par l&apos;Utilisateur des dispositions des présentes Conditions d&apos;utilisation ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  des défaillances de la Plateforme dues à une cause volontaire ou involontaire imputable à
                  l&apos;Utilisateur ou à un tiers, ou dues à un acte de malveillance, ou dues à des défaillances des
                  logiciels, smartphones, tablettes ou autres interfaces/produits de l&apos;Utilisateur ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>des changements de lois ou de règlements affectant la Plateforme.</Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            En outre, l&apos;Utilisateur reconnaît que la Plateforme peut être fournie en utilisant des réseaux de
            communication tiers et que Renault ne peut en aucun cas être tenu pour responsable en cas de défaillance de
            ces réseaux de communication.
          </Paragraph>
          <Paragraph>
            Lorsque l&apos;Utilisateur utilise la Plateforme, il s&apos;engage à le faire conformément aux présentes
            Conditions d&apos;utilisation et dans le respect des lois et règlements applicables.
          </Paragraph>
          <Paragraph>
            L&apos;Acheteur et le Vendeur sont chacun pleinement responsables de l&apos;utilisation de la Plateforme et
            de toute utilisation des données/informations reçues via la Plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'10.'} title={'Utilisation préjudiciable de la Plateforme'} />
          <Paragraph>
            En toutes circonstances, l&apos;Utilisateur doit s&apos;abstenir de toute activité susceptible de
            compromettre la disponibilité et/ou les fonctionnalités de la Plateforme, de nuire à la réputation de la
            Plateforme, de nuire à la réputation des services fournis et de nuire à la réputation et aux activités du
            Groupe Renault.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'11.'} title={'Personnel'} />
          <Paragraph>
            La Plateforme, mise en place par le Groupe Renault, contribue à l&apos;image, à la réputation et à la
            notoriété du Groupe Renault. La relation entre la Plate-forme et ses Utilisateurs est donc personnelle.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'12.'} title={'Désinscription, blocage ou exclusion de la Plate-forme'} />
          <Text type={'text_bold'} decoration={'underline'}>
            Désinscription
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Tout Acheteur peut, à tout moment, notifier à la Plateforme par l&apos;intermédiaire de son R1
            (concessionnaire fournisseur de Renault) sa décision de ne plus utiliser ses services.
          </Paragraph>
          <Paragraph>
            L&apos;Acheteur doit continuer à remplir les obligations qu&apos;il a contractées avec les Vendeurs avant la
            date de cessation de sa participation à la Plateforme.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Blocage
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            La Plateforme peut bloquer temporairement l&apos;accès d&apos;un Acheteur à la Plateforme, pour une période
            pouvant aller jusqu&apos;à 6 mois, dans les cas suivants :
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  suspicion sérieuse de comportement portant atteinte à la Plateforme, aux présentes Conditions
                  d&apos;utilisation, aux lois et règlements applicables ou à l&apos;image et à la réputation du Groupe
                  Renault ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  manquement à l&apos;obligation de signaler les modifications apportées aux éléments désignés ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  l&apos;utilisation de l&apos;Espace Acheteur par des personnes autres que l&apos;Acheteur ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  négligence dans l&apos;obligation de préserver la confidentialité des données de connexion ;
                </Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Pendant cette période, l&apos;Acheteur et la Plateforme s&apos;efforceront de bonne foi de remédier au
            comportement identifié.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Exclusion
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            La Plateforme peut exclure définitivement un Acheteur de l&apos;accès à la Plateforme en cas de :
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  sortie du réseau Renault autorisé à accéder à la Plateforme pour quelque raison que ce soit ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  d&apos;un comportement visé dans le présent article sous l&apos;intitulé « blocage ». La Plateforme
                  aura donc le choix entre le blocage et l&apos;exclusion immédiate ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  les fausses déclarations, quelle que soit leur gravité, dès lors qu&apos;elles entraînent un abus de
                  confiance ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>les plaintes répétées des Vendeurs ;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  tout changement dans la situation personnelle de l&apos;Acheteur susceptible d&apos;affecter le
                  caractère personnel (notamment : changement de contrôle direct ou indirect, changement significatif
                  dans la production ou les certifications, procédure d&apos;insolvabilité conformément à la législation
                  applicable).
                </Text>
              </Li>
            </Ol>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'13.'} title={"Transactions commerciales - Secret d'affaires"} />
          <Paragraph>
            La Plateforme ne permet en aucun cas à des tiers (concurrents, associés, partenaires, etc.) d&apos;avoir
            connaissance des échanges, négociations, contrats et accords réalisés ou conclus par l&apos;intermédiaire de
            la Plateforme. La Plateforme s&apos;engage à respecter la confidentialité à tout moment.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'14.'} title={'Promotions et publicité'} />
          <Paragraph>
            L&apos;Acheteur s&apos;engage à ne pas utiliser la Plateforme pour promouvoir, directement ou indirectement,
            sous quelque forme que ce soit, ses produits ou services ou ceux de toute autre personne.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'15.'} title={"Absence d'obligation d'utilisation"} />
          <Paragraph>
            L&apos;adhésion à la Plateforme ne crée aucune obligation pour les Utilisateurs d&apos;utiliser la
            Plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'16.'} title={"Modification des présentes conditions d'utilisation"} />
          <Paragraph>
            La Plateforme se réserve le droit de modifier les présentes Conditions d&apos;utilisation de temps à autre.
            Toute modification prendra effet à la date indiquée dans l&apos;avis envoyé aux utilisateurs.
          </Paragraph>
          <Paragraph>
            Les modifications ne s&apos;appliquent pas aux transactions en cours à la date d&apos;entrée en vigueur des
            modifications.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'17.'} title={'Utilisation des données personnelles collectées par la Plateforme'} />
          <Paragraph>
            Lorsque vous utilisez la Plateforme, vos données personnelles seront traitées par Renault conformément à la
            Déclaration de confidentialité accessible via le lien suivant:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'18.'} title={'Force majeure'} />
          <Paragraph>
            La Plateforme ne saurait être tenue responsable en cas de défaillance de la Plateforme due à un cas de force
            majeure.
          </Paragraph>
          <Paragraph>
            La force majeure comprend notamment les pannes de courant, les pannes d’Internet et les pannes informatiques
            (&quot;bugs&quot;, virus, logiciels malveillants, cambriolages), même si leurs origines techniques résident
            dans la conception du service d’information.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'19.'} title={'Confidentialité'} />
          <Paragraph>
            La Plateforme mettra en œuvre les moyens techniques appropriés pour assurer la confidentialité des
            informations et données transmises par l’intermédiaire de la Plateforme ou des négociations menées par
            l’intermédiaire de la Plateforme afin qu’elles ne soient pas transmises à des personnes extérieures aux
            Services de la Plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'20.'} title={'Propriété intellectuelle'} />
          <Paragraph>
            Tous les éléments, tels que les textes, logos, images, éléments graphiques ou sonores, logiciels, mise en
            page, bases de données de la plateforme, noms d’entreprise, marques, dessins et conceptions sont protégés
            par des droits de propriété intellectuelle. Les vendeurs doivent s’abstenir de toute action qui porte
            atteinte à ces droits de quelque manière que ce soit.
          </Paragraph>
          <Paragraph>
            Afin d’utiliser la Plateforme, l’Acheteur est autorisé à utiliser les marques et droits de propriété
            intellectuelle en sa possession, ainsi que son nom commercial ou le nom de société.
          </Paragraph>
          <Paragraph>
            L’Acheteur est autorisé à indiquer son statut d’Acheteur sur la Plateforme sur ses documents commerciaux, à
            condition qu’il ait préalablement soumis les documents pertinents à la Plateforme.
          </Paragraph>
          <Paragraph>
            Les utilisateurs se voient accorder une licence limitée, non exclusive, non transférable et non
            sous-licenciable pour utiliser la plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'21.'} title={'Responsabilité de l’acheteur'} />
          <Paragraph>
            L’Acheteur est seul responsable de la commande des Produits proposés à la vente sur la Plateforme.
          </Paragraph>
          <Paragraph>
            L’Acheteur est également seul responsable de l’utilisation de la Chambre de l’Acheteur et des informations
            fournies aux vendeurs.
          </Paragraph>
          <Paragraph>
            Plus généralement, l’Acheteur est uniquement responsable en tant qu’Acheteur et la Plateforme ne peut être
            tenue responsable en qualité d’Acheteur.
          </Paragraph>
          <Paragraph>
            Dans le cas où un vendeur, ou toute autre personne, intente une action en justice contre la plate-forme pour
            des faits liés aux activités de l’acheteur, l’acheteur s’engage à indemniser la plate-forme de toute
            condamnation.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'22.'} title={'Droit applicable - litiges'} />
          <Paragraph>
            Les présentes conditions d’utilisation et l’utilisation de la plateforme sont régies par le droit belge.
          </Paragraph>
          <Paragraph>
            Le tribunal de Bruxelles est seul compétent pour connaître de tout litige, qu’il soit contractuel ou
            délictuel, découlant de la validité, de l’interprétation ou de l’exécution des présentes conditions
            d’utilisation ou en rapport avec celles-ci plus généralement, Les services proposés par la Plateforme ou la
            relation entre les fournisseurs et la Plateforme.
          </Paragraph>
        </Section>
        <MarginBox mt={200} />
        <Flex align={'center'} direction={'column'}>
          <Text type={'h5_bold'}>GEBRUIKSVOORWAARDEN RPARTSTORE</Text>
          {Language.dutchBelgium.name}
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Deze gebruiksvoorwaarden (hierna &quot;<b>Gebruiksvoorwaarden</b>&quot;) regelen de toegang tot en het
            gebruik van de RpartStore, beschikbaar gesteld door Renault België Luxemburg N.V., gevestigd aan de
            Bergensesteenweg 281, 1620 te Drogenbos en ingeschreven in het handelsregister van de Kamer van Koophandel
            onder nummer 0403 463 679 (hierna &quot;<b>Renault</b>&quot;)
          </Paragraph>
          <Paragraph>
            De gebruiker moet deze gebruiksvoorwaarden aandachtig lezen alvorens de dienst te gebruiken.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'1.'} title={'Het Platform'} />
          <Paragraph>
            De RpartStore website (hierna het &quot;<b>Platform</b>&quot;) is opgezet om het contact te vergemakkelijken
            tussen zakelijke verkopers (hierna de &quot;<b>Verkopers</b>&quot;) die onderdelen en accessoires (hierna de
            &quot;<b>Producten</b>&quot;) te koop willen aanbieden via een website aan zakelijke kopers (hierna de
            &quot;<b>Kopers</b>&quot;), in overeenstemming met deze Gebruiksvoorwaarden. Het Platform is uitsluitend
            bedoeld voor zakelijk voor zakelijk gebruik.
          </Paragraph>
          <Paragraph>
            Het Platform is toegankelijk via deze link:{' '}
            <Link to={'https://rpartstore.renault.com/'} external newTab>
              https://www.rpartstore.renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'2.'} title={'Hosting'} />
          <Paragraph>
            Het Platform fungeert als een technische tussenpersoon (hosting dienst). Het Platform biedt Verkopers en
            Kopers (gezamenlijk &quot;<b>Gebruikers</b>&quot;) technische diensten voor opslag en doorgifte van
            gegevens.
          </Paragraph>
          <Paragraph>
            De rol van het Platform is puur technisch en beperkt zich tot het faciliteren van contact tussen Verkopers
            en Kopers.
          </Paragraph>
          <Paragraph>
            Het Platform geeft geen garantie en is niet aansprakelijk voor overeenkomsten, uitwisselingen, aanbiedingen
            of diverse communicaties die via het Platform plaatsvinden.
          </Paragraph>
          <Paragraph>
            Het Platform is op geen enkele wijze een verkoper of wederverkoper van de Producten die worden aangeboden
            door de Verkopers via het Platform.
          </Paragraph>
          <Paragraph>
            Overeenkomsten met betrekking tot door Verkopers aangeboden Producten worden altijd rechtstreeks gesloten
            tussen Verkoper en Koper, buiten het Platform om. De Verkoper kan eenvoudigweg commerciële contacten
            genereren (leadformulier) via het Platform. De uiteindelijke prijs van de Producten wordt bepaald door de
            Verkoper buiten het Platform om.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'3.'} title={'Doel van deze Gebruiksvoorwaarden'} />
          <Paragraph>
            Het doel van deze Gebruiksvoorwaarden is het definiëren van de voorwaarden voor toegang tot en gebruik van
            het Platform door Gebruikers.
          </Paragraph>
          <Paragraph>
            Het doel van het Platform is om een directe digitale relatie te tot stand te brengen tussen Verkopers en
            Kopers om zo commerciële contacten tussen hen te genereren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'4.'} title={'Voorwaarden voor toegang tot het Platform voor Kopers'} />
          <Paragraph>
            Toegang tot het Platform kan worden verkregen door:
            <Ul>
              <Li>
                <Text type={'text'}>Verkopers die Kopers vragen lid te worden via de pagina voor leden;</Text>
              </Li>
              <Li>
                <Text type={'text'}>Validatie door Verkopers van de toegang van Kopers tot het Platform.</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            De Membership Mini-site is een e-business applicatie waarmee Verkopers het RpartStore lidmaatschap voor hun
            Kopers kunnen aanvragen. De applicatie is toegankelijk vanaf RenaultNet en RpartStore.
          </Paragraph>
          <Paragraph>
            Toegang tot de Membership Mini-site wordt verleend door contact op te nemen met: Renault Parts{' '}
            <Link to={'mailto:rparts@renault.be'} external newTab>
              rparts@renault.be
            </Link>
            . Toegang wordt verleend aan de Verkoper die hierom verzoekt op voorwaarde dat hij individueel wordt vermeld
            in het Renault-netwerk.
          </Paragraph>
          <Paragraph>
            De Membership Mini-site versnelt het lidmaatschapsproces en stelt Verkopers in staat om
            lidmaatschapsaanvragen voor klanten in te dienen, ongeacht de aard van de gebruiker in Tresor (backoffice
            omgeving van Renault Group) en RpartStore (klant onbekend in Tresor, nieuw lidmaatschap, nieuwe gebruiker).
          </Paragraph>
          <Paragraph>
            De Verkoper kan lidmaatschapsaanvragen doen voor zijn klanten in overeenstemming met de bepalingen van dit
            artikel 4 van deze Gebruiksvoorwaarden. Als de klant niet is geïdentificeerd in de Tresor-database, wordt
            een verzoek voor aanmaak/wijziging automatisch verzonden naar de betreffende Tresor-beheerder.
          </Paragraph>
          <Paragraph>
            De Verkoper kan de gegevens van de klant wijzigen en de voortgang van de lidmaatschapsaanvraag volgen. De
            Verkoper kan een openstaande aanvraag niet wijzigen, maar wel vragen om deze te sluiten. Het callcenter
            zorgt dan voor de definitieve afsluiting.
          </Paragraph>
          <Paragraph>De toepasselijkheid van artikel 6:227b BW is uitgesloten.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'5.'} title={'Toegelaten Producten op het Platform'} />
          <Paragraph>
            Het Platform selecteert de Producten die via het Platform kunnen worden besteld. Kopers kunnen Verkopers
            niet verzoeken of verplichten om via het Platform andere producten aan te bieden dan de Producten die vooraf
            door het Platform zijn geselecteerd.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'6.'} title={'Creatie van Kopersruimte – Vertrouwelijkheid van inloggegevens'} />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              De stappen:
            </Text>
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  Ontvangst van een e-mail van de Verkoper waarin wordt bevestigd dat het verzoek voor creatie van een
                  kopersruimte in behandeling is genomen. De e-mail vermeldt ook de verwerkingstijd voor het verzoek,
                  die varieert tussen 24 en 72 uur;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  De Koper ontvangt vervolgens per e-mail de nodige informatie (ID / Wachtwoord) om in te loggen in de
                  RpartStore;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  De Verkoper ontvangt tevens een bericht dat diens klant nu toegang heeft tot RpartStore.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  In het geval van een technisch incident op de aanvraag, wordt de Verkoper hiervan per e-mail op de
                  hoogte gesteld.
                </Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Het gebruik van de Kopersruimte is strikt beperkt tot de door de Verkoper aangewezen gebruiker.
          </Paragraph>
          <Paragraph>
            De Koper verbindt zich ertoe om zijn/haar inloggegeven(s) geheim te houden en deze niet openbaar te maken,
            in welke vorm of aan welke persoon dan ook, en in geval van frauduleus of ongeoorloofd gebruik, deze te
            annuleren en de feiten onmiddellijk te melden aan de klantenservice via het mailadres Renault Parts{' '}
            <Link to={'mailto:renaultparts@renault.be'} external newTab>
              renaultparts@renault.be
            </Link>
            . De Koper wordt ervan op de hoogte gesteld dat elke bekendmaking van zijn/haar inloggegeven(s) aan een
            derde partij de uitsluitende verantwoordelijkheid van Koper is.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'7.'} title={'Gepersonaliseerde relatie'} />
          <Paragraph>
            Kopers kunnen niet alle Verkopers benaderen die zijn toegelaten tot het Platform. Er wordt een relatie
            tussen één Koper en één Verkoper gecreëerd. Indien er meer relaties van toepassing zijn, dient hier opnieuw
            een aanvraag voor te worden ingediend.
          </Paragraph>
          <Paragraph>
            Op het Platform vindt de Koper de Verkoper die overeenkomt met het gebruikelijke fysieke kanaal waar Koper
            gebruik van maakt; de relatie tot uw verkoper verandert niet.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'8.'} title={'Betaling'} />
          <Paragraph>
            Via het Platform kunnen uitsluitend commerciële contacten worden gelegd. Het verrichten van betalingen via
            het Platform is niet mogelijk.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'9.'} title={'Beschikbaarheid van het Platform'} />
          <Paragraph>
            Voor zover toegestaan op grond van de toepasselijke wet- en regelgeving accepteert Renault geen enkele
            verantwoordelijkheid voor het gebruik van het Platform door Gebruikers. Renault geeft geen enkele garantie,
            expliciet of impliciet, voor de snelheid en/of prestaties van het Platform of voor het gebruik door de
            Gebruikers van de gegevens en/of informatie die toegankelijk zijn via het Platform.
          </Paragraph>
          <Paragraph>
            Renault garandeert met name niet dat het Platform vrij is van fouten, defecten of onvolkomenheden, of dat
            het niet onderhevig is aan schade of aanvallen, met name virussen, die kunnen leiden tot storingen,
            onderbrekingen van de dienstverlening, uitval of verlies van gegevens of informatie.
          </Paragraph>
          <Paragraph>
            Toegang tot het Platform kan tijdelijk en periodiek worden opgeschort om technische redenen, die de
            technische teams zo snel mogelijk proberen op te lossen.
          </Paragraph>
          <Paragraph>
            Renault is niet aansprakelijk voor:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  niet-naleving door de Gebruiker van de bepalingen van deze Gebruiksvoorwaarden ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  storingen van het Platform als gevolg van een vrijwillige of onvrijwillige oorzaak die kan worden
                  toegeschreven aan de Gebruiker of een derde, of als gevolg van een kwaadwillige handeling, of als
                  gevolg van storingen van software, smartphones, tablets of andere interfaces/producten van de
                  Gebruiker;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>wijzigingen in wet- of regelgeving die van invloed zijn op het Platform.</Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Bovendien erkent de Gebruiker dat het Platform kan worden geleverd door gebruik te maken van
            communicatienetwerken van derden en dat Renault in geen geval aansprakelijk kan worden gehouden in geval van
            storingen van deze communicatienetwerken.
          </Paragraph>
          <Paragraph>
            Wanneer Gebruikers het Platform gebruiken, verbinden zij zich ertoe dit te doen in overeenstemming met deze
            Gebruiksvoorwaarden en in overeenstemming met de toepasselijke wet – en regelgeving.
          </Paragraph>
          <Paragraph>
            De Koper en de Verkoper zijn ieder volledig verantwoordelijk voor het gebruik van het Platform en voor elk
            gebruik van de gegevens/informatie ontvangen via het Platform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'10.'} title={'Schade toebrengend gebruik van het Platform'} />
          <Paragraph>
            Gebruikers dienen zich in alle omstandigheden te onthouden van activiteiten die de beschikbaarheid en/of
            functionaliteiten van het Platform in gevaar kunnen brengen, de reputatie van het Platform kunnen schaden,
            de reputatie van de diensten die worden geleverd kunnen schaden en de reputatie en activiteiten van de
            Renault Groep kunnen schaden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'11.'} title={'Persoonsgebonden'} />
          <Paragraph>
            Het Platform, opgezet door de Renault Groep, draagt bij aan het imago, de reputatie en de bekendheid van de
            Renault Groep. De relatie tussen het Platform en zijn Gebruikers is daarom Persoonsgebonden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'12.'} title={'Uitschrijving, blokkering of uitsluiting van het Platform'} />
          <Text type={'text_bold'} decoration={'underline'}>
            Uitschrijven
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Iedere Koper kan op elk moment het Platform via diens R1 (leverende Renault dealer) op de hoogte stellen van
            zijn beslissing om niet langer gebruik te maken van zijn diensten.
          </Paragraph>
          <Paragraph>
            De Koper moet blijven voldoen aan de verplichtingen die hij is aangegaan met de Verkopers voorafgaand aan de
            datum waarop zijn deelname aan het Platform wordt beëindigd.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Blokkeren
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Het Platform kan de toegang van een Koper tot het Platform tijdelijk blokkeren, voor een periode van
            maximaal 6 maanden, in de volgende gevallen:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  ernstig vermoeden van gedrag dat het Platform, deze Gebruiksvoorwaarden, de toepasselijke wet- en
                  regelgeving of het imago en de reputatie van de Renault Groep ondermijnt;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>geen melding van wijzigingen in aangewezen items;</Text>
              </Li>
              <Li>
                <Text type={'text'}>gebruik van de Koperszone door andere personen dan de Koper;</Text>
              </Li>
              <Li>
                <Text type={'text'}>nalatigheid in de verplichting om inloggegevens vertrouwelijk te houden;</Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Gedurende deze periode zullen de Koper en het Platform zich te goeder trouw inspannen om het vastgestelde
            gedrag te verhelpen.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Uitsluiting
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Het Platform kan een Koper permanent uitsluiten van toegang tot het Platform in geval van:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  het verlaten van het Renault-netwerk dat is toegelaten tot het Platform, om welke reden dan ook;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  gedrag waarnaar wordt verwezen in dit artikel onder het kopje &apos;blokkeren&apos;. Het Platform zal
                  daarom de keuze hebben tussen blokkering en onmiddellijke uitsluiting;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  het geven van een onjuiste voorstelling van zaken, hoe ernstig ook, gezien dit een vertrouwensbreuk
                  tot gevolg heeft;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>herhaalde klachten van Verkopers;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  elke verandering in de persoonlijke situatie van de Koper die het persoonsgebonden karakter kan
                  beïnvloeden (in het bijzonder: directe of indirecte verandering van zeggenschap, belangrijke
                  verandering in productie of certificeringen, insolventieprocedures in overeenstemming met de geldende
                  wetgeving).
                </Text>
              </Li>
            </Ol>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'13.'} title={'Handelstransacties - Bedrijfsgeheim'} />
          <Paragraph>
            Het Platform staat in geen geval toe dat derden (concurrenten, vennoten, partners, enz.) kennis hebben van
            de uitwisselingen, onderhandelingen, contracten en overeenkomsten die worden uitgevoerd of aangegaan via het
            Platform. Het Platform zal te allen tijde vertrouwelijkheid betrachten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'14.'} title={'Promoties en reclame'} />
          <Paragraph>
            De Koper verbindt zich ertoe het Platform niet te gebruiken om direct of indirect, in welke vorm dan ook,
            zijn producten of diensten of die van een andere persoon te promoten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'15.'} title={'Geen gebruiksplicht'} />
          <Paragraph>
            Lidmaatschap van het Platform schept geen verplichting voor Gebruikers om het Platform te gebruiken.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'16.'} title={'Wijziging van deze Gebruiksvoorwaarden'} />
          <Paragraph>
            Het Platform behoudt zich het recht voor om deze Gebruiksvoorwaarden van tijd tot tijd te wijzigen. Elke
            wijziging wordt van kracht op de datum vermeld in de kennisgeving die naar Gebruikers wordt gestuurd.
          </Paragraph>
          <Paragraph>
            De wijzigingen zijn niet van toepassing op transacties die op de datum waarop de wijzigingen van kracht
            worden, onderhanden zijn.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'17.'} title={'Gebruik van persoonlijke gegevens verzameld door het Platform'} />
          <Paragraph>
            Wanneer u het Platform gebruikt, worden uw persoonsgegevens verwerkt door Renault in overeenstemming met de
            Privacyverklaring die toegankelijk is via de volgende link:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'18.'} title={'Overmacht'} />
          <Paragraph>
            Het Platform kan niet aansprakelijk worden gehouden in geval van een storing van het Platform veroorzaakt
            door overmacht.
          </Paragraph>
          <Paragraph>
            Overmacht omvat in het bijzonder stroomstoringen, internetstoringen, en computerstoringen (&quot;bugs&quot;,
            virussen, malware, inbraak), zelfs als hun technische oorsprong ligt in het ontwerp van de informatiedienst.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'19.'} title={'Vertrouwelijkheid'} />
          <Paragraph>
            Het Platform implementeert passende technische middelen om de vertrouwelijkheid te waarborgen van informatie
            en gegevens die via het Platform worden verzonden of van onderhandelingen die via het Platform worden
            gevoerd, zodat deze niet worden doorgegeven aan personen buiten de diensten van het Platform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'20.'} title={'Intellectueel eigendom'} />
          <Paragraph>
            Alle elementen, zoals teksten, logo&apos;s, afbeeldingen, grafische of geluidselementen, software, lay-out,
            Platform databases, bedrijfsnamen, merken, tekeningen en modellen, zijn beschermd door intellectuele
            eigendomsrechten. Verkopers dienen zich te onthouden van handelingen die op enigerlei wijze inbreuk maken op
            deze rechten.
          </Paragraph>
          <Paragraph>
            Voor het gebruik van het Platform is de Koper gemachtigd om de handelsmerken en intellectuele
            eigendomsrechten waarover hij beschikt te gebruiken, evenals zijn handelsnaam of bedrijfsnaam.
          </Paragraph>
          <Paragraph>
            De Koper is gemachtigd om zijn status als Koper op het Platform te vermelden op zijn commerciële documenten,
            op voorwaarde dat hij de relevante documenten vooraf heeft ingediend bij het Platform.
          </Paragraph>
          <Paragraph>
            Gebruikers krijgen een beperkte, niet-exclusieve, niet-overdraagbare, niet-sublicentieerbare licentie om het
            Platform te gebruiken.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'21.'} title={'Aansprakelijkheid koper'} />
          <Paragraph>
            De Koper is als enige verantwoordelijk voor de bestelling van de Producten die te koop worden aangeboden op
            het Platform.
          </Paragraph>
          <Paragraph>
            De Koper is ook als enige verantwoordelijk voor het gebruik van Kopersruimte en de informatie die aan
            Verkopers wordt gegeven.
          </Paragraph>
          <Paragraph>
            Meer in het algemeen is de Koper alleen aansprakelijk als Koper en kan het Platform niet aansprakelijk
            worden gesteld in de hoedanigheid van Koper.
          </Paragraph>
          <Paragraph>
            In het geval dat een Verkoper, of een andere persoon, gerechtelijke stappen onderneemt tegen het Platform
            voor feiten die verband houden met de activiteiten van Koper, verbindt de Koper zich ertoe om het Platform
            te vrijwaren van elke veroordeling.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'22.'} title={'Toepasselijk recht - Geschillen'} />
          <Paragraph>
            Op deze Gebruiksvoorwaarden en het gebruik van het Platform is Belgische recht van toepassing.
          </Paragraph>
          <Paragraph>
            De rechtbank van Brussel is exclusief bevoegd kennis te nemen van alle geschillen, hetzij contractueel,
            hetzij uit onrechtmatige daad, voortvloeiend uit of in verband met de geldigheid, interpretatie of
            uitvoering van deze Gebruiksvoorwaarden of, meer in het algemeen, de diensten aangeboden door het Platform
            of de relatie tussen de Verkopers en het Platform.
          </Paragraph>
        </Section>
        <MarginBox mt={60} />
        <Text type={'h5_bold'}>BIJLAGE I - INFORMATIE VOOR KOPERS - TOEGANGSPROCEDURE</Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-NL.png'} alt={'information-diagram-netherlands'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
