import styled from 'styled-components';
import { defaultBoxShadow } from 'UI';

export const SMultiplateCard = styled.div`
  border-radius: 14.5px;
  ${defaultBoxShadow};
  background-color: white;
  cursor: pointer;

  :hover {
    filter: brightness(0.9);
    box-shadow: 0 5px 4px 0 ${({ theme }) => theme.color.grey85};
  }
`;
