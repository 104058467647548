import styled from 'styled-components';
import { effect } from 'styles/effect';

export const SellerCardStyled = styled.div<{ active: boolean }>`
  width: 420px;
  height: 100px;
  margin: 30px 0 0 0;
  padding: 15px 15px 15px 15px;
  border-radius: 14.4px;
  ${effect.hover.enlarge}
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.color.grey90 : theme.color.white)};
  box-shadow: 0 4px 10px 0 ${({ theme }) => theme.color.shadow_2};
`;

//   background-color: ${({ active, theme }) => (active ? theme.color.brand : theme.color.brand)};
//

export const BlackBox = styled.div`
  display: flex;
  background-color: black;
  width: 70px;
  height: 70px;
`;
