import React from 'react';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataRO = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>RPARTSTORE</Text>
          <Text type={'h5_bold'}>NOTIFICARE PENTRU INFORMAREA PRIVIND</Text>
          <Text type={'h5_bold'}>PROTECȚIA DATELOR CU CARACTER PERSONAL</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Site-ul web RPARTSTORE accesibil la adresa{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>{' '}
            („Platforma”), s-a creat pentru a facilita consultarea documentației tehnice post-vânzare pentru piesele și
            accesoriile Renault („Produsele”), precum și pentru a permite reunirea vânzătorilor („Vânzătorii”) care
            doresc să ofere Produse spre vânzare prin intermediul unui site web cumpărătorilor („Cumpărătorii”), în
            conformitate cu Termenii şi Condițiile Generale de Utilizare ale Platformei. Vânzătorii și Cumpărătorii sunt
            denumiți împreună „Utilizatorii”.
          </Paragraph>
          <Paragraph>
            Scopul acestei notificări pentru informarea privind protecția datelor este de a asigura transparența cu
            privire la prelucrarea datelor cu caracter personal efectuată în contextul utilizării Platformei de către
            dvs.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'În ce scop se utilizează datele dvs. și pe ce durată?'} />
          <Paragraph>
            Când utilizați Platforma, datele dvs. cu caracter personal sunt prelucrate în următoarele scopuri:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                <strong>Pentru a vă gestiona drepturile de acces și autorizare pe Platformă</strong> pe baza interesului
                nostru legitim de a gestiona confidențialitatea și securitatea datelor dvs. cu caracter personal.
                Prelucrăm aceste date pe durata în care contul dvs. de utilizator este deschis, cu excepția cazului în
                care este inactiv.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Pentru a gestiona securitatea și buna funcționare a aplicațiilor și rețelelor</strong> pe baza
                interesului nostru legitim de a garanta securitatea informatică a Platformei. Prelucrăm datele dvs. timp
                de 30 de zile în bazele noastre de date.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>
                  Pentru a răspunde, acolo unde este cazul, solicitărilor dvs. de a vă exercita drepturile în legătură
                  cu datele dvs. cu caracter personal,
                </strong>{' '}
                în conformitate cu obligațiile noastre legale și/sau de reglementare. Datele dvs. cu caracter personal
                sunt păstrate pentru timpul necesar pentru a răspunde la solicitarea dvs. Cu toate acestea, trebuie să
                păstrăm dovezi de arhivă cu privire la răspunsul nostru la solicitările dvs. de exercitare a drepturilor
                (inclusiv când sunt aplicabile datele cu caracter personal pe care le-am compilat în acest scop) pentru
                a putea răspunde oricărei acțiuni în justiție, pe durata termenului de prescripție prevăzut de
                legislația aplicabilă.
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            În plus, Platforma folosește module cookie și alte tehnologii de urmărire pentru a-și gestiona funcționarea
            și securitatea, precum și pentru a măsura audiența și performanța Platformei în condițiile stabilite în
            Politica privind modulele cookie aferentă Platformei, care poate fi{' '}
            <Link to={ROUTER_COOKIES(Country.RO.key)} external newTab>
              accesată aici
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Ce date cu caracter personal sunt prelucrate?'} />
          <Paragraph>
            Datele cu caracter personal pe care le colectăm depind de interacțiunea noastră cu dvs. și includ date
            legate de:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                Identitatea și datele dvs. de contact (nume de familie, prenume, adresa poștală și cea de e-mail de
                serviciu);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>Identificatori tehnici (număr IPN)</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Date de conectare (jurnale de activitate, module cookie) în conformitate cu{' '}
                <Link to={ROUTER_COOKIES(Country.RO.key)} external newTab>
                  Politica privind modulele cookie a Platformei
                </Link>
                .
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
        </Section>
        <Section>
          <SectionHeader title={'Cine prelucrează datele dvs. cu caracter personal?'} />
          <Paragraph>
            Operațiunile de prelucrare enumerate mai sus sunt efectuate de Renault SAS, după caz, în colaborare cu
            afiliatul Renault care își desfășoară activitatea în țara dvs. (*). În plus, Vânzătorii, membri ai rețelei
            primare a acestuia din urmă, responsabili de activitățile de distribuție, participă la gestionarea
            drepturilor dvs. de acces și autorizare pe Platformă prin crearea de profiluri de Utilizator în directoarele
            interne și instrumentele de autentificare ale Grupului Renault.
          </Paragraph>
          <Paragraph>
            <i>
              (*) Pentru a afla identitatea juridică a afiliatului Renault care își desfășoară activitatea în țara dvs.,
              vă rugăm să vizitați{' '}
              <Link to={'https://www.renaultgroup.com/en/directory/'} external newTab>
                directorul nostru
              </Link>
              , selectați-vă țara din listă și consultați informațiile sale juridice.
            </i>
          </Paragraph>
          <Paragraph>
            <i>
              Renault SAS are sediul în Franța, la adresa 122-122 bis avenue du Général Leclerc - 92 100
              Boulogne-Billancourt. Datele de contact ale responsabilului său cu protecția datelor (RPD) sunt
              următoarele: Renault SAS, Direction juridique – Délégué à la protection des données, 122-122 bis avenue du
              Général Leclerc – 92 100 Boulogne-Billancourt.
            </i>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Cine are acces la datele dvs. cu caracter personal?'} />
          <Paragraph>
            Ca parte a operațiunilor noastre de prelucrare a datelor, datele dvs. cu caracter personal pot fi accesate
            numai de personalul intern autorizat corespunzător și de furnizorii noștri de servicii terță parte de
            încredere, acționând ca subcontractanți conform instrucțiunilor noastre și numai în numele nostru. În plus,
            putem comunica datele dvs. cu caracter personal unor terțe părți pentru a ne conforma oricărei obligații
            legale sau unei decizii administrative sau judiciare.
          </Paragraph>
          <Paragraph>
            Întrucât parte dintre acești furnizori de servicii sau subcontractanții lor își au sediul în țări din afara
            UE/SEE, am convenit să implementăm măsuri de protecție adecvate prin semnarea Clauzelor Contractuale
            Standard (CCS).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Care sunt drepturile dvs. asupra datelor dvs. cu caracter personal?'} />
          <Paragraph>
            În conformitate cu reglementările aplicabile, în ceea ce privește activitățile de prelucrare descrise în
            această notificare pentru infirmare, aveți:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                <strong>Dreptul de acces:</strong> pentru a obține informații despre prelucrarea datelor dvs. cu
                caracter personal (în special, ce date sunt utilizate, în ce scop etc.) și o copie a acestor date.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Dreptul la rectificare:</strong> pentru a obține rectificarea datelor dvs. cu caracter personal
                dacă acestea sunt inexacte sau incomplete.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Dreptul la ștergere:</strong> pentru a obține ștergerea datelor dvs. cu caracter personal, fiind
                specificat faptul că acest drept poate fi limitat având în vedere obligațiile noastre contractuale sau
                legale.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Dreptul de a obiecta:</strong> pentru a vă opune utilizării unora dintre datele dvs. cu caracter
                personal. Trebuie să oferiți motive specifice legate de situația dvs. particulară.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Dreptul la restricționarea prelucrării:</strong> pentru a bloca utilizarea datelor dvs., în
                special dacă contestați acuratețea datelor dvs. cu caracter personal, v-ați opus prelucrării datelor
                dvs. cu caracter personal sau aveți nevoie de aceste date, de exemplu pentru a vă exercita sau a apăra
                un drept și doriți să vă asigurați că aceste date nu vor fi șterse (de exemplu, în cazul în care expiră
                perioada de păstrare).
              </Text>
            </Li>
          </Ul>
          <Paragraph align={'start'}>
            Vă puteți exercita toate drepturile prin trimiterea solicitării la adresa:{' '}
            <Link to={'mailto:dpo@renault.com'} external>
              dpo@renault.com
            </Link>{' '}
            sau{' '}
            <Link to={'mailto:dpo.romania@renault.com'} external>
              dpo.romania@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>
            <i>Versiune actualizată: Ianuarie 2024</i>
          </Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
