import React, { ChangeEvent } from 'react';
import { useTheme } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { ROUTER_HOME } from 'app/AppRouter';
import { SearchIcon } from 'assets/icons';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { Flex, Icon, Input, InputProps, MarginBox, Spin } from 'UI';
import { ONCHANGE_DEBOUNCE_INTERVAL } from 'utils/hooks/useInput';
import { Wrapper } from './SearchInput.styled';

export interface SearchInputProps extends InputProps {
  isLoading?: boolean;
  onClick?: () => void;
  name?: string;
}

export function SearchInput({
  id,
  name,
  value,
  onChange,
  onChangeDebounced,
  placeholder,
  color,
  onPressEnter,
  passRef,
  onKeyPress,
  onBlur,
  isLoading,
  onClick,
  className,
  autoComplete,
}: Readonly<SearchInputProps>) {
  const theme = useTheme();

  const handleChangeDebounced = useDebouncedCallback((debouncedVal: string, e?: ChangeEvent<HTMLInputElement>) => {
    onChangeDebounced && onChangeDebounced(debouncedVal, e);
  }, ONCHANGE_DEBOUNCE_INTERVAL);

  function onChangeLocal(val: string, e?: ChangeEvent<HTMLInputElement>) {
    onChange && onChange(val, e);
    handleChangeDebounced(val.trim(), e);
  }

  return (
    <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_ref_search_field`} placement={'bottom'}>
      <form onSubmit={(e) => e.preventDefault()}>
        <Wrapper className={className}>
          <Input
            id={id}
            name={name}
            autoComplete={autoComplete}
            value={value}
            onChange={onChangeLocal}
            placeholder={placeholder}
            color={color}
            onPressEnter={onPressEnter}
            passRef={passRef}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            ellipsis
            dataCy={'search-bar'}
          />
          {isLoading ? (
            <Flex align={'center'} justify={'center'}>
              <MarginBox mt={2}>
                <Spin />
              </MarginBox>
            </Flex>
          ) : (
            <Icon
              IconComponent={SearchIcon}
              width={24}
              height={24}
              display={'block'}
              mt={'auto'}
              mb={'auto'}
              color={theme.color.grey}
              onClick={onClick}
              dataCy={'button-search-searchbar'}
            />
          )}
        </Wrapper>
      </form>
    </FirstHelpPopin>
  );
}
