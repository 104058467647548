import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Text as PdfText, View } from '@react-pdf/renderer';
import {
  BoltIcon,
  EngineIcon,
  GasIcon,
  GearboxIcon,
  PictureCalendar,
  PictureCar,
  PictureFlag,
} from 'components/Pdf/PdfSvg';
import { fonts } from 'components/Pdf/PdfUI.styles';
import { CarDetailHeaderImage } from 'pages/CatalogPage/IAM/MaintenancePlan/ChecklistPdf';
import { theme } from 'styles';
import { capitalize } from 'UI';
import { isQueryBMM, textFormatter } from 'utils';
import { Country } from 'utils/i18n/Country';

export const VehicleContext = ({
  vehicleDetail,
  carImageUrl,
}: {
  vehicleDetail: VehicleDetail | undefined;
  carImageUrl: string | undefined;
}) => {
  const { t } = useTranslation();
  if (vehicleDetail) {
    const country = Country.findByKey(vehicleDetail.country);
    const isBMM = isQueryBMM(vehicleDetail.vehicleKey);
    const toDate =
      vehicleDetail.iamVehicle && vehicleDetail.iamVehicle.toDate ? ` - ${vehicleDetail.iamVehicle.toDate}` : '';
    const hpPowerIAM =
      vehicleDetail.iamVehicle?.hpPower ?? Math.floor(1.341 * Number(vehicleDetail.iamVehicle?.kwPower));
    const gearboxCode =
      vehicleDetail.iamVehicle && vehicleDetail.iamVehicle.gearboxCode && `[${vehicleDetail.iamVehicle.gearboxCode}]`;

    return (
      <View style={{ maxHeight: 90 }}>
        <View
          style={[
            {
              marginTop: 9,
              marginBottom: 10,
              height: 20,
              width: 250,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              backgroundColor: theme.color.grey00,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            },
          ]}
        >
          <>
            <View style={{ marginLeft: 10 }}></View>
            <PdfText style={[fonts.lead_text_white]}>
              {[
                vehicleDetail.vehicleBrand,
                vehicleDetail.model,
                vehicleDetail.iamVehicle &&
                  !isBMM &&
                  vehicleDetail.iamVehicle.phase &&
                  t('catalog.parts.banner.phase', 'Phase {{phase}}', { phase: vehicleDetail.iamVehicle.phase }),
              ]
                .filter(Boolean)
                .join(' ')}
            </PdfText>
            <View style={{ marginLeft: 50 }}></View>
            {!isBMM && country && <PictureFlag Flag={country.icon} />}
            <View style={{ marginLeft: 5 }}></View>
            {vehicleDetail.vrn !== undefined && <PdfText style={[fonts.lead_text_white]}>{vehicleDetail.vrn}</PdfText>}
            <View style={{ marginLeft: 10 }}></View>
          </>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* left image */}
          <CarDetailHeaderImage imageUrl={carImageUrl} />
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View style={{ marginLeft: 5 }}></View>
              {/* VINField */}
              <PdfText style={[fonts.textMid, fonts.bold]}>
                <Trans i18nKey={'catalog.vehicle.vin'}>VIN</Trans>: {vehicleDetail.vin ? vehicleDetail.vin : '-'}
              </PdfText>
              {/* IAMField */}
              {vehicleDetail.iamVehicle && (vehicleDetail.iamVehicle.doors || vehicleDetail.iamVehicle.bodyType) && (
                <>
                  <View style={{ marginLeft: 15 }}></View>
                  <PictureCar />
                  <View style={{ marginLeft: 5 }}></View>
                  <PdfText style={[fonts.textMid]}>
                    {vehicleDetail.iamVehicle?.bodyType && capitalize(vehicleDetail.iamVehicle.bodyType.toLowerCase())}
                    {vehicleDetail.iamVehicle.doors &&
                      ` ${t('catalog.parts.banner.doors', '{{doors}} doors', {
                        doors: vehicleDetail.iamVehicle.doors,
                      })}`}
                  </PdfText>
                </>
              )}

              {(vehicleDetail.manufacturingDate ||
                vehicleDetail.iamVehicle?.registrationDate ||
                vehicleDetail.iamVehicle?.toDate ||
                vehicleDetail.iamVehicle?.fromDate) && (
                <>
                  <>
                    <View
                      style={{
                        marginLeft:
                          (vehicleDetail.iamVehicle &&
                            (vehicleDetail.iamVehicle.doors || vehicleDetail.iamVehicle.bodyType)) ||
                          (vehicleDetail.dataHubVehicle && vehicleDetail.dataHubVehicle.modelType)
                            ? 30
                            : 0,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <PictureCalendar />
                      <PdfText style={[fonts.textMid]}>
                        {vehicleDetail.dataHubVehicle &&
                          vehicleDetail.manufacturingDate &&
                          textFormatter.formatDate(new Date(vehicleDetail.manufacturingDate))}
                        {vehicleDetail.iamVehicle &&
                          (vehicleDetail.iamVehicle.registrationDate
                            ? vehicleDetail.iamVehicle.registrationDate
                            : vehicleDetail.iamVehicle.fromDate
                            ? `${vehicleDetail.iamVehicle.fromDate}${toDate}`
                            : '')}
                      </PdfText>
                    </View>
                  </>
                </>
              )}
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 5,
              }}
            >
              {/* OtherField */}
              <View
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <View style={{ marginLeft: 5 }}></View>
                {(vehicleDetail.iamVehicle?.version && (
                  <PdfText style={[fonts.textMid]}>{vehicleDetail.iamVehicle?.version}</PdfText>
                )) ||
                  (vehicleDetail.iamVehicle?.engineCodes && (
                    <>
                      <EngineIcon />
                      <PdfText style={[fonts.textMid]}>{`${
                        vehicleDetail.iamVehicle.version ? vehicleDetail.iamVehicle.version : ''
                      }${
                        vehicleDetail.iamVehicle.engineCodes ? ` [${vehicleDetail.iamVehicle.engineCodes}]` : ''
                      }`}</PdfText>
                    </>
                  ))}
                <View style={{ marginLeft: 30 }} />
                {vehicleDetail.iamVehicle?.kwPower && (
                  <>
                    <BoltIcon />
                    <View style={{ marginLeft: 5 }}></View>
                    <PdfText style={[fonts.textMid]}>{`${
                      vehicleDetail.iamVehicle.kwPower
                        ? t('catalog.parts.banner.kw', '{{kw}} kW', { kw: vehicleDetail.iamVehicle.kwPower })
                        : ''
                    }${vehicleDetail.iamVehicle.kwPower && hpPowerIAM ? ' / ' : ''}${
                      hpPowerIAM ? t('catalog.parts.banner.hp', '{{hp}} HP', { hp: hpPowerIAM }) : ''
                    }`}</PdfText>
                    <View style={{ marginLeft: 30 }} />
                  </>
                )}

                <>
                  {vehicleDetail.iamVehicle && vehicleDetail.iamVehicle.energy && (
                    <>
                      <GasIcon />
                      <View style={{ marginLeft: 5 }}></View>
                      <PdfText style={[fonts.textMid]}>{`${capitalize(
                        vehicleDetail.iamVehicle.energy.toLowerCase(),
                      )}`}</PdfText>
                      <View style={{ marginLeft: 30 }} />
                    </>
                  )}
                  {vehicleDetail.iamVehicle &&
                    (vehicleDetail.iamVehicle.gearbox ||
                      vehicleDetail.iamVehicle.gearboxType ||
                      vehicleDetail.iamVehicle.gearboxCode) && (
                      <>
                        <GearboxIcon />
                        <View style={{ marginLeft: 5 }}></View>
                        <PdfText style={[fonts.textMid]}>{`${
                          vehicleDetail.iamVehicle.gearboxType
                            ? capitalize(vehicleDetail.iamVehicle.gearboxType.toLowerCase())
                            : ''
                        }${vehicleDetail.iamVehicle.gearboxType && vehicleDetail.iamVehicle.gearbox ? ' ' : ''}${
                          vehicleDetail.iamVehicle.gearbox
                            ? capitalize(vehicleDetail.iamVehicle.gearbox.toLowerCase())
                            : ''
                        }${
                          (vehicleDetail.iamVehicle.gearboxType || vehicleDetail.iamVehicle.gearbox) &&
                          vehicleDetail.iamVehicle.gearboxCode
                            ? ' '
                            : ''
                        }${vehicleDetail.iamVehicle.gearboxCode ? gearboxCode : ''}`}</PdfText>
                        <View style={{ marginLeft: 30 }} />
                      </>
                    )}
                </>
              </View>
            </View>
          </View>
        </View>

        <View style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: '#979797', width: 550 }}></View>
      </View>
    );
  }
  return <Trans i18nKey={'order.order_detail.no_vehicle_context'}>No vehicle context</Trans>;
};
