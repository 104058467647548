import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { ReferenceBlock, VehicleBlock } from 'components/MotrioWarranty/step/TypeOfGuaranteeStep/Block';
import { WarrantyRequests } from 'components/MotrioWarranty/step/TypeOfGuaranteeStep/WarrantyRequests';
import { Flex, MarginBox, Text } from 'UI';

interface TypeOfGuaranteeStepProps {
  reference: OrderReferenceItem;
  vehicleDetail?: VehicleDetail;
}

export function TypeOfGuaranteeStep(props: TypeOfGuaranteeStepProps) {
  const { t } = useTranslation();
  return (
    <Flex direction={'column'}>
      <Text type={'h4_paragraph_title'} disableGutter>
        {t('motrio.warranty.select_type_of_guarantee.header', 'Select the type of guarantee...')}
      </Text>
      <MarginBox mt={16} />
      <Text type={'text_disabled'}>
        {t(
          'motrio.warranty.select_type_of_guarantee.description',
          'Select the type of warranty for the reference and vehicle below:',
        )}
      </Text>
      <MarginBox mt={15} />
      <Flex>
        <ReferenceBlock reference={props.reference} />
        <MarginBox ml={32} />
        <VehicleBlock vehicleDetail={props.vehicleDetail} />
      </Flex>
      <MarginBox mt={32} />
      <WarrantyRequests />
      <MarginBox mt={32} />
    </Flex>
  );
}
