import React from 'react';
import { Container, Flex, Image, Li, Link, MarginBox, Ol, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const TermsAndConditionsNL = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>GEBRUIKSVOORWAARDEN RPARTSTORE</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Deze gebruiksvoorwaarden (hierna &quot;<b>Gebruiksvoorwaarden</b>&quot;) regelen de toegang tot en het
            gebruik van de RpartStore, beschikbaar gesteld door Renault Nederland N.V., gevestigd aan de Boeingavenue
            275, (1119 PD) te Schiphol-Rijk en ingeschreven in het handelsregister van de Kamer van Koophandel onder
            nummer 33102829 (hierna &quot;<b>Renault</b>&quot;)
          </Paragraph>
          <Paragraph>
            De gebruiker moet deze gebruiksvoorwaarden aandachtig lezen alvorens de dienst te gebruiken.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'1.'} title={'Het Platform'} />
          <Paragraph>
            De RpartStore website (hierna het &quot;<b>Platform</b>&quot;) is opgezet om het contact te vergemakkelijken
            tussen zakelijke verkopers (hierna de &quot;<b>Verkopers</b>&quot;) die onderdelen en accessoires (hierna de
            &quot;<b>Producten</b>&quot;) te koop willen aanbieden via een website aan zakelijke kopers (hierna de
            &quot;
            <b>Kopers</b>&quot;), in overeenstemming met deze Gebruiksvoorwaarden. Het Platform is uitsluitend bedoeld
            voor zakelijk voor zakelijk gebruik.
          </Paragraph>
          <Paragraph>
            Het Platform is toegankelijk via deze link:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'2.'} title={'Hosting'} />
          <Paragraph>
            Het Platform fungeert als een technische tussenpersoon (hosting dienst). Het Platform biedt Verkopers en
            Kopers (gezamenlijk &quot;<b>Gebruikers</b>&quot;) technische diensten voor opslag en doorgifte van
            gegevens.
          </Paragraph>
          <Paragraph>
            De rol van het Platform is puur technisch en beperkt zich tot het faciliteren van contact tussen Verkopers
            en Kopers.
          </Paragraph>
          <Paragraph>
            Het Platform geeft geen garantie en is niet aansprakelijk voor overeenkomsten, uitwisselingen, aanbiedingen
            of diverse communicaties die via het Platform plaatsvinden.
          </Paragraph>
          <Paragraph>
            Het Platform is op geen enkele wijze een verkoper of wederverkoper van de Producten die worden aangeboden
            door de Verkopers via het Platform.
          </Paragraph>
          <Paragraph>
            Overeenkomsten met betrekking tot door Verkopers aangeboden Producten worden altijd rechtstreeks gesloten
            tussen Verkoper en Koper, buiten het Platform om. De Verkoper kan eenvoudigweg commerciële contacten
            genereren (<i>leadformulier</i>) via het Platform. De uiteindelijke prijs van de Producten wordt bepaald
            door de Verkoper buiten het Platform om.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'3.'} title={'Doel van deze Gebruiksvoorwaarden'} />
          <Paragraph>
            Het doel van deze Gebruiksvoorwaarden is het definiëren van de voorwaarden voor toegang tot en gebruik van
            het Platform door Gebruikers.
          </Paragraph>
          <Paragraph>
            Het doel van het Platform is om een directe digitale relatie te tot stand te brengen tussen Verkopers en
            Kopers om zo commerciële contacten tussen hen te genereren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'4.'} title={'Voorwaarden voor toegang tot het Platform voor Kopers'} />
          <Paragraph>
            Toegang tot het Platform kan worden verkregen door:
            <Ul>
              <Li>
                <Text type={'text'}>Verkopers die Kopers vragen lid te worden via de pagina voor leden;</Text>
              </Li>
              <Li>
                <Text type={'text'}>Validatie door Verkopers van de toegang van Kopers tot het Platform.</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            De Membership Mini-site is een e-business applicatie waarmee Verkopers het RpartStore lidmaatschap voor hun
            Kopers kunnen aanvragen. De applicatie is toegankelijk vanaf RenaultNet en RpartStore.
          </Paragraph>
          <Paragraph>
            Toegang tot de Membership Mini-site wordt verleend door contact op te nemen met: Renault Parts{' '}
            <Link to={'mailto:renaultparts@renault.nl'} external newTab>
              renaultparts@renault.nl
            </Link>
            . Toegang wordt verleend aan de Verkoper die hierom verzoekt op voorwaarde dat hij individueel wordt vermeld
            in het Renault-netwerk.
          </Paragraph>
          <Paragraph>
            De Membership Mini-site versnelt het lidmaatschapsproces en stelt Verkopers in staat om
            lidmaatschapsaanvragen voor klanten in te dienen, ongeacht de aard van de gebruiker in Tresor (backoffice
            omgeving van Renault Group) en RpartStore (klant onbekend in Tresor, nieuw lidmaatschap, nieuwe gebruiker).
          </Paragraph>
          <Paragraph>
            De Verkoper kan lidmaatschapsaanvragen doen voor zijn klanten in overeenstemming met de bepalingen van dit
            artikel 4 van deze Gebruiksvoorwaarden. Als de klant niet is geïdentificeerd in de Tresor-database, wordt
            een verzoek voor aanmaak/wijziging automatisch verzonden naar de betreffende Tresor-beheerder.
          </Paragraph>
          <Paragraph>
            De Verkoper kan de gegevens van de klant wijzigen en de voortgang van de lidmaatschapsaanvraag volgen. De
            Verkoper kan een openstaande aanvraag niet wijzigen, maar wel vragen om deze te sluiten. Het callcenter
            zorgt dan voor de definitieve afsluiting.
          </Paragraph>
          <Paragraph>De toepasselijkheid van artikel 6:227b BW is uitgesloten.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'5.'} title={'Toegelaten Producten op het Platform'} />
          <Paragraph>
            Het Platform selecteert de Producten die via het Platform kunnen worden besteld. Kopers kunnen Verkopers
            niet verzoeken of verplichten om via het Platform andere producten aan te bieden dan de Producten die vooraf
            door het Platform zijn geselecteerd.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'6.'} title={'Creatie van Kopersruimte – Vertrouwelijkheid van inloggegevens'} />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              De stappen:
            </Text>
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  Ontvangst van een e-mail van de Verkoper waarin wordt bevestigd dat het verzoek voor creatie van een
                  kopersruimte in behandeling is genomen. De e-mail vermeldt ook de verwerkingstijd voor het verzoek,
                  die varieert tussen 24 en 72 uur;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  De Koper ontvangt vervolgens per e-mail de nodige informatie (ID / Wachtwoord) om in te loggen in de
                  RpartStore;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  De Verkoper ontvangt tevens een bericht dat diens klant nu toegang heeft tot RpartStore.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  In het geval van een technisch incident op de aanvraag, wordt de Verkoper hiervan per e-mail op de
                  hoogte gesteld.
                </Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Het gebruik van de Kopersruimte is strikt beperkt tot de door de Verkoper aangewezen gebruiker.
          </Paragraph>
          <Paragraph>
            De Koper verbindt zich ertoe om zijn/haar inloggegeven(s) geheim te houden en deze niet openbaar te maken,
            in welke vorm of aan welke persoon dan ook, en in geval van frauduleus of ongeoorloofd gebruik, deze te
            annuleren en de feiten onmiddellijk te melden aan de klantenservice via het mailadres Renault Parts{' '}
            <Link to={'renaultparts@renault.nl'} external newTab>
              renaultparts@renault.nl
            </Link>
            . De Koper wordt ervan op de hoogte gesteld dat elke bekendmaking van zijn/haar inloggegeven(s) aan een
            derde partij de uitsluitende verantwoordelijkheid van Koper is.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'7.'} title={'Gepersonaliseerde relatie'} />
          <Paragraph>
            Kopers kunnen niet alle Verkopers benaderen die zijn toegelaten tot het Platform. Er wordt een relatie
            tussen één Koper en één Verkoper gecreëerd. Indien er meer relaties van toepassing zijn, dient hier opnieuw
            een aanvraag voor te worden ingediend.
          </Paragraph>
          <Paragraph>
            Op het Platform vindt de Koper de Verkoper die overeenkomt met het gebruikelijke fysieke kanaal waar Koper
            gebruik van maakt; de relatie tot uw verkoper verandert niet.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'8.'} title={'Betaling'} />
          <Paragraph>
            Via het Platform kunnen uitsluitend commerciële contacten worden gelegd. Het verrichten van betalingen via
            het Platform is niet mogelijk.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'9.'} title={'Beschikbaarheid van het Platform'} />
          <Paragraph>
            Voor zover toegestaan op grond van de toepasselijke wet- en regelgeving accepteert Renault geen enkele
            verantwoordelijkheid voor het gebruik van het Platform door Gebruikers. Renault geeft geen enkele garantie,
            expliciet of impliciet, voor de snelheid en/of prestaties van het Platform of voor het gebruik door de
            Gebruikers van de gegevens en/of informatie die toegankelijk zijn via het Platform.
          </Paragraph>
          <Paragraph>
            Renault garandeert met name niet dat het Platform vrij is van fouten, defecten of onvolkomenheden, of dat
            het niet onderhevig is aan schade of aanvallen, met name virussen, die kunnen leiden tot storingen,
            onderbrekingen van de dienstverlening, uitval of verlies van gegevens of informatie.
          </Paragraph>
          <Paragraph>
            Toegang tot het Platform kan tijdelijk en periodiek worden opgeschort om technische redenen, die de
            technische teams zo snel mogelijk proberen op te lossen.
          </Paragraph>
          <Paragraph>
            Renault is niet aansprakelijk voor:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  niet-naleving door de Gebruiker van de bepalingen van deze Gebruiksvoorwaarden ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  storingen van het Platform als gevolg van een vrijwillige of onvrijwillige oorzaak die kan worden
                  toegeschreven aan de Gebruiker of een derde, of als gevolg van een kwaadwillige handeling, of als
                  gevolg van storingen van software, smartphones, tablets of andere interfaces/producten van de
                  Gebruiker;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>wijzigingen in wet- of regelgeving die van invloed zijn op het Platform.</Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Bovendien erkent de Gebruiker dat het Platform kan worden geleverd door gebruik te maken van
            communicatienetwerken van derden en dat Renault in geen geval aansprakelijk kan worden gehouden in geval van
            storingen van deze communicatienetwerken.
          </Paragraph>
          <Paragraph>
            Wanneer Gebruikers het Platform gebruiken, verbinden zij zich ertoe dit te doen in overeenstemming met deze
            Gebruiksvoorwaarden en in overeenstemming met de toepasselijke wet – en regelgeving.
          </Paragraph>
          <Paragraph>
            De Koper en de Verkoper zijn ieder volledig verantwoordelijk voor het gebruik van het Platform en voor elk
            gebruik van de gegevens/informatie ontvangen via het Platform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'10.'} title={'Schade toebrengend gebruik van het Platform'} />
          <Paragraph>
            Gebruikers dienen zich in alle omstandigheden te onthouden van activiteiten die de beschikbaarheid en/of
            functionaliteiten van het Platform in gevaar kunnen brengen, de reputatie van het Platform kunnen schaden,
            de reputatie van de diensten die worden geleverd kunnen schaden en de reputatie en activiteiten van de
            Renault Groep kunnen schaden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'11.'} title={'Persoonsgebonden'} />
          <Paragraph>
            Het Platform, opgezet door de Renault Groep, draagt bij aan het imago, de reputatie en de bekendheid van de
            Renault Groep. De relatie tussen het Platform en zijn Gebruikers is daarom Persoonsgebonden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'12.'} title={'Uitschrijving, blokkering of uitsluiting van het Platform '} />
          <Text type={'text_bold'} decoration={'underline'}>
            Uitschrijven
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Iedere Koper kan op elk moment het Platform via diens R1 (leverende Renault dealer) op de hoogte stellen van
            zijn beslissing om niet langer gebruik te maken van zijn diensten.
          </Paragraph>
          <Paragraph>
            De Koper moet blijven voldoen aan de verplichtingen die hij is aangegaan met de Verkopers voorafgaand aan de
            datum waarop zijn deelname aan het Platform wordt beëindigd.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Blokkeren
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Het Platform kan de toegang van een Koper tot het Platform tijdelijk blokkeren, voor een periode van
            maximaal 6 maanden, in de volgende gevallen:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  ernstig vermoeden van gedrag dat het Platform, deze Gebruiksvoorwaarden, de toepasselijke wet- en
                  regelgeving of het imago en de reputatie van de Renault Groep ondermijnt;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>geen melding van wijzigingen in aangewezen items;</Text>
              </Li>
              <Li>
                <Text type={'text'}>gebruik van de Koperszone door andere personen dan de Koper;</Text>
              </Li>
              <Li>
                <Text type={'text'}>nalatigheid in de verplichting om inloggegevens vertrouwelijk te houden;</Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Gedurende deze periode zullen de Koper en het Platform zich te goeder trouw inspannen om het vastgestelde
            gedrag te verhelpen.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Uitsluiting
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Het Platform kan een Koper permanent uitsluiten van toegang tot het Platform in geval van:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  het verlaten van het Renault-netwerk dat is toegelaten tot het Platform, om welke reden dan ook;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  gedrag waarnaar wordt verwezen in dit artikel onder het kopje &apos;blokkeren&apos;. Het Platform zal
                  daarom de keuze hebben tussen blokkering en onmiddellijke uitsluiting;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  het geven van een onjuiste voorstelling van zaken, hoe ernstig ook, gezien dit een vertrouwensbreuk
                  tot gevolg heeft;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>herhaalde klachten van Verkopers; </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  elke verandering in de persoonlijke situatie van de Koper die het persoonsgebonden karakter kan
                  beïnvloeden (in het bijzonder: directe of indirecte verandering van zeggenschap, belangrijke
                  verandering in productie of certificeringen, insolventieprocedures in overeenstemming met de geldende
                  wetgeving).
                </Text>
              </Li>
            </Ol>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'13.'} title={'Handelstransacties - Bedrijfsgeheim'} />
          <Paragraph>
            Het Platform staat in geen geval toe dat derden (concurrenten, vennoten, partners, enz.) kennis hebben van
            de uitwisselingen, onderhandelingen, contracten en overeenkomsten die worden uitgevoerd of aangegaan via het
            Platform. Het Platform zal te allen tijde vertrouwelijkheid betrachten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'14.'} title={'Promoties en reclame'} />
          <Paragraph>
            De Koper verbindt zich ertoe het Platform niet te gebruiken om direct of indirect, in welke vorm dan ook,
            zijn producten of diensten of die van een andere persoon te promoten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'15.'} title={'Geen gebruiksplicht'} />
          <Paragraph>
            Lidmaatschap van het Platform schept geen verplichting voor Gebruikers om het Platform te gebruiken.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'16.'} title={'Wijziging van deze Gebruiksvoorwaarden'} />
          <Paragraph>
            Het Platform behoudt zich het recht voor om deze Gebruiksvoorwaarden van tijd tot tijd te wijzigen. Elke
            wijziging wordt van kracht op de datum vermeld in de kennisgeving die naar Gebruikers wordt gestuurd.
          </Paragraph>
          <Paragraph>
            De wijzigingen zijn niet van toepassing op transacties die op de datum waarop de wijzigingen van kracht
            worden, onderhanden zijn.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'17.'} title={'Gebruik van persoonlijke gegevens verzameld door het Platform'} />
          <Paragraph>
            Wanneer u het Platform gebruikt, worden uw persoonsgegevens verwerkt door Renault in overeenstemming met de
            Privacyverklaring die toegankelijk is via de volgende link:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'18.'} title={'Overmacht'} />
          <Paragraph>
            Het Platform kan niet aansprakelijk worden gehouden in geval van een storing van het Platform veroorzaakt
            door overmacht.
          </Paragraph>
          <Paragraph>
            Overmacht omvat in het bijzonder stroomstoringen, internetstoringen, en computerstoringen (&quot;bugs&quot;,
            virussen, malware, inbraak), zelfs als hun technische oorsprong ligt in het ontwerp van de informatiedienst.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'19.'} title={'Vertrouwelijkheid'} />
          <Paragraph>
            Het Platform implementeert passende technische middelen om de vertrouwelijkheid te waarborgen van informatie
            en gegevens die via het Platform worden verzonden of van onderhandelingen die via het Platform worden
            gevoerd, zodat deze niet worden doorgegeven aan personen buiten de diensten van het Platform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'20.'} title={'Intellectueel eigendom'} />
          <Paragraph>
            Alle elementen, zoals teksten, logo&apos;s, afbeeldingen, grafische of geluidselementen, software, lay-out,
            Platform databases, bedrijfsnamen, merken, tekeningen en modellen, zijn beschermd door intellectuele
            eigendomsrechten. Verkopers dienen zich te onthouden van handelingen die op enigerlei wijze inbreuk maken op
            deze rechten.
          </Paragraph>
          <Paragraph>
            Voor het gebruik van het Platform is de Koper gemachtigd om de handelsmerken en intellectuele
            eigendomsrechten waarover hij beschikt te gebruiken, evenals zijn handelsnaam of bedrijfsnaam.
          </Paragraph>
          <Paragraph>
            De Koper is gemachtigd om zijn status als Koper op het Platform te vermelden op zijn commerciële documenten,
            op voorwaarde dat hij de relevante documenten vooraf heeft ingediend bij het Platform.
          </Paragraph>
          <Paragraph>
            Gebruikers krijgen een beperkte, niet-exclusieve, niet-overdraagbare, niet-sublicentieerbare licentie om het
            Platform te gebruiken.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'21.'} title={'Aansprakelijkheid koper'} />
          <Paragraph>
            De Koper is als enige verantwoordelijk voor de bestelling van de Producten die te koop worden aangeboden op
            het Platform.
          </Paragraph>
          <Paragraph>
            De Koper is ook als enige verantwoordelijk voor het gebruik van Kopersruimte en de informatie die aan
            Verkopers wordt gegeven.
          </Paragraph>
          <Paragraph>
            Meer in het algemeen is de Koper alleen aansprakelijk als Koper en kan het Platform niet aansprakelijk
            worden gesteld in de hoedanigheid van Koper.
          </Paragraph>
          <Paragraph>
            In het geval dat een Verkoper, of een andere persoon, gerechtelijke stappen onderneemt tegen het Platform
            voor feiten die verband houden met de activiteiten van Koper, verbindt de Koper zich ertoe om het Platform
            te vrijwaren van elke veroordeling.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'22.'} title={'Toepasselijk recht - Geschillen'} />
          <Paragraph>
            Op deze Gebruiksvoorwaarden en het gebruik van het Platform is Nederlands recht van toepassing.
          </Paragraph>
          <Paragraph>
            De rechtbank van Amsterdam is exclusief bevoegd kennis te nemen van alle geschillen, hetzij contractueel,
            hetzij uit onrechtmatige daad, voortvloeiend uit of in verband met de geldigheid, interpretatie of
            uitvoering van deze Gebruiksvoorwaarden of, meer in het algemeen, de diensten aangeboden door het Platform
            of de relatie tussen de Verkopers en het Platform.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Laatste update: 23 oktober 2023</Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'}>BIJLAGE I - INFORMATIE VOOR KOPERS - TOEGANGSPROCEDURE</Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-NL.png'} alt={'information-diagram-netherlands'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
