import { Client, StompConfig, Versions } from '@stomp/stompjs';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { env } from 'env';
import { beforeConnect, onConnect, onDisconnect, onStompError, onWebSocketError } from './handlers/stompEventHandlers';

const bffUrl = env.REACT_APP_BFF_URL;
const brokerURL = bffUrl.replace(/^http/, 'ws').replace(/^https/, 'wss') + '/ws';

export const setupStompClient = (dispatch: Dispatch, getState: () => RootState): Client => {
  const heartbeatIncoming = 60000;
  const heartbeatOutgoing = 60000;
  const reconnectDelay = 2000;
  const stompConfig: StompConfig = {
    heartbeatIncoming,
    heartbeatOutgoing,
    brokerURL,
    reconnectDelay,
    stompVersions: new Versions(['1.2', '1.1', '1.0']),
    splitLargeFrames: true,
  };
  const client = new Client(stompConfig);

  client.beforeConnect = () => beforeConnect(client, dispatch, getState);
  client.onConnect = (frame) => onConnect(client, dispatch, getState, frame);
  client.onDisconnect = (frame) => onDisconnect(dispatch, frame);
  client.onStompError = (frame) => onStompError(dispatch, frame);
  client.onWebSocketError = (event) => onWebSocketError(dispatch, event);

  return client;
};
