import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

const cssActiveCategory = css`
  background-color: ${theme.color.white};
  border-top: solid 2px ${theme.color.blue};
`;

const cssActiveSubCategory = css`
  border-color: ${theme.color.blue};
`;

export const CategoryFlex = styled(Flex)<{ isActive: boolean }>`
  ${({ isActive }) => isActive && cssActiveCategory};
  :hover {
    background-color: ${theme.color.white};
    cursor: pointer;
  }
`;

export const SFlex = styled(Flex)`
  border-bottom: solid 1px ${theme.color.grey75};
  padding: 15px;
`;

export const SubCategory = styled.div<{ isActive: boolean }>`
  height: 33px;
  padding: 0 10px 0 10px;
  border-radius: 16px;
  border: solid 1px ${theme.color.brand_black};
  ${({ isActive }) => isActive && cssActiveSubCategory};
  :hover {
    cursor: pointer;
  }
`;
