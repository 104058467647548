import styled from 'styled-components';
import { defaultBoxShadow } from 'UI';

export const SFilterContainer = styled.div`
  display: flex;
  flex: 0;
  min-width: 300px;
  height: fit-content;
  padding: 23px 0 0.5px;
  margin-right: 15px;
  border-radius: 4px;
  ${defaultBoxShadow};
  background-color: var(--white);
`;

export const SListItem = styled.div`
  padding: 15px;
  border-top: 1px solid ${({ theme }) => theme.color.grey95};
`;

export const TechnicalDataContainer = styled.div`
  width: 100%;
`;
export const TechnicalDataHeader = styled.div`
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 15px;
`;
