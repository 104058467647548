import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Box } from 'UI';

const highlightBorderCSS = css`
  border: solid 3px ${theme.color.clear_blue};
`;

export const HighlightWrapper = styled(Box)<{ isSelected?: boolean }>`
  border-radius: 8px;
  ${({ isSelected }) => isSelected && highlightBorderCSS};
  position: relative;
  z-index: 50;
`;
