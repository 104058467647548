import { Dispatch } from 'redux';
import {
  ROUTER_CART,
  ROUTER_CATALOG_DH_L1,
  ROUTER_CATALOG_DH_L3,
  ROUTER_HOME,
  ROUTER_IN_PROGRESS_ORDERS,
} from 'app/AppRouter';
import { disablePopins, enablePopins } from 'domains/firstHelp/FirstHelp.store';
import { PageType, SEEN_HINTS_LOCAL, SEEN_POPINS_LOCAL, Streams } from 'domains/firstHelp/FirstHelp.types';

export const VIEWS_SPLIT_STREAM = 20;

export const getInitStreams = (): Streams => {
  const map = new Map();
  const homepageStream1 = [
    `${ROUTER_HOME}_ref_search_field`,
    `${ROUTER_HOME}_veh_search_field`,
    `${ROUTER_HOME}_basket`,
    `${ROUTER_HOME}_estimate`,
    `${ROUTER_HOME}_menu`,
  ];
  const homepageStream2 = [
    `${ROUTER_HOME}_disconnect`,
    `${ROUTER_HOME}_sub_header`,
    `${ROUTER_HOME}_universal_products`,
  ];
  const cartStream1 = [
    `${ROUTER_CART}_file_import`,
    `${ROUTER_CART}_add_reference`,
    `${ROUTER_CART}_add_comment`,
    `${ROUTER_CART}_link_to_veh`,
  ];
  const ordersStream1 = [
    `${ROUTER_IN_PROGRESS_ORDERS}_order_detail`,
    `${ROUTER_IN_PROGRESS_ORDERS}_print`,
    `${ROUTER_IN_PROGRESS_ORDERS}_filters`,
  ];
  const catalogL1Stream1 = [
    `${ROUTER_CATALOG_DH_L1}_keyword_search`,
    `${ROUTER_CATALOG_DH_L1}_catalog_menu`,
    `${ROUTER_CATALOG_DH_L1}_vehicle_details`,
    `${ROUTER_CATALOG_DH_L1}_exploded_views`,
    `${ROUTER_CATALOG_DH_L1}_breadcrumb`,
  ];
  const catalogL3Stream1 = [
    `${ROUTER_CATALOG_DH_L3}_plate_detail`,
    `${ROUTER_CATALOG_DH_L3}_quick_search`,
    //todo update price tabs in catalog
    // `${ROUTER_CATALOG_DH_L3}_prices`,
    `${ROUTER_CATALOG_DH_L3}_add_to_estimate`,
    `${ROUTER_CATALOG_DH_L3}_labor_times`,
    `${ROUTER_CATALOG_DH_L3}_add_to_cart`,
    `${ROUTER_CATALOG_DH_L3}_plate_index`,
  ];
  const closeStream = ['close'];
  map.set(ROUTER_HOME, [homepageStream1, homepageStream2]);
  map.set(ROUTER_CART, [cartStream1]);
  map.set(ROUTER_IN_PROGRESS_ORDERS, [ordersStream1]);
  map.set(ROUTER_CATALOG_DH_L1, [catalogL1Stream1]);
  map.set(ROUTER_CATALOG_DH_L3, [catalogL3Stream1]);
  map.set('close', [closeStream]);
  return map;
};

export const getViewsDataIndex = (views: number): number => {
  return Math.floor(views / VIEWS_SPLIT_STREAM);
};

export const disableEnablePopins = (dispatch: Dispatch, condition: boolean, popinIds: string[]): void => {
  if (condition) {
    dispatch(disablePopins(popinIds));
  } else {
    dispatch(enablePopins(popinIds));
  }
};

const mapsToString = (map: Map<string, Map<string, string[]>>): string => {
  const mapString: Map<string, string> = new Map();
  map.forEach((value, key) => {
    mapString.set(key, JSON.stringify(Array.from(value.entries())));
  });
  return JSON.stringify(Array.from(mapString.entries()));
};

const stringToMaps = (str: string | null): Map<string, Map<string, string[]>> => {
  if (!str) {
    return new Map();
  }
  const mapString = new Map<string, string>(JSON.parse(str));
  const map = new Map();
  mapString.forEach((value, key) => {
    map.set(key, new Map<string[], number>(JSON.parse(value)));
  });
  return map;
};

const setsToString = (map: Map<string, Set<string>>): string => {
  const mapString: Map<string, string> = new Map();
  map.forEach((value, key) => {
    mapString.set(key, JSON.stringify(Array.from(value)));
  });
  return JSON.stringify(Array.from(mapString.entries()));
};

export const stringToSets = (str: string | null): Map<string, Set<string>> => {
  if (!str) {
    return new Map();
  }
  const mapString = new Map<string, string>(JSON.parse(str));
  const map = new Map();
  mapString.forEach((value, key) => {
    map.set(key, new Set<string>(JSON.parse(value)));
  });
  return map;
};

export const setSeenHintsLocalStorage = (userId: string, page: PageType): void => {
  const seenHintsLocalStorage = localStorage.getItem(SEEN_HINTS_LOCAL);
  const usersHintsMap = stringToSets(seenHintsLocalStorage);
  const currentUserSet = usersHintsMap.get(userId) ?? new Set<string>();
  currentUserSet.add(page);
  usersHintsMap.set(userId, currentUserSet);
  localStorage.setItem(SEEN_HINTS_LOCAL, setsToString(usersHintsMap));
};

export const setSeenPopinsLocalStorage = (userId: string, streamId: string, seenPopins: string[]): void => {
  const seenStreamsLocalStorage = localStorage.getItem(SEEN_POPINS_LOCAL);
  const seenStreamsViewsMap = stringToMaps(seenStreamsLocalStorage);
  const currentUserSeenStreams = seenStreamsViewsMap.get(userId) ?? new Map();
  const currentStream = currentUserSeenStreams.get(streamId) ?? [];
  const filteredSeenPopins = seenPopins.filter((popinId) => !currentStream.includes(popinId));
  currentUserSeenStreams.set(streamId, [...currentStream, ...filteredSeenPopins]);
  seenStreamsViewsMap.set(userId, currentUserSeenStreams);
  localStorage.setItem(SEEN_POPINS_LOCAL, mapsToString(seenStreamsViewsMap));
};

export const getSeenHintsLocal = (userId: string): Set<string> => {
  const seenHintsLocalStorage = localStorage.getItem(SEEN_HINTS_LOCAL);
  const usersHintsMap = stringToSets(seenHintsLocalStorage);
  const currentUserSet = usersHintsMap.get(userId) ?? new Set<string>();
  return currentUserSet;
};

export const getSeenPopinsLocal = (userId: string): Map<string, string[]> | undefined => {
  const seenPopinsLocalStorage = localStorage.getItem(SEEN_POPINS_LOCAL);
  const seenPopinsViewsMap = stringToMaps(seenPopinsLocalStorage);
  return seenPopinsViewsMap.get(userId);
};
