import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { useFetchIAMLaborTimes, useFetchIAMRepairMethods } from 'domains/catalog/Catalog.requests';
import {
  getExplodedIAMTreeItem,
  getIAMLaborTimesWrapper,
  getIAMRepairMethodsWrapper,
  getSearchVehicleResult,
} from 'domains/catalog/Catalog.store';
import {
  ActiveTabParam,
  IAMCatalogSourceParam,
  PARAM_LABOR_TIME,
  PARAM_REPAIR_METHODS,
  PARAM_SPARE_PARTS,
  PreferredTabParam,
} from 'domains/catalog/Catalog.types';
import { isValidIamCatalogType } from 'domains/catalog/Catalog.utils';
import { getIamCatalogBrandsView, getUserRights, setIamCatalogBrandsView } from 'domains/user';
import { useSubcategorySectionLinkParams } from 'pages/CatalogPage/common/SubcategorySection/SubcategorySection.hook';
import { usePreferredTabHook } from 'pages/CatalogPage/common/SubcategorySection/SubcategorySectionHeader.hook';
import LaborTimeSection from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection';
import RepairMethodSection from 'pages/CatalogPage/IAM/SubcategorySection/RepairMethodsSection/RepairMethodSection';
import { CenteredSpin, Container, MarginBox } from 'UI';
import { getData, hasData, isLoading } from 'utils';
import SparePartsSection from './SparePartsSection';
import SubcategorySectionHeader from './SubcategorySectionHeader';
import { TabState } from './SubcategorySectionHeader/SubcategorySectionHeader';

const SubcategorySection = () => {
  const { query, carPartGroupId, categoryId, subcategoryId } = useParams<{
    query: string;
    carPartGroupId: string;
    categoryId: string;
    subcategoryId: string;
  }>();

  const history = useHistory();
  const dispatch = useDispatch();

  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const activeTab = params.get(ActiveTabParam);
  const urlStoreCatalogSourceRaw = params.get(IAMCatalogSourceParam);
  const storeCatalogSource = useSelector(getIamCatalogBrandsView);
  const [selectedIamCatalogBrandsView, setSelectedIamCatalogBrandsView] = useState(
    isValidIamCatalogType(urlStoreCatalogSourceRaw) ? urlStoreCatalogSourceRaw : storeCatalogSource,
  );

  const [tabState, setTabState] = useState<TabState>(
    activeTab ? (activeTab as TabState) ?? PARAM_SPARE_PARTS : PARAM_SPARE_PARTS,
  );
  const [disabledTabs, setDisabledTabs] = useState<TabState[]>([]);
  const preferredTab = params.get(PreferredTabParam) || 'spare_parts';
  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const laborTimesWrapper = useSelector((state: RootState) =>
    getIAMLaborTimesWrapper(state, { query, nodeId: subcategoryId }),
  );
  const repairMethodsWrapper = useSelector((state: RootState) =>
    getIAMRepairMethodsWrapper(state, { query, nodeId: subcategoryId }),
  );
  const userRights = getData(useSelector(getUserRights));
  const tree = vehicleResult?.explodedIAMTree;
  const vehicleKey = vehicleResult?.vehicleDetail?.vehicleKey;
  const versionCode = vehicleResult?.vehicleDetail?.iamVehicle?.versionCode;

  const subcategory = getExplodedIAMTreeItem(getData(tree?.data), [carPartGroupId, categoryId, subcategoryId]);

  useFetchIAMLaborTimes(vehicleKey, subcategoryId, versionCode, laborTimesWrapper, userRights);
  useFetchIAMRepairMethods(vehicleKey, subcategoryId, versionCode, repairMethodsWrapper, userRights);
  useSubcategorySectionLinkParams(activeTab, setTabState, params);

  useEffect(() => {
    if (!isValidIamCatalogType(urlStoreCatalogSourceRaw)) {
      params.set(IAMCatalogSourceParam, selectedIamCatalogBrandsView);
      history.replace(`${location.pathname}?${params}`);
    }
    if (storeCatalogSource !== selectedIamCatalogBrandsView) {
      dispatch(setIamCatalogBrandsView(selectedIamCatalogBrandsView));
    }
  });

  useEffect(() => {
    const catalogSource = isValidIamCatalogType(urlStoreCatalogSourceRaw) ? urlStoreCatalogSourceRaw : undefined;
    if (catalogSource && catalogSource !== selectedIamCatalogBrandsView) {
      setSelectedIamCatalogBrandsView(catalogSource);
      dispatch(setIamCatalogBrandsView(catalogSource));
    }
  }, [urlStoreCatalogSourceRaw, selectedIamCatalogBrandsView, dispatch]);

  useEffect(() => {
    const disabledStates: TabState[] = [];
    if (!hasData(laborTimesWrapper)) {
      disabledStates.push(PARAM_LABOR_TIME);
      if (tabState === PARAM_LABOR_TIME) {
        params.set(ActiveTabParam, PARAM_SPARE_PARTS);
        setTabState(PARAM_SPARE_PARTS);
        history.replace(`${location.pathname}?${params}`);
      }
    }
    if (!isLoading(repairMethodsWrapper) && !hasData(repairMethodsWrapper)) {
      disabledStates.push(PARAM_REPAIR_METHODS);
      if (tabState === PARAM_REPAIR_METHODS) {
        params.set(ActiveTabParam, PARAM_SPARE_PARTS);
        setTabState(PARAM_SPARE_PARTS);
        history.replace(`${location.pathname}?${params}`);
      }
    }
    setDisabledTabs(disabledStates);
  }, [laborTimesWrapper, history, params, tabState, repairMethodsWrapper]);

  const selectedTab = useMemo(() => {
    switch (tabState) {
      case PARAM_SPARE_PARTS:
        return (
          <SparePartsSection
            query={query}
            selectedIamCatalogView={selectedIamCatalogBrandsView}
            setSelectedIamCatalogView={setSelectedIamCatalogBrandsView}
            carPartGroupId={carPartGroupId}
            categoryId={categoryId}
            subcategoryId={subcategoryId}
            laborTimesWrapper={laborTimesWrapper}
          />
        );
      case PARAM_LABOR_TIME:
        return <LaborTimeSection query={query} nodeId={subcategoryId} laborTimesWrapper={laborTimesWrapper} />;
      case PARAM_REPAIR_METHODS:
        return <RepairMethodSection query={query} nodeId={subcategoryId} repairMethodWrapper={repairMethodsWrapper} />;
      default:
        return <>Not yet implemented</>;
    }
  }, [
    tabState,
    carPartGroupId,
    categoryId,
    query,
    subcategoryId,
    selectedIamCatalogBrandsView,
    laborTimesWrapper,
    repairMethodsWrapper,
  ]);

  const getParamState = (tab: TabState) => {
    const urlParams = new URLSearchParams();
    urlParams.set(ActiveTabParam, tab);
    urlParams.set(PreferredTabParam, tab);
    return urlParams;
  };

  usePreferredTabHook(tabState, setTabState, disabledTabs, getParamState);

  if (
    isLoading(laborTimesWrapper) ||
    (preferredTab === 'labor_time' && hasData(laborTimesWrapper) && tabState !== 'labor_time')
  ) {
    return <CenteredSpin />;
  }

  return (
    <Container size={'xxl'}>
      <div id={'subcategory_section_container'} />
      <SubcategorySectionHeader disabledTabs={disabledTabs} getParamState={getParamState} label={subcategory?.label} />
      <MarginBox mt={15} />
      {selectedTab}
    </Container>
  );
};

export default SubcategorySection;
