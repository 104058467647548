import { useEffect } from 'react';
import { UseReactToPrintFn } from 'react-to-print';
import styled from 'styled-components';
import { Flex } from 'UI';

export const PdfHiddenWrapper = styled.div`
  display: none;
`;

export const PdfWrapper = styled(Flex)`
  padding: 20px;
`;

export const usePrintShortcut = (reactToPrintFn?: UseReactToPrintFn) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'p') {
        event.preventDefault();
        reactToPrintFn && reactToPrintFn();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    /* eslint-disable-next-line */
  }, [reactToPrintFn]);
};
