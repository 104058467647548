import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { INFORMATION_GET_BANNERS_URL_REST } from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { InformationBanner as InformationBannerType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import { Layout } from 'antd';
import { ROUTER_HOME } from 'app/AppRouter';
import { LoginSectionLg, LoginSectionMd, LoginSectionSm } from 'components/Login/LoginSection';
import { Footer } from 'components/Page/Footer/Footer';
import { getCredentials } from 'domains/auth/Auth.store';
import {
  ARCA_LOGIN,
  CREDENTIALS_LOCAL_PARAM,
  IDP,
  ISSUER_LOCAL_PARAM,
  KEYCLOAK,
  LOGIN_METHOD_LOCAL_PARAM,
  OKTA,
  RNET_LOGIN,
} from 'domains/auth/Auth.types';
import { authenticateAndRedirect, getUserManager } from 'domains/auth/Auth.util';
import { getTokenUser, setIsReload } from 'domains/user';
import PortfolioSection from 'pages/LandingPage/PortfolioSection';
import Sections from 'pages/LandingPage/Sections';
import { Teaser } from 'pages/LandingPage/Teaser/Teaser';
import { hasData } from 'utils';
import { useView } from 'utils/hooks/useView';
import { Country } from 'utils/i18n/Country';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { sendRequest } from 'utils/restClient';

const LandingPage = (): JSX.Element => {
  const history = useHistory();
  const user = useSelector(getTokenUser);
  const dispatch = useDispatch();
  const browserLanguage = getBrowserLanguage();
  const country = Country.getCountryByLanguage(browserLanguage?.toString() ?? '') ?? Country.DEFAULT.key;
  const credentials = useSelector(getCredentials);

  useEffect(() => {
    dispatch(setIsReload(false));
    // eslint-disable-next-line
  }, []);

  const onLoginHandler = () => {
    if (user) {
      history.push(ROUTER_HOME);
    } else {
      sessionStorage.setItem(CREDENTIALS_LOCAL_PARAM, JSON.stringify(credentials));
      authenticateAndRedirect(dispatch, history);
    }
  };

  const onRenUserArcaLoginHandler = () => {
    sessionStorage.setItem(LOGIN_METHOD_LOCAL_PARAM, ARCA_LOGIN);
    sessionStorage.setItem(ISSUER_LOCAL_PARAM, IDP);
    onLoginHandler();
  };

  const onRenUserTokenLoginHandler = () => {
    sessionStorage.setItem(LOGIN_METHOD_LOCAL_PARAM, RNET_LOGIN);
    sessionStorage.setItem(ISSUER_LOCAL_PARAM, IDP);
    onLoginHandler();
  };

  const onKeycloakLoginHandler = () => {
    sessionStorage.setItem(ISSUER_LOCAL_PARAM, KEYCLOAK);
    onLoginHandler();
  };

  const onOctaLoginHandler = () => {
    sessionStorage.setItem(ISSUER_LOCAL_PARAM, OKTA);
    onLoginHandler();
  };

  const [scroll, setScroll] = useState(0);

  const onScroll = () => {
    setScroll(scrollY);
  };

  useEffect(() => {
    const removeUser = async () => await getUserManager().removeUser();
    removeUser().catch(console.error);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const [informationBanners, setInformationBanners] = useState<InformationBannerType[]>([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const data = await sendRequest<InformationBannerType[]>({
          endpoint: INFORMATION_GET_BANNERS_URL_REST,
          urlSearchParams: new URLSearchParams({
            language: browserLanguage?.toString() ?? '',
            country,
          }),
        });
        setInformationBanners(data);
      } catch (err) {
        setInformationBanners([]);
      }
    };
    fetchBanners();
  }, [country, browserLanguage]);

  const scrollToPortfolio = () => {
    document.getElementById('portfolio-section')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const [currentPosition, setCurrentPosition] = useState(0);

  const incrementAndSetCurrentPosition = useCallback(
    (banners: InformationBannerType[]) => {
      setCurrentPosition(currentPosition > banners.length - 2 ? 0 : currentPosition + 1);
    },
    [setCurrentPosition, currentPosition],
  );

  useEffect(() => {
    if (!informationBanners[currentPosition]) setCurrentPosition(0);
    // eslint-disable-next-line
  }, [informationBanners.length]);

  useEffect(() => {
    if (!hasData(informationBanners) || informationBanners.length === 0) {
      return () => null;
    }
    const interval = setInterval(() => {
      incrementAndSetCurrentPosition(informationBanners);
    }, 5000);
    return () => clearInterval(interval);
  }, [informationBanners, incrementAndSetCurrentPosition]);

  return (
    <Layout>
      <Layout.Content>
        {useView([LoginSectionSm, LoginSectionMd, LoginSectionLg], {
          informationBanner: informationBanners[currentPosition],
          onRenUserArcaLoginHandler,
          onRenUserTokenLoginHandler,
          onKeycloakLoginHandler,
          onOctaLoginHandler,
          scrollToPortfolio,
          scroll,
        })}
        <Teaser />
        <PortfolioSection />
        <Sections />
      </Layout.Content>
      <Footer />
    </Layout>
  );
};

export default LandingPage;
