import { TFunction } from 'i18next';

interface DropdownItem {
  value: string;
  title: string;
}

export const getReasons = (t: TFunction): DropdownItem[] => [
  { value: 'Y10', title: t('order.claim.reason.wrong_price', 'Wrong Price') },
  { value: 'Y01', title: t('order.claim.reason.additional_part_received', 'Additional Part Received') },
  { value: 'Y02', title: t('order.claim.reason.missing_part', 'Missing Part') },
  { value: 'Y03', title: t('order.claim.reason.defective_part', 'Defective Part') },
  { value: 'Y04', title: t('order.claim.reason.damaged_part', 'Damaged Part') },
  { value: 'Y07', title: t('order.claim.reason.missing_package', 'Missing Package') },
  { value: 'Y08', title: t('order.claim.reason.missing_line', 'Missing line') },
  { value: 'Y09', title: t('order.claim.reason.unused_part', 'Unused Part') },
];

export const getReasonOption = (t: TFunction): Map<string, DropdownItem[]> =>
  new Map([
    [
      'Y10',
      [
        { value: '0', title: t('order.claim.option.less_paid', 'Less paid') },
        { value: '1', title: t('order.claim.option.too_much_paid', 'Too much paid') },
      ],
    ],
    [
      'Y01',
      [
        { value: '0', title: t('order.claim.option.do_not_accept', 'Do not accept') },
        { value: '1', title: t('order.claim.option.accept', 'Accept') },
      ],
    ],
  ]);
