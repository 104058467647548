import styled from 'styled-components';
import { Flex } from 'UI';

export const InputWrapper = styled(Flex)`
  padding: 0 19px 0 0px;
  margin: 0 0 0 0;
`;

export const Wrapper2 = styled.div`
  align-items: center;
  flex: 0;
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.color.grey80};
  text-align: left;
  padding: 0 2px 0 2px;
  margin: 10px 20px 10px 20px;
`;

export const SelectDealer = styled.div`
  overflow: auto;
  padding: 8px 8px;
  height: 200px;
`;

export const LabelDealer = styled.div<{
  width?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? width : 200) + 'px'};
  padding: 12px 5px 12px 5px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.color.grey95};
  }
`;
