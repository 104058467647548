import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DashboardPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { Ban } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { fetchArchivePromotionRequestSaga } from 'domains/promotion/Promotion.store';
import { DialogHandle } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/PromotionDetailMenu';
import { Flex, MarginBox, Text } from 'UI';

interface StopPublicationDialogProps {
  promotion: DashboardPromotion;
}

const StopPublicationDialog = forwardRef<DialogHandle, StopPublicationDialogProps>((props, ref) => {
  const disclosure = useDisclosure();
  const { t } = useTranslation();
  const { onOpen } = disclosure;
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    handleDialogOpen() {
      onOpen();
    },
  }));

  return (
    <>
      <Dialog
        title={t('backoffice.stop_publication', 'Stop publication')}
        handleConfirm={() =>
          dispatch(
            fetchArchivePromotionRequestSaga({
              promotionType: props.promotion.promotionType,
              promotionId: props.promotion.promotionId,
            }),
          )
        }
        icon={Ban}
        status={'error'}
        disclosure={disclosure}
        content={
          <Flex direction={'column'} align={'center'}>
            <Text type={'lead'}>
              {t(
                'backoffice.promotion.stop_publication.description',
                'Following this action, your promotion will be unpublished and archived.',
              )}
            </Text>
            <Text type={'lead'}>{t('common.dialog.confirmationQuestion', 'Would you like to confirm?')}</Text>
            <MarginBox mt={30} />
          </Flex>
        }
      />
    </>
  );
});

StopPublicationDialog.displayName = 'StopPublicationDialog';
export default StopPublicationDialog;
