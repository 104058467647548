import { MyStoreTireRimSizeType } from '@1po/1po-bff-fe-spec/generated/my_store/tires/model/MyStoreTireDiscount';

export const FIFTEEN = 'FIFTEEN';
export const SIXTEEN = 'SIXTEEN';
export const SEVENTEEN = 'SEVENTEEN';
export const EIGHTEEN = 'EIGHTEEN';
export const NINETEEN = 'NINETEEN';
export const TWENTY = 'TWENTY';

export function getTireDiameter(tireDiameter: number | undefined): MyStoreTireRimSizeType | undefined {
  if (!tireDiameter || tireDiameter <= 0) {
    return undefined;
  }

  if (tireDiameter <= 15) {
    return FIFTEEN;
  } else if (tireDiameter === 16) {
    return SIXTEEN;
  } else if (tireDiameter === 17) {
    return SEVENTEEN;
  } else if (tireDiameter === 18) {
    return EIGHTEEN;
  } else if (tireDiameter === 19) {
    return NINETEEN;
  } else if (tireDiameter >= 20) {
    return TWENTY;
  }
  return undefined;
}
