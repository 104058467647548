import React from 'react';
import { Trans } from 'react-i18next';
import { TruckIcon } from 'assets/icons';
import { theme } from 'styles';
import { Flex, Icon, Text } from 'UI';

export const DeliveryInformationUnavailable = () => {
  return (
    <Flex align={'center'}>
      <Icon IconComponent={TruckIcon} size={16} mt={4} mr={5} color={theme.color.grey55} />
      <Text type={'light_12_black_45'}>
        <Trans i18nKey={'common.price.unavailable.user_country'}>{'Delivery information unavailable'}</Trans>
      </Text>
    </Flex>
  );
};
