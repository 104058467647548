import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleFilled } from '@ant-design/icons';
import { PRICE_WRAPPER_MAX_WIDTH_NARROW } from 'components/ReferencePriceSection/PriceErrorDisplay';
import color from 'styles/color.json';
import { Flex, Text } from 'UI';
import { SAlert } from './ReferencePriceSection.styled';

export const PriceUnavailable = ({ narrow }: { narrow?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Flex maxWidth={narrow ? PRICE_WRAPPER_MAX_WIDTH_NARROW : 230}>
      {narrow ? (
        <>
          <InfoCircleFilled style={{ color: color['warning'], marginRight: '5px' }} />
          <Text type={'light_12'} displayStyle={'warning'} cursor={'pointer'} decoration={'underline'}>
            {t('common.price.unavailable', 'Price currently unavailable')}
          </Text>
          <Text type={'section'}>{t('common.price.unavailable.description', 'Please try again later.')}</Text>
        </>
      ) : (
        <SAlert
          icon={<InfoCircleFilled />}
          type={'info'}
          message={t('common.price.unavailable', 'Price currently unavailable')}
          description={t('common.price.unavailable.description', 'Please try again later.')}
          showIcon
        />
      )}
    </Flex>
  );
};
