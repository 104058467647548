export class FilterArray {
    filterLogic;
    filters;
    constructor(filterLogic, filters) {
        this.filterLogic = filterLogic;
        this.filters = filters;
    }
    addFilter(filter) {
        this.filters.push(filter);
    }
    toJSON() {
        return {
            type: 'filterArray',
            filterLogic: this.filterLogic,
            filters: this.filters,
        };
    }
}
