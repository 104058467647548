import styled from 'styled-components';
import { Box } from 'UI';

export const SBox = styled(Box)<{ isSelected?: boolean }>`
  div {
    display: inline-block;
    z-index: 25;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;
