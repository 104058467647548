import React from 'react';
import { useTranslation } from 'react-i18next';
import { BestsellerIcon } from 'assets/icons';
import color from 'styles/color.json';
import { Flex, Icon, MarginBox, Text } from 'UI';

const BestSeller = ({ isBestseller }: { isBestseller: boolean }) => {
  const { t } = useTranslation();
  return (
    <>
      {isBestseller ? (
        <Flex>
          <Icon IconComponent={BestsellerIcon} width={85} height={18} color={color['brand']} />
          <MarginBox ml={-78} mt={-2}>
            <Text type={'light_12'}>{t('catalog.universal_products.bestseller', 'Best seller')}</Text>
          </MarginBox>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default BestSeller;
