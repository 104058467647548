import { CrossSellingRow } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/model/CrossSellingRow';
import { CrossSellingRowError } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/model/CrossSellingRowError';
import { APP_NAMESPACE } from 'app/App.constants';
import {
  API_CALL_ERROR,
  FILE,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  ModalStatusType,
  NO_FILE,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import {
  DELETING_FILE,
  FETCHING_FILE,
  FILE_DELETED,
  FILE_FETCHED,
  FILE_REPLACED,
  FILE_UPLOADED,
  REPLACING_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { SEARCH_STATUS } from 'utils';

export const CROSS_SELLING_NAMESPACE = `${APP_NAMESPACE}/CROSS_SELLING`;

export interface CrossSellingState {
  fileUploadStatus: CrossSellingFileUploadStatus;
  fileRows: CrossSellingRow[];
  fileInformation: CrossSellingFileInformation;
  fileInformationForRequest: CrossSellingFileInformation;
  searchStatus: SEARCH_STATUS;
}

export const FILE_PROGRESS_FINISHED = 100;

export const UPLOAD_FILE = `${CROSS_SELLING_NAMESPACE}/UPLOAD_FILE`;
export const REPLACE_FILE = `${CROSS_SELLING_NAMESPACE}/REPLACE_FILE`;
export const GET_FILE = `${CROSS_SELLING_NAMESPACE}/GET_FILE`;
export const DELETE_FILE = `${CROSS_SELLING_NAMESPACE}/DELETE_FILE`;

export interface CrossSellingFileUploadStatus {
  fileStatus: CrossSellingFileStatus;
  correctRows: CrossSellingRow[];
  errorRows: CrossSellingRowError[];
  modalStatus: ModalStatusType;
  processFileProgress: number;
}

export type CrossSellingFileStatus =
  | typeof FETCHING_FILE
  | typeof REPLACING_FILE
  | typeof DELETING_FILE
  | typeof NO_FILE
  | typeof FILE
  | typeof UPLOADING_FILE
  | typeof FILE_UPLOADED
  | typeof FILE_FORMAT_ERROR
  | typeof FILE_STRUCTURE_ERROR
  | typeof FILE_REPLACED
  | typeof FILE_FETCHED
  | typeof FILE_DELETED
  | typeof API_CALL_ERROR;

export interface CrossSellingFileInformation {
  fileName: string;
}

export const MAX_FILE_ROWS = 1600;
