import styled from 'styled-components';
import { Divider, Flex, Text } from 'UI';

export const LoginFormWrapper = styled.div`
  box-shadow: 0 20px 30px ${({ theme }) => theme.color.grey10};
  border: none;
  background: white;
  border-radius: 20px;
  padding: 0 60px;
`;

export const SDivider = styled(Divider)`
  margin: 0;
  border-top: solid 1px ${({ theme }) => theme.color.silver_2};
`;

export const SText = styled(Text)`
  text-align: left;
`;

export const SLoginButtonWrapper = styled(Flex)`
  overflow: hidden;
`;
