import React from 'react';
import { useTranslation } from 'react-i18next';
import TextEditor from 'components/TextEditor';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { Flex, Icon, Input, MarginBox, Text } from 'UI';
import { Country } from 'utils/i18n/Country';
import { Language } from 'utils/i18n/Language';

export const LanguageTextSection = ({
  information,
  setInformation,
}: {
  information: CreateInformationModel | CreateInformationTemplateModel;
  setInformation: (informationToCreate: CreateInformationModel | CreateInformationTemplateModel) => void;
}) => {
  const { t } = useTranslation();
  const languagesOptions = Language.listWebLanguages();

  if (information?.textSection.length === 0) {
    return null;
  }

  return (
    <Flex direction={'column'} maxWidth={520}>
      <Text type={'h2'}>{t('backoffice.information', 'Information')}</Text>
      <Text type={'section'} disableGutter>
        {t(
          'backoffice.information.create_information.information.description_1',
          'Publish communication directly on the website, with targeted options.',
        )}
      </Text>
      <Text type={'section'} disableGutter>
        {t(
          'backoffice.information.create_information.information.description_2',
          'Type the message you would like to display on Rpartstore website.',
        )}
      </Text>
      <MarginBox mt={15} />
      {information.textSection?.map((section) => {
        if (!section.language) {
          return null;
        }
        const language = Language.findByKey(languagesOptions, section.language);
        const country = Country.findByKey(language?.getCountryCode());
        return (
          <Flex direction={'column'} key={`backoffice-informations-text_sections-${section.language}`}>
            <Flex direction={'row'}>
              {country?.icon && <Icon IconComponent={country.icon} size={16} color={'black'} mt={7} mr={10} />}
              <Text type={'h5_bold'}>{language?.name}</Text>
            </Flex>
            <MarginBox mt={5} />
            <Input
              value={section?.title}
              onChange={(newTitle) => {
                const textSection = information?.textSection?.map((s) => {
                  return {
                    ...s,
                    title: s.language === section.language ? newTitle : s.title,
                  };
                });
                setInformation({ ...information, textSection });
              }}
              bordered
              ellipsis
              placeholder={t(
                'backoffice.information.create_information.information.type_title_here',
                'Type your title here',
              )}
            />
            <MarginBox mt={5} />
            <TextEditor
              width={520}
              value={section?.description}
              onChange={(newDescription) => {
                const textSection = information?.textSection?.map((s) => {
                  return {
                    ...s,
                    description: s.language === section.language ? newDescription : s.description,
                  };
                });
                setInformation({ ...information, textSection });
              }}
              placeholder={t('common.input.text.placeholder', 'Type your text here')}
            />
            <MarginBox mt={15} />
          </Flex>
        );
      })}
    </Flex>
  );
};
