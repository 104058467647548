/* eslint-disable max-len */
import { GetLaborTimeIDsByGenericPartResponse } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimeIDsByGenericPartResponse';
import { GetLaborTimesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimesResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { getVehicleContext } from 'domains/catalog/Catalog.store';
import { sendGetLaborTimesByGenericPart, sendLaborTimesRequest } from 'domains/laborTime/LaborTime.api';
import * as actions from 'domains/laborTime/LaborTime.store';
import {
  setGenericPartLaborTimesIds,
  setGenericPartLaborTimesSearchStatus,
  setLaborTimes,
  setLaborTimesSearchStatus,
} from 'domains/laborTime/LaborTime.store';
import { getUserContext } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { LOADING, sagaGuard, select } from 'utils';

export function* fetchLaborTimeIdsByGenericPartsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchLaborTimeIdsByGenericPartsRequestSaga>): SagaIterator {
  const { vehicleKey, genericPart } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  yield put(setGenericPartLaborTimesSearchStatus({ vehicleKey, genericPart, status: LOADING }));
  yield put(
    sendGetLaborTimesByGenericPart({
      userContext,
      vehicleContext,
      genericPart,
    }),
  );
}

export function* fetchLaborTimeIdsByGenericPartsResponseSaga(
  action: WsResponse<GetLaborTimeIDsByGenericPartResponse>,
): SagaIterator {
  const { vehicleKey, genericPart, laborTimeIds } = action.payload;
  yield put(
    setGenericPartLaborTimesIds({
      vehicleKey,
      genericPart,
      ids: laborTimeIds,
    }),
  );
}

export function* fetchDHLaborTimesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchDHLaborTimesRequestSaga>): SagaIterator {
  const { vehicleKey, laborTimeIds } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  yield put(setLaborTimesSearchStatus({ vehicleKey, ids: laborTimeIds, status: LOADING }));
  yield put(sendLaborTimesRequest({ vehicleContext, userContext, laborTimeIds }));
}

export function* fetchDHLaborTimesResponseSaga(action: WsResponse<GetLaborTimesResponse>): SagaIterator {
  const { vehicleKey, laborTimes, elements, operations } = action.payload;
  yield put(setLaborTimes({ vehicleKey, laborTimes, elements, operations }));
}

export function* LaborTimeSagas(): SagaIterator {
  yield takeEvery(
    actions.fetchLaborTimeIdsByGenericPartsRequestSaga.type,
    sagaGuard(fetchLaborTimeIdsByGenericPartsRequestSaga),
  );
  yield takeEvery(
    actions.fetchLaborTimeIdsByGenericPartsResponseSaga.type,
    sagaGuard(fetchLaborTimeIdsByGenericPartsResponseSaga),
  );
  yield takeEvery(actions.fetchDHLaborTimesRequestSaga.type, sagaGuard(fetchDHLaborTimesRequestSaga));
  yield takeEvery(actions.fetchDHLaborTimesResponseSaga.type, sagaGuard(fetchDHLaborTimesResponseSaga));
}
