/* eslint-disable max-len */
import {
  OrderDeliveryType,
  OrderMKTPDeliveryMode,
} from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import { APP_NAMESPACE } from 'app/App.constants';

// every entity required between order validation and creation of order
export interface OrderValidationState {
  defaultDeliveryAddress: OrderDeliveryType;
  mktpDeliveryMode: OrderMKTPDeliveryMode | undefined;
  defaultPaymentMethod?: string;
  vehicles: ValidationItemsVehicles[];
  externalBaskets: ValidationItemsExternal[];
  otherSection: ValidationItemsOthers;
}

export interface ValidationItemsOthers {
  instructions?: string;
  deliveryAddress?: OrderDeliveryType;
  paymentMethod?: string;
}

export interface ValidationItemsVehicles extends ValidationItemsOthers {
  vehicleKey: string | undefined;
}

export interface ValidationItemsExternal extends ValidationItemsOthers {
  externalBasketId: string | undefined;
}

export const ORDER_VALIDATION_NAMESPACE = `${APP_NAMESPACE}/ORDER_VALIDATION`;
export const SET_VALIDATION_INSTRUCTIONS = `${ORDER_VALIDATION_NAMESPACE}/SET_VALIDATION_INSTRUCTIONS`;
export const SET_VALIDATION_DELIVERY_ADDRESS = `${ORDER_VALIDATION_NAMESPACE}/SET_VALIDATION_DELIVERY_ADDRESS`;
export const SET_VALIDATION_PAYMENT_METHOD = `${ORDER_VALIDATION_NAMESPACE}/SET_VALIDATION_PAYMENT_METHOD`;
export const RESET_VALIDATION_DELIVERY_ADDRESS = `${ORDER_VALIDATION_NAMESPACE}/RESET_VALIDATION_DELIVERY_ADDRESS`;
export const RESET_VALIDATION_PAYMENT_METHOD = `${ORDER_VALIDATION_NAMESPACE}/RESET_VALIDATION_PAYMENT_METHOD`;
export const RESET_VALIDATION_INSTRUCTIONS = `${ORDER_VALIDATION_NAMESPACE}/RESET_VALIDATION_INSTRUCTIONS`;
export const SET_DEFAULT_VALIDATION_DELIVERY_ADDRESS = `${ORDER_VALIDATION_NAMESPACE}/SET_DEFAULT_VALIDATION_DELIVERY_ADDRESS`;
export const SET_DEFAULT_VALIDATION_PAYMENT_METHOD = `${ORDER_VALIDATION_NAMESPACE}/SET_DEFAULT_VALIDATION_PAYMENT_METHOD`;
