import {
  ADD_EXTERNAL_BASKET_TO_USER_BASKET_URL,
  SUBSCRIBE_EXTERNAL_BASKETS_URL,
} from '@1po/1po-bff-fe-spec/generated/external_basket/ExternalBasketURLs';
import { AttachExternalBasket } from '@1po/1po-bff-fe-spec/generated/external_basket/request/AttachExternalBasket';
import { GetExternalBasket } from '@1po/1po-bff-fe-spec/generated/external_basket/request/GetExternalBasket';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendActionNoTimeout } from 'utils/domainStore/api';

export function sendSubscribeExternalBasketRequest(request: GetExternalBasket): WebSocketAction {
  return wsSendActionNoTimeout(SUBSCRIBE_EXTERNAL_BASKETS_URL, request);
}

export function sendAttachExternalBasketToUserBasket(request: AttachExternalBasket): WebSocketAction {
  return wsSendActionNoTimeout(ADD_EXTERNAL_BASKET_TO_USER_BASKET_URL, request);
}
