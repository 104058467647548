import styled from 'styled-components';
import { Box } from 'UI';

export const SFilterItem = styled(Box)`
  height: 25px;
  text-align: left;
  margin-top: 15px;
  margin-right: 15px;
  padding: 5px 7px 5px 14px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.color.grey_very_light_2};
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: center;
`;
