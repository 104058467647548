/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ElectronicPartsRepairRowError } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRowError';
import { List } from 'antd';
import { TFunction } from 'i18next';
import {
  getElectronicPartsRepairCorrectRows,
  getElectronicPartsRepairErrorRows,
  getElectronicPartsRepairFileInformationForRequest,
  replaceFileElectricPartsRepairSaga,
  setElectronicPartsRepairFileIsModalOpen,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import { ErrorAlert, ErrorDetails } from 'pages/CartPage/CartStep/FileUpload/FileReferencesRowsErrors.styled';
import { BlackButton, Box, Flex, MarginBox, Text, WhiteButton } from 'UI';

type rowError = ElectronicPartsRepairRowError['errorCode'];

interface EListI {
  data: ElectronicPartsRepairRowError[];
}

interface EListSameRowI {
  data: number[];
}

function EList(props: EListI) {
  const { t } = useTranslation();
  return (
    <List
      dataSource={props.data}
      renderItem={(item) => (
        <List.Item>
          <Text type={'text_bold'}>{`${t('common.row', 'Row')} ${item.rowNumber}`}</Text>
          <Box width={5} />
          <Text type={'light_14_black_65'}> {translateErrorCode(item.errorCode, t)}</Text>
          <Flex />
        </List.Item>
      )}
      split={false}
    />
  );
}

function EListSameRow(props: EListSameRowI) {
  const { t } = useTranslation();
  const { data } = props;

  const first = data.slice(0, data.length - 1);
  const last = data.slice(data.length - 1);

  return (
    <List
      dataSource={['empty']}
      renderItem={() => (
        <List.Item>
          <Text type={'text_bold'}>
            {`${t('common.rows', 'Rows')}${first.map((r) => ' ' + r.toString())} ${t(
              'common.and',
              'and',
            )} ${last.map((r) => r.toString())}`}
          </Text>
          <Box width={5} />
          <Text type={'light_14_black_65'}>
            {t('electronic_parts_repair.file_upload.row.same_with_diff_prices', 'Same reference with different prices')}
          </Text>
          <Flex />
        </List.Item>
      )}
      split={false}
    />
  );
}

function translateErrorCode(code: rowError, t: TFunction): string | null {
  switch (code) {
    case 'NO_FAMILY_CODE':
      return t('electronic_parts_repair.file_upload.row.no_family_code', 'Row without family code');
    case 'NO_REFERENCE':
      return t('electronic_parts_repair.file_upload.row.no_reference', 'Row without reference');
    case 'NO_PRICE':
      return t('electronic_parts_repair.file_upload.row.no_price', 'Row without price');
    case 'PRICE_FORMAT':
      return t('electronic_parts_repair.file_upload.row.invalid_price_format', 'Price value is not numeric');
    case 'PRICE_ZERO':
      return t('electronic_parts_repair.file_upload.row.price_zero', 'Price value is 0');
    case 'REFERENCE_WITH_DIFF_PRICE':
      return 'empty';
  }
  return null;
}

export function FileRowsErrors() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ers = useSelector(getElectronicPartsRepairErrorRows);
  const correctRows = useSelector(getElectronicPartsRepairCorrectRows);
  const fileInformationRequest = useSelector(getElectronicPartsRepairFileInformationForRequest);

  const errorRows = ers.filter((r) => r.errorCode !== 'REFERENCE_WITH_DIFF_PRICE');
  const errorSameRows = ers.filter((r) => r.errorCode === 'REFERENCE_WITH_DIFF_PRICE').map((r) => r.rowNumber);

  function handleClose() {
    const payload = {
      correctRows,
      fileName: fileInformationRequest.fileName,
      fileSize: fileInformationRequest.fileSize,
    };
    dispatch(replaceFileElectricPartsRepairSaga(payload));
  }

  function handleTryAgain() {
    dispatch(setElectronicPartsRepairFileIsModalOpen(true));
  }

  return (
    <Flex direction={'column'} minWidth={300} align={'center'}>
      <MarginBox mt={64}>
        <Box width={900}>
          <ErrorAlert
            message={
              <Flex direction={'row'} justify={'space-between'}>
                <Text type={'light_14_black_65'}>
                  {t(
                    'common.file_upload.items_could_not_be_added',
                    'One or several items in your file need to be checked.',
                  )}
                </Text>
              </Flex>
            }
            type={'error'}
            showIcon
            closable
            onClose={() => handleClose()}
          />
          <ErrorDetails>
            <Box align={'left'}>
              <Text type={'text'} displayStyle={'error'}>
                {t('common.details', 'Details')}
              </Text>
              {errorRows.length > 0 && <EList data={errorRows} />}
              {errorSameRows.length > 0 && <EListSameRow data={errorSameRows} />}
            </Box>
            <Flex justify={'flex-end'}>
              <WhiteButton stretch={false} size={'middle'} onClick={() => handleClose()}>
                {t('common.ignore', 'Ignore')}
              </WhiteButton>
              <MarginBox ml={15} />
              <BlackButton stretch={false} size={'middle'} onClick={() => handleTryAgain()}>
                {t('common.try_again', 'Try again')}
              </BlackButton>
            </Flex>
          </ErrorDetails>
        </Box>
      </MarginBox>
    </Flex>
  );
}
