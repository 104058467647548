import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { useTheme } from 'styled-components';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { useFetchExternalOrders } from 'domains/order/Order.requests';
import { getExternalOrders } from 'domains/order/Order.store';
import { getDelegationSession, getUserCommercialLink } from 'domains/user';
import { BillingAddressCard } from 'pages/MyOrdersPage/OrderCards/BillingAddressCard';
import { ClientInformationCard } from 'pages/MyOrdersPage/OrderCards/ClientInformationCard';
import { ContactCard } from 'pages/MyOrdersPage/OrderCards/ContactCard';
import { InformationCard } from 'pages/MyOrdersPage/OrderCards/InformationCard';
import { MiddleCardTitle, OrderDetailCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { OrderReferencesSection } from 'pages/MyOrdersPage/OrderReferencesSection/OrderReferencesSection';
import { ArchiveButtonRound, Box, Flex, MarginBox, Pipeline, PrintButtonRound, Text } from 'UI';
import { notifyNotImplemented } from 'UI/Notification/notification';
import { hasData, textFormatter } from 'utils';
import { handlePrintDetail } from './externalPdfPrint';

const TotalCard = ({ order }: { order: ExternalOrderItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <OrderDetailCard>
      <Flex direction={'column'}>
        <MiddleCardTitle>
          <Text type={'h6'}>
            {t('my_orders.details.total', `Total: ({{items}} item)`, {
              items: order.orderedReferences.length,
              count: order.orderedReferences.length,
            })}
          </Text>
        </MiddleCardTitle>
        <Flex justify={'flex-end'}>
          <MarginBox my={30} mx={15}>
            <Box width={300}>
              <Flex>
                <Text type={'text'}>{t('my_orders.details.total_vat_excl', 'Total VAT.Excl:')}</Text>
                <Flex justify={'flex-end'}>
                  {textFormatter.formatCurrency(order.totalPriceTaxExcluded, order.currency)}
                </Flex>
              </Flex>
              {order.totalTax && (
                <>
                  <MarginBox mt={15} />
                  <Flex>
                    <Text type={'text'}>{t('my_orders.details.vat', 'VAT:')}</Text>
                    <Flex justify={'flex-end'}>{textFormatter.formatCurrency(order.totalTax, order.currency)}</Flex>
                  </Flex>
                  <MarginBox mt={15} />
                </>
              )}
              {order.totalPriceTaxIncluded && (
                <>
                  <Pipeline horizontal color={theme.color.grey85} size={'100%'} />
                  <MarginBox mt={15} />
                  <Flex>
                    <Text type={'h5_bold'}>{t('my_orders.details.total_vat_incl', 'Total VAT.Incl:')}</Text>
                    <Flex justify={'flex-end'}>
                      {textFormatter.formatCurrency(order.totalPriceTaxIncluded, order.currency)}
                    </Flex>
                  </Flex>
                </>
              )}
            </Box>
          </MarginBox>
        </Flex>
      </Flex>
    </OrderDetailCard>
  );
};

const ExternalOrderDetails = () => {
  const { t } = useTranslation();
  const garageInfo = useSelector(getUserGarageInfo);
  const connectedAs = useSelector(getDelegationSession);
  const commercialLink = useSelector(getUserCommercialLink);
  const activeSeller = (connectedAs ? connectedAs.commercialLink?.seller : commercialLink?.seller) ?? null;
  const externalOrders = useSelector(getExternalOrders);
  const { orderId } = useParams<{ orderId: string }>();
  const orderNumber = Number(orderId);
  const order = hasData(externalOrders) ? externalOrders.find((order) => order.orderId === orderId) : undefined;

  useFetchExternalOrders(externalOrders);

  if (!order || !hasData(garageInfo) || !hasData(activeSeller) || !activeSeller) {
    return <></>;
  }
  const address = getFirstValidAddress(garageInfo);

  return (
    <Flex direction={'column'}>
      <Text type={'h0'}>{t('order.external.details', 'External order details')}</Text>
      <MarginBox mt={60} />
      <Flex>
        <Text type={'h1_banner_light'}>
          {t('my_orders.details.order_number', `Order n° {{orderId}}`, { orderId: orderNumber })}
        </Text>
        <MarginBox mt={60} />
        <Flex justify={'flex-end'}>
          <ArchiveButtonRound onClick={notifyNotImplemented} />
          <MarginBox ml={30} />
          <PrintButtonRound onClick={() => handlePrintDetail(order, garageInfo, activeSeller)} />
          <MarginBox ml={15} />
        </Flex>
      </Flex>
      <Flex>
        <ClientInformationCard
          garage={garageInfo}
          orderStatus={order.presentableOrderStatus}
          orderDate={order.creationDate}
          orderMark={order.mark}
        />
      </Flex>
      <MarginBox mt={45} />
      <Text type={'h1_banner_light'}>{t('my_orders.details.references', `References`)}</Text>
      <MarginBox mt={15} />
      <OrderReferencesSection order={order} substituteInlineInfo={false} />
      <MarginBox mt={90} />
      <Flex>
        <TotalCard order={order} />
      </Flex>
      <MarginBox mt={30} />
      <Flex>
        <Flex direction={'column'} size={2}>
          <InformationCard
            orderedBy={garageInfo.name}
            clientNumber={activeSeller.id}
            distributor={activeSeller.name}
            billingAccount={activeSeller.primaryClientCode}
          />
        </Flex>
        <MarginBox ml={30} />
        <Flex size={3}>
          <Flex direction={'column'}>
            {garageInfo && <ContactCard garageName={garageInfo.name} address={address} />}
          </Flex>
        </Flex>
        <MarginBox ml={30} />
        <Flex size={3}>
          <Flex direction={'column'}>{address && <BillingAddressCard address={address} />}</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ExternalOrderDetails;
