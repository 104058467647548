import {
  DocumentationAlertMotrioRequest,
  SendDocumentationAlertMotrio,
} from '@1po/1po-bff-fe-spec/generated/email/request/SendDocumentationAlertMotrio';
import {
  Attachment as GarageAttachment,
  SendEmailFromGarage,
} from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailFromGarage';
import {
  Attachment as AssistanceAttachment,
  Request as AssistanceRequest,
  SendEmailToAssistance,
} from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { APP_NAMESPACE } from 'app/App.constants';
import { ERROR, LOADING, NO_DATA } from 'utils/dataStatus';

export const EMAIL_NAMESPACE = `${APP_NAMESPACE}/EMAIL`;

export const SEND_EMAIL_FROM_GARAGE_REQUEST = `${EMAIL_NAMESPACE}/SEND_EMAIL_FROM_GARAGE_REQUEST`;
export const SEND_EMAIL_TO_ASSISTANCE_REQUEST = `${EMAIL_NAMESPACE}/SEND_EMAIL_TO_ASSISTANCE_REQUEST`;
export const SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_REQUEST = `${EMAIL_NAMESPACE}/SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO_REQUEST`;

export interface EmailState {
  emailFromGarageData: SendEmailFromGarageLocal;
  emailToAssistance: SendEmailToAssistanceLocal;
  emailDocumentationAlertMotrio: SendDocumentationAlertMotrioLocal;
}

export interface SendEmailFromGarageLocal extends Omit<SendEmailFromGarage, 'attachments'> {
  attachments?: GarageAttachment[] | NO_DATA;
}

export const SENT = 'sent';
export const FILLING = 'filling';
export type EMAIL_DATA_STATUS = typeof FILLING | typeof SENT | typeof LOADING | typeof ERROR;

export interface SendEmailToAssistanceLocal extends Omit<SendEmailToAssistance, 'attachments' | 'request'> {
  attachments?: AssistanceAttachment[] | NO_DATA;
  request?: AssistanceRequest;
  isSent: EMAIL_DATA_STATUS;
}

export interface SendDocumentationAlertMotrioLocal
  extends Omit<
    SendDocumentationAlertMotrio,
    'request' | 'socialReason' | 'companyRegistrationNumber' | 'vehicleRegistrationNumber' | 'email'
  > {
  request: DocumentationAlertMotrioRequest | undefined;
  isSent: EMAIL_DATA_STATUS;
  socialReason: string | undefined;
  socialReasonR1: string | undefined;
  companyRegistrationNumber: string | undefined;
  companyRegistrationNumberR1: string | undefined;
  vehicleRegistrationNumber: string | undefined;
  vehicleVin: string | undefined;
  vehicleBrand: string | undefined;
  vehicleModel: string | undefined;
  vehicleVersion: string | undefined;
  vehicleEngineCode: string | undefined;
  vehicleCountryCode: string | undefined;
  referenceNumber: string | undefined;
  referenceBrand: string | undefined;
  referenceDescription: string | undefined;
  email: string | undefined;
  countryCode: string | undefined;
}
