import React from 'react';
import { enableMapSet } from 'immer';
import { createRoot } from 'react-dom/client';
import { App } from 'app';

enableMapSet();

const render = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(document.getElementById('root')!);
  root.render(<App />);
};

render();

// Webpack Hot Module Replacement API to HRM
// This hot reload improve the basic one reloading only the updated modules
if (module.hot) {
  module.hot.accept('./app/App', () => {
    render();
  });
}
export type { FetchTranslationsProps } from 'utils/i18n/Translations';
