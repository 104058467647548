import styled from 'styled-components';
import { ThemeColorKeyType } from 'styles';
import { Flex } from 'UI';

export const PageFooterWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.color.grey15};
  color: ${({ theme }) => theme.color.white};
  z-index: 900;
  padding: 15px;
`;

export const PageFooterVersionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  text-align: center;
`;

export const ManageCookiesButton = styled.span<{ $color: ThemeColorKeyType }>`
  #ot-sdk-btn.ot-sdk-show-settings,
  #ot-sdk-btn.optanon-show-settings {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    color: ${({ theme, $color }) => theme.color[$color]};
    font-family: 'NouvelR', serif;
    font-size: 14px;
    line-height: 22px;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
      color: ${({ theme, $color }) => theme.color[$color]};
    }
  }
`;
