import React from 'react';
import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';
import { theme } from 'styles';
import { BreakpointSelectable, useBreakpointSelector } from 'utils/hooks/useBreakpoint';

const PipeLineContainer = styled.div<{ horizontal?: boolean }>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  width: ${({ horizontal }) => (horizontal ? undefined : '1px')};
  height: ${({ horizontal }) => (horizontal ? '1px' : undefined)};
`;

const SContainer = styled.div<{ size: number | string; color: string; horizontal?: boolean }>`
  width: ${({ horizontal, size }) => (horizontal ? (typeof size === 'number' ? `${size}px` : size) : '1px')};
  height: ${({ horizontal, size }) => (horizontal ? '1px' : typeof size === 'number' ? `${size}px` : size)};
  background-color: ${({ color }) => color};
`;

interface PipelineProps {
  size: number | string;
  color?: string;
  offsetRatio?: number;
  horizontal?: BreakpointSelectable<boolean>;
}

// component Divider vs Pipeline
export const Pipeline = ({ size, color = theme.color.grey_light, offsetRatio, horizontal }: PipelineProps) => {
  const breakpointSelector = useBreakpointSelector();
  const parsedHorizontal = breakpointSelector(horizontal);
  return offsetRatio ? (
    <PipeLineContainer horizontal={parsedHorizontal}>
      <SContainer horizontal={parsedHorizontal} size={`${100 / (2 + offsetRatio)}%`} color={'transparent'} />
      <SContainer horizontal={parsedHorizontal} size={`${(100 * offsetRatio) / (2 + offsetRatio)}%`} color={color} />
      <SContainer horizontal={parsedHorizontal} size={`${100 / (2 + offsetRatio)}%`} color={'transparent'} />
    </PipeLineContainer>
  ) : (
    <SContainer horizontal={parsedHorizontal} size={size} color={color} />
  );
};

export const Divider = styled(AntDivider)<{ size?: number }>`
  width: ${({ size }) => (size ? `${size}px` : undefined)};
`;
