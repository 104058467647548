import styled from 'styled-components';
import Popover from 'components/Popover';
import { Input, Modal, WhiteButton } from 'UI';

export const CarInput = styled(Input)`
  border-radius: 50px;
  font-size: 12px;
`;

export const SelectButton = styled(WhiteButton)`
  border: 1px solid #d9d9d9;
  height: 32px;
  min-width: 330px;
  justify-content: space-between;
  display: flex;
  font-size: 14px;
  align-items: center;
  color: ${({ theme }) => theme.color.grey55};

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.color.grey55};
  }

  &:active {
    background-color: white;
    color: ${({ theme }) => theme.color.grey55};
  }
`;

export const SPopover = styled(Popover)`
  padding: 0;
  width: 330px;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  }

  .ant-collapse {
    border: none;
    border-top: 1px solid #d9d9d9;
    border-radius: 0;
  }

  .ant-collapse-header {
    background-color: white;
  }
`;

export const SModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    margin-right: -20px;
    margin-left: -20px;
  }
`;
