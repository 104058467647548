import { Badge } from 'antd';
import styled from 'styled-components';

export const SBadge = styled(Badge)`
  cursor: pointer;
`;

export const CardEdge = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 3px;
  border: solid 2px ${({ active, theme }) => (active ? theme.color.clear_blue : theme.color.white)};
  border-radius: 0;
  box-shadow: 0 3px 6px ${({ theme }) => theme.color.shadow_3}; ;
`;

export const SPlateCard = styled.div<{ active: boolean }>`
  text-align: center;
  border: solid 2px ${({ active, theme }) => (active ? theme.color.clear_blue : theme.color.white)};
  border-radius: 0;
  width: 124px;
  height: 90px;
  box-shadow: 0 3px 6px ${({ theme }) => theme.color.shadow_3};
  background: ${({ theme }) => theme.color.white};

  :hover {
    cursor: pointer;
    box-shadow: 0 4px 6px ${({ theme }) => theme.color.shadow_8};
  }
`;

export const SPlateErrorCard = styled(SPlateCard)`
  height: 100px;
`;
