import React from 'react';
import { Result as AntResult } from 'antd';
import styled from 'styled-components';
import { CheckCircleIcon } from 'assets/icons';
import { theme } from 'styles';
import { Icon } from 'UI/Icon';
import { Text } from 'UI/Text';

interface ResultBaseProps extends ResultProps {
  status: 'success' | 'error' | 'info' | 'warning';
  icon: React.ReactNode;
}

interface ResultProps {
  title: string;
  subTitle: string;
  extra?: React.ReactNode;
}

const SResult = styled(AntResult)`
  background-color: ${theme.color.success_light_120};
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

const Result = ({ status, title, subTitle, icon, extra }: ResultBaseProps) => {
  const titleText = <Text type={'h1_banner_light'}>{title}</Text>;
  const subTitleText = <Text type={'lead'}>{subTitle}</Text>;
  return <SResult status={status} title={titleText} subTitle={subTitleText} icon={icon} extra={extra} />;
};

export const SuccessResult = ({ title, subTitle, extra }: ResultProps) => (
  <Result
    status={'success'}
    title={title}
    subTitle={subTitle}
    extra={extra}
    icon={<Icon IconComponent={CheckCircleIcon} size={93} color={theme.color.grass_green} />}
  />
);
