import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { VehicleField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateVehicle';
import { Vehicle } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { TFunction } from 'i18next';
import { Moment } from 'moment';
import { updateVehicle } from 'domains/estimate/Estimate.store';
import { Flex, FloatDatePicker, FloatInput, LightCard, Lowercase, MarginBox, Text } from 'UI';
import { getMomentDate, getStringDateFromMoment } from 'utils/date';
import { isQueryBMM } from 'utils/validateQuery';

export interface VehicleSectionProps {
  vehicle: Vehicle;
}

function catalogSourceSwitch(vehicle: Vehicle, t: TFunction<'translation'>) {
  switch (vehicle.catalogSource) {
    case 'DATAHUB':
      return (
        <>
          <Text type={'h5_bold'}>{vehicle.dataHubVehicle?.name ?? ''}</Text>
          <Text type={'light_14_black_65'}>{vehicle.dataHubVehicle?.modelType ?? ''}</Text>
          <Text type={'light_14_black_65'} transform={'capitalize'}>
            <Lowercase>{vehicle.dataHubVehicle?.engine ?? ''}</Lowercase>
          </Text>
          <Text type={'light_14_black_65'} transform={'capitalize'}>
            <Lowercase>{vehicle.dataHubVehicle?.gearbox ?? ''}</Lowercase>
          </Text>
        </>
      );
    case 'IAM':
      return (
        <>
          <Text type={'h5_bold'}>
            {vehicle.vehicleBrand ?? ''} {vehicle.model ?? ''}
          </Text>
          <Text type={'light_14_black_65'}>{`${vehicle.iamVehicle?.bodyType ?? ''} ${
            vehicle.iamVehicle?.doors ? vehicle.iamVehicle.doors + t('catalog.vehicle.doors', ' Doors') : ''
          }`}</Text>
          <Text type={'light_14_black_65'}>{`${vehicle.iamVehicle?.engineCodes ?? ''} ${
            vehicle.iamVehicle?.energy ?? ''
          }`}</Text>
        </>
      );
  }
}

const VehicleSection = ({ vehicle }: VehicleSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getManufacturingDate = useCallback((manufacturingDate: string) => getMomentDate(manufacturingDate) ?? null, []);

  const handleUpdate = (field: VehicleField) => (newValue: string) => {
    dispatch(updateVehicle({ field, newValue }));
  };

  const handleDateUpdateUpdate = (field: VehicleField) => (newValue: Moment | null) => {
    dispatch(updateVehicle({ field, newValue: getStringDateFromMoment(newValue) ?? '' }));
  };

  return (
    <LightCard title={t('common.vehicle', 'Vehicle')}>
      <Flex direction={'column'}>
        <Flex direction={'row'}>
          <Flex direction={'column'}>
            <FloatInput
              initialValue={vehicle.vin}
              onChangeDebounced={handleUpdate('VIN')}
              placeholder={t('catalog.vehicle.vin', 'VIN')}
              ellipsis
              readOnly={!isQueryBMM(vehicle.vehicleKey)}
              bordered={isQueryBMM(vehicle.vehicleKey) === true}
            />
          </Flex>
          <MarginBox ml={30} />
          <Flex direction={'column'}>
            <FloatDatePicker
              value={getManufacturingDate(vehicle.manufacturingDate)}
              onChange={handleDateUpdateUpdate('MANUFACTURING_DATE')}
              placeholder={t('catalog.vehicle.manufacturing_date', 'Manufacturing date')}
              ellipsis
            />
          </Flex>
        </Flex>
        <MarginBox mt={15} />
        <Flex direction={'row'}>
          <Flex direction={'column'}>
            <FloatInput
              initialValue={vehicle.vrn}
              onChangeDebounced={handleUpdate('VRN')}
              placeholder={t('catalog.vehicle.vrn', 'VRN')}
              ellipsis
              bordered
            />
          </Flex>
          <MarginBox ml={30} />
          <Flex direction={'column'}>
            <FloatInput
              initialValue={vehicle.mileage}
              onChangeDebounced={handleUpdate('MILEAGE')}
              placeholder={t('catalog.vehicle.mileage', 'Mileage')}
              ellipsis
              bordered
            />
          </Flex>
        </Flex>
        <MarginBox mt={15} />
        <Flex direction={'row'}>
          <Flex direction={'column'}>{catalogSourceSwitch(vehicle, t)}</Flex>
          <MarginBox ml={30} />
          <Flex direction={'column'}>
            <FloatInput
              initialValue={vehicle.administrationNumber}
              onChangeDebounced={handleUpdate('ADMINISTRATION_NUMBER')}
              placeholder={t('catalog.vehicle.administration_number', 'Administration number')}
              ellipsis
              bordered
            />
            <MarginBox mt={15} />
            <FloatInput
              initialValue={vehicle.parkNumber}
              onChangeDebounced={handleUpdate('PARK_NUMBER')}
              placeholder={t('catalog.vehicle.park_number', 'Park number')}
              ellipsis
              bordered
            />
          </Flex>
        </Flex>
      </Flex>
    </LightCard>
  );
};

export default VehicleSection;
