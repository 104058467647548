import React from 'react';
import { Table } from 'antd';
import { ManageCookiesButton } from 'components/Page/Footer/Footer.styled';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Language } from 'utils/i18n/Language';

export const CookiesBE = () => {
  const REN_GROUP_PERSONAL_DATA_URL = 'https://www.renaultgroup.com/en/renault-group-and-your-personal-data';
  const REN_COOKIES_URL = 'https://www.renault.nl/cookies.html';
  return (
    <Container size={'md'} alignText={'justify'}>
      <MarginBox mt={15} />
      <Flex align={'center'} direction={'column'}>
        <Text type={'h5_bold'}>POLITIQUE EN MATIÈRE DE COOKIES</Text>
        {Language.frenchBelgium.name}
      </Flex>
      <MarginBox mt={30} />
      <Paragraph>
        RpartStore utilise des technologies telles que les cookies et autres technologies similaires pour vous fournir,
        en tant qu’utilisateur de la plateforme, une expérience fluide et personnalisée de haute qualité. Les
        informations contenues dans cette politique de cookies s’ajoutent aux informations sur la protection des données
        personnelles contenues dans notre déclaration de confidentialité, qui peut être trouvée à l’adresse suivante: [
        <Link to={REN_GROUP_PERSONAL_DATA_URL} external newTab>
          GROUP PRIVACY POLICY PERSONAL DATA
        </Link>
        ].
      </Paragraph>
      <Paragraph>
        Vous pouvez à tout moment reconsidérer vos choix concernant les cookies en utilisant notre outil de gestion des
        cookies:{' '}
        <ManageCookiesButton $color={'brand_alt'}>
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            Gérer mes choix
          </button>
        </ManageCookiesButton>
        .
      </Paragraph>
      <Section>
        <SectionHeader title={'QUI SOMMES-NOUS?'} />
        <Paragraph>
          Renault Belgique Luxembourg N.V. et Renault SAS sont les responsables de traitement indépendants de DENT pour
          toutes données personnelles collectées par les cookies et autres trackers lus ou placés sur votre appareil
          (Renault Belgique Luxembourg N.V. est ci-après dénommée &quot;nous&quot;, &quot;notre&quot;).
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'QUE SONT LES COOKIES?'} />
        <Paragraph>
          Un cookie est un petit fichier informatique, un tracker, qui est stocké et lu lors de la visite d’un site web,
          à l’aide d’une application mobile, en lisant un e-mail, en installant ou en utilisant un logiciel, quel que
          soit le type de dispositif de communication utilisé (ordinateur, smartphone, lecteur numérique, console de
          jeux vidéo connectée à Internet, etc.)
        </Paragraph>
        <Paragraph>
          Lors de l’accès à un site Web, les cookies peuvent envoyer des informations au navigateur de l’utilisateur et
          lui permettre de renvoyer des informations au site Web d’origine (par exemple, un identifiant de session ou
          une sélection de langue).
        </Paragraph>
        <Paragraph>
          Les cookies ne nous permettent généralement pas de vous identifier directement, mais uniquement de collecter
          des informations spécifiques sur votre appareil, votre navigateur, vos interactions avec le site Web ou
          l’application que vous utilisez et, dans certains cas, votre position géographique.
        </Paragraph>
        <Paragraph>
          Les technologies utilisées peuvent relier différents appareils que vous avez utilisés pour déterminer avec une
          probabilité suffisante qu’ils vous appartiennent.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'À QUOI SERVENT LES COOKIES SUR CE SITE?'} />
        <Paragraph>Ce site utilise différents types de cookies. Ces éléments sont expliqués ci-dessous.</Paragraph>
        <SectionHeader title={'Cookies techniques'} underline />
        <Paragraph>
          Ces cookies sont destinés à permettre ou faciliter la communication électronique (détection d’erreurs de
          connexion, identification des points de connexion, sécurité du site Web, etc.) ou à fournir des services de
          communications en ligne que vous demandez spécifiquement (préférences d’affichage de l’écran, stockage des
          informations saisies dans les formulaires, panier (le cas échéant), accès à votre compte utilisateur, etc.)
        </Paragraph>
        <Paragraph>Ces cookies sont nécessaires au bon fonctionnement de notre site. </Paragraph>
        <Paragraph>
          Vous ne pouvez pas vous opposer à l’utilisation de ces cookies et votre consentement n’est pas requis, car les
          services sur notre site dépendent de ces cookies.
        </Paragraph>
        <SectionHeader title={'Cookies analytiques'} underline />

        <Paragraph>
          Ces cookies sont destinés au trafic du site ANALysE, à l’expérience utilisateur d’ANALysE ou simplement pour
          collecter des signaux agrégés sur le flux de visiteurs sur le site (par ex. choix de langue, page précédente
          visitée, horodatage de la visite, choix des cookies).
        </Paragraph>
        <Paragraph>
          Ces cookies peuvent traiter des données relatives aux pages visitées, au temps que vous passez sur les pages
          visitées, au navigateur que vous utilisez pour visiter ces pages, à votre utilisation du chat ou du chatbot
          sur notre site Web, etc.{' '}
        </Paragraph>
        <Paragraph>
          Les données collectées par ces cookies peuvent également être utilisées pour créer des algorithmes et des
          profils afin d’analyser vos intérêts et préférences possibles pour nos produits et services. Nous pouvons
          combiner ces informations avec d’autres informations, telles que des informations de notre base de données
          clients (pour plus d’informations sur le profilage et comment vous pouvez vous opposer à un tel profilage,
          veuillez consulter notre politique de confidentialité: [
          <Link to={REN_GROUP_PERSONAL_DATA_URL} external newTab>
            POLITIQUE DE CONFIDENTIALITÉ DU GROUPE DONNÉES PERSONNELLES
          </Link>
          ].
        </Paragraph>
        <Paragraph>
          L’utilisation et le stockage de ces cookies sont généralement soumis à votre consentement.
        </Paragraph>
        <Paragraph>
          Dans certaines circonstances, certaines technologies peuvent uniquement collecter des signaux techniques sans
          vous identifier personnellement ou suivre votre historique de navigation, dans de nombreux cas sans votre
          consentement, Ils ne sont utilisés que pour produire des statistiques anonymes pour mesurer l’audience et
          n’ont aucun impact ou un impact mineur sur votre vie privée.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'QUELS COOKIES SONT UTILISÉS SUR CE SITE?'} />
        <Paragraph>
          Le tableau ci-joint contient la liste de tous les cookies stockés et/ou utilisés par ce site et leurs
          principales fonctionnalités. Dans la mesure du possible, ces cookies sont hébergés sur des serveurs situés
          dans l’Espace économique européen (EEE). Cependant, étant donné que certains de nos prestataires de services
          sont situés dans des pays hors EEE, vos données personnelles seront traitées dans ces pays, qui peuvent avoir
          des réglementations différentes en matière de données personnelles que celles de l’Union européenne. Dans ce
          cas (i) nous veillerons à ce que ce transfert soit effectué conformément aux réglementations applicables et
          (ii) nous prendrons des mesures de protection qui garantissent un niveau adéquat de protection de vos données
          (En particulier, en utilisant les clauses contractuelles standard de la Commission européenne).
        </Paragraph>
        <Paragraph>
          Notre site Web peut contenir des cookies tiers (agence de communication, services d’analyse, etc.) grâce
          auxquels ils peuvent collecter des informations de navigation relatives à notre site Web pendant la durée de
          vie des cookies. Le stockage et l’utilisation de ces cookies par des tiers sont soumis à la politique de
          confidentialité de ces tiers. Nous vous informons sur l’objectif des cookies tiers sur notre site et les
          moyens dont vous disposez pour faire des choix concernant ces cookies.
        </Paragraph>
        <Table
          pagination={false}
          rowKey={(record) => record.id}
          columns={[
            {
              title: 'Propriétaire/Partenaire cookie',
              dataIndex: 'firm',
              key: 'firm',
            },
            { title: 'Objectif du cookie', dataIndex: 'utility', key: 'utility' },
            {
              title: 'Politique en matière de cookies',
              dataIndex: 'terms',
              key: 'terms',
              render: (to: string) => (
                <Link to={to} external newTab>
                  {'Politique en matière de cookies'}
                </Link>
              ),
            },
          ]}
          dataSource={[
            {
              id: 0,
              firm: 'Cookie banner',
              utility: 'Analytics',
              terms: 'https://www.dynatrace.com/company/trust-center/privacy/',
            },
            {
              id: 1,
              firm: 'Dynatrace',
              utility: 'Analytics',
              terms: 'https://www.dynatrace.com/company/trust-center/cookie-policy/',
            },
            {
              id: 2,
              firm: 'Matomo',
              utility: 'Analytics',
              terms: 'https://matomo.org/privacy-policy/',
            },
            {
              id: 3,
              firm: 'Renault',
              utility: 'Technique',
              terms: REN_COOKIES_URL,
            },
          ]}
        />
      </Section>
      <Section>
        <SectionHeader title={'COMMENT VOTRE CONSENTEMENT EST-IL OBTENU?'} />
        <Paragraph>
          Lorsque vous visitez notre site pour la première fois, il vous sera demandé de consentir à l’utilisation des
          cookies (dans la mesure où le consentement est requis) ou de refuser de le faire ou de faire certains choix
          concernant l’utilisation des cookies, en affichant une bannière d’information spéciale.
        </Paragraph>
        <Paragraph>
          Vous pouvez à tout moment retirer votre consentement ou modifier vos choix (par exemple, en acceptant
          uniquement certains types de cookies) avec notre outil spécial de gestion des cookies :{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Gérer mes choix
            </button>
          </ManageCookiesButton>
          .
        </Paragraph>
        <Paragraph>
          Veuillez noter que, conformément à la réglementation, les cookies nécessaires au fonctionnement du site ne
          sont pas soumis à votre consentement et ne peuvent donc pas être désactivés dans notre outil de gestion des
          cookies. Vous pouvez empêcher leur utilisation en modifiant les paramètres de votre navigateur (suivez la
          section &quot;Comment refuser les cookies&quot; ci-dessous), mais la qualité de votre expérience sur le site
          peut être affectée.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'COMBIEN DE TEMPS VOTRE CONSENTEMENT EST-IL CONSERVÉ?'} />
        <Paragraph>
          Si vous acceptez ou rejetez les cookies en tout ou partie sur notre site, votre choix sera stocké et
          enregistré (et nous garderons une trace de vos préférences) pour une période allant jusqu’à 6 mois. Cela
          signifie que lorsque vous consultez notre site Web ultérieurement, pendant cette période, nous ne vous
          demanderons pas de prendre des mesures concernant les cookies, car nous avons enregistré vos préférences
          stockées.
        </Paragraph>
        <Paragraph>
          A l’issue de cette période, nous vous demanderons à nouveau la permission ou le refus d’utilisation des
          cookies.{' '}
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'COMMENT GÉRER LES COOKIES?'} />
        <SectionHeader title={'Comment refuser les cookies?'} underline />
        <Paragraph>
          Si vous ne souhaitez pas que les cookies soient stockés ou lus sur votre appareil, un cookie de rejet sera
          stocké sur votre équipement afin que nous puissions enregistrer que vous avez objecté à l’utilisation des
          cookies. Si vous supprimez ce refus de cookies, nous ne pourrons plus reconnaître que vous avez refusé
          l’utilisation et le stockage des cookies (ce qui signifie que vous devrez réinitialiser vos préférences lors
          d’une prochaine visite sur notre site Web).
        </Paragraph>
        <Paragraph>
          Vous avez plusieurs options pour gérer les cookies, y compris le blocage et / ou la suppression de ceux-ci.
        </Paragraph>
        <SectionHeader title={'Outil de gestion des cookies'} underline />
        <Paragraph>
          Un outil de cookies est proposé pour vous aider à gérer vos préférences en matière de cookies afin que vous
          puissiez gérer les cookies stockés et/ou utilisés sur ce site. Vous pouvez accéder au configurateur et
          modifier vos préférences à tout moment en cliquant sur ce lien ci-dessous, selon le type de cookie concerné.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'GÉRER LES COOKIES'} />
        <SectionHeader title={'Paramètres du navigateur'} underline />
        <Paragraph>Vous pouvez également gérer les cookies via votre navigateur.</Paragraph>
        <Paragraph>
          La plupart des navigateurs sont configurés pour accepter les cookies par défaut, mais si vous le souhaitez,
          vous pouvez choisir d’accepter tous les cookies, de rejeter systématiquement les cookies ou de choisir quels
          cookies vous acceptez en fonction de leur provenance. Vous pouvez également supprimer régulièrement les
          cookies de votre appareil à l’aide de votre navigateur.
        </Paragraph>
        <Paragraph>
          Cependant, n’oubliez pas d’utiliser tous les navigateurs sur vos différents appareils (tablettes, smartphones,
          ordinateurs, etc.).
        </Paragraph>
        <Paragraph>
          Chaque navigateur dispose d’un paramètre différent pour gérer les cookies et vos choix. Ceci est décrit dans
          le menu d’aide de votre navigateur, qui explique comment modifier vos préférences en matière de cookies. Par
          exemple:
        </Paragraph>
        <Paragraph align={'start'}>
          <Ul>
            <Li>
              Pour Internet Explorer™:{' '}
              <Link external newTab to={'https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'}>
                https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
              </Link>{' '}
              ;
            </Li>
            <Li>
              Pour Safari™:{' '}
              <Link external newTab to={'https://support.apple.com/fr-fr/safari'}>
                https://support.apple.com/fr-fr/safari
              </Link>{' '}
              ;
            </Li>
            <Li>
              Pour Chrome™:{' '}
              <Link external newTab to={'https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=fr&answer=95647'}>
                https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=fr&answer=95647
              </Link>{' '}
              ;
            </Li>
            <Li>
              Pour Firefox™:{' '}
              <Link
                external
                newTab
                to={'https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies'}
              >
                https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
              </Link>{' '}
              ;
            </Li>
            <Li>
              Pour Opera™:{' '}
              <Link external newTab to={'https://help.opera.com/Windows/10.20/fr/cookies.html'}>
                https://help.opera.com/Windows/10.20/fr/cookies.html
              </Link>
            </Li>
          </Ul>
        </Paragraph>
        <SectionHeader title={'Modules émetteurs pour la création d’objection '} underline />
        <Paragraph>
          Vous pouvez choisir de désactiver certains cookies tiers en allant directement sur la page de l’éditeur des
          cookies. De cette façon:
        </Paragraph>
        <Paragraph align={'start'}>
          <Ul>
            <Li>
              Pour désactiver les témoins de Google Analytics, allez à la page:{' '}
              <Link external newTab to={'https://tools.google.com/dlpage/gaoptout?hl=fr'}>
                https://tools.google.com/dlpage/gaoptout?hl=fr
              </Link>{' '}
              ;
            </Li>
            <Li>
              Pour désactiver les témoins intelliAd, allez à la page:{' '}
              <Link external newTab to={'https://login.intelliad.com/optout.php'}>
                https://login.intelliad.com/optout.php
              </Link>{' '}
              ;
            </Li>
            <Li>
              Accédez à la page pour désactiver les témoins Adobe:{' '}
              <Link external newTab to={'https://www.adobe.com/uk/privacy/opt-out.html'}>
                https://www.adobe.com/uk/privacy/opt-out.html
              </Link>{' '}
              ;
            </Li>
          </Ul>
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'COMMENT POUVEZ-VOUS EXERCER VOS AUTRES DROITS?'} />
        <Paragraph>
          Conformément à la législation applicable en matière de protection des données personnelles, vous disposez d’un
          droit d’accès, de rectification, d’opposition, de limitation et de suppression de vos données personnelles.
          Certaines informations traitées par le biais de cookies peuvent être qualifiées de données personnelles. Pour
          exercer vos autres droits, vous pouvez consulter nos informations sur la protection de vos données
          personnelles en cliquant sur le lien suivant [
          <Link to={REN_COOKIES_URL} external>
            {REN_COOKIES_URL}
          </Link>
          ].
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'CHANGEMENTS'} />
        <Paragraph>
          Nous pouvons modifier cette politique de temps à autre. Nous vous informerons et/ou demanderons votre
          consentement lorsque cela est nécessaire ou requis. Nous vous recommandons donc de consulter cette politique
          chaque fois que vous visitez notre site Web pour voir la dernière version.
        </Paragraph>
      </Section>
      <Flex justify={'flex-end'}>
        <Text type={'text'}>Dernière mise à jour: 08/11/2024 </Text>
      </Flex>
      <MarginBox mt={200} />
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex align={'center'} direction={'column'}>
            <Text type={'h5_bold'}>1. COOKIEBANNER</Text>
            {Language.dutchBelgium.name}
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Er moet een banner worden weergegeven wanneer een gebruiker voor het eerst op een Renault domeinnaam
            terechtkomt, ongeacht op welke pagina hij/zij terechtkomt. Deze banner moet regelmatig worden weergegeven op
            basis van aanbevelingen van lokale autoriteiten (bijv. voor Nederland elke 6 maanden). Deze banner moet de
            volgende tekst bevatten :
          </Paragraph>
          <Paragraph>
            <b>De bescherming van uw online gegevens is een prioriteit voor Renault.</b>
          </Paragraph>
          <Paragraph>
            Onze website en onze partners [
            <Link to={REN_COOKIES_URL} external newTab>
              BEKIJK HET COOKIEBELEID VAN RENAULT: HELDER EN DUIDELIJK
            </Link>
            ] willen cookies gebruiken om de bezoekers en de prestaties van de website te meten, om u gepersonaliseerde
            en/of op uw locatie gebaseerde reclame en inhoud te tonen en om u in staat te stellen via sociale netwerken
            met onze inhoud te communiceren.
          </Paragraph>
          <Paragraph>
            U kunt uw keuzes op elk gewenst moment heroverwegen door naar het gedeelte &quot;Cookies&quot; op onze
            website te gaan.
          </Paragraph>
          <Paragraph>
            Een volgend informatieniveau moet toegankelijk zijn via een knop &quot;<b>Lees ons cookiebeleid</b>&quot; of
            &quot;<b>Meer informatie</b>&quot; die de gebruiker doorverwijst naar een speciale pagina met informatie
            over cookies in overeenstemming met de AVG, de e-Privacyrichtlijn en andere toepasselijke
            transparantievereisten en -richtlijnen (&quot;<b>Cookiebeleid</b>&quot;) en hen de mogelijkheid biedt het
            platform voor toestemmingsbeheer te openen om hun keuzes te beheren - zie onderstaande sjabloon.
          </Paragraph>
        </Section>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>COOKIEBELEID</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          Via de RpartStore wordt gebruik gemaakt van technologieën zoals cookies en vergelijkbare technologieën om u,
          als gebruiker van het platform, een vloeiende en gepersonaliseerde ervaring van hoge kwaliteit te bieden. De
          informatie in dit cookiebeleid is een aanvulling op de informatie over de bescherming van persoonsgegevens dat
          te vinden is in onze privacyverklaring die te vinden is via de volgende link: [
          <Link to={REN_GROUP_PERSONAL_DATA_URL} external newTab>
            GROUP PRIVACYBELEID PERSOONSGEGEVENS.
          </Link>
          ]
        </Paragraph>
        <Paragraph>
          U kunt uw keuzes met betrekking tot cookies op elk gewenst moment heroverwegen met behulp van onze tool voor
          cookiebeheer:{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Beheer mijn keuzes
            </button>
          </ManageCookiesButton>
          .
        </Paragraph>
        <Section>
          <SectionHeader title={'WIE ZIJN WIJ?'} />
          <Paragraph>
            Renault België Luxemburg N.V. en Renault SAS zijn onafhankelijke verwerkingsverantwoordelijken voor
            eventuele persoonsgegevens die worden verzameld door de cookies en andere trackers die op uw apparaat worden
            gelezen of geplaatst (Renault België Luxemburg N.V. wordt hierna aangeduid als &quot;wij&quot;,
            &quot;ons&quot;, &quot;onze&quot;).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WAT ZIJN COOKIES?'} />
          <Paragraph>
            Een cookie is een klein computerbestand, een tracker, dat wordt opgeslagen en uitgelezen bij het bezoeken
            van een website, het gebruik van een mobiele app, het lezen van een e-mail, het installeren of gebruiken van
            software, ongeacht het type communicatiemiddel dat wordt gebruikt (computer, smartphone, digitale reader,
            videospelconsole aangesloten op het internet, enz.)
          </Paragraph>
          <Paragraph>
            Bij het raadplegen van een website kunnen cookies informatie naar de browser van de gebruiker sturen en de
            browser in staat stellen informatie terug te sturen naar de oorspronkelijke website (bijvoorbeeld een
            sessie-ID of taalkeuze).
          </Paragraph>
          <Paragraph>
            Cookies stellen ons meestal niet in staat om u rechtstreeks te identificeren, maar alleen om specifieke
            informatie te verzamelen over uw apparaat, browser, interacties met de website of app die u gebruikt en, in
            sommige gevallen, uw geografische positie.
          </Paragraph>
          <Paragraph>
            De gebruikte technologieën kunnen verschillende apparaten die u hebt gebruikt met elkaar in verband brengen
            om met voldoende waarschijnlijkheid vast te stellen dat deze apparaten bij u horen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WAARVOOR WORDEN COOKIES OP DEZE WEBSITE GEBRUIKT?'} />
          <Paragraph>
            Deze website maakt gebruik van verschillende soorten cookies. Deze worden hierna toegelicht.
          </Paragraph>
          <Section>
            <SectionHeader title={'Technische cookies'} underline />
            <Paragraph>
              Deze cookies zijn bedoeld om elektronische communicatie mogelijk te maken of te vergemakkelijken (opsporen
              van verbindingsfouten, identificatie van verbindingspunten, beveiliging van de website, enz.) of om online
              communicatiediensten aan te bieden waar u specifiek om vraagt (voorkeuren voor schermweergave, opslaan van
              informatie die is ingevoerd in de formulieren, winkelwagentje (indien van toepassing), toegang tot uw
              gebruikersaccount, enz.)
            </Paragraph>
            <Paragraph>Deze cookies zijn noodzakelijk voor een goede werking van onze website.</Paragraph>
            <Paragraph>
              U kunt geen bezwaar maken tegen het gebruik van deze cookies en uw toestemming is niet vereist, aangezien
              de diensten op onze website afhankelijk zijn van deze cookies.
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'Analytische cookies'} underline />
            <Paragraph>
              Deze cookies zijn bedoeld om websiteverkeer te analyseren, om de gebruikerservaring te analyseren of
              gewoon om geaggregeerde signalen te verzamelen over de stroom van bezoekers op de site (bijv. taalkeuze,
              vorige bezochte pagina, tijdstempel van het bezoek, keuze voor cookies).
            </Paragraph>
            <Paragraph>
              Deze cookies kunnen gegevens verwerken met betrekking tot de bezochte pagina&apos;s, de tijd die u
              doorbrengt op de bezochte pagina&apos;s, de browser die u gebruikt om deze pagina&apos;s te bezoeken, uw
              gebruik van de chat of chatbot op onze website, enz.
            </Paragraph>
            <Paragraph>
              De gegevens die door deze cookies worden verzameld, kunnen ook worden gebruikt om algoritmen en profielen
              op te stellen om uw mogelijke interesses en voorkeuren voor onze producten en diensten te analyseren. We
              kunnen deze gegevens combineren met andere informatie, zoals informatie uit onze klantendatabase (zie voor
              meer informatie over profilering en hoe u bezwaar kunt maken tegen dergelijke profilering ons privacy
              beleid: [
              <Link to={REN_GROUP_PERSONAL_DATA_URL} external newTab>
                GROUP PRIVACYBELEID PERSOONSGEGEVENS.
              </Link>
              ].
            </Paragraph>
            <Paragraph>
              Het gebruik en de opslag van deze cookies is over het algemeen afhankelijk van uw toestemming.{' '}
            </Paragraph>
            <Paragraph>
              Onder bepaalde omstandigheden kunnen sommige technologieën alleen technische signalen verzamelen zonder u
              persoonlijk te identificeren of uw browsegeschiedenis te volgen, in veel gevallen zonder dat daarvoor uw
              toestemming nodig is, omdat ze alleen worden gebruikt om anonieme statistieken te produceren voor
              publieksmeting en geen of geringe impact vormen op uw persoonlijke levenssfeer.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'WELKE COOKIES WORDEN OP DEZE WEBSITE GEBRUIKT?'} />
          <Paragraph>
            De bijgevoegde tabel bevat de lijst van alle cookies die door deze website worden opgeslagen en/of gebruikt
            en hun belangrijkste kenmerken. Waar mogelijk worden deze cookies gehost op servers in de Europese
            Economische Ruimte (EER). Aangezien sommige van onze serviceproviders zich echter in landen buiten de EER
            bevinden, worden uw persoonsgegevens verwerkt in deze landen, die mogelijk een andere regelgeving inzake
            persoonsgegevens hebben dan de Europese Unie. In een dergelijk geval (i) zien wij er nauwlettend op toe dat
            deze overdracht wordt uitgevoerd in overeenstemming met de toepasselijke regelgeving en (ii) treffen wij
            waarborgen die een adequaat niveau van bescherming van uw gegevens waarborgen (in het bijzonder door gebruik
            te maken van de modelcontractbepalingen van de Europese Commissie).
          </Paragraph>
          <Paragraph>
            Onze website kan cookies van derden bevatten (communicatiebureau, analysediensten, enz.) waardoor zij,
            gedurende de levensduur van de cookies, browse-informatie met betrekking tot onze website kunnen verzamelen.
            De opslag en het gebruik van deze cookies door derden is onderworpen aan het privacy beleid van deze derden.
            Wij informeren u over het doel van cookies van derden op onze website en over de middelen waarover u
            beschikt om keuzes te maken met betrekking tot deze cookies.
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Partner-eigenaar van de cookie',
                dataIndex: 'firm',
                key: 'firm',
              },
              { title: 'Doel cookie', dataIndex: 'utility', key: 'utility' },
              {
                title: 'Cookiebeleid ',
                dataIndex: 'terms',
                key: 'terms',
                render: (to: string) => (
                  <Link to={to} external newTab>
                    {'Cookiebeleid'}
                  </Link>
                ),
              },
            ]}
            dataSource={[
              {
                id: 0,
                firm: 'Inhoud vierkant',
                utility: 'Analytics',
                terms: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
              },
              {
                id: 1,
                firm: 'Dynatrace',
                utility: 'Analytics',
                terms: 'https://www.dynatrace.com/company/trust-center/cookie-policy/',
              },
              {
                id: 2,
                firm: 'Matomo',
                utility: 'Analytics',
                terms: 'https://matomo.org/privacy-policy/',
              },
              {
                id: 3,
                firm: 'Renault',
                utility: 'Technisch',
                terms: REN_COOKIES_URL,
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'HOE WORDT UW TOESTEMMING VERKREGEN?'} />
          <Paragraph>
            Wanneer u onze website voor het eerst bezoekt, wordt u gevraagd om in te stemmen met het gebruik van cookies
            (voor zover daarvoor toestemming nodig is) of om dit te weigeren of om bepaalde keuzes te maken over het
            gebruik van cookies, door een speciale informatiebanner weer te geven.
          </Paragraph>
          <Paragraph>
            U kunt uw toestemming op elk gewenst moment intrekken of uw keuzes wijzigen (bijvoorbeeld door alleen
            bepaalde soorten cookies te accepteren) met onze speciale tool voor cookiebeheer:{' '}
            <ManageCookiesButton $color={'brand_alt'}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                Beheer mijn keuzes
              </button>
            </ManageCookiesButton>
            .
          </Paragraph>
          <Paragraph>
            Houd er rekening mee dat, in overeenstemming met de regelgeving, cookies die noodzakelijk zijn voor het
            functioneren van de website niet onderhevig zijn aan uw toestemming en daarom niet kunnen worden
            uitgeschakeld in onze tool voor cookiebeheer. U kunt het gebruik ervan voorkomen door uw browserinstellingen
            te wijzigen (volg de paragraaf &quot;hoe cookies weigeren&quot; hieronder), maar de kwaliteit van uw
            ervaring op de website kan daaronder lijden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'HOE LANG WORDT UW TOESTEMMING BEWAARD?'} />
          <Paragraph>
            Wanneer u cookies op onze website geheel of gedeeltelijk accepteert of weigert, wordt uw keuze opgeslagen en
            bewaard (en houden wij uw voorkeuren bij) voor een periode van maximaal 6 maanden . Dit betekent dat wanneer
            u onze website later, tijdens deze periode, raadpleegt, wij u niet zullen vragen om actie te ondernemen met
            betrekking tot cookies, aangezien wij een registratie hebben van uw opgeslagen voorkeuren.
          </Paragraph>
          <Paragraph>
            Na afloop van deze periode vragen we je opnieuw om toestemming of om het gebruik van cookies te weigeren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'HOE KUN JIJ COOKIES BEHEREN?'} />
          <SectionHeader title={'Hoe weiger je cookies?'} underline />
          <Paragraph>
            Als u niet wilt dat er cookies op uw apparaat worden opgeslagen of gelezen, wordt er een weigeringscookie op
            uw apparatuur opgeslagen zodat we kunnen registreren dat u bezwaar hebt gemaakt tegen het gebruik van
            cookies. Als u deze cookie-weigering verwijdert, kunnen wij niet langer herkennen dat u het gebruik en de
            opslag van cookies hebt geweigerd (wat betekent dat u bij een volgend bezoek aan onze website uw voorkeuren
            opnieuw moet instellen).
          </Paragraph>
          <Paragraph>
            U hebt verschillende opties om cookies te beheren, waaronder het blokkeren en/of verwijderen ervan.
          </Paragraph>
          <SectionHeader title={'Hulpmiddel voor cookiebeheer'} underline />
          <Paragraph>
            Er wordt een cookietool aangeboden waarmee u uw cookievoorkeuren kunt beheren, zodat u de cookies kunt
            beheren die op deze website worden opgeslagen en/of gebruikt. U kunt op elk gewenst moment, door op deze
            link hieronder te klikken, toegang krijgen tot de configurator en uw voorkeuren wijzigen, afhankelijk van
            het type cookie in kwestie.
          </Paragraph>
        </Section>
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>DE COOKIES BEHEREN</Text>
        </Flex>
        <Section>
          <SectionHeader title={'Browserinstellingen'} underline />
          <Paragraph>U kunt cookies ook beheren via uw browser.</Paragraph>
          <Paragraph>
            De meeste browsers zijn standaard ingesteld om cookies te accepteren, maar als u wilt, kunt u ervoor kiezen
            om alle cookies te accepteren, cookies systematisch te weigeren of te kiezen welke cookies u accepteert
            afhankelijk van waar ze vandaan komen. U kunt ook regelmatig cookies van uw apparaat verwijderen met behulp
            van uw browser.
          </Paragraph>
          <Paragraph>
            Vergeet echter niet om alle browsers op je verschillende apparaten (tablets, smartphones, computers, enz.)
            in te stellen.
          </Paragraph>
          <Paragraph>
            Elke browser heeft een andere instelling voor het beheren van cookies en uw keuzes. Dit wordt beschreven in
            het helpmenu van uw browser, waarin staat hoe u uw cookievoorkeuren kunt wijzigen. Bijvoorbeeld:
            <Ul>
              <Li>
                <Text type={'text'}>
                  voor Internet Explorer™:{' '}
                  <Link to={'https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'} external newTab>
                    https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Safari™:{' '}
                  <Link to={'https://support.apple.com/fr-fr/safari'} external newTab>
                    https://support.apple.com/fr-fr/safari
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Chrome™:{' '}
                  <Link
                    to={'https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647'}
                    external
                    newTab
                  >
                    https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Firefox™:{' '}
                  <Link
                    to={'https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies'}
                    external
                    newTab
                  >
                    https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  voor Opera™:{' '}
                  <Link to={'https://help.opera.com/Windows/10.20/fr/cookies.html'} external newTab>
                    https://help.opera.com/Windows/10.20/fr/cookies.html
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <SectionHeader title={'Uitgeversmodules voor het maken van bezwaren'} underline />
          <Paragraph>
            U kunt ervoor kiezen om sommige cookies van derden uit te schakelen door rechtstreeks naar de pagina van de
            uitgever van de cookie te gaan. Op deze manier :
          </Paragraph>
          <Paragraph align={'start'}>
            <Ul>
              <Li>
                Om Google Analytics-cookies uit te schakelen, gaat u naar de pagina :{' '}
                <Link to={'https://tools.google.com/dlpage/gaoptout?hl=fr'} external newTab>
                  https://tools.google.com/dlpage/gaoptout?hl=fr
                </Link>
              </Li>
              <Li>
                Om intelliAd cookies uit te schakelen, ga naar de pagina :{' '}
                <Link to={'https://login.intelliad.com/optout.php'} external newTab>
                  https://login.intelliad.com/optout.php
                </Link>
              </Li>
              <Li>
                Ga naar de pagina om Adobe-cookies uit te schakelen :{' '}
                <Link to={'https://www.adobe.com/uk/privacy/opt-out.html'} external newTab>
                  https://www.adobe.com/uk/privacy/opt-out.html
                </Link>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'HOE KUNT U UW ANDERE RECHTEN UITOEFENEN?'} />
          <Paragraph>
            In overeenstemming met de toepasselijke wetgeving inzake de bescherming van persoonsgegevens hebt u het
            recht op toegang tot, rectificatie van, bezwaar tegen, beperking van en verwijdering van uw
            persoonsgegevens. Sommige informatie die wordt verwerkt via cookies, kan gekwalificeerd worden als
            persoonsgegevens. Om uw andere rechten uit te oefenen, kunt u onze informatie over de bescherming van uw
            persoonsgegevens raadplegen door op de volgende link te klikken [
            <Link to={REN_COOKIES_URL} external newTab>
              {REN_COOKIES_URL}
            </Link>
            ].
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'VERANDERINGEN'} />
          <Paragraph>
            We kunnen dit beleid van tijd tot tijd wijzigen. We zullen u informeren en/of om uw toestemming vragen
            wanneer dat nodig of vereist is. We raden u daarom aan dit beleid te raadplegen telkens wanneer u onze
            website bezoekt om de meest recente versie te bekijken.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Laatste update: 08/11/2024</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
