import styled, { css } from 'styled-components';
import { InputNumber } from 'UI';

const invalidCss = css`
  border: 1px solid ${({ theme }) => theme.color.red};
  background-color: ${({ theme }) => theme.color.very_light_pink};
`;

export const SValidatedInputNumber = styled(InputNumber)<{ isValid?: boolean }>`
  :focus {
    border: 1px solid ${({ theme }) => theme.color.brand_light};
  }

  ${({ isValid }) => !isValid && invalidCss};
`;
