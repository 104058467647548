import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import {
  OrderReferenceItem,
  OrderReferenceItemPrice,
  SubstituteReference,
  VehicleDetail,
} from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { BrandsSearchEnginIcon, CheckCircleIcon, CloneIcon, ReplyIcon } from 'assets/icons';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer } from 'components/DataContainer';
import { getDiscountTextStyle } from 'components/Discount';
import { DocumentationAlertButton } from 'components/DocumentationAlertButton';
import { MotrioWarrantyButton } from 'components/MotrioWarranty/MotrioWarrantyButton';
import { PriceErrorDisplay, PriceUnavailable } from 'components/ReferencePriceSection';
import { OrderStockDisplay } from 'components/StockInfo/StockDisplay';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { OrderItemLocal, OrderReferenceItemLocal } from 'domains/order/Order.types';
import { OrderReferenceStatus } from 'domains/order/OrderReferenceStatus';
import { DHReferenceLocal, getDHReference, getIAMReference, getPrice } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { isRefMKTP } from 'domains/references/References.utils';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { theme } from 'styles';
import { Box, Flex, Icon, Image, InfoCircleButton, MarginBox, Pipeline, Spin, Text, URL } from 'UI';
import { getData, textFormatter } from 'utils';
import { SubstituteFoot, SubstituteProducts, SubstituteTitle } from './OrderReferenceCard.styled';

export const OrderReferenceImage = ({
  referenceImageUrl,
  referenceBrand,
  vehicleDetail,
}: {
  referenceImageUrl: string | undefined;
  referenceBrand: ReferenceBrandType | undefined;
  vehicleDetail?: VehicleDetail;
}) => {
  if (referenceImageUrl || vehicleDetail?.catalogSource === 'IAM') {
    return (
      <Image
        alt={'reference-image'}
        height={100}
        width={100}
        src={referenceImageUrl}
        type={URL}
        catalogSource={vehicleDetail?.catalogSource}
        fallbackComponent={<Box width={100} height={100} background={theme.color.grey85} />}
      />
    );
  }
  return <BrandImage referenceBrand={referenceBrand} vehicleBrand={vehicleDetail?.vehicleBrand} size={100} />;
};

export const ReplacementReference = ({ reference }: { reference: OrderReferenceItem }) => {
  const { t } = useTranslation();

  return reference.substitutes || reference.deliveredSubstitutedQuantity ? (
    <MarginBox ml={10}>
      <Flex direction={'column'}>
        {reference.substitutes?.map((s) => (
          <Text key={s.referenceNumber} type={'link'}>
            {t(
              'order.order_detail.substituted_reference_count',
              '{{count}} out of {{total}} Substituted by Ref: {{ref}}',
              {
                count: s.deliveredQuantity,
                total: reference.quantity,
                ref: s.referenceNumber,
              },
            )}
          </Text>
        ))}
      </Flex>
    </MarginBox>
  ) : (
    <></>
  );
};

export const HeadlineSubstitute = ({ reference }: { reference: OrderReferenceItem }) => {
  const { t } = useTranslation();

  return (
    <>
      {reference.substitutes && reference.substitutes?.length > 0 && (
        <SubstituteTitle key={reference.referenceNumber}>
          <Icon IconComponent={BrandsSearchEnginIcon} color={theme.color.yellow_light} size={20} mr={15} />
          <Text key={reference.referenceNumber} type={'lead_text_white'}>
            {t(
              'order.order_detail.substituted_reference_count_detail',
              '{{count}} / {{total}} of these references have been substituted:',
              {
                count: reference.substitutes?.reduce((acc, next) => acc + (next?.deliveredQuantity ?? 0), 0) ?? 0,
                total: reference.quantity,
              },
            )}
          </Text>
        </SubstituteTitle>
      )}
    </>
  );
};

export const FootSubstitute = () => <SubstituteFoot />;

export const DeliveredQuantity = ({
  deliveredQuantity,
  deliveredSubstitutedQuantity,
}: {
  deliveredQuantity?: number;
  deliveredSubstitutedQuantity?: number;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {deliveredQuantity !== undefined && deliveredQuantity !== 0
        ? `(${t('order.reference_table.references.shipped', '{{count}} shipped', {
            count: deliveredQuantity - (deliveredSubstitutedQuantity ?? 0),
          })})`
        : ''}
    </>
  );
};

const renderPrice = (t: TFunction, price: OrderReferenceItemPrice) => (
  <Text
    type={'h5_bold'}
    decoration={price.discount ? 'line-through' : 'none'}
    dataCy={'order-price-vat-excl'}
    disableGutter
  >
    {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
      vat_exclude_price: textFormatter.formatCurrency(price.originalUnitPriceVatExcluded, price.currency),
    })}
  </Text>
);

const renderDiscount = (t: TFunction, price: OrderReferenceItemPrice) => {
  if (price?.discount === undefined) {
    return <></>;
  }

  const displayStyle = getDiscountTextStyle({
    referenceNumber: '',
    discount: price.discount,
    promotionType: price.promotionType,
    discountType: price.discountType,
  } as ReferenceDiscount);

  return (
    <>
      <Text type={'section'} displayStyle={displayStyle} disableGutter>
        {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
          vat_exclude_price: textFormatter.formatCurrency(price.unitPriceVatExcluded, price.currency),
        })}
      </Text>
      <Text type={'dark_12'} displayStyle={displayStyle}>
        {t('order.reference.price.discount', '{{discount}}% Off', { discount: price.discount })}
      </Text>
    </>
  );
};

export const getImageUrl = (catalogReference?: DHReferenceLocal): string | undefined => {
  const images = catalogReference?.imageKeys;
  return images && images.length > 0 ? images[0] : undefined;
};

export function renderSubstituteFooter(
  substituteInlineInfo: boolean | undefined,
  reference: OrderReferenceItemLocal | OrderReferenceItem,
) {
  return (
    substituteInlineInfo !== undefined &&
    !substituteInlineInfo &&
    reference.substitutes &&
    reference.substitutes.length > 0 && <FootSubstitute />
  );
}

const OrderReferenceCard = ({
  reference,
  order,
  substituteInlineInfo,
}: {
  reference: OrderReferenceItem;
  order: OrderItemLocal | ExternalOrderItem;
  substituteInlineInfo?: boolean;
}) => {
  const { t } = useTranslation();
  const singleLineStock = reference.warehouseDeliveryStatuses && reference.warehouseDeliveryStatuses.length === 1;
  const { price } = reference;
  const referenceNumber = reference.referenceNumber;
  useFetchSingleReference(referenceNumber, null, undefined);
  const catalogReference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber, vehicleKey: undefined }),
  );

  const IAMReference = useSelector((state: RootState) =>
    getIAMReference(state, { referenceNumber, vehicleKey: undefined }),
  );
  const isMKTP = isRefMKTP(getData(IAMReference));

  const renderStocks = () => {
    if (!('orderDate' in order)) {
      return <></>;
    }
    return (
      <OrderStockDisplay
        orderId={order.internalOrderId}
        referenceNumber={reference.referenceNumber}
        warehouseDeliveryStatuses={reference.warehouseDeliveryStatuses}
        narrow={false}
        quantity={Number(reference.quantity)}
        orderDate={order.orderDate}
        isMKTP={isMKTP}
      />
    );
  };

  return (
    <>
      {substituteInlineInfo !== undefined && !substituteInlineInfo && <HeadlineSubstitute reference={reference} />}
      <MarginBox mb={15}>
        <Flex>
          <Box width={100} height={100}>
            <OrderReferenceImage
              referenceImageUrl={getImageUrl(getData(catalogReference))}
              referenceBrand={reference.brand}
              vehicleDetail={'vehicleDetail' in order ? order.vehicleDetail : undefined}
            />
          </Box>
          <MarginBox ml={15} />
          <Flex direction={'column'}>
            <Flex direction={'row'} align={'center'}>
              <Text type={'text_bold'} dataCy={'order-reference'}>
                {t('catalog.reference.referenceNumber.short', 'Ref: {{referenceNumber}}', {
                  referenceNumber: reference.referenceNumber,
                })}
              </Text>
              <MarginBox mr={10} />
              <CopyToClipboardButton
                textToCopy={reference.referenceNumber}
                message={t(
                  'catalog.reference_card.reference_number.copied_to_clipboard',
                  'Reference number {{referenceNumber}} copied to clipboard',
                  { referenceNumber: reference.referenceNumber },
                )}
              />
              {substituteInlineInfo && <ReplacementReference reference={reference} />}
              {reference.referenceMark && (
                <>
                  <MarginBox mr={15} />
                  <MarginBox mt={-5}>
                    <InfoCircleButton color={'grey30'} tooltip={reference.referenceMark} />
                  </MarginBox>
                </>
              )}
              {reference.brand === 'MOTRIO' && (
                <>
                  <MarginBox mr={5} />
                  <DocumentationAlertButton
                    reference={reference}
                    vehicleDetail={'vehicleDetail' in order ? order.vehicleDetail : undefined}
                    vehicleRegistrationNumber={
                      'vehicleDetail' in order ? order.vehicleDetail?.vrn ?? order.vehicleDetail?.vehicleKey : undefined
                    }
                    inverted
                    size={12}
                    boxSize={'xsm'}
                  />
                </>
              )}
            </Flex>
            <Text type={'light_14_black_65'} dataCy={'designation-reference'}>
              {reference.name}
            </Text>
            <Flex maxHeight={22}>
              <Text type={'text_bold'}>{t('common.stock', 'Stock: ')}</Text>
              <MarginBox ml={5} />
              {singleLineStock && renderStocks()}
            </Flex>
            {!singleLineStock && <MarginBox ml={15}>{renderStocks()}</MarginBox>}
            <Flex>
              <Text type={'text_bold'}>{t('common.quantity.short', 'Qty: ')}</Text>
              <MarginBox ml={5} />
              <Text type={'light_14_black_65'}>
                {`${reference.quantity} `}
                <DeliveredQuantity
                  deliveredQuantity={reference.deliveredQuantity}
                  deliveredSubstitutedQuantity={reference.deliveredSubstitutedQuantity}
                />
              </Text>
              {reference.returnedQuantity !== undefined && reference.returnedQuantity !== 0 && (
                <MarginBox ml={5}>
                  <Icon IconComponent={ReplyIcon} size={10} color={theme.color.success} mr={3} />
                  <Text type={'light_14_black_65'}>
                    {t('order.reference_table.references.return_status', 'Returned articles: {{ returnCount }}', {
                      returnCount: reference.returnedQuantity,
                    })}
                  </Text>
                </MarginBox>
              )}
            </Flex>
          </Flex>
          {reference.price.clientUnitPriceVatIncluded && (
            <Flex>
              <Text type={'text_bold'}>{t('order.client_price', 'Client Price: ')}</Text>
              <MarginBox mr={15} />
              <Text type={'light_14_black_65'}>
                {t('order.client_price_VAT_included', '{{ price }} VAT. Incl', {
                  price: textFormatter.formatCurrency(
                    reference.price.clientUnitPriceVatIncluded,
                    reference.price.currency,
                  ),
                })}
              </Text>
            </Flex>
          )}
          <Flex maxWidth={200} direction={'column'} align={'flex-end'}>
            {renderPrice(t, price)}
            {renderDiscount(t, price)}
            <OrderReferenceStatus referenceStatus={reference.status} orderStatus={order.orderStatus} />
            {reference.isUrgent && (
              <Flex align={'flex-end'}>
                <Flex>
                  <Icon
                    IconComponent={CheckCircleIcon}
                    color={theme.color.brand_black}
                    height={11}
                    width={11}
                    mt={1}
                    mr={5}
                  />
                  <Text type={'light_14_black_65'} displayStyle={'link'}>
                    {t('order.urgent', 'Urgent')}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Flex />
            {reference.brand === 'MOTRIO' && (
              <MotrioWarrantyButton
                reference={reference}
                vehicleDetail={'vehicleDetail' in order ? order.vehicleDetail : undefined}
                disabled={reference.status !== 'DELIVERED' && reference.status !== 'PARTIALLY_DELIVERED'}
              />
            )}
          </Flex>
          <Box width={60} />
        </Flex>
        <MarginBox mt={15} />
        <Pipeline size={'100%'} horizontal color={theme.color.grey85} />
      </MarginBox>
      {substituteInlineInfo !== undefined && !substituteInlineInfo && (
        <>
          {reference.substitutes && reference.substitutes.length > 0 && (
            <SubstituteProducts>
              <Icon IconComponent={CloneIcon} color={theme.color.brand_black} size={20} mr={15} />
              <Text type={'h5_bold'}>{t('order.substitute_products', 'Substitute products')}</Text>
            </SubstituteProducts>
          )}
          {reference.substitutes?.map((s) => (
            <OrderReferenceSubstituteCard
              key={s.referenceNumber}
              substituteReference={s}
              isUrgent={reference.isUrgent ?? false}
              currency={reference.price.currency}
              quantity={s.deliveredQuantity}
              deliveredQuantity={s.deliveredQuantity}
              vehicleDetail={'vehicleDetail' in order ? order.vehicleDetail : undefined}
            />
          ))}
        </>
      )}
      {renderSubstituteFooter(substituteInlineInfo, reference)}
    </>
  );
};

const OrderReferenceSubstituteCard = ({
  substituteReference,
  vehicleDetail,
  isUrgent,
  currency,
  quantity,
  deliveredQuantity,
}: {
  substituteReference: SubstituteReference;
  vehicleDetail: VehicleDetail | undefined;
  isUrgent: boolean;
  currency: string;
  quantity: number;
  deliveredQuantity: number;
}) => {
  const { t } = useTranslation();
  const substituteReferenceNumber = substituteReference.referenceNumber;

  const substituteRef = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: substituteReferenceNumber, vehicleKey: undefined }),
  );
  const substituteRefData = getData(substituteRef);
  const substitutePrice = useSelector((state: RootState) => getPrice(state, substituteReferenceNumber));
  const substitutePriceData = getData(substitutePrice);

  useFetchPrices(substituteReference.referenceNumber ? [substituteReference.referenceNumber] : []);
  useFetchSingleReference(substituteReference.referenceNumber, null, undefined);

  return (
    <MarginBox mx={0} mb={15}>
      <Flex>
        <Box width={100} height={100}>
          <OrderReferenceImage
            referenceImageUrl={getImageUrl(substituteRefData)}
            referenceBrand={substituteRefData?.brand}
            vehicleDetail={vehicleDetail}
          />
        </Box>
        <MarginBox ml={15} />
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <Text type={'text_bold'}>
              {t('catalog.reference.referenceNumber.short', 'Ref: {{referenceNumber}}', {
                referenceNumber: substituteReference.referenceNumber,
              })}
            </Text>
            <MarginBox mr={10} />
            <CopyToClipboardButton
              textToCopy={substituteReference.referenceNumber}
              message={t(
                'catalog.reference_card.reference_number.copied_to_clipboard',
                'Reference number {{referenceNumber}} copied to clipboard',
                { referenceNumber: substituteReference.referenceNumber },
              )}
            />
          </Flex>
          <DataContainer
            data={substituteRef}
            NotFound={() => <></>}
            ErrorState={() => <></>}
            Loading={() => <Spin size={'small'} />}
          >
            <Text type={'light_14_black_65'}>{substituteRefData?.name}</Text>
          </DataContainer>
          <Flex>
            <Text type={'text_bold'}>{t('common.quantity.short', 'Qty: ')}</Text>
            <MarginBox ml={5} />
            <Text type={'light_14_black_65'}>
              {`${quantity} `}
              <DeliveredQuantity deliveredQuantity={deliveredQuantity} deliveredSubstitutedQuantity={undefined} />
            </Text>
            {substituteReference.returnedQuantity !== undefined && substituteReference.returnedQuantity !== 0 && (
              <MarginBox ml={5}>
                <Icon IconComponent={ReplyIcon} size={10} color={theme.color.success} mr={3} />
                <Text type={'light_14_black_65'}>
                  {t('order.reference_table.references.return_status', 'Returned articles: {{ returnCount }}', {
                    returnCount: substituteReference.returnedQuantity,
                  })}
                </Text>
              </MarginBox>
            )}
          </Flex>
        </Flex>
        <Flex maxWidth={200} direction={'column'} align={'flex-end'}>
          <DataContainer
            data={substitutePrice}
            NotFound={() => <PriceUnavailable narrow={false} />}
            ErrorState={() => <PriceErrorDisplay narrow={false} />}
            Loading={() => <Spin size={'small'} />}
          >
            <Text type={'h5_bold'}>
              {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
                vat_exclude_price: textFormatter.formatCurrency(
                  substitutePriceData?.clientView?.recommendedPriceVatExcluded ?? 0,
                  currency,
                ),
              })}
            </Text>
          </DataContainer>
          {isUrgent && (
            <Flex align={'flex-end'}>
              <Flex>
                <Icon
                  IconComponent={CheckCircleIcon}
                  color={theme.color.brand_black}
                  height={11}
                  width={11}
                  mt={1}
                  mr={5}
                />
                <Text type={'light_14_black_65'} displayStyle={'link'}>
                  {t('order.urgent', 'Urgent')}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Box width={60} />
      </Flex>
      <MarginBox mt={15} />
      <Pipeline size={'100%'} horizontal color={theme.color.grey85} />
    </MarginBox>
  );
};

export default OrderReferenceCard;
