import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConnectIcon, UserCircleIcon } from 'assets/icons';
import ProfileCard from 'components/Page/Header/TopHeader/Profile/ProfileCard';
import { SPopover } from 'components/Page/Header/TopHeader/TopHeader.styled';
import { getDelegationSession, getTokenUser, getUserCommercialLink } from 'domains/user';
import { theme } from 'styles';
import { Icon, MarginBox, Text, WithTooltip } from 'UI';
import { hasData } from 'utils';
import { Bubble } from './UserInfo.styled';

export const fmt = (name?: string, tresor?: string) => [name, tresor].filter((n) => n).join(' - ');

const UserInfo = () => {
  const { t } = useTranslation();
  const [profileVisible, setProfileVisible] = useState<boolean>(false);
  const userInfo = useSelector(getTokenUser);
  const commercialLink = useSelector(getUserCommercialLink);
  const connectedAs = useSelector(getDelegationSession);
  const isConnectedAs = hasData(connectedAs);
  const id = userInfo?.selectedProfile?.id ?? '';
  const garageName = commercialLink?.name ?? '';
  const connectedAsGarageName = fmt(
    connectedAs?.commercialLink.name ?? '',
    connectedAs?.commercialLink.tresorCode ?? '',
  );
  const firstName = userInfo?.firstName ?? '';
  const lastName = userInfo?.lastName ?? '';
  const getGarageNameMaxLen = () => {
    if (isConnectedAs) {
      return 18 - id.length;
    }
    return 28 - id.length - firstName.length;
  };

  const getIsGarageNameTooLong = () => {
    if (isConnectedAs) {
      return connectedAsGarageName.length > getGarageNameMaxLen();
    }
    return garageName.length > getGarageNameMaxLen();
  };

  const getLabelPart = (label: string) => {
    if (label.trim() === '') {
      return '';
    }
    return `- ${label}`;
  };

  const getLabel = (short?: boolean) => {
    const getGarageNameShort = (name: string) => {
      if (name === '' || !name) {
        return '';
      }
      const shortened = name.substring(0, getGarageNameMaxLen() - 2).trim();
      const undashed = shortened[shortened.length - 1] === '-' ? shortened.slice(0, -1).trim() : shortened;
      return getIsGarageNameTooLong() ? `${undashed}..` : name;
    };

    if (isConnectedAs) {
      return `${t('header.connected_as ', 'Connected as')} ${id} ${getLabelPart(
        short ? getGarageNameShort(connectedAsGarageName) : connectedAsGarageName,
      )}`;
    }
    return `${id} ${getLabelPart(`${short ? getGarageNameShort(garageName) : garageName}`)} ${getLabelPart(
      `${firstName} ${short ? '' : lastName}`,
    )}`;
  };

  const getIcon = () => {
    if (isConnectedAs) {
      return (
        <Icon
          IconComponent={ConnectIcon}
          background={theme.color.red}
          round
          size={32}
          color={theme.color.white}
          display={'block'}
        />
      );
    }
    return <Icon IconComponent={UserCircleIcon} size={32} color={theme.color.grass_green} display={'block'} />;
  };

  return (
    <SPopover
      id={'top_header-profile-popover'}
      getPopupContainer={(trigger) => trigger.parentElement ?? trigger}
      content={<ProfileCard setProfileVisible={setProfileVisible} />}
      trigger={'click'}
      placement={'bottomRight'}
      visible={profileVisible}
      onVisibleChange={(visible) => setProfileVisible(visible)}
    >
      <Bubble direction={'row'} align={'center'}>
        {getIcon()}
        <MarginBox mr={10} ml={5}>
          <WithTooltip title={getLabel()} placement={'bottom'}>
            <Text type={'text_dim_bold'}>{getLabel(true)}</Text>
          </WithTooltip>
        </MarginBox>
      </Bubble>
    </SPopover>
  );
};

export default UserInfo;
