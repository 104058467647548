import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getIAMReferences } from 'domains/references';
import { ClientView } from 'domains/user';
import ReferenceCardsContainer from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer';
import { Box, Flex, MarginBox, Text } from 'UI';
import { getData } from 'utils';

const Family = ({
  familyId,
  familyLabel,
  onSelectReferences,
  selectedReferences,
  references,
}: {
  familyId: string;
  familyLabel: string;
  onSelectReferences: (family: string, references: string[]) => void;
  selectedReferences: string[] | undefined;
  references: string[] | undefined;
}) => {
  const { t } = useTranslation();

  function onSelectReference(referenceNumber: string) {
    onSelectReferences(familyId, [referenceNumber]);
  }

  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referencesRaw = useSelector((state: RootState) =>
    getIAMReferences(state, {
      vehicleKey,
      referenceNumbers: selectedReferences,
    }),
  );

  const referencesRawData = getData(referencesRaw);

  return (
    <Flex direction={'column'}>
      <>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'h4_paragraph'} transform={'capitalize'}>
            {familyLabel}
          </Text>
          <Box width={40} />
          {referencesRawData && referencesRawData.length > 0 && (
            <Text type={'link_bold'}>{t('catalog.maintenance.new_maintenance_plan.selected', 'Selected:')}</Text>
          )}
          <Box width={12} />
          {referencesRawData?.map((reference) => {
            return (
              <React.Fragment key={reference.referenceNumber}>
                <Text type={'text'} transform={'capitalize'}>
                  {`${reference.supplier} ${reference.referenceNumber}`}
                </Text>
                <Box width={12} />
              </React.Fragment>
            );
          })}
        </Flex>
        <MarginBox mt={25} />
        <Flex direction={'column'}>
          {references && (
            <ReferenceCardsContainer
              referenceNumbers={references}
              selectedReferences={selectedReferences}
              onSelect={onSelectReference}
              sparePartsView={ClientView}
              isMaintenance
            />
          )}
        </Flex>
      </>
    </Flex>
  );
};

export default Family;
