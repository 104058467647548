import { CHANGE_GARAGE_EMAIL_URL, GET_GARAGE_INFO_URL } from '@1po/1po-bff-fe-spec/generated/garage/GarageURLs';
import { ChangeGarageEmail } from '@1po/1po-bff-fe-spec/generated/garage/request/ChangeGarageEmail';
import { GetGarageInfo } from '@1po/1po-bff-fe-spec/generated/garage/request/GetGarageInfo';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendGetGarageInfo(request: GetGarageInfo): WebSocketAction {
  return wsSendAction(GET_GARAGE_INFO_URL, request);
}

export function sendChangeGarageEmail(request: ChangeGarageEmail): WebSocketAction {
  return wsSendAction(CHANGE_GARAGE_EMAIL_URL, request);
}
