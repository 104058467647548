import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CatalogSource, VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { ROUTER_CATALOG_VEHICLE, ROUTER_HISTORY } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { fetchVehicleFunction } from 'domains/catalog/Catalog.requests';
import { VehicleCountry } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getVehicleSearchHistory } from 'domains/user';
import {
  Box,
  CarButton,
  CenteredSpin,
  CenterFlex,
  Flex,
  MarginBox,
  ScalableImage,
  SearchHistoryButton,
  Text,
} from 'UI';
import { getData, isLoading, isQueryBMM } from 'utils';
import { HighlightBorder, HighlightDot, SearchHistoryLabel, SSearchHistoryCube } from './SearchHistoryOverview.styled';

const SearchHistoryImage = ({
  imageUrl,
  catalogSource,
  currentlySelected,
}: Readonly<{
  imageUrl: string | undefined;
  catalogSource: CatalogSource;
  currentlySelected: boolean;
}>) => {
  return currentlySelected ? (
    <Box height={90} width={90}>
      <HighlightBorder>
        <HighlightDot />
        <CenterFlex>
          <ScalableImage
            imageUrl={imageUrl}
            catalogSource={catalogSource}
            fallbackComponent={<CarButton round boxSize={'xl'} />}
            alt={'car-preview'}
            width={90}
            height={90}
            cursor={'pointer'}
          />
        </CenterFlex>
      </HighlightBorder>
    </Box>
  ) : (
    <Box height={90} width={90}>
      <CenterFlex>
        <ScalableImage
          imageUrl={imageUrl}
          catalogSource={catalogSource}
          fallbackComponent={<CarButton round boxSize={'lg'} />}
          alt={'car-preview'}
          width={90}
          height={90}
          cursor={'pointer'}
        />
      </CenterFlex>
    </Box>
  );
};

function SearchHistoryCube({
  item,
  redirectToCatalog,
  currentlySelected,
}: {
  item: VehicleDetail;
  redirectToCatalog: boolean;
  currentlySelected: boolean;
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);

  const getVehicleLabel = () => {
    return item.catalogSource === 'DATAHUB'
      ? item.dataHubVehicle?.name
      : isQueryBMM(item.vehicleKey)
      ? `${item.vehicleBrand} ${item.iamVehicle?.modelType}`
      : `${item.iamVehicle?.name} ${item.iamVehicle?.modelType}`;
  };

  function selectVehicle() {
    if (item.country) {
      params.set(VehicleCountry, item.country);
    }

    fetchVehicleFunction(
      dispatch,
      item.vehicleKey,
      true,
      item.country,
      item.catalogSource,
      undefined,
      undefined,
      item,
      'requestId',
    );

    redirectToCatalog
      ? history.push(`${getCatalogSourceUrl(item.catalogSource)}/${item.vehicleKey}${ROUTER_CATALOG_VEHICLE}?${params}`)
      : {};
  }

  return (
    <SSearchHistoryCube>
      <Box>
        <CenterFlex onClick={selectVehicle} style={{ cursor: 'pointer' }}>
          <SearchHistoryImage
            imageUrl={item.imageUrl}
            currentlySelected={currentlySelected}
            catalogSource={item.catalogSource}
          />
        </CenterFlex>
      </Box>
      <SearchHistoryLabel type={'light_14_black_45'}>{getVehicleLabel()}</SearchHistoryLabel>
      <Text type={'light_14_black_45'}>{item.vrn ?? item.vin}</Text>
    </SSearchHistoryCube>
  );
}

function SearchHistoryBtn() {
  const { t } = useTranslation();
  return (
    <SSearchHistoryCube>
      <Box>
        <Flex align={'center'} justify={'center'}>
          <Box height={90} width={90}>
            <CenterFlex>
              <SearchHistoryButton round boxSize={'lg'} link={ROUTER_HISTORY} />
            </CenterFlex>
          </Box>
        </Flex>
      </Box>
      <SearchHistoryLabel type={'light_14_black_45'}>{t('catalog.history', 'Search History')}</SearchHistoryLabel>
    </SSearchHistoryCube>
  );
}

const SearchHistoryOverview = ({ redirectToCatalog }: { redirectToCatalog: boolean }) => {
  const { t } = useTranslation();
  const vehicleSearchHistory = useSelector(getVehicleSearchHistory);
  const lastSearchedVehicleKey = useSelector((state: RootState) => state.catalog.lastSearchedVehicleKey);
  const definedVehicleSearchHistory = getData(vehicleSearchHistory);
  const showSearchHistory =
    (definedVehicleSearchHistory && definedVehicleSearchHistory.length > 1) ||
    (vehicleSearchHistory?.length === 1 &&
      definedVehicleSearchHistory &&
      definedVehicleSearchHistory[0].vehicleKey !== lastSearchedVehicleKey);

  return (
    <>
      {isLoading(vehicleSearchHistory) && <CenteredSpin />}
      {showSearchHistory && (
        <Flex direction={'column'}>
          <CenterFlex>
            <Text type={'h2'}>{t('catalog.history.recent_vehicles', 'Recent vehicles')}</Text>
          </CenterFlex>
          <Flex align={'center'} justify={'center'}>
            <DataContainer data={vehicleSearchHistory} noDataMarginY={20}>
              {getData(vehicleSearchHistory)
                ?.slice(0, 4)
                .map((item, i) => (
                  <SearchHistoryCube
                    item={item}
                    currentlySelected={lastSearchedVehicleKey === item.vehicleKey}
                    redirectToCatalog={redirectToCatalog}
                    key={i}
                  />
                ))}
            </DataContainer>
            {getData(vehicleSearchHistory) && <SearchHistoryBtn />}
          </Flex>
          <MarginBox mt={10} />
        </Flex>
      )}
    </>
  );
};

export default SearchHistoryOverview;
