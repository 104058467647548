import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetInformations } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformations';
import { RootState } from 'app/AppStore';
import {
  fetchInformationBannersRequestSaga,
  fetchInformationRequestSaga,
  fetchInformationsRequestSaga,
  getInformationDetails,
  getLoadedInformations,
} from 'domains/information/Information.store';
import { getUserContext, getUserProfileSearchStatus, isDelegationSessionStatusLoading } from 'domains/user';
import { FOUND } from 'utils';

export const useFetchInformationBanners = (): void => {
  const dispatch = useDispatch();
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const webLang = useSelector(getUserContext).webLanguage;
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);

  useEffect(() => {
    if (isDelegationLoading) {
      return;
    }
    if (searchStatus === FOUND) {
      dispatch(fetchInformationBannersRequestSaga());
    }
  }, [dispatch, searchStatus, isDelegationLoading, webLang]);
};

export const useFetchInformation = (informationId: string): void => {
  const dispatch = useDispatch();
  const information = useSelector((state: RootState) => getInformationDetails(state, informationId));
  const informationMissing = information === undefined;
  useEffect(() => {
    if (informationId && informationMissing) {
      dispatch(fetchInformationRequestSaga({ informationId }));
    }
  }, [dispatch, informationMissing, informationId]);
};

export const useFetchInformations = ({ status }: GetInformations): void => {
  const dispatch = useDispatch();
  const { status: searchStatus } = useSelector((state: RootState) => getLoadedInformations(state, status));

  useEffect(() => {
    if (!searchStatus) {
      dispatch(fetchInformationsRequestSaga({ status }));
    }
  }, [dispatch, status, searchStatus]);
};
