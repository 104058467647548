import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import {
  clearMyStoreTiresChanges,
  getMyStoreTiresDiscountChanges,
  updateMyStoreTireDiscountListRequest,
} from 'domains/myStore/MyStore.store';
import { BlackButton, WhiteButton } from 'UI';

export const MyStoreDiscountControlButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userChanges = useSelector((state: RootState) => getMyStoreTiresDiscountChanges(state));

  const onSave = () => {
    dispatch(updateMyStoreTireDiscountListRequest(userChanges));
  };

  const onCancel = () => {
    dispatch(clearMyStoreTiresChanges());
  };

  return (
    <>
      <WhiteButton size={'large'} onClick={onCancel}>
        {t('common.action.cancel', 'Cancel')}
      </WhiteButton>
      <BlackButton size={'large'} onClick={onSave}>
        {t('common.action.save', 'Save')}
      </BlackButton>
    </>
  );
};
