import { put, select, takeEvery } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { isRemoteLoggingEnabled } from 'domains/appContext/AppContext.store';
import { sagaGuard } from 'utils';
import { sendClientLogRequest } from './ClientLog.api';
import * as actions from './ClientLog.store';

export const isEnabled = (state: RootState): boolean => isRemoteLoggingEnabled(state);

export function* putClientLogSaga(action: AnyAction): SagaIterator {
  const enabled = yield select(isEnabled);
  if (enabled) {
    const { clientLogRequest } = action.payload;
    yield put(sendClientLogRequest(clientLogRequest));
  }
}

export function* ClientLogSagas(): SagaIterator {
  yield takeEvery(actions.putClientLogSaga.type, sagaGuard(putClientLogSaga));
}
