import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Trans, useTranslation } from 'react-i18next';
import { CopyIcon } from 'assets/icons';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import { theme } from 'styles';
import { Icon, notifyTop, WithTooltip } from 'UI';

export const DefaultCopyMessage = <Trans i18nKey={'common.copied_to_clipboard'}>Copied to clipboard</Trans>;

export const CopyToClipboardButton = ({
  textToCopy,
  message,
  size = 16,
  tooltip,
  inverted = false,
}: {
  textToCopy: string;
  message?: string;
  size?: number;
  tooltip?: string;
  inverted?: boolean;
}) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    notifyTop('info', message ?? DefaultCopyMessage);
  };

  const iconColor = inverted ? theme.color.white : theme.color.brand_black;
  const hoverColor = inverted ? theme.color.white : theme.color.brand_black_light_120;

  return (
    <StopPropagation>
      <WithTooltip title={tooltip ?? t('common.action.copy_to_clipboard', 'Copy to clipboard')}>
        <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
          <Icon IconComponent={CopyIcon} height={size} width={size} color={iconColor} hoverFill={hoverColor} />
        </CopyToClipboard>
      </WithTooltip>
    </StopPropagation>
  );
};
