import {
  OrderDeliveryType,
  OrderMKTPDeliveryMode,
} from '@1po/1po-bff-fe-spec/generated/order/request/CheckoutOrderRequest';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  ORDER_VALIDATION_NAMESPACE,
  OrderValidationState,
  RESET_VALIDATION_DELIVERY_ADDRESS,
  RESET_VALIDATION_INSTRUCTIONS,
  RESET_VALIDATION_PAYMENT_METHOD,
  SET_DEFAULT_VALIDATION_DELIVERY_ADDRESS,
  SET_DEFAULT_VALIDATION_PAYMENT_METHOD,
  SET_VALIDATION_DELIVERY_ADDRESS,
  SET_VALIDATION_INSTRUCTIONS,
  SET_VALIDATION_PAYMENT_METHOD,
  ValidationItemsExternal,
  ValidationItemsVehicles,
} from 'domains/orderValidation/OrderValidation.types';

const initialState: OrderValidationState = {
  defaultDeliveryAddress: 'OWN_ADDRESS',
  mktpDeliveryMode: undefined,
  vehicles: [],
  externalBaskets: [],
  otherSection: {
    instructions: '',
  },
};

const findVehicle = (vehicles: ValidationItemsVehicles[], vehicleKey: string) => {
  return vehicles?.find((vehicle: ValidationItemsVehicles) => vehicleKey === vehicle.vehicleKey);
};

const findExternal = (externals: ValidationItemsExternal[], externalBasketId: string) => {
  return externals?.find((ext: ValidationItemsExternal) => externalBasketId === ext.externalBasketId);
};

const getVehiclesWithUpdatedDeliveryAddress = (
  vehicles: ValidationItemsVehicles[],
  vehicleKey: string,
  deliveryAddress: OrderDeliveryType | undefined,
): ValidationItemsVehicles[] => {
  const vehicleStored = findVehicle(vehicles, vehicleKey);
  if (vehicleStored) {
    return vehicles?.map((vehicle: ValidationItemsVehicles) => {
      if (vehicle.vehicleKey === vehicleKey)
        return {
          ...vehicle,
          deliveryAddress,
        };
      else return vehicle;
    });
  }
  return vehicles ? vehicles?.concat({ vehicleKey, deliveryAddress }) : [{ vehicleKey, deliveryAddress }];
};

const getExternalsWithUpdatedDeliveryAddress = (
  externalBaskets: ValidationItemsExternal[],
  externalBasketId: string,
  deliveryAddress: OrderDeliveryType | undefined,
): ValidationItemsExternal[] => {
  const externalStored = findExternal(externalBaskets, externalBasketId);
  if (externalStored) {
    return externalBaskets?.map((ext: ValidationItemsExternal) => {
      if (ext.externalBasketId === externalBasketId)
        return {
          ...ext,
          deliveryAddress,
        };
      else return ext;
    });
  }
  return externalBaskets
    ? externalBaskets?.concat({ externalBasketId, deliveryAddress })
    : [{ externalBasketId, deliveryAddress }];
};

const getVehiclesWithUpdatedPaymentMethod = (
  vehicles: ValidationItemsVehicles[],
  vehicleKey: string,
  paymentMethod: string | undefined,
): ValidationItemsVehicles[] => {
  const vehicleStored = findVehicle(vehicles, vehicleKey);
  if (vehicleStored) {
    return vehicles?.map((vehicle: ValidationItemsVehicles) => {
      if (vehicle.vehicleKey === vehicleKey)
        return {
          ...vehicle,
          paymentMethod,
        };
      else return vehicle;
    });
  }
  return vehicles ? vehicles?.concat({ vehicleKey, paymentMethod }) : [{ vehicleKey, paymentMethod }];
};

const getExternalsWithUpdatedPaymentMethod = (
  externalBaskets: ValidationItemsExternal[],
  externalBasketId: string,
  paymentMethod: string | undefined,
): ValidationItemsExternal[] => {
  const externalStored = findExternal(externalBaskets, externalBasketId);
  if (externalStored) {
    return externalBaskets?.map((ext: ValidationItemsExternal) => {
      if (ext.externalBasketId === externalBasketId)
        return {
          ...ext,
          paymentMethod,
        };
      else return ext;
    });
  }
  return externalBaskets
    ? externalBaskets?.concat({ externalBasketId, paymentMethod })
    : [{ externalBasketId, paymentMethod }];
};

const getVehiclesWithUpdatedInstructions = (
  vehicles: ValidationItemsVehicles[],
  vehicleKey: string,
  instructions: string | undefined,
): ValidationItemsVehicles[] => {
  const vehicleStored = findVehicle(vehicles, vehicleKey);
  if (vehicleStored) {
    return vehicles?.map((vehicle: ValidationItemsVehicles) => {
      if (vehicle.vehicleKey === vehicleKey)
        return {
          ...vehicle,
          instructions,
        };
      else return vehicle;
    });
  }
  return vehicles ? vehicles?.concat({ vehicleKey, instructions }) : [{ vehicleKey, instructions }];
};

const getExternalsWithUpdatedInstructions = (
  externalBaskets: ValidationItemsExternal[],
  externalBasketId: string,
  instructions: string | undefined,
): ValidationItemsExternal[] => {
  const externalStored = findExternal(externalBaskets, externalBasketId);
  if (externalStored) {
    return externalBaskets?.map((ext: ValidationItemsExternal) => {
      if (ext.externalBasketId === externalBasketId)
        return {
          ...ext,
          instructions,
        };
      else return ext;
    });
  }
  return externalBaskets
    ? externalBaskets?.concat({ externalBasketId, instructions })
    : [{ externalBasketId, instructions }];
};

// Saga actions
// order part
export const setDefaultValidationDeliveryAddressSaga = createAction<OrderDeliveryType>(
  SET_DEFAULT_VALIDATION_DELIVERY_ADDRESS,
);
export const setDefaultValidationPaymentMethodSaga = createAction<string>(SET_DEFAULT_VALIDATION_PAYMENT_METHOD);
export const setValidationInstructionsSaga = createAction<{
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  instructions: string;
}>(SET_VALIDATION_INSTRUCTIONS);
export const setValidationDeliveryAddressSaga = createAction<{
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  deliveryAddress: OrderDeliveryType;
}>(SET_VALIDATION_DELIVERY_ADDRESS);
export const setValidationPaymentMethodSaga = createAction<{
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  paymentMethod: string;
}>(SET_VALIDATION_PAYMENT_METHOD);
export const resetValidationDeliveryAddressSaga = createAction<{
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
}>(RESET_VALIDATION_DELIVERY_ADDRESS);
export const resetValidationInstructionsSaga = createAction<{
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
}>(RESET_VALIDATION_INSTRUCTIONS);
export const resetValidationPaymentMethodSaga = createAction<{
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
}>(RESET_VALIDATION_PAYMENT_METHOD);

const slice = createSlice({
  name: ORDER_VALIDATION_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setDefaultDeliveryAddress: (state, action: PayloadAction<{ deliveryAddress: OrderDeliveryType }>) => {
      state.defaultDeliveryAddress = action.payload.deliveryAddress;
    },
    setOrderMKTPDeliveryMode: (state, action: PayloadAction<OrderMKTPDeliveryMode>) => {
      state.mktpDeliveryMode = action.payload;
    },
    setDefaultPaymentMethod: (state, action: PayloadAction<{ paymentMethod: string }>) => {
      state.defaultPaymentMethod = action.payload.paymentMethod;
    },
    setOrderValidationDeliveryAddress: (
      state,
      action: PayloadAction<{
        vehicleKey: string | undefined;
        externalBasketId: string | undefined;
        deliveryAddress: OrderDeliveryType;
      }>,
    ) => {
      const { vehicleKey, externalBasketId, deliveryAddress } = action.payload;
      if (vehicleKey) {
        state.vehicles = getVehiclesWithUpdatedDeliveryAddress(state.vehicles, vehicleKey, deliveryAddress);
      } else if (externalBasketId) {
        state.externalBaskets = getExternalsWithUpdatedDeliveryAddress(
          state.externalBaskets,
          externalBasketId,
          deliveryAddress,
        );
      } else {
        state.otherSection = { ...state.otherSection, deliveryAddress };
      }
    },
    resetOrderValidationDeliveryAddress: (
      state,
      action: PayloadAction<{ vehicleKey?: string; externalBasketId?: string }>,
    ) => {
      const { vehicleKey, externalBasketId } = action.payload;
      if (vehicleKey) {
        state.vehicles = getVehiclesWithUpdatedDeliveryAddress(state.vehicles, vehicleKey, undefined);
      } else if (externalBasketId) {
        state.externalBaskets = getExternalsWithUpdatedDeliveryAddress(
          state.externalBaskets,
          externalBasketId,
          undefined,
        );
      } else {
        state.otherSection = {
          ...state.otherSection,
          deliveryAddress: undefined,
        };
      }
    },
    setOrderValidationInstructions: (
      state,
      action: PayloadAction<{
        vehicleKey: string | undefined;
        externalBasketId: string | undefined;
        instructions: string;
      }>,
    ) => {
      const { vehicleKey, externalBasketId, instructions } = action.payload;
      if (vehicleKey) {
        state.vehicles = getVehiclesWithUpdatedInstructions(state.vehicles, vehicleKey, instructions);
      } else if (externalBasketId) {
        state.externalBaskets = getExternalsWithUpdatedInstructions(
          state.externalBaskets,
          externalBasketId,
          instructions,
        );
      } else {
        state.otherSection = {
          ...state.otherSection,
          instructions,
        };
      }
    },
    resetOrderValidationInstructions: (
      state,
      action: PayloadAction<{
        vehicleKey: string | undefined;
        externalBasketId?: string;
      }>,
    ) => {
      const { vehicleKey } = action.payload;

      if (vehicleKey) {
        state.vehicles = getVehiclesWithUpdatedInstructions(state.vehicles, vehicleKey, undefined);
      } else {
        state.otherSection = {
          ...state.otherSection,
          instructions: undefined,
        };
      }
    },
    setOrderValidationPaymentMethod: (
      state,
      action: PayloadAction<{
        vehicleKey: string | undefined;
        externalBasketId: string | undefined;
        paymentMethod: string;
      }>,
    ) => {
      const { vehicleKey, externalBasketId, paymentMethod } = action.payload;
      if (vehicleKey) {
        state.vehicles = getVehiclesWithUpdatedPaymentMethod(state.vehicles, vehicleKey, paymentMethod);
      } else if (externalBasketId) {
        state.externalBaskets = getExternalsWithUpdatedPaymentMethod(
          state.externalBaskets,
          externalBasketId,
          paymentMethod,
        );
      } else {
        state.otherSection = {
          ...state.otherSection,
          paymentMethod,
        };
      }
    },
    resetOrderValidationPaymentMethod: (
      state,
      action: PayloadAction<{
        vehicleKey: string | undefined;
        externalBasketId?: string;
      }>,
    ) => {
      const { vehicleKey, externalBasketId } = action.payload;

      if (vehicleKey) {
        state.vehicles = getVehiclesWithUpdatedPaymentMethod(state.vehicles, vehicleKey, undefined);
      } else if (externalBasketId) {
        state.externalBaskets = getExternalsWithUpdatedPaymentMethod(
          state.externalBaskets,
          externalBasketId,
          undefined,
        );
      } else {
        state.otherSection = { ...state.otherSection, paymentMethod: undefined };
      }
    },
  },
});

// Actions
export const {
  setInitialState,
  setDefaultDeliveryAddress,
  setOrderMKTPDeliveryMode,
  setDefaultPaymentMethod,
  setOrderValidationPaymentMethod,
  setOrderValidationDeliveryAddress,
  setOrderValidationInstructions,
  resetOrderValidationDeliveryAddress,
  resetOrderValidationInstructions,
  resetOrderValidationPaymentMethod,
} = slice.actions;

export const getDefaultDeliveryAddress = createSelector(
  (state: RootState) => state.orderValidation.defaultDeliveryAddress,
  (defaultDeliveryAddress) => defaultDeliveryAddress,
);

export const getOrderMKTPDeliveryMode = createSelector(
  (state: RootState) => state.orderValidation.mktpDeliveryMode,
  (mktpDeliveryMode) => mktpDeliveryMode,
);

export const getDefaultPaymentMethod = createSelector(
  (state: RootState) => state.orderValidation.defaultPaymentMethod,
  (defaultPaymentMethod) => defaultPaymentMethod,
);

export const getVehiclesValidation = createSelector(
  (state: RootState) => state.orderValidation.vehicles,
  (vehicles) => vehicles,
);

export const getOtherSectionValidation = createSelector(
  (state: RootState) => state.orderValidation.otherSection,
  (otherSection) => otherSection,
);

export const getExternalSectionValidation = createSelector(
  (state: RootState) => state.orderValidation.externalBaskets,
  (externalBaskets) => externalBaskets,
);

// Export reducer
export default slice.reducer;
