import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { FileUploadPreview, FileUploadStrip } from 'components/FileUpload';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import {
  findStatusTypeByPromotionID,
  removeUploadedFileInfo,
  setUploadedReferencesFromFileSizeAndName,
  updateUploadedFileInfo,
  uploadReferencesFileSaga,
  validateProductsRequestSaga,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionModel, isFlashPromotion, UploadedReferencesFromFile } from 'domains/promotion/Promotion.types';
import { validateFile } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/common';
import { Flex, MarginBox, Text } from 'UI';
import { hasData, useBreakpointSelectorFull } from 'utils';
import { ApplyDiscountReferences } from './ApplyDiscountReferences';
import { unblockRemovedReferenceInFirstFoundFile } from './PromotionErrorNotifications';
import { RemovePromotedProductsButton } from './RemovePromotedProductsButton';

export function UploadedReferences({
  uploadedReferences,
  promotion,
  setPromotion,
  setCurrentStep,
  index,
}: Readonly<{
  uploadedReferences: UploadedReferencesFromFile;
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  setCurrentStep: (x: number) => void;
  index: number;
}>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemCount = useBreakpointSelectorFull()({ xs: 40, sm: 40, md: 50, lg: 50, xl: 48, xxl: 80 });

  const uploadFile = (fileId: string) => (fileName: string, fileSize: number, fileBase64: string) => {
    if (promotion.promotionType === 'PROMOTION_FLASH' || !promotion.publicationStart) {
      return;
    }
    dispatch(setUploadedReferencesFromFileSizeAndName({ fileName, fileId, fileSize }));
    dispatch(uploadReferencesFileSaga({ fileId, fileName, fileBase64, promotion }));
  };

  const updateFileInfo = (fileId: string) => (fileName: string, fileSize: number) => {
    dispatch(updateUploadedFileInfo({ fileName, fileId, fileSize }));
  };

  const promotionStatus = useSelector((state: RootState) =>
    findStatusTypeByPromotionID(state, hasData(promotion) ? promotion.promotionId : undefined),
  );

  const isEditing = promotion?.editing;
  const isSavedOrPlanned = promotionStatus === 'SAVED' || promotionStatus === 'PLANNED';
  const isPublished = promotionStatus === 'PUBLISHED';
  const isBannerOrFlashTimeLtd =
    promotion?.promotionType === 'BANNER' || promotion?.promotionType === 'PROMOTION_FLASH_TIME_LIMITED';
  const isFileUploaded = promotion?.uploadedReferencesFromFiles[0].status === 'file';
  const renderRemoveBtn = (isEditing && isSavedOrPlanned) || (!isPublished && isBannerOrFlashTimeLtd && isFileUploaded);

  const isFirstFile = index === 0;

  const handleRemoveReferences = () => {
    if (!promotion.uploadedReferencesFromFiles || index === undefined) {
      return;
    }

    const updatedReferences = [...promotion.uploadedReferencesFromFiles];
    const fileId = updatedReferences[index].fileId;
    const removedReferences = updatedReferences[index]?.rows ?? [];
    removedReferences.forEach((r) => unblockRemovedReferenceInFirstFoundFile(r, index, updatedReferences));
    updatedReferences[index] = {
      fileId: uuidV4(),
      rows: [],
      status: NO_FILE,
      discount: 0,
    };

    const updatedPromotion = {
      ...promotion,
      uploadedReferencesFromFiles: updatedReferences,
    };
    setPromotion(updatedPromotion);
    dispatch(validateProductsRequestSaga({ promotion: updatedPromotion }));

    if (!fileId) {
      return;
    }
    dispatch(removeUploadedFileInfo({ fileId }));
  };

  const renderDiscount = !isFlashPromotion(promotion.promotionType) && promotion.promotionType !== 'BANNER';
  const duplicateRows = [
    ...(uploadedReferences.alreadyPromoted ?? []),
    ...(uploadedReferences.alreadyLocallyPromoted ?? []),
  ];

  const uniqueDuplicateRows = [...new Set(duplicateRows)];
  return (
    <>
      <Flex direction={'column'} size={4}>
        {isFirstFile && (
          <Flex>
            <Text type={'h5_bold'}>{t('backoffice.promotion.by_references', 'By references')}</Text>
          </Flex>
        )}
        <Flex key={`reference-file-flex-${uploadedReferences.fileId}`}>
          <Flex direction={'column'}>
            <MarginBox mt={15} />
            <Text type={'dark_12_white_65'}>
              {t(
                'backoffice.promotion.by_references.file_upload_limit',
                'Supported extension : CSV. File size 100 Ko Max.',
              )}
            </Text>
            <MarginBox mt={15} />
            <Flex direction={'row'}>
              <FileUploadStrip
                key={`reference-file-${uploadedReferences.fileId}`}
                handleFileBase64={uploadFile(uploadedReferences.fileId ?? '')}
                handleFileInfo={updateFileInfo(uploadedReferences.fileId ?? '')}
                validateFile={validateFile(t)}
                status={uploadedReferences.status}
                fileName={uploadedReferences.fileName}
                template={{
                  link: '/fileTemplates/promotion_references.csv',
                  fileName: 'References.csv',
                }}
                disable={uploadedReferences.editing}
              />
              <Flex />
              {renderDiscount && (
                <ApplyDiscountReferences
                  uploadedReferences={uploadedReferences}
                  promotion={promotion}
                  setPromotion={setPromotion}
                  index={index}
                />
              )}
              {renderRemoveBtn && (
                <RemovePromotedProductsButton index={index} onDeleteClicked={handleRemoveReferences} />
              )}
            </Flex>
            <MarginBox mt={15} />
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={'row'}>
        <FileUploadPreview
          key={`reference-file-preview-${uploadedReferences.fileId}`}
          fileSize={uploadedReferences.fileSize}
          fileName={uploadedReferences.fileName}
          uploadTime={uploadedReferences.uploadTime}
          columnName={t('catalog.reference_plural', 'References')}
          data={uploadedReferences.rows}
          duplicateRows={uniqueDuplicateRows}
          loadMoreBy={itemCount}
          promotion={promotion}
          setPromotion={setPromotion}
          fileId={uploadedReferences.fileId}
          referenceEditing={uploadedReferences.editing}
          setCurrentStep={setCurrentStep}
        />
      </Flex>
      <MarginBox mt={15} />
    </>
  );
}
