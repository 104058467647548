import { breakpoint } from './breakpoint';
import color from './color.json';
import { displayMode } from './displayMode';
import { displayStyle } from './displayStyle';
import { font } from './font';
import { spacing } from './spacing';

export type ThemeType = typeof theme;
export type ThemeColorKeyType = keyof ThemeType['color'];
export type AbsoluteThemeColorType = ThemeType['color'][ThemeColorKeyType];
export type StatusType = 'info' | 'warning' | 'error' | 'success';
export type ThemeFontKeyType = keyof ThemeType['font'];
export type ThemeBreakpointKeyType = keyof ThemeType['breakpoint'];
export type ThemeDisplayStyleKeyType = keyof ThemeType['displayStyle'];
export type DisplayModeKeyType = keyof ThemeType['displayMode'];

export const theme = {
  color,
  displayStyle,
  font,
  spacing,
  breakpoint,
  displayMode,
};
