import color from 'styles/color.json';
import { AbsoluteThemeColorType } from 'styles/theme';

export type DisplayModeType = 'light' | 'R3_MOTRIO';

interface DisplayModeProps {
  brandColor: AbsoluteThemeColorType;
  searchFontColor: AbsoluteThemeColorType;
  secondaryColor: AbsoluteThemeColorType;
  searchBackgroundColor: AbsoluteThemeColorType;
}

export const displayMode: Record<DisplayModeType, DisplayModeProps> = {
  light: {
    brandColor: color.blue_dark_3,
    searchFontColor: color.brand_black,
    secondaryColor: color.white65,
    searchBackgroundColor: color.white,
  },
  R3_MOTRIO: {
    brandColor: color.brand_motrio,
    searchFontColor: color.brand_black,
    secondaryColor: color.white85,
    searchBackgroundColor: color.white,
  },
};
