import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { PictureResponse } from '@1po/1po-bff-fe-spec/generated/picture/PictureResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import * as actions from 'domains/pictures/Pictures.store';
import { setPicture, setPictureLoading } from 'domains/pictures/Pictures.store';
import { sagaGuard } from 'utils';
import { sendFetchBase64FromUrlRequestSaga } from './Pictures.api';

export function* fetchPictureResponseSaga(action: WsResponse<PictureResponse>): SagaIterator {
  yield put(setPicture(action.payload));
}

export function* fetchBase64FromUrlRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchBase64FromUrlRequestSaga>): SagaIterator {
  yield put(setPictureLoading(payload.url));
  yield put(sendFetchBase64FromUrlRequestSaga(payload));
}

export function* PicturesSagas(): SagaIterator {
  yield takeEvery(actions.fetchPictureResponseSaga.type, sagaGuard(fetchPictureResponseSaga));
  yield takeEvery(actions.fetchBase64FromUrlRequestSaga.type, sagaGuard(fetchBase64FromUrlRequestSaga));
}
