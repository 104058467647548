import React from 'react';
import { Progress as AntdProgress } from 'antd';
import { useTheme } from 'styled-components';
import { CheckCircleIcon, CircleXMark, InfoCircleIcon } from 'assets/icons';
import { AbsoluteThemeColorType, ThemeColorKeyType } from 'styles';
import { MarginBox } from 'UI/Box';
import { Flex } from 'UI/Flex';
import { Icon } from 'UI/Icon';

const getProgressIcon = (status: ProgressStatusType) => {
  switch (status) {
    case 'success':
      return CheckCircleIcon;
    case 'error':
      return CircleXMark;
    case 'warning':
    case 'info':
    default:
      return InfoCircleIcon;
  }
};
type ProgressType = 'line' | 'circle' | 'dashboard';
export type ProgressStatusType = 'success' | 'error' | 'warning' | 'info';

interface ProgressProps {
  percent: number;
  strokeColor?: AbsoluteThemeColorType;
  color?: ThemeColorKeyType;
  type?: ProgressType;
  status?: ProgressStatusType;
  enableAnimation?: boolean;
  showIcon?: boolean;
}

export const Progress = ({
  percent,
  strokeColor,
  color,
  type,
  enableAnimation,
  status = 'info',
  showIcon,
}: ProgressProps) => {
  const theme = useTheme();
  const shouldDisplayIcon = !!showIcon;

  return (
    <Flex align={'center'}>
      <AntdProgress
        percent={percent}
        type={type}
        status={enableAnimation ? 'active' : 'normal'}
        strokeColor={color ? theme.color[color] : strokeColor}
        showInfo={false}
      />
      <MarginBox mr={3} />
      {shouldDisplayIcon && color && (
        <Icon IconComponent={getProgressIcon(status)} color={theme.color[color]} size={10} mb={9} />
      )}
    </Flex>
  );
};
