import { Tag } from 'antd';
import styled from 'styled-components';
import { defaultBoxShadow, Flex, Input, YellowButton } from 'UI';

export const SCard = styled.div`
  background-color: rgba(0, 0, 0, 0.01);
  padding: 15px;
  ${defaultBoxShadow};
`;

export const SaveButtonWrapper = styled.div`
  position: relative;
`;

export const SButton = styled(YellowButton)`
  position: absolute;
  right: 0;
  top: 75px;
  z-index: 1;
`;

export const DefaultTag = styled(Tag)`
  margin: 2px 2px;
  background: ${({ theme }) => theme.color.grey95_light};
  border: 1px solid ${({ theme }) => theme.color.grey80_light};
`;

export const DisabledTag = styled(DefaultTag)`
  background: ${({ theme }) => theme.color.grey80};
  border: 1px solid ${({ theme }) => theme.color.grey65_light};
`;

export const ErrorTag = styled(DefaultTag)`
  background: ${({ theme }) => theme.color.very_light_pink};
  border: 1px solid ${({ theme }) => theme.color.pink_bright};
`;

export const STagReferences = styled(Tag)<{ closable: boolean }>`
  line-height: 20px;
  width: 115px;
  margin-top: 15px;
  margin-right: 15px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.grey_04};
  border: 1px solid ${({ theme }) => theme.color.grey_15};
`;

export const STagReferencesError = styled(STagReferences)<{ closable: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.red_light};
`;

export const SInput = styled(Input)`
  height: 40px;
  width: auto;

  ::placeholder {
    color: ${({ theme }) => theme.color.grey_15};
    font-size: 16px;
  }
`;

export const RelativeFlex = styled(Flex)`
  position: relative;
`;

export const AddIconPosition = styled.div`
  position: absolute;
  right: 0;
`;
