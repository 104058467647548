import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_CART } from 'app/AppRouter';
import { NAVIGATE_FROM_CAR_DETAIL } from 'pages/CartPage/CartDetailsPage';
import { CartStepper } from 'pages/CartPage/CartPage';
import { Container, emptyPadding, indentPadding } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import ConfirmationStep from './ConfirmationStep';

const CartConfirmationPage = () => {
  const history = useHistory();

  useEffect(() => {
    if ('true' !== localStorage.getItem(NAVIGATE_FROM_CAR_DETAIL)) {
      history.push(ROUTER_CART);
    } else {
      localStorage.removeItem(NAVIGATE_FROM_CAR_DETAIL);
    }
  }, [history]);

  useResetScroll();

  return (
    <Container padding={emptyPadding}>
      <>
        <CartStepper step={2} />
        <Container padding={indentPadding}>
          <ConfirmationStep />
        </Container>
      </>
    </Container>
  );
};

export default CartConfirmationPage;
