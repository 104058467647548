export class FilterBy {
    field;
    value;
    filterOperation;
    constructor(field, value, filterOperation) {
        this.field = field;
        this.value = value;
        this.filterOperation = filterOperation;
    }
    toJSON() {
        return {
            type: 'filterBy',
            field: this.field,
            value: this.value,
            filterOperation: this.filterOperation,
        };
    }
}
