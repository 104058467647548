import { Technicity } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/Technicity';
import { LaborTimeDuration } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimesResponse';
import { LaborLevel, LaborType } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateSettings';
import { LaborPriceItem } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import isNumeric from 'antd/es/_util/isNumeric';
import { TFunction } from 'i18next';
import { getTechnicityTier } from 'domains/catalog/Catalog.types';

type LaborDetail = {
  laborType: LaborType;
  laborLevel: LaborLevel;
  laborTimeRate: string | undefined;
  time: number;
};

export const MECHANICS = 'MECHANICS';
export const TRIM = 'TRIM';
export const ELECTRICITY = 'ELECTRICITY';
export const PANELWORK = 'PANELWORK';
export const PAINTING = 'PAINTING';

function getLaborTypeName(laborType: LaborType | undefined, t: TFunction): string {
  switch (laborType) {
    case MECHANICS:
      return t('catalog.parts.category.car_parts.technicity.mechanical', 'Mechanical');
    case TRIM:
      return t('catalog.parts.category.car_parts.technicity.trim', 'Trim');
    case ELECTRICITY:
      return t('catalog.parts.category.car_parts.technicity.electricity', 'Electricity');
    case PANELWORK:
      return t('catalog.parts.category.car_parts.technicity.panelwork', 'Panelwork');
    case PAINTING:
      return t('catalog.parts.category.car_parts.technicity.painting', 'Painting');
    default:
      return '';
  }
}

const MECHANICS_HOURS = 'mechanicsHours';
const TRIM_HOURS = 'trimHours';
const ELECTRICITY_HOURS = 'electricityHours';
const PANELWORK_HOURS = 'panelWorkHours';
const PAINTING_HOURS = 'paintingHours';

function getLaborType(laborType: string | undefined): LaborType | undefined {
  if (!laborType) {
    return undefined;
  }
  switch (laborType) {
    case MECHANICS_HOURS:
      return MECHANICS;
    case TRIM_HOURS:
      return TRIM;
    case ELECTRICITY_HOURS:
      return ELECTRICITY;
    case PANELWORK_HOURS:
      return PANELWORK;
    case PAINTING_HOURS:
      return PAINTING;
    default:
      return undefined;
  }
}

export const getTechnicityFieldData = (
  t: TFunction,
  isWithLaborType: boolean,
  laborType?: LaborType,
  laborLevel?: LaborLevel,
  laborTimeRate?: string,
): string => {
  if (isWithLaborType) {
    return (
      (laborType ? getLaborTypeName(laborType, t) : '?') + '-' + getTechnicityTier(laborLevel) + '-' + laborTimeRate ??
      '?'
    );
  } else {
    return getTechnicityTier(laborLevel) + '-' + laborTimeRate ?? '?';
  }
};

export const getSettingsLaborTimeRate = (
  laborType: LaborType,
  laborLevel?: LaborLevel,
  laborPriceList?: LaborPriceItem[],
): LaborPriceItem | undefined =>
  laborPriceList?.find(
    (laborTimeRate) => laborTimeRate.technicityType === laborType && laborTimeRate.technicityLevel === laborLevel,
  );

export const getLaborTimeRate = (
  laborType: LaborType,
  laborLevel?: LaborLevel,
  laborPriceList?: LaborPriceItem[],
): LaborPriceItem | undefined => {
  if (!laborPriceList || !laborLevel || laborPriceList.length === 0) {
    return undefined;
  }
  const laborTimeRate = laborPriceList.find(
    (laborTimeRate) => laborTimeRate.technicityType === laborType && laborTimeRate.technicityLevel === laborLevel,
  );
  if (laborTimeRate) {
    return laborTimeRate;
  } else if (!laborTimeRate && laborType !== MECHANICS) {
    const defaultLaborTimeRate = laborPriceList.find(
      (laborTimeRate) => laborTimeRate.technicityType === MECHANICS && laborTimeRate.technicityLevel === laborLevel,
    );
    if (defaultLaborTimeRate) {
      return defaultLaborTimeRate;
    }
  }
  return undefined;
};

export function getLaborDetailList(
  laborTime: LaborTimeDuration | undefined,
  technicity: Technicity,
  laborPriceList?: LaborPriceItem[],
): LaborDetail[] {
  if (!laborTime) return [];
  return Object.entries(laborTime)
    .map(([laborTimeType, laborTimeAmount]) => {
      const laborType = getLaborType(laborTimeType);

      if (!laborType || !laborTimeAmount || !isNumeric(laborTimeAmount) || !(laborTimeAmount > 0)) {
        return;
      }

      return {
        laborType,
        laborLevel: technicity,
        laborTimeRate: getLaborTimeRate(laborType, technicity, laborPriceList)?.price,
        time: +laborTimeAmount,
      };
    })
    .filter((laborDetail) => laborDetail) as LaborDetail[];
}
