import React, { ReactNode } from 'react';
import styled, { css, ThemeProps, useTheme } from 'styled-components';
import { AbsoluteThemeColorType, ThemeDisplayStyleKeyType, ThemeFontKeyType, ThemeType } from 'styles';

const defaultFontFamily = 'NouvelR';

type TransformType = 'capitalize' | 'lowercase' | 'uppercase' | 'sentence-case' | 'none';
type Cursor = 'initial' | 'pointer';
export type Decoration = 'none' | 'underline' | 'line-through';

export interface TextProps {
  className?: string;
  type: ThemeFontKeyType;
  transform?: TransformType;
  cursor?: Cursor;
  decoration?: Decoration;
  displayStyle?: ThemeDisplayStyleKeyType | ThemeDisplayStyleKeyType[] | false;
  disableGutter?: boolean;
  onClick?: () => void;
  id?: string;
  ellipsis?: boolean;
  hoverUnderLine?: boolean;
  noWrap?: boolean;
  children?: ReactNode | string;
  dataCy?: string;
}

const fontStyles = css`
  font-family: ${({ theme, type }: ThemeProps<ThemeType> & TextProps) => theme.font[type]?.family ?? defaultFontFamily};
  font-size: ${({ theme, type }: ThemeProps<ThemeType> & TextProps) => theme.font[type]?.size + 'px'};
  font-weight: ${({ theme, type }: ThemeProps<ThemeType> & TextProps) => theme.font[type]?.weight};
  line-height: ${({ theme, type }: any) =>
    theme.font[type] && theme.font[type]?.lineHeight
      ? typeof theme.font[type].lineHeight === 'number'
        ? `${theme.font[type].lineHeight}px`
        : theme.font[type].lineHeight
      : 'inherit'};
  letter-spacing: ${({ theme, type }: any) => theme.font[type] && theme.font[type]?.letterSpacing + 'px'};
  cursor: ${({ cursor }) => cursor ?? 'inherit'};
  color: ${({ theme, type }: ThemeProps<ThemeType> & TextProps) => theme.font[type]?.color};
  text-transform: ${({ transform }) => transform ?? 'none'};
  text-decoration: ${({ decoration }) => decoration ?? 'none'};
  margin-bottom: ${({ theme, type, disableGutter }) =>
    !disableGutter && theme.font[type]?.heading ? '0.5em' : 'initial'};
  text-overflow: ${({ ellipsis }) => (ellipsis ? 'ellipsis' : 'initial')};
  overflow: ${({ ellipsis }) => (ellipsis ? 'hidden' : 'initial')};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'inherit')};
  ${({ theme, displayStyle }) =>
    displayStyle &&
    (Array.isArray(displayStyle)
      ? displayStyle.reduce((a, n) => a + theme.displayStyle[n], '')
      : theme.displayStyle[displayStyle])};

  :hover {
    text-decoration: ${({ hoverUnderLine }) => (hoverUnderLine ? 'underline' : 'initial')};
  }

  p {
    margin: 0;
  }
`;

const P = styled.span<TextProps>`
  ${fontStyles}
`;
const H1 = styled.h1<TextProps>`
  ${fontStyles}
`;
const H2 = styled.h2<TextProps>`
  ${fontStyles}
`;
const H3 = styled.h3<TextProps>`
  ${fontStyles}
`;
const H4 = styled.h4<TextProps>`
  ${fontStyles}
`;
const H5 = styled.h5<TextProps>`
  ${fontStyles}
`;
const H6 = styled.h6<TextProps>`
  ${fontStyles}
`;

export const Text = (props: TextProps) => {
  const { font } = useTheme();
  const variant = font[props.type];
  const CustomText = () =>
    props.transform === 'sentence-case' ? <SentenceCase>{props.children}</SentenceCase> : <>{props.children}</>;

  switch (variant?.heading) {
    case 1:
      return (
        <H1 {...props} data-cy={props.dataCy}>
          <CustomText />
        </H1>
      );
    case 2:
      return (
        <H2 {...props} data-cy={props.dataCy}>
          <CustomText />
        </H2>
      );
    case 3:
      return (
        <H3 {...props} data-cy={props.dataCy}>
          <CustomText />
        </H3>
      );
    case 4:
      return (
        <H4 {...props} data-cy={props.dataCy}>
          <CustomText />
        </H4>
      );
    case 5:
      return (
        <H5 {...props} data-cy={props.dataCy}>
          <CustomText />
        </H5>
      );
    case 6:
      return (
        <H6 {...props} data-cy={props.dataCy}>
          <CustomText />
        </H6>
      );
    default:
      return (
        <P {...props} data-cy={props.dataCy}>
          <CustomText />
        </P>
      );
  }
};

export const ColoredText = styled(Text)<{ color: AbsoluteThemeColorType }>`
  color: ${({ color }) => color};
`;

// do we want to handle also array?
const toLowercase = (text: ReactNode) => {
  if (typeof text === 'string') {
    return text.toLowerCase();
  } else {
    return text;
  }
};

export const Lowercase = ({ children }: { children: ReactNode }) => {
  return <>{toLowercase(children)}</>;
};

// Transforms only pure strings, multiple elements needs to be converted separately
export const SentenceCase = ({ children }: { children: string | ReactNode }) => {
  if (!children || children !== 'string') {
    return <>{sentenceCase(children as string)}</>;
  }
  return <>{children}</>;
};

export const capitalize = (text: string) =>
  text && text.length > 0 ? text.charAt(0).toUpperCase() + text.slice(1) : text;

export const sentenceCase = (text: string) =>
  text && text.length > 0 ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : text;

export const Label = styled.div<{
  width?: number;
  height?: number;
}>`
  display: inline-block;
  height: ${({ height }) => (height ? height + 'px' : 'auto')};
  width: ${({ width }) => (width ?? 200) + 'px'};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: auto 0 auto 0;
`;
