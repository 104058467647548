import React from 'react';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { Container, Flex, Image, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Country } from 'utils/i18n/Country';

export const TermsAndConditionsAT = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>NUTZUNGSBEDINGUNGEN</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Die vorliegenden Allgemeinen Nutzungsbedingungen (nachfolgend „<strong>ANB</strong>“) regeln den Zugang zu
            und die Nutzung des Dienstes RPARTSTORE, der verfügbar gemacht wird von Renault, SAS mit einem Kapital von
            533.941.113 €, mit Sitz in 122-122 bis avenue du Général Leclerc – 92100 Boulogne-Billancourt – Frankreich,
            eingetragen im Handelsregister von Nanterre unter der Nummer 780 129 987 (im Folgenden „
            <strong>RENAULT</strong>“).
          </Paragraph>
          <Paragraph>
            BEVOR NUTZER AUF DIE DIENSTE ZUGREIFEN, MÜSSEN SIE DIESE NUTZUNGSBEDINGUNGEN SORGFÄLTIG LESEN UND
            AKZEPTIEREN.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 1'} title={'Bezeichnung der Plattform'} />
          <Paragraph>
            Die RPARTSTORE-Website (nachfolgend die „<strong>Plattform</strong>“ genannt) wurde eingerichtet, um den
            Kontakt zwischen Verkäufern (R1-Primärnetz) (nachfolgend die „<strong>Verkäufer</strong>“ genannt), die
            Teile und Zubehör (nachfolgend die „<strong>Produkte</strong>“ genannt) zum Verkauf über eine Website
            anbieten möchten, und Käufern (R2-Sekundärnetz/R3-freie Werkstätten) (nachfolgend die „
            <strong>Käufer</strong>“ genannt) zu erleichtern, in Übereinstimmung mit diesen allgemeinen
            Nutzungsbedingungen (nachfolgend „<strong>ANB</strong>“).
          </Paragraph>
          <Paragraph>
            Die Dienste der Plattform können unter folgender Url abgerufen werden:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 2'} title={'Hosting-Qualität'} />
          <Paragraph>
            Die Plattform fungiert als technischer Vermittler. Sie bietet Verkäufern und Käufern (gemeinsam die „
            <strong>Nutzer</strong>“) technische Datenspeicherungs- und -transportdienste im Sinne der Vorschriften an.
          </Paragraph>
          <Paragraph>
            Die Rolle der Plattform ist eine rein technische und beschränkt sich auf die Herstellung des Kontakts
            zwischen Verkäufern und Käufern.
          </Paragraph>
          <Paragraph>
            RENAULT übernimmt keine Gewährleistung oder Haftung für Vereinbarungen, Austausche, Angebote oder
            verschiedenste Arten von Kommunikation, die über die Plattform abgewickelt werden können.
          </Paragraph>
          <Paragraph>
            Weder RENAULT noch die Plattform ist Verkäufer oder Wiederverkäufer der auf der Plattform durch Verkäufer
            angebotenen Produkte.
          </Paragraph>
          <Paragraph>
            Transaktionen in Bezug auf die von Verkäufern angebotenen Produkte werden immer direkt zwischen Verkäufern
            und Käufern außerhalb der Plattform abgeschlossen. Der Verkäufer kann über die Plattform lediglich
            Geschäftskontakte generieren (<i>Lead-Formular</i>). Der finale Preis der Produkte wird zwischen Verkäufer
            und Käufer außerhalb der Plattform vereinbart.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 3'} title={'Zweck der ANB'} />
          <Paragraph>
            Zweck dieser ANB ist die Festlegung der Bedingungen für den Zugang und die Nutzung der Plattform durch die
            Nutzer.
          </Paragraph>
          <Paragraph>
            Der Zweck der Plattform ist es, eine direkte digitale Geschäftsbeziehung zwischen Verkäufern und Käufern zu
            schaffen, um Geschäftskontakte zwischen ihnen herzustellen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 4'} title={'Bedingungen für den Zugang zur Plattform für Käufer'} />
          <Paragraph>
            Der Zugang zur Plattform impliziert:
            <Ul>
              <Li>
                <Text type={'text'}>
                  Verkäufer fordern Käufer dazu auf, über die Mitgliedschafts-Minisite beizutreten
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Verkäufer validieren den Zugang von Käufern zur Plattform</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Die Mitgliedschafts-Minisite ist eine E-Business-Anwendung, auf der Verkäufer für ihre Käufer (Kunden) die
            RPARTSTORE-Mitgliedschaft beantragen können. Die Anwendung ist über RENAULTNET und RPARTSTORE zugänglich.
          </Paragraph>
          <Paragraph>
            Das Callcenter, welches unter der Nummer 0820 87 44 40 erreichbar ist, kann dem beantragenden Verkäufer
            Zugriff auf die Mitgliedschafts-Minisite gewähren, sofern der Verkäufer individuell im Renault-Netz
            referenziert ist.
          </Paragraph>
          <Paragraph>
            Die Mitgliedschafts-Minisite beschleunigt den Mitgliedschaftsprozess und ermöglicht es Verkäufern außerdem,
            Mitgliedschaftsanträge für ihre Käufer (Kunden) zu stellen, und zwar unabhängig von der Art des Nutzers in
            TRESOR bzw. RPARTSTORE (Kunde unbekannt in TRESOR, neue Mitgliedschaft, neuer Nutzer).
          </Paragraph>
          <Paragraph>
            Der Verkäufer kann für seine Kunden Mitgliedschaftsanträge gemäß den Bestimmungen in Artikel 4 (siehe oben)
            stellen. Wenn der Kunde in der TRESOR-Datenbank nicht identifiziert ist, wird automatisch ein Antrag auf
            Erstellung/Änderung an den zuständigen TRESOR-Administrator gesendet.
          </Paragraph>
          <Paragraph>
            Der Verkäufer hat die Möglichkeit, die Daten des Kunden zu ändern und den Bearbeitungsstand des
            Mitgliedsantrags zu verfolgen. Er kann einen offenen Antrag nicht ändern, aber er kann beantragen, dass ein
            Antrag geschlossen wird. Das Callcenter kümmert sich dann um die endgültige Bearbeitung.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 5'} title={'Auf der Plattform zugelassene Produkte'} />
          <Paragraph>
            Die Plattform wählt die Arten von Produkten aus, die über sie bestellt werden können. Käufer dürfen die
            Verkäufer nicht dazu auffordern, über die Plattform andere als die zuvor von der Plattform ausgewählten
            Produkte anzubieten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 6'} title={'Technologie für die Anzeige der Rangfolge'} />
          <Paragraph>
            Die Anzeige der Angebote unterliegt keinen vorgegebenen Rangfolgekriterien. Der Käufer kann nur eine
            Sortierung nach auf- oder absteigender Reihenfolge auswählen, welche ggf. die Anzeige der Rangfolge
            bestimmt.
          </Paragraph>
          <Paragraph>
            Hinsichtlich der Anzeige des Angebots kann RENAULT eine Standard-Rangfolge festlegen, die auf einer der
            folgenden Optionen basiert:
          </Paragraph>
          <Ul>
            <Li>
              Alter
              <Ul type={'circle'}>
                <Li>
                  <Text type={'text'}>absteigend</Text>
                </Li>
                <Li>
                  <Text type={'text'}>aufsteigend</Text>
                </Li>
              </Ul>
            </Li>
            <Li>Preis: aufsteigend</Li>
            <Li>
              Relevanz:
              <Ul type={'circle'}>
                <Li>
                  <Text type={'text'}>zufällig</Text>
                </Li>
                <Li>
                  <Text type={'text'}>Marken</Text>
                </Li>
                <Li>
                  <Text type={'text'}>Technische Eigenschaften</Text>
                </Li>
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            RENAULT behält sich das Recht vor, die ausgewählte Klassifizierungsoption jederzeit zu ändern. Der Käufer
            kann diese Standard-Klassifizierung mithilfe einer der folgenden Optionen ändern:
            <Ul>
              <Li>Preis aufsteigend</Li>
              <Li>Preis absteigend</Li>
              <Li>Alter aufsteigend: vom neuesten zum ältesten</Li>
              <Li>Alter absteigend: vom ältesten zum neuesten</Li>
              <Li>Lieferzeit aufsteigend</Li>
              <Li>Nähe (sichtbar, wenn Kunden nach Standort suchen)</Li>
              <Li>Relevanz:</Li>
              <Ul type={'circle'}>
                <Li>
                  <Text type={'text'}>zufällig</Text>
                </Li>
                <Li>
                  <Text type={'text'}>Marken</Text>
                </Li>
              </Ul>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Artikel 7'}
            title={'Schaffung eines Käuferraums – Vertraulichkeit von Kennungen und Passwörtern'}
          />
          <Text type={'text'} decoration={'underline'}>
            Die Etappen:
          </Text>
          <Ul type={'decimal'}>
            <Li>
              <Text type={'text'}>
                Erhalt einer E-Mail des Verkäufers, in der bestätigt wird, dass der Antrag berücksichtigt wurde. In der
                E-Mail wird auch die Bearbeitungszeit für den Antrag angegeben, die zwischen 24 und 72 Stunden liegen
                kann.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Der Käufer erhält dann per E-Mail die notwendigen Informationen (ID/Passwort), um sich mit RPARTSTORE zu
                verbinden.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Außerdem erhält der Verkäufer eine Nachricht, dass der Käufer nun Zugang zu RPARTSTORE hat.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Im Falle einer technischen Störung der Anfrage wird der Verkäufer per E-Mail benachrichtigt.
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            Die Nutzung des Käuferbereichs ist streng auf den vom Verkäufer benannten Käufer beschränkt.
          </Paragraph>
          <Paragraph>
            Der Käufer verpflichtet sich, seine Codes geheim zu halten und sie in keiner Form an weitere Personen
            weiterzugeben, sowie sie im Falle einer betrügerischen oder unbefugten Nutzung zu stornieren und den
            Sachverhalt unverzüglich dem Callcenter unter 0820 87 44 40 zu melden. Der Käufer wird darauf hingewiesen,
            dass jede Weitergabe seiner Codes an Dritte in seiner alleinigen Verantwortung liegt.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 8'} title={'Personalisierte Beziehung'} />
          <Paragraph>Käufer können nicht alle auf der Plattform zugelassenen Verkäufer kontaktieren.</Paragraph>
          <Paragraph>
            Auf der Plattform findet der Käufer den Verkäufer, der dem üblichen physischen Kanal entspricht; die
            Verbindung des Käufers ändert sich nicht.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 9'} title={'Zahlung'} />
          <Paragraph>
            Über die Plattform können nur geschäftliche Kontakte geknüpft werden. Über die Plattform können keine
            Zahlungen geleistet werden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 10'} title={'Verfügbarkeit der Plattform-Services'} />
          <Paragraph>
            Soweit gesetzlich zulässig lehnt RENAULT jede Verantwortung für die Nutzung der Plattform durch den Nutzer
            ab und übernimmt weder ausdrücklich noch stillschweigend eine Garantie oder Zusage für die Geschwindigkeit
            und/oder die Leistung der Plattform oder für die Nutzung der über die Plattform zugänglichen Daten und/oder
            Informationen durch den Käufer.
          </Paragraph>
          <Paragraph>
            RENAULT leistet insbesondere nicht Gewähr, dass die Plattform frei von Fehlern, Mängeln oder Auslassungen
            ist oder dass sie keinen Schäden oder Angriffen, insbesondere Viren, ausgesetzt ist, die zu
            Funktionsstörungen, Serviceunterbrechungen, Ausfällen oder Daten- oder Informationsverlusten führen können.
          </Paragraph>
          <Paragraph>
            Der Zugang zur Plattform kann aus technischen Gründen vorübergehend und von Zeit zu Zeit unterbrochen
            werden. Die technischen Teams sind bemüht, etwaige Probleme so schnell wie möglich zu beheben.
          </Paragraph>
          <Paragraph align={'start'}>
            RENAULT wird von jeglicher Verantwortung entbunden:
            <Ul>
              <Li>
                <Text type={'text'}>Im Falle der Nichteinhaltung der Bestimmungen dieser ANB durch einen Nutzer;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Im Falle einer Funktionsstörung der Plattform, die entweder auf eine freiwillige oder unfreiwillige
                  Ursache, die dem Nutzer oder einem Dritten zuzurechnen ist, oder auf eine böswillige Handlung oder auf
                  eine Funktionsstörung der Software, der Smartphones, der Tablet-PCs oder anderer
                  Schnittstellen/Produkte des Nutzers zurückzuführen ist;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Im Falle von Gesetzes- oder Regulierungsänderungen, die die Plattform betreffen.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Darüber hinaus erkennt der Nutzer an, dass die Plattform über Kommunikationsnetze bereitgestellt werden kann
            und dass RENAULT unter keinen Umständen für eine Störung dieser Kommunikationsnetze haftbar gemacht werden
            kann.
          </Paragraph>
          <Paragraph>
            Wenn Nutzer die Plattform nutzen, verpflichten sie sich, dies in Übereinstimmung mit diesen ANB und unter
            Einhaltung der geltenden Vorschriften zu tun.
          </Paragraph>
          <Paragraph>
            Käufer und Verkäufer tragen die volle Verantwortung für die Nutzung der Plattform und für die Verwendung der
            über die Plattform erhaltenen Daten/Informationen, auch wenn sie nicht selbst die Person sind, die diese
            Daten/Informationen verwendet.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 11'} title={'Nicht schädliche Verwendung der Plattform'} />
          <Paragraph>
            Die Nutzer müssen sich unter allen Umständen jeglicher Aktivitäten enthalten, die die Tätigkeit der
            Plattform beeinträchtigen und ihrem Ruf, dem Ruf der von ihr erbrachten Dienstleistung sowie dem Ruf und den
            Aktivitäten der Renault-Gruppe schaden könnten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 12'} title={'Intuitu personae'} />
          <Paragraph>
            Die von der Renault-Gruppe eingerichtete Plattform trägt zum Image, zum Ruf und zum Bekanntheitsgrad der
            Renault-Gruppe bei. Die Beziehung zwischen der Plattform und ihren Nutzern basiert daher auf „intuitu
            personae“.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 13'} title={'Abmeldung, Sperrung oder Ausschluss von der Plattform'} />
          <Text type={'text_bold'}>a) Rücktritt</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Jeder Käufer kann die Plattform jederzeit über seinen Verkäufer (R1) informieren, dass er die
            Plattformdienste nicht mehr nutzen möchte.
          </Paragraph>
          <Paragraph>
            Der Käufer muss weiterhin die Verpflichtungen erfüllen, die er mit den Verkäufern vor der Beendigung seiner
            Teilnahme an der Plattform, eingegangen ist.
          </Paragraph>
          <Text type={'text_bold'}>b) Sperrung</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Die Plattform kann – vorbehaltlich einer vorherigen Mitteilung oder einer Mitteilung, die spätestens am Tag
            der Durchführung der Beschränkungsmaßnahme erfolgt – alle geeigneten und verhältnismäßigen Maßnahmen
            ergreifen, die sie für erforderlich hält, nämlich den Zugang zu beschränken oder die Informationen/Elemente,
            die der Händler online gestellt hat, ganz oder teilweise herabzustufen oder auszusetzen (nachstehend
            „Beschränkungsmaßnahmen“ genannt). Beschränkungsmaßnahmen können aus folgenden Gründen beschlossen werden:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  ernsthafte Vermutung eines missbräuchlichen Verhaltens, das die Plattform, die vorliegenden ANB, das
                  geltende Recht oder das Image und den Ruf der Renault-Gruppe schädigt oder verletzt;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Nutzung des Käuferbereichs durch andere Personen als den Käufer;</Text>
              </Li>
              <Li>
                <Text type={'text'}>Verletzung oder Nichteinhaltung der Vertraulichkeitsverpflichtung;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Kenntnisnahme über das Vorhandensein illegaler oder vermeintlich illegaler Inhalte, insbesondere nach
                  Erhalt einer Mitteilung oder einer von einer zuständigen Behörde erlassenen Anordnung, gegen illegale
                  Inhalte vorzugehen.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Die Beschränkungsmaßnahme wird für einen Zeitraum ergriffen, der in einem angemessenen Verhältnis zu dem
            beanstandeten Sachverhalt steht, jedoch einen Zeitraum von sechs (6) Monaten nicht überschreiten wird.
          </Paragraph>
          <Paragraph>
            Die getroffene Beschränkungsmaßnahme kann über das interne Beschwerdesystem angefochten werden. Wenn der
            Käufer nicht in der Lage ist, die beanstandeten Tatsachen zu beheben, kann die Plattform den Käufer unter
            den nachstehend genannten Bedingungen dauerhaft ausschließen.
          </Paragraph>
          <Text type={'text_bold'}>c) Ausschluss</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Die Plattform kann einen Käufer in folgenden Fällen dauerhaft ausschließen:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  Bei Verlassen des zur Plattform zugelassenen Renault-Netzwerks aus welchem Grund immer;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Verhaltensweisen im Sinne von Buchstabe b, Punkt 1) dieses Artikels. Die Plattform hat die Wahl
                  zwischen Sperrung und sofortigem Ausschluss;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Täuschung, unabhängig vom Schweregrad, da Täuschung einen Vertrauensbruch darstellt;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Wiederholung der unter Buchstabe b, Punkte 2) bis 4) genannten Verhaltensweisen;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Wiederholte Beschwerden durch Verkäufer;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Jegliche Änderung in der persönlichen Situation des Käufers, die sich auf die „intuitu personae“
                  auswirken kann (insbesondere: direkter oder indirekter Kontrollwechsel, wesentliche Änderung der
                  Produktion oder der Zertifizierungen, Insolvenzverfahren).
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 14'} title={'Geschäftsverkehr – Geschäftsgeheimnis'} />
          <Paragraph>
            Die Plattform darf in keiner Weise Dritten (Konkurrenten, Mitarbeitern, Partnern usw.) Einblick in den
            Austausch, die Verhandlungen, Verträge und Vereinbarungen gewähren, die über die Plattform durchgeführt oder
            abgeschlossen werden. Die Plattform wird zu jeder Zeit ihr Vertraulichkeitsprogramm einhalten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 15'} title={'Werbeaktionen und Werbung'} />
          <Paragraph>
            Der Käufer verpflichtet sich, die Plattform weder direkt noch indirekt zu nutzen, um direkt oder indirekt,
            in jeglicher Form, für seine Produkte oder Dienstleistungen oder die einer anderen Person zu werben.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 16'} title={'Konkurrierende Angebote – keine Exklusivität'} />
          <Paragraph>
            Die Mitgliedschaft auf der Plattform verpflichtet die Käufer nicht zur Nutzung der Plattform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 17'} title={'Modifizierung der ANB'} />
          <Paragraph>
            RENAULT behält sich das Recht vor, die vorliegenden ANB zu ändern. Jede Änderung tritt an dem Datum in
            Kraft, das in der entsprechenden Mitteilung an die Nutzer angegeben ist.
          </Paragraph>
          <Paragraph>
            Die Änderungen gelten nicht für Transaktionen, die zum Zeitpunkt ihres Inkrafttretens bereits laufen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Artikel 18'}
            title={'Verwendung der von der Plattform erhobenen personenbezogenen Daten'}
          />
          <Paragraph>
            Im Zusammenhang mit der Nutzung der Plattform werden personenbezogenen Daten der Nutzer von RENAULT gemäß
            der Datenschutzrichtlinie verarbeitet, die über den folgenden Link zugänglich ist:{' '}
            <Link to={ROUTER_PERSONAL_DATA(Country.AT.key)} newTab>
              https://rpartstore.renault.com/personal-data/at
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 19'} title={'Höhere Gewalt'} />
          <Paragraph>
            Weder RENAULT noch die Plattform können für Ereignisse höherer Gewalt haftbar gemacht werden, die zu einer
            Störung der Plattform führen.
          </Paragraph>
          <Paragraph>
            Der Nutzer erklärt sich ferner damit einverstanden, dass zu den vertraglichen Merkmalen höherer Gewalt
            insbesondere Stromausfälle, Ausfälle des Internets, Fehlfunktionen von Computern (Bugs, Viren, Malware,
            Hacking) gehören, auch wenn ihr technischer Ursprung in der Konzeption des Informationsdienstes liegt.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 20'} title={'Vertraulichkeit und Beweismittel'} />
          <Paragraph>
            Die Plattform setzt geeignete technische Mittel ein, um die Vertraulichkeit der über sie übermittelten
            Informationen und Daten oder der über sie geführten Verhandlungen zu gewährleisten, so dass sie nicht an
            Personen außerhalb ihrer Dienste übermittelt werden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 21'} title={'Geistiges Eigentum'} />
          <Paragraph>
            Alle Elemente, aus denen diese Plattform besteht (z. B. Texte, Logos, Bilder, Grafik- oder Tonelemente,
            Software, Layout, Datenbanken, Source Code usw.), sowie Firmennamen, Marken, Designs und
            unterscheidungskräftige Zeichen (nachstehend „<strong>Content</strong>“) sind durch geistige Eigentumsrechte
            geschützt. Der Content ist alleiniges Eigentum von RENAULT und/oder Dritten, die dessen Nutzung zugestimmt
            haben.
          </Paragraph>
          <Paragraph>
            Der Nutzer erhält eine einfache, beschränkte, nicht exklusive, nicht übertragbare, nicht unterlizenzierbare,
            widerrufliche Lizenz zur Nutzung des Contents wie in diesen ANB beschrieben. Jegliche andere Nutzung des
            Contents in Teilen oder zur Gänze, insbesondere die Reproduktion, Darstellung, Anpassung, Veränderung,
            Dekompilierung durch ein beliebiges Verfahren oder auf einem beliebigen Medium, einschließlich der
            Zusammenführung mit anderen Computerprogrammen, ist ohne die vorherige schriftliche Zustimmung durch RENAULT
            strengstens untersagt und stellt einen Verstoß gegen diese ANB dar.
          </Paragraph>
          <Paragraph>
            Der Nutzer verpflichtet sich, RENAULT so schnell wie möglich zu informieren, wenn er Kenntnis von einer
            Handlung hat, die gegen die Bestimmungen dieses Artikels verstößt.
          </Paragraph>
          <Paragraph>
            Im Rahmen der Nutzung der Plattform ist der Nutzer berechtigt, die Marken und geistigen Eigentumsrechte,
            deren Inhaber er ist oder die er rechtmäßig erworben hat, sowie seinen Handelsnamen oder seine gesetzliche
            Bezeichnung zu verwenden. Der Nutzer garantiert, dass er über die Rechte verfügt, die es RENAULT erlauben,
            die vom Nutzer bereitgestellten Elemente auf der Plattform wiederzugeben. In diesem Zusammenhang sichert der
            Nutzer RENAULT gegen alle Ansprüche, Forderungen, Klagen oder Rechtsmittel ab, die von Dritten im
            Zusammenhang mit der Nutzung dieser Elemente erhoben werden, und trägt alle Kosten und Schäden, die sich
            daraus ergeben können, insbesondere infolge einer gerichtlichen Entscheidung – auch wenn diese noch nicht
            rechtskräftig ist – oder aus einem Vergleich.
          </Paragraph>
          <Paragraph>
            Der Nutzer ist berechtigt, auf seinen Geschäftspapieren seinen Status als von der Plattform referenzierter
            Nutzer anzugeben.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 22'} title={'Haftung'} />
          <Paragraph>Die Verkäufer sind allein für die Nutzung ihres Verkäuferbereichs verantwortlich.</Paragraph>
          <Paragraph>
            Die Käufer sind allein für die Bestellung der auf der Plattform zum Verkauf angebotenen Produkte und
            Zubehörteile verantwortlich. Sie sind auch allein verantwortlich für die Nutzung ihres Käuferbereichs und
            die Informationen, die sie den Verkäufern geben.
          </Paragraph>
          <Paragraph>
            Im Allgemeinen haften ausschließlich Nutzer als solche und die Plattform kann nicht als Nutzer haftbar
            gemacht werden.
          </Paragraph>
          <Paragraph>
            Für den Fall, dass ein Verkäufer oder eine andere Person wegen Tatsachen, die mit der Tätigkeit des Käufers
            zusammenhängen, gerichtlich gegen die Plattform vorgeht, verpflichtet sich der Verkäufer, die Plattform von
            jeglicher Verurteilung freizustellen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 23'} title={'Reklamationen – Benachrichtigung'} />
          <Paragraph type={'text_bold'}>
            <i>a) Internes Beschwerdesystem</i>
          </Paragraph>
          <Paragraph align={'start'}>
            Die Plattform hat ein internes Beschwerdesystem zu folgenden Themen eingerichtet:
            <Ul>
              <Li>
                Mutmaßliches Versagen der Plattform im Zusammenhang mit der Bereitstellung von
                Online-Vermittlungsdiensten, von dem der beschwerdeführende Nutzer betroffen ist
              </Li>
              <Li>
                Technologische Probleme, die in direktem Zusammenhang mit der Erbringung von Vermittlungsdiensten stehen
                und von denen der beschwerdeführende Nutzer betroffen ist
              </Li>
              <Li>
                Von der Plattform ergriffene Beschränkungsmaßnahmen oder Verhalten der Plattform in direktem
                Zusammenhang mit der Bereitstellung von Online-Vermittlungsdiensten, von denen der beschwerdeführende
                Nutzer betroffen ist
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Bei Beschränkungsmaßnahmen, die von der Plattform nach einer Benachrichtigung ergriffen werden oder mit der
            Begründung, dass die vom Nutzer zur Verfügung gestellten Informationen/Elemente als rechtswidrig oder
            unvereinbar mit den ANB angesehen werden, kann der Nutzer innerhalb von 6 Monaten nach Benachrichtigung über
            die entsprechende Maßnahme eine Beschwerde einreichen.
          </Paragraph>
          <Paragraph>
            Die Plattform verpflichtet sich, Beschwerden innerhalb eines Zeitrahmens zu bearbeiten, der der Komplexität
            und der Bedeutung der entsprechenden Thematik Rechnung trägt, und dem beschwerdeführenden Nutzer eine
            Antwort zu übermitteln.
          </Paragraph>
          <Paragraph>
            Gegen Entscheidungen, die im Rahmen des internen Beschwerdesystems der Plattform getroffen werden, können
            außergerichtliche Rechtsmittel gemäß Artikel 24 oder gerichtliche Rechtsbehelfe erhoben werden.
          </Paragraph>
          <Paragraph type={'text_bold'}>
            <i>b) Benachrichtigung</i>
          </Paragraph>
          <Paragraph>
            <Text type={'text'}>
              Der Nutzer kann begründete Meldungen auf elektronischem Wege machen, um auf das Vorhandensein von Inhalten
              auf der Plattform hinzuweisen, die er als rechtswidrig erachtet. Diese Benachrichtigungen werden von der
              Plattform überprüft. Dabei behält sich die Plattform das Recht vor, zu entscheiden, ob sie die
              Einschätzung des Nutzers teilt, und entsprechende Beschränkungsmaßnahmen zu ergreifen. Die von der
              Plattform getroffene Entscheidung wird dem Beschwerdeführer mitgeteilt, sofern er/sie sich entsprechend
              identifiziert hat. Die Entscheidung kann insbesondere über das interne Beschwerdesystem angefochten
              werden. Wenn die Plattform aus technischen oder betrieblichen Gründen nicht in der Lage ist, unmittelbar
              tätig zu werden, wird sie den Beschwerdeführer entsprechend informieren.
            </Text>
          </Paragraph>
          <Paragraph>
            Eine zentrale Anlaufstelle steht unter folgender Adresse zur Verfügung:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 24'} title={'Mediation'} />
          <Paragraph type={'text_bold'}>
            <i>a) Streitigkeiten zwischen Nutzern – Bereitstellung eines Mediators</i>
          </Paragraph>
          <Paragraph>
            Die Plattform benennt auf ihrer Website einen unabhängigen Mediator, dem die Nutzer in gegenseitigem
            Einvernehmen ihre Streitigkeit vorlegen können. Diese Befassung führt zur Aussetzung der Verpflichtungen zur
            Folge, die sich aus den ANB, den Allgemeinen Verkaufsbedingungen für Teile und Zubehör oder aus anderen
            zwischen den Nutzern geschlossenen Verträgen ergeben. Der Mediator ist verpflichtet, innerhalb eines Monats
            nach seiner Befassung eine Entscheidung zu treffen, wobei er die Möglichkeit hat, diese Frist um einen Monat
            zu verlängern. Mit Zustimmung der Parteien kann auch eine längere Frist vereinbart werden.
          </Paragraph>
          <Paragraph type={'text_bold'}>
            <i>b) Streitigkeiten im Zusammenhang mit den von der Plattform erbrachten Dienstleistungen</i>
          </Paragraph>
          <Paragraph>
            Der unter a) genannte Mediator kann auch von jedem Nutzer der Plattform unter den oben genannten Bedingungen
            kontaktiert werden. Die Plattform erklärt sich im Voraus bereit, an einem Mediationsverfahren teilzunehmen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 25'} title={'Anwendbares Recht – Rechtsstreitigkeiten'} />
          <Paragraph>Diese ANB unterliegen dem österreichischen Recht.</Paragraph>
          <Paragraph>
            Im Falle von Meinungsverschiedenheiten zwischen den Parteien werden die Parteien zunächst versuchen, eine
            gütliche Einigung herbeizuführen. Wenn das nicht gelingt, kann gerichtliche Hilfe in Anspruch genommen
            werden. Für sämtliche Streitigkeiten aus und im Zusammenhang diesen ANB wird die ausschließliche
            Zuständigkeit des für den ersten Wiener Gemeindebezirk sachlich zuständigen Gerichts vereinbart.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Version aktualisiert: Mai 2024</Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'} decoration={'underline'}>
          ANLAGE I
        </Text>
        <MarginBox mt={15} />
        <Text type={'h5_bold'} decoration={'underline'}>
          INFORMATIONEN FÜR KÄUFER – ZUGANGSVERFAHREN
        </Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-AT.png'} alt={'information-diagram-austria'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
