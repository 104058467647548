import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { DashboardPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import { Ban, CopyIcon, PencilAltIcon, TrashAltIcon } from 'assets/icons';
import { resetPublishedPromotionStatus } from 'domains/promotion/Promotion.store';
import { getUserRights, UserRole } from 'domains/user';
import AdjustPromotionDialog from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/AdjustPromotionDialog';
import DeletePromotionDialog from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/DeletePromotionDialog';
import {
  SLine,
  SListItem,
  SPromotionDetailMenu,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/PromotionDetailMenu.styled';
import StopPublicationDialog from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/StopPublicationDialog';
import { COPY, EDIT, PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { theme, ThemeColorKeyType } from 'styles';
import { Flex, Icon, IconType, MarginBox, Text } from 'UI';
import { getCondArrayItem } from 'utils';
import { getDateFromString } from 'utils/date';

export type DialogHandle = {
  handleDialogOpen: () => void;
};

interface PromotionDetailsMenuProps {
  promotion: DashboardPromotion;
  setPromotionDetailMenuVisible: (visible: boolean) => void;
  statusType: StatusType;
}

interface ListItem {
  icon: IconType;
  label: string;
  hoverColor?: ThemeColorKeyType;
  additionalAction?: () => void;
}

interface LinkSectionProps {
  setPromotionDetailMenuVisible: (visible: boolean) => void;
  setStopPublicationDialogVisible: (visible: boolean) => void;
  setDeletePromotionDialogVisible: (visible: boolean) => void;
  setAdjustPromotionDialogVisible: (visible: boolean) => void;
  statusType: StatusType;
  promotion: DashboardPromotion;
}

interface HeaderProps {
  promotionTitle: string;
  postedBy: string;
  promotionCreationDate: string;
}

const Header = ({ promotionTitle, postedBy, promotionCreationDate }: HeaderProps) => {
  const { t } = useTranslation();
  return (
    <MarginBox mt={15} ml={15}>
      <Flex direction={'column'}>
        <Text type={'h5_bold'} ellipsis>
          {promotionTitle}
        </Text>
        <Flex direction={'row'}>
          <Text type={'text'}>
            {`${t('promotion.detail_menu.posted_by', 'Posted By ')} ${postedBy} ${getDateFromString(
              promotionCreationDate,
            )}`}
          </Text>
        </Flex>
      </Flex>
    </MarginBox>
  );
};

const enum Status {
  SAVED = 'SAVED',
  PLANNED = 'PLANNED',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

const ListSection = ({
  setPromotionDetailMenuVisible,
  statusType,
  setStopPublicationDialogVisible,
  setDeletePromotionDialogVisible,
  setAdjustPromotionDialogVisible,
  promotion,
}: LinkSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userRights = useSelector(getUserRights);

  function getListItems(status: StatusType): ListItem[] {
    const showAdjustPromotionItem =
      status === Status.PLANNED &&
      userRights.includes(UserRole.R1) &&
      promotion.promotionType === 'DISCOUNT' &&
      !!(promotion.additionalDiscount && promotion.additionalDiscount > 0);

    return [
      ...getCondArrayItem(
        (status === Status.SAVED || status === Status.ARCHIVED) && {
          icon: CopyIcon,
          label: t('promotion.detail_menu.make_copy', 'Make Copy'),
          additionalAction: () => {
            history.push(`${ROUTER_BACKOFFICE}/${PROMOTION}/${COPY}/${promotion.promotionId}`);
          },
        },
      ),
      ...getCondArrayItem(
        status !== Status.ARCHIVED &&
          promotion.editable && {
            icon: PencilAltIcon,
            label: t('common.action.edit', 'Edit'),
            additionalAction: () => {
              dispatch(resetPublishedPromotionStatus({ id: promotion.promotionId }));
              history.push(`${ROUTER_BACKOFFICE}/${PROMOTION}/${EDIT}/${promotion.promotionId}`);
            },
          },
      ),
      ...getCondArrayItem(
        showAdjustPromotionItem && {
          icon: PencilAltIcon,
          label: t('promotion.detail_menu.adjust_promotion', 'Adjust promotion'),
          additionalAction: () => {
            setAdjustPromotionDialogVisible(true);
          },
        },
      ),
      ...getCondArrayItem(
        (status === Status.PLANNED || status === Status.SAVED) &&
          promotion.editable && {
            icon: TrashAltIcon,
            label: t('promotion.detail_menu.delete', 'Delete'),
            hoverColor: theme.color.error,
            additionalAction: () => {
              setDeletePromotionDialogVisible(true);
            },
          },
      ),
      ...getCondArrayItem(
        status === 'PUBLISHED' &&
          promotion.editable && {
            icon: Ban,
            label: t('backoffice.stop_publication', 'Stop publication'),
            hoverColor: theme.color.error,
            additionalAction: () => {
              setStopPublicationDialogVisible(true);
            },
          },
      ),
    ];
  }

  return (
    <Flex direction={'column'}>
      {getListItems(statusType).map((menuItem) => {
        return (
          <SListItem
            minHeight={50}
            align={'center'}
            hoverColor={menuItem.hoverColor}
            key={menuItem.label}
            onClick={() => {
              menuItem?.additionalAction?.();
              setPromotionDetailMenuVisible(false);
            }}
          >
            <MarginBox ml={21} />
            <Icon IconComponent={menuItem.icon} size={20} display={'inline'} color={theme.color.grey00} />
            <MarginBox ml={16} />
            <Text type={'section'} cursor={'pointer'} disableGutter>
              {menuItem.label}
            </Text>
          </SListItem>
        );
      })}
    </Flex>
  );
};

export const PromotionDetailMenu = ({
  setPromotionDetailMenuVisible,
  promotion,
  statusType,
}: PromotionDetailsMenuProps) => {
  const deletePromotionDialogRef = useRef<DialogHandle>(null);
  const stopPublicationDialogRef = useRef<DialogHandle>(null);
  const adjustPromotionDialogRef = useRef<DialogHandle>(null);

  return (
    <>
      <StopPublicationDialog promotion={promotion} ref={stopPublicationDialogRef} />
      <DeletePromotionDialog promotion={promotion} ref={deletePromotionDialogRef} />
      <AdjustPromotionDialog
        promotionId={promotion.promotionId}
        isAdjusted={promotion.isAdjusted}
        ref={adjustPromotionDialogRef}
      />
      <SPromotionDetailMenu direction={'column'} minWidth={280}>
        <Header
          promotionTitle={promotion.title ?? ''}
          postedBy={promotion.createdBy ?? ''}
          promotionCreationDate={promotion.created ?? ''}
        />
        <MarginBox mt={15} />
        <Flex align={'center'} justify={'center'}>
          <SLine />
        </Flex>
        <ListSection
          setPromotionDetailMenuVisible={setPromotionDetailMenuVisible}
          statusType={statusType}
          setStopPublicationDialogVisible={() => stopPublicationDialogRef.current?.handleDialogOpen()}
          setDeletePromotionDialogVisible={() => deletePromotionDialogRef.current?.handleDialogOpen()}
          setAdjustPromotionDialogVisible={() => adjustPromotionDialogRef.current?.handleDialogOpen()}
          promotion={promotion}
        />
      </SPromotionDetailMenu>
    </>
  );
};
