/* eslint-disable-next-line */
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { getWebLanguage } from 'domains/user';
import { AppTranslation } from 'utils/i18n';

const TranslationKeyFeature = () => {
  const [showTranslationKeys, setShowTranslationKeys] = useState(false);
  const webLang = useSelector(getWebLanguage);
  useHotkeys(
    'ctrl+alt+t,control+⌘+t,control+command+t',
    (event) => {
      event.preventDefault();
      console.info(`Called to ${showTranslationKeys ? 'hide' : 'show'} translation keys`);
      if (!showTranslationKeys) {
        AppTranslation.changeLanguage('cimode');
        setShowTranslationKeys(true);
      } else {
        AppTranslation.changeLanguage(webLang.key);
        setShowTranslationKeys(false);
      }
    },
    [showTranslationKeys],
  );
  return null;
};

export default TranslationKeyFeature;
