import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon } from 'assets/icons';
import { goBack } from 'UI/Button/back';
import { SButton } from 'UI/Button/BackButton.styled';
import { Icon } from 'UI/Icon';
import { Text } from 'UI/Text';
import { useSmall } from 'utils';

export const BackButton = () => {
  const { t } = useTranslation();
  const small = useSmall();

  const handleClick = () => {
    goBack.searchHistory();
  };

  return (
    <SButton direction={'row'} align={'center'} justify={'flex-start'} onClick={handleClick} dataCy={'button-back'}>
      <Icon IconComponent={ArrowLeftIcon} size={14} mr={10} mb={1} />
      {!small && (
        <Text disableGutter type={'h6'} cursor={'pointer'}>
          {t('common.action.back', 'Back')}
        </Text>
      )}
    </SButton>
  );
};
