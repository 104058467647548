import React from 'react';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { Container, Flex, Image, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Country } from 'utils/i18n/Country';

export const TermsAndConditionsRO = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>TERMENI ȘI CONDIȚII DE UTILIZARE</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Acești Termeni și Condiții Generale de Utilizare (denumite în continuare „TCGU”) reglementează accesul și
            utilizarea serviciului RPARTSTORE, pus la dispoziție de către Renault, SAS cu un capital de 533.941.113
            euro, cu sediul social la adresa 122-122 bis avenue du Général Leclerc - 92100 Boulogne-Billancourt,
            înregistrată la Registrul Comerțului din Nanterre sub numărul 780 129 987 prin intermediul Renault
            Commercial Roumanie SRL, cu sediul social în București, sector 6, strada Preciziei nr. 3G, Clădirea A,
            parte, camera AP 10, CUI/CIF RO13943110 (denumită în continuare „RENAULT“).
          </Paragraph>
          <Paragraph>
            UTILIZATORUL TREBUIE SĂ CITEASCĂ CU ATENȚIE ACEȘTI TERMENI ȘI CONDIȚII ÎNAINTE DE A LE ACCEPTA ȘI DE A
            ACCESA SERVICIILE.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 1.'} title={'Denumirea Platformei'} />
          <Paragraph>
            Site-ul web RPARTSTORE (denumit în continuare „<strong>Platforma</strong>”) a fost înființat pentru a
            facilita contactul între vânzători (denumiți în continuare „<strong>Vânzători</strong>”) care doresc să
            ofere piese și accesorii (denumite în continuare „<strong>Produsele</strong>”) comercializate prin
            intermediul unui site web către cumpărători (denumiți în continuare „<strong>Cumpărătorii</strong>”), în
            conformitate cu acești termeni și condiții generale de utilizare (denumite în continuare „
            <strong>TCGU</strong>”).
          </Paragraph>
          <Paragraph>
            Serviciile Platformei pot fi accesate la următoarea adresă:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 2.'} title={'Calitatea găzduirii'} />
          <Paragraph>
            Platforma acționează ca intermediar tehnic. Oferă Vânzătorilor și Cumpărătorilor („Utilizatorii”) servicii
            de stocare și transport a datelor tehnice în conformitate cu reglementările.
          </Paragraph>
          <Paragraph>
            Rolul Platformei este pur tehnic și se limitează la facilitarea contactului dintre Vânzători și Cumpărători.
          </Paragraph>
          <Paragraph>
            Platforma nu își asumă nicio garanție sau răspundere cu privire la orice acorduri, schimburi, oferte sau
            comunicări diverse care ar putea avea loc prin intermediul său.
          </Paragraph>
          <Paragraph>
            Platforma nu este în niciun fel un vânzător sau revânzător al Produselor oferite de Vânzători prin
            intermediul Platformei.
          </Paragraph>
          <Paragraph>
            Tranzacțiile legate de Produsele oferite de Vânzători sunt întotdeauna încheiate direct între Vânzători și
            Cumpărători, în afara Platformei. Vânzătorul poate genera pur și simplu contacte comerciale (
            <i>formular pentru clienți potențiali</i>) prin intermediul Platformei. Prețul Produselor este stabilit de
            Vânzător în afara Platformei.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 3.'} title={'Scopul TCGU'} />
          <Paragraph>
            Scopul acestor termeni și condiții generale de utilizare este de a defini termenii și condițiile de acces și
            utilizare a Platformei de către Utilizatori.
          </Paragraph>
          <Paragraph>
            Scopul Platformei este de a crea o relație digitală directă între Vânzători și Cumpărători pentru a genera
            contacte comerciale între aceștia.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 4.'} title={'Condiții pentru accesul Cumpărătorilor la Platformă'} />
          <Paragraph>
            Accesul la Platformă presupune:
            <Ul>
              <Li>
                <Text type={'text'}>
                  Solicitarea adresată de Vânzători Cumpărătorilor să se alăture prin intermediul mini-site-ului de
                  afiliere
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Validarea de către Vânzători a accesului Cumpărătorilor la Platformă</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Mini-site-ul de afiliere este o aplicație de activități comerciale electronice care le permite Vânzătorilor
            să solicite afilierea clienților lor Cumpărători la RPARTSTORE. Aplicația se poate accesa din RENAULTNET și
            RPARTSTORE.
          </Paragraph>
          <Paragraph>
            Accesul la mini-site-ul de afiliere este acordat de către RENAULT Vânzătorului care îl solicită cu condiția
            ca acesta să fie menționat individual în rețeaua Renault.
          </Paragraph>
          <Paragraph>
            Mini-site-ul de afiliere accelerează procesul de afiliere și permite Vânzătorilor să adreseze cereri de
            afiliere pentru clienții lor, indiferent de natura utilizatorului în TRESOR și RPARTSTORE (client necunoscut
            în TRESOR, afiliere nouă, utilizator nou).
          </Paragraph>
          <Paragraph>
            Vânzătorul poate adresa cereri de afiliere pentru clienții săi în conformitate cu prevederile prezentului
            articol 4. În cazul în care clientul nu este identificat în baza de date TRESOR, se va trimite automat o
            cerere de creare/modificare administratorului TRESOR relevant.
          </Paragraph>
          <Paragraph>
            Vânzătorul poate modifica detaliile clientului și poate monitoriza progresul cererilor de afiliere. Acesta
            nu poate modifica o cerere deschisă, dar poate solicita închiderea acesteia. Centrul de apeluri se va ocupa
            apoi de închiderea finală.
          </Paragraph>
          <SectionHeader title={'Produse admise pe Platformă'} />
          <Paragraph>
            Platforma selectează tipurile de produse care pot fi comandate prin intermediul său, Cumpărătorii nu pot
            solicita sau cere Vânzătorilor să ofere, prin intermediul Platformei, alte produse decât cele selectate
            anterior de către Platformă.
          </Paragraph>
          <SectionHeader title={'Tehnologia de clasificare'} />
          <Paragraph>
            Afișarea ofertelor nu face obiectul niciunui criteriu de clasificare prestabilit. Cumpărătorul poate alege
            doar criteriile de sortare în ordinea crescătoare sau descrescătoare a prețului, stabilind o ordine de
            clasificare, după caz.
          </Paragraph>
          <Paragraph>
            În ceea ce privește afișarea ofertelor, RENAULT poate stabili o ordine de clasificare implicită pe baza
            uneia dintre următoarele opțiuni:
            <Ul>
              <Li>
                Vechime:
                <Ul type={'circle'}>
                  <Li>De la cel mai vechi la cel mai nou</Li>
                  <Li>De la cel mai nou la cel mai vechi</Li>
                </Ul>
              </Li>
              <Li>Preț: Preț în ordine crescătoare</Li>
              <Li>
                Relevanță:
                <Ul type={'circle'}>
                  <Li>Aleatoriu</Li>
                  <Li>Mărci</Li>
                  <Li>Atribute tehnice</Li>
                </Ul>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            RENAULT își rezervă dreptul de a modifica în orice moment opțiunea de clasificare aleasă. Cumpărătorul poate
            modifica această clasificare implicită utilizând una dintre opțiunile de mai jos:
            <Ul>
              <Li>Preț în ordine crescătoare</Li>
              <Li>Preț în ordine descrescătoare</Li>
              <Li>Vechime în ordine crescătoare: de la cel mai nou la cel mai vechi</Li>
              <Li>Vechime în ordine descrescătoare: de la cel mai vechi la cel mai nou</Li>
              <Li>Termen de livrare în ordine crescătoare</Li>
              <Li>Proximitate (opțiune disponibilă când utilizatorii caută după locație)</Li>
              <Li>Relevanță:</Li>
              <Ul type={'circle'}>
                <Li>
                  <Text type={'text'}>Aleatoriu</Text>
                </Li>
                <Li>
                  <Text type={'text'}>Mărci</Text>
                </Li>
              </Ul>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Articolul 5.'}
            title={
              'Crearea unui spațiu pentru Cumpărători – Confidențialitatea elementelor de identificare și a parolelor'
            }
          />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Etapele:
            </Text>
            <Ul type={'decimal'}>
              <Li>
                Primirea unui e-mail de la Vânzător prin care se confirmă că solicitarea s-a luat în considerare.
                E-mailul va indica și timpul de procesare a solicitării, care variază între 24 și 72 de ore.
              </Li>
              <Li>
                Cumpărătorul va primi apoi prin e-mail informațiile necesare (ID/Parola) pentru a se conecta la
                RPARTSTORE.
              </Li>
              <Li>
                Vânzătorul va primi, de asemenea, un mesaj prin care este informat că clientul său poate accesa acum
                RPARTSTORE.
              </Li>
              <Li>În cazul unui incident tehnic legat de solicitare, Vânzătorul va fi informat prin e-mail.</Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Utilizarea Zonei Cumpărătorului este strict limitată la utilizatorul desemnat de Vânzător.
          </Paragraph>
          <Paragraph>
            Cumpărătorul se obligă să păstreze secrete parolele sale și să nu le divulge sub nicio formă și niciunei
            persoane și, în caz de utilizare frauduloasă sau neautorizată, să le anuleze și să raporteze fără.
            Cumpărătorul este informat că este responsabil în mod exclusiv pentru orice divulgare a parolelor sale către
            o terță parte.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 6.'} title={'Relație personalizată'} />
          <Paragraph>
            Cumpărătorii se pot adresa solicitări tuturor Vânzătorilor la care sunt atasati pe Platformă.
          </Paragraph>
          <Paragraph>Pe Platformă, Cumpărătorul va găsi Vânzătorii la care este atasat.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 7.'} title={'Plată'} />
          <Paragraph>
            Se înțelege faptul că numai contactele comerciale pot fi efectuate prin intermediul Platformei și că nu se
            va efectua nicio plată prin intermediul Platformei.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 8.'} title={'Disponibilitatea serviciilor Platformei'} />
          <Paragraph>
            În limitele legilor și reglementărilor aplicabile, RENAULT își declină orice responsabilitate pentru
            utilizarea Platformei de către Utilizator și nu oferă nicio garanție, expresă sau implicită, cu privire la
            viteza și/sau performanța Site-ului sau cu privire la utilizarea de către Cumpărător a datelor și/sau
            informațiilor accesibile prin Platformă.
          </Paragraph>
          <Paragraph>
            În special, RENAULT nu garantează că Platforma nu conține erori, defecte sau omisiuni și nici că nu este
            supusă deteriorării sau atacurilor, în special de către viruși, care ar putea cauza disfuncționalități,
            întreruperi ale furnizării serviciilor, defecțiuni sau pierderi de date sau informații.
          </Paragraph>
          <Paragraph>
            Accesul la Platformă poate fi suspendat temporar și periodic din motive tehnice, pe care echipele tehnice se
            vor strădui să le remedieze cât mai repede posibil.
          </Paragraph>
          <Paragraph align={'start'}>
            RENAULT va fi exonerată de orice responsabilitate:
            <Ul>
              <Li>
                În cazul nerespectării de către Utilizator a prevederilor acestor Termeni și Condiții Generale de
                Utilizare;
              </Li>
              <Li>
                În cazul unei defecțiuni a Platformei care rezultă fie dintr-o cauză voluntară sau involuntară
                imputabilă Utilizatorului sau unei terțe părți, fie dintr-un act rău intenționat, fie din funcționarea
                defectuoasă a software-ului, smartphone-urilor, tabletelor sau oricăror alte interfețe/produse
                aparținând Utilizatorului;
              </Li>
              <Li>În cazul unor modificări legislative sau de reglementare care afectează Platforma.</Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            În plus, Utilizatorul confirmă faptul că înțelege şi acceptă că Platforma poate fi furnizată de rețele de
            comunicații și că RENAULT nu poate fi ținută răspunzătoare în niciun caz pentru defectarea unor astfel de
            rețele de comunicații.
          </Paragraph>
          <Paragraph>
            Atunci când Utilizatorii utilizează Platforma, se angajează să facă acest lucru în conformitate cu acești
            TCGU și în conformitate cu reglementările aplicabile.
          </Paragraph>
          <Paragraph>
            Cumpărătorul și Vânzătorul sunt pe deplin responsabili pentru utilizarea Site-ului și pentru orice
            conformitate şi utilizare a datelor/informațiilor primite prin intermediul Site-ului, chiar și atunci când
            nu utilizează ei aceste date/informații.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 9.'} title={'Utilizarea Platformei fără a provoca prejudicii '} />
          <Paragraph>
            Cumpărătorul trebuie să se abțină de la orice activitate de natură să compromită activitatea Platformei, să
            prejudicieze reputația acesteia, reputația serviciilor pe care le furnizează și reputația și activitățile
            Grupului Renault.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 10.'} title={'Intuitu personae'} />
          <Paragraph>
            Platforma, înființată de Grupul Renault, contribuie la imaginea, reputația și notorietatea Grupului Renault.
            Relația dintre Platformă și Utilizatorii săi se bazează așadar pe un caracter „intuitu personae”.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 11.'} title={'Retragerea, blocarea sau excluderea din Platformă'} />
          <Text type={'text_bold'}>a) Retragere</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Orice Cumpărător poate notifica în orice moment Platforma prin Reparatorul Autorizat la care este atasat cu
            privire la decizia sa de a nu mai utiliza serviciile Platformei.
          </Paragraph>
          <Paragraph>
            Cumpărătorul trebuie să-și îndeplinească în continuare obligațiile sale asumate față de Vânzători înainte de
            data la care încetează participarea sa la Platformă.
          </Paragraph>
          <Text type={'text_bold'}>b) Blocare</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Platforma poate lua toate măsurile adecvate și proporționale pe care le consideră necesare, constând în
            restricționarea accesului, declasarea sau suspendarea (denumite în continuare „Măsurile de restricționare”)
            a tuturor informațiilor/elementelor plasate online de Vanzator sau a unei părți a acestora, sub rezerva
            notificării prealabile sau cel mai târziu în ziua punerii în aplicare a Măsurii de restricționare decise pe
            baza următoarelor motive:
            <Ul type={'decimal'}>
              <Li>
                prezumție de comportament abuziv care aduce prejudicii sau încalcă prevederile platformei, acestor
                termeni și condiții, legilor în vigoare sau imaginii și reputației Grupului Renault;
              </Li>
              <Li>utilizarea Zonei Cumpărătorului de către alte persoane decât Cumpărătorul;</Li>
              <Li>neglijență sau nerespectarea obligației de confidențialitate;</Li>
              <Li>
                cunoașterea de către Platformă a existenței unui conținut ilicit sau presupus ilicit, în special în urma
                primirii unei notificări sau a unui ordin emise/emis de o autoritate competentă de a acționa împotriva
                conținutului ilicit.
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Măsura de restricționare se ia pe o perioadă proporțională cu faptele reclamate, fără a putea depăși o
            perioadă de șase (6) luni.
          </Paragraph>
          <Paragraph>
            Măsura de restricționare luată de Platformă poate fi contestată folosind sistemul intern de reclamații al
            Platformei. În cazul în care Cumpărătorul nu poate remedia faptele reclamate, Platforma îl poate exclude
            definitiv pe Cumpărător în condițiile prezentate mai jos.
          </Paragraph>
          <Text type={'text_bold'}>c) Excludere</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Platforma poate exclude definitiv un Cumpărător în următoarele cazuri:
            <Ul type={'decimal'}>
              <Li>
                rețelei Dacia-Renaultadmise pe Platformă din orice motiv;In cazul in care Vanzatorul la care este atasat
                nu mai face parte din reteaua autorizata post vanzare Dacia sau reteaua autorizata post vanzare Renault.
              </Li>
              <Li>
                comportamentul menționat la punctul b - 1) din prezentul articol. Prin urmare, Platforma va putea alege
                între blocare și excluderea imediată;
              </Li>
              <Li>denaturarea, indiferent de gravitate, întrucât denaturarea este o încălcare a încrederii;</Li>
              <Li>repetarea comportamentului menționat la punctele b°) 2) până la 4) de mai sus;</Li>
              <Li>reclamații repetate depuse de Vânzători;</Li>
              <Li>
                orice modificare a situației personale a Cumpărătorului care este de natură să afecteze intuitu personae
                (în special schimbarea directă sau indirectă a controlului, schimbare semnificativă legată de producție
                sau certificări, proceduri de insolvență în conformitate cu legile în vigoare).
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 12.'} title={'Tranzacții comerciale - Secretul comercial'} />
          <Paragraph>
            Platforma nu poate permite în niciun fel terțelor părți (concurenți, asociați, parteneri etc.) să cunoască
            schimburile, negocierile, contractele și acordurile efectuate sau încheiate prin intermediul Platformei.
            Platforma își va respecta în orice moment programul de confidențialitate.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 13.'} title={'Promoții și publicitate'} />
          <Paragraph>
            Cumpărătorul se obligă să nu folosească Platforma direct sau indirect pentru a promova direct sau indirect,
            sub orice formă, produsele sau serviciile sale sau pe cele ale oricărei alte persoane.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 14.'} title={'Oferte concurente - Nicio exclusivitate'} />
          <Paragraph>Afilierea la Platformă nu creează nicio obligație pentru Cumpărători de a o utiliza.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 15.'} title={'Modificarea TCGU'} />
          <Paragraph>
            Platforma își rezervă dreptul de a modifica TCGU. Orice modificare va intra în vigoare la data indicată în
            notificarea transmisă Vânzătorilor şi Cumpărătorilor în spațiile acestora.
          </Paragraph>
          <Paragraph>Modificările nu se vor aplica tranzacțiilor în curs la data intrării în vigoare.</Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Articolul 16.'}
            title={'Utilizarea datelor cu caracter personal colectate de Platformă '}
          />
          <Paragraph>
            În ceea ce privește utilizarea Platformei, datele dvs. cu caracter personal sunt prelucrate de RENAULT
            conform Politicii de confidențialitate, accesibile prin următorul link:
            <Link to={ROUTER_PERSONAL_DATA(Country.RO.key)} newTab>
              https://rpartstore.renault.com/personal-data/ro
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 17.'} title={'Forța majoră'} />
          <Paragraph>
            Platforma nu poate fi ținută răspunzătoare pentru niciun eveniment de forță majoră care are ca rezultat
            funcționarea defectuoasă a Platformei.
          </Paragraph>
          <Paragraph>
            În plus, Utilizatorii se obligă să considere ca incluse în caracteristicile contractuale ale forței majore,
            în special, întreruperile alimentării cu curent, defecțiunile rețelei de internet, defecțiunile computerelor
            (erori, viruși, malware, intruziuni), chiar dacă își au originea din punct de vedere tehnic în proiectarea
            serviciul de informații.
          </Paragraph>
          <Paragraph>
            Orice eveniment care are caracteristici de forță majoră așa se definește în jurisprudență și care are
            consecințe asupra obligațiilor contractuale ale oricărei părți nu poate angaja răspunderea acesteia.
          </Paragraph>
          <Paragraph>
            Părțile convin să califice în mod expres drept forță majoră în special defecțiunile rețelei de alimentare
            sau de internet sau defecțiunile computerelor (cum ar fi erori, viruși, malware, intruziuni) care au
            consecințe asupra funcționării Platformei.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 18.'} title={'Confidențialitate și probe'} />
          <Paragraph>
            Platforma pune în aplicare mijloace tehnice adecvate pentru a asigura confidențialitatea informațiilor și
            datelor transmise prin intermediul său sau a negocierilor desfășurate prin intermediul său, astfel încât
            acestea să nu fie transmise unor persoane din afara serviciilor sale.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 19.'} title={'Proprietate intelectuală'} />
          <Paragraph>
            Toate elementele care alcătuiesc Platforma (cum ar fi texte, logouri, imagini, elemente grafice sau sonore,
            software, aspect, baze de date, cod sursă etc.), precum și denumirile societăților, mărcile comerciale,
            desenele și semnele distinctive (denumite în continuare „Conținut”) sunt protejate de drepturi de
            proprietate intelectuală. Conținutul este deținut exclusiv de RENAULT și/sau terțele părți care au autorizat
            utilizarea sa.
          </Paragraph>
          <Paragraph>
            Utilizatorii beneficiază de o licență simplă, limitată, neexclusivă, netransferabilă, fără sublicență,
            revocabilă pentru a utiliza Conținutul în contextul strict al utilizării Platformei așa cum se descrie aici.
            Se interzice strict și constituie o încălcare orice altă utilizare a Conținutului, în întregime sau parțial,
            în special reproducerea, reprezentarea, adaptarea, modificarea, decompilarea prin orice proces și pe orice
            suport, inclusiv combinarea cu alte programe de calculator, fără permisiunea prealabilă scrisă a RENAULT.
          </Paragraph>
          <Paragraph>
            Utilizatorii se angajează să informeze RENAULT cât mai curând posibil dacă află despre orice acțiune
            efectuată cu încălcarea prevederilor prezentului articol.
          </Paragraph>
          <Paragraph>
            În cadrul strict al utilizării Platformei, Utilizatorii sunt autorizați să utilizeze mărcile comerciale și
            drepturile de proprietate intelectuală deținute de aceasta sau asupra cărora a dobândit în mod legal
            drepturile, precum și denumirea sa comercială sau denumirea sa legală. Utilizatorul garantează că are
            drepturi care permit RENAULT să reproducă pe Platformă elementele furnizate de Utilizator. În acest sens,
            Utilizatorul garantează RENAULT împotriva oricăror cereri, solicitări, acțiuni sau remedii provenite de la
            oricare terță parte în legătură cu utilizarea elementelor menționate și va suporta toate costurile și
            daunele care pot rezulta din aceasta, în special, ca urmare a unei hotărâri judecătorești, inclusiv a unei
            hotărâri care nu este finală, sau a unui acord de soluționare.
          </Paragraph>
          <Paragraph>
            Utilizatorul este autorizat să menționeze în documentele sale comerciale statutul său de Utilizator la care
            face referire Platforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 20.'} title={'Confidențialitate și probe'} />
          <Paragraph>
            Platforma folosește mijloace tehnice adecvate pentru a proteja confidențialitatea tuturor informațiilor și
            datelor transmise, precum și a vânzărilor efectuate prin intermediul acesteia, pentru a evita orice
            divulgare către terțe părți.
          </Paragraph>
          <Paragraph>
            Aceste date vor fi totuși considerate drept dovezi valabile și admisibile între Vânzători și Cumpărători,
            precum și între aceștia din urmă și Platformă.
          </Paragraph>
          <Paragraph>Aceste date vor fi stocate în conformitate cu prevederile legale.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 21.'} title={'Răspundere'} />
          <Paragraph>
            Vânzătorul confirmă faptul că RENAULT, în calitatea sa de gazdă, nu este parte la niciun acord și/sau
            contract încheiat între Vânzător și Cumpărător, în urma publicării informatiilor referitoare la piese şi
            accesorii pe Platformă.{' '}
          </Paragraph>
          <Paragraph>
            Vânzătorul este singurul responsabil de prezentarea sau descrierea pieselor şi accesoriilor pe care le
            oferă, de afișarea prețurilor acestora, de orice activitate de comunicare și publicitate pe care ar putea să
            o efectueze; drept urmare, Platforma nu poate fi ținută responsabilă pentru erorile sau omisiunile
            Vânzătorului în descrierea pieselor și accesoriilor și/sau indicarea prețurilor care se publică pe
            Platformă.
          </Paragraph>
          <Paragraph>
            Vânzătorul este singurul responsabil pentru aplicarea corectă a prevederilor legislației specifice și în
            special pentru negocierea, încheierea și executarea contractelor legate de piesele și accesoriile oferite
            online pe Platformă și gestionarea oricăror reclamații, recursuri, litigii pe care Cumpărătorul le poate
            iniția cu privire la acestea, în special în cazul neplății.
          </Paragraph>
          <Paragraph>
            Mai general, Vânzătorul este singurul răspunzător față de Cumpărători și terțele părți în calitatea sa de
            Vânzător, oferă garanții Grupului RENAULT cu privire la orice recursuri posibile în acest sens și va suporta
            toate costurile și daunele care ar putea rezulta, în special ca urmare a unei hotărâri judecătorești,
            inclusiv a unei hotărâri care nu este finală sau a unei tranzacții judiciare.
          </Paragraph>
          <Paragraph>
            În cazul în care un Cumpărător sau orice altă persoană acționează Platforma în justiție pentru fapte legate
            de activitatea Vânzătorului, acesta din urmă se obligă să despăgubească Platforma conform oricărei hotărâri
            judecătoreşti.
          </Paragraph>
          <Paragraph>
            În plus, Vânzătorul este singurul responsabil pentru utilizarea Zonei proprii a Vânzătorului.
          </Paragraph>
          <Paragraph>
            Cumpărătorul este singurul responsabil pentru comanda plasată pentru piesele și accesoriile oferite spre
            vânzare pe Platformă.
          </Paragraph>
          <Paragraph>
            În plus, acesta este singurul responsabil pentru utilizarea Zonei proprii a Cumpărătorului și pentru
            informațiile oferite Vânzătorilor.
          </Paragraph>
          <Paragraph>
            Mai general, răspunde în mod exclusiv în calitate de Cumpărător, iar Platforma nu poate fi ținută
            răspunzătoare.
          </Paragraph>
          <Paragraph>
            În cazul în care un Vânzător sau orice altă persoană acționează Platforma în justiție pentru fapte legate de
            activitatea Cumpărătorului, acesta din urmă se obligă să despăgubească Platforma conform oricărei hotărâri
            judecătoreşti.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 22.'} title={'Reclamații – Notificare'} />
          <Paragraph type={'text_bold'}>
            <i>a) Sistemul intern pentru reclamații</i>
          </Paragraph>
          <Paragraph align={'start'}>
            Platforma are creat un sistem intern pentru reclamații pe următoarele subiecte:
            <Ul>
              <Li>
                presupusa nefurnizare de către Platformă de servicii de intermediere online, afectând Utilizatorul care
                depune reclamația;
              </Li>
              <Li>
                probleme tehnologice legate direct de furnizarea de servicii de intermediere și care afectează
                Utilizatorul care depune reclamația;
              </Li>
              <Li>
                măsuri de restricționare luate de către Platformă sau comportamentul său legat direct de furnizarea de
                servicii de intermediere online, afectând Utilizatorul care depune reclamația.
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            În ceea ce privește Măsurile de restricționare luate de Platformă în urma notificării sau pe motiv că
            informațiile/elementele furnizate de Utilizator sunt considerate a fi ilicite sau incompatibile cu TCGU,
            Utilizatorul poate depune o plângere într-o perioadă de 6 luni de la notificarea Măsurii menționate.
          </Paragraph>
          <Paragraph>
            Platforma se angajează să trateze reclamațiile într-un interval de timp compatibil cu complexitatea și
            importanța problemei semnalate și să trimită un răspuns Utilizatorului care depune reclamația.
          </Paragraph>
          <Paragraph type={'text_bold'}>
            <i>b) Notificarea</i>
          </Paragraph>
          <Paragraph>
            <Text type={'text'}>
              Utilizatorul poate transmite orice notificare precisă și fundamentată prin mijloace electronice, pentru a
              indica prezența pe Platforma a unui conținut pe care îl considera ca fiind ilicit. Aceste notificări vor
              fi verificate de Platformă, care își rezervă dreptul de a decide dacă acceptă sau nu această calificare și
              de a lua Măsurile de restricționare corespunzătoare. Decizia luată de Platformă va fi comunicată
              reclamantului, dacă acesta s-a identificat, și poate fi contestată, în special prin intermediul sistemului
              intern pentru reclamații al Platformei. În cazul în care, din motive tehnice sau operaționale, Platforma
              nu poate lua măsuri directe, va informa reclamantul.
            </Text>
          </Paragraph>
          <Paragraph>
            Punctul unic de contact este disponibil la adresa de contact:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolul 23.'} title={'Legea aplicabilă - Litigii'} />
          <Paragraph type={'text'}>Aceşti TCGU sunt guvernate de dreptul român.</Paragraph>
          <Paragraph>
            În cazul unui conflict în legătură cu TCGU, părțile vor încerca mai întâi să îl soluționeze pe cale
            amiabilă, înainte de a-l înainta instanțelor competente de la sediul Renault Commercial Roumanie S.R.L.,
            cărora părțile le conferă competență exclusivă, fără a ține seama de existența mai multor pârâți sau de
            cererile de despăgubire înaintate de părți terțe.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>
            <i>Versiune actualizată: Ianuarie 2024</i>
          </Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'} decoration={'underline'}>
          ANEXA I
        </Text>
        <MarginBox mt={15} />
        <Text type={'h5_bold'} decoration={'underline'}>
          INFORMAȚII PENTRU CUMPĂRĂTORI – PROCEDURA DE ACCES
        </Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-RO.png'} alt={'information-diagram-romania'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
