import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const Bubble = styled(Flex)`
  background-color: ${theme.color.white};
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;

  :hover {
    cursor: pointer;
  }
`;
