/* eslint-disable max-len */
import {
  ELECTRONIC_PARTS_REPAIR_GET_FILE_URL,
  ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_URL,
  ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { ElectronicPartsRepairReplaceReferencesFileRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/request/ElectronicPartsRepairReplaceReferencesFileRequest';
import { FileUploadRequest } from '@1po/1po-bff-fe-spec/generated/common/file_upload/request/FileUploadRequest';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendElectronicPartsRepairFileRequest(payload: FileUploadRequest): WebSocketAction {
  return wsSendAction(ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_URL, payload);
}

export function sendElectronicPartsRepairReplaceRequest(
  payload: ElectronicPartsRepairReplaceReferencesFileRequest,
): WebSocketAction {
  return wsSendAction(ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_URL, payload);
}

export function sendGetElectronicPartsFileRequest(): WebSocketAction {
  return wsSendAction(ELECTRONIC_PARTS_REPAIR_GET_FILE_URL, null);
}
