import { Select } from 'antd';
import styled from 'styled-components';

export const SSelect = styled(Select)`
  width: 230px;
  text-align: left;

  .ant-select-arrow {
    color: ${({ theme }) => theme.color.grey35};
  }
`;
