/* eslint-disable max-len */
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformations';
import { PromotionReferencesFileUploadResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionReferencesFileUploadResponse';
import { UploadReferencesFileResponse } from '@1po/1po-bff-fe-spec/generated/basket/response/UploadReferencesFileResponse';
import { Provider } from '@1po/1po-bff-fe-spec/generated/catalog/category/response/SearchVehicleIAMPartCategoryTreeResponse';
import { BusinessError } from '@1po/1po-bff-fe-spec/generated/error/BusinessError';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import {
  API_CALL_ERROR,
  CONTENT_LIMIT_ERROR,
  EMPTY_FILE_ERROR,
  FILE,
  FILE_STRUCTURE_ERROR,
  NO_FILE,
  SAME_FILE_ERROR,
  TECHNICAL_ERROR,
} from 'components/FileUpload/paramsByState';
import {
  setBasketIdStatus,
  setFileReferencesUploadStatusErrorRows,
  setFileReferencesUploadStatusModalStatus,
} from 'domains/basket/Basket.store';
import {
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  getSearchStatus,
  setExplodedTreeNoDataStatus,
  setFulltextAutocompleteNoData,
  setIAMFulltextEquivalentNoData,
  setIAMLaborTimeNoDataStatus,
  setIAMRepairMethodsNoDataStatus,
  setIAMRepairMethodsTreeNoDataStatus,
  setIAMVehicleBrandsNoDataStatus,
  setIAMVehicleModelsNoDataStatus,
  setIAMVehicleVersionsNoDataStatus,
  setLevels3DetailNoDataStatus,
  setMaintenancePlanStatus,
  setMarketingReferences,
  setNodeIAMReferencesStatus,
  setPlateReferenceNoDataStatus,
  setSearchStatus,
  setUniversalProductsNoDataState,
  setUniversalProductsTree,
  setVehicleBrandsNoDataStatus,
  setVehicleDetail,
  setVehicleEnginesNoDataStatus,
  setVehicleTechnicalCriteriaNoDataStatus,
  setVehicleTechnicalDataDetailsNoDataStatus,
  setVehicleTechnicalDataTreeNoDataStatus,
} from 'domains/catalog/Catalog.store';
import {
  getCrossSellingFileUploadStatus,
  setCrossSellingFileModalStatus,
  setCrossSellingFileUploadStatus,
  setCrossSellingSearchFileStatus,
} from 'domains/crossSelling/CrossSelling.store';
import {
  getElectronicPartsRepairFileUploadStatus,
  setElectronicPartsRepairFileIsModalOpen,
  setElectronicPartsRepairFileModalStatus,
  setElectronicPartsRepairFileUploadStatus,
  setElectronicPartsRepairSearchStatus,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import { REPLACING_FILE, SAME_FILE } from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { setEmailToAssistanceDataStatus } from 'domains/email/Email.store';
import * as actions from 'domains/errors/Errors.store';
import { BE_NOT_FOUND } from 'domains/errors/Errors.types';
import {
  createNewEstimate,
  setBundleFulltextAutocompleteNoData,
  setEstimateDataStatus,
  setEstimateHistorySearchStatus,
  setSettings,
} from 'domains/estimate/Estimate.store';
import { setGarageNoDataStatus } from 'domains/garage/Garage.store';
import {
  setInformationBanners,
  setInformationStatus,
  setLoadedInformationsSearchStatus,
} from 'domains/information/Information.store';
import { setGenericPartLaborTimesSearchStatus, setLaborTimesSearchStatus } from 'domains/laborTime/LaborTime.store';
import {
  setRepairMethodDetailsStatus,
  setServiceOperationsStatus,
  setServiceProposalOperationsStatus,
  setServiceProposalsStatus,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { setMaintenanceValuesStatus } from 'domains/maintenanceValues/MaintenanceValues.store';
import { setBundlesStatus, setMyStoreTiresSearchStatus } from 'domains/myStore/MyStore.store';
import {
  setExternalOrdersNoDataStatus,
  setInProgressOrderSearchStatus,
  setInProgressOrdersNotFound,
  setOrderClaimStatus,
} from 'domains/order/Order.store';
import { setCreateOrderListSearchStatus, setOrderListFileModalStatus } from 'domains/orderList/OrderList.store';
import { setPictureNoDataStatus } from 'domains/pictures/Pictures.store';
import {
  removeUploadedReferencesRows,
  removeUploadedTargetedAudienceRows,
  setCurrentPromotionsDataStatus,
  setFullPromotionState,
  setPromotionBannersStatus,
  setPromotionDetailDataStatus,
  setPublishedPromotionStatus,
} from 'domains/promotion/Promotion.store';
import {
  setDHSingleReferenceStatus,
  setPlatesForReferenceSearchStatus,
  setReferencesPriceNoDataStatus,
  setReferencesRepairPriceNoDataStatus,
  setReferencesStocksNoDataStatus,
  setResolveCrossSellingReferencesStatus,
  setReuseStockNoDataStatus,
} from 'domains/references';
import {
  setIAMTiresNoDataStatus,
  setMprValidationStatus,
  setTireBrandCategoriesSearchStatus,
  setTireSearchStatus,
  setVehicleTiresStatus,
} from 'domains/tires/Tire.store';
import {
  setAvailableDMSServices,
  setDelegationSessionStatus,
  setPossibleBuyersForSellerToDelegateNoData,
  setPossibleSellersToDelegateNoData,
  setUserSellersToChoose,
  setVehicleSearchHistoryNoDataStatus,
} from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { notifyTop } from 'UI/Notification/notification';
import { ERROR, FOUND, NOT_FOUND, sagaGuard } from 'utils';
import { select } from 'utils/domainStore';
import { AppTranslation } from 'utils/i18n';
import {
  getAllDocumentsId,
  getAllMultiPlateIds,
  getAllReferences,
  getClaimErrorMessage,
  getDMSErrorMessages,
  getDMSErrorType,
  getFirstBrand,
  getFirstEstimateId,
  getFirstGarageId,
  getFirstIAMVehicleCode,
  getFirstInformationId,
  getFirstModel,
  getFirstModelType,
  getFirstNodeId,
  getFirstOrderNumber,
  getFirstPictureKey,
  getFirstPlateId,
  getFirstPromotionId,
  getFirstProvider,
  getFirstReference,
  getFirstSearchId,
  getFirstSearchText,
  getFirstSellerId,
  getFirstVehicleKey,
  getFirstVIN,
  getGenericPart,
  getInformationsStatus,
  getLaborTimeId,
  getLaborTimeIds,
  getTireSearchStatus,
} from './Errors.mapper';

const getStatus = (payload: BusinessError) => (payload.errorType === BE_NOT_FOUND ? NOT_FOUND : ERROR);

export function* fetchSearchVehicleErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload?.requestId) {
    yield put(
      setVehicleDetail({
        requestId: action.payload.requestId,
        vehicle: undefined,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchPlateReferencesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const plateId = getFirstPlateId(action);
  const vehicleKey = getFirstVehicleKey(action);
  if (plateId && vehicleKey) {
    yield put(
      setPlateReferenceNoDataStatus({
        vehicleKey,
        plateId,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchMarketingReferencesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const nodeId = getFirstNodeId(action);
  if (vehicleKey && nodeId) {
    yield put(
      setMarketingReferences({
        vehicleKey,
        nodeId,
        incomingReferences: [],
      }),
    );
  }
}

export function* fetchReferencesStocksErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const references = getAllReferences(action);
  if (references) {
    yield put(setReferencesStocksNoDataStatus({ references, status: getStatus(action.payload) }));
  }
}

export function* fetchReferencesPriceErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const references = getAllReferences(action);
  if (references) {
    yield put(
      setReferencesPriceNoDataStatus({
        referenceNumbers: references,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchLaborTimeIdsByGenericPartErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const genericPart = getGenericPart(action);
  const vehicleKey = getFirstVehicleKey(action);

  if (vehicleKey && genericPart) {
    yield put(
      setGenericPartLaborTimesSearchStatus({
        vehicleKey,
        genericPart,
        status: NOT_FOUND,
      }),
    );
  }
}

export function* fetchReferencesRepairPriceErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const references = getAllReferences(action);
  if (references) {
    yield put(
      setReferencesRepairPriceNoDataStatus({
        referenceNumbers: references,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchBasketErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    const { errorType } = action.payload;
    if (errorType !== BE_NOT_FOUND) {
      yield call(notifyTop, 'error', 'Basket loading was not successful');
    }
    yield put(setBasketIdStatus(getStatus(action.payload)));
  }
}

export function* fetchAddEstimateKnownLaborTimeErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const laborTimeId = getLaborTimeId(action);
  if (laborTimeId) {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t('errors.estimate_settings_not_found', 'Estimate settings does not exist'),
      AppTranslation.t(
        'errors.estimate_settings_not_found.missing_labor_time_technicity',
        'Please fill in labor time technicity in the settings tab',
      ),
    );
    return;
  }

  yield call(notifyTop, 'error', AppTranslation.t('errors.unknown_code', 'Unknown code'), null);
}

export function* fetchUploadFileReferences99ReferencesErrorSaga(
  action: WsResponse<UploadReferencesFileResponse>,
): SagaIterator {
  const { errorRows } = action.payload;
  yield put(setFileReferencesUploadStatusErrorRows(errorRows));
  yield put(setFileReferencesUploadStatusModalStatus(CONTENT_LIMIT_ERROR));
}

export function* fetchUploadFileReferencesTechnicalErrorSaga(
  action: WsResponse<UploadReferencesFileResponse>,
): SagaIterator {
  const { errorRows } = action.payload;
  yield put(setFileReferencesUploadStatusErrorRows(errorRows));
  yield put(setFileReferencesUploadStatusModalStatus(TECHNICAL_ERROR));
}

export function* fetchUploadFileReferencesApiCallErrorSaga(
  action: WsResponse<UploadReferencesFileResponse>,
): SagaIterator {
  const { errorRows } = action.payload;
  yield put(setFileReferencesUploadStatusErrorRows(errorRows));
  yield put(setFileReferencesUploadStatusModalStatus(API_CALL_ERROR));
}

export function* fetchDealerOrderListSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(setInProgressOrdersNotFound(getStatus(action.payload)));
  }
}

export function* fetchEstimateErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload.errorType === BE_NOT_FOUND) {
    const vehicle = yield* select(getLastVehicleDetail);
    yield put(createNewEstimate({ vehicle }));
  }
}

export function* fetchEstimateByIdErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const estimateId = getFirstEstimateId(action);
  if (estimateId) {
    yield put(setEstimateDataStatus({ estimateId, status: getStatus(action.payload) }));
  }
}

export function* fetchFulltextSearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  const searchId = getFirstSearchId(action);
  if (searchId && searchStatus?.searchId === searchId) {
    yield put(
      setSearchStatus({
        searchId,
        vehicleKey,
        searchText: searchStatus.searchText,
        status: getStatus(action.payload),
        source: 'DATAHUB',
        type: 'FULLTEXT',
      }),
    );
  }
}

export function* fetchIAMFulltextSearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  const searchId = getFirstSearchId(action);
  if (searchId && searchStatus?.searchId === searchId) {
    yield put(
      setSearchStatus({
        searchId,
        vehicleKey,
        searchText: searchStatus.searchText,
        status: NOT_FOUND,
        source: 'IAM',
        type: 'FULLTEXT',
      }),
    );
  }
}

export function* fetchIAMEquivalentReferencesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  const searchId = getFirstSearchId(action);
  if (searchId && searchStatus?.searchId === searchId) {
    yield put(setIAMFulltextEquivalentNoData(getStatus(action.payload)));
  }
}

export function* fetchAutocompleteFulltextSearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const searchText = getFirstSearchText(action);
  if (vehicleKey && searchText) {
    yield put(setFulltextAutocompleteNoData({ vehicleKey, searchText, status: getStatus(action.payload) }));
  }
}

export function* fetchSingleVehicleReferenceErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const reference = getFirstReference(action);
  if (reference) {
    yield put(setDHSingleReferenceStatus({ reference, vehicleKey, status: getStatus(action.payload) }));
  }
}

export function* fetchPlatesForReferenceError(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action) ?? '';
  const reference = getFirstReference(action);
  if (reference) {
    yield put(setPlatesForReferenceSearchStatus({ reference, vehicleKey, status: getStatus(action.payload) }));
  }
}

export function* fetchReferenceTextSearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const reference = getFirstReference(action);
  const searchId = getFirstSearchId(action);
  if (reference) {
    yield put(setDHSingleReferenceStatus({ reference, vehicleKey, status: getStatus(action.payload) }));
  }
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  if (!searchId || searchStatus?.searchId !== searchId) {
    return;
  }
  yield put(
    setSearchStatus({
      searchId,
      vehicleKey,
      searchText: searchStatus.searchText,
      status: getStatus(action.payload),
      source: 'DATAHUB',
      type: 'REFERENCE',
    }),
  );
}

export function* fetchSearchVehicleBrandsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(setVehicleBrandsNoDataStatus(getStatus(action.payload)));
  }
}

export function* fetchSearchVehicleEnginesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const brandCode = getFirstBrand(action);
  const modelCode = getFirstModel(action);
  const modelTypeCode = getFirstModelType(action);

  if (brandCode && modelCode && modelTypeCode) {
    yield put(
      setVehicleEnginesNoDataStatus({ brandCode, modelCode, modelTypeCode, status: getStatus(action.payload) }),
    );
  }
}

export function* fetchIAMVehicleBrandsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(yield put(setIAMVehicleBrandsNoDataStatus(getStatus(action.payload))));
}

export function* fetchIAMVehicleModelsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const brandCode = getFirstBrand(action);
  if (brandCode) {
    yield put(yield put(setIAMVehicleModelsNoDataStatus({ brandCode, status: getStatus(action.payload) })));
  }
}

export function* fetchIAMVehicleVersionsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const modelCode = getFirstModel(action);
  if (modelCode) {
    yield put(yield put(setIAMVehicleVersionsNoDataStatus({ modelCode, status: getStatus(action.payload) })));
  }
}

export function* fetchVehicleSearchHistoryErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(setVehicleSearchHistoryNoDataStatus(getStatus(action.payload)));
  }
}

export function* fetchUniversalProductsTreeErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(setUniversalProductsTree(getStatus(action.payload)));
  }
}

export function* fetchUniversalProductsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const referenceNumbers = getAllDocumentsId(action);
  if (referenceNumbers && referenceNumbers.length > 0) {
    yield put(setUniversalProductsNoDataState({ referenceNumbers, status: getStatus(action.payload) }));
  }
}

export function* fetchUserConnectAsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(setDelegationSessionStatus(NOT_FOUND));
    yield call(notifyTop, 'error', 'Unable to delegate');
  }
}

export function* getPossibleBuyersForSellerErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(
      setPossibleBuyersForSellerToDelegateNoData({
        sellerId: getFirstSellerId(action) ?? 'missing dealer',
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* getPossibleSellersForUserErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  if (action.payload) {
    yield put(setPossibleSellersToDelegateNoData(getStatus(action.payload)));
  }
}

export function* fetchUserSellersErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield call(notifyTop, 'error', AppTranslation.t('seller.error', 'Seller information unavailable.'), null);
  if (action.payload) {
    yield put(setUserSellersToChoose(getStatus(action.payload)));
  }
}

export function* fetchDHLaborTimesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const ids = getLaborTimeIds(action);
  if (vehicleKey && ids) {
    yield put(setLaborTimesSearchStatus({ vehicleKey, ids, status: getStatus(action.payload) }));
  }
}

export function* fetchIAMLaborTimesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const nodeId = getFirstNodeId(action);
  if (vehicleKey && nodeId) {
    yield put(setIAMLaborTimeNoDataStatus({ vehicleKey, nodeId, status: getStatus(action.payload) }));
  }
}

export function* fetchEstimateHistorySearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setEstimateHistorySearchStatus(getStatus(action.payload)));
}

export function* fetchGarageInfoErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const garageId = getFirstGarageId(action);
  if (garageId) {
    yield put(setGarageNoDataStatus({ garageId, status: getStatus(action.payload) }));
  }
}

export function* fetchEstimateSettingsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setSettings(getStatus(action.payload)));
}

export function* fetchUploadFileElectronicPartsRepairFileStructureErrorSaga(
  action: WsResponse<BusinessError>,
): SagaIterator {
  yield put(setElectronicPartsRepairFileModalStatus(NO_FILE));
  yield put(setElectronicPartsRepairFileModalStatus(FILE_STRUCTURE_ERROR));
  yield put(setElectronicPartsRepairFileIsModalOpen(true));
  yield put(setElectronicPartsRepairSearchStatus(getStatus(action.payload)));
}

export function* fetchReplaceFileElectronicPartsRepairErrorSaga(): SagaIterator {
  yield put(setElectronicPartsRepairFileUploadStatus(SAME_FILE));
}

export function* fetchFileElectricPartsRepairErrorSaga(action: WsResponse): SagaIterator {
  const status = yield* select(getElectronicPartsRepairFileUploadStatus);

  if (status === REPLACING_FILE) {
    yield call(notifyTop, 'error', 'No response from the API. Something went wrong...', action.payload.message);
    yield put(setElectronicPartsRepairFileUploadStatus(API_CALL_ERROR));
    return;
  }
  yield put(setElectronicPartsRepairFileUploadStatus(NO_FILE));
}

export function* uploadFileCrossSellingErrorSaga(action: WsResponse): SagaIterator {
  const { status } = action.payload;
  switch (status) {
    case 'ERROR_EMPTY_FILE':
      yield put(setCrossSellingFileModalStatus(EMPTY_FILE_ERROR));
      return;
    case 'ERROR_FILE_STRUCTURE':
      yield put(setCrossSellingFileModalStatus(FILE_STRUCTURE_ERROR));
      return;
  }
  yield put(setElectronicPartsRepairFileUploadStatus(NO_FILE));
}

export function* deleteFileCrossSellingErrorSaga(): SagaIterator {
  yield put(setCrossSellingFileModalStatus(TECHNICAL_ERROR));
}

export function* fetchFileCrossSellingErrorSaga(action: WsResponse): SagaIterator {
  const status = yield* select(getCrossSellingFileUploadStatus);

  if (status === REPLACING_FILE) {
    yield call(notifyTop, 'error', 'No response from the API. Something went wrong...', action.payload.message);
    yield put(setCrossSellingFileUploadStatus(API_CALL_ERROR));
    return;
  }
  yield put(setCrossSellingSearchFileStatus(NOT_FOUND));
  yield put(setCrossSellingFileUploadStatus(NO_FILE));
}

export function* replaceFileCrossSellingErrorSaga(): SagaIterator {
  yield put(setCrossSellingFileModalStatus(SAME_FILE_ERROR));
  yield put(setCrossSellingFileUploadStatus(FILE));
}

export function* fetchCrossSellingFileReferencesErrorSaga(): SagaIterator {
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  yield put(setResolveCrossSellingReferencesStatus({ vehicleKey, status: NOT_FOUND }));
}

export function* fetchCrossSellingReferencesErrorSaga(): SagaIterator {
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  yield put(setResolveCrossSellingReferencesStatus({ vehicleKey, status: NOT_FOUND }));
}

export function* fetchIAMReferencesErrorSaga(action: WsResponse): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const nodeId = getFirstNodeId(action);
  const provider = getFirstProvider(action);

  if (nodeId && vehicleKey) {
    yield put(setNodeIAMReferencesStatus({ nodeId, vehicleKey, provider, loadingState: NOT_FOUND }));
  }
}

export function* fetchExternalOrdersErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setExternalOrdersNoDataStatus(getStatus(action.payload)));
}

export function* fetchPromotionErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const id = getFirstPromotionId(action);
  if (id) {
    yield put(setPromotionDetailDataStatus({ id, status: getStatus(action.payload) }));
  }
}

export function* fetchCurrentPromotionsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setCurrentPromotionsDataStatus({ status: getStatus(action.payload) }));
}

export function* fetchFullPromotionErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const id = getFirstPromotionId(action);
  if (id) {
    yield put(setFullPromotionState({ id, status: getStatus(action.payload) }));
  }
}

export function* fetchPromotionPublishErrorSaga(action: WsResponse<{ id: string }>): SagaIterator {
  const { id } = action.payload;
  if (id) {
    yield put(setPublishedPromotionStatus({ id, status: ERROR }));
  }
}

export function* fetchPromotionBannersErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setPromotionBannersStatus(getStatus(action.payload)));
}

export function* handleInformationBannersErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setInformationBanners(getStatus(action.payload)));
}

export function* fetchBackofficePromotionUploadReferencesErrorSaga(
  action: WsResponse<PromotionReferencesFileUploadResponse>,
): SagaIterator {
  yield call(
    notifyTop,
    'error',
    AppTranslation.t('common.file_upload.error', 'Upload error'),
    AppTranslation.t('common.file_upload.error.wrong_format_structure', 'File has wrong structure'),
  );
  yield put(removeUploadedReferencesRows({ fileId: action.payload.fileId }));
}

export function* fetchBackofficePromotionUploadTargetedAudienceErrorSaga(): SagaIterator {
  yield call(
    notifyTop,
    'error',
    AppTranslation.t('common.file_upload.error', 'Upload error'),
    AppTranslation.t('common.file_upload.error.wrong_format_structure', 'File has wrong structure'),
  );
  yield put(removeUploadedTargetedAudienceRows());
}

export function* fetchPictureErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const key = getFirstPictureKey(action);
  if (key) {
    yield put(setPictureNoDataStatus({ key, status: getStatus(action.payload) }));
  }
}

export function* fetchExplodedTreeError(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  if (vehicleKey) {
    yield put(setExplodedTreeNoDataStatus({ vehicleKey, status: getStatus(action.payload) }));
  }
}

export function* fetchIAMExplodedTreeError(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const provider = getFirstProvider(action) as Provider;
  if (vehicleKey) {
    yield put(setExplodedTreeNoDataStatus({ vehicleKey, status: getStatus(action.payload), provider }));
  }
}

export function* fetchInformationErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const id = getFirstInformationId(action);
  if (id) {
    yield put(setInformationStatus({ id, status: getStatus(action.payload) }));
  }
}

export function* fetchInformationsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const searchStatus = getStatus(action.payload);
  const statusType = getInformationsStatus(action) as StatusType;
  if (statusType) {
    yield put(setLoadedInformationsSearchStatus({ statusType, searchStatus }));
  }
}

export function* fetchSaveInformationErrorSaga(): SagaIterator {
  yield call(
    notifyTop,
    'error',
    AppTranslation.t('backoffice.save.error', 'Save error'),
    AppTranslation.t('backoffice.information.save.error', 'The information has not been saved.'),
  );
}

export function* fetchPublishInformationErrorSaga(): SagaIterator {
  yield call(
    notifyTop,
    'error',
    AppTranslation.t('backoffice.publish.error', 'Publish error'),
    AppTranslation.t('backoffice.information.publish.error', 'The publication of information was not successful.'),
  );
}

export function* fetchSaveInformationTemplateErrorSaga(): SagaIterator {
  yield call(
    notifyTop,
    'error',
    AppTranslation.t('backoffice.save.error', 'Save error'),
    AppTranslation.t('backoffice.information_template.save.error', 'The information template has not been saved.'),
  );
}

export function* fetchTechnicalCriteriaErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  if (vehicleKey) {
    yield put(setVehicleTechnicalCriteriaNoDataStatus({ vehicleKey, status: getStatus(action.payload) }));
  }
}

export function* fetchTechnicalDataTreeErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const vehicleCode = getFirstIAMVehicleCode(action);
  if (vehicleKey && vehicleCode) {
    yield put(setVehicleTechnicalDataTreeNoDataStatus({ vehicleKey, vehicleCode, status: getStatus(action.payload) }));
  }
}

// TODO BE needs to properly send error from streamingQuery, currently responds only to send req timeout
export function* fetchTechnicalDataDetailsErrorSaga(
  action: WsResponse<{ vehicleKey: string; vehicleCode: string; categoryIds: string[] }>,
): SagaIterator {
  const { vehicleKey, vehicleCode, categoryIds } = action.payload;
  if (vehicleKey && vehicleCode && categoryIds) {
    yield put(
      setVehicleTechnicalDataDetailsNoDataStatus({
        vehicleKey,
        vehicleCode,
        l3Ids: categoryIds,
        status: ERROR,
      }),
    );
  }
}

export function* fetchLevel3ErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const lv3s = getAllMultiPlateIds(action);

  if (vehicleKey && lv3s) {
    yield put(setLevels3DetailNoDataStatus({ lv3s, vehicleKey, status: getStatus(action.payload) }));
  }
}

export function* fetchMaintenancePlanErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVIN(action);
  if (vehicleKey) {
    yield put(
      setMaintenancePlanStatus({
        vehicleKey,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchMaintenanceValuesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  if (vehicleKey) {
    yield put(
      setMaintenanceValuesStatus({
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchIAMServiceOperationsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVIN(action);
  if (vehicleKey) {
    yield put(
      setServiceOperationsStatus({
        vehicleKey,
        versionCode: undefined,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchIAMServiceProposalsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVIN(action);
  if (vehicleKey) {
    yield put(
      setServiceProposalsStatus({
        vehicleKey,
        versionCode: undefined,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchIAMServiceProposalOperationsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVIN(action);
  if (vehicleKey) {
    yield put(
      setServiceProposalOperationsStatus({
        vehicleKey,
        versionCode: undefined,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* fetchIAMRepairMethodsErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  const nodeId = getFirstNodeId(action);
  if (vehicleKey && nodeId) {
    yield put(
      setIAMRepairMethodsNoDataStatus({
        vehicleKey,
        nodeId,
        status: getStatus(action.payload),
      }),
    );
  }
}

export function* emailGenericErrorSaga(): SagaIterator {
  yield call(notifyTop, 'error', AppTranslation.t('email.send.error', 'Email not sent'), null);
}

function* sendEmailToAssistanceErrorSaga(): SagaIterator {
  yield put(setEmailToAssistanceDataStatus(ERROR));
}

export function* tireProductsSearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const searchParamsBase64 = getTireSearchStatus(action);
  if (searchParamsBase64 !== undefined) {
    yield put(setTireSearchStatus({ searchParamsBase64, status: getStatus(action.payload) }));
  }
}

export function* vehicleTiresErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  if (!vehicleKey) return;
  yield put(setVehicleTiresStatus({ vehicleKey, status: getStatus(action.payload) }));
}

export function* fetchIAMTiresErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleCode = getFirstIAMVehicleCode(action);
  if (!vehicleCode) return;
  yield put(setIAMTiresNoDataStatus({ vehicleCode, status: getStatus(action.payload) }));
}

export function* fetchTireBrandCategoriesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setTireBrandCategoriesSearchStatus({ searchStatus: getStatus(action.payload) }));
}

export function* fetchGetTireBrandCategoriesErrorSaga(): SagaIterator {
  yield put(setTireBrandCategoriesSearchStatus({ searchStatus: ERROR }));
}

export function* fetchReuseStocksErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const referenceNumbers = getAllReferences(action);
  if (!referenceNumbers || referenceNumbers.length < 1) return;
  yield put(setReuseStockNoDataStatus({ referenceNumbers, status: getStatus(action.payload) }));
}

export function* availableDMSServicesErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield put(setAvailableDMSServices(getStatus(action.payload)));
}

export function* repairOrderErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const errorType = getDMSErrorType(action);
  const errorMessages =
    errorType === 'API_ERROR'
      ? getDMSErrorMessages(action)?.join(', ')
      : AppTranslation.t(
          'estimate.dms.unknown_error',
          'Unknown error occurred when trying to get repair order from DMS.',
        );

  const vin = getFirstVIN(action);

  if (vin) {
    yield call(notifyTop, 'error', AppTranslation.t('estimate.dms.vin_error', 'Vehicle not found'), vin);
    return;
  }

  yield call(
    notifyTop,
    'error',
    AppTranslation.t('estimate.dms.api_error', 'There has been an error contacting the DMS'),
    errorMessages,
  );
}

export function* sendEstimateToDMSErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const errorType = getDMSErrorType(action);
  const errorMessages =
    errorType === 'API_ERROR' ? getDMSErrorMessages(action)?.join(', ') : getSendEstimateToDMSErrorMessage(errorType);

  yield call(
    notifyTop,
    'error',
    AppTranslation.t('estimate.dms.export.api_error', 'There has been an error sending the informations to your DMS'),
    errorMessages,
  );
}

function getSendEstimateToDMSErrorMessage(errorType: string | undefined): string {
  switch (errorType) {
    case 'ESTIMATE_NOT_FOUND':
      return AppTranslation.t('estimate.dms.export.estimate_not_found', 'Estimate was not found during DMS export');
    case 'NO_ITEMS_IN_ESTIMATE':
      return AppTranslation.t(
        'estimate.dms.export.no_items_in_estimate',
        'No items in estimate, nothing to send to DMS.',
      );
    default:
      return AppTranslation.t('estimate.dms.export.unknown_error', 'Unknown error occurred during DMS export');
  }
}

export function* fetchUploadOrderListReferencesTechnicalErrorSaga(): SagaIterator {
  yield put(setCreateOrderListSearchStatus({ searchStatus: FOUND }));
  yield call(
    notifyTop,
    'error',
    AppTranslation.t(
      'order_list.add_to_cart.error.technical',
      'The order list upload failed due to technical error.',
    ) as string,
    null,
  );
}

export function* fetchUploadOrderListReferencesExternalApiCallErrorSaga(): SagaIterator {
  yield put(setCreateOrderListSearchStatus({ searchStatus: FOUND }));
  yield call(
    notifyTop,
    'error',
    AppTranslation.t(
      'order_list.add_to_cart.error.api_call',
      'The order list upload failed due to external api call.',
    ) as string,
    null,
  );
}

export function* fetchUploadOrderListReferencesResponseNotReceivedErrorSaga(): SagaIterator {
  yield put(setCreateOrderListSearchStatus({ searchStatus: ERROR }));
}

export function* getBundlesErrorSaga(): SagaIterator {
  yield put(setBundlesStatus(ERROR));
}

export function* getMyStoreErrorSaga(): SagaIterator {
  yield put(setMyStoreTiresSearchStatus(ERROR));
}

export function* fetchMprValidationErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const referenceNumbers = getAllReferences(action);
  if (!referenceNumbers || referenceNumbers.length < 1) return;
  for (const reference of referenceNumbers) {
    yield put(setMprValidationStatus({ reference, status: ERROR }));
  }
}

export function* fetchFileUploadOrderListReferences99ReferencesErrorSaga(): SagaIterator {
  yield put(setOrderListFileModalStatus(CONTENT_LIMIT_ERROR));
}

export function* fetchFileUploadOrderListReferencesFileErrorSaga(): SagaIterator {
  yield put(setOrderListFileModalStatus(FILE_STRUCTURE_ERROR));
}

export function* fetchOTSInformationErrorSaga(): SagaIterator {
  yield call(notifyTop, 'error', AppTranslation.t('ots.get.error', 'OTS information unavailable'), null);
}

export function* sendClaimErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  yield call(notifyTop, 'error', getClaimErrorMessage(action) ?? '');
  yield put(setOrderClaimStatus(undefined));
}

export function* sendIAMRepairMethodDetailErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const nodeId = getFirstNodeId(action);
  if (nodeId) {
    yield put(setRepairMethodDetailsStatus({ categoryId: nodeId, status: getStatus(action.payload) }));
  }
}

export function* fetchIAMRepairMethodsTreeErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const vehicleKey = getFirstVehicleKey(action);
  if (vehicleKey) {
    yield put(setIAMRepairMethodsTreeNoDataStatus({ vehicleKey, status: getStatus(action.payload) }));
  }
}

export function* fetchOrderByIdErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const orderId = getFirstOrderNumber(action);
  if (orderId) {
    yield put(setInProgressOrderSearchStatus({ orderId, status: getStatus(action.payload) }));
  }
}

export function* fetchBundlesAutocompleteFulltextSearchErrorSaga(action: WsResponse<BusinessError>): SagaIterator {
  const searchText = getFirstSearchText(action);
  if (searchText) {
    yield put(setBundleFulltextAutocompleteNoData({ searchText, status: getStatus(action.payload) }));
  }
}

export function* ErrorsSagas(): SagaIterator {
  yield takeEvery(actions.fetchUserConnectAsErrorSaga.type, sagaGuard(fetchUserConnectAsErrorSaga));
  yield takeEvery(actions.getPossibleBuyersForSellerErrorSaga.type, sagaGuard(getPossibleBuyersForSellerErrorSaga));
  yield takeEvery(actions.getPossibleSellersForUserErrorSaga.type, sagaGuard(getPossibleSellersForUserErrorSaga));
  yield takeEvery(actions.fetchUserSellersErrorSaga.type, sagaGuard(fetchUserSellersErrorSaga));
  yield takeEvery(actions.fetchSearchVehicleErrorSaga.type, sagaGuard(fetchSearchVehicleErrorSaga));
  yield takeEvery(actions.fetchPlateReferencesErrorSaga.type, sagaGuard(fetchPlateReferencesErrorSaga));
  yield takeEvery(actions.fetchMarketingReferencesErrorSaga.type, sagaGuard(fetchMarketingReferencesErrorSaga));
  yield takeEvery(actions.fetchReferencesStocksErrorSaga.type, sagaGuard(fetchReferencesStocksErrorSaga));
  yield takeEvery(actions.fetchReferencesPriceErrorSaga.type, sagaGuard(fetchReferencesPriceErrorSaga));
  yield takeEvery(actions.uploadFileCrossSellingErrorSaga.type, sagaGuard(uploadFileCrossSellingErrorSaga));
  yield takeEvery(actions.deleteFileCrossSellingErrorSaga.type, sagaGuard(deleteFileCrossSellingErrorSaga));
  yield takeEvery(
    actions.fetchCrossSellingFileReferencesErrorSaga.type,
    sagaGuard(fetchCrossSellingFileReferencesErrorSaga),
  );
  yield takeEvery(actions.fetchCrossSellingReferencesErrorSaga.type, sagaGuard(fetchCrossSellingReferencesErrorSaga));
  yield takeEvery(
    actions.fetchLaborTimeIdsByGenericPartErrorSaga.type,
    sagaGuard(fetchLaborTimeIdsByGenericPartErrorSaga),
  );
  yield takeEvery(actions.fetchReferencesRepairPriceErrorSaga.type, sagaGuard(fetchReferencesRepairPriceErrorSaga));
  yield takeEvery(actions.fetchBasketErrorSaga.type, sagaGuard(fetchBasketErrorSaga));
  yield takeEvery(
    actions.fetchAddEstimateKnownLaborTimeErrorSaga.type,
    sagaGuard(fetchAddEstimateKnownLaborTimeErrorSaga),
  );
  yield takeEvery(
    actions.fetchUploadFileReferences99ReferencesErrorSaga.type,
    sagaGuard(fetchUploadFileReferences99ReferencesErrorSaga),
  );
  yield takeEvery(
    actions.fetchUploadFileReferencesTechnicalErrorSaga.type,
    sagaGuard(fetchUploadFileReferencesTechnicalErrorSaga),
  );
  yield takeEvery(
    actions.fetchUploadFileReferencesApiCallErrorSaga.type,
    sagaGuard(fetchUploadFileReferencesApiCallErrorSaga),
  );
  yield takeEvery(actions.fetchDealerOrderListSaga.type, sagaGuard(fetchDealerOrderListSaga));
  yield takeEvery(actions.fetchOrderByIdErrorSaga.type, sagaGuard(fetchOrderByIdErrorSaga));
  yield takeEvery(actions.fetchEstimateErrorSaga.type, sagaGuard(fetchEstimateErrorSaga));
  yield takeEvery(actions.fetchEstimateByIdErrorSaga.type, sagaGuard(fetchEstimateByIdErrorSaga));
  yield takeEvery(actions.fetchFulltextSearchErrorSaga.type, sagaGuard(fetchFulltextSearchErrorSaga));
  yield takeEvery(actions.fetchIAMFulltextSearchErrorSaga.type, sagaGuard(fetchIAMFulltextSearchErrorSaga));
  yield takeEvery(actions.fetchIAMEquivalentReferencesErrorSaga.type, sagaGuard(fetchIAMEquivalentReferencesErrorSaga));
  yield takeEvery(
    actions.fetchAutocompleteFulltextSearchErrorSaga.type,
    sagaGuard(fetchAutocompleteFulltextSearchErrorSaga),
  );
  yield takeEvery(actions.fetchSingleVehicleReferenceErrorSaga.type, sagaGuard(fetchSingleVehicleReferenceErrorSaga));
  yield takeEvery(actions.fetchPlatesForReferenceError.type, sagaGuard(fetchPlatesForReferenceError));
  yield takeEvery(actions.fetchReferenceTextSearchErrorSaga.type, sagaGuard(fetchReferenceTextSearchErrorSaga));
  yield takeEvery(actions.fetchSearchVehicleBrandsErrorSaga.type, sagaGuard(fetchSearchVehicleBrandsErrorSaga));
  yield takeEvery(actions.fetchSearchVehicleEnginesErrorSaga.type, sagaGuard(fetchSearchVehicleEnginesErrorSaga));
  yield takeEvery(actions.fetchIAMVehicleBrandsErrorSaga.type, sagaGuard(fetchIAMVehicleBrandsErrorSaga));
  yield takeEvery(actions.fetchIAMVehicleModelsErrorSaga.type, sagaGuard(fetchIAMVehicleModelsErrorSaga));
  yield takeEvery(actions.fetchIAMVehicleVersionsErrorSaga.type, sagaGuard(fetchIAMVehicleVersionsErrorSaga));
  yield takeEvery(actions.fetchVehicleSearchHistoryErrorSaga.type, sagaGuard(fetchVehicleSearchHistoryErrorSaga));
  yield takeEvery(actions.fetchUniversalProductsTreeErrorSaga.type, sagaGuard(fetchUniversalProductsTreeErrorSaga));
  yield takeEvery(actions.fetchUniversalProductsErrorSaga.type, sagaGuard(fetchUniversalProductsErrorSaga));
  yield takeEvery(actions.fetchDHLaborTimesErrorSaga.type, sagaGuard(fetchDHLaborTimesErrorSaga));
  yield takeEvery(actions.fetchIAMLaborTimesErrorSaga.type, sagaGuard(fetchIAMLaborTimesErrorSaga));
  yield takeEvery(actions.fetchEstimateHistorySearchErrorSaga.type, sagaGuard(fetchEstimateHistorySearchErrorSaga));
  yield takeEvery(actions.fetchGarageInfoErrorSaga.type, sagaGuard(fetchGarageInfoErrorSaga));
  yield takeEvery(actions.fetchEstimateSettingsErrorSaga.type, sagaGuard(fetchEstimateSettingsErrorSaga));
  yield takeEvery(
    actions.fetchUploadFileElectronicPartsRepairFileStructureErrorSaga.type,
    sagaGuard(fetchUploadFileElectronicPartsRepairFileStructureErrorSaga),
  );
  yield takeEvery(
    actions.fetchReplaceFileElectronicPartsRepairErrorSaga.type,
    sagaGuard(fetchReplaceFileElectronicPartsRepairErrorSaga),
  );
  yield takeEvery(actions.fetchFileElectricPartsRepairErrorSaga.type, sagaGuard(fetchFileElectricPartsRepairErrorSaga));
  yield takeEvery(actions.fetchFileCrossSellingErrorSaga.type, sagaGuard(fetchFileCrossSellingErrorSaga));
  yield takeEvery(actions.replaceFileCrossSellingErrorSaga.type, sagaGuard(replaceFileCrossSellingErrorSaga));
  yield takeEvery(actions.fetchIAMReferencesErrorSaga.type, sagaGuard(fetchIAMReferencesErrorSaga));
  yield takeEvery(actions.fetchExternalOrdersErrorSaga.type, sagaGuard(fetchExternalOrdersErrorSaga));

  // Promotion
  yield takeEvery(actions.fetchPromotionErrorSaga.type, sagaGuard(fetchPromotionErrorSaga));
  yield takeEvery(actions.fetchCurrentPromotionsErrorSaga.type, sagaGuard(fetchCurrentPromotionsErrorSaga));
  yield takeEvery(actions.fetchFullPromotionErrorSaga.type, sagaGuard(fetchFullPromotionErrorSaga));
  yield takeEvery(actions.fetchPromotionPublishErrorSaga.type, sagaGuard(fetchPromotionPublishErrorSaga));
  yield takeEvery(actions.fetchPromotionBannersErrorSaga.type, sagaGuard(fetchPromotionBannersErrorSaga));
  yield takeEvery(
    actions.fetchBackofficePromotionUploadReferencesErrorSaga.type,
    sagaGuard(fetchBackofficePromotionUploadReferencesErrorSaga),
  );
  yield takeEvery(
    actions.fetchBackofficePromotionUploadTargetedAudienceErrorSaga.type,
    sagaGuard(fetchBackofficePromotionUploadTargetedAudienceErrorSaga),
  );
  yield takeEvery(actions.fetchPictureErrorSaga.type, sagaGuard(fetchPictureErrorSaga));
  yield takeEvery(actions.fetchExplodedTreeError.type, sagaGuard(fetchExplodedTreeError));
  yield takeEvery(actions.fetchIAMExplodedTreeError.type, sagaGuard(fetchIAMExplodedTreeError));

  // Information
  yield takeEvery(actions.fetchInformationErrorSaga.type, sagaGuard(fetchInformationErrorSaga));
  yield takeEvery(actions.handleInformationBannersErrorSaga.type, sagaGuard(handleInformationBannersErrorSaga));
  yield takeEvery(actions.fetchInformationsErrorSaga.type, sagaGuard(fetchInformationsErrorSaga));
  yield takeEvery(actions.fetchSaveInformationErrorSaga.type, sagaGuard(fetchSaveInformationErrorSaga));
  yield takeEvery(actions.fetchPublishInformationErrorSaga.type, sagaGuard(fetchPublishInformationErrorSaga));
  yield takeEvery(actions.fetchSaveInformationTemplateErrorSaga.type, sagaGuard(fetchSaveInformationTemplateErrorSaga));

  yield takeEvery(actions.fetchTechnicalCriteriaErrorSaga.type, sagaGuard(fetchTechnicalCriteriaErrorSaga));
  yield takeEvery(actions.fetchTechnicalDataTreeErrorSaga.type, sagaGuard(fetchTechnicalDataTreeErrorSaga));
  yield takeEvery(actions.fetchTechnicalDataDetailsErrorSaga.type, sagaGuard(fetchTechnicalDataDetailsErrorSaga));
  yield takeEvery(actions.fetchLevel3ErrorSaga.type, sagaGuard(fetchLevel3ErrorSaga));

  //Maintenance  plan
  yield takeEvery(actions.fetchMaintenancePlanErrorSaga.type, sagaGuard(fetchMaintenancePlanErrorSaga));
  yield takeEvery(actions.fetchIAMServiceOperationsErrorSaga.type, sagaGuard(fetchIAMServiceOperationsErrorSaga));
  yield takeEvery(actions.fetchIAMServiceProposalsErrorSaga.type, sagaGuard(fetchIAMServiceProposalsErrorSaga));
  yield takeEvery(
    actions.fetchIAMServiceProposalOperationsErrorSaga.type,
    sagaGuard(fetchIAMServiceProposalOperationsErrorSaga),
  );
  yield takeEvery(actions.fetchMaintenanceValuesErrorSaga.type, sagaGuard(fetchMaintenanceValuesErrorSaga));

  // emails
  yield takeEvery(actions.sendEmailFromGarageErrorSaga.type, sagaGuard(emailGenericErrorSaga));
  yield takeEvery(actions.sendEmailToAssistanceErrorSaga.type, sagaGuard(sendEmailToAssistanceErrorSaga));
  yield takeEvery(actions.sendEmailReturnRequestErrorSaga.type, sagaGuard(emailGenericErrorSaga));

  //tires
  yield takeEvery(actions.tireProductsSearchErrorSaga.type, sagaGuard(tireProductsSearchErrorSaga));
  yield takeEvery(actions.vehicleTiresErrorSaga.type, sagaGuard(vehicleTiresErrorSaga));
  yield takeEvery(actions.availableDMSServicesErrorSaga.type, sagaGuard(availableDMSServicesErrorSaga));
  yield takeEvery(actions.repairOrderErrorSaga.type, sagaGuard(repairOrderErrorSaga));
  yield takeEvery(actions.getBundlesErrorSaga.type, sagaGuard(getBundlesErrorSaga));
  yield takeEvery(actions.getMyStoreTiresErrorSaga.type, sagaGuard(getMyStoreErrorSaga));

  yield takeEvery(actions.sendEstimateToDMSErrorSaga.type, sagaGuard(sendEstimateToDMSErrorSaga));
  yield takeEvery(actions.fetchIAMTiresErrorSaga.type, sagaGuard(fetchIAMTiresErrorSaga));
  yield takeEvery(actions.fetchTireBrandCategoriesErrorSaga.type, sagaGuard(fetchTireBrandCategoriesErrorSaga));
  yield takeEvery(actions.fetchGetTireBrandCategoriesErrorSaga.type, sagaGuard(fetchGetTireBrandCategoriesErrorSaga));
  yield takeEvery(actions.fetchReuseStocksErrorSaga.type, sagaGuard(fetchReuseStocksErrorSaga));
  yield takeEvery(actions.fetchMprValidationErrorSaga.type, sagaGuard(fetchMprValidationErrorSaga));
  yield takeEvery(
    actions.fetchUploadOrderListReferencesTechnicalErrorSaga.type,
    sagaGuard(fetchUploadOrderListReferencesTechnicalErrorSaga),
  );
  yield takeEvery(
    actions.fetchUploadOrderListReferencesApiCallErrorSaga.type,
    sagaGuard(fetchUploadOrderListReferencesExternalApiCallErrorSaga),
  );
  yield takeEvery(
    actions.fetchUploadOrderListReferencesResponseNotReceivedErrorSaga.type,
    sagaGuard(fetchUploadOrderListReferencesResponseNotReceivedErrorSaga),
  );
  yield takeEvery(
    actions.fetchFileUploadOrderListReferences99ReferencesErrorSaga.type,
    sagaGuard(fetchFileUploadOrderListReferences99ReferencesErrorSaga),
  );
  yield takeEvery(
    actions.fetchFileUploadOrderListReferencesFileErrorSaga.type,
    sagaGuard(fetchFileUploadOrderListReferencesFileErrorSaga),
  );
  yield takeEvery(actions.fetchIAMRepairMethodsErrorSaga.type, sagaGuard(fetchIAMRepairMethodsErrorSaga));
  yield takeEvery(actions.fetchOTSInformationErrorSaga.type, sagaGuard(fetchOTSInformationErrorSaga));
  yield takeEvery(actions.sendIAMRepairMethodDetailErrorSaga.type, sagaGuard(sendIAMRepairMethodDetailErrorSaga));
  yield takeEvery(actions.fetchIAMRepairMethodsTreeErrorSaga.type, sagaGuard(fetchIAMRepairMethodsTreeErrorSaga));
  yield takeEvery(
    actions.fetchBundlesAutocompleteFulltextSearchErrorSaga.type,
    sagaGuard(fetchBundlesAutocompleteFulltextSearchErrorSaga),
  );
  yield takeEvery(actions.sendClaimErrorSaga.type, sagaGuard(sendClaimErrorSaga));
}
