/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { PlateDetail } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/PlateDetail';
import { List } from 'antd';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { useDecodePlateImage } from 'domains/catalog/Catalog.hooks';
import { useFetchPlates } from 'domains/catalog/Catalog.requests';
import {
  getDetailOfLevel3,
  getExplodedTreeItem,
  getMultiplateLabels,
  getPlateFullImageKey,
  getPlates,
  getPlatesImagesKeys,
  getSearchVehicleResult,
  plateHasDescription,
} from 'domains/catalog/Catalog.store';
import {
  DATAHUB,
  PARAM_MULTIPLATE,
  PARAM_STANDARD,
  PlateIdParam,
  PlateImage,
  PlateViewModeParam,
} from 'domains/catalog/Catalog.types';
import { useGetCatalogSourceUrlFromLocation } from 'domains/catalog/Catalog.utils';
import { useFetchUrlImage } from 'domains/pictures/Pictures.requests';
import { getPicture } from 'domains/pictures/Pictures.store';
import PlateDetailCardImage from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateDetailCardImage';
import PlateThumbnailSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateThumbnailSection';
import { theme } from 'styles';
import { Box, BREADCRUMB_HEIGHT, Flex, Lowercase, MarginBox, Pipeline, Text } from 'UI';
import { hasData } from 'utils';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { svgElementPlateId } from 'utils/svg/common';
import { SMultiplateCard } from './MultiplateChoice.styled';

const MultiplateLabelItem = ({
  title,
  description,
  isLast,
}: {
  title: string;
  description: string[];
  isLast: boolean;
}) => {
  return (
    <>
      <Text type={'h5_bold'} transform={'capitalize'}>
        <Lowercase>{title}</Lowercase>
      </Text>
      {description?.length > 0 && <Box height={5} />}
      {description.map((descriptionItem, subIndex) => (
        <Text key={`description-title-${subIndex}`} type={'light_14_black_85'} transform={'capitalize'}>
          <Lowercase>{descriptionItem}</Lowercase>
        </Text>
      ))}
      {!isLast && <Box height={15} />}
    </>
  );
};

export const MultiplateChoiceCard = ({
  onClick,
  plateId,
  vehicleKey,
}: {
  onClick: () => void;
  plateId: string;
  vehicleKey: string;
}) => {
  const plateImage = useSelector((state: RootState) => getPlateFullImageKey(state, { plateId, vehicleKey }));
  const plateImageBase64 = useSelector((state: RootState) => getPicture(state, plateImage.imageKey));
  const svgElement = useDecodePlateImage(plateImage, plateImageBase64);
  const plateDetails = useSelector((state: RootState) => getMultiplateLabels(state, { plateId, vehicleKey }));
  const filteredDetails = Array.isArray(plateDetails) ? plateDetails.filter((x) => x) : [];
  const dataLeft = filteredDetails.filter((x, index) => index <= filteredDetails.length / 2) as PlateDetail[];
  const dataRight = filteredDetails.filter((x, index) => index > filteredDetails.length / 2) as PlateDetail[];

  useFetchUrlImage(plateImage.imageKey, DATAHUB);

  return (
    <SMultiplateCard onClick={onClick}>
      <MarginBox mx={8} my={8}>
        <Flex direction={['column', 'row']}>
          <Flex minHeight={200} minWidth={200} maxWidth={300} maxHeight={300}>
            <PlateDetailCardImage plateImage={plateImage} image={svgElement} svgElementPlateId={svgElementPlateId} />
          </Flex>
          <Flex>
            <MarginBox mx={60} my={30}>
              <Flex direction={'column'}>
                {dataLeft.map(({ name: title, descriptions }, index) => (
                  <MultiplateLabelItem
                    key={`description-${index}`}
                    isLast={index + 1 >= dataLeft.length}
                    title={title}
                    description={descriptions}
                  />
                ))}
              </Flex>
            </MarginBox>
          </Flex>
          <Pipeline size={0} color={theme.color.silver_2} offsetRatio={18} horizontal={[true, false]} />
          <Flex>
            <MarginBox mx={60} my={30}>
              <Flex direction={'column'}>
                {dataRight.map(({ name: title, descriptions }, index) => (
                  <MultiplateLabelItem
                    key={`description-${index}`}
                    isLast={index + 1 >= dataRight.length}
                    title={title}
                    description={descriptions}
                  />
                ))}
              </Flex>
            </MarginBox>
          </Flex>
        </Flex>
      </MarginBox>
    </SMultiplateCard>
  );
};

export interface MultiplateChoiceProps {
  query: string;
  carPartGroupId: string;
  categoryId: string;
  subcategoryId: string;
  plateId: string;
}

export const MultiplateChoice = ({
  query,
  carPartGroupId,
  categoryId,
  subcategoryId,
  plateId,
}: MultiplateChoiceProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const catalogSourceUrl = useGetCatalogSourceUrlFromLocation();
  const vehicleKey = vehicleResult?.vehicleDetail?.vehicleKey;
  const params = new URLSearchParams(location.search);
  const tree = vehicleResult?.explodedTree?.data;
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const subcategory = getExplodedTreeItem(tree, [carPartGroupId, categoryId, subcategoryId]);
  const multiplates = useSelector((state: RootState) =>
    getDetailOfLevel3(state, { nodeId: subcategory?.nodeId, vehicleKey }),
  );
  const multiplateIds = hasData(multiplates) ? multiplates?.map((multiplate) => multiplate.plateIds).flat() : undefined;
  const plates = useSelector((state: RootState) => getPlates(state, { plateIds: multiplateIds, vehicleKey }));
  const selectedMultiplate = Array.isArray(multiplates)
    ? multiplates?.find((multiplate) => multiplate.plateIds.includes(plateId))
    : undefined;
  const plateIds = selectedMultiplate ? selectedMultiplate.plateIds : subcategory?.plateIds;
  const plateImages = useSelector((state: RootState) => getPlatesImagesKeys(state, { plateIds, vehicleKey }));
  const subcategories = useMemo(() => [subcategory].filter((x) => x) as VehiclePartCategoryTreeItem[], [subcategory]);
  const plateImage = useSelector((state: RootState) => getPlateFullImageKey(state, { plateId, vehicleKey }));

  useFetchPlates(subcategories, plateImages, query, vehicleResult, new Map([[subcategory?.nodeId ?? '', multiplates]]));

  const handleSelectMultiplate = (plateIds: string[]) => {
    const id = plateIds[0];
    const plate = plates?.find((p) => p?.plateId === id);
    params.set(PlateIdParam, id);
    if (plateIds.length === 1 && !plateHasDescription(plate?.plateDetail)) {
      params.set(PlateViewModeParam, PARAM_STANDARD);
    } else {
      params.set(PlateViewModeParam, PARAM_MULTIPLATE);
    }
    history.push(
      `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}/${carPartGroupId}/${categoryId}/${subcategoryId}?${params}`,
    );
  };

  const handleValidate = (selectedPlateId: string | undefined) => () => {
    window.scrollTo({
      top: 0,
      left: 100,
    });
    params.set(PlateViewModeParam, PARAM_STANDARD);
    params.set(PlateIdParam, selectedPlateId as string);

    history.push(
      `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}/${carPartGroupId}/${categoryId}/${subcategoryId}?${params}`,
    );
  };

  const renderMultiplateCard = (multiplate: PlateImage | undefined, index: number) => {
    if (!multiplate?.plateId || !vehicleKey) return null;
    return (
      <React.Fragment key={`multiplate-${index}`}>
        <MultiplateChoiceCard
          onClick={handleValidate(multiplate?.plateId)}
          plateId={multiplate.plateId}
          vehicleKey={vehicleKey}
        />
        {index + 1 < plateImages.length && <Box height={40} />}
      </React.Fragment>
    );
  };

  useEffect(() => {
    const subcategoryElement = document.getElementById('subcategory_section_container');
    if (subcategoryElement)
      window.scrollTo({
        top: subcategoryElement.offsetTop - BREADCRUMB_HEIGHT - offsetTop,
      });
  }, [history.location.pathname, offsetTop]);

  return (
    <>
      <Box height={45} />
      <Flex>
        <PlateThumbnailSection
          multiplates={multiplates}
          handleSelectMultiplate={handleSelectMultiplate}
          plateImage={plateImage}
          selectedPlateId={plateId}
        />
        <Flex direction={'column'}>
          <Flex size={0} direction={'column'} align={'flex-start'}>
            <Text type={'h2'}>{t('catalog.multiplate_choice', 'Applicability')}</Text>
            <Box height={30} />
            <Text type={'light_14_black_85'}>
              {t(
                'catalog.multiplate_choice.description.line1',
                'We’ve identified several answers to your search, please select the appropriate description of your vehicle.',
              )}
            </Text>
            <Text type={'light_14_black_85'}>
              {t('catalog.multiplate_choice.description.line2', 'Then validate your selection.')}
            </Text>
          </Flex>
          <Box height={30} />
          <List dataSource={plateImages} renderItem={renderMultiplateCard} />
        </Flex>
      </Flex>
    </>
  );
};
