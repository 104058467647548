/* eslint-disable max-len */
import {
  DELIVERY_LEAD_TIME_GET_URL,
  DELIVERY_LEAD_TIME_MODIFY_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { DeliveryLeadTimeModifyRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/request/DeliveryLeadTimeModifyRequest';
import { WebSocketAction } from 'utils';
import { wsSendAction, wsSendActionNoTimeout } from 'utils/domainStore/api';

export function sendGetDeliveryLeadTimeRequest(): WebSocketAction {
  return wsSendAction(DELIVERY_LEAD_TIME_GET_URL, {});
}

export function sendUpdateDeliveryLeadTimeRequest(payload: DeliveryLeadTimeModifyRequest): WebSocketAction {
  return wsSendActionNoTimeout(DELIVERY_LEAD_TIME_MODIFY_URL, payload);
}
