import React from 'react';
import { CenterFlex, Text } from 'UI';

export const ArchivedOrders = () => {
  return (
    <CenterFlex>
      <Text type={'h3_dim'}>Not implemented yet</Text>
    </CenterFlex>
  );
};
