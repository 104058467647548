import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { Bell } from 'assets/icons';
import { DocumentationAlertForm } from 'components/DocumentationAlertButton/DocumentaryAlertForm';
import { resetEmailDocumentationAlertMotrioData } from 'domains/email/Email.store';
import { DHReferenceLocal, IAMReferenceLocal } from 'domains/references';
import { BoxSizeType, IconButton, Modal, WithTooltip } from 'UI';

export const DocumentationAlertButton = ({
  inverted,
  size = 16,
  boxSize,
  reference,
  vehicleRegistrationNumber,
  vehicleDetail,
}: {
  inverted?: boolean;
  size?: number;
  boxSize?: BoxSizeType;
  reference: DHReferenceLocal | IAMReferenceLocal | OrderReferenceItem | undefined;
  vehicleRegistrationNumber: string | undefined;
  vehicleDetail: VehicleDetail | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  function onCancel() {
    dispatch(resetEmailDocumentationAlertMotrioData());
    setIsOpen(false);
  }

  return (
    <>
      <WithTooltip title={t('motrio.documentation_alert.raise_error', 'Raise a documentation error')}>
        <div>
          <IconButton
            IconComponent={Bell}
            round
            size={size}
            color={inverted ? 'white' : 'red'}
            bgColor={inverted ? 'red' : 'white'}
            onClick={() => setIsOpen(!isOpen)}
            boxSize={boxSize}
            hoverFill={inverted ? 'red' : 'white'}
            dataCy={'icon-raise-documentation-error'}
          />
        </div>
      </WithTooltip>
      <Modal width={1200} footer={''} open={isOpen} onCancel={onCancel}>
        <DocumentationAlertForm
          onCancel={onCancel}
          reference={reference}
          vehicleRegistrationNumber={vehicleRegistrationNumber}
          vehicleDetail={vehicleDetail}
        />
      </Modal>
    </>
  );
};
