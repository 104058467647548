import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { useFetchOrder } from 'domains/order/Order.requests';
import { getInProgressOrder } from 'domains/order/Order.store';
import InProgressOrderDetails from 'pages/MyOrdersPage/InProgressOrders/InProgressOrderDetails';
import { Text } from 'UI';

export function InProgressOrderDetailsWrapper() {
  const { orderId } = useParams<{ orderId: string }>();
  const { t } = useTranslation();

  const orderSearchData = useSelector((state: RootState) => getInProgressOrder(state, orderId));
  const order = orderSearchData?.data;

  useFetchOrder(orderId);

  return (
    <>
      <Text type={'h0'} dataCy={'title-order-details'}>
        {t('order.details', 'Order details')}
      </Text>
      <DataContainer data={orderSearchData?.searchStatus}>
        {order && <InProgressOrderDetails order={order} />}
      </DataContainer>
    </>
  );
}
