import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import CopyPromotion from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CopyPromotion';
import CreatePromotion from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion';
import Promotion from 'pages/BackOfficePage/BackOfficeCategories/Promotion/Promotion/Promotion';
import { COPY, CREATE_NEW, EDIT, PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import EditPromotion from './EditPromotion';

export function PromotionBase() {
  return (
    <Switch>
      <Route exact path={`${ROUTER_BACKOFFICE}/${PROMOTION}/${CREATE_NEW}`} render={() => <CreatePromotion />} />
      <Route exact path={`${ROUTER_BACKOFFICE}/${PROMOTION}/${EDIT}/:promotionId`} render={() => <EditPromotion />} />
      <Route exact path={`${ROUTER_BACKOFFICE}/${PROMOTION}/${COPY}/:promotionId`} render={() => <CopyPromotion />} />
      <Route exact path={'*'} render={() => <Promotion />} />
    </Switch>
  );
}
