import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { VehicleTabContentBase } from 'pages/CatalogPage/common';
import { Flex, Text } from 'UI';
import { isQueryBMM } from 'utils';

export const PdfShortcutHeader = ({ title }: { title: string }) => {
  const currentVehicle = useSelector(getLastVehicleDetail);
  const isBMM = isQueryBMM(currentVehicle?.vehicleKey ?? '');
  const date = moment().format('DD-MM-YYYY');
  return (
    <>
      <div>
        <Text type={'h2'} disableGutter>
          {title}
        </Text>
        <Text type={'h6_black_45'}>
          <Trans i18nKey={'estimate.pdf.date'} tOptions={{ date }}>
            Date: {{ date }}
          </Trans>
        </Text>
      </div>
      <Flex minWidth={900}>
        {currentVehicle && (
          <VehicleTabContentBase
            result={currentVehicle}
            vehicleImage={currentVehicle.imageUrl}
            isBMM={isBMM}
            isPopover={true}
          />
        )}
      </Flex>
    </>
  );
};
