import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ArrowAltCircleDownIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import LanguageMenu from 'components/LanguageMenu';
import ForgottenPassword, { isPasswordRetrievalAllowed } from 'components/Login/LoginForm/ForgottenPassword';
import OnePortalLogo from 'components/OnePortalLogo';
import InformationBanner from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { getCredentials, getCredentialsSearchState } from 'domains/auth/Auth.store';
import { Box, Flex, Grid, Icon, MarginBox, Text, WhiteButton, YellowButton } from 'UI';
import { LoginSectionProps } from './LoginSection';
import {
  InfoWrapper,
  LOGIN_SECTION_CONTAINER_SIZE,
  LoginButtonsWrapper,
  ScrollButton,
  ScrollButtonWrapper,
  SInnerContainer,
  SOuterContainer,
  SWrapper,
} from './LoginSection.styled';

export const LoginSectionMd = ({
  informationBanner,
  onRenUserArcaLoginHandler,
  onRenUserTokenLoginHandler,
  onKeycloakLoginHandler,
  onOctaLoginHandler,
  scroll,
  scrollToPortfolio,
}: LoginSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const credentials = useSelector(getCredentials);
  const credentialsSearchStatus = useSelector(getCredentialsSearchState);
  const [showOtherLoginOptions, setShowOtherLoginOptions] = useState<boolean>(false);

  function handleChangeShowLoginOptions() {
    setShowOtherLoginOptions((prev) => !prev);
  }

  return (
    <SWrapper>
      <SOuterContainer>
        <SInnerContainer>
          <Flex size={0} direction={'column'} basis={700}>
            <InfoWrapper>
              <OnePortalLogo />
              <MarginBox mt={60} />
              <Text type={'heading_landing_md'}>{t('section.landing.intro', 'Buy car parts for your vehicle')}</Text>
              <Text type={'text_landing_md'}>
                <Trans i18nKey={'section.landing.intro.description'}>
                  Revolutionizing automobile aftermarket spare parts solution. Discover Renault Group official solution
                  , developed with and for automotive professionals, in order to optimize your daily work.
                </Trans>
              </Text>
              <LoginButtonsWrapper>
                <DataContainer data={credentialsSearchStatus}>
                  <Grid
                    columns={2}
                    gutter={[
                      [10, 10],
                      [10, 10],
                    ]}
                  >
                    <Box width={180}>
                      <YellowButton stretch onClick={onOctaLoginHandler}>
                        <Text type={'text'}>{t('login.okta_connection', 'Okta connection')}</Text>
                      </YellowButton>
                    </Box>
                    <Box width={180}>
                      <WhiteButton size={'large'} onClick={handleChangeShowLoginOptions}>
                        <Text type={'text'}>
                          {showOtherLoginOptions
                            ? t('login.other_login_options.button.description.hide', 'Hide Other Options')
                            : t('login.other_login_options.button.description.show', 'See other login options')}
                        </Text>
                      </WhiteButton>
                    </Box>
                    {showOtherLoginOptions && (
                      <Box width={180}>
                        <WhiteButton size={'large'} onClick={onRenUserTokenLoginHandler}>
                          <Text type={'text'}>{t('login.rnet_connection', 'Rnet connection')}</Text>
                        </WhiteButton>
                      </Box>
                    )}
                    {showOtherLoginOptions && (
                      <Box width={180}>
                        <WhiteButton size={'large'} onClick={onRenUserArcaLoginHandler}>
                          <Text type={'text'}>{t('login.arca_connection', 'ARCA connection')}</Text>
                        </WhiteButton>
                      </Box>
                    )}
                    {credentials?.keycloakIssuer && credentials?.keycloakClientId && showOtherLoginOptions && (
                      <Box width={180}>
                        <WhiteButton size={'large'} onClick={onKeycloakLoginHandler} data-cy="keycloak-login">
                          <Text type={'text'}>{'Keycloak login'}</Text>
                        </WhiteButton>
                      </Box>
                    )}
                    {isPasswordRetrievalAllowed() && (
                      <Box width={180}>
                        <ForgottenPassword />
                      </Box>
                    )}
                  </Grid>
                </DataContainer>
              </LoginButtonsWrapper>
            </InfoWrapper>
          </Flex>
          <Flex maxWidth={LOGIN_SECTION_CONTAINER_SIZE - 700 - 504} basis={0} />
          <Flex size={0} direction={'column'} basis={504}>
            <Box>
              <Box height={90}>
                <Flex justify={'flex-end'}>
                  <MarginBox mt={20} mr={68}>
                    <LanguageMenu />
                  </MarginBox>
                </Flex>
              </Box>
            </Box>
          </Flex>
          {scroll === 0 && (
            <ScrollButtonWrapper offset={informationBanner ? 75 : 30}>
              <ScrollButton>
                <Icon
                  onClick={scrollToPortfolio}
                  IconComponent={ArrowAltCircleDownIcon}
                  color={'rgba(255, 255, 255, 0.9)'}
                  hoverFill={'rgba(255, 255, 255, 1)'}
                  size={48}
                />
              </ScrollButton>
            </ScrollButtonWrapper>
          )}
        </SInnerContainer>
      </SOuterContainer>
      {informationBanner && <InformationBanner banner={informationBanner} />}
    </SWrapper>
  );
};
