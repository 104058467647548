import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { MyStoreTireRimSizeType } from '@1po/1po-bff-fe-spec/generated/my_store/tires/request/UpdateMyStoreTireDiscount';
import { Table } from 'antd';
import { DataContainer } from 'components/DataContainer';
import { clearMyStoreTiresChanges, getMyStoreTiresSearchStatus } from 'domains/myStore/MyStore.store';
import MyStoreHeader from 'pages/MyStorePage/MyStoreHeader';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Container, Flex, Text } from 'UI';
import { MyStoreDiscountControlButtons } from './TiresTable/MyStoreDiscountControlButtons';
import { MyStoreDiscountInput } from './TiresTable/MyStoreDiscountInput';

interface MyStoreTireRow {
  name: string;
  brand: ReferenceBrandType;
  brandId: string;
  FIFTEEN?: number;
  SIXTEEN?: number;
  SEVENTEEN?: number;
  EIGHTEEN?: number;
  NINETEEN?: number;
  TWENTY?: number;
}

function GetTitle(tr: string, dataCy?: string) {
  return (
    <Text type={'text_bold'} dataCy={dataCy}>
      {tr}
    </Text>
  );
}

const getBrandsData = (): MyStoreTireRow[] =>
  tireBrandsData.map((b) => {
    return {
      name: b.name,
      brand: b.brand,
      brandId: b.brandId,
    };
  });

const Tires = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // on component unmount
  useEffect(
    () => () => {
      dispatch(clearMyStoreTiresChanges());
    },
    [dispatch],
  );

  const myStoreTiresSearchStatus = useSelector(getMyStoreTiresSearchStatus);
  const rawData = getBrandsData();

  function getDiscountInput(brand: ReferenceBrandType, rimSize: string) {
    return (
      <Flex justify={'center'} align={'center'}>
        <MyStoreDiscountInput brand={brand} tireRimSizeType={rimSize as MyStoreTireRimSizeType} />
      </Flex>
    );
  }

  const columns = [
    {
      title: GetTitle(t('my_store.tires.table.brand.title', 'Brand')),
      dataIndex: 'name',
      width: '12vw',
      key: 'name',
      render: function rowSelector(name: string) {
        return (
          <Flex justify={'flex-start'} align={'center'}>
            <Text type={'text'}>{name}</Text>
          </Flex>
        );
      },
      filters: [
        ...tireBrandsData.map((b) => {
          return {
            text: b.name,
            value: b.brandId,
          };
        }),
      ],
      onFilter: (value: string | number | boolean, row: MyStoreTireRow) => {
        return row.brandId.startsWith(value as string);
      },
    },
    {
      title: GetTitle(t('my_store.tires.table.column.fifteen', '⌀ = 15" and -')),
      dataIndex: 'FIFTEEN',
      key: 'FIFTEEN',
      width: '12vw',
      render: (_name: string, row: MyStoreTireRow) => getDiscountInput(row.brand, 'FIFTEEN'),
    },
    {
      title: GetTitle(t('my_store.tires.table.column.sixteen', '⌀ = 16”')),
      dataIndex: 'SIXTEEN',
      key: 'SIXTEEN',
      width: '12vw',
      render: (_name: string, row: MyStoreTireRow) => getDiscountInput(row.brand, 'SIXTEEN'),
    },
    {
      title: GetTitle(t('my_store.tires.table.column.seventeen', '⌀ = 17”')),
      dataIndex: 'SEVENTEEN',
      key: 'SEVENTEEN',
      width: '12vw',
      render: (_name: string, row: MyStoreTireRow) => getDiscountInput(row.brand, 'SEVENTEEN'),
    },
    {
      title: GetTitle(t('my_store.tires.table.column.eighteen', '⌀ = 18”')),
      dataIndex: 'EIGHTEEN',
      key: 'EIGHTEEN',
      width: '12vw',
      render: (_name: string, row: MyStoreTireRow) => getDiscountInput(row.brand, 'EIGHTEEN'),
    },
    {
      title: GetTitle(t('my_store.tires.table.column.nineteen', '⌀ = 19”')),
      dataIndex: 'NINETEEN',
      key: 'NINETEEN',
      width: '12vw',
      render: (_name: string, row: MyStoreTireRow) => getDiscountInput(row.brand, 'NINETEEN'),
    },
    {
      title: GetTitle(t('my_store.tires.table.column.twenty', '⌀ = 20” and +')),
      dataIndex: 'TWENTY',
      key: 'TWENTY',
      width: '12vw',
      render: (_name: string, row: MyStoreTireRow) => getDiscountInput(row.brand, 'TWENTY'),
    },
  ];

  return (
    <Flex direction={'column'}>
      <DataContainer data={myStoreTiresSearchStatus}>
        <MyStoreHeader
          leftSection={
            <>
              <Text type={'h2'} disableGutter>
                {t('my_store.tires.page.title', 'Tire Discount for Recommended Customer Price')}
              </Text>
              <Text type={'light_dimmer'} disableGutter>
                {t('my_store.tires.page.description', 'Discount Applied for the Current year')}
              </Text>
            </>
          }
          rightSection={<MyStoreDiscountControlButtons />}
        />
        <Container>
          <Table dataSource={rawData} columns={columns} pagination={false} rowKey={(row) => row.brandId} />
        </Container>
      </DataContainer>
    </Flex>
  );
};

export default Tires;
