import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DataContainer } from 'components/DataContainer';
import { GetIAMServiceOperationsResponseLocal } from 'domains/maintenancePlan/MaintenancePlan.types';
import { BottomStickyBox } from 'pages/CatalogPage/IAM/MaintenancePlan/BottomStickyBox';
import RenderOperation from 'pages/CatalogPage/IAM/MaintenancePlan/ServiceOperations/Operation';
import { Box, CenteredSpin, MarginBox, Text } from 'UI';
import { getData, NO_DATA } from 'utils';

const ServiceOperations = ({
  operations,
  handleNext,
}: {
  operations: NO_DATA | GetIAMServiceOperationsResponseLocal;
  handleNext: (step: number) => void;
}) => {
  const { t } = useTranslation();
  const operationsData = getData(operations);

  return (
    <>
      <Text type={'h1_banner_light'}>
        <Trans i18nKey={'catalog.maintenance.operations.title'}>Operations</Trans>
      </Text>
      <Box>
        <Text type={'light_14_black_85'}>
          <Trans i18nKey={'catalog.maintenance.operations.description'}>
            You’ll find below, recommended Maintenance Plan according to manufacturer specifications for the selected
            time period or kilometers, whichever occurs first.
          </Trans>
        </Text>
      </Box>
      <MarginBox mt={38} />
      <DataContainer
        data={operations}
        NotFound={() => (
          <Text type={'light_14_black_85'}>
            <Trans i18nKey={'catalog.maintenance.operations.not_found'}>Operations not found</Trans>
          </Text>
        )}
        ErrorState={() => (
          <Text type={'light_14_black_85'}>
            <Trans i18nKey={'catalog.maintenance.operations.error_loading'}>Error loading operations</Trans>
          </Text>
        )}
        Loading={() => (
          <>
            <MarginBox mt={38} />
            <CenteredSpin size={'large'} />
            <MarginBox mt={38} />
          </>
        )}
      >
        <Text type={'h1_banner_light'}>
          <Trans i18nKey={'catalog.maintenance.operations.selected'}>Selected operations</Trans>
        </Text>
        <MarginBox mt={20} mb={10}>
          <Text type={'light_14_black_85'}>
            <Trans i18nKey={'catalog.maintenance.operations.description_selected'}>
              Operations which were selected by the proposal service.
            </Trans>
          </Text>
        </MarginBox>
        {/* main selected operations */}
        {operationsData?.mainOperations?.map((o) =>
          o.operations
            .filter((op) => op.selected)
            .map((operation) => (
              <RenderOperation isMain key={operation.id} operation={operation} categoryLabel={o.label} />
            )),
        )}
        {/* additional selected operations */}
        <MarginBox mt={38} />
        {operationsData?.additionalOperations?.map((o) =>
          o.operations
            .filter((op) => op.selected)
            .map((operation) => (
              <RenderOperation isMain={false} key={operation.id} operation={operation} categoryLabel={o.label} />
            )),
        )}
        <MarginBox mt={20} />
        <Text type={'h1_banner_light'}>
          <Trans i18nKey={'catalog.maintenance.operations.other'}>Other operations</Trans>
        </Text>
        <MarginBox mt={20} mb={10}>
          <Text type={'light_14_black_85'}>
            <Trans i18nKey={'catalog.maintenance.operations.description_other'}>
              Operations which were not selected by the proposal service.
            </Trans>
          </Text>
        </MarginBox>
        {/* main unselected operations */}
        {operationsData?.mainOperations?.map((o) =>
          o.operations
            .filter((op) => !op.selected)
            .map((operation) => (
              <RenderOperation isMain key={operation.id} operation={operation} categoryLabel={o.label} />
            )),
        )}
        {/* additional unselected operations */}
        <MarginBox mt={38} />
        {operationsData?.additionalOperations?.map((o) =>
          o.operations
            .filter((op) => !op.selected)
            .map((operation) => (
              <RenderOperation isMain={false} key={operation.id} operation={operation} categoryLabel={o.label} />
            )),
        )}
        <MarginBox mt={16} />
        <BottomStickyBox
          text1={t('catalog.maintenance.new_maintenance_plan.validate_maintenance_plan', 'Validate Maintenance plan')}
          text2={t(
            'catalog.maintenance.new_maintenance_plan.selected_operations_added',
            'Selected Operations and parts will automatically be added to new estimate.',
          )}
          callback={() => handleNext(3)}
          buttonLabel={t('catalog.maintenance.new_maintenance_plan.validate', 'Validate')}
        />
      </DataContainer>
    </>
  );
};

export default ServiceOperations;
