import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown as AntDropdown } from 'antd';
import { DownOutlinedIcon } from 'assets/icons';
import { MarginBox, Flex, Icon, Text, Checkbox } from 'UI';
import { SButton, SCheckboxMenu } from 'UI/DropdownMenu/DropdownMenu.styled';

// id for SELECT_ALL option
export const SELECT_ALL = 'ALL';

interface DropdownMenuInterfaceBase {
  items: DropdownMenuItem[];
  value?: string[];
  width?: number | string;
  onChange?: (val: string[]) => void;
  allowSelectAll?: boolean;
  defaultSelectedKeys?: string[];
}

export interface DropdownMenuInterface extends DropdownMenuInterfaceBase {
  label: string;
}

export interface DropdownMenuItem {
  label: string;
  id: string;
}

const CheckboxMenu = ({
  items,
  value,
  width,
  onChange,
  allowSelectAll,
  defaultSelectedKeys,
}: DropdownMenuInterfaceBase) => {
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState<string[]>(defaultSelectedKeys ?? []);

  useEffect(() => {
    value && setCheckedList(value.length === 0 ? [SELECT_ALL] : value);
  }, [value]);

  const handleChange = (id: string) => (checked: boolean) => {
    if (isSelected(SELECT_ALL)) {
      const checkListWithoutSelectAll = checked
        ? [id]
        : checkedList.filter((cl) => cl === id).filter((clt) => clt !== SELECT_ALL);
      setCheckedList(checkListWithoutSelectAll);
      onChange && onChange(checkListWithoutSelectAll);
    } else {
      const newCheckList = checked ? [...checkedList, id] : checkedList.filter((cl) => cl !== id);
      const checkListWithSelectAll =
        newCheckList.length === items.length ? [SELECT_ALL, ...newCheckList] : newCheckList;
      setCheckedList(checkListWithSelectAll);
      onChange && onChange(checkListWithSelectAll);
    }
  };

  const handleSelectAll = () => {
    const selectAllList = [SELECT_ALL];
    setCheckedList(selectAllList);
    onChange && onChange(selectAllList);
  };

  function isSelected(id: string): boolean {
    return !!checkedList.find((l) => l === id);
  }

  return (
    <SCheckboxMenu direction={'column'} minWidth={width}>
      {allowSelectAll && (
        <MarginBox my={7} mx={15}>
          <Checkbox checked={isSelected(SELECT_ALL)} onChange={handleSelectAll} label={t('common.all', 'ALL')} />
        </MarginBox>
      )}
      {items.map((i) => (
        <MarginBox key={i.id} my={7} mx={15}>
          <Checkbox checked={isSelected(i.id)} onChange={handleChange(i.id)} label={i.label} />
        </MarginBox>
      ))}
    </SCheckboxMenu>
  );
};

// For now only one variant: "CheckboxMenu"
export const DropdownMenu = (props: Readonly<DropdownMenuInterface>) => {
  const handleOnChange = (items: string[]) => {
    if (!props.onChange) return;
    props.onChange(items);
  };

  return (
    <AntDropdown
      overlay={
        <CheckboxMenu
          items={props.items}
          value={props.value}
          width={props.width}
          onChange={handleOnChange}
          allowSelectAll={props.allowSelectAll}
          defaultSelectedKeys={props.defaultSelectedKeys}
        />
      }
      placement="bottom"
      trigger={['click']}
    >
      <SButton width={props.width} stretch>
        <Flex justify={'space-between'} align={'center'}>
          <Text type={'light_14_black_65'} ellipsis>
            {props.label}
          </Text>
          <Icon IconComponent={DownOutlinedIcon} size={14} />
        </Flex>
      </SButton>
    </AntDropdown>
  );
};
