import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { SparePartsViewType } from 'domains/user';
import TireReferenceCard from 'pages/TiresPage/TireReferencesSection/TireReferenceCard/TireReferenceCard';
import { Flex, MarginBox, WhiteButton } from 'UI';
import { PaddingBox } from 'UI/Box/PaddingBox';

const PAGING_SIZE = 10;

interface TireReferenceCardsInfiniteScrollProps {
  tires: TireItem[];
  sparePartsView: SparePartsViewType;
  singleSection: boolean;
  initShowCount: number;
  compact?: boolean;
}

export const TireReferenceCardsInfiniteScroll = ({
  tires,
  sparePartsView,
  initShowCount,
  compact,
}: TireReferenceCardsInfiniteScrollProps) => {
  const { t } = useTranslation();
  const [showCount, setShowCount] = React.useState(initShowCount);
  const showTires = useMemo(() => tires.slice(0, showCount), [showCount, tires]);
  const hasMore = tires.length > 0 && tires.length > showTires.length;

  const handleShowMore = () => {
    setShowCount((prevState) => prevState + PAGING_SIZE);
  };

  return (
    <PaddingBox px={15} py={1}>
      {showTires?.map((tire) => (
        <TireReferenceCard
          tire={tire}
          key={`tire-ref-${tire.partNumber}`}
          sparePartsView={sparePartsView}
          compact={compact}
        />
      ))}
      {hasMore && (
        <Flex justify={'center'}>
          <MarginBox my={30}>
            <WhiteButton outlined onClick={handleShowMore}>
              {t('common.action.show_more', 'Show more')}
            </WhiteButton>
          </MarginBox>
        </Flex>
      )}
    </PaddingBox>
  );
};
