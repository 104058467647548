import React from 'react';
import { RenaultRPartStoreLogoColorIcon } from 'assets/icons';
import { Icon } from 'UI';

const OnePortalLogo = (): JSX.Element => (
  <Icon
    IconComponent={RenaultRPartStoreLogoColorIcon}
    width={150}
    display={'block'}
    color={'white'}
    dataCy={'rpart-store-logo'}
  />
);

export default OnePortalLogo;
