import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Text } from 'UI/Text';

export interface FloatingLabelProps extends PropsWithChildren {
  label?: string;
  showAlways?: boolean;
  displayFloatingLabel: boolean;
  setFocus: (focus: boolean) => void;
}

export const SFloatLabel = styled(Text)`
  z-index: 2;
  position: absolute;
  left: 10px;
  top: -10px;
  transition: 0.2s ease all;
  padding: 0px 3px 0px 3px;
  pointer-events: none;
  background: ${({ theme }) => theme.color.white};
`;
export const SFloatLabelContainer = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FloatingLabel = ({ children, label, displayFloatingLabel, setFocus }: FloatingLabelProps) => {
  return (
    <SFloatLabelContainer onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      {displayFloatingLabel && <SFloatLabel type={'light_10_black_65'}>{label}</SFloatLabel>}
    </SFloatLabelContainer>
  );
};
