/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  IAMRepairMethod,
  IAMRepairMethodDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsResponse';
import { TFunction } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { EyeIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import {
  fetchIAMRepairMethodDetail,
  getIAMRepairMethodDetailData,
  getIAMRepairMethodDetailStatus,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { GetTitle } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection';
import { STable } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
import { theme } from 'styles';
import { Box, CenteredSpin, ErrorAlert, Flex, Icon, MarginBox, Text } from 'UI';
import { getData, isLoading, NO_DATA } from 'utils';

interface RepairMethodSectionProps {
  query: string;
  nodeId: string;
  repairMethodWrapper: NO_DATA | IAMRepairMethod[];
}

const openWindowWithHtml = (html: string): void => {
  const newWindow = window.open('', '_blank');
  if (newWindow) {
    newWindow.document.open();
    newWindow.document.write(html);
    newWindow.document.close();
  }
};
const SPACE_BETWEEN = 'space-between';
const RepairMethodSection = ({ repairMethodWrapper }: RepairMethodSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const repairMethods = getData(repairMethodWrapper);
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const detailStatus = useSelector((state: RootState) => getIAMRepairMethodDetailStatus(state, categoryId));
  const detail = useSelector((state: RootState) => getIAMRepairMethodDetailData(state, categoryId));

  function clickDetail(selCategory: string) {
    setCategoryId(selCategory);
    dispatch(fetchIAMRepairMethodDetail({ categoryId: selCategory }));
  }

  useEffect(() => {
    if (detailStatus === 'found' && detail) {
      openWindowWithHtml(detail);
    }
  }, [categoryId, detailStatus, detail]);

  const tableColumns = (t: TFunction) => {
    return [
      {
        title: GetTitle(t('catalog.parts.category.car_parts.repair_method.header.designation', 'Designation')),
        dataIndex: 'designation',
        key: 'designation',
        render: function rowSelector(designation: string) {
          return (
            <Flex justify={SPACE_BETWEEN} align={'center'}>
              <Text type={'text'} disableGutter>
                {designation}
              </Text>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('catalog.parts.category.car_parts.repair_method.header.details', 'Details')),
        dataIndex: 'details',
        key: 'details',
        render: function rowSelector(rows: IAMRepairMethodDetail[]) {
          return (
            <Flex key={uuidv4()} justify={SPACE_BETWEEN} align={'center'}>
              <Flex key={uuidv4()} justify={'flex-start'} direction={'column'}>
                {rows.map((detail) => (
                  <>
                    <Text key={detail.id} type={'text'} disableGutter>
                      {detail.detail}
                    </Text>
                  </>
                ))}
              </Flex>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('catalog.parts.category.car_parts.repair_method.header.documentation', 'Documentation')),
        dataIndex: 'details',
        key: 'documentation',
        width: 150,
        render: function rowSelector(rows: IAMRepairMethodDetail[]) {
          return (
            <Flex justify={SPACE_BETWEEN} align={'center'}>
              <Flex key={uuidv4()} justify={'flex-start'} direction={'column'}>
                {rows.map((detail) => (
                  <Flex key={detail.id} direction={'row'} justify={SPACE_BETWEEN} align={'center'}>
                    <Text type={'text'} displayStyle={'link'} cursor={'pointer'} decoration={'underline'}>
                      <a
                        style={{ color: theme.color.clear_blue }}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => clickDetail(detail.id)}
                      >
                        {t('catalog.parts.category.car_parts.repair_method.see_method', 'See method')}
                      </a>
                    </Text>
                    {detail.id === categoryId && detailStatus === 'loading' && (
                      <Box>
                        <CenteredSpin size={'small'} />
                      </Box>
                    )}
                    <Icon
                      IconComponent={EyeIcon}
                      color={theme.color.clear_blue}
                      height={20}
                      width={20}
                      onClick={() => clickDetail(detail.id)}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
          );
        },
      },
    ];
  };

  const columns = tableColumns(t);

  return (
    <>
      <Text type={'section_bold'}>
        {t('catalog.parts.category.car_parts.repair_method', 'Repair method')}
        {` – `}
        <Trans count={repairMethods?.length ?? 0} i18nKey={'catalog.parts.category.car_parts.labor_time.items_found'}>
          {'{{count}} items found'}
        </Trans>
      </Text>
      <MarginBox mt={45} />
      <DataContainer
        data={repairMethods}
        NotFound={() => (
          <MarginBox my={20}>
            <Box height={40}>
              <ErrorAlert
                message={t(
                  'catalog.parts.category.car_parts.repair_method.notification.noMatchFound',
                  'Sorry, no match found for your research',
                )}
              />
            </Box>
          </MarginBox>
        )}
      >
        <MarginBox mt={20} />
        <STable<IAMRepairMethod>
          loading={isLoading(repairMethods)}
          columns={columns}
          dataSource={repairMethods}
          pagination={false}
          size={'large'}
          bordered={true}
        />
      </DataContainer>
    </>
  );
};

export default RepairMethodSection;
