import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { getDisplayTimeoutNotifications } from 'domains/appContext/AppContext.store';
import { getAuthenticatedUserIsExpired } from 'domains/auth/Auth.util';
import { setTokenExpired } from 'domains/user/User.store';
import { handleTimedOutRequest } from 'domains/webSockets/handlers/handleTimedOutRequest';
import { getRequest } from 'domains/webSockets/WebSocket.store';
import { notifyTop } from 'UI/Notification/notification';
import { AppTranslation } from 'utils/i18n';

export const checkAddSetIsExpired = async (dispatch: Dispatch): Promise<boolean> => {
  const isExpired = await getAuthenticatedUserIsExpired();
  if (isExpired) {
    dispatch(setTokenExpired(isExpired));
  }
  return isExpired;
};

export const checkRequestTimeout = (traceId: string, getState: () => RootState, dispatch: Dispatch): void => {
  const isDisplayNotificationsEnabled = getDisplayTimeoutNotifications(getState());
  try {
    const request = getRequest(getState(), traceId);
    if (request !== undefined) {
      const str = AppTranslation.t(
        'errors.websocket.no_response_received.message',
        'Response for "{{messageUrl}}" was not received',
        { messageUrl: `${request.url}` },
      );
      console.error(str, request);
      handleTimedOutRequest(request, dispatch);
      if (isDisplayNotificationsEnabled) {
        notifyTop('error', AppTranslation.t('errors.websocket.no_response_received', 'No response'), str);
      }
    }
  } catch (e) {
    console.error('saga failed', e);
    throw e;
  }
};
