/* eslint-disable max-len */
import React from 'react';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { SPlateCard } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateThumbnailSection/PlateThumbnailCard/PlateThumbnailCard.styled';
import { Flex, Image, URL } from 'UI';

const RenderImage = ({
  imageKey,
  catalogSource,
  selectedIndex,
}: {
  imageKey: string;
  catalogSource: CatalogSource;
  selectedIndex: number;
}) => {
  return (
    <Image
      src={imageKey}
      type={URL}
      catalogSource={catalogSource}
      alt={`product-thumbnail-${selectedIndex}`}
      height={'86px'}
      width={'120px'}
      fallbackComponent={<Image alt={'default-product-thumbnail'} src={'catalog/product-placeholder.jpg'} />}
    />
  );
};

export const ProductThumbnailCard = ({
  imageKey,
  active,
  onClick,
  index,
  catalogSource,
}: {
  imageKey: string;
  onClick: (e: any) => void;
  active: boolean;
  index: number;
  catalogSource: CatalogSource;
}) => {
  return (
    <div onClick={onClick}>
      <Flex>
        <SPlateCard active={active} className={'product_id' + index}>
          <RenderImage imageKey={imageKey} selectedIndex={index} catalogSource={catalogSource} />
        </SPlateCard>
      </Flex>
    </div>
  );
};
