import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowAltCircleUpIcon, AngleUpIcon } from 'assets/icons';
import { theme } from 'styles';
import { Box, Icon, Text } from 'UI';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { ScrollButton } from './BackToTopButton.styled';

function useScrollToTop() {
  const [scroll, setScroll] = useState(0);

  function onScroll() {
    setScroll(scrollY);
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return scroll;
}

export default function BackToTopButton() {
  const scroll = useScrollToTop();

  if (scroll === 0) {
    return null;
  }

  return (
    <ScrollButton>
      <Icon
        onClick={() => scrollToTopSmooth()}
        IconComponent={ArrowAltCircleUpIcon}
        color={theme.color.grey75}
        hoverFill={theme.color.grey55}
        size={48}
      />
    </ScrollButton>
  );
}

const SBackToTopButtonSm = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.grey95};
`;

export const BackToTopButtonSm = () => {
  const scroll = useScrollToTop();

  if (scroll === 0) {
    return null;
  }

  return (
    <SBackToTopButtonSm>
      <Text type={'light_12'}>Back to top</Text>
      <Box width={10} />
      <Icon
        display={'flex'}
        onClick={() => scrollToTopSmooth()}
        IconComponent={AngleUpIcon}
        color={theme.color.clear_blue}
        hoverFill={theme.color.clear_blue_light_120}
        size={10}
      />
    </SBackToTopButtonSm>
  );
};
