interface RoutineVehicleMaintenanceItem {
  src: string;
  width: number;
  offsetX: number;
  offsetY: number;
}

export const routineVehicleMaintenanceItems: Record<string, RoutineVehicleMaintenanceItem> = {
  'N-21375': {
    src: 'WindowsCombined.png',
    width: 250,
    offsetX: 590,
    offsetY: -10,
  },
  'N-21376': {
    src: 'DoorMirror.png',
    width: 100,
    offsetX: 320,
    offsetY: 0,
  },
  'N-21377': {
    src: 'FrontExteriorLighting.png',
    width: 110,
    offsetX: 30,
    offsetY: 180,
  },
  'N-21379': {
    src: 'RearExteriorLighting.png',
    width: 150,
    offsetX: 1005,
    offsetY: 190,
  },
  'N-21380': {
    src: 'FrontWipers.png',
    width: 130,
    offsetX: 460,
    offsetY: -15,
  },
  'N-21381': {
    src: 'RearWiper.png',
    width: 105,
    offsetX: 900,
    offsetY: 0,
  },
  'N-21382': {
    src: 'CabinFilter.png',
    width: 80,
    offsetX: 560,
    offsetY: 110,
  },
  'N-21383': {
    src: '12VBattery.png',
    width: 110,
    offsetX: 160,
    offsetY: 210,
  },
  'N-21384': {
    src: 'FuseRelayCombined.png',
    width: 110,
    offsetX: 240,
    offsetY: 360,
  },
  'N-21385': {
    src: 'Wheel.png',
    width: 80,
    offsetX: 690,
    offsetY: 345,
  },
  'N-21386': {
    src: 'SuspensionFront.png',
    width: 57,
    offsetX: 660,
    offsetY: 165,
  },
  'N-21387': {
    src: 'SuspensionRear.png',
    width: 70,
    offsetX: 915,
    offsetY: 165,
  },
  'N-21388': {
    src: 'FrontBrakeCombined.png',
    width: 160,
    offsetX: 515,
    offsetY: 360,
  },
  'N-21389': {
    src: 'RearBrake.png',
    width: 150,
    offsetX: 900,
    offsetY: 320,
  },
  'N-21392': {
    src: 'OilFilter.png',
    width: 55,
    offsetX: 330,
    offsetY: 255,
  },
  'N-21393': {
    src: 'AirFilter.png',
    width: 80,
    offsetX: 210,
    offsetY: 125,
  },
  'N-21394': {
    src: 'FuelFilter.png',
    width: 75,
    offsetX: 430,
    offsetY: 275,
  },
  'N-21395': {
    src: 'TensionerBelt.png',
    width: 100,
    offsetX: 315,
    offsetY: 150,
  },
  'N-21396': {
    src: 'IgnitionCombined.png',
    width: 80,
    offsetX: 430,
    offsetY: 115,
  },
  'N-32467': {
    src: 'Clutch.png',
    width: 120,
    offsetX: 515,
    offsetY: 205,
  },
};
