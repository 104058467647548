import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import {
  ROUTER_CART,
  ROUTER_CATALOG_DH,
  ROUTER_CATALOG_DH_L3,
  ROUTER_HOME,
  ROUTER_IN_PROGRESS_ORDERS,
} from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import {
  getSeenPopinsLocal,
  setSeenHintsLocalStorage,
  setSeenPopinsLocalStorage,
} from 'domains/firstHelp/FirstHelp.api';
import {
  addCurrentPopinViewed,
  setShowHintPages,
  getCurrentPopinsViewed,
  getPopin,
  getSessionId,
  getShowFirstHelp,
  getStream,
  getStreamViewTimeStamp,
  resetCurrentStreamView,
  setSeenPopins,
  setShowFirstHelp,
  setShowFirstHelpIndex,
  setStreamViewTimeStamp,
} from 'domains/firstHelp/FirstHelp.store';
import { PageType } from 'domains/firstHelp/FirstHelp.types';
import { getTokenUser } from 'domains/user';
import { Box, Flex, MarginBox, Text, WhiteButton } from 'UI';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';

const FocusBox = styled.div<{ shadowWidth: number }>`
  position: relative;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 ${({ shadowWidth }) => shadowWidth}px rgba(0, 0, 0, 0.6);
  z-index: 9999;
  border: dashed 2px #adb3b9;
  margin-top: -2px;
  margin-bottom: -2px;
`;

const Wrapper = styled.div`
  padding: 10px;
`;

interface FirstHelpPopinProps {
  streamId: PageType;
  popinId: string;
  placement: 'top' | 'left' | 'bottom' | 'right' | 'bottomLeft' | 'bottomRight' | 'leftBottom' | 'rightBottom';
  children?: React.ReactNode;
  hideCounter?: boolean;
  skip?: boolean;
}

export const FirstHelpPopin = ({
  streamId,
  popinId,
  placement,
  children,
  hideCounter = false,
  skip = false,
}: FirstHelpPopinProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const sessionId = useSelector(getSessionId);
  const history = useHistory();
  const streamViewTimeStamp = useSelector(getStreamViewTimeStamp);
  const currentPopinsViewed = useSelector(getCurrentPopinsViewed);
  const userId = useSelector(getTokenUser)?.id ?? '';
  const stream = useSelector((state: RootState) => getStream(state, streamId));
  const { showStreamId, showIndex } = useSelector(getShowFirstHelp);
  const popin = useSelector((state: RootState) => getPopin(state, (stream && stream[showIndex]) ?? ''));
  const seenPopinsLocal = getSeenPopinsLocal(userId)?.get(streamId);
  const pageStickyOffset = useOffsetTop('CART');
  const isClose = popinId === 'close';

  const getCurrentPage = () => {
    if (history.location.pathname.startsWith(ROUTER_CATALOG_DH)) {
      return ROUTER_CATALOG_DH_L3;
    }
    switch (history.location.pathname) {
      case ROUTER_HOME:
        return ROUTER_HOME;
      case ROUTER_CART:
        return ROUTER_CART;
      case ROUTER_IN_PROGRESS_ORDERS:
        return ROUTER_IN_PROGRESS_ORDERS;
      default:
        return undefined;
    }
  };
  const currentPage = getCurrentPage();

  const getMainHeight = () => {
    const mainEl = document.getElementsByTagName('main');
    return mainEl[0] ? mainEl[0].clientHeight : 0;
  };

  useEffect(() => {
    if (
      stream &&
      showStreamId === streamId &&
      stream[showIndex] === popinId &&
      !seenPopinsLocal?.includes(popinId) &&
      !skip
    ) {
      dispatch(addCurrentPopinViewed(popinId));
    }
    // eslint-disable-next-line
  }, [dispatch, seenPopinsLocal, sessionId, streamId, showStreamId, showIndex, popinId, userId]);

  useEffect(() => {
    if (
      stream &&
      stream[0] === popinId &&
      stream[showIndex] === popinId &&
      !isClose &&
      showStreamId === streamId &&
      !skip
    ) {
      dispatch(setStreamViewTimeStamp(moment()));
    }
    // eslint-disable-next-line
  }, [popinId, showIndex, showStreamId]);

  useEffect(() => {
    const focusBox = document.getElementById('first_help_box');
    const top = focusBox?.getBoundingClientRect().top ?? 0;
    if (focusBox && (top > window.innerHeight || top < pageStickyOffset)) {
      focusBox.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });

  const close = () => {
    const streamViewSeconds = moment().second() - (streamViewTimeStamp?.second() ?? moment().second());
    const isFirstPopin = stream && stream[0] === popinId && currentPopinsViewed.size === 1;
    if (streamViewSeconds > 5 && (!isFirstPopin || stream.length === 1) && stream) {
      setSeenPopinsLocalStorage(userId, streamId, [...currentPopinsViewed]);
      dispatch(setSeenPopins([...currentPopinsViewed]));
    }
    dispatch(resetCurrentStreamView());
    dispatch(setShowFirstHelp('close'));
  };

  const visibleSwithchOff = useCallback(() => {
    reset();
    // eslint-disable-next-line
  }, [showStreamId]);

  const reset = () => {
    document.removeEventListener('click', visibleSwithchOff);
    if (currentPage) {
      setSeenHintsLocalStorage(userId, currentPage);
      dispatch(setShowHintPages([currentPage]));
    }
    dispatch(setShowFirstHelp(undefined));
  };

  useEffect(() => {
    if (isClose && showStreamId === 'close') {
      document.addEventListener('click', visibleSwithchOff);
    } else {
      document.removeEventListener('click', visibleSwithchOff);
    }
    // eslint-disable-next-line
  }, [showStreamId]);

  useEffect(() => {
    if (isClose && showStreamId === 'close') {
      const tooltipParent = document.getElementsByClassName('tooltip-close')[0]?.parentElement?.parentElement;
      tooltipParent?.style.setProperty('width', '0');
    }
  }, [isClose, showStreamId]);

  if (!stream || !showStreamId || showStreamId !== streamId || stream[showIndex] !== popinId || skip) {
    return <>{children}</>;
  }

  const getButton = () => {
    const previous = (
      <>
        {showIndex > 0 && (
          <WhiteButton size={'middle'} onClick={() => dispatch(setShowFirstHelpIndex(showIndex - 1))}>
            {t('common.action.previous', 'Previous')}
          </WhiteButton>
        )}
      </>
    );

    switch (showIndex) {
      case stream.length - 1: {
        return (
          <>
            <WhiteButton size={'middle'} onClick={isClose ? reset : close}>
              {t('common.action.close', 'Close')}
            </WhiteButton>
            {previous}
          </>
        );
      }
      default: {
        return (
          <>
            <WhiteButton size={'middle'} onClick={() => dispatch(setShowFirstHelpIndex(showIndex + 1))}>
              {t('common.action.next', 'Next')}
            </WhiteButton>
            {previous}
          </>
        );
      }
    }
  };

  const getCloseDescription = () => {
    switch (getCurrentPage()) {
      case ROUTER_HOME:
        return t('help.close.description.homepage', 'Click here to see the homepage features.');
      case ROUTER_CART:
        return t('help.close.description.basket', 'Click here to see the basket features.');
      case ROUTER_IN_PROGRESS_ORDERS:
        return t('help.close.description.order_history', 'Click here to see the order history features.');
      default:
        return t('help.close.description.catalog', 'Click here to see the catalog features.');
    }
  };

  const TTContent = () => {
    return (
      <Wrapper onMouseOver={() => undefined}>
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <Text type={'dark_12_white_bold'}>
              <strong>{popin?.title}</strong>
            </Text>
            <Flex direction={'row-reverse'}>
              <Text type={'dark_12_white_bold'} hoverUnderLine cursor={'pointer'} onClick={isClose ? reset : close}>
                <strong>X</strong>
              </Text>
            </Flex>
          </Flex>
          <Box height={12} />
          <Text type={'dark_14_white_100'}>{isClose ? getCloseDescription() : popin?.description}</Text>
          <Box height={12} />
          <Flex direction={'row'} align={'center'}>
            <MarginBox mr={15}>{!hideCounter && `${showIndex + 1}/${stream.length}`}</MarginBox>
            <Flex direction={'row-reverse'}>{getButton()}</Flex>
          </Flex>
        </Flex>
      </Wrapper>
    );
  };

  return (
    <StopPropagation id={'first_help_box'}>
      <Tooltip
        title={TTContent}
        visible={true}
        color={'#333c45'}
        placement={placement}
        overlayStyle={{ minWidth: '320px' }}
        overlayClassName={`tooltip-${popinId}`}
      >
        <FocusBox shadowWidth={isClose ? 0 : Math.max(window.innerWidth, getMainHeight())}>{children}</FocusBox>
      </Tooltip>
    </StopPropagation>
  );
};

export default FirstHelpPopin;
