import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Loader from 'components/Loader';
import { getTokenUser, getUserProfileSearchStatus, getUserRights, UserRole } from 'domains/user';
import { isLoading } from 'utils';
import { Country } from 'utils/i18n/Country';
import { doesUserBelongToCountry, hasUserAnyRight } from 'utils/rights';

export interface PrivateRouteProps {
  render: () => JSX.Element;
  requiredRights?: UserRole[];
  requiredCountries?: Country[];
}

const PrivateComponent = ({ render, requiredRights = [], requiredCountries = [], ...props }: PrivateRouteProps) => {
  const userRights = useSelector(getUserRights);
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const userCountry = Country.findByKey(useSelector(getTokenUser)?.country);

  return (
    <>
      {isLoading(searchStatus) || isLoading(userRights) ? (
        <Loader />
      ) : (
        <Route
          {...props}
          render={() => {
            const isAuthorizedUser = hasUserAnyRight(requiredRights, userRights);
            const isAuthorizedCountry = doesUserBelongToCountry(requiredCountries, userCountry);
            return isAuthorizedUser && isAuthorizedCountry ? render() : <></>;
          }}
        />
      )}
    </>
  );
};

export default PrivateComponent;
