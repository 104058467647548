/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import { RootState } from 'app/AppStore';
import { getPromotionsForTodayEnabled } from 'domains/appContext/AppContext.store';
import {
  getDuplicatePromotions,
  hasCreatePromotionAnyProductsFilled,
  initialCreatePromotion,
  validateProductsRequestSaga,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionStepProps, isFlashPromotion } from 'domains/promotion/Promotion.types';
import { getUserRights, UserRole } from 'domains/user';
import { isFilled } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion';
import { PromotionVisual } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/DetailsStep/PromotionVisual';
import { Box, DatePicker, Flex, InputNumber, MarginBox, Radio, StepButtons, Text } from 'UI';
import {
  addDayToMoment,
  getMomentDate,
  getStringDateFromMoment,
  getStringMaxDate,
  getStringMinDate,
  subtractDayFromMoment,
} from 'utils/date';
import { DuplicatePromotionsDisplay } from './DuplicatePromotionsDisplay';
import { AdditionalPromotionComponent } from '../AdditionalPromotionComponent';

interface DetailsStepProps extends CreatePromotionStepProps {
  promotionStatus: StatusType | undefined;
}

export const DetailsStep = ({
  promotion,
  setPromotion,
  currentStep,
  setCurrentStep,
  totalSteps,
  validateStep,
  promotionStatus,
}: DetailsStepProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const promotionsForTodayEnabled = useSelector(getPromotionsForTodayEnabled);
  const duplicatePromotions = useSelector((state: RootState) =>
    getDuplicatePromotions(state, promotion.duplicatePromotionIds),
  );
  const isStartDateOverlapping = !!duplicatePromotions?.some((dpr) => {
    const duplicatePromotionStart = getStringDateFromMoment(getMomentDate(dpr.publicationStart)) ?? '';
    const duplicatePromotionEnd =
      getStringDateFromMoment(subtractDayFromMoment(getMomentDate(dpr.publicationEnd))) ?? '';
    const promotionStart = promotion.publicationStart ?? '';
    const promotionEnd = getStringDateFromMoment(subtractDayFromMoment(getMomentDate(promotion.publicationEnd))) ?? '';
    return (
      promotionStart >= duplicatePromotionStart ||
      (promotionStart >= duplicatePromotionStart && promotionStart <= duplicatePromotionEnd) ||
      (promotionStart <= duplicatePromotionStart && promotionEnd > duplicatePromotionEnd)
    );
  });

  const isEndDateOverlapping = !!duplicatePromotions?.some((dupr) => {
    const duplicatedPromotionStart =
      getStringDateFromMoment(getMomentDate(dupr.publicationStart)) ?? getStringMinDate();
    const duplicatedPromotionEnd =
      getStringDateFromMoment(subtractDayFromMoment(getMomentDate(dupr.publicationEnd))) ?? getStringMaxDate();
    const promStart = promotion.publicationStart ?? getStringMinDate();
    const promEnd =
      getStringDateFromMoment(subtractDayFromMoment(getMomentDate(promotion.publicationEnd))) ?? getStringMaxDate();
    return (
      promEnd <= duplicatedPromotionEnd || (promEnd >= duplicatedPromotionEnd && promStart <= duplicatedPromotionStart)
    );
  });

  const getDetailItems = () => {
    switch (promotion.promotionType) {
      case 'PROMOTION_FLASH':
        return <>{renderFlashTypeSelector()}</>;
      case 'PROMOTION_FLASH_TIME_LIMITED':
        return (
          <>
            {renderFlashTypeSelector()}
            {renderPublicationDate()}
            {renderTemplate()}
          </>
        );
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
        return (
          <>
            {renderFlashTypeSelector()}
            {renderQuantity()}
            {renderPublicationDate()}
          </>
        );
      case 'BANNER':
        return (
          <>
            {renderPublicationDate()}
            {renderVisual()}
          </>
        );
      default:
        return (
          <>
            {renderPublicationDate()}
            {renderTemplate()}
          </>
        );
    }
  };

  const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
    const momentToCompare =
      promotionsForTodayEnabled || promotion.promotionType === 'BANNER' ? moment().subtract(1, 'd') : moment();
    return current && current < momentToCompare.endOf('day');
  };
  const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
    const startDate = moment(promotion.publicationStart);
    if (promotion.promotionType === 'PROMOTION_FLASH_TIME_LIMITED') {
      return (
        current && !(current > startDate.subtract(1, 'd').endOf('day') && current < startDate.add(10, 'd').endOf('day'))
      );
    }
    return current && current < startDate.subtract(1, 'd').endOf('day');
  };

  const renderFlashTypeSelector = () => {
    return (
      <Flex direction={'column'}>
        <MarginBox mt={45} />
        <Text type={'h2'}>{t('backoffice.promotion.promotion_flash_type', 'Flash promotion type')}</Text>
        <Text type={'text'}>
          {t(
            'backoffice.promotion.promotion_flash_type.description',
            'Create a commercial animation with limited time or on a specific references quantity. Period 10 days max',
          )}
        </Text>
        <MarginBox mt={30} />
        <Flex direction={'row'}>
          <Radio
            disable={promotion.isSaved || promotion.editing}
            onChange={() => {
              setPromotion({
                ...promotion,
                promotionType: 'PROMOTION_FLASH_TIME_LIMITED',
                containsCustomImage: true,
                quantityLimitFlashPromotion: undefined,
              });
            }}
            checked={promotion.promotionType === 'PROMOTION_FLASH_TIME_LIMITED'}
            label={t('backoffice.promotion.promotion_flash_time_limited', 'Limited time')}
          />
          <Box width={30} />
          <Radio
            disable={promotion.isSaved || promotion.editing}
            onChange={() =>
              setPromotion({
                ...promotion,
                promotionType: 'PROMOTION_FLASH_QUANTITY_LIMITED',
                containsCustomImage: false,
              })
            }
            checked={promotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED'}
            label={t('backoffice.promotion.promotion_flash_quantity_limited', 'Quantity limited to')}
          />
        </Flex>
      </Flex>
    );
  };

  const renderQuantity = () => {
    return (
      <Flex direction={'column'}>
        <MarginBox mt={30} />
        <Flex maxWidth={100}>
          <InputNumber
            disabled={promotion.editing}
            onBlur={(quantity) => {
              setPromotion({
                ...promotion,
                quantityLimitFlashPromotion: quantity ?? 0,
              });
            }}
            value={promotion.quantityLimitFlashPromotion !== 0 ? promotion.quantityLimitFlashPromotion : undefined}
            placeholder={'-'}
            min={0}
            max={1000000}
          />
        </Flex>
      </Flex>
    );
  };

  const renderPublicationDate = () => {
    return (
      <>
        <MarginBox mt={45} />
        <Text type={'h2'}>{t('backoffice.publication_date', 'Publication date')}</Text>
        <Text type={'text'}>
          {t(
            'backoffice.promotion.publication_date.description',
            'Please enter your desired promotion period, by selecting start and end date.',
          )}
        </Text>
        <MarginBox mt={15} />
        <DuplicatePromotionsDisplay
          promotion={promotion}
          setCurrentStep={setCurrentStep}
          promotionStatus={promotionStatus}
        />
        <Flex>
          <DatePicker
            value={getMomentDate(promotion.publicationStart)}
            disabled={promotion.editing}
            onChange={(newValue) => {
              const endMoment = getMomentDate(promotion.publicationEnd);
              const isEndDateStillValid = endMoment && newValue ? endMoment >= newValue : false;
              const newStart = getStringDateFromMoment(newValue);
              const updatedStart = {
                ...promotion,
                publicationStart: newStart,
                publicationEnd: isEndDateStillValid ? promotion.publicationEnd : undefined,
              };
              setPromotion(updatedStart);
              if (
                newValue &&
                hasCreatePromotionAnyProductsFilled(updatedStart) &&
                (endMoment || promotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED')
              ) {
                dispatch(validateProductsRequestSaga({ promotion: updatedStart }));
              }
            }}
            displayStyle={isStartDateOverlapping ? 'error' : undefined}
            disabledDate={disabledStartDate}
            placeholder={t('common.date.starting', 'Starting')}
          />
          <MarginBox ml={60}>
            <DatePicker
              disabled={!isFilled(promotion.publicationStart)}
              disabledDate={disabledEndDate}
              value={subtractDayFromMoment(getMomentDate(promotion.publicationEnd))}
              onChange={(newValue) => {
                const updatePromotion = {
                  ...promotion,
                  publicationEnd: getStringDateFromMoment(addDayToMoment(newValue)),
                };
                setPromotion(updatePromotion);
                if (newValue && hasCreatePromotionAnyProductsFilled(updatePromotion)) {
                  dispatch(validateProductsRequestSaga({ promotion: updatePromotion }));
                }
              }}
              placeholder={t('common.date.until', 'Until')}
              displayStyle={isEndDateOverlapping ? 'error' : undefined}
            />
          </MarginBox>
        </Flex>
      </>
    );
  };
  const renderVisual = () => {
    return (
      <>
        {promotion.containsCustomImage && (
          <>
            <MarginBox mt={30} />
            <Text type={'h2'}>
              {promotion.promotionType === 'DISCOUNT' && userRights.includes(UserRole.COUNTRY_ADMIN)
                ? t('backoffice.promotion.add_primary_visual', 'Add your primary visual')
                : t('backoffice.promotion.add_visual', 'Add your visual')}
            </Text>
            <MarginBox mt={30} />
            <AdditionalPromotionComponent
              promotionType={promotion.promotionType}
              defaultComponent={() => (
                <PromotionVisual
                  promotionId={promotion.promotionId}
                  editing={promotion.editing}
                  image={promotion.image}
                  handleSetFileBase64={(fileName: string, imageBase64: string) =>
                    setPromotion({
                      ...promotion,
                      image: {
                        base64: imageBase64,
                        name: fileName,
                        extension: fileName.split('.').pop() ?? '',
                      },
                    })
                  }
                  handleSetNoFile={() =>
                    setPromotion({
                      ...promotion,
                      image: undefined,
                    })
                  }
                />
              )}
              additionalTitle={t('backoffice.promotion.additional_visual', 'Additional visual')}
              additionalComponent={() => (
                <PromotionVisual
                  promotionId={promotion.promotionId}
                  editing={promotion.editing}
                  image={promotion.additionalInformation?.image}
                  handleSetFileBase64={(fileName: string, imageBase64: string) =>
                    setPromotion({
                      ...promotion,
                      additionalInformation: {
                        ...promotion.additionalInformation,
                        image: {
                          base64: imageBase64,
                          name: fileName,
                          extension: fileName.split('.').pop() ?? '',
                        },
                      },
                    })
                  }
                  handleSetNoFile={() =>
                    setPromotion({
                      ...promotion,
                      additionalInformation: {
                        ...promotion.additionalInformation,
                        image: undefined,
                      },
                    })
                  }
                />
              )}
              handleAddAdditionalComponent={() =>
                setPromotion({
                  ...promotion,
                  additionalInformation: { ...promotion.additionalInformation, containsAdditionalImage: true },
                })
              }
              handleRemoveAdditionalComponent={() =>
                setPromotion({
                  ...promotion,
                  additionalInformation: {
                    ...promotion.additionalInformation,
                    containsAdditionalImage: false,
                    image: undefined,
                  },
                })
              }
              showAdditionalComponent={promotion.additionalInformation.containsAdditionalImage}
            />
          </>
        )}
      </>
    );
  };

  const renderTemplate = () => {
    return (
      <>
        <MarginBox mt={45} />
        <Text type={'h2'}>{t('backoffice.promotion.select_template', 'Select template')}</Text>
        <Text type={'text'}>
          {t(
            'backoffice.promotion.select_template.description',
            "You can either create a promotion push with or without visual that will be displayed on the Rpartstore's website.",
          )}
        </Text>
        <MarginBox mt={15} />
        <Radio
          label={t('backoffice.promotion.with_visual', 'Create push with visual')}
          checked={promotion.containsCustomImage}
          onChange={() => setPromotion({ ...promotion, containsCustomImage: true })}
        />
        <MarginBox mt={15} />
        <Radio
          label={t('backoffice.promotion.without_visual', 'Promote references without visual')}
          checked={!promotion.containsCustomImage}
          onChange={() => setPromotion({ ...promotion, containsCustomImage: false })}
        />
        {renderVisual()}
      </>
    );
  };
  return (
    <>
      <MarginBox mt={45} />
      <Text type={'h2'}>{t('backoffice.promotion.promotion_type', 'Promotion type')}</Text>
      <MarginBox mt={30} />
      <Flex>
        <Radio
          disable={promotion.isSaved || promotion.editing || promotion.copyPromotion}
          onChange={() => setPromotion({ ...initialCreatePromotion(), promotionType: 'DISCOUNT' })}
          checked={promotion.promotionType === 'DISCOUNT'}
          label={t('backoffice.promotion.discount', 'Discount')}
        />
        <Box width={30} />
        <Radio
          disable={promotion.isSaved || promotion.editing || promotion.copyPromotion}
          onChange={() => {
            if (!isFlashPromotion(promotion.promotionType)) {
              setPromotion({
                ...initialCreatePromotion(),
                promotionType: 'PROMOTION_FLASH',
              });
            }
          }}
          checked={
            promotion.promotionType === 'PROMOTION_FLASH' ||
            promotion.promotionType === 'PROMOTION_FLASH_TIME_LIMITED' ||
            promotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED'
          }
          label={t('backoffice.promotion.promotion_flash', 'Promotion flash')}
        />
        <Box width={30} />
        <Radio
          disable={true}
          onChange={() => setPromotion({ ...promotion, promotionType: 'LOAYALITY_POINTS' })}
          checked={promotion.promotionType === 'LOAYALITY_POINTS'}
          label={t('backoffice.promotion.loyalty_points', 'Loyalty points')}
        />
        <Box width={30} />
        <Radio
          disable={true}
          onChange={() => setPromotion({ ...promotion, promotionType: 'ADVANTAGE_CODE' })}
          checked={promotion.promotionType === 'ADVANTAGE_CODE'}
          label={t('backoffice.promotion.advantage_code', 'Advantage code')}
        />
        <Box width={30} />
        <Radio
          disable={true}
          onChange={() => setPromotion({ ...promotion, promotionType: 'GIVE_AWAY' })}
          checked={promotion.promotionType === 'GIVE_AWAY'}
          label={t('backoffice.promotion.give_away', 'Give away')}
        />
        <Box width={30} />
        <Radio
          disable={promotion.isSaved || promotion.editing || promotion.copyPromotion}
          onChange={() => setPromotion({ ...initialCreatePromotion(), promotionType: 'BANNER' })}
          checked={promotion.promotionType === 'BANNER'}
          label={t('backoffice.promotion.banner', 'Banner')}
        />
      </Flex>
      {getDetailItems()}
      <MarginBox mt={45} />
      <Flex>
        <StepButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalItems={totalSteps}
          nextEnabled={validateStep()}
        />
      </Flex>
    </>
  );
};
