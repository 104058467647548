import styled, { css } from 'styled-components';
import { Flex } from 'UI';

const hoverMenuItem = css`
  background-color: ${({ theme }) => theme.color.grey95};
`;

const activeMenuItem = css`
  ${hoverMenuItem};
  border-right: solid 5px ${({ theme }) => theme.color.brand};
`;

export const MenuItemFlex = styled(Flex)<{ $isActive: boolean }>`
  background-color: white;

  ${({ $isActive }) => ($isActive ? activeMenuItem : null)}
  :hover {
    ${hoverMenuItem};
    cursor: pointer;
  }
`;
