import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { ALL, SUMMER, SUMMER_WINTER, WINTER } from 'domains/tires/Tire.types';

interface SearchParams {
  searchParamsBase64: string;
  tireSearchParams: string;
  width: string;
  series: string;
  diameter: string;
  chargeIndicator: string;
  doubleChargeIndicator?: string;
  speedIndicator: string;
}

interface TireSearchQuery {
  chargeIndicator?: string;
  doubleChargeIndicator?: string;
}

export function getQueryWithDoubleChargeIndicator(
  regExp: RegExpMatchArray | undefined,
  separator?: string,
): TireSearchQuery {
  if (!regExp || regExp.index === undefined || !separator) {
    return {};
  }
  const availableLoadIndexes = regExp[0]?.split(separator) ?? [];
  if (availableLoadIndexes.length <= 1 || availableLoadIndexes[0].startsWith(availableLoadIndexes[1])) {
    return { chargeIndicator: availableLoadIndexes[0] };
  }
  return { chargeIndicator: availableLoadIndexes[0], doubleChargeIndicator: availableLoadIndexes[1] };
}

export const toSearchParams = (
  query: string,
  season: string | undefined,
  brands: string[],
  chargeIndicatorSeparator?: string,
): SearchParams => {
  const MAX_LEN_WHEN_DOUBLE_LOAD = 6;
  const MAX_LEN_WHEN_NODOUBLE_LOAD = 5;
  const params = query.split(' ');
  const width = params[0] ?? '';
  const series = params[1] ?? '';
  const diameter = params[2] ?? '';
  const chargeIndicator = params.length >= 4 ? params[3] : '';
  const speedIndicator = (() => {
    switch (params.length) {
      case MAX_LEN_WHEN_NODOUBLE_LOAD:
        return params[MAX_LEN_WHEN_NODOUBLE_LOAD - 1];
      case MAX_LEN_WHEN_DOUBLE_LOAD:
        return params[MAX_LEN_WHEN_DOUBLE_LOAD - 1];
      default:
        return '';
    }
  })();

  const { chargeIndicator: newChargeIndicator, doubleChargeIndicator } = getQueryWithDoubleChargeIndicator(
    RegExp(String.raw`\d+${chargeIndicatorSeparator}\d+`).exec(query) ?? undefined,
    chargeIndicatorSeparator,
  );

  const allBrands = brands.join('-');
  const rawInitialChargeIndicator = chargeIndicator ? `-${chargeIndicator}` : '';
  const rawNewChargeIndicator = newChargeIndicator ? `-${newChargeIndicator}` : '';
  const rawChargeIndicator = rawNewChargeIndicator.length > 0 ? rawNewChargeIndicator : rawInitialChargeIndicator;
  const rawDoubleChargeIndicator = doubleChargeIndicator ? `-${doubleChargeIndicator}` : '';
  const rawSpeedIndicator = speedIndicator ? `-${speedIndicator}` : '';

  // eslint-disable-next-line max-len
  const tireSearchParams = `${width}-${series}-${diameter}${rawChargeIndicator}${rawDoubleChargeIndicator}${rawSpeedIndicator}`.toUpperCase();
  const allRequestParams = `${allBrands}-${season}-${tireSearchParams}`;

  return {
    searchParamsBase64: Buffer.from(allRequestParams).toString('base64'),
    tireSearchParams,
    width,
    series,
    diameter,
    chargeIndicator: newChargeIndicator && newChargeIndicator.length > 0 ? newChargeIndicator : chargeIndicator,
    doubleChargeIndicator,
    speedIndicator,
  };
};

export function convertDHTireTechnicalDesignationToSearchQuery(
  technicalDesignation: string | undefined,
): string | undefined {
  if (!technicalDesignation) {
    return undefined;
  }
  return technicalDesignation.replace('/ ', '').replace('R ', 'R').replace('Z ', 'Z');
}

export function convertDHTireSizeToSearchQuery(technicalDesignation: string | undefined): string | undefined {
  if (!technicalDesignation) {
    return undefined;
  }
  return technicalDesignation
    .split(',')?.[0]
    ?.replace('/', ' ')
    .replace(')', ' ')
    .replace('(', '')
    .replace('CP', '')
    .replace('C', '');
}

export function convertIAMTireSizeToSearchQuery(tireSize: string | undefined): string | undefined {
  if (!tireSize) {
    return undefined;
  }

  const lastChar = tireSize.at(-1) ?? '';
  if (lastChar?.match(/[a-zA-Z]/)) {
    tireSize = tireSize.replace(lastChar, ` ${lastChar}`);
  }

  return tireSize.replace('/', ' ').replace('R', ' R').replace('Z', ' Z');
}

export function mapDHTireUsageToSeason(usage: string | undefined): Season {
  switch (usage) {
    case 'Summer':
      return SUMMER;
    case 'Winter':
      return WINTER;
    case 'Summer and winter':
      return SUMMER_WINTER;
    default:
      return ALL;
  }
}

export function mapIAMTireUsageToSeason(usage: string | undefined): Season {
  switch (usage) {
    case 'Summer and winter':
      return SUMMER_WINTER;
    case 'winter only':
      return WINTER;
    case 'Summer':
      return SUMMER;
    default:
      return ALL;
  }
}
