import React from 'react';
import { Box, Container, Flex, Image, Link, MarginBox, Text } from 'UI';

export const TermsAndConditionsFR = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>CONDITIONS GENERALES D’UTILISATION</Text>
        </Flex>
        <MarginBox mt={30} />
        <Text type={'text'}>
          Les présentes Conditions Générales d&apos;Utilisation (ci-après &quot; CGU &quot;) régissent l&apos;accès et
          l&apos;utilisation du service RPARTSTORE, mis à disposition par Renault, SAS au capital de 533 941 113 € dont
          le siège social est situé 122-122 bis avenue du Général Leclerc - 92100 Boulogne-Billancourt, inscrite au
          Registre du Commerce de Nanterre sous le n° 780 129 987 (ci-après &quot;RENAULT&quot;).
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L’UTILISATEUR EST TENU DE LIRE ATTENTIVEMENT LES PRÉSENTES CONDITIONS SUR LE SITE AVANT DE LES ACCEPTER ET
          D&apos;ACCÉDER AUX SERVICES.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 1.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Désignation de la Plateforme</Text>
        </Flex>
        <MarginBox mt={15} />
        <Box>
          <Text type={'text'}>Le site internet RPARTSTORE (ci-après la « </Text>
          <Text type={'text_bold'}>Plateforme</Text>
          <Text type={'text'}>
            {' '}
            »), a été constitué afin de faciliter la mise en relation de vendeurs (ci-après les «{' '}
          </Text>
          <Text type={'text_bold'}>Vendeurs</Text>
          <Text type={'text'}> ») qui souhaitent offrir à la vente des pièces et des accessoires (ci-après les « </Text>
          <Text type={'text_bold'}>Produits</Text>
          <Text type={'text'}>
            {' '}
            ») par l’intermédiaire d’un site internet à destination d’acheteurs (ci-après les «{' '}
          </Text>
          <Text type={'text_bold'}>Acheteurs</Text>
          <Text type={'text'}> »), conformément aux présentes conditions générales d’utilisation (ci-après les « </Text>
          <Text type={'text_bold'}>CGU</Text>
          <Text type={'text'}> »).</Text>
        </Box>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Les services de la Plateforme sont accessibles à l’adresse électronique suivante :{' '}
          <Link to={'https://rpartstore.renault.com'} external newTab>
            https://rpartstore.renault.com
          </Link>
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 2.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Qualité d’hébergeur</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme intervient comme intermédiaire technique. Elle offre aux Vendeurs et aux Acheteurs (&quot;les
          Utilisateurs&quot;) des services techniques de stockage et de transport de données au sens de la
          réglementation.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Le rôle de la Plateforme est purement technique et se limite à faciliter les mises en relation des Vendeurs
          avec les Acheteurs.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme n’assume aucune garantie ou aucune responsabilité au titre des conventions, échanges, offres ou
          communications diverses qui pourraient avoir lieu par son entremise.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme n’est en aucun cas vendeur ou revendeur des Produits proposés par les Vendeurs par
          l’intermédiaire de la Plateforme.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Les transactions relatives aux Produits offerts par les Vendeurs sont toujours conclues directement entre les
          Vendeurs et les Acheteurs, en dehors de la Plateforme. Le Vendeur peut simplement générer des contacts
          commerciaux (lead form) via la Plateforme. Le prix final des Produits est donné par le Vendeur en dehors la
          Plateforme.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 3.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Objet des conditions générales d’utilisation</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Les présentes conditions générales d’utilisation ont pour objet de définir les conditions et les modalités
          d’accès et d’utilisation de la Plateforme par les Utilisateurs.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme a pour objet de créer une relation digitale directe entre les Vendeurs et les Acheteurs pour
          générer des contacts commerciaux entre eux.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 4.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Conditions d’accès à la Plateforme pour les Acheteurs</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>L’accès à la Plateforme suppose :</Text>
        <MarginBox mt={15} />
        <ul>
          <li>
            <Text type={'text'}>La demande par les Vendeurs d’adhésion des Acheteurs via le mini-site d’adhésion</Text>
          </li>
          <li>
            <Text type={'text'}>La validation par les Vendeurs des accès des Acheteurs à la Plateforme</Text>
          </li>
        </ul>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Le Mini-site d’adhésion est une application e-business permettant aux Vendeurs de faire, par eux-mêmes, les
          demandes d’adhésion à RPARTSTORE pour leurs clients Acheteurs. L’application est accessible depuis RENAULTNET
          et RPARTSTORE.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;accès au mini-site d’adhésion est accordé par le centre d’appel joignable au numéro suivant :
          0805980450 (A ADAPTER PAR PAYS); au Vendeur qui le demande à la condition qu&apos;il soit individuellement
          référencé dans le réseau Renault.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Le Mini-site d’adhésion permet d’accélérer le processus d’adhésion et permet également aux Vendeurs de faire
          des demandes d’adhésion pour leurs clients quel que soit la nature de l’utilisateur au niveau de TRESOR et de
          RPARTSTORE (Client inconnu dans TRESOR, ré adhésion, nouvel utilisateur).
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Le Vendeur peut effectuer des demandes d’adhésion pour ses clients conformément aux dispositions de l’article
          4 ci-dessus. Si son client n’est pas identifié dans la base TRESOR, une demande de création/modification sera
          automatiquement envoyée à l’administrateur TRESOR concerné.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Le Vendeur peut modifier les coordonnées du client et peut suivre l’avancement de ses demandes d’adhésion. Il
          ne peut pas modifier une demande d’adhésion ouverte, mais il peut en demander la fermeture. C’est donc le
          centre d’appel qui se chargera de réaliser la clôture finale.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 5.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Produits admis sur la Plateforme</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme sélectionne les types de produits pouvant être commandés par son intermédiaire, les Acheteurs ne
          pourront solliciter ou exiger des Vendeurs qu&apos;ils offrent, par l&apos;intermédiaire de la Plateforme,
          d&apos;autres produits que ceux que la Plateforme aura préalablement sélectionnés.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 6.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>
            Création d’un espace Acheteur - Confidentialité des identifiants et mots de passe
          </Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'} decoration={'underline'}>
          Les étapes:
        </Text>
        <MarginBox mt={15} />
        <ol>
          <li>
            <Text type={'text'}>
              Réception d’un email par le Vendeur confirmant que la demande est bien prise en compte, l’email indiquera
              également le temps de traitement de la demande qui varie entre 24h et 72h.
            </Text>
          </li>
          <li>
            <Text type={'text'}>
              L’Acheteur recevra ensuite par email les informations nécessaires (Identifiant / Mot de passe) à sa
              connexion à RPARTSTORE.
            </Text>
          </li>
          <li>
            <Text type={'text'}>
              Le Vendeur recevra également un message l’avertissant que son client a désormais accès à RPARTSTORE.
            </Text>
          </li>
          <li>
            <Text type={'text'}>En cas d’incident technique sur la demande, le Vendeur sera prévenu par email. </Text>
          </li>
        </ol>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L’utilisation de l’Espace Acheteur est strictement limitée à l’utilisateur désigné par le Vendeur.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur s’engage à conserver secret ses codes et à ne pas les divulguer sous quelque forme que ce soit
          et à quelque personne que ce soit et, en cas d’utilisation frauduleuse ou non autorisée, à les annuler et à
          signaler sans délai les faits au service client au 0805980450 (A ADAPTER PAR PAYS). L’Acheteur est informé que
          toute divulgation de ses codes à un tiers relève de sa seule responsabilité.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 7.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Relation personnalisée</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>Les Acheteurs ne peuvent pas solliciter tous les Vendeurs admis sur la Plateforme. </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L’Acheteur retrouvera sur la Plateforme son Vendeur correspondant au canal physique habituel ; son
          rattachement ne changera pas.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 8.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Paiement</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Il est entendu que seules des contacts commerciaux pouvant être opérées par la Plateforme, aucun paiement ne
          sera opéré via la Plateforme.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 9.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Disponibilités des services de la Plateforme</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Dans la limite des lois et règlements applicables, RENAULT décline toute responsabilité quant à
          l&apos;utilisation de la Plateforme par l’Utilisateur et ne donne aucune garantie, expresse ou implicite,
          quant à la rapidité et/ou la performance du Site ou quant à l’utilisation qui serait faite par le Client des
          données et/ou informations accessibles via la Plateforme.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          En particulier, RENAULT ne garantit pas que la Plateforme sera exempte d&apos;erreurs, de défauts ou
          d’omissions, ni qu&apos;elle ne fera l&apos;objet de dommages ou d&apos;attaques, notamment de virus, pouvant
          entraîner des dysfonctionnements, des interruptions de service, des pannes ou des pertes de données ou
          d&apos;informations.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;accès à la Plateforme peut être momentanément et périodiquement suspendu pour des raisons techniques,
          pour la résolution desquelles les équipes techniques mettront tout en œuvre dans les plus brefs délais le cas
          échéant.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>RENAULT sera exonérée de toute responsabilité :</Text>
        <MarginBox mt={15} />
        <ul>
          <li>
            <Text type={'text'}>
              En cas de non-respect par l’Utilisateur des stipulations des présentes Conditions Générales d’Utilisation
              ;
            </Text>
          </li>
          <li>
            <Text type={'text'}>
              En cas de dysfonctionnement de la Plateforme résultant soit d&apos;une cause, volontaire ou involontaire,
              imputable à l’Utilisateur ou à un tiers, soit d&apos;un acte malveillant, soit du dysfonctionnement d’un
              logiciel, smartphone, tablette PC ou toute autre interface/produit appartenant à l’Utilisateur ;
            </Text>
          </li>
          <li>
            <Text type={'text'}>En cas de modifications législatives ou réglementaires affectant la Plateforme.</Text>
          </li>
        </ul>
        <MarginBox mt={15} />
        <Text type={'text'}>
          En outre, l’Utilisateur reconnaît que la Plateforme pouvant être fournie par des réseaux de communication, la
          responsabilité de RENAULT ne pourra en aucun cas être engagée en cas de dysfonctionnement desdits réseaux de
          communication.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Lorsque l’Utilisateur utilise la Plateforme, il s&apos;engage à le faire conformément aux présentes Conditions
          Générales d’Utilisation ainsi que dans le respect des réglementations applicables.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L’Acheteur et le Vendeur sont entièrement responsables de l’utilisation du Site et de toute utilisation des
          données/informations reçues par le biais du Site, même lorsqu’ils ne sont pas la personne qui utilise ces
          données/informations.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 10.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Utilisation non préjudiciable de la Plateforme</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur doit en toutes circonstances s’abstenir de toute activité de nature à compromettre l’activité
          de la Plateforme, à nuire à sa réputation, à celle des services qu’elle fournit ainsi qu’à la réputation et
          aux activités du groupe Renault.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 11.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Intuitu personae</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme, constituée par le Groupe Renault, contribue à l’image, à la réputation, et à la notoriété du
          Groupe Renault. Les relations entre la Plateforme et ses Utilisateurs sont donc empreintes « d’intuitu
          personae ».
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 12.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Retrait, blocage, ou exclusion de la Plateforme</Text>
        </Flex>
        <MarginBox mt={30} />
        <Text type={'text_bold'}>a) Retrait</Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Tout Acheteur peut à tout moment notifier à la Plateforme via son R1 de rattachement, sa décision de ne plus
          recourir à ses services.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur devra continuer à assurer les obligations qu’il a souscrites envers les Vendeurs
          antérieurement à la date de cessation de sa participation à la Plateforme.
        </Text>
        <MarginBox mt={30} />
        <Text type={'text_bold'}>b) Blocage</Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme pourra procéder au blocage temporaire de l’accès d’un Acheteur, pour une durée maximale de 6
          mois, dans les cas suivants :
        </Text>
        <MarginBox mt={15} />
        <MarginBox ml={15}>
          <ol>
            <li>
              <Text type={'text'}>
                présomption sérieuse de comportements portant atteinte à la Plateforme, aux présentes C.G.U., au droit
                en vigueur, ou à l’image et à la réputation du groupe Renault ;
              </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>absence de notification des modifications des éléments déclarés ;</Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>
                utilisation de l’Espace Acheteur par des personnes extérieures à l&apos;Acheteur ;
              </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>
                négligence dans l’obligation de confidentialité des identifiants et mots de passe ;
              </Text>
            </li>
          </ol>
        </MarginBox>
        <Text type={'text'}>
          Pendant cette période, l&apos;Acheteur et la Plateforme s’efforceront de bonne foi de remédier aux
          comportements relevés.
        </Text>
        <MarginBox mt={30} />
        <Text type={'text_bold'}>c) Exclusion</Text>
        <MarginBox mt={15} />
        <Text type={'text'}>La Plateforme pourra procéder à l’exclusion définitive d’un Acheteur en cas de :</Text>
        <MarginBox mt={15} />
        <MarginBox ml={15}>
          <ol>
            <li>
              <Text type={'text'}>
                sortie, pour quelle que cause que ce soit, du réseau Renault admis sur la Plateforme ;
              </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>
                comportements visés au point b - 1) du présent article. La Plateforme aura donc une option entre le
                blocage et l’exclusion immédiate ;{' '}
              </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>
                fausse déclaration quelle que soit la gravité de celle-ci, car une fausse déclaration rompt la confiance
                ;{' '}
              </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>réitération des comportements visés aux points b°) 2) à 4) ci-dessus ; </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>plaintes répétées de la part des Vendeurs ; </Text>
            </li>
            <MarginBox mt={15} />
            <li>
              <Text type={'text'}>
                modification de la situation personnelle de l&apos;Acheteur de nature à affecter l’intuitu personae
                (notamment : changement de contrôle direct ou indirect, modification significative des productions ou
                des certifications, procédure d’insolvabilité dans le respect des lois en vigueur).
              </Text>
            </li>
          </ol>
        </MarginBox>
        <MarginBox mt={15} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 13.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Transactions commerciales – Secret des affaires</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme ne pourra en aucune manière permettre à des tiers quelconques (concurrents, associés,
          partenaires, etc.) d’avoir connaissance des échanges, négociations, contrats et conventions, poursuivis ou
          conclus par son intermédiaire. La Plateforme respectera en toute occasion son programme de confidentialité.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 14.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Promotions et publicités</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur s’interdit d’utiliser directement ou indirectement la Plateforme pour promouvoir directement
          ou indirectement, sous quelque forme que ce soit, ses produits ou services, de même que ceux de toute autre
          personne.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 15.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Offres concurrentes – Absence d’exclusivité</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L’adhésion à la Plateforme ne peut créer aucune obligation d&apos;y recourir à la charge des Acheteurs.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 16.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Modification des CGU</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme se réserve la possibilité de modifier les CGU. Toute modification prendra effet à la date qui
          figurera dans la notification qui en sera faite aux Acheteurs sur leurs espaces.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Les modifications ne s’appliqueront pas aux transactions qui seraient en cours à la date de leur entrée en
          vigueur.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 17.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Utilisation des données personnelles recueillies par la Plateforme</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Le service offert par la Plateforme fait l’objet d’un traitement automatisé dont la finalité est d’assurer la
          mise en relation des Vendeurs et des Acheteurs et la passation de commandes de Produits.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L’entrée en relation d’un Acheteur avec la Plateforme peut requérir la communication par l&apos;Acheteur de
          données personnelles concernant ses clients finaux. La communication de ces données est strictement nécessaire
          au service offert par la Plateforme.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme est « Responsable du traitement » des données personnelles au sens du règlement général 2016/679
          du Parlement et de la loi du 20 juin 2018 relative à la protection des données personnelles.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme communiquera à chaque personne physique dont les données sont nécessaires à l’exécution du
          service offert par la Plateforme, l’ensemble des informations règlementairement et légalement requises.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme s’engage à ne communiquer aucune des données personnelles recueillies à des entités extérieures
          au groupe Renault.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme garantit en tout état de cause le respect des dispositions du règlement général 2016/679 du 27
          avril 2016 relatif au traitement des données à caractère personnel et de la loi du 20 juin 2018 relative à la
          protection des données personnelles.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Les données personnelles pourront être conservées pendant une durée de 3 année[s] à compter de la fin des
          relations (données indirectement identifiantes telles que le VIN).
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>(A ADAPTER PAR PAYS)</Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 18.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Force majeure</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Tout évènement ayant les caractères de la force majeure, et ayant pour conséquence un dysfonctionnement de la
          Plateforme, ne peut engager la responsabilité de la Plateforme.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur accepte également de considérer que revêtiront contractuellement les caractères de la force
          majeure, notamment, les coupures électriques, les défaillances de réseaux Internet, les dysfonctionnements
          informatiques (« bugs », virus, malware, intrusions), même s’ils trouvent leur origine technique dans la
          conception du service information.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 19.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Confidentialité et preuve </Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          La Plateforme met en œuvre les moyens techniques adéquats pour assurer la confidentialité des informations et
          données transmises par son intermédiaire, ou des négociations réalisées par son entremise de manière à ce
          qu’elles ne soient pas transmises à des personnes extérieures à ses services.
        </Text>
        <MarginBox mt={30} />
        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 20.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Propriété intellectuelle</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Tous les éléments, tels que textes logos, images, éléments graphiques ou sonores, logiciels, mise en page,
          bases de données de la Plateforme, dénominations sociales, marques, dessins et modèles, sont protégés par des
          droits de propriété intellectuelle. Les Vendeurs doivent s’abstenir de tout acte portant atteinte d’une
          quelconque manière à ces droits.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Pour l’utilisation de la Plateforme, l&apos;Acheteur est autorisé à faire usage des marques et droits de
          propriété intellectuelle dont il a la disposition, ainsi que de son nom commercial ou de sa dénomination
          sociale.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur est autorisé à mentionner sur ses documents commerciaux sa qualité d&apos;Acheteur référencé
          par la Plateforme, à la condition qu’il ait soumis préalablement à la Plateforme les documents concernés.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Les Acheteurs bénéficient d’une licence limitée, non exclusive, non transférable, non sous licenciable,
          d’utilisation des logiciels et brevets permettant l’utilisation de la Plateforme.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>(A ADAPTER PAR PAYS)</Text>
        <MarginBox mt={30} />

        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 21.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Responsabilité de l&apos;Acheteur</Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>
          L&apos;Acheteur est seul responsable de la commande qu’il passe relativement aux produits et accessoires
          offerts à la vente sur la Plateforme.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Il est également seul responsable de l’utilisation de son Espace Acheteur et des informations données aux
          Vendeurs.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Plus généralement, il est seul responsable comme Acheteur, et la Plateforme ne peut être tenue pour
          responsable en qualité d&apos;Acheteur.
        </Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Pour le cas où un Vendeur, ou une personne quelconque, agirait en justice contre la Plateforme pour des faits
          qui relèveraient de son activité d&apos;Acheteur, celui-ci s’oblige à garantir la Plateforme de toute
          condamnation. Vendeurs.
        </Text>
        <MarginBox mt={30} />

        <Flex>
          <Text type={'text_bold'} decoration={'underline'}>
            Article 22.
          </Text>
          <MarginBox ml={15} />
          <Text type={'text_bold'}>Droit applicable – Litiges </Text>
        </Flex>
        <MarginBox mt={15} />
        <Text type={'text'}>Les présentes CGU sont régies par le droit français. (A ADAPTER PAR PAYS)</Text>
        <MarginBox mt={15} />
        <Text type={'text'}>
          Tous les litiges, quelle qu’en soit la cause, contractuelle ou délictuelle, qui trouveraient leur cause ou
          leur origine dans la validité, l’interprétation, l’exécution des présentes CGU ou, plus généralement, dans les
          services offerts par la Plateforme ou dans les relations entre les Vendeurs et la Plateforme, relèvera de la
          compétence des tribunaux de Paris. (A ADAPTER PAR PAYS)
        </Text>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Mise à jour le 30 mai 2023</Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'}>ANNEXE I</Text>
        <MarginBox mt={15} />
        <Text type={'h5_bold'}>INFORMATIONS « ACHETEURS » - PROCEDURE D’ACCES</Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-FR.jpg'} alt={'Information diagram'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
