import React from 'react';
import styled from 'styled-components';
import { ButtonProps, DropDownButton } from 'UI/Button';
import { Flex } from 'UI/Flex';

// eslint-disable-next-line react/display-name
const _Button = React.forwardRef(({ ...props }: ButtonProps, ref?: React.Ref<HTMLButtonElement>) => (
  <DropDownButton {...props} passRef={ref} />
));

export const SButton = styled(_Button)<{ width?: number | string }>`
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
`;

export const SCheckboxMenu = styled(Flex)<{ width?: number | string }>`
  box-shadow: 0 2px 8px ${({ theme }) => theme.color.grey75};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.color.white};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
`;
