import styled from 'styled-components';
import { Text } from 'UI';

export const SContainer = styled.div`
  background-color: ${({ theme }) => theme.color.blue_dark_3};
  color: ${({ theme }) => theme.color.white};
  padding-top: 45px;
  padding-bottom: 45px;
  height: 250px;
`;

export const SText = styled(Text)`
  :hover {
    color: ${({ theme }) => theme.color.white85};
  }
`;
