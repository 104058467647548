import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TireBrandDetail } from '@1po/1po-bff-fe-spec/generated/tire/model/TireBrandDetail';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTire';
import { VehicleTires } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { MprValidationResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/MprValidationResponse';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { Filters, ReferenceFilterTypes } from 'components/Filter/Filter.types';
import { NO_DATA, SearchData } from 'utils';

export const TIRE_NAMESPACE = `${APP_NAMESPACE}/TIRE`;
export const TIRE_SEARCH_PRODUCTS = `${TIRE_NAMESPACE}/TIRE_SEARCH_PRODUCTS`;
export const GET_IAM_TIRES_REQUEST = `${TIRE_NAMESPACE}/GET_IAM_TIRES_REQUEST`;
export const TIRES_FULLTEXT_SEARCH_REQUEST = `${TIRE_NAMESPACE}/TIRES_FULLTEXT_SEARCH_REQUEST`;
export const TIRES_UNIQUE_DIMENSIONS_SEARCH_REQUEST = `${TIRE_NAMESPACE}/TIRES_UNIQUE_DIMENSIONS_SEARCH_REQUEST`;
export const TIRE_BRAND_CATEGORIES_REQUEST = `${TIRE_NAMESPACE}/TIRE_BRAND_CATEGORIES_REQUEST`;
export const TIRE_REPLACEMENT_PRODUCTS_REQUEST = `${TIRE_NAMESPACE}/TIRE_REPLACEMENT_PRODUCTS_REQUEST`;
export const MPR_VALIDATION_REQUEST = `${TIRE_NAMESPACE}/MPR_VALIDATION_REQUEST`;

export const SUMMER = 'SUMMER' as Season;
export const WINTER = 'WINTER' as Season;
export const SUMMER_WINTER = 'SUMMER_WINTER' as Season;
export const ALL = 'ALL' as Season;

export type TireFilters = Filters;
export type TireSet = 1 | 2 | 4 | 6 | 8;

export interface SelectedTireProps {
  selectedTire: string | undefined;
  setSelectedTire: (tire: string | undefined) => void;
}

export interface CatalogTires extends ReferenceFilterTypes {
  tires?: TireItem[];
}

export interface IAMVehicleTires {
  sameSizes: VehicleTire[];
  differentSizes: VehicleTire[];
}

export interface TireSearchDimensions {
  chargeIndicator: string[];
  diameter: string[];
  width: string[];
  series: string[];
  speedIndicator: string[];
}

export interface TireSearchInputDimensions {
  chargeIndicator?: string;
  diameter?: string;
  width?: string;
  series?: string;
  speedIndicator?: string;
}

export const TIRE_BRAND_CATEGORY_PREMIUM = 'premium';
export const TIRE_BRAND_CATEGORY_MEDIUM = 'medium';
export const TIRE_BRAND_CATEGORY_ENTRY = 'entry';
export type TireBrandCategoryType =
  | typeof TIRE_BRAND_CATEGORY_PREMIUM
  | typeof TIRE_BRAND_CATEGORY_MEDIUM
  | typeof TIRE_BRAND_CATEGORY_ENTRY;

export interface TireBrandDetailLocal extends TireBrandDetail {
  category: TireBrandCategoryType;
}

export interface TireState {
  catalogTires: Map<string, SearchData<CatalogTires>>;
  vehicleTires: Map<string, VehicleTires | NO_DATA>;
  iamVehicleTires: Map<string, IAMVehicleTires | NO_DATA>;
  tireFilters: TireFilters;
  searchQuery: string;
  searchParamsBase64: string | undefined;
  tireSetParam: TireSet | undefined;
  fulltextSearch: SearchData<string[]>;
  searchDimensions: TireSearchDimensions | undefined;
  tireSearchInputDimensions: TireSearchInputDimensions | undefined;
  brandCategories: SearchData<Map<string, TireBrandDetailLocal>>;
  mprValidation: Map<string, SearchData<MprValidationResponse>>;
}
