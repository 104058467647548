import { useEffect } from 'react';

const TIMEOUT_DURATION = 200;

// TODO typing
// export type FocusDecoration = { current?: { focus?: () => void } };
// export type RefType<T> = RefObject<T>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type RefType<T> = any;

export function useFocus<T>(ref: RefType<T>, predicate?: boolean[] | undefined) {
  useEffect(() => {
    setTimeout(() => {
      if (
        // eslint-disable-next-line no-extra-boolean-cast
        !!(
          ref &&
          ref.current &&
          ref.current.focus &&
          (typeof predicate === 'undefined' ||
            (predicate && predicate.length > 0 && predicate.filter((x) => x).length === predicate.length))
        )
      ) {
        ref.current.focus();
      }
    }, TIMEOUT_DURATION);
  }, [predicate, ref]);
}

export function useFocusOnMount<T>(ref: RefType<T>) {
  useEffect(() => {
    setTimeout(() => {
      if (ref && ref.current && ref.current.focus) {
        ref.current.focus();
      }
    }, TIMEOUT_DURATION);
  }, [ref]);
}

export function focusRef<T>(ref: RefType<T>) {
  setTimeout(() => {
    if (ref && ref.current && ref.current.focus) {
      ref.current.focus();
    }
  }, TIMEOUT_DURATION);
}
