import { ThemeConfig } from 'antd-v5';

const brand = 'rgb(255, 204, 51)';
const clear_blue = 'rgb(24, 144, 255)';
export const antLessTheme: ThemeConfig = {
  token: {
    colorPrimary: clear_blue,
  },
  components: {
    Spin: {
      colorPrimary: brand,
    },
    Slider: {
      colorPrimary: clear_blue,
    },
    Radio: {
      colorPrimary: clear_blue,
    },
  },
};
