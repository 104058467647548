import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FOUND, getData, hasUserAnyRight } from 'utils';
import { fetchBasketRequest, getBasketIdStatus } from './Basket.store';
import {
  getTradingProfile,
  getUserProfileSearchStatus,
  getUserRights,
  isDelegationSessionStatusLoading,
  UserRole,
} from '../user';

export const useFetchBasket = (): void => {
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const searchStatus = useSelector(getUserProfileSearchStatus);

  const tradingProfile = useSelector(getTradingProfile);
  const basketIdStatus = useSelector(getBasketIdStatus);
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);

  useEffect(() => {
    if (!hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], getData(userRights) ?? [])) {
      return;
    }
    if (isDelegationLoading) {
      return;
    }
    if (basketIdStatus === undefined && searchStatus === FOUND && tradingProfile?.isComplete()) {
      dispatch(fetchBasketRequest());
    }
  }, [dispatch, basketIdStatus, searchStatus, tradingProfile, userRights, isDelegationLoading]);
};
