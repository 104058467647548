import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { Row } from 'antd-v5';
import { GlobeIcon } from 'assets/icons';
import { Icon } from 'UI';
import { Country } from 'utils/i18n/Country';
import { Language } from 'utils/i18n/Language';
import { SSelect } from './LanguageSelect.styled';

const { Option } = Select;

interface Props {
  languageList: Array<Language>;
  selected: Language | Language[];
  onSelect: (selected: Language) => void;
  onDeselect?: (deselected: Language) => void;
  disabled?: boolean;
  allowClear?: boolean;
}

const LanguageSelect = ({
  languageList,
  selected,
  onSelect,
  onDeselect,
  disabled = false,
  allowClear = false,
}: Props) => {
  const { t } = useTranslation();
  const sortedLanguageList = languageList.sort((l1, l2) => l1.name.localeCompare(l2.name));

  function findLang(lang: string): Language {
    return languageList.find((item) => item.key === lang) ?? Language.DEFAULT;
  }

  return (
    <SSelect
      mode={Array.isArray(selected) ? 'multiple' : undefined}
      allowClear={allowClear}
      showSearch={false}
      disabled={disabled}
      placeholder={t('common.select_language', 'Select language')}
      value={Array.isArray(selected) ? selected.map((s) => s.key) : selected.key}
      filterOption={(input, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      optionFilterProp="label"
      onSelect={(langKey: any) => {
        onSelect(findLang(langKey));
      }}
      onDeselect={(langKey: any) => {
        onDeselect && onDeselect(findLang(langKey));
      }}
      showArrow
    >
      {sortedLanguageList.map(({ country, key, name }) => (
        <Option key={key} value={key} title={name}>
          <Row>
            <Icon
              IconComponent={country instanceof Country ? country.icon : GlobeIcon}
              size={16}
              color={'black'}
              mt={3}
              mr={10}
            />
            &nbsp; {name}
          </Row>
        </Option>
      ))}
    </SSelect>
  );
};

export default LanguageSelect;
