declare global {
  interface Window {
    env: any;
  }
}

type EnvType = {
  REACT_APP_BUILD_VERSION: string;
  REACT_APP_MATOMO_HOME: string;
  REACT_APP_MATOMO_SITE_ID: string;
  REACT_APP_ENABLE_DISPLAY_TRANSLATION_KEYS: string;
  REACT_APP_BFF_URL: string;
  REACT_APP_ENVIRONMENT: string;
};

export const env: EnvType = { ...process.env, ...window.env };
