import React from 'react';
import styled from 'styled-components';

type UListStyleType = 'none' | 'initial' | 'disc' | 'circle' | 'square' | 'decimal' | 'lower-alpha' | 'upper-alpha';

const SUl = styled.ul<{ listStyleType: UListStyleType }>`
  list-style-type: ${({ listStyleType }) => listStyleType};
`;

export const Ul = ({ type = 'disc', children }: { type?: UListStyleType; children: React.ReactNode }) => {
  return <SUl listStyleType={type}>{children}</SUl>;
};

type OListStyleType = 'a' | 'A' | 'i' | 'I' | '1';

export const Ol = ({ type = 'a', children }: { type?: OListStyleType; children: React.ReactNode }) => {
  return <ol type={type}>{children}</ol>;
};

export const Li = ({ children }: { children: React.ReactNode }) => {
  return <li>{children}</li>;
};
