import styled from 'styled-components';
import { Flex } from 'UI';

export const SLine = styled.div`
  width: 250px;
  height: 0.5px;
  border: solid 1px ${({ theme }) => theme.color.grey95};
`;

export const SListItem = styled(Flex)<{ hoverColor: string | undefined }>`
  :hover {
    background-color: ${({ theme }) => theme.color.grey95};
    cursor: pointer;

    h6,
    span > svg {
      color: ${({ hoverColor }) => hoverColor};
      fill: ${({ hoverColor }) => hoverColor};
    }
  }
`;

export const SPromotionDetailMenu = styled(Flex)`
  overflow: hidden;
`;
