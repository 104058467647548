import { Descriptions as DescriptionsAnt } from 'antd';
import styled from 'styled-components';
import { Flex } from 'UI/Flex';

export const SDescriptions = styled(DescriptionsAnt)`
  .ant-descriptions-row,
  .ant-descriptions-row > th.ant-descriptions-item-label,
  .ant-descriptions-row > td.ant-descriptions-item-content {
    background-color: ${({ theme }) => theme.color.grey95} !important;
    border: none;
    height: 28px;
    padding-bottom: 0;
    padding-top: 0;
    width: calc(25%);
  }

  .ant-descriptions-row:nth-child(even) > th.ant-descriptions-item-label,
  .ant-descriptions-row:nth-child(even) > td.ant-descriptions-item-content {
    background-color: white !important;
  }

  .ant-descriptions-header {
    margin-bottom: 10px !important;
    margin-top: 0 !important;
    margin-left: 15px;
  }
`;

export const ShowMoreButtonWrapper = styled(Flex)`
  z-index: 1;
`;
