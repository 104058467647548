import React from 'react';
import { Trans } from 'react-i18next';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { ReactComponent as ComingSoonLogoIcon } from 'assets/CarBrand/brand_coming_soon.svg';
import { ExclamationTriangleIcon } from 'assets/icons';
import { ProductImageBackground, ProductImageUnavailable } from 'pages/CatalogPage/DH/Product/Product.styled';
import { theme } from 'styles';
import { CenterFlex, Icon, ScalableImage, Text } from 'UI';

const DefaultProductImage = () => <Icon IconComponent={ComingSoonLogoIcon} width={200} height={220} />;

export interface ProductImageProps {
  imageUrl: string | undefined;
  catalogSource: CatalogSource;
  alt: string;
  availableInUserCountry?: boolean;
}

export const ProductImage = ({ imageUrl, catalogSource, alt, availableInUserCountry }: ProductImageProps) => {
  return (
    <>
      {availableInUserCountry ? (
        <ProductImageBackground imageBase64={imageUrl}>
          <CenterFlex>
            <ScalableImage
              imageUrl={imageUrl ?? '_'}
              catalogSource={catalogSource}
              alt={alt}
              cursor={'pointer'}
              fallbackSrc={''}
              fallbackComponent={<DefaultProductImage />}
            />
          </CenterFlex>
        </ProductImageBackground>
      ) : (
        <ProductImageUnavailable direction={'column'} align={'center'} justify={'center'}>
          <Icon IconComponent={ExclamationTriangleIcon} width={150} height={100} color={theme.color.shadow_6} />
          <Text type={'h1_banner_black_45'}>
            <Trans i18nKey={'catalog.product.unavailable'}>Unavailable</Trans>
          </Text>
        </ProductImageUnavailable>
      )}
    </>
  );
};
