import styled from 'styled-components';
import { ThemeColorKeyType } from 'styles';

export const StatusDot = styled.div<{ color: ThemeColorKeyType }>`
  display: inline-block;
  line-height: 7px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: ${({ theme, color }) => theme.color[color]};
  margin: auto 0 auto 0;
`;
