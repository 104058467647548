import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DashboardPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { TrashAltIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { deletePromotionRequestSaga } from 'domains/promotion/Promotion.store';
import { DialogHandle } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/PromotionDetailMenu';
import { Flex, MarginBox, Text } from 'UI';

interface DeletePromotionDialogProps {
  promotion: DashboardPromotion;
}

const DeletePromotionDialog = forwardRef<DialogHandle, DeletePromotionDialogProps>((props, ref) => {
  const disclosure = useDisclosure();
  const { t } = useTranslation();
  const { onOpen } = disclosure;
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    handleDialogOpen() {
      onOpen();
    },
  }));

  return (
    <>
      <Dialog
        title={t('backoffice.promotion.delete.title', 'Delete promotion')}
        handleConfirm={() =>
          dispatch(
            deletePromotionRequestSaga({
              promotionId: props.promotion.promotionId,
              promotionType: props.promotion.promotionType,
            }),
          )
        }
        icon={TrashAltIcon}
        status={'error'}
        disclosure={disclosure}
        content={
          <Flex direction={'column'} align={'center'}>
            <Text type={'lead'}>
              {t('backoffice.promotion.delete.description', 'This action will delete selected promotion')}
            </Text>
            <Text type={'lead'}>{t('common.dialog.confirmationQuestion', 'Would you like to confirm?')}</Text>
            <MarginBox mt={30} />
          </Flex>
        }
      />
    </>
  );
});

DeletePromotionDialog.displayName = 'DeletePromotionDialog';
export default DeletePromotionDialog;
