import React, { useEffect } from 'react';
import Loader from 'components/Loader';
import { getUserManager } from 'domains/auth/Auth.util';

const LoginRedirect = (): JSX.Element => {
  useEffect(() => {
    getUserManager().signinPopupCallback();
  }, []);
  return <Loader />;
};

export default LoginRedirect;
