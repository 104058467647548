export interface TrackingEvent {
  category: string;
  action: string;
  name: string;
  value?: string;
}

// Matomo Event categories
const TRACKING_CATEGORY_PURCHASE_FUNNEL = 'Purchase funnel';
const TRACKING_CATEGORY_CONSULT_ORDERS = 'Consult my orders';
const TRACKING_CATEGORY_ESTIMATE = 'Estimate';
const TRACKING_CATEGORY_QUOTE = 'Quote';
const TRACKING_CATEGORY_VEHICLE_SEARCH = 'Vehicle search';
const TRACKING_CATEGORY_VIEW_DISPLAY = 'View display';

// Matomo Event actions
const TRACKING_ACTION_CART_VALIDATION = 'Cart validation';
const TRACKING_ACTION_GO_TO_CART = 'Go to Cart';
const TRACKING_ACTION_ADD_PART_TO_CART = 'Add part to Cart';
const TRACKING_ACTION_GO_TO_ESTIMATE = 'Go to Estimate';
const TRACKING_ACTION_ADD_TO_ESTIMATE = 'Add part to Estimate';
const TRACKING_ACTION_ADD_TIRES_TO_ESTIMATE = 'Add tires to Estimate';
const TRACKING_ACTION_CREATE_NEW_ESTIMATE = 'Create new estimate';
const TRACKING_ACTION_CONSULT_ESTIMATE_HISTORY = 'Consult my estimates history';
const TRACKING_ACTION_ORDERS_IN_PROGRESS = 'My orders in progress';
const TRACKING_ACTION_EXTERNAL_ORDERS = 'External orders';
const TRACKING_ACTION_ARCHIVED_ORDERS = 'Archived orders';
const TRACKING_ACTION_PARTS_DELETION = 'Parts deletion';
const TRACKING_ACTION_TRANSFER_ESTIMATE_TO_CART = 'Transfer Estimate to Cart';
const TRACKING_ACTION_VIN_VRN_SEARCH = 'VIN or VRN search';
const TRACKING_ACTION_GARAGE_VIEW = 'Garage view';
const TRACKING_ACTION_CLIENT_VIEW = 'Client view';

// Matomo Event names
const TRACKING_NAME_GO_TO_CART_ICON = 'Go to Cart icon';
const TRACKING_NAME_GO_TO_CART_SHORTCUT = 'Go to Cart shortcut';
const TRACKING_ACTION_ADD_TO_CART = 'Add to Cart';
const TRACKING_ACTION_ADD_TO_CART_TIRES = 'Add to Cart tires';
const TRACKING_ACTION_ADD_TO_CART_PROMOTION = 'Add to Cart promotion';
const TRACKING_ACTION_ADD_TO_CART_CROSS_SELLING = 'Add to Cart Xsell';
const TRACKING_ACTION_ADD_REFERENCE_TO_CART = 'Add reference to Cart';
const TRACKING_NAME_SEE_ESTIMATE = 'Go to See estimate';
const TRACKING_NAME_ESTIMATE_VIA_MENU = 'Estimate via menu';
const TRACKING_NAME_ESTIMATE_CONFIRM = 'Confirm';
const TRACKING_NAME_PAGE_RESULT_TRASH = 'Trash in result page';
const TRACKING_NAME_PAGE_PRODUCT_TRASH = 'Trash in product page';
const TRACKING_NAME_CART_REFERENCE_QUANTITY_ZERO = 'Quantity 0';

// Matomo tracking objects
export const TRACKING_EVENT_CART_VALIDATION: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_CART_VALIDATION,
  name: TRACKING_ACTION_CART_VALIDATION,
};
export const TRACKING_EVENT_ADD_TO_CART: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_ADD_PART_TO_CART,
  name: TRACKING_ACTION_ADD_TO_CART,
};
export const TRACKING_EVENT_ADD_TIRES_TO_CART: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_ADD_PART_TO_CART,
  name: TRACKING_ACTION_ADD_TO_CART_TIRES,
};
export const TRACKING_EVENT_ADD_PROMOTION_TO_CART: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_ADD_PART_TO_CART,
  name: TRACKING_ACTION_ADD_TO_CART_PROMOTION,
};
export const TRACKING_EVENT_ADD_CROSS_SELLING_TO_CART: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_ADD_PART_TO_CART,
  name: TRACKING_ACTION_ADD_TO_CART_CROSS_SELLING,
};
export const TRACKING_EVENT_ADD_REFERENCE_TO_CART: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_ADD_PART_TO_CART,
  name: TRACKING_ACTION_ADD_REFERENCE_TO_CART,
};
export const TRACKING_EVENT_PAGE_RESULT_PART_DELETION: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_PARTS_DELETION,
  name: TRACKING_NAME_PAGE_RESULT_TRASH,
};
export const TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_PARTS_DELETION,
  name: TRACKING_NAME_PAGE_PRODUCT_TRASH,
};
export const TRACKING_EVENT_CART_PART_QUANTITY_ZERO: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_PARTS_DELETION,
  name: TRACKING_NAME_CART_REFERENCE_QUANTITY_ZERO,
};
export const TRACKING_EVENT_ORDERS_IN_PROGRESS: TrackingEvent = {
  category: TRACKING_CATEGORY_CONSULT_ORDERS,
  action: TRACKING_ACTION_ORDERS_IN_PROGRESS,
  name: TRACKING_ACTION_ORDERS_IN_PROGRESS,
};
export const TRACKING_EVENT_EXTERNAL_ORDERS: TrackingEvent = {
  category: TRACKING_CATEGORY_CONSULT_ORDERS,
  action: TRACKING_ACTION_EXTERNAL_ORDERS,
  name: TRACKING_ACTION_EXTERNAL_ORDERS,
};
export const TRACKING_EVENT_ARCHIVED_ORDERS: TrackingEvent = {
  category: TRACKING_CATEGORY_CONSULT_ORDERS,
  action: TRACKING_ACTION_ARCHIVED_ORDERS,
  name: TRACKING_ACTION_ARCHIVED_ORDERS,
};
export const TRACKING_EVENT_GO_TO_CART_ICON: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_GO_TO_CART,
  name: TRACKING_NAME_GO_TO_CART_ICON,
};
export const TRACKING_EVENT_GO_TO_CART_SHORTCUT: TrackingEvent = {
  category: TRACKING_CATEGORY_PURCHASE_FUNNEL,
  action: TRACKING_ACTION_GO_TO_CART,
  name: TRACKING_NAME_GO_TO_CART_SHORTCUT,
};
export const TRACKING_EVENT_GO_TO_ESTIMATE: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_GO_TO_ESTIMATE,
  name: TRACKING_NAME_SEE_ESTIMATE,
};
export const TRACKING_EVENT_ADD_TO_ESTIMATE: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_ADD_TO_ESTIMATE,
  name: TRACKING_ACTION_ADD_TO_ESTIMATE,
};
export const TRACKING_EVENT_ADD_TIRES_TO_ESTIMATE: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_ADD_TIRES_TO_ESTIMATE,
  name: TRACKING_ACTION_ADD_TIRES_TO_ESTIMATE,
};
export const TRACKING_EVENT_GO_TO_ESTIMATE_VIA_MENU: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_GO_TO_ESTIMATE,
  name: TRACKING_NAME_ESTIMATE_VIA_MENU,
};
export const TRACKING_EVENT_CREATE_NEW_ESTIMATE: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_CREATE_NEW_ESTIMATE,
  name: TRACKING_ACTION_CREATE_NEW_ESTIMATE,
};
export const TRACKING_EVENT_CREATE_NEW_ESTIMATE_CONFIRM: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_CREATE_NEW_ESTIMATE,
  name: TRACKING_NAME_ESTIMATE_CONFIRM,
};
export const TRACKING_EVENT_ESTIMATE_HISTORY: TrackingEvent = {
  category: TRACKING_CATEGORY_ESTIMATE,
  action: TRACKING_ACTION_CONSULT_ESTIMATE_HISTORY,
  name: TRACKING_ACTION_CONSULT_ESTIMATE_HISTORY,
};
export const TRACKING_EVENT_TRANSFER_ESTIMATE_TO_CART: TrackingEvent = {
  category: TRACKING_CATEGORY_QUOTE,
  action: TRACKING_ACTION_TRANSFER_ESTIMATE_TO_CART,
  name: TRACKING_ACTION_TRANSFER_ESTIMATE_TO_CART,
};

export const TRACKING_EVENT_VIN_VRN_SEARCH: TrackingEvent = {
  category: TRACKING_CATEGORY_VEHICLE_SEARCH,
  action: TRACKING_ACTION_VIN_VRN_SEARCH,
  name: TRACKING_ACTION_VIN_VRN_SEARCH,
};

export const TRACKING_EVENT_GARAGE_VIEW: TrackingEvent = {
  category: TRACKING_CATEGORY_VIEW_DISPLAY,
  action: TRACKING_ACTION_GARAGE_VIEW,
  name: TRACKING_ACTION_GARAGE_VIEW,
};

export const TRACKING_EVENT_CLIENT_VIEW: TrackingEvent = {
  category: TRACKING_CATEGORY_VIEW_DISPLAY,
  action: TRACKING_ACTION_CLIENT_VIEW,
  name: TRACKING_ACTION_CLIENT_VIEW,
};
