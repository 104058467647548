import React, { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export function WithLink({ to, external, download, newTab, children }: Omit<LinkProps, 'to'> & { to?: string }) {
  return to ? (
    <Link to={to} external={external} download={download} newTab={newTab}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
}

export type LinkProps = PropsWithChildren<{
  to: string;
  external?: boolean;
  download?: string;
  newTab?: boolean;
  dataCy?: string;
}>;

export function Link({ to, external, download, newTab, children, dataCy }: LinkProps) {
  const newTabProps = newTab && { target: '_blank', rel: 'noreferrer' };
  return external ? (
    <a href={to} download={download} {...newTabProps} data-cy={dataCy}>
      {children}
    </a>
  ) : (
    <RouterLink to={to} data-cy={dataCy} {...newTabProps}>
      {children}
    </RouterLink>
  );
}
