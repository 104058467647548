import styled from 'styled-components';
import { BlueButton, Input } from 'UI';

export const SInput = styled(Input)`
  height: 45px;
  width: 330px;
  border-color: ${({ theme }) => theme.color.grey90};
  border-radius: 23px;
`;

export const MagnifierButton = styled(BlueButton)`
  width: 45px;
  height: 45px;
  margin-right: -11px;
  border-radius: 0 23px 23px 0;
`;
