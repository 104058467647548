import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Essay } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/LaborTime';
import {
  getTestCode,
  getTestText,
  hasTest,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSection.types';
import { WithTooltip } from 'UI';
import { STestIcon } from './TestIcon.styled';

export interface TestIconProps {
  essay?: Essay;
  testWasAdded?: boolean;
}

export default function TestIcon({ essay, testWasAdded }: TestIconProps) {
  const { t } = useTranslation();

  if (!hasTest(essay)) return null;

  return (
    <WithTooltip title={`${getTestCode(essay)} ${getTestText(essay, t)}`} placement={'bottom'}>
      <STestIcon testWasAdded={testWasAdded}>
        <Trans i18nKey={'common.test'}>{'Test'}</Trans>
      </STestIcon>
    </WithTooltip>
  );
}
