import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { RootState } from 'app/AppStore';
import { NO_DATA } from 'utils';
import { fetchGarageInfoRequest, getGarageInfo, getUserGarageInfo } from './Garage.store';
import { DealerRepository, getUserCommercialLink } from '../user';

export function useFetchCurrentGarageInfo() {
  const dispatch = useDispatch();
  const garageInfo = useSelector(getUserGarageInfo);
  const commercialLink = useSelector(getUserCommercialLink);
  const tresorCode = commercialLink?.tresorCode;
  useEffect(() => {
    if (!garageInfo && tresorCode) {
      dispatch(fetchGarageInfoRequest({ garageId: tresorCode, repository: 'TRESOR' }));
    }
  }, [dispatch, garageInfo, tresorCode]);
}

export function useFetchGarageInfo(garageId: string, repository: DealerRepository): Garage | NO_DATA {
  const dispatch = useDispatch();
  const garageInfo = useSelector((state: RootState) => getGarageInfo(state, garageId));
  useEffect(() => {
    if (garageId && !garageInfo) {
      dispatch(fetchGarageInfoRequest({ garageId, repository }));
    }
  }, [dispatch, garageId, garageInfo, repository]);

  return garageInfo;
}
