import React from 'react';
import { ThemeFontKeyType } from 'styles';
import { Box, BoxAlignType, Text } from 'UI';

const CloseButton = ({
  onClose,
  align,
  type,
}: {
  onClose: () => void;
  align: BoxAlignType;
  type: ThemeFontKeyType;
}) => {
  return (
    <Box align={align}>
      <Text type={type} hoverUnderLine cursor={'pointer'} onClick={() => onClose()}>
        <strong>X</strong>
      </Text>
    </Box>
  );
};

export default CloseButton;
