import styled from 'styled-components';
import Popover from 'components/Popover';
import { theme } from 'styles';
import { Box, Flex } from 'UI';

export const SPromotionItem = styled(Box)`
  width: 210px;
  height: 210px;
  margin: 0 0 15px;
  padding: 10px 10px 170px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.grey_very_light_2};
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const ArrowBadgeWrapper = styled(Flex)`
  position: absolute;
  top: 10px;
  z-index: 1;
`;

export const ArrowBadge = styled.div`
  padding-left: 5px;
  padding-right: 10px;
  margin-top: 10px;
  height: 24px;
  background-color: ${theme.color.brand};

  &:before {
    content: '';
    right: -12px;
    bottom: 0;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 12px solid ${theme.color.brand};
    border-bottom: 12px solid ${theme.color.brand};
    border-right: 12px solid transparent;
  }
`;

export const SCardMenuWrapper = styled(Flex)`
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 1;
`;

export const DetailDot = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 1px 0 1px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.grey55};
`;

export const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: white;
  z-index: 1;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.color.brand_black};
  }

  &:hover ${DetailDot} {
    background-color: white;
  }
`;

export const SPopover = styled(Popover)`
  max-width: 350px;

  .ant-popover-inner {
    overflow: hidden;
  }
`;
