import { DependencyList, useEffect } from 'react';
import { BANNER_HEIGHT } from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';

export function scrollToTopSmooth(top?: number) {
  window.scrollTo({
    top: top ?? 0,
    left: 0,
    behavior: 'smooth',
  });
}

export function scrollToTopSharp(top?: number) {
  window.scrollTo({ top: top ?? 0, left: 0 });
}

export function scrollToElementSmooth(element: Element, isBanner: boolean, offset?: number) {
  const elementPosition = element?.getBoundingClientRect().top ?? 0;
  const customOffset = offset ?? 0;
  const offsetPosition =
    elementPosition + window.scrollY - HEADER_HEIGHT - customOffset - (isBanner ? BANNER_HEIGHT : 0) - 5;
  window.scrollTo({
    top: offsetPosition,
    left: 0,
    behavior: 'smooth',
  });
}

export function useResetScroll(deps?: DependencyList) {
  useEffect(() => {
    scrollToTopSmooth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);
}

export function useResetScrollSharp(deps?: DependencyList) {
  useEffect(() => {
    scrollToTopSharp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? []);
}
