import React, { ReactNode, useState } from 'react';
import { Tabs as AntTabs, TabsProps as AntdTabsProps } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TabsType } from 'antd/lib/tabs';
import styled from 'styled-components';
import { Flex, MarginBox, Text } from 'UI';
import { STab } from './Tabs.styled';

const { TabPane } = AntTabs;

export interface TabsProps extends AntdTabsProps {
  children?: ReactNode;
  defaultActiveKey?: string;
  type?: TabsType;
  size?: SizeType;
  activeKey?: string;
  onChange?: any;
  centered?: boolean;
  disabled?: boolean;
}

export interface LinkTabsProps extends TabsProps {
  onChange: any;
  activeKey: string | undefined;
}

export interface TabItems {
  key: string;
  label: ReactNode;
  additionalAction?: () => void;
  component: ReactNode;
  dataCy?: string;
}

export const Tabs = ({ children, ...props }: TabsProps) => {
  return <AntTabs {...props}>{children}</AntTabs>;
};

export const Tab = TabPane;

export const TabsTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  margin: 0 15px 0 15px;
`;

export const AltTabs = ({ onChange, children, activeKey, centered, ...props }: LinkTabsProps) => {
  return (
    <AntTabs {...props} onChange={onChange} activeKey={activeKey} centered={centered}>
      {children}
    </AntTabs>
  );
};

export interface TabDataType {
  title: string;
  key: string;
  component: React.ReactNode;
  disabled?: boolean;
}

interface WideTabsCustomType {
  tabData: TabDataType[];
  defaultActiveKey?: string;
  activeKey?: string;
  onChange?: (type: string) => void;
  isActiveBlue?: boolean;
}

const TabCustom = ({
  title,
  tabKey,
  setTabState,
  active,
  activeKey,
  onChange,
  disabled = false,
  isActiveBlue,
}: {
  title: string;
  tabKey: string;
  setTabState: (s: string) => void;
  active: boolean;
  activeKey?: string;
  onChange?: (tabKey: string) => void;
  disabled?: boolean;
  isActiveBlue: boolean;
}) => {
  const getDisplayStyle = () => {
    if (disabled) {
      return 'disabled';
    }
    if (active && isActiveBlue) {
      return 'white';
    }
    if (active) {
      return 'link';
    }
    return undefined;
  };
  return (
    <STab
      onClick={() => {
        if (disabled) {
          return;
        }
        !activeKey && setTabState(tabKey);
        onChange?.(tabKey);
      }}
      active={active}
      disabled={disabled}
      isActiveBlue={isActiveBlue}
    >
      <Text type={'text'} displayStyle={getDisplayStyle()}>
        {title}
      </Text>
    </STab>
  );
};

export const WideTabs = ({
  tabData,
  defaultActiveKey,
  activeKey,
  onChange,
  isActiveBlue = false,
}: WideTabsCustomType) => {
  const [tabState, setTabState] = useState<string>(activeKey ?? defaultActiveKey ?? tabData[0]?.key);
  const currentData = tabData.find((td) => td.key === (activeKey ?? tabState))?.component;

  if (tabData.length === 0) {
    return <></>;
  }

  return (
    <Flex direction={'column'}>
      <Flex>
        {tabData.map((td) => (
          <TabCustom
            key={td.key}
            title={td.title}
            tabKey={td.key}
            setTabState={setTabState}
            active={td.key === (activeKey ?? tabState)}
            activeKey={activeKey}
            onChange={onChange}
            disabled={td.disabled}
            isActiveBlue={isActiveBlue}
          />
        ))}
      </Flex>
      <MarginBox mt={16} />
      <>{currentData}</>
    </Flex>
  );
};
