import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { TechnicalDataL1 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL1';
import { PdfShortcutHeader } from 'components/Pdf/PrintPdfShortcut';
import { MaintenanceTabsType } from 'domains/catalog/Catalog.types';
import DefaultMaintenanceTable from 'pages/CatalogPage/IAM/MaintenancePlan/DefaultMaintenance/DefaultMaintenanceTable';
import { PrintButtonRound } from 'UI';
import { PdfHiddenWrapper, PdfWrapper, usePrintShortcut } from 'utils/hooks/usePrintShortcut';

interface DefaultMaintenancePlanPdfProps {
  tab: MaintenanceTabsType | undefined;
  data: TechnicalDataL1 | undefined;
}

const DefaultMaintenancePlanPdf = ({ tab, data }: DefaultMaintenancePlanPdfProps) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  usePrintShortcut(reactToPrintFn);

  const getTitle = () => {
    switch (tab) {
      case 'intervals':
        return t('catalog.maintenance.change_intervals.title', 'Change intervals');
      case 'fluids':
        return t('catalog.maintenance.fluids.title', 'Fluids');
      default:
        return '';
    }
  };

  return (
    <>
      <PrintButtonRound onClick={reactToPrintFn} />
      <PdfHiddenWrapper>
        <PdfWrapper direction={'column'} ref={contentRef} gap={15}>
          <PdfShortcutHeader title={getTitle()} />
          <DefaultMaintenanceTable tab={tab} technicalData={data} />
        </PdfWrapper>
      </PdfHiddenWrapper>
    </>
  );
};

export default DefaultMaintenancePlanPdf;
