/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { useFetchMarketingReferences, useFetchPlateReferences, useFetchPlates } from 'domains/catalog/Catalog.requests';
import {
  getDetailOfLevel3,
  getExplodedTreeItem,
  getMarketingReferencesWrapperForVehicle,
  getPlateReferences,
  getPlates,
  getPlatesImagesKeys,
  getSearchVehicleResult,
  plateHasDescription,
} from 'domains/catalog/Catalog.store';
import {
  HighlightIndex,
  PARAM_MULTIPLATE,
  PARAM_STANDARD,
  PlateIdParam,
  PlateViewModeParam,
} from 'domains/catalog/Catalog.types';
import { useGetCatalogSourceUrlFromLocation } from 'domains/catalog/Catalog.utils';
import MarketingProductsBanner from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/MarketingProductsBanner';
import { marketingProductsIndex } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/MarketingProductsBanner/MarketingProductsBanner';
import PlateDetailSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateDetailSection';
import ReferencesSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferencesSection';
import { Box, Flex, MarginBox, Text } from 'UI';
import { FOUND, hasData, NOT_FOUND } from 'utils';
import {
  OFFSET_LINK_TABS,
  OFFSET_PADDING,
  OFFSET_SUB_HEADER,
  OFFSET_VEHICLE_INFO,
  useOffsetTop,
} from 'utils/hooks/useOffsetTop';

export type SelectedIndexType = string | null | typeof marketingProductsIndex;

export interface SparePartsSectionProps {
  query: string;
  carPartGroupId: string;
  categoryId: string;
  subcategoryId: string;
  plateId?: string;
  highlightIndex?: string;
}

const SparePartsSection = ({
  query,
  carPartGroupId,
  categoryId,
  subcategoryId,
  plateId,
  highlightIndex,
}: SparePartsSectionProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const vehicleKey = vehicleResult?.vehicleDetail?.vehicleKey;
  const catalogSourceUrl = useGetCatalogSourceUrlFromLocation();
  const tree = vehicleResult?.explodedTree?.data;
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const minHeight =
    offsetTop + OFFSET_SUB_HEADER + OFFSET_LINK_TABS + OFFSET_VEHICLE_INFO + OFFSET_PADDING + window.innerHeight;
  const subcategory = getExplodedTreeItem(tree, [carPartGroupId, categoryId, subcategoryId]);
  const plateImages = useSelector((state: RootState) =>
    getPlatesImagesKeys(state, { plateIds: subcategory?.plateIds, vehicleKey }),
  );
  const plateReferences = useSelector((state: RootState) => getPlateReferences(state, { plateId, vehicleKey }));
  const [highlightedIndex, setHighlightedIndex] = useState<string | null>(highlightIndex ?? null);
  const [selectedIndex, setSelectedIndex] = useState<SelectedIndexType>(null);
  const multiplates = useSelector((state: RootState) =>
    getDetailOfLevel3(state, { nodeId: subcategory?.nodeId, vehicleKey }),
  );
  const multiplateIds = hasData(multiplates) ? multiplates?.map((multiplate) => multiplate.plateIds).flat() : undefined;
  const plates = useSelector((state: RootState) => getPlates(state, { plateIds: multiplateIds, vehicleKey }));

  const marketingReferencesWrapper = useSelector((state: RootState) =>
    getMarketingReferencesWrapperForVehicle(state, { query, nodeId: subcategoryId }),
  );

  const subcategories = useMemo(() => (subcategory ? [subcategory] : undefined), [subcategory]);

  useFetchPlates(subcategories, plateImages, query, vehicleResult, new Map([[subcategory?.nodeId ?? '', multiplates]]));
  useFetchMarketingReferences(subcategory, marketingReferencesWrapper, vehicleResult?.vehicleDetail?.vehicleKey);

  useEffect(() => {
    setHighlightedIndex(highlightIndex ?? null);
    setSelectedIndex(highlightIndex ?? null);
  }, [highlightIndex]);

  useEffect(() => {
    if (!plateId && subcategory && subcategory.plateIds.length > 0) {
      const selectedPlateId = plateId ?? plateImages[0]?.plateId;
      if (selectedPlateId) {
        history.replace(
          `${catalogSourceUrl}/${query}/${carPartGroupId}/${categoryId}/${subcategoryId}/${selectedPlateId}`,
        );
      }
    }
  }, [carPartGroupId, categoryId, history, plateId, plateImages, query, subcategoryId, subcategory, catalogSourceUrl]);

  useFetchPlateReferences(plateId, vehicleKey);

  const handleSelectMultiplate = useCallback(
    (plateIds: string[]) => {
      const id = plateIds[0];
      const plate = plates?.find((p) => p?.plateId === id);
      const params = new URLSearchParams(location.search);
      if (plateIds.length === 1 && !plateHasDescription(plate?.plateDetail)) {
        params.set(PlateViewModeParam, PARAM_STANDARD);
      } else {
        params.set(PlateViewModeParam, PARAM_MULTIPLATE);
      }
      setHighlightedIndex(null);
      setSelectedIndex(null);
      params.set(PlateIdParam, id);
      params.delete(HighlightIndex);
      history.push(
        `${catalogSourceUrl}/${query}${ROUTER_CATALOG_VEHICLE}/${carPartGroupId}/${categoryId}/${subcategoryId}?${params}`,
      );
    },
    [carPartGroupId, catalogSourceUrl, categoryId, history, plates, query, subcategoryId],
  );

  const shouldDisplayBanner =
    plateReferences && plateReferences !== NOT_FOUND && marketingReferencesWrapper?.status === FOUND;

  return (
    <Flex direction={'column'} minHeight={minHeight}>
      <Text transform={'capitalize'} type={'text_dim'}>
        {t('catalog.parts.category.car_parts.parts', 'Spare parts')}
      </Text>
      {subcategory && subcategory.plateIds.length > 0 && plateId && (
        <>
          <Box height={45} />
          <Flex>
            <PlateDetailSection
              plateId={plateId}
              handleSelectMultiplate={handleSelectMultiplate}
              subcategory={subcategory}
              plateReferences={plateReferences}
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
              highlightedIndex={highlightedIndex}
              setHighlightedIndex={setHighlightedIndex}
              vehicleKey={vehicleKey}
            />
          </Flex>
        </>
      )}
      <MarginBox my={30}>
        {shouldDisplayBanner && <MarketingProductsBanner setSelectedIndex={setSelectedIndex} />}
      </MarginBox>
      <ReferencesSection
        vehicleResult={vehicleResult}
        plateReferences={plateReferences}
        marketingReferencesWrapper={marketingReferencesWrapper}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        setHighlightedIndex={setHighlightedIndex}
        plateId={plateId}
        nodeId={subcategory?.nodeId}
      />
    </Flex>
  );
};

export default SparePartsSection;
