import React from 'react';
import { Table } from 'antd';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataPL = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>NOTA INFORMACYJNA DOTYCZĄCA OCHRONY DANYCH OSOBOWYCH</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Grupa Renault, do której należy Renault SAS, stale dąży do zapewnienia zgodności z przepisami dotyczącymi
            ochrony danych osobowych (zwanymi dalej „danymi osobowymi”). Jest to jedno z najważniejszych zagadnień
            etycznych dla Grupy, opisane w Polityce Grupy dotyczącej ochrony danych osobowych dostępnej pod adresem:{' '}
            <Link
              to={'https://group.renault.com/nos-engagements/le-groupe-renault-et-vos-donnees-personnelles/'}
              external
              newTab
            >
              https://group.renault.com/nos-engagements/le-groupe-renault-et-vos-donnees-personnelles/
            </Link>
            . Grupa Renault uważa, że ochrona danych osobowych jest niezbędna do budowania opartych na zaufaniu relacji
            z klientami.
          </Paragraph>
          <Paragraph>
            Renault SAS udostępnia w Internecie platformę <strong>RPARTSTORE</strong>{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>{' '}
            („Platforma”) w celu i) ułatwienia zapoznania się z dokumentacją techniczną, sprzedaży części i akcesoriów
            („Produkty”), ii) zgłaszania incydentów związanych z działaniem Platformy oraz iii) generowania
            bezpośrednich kontaktów handlowych między sprzedawcą i kupującymi, czyli specjalistycznymi
            przedsiębiorstwami z sieci Renault. W trakcie przeglądania i interakcji z Platformą Renault, jako
            administrator danych osobowych, gromadzi bezpośrednio od użytkowników Platformy („Użytkownicy”) lub,
            pośrednio i w odpowiednich przypadkach, dane osobowe dotyczące klientów końcowych Użytkowników
            („Zainteresowane Osoby Trzecie”). Zgodnie z Ogólnymi Warunkami Użytkowania Platformy (zwanymi dalej
            „Ogólnymi Warunkami”) Renault SAS zamierza zapewnić jak największą przejrzystość w zakresie przetwarzania
            danych osobowych za pośrednictwem Platformy. Celem niniejszej Noty Informacyjnej jest szczegółowe
            powiadomienie Użytkowników będących przedstawicielami spółek zależnych Grupy Renault lub przedsiębiorstw
            należących do sieci Renault (w dalszej części określanych jako ”Ty” „Cię” „Twoje”) o warunkach przetwarzania
            ich danych osobowych Zainteresowanych Podmiotów Trzecich przez Renault oraz, w odpowiednich przypadkach,
            danych osobowych Zainteresowanych Osób Trzecich, a także o Twoich prawach i możliwościach w zakresie
            kontroli danych osobowych i ochrony prywatności.
          </Paragraph>
          <Paragraph>
            Wyrażenia rozpoczynające się od wielkiej litery, które nie zostały zdefiniowane w niniejszej nocie
            informacyjnej o ochronie danych osobowych (zwanej dalej „Notą”), mają znaczenie nadane im w Ogólnych
            Warunkach. Na potrzeby niniejszego dokumentu terminy „my”, „nasz”, „nasze” lub „Renault” odnoszą się do
            Renault SAS. Na potrzeby niniejszego dokumentu Użytkownik i Zainteresowane Osoby Trzecie są określane razem
            jako „Zainteresowane Osoby”.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'1. KTO PRZETWARZA TWOJE DANE OSOBOWE?'} />
          <Paragraph>
            Renault SAS, spółka matka Grupy Renault, z siedzibą we Francji, pod adresem: 122-122 bis avenue du Général
            Leclerc 92100 Boulogne-Billancourt, przetwarza Twoje dane osobowe w celach wymienionych poniżej (zob. 3. DO
            JAKICH CELÓW SĄ UŻYWANE TWOJE DANE OSOBOWE?) jako administratr danych osobowych.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. JAKIE DANE OSOBOWE PRZETWARZAMY?'} />
          <Paragraph>
            „Dane osobowe” oznaczają wszelkie informacje pozwalające zidentyfikować Cię w sposób bezpośredni (np.
            nazwisko i/lub imię) lub pośredni (np. identyfikator składający się z szeregu znaków alfanumerycznych, które
            tworzą Twój numer NIP, jeżeli jesteś Użytkownikiem reprezentującym spółkę zależną Renault lub należysz do
            sieci Renault).
          </Paragraph>
          <Paragraph>
            Zobowiązujemy się do gromadzenia wyłącznie danych osobowych, które są istotne i odpowiednie z punktu
            widzenia poszczególnych celów przetwarzania danych osobowych.
          </Paragraph>
          <Paragraph>Gromadzone przez nas dane osobowe zależą od naszych interakcji z Tobą i mogą zawierać:</Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>dane dotyczące Twojego logowania/uwierzytelnienia na Platformie,</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                dane identyfikacyjne i służbowe dane adresowe (służbowy e-mail, nazwisko, imię),
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                dane dotyczące korzystania z Platformy oraz urządzenia, którego w tym celu używasz, w szczególności logi
                (dotyczące liczby odwiedzin, odwiedzanych stron, używanej przeglądarki internetowej), adres IP, strefa
                czasowa i pliki cookie (więcej informacji znajdą na stronie „Pliki cookie” na Platformie),
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                wyceny przeprowadzone za pośrednictwem Platformy związane z diagnostyką, naprawą lub recyklingiem
                („Czynności Techniczne”) w pojeździe Zainteresowanej Osoby Trzeciej, w tym wyceny, które nie
                doprowadziły do złożenia zamówienia na Produkty,
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                dane osobowe dotyczące Ciebie oraz, w odpowiednich przypadkach, Zainteresowanych Osób Trzecich w ramach
                zgłaszania incydentów związanych z działaniem Platformy,
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                dane osobowe dotyczące Ciebie oraz, w odpowiednich przypadkach, Zainteresowanych Osób Trzecich w związku
                z zamówieniami na Produkty składanymi w sieci dealerów Renault (gromadzone dane obejmują zwłaszcza
                służbowe dane adresowe osoby odpowiedzialnej za odbiór dostawy),
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                dane związane z relacjami handlowymi oraz, w odpowiednich przypadkach, Twoje ewentualne wnioski o
                wsparcie, reklamacje i/lub dane związane z egzekwowaniem praw związanych z danymi osobowymi).
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
          <Paragraph>
            Dane niezbędne do udzielenia odpowiedzi na Twój wniosek są zaznaczane w formularzach gromadzenia danych (w
            szczególności za pomocą gwiazdek). Jeżeli nie chcesz podać obowiązkowych danych, prawdopodobnie nie będziemy
            mogli przetworzyć Twojego wniosku lub wykonać przedmiotowych usług.
          </Paragraph>
          <Paragraph>
            Zgodnie z naszymi Ogólnymi Warunkami oferowane przez nas produkty i usługi są przeznaczone dla osób
            pełnoletnich. Ponieważ usługi oferowane za pośrednictwem Platformy nie są przeznaczone dla osób
            niepełnoletnich, świadomie nie gromadzimy dotyczących ich danych.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. DO JAKICH CELÓW SĄ UŻYWANE TWOJE DANE OSOBOWE?'} />
          <Paragraph>
            Poniżej przedstawiamy Ci wyjaśnienia na temat sytuacji w jakich zbieramy Twoje dane osobowe, podstawy
            prawnej ich przetwarzania, celów przetwarzania oraz okresu ich przechowywania.
          </Paragraph>
          <MarginBox mt={15} />
          <Section>
            <SectionHeader title={'3.1 SYTUACJE, W KTÓRYCH ZBIERAMY DANE'} />
            <Paragraph>Zbieramy Twoje dane osobowe w szczególności, gdy:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  masz bezpośredni kontakt z Renault za pomocą Platformy (wypełnianie formularza, nawiązywanie kontaktu
                  itp.).
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  odwiedzasz Platformę, która może wykorzystywać pliki cookie lub inne elementy śledzące,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  zgłaszasz incydent związany z działaniem Platformy, rejestrujesz Czynność Techniczną lub, ewentualnie,
                  edytujesz i wysyłasz formularz zamówienia Produktów.
                </Text>
              </Li>
            </Ul>
          </Section>
          <Section>
            <SectionHeader title={'3.2. CELE PRZETWARZANIA DANYCH OSOBOWYCH'} />
            <Paragraph>
              W ramach wzajemnych relacji i w zależności od uwarunkowań gromadzenia Twoich danych osobowych możemy je
              wykorzystywać do:
            </Paragraph>
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>A – Obsługi początkowych kontaktów</Text>
              </Li>
              <Li>
                <Text type={'text'}>B – Świadczenia usług dla Ciebie</Text>
              </Li>
              <Li>
                <Text type={'text'}>C – Prowadzenia badań i analiz</Text>
              </Li>
              <Li>
                <Text type={'text'}>D – Obsługi naszych działań marketingowych.</Text>
              </Li>
            </Ul>
            <MarginBox mt={10} />
            <Section>
              <SectionHeader title={'3.2.A. OBSŁUGA POCZĄTKOWYCH KONTAKTÓW'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Cele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-a',
                    width: '50%',
                  },
                  {
                    title: 'Podstawa prawna',
                    dataIndex: 'legal',
                    key: 'legal-3-2-a',
                    width: '50%',
                  },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: 'Monitorowanie wizyt na Platformie oraz zarządzanie jej działaniem i bezpieczeństwem',
                    legal: (
                      <Text type={'text'}>
                        Podstawą prawną przetwarzania jest Twoja zgoda na umieszczanie / odczytywanie „nieobowiązkowych”
                        plików cookie na swoim urządzeniu (patrz strona „Pliki cookie” na Platformie), oraz nasz prawnie
                        uzasadniony interes (zapewnienie użytkownikowi bezpiecznego Serwisu Internetowego)
                      </Text>
                    ),
                  },
                  {
                    id: '1',
                    objectives: 'Zarządzanie sposobem uwierzytelniania na Platformie',
                    legal: (
                      <Text type={'text'}>
                        Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś (tj. Ogólnych
                        Warunków).
                      </Text>
                    ),
                  },
                  {
                    id: '2',
                    objectives: 'Logowanie się do Platformy',
                    legal: (
                      <Text type={'text'}>
                        Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś (tj. Ogólnych
                        Warunków).
                      </Text>
                    ),
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.B. ŚWIADCZENIE USŁUG DLA CIEBIE'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Cele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-b',
                    width: '50%',
                  },
                  { title: 'Podstawa prawna', dataIndex: 'legal', key: 'legal-3-2-b', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Umożliwienie wykonania Czynności Technicznych, w szczególności związanych z naprawami pojazdów`,
                    legal: `Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś (tj. Ogólnych Warunków).`,
                  },
                  {
                    id: '1',
                    // eslint-disable-next-line max-len
                    objectives: `Umożliwienie Ci zapoznania się z posprzedażową dokumentacją techniczną Produktów RENAULT (części zamienne, czas naprawy,  metody naprawy i diagnostyka, samouczki, przy czym lista ta nie jest wyczerpująca) i/lub innych producentów `,
                    legal: `Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś (tj. Ogólnych Warunków).`,
                  },
                  {
                    id: '2',
                    // eslint-disable-next-line max-len
                    objectives: `Umożliwienie oszacowania kosztów Czynności Technicznych  oraz, w odpowiednich przypadkach, wystawienia zamówienia zakupu Produktów za pośrednictwem Platformy`,
                    legal: `Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś (tj. Ogólnych Warunków).`,
                  },
                  {
                    id: '3',
                    objectives: `Zarządzanie naszymi relacjami handlowymi`,
                    legal: `Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś(tj. Ogólnych Warunków).`,
                  },
                  {
                    id: '4',
                    objectives: `Obsługa Twoich wniosków o wsparcie i zgłoszeń incydentów związanych z działaniem Platformy`,
                    legal: `Podstawą prawną przetwarzania jest wykonanie umowy, którą z nami zawarłeś (tj. Ogólnych Warunków).`,
                  },
                  {
                    id: '5',
                    // eslint-disable-next-line max-len
                    objectives: `Odpowiedź na Twoje ewentualne wnioski o skorzystanie z praw związanych z danymi osobowymi  (zob. sekcja 5. „Jakie masz prawa?”)`,
                    // eslint-disable-next-line max-len
                    legal: `Podstawą przetwarzania w tym zakresie są nasze ustawowe obowiązki w zakresie ochrony danych osobowych, które mogą wymagać weryfikacji Twojej tożsamości.`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.C. PROWADZENIA BADAŃ I ANALIZ'} />
              <Paragraph>
                Badania i analizy są przeprowadzane w celu pomiaru naszej wydajności, oceny jakości naszych produktów i
                usług oraz poziomu zadowolenia klientów, a także ich ciągłego doskonalenia.
              </Paragraph>
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Cele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-c',
                    width: '50%',
                  },
                  { title: 'Podstawa prawna', dataIndex: 'legal', key: 'legal-3-2-c', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `Obsługa badań satysfakcji prowadzonych przez Renault, w tym odpowiedzi (Twoje opinie), w celu poprawy zadowolenia Użytkowników z Platformy`,
                    legal: `Podstawą prawną przetwarzania jest  Twoja zgoda.`,
                  },
                  {
                    id: '1',
                    objectives: `Monitorowanie naszych działań (sprawozdawczość)`,
                    // eslint-disable-next-line max-len
                    legal: `Podstawą prawną przetwarzania jest nasz prawnie uzasadniony interes (pomiar wyników naszej działalności oraz doskonalenie naszych produktów i usług).`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.D. OBSŁUGA NASZYCH DZIAŁAŃ MARKETINGOWYCH'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Cele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-d',
                    width: '50%',
                  },
                  { title: 'Podstawa prawna', dataIndex: 'legal', key: 'legal-3-2-d', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Wyświetlanie  kampanii reklamowych do sieci Renault (B2B)`,
                    legal: `Podstawą prawną przetwarzania jest nasz prawnie uzasadniony interes (lepsza znajomość naszych Produktów).`,
                  },
                ]}
              />
              <MarginBox mt={15} />
              <Paragraph>
                Jeżeli będziemy musieli przetwarzać Twoje dane osobowe w celach innych niż wymienione w powyższej
                tabeli, zostaniesz o tym uprzednio poinformowany, a my podejmiemy wszelkie dodatkowe kroki, które mogą
                być konieczne do zapewnienia zgodności z prawem wszystkich procesów przetwarzania.
              </Paragraph>
            </Section>
          </Section>
          <Section>
            <SectionHeader title={'3.3 OKRES PRZECHOWYWANIA TWOICH DANYCH'} />
            <Paragraph>
              Zgodnie z przepisami prawa zobowiązujemy się do przechowywania Twoich danych osobowych wyłącznie przez
              okres niezbędny do osiągnięcia zamierzonego celu, zaspokojenia Twoich potrzeb lub wypełnienia naszych
              zobowiązań prawnych.
            </Paragraph>
            <Paragraph>Aby określić ten okres, bierzemy pod uwagę następujące czynniki:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>czas niezbędny dla obsługi Twojego wniosku, zgłoszenia lub reklamacji,</Text>
              </Li>
              <Li>
                <Text type={'text'}>Twoje zainteresowanie naszymi usługami i Platformą,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  konieczność przechowywania określonej historii Twoich interakcji z Platformą, przy czym okres ten
                  różni się w zależności od tego, czy złożyłeś zamówienie na usługę,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  wypełnienie ciążącego na nas obowiązku ustawowego lub wynikającego z innych przepisów obowiązującego
                  prawa.
                </Text>
              </Li>
            </Ul>
            <Paragraph>W szczególności:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  dane osobowe gromadzone przez pliki cookie na potrzeby analizy wydajności treści przeglądanych na
                  Platformie (pomiar ruchu). „Nieobowiązkowe” elementy śledzące/pliki cookie, takie jak reklamowe pliki
                  cookie, są przechowywane przez maksymalnie 13 miesięcy od daty wyrażenia przez Ciebie zgody. Dane
                  osobowe gromadzone za pośrednictwem plików cookie będą przetwarzane przez okres 25 miesięcy, a
                  następnie anonimizowane. Twoje wybory (zgoda lub odmowa zgody na „nieobowiązkowe” pliki cookie
                  wymagające uprzedniej akceptacji) będą przechowywane przez okres 6 miesięcy od wyrażenia zgody. Więcej
                  informacji na temat naszej polityki dotyczącej plików cookie można znaleźć na stronie „Pliki cookie”
                  na Platformie,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  dane osobowe przetwarzane w ramach zarządzania działaniem i bezpieczeństwem Platformy oraz naszych baz
                  danych istniejących klientów/potencjalnych klientów są przechowywane przez okres 6 miesięcy (np. logi
                  itd.),
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  dane osobowe przetwarzane w ramach historii Czynności Technicznych wykonywanych za pośrednictwem
                  Platformy i/lub wysłanych formularzy zamówień są przechowywane przez okres 6 miesięcy. Należy
                  zaznaczyć, że wyceny dokonane przez Użytkownika za pośrednictwem Platformy są przechowywane przez
                  okres 3 miesięcy.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  jeżeli Użytkownik jest pracownikiem Renault lub pracownikiem przedsiębiorstwa będącego członkiem sieci
                  Renault, jego dane, zebrane za pośrednictwem Platformy, będą przechowywane przez okres obowiązywania
                  jego umowy o pracę z Renault lub, w odpowiednich przypadkach, przez okres obowiązywania upoważnienia
                  udzielonego przez pracodawcę Użytkownika w celu uzyskania dostępu do Platformy,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  dane osobowe dotyczące Twoich wniosków o korzystanie z praw są przechowywane przez okres 3 lat od
                  zamknięcia wniosku, w zależności od wykonywanego prawa. Jeżeli wymagany był dowód tożsamości, zostanie
                  on usunięty natychmiast po zakończeniu weryfikacji.
                </Text>
              </Li>
            </Ul>
            <Paragraph>
              Jeśli Twoje dane osobowe nie są już potrzebne, są usuwane z naszych systemów i rejestrów lub anonimizowane
              w sposób uniemożliwiający Twoją identyfikację.
            </Paragraph>
            <Paragraph>
              Nie jest jednak wykluczone, że będziemy musieli przechowywać niektóre z Twoich danych osobowych, aby móc
              odpowiedzieć na żądania dotyczące roszczeń i brać udział w postępowaniach sądowych, przez okres
              przedawnienia przewidziany w obowiązującym ustawodawstwie.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'4. KTO MA DOSTĘP DO TWOICH DANYCH OSOBOWYCH?'} />
          <Paragraph>Aby osiągnąć powyższe cele i tylko w zakresie niezbędnym do ich realizacji:</Paragraph>
          <Ul>
            <Li>
              pilnujemy, aby Twoje dane osobowe mogły być przetwarzane wyłącznie przez osoby należycie umocowane z uwagi
              na pełnione funkcje i wykonywane zadania.
            </Li>
            <Li>
              możemy przekazywać niektóre z tych danych następującym odbiorcom:
              <Ul type={'decimal'}>
                <MarginBox mt={10} />
                <Li>
                  członkom sieci Renault (tj. członkom sieci R1, u których Użytkownik jest upoważniony do składania
                  zamówień na Produkty), z zastrzeżeniem Twojej zgody, gdy jest ona wymagana przez przepisy prawa.
                  Renault i członkowie jego sieci zawarli bowiem umowę, na mocy której zobowiązują się, jako odrębni
                  administratorzy danych osobowych, do przestrzegania swoich obowiązków wynikających z przepisów
                  ustawowych i wykonawczych dotyczących ochrony danych osobowych, w tym między innymi do przestrzegania
                  następujących zasad:
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>przetwarzanie danych osobowych w sposób uczciwy i zgodny z prawem,</Li>
                    <Li>upewnienie się co do prawdziwości i jakości danych,</Li>
                    <Li>
                      wdrożenie wszelkich odpowiednich środków technicznych i organizacyjnych w celu zagwarantowania
                      poziomu bezpieczeństwa odpowiedniego do poziomu ryzyka oraz współpracy w przypadku naruszenia
                      ochrony danych,
                    </Li>
                    <Li>zatrudnianie wyłącznie godnych zaufania podwykonawców,</Li>
                    <Li>wdrożenie odpowiednich gwarancji w zakresie przekazywania danych,</Li>
                    <Li>
                      przestrzeganie praw zainteresowanych osób, w szczególności prawa do informacji o przetwarzaniu
                      danych.
                    </Li>
                  </Ul>
                  <MarginBox mt={10} />
                  Jeśli chodzi o ostatnią zasadę, pracownicy sieci Renault są informowani o tym, że niniejsza Nota
                  stanowi uzupełnienie noty informacyjnej dotyczącej przetwarzania danych, która mogła zostać Ci
                  przekazana przez pracodawcę jako podmiotu należącego do sieci Renault.
                </Li>
                <MarginBox mt={10} />
                <Li>
                  podwykonawcom przetwarzania danych osobowych w rozumieniu obowiązujących przepisów o ochronie danych
                  osobowych, w celu wykonania zadań. Dbamy o bezpieczeństwo tych relacji (umowy, audyty, gwarancje i
                  testy bezpieczeństwa itp.) oraz o to, aby otrzymywali oni instrukcje dotyczące zadań, które im
                  powierzamy, w tym w szczególności:
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>hosting,</Li>
                    <Li>
                      obsługa i utrzymanie Platformy oraz baz danych gromadzonych i/lub przetwarzanych za jej
                      pośrednictwem,
                    </Li>
                    <Li>
                      działania związane z poszukiwaniem klientów w stosunku do członków profesjonalnych należących do
                      sieci Renault (B2B),
                    </Li>
                    <Li>zarządzanie relacjami z klientami i wsparcie w zakresie incydentów związanych z platformą,</Li>
                    <Li>pomiar ruchu na Platformie,</Li>
                    <Li>
                      przeprowadzanie analiz w celu zmierzenia Twojego zadowolenia z usług Platformy i znajomości
                      oczekiwań naszej sieci.
                    </Li>
                    <MarginBox mt={7} />
                  </Ul>
                </Li>
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            Możemy również być zobowiązani do przekazania Twoich danych osobowych osobom trzecim w celu spełnienia
            obowiązku prawnego bądź wykonania decyzji administracyjnej lub sądowej.
          </Paragraph>
          <Paragraph>
            Renault może udostępniać Twoje dane osobom trzecim w przypadku przeniesienia obowiązków i praw związanych ze
            stosunkiem umownym między Tobą a Renault na te strony trzecie w przypadku przeniesienia działalności,
            łączenia się poprzez utworzenie nowej spółki, łączenia się przez przejęcie, podziału lub jakiejkolwiek
            zmiany kontroli dotyczącej Renault. W takim przypadku Renault uprzednio przekaże Ci specjalnie informacje
            dotyczące udostępniania Twoich danych i uzyska Twoją zgodę, jeśli jest to prawnie wymagane.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'5. JAKIE MASZ PRAWA?'} />
          <Section>
            <SectionHeader title={'5.1. TWOJE PRAWA'} />
            <Paragraph>Przysługuje Ci szereg praw wynikających z przepisów o ochronie danych osobowych:</Paragraph>
            <Paragraph>
              Prawo do wniesienia sprzeciwu wobec przetwarzania Twoich danych osobowych, pod warunkiem uzasadnienia
              Twojej szczególnej sytuacji, oraz prawo do żądania ograniczenia przetwarzania Twoich danych osobowych, w
              określonych przypadkach przewidzianych w przepisach prawa.
            </Paragraph>
            <Paragraph>
              Prawo rezygnacji z otrzymywania od nas informacji: w każdej chwili możesz zrezygnować z otrzymywania od
              nas informacji.
            </Paragraph>
            <Paragraph>
              Prawo do wycofania w dowolnym momencie zgody w odniesieniu do celów, dla których została ona udzielona
              (zob. pkt 3.2 powyżej CELE PRZETWARZANIA DANYCH OSOBOWYCH).
            </Paragraph>
            <Paragraph>
              Prawo do informacji: masz prawo do uzyskania jasnych, przejrzystych i zrozumiałych informacji o tym, w
              jaki sposób wykorzystujemy Twoje dane osobowe oraz o przysługujących Ci prawach. Przykładem tego jest
              niniejsza Nota.
            </Paragraph>
            <Paragraph>
              Prawo dostępu do swoich danych osobowych: masz prawo do uzyskania informacji o przetwarzaniu swoich danych
              osobowych (w szczególności o tym, jakie dane są wykorzystywane i w jakim celu itd.), a także uzyskania ich
              kopii.
            </Paragraph>
            <Paragraph>
              Prawo do sprostowania: masz prawo do sprostowania swoich danych, jeżeli są one niedokładne lub
              niekompletne pomimo naszych starań, aby je aktualizować. Umożliwi nam to wywiązanie się z obowiązku
              aktualizacji danych.
            </Paragraph>
            <Paragraph>
              Prawo do przenoszenia danych, czyli, pod pewnymi warunkami, prawo do otrzymywania danych osobowych, które
              nam przekazałeś, w uporządkowanym, powszechnie używanym formacie informatycznym oraz do przekazywania ich
              stronie trzeciej, jeżeli jest to technicznie możliwe.
            </Paragraph>
            <Paragraph>
              Prawo do usunięcia danych (lub prawo do bycia zapomnianym): masz prawo do usunięcia swoich danych. Prawo
              to może być ograniczone w świetle naszych zobowiązań umownych (bieżąca umowa) lub zobowiązań prawnych (w
              szczególności tych związanych z zapobieganiem sporom sądowym).
            </Paragraph>
            <Paragraph>
              Ponadto masz prawo złożyć skargę do organu nadzoru ochrony danych osobowych, np. Commission Nationale
              Informatique et Libertés (CNIL) we Francji. Zachęcamy do skontaktowania się z nami przed wniesieniem
              jakiejkolwiek skargi, aby podjąć próbę wspólnego rozwiązania problemu. Więcej informacji o przysługujących
              ci prawach znajdziesz pod linkiem{' '}
              <Link to={'https://www.cnil.fr/professionnel'} external newTab>
                https://www.cnil.fr/professionnel
              </Link>
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'5.2. JAK EGZEKWOWAĆ SWOJE PRAWA?'} />
            <Paragraph>
              W celu wykonania któregokolwiek z przysługujących Ci praw możesz się w każdej chwili z nami skontaktować
              wysyłając pismo na następujący adres:
            </Paragraph>
            <Paragraph>
              Renault SAS, Dział Prawny - Inspektor Ochrony Danych, 122-122 bis avenue du Général Leclerc 92100
              Boulogne-Billancourt, Francja.
            </Paragraph>
            <Paragraph>lub</Paragraph>
            <Paragraph>
              e-mailem pod następującym adresem:{' '}
              <Link to={'mailto:dataprotection-com@renault.com'} external newTab>
                dataprotection-com@renault.com
              </Link>
              .
            </Paragraph>
            <Paragraph>
              W obu przypadkach, w celu łatwiejszego przetworzenia wniosku, należy określić, że dotyczy on Platformy „
              <b>RPARTSTORE</b>”.
            </Paragraph>
            <Paragraph>
              W przypadku wątpliwości co do Twojej tożsamości możesz zostać poproszony o przedstawienie dowodu
              tożsamości (kopia dowodu tożsamości jest przetwarzana wyłącznie w celu udzielenia odpowiedzi na Twój
              wniosek i jest usuwana natychmiast po weryfikacji).
            </Paragraph>
            <Paragraph>
              Dołożymy wszelkich starań, aby odpowiedzieć w możliwie najkrótszym terminie, najpóźniej w ciągu jednego
              miesiąca od otrzymania wniosku. Zgodnie z obowiązującymi przepisami zastrzegamy sobie możliwość
              przedłużenia tego okresu do trzech miesięcy w przypadku dużej złożoności Twojego wniosku.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'6. W JAKI SPOSÓB ZAPEWNIAMY BEZPIECZEŃSTWO TWOICH DANYCH OSOBOWYCH?'} />
          <Paragraph>
            Twoje dane osobowe są przechowywane na zabezpieczonych serwerach. Wprowadzamy odpowiednie środki
            bezpieczeństwa i ochrony danych, wykorzystujące najnowsze technologie i wymagamy tego samego od swoich
            podwykonawców i partnerów.
          </Paragraph>
          <Paragraph>
            Jeśli przetwarzanie danych osobowych wymaga ich przekazania, zapewniamy przesył danych w odpowiednich
            warunkach, które gwarantują wystarczający poziom ochrony, bezpieczeństwa i poufności.
          </Paragraph>
          <Paragraph>
            W możliwie jak najszerszym zakresie twoje dane przetwarzane są na serwerach znajdujących się na terytorium
            Europejskiego Obszaru Gospodarczego (EOG). Ponieważ jednak niektórzy nasi dostawcy usług lub ich
            podwykonawcy mają siedzibę w krajach poza EOG, Twoje dane osobowe są przetwarzane w tych krajach. W
            niektórych z tych krajów mogą obowiązywać inne przepisy dotyczące ochrony danych niż w Unii Europejskiej. W
            takich przypadkach dokładamy szczególnych starań, by zapewnić przekazywanie danych zgodnie z przepisami
            rozporządzenia i z zachowaniem zabezpieczeń w celu ochrony twojej prywatności, podstawowych praw i wolności
            na poziomie równorzędnym z ochroną w Unii Europejskiej (w szczególności poprzez stosowanie standardowych
            klauzul umownych określonych przez Komisję Europejską). Wystarczy, że zgłosisz zapytanie na adres wskazany w
            części 5. „ JAKIE SĄ TWOJE PRAWA?”, a prześlemy Ci dodatkowe informacje dotyczące takiego przekazania danych
            (w szczególności standardowych klauzul umownych określonych przez Komisję Europejską).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. LINK DO INNYCH INFORMACJI'} />
          <Paragraph>
            Możemy również automatycznie gromadzić informacje techniczne dotyczące Twojej osoby podczas korzystania
            przez Ciebie z naszych serwisów internetowych lub naszych aplikacji mobilnych, w szczególności adres IP i
            parametry przeglądania, za pomocą elementów śledzących (plików cookie). Więcej informacji na stronie „
            <Link to={ROUTER_COOKIES(Country.PL.key)} newTab>
              Pliki cookie
            </Link>
            ” na Platformie.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. MODYFIKACJA INFORMACJI'} />
          <Paragraph>
            Co pewien czas możemy być zmuszeni do modyfikowania niniejszej Noty. Gdy będzie to konieczne lub wymagane,
            powiadomimy Cię i/lub będziemy prosić o zgodę. Dlatego zachęcamy do regularnego sprawdzania informacji przy
            okazji odwiedzania portalu w celu zapoznania się z jej najnowszą wersją.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Aktualizacja: 30 maja 2023 roku</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
