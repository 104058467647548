/* eslint-disable max-len */
import { GetMaintenanceValuesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_values/request/GetMaintenanceValuesRequest';
import { GetMaintenanceValuesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_values/response/GetMaintenanceValuesResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { getLastVehicleDetail, getVehicleContext } from 'domains/catalog/Catalog.store';
import { sendMaintenanceValuesRequest } from 'domains/maintenanceValues/MaintenanceValues.api';
import * as actions from 'domains/maintenanceValues/MaintenanceValues.store';
import {
  setMaintenanceValues,
  setMaintenanceValuesStatus,
  setMaintenanceValuesXml,
} from 'domains/maintenanceValues/MaintenanceValues.store';
import { getUserContext } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { LOADING, sagaGuard, select } from 'utils';

/* eslint-enable max-len */

export function* fetchMaintenanceValuesRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  if (!vehicleDetail) return;
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleDetail.vehicleKey));
  const userContext = yield* select(getUserContext);
  const request: GetMaintenanceValuesRequest = { vehicleContext, userContext };
  yield put(setMaintenanceValuesStatus({ status: LOADING }));
  yield put(setMaintenanceValuesXml(undefined));
  yield put(sendMaintenanceValuesRequest(request));
}

export function* fetchMaintenanceValuesResponseSaga(action: WsResponse<GetMaintenanceValuesResponse>): SagaIterator {
  yield put(setMaintenanceValues(action.payload));
}

export function* MaintenanceValuesSagas(): SagaIterator {
  yield takeEvery(actions.fetchMaintenanceValuesRequestSaga.type, sagaGuard(fetchMaintenanceValuesRequestSaga));
  yield takeEvery(actions.fetchMaintenanceValuesResponseSaga.type, sagaGuard(fetchMaintenanceValuesResponseSaga));
}
