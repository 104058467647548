import React, { ReactNode } from 'react';
import { Empty as AntdEmpty } from 'antd';
import { Box, MarginBox } from 'UI/Box';
import { CenterFlex, Flex } from 'UI/Flex';
import { Text } from 'UI/Text';

interface EmptyProps {
  image?: React.ReactNode;
  description?: string;
}

const EmptyWithProps = ({ image = AntdEmpty.PRESENTED_IMAGE_SIMPLE, description }: EmptyProps) => (
  <AntdEmpty description={description} image={image} />
);

export const Empty = ({ image, description }: EmptyProps) => <EmptyWithProps image={image} description={description} />;

export const CenteredEmpty = () => (
  <CenterFlex>
    <Empty />
  </CenterFlex>
);

export const EmptyDialog = ({
  title,
  description,
  image,
}: {
  title: ReactNode;
  description: ReactNode;
  image: ReactNode;
}) => (
  <MarginBox mx={45} my={45}>
    <Flex direction={'column'} align={'center'}>
      {image}
      <Box height={15} />
      <Text type={'h5_bold'}>{title}</Text>
      <Text type={'light_14_85'}>{description}</Text>
    </Flex>
  </MarginBox>
);
