import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { Flex, MarginBox, Text, Checkbox } from 'UI';
import { getDealerTypeLabel, getDealerTypes } from '../Options';

export const TargetAudience = ({
  information,
  setInformation,
}: {
  information: CreateInformationModel | CreateInformationTemplateModel;
  setInformation: (informationToCreate: CreateInformationModel | CreateInformationTemplateModel) => void;
}) => {
  const { t } = useTranslation();
  const dealerTypes = getDealerTypes();

  return (
    <>
      <Text type={'h2'}>{t('backoffice.information.create_information.targeted_audience', 'Targeted audience')}</Text>
      <Flex direction={'row'}>
        {dealerTypes.map((dealer) => (
          <MarginBox key={`promotion_dealer_checkbox_${dealer}`} mr={30}>
            <Checkbox
              checked={information.targetAudience?.includes(dealer)}
              onChange={(v) => {
                const checked = v;
                const currentDealers = information.targetAudience ?? [];
                if (checked) {
                  setInformation({
                    ...information,
                    targetAudience: [dealer, ...currentDealers],
                  });
                } else {
                  setInformation({
                    ...information,
                    targetAudience: currentDealers.filter((d) => d !== dealer),
                  });
                }
              }}
              label={<Text type={'light_14_black_65'}>{getDealerTypeLabel(dealer, t)}</Text>}
            />
          </MarginBox>
        ))}
      </Flex>
    </>
  );
};
