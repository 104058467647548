import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { SSOLinkResponse } from '@1po/1po-bff-fe-spec/generated/sso/SSOLinkResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { LOADING, mapQueryWsResponseToSearchData, sagaGuard } from 'utils';
import { sendGetSSOLink } from './SSOLink.api';
import * as actions from './SSOLink.store';
import { setSSOLink } from './SSOLink.store';

export function* fetchSSOLinkRequest({ payload }: ReturnType<typeof actions.fetchSSOLinkRequest>): SagaIterator {
  yield put(setSSOLink({ searchStatus: LOADING }));
  yield put(sendGetSSOLink({ ssoLinkType: payload }));
}

export function* fetchSSOLinkResponse(action: WsResponse<SSOLinkResponse>): SagaIterator {
  yield put(setSSOLink(mapQueryWsResponseToSearchData(action)));
}

export function* SSOLinkSagas(): SagaIterator {
  yield takeEvery(actions.fetchSSOLinkRequest.type, sagaGuard(fetchSSOLinkRequest));
  yield takeEvery(actions.fetchSSOLinkResponse.type, sagaGuard(fetchSSOLinkResponse));
}
