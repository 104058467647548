import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import Popover from 'components/Popover';
import {
  Box,
  Flex,
  MarginBox,
  notifyNotImplemented,
  Panel,
  SectionCollapse,
  Tab,
  Tabs,
  TabsTitle,
  Text,
  YellowButton,
} from 'UI';
import { FAQList } from './Faq';
import { RecentList, TutorialData, TutorialList } from './Tutorial';

const staticTutorial: TutorialData = {
  title: 'Tutorial name',
  published: new Date(2022, 5, 5),
  author: 'Author name',
  section: 'Section',
  category: 'Category',
  img: undefined,
};

const staticTutorialSecond: TutorialData = {
  title: 'How to find my password',
  published: new Date(2022, 3, 4),
  author: 'Author name',
  section: 'Section',
  category: 'Category',
  img: undefined,
};

const recentData = [staticTutorial, staticTutorial, staticTutorial, staticTutorial];
const tutorialsData = [
  staticTutorial,
  staticTutorialSecond,
  staticTutorial,
  staticTutorial,
  staticTutorial,
  staticTutorial,
  staticTutorial,
  staticTutorial,
].map((t, i) => ({ ...t, title: `${t.title} #${i + 1}` }));

export type FAQStatus = 'published' | 'publishing' | 'not_published_yet';

export interface FAQ {
  key: string;
  title: string;
  section: string;
  author: string;
  status: FAQStatus;
  published: Date;
}

const faqData: FAQ[] = [
  {
    title: 'How do I cancel an order?',
    section: 'Order',
    author: 'Marc Murphy',
    status: 'published',
    published: new Date(2022, 6, 9),
    key: '1',
  },
  {
    title: 'How do I delete a reference?',
    section: 'Cart',
    author: 'Author name',
    status: 'published',
    published: new Date(2022, 6, 6),
    key: '2',
  },
  {
    title: 'When we will be able to add other too looooooong text in order to surely trigger overflow',
    section: 'Marketing',
    author: 'Pavlína Nejvymyslenější',
    status: 'published',
    published: new Date(2022, 4, 2),
    key: '3',
  },
  {
    title: 'Where is my order?',
    section: 'Delivery',
    author: 'Hans',
    status: 'published',
    published: new Date(2022, 2, 0),
    key: '4',
  },
];

const AssistanceTab = ({
  recents,
  faqs,
  tutorials,
}: {
  recents: TutorialData[];
  faqs: FAQ[];
  tutorials: TutorialData[];
}) => (
  <>
    <RecentList data={recents} />
    <MarginBox mt={45}>
      <SectionCollapse>
        <Panel
          header={
            <Text type={'h2'} cursor={'pointer'}>
              <Trans i18nKey="backoffice.assistance.frequently_asked_questions">Frequently asked questions</Trans>
            </Text>
          }
          key={'frequently_asked_questions'}
        >
          <FAQList data={faqs} />
        </Panel>
      </SectionCollapse>
    </MarginBox>
    <MarginBox mt={45}>
      <SectionCollapse>
        <Panel
          header={
            <Text type={'h2'} cursor={'pointer'}>
              <Trans i18nKey="backoffice.assistance.tutorials">Tutorials</Trans>
            </Text>
          }
          key={'tutorials'}
        >
          <TutorialList data={tutorials} />
        </Panel>
      </SectionCollapse>
    </MarginBox>
  </>
);

type LinkItem = { key: number; link: string; label: ReactNode; component: ReactNode; disabled?: boolean };

const countryAssistanceItems: LinkItem[] = [
  {
    key: 1,
    link: '/published',
    label: <Trans i18nKey="backoffice.assistance.published">Published</Trans>,
    component: <AssistanceTab recents={recentData} faqs={faqData} tutorials={tutorialsData} />,
  },
  {
    key: 2,
    link: '/saved',
    label: <Trans i18nKey="backoffice.assistance.saved">Saved</Trans>,
    component: <AssistanceTab recents={[]} faqs={[]} tutorials={[]} />,
  },
  {
    key: 3,
    link: '/archived',
    label: <Trans i18nKey="backoffice.assistance.archived">Archived</Trans>,
    component: <AssistanceTab recents={[]} faqs={[]} tutorials={[]} />,
    disabled: true,
  },
];

const centralAssistanceItems: LinkItem[] = [
  {
    key: 1,
    link: '/sent',
    label: <Trans i18nKey="backoffice.assistance.sent">Sent</Trans>,
    component: <AssistanceTab recents={recentData} faqs={faqData} tutorials={tutorialsData} />,
  },
  {
    key: 2,
    link: '/saved',
    label: <Trans i18nKey="backoffice.assistance.saved">Saved</Trans>,
    component: <AssistanceTab recents={[]} faqs={[]} tutorials={[]} />,
  },
  {
    key: 3,
    link: '/archived',
    label: <Trans i18nKey="backoffice.assistance.archived">Archived</Trans>,
    component: <AssistanceTab recents={[]} faqs={[]} tutorials={[]} />,
    disabled: true,
  },
];

const Assistance = ({ items }: { items: LinkItem[] }) => {
  return (
    <>
      <Flex>
        <Flex>
          <Text type={'h2'}>
            <Trans i18nKey="backoffice.assistance.recent">Recent</Trans>
          </Text>
        </Flex>
        <Box width={240}>
          <Popover
            content={
              <Box width={280} height={160}>
                <Box height={60}>
                  <Text type={'text'}>
                    <Trans i18nKey="backoffice.assistance.create.title">What would you like to create?</Trans>
                  </Text>
                </Box>
                <Box height={50}>
                  <Trans i18nKey="backoffice.assistance.create.tutorial">
                    <Text type={'text'}>New tutorial</Text>
                  </Trans>
                </Box>
                <Box height={50}>
                  <Trans i18nKey="backoffice.assistance.create.faq">
                    <Text type={'text'}>New FAQ</Text>
                  </Trans>
                </Box>
              </Box>
            }
            trigger="click"
            placement={'bottom'}
          >
            <YellowButton onClick={notifyNotImplemented}>
              <Trans i18nKey="backoffice.assistance.create_new_support">Create new support</Trans>
            </YellowButton>
          </Popover>
        </Box>
      </Flex>
      <MarginBox mt={15}>
        <Tabs>
          {items.map((item) => (
            <Tab tab={<TabsTitle>{item.label}</TabsTitle>} key={item.key} disabled={item.disabled}>
              {item.component}
            </Tab>
          ))}
        </Tabs>
      </MarginBox>
    </>
  );
};

export const CountryAssistance = () => <Assistance items={countryAssistanceItems} />;

export const CentralAssistance = () => <Assistance items={centralAssistanceItems} />;
