import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, List } from 'antd';
import { ChevronDownIcon } from 'assets/icons';
import { SFilterPanel } from 'components/Filter/Filter.styled';
import { getIamCatalogFilters, resetIamCategoriesFilter, setIamCategoriesFilter } from 'domains/catalog/Catalog.store';
import { SListItem } from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalData.styled';
import { Box, Flex, Icon, MarginBox, SectionCollapse, Text } from 'UI';

const Filter = ({ categories }: { categories: string[] | undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const categoriesFilter = useSelector(getIamCatalogFilters).categories;

  const resetFilters = () => {
    dispatch(resetIamCategoriesFilter());
  };

  const setCategoriesFilter = (category: string) => {
    dispatch(setIamCategoriesFilter(category));
  };

  return (
    <Box width={'100%'}>
      <MarginBox ml={15} mt={-15} mr={10}>
        <Flex justify={'space-between'} align={'center'}>
          <Text type={'h3'}>{t('catalog.iam.spare_parts.filter.filter_by', 'Filter by')}</Text>
          <Box>
            <Flex>
              <Text type={'light_14_black_85'} cursor={'pointer'} onClick={resetFilters}>
                {t('catalog.iam.spare_parts.filter.clear_filters', 'Clear filters')}
              </Text>
              <MarginBox mt={4} ml={5}>
                <Icon IconComponent={ChevronDownIcon} size={16} />
              </MarginBox>
            </Flex>
          </Box>
        </Flex>
      </MarginBox>
      <SectionCollapse position={'end'} noShadow>
        <SFilterPanel
          header={<Text type={'text_dim'}>{t('catalog.iam.technical_data.filter.categories', 'Categories')}</Text>}
          key="1"
        >
          <List
            dataSource={categories}
            renderItem={(category) => (
              <SListItem>
                <Checkbox checked={categoriesFilter.includes(category)} onChange={() => setCategoriesFilter(category)}>
                  <Text type={'light_14_black_65'}>{category}</Text>
                </Checkbox>
              </SListItem>
            )}
            split={false}
          />
        </SFilterPanel>
      </SectionCollapse>
    </Box>
  );
};

export default Filter;
