import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadModal } from 'components/FileUpload';
import {
  API_CALL_ERROR,
  CONTENT_LIMIT_ERROR,
  FILE,
  FILE_FORMAT_ERROR,
  FILE_UPLOADED,
  MAX_SIZE_ERROR,
  ModalStatusType,
  NO_FILE,
} from 'components/FileUpload/paramsByState';
import {
  getOrderListFileUploadErrorRows,
  getOrderListFileUploadIsModalOpen,
  getOrderListFileUploadModalStatus,
  getOrderListFileUploadStatus,
  setOrderListFileModalStatus,
  setOrderListFileReferencesIsModalOpen,
  uploadFileOrderList,
} from 'domains/orderList/OrderList.store';
import { MarginBox, Text } from 'UI';
import { OrderListReferencesRowsErrors } from './index';

export default function OrderListReferencesFileUpload() {
  const status = useSelector(getOrderListFileUploadStatus);
  const modalStatus = useSelector(getOrderListFileUploadModalStatus);
  const modalIsOpen = useSelector(getOrderListFileUploadIsModalOpen);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorRows = useSelector(getOrderListFileUploadErrorRows);

  const uploadFile = (fileName: string, _fileSize: number, fileBase64: string) => {
    dispatch(uploadFileOrderList({ fileName, fileBase64 }));
  };

  function validateFile(file: File) {
    if (!file.name.endsWith('.csv')) {
      dispatch(setOrderListFileModalStatus(FILE_FORMAT_ERROR));
      return false;
    } else if (file.size > 10 * 1024) {
      dispatch(setOrderListFileModalStatus(MAX_SIZE_ERROR));
      return false;
    } else {
      dispatch(setOrderListFileModalStatus(FILE));
      return true;
    }
  }

  const handleNoFile = () => dispatch(setOrderListFileModalStatus(NO_FILE));

  const handleCancel = () => dispatch(setOrderListFileReferencesIsModalOpen(false));

  const descriptions = [
    t('cart.file_upload.drag_n_drop.support_extension', 'Support extension: CSV - Maximum file size: 10KB'),
    t('cart.file_upload.drag_n_drop.max_references', '99 references maximum'),
  ];

  const getText1Override = (modalStatusType: ModalStatusType) => {
    if (modalStatusType === CONTENT_LIMIT_ERROR) {
      return t('cart.file_upload.drag_n_drop.max_references_error', 'More than 99 references');
    }
    return undefined;
  };

  const resolveComponent = (): JSX.Element | React.ReactNode => {
    switch (status) {
      case NO_FILE:
      case FILE_UPLOADED:
        return (
          <>
            {errorRows.length > 0 && (
              <>
                <OrderListReferencesRowsErrors />
                <MarginBox mt={15} />
              </>
            )}
          </>
        );
      case API_CALL_ERROR:
        return (
          <Text type={'text_dim_bold'}>
            {t('common.file_upload.drag_n_drop.api_call_error', 'No response from API')}
          </Text>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {resolveComponent()}
      <FileUploadModal
        template={{ link: '/fileTemplates/importFile.csv', fileName: 'Order list references upload - template.csv' }}
        handleFileBase64={uploadFile}
        validateFile={validateFile}
        handleNoFile={handleNoFile}
        handleCancel={handleCancel}
        isOpen={modalIsOpen}
        status={modalStatus}
        descriptions={descriptions}
        getText1Override={getText1Override}
      />
    </>
  );
}
