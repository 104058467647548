import React, { ReactNode, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { FileAltRegularIcon } from 'assets/icons';
import { Dialog, Disclosure } from 'components/Dialog';
import { VehicleItem } from 'components/SearchVehicleCountrySelection/SearchVehicleCountrySelection';
import { createNewEstimate, getCurrentEstimateId, getEstimateVehicleKey } from 'domains/estimate/Estimate.store';
import { Flex, MarginBox, Radio } from 'UI';

enum ADD_TO_ESTIMATE_OPTION {
  CURRENT,
  NEW,
  CURRENT_WITH_VEHICLE,
  NEW_WITH_VEHICLE,
}

interface RadioButtonsOptions {
  option: ADD_TO_ESTIMATE_OPTION;
  label: ReactNode;
  onConfirm?: () => void;
  vehicleDetail?: React.ReactNode;
}

function getRadioButtonsOptions(
  dispatch: Dispatch,
  vehicleDetail: VehicleDetail | undefined,
  openInCatalog: boolean,
  estimateWithoutVehicle: boolean,
): RadioButtonsOptions[] {
  return [
    estimateWithoutVehicle
      ? {
          option: ADD_TO_ESTIMATE_OPTION.CURRENT,
          label: (
            <Trans i18nKey={'estimate.add_dialog.add_to_current_estimate'}>
              Add to <strong>current</strong> estimate not linked to the current vehicle
            </Trans>
          ),
        }
      : undefined,
    estimateWithoutVehicle || (!estimateWithoutVehicle && !openInCatalog)
      ? {
          option: ADD_TO_ESTIMATE_OPTION.NEW,
          label: (
            <Trans i18nKey={'estimate.add_dialog.add_to_new_estimate'}>
              Add to a <strong>new</strong> estimate not linked to the current vehicle
            </Trans>
          ),
          onConfirm: () => {
            dispatch(createNewEstimate({ vehicle: undefined }));
          },
        }
      : undefined,
    !estimateWithoutVehicle
      ? {
          option: ADD_TO_ESTIMATE_OPTION.CURRENT_WITH_VEHICLE,
          label: (
            <Trans i18nKey={'estimate.add_dialog.add_to_current_estimate_with_vehicle'}>
              Add to the <strong>previous</strong> estimate from the current car
            </Trans>
          ),
          vehicleDetail: vehicleDetail ? <VehicleItem v={vehicleDetail} /> : null,
        }
      : undefined,
    vehicleDetail
      ? {
          option: ADD_TO_ESTIMATE_OPTION.NEW_WITH_VEHICLE,
          label: (
            <Trans i18nKey={'estimate.add_dialog.add_to_new_estimate_with_vehicle'}>
              Add to <strong>new</strong> estimate from the current car
            </Trans>
          ),
          onConfirm: () => {
            dispatch(createNewEstimate({ vehicle: vehicleDetail }));
          },
          vehicleDetail: vehicleDetail ? <VehicleItem v={vehicleDetail} /> : null,
        }
      : undefined,
  ].filter((v) => !!v) as RadioButtonsOptions[];
}

interface AddToEstimateDialogProps {
  vehicleDetail: VehicleDetail | undefined;
  disclosure: Disclosure;
  handleConfirm: () => void;
  openInCatalog: boolean;
}

export const AddToEstimateDialog = ({
  vehicleDetail,
  disclosure,
  handleConfirm,
  openInCatalog,
}: AddToEstimateDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const estimateId = useSelector(getCurrentEstimateId);
  const estimateVehicleKey = useSelector((state: RootState) => getEstimateVehicleKey(state, estimateId));
  const options = useMemo(() => {
    return getRadioButtonsOptions(dispatch, vehicleDetail, openInCatalog, estimateVehicleKey === undefined);
  }, [dispatch, estimateVehicleKey, openInCatalog, vehicleDetail]);
  const [addToEstimateOption, setAddToEstimateOption] = useState<ADD_TO_ESTIMATE_OPTION>(options[0].option);

  const handleConfirmLocal = () => {
    const option = options.find((o) => o.option === addToEstimateOption);
    option?.onConfirm?.();
    handleConfirm();
  };

  return (
    <Dialog
      title={t('catalog.action.add_ref_to_estimate', 'Add reference to estimate')}
      icon={FileAltRegularIcon}
      handleConfirm={handleConfirmLocal}
      disclosure={disclosure}
      width={750}
      content={
        <Flex direction={'column'} justify={'flex-start'}>
          {options.map((o, key) => (
            <React.Fragment key={key}>
              <Radio
                checked={addToEstimateOption === o.option}
                label={o.label}
                onChange={() => setAddToEstimateOption(o.option)}
              />
              <MarginBox mt={10}>{o.vehicleDetail}</MarginBox>
            </React.Fragment>
          ))}
        </Flex>
      }
    />
  );
};
