import React from 'react';
import styled from 'styled-components';
import { Image, Text } from 'UI';

export const Card = styled.div`
  width: 600px;
  box-shadow: 0 4px 10px 0 ${({ theme }) => theme.color.shadow_2};
`;

export const STextWrapper = styled.div`
  padding: 15px 45px 45px 45px;
`;

const MenuCard = ({
  cardTitle,
  cardSubtitle,
  bannerImg,
}: {
  cardTitle: React.ReactNode;
  cardSubtitle: React.ReactNode;
  bannerImg: string;
}) => {
  return (
    <Card>
      <Image src={bannerImg} alt={bannerImg} />
      <STextWrapper>
        <Text type={'h1_black'}>{cardTitle}</Text>
        <Text type={'text_dim'}>{cardSubtitle}</Text>
      </STextWrapper>
    </Card>
  );
};

export default MenuCard;
