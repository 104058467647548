import React from 'react';
import { Trans } from 'react-i18next';
import { FolderOpenIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import {
  BlackButton,
  Box,
  CenteredSpin,
  EmptyDialog,
  Flex,
  Label,
  MarginBox,
  Text,
  TrashAltButton,
  WhiteButton,
} from 'UI';
import { notifyNotImplemented } from 'UI/Notification/notification';
import { StatusDot } from 'UI/StatusDot';
import { textFormatter } from 'utils';
import { STable } from './Faq.styled';

export type FAQStatus = 'published' | 'publishing' | 'not_published_yet';

export interface FAQ {
  key: string;
  title: string;
  section: string;
  author: string;
  status: FAQStatus;
  published: Date;
}

const faqColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: '25%',
    render: function rowSelector(title: string) {
      return (
        <Flex align={'center'}>
          <TrashAltButton bgColor={'transparent'} />
          <MarginBox ml={15}>
            <Label width={200} height={17}>
              <Text type={'section_bold'} disableGutter transform={'capitalize'} ellipsis>
                {title}
              </Text>
            </Label>
          </MarginBox>
        </Flex>
      );
    },
  },
  {
    title: 'Section',
    dataIndex: 'section',
    key: 'section',
    width: '15%',
    render: function rowSelector(section: string) {
      return (
        <Text type={'light_14_85'} transform={'capitalize'}>
          {section}
        </Text>
      );
    },
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    width: '15%',
    render: function rowSelector(author: string) {
      return (
        <Text type={'light_14_85'} transform={'capitalize'}>
          {author}
        </Text>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    render: function rowSelector(status: FAQStatus) {
      return (
        <Flex>
          <StatusDot color={'selected'} />
          <Box width={18} />
          <Text type={'light_14_85'} transform={'capitalize'}>
            {status}
          </Text>
        </Flex>
      );
    },
  },
  {
    title: 'Published',
    dataIndex: 'published',
    key: 'published',
    width: '15%',
    render: function rowSelector(published: Date) {
      return (
        <Text type={'light_14_85'} transform={'capitalize'}>
          {textFormatter.formatDateNumeric(published)}
        </Text>
      );
    },
  },
  {
    title: 'Action',
    dataIndex: 'status',
    key: 'action',
    width: '15%',
    render: function rowSelector() {
      return <BlackButton onClick={notifyNotImplemented}>Stop publication</BlackButton>;
    },
  },
];

export const FAQList = ({ data }: { data: FAQ[] }) => (
  <DataContainer data={data} Loading={() => <CenteredSpin size={'large'} />}>
    {data.length > 0 ? (
      <STable<FAQ> dataSource={data} columns={faqColumns} pagination={false} />
    ) : (
      <EmptyDialog
        title={
          <Trans i18nKey="backoffice.assistance.faq.is_empty_title">{"You don't have any FAQ in your folders"}</Trans>
        }
        description={
          <Trans i18nKey="backoffice.assistance.faq.is_empty_description">FAQs shared with you will appear here.</Trans>
        }
        image={<FolderOpenIcon />}
      />
    )}
    <MarginBox mt={45}>
      <Flex justify={'center'}>
        <Box width={160}>
          <WhiteButton onClick={notifyNotImplemented}>
            <Trans i18nKey="common.action.show_more">Show more</Trans>
          </WhiteButton>
        </Box>
      </Flex>
    </MarginBox>
  </DataContainer>
);
