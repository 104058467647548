import React from 'react';
import { Text as PdfText, View } from '@react-pdf/renderer';
import { VerticalSpacer } from 'components/Pdf/PdfUI';
import { Flex, MarginBox, Text } from 'UI';
import { textFormatter } from 'utils';

export const toDate = (rawDate: string) => {
  const date = new Date(rawDate);
  return (
    <Flex direction={'row'} align={'center'}>
      <Text type={'light_14_black_85'}>{textFormatter.formatDateShort(date)}</Text>
      <MarginBox mr={5} />
      <Text type={'light_14_black_85'}>{textFormatter.formatLocaleTimeString(date)}</Text>
    </Flex>
  );
};

export const toDatePdf = (rawDate: string) => {
  const date = new Date(rawDate);
  return (
    <View>
      <PdfText>{textFormatter.formatDateShort(date)}</PdfText>
      <VerticalSpacer />
      <PdfText>{textFormatter.formatLocaleTimeString(date)}</PdfText>
    </View>
  );
};
