import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StatusType as InformationStatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformations';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import { Ban, PencilAltIcon, TrashAltIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { fetchArchiveInformationRequestSaga, resetInformation } from 'domains/information/Information.store';
import { EDIT, INFORMATION } from 'pages/BackOfficePage/BackOfficePage.type';
import { StatusType } from 'styles';
import { Flex, Icon, IconType, Text } from 'UI';
import { ActionWrapper } from './Information.styled';

interface InformationActionsProps {
  tab: InformationStatusType;
  informationId: string;
}

interface DialogStateProps {
  title: string;
  description: ReactNode | undefined;
  icon: IconType | undefined;
  status: StatusType;
  action: () => void;
}

const InformationActions = ({ tab, informationId }: InformationActionsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [dialogState, setDialogState] = useState<DialogStateProps>({
    title: '',
    description: undefined,
    icon: undefined,
    status: 'info',
    action: () => undefined,
  });
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;

  const getIcon = (icon: IconType) => <Icon IconComponent={icon} size={16} ml={15} mr={15} />;

  const getEditAction = (action: () => void) => (
    <ActionWrapper onClick={action}>
      {getIcon(PencilAltIcon)}
      <Text type={'text_bold'}>{t('common.action.edit', 'Edit')}</Text>
    </ActionWrapper>
  );
  const getStopPublicationAction = (action: () => void) => (
    <ActionWrapper hoverRed onClick={action}>
      {getIcon(TrashAltIcon)}
      <Text type={'text_bold'}>{t('backoffice.stop_publication', 'Stop publication')}</Text>
    </ActionWrapper>
  );
  const getDeleteAction = (action: () => void) => (
    <ActionWrapper hoverRed onClick={action}>
      {getIcon(TrashAltIcon)}
      <Text type={'text_bold'}>{t('common.action.delete', 'Delete')}</Text>
    </ActionWrapper>
  );

  const getActions = () => {
    switch (tab) {
      case 'PUBLISHED':
        return (
          <Flex direction={'column'}>
            {getEditAction(() => {
              dispatch(resetInformation({ id: informationId }));
              history.push(`${ROUTER_BACKOFFICE}/${INFORMATION}/${EDIT}/${informationId}`);
            })}
            {getStopPublicationAction(() => {
              setDialogState({
                title: 'Stop publication',
                description: (
                  <Flex direction={'column'} align={'center'}>
                    <Text type={'lead'}>
                      {t(
                        'backoffice.information.stop_publication.description',
                        'Following this action, your information will be unpublished and archived.',
                      )}
                    </Text>
                    <Text type={'lead'}>{t('common.dialog.confirmationQuestion', 'Would you like to confirm?')}</Text>
                  </Flex>
                ),
                icon: Ban,
                status: 'error',
                action: () => dispatch(fetchArchiveInformationRequestSaga({ informationId })),
              });
              onOpen();
            })}
          </Flex>
        );
      case 'PLANNED':
      case 'SAVED':
        return (
          <Flex direction={'column'}>
            {getEditAction(() => {
              dispatch(resetInformation({ id: informationId }));
              history.push(`${ROUTER_BACKOFFICE}/${INFORMATION}/${EDIT}/${informationId}`);
            })}
            {getDeleteAction(() => undefined)}
          </Flex>
        );
      case 'ARCHIVED':
      default:
        return <></>;
    }
  };

  return (
    <>
      <Dialog
        title={dialogState.title}
        content={dialogState.description}
        status={dialogState.status}
        icon={dialogState.icon}
        handleConfirm={dialogState.action}
        disclosure={disclosure}
      />
      {getActions()}
    </>
  );
};

export default InformationActions;
