/* eslint-disable max-len */
import { GetReturnsFeatureSettingsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/r1_network/response/GetReturnsFeatureSettingsResponse';
import { QueryResponseType } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import {
  sendGetReturnsFeatureSettingsRequest,
  sendSaveReturnsFeatureSettingsRequest,
} from 'domains/r1Network/R1Network.api';
import * as actions from 'domains/r1Network/R1Network.store';
import { getReturnsFeatureSettings, setReturnsFeatureSettings } from 'domains/r1Network/R1Network.store';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { notifyTop } from 'UI';
import { AppTranslation, sagaGuard, select } from 'utils';

export function* getReturnsFeatureSettingsRequestSaga(): SagaIterator {
  yield put(sendGetReturnsFeatureSettingsRequest());
}

export function* getReturnsFeatureSettingsResponseSaga(
  action: WsResponse<GetReturnsFeatureSettingsResponse>,
): SagaIterator {
  const { isEnabled, email } = action.payload;
  yield put(setReturnsFeatureSettings({ isEnabled, email }));
}

export function* saveReturnsFeatureSettingsRequestSaga(): SagaIterator {
  const { isEnabled, email } = yield* select(getReturnsFeatureSettings);
  if (email) {
    yield put(sendSaveReturnsFeatureSettingsRequest({ isEnabled, email }));
  }
}

// eslint-disable-next-line require-yield
export function* saveReturnsFeatureSettingsResponseSaga(action: WsResponse): SagaIterator {
  switch (action.queryResponseType) {
    case QueryResponseType.COMMAND_RESPONSE_ERROR:
    case QueryResponseType.COMMAND_RESPONSE_INVALID: {
      notifyTop(
        'error',
        AppTranslation.t('backoffice.r1_network.returns_feature.command.response.error.title', 'My Network'),
        AppTranslation.t(
          'backoffice.r1_network.returns_feature.command.response.error.message',
          'Unable to persist changes',
        ),
      );
      break;
    }
    case QueryResponseType.COMMAND_RESPONSE_NO_CHANGE: {
      break;
    }
    case QueryResponseType.COMMAND_RESPONSE_SUCCESS: {
      notifyTop(
        'success',
        AppTranslation.t('backoffice.r1_network.returns_feature.command.response.success.title', 'My Network'),
        AppTranslation.t(
          'backoffice.r1_network.returns_feature.command.response.success.message',
          'Changes has been saved',
        ),
      );
      break;
    }
  }
}

export function* R1NetworkSagas(): SagaIterator {
  yield takeEvery(actions.getReturnsFeatureSettingsRequestSaga.type, sagaGuard(getReturnsFeatureSettingsRequestSaga));
  yield takeEvery(actions.getReturnsFeatureSettingsResponseSaga.type, sagaGuard(getReturnsFeatureSettingsResponseSaga));
  yield takeEvery(actions.saveReturnsFeatureSettingsRequestSaga.type, sagaGuard(saveReturnsFeatureSettingsRequestSaga));
  yield takeEvery(
    actions.saveReturnsFeatureSettingsResponseSaga.type,
    sagaGuard(saveReturnsFeatureSettingsResponseSaga),
  );
}
