import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { SearchByHistory } from 'pages/HomePage/search/SearchByHistory';
import { Flex, Select, SelectOptionSingle } from 'UI';
import { useSmall } from 'utils';
import { Country } from 'utils/i18n/Country';
import { SearchByModel } from './SearchByModel';
import { SearchByVINVRN } from './SearchByVINVRN';

const SContainerBase = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  padding: 0 15px;
`;

const SContainerBaseFull = styled.div`
  width: 100%;
  min-width: 720px;
  display: flex;
  justify-content: center;
  padding: 0 15px;
`;

export type SearchVariantType = 'VINVRN' | 'Model' | 'History';

export interface SearchVariantOption extends SelectOptionSingle {
  value: SearchVariantType;
}

const getSearchVariantOptions = (t: TFunction): SearchVariantOption[] => [
  { title: `${t('catalog.parts.search.by_vin_or_vrn', 'Search by VIN or VRN')}`, value: 'VINVRN' },
  { title: `${t('catalog.parts.search.by_model', 'Search by vehicle model')}`, value: 'Model' },
];

const getSearchVariantOptionsModel = (t: TFunction): SearchVariantOption[] => [
  { title: `${t('catalog.parts.search.by_vin_or_vrn', 'Search by VIN or VRN')}`, value: 'VINVRN' },
  { title: `${t('catalog.parts.search_by_model', 'Vehicle Model')}`, value: 'Model' },
];

export type SearchQueryType = {
  country: Country;
  query: string;
  pendingQuery: string | undefined;
};

export function SelectSearchVariant({
  bordered,
  searchVariant,
  setSearchVariant,
  searchVariantOptions,
}: {
  bordered?: boolean;
  searchVariant: SearchVariantType;
  setSearchVariant: (x: SearchVariantType) => void;
  searchVariantOptions: SearchVariantOption[];
}) {
  const { t } = useTranslation();
  const small = useSmall();

  return (
    <Flex size={'0 10'} maxWidth={small ? 110 : 215} data-cy={'dropdown-vin-vrn'}>
      {searchVariant === 'Model' ? (
        <Select
          onChange={(newValue) => setSearchVariant(String(newValue) as SearchVariantType)}
          value={searchVariant}
          options={getSearchVariantOptionsModel(t)}
          bordered={bordered}
          size={'large'}
          alignLeft
        />
      ) : (
        <Select
          onChange={(newValue) => setSearchVariant(String(newValue) as SearchVariantType)}
          value={searchVariant}
          options={searchVariantOptions}
          bordered={bordered}
          size={'large'}
          alignLeft
        />
      )}
    </Flex>
  );
}

const SearchBarBase = ({
  visible,
  setIsChangeVehiclePopupVisible,
  onFound,
}: {
  visible?: boolean;
  setIsChangeVehiclePopupVisible?: (a: boolean) => void;
  onFound?: (vehicleDetail: VehicleDetail) => void;
}) => {
  const { t } = useTranslation();
  const searchVariantOptions = getSearchVariantOptions(t);
  const [searchVariant, setSearchVariant] = useState<SearchVariantType>(searchVariantOptions[0].value);
  return (
    <>
      <Flex size={0} />
      <Flex justify={'center'}>
        {searchVariant === 'VINVRN' && (
          <SearchByVINVRN
            visible={visible}
            searchVariant={searchVariant}
            setSearchVariant={setSearchVariant}
            searchVariantOptions={searchVariantOptions}
            onFound={onFound}
          />
        )}
        {searchVariant === 'Model' && (
          <SearchByModel
            setIsChangeVehiclePopupVisible={setIsChangeVehiclePopupVisible}
            searchVariant={searchVariant}
            setSearchVariant={setSearchVariant}
            searchVariantOptions={searchVariantOptions}
            onFound={onFound}
          />
        )}
        {searchVariant === 'History' && (
          <SearchByHistory
            searchVariant={searchVariant}
            setSearchVariant={setSearchVariant}
            searchVariantOptions={searchVariantOptions}
          />
        )}
      </Flex>
      <Flex size={0} />
    </>
  );
};

const SearchBar = () => (
  <SContainerBase>
    <SearchBarBase />
  </SContainerBase>
);

export const SearchBarFullBase = ({
  visible,
  setIsChangeVehiclePopupVisible,
  onFound,
}: {
  visible?: boolean;
  setIsChangeVehiclePopupVisible: (a: boolean) => void;
  onFound?: (vehicleDetail: VehicleDetail) => void;
}) => (
  <SContainerBaseFull>
    <SearchBarBase
      visible={visible}
      setIsChangeVehiclePopupVisible={setIsChangeVehiclePopupVisible}
      onFound={onFound}
    />
  </SContainerBaseFull>
);

export default SearchBar;
