import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const MenuItem = styled(Flex)<{ active: boolean }>`
  min-height: 70px;
  max-height: 70px;
  padding-left: 45px;
  border-bottom: solid 1px ${theme.color.grey75};
  background-color: ${({ active }) => (active ? theme.color.grey95 : theme.color.white)};

  :hover {
    cursor: pointer;
  }
`;
