import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderReferenceItem, VehicleDetail } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { BlockBody, BlockFlex, BlockHeader } from 'components/MotrioWarranty/step/TypeOfGuaranteeStep/Block.styled';
import { Flex, MarginBox, Text } from 'UI';

interface LineProps {
  label: ReactNode;
  value: ReactNode;
}

function Line(props: LineProps) {
  return (
    <Flex size={0}>
      <Text type={'light_14_bold_black_85'}>{props.label}</Text>
      <MarginBox ml={5} />
      <Text type={'light_14_black_85'}>{props.value}</Text>
    </Flex>
  );
}

interface ReferenceBlockProps {
  reference: OrderReferenceItem;
}

export function ReferenceBlock(props: ReferenceBlockProps) {
  const { t } = useTranslation();
  const ref = props.reference;

  return (
    <BlockFlex direction={'column'} maxWidth={'50%'}>
      <BlockHeader size={0}>
        <Text type={'h5_bold'}>{t('motrio.warranty.part', 'Part')}</Text>
      </BlockHeader>
      <BlockBody>
        <Flex direction={'column'}>
          <Line label={t('motrio.warranty.part.ref', 'Ref:')} value={ref.referenceNumber} />
          <Line label={t('motrio.warranty.part.designation', 'Designation:')} value={ref.name} />
        </Flex>
        <Flex direction={'column'}>
          <Line label={t('motrio.warranty.part.brand', 'Brand:')} value={ref.brand} />
        </Flex>
      </BlockBody>
    </BlockFlex>
  );
}

interface VehicleBlockProps {
  vehicleDetail?: VehicleDetail;
}
export function VehicleBlock(props: VehicleBlockProps) {
  const { t } = useTranslation();
  const vehicle = props.vehicleDetail;

  if (vehicle === undefined) return <></>;

  return (
    <BlockFlex direction={'column'} maxWidth={'50%'}>
      <BlockHeader size={0}>
        <Text type={'h5_bold'}>{t('motrio.warranty.vehicle', 'Vehicle')}</Text>
      </BlockHeader>
      <BlockBody>
        <Flex direction={'column'}>
          <Line label={t('motrio.warranty.vehicle.brand', 'Brand:')} value={vehicle.vehicleBrand} />
          <Line label={t('motrio.warranty.vehicle.model', 'Model:')} value={vehicle.model} />
          {vehicle.iamVehicle?.version !== undefined && (
            <Line label={t('motrio.warranty.vehicle.version', 'Version:')} value={vehicle.iamVehicle.version} />
          )}
        </Flex>
        <Flex direction={'column'}>
          <Line label={t('motrio.warranty.vehicle.vin', 'VIN:')} value={vehicle.vin} />
          <Line label={t('motrio.warranty.vehicle.vrn', 'VRN:')} value={vehicle.vrn} />
        </Flex>
      </BlockBody>
    </BlockFlex>
  );
}
