import styled from 'styled-components';
import { useBreakpointSelectorFull } from 'utils';

export const Container = styled.div`
  text-align: left;
  margin: 10px 35px 10px 35px;
  min-height: calc(
    100vh - 20px - ${() => useBreakpointSelectorFull()({ xs: 100, sm: 50, md: 50, lg: 50, xl: 50, xxl: 100 }) + 'px'} -
      48px - 90px
  );
`;
