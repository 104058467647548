import styled from 'styled-components';
import { Flex, Text } from 'UI';

export const SContainer = styled(Flex)`
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.color.shadow_7};
`;

export const SText = styled(Text)`
  :hover {
    color: ${({ theme }) => theme.color.clear_blue};
  }
`;
