import React from 'react';
import { Table } from 'antd';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataDE = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>INFORMATIONSHINWEIS ÜBER SCHUTZ PERSONENBEZOGENER DATEN</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Die Renault-Gruppe, zu der Renault SAS gehört, überwacht ständig die Einhaltung der Vorschriften zum Schutz
            personenbezogener Daten (im Folgenden &quot;personenbezogene Daten&quot;), wie sie in ihrer Richtlinie zum
            Schutz personenbezogener Daten der Gruppe beschrieben sind, die unter{' '}
            <Link
              to={'https://www.renaultgroup.com/en/our-commitments/the-groupe-renault-and-your-personal-data/'}
              external
              newTab
            >
              https://www.renaultgroup.com/en/our-commitments/the-groupe-renault-and-your-personal-data/
            </Link>{' '}
            verfügbar ist. Die Groupe Renault ist der Ansicht, dass der Schutz personenbezogener Daten für den Aufbau
            einer vertrauensvollen Beziehung zu ihren Kunden unerlässlich ist.
          </Paragraph>
          <Paragraph>
            Renault SAS stellt die RPARTSTORE-Website{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>{' '}
            (die &quot;Plattform&quot;) zur Verfügung , um i) die Konsultation der technischen After-Sales-Dokumentation
            zu erleichtern, Ersatzteile und Zubehör für den Verkauf von Fahrzeugen (die &quot;Produkte&quot;) zur
            Verfügung zu stellen, ii) dem Nutzer die Möglichkeit zu geben, technische Vorfälle der Plattform zu melden,
            und iii) einen direkten kommerziellen Kontakt zwischen Verkäufern und Käufern, Fachleuten aus dem
            Renault-Netzwerk, herzustellen. Im Zusammenhang mit dem Surfen und der Interaktion mit der Plattform erhebt
            Renault als Datenverantwortlicher personenbezogene Daten direkt von den Nutzern der Plattform
            (&quot;Nutzer&quot;) sowie gegebenenfalls indirekt personenbezogene Daten in Bezug auf die Endkunden der
            Nutzer (&quot;Dritte betroffene Person&quot;). In Übereinstimmung mit den Allgemeinen Nutzungsbedingungen
            der Plattform (im Folgenden als &quot;Allgemeine Geschäftsbedingungen&quot; bezeichnet) beabsichtigt Renault
            SAS, die größtmögliche Transparenz in Bezug auf die Verarbeitung personenbezogener Daten über die Plattform
            zu gewährleisten. Der Zweck dieser Erklärung besteht darin, Nutzer, die Vertreter einer Tochtergesellschaft
            der Renault-Gruppe sind oder Teil des Renault-Netzwerks sind (&quot;Sie&quot;), ausführlich über die
            Bedingungen zu informieren, unter denen Renault personenbezogene Daten und gegebenenfalls die
            personenbezogenen Daten betroffener Dritter verarbeitet, sowie über die Rechte und Möglichkeiten, die Sie
            haben, um Ihre personenbezogenen Daten zu kontrollieren und Ihre Privatsphäre zu schützen.
          </Paragraph>
          <Paragraph>
            Großgeschriebene Begriffe, die in dieser Datenschutzerklärung (im Folgenden die &quot;Erklärung&quot;)
            verwendet, aber nicht definiert werden, haben die Bedeutung, die ihnen in den Allgemeinen
            Geschäftsbedingungen zugewiesen wird. Für die Zwecke dieses Dokuments beziehen sich die Begriffe
            &quot;wir&quot;, &quot;uns&quot;, &quot;unser&quot; oder &quot;Renault&quot; auf Renault SAS. Für die Zwecke
            dieser Erklärung werden Nutzer und Dritte zusammen als &quot;betroffene Personen&quot; bezeichnet.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'1. WER VERARBEITET IHRE PERSONENBEZOGENEN DATEN?'} />
          <Paragraph>
            Renault SAS, die Muttergesellschaft der Renault-Gruppe, mit Sitz in Frankreich in 122-122 bis avenue du
            Général Leclerc 92100 Boulogne-Billancourt, verarbeitet Ihre personenbezogenen Daten zu den unten
            aufgeführten Zwecken (siehe 3. WARUM WERDEN IHRE PERSONENBEZOGENEN DATEN VERWENDET?) als Verantwortlicher
            für die Datenverarbeitung.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. WELCHE PERSONENBEZOGENEN DATEN VERARBEITEN WIR?'} />
          <Paragraph>
            &quot;Personenbezogene Daten&quot; sind alle Informationen, die verwendet werden können, um Sie entweder
            direkt (z.B. Ihren Vor- und/oder Nachnamen) oder indirekt (z. B. die Kennung, die aus einer Folge
            alphanumerischer Zeichen besteht, die Ihre IPN darstellen, wenn Sie ein Benutzer sind, der eine
            Renault-Tochtergesellschaft vertritt oder Teil des Renault-Netzwerks ist).
          </Paragraph>
          <Paragraph>
            Im Allgemeinen verpflichten wir uns, nur die personenbezogenen Daten zu erheben, die für jeden der Zwecke,
            für die wir Ihre personenbezogenen Daten verarbeiten, erforderlich sind.
          </Paragraph>
          <Paragraph>
            Die personenbezogenen Daten, die wir erheben, hängen von unserer Interaktion mit Ihnen ab und können
            Informationen enthalten über:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>Ihre Verbindung / Authentifizierung auf der Plattform;</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Identifikationsdaten und Ihre beruflichen Kontaktdaten (berufliche E-Mail-Adresse, Name, Vorname);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Ihre Nutzung der Plattform und des Geräts, mit dem Sie die Plattform besuchen, einschließlich Protokolle
                (in Bezug auf die Anzahl der Besuche, die besuchte Seite, den verwendeten Webbrowser), die IP-Adresse,
                die Zeitzone und die Cookies (weitere Informationen finden Sie auf der Seite &quot;Cookies&quot; der
                Plattform);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Schätzungen, die Sie über die Plattform in Bezug auf Diagnose, Reparatur oder Recycling
                (&quot;technische Operationen&quot;) am Fahrzeug der dritten betroffenen Person vorgenommen haben,
                einschließlich Schätzungen, die nicht zur Einreichung eines Serviceauftrags führen;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Ihre personenbezogenen Daten und gegebenenfalls die personenbezogenen Daten der dritten betroffenen
                Personen im Zusammenhang mit der Meldung eines technischen Vorfalls in Bezug auf die Plattform;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Ihre personenbezogenen Daten und gegebenenfalls die personenbezogenen Daten der dritten betroffenen
                Personen im Rahmen eines Serviceauftrags, der es Ihnen ermöglicht, eine Bestellung von Produkten beim
                Renault-Händlernetz R1 aufzugeben (bei den erhobenen Daten handelt es sich insbesondere um die
                beruflichen Daten des Geschäftskontakts, der die Lieferung erhalten soll);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                personenbezogene Daten im Zusammenhang mit der Geschäftsbeziehung (z. B. Ihre eventuellen Anfragen nach
                Unterstützung in Bezug auf das Funktionieren der Plattform, Beschwerden und/oder die Ausübung Ihrer
                Rechte an personenbezogenen Daten).
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
          <Paragraph>
            Daher werden die Daten, die erforderlich sind, um auf Ihre Anfrage zu antworten oder die Anforderungen eines
            Vertrags oder einer gesetzlichen Verpflichtung zu erfüllen, in den Erfassungsformularen angegeben. Wenn Sie
            die Pflichtangaben nicht machen möchten, kann es sein, dass wir Ihre Anfrage nicht bearbeiten oder Ihnen die
            entsprechenden Dienstleistungen nicht erbringen können.
          </Paragraph>
          <Paragraph>
            In Übereinstimmung mit unseren Allgemeinen Geschäftsbedingungen ist die Plattform nicht für Minderjährige
            bestimmt und wir sammeln daher nicht wissentlich Daten über sie. Die Produkte und Dienstleistungen, die wir
            anbieten, richten sich in erster Linie an Erwachsene. Daher führen wir keine spezifische Verarbeitung von
            Minderjährigen durch.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. WOFÜR WERDEN IHRE PERSONENBEZOGENEN DATEN VERWENDET?'} />
          <Paragraph>
            Wir geben Ihnen Erläuterungen zum Kontext der Erhebung Ihrer personenbezogenen Daten, zu den Zwecken, für
            die wir sie verarbeiten, und zur Dauer, für die wir sie gespeichert haben.
          </Paragraph>
          <MarginBox mt={15} />
          <Section>
            <SectionHeader title={'3.1 KONTEXT DER ERHEBUNG'} />
            <Paragraph>Wir erheben Ihre personenbezogenen Daten insbesondere, wenn:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Sie interagieren direkt mit Renault über die Plattform (Formular ausfüllen, Kontakt aufnehmen, ...)
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Sie besuchen die Plattform, die Cookies oder andere Tracer verwenden kann,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Sie fordern technische Unterstützung in Bezug auf das Funktionieren der Plattform an, erfassen einen
                  technischen Vorgang oder bearbeiten und senden gegebenenfalls einen Serviceauftrag.
                </Text>
              </Li>
            </Ul>
          </Section>
          <Section>
            <SectionHeader title={'3.2. ZIELE DER VERARBEITUNG PERSONENBEZOGENER DATEN'} />
            <Paragraph>
              Im Rahmen unserer Geschäftsbeziehung und in Übereinstimmung mit dem Kontext, in dem Ihre personenbezogenen
              Daten erhoben werden, können wir Ihre Daten zu folgenden Zwecken verwenden:
            </Paragraph>
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>A – Verwalten unserer anfänglichen Interaktionen</Text>
              </Li>
              <Li>
                <Text type={'text'}>B – Bereitstellung des Dienstes der Plattform</Text>
              </Li>
              <Li>
                <Text type={'text'}>C – Durchführung von Studien und Analysen</Text>
              </Li>
              <Li>
                <Text type={'text'}>D – Verwaltung unserer Marketingaktivitäten</Text>
              </Li>
            </Ul>
            <MarginBox mt={10} />
            <Section>
              <SectionHeader title={'3.2.A. VERWALTUNG UNSERER ANFÄNGLICHEN INTERAKTIONEN'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Ziele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-a',
                    width: '50%',
                  },
                  {
                    title: 'Rechtsgrundlage',
                    dataIndex: 'legal',
                    key: 'legal-3-2-a',
                    width: '50%',
                  },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: 'Messung der Besuche auf der Plattform und Verwaltung ihrer Wartung und Sicherheit',
                    legal: (
                      <Text type={'text'}>
                        Diese Verarbeitung beruht auf Ihrer Zustimmung zu den &quot;nicht wesentlichen&quot; Cookies,
                        die auf Ihrem Endgerät abgelegt/gelesen werden (siehe Seite &quot;Cookies&quot; der Plattform).
                        <br />
                        <br />
                        und auf unser berechtigtes Interesse (Ihnen eine sichere Plattform zur Verfügung zu stellen)
                      </Text>
                    ),
                  },
                  {
                    id: '1',
                    objectives: 'Die Verwaltung Ihrer Authentifizierung auf der Plattform',
                    legal: (
                      <Text type={'text'}>
                        Diese Verarbeitung ist durch die Ausführung des Vertrages, den Sie mit uns geschlossen haben
                        (d.h. der Allgemeinen Geschäftsbedingungen), gerechtfertigt
                      </Text>
                    ),
                  },
                  {
                    id: '2',
                    objectives: 'Um Ihnen die Verbindung zur Plattform zu ermöglichen zur Plattform',
                    legal: (
                      <Text type={'text'}>
                        Diese Verarbeitung ist durch die Ausführung des Vertrages, den Sie mit uns geschlossen haben
                        (d.h. der Allgemeinen Geschäftsbedingungen), gerechtfertigt
                      </Text>
                    ),
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.B. VERWALTUNG UNSERER GESCHÄFTSBEZIEHUNG'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Ziele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-b',
                    width: '50%',
                  },
                  { title: 'Rechtsgrundlage', dataIndex: 'legal', key: 'legal-3-2-b', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `Um Ihnen die Durchführung von technischen Arbeiten, insbesondere im Zusammenhang mit der Reparatur der Fahrzeuge, zu ermöglichen`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung ist durch die Ausführung des von Ihnen mit uns geschlossenen Vertrags (d.h. der Allgemeinen Geschäftsbedingungen) gerechtfertigt`,
                  },
                  {
                    id: '1',
                    // eslint-disable-next-line max-len
                    objectives: `Ermöglichen Sie es Ihnen, die Technische After-Sales-Dokumentation über die Produkte von RENAULT (wie z.B.: Ersatzteile, Arbeitszeit, Reparaturmethoden und Diagnostik, Tutorials, ohne Anspruch auf Vollständigkeit) und/oder Marken anderer Automobilhersteller`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung ist durch die Ausführung des von Ihnen mit uns geschlossenen Vertrags (d.h. der Allgemeinen Geschäftsbedingungen) gerechtfertigt`,
                  },
                  {
                    id: '2',
                    // eslint-disable-next-line max-len
                    objectives: `Ermöglicht es Ihnen, eine Schätzung vorzunehmen des Umfangs des technischen Betriebs und ggf. zur Erteilung eines Serviceauftrags über die Plattform`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung ist durch die Ausführung des von Ihnen mit uns geschlossenen Vertrags (d.h. der Allgemeinen Geschäftsbedingungen) gerechtfertigt`,
                  },
                  {
                    id: '3',
                    objectives: `Verwaltung unserer Geschäftsbeziehung`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung ist durch die Ausführung des von Ihnen mit uns geschlossenen Vertrags (d.h. der Allgemeinen Geschäftsbedingungen) gerechtfertigt`,
                  },
                  {
                    id: '4',
                    objectives: `Verwalten Sie Ihre Supportanfragen Supportanfragen bezüglich des Funktionierens der Plattform`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung ist durch die Ausführung des von Ihnen mit uns geschlossenen Vertrags (d.h. der Allgemeinen Geschäftsbedingungen) gerechtfertigt`,
                  },
                  {
                    id: '5',
                    // eslint-disable-next-line max-len
                    objectives: `Beantwortung Ihrer möglichen Anfragen zur Ausübung von Rechten in Bezug auf Ihre personenbezogenen Daten (siehe Abschnitt 5 unten "Ihre Rechte")`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung beruht auf unseren gesetzlichen Verpflichtungen und kann die Überprüfung Ihrer Identität beinhalten`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.C. DURCHFÜHRUNG VON STUDIEN UND ANALYSEN'} />
              <Paragraph>
                Studien und Analysen werden durchgeführt, um unsere Leistung zu messen, die Qualität unserer Produkte
                und Dienstleistungen sowie die Kundenzufriedenheit zu bewerten und kontinuierlich zu verbessern.
              </Paragraph>
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Ziele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-c',
                    width: '50%',
                  },
                  { title: 'Rechtsgrundlage', dataIndex: 'legal', key: 'legal-3-2-c', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `Verwaltung unserer Zufriedenheitsumfragen, einschließlich der Antworten (d. h. Ihrer Meinungen), zur Verbesserung der Zufriedenheit der Nutzer mit der Plattform`,
                    legal: `Diese Verarbeitung beruht auf unseren berechtigten Interessen (um die Leistung unserer Tätigkeit zu messen)`,
                  },
                  {
                    id: '1',
                    objectives: `Überwachen Sie unsere Aktivitäten (Berichterstattung)`,
                    // eslint-disable-next-line max-len
                    legal: `Diese Verarbeitung beruht auf unserem berechtigten Interesse (Verbesserung unserer Produkte und Dienstleistungen)`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.D. VERWALTUNG UNSERER MARKETINGAKTIVITÄTEN'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Ziele',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-d',
                    width: '50%',
                  },
                  { title: 'Rechtsgrundlage', dataIndex: 'legal', key: 'legal-3-2-d', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Das Versenden von Werbekampagnen an das Renault-Netzwerk (B2B)`,
                    legal: `Diese Verarbeitung beruht auf unserem berechtigten Interesse (Bekanntmachung unserer Produkte)`,
                  },
                ]}
              />
              <MarginBox mt={15} />
              <Paragraph>
                Wenn wir Ihre personenbezogenen Daten für andere als die in der obigen Tabelle aufgeführten Zwecke
                verarbeiten müssen, werden Sie im Voraus informiert und wir werden alle zusätzlichen Maßnahmen
                ergreifen, die erforderlich sind, um die Rechtskonformität aller Verarbeitungen sicherzustellen.
              </Paragraph>
            </Section>
          </Section>
          <Section>
            <SectionHeader title={'3.3 DIE DAUER, FÜR DIE WIR IHRE DATEN AUFBEWAHREN'} />
            <Paragraph>
              In Übereinstimmung mit den Vorschriften verpflichten wir uns, Ihre personenbezogenen Daten nur so lange
              aufzubewahren, wie es zur Erreichung des verfolgten Ziels, zur Erfüllung Ihrer Bedürfnisse oder zur
              Erfüllung unserer gesetzlichen Verpflichtungen erforderlich ist.
            </Paragraph>
            <Paragraph>Bei der Festlegung dieses Zeitpunkts berücksichtigen wir insbesondere Folgendes:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Die Zeit, die für die Bearbeitung Ihrer Anfrage oder Beschwerde erforderlich ist,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Ihr Interesse an unserer Plattform,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Die Notwendigkeit, eine bestimmte Historie Ihrer Interaktionen mit der Plattform zu führen, wobei
                  diese Dauer insbesondere davon abhängt, ob Sie einen Serviceauftrag unterzeichnet haben oder nicht;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Unsere gesetzlichen oder regulatorischen Verpflichtungen</Text>
              </Li>
            </Ul>

            <Paragraph>Besonders:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Personenbezogene Daten, die durch Cookies im Zusammenhang mit der Analyse der Leistung der Inhalte,
                  die Sie auf unseren Websites aufrufen, und der Anzeige gezielter Online-Werbung erhoben werden. Nicht
                  wesentliche&quot; Tracker/Cookies, wie z. B. Werbe-Cookies, werden maximal 13 Monate ab dem Datum
                  Ihrer Einwilligung aufbewahrt. Personenbezogene Daten, die über Cookies erhoben werden, werden 25
                  Monate lang verarbeitet und danach anonymisiert. Die Aufbewahrungsfrist für Ihre Auswahl (Zustimmung
                  oder Ablehnung sogenannter &quot;nicht wesentlicher&quot; Cookies vorbehaltlich der vorherigen
                  Einholung der Einwilligung) beträgt 6 Monate ab dem Zeitpunkt der Einholung Ihrer Einwilligung.
                  Weitere Informationen finden Sie auf unserer Seite &quot;Cookies&quot;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Die personenbezogenen Daten, die im Rahmen der Wartung und Sicherheit der Plattform und unserer
                  Datenbanken verarbeitet werden, werden 6 Monate lang aufbewahrt (z. B.: Protokolle, ...);
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Die personenbezogenen Daten, die im Rahmen der Historie Ihrer über die Plattform durchgeführten
                  technischen Vorgänge und/oder des von Ihnen gesendeten Serviceauftrags verarbeitet werden, werden 6
                  Monate lang aufbewahrt. Es wird darauf hingewiesen, dass die vom Nutzer über die Plattform
                  vorgenommenen Schätzungen 3 Monate lang aufbewahrt werden.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Handelt es sich bei dem Nutzer um einen Mitarbeiter von Renault oder um einen Mitarbeiter eines
                  professionellen Mitglieds des Renault-Netzwerks, werden seine über die Plattform erhobenen Daten für
                  die Dauer seines Arbeitsvertrags mit Renault oder gegebenenfalls der vom Arbeitgeber des Nutzers
                  erteilten Genehmigung für den Zugriff auf die Plattform aufbewahrt.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Personenbezogene Daten im Zusammenhang mit Ihren Anträgen auf Ausübung Ihrer Rechte werden je nach
                  ausgeübtem Recht maximal 3 Jahre ab Abschluss Ihrer Anfrage aufbewahrt. Wenn die Erhebung eines
                  Identitätsnachweises erforderlich war, wird dieser gelöscht, sobald die Verifizierung abgeschlossen
                  ist.
                </Text>
              </Li>
            </Ul>
            <Paragraph>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, werden sie aus unseren Systemen und
              Aufzeichnungen gelöscht oder anonymisiert, so dass wir Sie nicht mehr identifizieren können.
            </Paragraph>
            <Paragraph>
              Es kann jedoch erforderlich sein, einige Ihrer personenbezogenen Daten zu archivieren, um auf ein
              Gerichtsverfahren reagieren zu können, und zwar während der in den geltenden Rechtsvorschriften
              vorgesehenen Verjährungsfrist.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'4. WER HAT ZUGRIFF AUF IHRE PERSONENBEZOGENEN DATEN?'} />
          <Paragraph>
            Um die oben beschriebenen Zwecke zu erreichen, und nur in dem Umfang, der für die Verfolgung dieser Zwecke
            erforderlich ist:
          </Paragraph>
          <Ul>
            <Li>
              Wir stellen sicher, dass nur diejenigen Personen berechtigt sind, Ihre personenbezogenen Daten gemäß ihren
              Funktionen und Pflichten ordnungsgemäß zu verarbeiten.
            </Li>
            <Li>
              Wir können einige der personenbezogenen Daten an folgende Empfänger übermitteln:
              <Ul type={'decimal'}>
                <MarginBox mt={10} />
                <Li>
                  an die Mitglieder des Renault-Netzwerks (d. h. die Mitglieder des R1-Netzwerks, bei denen der Benutzer
                  berechtigt ist, eine Bestellung von Produkten aufzugeben), vorbehaltlich Ihrer Zustimmung, sofern dies
                  gesetzlich vorgeschrieben ist. Renault und die Mitglieder des Netzwerks haben nämlich eine
                  Vereinbarung geschlossen, in der sie sich beide als unabhängige Datenverantwortliche verpflichten,
                  ihren Verpflichtungen gemäß den Gesetzen und Vorschriften zum Schutz personenbezogener Daten
                  nachzukommen, darunter unter anderem die folgenden Grundsätze:
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>personenbezogene Daten rechtmäßig und nach Treu und Glauben zu verarbeiten,</Li>
                    <Li>die Richtigkeit und Qualität der Daten zu gewährleisten,</Li>
                    <Li>
                      alle geeigneten technischen und organisatorischen Maßnahmen zu ergreifen, um ein dem Risiko
                      angemessenes Sicherheitsniveau zu gewährleisten und im Falle einer Datenschutzverletzung zu
                      kooperieren,
                    </Li>
                    <Li>nur vertrauenswürdige Unterauftragsverarbeiter einzustellen,</Li>
                    <Li>geeignete Sicherheitsvorkehrungen für die Datenübertragung zu treffen,</Li>
                    <Li>
                      die Rechte der betroffenen Personen zu respektieren, einschließlich des Rechts, über die
                      Datenverarbeitung informiert zu werden.
                    </Li>
                  </Ul>
                  <MarginBox mt={10} />
                  In Bezug auf den letzten Grundsatz werden die Mitarbeiter des Renault-Netzwerks darüber informiert,
                  dass diese Mitteilung eine Ergänzung zu der Informationsmitteilung über die Datenverarbeitung
                  darstellt, die Ihnen möglicherweise von Ihrem Arbeitgeber als beruflicher Teil des Renault-Netzwerks
                  zur Verfügung gestellt wurde.
                </Li>
                <MarginBox mt={10} />
                <Li>
                  Auftragsverarbeiter personenbezogener Daten im Sinne der geltenden Vorschriften über personenbezogene
                  Daten, um die nachstehend aufgeführten Aufgaben zu erfüllen. Wir kümmern uns darum, diese Beziehungen
                  zu sichern (Verträge, Audits, Garantien und Sicherheitsprüfungen...) und ihnen Anweisungen zu den
                  Aufgaben zu geben, die wir ihnen anvertrauen, insbesondere:
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>Hosting,</Li>
                    <Li>
                      Verbriefung und Pflege der Plattform und der über die Plattform erhobenen und/oder verarbeiteten
                      Datenbanken,
                    </Li>
                    <Li>Kommerzielle Prospektion für professionelle Mitglieder des Renault-Netzwerks (B2B),</Li>
                    <Li>
                      Verwaltung der Kundenbeziehungen und des technischen Supports in Bezug auf das Funktionieren der
                      Plattform,
                    </Li>
                    <Li>Publikumsmessungen auf der Plattform,</Li>
                    <Li>Messung der Zufriedenheit des Nutzers mit der Plattform.</Li>
                    <MarginBox mt={7} />
                  </Ul>
                </Li>
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            Wir können verpflichtet sein, Ihre personenbezogenen Daten an Dritte weiterzugeben, um einer gesetzlichen
            Verpflichtung oder einer behördlichen oder gerichtlichen Entscheidung nachzukommen.
          </Paragraph>
          <Paragraph>
            Renault kann Ihre Daten an Dritte weitergeben, wenn Pflichten und Rechte im Zusammenhang mit dem
            Vertragsverhältnis zwischen Ihnen und Renault auf diese Dritten übertragen werden, im Falle der Übertragung
            eines Geschäftsbereichs, einer Fusion durch Gründung eines neuen Unternehmens, einer Spaltung oder eines
            Kontrollwechsels, der Renault betrifft. Im Vorfeld eines solchen Ereignisses wird Renault Sie gesondert über
            die Informationen im Zusammenhang mit der Weitergabe Ihrer Daten informieren und Ihre Einwilligung einholen,
            sofern dies gesetzlich vorgeschrieben ist.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'5. WELCHE RECHTE HABEN SIE?'} />
          <Section>
            <SectionHeader title={'5.1. IHRE RECHTE'} />
            <Paragraph>Gemäß den Vorschriften zum Schutz personenbezogener Daten haben Sie mehrere Rechte:</Paragraph>
            <Paragraph>
              Ein Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, sofern Sie Gründe haben, die
              mit Ihrer besonderen Situation zusammenhängen, und das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen, in einigen Fällen wie in den Vorschriften vorgesehen.
            </Paragraph>
            <Paragraph>
              Ein Widerspruchsrecht gegen jede Mitteilung von uns: Sie können jederzeit verlangen, dass Sie unsere
              Mitteilungen nicht mehr von uns erhalten.
            </Paragraph>
            <Paragraph>
              Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen, und zwar für die Zwecke, für die wir Ihre
              Einwilligung eingeholt haben (siehe oben 3.2. ZWECKE DER VERARBEITUNG PERSONENBEZOGENER DATEN).
            </Paragraph>
            <Paragraph>
              Auskunftsrecht: Sie haben das Recht, klare, transparente und verständliche Informationen darüber zu
              erhalten, wie wir Ihre personenbezogenen Daten verwenden und welche Rechte Sie haben. Diese Erklärung ist
              ein Beispiel dafür.
            </Paragraph>
            <Paragraph>
              Ein Recht auf Zugang zu Ihren personenbezogenen Daten: Sie haben das Recht, Informationen über die
              Verarbeitung Ihrer personenbezogenen Daten (einschließlich der Daten, die verwendet werden, für welche
              Zwecke usw.) und eine Kopie davon zu erhalten.
            </Paragraph>
            <Paragraph>
              Recht auf Berichtigung: Sie haben das Recht, Ihre Daten zu berichtigen, wenn sie unrichtig oder
              unvollständig sind, obwohl wir uns bemühen, sie auf dem neuesten Stand zu halten, damit wir unserer
              Verpflichtung nachkommen können, über aktuelle Daten über Sie zu verfügen.
            </Paragraph>
            <Paragraph>
              Ein Recht auf Übertragbarkeit Ihrer Daten, d.h. unter bestimmten Voraussetzungen das Recht, die
              personenbezogenen Daten, die Sie uns zur Verfügung gestellt haben, in einem strukturierten, gängigen
              EDV-Format zu erhalten und sie an Dritte zu übermitteln, sofern dies technisch möglich ist.
            </Paragraph>
            <Paragraph>
              Recht auf Löschung (oder Recht auf Vergessenwerden): Sie haben das Recht, Ihre Daten löschen oder löschen
              zu lassen. Dieses Recht kann im Hinblick auf unsere vertraglichen oder gesetzlichen Verpflichtungen
              (Vertrag in Arbeit) (insbesondere Verhinderung von Rechtsansprüchen) eingeschränkt sein.
            </Paragraph>
            <Paragraph>
              Sie haben das Recht, allgemeine oder spezifische Richtlinien für bestimmte Arten der Verarbeitung in Bezug
              auf die Aufbewahrung, Löschung und Übermittlung Ihrer personenbezogenen Daten im Falle Ihres Todes
              festzulegen. Sie können diese Richtlinien jederzeit ändern oder löschen. Sie können uns diese besonderen
              Richtlinien mitteilen, indem Sie an die unten angegebene Adresse schreiben.
            </Paragraph>
            <Paragraph>
              Schließlich haben Sie das Recht, eine Beschwerde bei einer Aufsichtsbehörde für den Schutz
              personenbezogener Daten einzureichen, z. B. in Frankreich, der Commission Nationale Informatique et
              Libertés (CNIL). Wir empfehlen Ihnen, sich vor jeder Beschwerde mit uns in Verbindung zu setzen, damit wir
              versuchen können, Ihr Problem gemeinsam zu lösen.
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'5.2. WIE ÜBE ICH SIE AUS?'} />
            <Paragraph>
              Um eines Ihrer Rechte auszuüben, können Sie Ihre Anfrage jederzeit per Post an die folgende Adresse senden
            </Paragraph>
            <Paragraph>
              Renault SAS, Rechtsabteilung - Datenschutzbeauftragter, 122-122 bis avenue du Général Leclerc 92100
              Boulogne-Billancourt, Frankreich
            </Paragraph>
            <Paragraph>oder</Paragraph>
            <Paragraph>
              eine E-Mail an folgende Adresse:{' '}
              <Link to={'mailto:dataprotection-com@renault.com'} external newTab>
                dataprotection-com@renault.com
              </Link>
              .
            </Paragraph>
            <Paragraph>
              In beiden Fällen bitten wir Sie, um die Bearbeitung Ihrer Anfrage zu erleichtern, anzugeben, dass sich
              Ihre Anfrage auf die Plattform « RPARTSTORE » bezieht.
            </Paragraph>
            <Paragraph>
              Wenn Sie sich über Ihre Identität nicht sicher sind, können Sie aufgefordert werden, einen
              Identitätsnachweis zu erbringen (d. h. die Kopie Ihrer Identität wird ausschließlich zum Zweck der
              Beantwortung Ihrer Anfrage verarbeitet und sofort nach der Überprüfung gelöscht).
            </Paragraph>
            <Paragraph>
              Wir werden uns bemühen, Ihre Anfrage so schnell wie möglich, spätestens jedoch einen Monat nach Eingang
              Ihrer Anfrage, zu beantworten. In Übereinstimmung mit den geltenden Vorschriften behalten wir uns das
              Recht vor, diese Frist auf drei Monate zu verlängern, wenn Ihre Anfrage besonders komplex ist.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'6. WIE WERDEN IHRE PERSONENBEZOGENEN DATEN GESCHÜTZT?'} />
          <Paragraph>
            Ihre personenbezogenen Daten werden auf sicheren Servern gespeichert. Wir setzen angemessene
            Datensicherheits- und Datenschutzmaßnahmen nach dem neuesten Stand der Technik ein und verlangen dies von
            unseren Auftragsverarbeitern und Partnern.
          </Paragraph>
          <Paragraph>
            Wenn die Verarbeitung personenbezogener Daten die Übermittlung von Daten beinhaltet, stellen wir sicher,
            dass diese Übermittlung unter geeigneten Bedingungen erfolgt, die ein angemessenes Schutz-, Sicherheits- und
            Vertraulichkeitsniveau gewährleisten.
          </Paragraph>
          <Paragraph>
            Wann immer möglich, werden Ihre Daten im Europäischen Wirtschaftsraum (EWR) verarbeitet. Da sich jedoch
            einige unserer Dienstleister oder deren Auftragsverarbeiter in Ländern außerhalb des EWR befinden, können
            Ihre personenbezogenen Daten in diesen Ländern verarbeitet werden. Einige dieser Länder haben möglicherweise
            andere Vorschriften zum Schutz personenbezogener Daten als die Europäische Union. In einem solchen Fall (i)
            achten wir besonders darauf, dass diese Übermittlung in Übereinstimmung mit den geltenden Vorschriften
            erfolgt, und (ii) wir treffen Sicherheitsvorkehrungen, die ein angemessenes Schutzniveau Ihrer Privatsphäre
            und Ihrer Grundrechte gewährleisten (insbesondere durch die Verwendung der Standardvertragsklauseln der
            Europäischen Kommission). Auf einfache Anfrage an die in der 5. Im Abschnitt &quot;Welche Rechte haben
            Sie?&quot; können wir Ihnen weitere Informationen zu solchen Übermittlungen (insbesondere die
            Standardvertragsklauseln der Europäischen Kommission) zur Verfügung stellen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. WEITERE INFORMATIONEN, DIE SIE BENÖTIGEN'} />
          <Paragraph>
            Wir können auch automatisch technische Informationen über Sie sammeln, wenn Sie eine unserer Websites
            besuchen oder eine unserer mobilen Anwendungen nutzen, insbesondere Ihre IP-Adresse, Ihre
            Navigationsparameter, mit Hilfe von Tracern (Cookies).
          </Paragraph>
          <Paragraph>
            Für weitere Informationen laden wir Sie ein, die Seite &quot;
            <Link to={ROUTER_COOKIES(Country.DE.key)} newTab>
              Cookies
            </Link>
            &quot; der Plattform zu konsultieren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. Sonstiges ÄNDERUNG DIESES HINWEISES'} />
          <Paragraph>
            Wir behalten uns das Recht vor, diese Erklärung von Zeit zu Zeit zu ändern. Wir werden Sie informieren
            und/oder Ihre Zustimmung einholen, wann immer dies erforderlich oder erforderlich ist. Wir empfehlen Ihnen
            daher, diese Erklärung jedes Mal zu lesen, wenn Sie unsere Plattform besuchen, um die neueste Version zu
            überprüfen.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Aktualisiert am 19. Juni 2023</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
