import React from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { useGetPromotionImage } from 'pages/PromotionPage/PromotionCardsContainer/PromotionCard/PromotionCardImage';
import { Box, Flex, MarginBox, Pipeline, Text } from 'UI';
import { getMomentDate } from 'utils/date';
import { DuplicatePromotionThumbnail } from './DuplicatePromotionRow.styled';

export const DuplicatePromotionRow = ({ promotion }: { promotion: Promotion }) => {
  const { t } = useTranslation();

  const startDate = getMomentDate(promotion?.publicationStart);
  const endDate = getMomentDate(promotion?.publicationEnd)?.subtract(1, 'd');
  const background = useGetPromotionImage({ image: promotion.image, thumbnail: true });

  return (
    <Flex direction={'column'}>
      <Flex direction={'row'}>
        <Box width={70} height={70}>
          <DuplicatePromotionThumbnail background={background} />
        </Box>
        <MarginBox mr={15} />
        <Flex direction={'column'} justify={'space-around'}>
          <Text type={'lead'}>{promotion.title}</Text>
          <Text type={'light_14_black_55'}>
            {startDate &&
              t('backoffice.promotion.details_step.promotion_dates', 'Promotion dates: {{date}}', {
                date: startDate.format('MMMM Do, YYYY'),
              })}
            {endDate &&
              t('common.date.to', ' to {{date}}', {
                date: endDate.format('MMMM Do, YYYY'),
              })}
          </Text>
          <Pipeline size={400} horizontal />
        </Flex>
      </Flex>
      <MarginBox mt={15} />
    </Flex>
  );
};
