class CacheMap<K, V> implements Map<K, V> {
  private map: Map<K, { value: V; timeoutId: NodeJS.Timeout }>;
  private readonly expirationTime: number;

  constructor(expirationTime: number) {
    this.map = new Map();
    this.expirationTime = expirationTime;
  }

  set(key: K, value: V): this {
    // Clear existing timeout if the key already exists
    if (this.map.has(key)) {
      clearTimeout(this.map.get(key)?.timeoutId);
    }

    // Set a timeout to remove the key after the expiration time
    const timeoutId = setTimeout(() => {
      this.map.delete(key);
    }, this.expirationTime);

    // Store the value along with the timeoutId
    this.map.set(key, { value, timeoutId });
    return this; // Return `this` to allow chaining
  }

  get(key: K): V | undefined {
    const entry = this.map.get(key);
    return entry ? entry.value : undefined;
  }

  has(key: K): boolean {
    return this.map.has(key);
  }

  delete(key: K): boolean {
    const entry = this.map.get(key);
    if (entry) {
      clearTimeout(entry.timeoutId);
      this.map.delete(key);
      return true;
    }
    return false;
  }

  clear(): void {
    this.map.forEach((entry) => clearTimeout(entry.timeoutId));
    this.map.clear();
  }

  get size(): number {
    return this.map.size;
  }

  // Required to fully implement the Map interface

  entries(): IterableIterator<[K, V]> {
    return new Map([...this.map.entries()].map(([k, { value }]) => [k, value])).entries();
  }

  keys(): IterableIterator<K> {
    return this.map.keys();
  }

  values(): IterableIterator<V> {
    return new Map([...this.map.entries()].map(([k, { value }]) => [k, value])).values();
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.entries();
  }

  forEach(callbackFn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    this.map.forEach(({ value }, key) => {
      callbackFn.call(thisArg, value, key, this);
    });
  }

  get [Symbol.toStringTag](): string {
    return 'CacheMap';
  }
}
export default CacheMap;
