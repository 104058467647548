const PREFIX = "/basket/";
export const GET_URL = PREFIX + "get";
export const ADD_REFERENCES_URL = PREFIX + "add-reference-items";
export const ADD_REFERENCE_BY_REF_NUMBER_URL = PREFIX + "add-reference-by-ref-number";
export const UPDATE_ORDER_MARK_URL = PREFIX + "update-order-mark";
export const UPDATE_REFERENCE_MARK_URL = PREFIX + "update-reference-mark";
export const UPDATE_REFERENCE_QUANTITY_URL = PREFIX + "update-reference-items-quantity";
export const UPLOAD_REFERENCES_FILE_URL = PREFIX + "upload/references";
export const UPDATE_REFERENCE_URGENCY_FLAG_URL = PREFIX + "update-urgency-flag";
export const UPDATE_ALL_REFERENCE_URGENCY_FLAGS_URL = PREFIX + "update-all-urgency-flags";
export const REMOVE_REFERENCES_URL = PREFIX + "remove-reference-items";
export const REMOVE_REFERENCE_URL = PREFIX + "remove-reference-item";
export const REVERT_LAST_REMOVAL_URL = PREFIX + "revert-last-removal";
export const ATTACH_OTHER_REFERENCE_START_URL = PREFIX + "attach-other-reference-start";
export const ATTACH_OTHER_REFERENCE_CANCEL_URL = PREFIX + "attach-other-reference-cancel";
export const ATTACH_OTHER_REFERENCE_CONFIRM_URL = PREFIX + "attach-other-reference-confirm";
export const NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS = PREFIX + "notify-user-about-expired-discounts";
