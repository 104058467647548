import { useEffect, useState } from 'react';

export type IndexType = string | null;
export const svgElementPlateId = 'svg-plate-detail-';
export const svgElementPlateModalId = 'svg-plate-detail-modal-';
export const svgTextPosition = 'svg g g text';

export function useDuplicateSvgLinesAndNumber(
  svgElement: string | JSX.Element | undefined,
  svgId: string,
  elementId: string,
  svgTextPosition: string,
  getSiblingsById: boolean,
  indexesLength: number,
): void {
  const [lastPlateId, setLastPlateId] = useState('');

  useEffect(() => {
    if (elementId !== lastPlateId && typeof svgElement === 'object') {
      addInvisibleLinesAndNumber(svgId, elementId, svgTextPosition, getSiblingsById, indexesLength);
      setLastPlateId(elementId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, svgElement, svgId, svgTextPosition, indexesLength, getSiblingsById]);
}

export function getElementAndSiblingsById(elementId: string): Element[] {
  try {
    return Array.from(document.querySelectorAll(`[id^=${elementId}]`));
  } catch {
    return [];
  }
}

export function getElementAndSiblings(element: Element): Element[] {
  const siblings = [];
  let current: Element | null = element;

  while (current != null) {
    siblings.push(current);
    current = current.nextElementSibling;
  }

  return siblings;
}

export const getSvgTextElementsFromDocument = (
  svgId: string,
  plateId: string,
  query: string,
): NodeListOf<Element> | undefined => {
  const svgElement = document.getElementById(`${svgId}${plateId}`);
  return svgElement?.querySelectorAll(query);
};

export const addInvisibleLinesAndNumber = (
  svgId: string,
  plateId: string,
  svgTextPosition: string,
  getSiblingsById: boolean,
  indexesLength: number,
): void => {
  const svgTextElements = getSvgTextElementsFromDocument(svgId, plateId, svgTextPosition);
  if (!svgTextElements || svgTextElements.length > indexesLength + 1) {
    return;
  }

  const fragment = document.createDocumentFragment();

  Array.from(svgTextElements).forEach((indexElement) => {
    const linesAndNumber = getSiblingsById
      ? getElementAndSiblingsById(indexElement.id)
      : getElementAndSiblings(indexElement);

    const lines = linesAndNumber.slice(1);
    const indexParent = indexElement.parentElement;
    if (!indexParent) {
      return;
    }

    // Clone index element
    const clonedIndex = indexElement.cloneNode(true) as Element;
    clonedIndex.classList.add('invisible-index');
    changeNumberAttribute(clonedIndex, indexElement, 'x', -1);
    changeNumberAttribute(clonedIndex, indexElement, 'y', 0);
    fragment.appendChild(clonedIndex);

    // Clone line elements
    lines.forEach((line) => {
      const clonedLine = line.cloneNode(true) as Element;
      clonedLine.classList.add('invisible-line');
      fragment.appendChild(clonedLine);
    });
  });

  document.getElementById(`${svgId}${plateId}`)?.appendChild(fragment);
};

function changeNumberAttribute(toElement: Element, fromElement: Element, attribute: string, change: number) {
  const newValue = Number(fromElement.getAttribute(attribute)) + change;
  toElement.setAttribute(attribute, newValue.toString());
}
