import styled from 'styled-components';
import { Text } from 'UI';

export const TabTitle = styled.div`
  width: 100%;
  min-height: 120px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.grey_very_dark};
  display: block;
  padding-top: 30px;
  padding-left: 15px;
  text-align: center;
  text-transform: capitalize;
`;

export const Link = styled.a<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.color.info : theme.color.brand_black)};

  :hover {
    color: ${({ theme }) => theme.color.info};
  }
`;

export const SubTitleText = styled(Text)`
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.grey35}
  text-align: center;
`;

export const SSearchShortcutWrapper = styled.div`
  padding: 15px 15px 18px 30px;
`;
