import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { RootState } from 'app/AppStore';
import { getCatalogIAMExplodedTree, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import {
  CarCategorySectionItemImg,
  SBodyCarCategorySectionItem,
  SCarCategorySection,
  SCarCategorySectionItem,
} from 'pages/CatalogPage/IAM/CarCategorySection/CarCategorySection.styled';
import {
  carPartGroupsItemBaseUrlEnd,
  carPartGroupsItemBaseUrlStart,
  carPartGroupsItemDefaultPicture,
} from 'pages/CatalogPage/IAM/CarPartGroupsSection/CarPartGroupsSection';
import { Flex, MarginBox, PUBLIC, Text, URL } from 'UI';
import { getData } from 'utils';

export const CarCategorySection = () => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleExplodedView = getData(useSelector((state: RootState) => getCatalogIAMExplodedTree(state, vehicleKey)));
  const { carPartGroupId, subcategoryId } = useParams<{
    carPartGroupId?: string;
    subcategoryId?: string;
  }>();
  //eslint-disable-next-line
  const level1Item = vehicleExplodedView?.data?.find((d) => d.nodeId === carPartGroupId);

  return (
    <>
      <SCarCategorySection wrap={'wrap'} justify={'center'}>
        {level1Item?.items.map((i) => (
          <CarCategorySectionItem
            carPartGroupId={carPartGroupId}
            subcategoryId={subcategoryId}
            item={i}
            key={i.nodeId}
          />
        ))}
      </SCarCategorySection>
      <MarginBox mt={30} />
    </>
  );
};

interface CarCategorySectionItemProps {
  carPartGroupId: string | undefined;
  subcategoryId: string | undefined;
  item: VehicleIAMPartCategoryTreeItem;
}

function CarCategorySectionItem(props: Readonly<CarCategorySectionItemProps>) {
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const item = props.item;
  const pictureHeight = 250;
  const pictureWidth = 430;

  return (
    <SCarCategorySectionItem>
      <CarCategorySectionItemImg
        type={URL}
        alt={'img'}
        src={carPartGroupsItemBaseUrlStart + item.nodeId + carPartGroupsItemBaseUrlEnd}
        fallbackComponent={
          <CarCategorySectionItemImg
            type={PUBLIC}
            alt={'img'}
            src={carPartGroupsItemDefaultPicture}
            height={pictureHeight}
            width={pictureWidth}
          />
        }
        height={pictureHeight}
        width={pictureWidth}
      />
      <MarginBox mt={5} />
      <SBodyCarCategorySectionItem>
        <Text type={'h2'} transform={'sentence-case'}>
          {item.label}
        </Text>
        <MarginBox mt={20} />
        <Flex direction={'column'}>
          {item.items.map((i) => (
            <Fragment key={i.index}>
              <Text
                type={'h5_light'}
                cursor={'pointer'}
                transform={'sentence-case'}
                onClick={() =>
                  history.push(
                    `${props.subcategoryId ? '.' : props.carPartGroupId}/${item.nodeId}/${i.nodeId}?${params}`,
                  )
                }
                hoverUnderLine
              >
                {i.label}
              </Text>
              <MarginBox mt={15} />
            </Fragment>
          ))}
        </Flex>
      </SBodyCarCategorySectionItem>
    </SCarCategorySectionItem>
  );
}
