/* eslint-disable max-len */
import { ElectronicPartsRepairRowCorrect } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRowCorrect';
import { ElectronicPartsRepairRowError } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRowError';
import {
  ElectronicPartsRepairFileResponse,
  ElectronicPartsRepairRow,
} from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/response/ElectronicPartsRepairFileResponse';
import {
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_RESPONSE,
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_RESPONSE,
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import {
  ELECTRONIC_PARTS_REPAIR_NAMESPACE,
  ElectronicPartsFileInformation,
  ElectronicPartsFileStatus,
  ElectronicPartsRepairState,
  FETCHING_FILE,
  GET_FILE,
  REPLACE_FILE,
  UPLOAD_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { SEARCH_STATUS } from 'utils';

// Init state
const initialState: ElectronicPartsRepairState = {
  fileUploadStatus: {
    fileStatus: FETCHING_FILE,
    correctRows: [],
    errorRows: [],
    isModalOpen: false,
    modalStatus: NO_FILE,
    hasFile: false,
  },
  fileInformation: { fileName: '', fileSize: 0, uploadTime: undefined },
  fileInformationForRequest: { fileName: '', fileSize: 0, uploadTime: undefined },
  fileRows: [],
  searchStatus: undefined,
};

// Saga actions
export const uploadFileElectricPartsRepairSaga = createAction<{
  fileBase64: string;
}>(UPLOAD_FILE);

export const replaceFileElectricPartsRepairSaga = createAction<{
  correctRows: ElectronicPartsRepairRowCorrect[];
  fileName: string;
  fileSize: number;
}>(REPLACE_FILE);

export const fetchFileElectricPartsRepairResponseSaga = createAction<{
  fileRows: ElectronicPartsRepairRow[];
  fileName: string;
  fileSize: number;
}>(BACKOFFICE_ELECTRONIC_PARTS_REPAIR_GET_FILE_RESPONSE);

export const fetchFileElectricPartsRepairRequestSaga = createAction(GET_FILE);

export const fetchUploadFileElectronicPartsRepairResponseSaga = createAction(
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_UPLOAD_REFERENCES_FILE_RESPONSE,
);

export const fetchReplaceFileElectronicPartsRepairResponse = createAction(
  BACKOFFICE_ELECTRONIC_PARTS_REPAIR_REPLACE_REFERENCES_FILE_RESPONSE,
);

// Slice
const slice = createSlice({
  name: ELECTRONIC_PARTS_REPAIR_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setElectronicPartsRepairFileUploadStatus: (state, { payload }: PayloadAction<ElectronicPartsFileStatus>) => {
      state.fileUploadStatus.fileStatus = payload;
    },
    setElectronicPartsRepairFileModalStatus: (state, { payload }: PayloadAction<ModalStatusType>) => {
      state.fileUploadStatus.modalStatus = payload;
    },
    setElectronicPartsRepairFileCorrectRows: (state, { payload }: PayloadAction<ElectronicPartsRepairRowCorrect[]>) => {
      state.fileUploadStatus.correctRows = payload;
    },
    setElectronicPartsRepairFileErrorRows: (state, { payload }: PayloadAction<ElectronicPartsRepairRowError[]>) => {
      state.fileUploadStatus.errorRows = payload;
    },
    setElectronicPartsRepairFileInformationForRequest: (
      state,
      { payload }: PayloadAction<ElectronicPartsFileInformation>,
    ) => {
      state.fileInformationForRequest = payload;
    },
    setElectronicPartsRepairFileIsModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.fileUploadStatus.isModalOpen = payload;
    },
    setElectronicPartsRepairFileHasFile: (state, { payload }: PayloadAction<boolean>) => {
      state.fileUploadStatus.hasFile = payload;
    },
    setElectronicPartsRepairFileResponse: (state, { payload }: PayloadAction<ElectronicPartsRepairFileResponse>) => {
      const { fileName, fileSize, fileRows, uploadTime } = payload;
      state.fileInformation = {
        fileName,
        fileSize,
        uploadTime,
      };
      state.fileRows = fileRows;
    },
    setElectronicPartsRepairSearchStatus: (state, { payload }: PayloadAction<SEARCH_STATUS>) => {
      state.searchStatus = payload;
    },
  },
});

// Actions
export const {
  setInitialState,
  setElectronicPartsRepairFileUploadStatus,
  setElectronicPartsRepairFileModalStatus,
  setElectronicPartsRepairFileCorrectRows,
  setElectronicPartsRepairFileErrorRows,
  setElectronicPartsRepairFileInformationForRequest,
  setElectronicPartsRepairFileIsModalOpen,
  setElectronicPartsRepairFileHasFile,
  setElectronicPartsRepairFileResponse,
  setElectronicPartsRepairSearchStatus,
} = slice.actions;

// Getters/Selectors
export const getElectronicPartsRepairCorrectRows = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileUploadStatus.correctRows,
  (correctRows) => correctRows,
);

export const getElectronicPartsRepairSearchStatus = createSelector(
  (state: RootState) => state.electronicCarRepairs.searchStatus,
  (status) => status,
);

export const getElectronicPartsRepairRows = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileRows,
  (fileRows) => fileRows,
);

export const getElectronicPartsRepairErrorRows = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileUploadStatus.errorRows,
  (errorRows) => errorRows,
);

export const getElectronicPartsRepairFileUploadStatus = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileUploadStatus.fileStatus,
  (fileStatus) => fileStatus,
);

export const getElectronicPartsRepairFileModalStatus = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileUploadStatus.modalStatus,
  (modalStatus) => modalStatus,
);

export const getElectronicPartsRepairFileInformation = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileInformation,
  (fileInformation) => fileInformation,
);

export const getElectronicPartsRepairFileInformationForRequest = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileInformationForRequest,
  (fileInformationForRequest) => fileInformationForRequest,
);

export const getElectronicPartsRepairFileIsModalOpen = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileUploadStatus.isModalOpen,
  (isModalOpen) => isModalOpen,
);

export const getElectronicPartsRepairFileHasFile = createSelector(
  (state: RootState) => state.electronicCarRepairs.fileUploadStatus.hasFile,
  (hasFile) => hasFile,
);

// Export reducer
export default slice.reducer;
