import { env } from 'env';
import { EventTracker } from 'utils/eventTracker';
import { TrackingEvent } from 'utils/eventTracker/EventTracker.types';

const analyticsUrl = env.REACT_APP_MATOMO_HOME;

const siteId = env.REACT_APP_MATOMO_SITE_ID || '-1';
export const appTracker = new EventTracker({ siteId, analyticsUrl });

export function trackAppEvent(trackingEvent: TrackingEvent): void {
  const { category, action, name, value } = trackingEvent;
  appTracker.trackEvent(category, action, name, value);
}
