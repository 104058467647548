import React, { ReactNode } from 'react';
import { Form as AntForm } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { Store } from 'antd/lib/form/interface';

export type ValidationMessages = {
  types?: {
    number?: string;
    integer?: string;
    email?: string;
    string?: string;
  };
};

export interface FormProps<T> {
  children?: ReactNode;
  onFinish?: (values: any) => void;
  validationMessages?: ValidationMessages;
  layout?: FormLayout;
  initialValues?: Store;
  form?: FormInstance<T>;
}

export const Form = <T,>({ onFinish, children, validationMessages, layout, initialValues, form }: FormProps<T>) => {
  return (
    <AntForm
      onFinish={onFinish}
      validateMessages={validationMessages}
      layout={layout}
      initialValues={initialValues}
      form={form}
    >
      {children}
    </AntForm>
  );
};

Form.Item = AntForm.Item;
