import styled from 'styled-components';
import { Flex } from 'UI';

export const CardFlex = styled(Flex)`
  padding: 8px 15px;
  border-radius: 16px;
`;

export const StepLineFlex = styled(Flex)`
  cursor: pointer;
`;
