import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { PromotionType } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/PromotionType';
import { DashboardPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { TFunction } from 'i18next';
import { useFetchFullPromotion } from 'domains/promotion/Promotion.requests';
import { getUserRights, UserRole } from 'domains/user';
import {
  ArrowBadge,
  ArrowBadgeWrapper,
  DetailDot,
  DetailWrapper,
  SCardMenuWrapper,
  SPopover,
  SPromotionItem,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/PromotionCard.styled';
import { PromotionDetailMenu } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard/PromotionDetailMenu';
import { PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Box, MarginBox, Text, URL, WithLink } from 'UI';
import { getMomentDate } from 'utils/date';

function translatePromotionType(t: TFunction, promotionType: PromotionType): string {
  switch (promotionType) {
    case 'DISCOUNT':
      return t('backoffice.promotion.discount', 'Discount');
    case 'PROMOTION_FLASH_QUANTITY_LIMITED':
    case 'PROMOTION_FLASH_TIME_LIMITED':
      return t('backoffice.promotion.promotion_flash', 'Promotion flash');
    case 'LOAYALITY_POINTS':
      return t('backoffice.promotion.loyalty_points', 'Loyalty points');
    case 'ADVANTAGE_CODE':
      return t('backoffice.promotion.advantage_code', 'Advantage code');
    case 'GIVE_AWAY':
      return t('backoffice.promotion.give_away', 'Give away');
    case 'BANNER':
      return t('backoffice.promotion.banner', 'Banner');
    default:
      return '';
  }
}

export const PromotionCard = ({ promotion, statusType }: { promotion: DashboardPromotion; statusType: StatusType }) => {
  const { t } = useTranslation();
  const [promotionDetailMenuVisible, setPromotionDetailMenuVisible] = useState<boolean>(false);
  const userRights = useSelector(getUserRights);
  const startDate = getMomentDate(promotion?.publicationStart);
  const endDate = getMomentDate(promotion?.publicationEnd)?.subtract(1, 'd');
  const effectiveEndDate = getMomentDate(promotion?.effectiveEndDate);
  const showAdjustmentBadge =
    !promotion.isAdjusted &&
    userRights.includes(UserRole.R1) &&
    promotion.promotionType === 'DISCOUNT' &&
    statusType === 'PLANNED' &&
    !!(promotion.additionalDiscount && promotion.additionalDiscount > 0);

  useFetchFullPromotion(promotion.promotionId);

  const getDateLabelByStatus = () => {
    if (statusType === 'PUBLISHED' || statusType === 'ARCHIVED') {
      const showDate = promotion.publicationEnd === promotion.effectiveEndDate ? endDate : effectiveEndDate;
      return showDate && ` - ${t('common.date.until', 'Until')} ${showDate.format('MMMM Do, YYYY')}`;
    } else {
      return (
        startDate && ` - ${t('backoffice.promotion.from_start_date', 'Starts on')} ${startDate.format('MMMM Do, YYYY')}`
      );
    }
  };

  const getFlashPromoQuantity = () => {
    if (promotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED' && endDate === undefined) {
      return (
        promotion.quantity &&
        ` - ${t('backoffice.promotion.units_left', '{{count}} units left', { count: promotion.quantity })}`
      );
    }
  };

  const onVisibleChange = (visible: boolean) => {
    setPromotionDetailMenuVisible(visible);
  };

  return (
    <Box width={200}>
      {showAdjustmentBadge && (
        <ArrowBadgeWrapper direction={'row'}>
          <ArrowBadge>
            <Text type={'mobile_h3'}>{t('common.customize', 'Customize')}</Text>
          </ArrowBadge>
        </ArrowBadgeWrapper>
      )}
      <SCardMenuWrapper>
        <SPopover
          content={
            <PromotionDetailMenu
              setPromotionDetailMenuVisible={setPromotionDetailMenuVisible}
              promotion={promotion}
              statusType={statusType}
            />
          }
          trigger={'click'}
          placement={'bottomLeft'}
          onVisibleChange={onVisibleChange}
          visible={promotionDetailMenuVisible}
          showArrow={false}
        >
          <DetailWrapper>
            <DetailDot />
            <DetailDot />
            <DetailDot />
          </DetailWrapper>
        </SPopover>
      </SCardMenuWrapper>
      <WithLink to={`/${PROMOTION}/${promotion.promotionId}`}>
        {promotion.imageUrl ? (
          <SPromotionItem backgroundImage={promotion.imageUrl} backgroundImageType={URL} />
        ) : (
          <SPromotionItem />
        )}
      </WithLink>
      <MarginBox mt={10}>
        <Box height={22}>
          <Text type={'section_bold'} ellipsis noWrap>
            {promotion.title}
          </Text>
        </Box>
        <Box height={22}>
          {
            <Text type={'description'}>
              {translatePromotionType(t, promotion.promotionType)}
              {getDateLabelByStatus()}
              {getFlashPromoQuantity()}
            </Text>
          }
        </Box>
      </MarginBox>
    </Box>
  );
};
