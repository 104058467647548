import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PromotionType } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import bigDecimal from 'js-big-decimal';
import moment from 'moment';
import { RootState } from 'app/AppStore';
import { CertificateIcon, DiscountCornerIcon } from 'assets/icons';
import {
  CornerLabel,
  DiscountPositionWrapper,
  FlashPositionWrapper,
  FlashTextWrapper,
} from 'components/Discount/Discount.styled';
import { isFlashPromotion } from 'domains/promotion/Promotion.types';
import { getDiscount, ReferencePriceType } from 'domains/references';
import { theme } from 'styles';
import { Box, CenterFlex, Flex, Icon, Text } from 'UI';

const scale_compute = 10;
const scale_final = 2;
const hundred = new bigDecimal('100');

interface DiscountCornerProps {
  reference: string;
  type: 'cart' | 'catalog' | 'catalog_mini' | 'promotion' | 'product' | 'productList';
  staticPercent?: number;
  staticType?: PromotionType;
}

interface FlashCertificateProps {
  reference: string;
  type: 'product' | 'promotion';
  staticPercent?: number;
}

const discountSizes = {
  cart: {
    icon: 60,
    font: {
      type: 'dark_14_white_100',
      offsetY: -65,
    },
  },
  catalog: {
    icon: 110,
    font: {
      type: 'h1_lead_white_singular',
      offsetY: -120,
    },
  },
  catalog_mini: {
    icon: 58,
    font: {
      type: 'light_12_dark',
      offsetY: -68,
    },
  },
  promotion: {
    icon: 55,
    font: {
      type: 'light_12_dark',
      offsetY: -62,
    },
  },
  productList: {
    icon: 55,
    font: {
      type: 'light_12_dark',
      offsetY: -65,
    },
  },
  product: {
    icon: 120,
    font: {
      type: 'h1_lead_white_singular',
      offsetY: -135,
      offsetX: -5,
    },
  },
};

const flashSizes = {
  product: {
    icon: 96,
    offsetY: 15,
    font: {
      type: 'h1_lead_white_singular',
      offsetY: -80,
    },
  },
  promotion: {
    icon: 48,
    offsetY: 12,
    font: {
      type: 'advertisement_text',
      offsetY: -35,
    },
  },
};

export const getDiscountTextStyle = (discount: ReferenceDiscount | undefined) => {
  switch (discount?.promotionType) {
    case 'DISCOUNT':
      return 'promoDiscount';
    case 'PROMOTION_FLASH_QUANTITY_LIMITED':
    case 'PROMOTION_FLASH_TIME_LIMITED':
      return 'cyanHighlight';
    default:
      return undefined;
  }
};
export const getDiscountColor = (discount: ReferenceDiscount | undefined) => {
  switch (discount?.promotionType) {
    case 'DISCOUNT':
      return theme.color.red;
    case 'PROMOTION_FLASH_QUANTITY_LIMITED':
    case 'PROMOTION_FLASH_TIME_LIMITED':
      return theme.color.cyan;
    default:
      return theme.color.brand_black;
  }
};

function getPriceWithoutPromotionDiscount(price: ReferencePriceType, vatIncluded: boolean, isUrgent: boolean) {
  const garageView = price.garageView;
  if (vatIncluded) {
    return Number(isUrgent ? garageView?.vatIncludedPriceUrgent : garageView?.vatIncludedPrice);
  } else {
    return Number(isUrgent ? garageView?.vatExcludedPriceUrgent : garageView?.vatExcludedPrice);
  }
}

export function getBasePrice(price: ReferencePriceType, vatIncluded: boolean) {
  return new bigDecimal(
    vatIncluded ? price.clientView?.recommendedPriceVatIncluded : price.clientView?.recommendedPriceVatExcluded,
  );
}

export function calculateUrgentDeliveryAddition(
  price: ReferencePriceType | undefined,
  discount: ReferenceDiscount | undefined,
  vatIncluded: boolean,
) {
  const urgentPriceWithDiscount = calculatePriceAfterDiscount(discount, price, vatIncluded, true);
  const standardPriceWithDiscount = calculatePriceAfterDiscount(discount, price, vatIncluded, false);
  return urgentPriceWithDiscount - standardPriceWithDiscount;
}

export const calculatePriceAfterDiscount = (
  discount: ReferenceDiscount | undefined,
  price: ReferencePriceType | undefined,
  vatIncluded: boolean,
  isUrgent = false,
): number => {
  if (!price) {
    return 0;
  }
  if (!discount) {
    return getPriceWithoutPromotionDiscount(price, vatIncluded, isUrgent);
  }

  const basePrice = getBasePrice(price, vatIncluded);
  const groupDiscount = isUrgent
    ? Number(price.garageView?.discountRateUrgent ?? 0)
    : Number(price.garageView?.discountRate ?? 0);

  switch (discount.discountType) {
    case 'PCL': {
      const joinDiscount = discount.discount + groupDiscount;
      return Number(
        basePrice
          .divide(hundred, scale_compute, bigDecimal.RoundingModes.HALF_UP)
          .multiply(new bigDecimal(100 - Math.min(joinDiscount, 100)))
          .round(scale_final, bigDecimal.RoundingModes.HALF_EVEN)
          .getValue(),
      );
    }
    case 'PNMRA': {
      const partialPrice = basePrice
        .divide(hundred, scale_compute, bigDecimal.RoundingModes.HALF_UP)
        .multiply(new bigDecimal(100 - groupDiscount));
      return Number(
        partialPrice
          .divide(hundred, scale_compute, bigDecimal.RoundingModes.HALF_UP)
          .multiply(hundred.subtract(new bigDecimal(discount.discount)))
          .round(scale_final, bigDecimal.RoundingModes.HALF_EVEN)
          .getValue(),
      );
    }
    default:
      return 0;
  }
};

export const FlashCertificate = ({ reference, type, staticPercent }: FlashCertificateProps) => {
  const { t } = useTranslation();
  const referenceDiscount = useSelector((state: RootState) => getDiscount(state, reference));
  const showUnits = type === 'product';

  const getTimeLeft = (units: 'd' | 'h' | 'm') => moment(referenceDiscount?.validityEnd).diff(moment(), units);

  const daysLeft = getTimeLeft('d');
  const hoursLeft = getTimeLeft('h');
  const minutesLeft = getTimeLeft('m');

  if (!isFlashPromotion(referenceDiscount?.promotionType) && !staticPercent) {
    return null;
  }

  const getUnitsText = () => {
    switch (referenceDiscount?.promotionType) {
      case 'PROMOTION_FLASH_TIME_LIMITED': {
        if (daysLeft > 0) {
          return `${t('backoffice.promotion.days_left', '{{count}} days left.', {
            count: daysLeft,
          })}.`;
        }
        if (hoursLeft > 0) {
          return `${t('backoffice.promotion.hours_left', '{{count}} hours left.', {
            count: hoursLeft,
          })}.`;
        }
        return `${t('backoffice.promotion.minutes_left', '{{count}} minutes left.', {
          count: minutesLeft,
        })}.`;
      }
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
        return `${t('backoffice.promotion.units_left', '{{count}} units left', {
          count: referenceDiscount?.remainingQuantity,
        })}.`;
    }
  };

  return (
    <Flex direction={'column'} align={'center'}>
      <Icon IconComponent={CertificateIcon} size={flashSizes[type].icon} color={theme.color.cyan} noPointer />
      <FlashPositionWrapper offsetY={flashSizes[type].offsetY}>
        <Text type={flashSizes[type].font.type} disableGutter cursor={'initial'}>
          {`-${staticPercent ?? referenceDiscount?.discount}%`}
        </Text>
      </FlashPositionWrapper>
      {showUnits && (
        <>
          <Box height={17} />
          <FlashTextWrapper>
            <CenterFlex>
              <Text type={'card_title_dark'} disableGutter cursor={'initial'}>
                {getUnitsText()}
              </Text>
            </CenterFlex>
          </FlashTextWrapper>
        </>
      )}
    </Flex>
  );
};

export const getPromotionColor = ({
  staticType,
  referencePromotionType,
  fallbackColor,
}: {
  staticType?: PromotionType;
  referencePromotionType?: PromotionType;
  fallbackColor?: string;
}) => {
  const promotionType = staticType ?? referencePromotionType;
  switch (promotionType) {
    case 'PROMOTION_FLASH_QUANTITY_LIMITED':
    case 'PROMOTION_FLASH_TIME_LIMITED':
      return theme.color.cyan;
    case 'DISCOUNT':
      return theme.color.red_alt;
    default:
      return fallbackColor ?? theme.color.brand_black;
  }
};
export const DiscountCorner = ({ reference, type, staticPercent, staticType }: DiscountCornerProps) => {
  const referenceDiscount = useSelector((state: RootState) => getDiscount(state, reference));
  if (!referenceDiscount && !staticPercent) {
    return null;
  }

  if (type === 'promotion' && isFlashPromotion(staticType)) {
    return null;
  }

  if (type === 'product' && isFlashPromotion(referenceDiscount?.promotionType)) {
    return null;
  }

  return (
    <DiscountPositionWrapper>
      <Icon
        IconComponent={DiscountCornerIcon}
        noPointer
        size={discountSizes[type].icon}
        color={getPromotionColor({ staticType, referencePromotionType: referenceDiscount?.promotionType })}
      />
      <CornerLabel offsetY={discountSizes[type].font.offsetY}>
        <Text type={discountSizes[type].font.type}>{`-${staticPercent ?? referenceDiscount?.discount} %`}</Text>
      </CornerLabel>
    </DiscountPositionWrapper>
  );
};
