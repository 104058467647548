import { Alert } from 'antd';
import styled from 'styled-components';
import { Divider } from 'UI';

export const ErrorAlert = styled(Alert)`
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.color.error_light};
  flex: 1;
`;

export const ErrorDetails = styled.div`
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.color.error_light};
  background-color: ${({ theme }) => theme.color.very_light_pink};
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.color.grey50};
  flex: 1;
  padding: 12px 24px 12px 24px;
`;

export const SDivider = styled(Divider)`
  border-left: 1px solid ${({ theme }) => theme.color.error_light};
  height: inherit;
  margin: 0 25px 0 0;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  max-height: 260px;
  overflow: hidden;

  :hover {
    overflow-y: auto;
  }
`;
