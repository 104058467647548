import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getDHReference } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import ReferenceCard from 'pages/CatalogPage/DH/Product/ProductMenu/ReferenceCard';
import { getData } from 'utils';

export interface SubstituteReferenceItemProps {
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
}

const SubstituteReferenceItem = ({ referenceNumber, sparePartsView }: SubstituteReferenceItemProps) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const reference = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));
  const substituteReference = getData(reference)?.substitutedReference;
  if (!substituteReference) {
    return null;
  }
  return <ReferenceCard referenceNumber={substituteReference.referenceNumber} sparePartsView={sparePartsView} />;
};

export default SubstituteReferenceItem;
