import styled from 'styled-components';
import { Box, Flex, Image } from 'UI';

export const SCarCategorySection = styled(Flex)`
  row-gap: 60px;
  column-gap: 30px;
`;

export const CarCategorySectionItemImg = styled(Image)`
  border-radius: 10px;
`;

export const SCarCategorySectionItem = styled(Box)`
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.15);
`;

export const SBodyCarCategorySectionItem = styled(Box)`
  padding: 15px;
`;
