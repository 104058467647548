/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ElectronicPartsRepairRow } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRow';
import moment from 'moment/moment';
import {
  getElectronicPartsRepairFileHasFile,
  getElectronicPartsRepairFileInformation,
  getElectronicPartsRepairRows,
  setElectronicPartsRepairFileIsModalOpen,
  setElectronicPartsRepairFileUploadStatus,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import {
  ElectronicPartsFileStatus,
  FILE_FETCHED,
  FILE_REPLACED,
  SAME_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { BorderedFlex } from 'pages/BackOfficePage/BackOfficeCategories/ElectronicPartsRepair/FilePreview/FilePreview.styled';
import { Flex, InfiniteScroll, MarginBox, Text, useInfiniteScroll, YellowButton } from 'UI';
import { notifyTop } from 'UI/Notification/notification';
import { textFormatter } from 'utils';

interface FilePreviewProps {
  fileStatus: ElectronicPartsFileStatus;
}

const FilePreview = ({ fileStatus }: FilePreviewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rows = useSelector(getElectronicPartsRepairRows);
  const fileInformation = useSelector(getElectronicPartsRepairFileInformation);
  const fileName = fileInformation.fileName ?? '';
  const fileSize = fileInformation.fileSize ? fileInformation.fileSize + ' B ' : '';
  const uploadDate = fileInformation.uploadTime ? new Date(fileInformation.uploadTime) : '';
  const uploadDateFormatted =
    uploadDate && moment(uploadDate).isValid() ? textFormatter.formatDateNumeric(uploadDate) : '';
  const uploadDateDisplay = uploadDateFormatted ? '- Uploaded ' + uploadDateFormatted : '';
  const fileAlreadyUploaded = useSelector(getElectronicPartsRepairFileHasFile);
  const { hasMore, loadMore, currentData } = useInfiniteScroll<ElectronicPartsRepairRow>({
    data: rows,
    chunkSize: 50,
  });
  const replace = () => {
    dispatch(setElectronicPartsRepairFileIsModalOpen(true));
  };

  useEffect(() => {
    if (fileAlreadyUploaded && fileStatus === FILE_REPLACED) {
      notifyTop(
        'success',
        t(
          'backoffice.electronic_parts_repair.file_preview.file_successfully_updated',
          'Your file was successfully updated and is now available online',
        ),
      );
      dispatch(setElectronicPartsRepairFileUploadStatus(FILE_FETCHED));
    }
    if (!fileAlreadyUploaded && fileStatus === FILE_REPLACED) {
      notifyTop(
        'success',
        t(
          'backoffice.electronic_parts_repair.file_preview.file_successfully_uploaded',
          'Your file was successfully uploaded and is now available online',
        ),
      );
      dispatch(setElectronicPartsRepairFileUploadStatus(FILE_FETCHED));
    }
    if (fileStatus === SAME_FILE) {
      notifyTop(
        'error',
        t('backoffice.electronic_parts_repair.file_preview.same_file_error', 'This file is already uploaded.'),
      );
      dispatch(setElectronicPartsRepairFileUploadStatus(FILE_FETCHED));
    }
  }, [dispatch, fileAlreadyUploaded, fileStatus, t]);

  return (
    <Flex justify={'center'}>
      <MarginBox mt={24}>
        <Flex minWidth={800} maxWidth={800} direction={'column'}>
          <MarginBox mt={40} />
          <Flex direction={'row'}>
            <Text type={'h2'}>{t('common.file_upload.file_preview', 'File preview')}</Text>
            <Flex justify={'flex-end'}>
              <YellowButton size={'large'} stretch={false} onClick={replace}>
                {t('common.file_upload.file.replace', 'Replace file')}
              </YellowButton>
            </Flex>
          </Flex>
          <MarginBox mt={15} />
          <Text type={'text_dim'}>
            {`${t('backoffice.electronic_parts_repair.file_preview.file_name', 'File name')}: ${fileName}`}
          </Text>
          <Text type={'text_dim'}>
            {`${t(
              'backoffice.electronic_parts_repair.file_preview.file_size',
              'File size',
            )}: ${fileSize} ${uploadDateDisplay}`}
          </Text>
          <MarginBox mt={23} />

          {rows?.length > 0 && (
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
              <Flex direction={'row'}>
                <BorderedFlex justify={'center'} align={'center'} maxHeight={55} minHeight={55}>
                  <Text type={'h6'} disableGutter>
                    {t(
                      'backoffice.electronic_parts_repair.file_preview.commercial_family_code',
                      'Commercial Family Code',
                    )}
                  </Text>
                </BorderedFlex>
                <BorderedFlex justify={'center'} align={'center'} maxHeight={55} minHeight={55}>
                  <Text type={'h6'} disableGutter>
                    {t('backoffice.electronic_parts_repair.file_preview.reference', 'Reference')}
                  </Text>
                </BorderedFlex>
                <BorderedFlex justify={'center'} align={'center'} maxHeight={55} minHeight={55}>
                  <Text type={'h6'} disableGutter>
                    {t('backoffice.electronic_parts_repair.file_preview.price', 'Price')}
                  </Text>
                </BorderedFlex>
              </Flex>
              {currentData.map((row, index) => {
                return (
                  <Flex key={`electronic_parts_repair_row_${index}`} direction={'row'}>
                    <BorderedFlex justify={'center'} align={'center'} maxHeight={55} minHeight={55}>
                      <Text type={'lead'} disableGutter>
                        {row.commercialFamilyCode}
                      </Text>
                    </BorderedFlex>
                    <BorderedFlex justify={'center'} align={'center'} maxHeight={55} minHeight={55}>
                      <Text type={'lead'} disableGutter>
                        {row.referenceNumber}
                      </Text>
                    </BorderedFlex>
                    <BorderedFlex justify={'center'} align={'center'} maxHeight={55} minHeight={55}>
                      <Text type={'lead'} disableGutter>
                        {row.price}
                      </Text>
                    </BorderedFlex>
                  </Flex>
                );
              })}
            </InfiniteScroll>
          )}
        </Flex>
      </MarginBox>
    </Flex>
  );
};

export default FilePreview;
