import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getUploadedReferencesFromFile, setNewUploadedReference } from 'domains/promotion/Promotion.store';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import { Flex, MarginBox, PlusCircleButton, Text } from 'UI';
import { AddPromotedReference } from './AddPromotedReferece';
import { UploadedReferences } from './UploadedReferences';

interface ByReferencesProps {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  setCurrentStep: (x: number) => void;
  maxFileCount: number;
}

export function ReferencesDiscountRow({
  promotion,
  setPromotion,
  setCurrentStep,
  maxFileCount,
}: Readonly<ByReferencesProps>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadedReferences = useSelector(getUploadedReferencesFromFile);
  const [displayAddButton, setDisplayAddButton] = useState<boolean>(false);

  const handleDisplayAddButton = useCallback(() => {
    setDisplayAddButton(uploadedReferences.length < maxFileCount);
  }, [uploadedReferences, maxFileCount]);

  useEffect(() => {
    handleDisplayAddButton();
  }, [handleDisplayAddButton]);

  const addFile = () => {
    dispatch(setNewUploadedReference({ fileId: uuidv4() }));
    setDisplayAddButton(false);
  };

  return (
    <Flex direction={'column'}>
      {maxFileCount > 0 ? (
        <>
          {[...uploadedReferences.values()].map((uploadedReference, index) => (
            <UploadedReferences
              key={`ReferencesDiscountRow_${index}`}
              uploadedReferences={uploadedReference}
              promotion={promotion}
              setPromotion={setPromotion}
              setCurrentStep={setCurrentStep}
              index={index}
            />
          ))}
          {displayAddButton && (
            <>
              <PlusCircleButton onClick={addFile}>
                <Text type={'link_bold'} decoration={'underline'}>
                  {t('backoffice.promotion.add_csv_file', 'Add CSV file')}
                </Text>
              </PlusCircleButton>
              <MarginBox mt={15} />
            </>
          )}
        </>
      ) : (
        <AddPromotedReference promotion={promotion} setPromotion={setPromotion} setCurrentStep={setCurrentStep} />
      )}
    </Flex>
  );
}
