import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleIcon, TimesCircleIcon } from 'assets/icons';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text } from 'UI';
import {
  ReferenceUnavailableForOrderWrapper,
  ReferenceUnavailableWrapperMedium,
  ReferenceUnavailableWrapperSmall,
} from './ReferenceUnavailable.styled';

type ProductReferenceUnavailableSizeType = 'small' | 'medium';

interface ProductReferenceUnavailableProps {
  size?: ProductReferenceUnavailableSizeType;
  message?: string;
}

export const ReferenceUnavailable = ({ size = 'small', message }: ProductReferenceUnavailableProps) => {
  const { t } = useTranslation();
  const ReferenceUnavailableWrapper =
    size === 'small' ? ReferenceUnavailableWrapperSmall : ReferenceUnavailableWrapperMedium;

  return (
    <ReferenceUnavailableWrapper>
      <MarginBox mr={15} />
      <Icon IconComponent={TimesCircleIcon} color={theme.color.lipstick} width={25} height={25} />
      <MarginBox mr={5} />
      <Text type={'light_14_black_75'}>
        {message ?? t('catalog.reference.unavailable', 'Looks like this item is not available in your country.')}
      </Text>
      <MarginBox mr={15} />
    </ReferenceUnavailableWrapper>
  );
};

export const ReferenceUnavailableForOrder = ({ enabled = false }: { enabled?: boolean }) => {
  const { t } = useTranslation();
  if (!enabled) {
    return null;
  }
  return (
    <ReferenceUnavailableForOrderWrapper>
      <MarginBox mx={15} my={15}>
        <Flex direction={'row'}>
          <InfoCircleIcon width={24} height={24} fill={theme.color.info} />
          <MarginBox mr={15} />
          <Text type={'text_bold'}>
            {t(
              'catalog.reference.order_unavailable.error_message',
              'Please contact your Dealer to place an order for this reference.',
            )}
          </Text>
        </Flex>
      </MarginBox>
    </ReferenceUnavailableForOrderWrapper>
  );
};
