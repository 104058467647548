import React, { Suspense } from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider as ConfigProviderV5 } from 'antd-v5';
import AppRouterContainer from 'app/AppRouterContainer';
import Loader from 'components/Loader';
import { ErrorBoundary } from 'composers/ErrorBoundary';
import { antLessTheme } from 'styles/antLessTheme';
import { Theme } from 'UI';
import { GlobalStyle } from './App.styled';
import 'antd/dist/antd.less';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import { globalStore } from './AppStore';
import 'react-image-crop/dist/ReactCrop.css';
import ReloadInit from './ReloadInit';

export const App = () => {
  return (
    <Provider store={globalStore} context={ReactReduxContext}>
      <ConfigProviderV5 prefixCls={'ant5'} theme={antLessTheme}>
        <ReloadInit />
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <Theme>
                <GlobalStyle />
                <AppRouterContainer />
              </Theme>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </ConfigProviderV5>
    </Provider>
  );
};
