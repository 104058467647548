import React from 'react';
import { Layout } from 'antd';
import BackOfficeHeader from 'pages/BackOfficePage/BackOfficeLayout/BackOfficeHeader';
import BackOfficeMenu from 'pages/BackOfficePage/BackOfficeLayout/BackOfficeMenu';
import BackOfficeRouter from 'pages/BackOfficePage/BackOfficeLayout/BackOfficeRouter';
import { Container, emptyPadding } from 'UI';

const BackOfficePage = () => {
  return (
    <Container padding={emptyPadding} size={'xxl'}>
      <BackOfficeHeader />
      <Layout>
        <BackOfficeMenu />
        <BackOfficeRouter />
      </Layout>
    </Container>
  );
};

export default BackOfficePage;
