import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { PlusCircleIcon } from 'assets/icons';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { addBundleFromMyStore, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { addBundlesSectionRequestSaga, getAllSelectedBundles } from 'domains/myStore/MyStore.store';
import { Flex, Icon, NotificationLink, notifyTop, WhiteBlueButton } from 'UI';
import { TRACKING_EVENT_ADD_TO_ESTIMATE, TRACKING_EVENT_GO_TO_ESTIMATE } from 'utils/eventTracker/EventTracker.types';

const BundlesHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const selectedBundles = useSelector((state: RootState) => getAllSelectedBundles(state));

  const dispatch = useDispatch();

  const handleAddSection = () => {
    dispatch(addBundlesSectionRequestSaga());
  };

  const handleAddToEstimateClick = () => {
    notifyTop(
      'success',
      <Trans i18nKey={'estimate.bundles.addedMultipleToEstimate'}>{'Bundles have been added to your estimate.'}</Trans>,
      undefined,
      <NotificationLink
        onClick={() => {
          trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
          dispatch(setSelectedTab(EstimateTabName));
          history.push(`${ROUTER_ESTIMATE}`);
        }}
      >
        <Trans i18nKey={'catalog.reference_card.added_to_estimate.go_to_estimate'}>{'Go to estimate'}</Trans>
      </NotificationLink>,
    );
    trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
    dispatch(addBundleFromMyStore(selectedBundles));
  };

  return (
    <>
      <WhiteBlueButton outlined size={'large'} onClick={handleAddSection}>
        <Flex gap={8} align={'center'}>
          <Icon IconComponent={PlusCircleIcon} size={20} />
          {t('my_store.bundles.actions.add_bundle_type', 'Add a bundle type')}
        </Flex>
      </WhiteBlueButton>
      <AddToEstimateButtonAndDialog
        buttonComponent={'button'}
        disabled={!selectedBundles?.length}
        handleAddToEstimateClick={() => handleAddToEstimateClick()}
      />
    </>
  );
};

export default BundlesHeader;
