import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import PageViewCounter from 'components/Help/PageViewCounter';
import LanguageSettings from 'components/LanguageSettings';
import UserInfoTemp from 'components/UserInfoTemp';
import { getUserRights, UserRole } from 'domains/user';
import { MyOrdersRouter } from 'pages/MyOrdersPage/MyOrdersRouter';
import { ACCOUNT, LANGUAGE, ORDERS, SETTINGS } from 'pages/ProfilePage/ProfilePage.types';
import { Settings } from 'pages/ProfilePage/Settings/Settings';
import { Flex, LinkTabs, Text } from 'UI';
import { getCondArrayItem, getData, hasUserAnyRight } from 'utils';
import { TRACKING_EVENT_ORDERS_IN_PROGRESS } from 'utils/eventTracker/EventTracker.types';
import { Container } from './ProfilePage.styled';

const getNavigationItems = (t: TFunction, userRights: UserRole[]) => {
  return [
    {
      key: 1,
      label: t('profile.my_account', 'My account'),
      link: `/${ACCOUNT}`,
      component: <UserInfoTemp />,
      dataCy: 'button-my-account',
    },
    {
      key: 2,
      label: t('profile.language', 'Language'),
      link: `/${LANGUAGE}`,
      component: <LanguageSettings />,
      dataCy: 'button-language',
    },
    {
      key: 3,
      label: t('profile.settings', 'Settings'),
      link: `/${SETTINGS}`,
      component: <Settings />,
      dataCy: 'button-settings',
    },
    ...getCondArrayItem(
      hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], userRights) && {
        key: 5,
        label: t('order_plural', 'Orders'),
        link: `/${ORDERS}`,
        component: (
          <>
            <PageViewCounter page={ROUTER_IN_PROGRESS_ORDERS} />
            <MyOrdersRouter />
          </>
        ),
        additionalAction: () => trackAppEvent(TRACKING_EVENT_ORDERS_IN_PROGRESS),
        dataCy: 'button-order',
      },
    ),
  ];
};

const ProfilePage = (): JSX.Element => {
  const { t } = useTranslation();
  const userRights = useSelector(getUserRights);
  const navigationItems = getNavigationItems(t, getData(userRights) ?? []);

  return (
    <Container>
      <Flex>
        <Text type={'h0'} dataCy={'title-my-profile'}>
          {t('profile.my_profile', 'My profile')}
        </Text>
      </Flex>
      <LinkTabs items={navigationItems} />
    </Container>
  );
};

export default ProfilePage;
