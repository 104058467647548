import React from 'react';
import { useHistory } from 'react-router-dom';
import { CategoryCard, Level3Category } from 'components/Page/PageSideBar/PageSideBar.styled';
import { TreeType } from 'components/TreeMenu/TreeMenu';
import { formatCategoryLabelToDataCy } from 'domains/catalog/Catalog.utils';
import { Flex, Text } from 'UI';

interface SubCategoryCardProps {
  level2Label: string;
  level2NodeId: string;
  level1NodeId: string;
  items: TreeType[];
  setActiveCategory: (s: string) => void;
  baseUrl: string;
  disableLevel2Click?: boolean;
}

const SubCategoryCard = ({
  level2Label,
  level2NodeId,
  items,
  level1NodeId,
  setActiveCategory,
  baseUrl,
  disableLevel2Click,
}: SubCategoryCardProps) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);

  const handleRedirect = (level1: string, level2: string, level3?: string) => {
    if (!level3 && disableLevel2Click) return;
    const link = `${baseUrl}/${level1}/${level2}${level3 ? `/${level3}` : ''}`;
    const linkWithParams = `${link}?${urlParams}`;
    history.push(linkWithParams);
    setActiveCategory('');
  };

  return (
    <CategoryCard direction={'column'} gap={15} size={0}>
      <Text
        type={'h4_paragraph'}
        transform={'uppercase'}
        dataCy={formatCategoryLabelToDataCy(level2Label, 'subtab')}
        cursor={disableLevel2Click ? 'initial' : 'pointer'}
        onClick={() => handleRedirect(level1NodeId, level2NodeId)}
      >
        {level2Label}
      </Text>
      <Flex direction={'column'} gap={2} wrap={'wrap'}>
        {items.map((level3) => (
          <Level3Category
            key={level3.nodeId}
            align={'center'}
            onClick={() => handleRedirect(level1NodeId, level2NodeId, level3.nodeId)}
          >
            <Text
              type={'text_dim'}
              disableGutter
              transform={'capitalize'}
              dataCy={formatCategoryLabelToDataCy(level3.label, 'subtab')}
            >
              {level3.label}
            </Text>
          </Level3Category>
        ))}
      </Flex>
    </CategoryCard>
  );
};

export default SubCategoryCard;
