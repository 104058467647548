import styled from 'styled-components';

type LoaderWrapperProps = {
  height: string;
};

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  height: ${(props) => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
