import { AuthConfig } from '@1po/1po-bff-fe-spec/generated/auth/response/AuthConfig';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  AUTH_NAMESPACE,
  AuthState,
  GET_AUTH_CONFIG_REQUEST_SAGA,
  GET_AUTH_CONFIG_RESPONSE_SAGA,
} from 'domains/auth/Auth.types';
import { FOUND, SEARCH_STATUS } from 'utils';

// Init state
const initialState: AuthState = {
  credentials: {
    searchStatus: undefined,
  },
};
// Saga actions
export const getAuthConfigRequestSaga = createAction(GET_AUTH_CONFIG_REQUEST_SAGA);
export const getAuthConfigResponseSaga = createAction<AuthConfig>(GET_AUTH_CONFIG_RESPONSE_SAGA);

// Slice
const slice = createSlice({
  name: AUTH_NAMESPACE,
  initialState,
  reducers: {
    setCredentialsSearchStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.credentials = {
        searchStatus: action.payload,
      };
    },
    setCredentials: (state, action: PayloadAction<AuthConfig>) => {
      state.credentials = {
        searchStatus: FOUND,
        data: action.payload,
      };
    },
  },
});
// Actions
export const { setCredentials, setCredentialsSearchStatus } = slice.actions;

// Getters/Selectors
export const getCredentials = createSelector(
  (state: RootState) => state.auth.credentials?.data,
  (data) => data,
);

export const getCredentialsSearchState = createSelector(
  (state: RootState) => state.auth.credentials?.searchStatus,
  (searchStatus) => searchStatus,
);

// Export reducer
export default slice.reducer;
