import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { toCreatePromotionCopyModel, toCreatePromotionModel } from 'domains/promotion/Promotion.mapper';
import { getFullPromotion, setCreatePromotion, validateProductsRequestSaga } from 'domains/promotion/Promotion.store';
import { hasData } from 'utils';

export function useSetCreatePromoModel(promotionId: string) {
  const dispatch = useDispatch();
  const existingPromotion = useSelector((state: RootState) => getFullPromotion(state, promotionId));

  useEffect(() => {
    if (!hasData(existingPromotion)) {
      return;
    }
    const createPromotionModel = toCreatePromotionModel({
      promotion: existingPromotion,
      isPublished: existingPromotion.status === 'PUBLISHED',
    });

    dispatch(setCreatePromotion(createPromotionModel));
    dispatch(validateProductsRequestSaga({ promotion: createPromotionModel }));
  }, [dispatch, existingPromotion]);
}

export function useSetCopyPromoModel(promotionId: string) {
  const dispatch = useDispatch();
  const existingPromotion = useSelector((state: RootState) => getFullPromotion(state, promotionId));
  useEffect(() => {
    if (!hasData(existingPromotion)) {
      return;
    }
    const promotion = toCreatePromotionCopyModel({ promotion: existingPromotion });
    dispatch(setCreatePromotion(promotion));
    dispatch(validateProductsRequestSaga({ promotion }));
  }, [dispatch, existingPromotion]);
}
