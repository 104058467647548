import styled from 'styled-components';
import { effect } from 'styles/effect';
import { Box } from 'UI';

export const RoutineVehicleMaintenanceItemContainer = styled.div<{
  offsetX: number;
  offsetY: number;
  rotation?: number;
}>`
  position: absolute;
  margin-top: ${({ offsetY }) => `${offsetY}px`};
  margin-left: ${({ offsetX }) => `${offsetX}px`};
  cursor: pointer;
  z-index: 10;
  transform: rotate(${({ rotation }) => `${rotation}deg`});

  ${effect.hover.enlarge}
`;

export const RoutineVehicleMaintenanceBackgroundImage = styled(Box)`
  position: absolute;
  top: 70px;
  opacity: 0.5;
`;
