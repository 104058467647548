import { Table } from 'antd';
import styled from 'styled-components';

export const STable = styled(Table)`
  flex: 1;

  .ant-table-tbody > tr > td {
    padding: 10px 10px;
  }

  thead > tr > th {
    text-align: center;
    white-space: nowrap;
  }

  tbody > tr {
    :hover {
      box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
    }
  }

  tbody > tr.ant-table-row.ant-table-row-level-1 {
    background: ${({ theme }) => theme.color.grey95};

    td.ant-table-cell {
      background: inherit;
      transition: none;
    }

    :hover {
      box-shadow: none;
    }

    td:hover {
      background: inherit;
    }
  }

  tbody > tr.ant-table-row.ant-table-row-level-1 td:first-child {
    .tableDivider {
      background: ${({ theme }) => theme.color.grey85};
      position: absolute;
      width: 1px;
      height: 125%;
      left: 19px;
      margin-top: -21%;
      overflow: hidden;
    }
  }
` as typeof Table;

export const IconWrapper = styled.div`
  display: flex;
  flex: 0;
`;
