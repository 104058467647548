import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTER_CART } from 'app/AppRouter';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { setFileReferencesUploadStatusIsModalOpen } from 'domains/basket/Basket.store';
import { getUserCommercialLink } from 'domains/user';
import { handlePrintList } from 'pages/CartPage/CartPdf/pdfPrint';
import { useCartPdfData } from 'pages/CartPage/CartPdf/useCartPdfData';
import CartAddReference from 'pages/CartPage/CartStep/CartAddReference';
import FileReferencesUpload from 'pages/CartPage/CartStep/FileUpload';
import { Flex, MarginBox, PrintButtonRound, Text, UploadButtonRound } from 'UI';
import { notifyTop } from 'UI/Notification/notification';

interface CartHeaderProps {
  setAddedByReferenceBox: (referenceNumber: string | undefined) => void;
  isCartEmpty: boolean;
}

const CartHeader = ({ setAddedByReferenceBox, isCartEmpty }: CartHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const commercialLink = useSelector(getUserCommercialLink);
  const pdfData = useCartPdfData();

  function openModal() {
    if (commercialLink === null) {
      notifyTop('error', t('cart.file_upload.seller_is_missing', 'Seller is missing, contact your R1.'), null);
    } else {
      dispatch(setFileReferencesUploadStatusIsModalOpen(true));
    }
  }

  return (
    <Flex align={'center'}>
      <FileReferencesUpload />
      <Text type={'h1'} dataCy={'cart-title'}>
        {t('cart', 'Cart')}
      </Text>
      <Flex />
      {!isCartEmpty && <CartAddReference setAddedByReferenceBox={setAddedByReferenceBox} />}
      <MarginBox ml={15} />
      <PrintButtonRound
        onClick={() => {
          pdfData && handlePrintList(pdfData);
        }}
      />
      <MarginBox ml={5} />
      <FirstHelpPopin streamId={ROUTER_CART} popinId={`${ROUTER_CART}_file_import`} placement={'bottomLeft'}>
        <UploadButtonRound
          onClick={openModal}
          size={22}
          boxSize={'md'}
          tooltip={t('cart.file_upload.upload_reference_list', 'Upload references list')}
        />
      </FirstHelpPopin>
    </Flex>
  );
};

export default CartHeader;
