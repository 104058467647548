import React from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { CardTitle, OrderDetailCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { Flex, MarginBox, Pipeline, Text } from 'UI';

export const BillingAddressCard = ({ address }: { address: Address | undefined }) => {
  const { t } = useTranslation();
  return (
    <OrderDetailCard maxHeight data-cy={'section-billing-address'}>
      <CardTitle>
        <Text type={'h6'}>{t('my_orders.details.billing_address', 'Billing address')}</Text>
      </CardTitle>
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.address', 'Address')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{address?.address1 ?? ''}</Text>
          </Flex>
        </Flex>
      </MarginBox>
      <Pipeline size={'100%'} horizontal />
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.area_code', 'Area code')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{address?.zipCode ?? ''}</Text>
          </Flex>
        </Flex>
      </MarginBox>
      <Pipeline size={'100%'} horizontal />
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.city', 'City')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{address?.city ?? ''}</Text>
          </Flex>
        </Flex>
      </MarginBox>
      <Pipeline size={'100%'} horizontal />
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.country', 'Country')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{address?.countryCode ?? ''}</Text>
          </Flex>
        </Flex>
      </MarginBox>
    </OrderDetailCard>
  );
};
