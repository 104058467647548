import React, { ReactNode } from 'react';
import { HatIcon } from 'assets/icons';
import { Flex, Icon, MarginBox } from 'UI';
import { useBreakpointSelectorFull, useLarge } from 'utils';

const RightBannerSide = ({ children }: { children: ReactNode }) => {
  const large = useLarge();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const showHatIcon = breakpointSelectorFull({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: true,
  });

  return (
    <>
      {showHatIcon && (
        <MarginBox mb={-55}>
          <Icon IconComponent={HatIcon} width={110} height={22.5} />
        </MarginBox>
      )}
      <Flex justify={'flex-end'}>
        <MarginBox mr={large ? 30 : 10}>{children}</MarginBox>
      </Flex>
    </>
  );
};
export default RightBannerSide;
