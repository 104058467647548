import styled from 'styled-components';
import { defaultBoxShadow, Flex } from 'UI';

export const TireReferencesPopupContainer = styled(Flex)<{ marginTop?: number }>`
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: ${({ theme }) => theme.color.white};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  ${defaultBoxShadow};
`;
