import { Moment } from 'moment';
import { APP_NAMESPACE } from 'app/App.constants';
import {
  ROUTER_CART,
  ROUTER_CATALOG_DH,
  ROUTER_CATALOG_DH_L1,
  ROUTER_CATALOG_DH_L3,
  ROUTER_HOME,
  ROUTER_IN_PROGRESS_ORDERS,
} from 'app/AppRouter';

export const FIRST_HELP_NAMESPACE = `${APP_NAMESPACE}/FIRST_HELP`;

export const PAGE_VIEWS_LOCAL = 'page_views_local';
export const SEEN_POPINS_LOCAL = 'seen_popins_local';
export const SEEN_HINTS_LOCAL = 'seen_hints_local';

export interface FirstHelpState {
  session: Session;
  pageViews: Map<PageType, number>;
  streams: Streams;
  popins: Popins;
  showFirstHelp: ShowFirstHelpType;
  streamViewTimeStamp: Moment | undefined;
  currentPopinsViewed: Set<string>;
}

interface ShowFirstHelpType {
  showStreamId: PageType | undefined;
  showIndex: number;
}

export type Streams = Map<string, string[][]>;

interface Session {
  id: string;
  visitedStreamIds: Set<string>;
  hintSeenPages: Set<string>;
}

export type Popins = Map<string, PopinData>;

export interface PopinData {
  seen: boolean;
  disabled: boolean;
  title: string;
  description: string;
}

export type PageType =
  | typeof ROUTER_HOME
  | typeof ROUTER_CART
  | typeof ROUTER_IN_PROGRESS_ORDERS
  | typeof ROUTER_CATALOG_DH_L1
  | typeof ROUTER_CATALOG_DH_L3
  | typeof ROUTER_CATALOG_DH
  | 'close';
