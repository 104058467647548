import React from 'react';
import { ProductSection } from 'pages/CatalogPage/DH/Product/ProductSection';
import { Container, defaultPadding } from 'UI';

const ProductPage = () => {
  return (
    <Container padding={defaultPadding} alignText={'left'} size={'xxl'}>
      <ProductSection />
    </Container>
  );
};

export default ProductPage;
