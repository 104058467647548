import moment from 'moment/moment';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import { textFormatter } from 'utils';
import { getMomentDate, subtractDayFromMoment } from 'utils/date';

const getFormattedDate = (date: string | undefined) => textFormatter.formatDateShort(moment(date).toDate());

const getPublicationEnd = (endDate: string | undefined) =>
  getFormattedDate(subtractDayFromMoment(getMomentDate(endDate))?.toISOString());

export interface DiscountPromotionPublicationTableData {
  key: string;
  title: string;
  discountType: string;
  period: {
    publicationStart: string;
    publicationEnd: string;
  };
  commercialFamilyCodes: { key: string; items: string; discountCommercialFamily: number }[];
  referencesFiles: { key: string; fileName: string; discountReferences: number }[];
  targetedAudience: {
    dealerTypes: string;
    fileName: string;
  };
}

export interface FlashPromotionPublicationTableData {
  key: string;
  title: string;
  discountType: string;
  period: {
    publicationStart: string;
    publicationEnd: string;
  };
  commercialFamilyCodes: string;
  referencesTimeLimitedFileName: string;
  referenceQuantityLimited: string;
  discount: string | number;
  units?: string | number;
  targetedAudience: {
    dealerTypes: string;
    fileName: string;
  };
}

export interface BannerPromotionPublicationTableData {
  key: string;
  title: string;
  period: {
    publicationStart: string;
    publicationEnd: string;
  };
  commercialFamilyCodes: string;
  referencesTimeLimited: string;
  targetedAudience: {
    dealerTypes: string;
    fileName: string;
  };
}

function getClippedAdditionalDiscount(
  discount: number,
  additionalDiscount: number,
  showAdditionalDiscount: boolean,
): number {
  if (isNaN(additionalDiscount)) {
    return discount;
  }
  const additionalClippedDiscount = discount + additionalDiscount > 100 ? 100 : additionalDiscount + discount;
  return showAdditionalDiscount ? additionalClippedDiscount : discount;
}

export const getDiscountPromotionsData = (
  promotion: CreatePromotionModel,
  showAdditionalDiscount: boolean,
): DiscountPromotionPublicationTableData => {
  const dealerListFileName = promotion.uploadedTargetedAudienceFromFile.fileName ?? '';
  const dealerTypes = promotion.targetAudience.dealerTypes
    ? [...promotion.targetAudience.dealerTypes.map((dealer) => dealer.dealerType)]
        .sort((a, b) => a.localeCompare(b))
        .join(',')
    : '';
  const promotionTitle = promotion.title ?? '';
  return {
    key: promotion.promotionId,
    title: showAdditionalDiscount ? promotion.additionalInformation.title ?? promotionTitle : promotionTitle,
    discountType: promotion.discountType ?? '',
    period: {
      publicationStart: promotion.publicationStart ? getFormattedDate(promotion.publicationStart) : '',
      publicationEnd: promotion.publicationEnd ? getPublicationEnd(promotion.publicationEnd) : '',
    },
    commercialFamilyCodes:
      promotion?.commercialFamilyCodes?.map((section, idx) => {
        return {
          key: `cf_codes-section${idx}`,
          items: section.items.map((item) => item.item).join(', ') ?? '',
          discountCommercialFamily: getClippedAdditionalDiscount(
            section.discount,
            promotion?.additionalInformation?.discount ?? 0,
            showAdditionalDiscount,
          ),
        };
      }) ?? [],
    referencesFiles:
      promotion?.uploadedReferencesFromFiles?.map((section, idx) => {
        return {
          key: `references-section${idx}`,
          fileName: section.fileName ?? '',
          discountReferences: getClippedAdditionalDiscount(
            section.discount,
            promotion?.additionalInformation?.discount ?? 0,
            showAdditionalDiscount,
          ),
        };
      }) ?? [],
    targetedAudience: {
      dealerTypes: promotion.targetAudienceType === 'NETWORK' ? dealerTypes : '',
      fileName: promotion.targetAudienceType === 'DEALERS' ? dealerListFileName : '',
    },
  };
};

export const getFlashPromotionsData = (promotion: CreatePromotionModel): FlashPromotionPublicationTableData => {
  const dealerListFileName = promotion.uploadedTargetedAudienceFromFile.fileName ?? '';
  const dealerTypes = promotion.targetAudience.dealerTypes
    ? [...promotion.targetAudience.dealerTypes.map((dealer) => dealer.dealerType)]
        .sort((a, b) => a.localeCompare(b))
        .join(',')
    : '';
  const commercialFamilyCodes = promotion.commercialFamilyCodes ? promotion.commercialFamilyCodes[0].discount : '';
  return {
    key: promotion.promotionId,
    title: promotion.title ?? '',
    discountType: promotion.discountType ?? '',
    period: {
      publicationStart: promotion.publicationStart ? getFormattedDate(promotion.publicationStart) : '',
      publicationEnd: promotion.publicationEnd ? getPublicationEnd(promotion.publicationEnd) : '',
    },
    commercialFamilyCodes: promotion?.commercialFamilyCodes?.[0].items?.map((item) => item.item).join(', ') ?? '',
    referencesTimeLimitedFileName: promotion?.uploadedReferencesFromFiles?.[0].fileName ?? '',
    referenceQuantityLimited:
      promotion.references && promotion.references.length > 0 && promotion.references[0].items.length > 0
        ? promotion.references[0].items[0].item
        : '',
    discount:
      promotion.references && promotion.references.length > 0
        ? promotion.references[0].discount
        : commercialFamilyCodes,
    units: promotion.quantityLimitFlashPromotion ?? '',
    targetedAudience: {
      dealerTypes: promotion.targetAudienceType === 'NETWORK' ? dealerTypes : '',
      fileName: promotion.targetAudienceType === 'DEALERS' ? dealerListFileName : '',
    },
  };
};

export const getBannerPromotionsData = (promotion: CreatePromotionModel): BannerPromotionPublicationTableData => {
  const dealerListFileName = promotion.uploadedTargetedAudienceFromFile.fileName ?? '';
  const dealerTypes = promotion.targetAudience.dealerTypes
    ? [...promotion.targetAudience.dealerTypes.map((dealer) => dealer.dealerType)]
        .sort((a, b) => a.localeCompare(b))
        .join(',')
    : '';
  return {
    key: promotion.promotionId,
    title: promotion.title ?? '',
    period: {
      publicationStart: promotion.publicationStart ? getFormattedDate(promotion.publicationStart) : '',
      publicationEnd: promotion.publicationEnd ? getPublicationEnd(promotion.publicationEnd) : '',
    },
    commercialFamilyCodes: promotion.commercialFamilyCodes
      ? promotion.commercialFamilyCodes[0].items.map((item) => item.item).join(', ')
      : '',
    referencesTimeLimited: promotion.uploadedReferencesFromFiles
      ? promotion.uploadedReferencesFromFiles[0].fileName ?? ''
      : '',
    targetedAudience: {
      dealerTypes: promotion.targetAudienceType === 'NETWORK' ? dealerTypes : '',
      fileName: promotion.targetAudienceType === 'DEALERS' ? dealerListFileName : '',
    },
  };
};
