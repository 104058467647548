import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CogIcon, FileAltIcon, FolderOpenIcon, WrenchIcon } from 'assets/icons';
import PortfolioItem from 'pages/LandingPage/PortfolioSection/PortfolioItem';
import { Flex, Grid } from 'UI';
import { useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';
import { PortfolioWrapper } from './PortfolioSection.styled';

const infoContents = (t: TFunction) => [
  {
    icon: CogIcon,
    title: t('section.landing.portfolio.car_parts', 'Car parts'),
    text: (
      <Trans i18nKey={'section.landing.portfolio.car_parts.description'}>
        Browse our large selection of parts based on your vehicle VIN or VRN, brand and description. Find original,
        Motrio or Discounted car parts, equipment and accessories for Renault, Dacia and other brands.
      </Trans>
    ),
  },
  {
    icon: FolderOpenIcon,
    title: t('section.landing.portfolio.documentation', 'Documentation'),
    text: t(
      'section.landing.portfolio.documentation.description',
      'Access to professionals documentation, Repair methods and Diagnostic Methods to ease your workflow.',
    ),
  },
  {
    icon: WrenchIcon,
    title: t('catalog.parts.category.maintenance', 'Maintenance'),
    text: t(
      'section.landing.portfolio.maintenance.description',
      'Identify a vehicle and access to specific maintenance recommendation to fix your car.',
    ),
  },
  {
    icon: FileAltIcon,
    title: t('section.landing.portfolio.estimate', 'Estimate'),
    text: (
      <Trans i18nKey={'section.landing.portfolio.estimate.description'}>
        Get an Instant estimate, add labor time and parts required to fix your customers car. Our estimates show the
        full price breakdown, and personalized discounts.
      </Trans>
    ),
  },
];

const PortfolioSection = (): JSX.Element => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  return (
    <Flex justify={'space-evenly'}>
      <PortfolioWrapper id={'portfolio-section'}>
        <Grid
          columns={breakpointSelectorFull({
            xs: 1,
            sm: 1,
            md: 2,
            lg: 4,
            xl: 4,
            xxl: 4,
          })}
          gutter={22}
        >
          {Object.values(infoContents(t)).map(({ icon, title, text }) => (
            <PortfolioItem key={title} InfoIcon={icon} infoTitle={title} infoText={text} />
          ))}
        </Grid>
      </PortfolioWrapper>
    </Flex>
  );
};
export default PortfolioSection;
