import { isEmpty } from 'fast-glob/out/utils/string';
import { Language } from 'utils/i18n/Language';

class FormattingService {
  static EMPTY = '';
  private _locale: string | string[];

  constructor() {
    this._locale = Language.DEFAULT.locale;
  }

  getLocale(): string | string[] {
    return this._locale;
  }

  setLocale(locale: string | string[]): FormattingService {
    this._locale = locale;
    return this;
  }

  formatDateShort(value: Date): string {
    return this.formatDate(value, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  formatDateNumeric(value: Date): string {
    return this.formatDate(value, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  formatDateLong(value: Date): string {
    return this.formatDate(value, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  formatDateLongNoYear(value: Date): string {
    return this.formatDate(value, {
      month: 'long',
      day: 'numeric',
    });
  }

  formatDateMonth(value: Date): string {
    return this.formatDate(value, {
      year: 'numeric',
      month: 'numeric',
    });
  }

  formatDateHourMinute(value: Date): string {
    return this.formatDate(value, {
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  formatLocaleTimeString(value: Date): string {
    return this.formatDate(value, {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    });
  }

  formatDateRelative(value: number, unit: 'hour' | 'day'): string {
    if (!value) {
      return FormattingService.EMPTY;
    }
    return new Intl.RelativeTimeFormat('en', {
      localeMatcher: 'best fit', // other values: "lookup"
      numeric: 'always', // other values: "auto"
      style: 'long', // other values: "short" or "narrow"
    }).format(value, unit);
  }

  formatDate(value: Date, options?: Intl.DateTimeFormatOptions): string {
    if (!value) {
      return FormattingService.EMPTY;
    }
    return new Intl.DateTimeFormat(this._locale, options).format(value);
  }

  formatNumber(value: number, maximumFractionDigits?: number, minimumFractionDigits?: number): string {
    if (isNaN(value)) {
      return FormattingService.EMPTY;
    }
    const number = new Intl.NumberFormat(this._locale, {
      style: 'decimal',
      currencyDisplay: 'symbol', // localized symbol
      maximumFractionDigits: maximumFractionDigits ?? 2,
      minimumFractionDigits: minimumFractionDigits ?? 0,
    }).format(value);
    return replaceNoBreakSpace(number);
  }

  getThousandSeparator(): string {
    return Intl.NumberFormat(this._locale)
      .format(11111)
      .replace(/\p{Number}/gu, '');
  }

  getDecimalSeparator(): string {
    return Intl.NumberFormat(this._locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '');
  }

  parseLocaleNumber(stringNumber: string): number {
    const thousandSeparator = this.getThousandSeparator();
    const decimalSeparator = this.getDecimalSeparator();
    const normalized = stringNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
      .replace(new RegExp(/[^\d.]/g), '');

    return parseFloat(normalized);
  }

  formatCurrency(value: number | string, currency?: string): string {
    if (Number.isNaN(value) || !currency || isEmpty(currency)) {
      return FormattingService.EMPTY;
    }
    const number = Intl.NumberFormat(this._locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      useGrouping: true,
    }).format(Number(value));
    return replaceNoBreakSpace(number);
  }

  formatPercent(value: number | undefined): string {
    if (value === undefined) {
      return FormattingService.EMPTY;
    }
    const number = Intl.NumberFormat(this._locale, {
      style: 'percent',
    }).format(value);
    return replaceNoBreakSpace(number);
  }

  formatPercentDecimal(value: number | undefined): string {
    if (value === undefined) {
      return FormattingService.EMPTY;
    }
    return this.formatPercent(value / 100);
  }

  formatAmount(value: number, unit: 'hour' | 'day', display: 'short' | 'long' = 'short'): string {
    if (!value) {
      return FormattingService.EMPTY;
    }
    const number = Intl.NumberFormat(this._locale, {
      style: 'unit',
      unit,
      unitDisplay: display,
    }).format(value);
    return replaceNoBreakSpace(number);
  }

  getCurrencySymbol(currency: string): string {
    return new Intl.NumberFormat(this._locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(1)
      .replace(/\d/g, '')
      .trim();
  }

  getCurrencyPlacement(currency: string): 'before' | 'after' {
    return new Intl.NumberFormat(this._locale, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(1)
      .indexOf(this.getCurrencySymbol(currency)) === 0
      ? 'before'
      : 'after';
  }

  formatTextOverflow({
    src,
    maxWordLength = 25,
    maxLength = 100,
  }: {
    src?: string;
    maxWordLength?: number;
    maxLength?: number;
  }): any {
    if (src === undefined) return;
    const shorten = src
      .split(' ')
      .map((word) => (word.length > maxWordLength ? `${word.substring(0, maxWordLength - 3)}...` : word))
      .join(' ');
    return shorten.length > maxLength ? shorten.substring(0, maxLength) + '...' : shorten;
  }
}

function replaceNoBreakSpace(str: string): string {
  // Renault font do not support 'NARROW NO-BREAK SPACE'
  return str.replaceAll(String.fromCharCode(parseInt('202f', 16)), String.fromCharCode(parseInt('00A0', 16)));
}

export default FormattingService;
