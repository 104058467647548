import {
  ReferenceStock,
  WarehouseStockDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { isEmptyStock } from 'components/StockInfo/EmptyStock';
import { ReferenceStockStatus, WAREHOUSE_STOCKS_ORDER } from 'domains/references/References.types';
import { getData, SearchData } from 'utils';

export function stocksMapToArray(
  stockData: Map<string, SearchData<ReferenceStock> | undefined>,
): ReferenceStockStatus[] {
  return Array.from(stockData, (ref) => {
    const data: ReferenceStock | undefined = getData(ref?.[1])?.data;
    return {
      data: {
        ...data,
        reference: data?.reference ?? '',
        confirmedQuantity: data?.confirmedQuantity ?? 0,
        warehouses: data?.warehouses ?? [],
      },
      searchStatus: ref?.[1]?.searchStatus,
    };
  });
}

export function sortStocksBySearchStatus(a: ReferenceStockStatus, b: ReferenceStockStatus) {
  const isEmptyStockA = isEmptyStock(a);
  const isEmptyStockB = isEmptyStock(b);
  if (isEmptyStockA && isEmptyStockB) {
    return 0;
  } else if (isEmptyStockA) {
    return 1;
  } else if (isEmptyStockB) {
    return -1;
  }
  return 0;
}

export function sortStockWarehouses(a: WarehouseStockDetail, b: WarehouseStockDetail) {
  const whTypeA = WAREHOUSE_STOCKS_ORDER.indexOf(a.type);
  const whTypeB = WAREHOUSE_STOCKS_ORDER.indexOf(b.type);

  // sort by warehouse type, continue if both warehouses are unknown
  if (!(whTypeA === -1 && whTypeB === -1)) {
    if (whTypeA === -1 || whTypeA > whTypeB) {
      return -1;
    } else if (whTypeB === -1 || whTypeA < whTypeB) {
      return 1;
    }
  }

  // sort by delivery date
  const whDeliveryA = a.expectedDeliveryDate ?? '';
  const whDeliveryB = b.expectedDeliveryDate ?? '';

  if (whDeliveryA > whDeliveryB) {
    return 1;
  } else if (whDeliveryA < whDeliveryB) {
    return -1;
  }
  return 0;
}

export function sortStocksByWarehouse(a: ReferenceStockStatus, b: ReferenceStockStatus) {
  if ((!a.data && !b.data) || (a.data.warehouses.length === 0 && b.data.warehouses.length === 0)) {
    return 0;
  } else if (!a.data || a.data.warehouses.length === 0) {
    return 1;
  } else if (!b.data || b.data.warehouses.length === 0) {
    return -1;
  }

  // sort by first warehouse type
  const warehouseA = a.data.warehouses[0];
  const warehouseB = b.data.warehouses[0];
  const warehouseTypeA = WAREHOUSE_STOCKS_ORDER.indexOf(warehouseA.type);
  const warehouseTypeB = WAREHOUSE_STOCKS_ORDER.indexOf(warehouseB.type);

  // sort by warehouse type, continue if both warehouses are unknown
  if (!(warehouseTypeA === -1 && warehouseTypeB === -1)) {
    if (warehouseTypeB === -1 || warehouseTypeA > warehouseTypeB) {
      return -1;
    } else if (warehouseTypeA === -1 || warehouseTypeA < warehouseTypeB) {
      return 1;
    }
  }

  // sort by delivery date
  const warehouseDeliveryA = warehouseA.expectedDeliveryDate ?? '';
  const warehouseDeliveryB = warehouseB.expectedDeliveryDate ?? '';

  if (warehouseDeliveryA > warehouseDeliveryB) {
    return 1;
  } else if (warehouseDeliveryA < warehouseDeliveryB) {
    return -1;
  }
  return 0;
}
