import styled from 'styled-components';
import { Flex } from 'UI';

export const MaintenanceCard = styled(Flex)<{ active: boolean; disabled?: boolean; padding?: number }>`
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  border: ${({ theme, active }) => `solid 2px ${active ? theme.color.info : theme.color.white}`};
  padding: ${({ padding }) => (typeof padding === 'number' ? `${padding}px` : padding)};

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  }
`;

export const StickCard = styled(Flex)`
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.color.white};
  padding: 5px 75px;
`;

export const SBadgeCheckD = styled.div`
  position: relative;
  left: 7px;
  top: 12px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: '#356';
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 0px;
  margin-right: -25px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.blue};
`;

export const BadgeFlag = styled.div<{ bgColor: string; color: string }>`
  width: 96px;
  height: 20px;
  padding: 0 6px 0 5px;
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
  color: ${({ color }) => color};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-right: 10px solid white;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
  }
`;

export const StickyBoxContainer = styled.div`
  position: relative;
  margin: 15px -30px 0px -30px;
`;
