import { call, put, PutEffect, select as reduxSagaSelect } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { putClientLogSaga } from 'domains/clientLog/ClientLog.saga';
import logger from 'utils/remoteLogger';

type GeneratorPutEffect<P extends AnyAction> = Generator<PutEffect<P>>;

export function* startSaga(action: AnyAction): GeneratorPutEffect<{ type: string }> {
  const { type } = action;
  yield put({ ...action, type: `${type}/START` });
}

export function* endSagaWithSuccess(
  action: AnyAction,
  response: unknown,
): GeneratorPutEffect<{ type: string; response: any }> {
  const { type } = action;
  yield put({ ...action, type: `${type}/SUCCESS`, response });
}

export function* endSagaWithError(action: AnyAction, error: unknown): GeneratorPutEffect<{ type: string; error: any }> {
  const { type } = action;
  yield put({ ...action, type: `${type}/ERROR`, error });
}

export function* select<T>(selector: (state: RootState) => T): Generator<any, T, T> {
  return yield reduxSagaSelect(selector);
}

export function sagaGuard<T extends (...args: any) => any>(sagaToRun: (action: ReturnType<T>) => SagaIterator) {
  return function* (action: ReturnType<T>): SagaIterator {
    try {
      yield call(sagaToRun, action);
    } catch (e) {
      yield call(putClientLogSaga, logger.error(`saga ${sagaToRun.name} failed with error: ${e}`));
      throw e;
    }
  };
}
