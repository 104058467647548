import React from 'react';
import { Switch } from 'react-router-dom';
import { ROUTER_PROMOTION } from 'app/AppRouter';
import PrivateRoute from 'composers/PrivateRoute';
import PromotionPage from 'pages/PromotionPage/index';
import PromotionDetailPage from 'pages/PromotionPage/PromotionDetailPage';

export const PromotionRouter = () => {
  return (
    <Switch>
      <PrivateRoute path={`${ROUTER_PROMOTION}/:promotionId`} render={() => <PromotionDetailPage />} />
      <PrivateRoute path={`${ROUTER_PROMOTION}`} render={() => <PromotionPage />} />
    </Switch>
  );
};
