/* eslint-disable max-len */
import { DeliveryLeadTimeResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import { DeliveryLeadTimeUpdateResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeUpdateResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import {
  sendGetDeliveryLeadTimeRequest,
  sendUpdateDeliveryLeadTimeRequest,
} from 'domains/deliveryLeadTime/DeliveryLeadTime.api';
import * as actions from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { setDeliveryLeadTime, setDeliveryLeadTimeUpdate } from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { sagaGuard } from 'utils';

function* fetchDeliveryLeadTimeSaga(): SagaIterator {
  yield put(sendGetDeliveryLeadTimeRequest());
}

function* updateDeliveryLeadTimeSaga({ payload }: ReturnType<typeof actions.updateDeliveryLeadTime>): SagaIterator {
  yield put(sendUpdateDeliveryLeadTimeRequest(payload));
}

function* fetchDeliveryLeadTimeResponseSaga(action: WsResponse<DeliveryLeadTimeResponse>): SagaIterator {
  yield put(setDeliveryLeadTime(action.payload));
}

function* fetchDeliveryLeadTimeResponseUpdateSaga(action: WsResponse<DeliveryLeadTimeUpdateResponse>): SagaIterator {
  yield put(setDeliveryLeadTimeUpdate(action.payload));
}

export function* DeliveryLeadTimeSagas(): SagaIterator {
  yield takeEvery(actions.fetchDeliveryLeadTime.type, sagaGuard(fetchDeliveryLeadTimeSaga));
  yield takeEvery(actions.updateDeliveryLeadTime.type, sagaGuard(updateDeliveryLeadTimeSaga));
  yield takeEvery(actions.fetchDeliveryLeadTimeResponse.type, sagaGuard(fetchDeliveryLeadTimeResponseSaga));
  yield takeEvery(actions.fetchDeliveryLeadTimeUpdateResponse.type, sagaGuard(fetchDeliveryLeadTimeResponseUpdateSaga));
}
