import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text } from 'UI';

const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text type={'h1_banner_light'}>{t('catalog.universal_products', 'Universal products')}</Text>
      <Flex>
        <Flex>
          <Text type={'text_dim'}>
            <Trans i18nKey={'catalog.universal_products.subtitle'}>
              All the products for your car in one place. On our online store you will find numerous car accessories:
              from care products for the interior and exterior of your car to numerous gadgets and accessories for
              travel and transport.line
            </Trans>
          </Text>
        </Flex>
        <Flex />
      </Flex>
    </>
  );
};

export default Header;
