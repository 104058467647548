import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { TimesCircleIcon } from 'assets/icons';
import { Dialog, Disclosure } from 'components/Dialog';
import { StockDisplayCustom } from 'components/StockInfo/StockDisplay';
import { STable } from 'pages/CartPage/CartStep/CartStockValidation/CardStockValidation.styled';
import { Flex, MarginBox, Text } from 'UI';

export interface ReferenceCardStockValidation extends ReferenceStock {
  name: string;
  quantity: number;
}

export interface CardStockValidationProps {
  referenceStock: ReferenceCardStockValidation[];
  disclosureStock: Disclosure;
  handleConfirm: () => void;
  handleCancel?: () => void;
}

const CardStockValidation = ({
  referenceStock,
  disclosureStock,
  handleConfirm,
  handleCancel,
}: CardStockValidationProps) => {
  const { t } = useTranslation();

  const dataColumns = () => [
    {
      title: null,
      dataIndex: 'product',
      key: 'product',
      render: function renderProductName(text: any, reference: ReferenceCardStockValidation) {
        return (
          <Flex maxWidth={160}>
            <Text cursor={'pointer'} type={'text_bold'} transform={'capitalize'}>
              {reference.name}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: null,
      dataIndex: 'referenceNumber',
      key: 'referenceNumber',
      render: function renderReferenceNumber(referenceNumber: string) {
        return (
          <Flex minWidth={120}>
            <Text type={'text_dim'}>
              {t('cart.dialog.referenceNumber.short', 'Ref: {{ referenceNumber }}', {
                referenceNumber,
              })}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: '',
      dataIndex: 'referenceNumber',
      key: 'stock',
      render: function renderStock(referenceNumber: string, reference: ReferenceCardStockValidation) {
        return (
          <Flex minWidth={200}>
            <StockDisplayCustom
              stock={reference}
              quantity={reference.quantity}
              narrow={false}
              referenceNumber={referenceNumber}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Dialog
      disclosure={disclosureStock}
      title={t('common.dialog.availability', 'Availability')}
      description={
        t(
          'cart.dialog.stock_availability_description',
          "Sorry, some reference's availability have changed since they have been\n added to your basket, delivery time might be affected",
        ) as string
      }
      icon={TimesCircleIcon}
      status={'error'}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      buttonsText={{
        cancel: t('cart.dialog.availability.back_to_basket', 'Back to basket'),
        confirm: t('cart.dialog.availability.confirm_order', 'Confirm order'),
      }}
      content={
        <>
          <MarginBox mt={40} />
          <STable<ReferenceCardStockValidation>
            showHeader={false}
            columns={dataColumns()}
            dataSource={referenceStock}
            pagination={false}
          />
        </>
      }
    />
  );
};

export default CardStockValidation;
