import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import { ExclamationCircleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import QuantityModule from 'components/QuantityModule';
import StockDisplay from 'components/StockInfo';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import {
  addOtherSectionReferenceRequest,
  getBasketOtherSection,
  getBasketVehicles,
  updateReferenceQuantityRequest,
} from 'domains/basket/Basket.store';
import {
  addReferenceFromCatalogToCart,
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  removeCartReferenceInCatalog,
} from 'domains/catalog/Catalog.store';
import { getDHReference, getIsStockAvailable, getPrice } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import { SImageBox, SListItem } from 'pages/CatalogPage/DH/Product/Product.styled';
import {
  getProductLink,
  hasPrice,
  PriceField,
  renderPromoCorner,
} from 'pages/CatalogPage/DH/Product/ProductMenu/ProductMenuList';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { theme } from 'styles';
import { BlackButton, Flex, Icon, MarginBox, Scale, Text, ThumbsUpRoundButton } from 'UI';
import { getData, hasData } from 'utils';

const LinkWrapper = styled.div`
  :hover {
    cursor: pointer;
  }
`;

interface ReferenceCardProps {
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  isBestSupersession?: boolean;
  plateId?: string;
  index?: number;
}

const ReferenceCard = ({ referenceNumber, sparePartsView, isBestSupersession, index, plateId }: ReferenceCardProps) => {
  const { query } = useParams<{
    query: string;
  }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicle = useSelector(getLastVehicleDetail);
  const vehicleBrand = useSelector(getLastVehicleDetail)?.vehicleBrand ?? 'OTHER';
  const price = getData(useSelector((state: RootState) => getPrice(state, referenceNumber)));
  const reference = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));
  const basketVehicles = useSelector(getBasketVehicles);
  const basketOtherSection = useSelector(getBasketOtherSection);
  const referencesOfCurrentVeh = basketVehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)
    ?.references;

  const isApplicableOrInCurrVehCart = () => {
    if (vehicleKey) {
      return (
        getData(reference)?.isApplicableToCurrentVehicle ||
        referencesOfCurrentVeh?.find((ref) => ref.referenceNumber === referenceNumber)
      );
    } else {
      return false;
    }
  };
  const getBasketReferences = () =>
    isApplicableOrInCurrVehCart() ? referencesOfCurrentVeh : basketOtherSection?.references;
  const basketReferences = getBasketReferences();
  const refQuantity = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber)?.quantity as number;
  const isInBasket = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber);

  function quantityCallback(quantity: number | string) {
    if (quantity === 0) {
      dispatch(
        removeCartReferenceInCatalog({
          basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
          vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
          referenceNumber,
          referenceType: getData(reference)?.type ?? 'STANDARD',
          origin: undefined,
          supplierCode: undefined,
          referenceSource: 'STANDARD',
        }),
      );
    } else {
      dispatch(
        updateReferenceQuantityRequest({
          basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
          vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
          externalBasketId: undefined,
          referenceNumber,
          newQuantity: quantity as number,
          referenceType: getData(reference)?.type ?? 'STANDARD',
        }),
      );
    }
  }

  const handleAddToCart = () => {
    if (price?.garageView?.vatExcludedPrice && price.garageView?.vatIncludedPrice) {
      if (vehicleKey && vehicle && getData(reference)?.isApplicableToCurrentVehicle) {
        dispatch(addReferenceFromCatalogToCart({ referenceNumber, index, plateId }));
      } else {
        dispatch(
          addOtherSectionReferenceRequest({
            reference: getData(reference),
          }),
        );
      }
    }
  };

  return (
    <DataContainer data={reference}>
      {hasData(reference) && (
        <LinkWrapper
          onClick={() => {
            history.push(getProductLink(query, referenceNumber));
          }}
        >
          <SListItem>
            {renderPromoCorner(reference.referenceNumber, sparePartsView)}
            <Flex align={'center'} gap={20}>
              <SImageBox>
                <BrandImage referenceBrand={reference.brand} vehicleBrand={vehicleBrand} size={90} />
              </SImageBox>
              <Flex direction={'column'} align={'flex-start'}>
                <Flex gap={10}>
                  <Text type={'h4_paragraph'} disableGutter>
                    {t('cart.dialog.referenceNumber.short', 'Ref: {{ referenceNumber }}', {
                      referenceNumber: reference.referenceNumber,
                    })}
                  </Text>
                  {isBestSupersession && <ThumbsUpRoundButton />}
                </Flex>
                <Scale value={0.8} origin={'left'}>
                  <StockDisplay vehicleKey={vehicleKey} referenceNumber={reference.referenceNumber} />
                </Scale>
                {availableInUserCountry ? (
                  <>
                    {hasPrice(price, sparePartsView) && (
                      <PriceField
                        inline
                        price={price}
                        sparePartsView={sparePartsView}
                        referenceNumber={reference.referenceNumber}
                      />
                    )}
                  </>
                ) : (
                  <Flex justify={'flex-end'} align={'center'}>
                    <Icon size={18} IconComponent={ExclamationCircleIcon} color={theme.color.grey70} />
                    <MarginBox ml={3} />
                    <Text type={'light_12_black_45'}>
                      {t('catalog.reference.unavailable', 'Looks like this item is not available in your country.')}
                    </Text>
                  </Flex>
                )}
              </Flex>
              <StopPropagation>
                {isInBasket ? (
                  <QuantityModule value={refQuantity} onChange={quantityCallback} showDelete />
                ) : (
                  <BlackButton
                    onClick={handleAddToCart}
                    disabled={
                      !availableInUserCountry ||
                      !(price?.garageView?.vatExcludedPrice && price.garageView?.vatIncludedPrice)
                    }
                  >
                    {t('cart.action.add_to_cart', 'Add to cart')}
                  </BlackButton>
                )}
              </StopPropagation>
              <MarginBox ml={10} />
            </Flex>
          </SListItem>
        </LinkWrapper>
      )}
    </DataContainer>
  );
};

export default ReferenceCard;
