import {
  GET_IAM_TIRES,
  GET_TIRE_BRAND_CATEGORIES,
  TIRE_FULLTEXT_SEARCH,
  TIRE_PRODUCTS_SEARCH,
  TIRE_REPLACEMENT_PRODUCTS_SEARCH,
  TIRE_UNIQUE_DIMENSION_SEARCH,
  GET_MPR_VALIDATION,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { GetIAMTiresRequest } from '@1po/1po-bff-fe-spec/generated/tire/iam/request/GetIAMTiresRequest';
import { MprValidationRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/MprValidationRequest';
import { TireBrandCategoriesRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/TireBrandCategoriesRequest';
import { TireFulltextSearchRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/TireFulltextSearchRequest';
import { TireReplacementProductsRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/TireReplacementProductsRequest';
import { TireSearchRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/TireSearchRequest';
import { TireUniqueDimensionsRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/TireUniqueDimensionsRequest';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendAction } from 'utils/domainStore/api';

export function searchTires(request: TireSearchRequest): WebSocketAction {
  return wsSendAction(TIRE_PRODUCTS_SEARCH, request);
}

export function sendIAMTiresRequest(request: GetIAMTiresRequest): WebSocketAction {
  return wsSendAction(GET_IAM_TIRES, request);
}

export function sendTiresFulltextSearchRequest(request: TireFulltextSearchRequest): WebSocketAction {
  return wsSendAction(TIRE_FULLTEXT_SEARCH, request);
}

export function sendTireUniqueDimensionsRequest(request: TireUniqueDimensionsRequest): WebSocketAction {
  return wsSendAction(TIRE_UNIQUE_DIMENSION_SEARCH, request);
}

export function sendTireBrandCategoriesRequest(request: TireBrandCategoriesRequest): WebSocketAction {
  return wsSendAction(GET_TIRE_BRAND_CATEGORIES, request);
}

export function sendTireReplacementProductsRequest(request: TireReplacementProductsRequest): WebSocketAction {
  return wsSendAction(TIRE_REPLACEMENT_PRODUCTS_SEARCH, request);
}

export function sendMprValidationRequest(request: MprValidationRequest): WebSocketAction {
  return wsSendAction(GET_MPR_VALIDATION, request);
}
