import React from 'react';
import { useTranslation } from 'react-i18next';
import { TirePumpIcon, TireSoundIcon, TireWeatherIcon } from 'assets/icons';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';

const TiresRatingIcons = ({
  availableInUserCountry,
  fuelConsumption,
  wetGrip,
  exteriorSound,
}: {
  availableInUserCountry: boolean;
  fuelConsumption: string | undefined;
  wetGrip: string | undefined;
  exteriorSound: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Flex direction={'row'} align={'flex-end'} maxHeight={30} wrap={'nowrap'}>
      {fuelConsumption && (
        <>
          <WithTooltip title={t('catalog.tires.reference_card.rolling_resistance_rating', 'Rolling resistance rating')}>
            <Flex size={0} wrap={'nowrap'}>
              <Icon IconComponent={TirePumpIcon} height={22} color={theme.color.grey35} noPointer />
              <Text
                type={availableInUserCountry ? 'light_14_black_65' : 'light_14_bold_black_45'}
                transform={'capitalize'}
                noWrap
              >
                {fuelConsumption}
              </Text>
            </Flex>
          </WithTooltip>
          <MarginBox mr={7} />
        </>
      )}
      {wetGrip && (
        <>
          <WithTooltip title={t('catalog.tires.reference_card.wet_grip_rating', 'Grip on wet ground rating')}>
            <Flex size={0} wrap={'nowrap'}>
              <Icon IconComponent={TireWeatherIcon} height={19} color={theme.color.grey35} noPointer />
              <Text
                type={availableInUserCountry ? 'light_14_black_65' : 'light_14_bold_black_45'}
                transform={'capitalize'}
                noWrap
              >
                {wetGrip}
              </Text>
            </Flex>
          </WithTooltip>
          <MarginBox mr={7} />
        </>
      )}
      {exteriorSound && (
        <>
          <WithTooltip title={t('catalog.tires.reference_card.noise_rating', 'Noise rating')}>
            <Flex size={0} wrap={'nowrap'}>
              <Icon IconComponent={TireSoundIcon} height={19} color={theme.color.grey35} noPointer />
              <Text type={availableInUserCountry ? 'light_14_black_65' : 'light_14_bold_black_45'} noWrap>
                {exteriorSound} {t('catalog.tires.reference_card.decibel', 'DB')}
              </Text>
            </Flex>
          </WithTooltip>
          <MarginBox mr={7} />
        </>
      )}
    </Flex>
  );
};

export default TiresRatingIcons;
