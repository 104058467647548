import { useMediaQuery } from '@react-hook/media-query';
import { useTheme } from 'styled-components';
import { ThemeBreakpointKeyType } from 'styles';

type breakpointMappingType = Record<ThemeBreakpointKeyType, number>;

export type BreakpointKeyType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
export const Breakpoints: breakpointMappingType = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 5,
};

export function useBreakpoint() {
  const {
    breakpoint: { sm, md, lg, xl, xxl },
  } = useTheme();
  const matches = [
    true,
    useMediaQuery(`(min-width: ${sm}px)`),
    useMediaQuery(`(min-width: ${md}px)`),
    useMediaQuery(`(min-width: ${lg}px)`),
    useMediaQuery(`(min-width: ${xl}px)`),
    useMediaQuery(`(min-width: ${xxl}px)`),
  ];
  return matches.reduce((acc, next, i) => (next ? i : acc), 0);
}

export function useSmall() {
  const match = useBreakpoint();
  return match <= Breakpoints.sm;
}

export function useLarge() {
  const match = useBreakpoint();
  return match >= Breakpoints.lg;
}

export function useExtraLarge() {
  const match = useBreakpoint();
  return match >= Breakpoints.xl;
}

export function useExtraExtraLarge() {
  const match = useBreakpoint();
  return match >= Breakpoints.xxl;
}

const BreakpointSelectorMapping = [Breakpoints.md, Breakpoints.lg];

export type BreakpointSelectable<T> = T | Array<T> | undefined;

export function useBreakpointSelector() {
  const match = useBreakpoint();
  const index = BreakpointSelectorMapping.reduce((acc, next, i) => (match >= next ? i : acc), 0);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  return <T extends unknown>(prop: BreakpointSelectable<T>): T | undefined =>
    Array.isArray(prop) ? prop[index] : prop;
}

export type BreakpointSelectableFull<T> = Record<ThemeBreakpointKeyType, T> | undefined;

export function useBreakpointSelectorFull() {
  const match = useBreakpoint();
  const index = (Object.keys(Breakpoints) as Array<ThemeBreakpointKeyType>).reduce(
    (acc, next) => (match >= Breakpoints[next] ? next : acc),
    'xs',
  );
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  return <T extends unknown>(prop: BreakpointSelectableFull<T>): T | undefined => (prop ? prop[index] : undefined);
}
