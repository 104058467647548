/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlashPromotionPublicationTableData } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/PublicationStep.types';
import { STable } from 'pages/EstimatePage/EstimatePage.styled';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { Flex, Text, WithTooltip } from 'UI';

export const TableQuantityLimited = ({ data }: { data: FlashPromotionPublicationTableData }) => {
  const { t } = useTranslation();
  const getDataColumns = () => {
    return [
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.promotion_title', 'Promotion title')),
        dataIndex: 'title',
        render: function renderTitle(title: string) {
          return (
            <Flex maxWidth={150} justify={'center'} align={'center'}>
              <WithTooltip title={title}>
                <Text type={'text'} ellipsis>
                  {title}
                </Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.price_option', 'Price option')),
        dataIndex: 'discountType',
        render: function renderDiscountType(discountType: string) {
          const discountTypeText = t(
            'backoffice.promotion.publication_preview.discount_type',
            'Based on {{discountType}}',
            { discountType },
          );
          return (
            <Flex minWidth={120} justify={'center'} align={'center'}>
              <WithTooltip title={discountTypeText}>
                <Text type={'text'}>{discountTypeText}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: data.period.publicationEnd
          ? GetTitle(t('backoffice.promotion.publication_preview.period', 'Period'))
          : GetTitle(t('backoffice.promotion.publication_preview.starting_date', 'Starting date')),
        dataIndex: 'period',
        width: 90,
        render: function renderPeriod(period: { publicationStart: string; publicationEnd: string }) {
          const periodTooltipText = `${period.publicationStart} - ${period.publicationEnd}`;
          return (
            <Flex minWidth={90} direction={'column'} justify={'center'} align={'center'}>
              <WithTooltip title={periodTooltipText}>
                <Text type={'text'}>{period.publicationStart}</Text>
              </WithTooltip>
              <WithTooltip title={periodTooltipText}>
                <Text type={'text'}>{period.publicationEnd}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.references', 'References')),
        dataIndex: 'referenceQuantityLimited',
        render: function renderReferences(references: string) {
          return (
            <Flex justify={'center'} align={'center'}>
              <WithTooltip title={references}>
                <Text type={'text'}>{references}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.discount', 'Discount')),
        dataIndex: 'discount',
        render: function renderDiscount(discount: string) {
          const discountText = `${discount}%`;
          return (
            <Flex justify={'center'} align={'center'}>
              <WithTooltip title={discountText}>
                <Text type={'text'}>{discountText}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.units', 'Units')),
        dataIndex: 'units',
        render: function renderTarget(units: string) {
          return (
            <Flex justify={'center'} align={'center'}>
              <WithTooltip title={units}>
                <Text type={'text'}>{units}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.target', 'Target')),
        dataIndex: 'targetedAudience',
        render: function renderTarget(targetedAudience: { dealerTypes: string; fileName: string }) {
          const targetedAudienceToolTipTitle = `${targetedAudience.dealerTypes}\n${targetedAudience.fileName}`;
          return (
            <Flex direction={'column'} justify={'center'} align={'center'}>
              <WithTooltip title={targetedAudienceToolTipTitle}>
                <Text type={'text'}>{targetedAudience.dealerTypes}</Text>
              </WithTooltip>
              <WithTooltip title={targetedAudienceToolTipTitle}>
                <Text type={'text'}>{targetedAudience.fileName}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
    ];
  };

  if (!data) {
    return null;
  }
  return (
    <STable
      key={'promotions_flash_quantity_limited_table'}
      dataSource={[data]}
      columns={getDataColumns()}
      pagination={false}
    />
  );
};
