import React from 'react';
import { useTranslation } from 'react-i18next';
import { EngineOilLocal } from 'domains/catalog/Catalog.types';
import { MaintenanceDataProps } from 'pages/CatalogPage/DH/Maintenance/Maintenance';
import { FluidHeader, EngineTable, SFlex } from 'pages/CatalogPage/DH/Maintenance/Maintenance.styled';
import { DarkHalfCard } from 'pages/CatalogPage/IAM/CarDetailHeader/CarDetailHeader.styled';
import { Box, CenterFlex, Flex, Image, InfoAlert, MarginBox, Pipeline, Text, Space } from 'UI';

const Title = ({ children }: { children: string }) => (
  <Text type={'h6'} disableGutter>
    {children}
  </Text>
);

const getColumns = (standards: string[]) => {
  const standardsColumns = standards.map((standard) => {
    return {
      title: <Title>{standard}</Title>,
      key: standard,
      render: function renderCheck(row: EngineOilLocal) {
        const capacities = row.dataForTemp
          .filter((r) => r.standard === standard)
          .map((c) => c.engineCapacity)
          .join(' ');
        return <CenterFlex>{capacities}</CenterFlex>;
      },
    };
  });

  return [
    {
      title: <Title>{'°C'}</Title>,
      dataIndex: 'temp',
      key: 'temp',
      render: function renderTemperature(temperature: string) {
        return <CenterFlex>{`${temperature} °C`}</CenterFlex>;
      },
    },
    ...standardsColumns,
  ];
};

const FluidsSection = ({ maintenancePlanData, getLabel }: MaintenanceDataProps) => {
  if (
    maintenancePlanData.brakeFluid.length === 0 &&
    maintenancePlanData.coolantFluid.length === 0 &&
    maintenancePlanData.engineOilList.length === 0
  )
    return <></>;
  const hasEngineData = maintenancePlanData.engineOilList.length > 0;

  const getFluidHeader = (title: string) => {
    return (
      <FluidHeader>
        <CenterFlex>
          <Title>{title}</Title>
        </CenterFlex>
      </FluidHeader>
    );
  };

  const FluidInfoCard = ({ title, data }: { title: string; data: string[] }) => {
    if (data.length === 0) return <></>;
    return (
      <>
        {getFluidHeader(title)}
        {data.map((d, index) => {
          return (
            <div key={`${d}_${index}`}>
              <MarginBox my={15} ml={15}>
                <Text type={'text_dim'} disableGutter>
                  {d}
                </Text>
              </MarginBox>
              <Pipeline size={'100%'} horizontal />
            </div>
          );
        })}
      </>
    );
  };

  const EngineFluids = ({
    engineData,
    standards,
    averageCapacity,
  }: {
    engineData: EngineOilLocal[];
    standards: string[];
    averageCapacity: string[];
  }) => {
    const { t } = useTranslation();

    return (
      <>
        {getFluidHeader(getLabel(30))}
        <Flex>
          <SFlex direction={'column'}>
            <Space size={15} direction={'vertical'}>
              <MarginBox />
              <Text type={'heading_black_content'} disableGutter>{`${getLabel(32)}: ${standards.join(', ')}`}</Text>
              <Pipeline size={'100%'} horizontal />
              <Text type={'search_result_highlighted'}>{getLabel(36)}</Text>
              <Text type={'search_result_highlighted'}>{getLabel(38)}</Text>
              <EngineTable columns={getColumns(standards)} dataSource={engineData} pagination={false} rowKey={'temp'} />
            </Space>
          </SFlex>
          <SFlex direction={'column'}>
            <Space size={15} direction={'vertical'}>
              <MarginBox />
              <Text type={'heading_black_content'} disableGutter>{`${t(
                'catalog.maintenance.average_capacity',
                'Average capacity:',
              )} ${averageCapacity.join(', ')}`}</Text>
              <Pipeline size={'100%'} horizontal />
              <Text type={'search_result_highlighted'}>{getLabel(33)}</Text>
              <Image src={'catalog/castrol_logo.png'} alt={'castrol_logo'} />
            </Space>
          </SFlex>
        </Flex>
        <InfoAlert
          size={'lg'}
          message={
            <SFlex direction={'column'}>
              <Text type={'search_result_highlighted'}>{t('common.note', 'Note')}</Text>
              <Box height={10} />
              <Text type={'heading_black_content'}>{getLabel(39)}</Text>
            </SFlex>
          }
        />
      </>
    );
  };

  return (
    <>
      <DarkHalfCard>
        <Text type={'card_title_dark'}>{getLabel(29)}</Text>
      </DarkHalfCard>
      <Box height={30} />
      <Flex direction={'row'}>
        {hasEngineData && (
          <>
            <Flex size={5}>
              <Flex direction={'column'}>
                <EngineFluids
                  engineData={maintenancePlanData.engineOilList}
                  standards={maintenancePlanData.engineOilStandards}
                  averageCapacity={maintenancePlanData.averageCapacity}
                />
              </Flex>
            </Flex>
            <Box width={30} />
          </>
        )}
        <Flex size={4} direction={'column'}>
          <Space size={45} direction={'vertical'}>
            <FluidInfoCard title={getLabel(31)} data={maintenancePlanData.brakeFluid} />
            <FluidInfoCard title={getLabel(37)} data={maintenancePlanData.coolantFluid} />
          </Space>
        </Flex>
        {!hasEngineData && <Flex size={5} />}
      </Flex>
    </>
  );
};

export default FluidsSection;
