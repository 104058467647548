import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { CartListPdfPage } from 'pages/CartPage/CartPdf/CartPdf';
import { CartListPdfData } from 'pages/CartPage/CartPdf/CartPdf.types';

export const handlePrintList = async (pdfData: CartListPdfData) => {
  const blob = await pdf(<CartListPdfPage data={pdfData} />).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};
