import styled from 'styled-components';

export const Header = styled.div<{ type: 'main' | 'equivalent'; $isWide: boolean }>`
  background-color: ${({ type, theme }) => (type === 'main' ? theme.color.clear_blue : theme.color.grey90)};
  height: ${({ $isWide }) => ($isWide ? '70px' : '50px')};
  display: flex;
  padding: 12px 15px 0 15px;
  border-radius: 8px;

  :hover {
    ${({ type }) => type === 'equivalent' && 'cursor: pointer;'};
  }
`;

export const OpennerWrapper = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => !$isOpen && 'transform: rotate(-90deg);'};
  height: 20px;
`;
