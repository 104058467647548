import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle, OrderDetailCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { Flex, MarginBox, Pipeline, Text } from 'UI';

export const InformationCard = ({
  orderedBy,
  clientNumber,
  distributor,
  billingAccount,
}: {
  orderedBy: string | undefined;
  clientNumber: string | undefined;
  distributor: string | undefined;
  billingAccount: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <OrderDetailCard data-cy={'section-information'}>
      <CardTitle>
        <Text type={'h6'}>{t('my_orders.details.information', 'Information')}</Text>
      </CardTitle>
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('order.order_detail.ordered_by', 'Ordered by')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_85'}>{orderedBy}</Text>
          </Flex>
        </Flex>
      </MarginBox>
      <Pipeline size={'100%'} horizontal />
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.client_number', 'Client number')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{clientNumber}</Text>
          </Flex>
        </Flex>
      </MarginBox>
      <Pipeline size={'100%'} horizontal />
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.distributor', 'Distributor')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{distributor}</Text>
          </Flex>
        </Flex>
      </MarginBox>
      <Pipeline size={'100%'} horizontal />
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.billing_account', 'Billing account')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{billingAccount}</Text>
          </Flex>
        </Flex>
      </MarginBox>
    </OrderDetailCard>
  );
};
