import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CENTRAL_DELIVERY, COUNTRY_DELIVERY, REGION_DELIVERY } from 'domains/deliveryLeadTime/DeliveryLeadTime.types';
import WarehouseDelivery from 'pages/BackOfficePage/BackOfficeCategories/DeliveryLeadTime/WarehouseDelivery';
import { WideTabs } from 'UI';
import { notifyTop } from 'UI/Notification/notification';

const DeliveryLeadTime = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [optionsSaved, setOptionsSaved] = useState(false);
  const [optionInvalid, setOptionInvalid] = useState(false);

  useEffect(() => {
    if (optionsSaved) {
      notifyTop(
        'success',
        t(
          'backoffice.delivery_lead_time.notifications.updated',
          'The configuration of the delivery times has been taken into account.',
        ),
      );
      setOptionsSaved(false);
    }
  }, [history, optionsSaved, t]);

  useEffect(() => {
    if (optionInvalid) {
      notifyTop(
        'error',
        t(
          'backoffice.delivery_lead_time.notifications.invalid_times',
          'Wrong configuration, please fill in both fields.',
        ),
      );
      setOptionInvalid(false);
    }
  }, [history, optionInvalid, t]);

  const tabData = [
    {
      title: t('backoffice.delivery_lead_time.central.tabname', 'Configure central delivery'),
      key: 'central',
      component: (
        <WarehouseDelivery
          setOptionsSaved={setOptionsSaved}
          setOptionInvalid={setOptionInvalid}
          title={t('backoffice.delivery_lead_time.central.title', 'Central delivery options')}
          warehouse={CENTRAL_DELIVERY}
          key={'central'}
        />
      ),
    },
    {
      title: t('backoffice.delivery_lead_time.country.tabName', 'Configure country delivery'),
      key: 'country',
      component: (
        <WarehouseDelivery
          setOptionsSaved={setOptionsSaved}
          setOptionInvalid={setOptionInvalid}
          title={t('backoffice.delivery_lead_time.country.title', 'Country delivery options')}
          warehouse={COUNTRY_DELIVERY}
          allowDisableSwitch
          isDisabledByDefault={true}
          key={'country'}
        />
      ),
    },
    {
      title: t('backoffice.delivery_lead_time.regional.tabName', 'Configure regional delivery'),
      key: 'regional',
      component: (
        <WarehouseDelivery
          setOptionsSaved={setOptionsSaved}
          setOptionInvalid={setOptionInvalid}
          title={t('backoffice.delivery_lead_time.regional.title', 'Regional delivery options')}
          warehouse={REGION_DELIVERY}
          allowDisableSwitch
          isDisabledByDefault={true}
          key={'regional'}
        />
      ),
    },
  ];

  return <WideTabs tabData={tabData} />;
};

export default DeliveryLeadTime;
