import { Table } from 'antd';
import styled from 'styled-components';
import { AntdPopoverWrapper } from 'components/Popover/Popover';
import { BlackButton, WhiteButton } from 'UI';

export const STable = styled(Table)`
  flex: 1;

  .ant-table-tbody > tr > td {
    padding: 10px 10px;
  }

  thead > tr > th {
    text-align: center;
    white-space: nowrap;
  }

  tbody > tr {
    :hover {
      box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
    }
  }

  tbody > tr.ant-table-row.ant-table-row-level-1 {
    background: ${({ theme }) => theme.color.grey95};

    td.ant-table-cell {
      background: inherit;
      transition: none;
    }

    :hover {
      box-shadow: none;
    }

    td:hover {
      background: inherit;
    }
  }

  tbody > tr.ant-table-row.ant-table-row-level-1 td:first-child {
    .tableDivider {
      background: ${({ theme }) => theme.color.grey85};
      position: absolute;
      width: 1px;
      height: 125%;
      left: 19px;
      margin-top: -21%;
      overflow: hidden;
    }
  }
` as typeof Table;

export const SDialogContent = styled.div`
  border: solid 1px ${({ theme }) => theme.color.grey85};
  min-width: 650px;
  margin-top: 30px;
`;

export const SDialogContentRow = styled.div`
  padding: 15px 30px 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex: 0;
`;

export const ZPopover = styled(AntdPopoverWrapper)`
  z-index: 9999;
`;

export const SLaborTimeFilterButton = styled(BlackButton)`
  padding: 0 20px 0 20px;
  margin-left: 15px;
  min-width: 79px;
  min-height: 46px;

  &:hover:not(.active) {
    background-color: ${({ theme }) => theme.color.grey95};
  }

  &.active {
    .labor-filter-text {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const SWhiteOutlinedButton = styled(WhiteButton)`
  padding: 0 20px 0 20px;
  margin-left: 15px;
  min-width: 79px;
  min-height: 46px;

  &:hover:not(.active) {
    background-color: ${({ theme }) => theme.color.grey95};
  }

  &.active {
    .labor-filter-text {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
