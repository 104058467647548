import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PreferredTabParam } from 'domains/catalog/Catalog.types';

type TabState = 'spare_parts' | 'labor_time' | 'repair_methods';

export const usePreferredTabHook = (
  tabState: TabState,
  setTabState: (tab: TabState) => void,
  disabledTabs: TabState[],
  getParamState: (tabState: TabState) => URLSearchParams,
) => {
  // eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const preferredTab = (params.get(PreferredTabParam) as TabState) || 'spare_parts';
  const history = useHistory();

  useEffect(() => {
    if (preferredTab !== tabState && !disabledTabs.some((n) => n === preferredTab)) {
      const uparam = getParamState(preferredTab);
      const newParams = uparam ? `?${uparam}` : '';
      history.replace(`${newParams}`);
      setTabState(preferredTab);
    }
  }, [preferredTab, setTabState, disabledTabs, history, tabState, getParamState]);
};
