/* eslint-disable max-len */
import { GetIAMAvailableModulesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/available_modules/requerst/GetIAMAvailableModulesRequest';
import { GetIAMAvailableModulesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/available_modules/response/GetIAMAvailableModulesResponse';
import { GetIAMRepairMethodDetailRequest } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/request/GetIAMRepairMethodDetailRequest';
import { GetIAMRepairMethodDetailResponse } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodDetailResponse';
import { GetIAMServiceChecklistRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceChecklistRequest';
import { GetIAMServiceOperationsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceOperationsRequest';
import { GetIAMServiceProposalOperationsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceProposalOperationsRequest';
import { GetIAMServiceProposalRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceProposalRequest';
import { GetIAMFamilyReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { GetIAMServiceChecklistResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceChecklistResponse';
import { GetIAMServiceOperationsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceOperationsResponse';
import { GetIAMServiceProposalOperationsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalOperationsResponse';
import { GetIAMServiceProposalResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalResponse';

import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { sendIAMServiceOperationsRequest } from 'domains/catalog/Catalog.api';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import {
  sendIAMAvailableModulesRequest,
  sendIAMRepairMethodDetailRequest,
  sendIAMServiceChecklistRequest,
  sendIAMServiceProposalOperationsRequest,
  sendIAMServiceProposalsRequest,
} from 'domains/maintenancePlan/MaintenancePlan.api';
import * as actions from 'domains/maintenancePlan/MaintenancePlan.store';
import {
  addSelectedReferences,
  getLastSearchServiceOperationsData,
  removeSelectedReferences,
  selectOperation,
  setAvailableModules,
  setAvailableModulesStatus,
  setFamilyReferences,
  setMileageInput,
  setOptionInput,
  setRegistrationDateInput,
  setRepairMethodDetails,
  setRepairMethodDetailsStatus,
  setSelectedOperations,
  setSelectedReferences,
  setServiceChecklist,
  setServiceChecklistStatus,
  setServiceOperationsStatus,
  setServiceOperationsTree,
  setServiceProposalOperations,
  setServiceProposalOperationsStatus,
  setServiceProposals,
  setServiceProposalsStatus,
  unselectOperation,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { getUserContext } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { LOADING, sagaGuard, select } from 'utils';

export function* fetchIAMServiceOperationsRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }
  if (vehicleDetail?.iamVehicle?.versionCode === undefined) {
    return;
  }

  const request: GetIAMServiceOperationsRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
    },
    userContext,
  };
  yield put(
    setServiceOperationsStatus({
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      status: LOADING,
    }),
  );
  yield put(sendIAMServiceOperationsRequest(request));
}

export function* fetchIAMServiceOperationsResponseGaga(
  action: WsResponse<GetIAMServiceOperationsResponse>,
): SagaIterator {
  yield put(setServiceOperationsTree(action.payload));
}

export function* fetchIAMServiceFamiliesResponseSaga(action: WsResponse<GetIAMFamilyReferencesResponse>): SagaIterator {
  yield put(setFamilyReferences(action.payload));
}

export function* fetchIAMServiceProposalsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMServiceProposalsRequestSaga>): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }

  if (vehicleDetail?.iamVehicle?.versionCode === undefined) {
    return;
  }

  const { registrationDate } = payload;
  const request: GetIAMServiceProposalRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
    },
    registrationDate,
    userContext,
  };
  yield put(
    setServiceProposalsStatus({
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      status: LOADING,
    }),
  );
  yield put(sendIAMServiceProposalsRequest(request));
}

export function* fetchIAMServiceProposalsResponseSaga(action: WsResponse<GetIAMServiceProposalResponse>): SagaIterator {
  yield put(setServiceProposals(action.payload));
}

export function* fetchIAMServiceProposalOperationsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMServiceProposalOperationsRequestSaga>): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }

  if (vehicleDetail?.iamVehicle?.versionCode === undefined) {
    return;
  }

  const { proposalId, mileage, registrationDate } = payload;
  const request: GetIAMServiceProposalOperationsRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
    },
    proposalId,
    mileage,
    registrationDate,
    userContext,
  };
  yield put(
    setServiceProposalOperationsStatus({
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      status: LOADING,
    }),
  );
  yield put(sendIAMServiceProposalOperationsRequest(request));
}

export function* fetchIAMServiceProposalOperationsResponseSaga(
  action: WsResponse<GetIAMServiceProposalOperationsResponse>,
): SagaIterator {
  yield put(setServiceProposalOperations(action.payload));
}

export function* selectOperationRequest({ payload }: ReturnType<typeof actions.selectOperationRequest>): SagaIterator {
  yield put(selectOperation(payload));
}

export function* removeOperationRequest({ payload }: ReturnType<typeof actions.selectOperationRequest>): SagaIterator {
  yield put(unselectOperation(payload));
}

export function* setSelectedOperationsRequest({
  payload,
}: ReturnType<typeof actions.setOperationsRequest>): SagaIterator {
  yield put(setSelectedOperations(payload));
}

export function* setReferencesRequest({ payload }: ReturnType<typeof actions.setReferencesRequest>): SagaIterator {
  yield put(setSelectedReferences(payload));
}

export function* addReferencesRequest({ payload }: ReturnType<typeof actions.addReferencesRequest>): SagaIterator {
  yield put(addSelectedReferences(payload));
}

export function* removeReferencesRequest({
  payload,
}: ReturnType<typeof actions.removeReferencesRequest>): SagaIterator {
  yield put(removeSelectedReferences(payload));
}

export function* setRegistrationDateInputRequest({
  payload,
}: ReturnType<typeof actions.setRegistrationDateInputRequest>): SagaIterator {
  yield put(setRegistrationDateInput(payload));
}

export function* setMileageInputRequest({ payload }: ReturnType<typeof actions.setMileageInputRequest>): SagaIterator {
  yield put(setMileageInput(payload));
}

export function* setOptionInputRequest({ payload }: ReturnType<typeof actions.setOptionInputRequest>): SagaIterator {
  yield put(setOptionInput(payload));
}

export function* fetchIAMAvailableModulesRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }

  if (vehicleDetail?.iamVehicle?.versionCode === undefined) {
    return;
  }

  const request: GetIAMAvailableModulesRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
    },
    userContext,
  };
  yield put(setAvailableModulesStatus({ status: LOADING }));

  yield put(sendIAMAvailableModulesRequest(request));
}

export function* fetchIAMAvailableModulesResponseSaga(
  action: WsResponse<GetIAMAvailableModulesResponse>,
): SagaIterator {
  yield put(setAvailableModules(action.payload));
}

export function* fetchIAMChecklistRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }

  if (vehicleDetail?.iamVehicle?.versionCode === undefined) {
    return;
  }

  const operationsData = yield* select(getLastSearchServiceOperationsData);
  const selectedMainOperations = operationsData?.mainOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected)
    .map((op) => op.id)
    .join(',');
  const selectedAddOperations = operationsData?.additionalOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected)
    .map((op) => op.id)
    .join(',');

  const request: GetIAMServiceChecklistRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
    },
    userContext,
    operationIds: selectedMainOperations ?? '',
    additionalOperationIds: selectedAddOperations ?? '',
  };
  yield put(setServiceChecklistStatus({ status: LOADING }));

  yield put(sendIAMServiceChecklistRequest(request));
}

export function* fetchIAMChecklistResponseSaga(action: WsResponse<GetIAMServiceChecklistResponse>): SagaIterator {
  yield put(setServiceChecklist(action.payload));
}

export function* sendIAMRepairMethodDetailRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMRepairMethodDetail>): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }

  if (vehicleDetail?.iamVehicle?.versionCode === undefined) {
    return;
  }
  const request: GetIAMRepairMethodDetailRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
    },
    userContext,
    categoryId: payload.categoryId,
  };
  yield put(setRepairMethodDetailsStatus({ categoryId: payload.categoryId, status: LOADING }));

  yield put(sendIAMRepairMethodDetailRequest(request));
}

export function* fetchIAMRepairMethodDetailResponseSaga(
  action: WsResponse<GetIAMRepairMethodDetailResponse>,
): SagaIterator {
  yield put(setRepairMethodDetails(action.payload));
}

export function* MaintenancePlanSagas(): SagaIterator {
  yield takeEvery(actions.fetchIAMAvailableModulesRequestSaga.type, sagaGuard(fetchIAMAvailableModulesRequestSaga));
  yield takeEvery(actions.fetchIAMAvailableModulesResponseSaga.type, sagaGuard(fetchIAMAvailableModulesResponseSaga));
  yield takeEvery(actions.fetchIAMChecklistRequestSaga.type, sagaGuard(fetchIAMChecklistRequestSaga));
  yield takeEvery(actions.fetchIAMChecklistResponseSaga.type, sagaGuard(fetchIAMChecklistResponseSaga));
  yield takeEvery(actions.fetchIAMServiceOperationsRequestSaga.type, sagaGuard(fetchIAMServiceOperationsRequestSaga));
  yield takeEvery(actions.fetchIAMServiceOperationsResponseGaga.type, sagaGuard(fetchIAMServiceOperationsResponseGaga));
  yield takeEvery(actions.fetchIAMServiceFamiliesResponseSaga.type, sagaGuard(fetchIAMServiceFamiliesResponseSaga));
  yield takeEvery(actions.fetchIAMServiceProposalsRequestSaga.type, sagaGuard(fetchIAMServiceProposalsRequestSaga));
  yield takeEvery(actions.fetchIAMServiceProposalsResponseSaga.type, sagaGuard(fetchIAMServiceProposalsResponseSaga));
  yield takeEvery(
    actions.fetchIAMServiceProposalOperationsRequestSaga.type,
    sagaGuard(fetchIAMServiceProposalOperationsRequestSaga),
  );
  yield takeEvery(
    actions.fetchIAMServiceProposalOperationsResponseSaga.type,
    sagaGuard(fetchIAMServiceProposalOperationsResponseSaga),
  );
  yield takeEvery(actions.selectOperationRequest.type, sagaGuard(selectOperationRequest));
  yield takeEvery(actions.setOperationsRequest.type, sagaGuard(setSelectedOperationsRequest));
  yield takeEvery(actions.removeOperationRequest.type, sagaGuard(removeOperationRequest));
  yield takeEvery(actions.setReferencesRequest.type, sagaGuard(setReferencesRequest));
  yield takeEvery(actions.addReferencesRequest.type, sagaGuard(addReferencesRequest));
  yield takeEvery(actions.removeReferencesRequest.type, sagaGuard(removeReferencesRequest));
  yield takeEvery(actions.setRegistrationDateInputRequest.type, sagaGuard(setRegistrationDateInputRequest));
  yield takeEvery(actions.setMileageInputRequest.type, sagaGuard(setMileageInputRequest));
  yield takeEvery(actions.setOptionInputRequest.type, sagaGuard(setOptionInputRequest));

  yield takeEvery(actions.fetchIAMRepairMethodDetail.type, sagaGuard(sendIAMRepairMethodDetailRequestSaga));
  yield takeEvery(
    actions.fetchIAMRepairMethodDetailResponseSaga.type,
    sagaGuard(fetchIAMRepairMethodDetailResponseSaga),
  );
}
