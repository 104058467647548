import styled from 'styled-components';

export const SProductCard = styled.div`
  border: none;
  border-radius: 0;
  height: 100%;
  width: 100%;
  min-height: 450px;
  min-width: 660px;
`;

export const SProductImageContainer = styled.div`
  position: relative;
  min-height: 450px;
  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

export const CarBrandContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

export const PromotionContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;
