/* eslint-disable max-len */
import {
  CROSS_SELLING_DELETE_FILE_URL,
  CROSS_SELLING_GET_FILE_URL,
  CROSS_SELLING_REPLACE_FILE_URL,
  CROSS_SELLING_UPLOAD_FILE_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { CrossSellingReplaceFileRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/request/CrossSellingReplaceFileRequest';
import { FileUploadRequest } from '@1po/1po-bff-fe-spec/generated/common/file_upload/request/FileUploadRequest';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendCrossSellingFileRequest(payload: FileUploadRequest): WebSocketAction {
  return wsSendAction(CROSS_SELLING_UPLOAD_FILE_URL, payload);
}

export function sendCrossSellingReplaceRequest(payload: CrossSellingReplaceFileRequest): WebSocketAction {
  return wsSendAction(CROSS_SELLING_REPLACE_FILE_URL, payload);
}

export function sendGetCrossSellingFileRequest(): WebSocketAction {
  return wsSendAction(CROSS_SELLING_GET_FILE_URL, null);
}

export function sendDeleteCrossSellingFileRequest(): WebSocketAction {
  return wsSendAction(CROSS_SELLING_DELETE_FILE_URL, null);
}
