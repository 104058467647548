import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import { PlusIcon } from 'assets/icons';
import { setInitialCreatePromotion } from 'domains/promotion/Promotion.store';
import { PROMOTION_TAB, PromotionFilterTypeOption, PromotionTabParam } from 'domains/promotion/Promotion.types';
import PromotionFilterDropdown from 'pages/BackOfficePage/BackOfficeCategories/Promotion/Promotion/PromotionFilterDropdown';
import AdjustmentNumber from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionNotification/AdjustmentNumber';
import { PromotionTab } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionTab';
import { CREATE_NEW, PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Box, Flex, Icon, MarginBox, Tab, Tabs, TabsTitle, Text, YellowButton } from 'UI';
import { SELECT_ALL } from 'UI/DropdownMenu';

const CreatePromotionBtn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  function onClick() {
    dispatch(setInitialCreatePromotion());
    history.push(`${ROUTER_BACKOFFICE}/${PROMOTION}/${CREATE_NEW}`);
  }

  return (
    <YellowButton onClick={onClick}>
      <Flex align={'center'}>
        <Icon IconComponent={PlusIcon} width={15} height={15} />
        <MarginBox ml={10} />
        <Text type={'text'}>{t('backoffice.create_promotion', 'Create promotion')}</Text>
      </Flex>
    </YellowButton>
  );
};

const Promotion = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const promotionTabParam = params.get(PromotionTabParam);

  const [tabKey, setTabKey] = useState<PROMOTION_TAB>(params.get(PromotionTabParam) as PROMOTION_TAB);
  const [promotionTypeFilterOptions, setPromotionTypeFilterOptions] = useState<PromotionFilterTypeOption[]>([
    SELECT_ALL,
  ]);

  useEffect(() => {
    if (promotionTabParam !== null || promotionTabParam !== tabKey) {
      history.replace(`${location.pathname}?${params}`);
      setTabKey(promotionTabParam as PROMOTION_TAB);
    } else if (promotionTabParam === null) {
      params.set(PromotionTabParam, 'published');
      history.replace(`${location.pathname}?${params}`);
      setTabKey('published');
    }
    // eslint-disable-next-line
  }, [promotionTabParam]);

  const changeTab = (tab: PROMOTION_TAB) => {
    setTabKey(tab);
    params.set(PromotionTabParam, tab);
    history.push(`${location.pathname}?${params}`);
  };

  return (
    <Flex direction={'column'}>
      <Flex direction={'row-reverse'}>
        <Box>
          <CreatePromotionBtn />
          <MarginBox mt={20} />
          <PromotionFilterDropdown
            promotionTypeFilterOptions={promotionTypeFilterOptions}
            setPromotionTypeFilterOptions={setPromotionTypeFilterOptions}
          />
        </Box>
      </Flex>
      <MarginBox mt={15} />
      <Tabs activeKey={tabKey} defaultActiveKey="published" onChange={changeTab}>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.published', 'Published')}</TabsTitle>} key={'published'}>
          <PromotionTab
            statusType={'PUBLISHED'}
            promotionTypeFilterOptions={promotionTypeFilterOptions}
            setPromotionTypeFilterOptions={(items) => setPromotionTypeFilterOptions(items)}
          />
        </Tab>
        <Tab
          tab={
            <Flex>
              <TabsTitle>{t('backoffice.assistance.planned', 'Planned')}</TabsTitle>
              <Flex direction={'row-reverse'}>
                <MarginBox mt={-5}>
                  <AdjustmentNumber />
                </MarginBox>
              </Flex>
            </Flex>
          }
          key={'planned'}
        >
          <PromotionTab
            statusType={'PLANNED'}
            promotionTypeFilterOptions={promotionTypeFilterOptions}
            setPromotionTypeFilterOptions={(items) => setPromotionTypeFilterOptions(items)}
          />
        </Tab>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.saved', 'Saved')}</TabsTitle>} key={'saved'}>
          <PromotionTab
            statusType={'SAVED'}
            promotionTypeFilterOptions={promotionTypeFilterOptions}
            setPromotionTypeFilterOptions={(items) => setPromotionTypeFilterOptions(items)}
          />
        </Tab>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.archived', 'Archived')}</TabsTitle>} key={'archived'}>
          <PromotionTab
            statusType={'ARCHIVED'}
            promotionTypeFilterOptions={promotionTypeFilterOptions}
            setPromotionTypeFilterOptions={(items) => setPromotionTypeFilterOptions(items)}
          />
        </Tab>
      </Tabs>
    </Flex>
  );
};

export default Promotion;
