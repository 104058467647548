import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InfoCircleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import LanguageMenu from 'components/LanguageMenu';
import ForgottenPassword, { isPasswordRetrievalAllowed } from 'components/Login/LoginForm/ForgottenPassword';
import InformationBanner from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { getCredentials, getCredentialsSearchState } from 'domains/auth/Auth.store';
import { Box, Flex, Icon, MarginBox, Text, WhiteButton, YellowButton } from 'UI';
import { LoginSectionProps } from './LoginSection';

const Overlay = styled.div`
  position: absolute;
  height: 64px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.grey100};
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OverlayCenter = styled.div`
  display: flex;
  justify-content: center;
`;

const LoginSectionSmallContainer = styled.div`
  background-size: cover;
  background-position: right;
  background-image: url('/images/landing/background-small.jpg');
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding: 16px 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

export const LoginSectionSm = ({
  informationBanner,
  onRenUserArcaLoginHandler,
  onRenUserTokenLoginHandler,
  onKeycloakLoginHandler,
  onOctaLoginHandler,
}: LoginSectionProps) => {
  const { t } = useTranslation();
  const credentials = useSelector(getCredentials);
  const credentialsSearchStatus = useSelector(getCredentialsSearchState);
  const [showOtherLoginOptions, setShowOtherLoginOptions] = useState<boolean>(false);

  function handleChangeOtherLoginOptions() {
    setShowOtherLoginOptions((prev) => !prev);
  }

  return (
    <LoginSectionSmallContainer>
      <Flex size={0}>
        <Text type={'h3_dark'}>{t('section.landing.intro', 'Buy car parts for your vehicle')}</Text>
        <Flex />
        <LanguageMenu />
      </Flex>
      <MarginBox mt={15}>
        <Text type={'light_12_dark'}>
          <Trans i18nKey={'section.landing.intro.description'}>
            Revolutionizing automobile aftermarket spare parts solution. Discover Renault Group official solution ,
            developed with and for automotive professionals, in order to optimize your daily work.
          </Trans>
        </Text>
      </MarginBox>
      <ButtonContainer>
        <DataContainer data={credentialsSearchStatus}>
          <>
            <MarginBox mt={16} />
            <YellowButton stretch onClick={onOctaLoginHandler}>
              {t('login.okta_connection.description', 'Continue with Okta connection')}
            </YellowButton>
          </>
          <MarginBox mt={16} />
          <WhiteButton onClick={handleChangeOtherLoginOptions} stretch>
            {showOtherLoginOptions
              ? t('login.other_login_options.button.description.hide', 'Hide Other Options')
              : t('login.other_login_options.button.description.show', 'See other login options')}
          </WhiteButton>
          <MarginBox mt={16} />
          {showOtherLoginOptions && (
            <>
              <WhiteButton onClick={onRenUserTokenLoginHandler} stretch>
                {t('login.rnet_connection.button.description', 'Continue with Rnet')}
              </WhiteButton>
              <MarginBox mt={16} />
              <WhiteButton stretch onClick={onRenUserArcaLoginHandler}>
                {t('login.arca_connection.button.description', 'Continue with Arca for Renault group')}
              </WhiteButton>
            </>
          )}
          {credentials?.keycloakIssuer && credentials?.keycloakClientId && showOtherLoginOptions && (
            <>
              <MarginBox mt={16} />
              <WhiteButton onClick={onKeycloakLoginHandler} stretch dataCy="keycloak-login">
                {'Keycloak login'}
              </WhiteButton>
            </>
          )}
          {isPasswordRetrievalAllowed() && (
            <>
              <MarginBox mt={16} />
              <ForgottenPassword />
            </>
          )}
          <MarginBox mt={56} />
          {informationBanner && <InformationBanner banner={informationBanner} />}
          <Box height={64} />
          <Overlay>
            <OverlayCenter>
              <Icon IconComponent={InfoCircleIcon} height={15} width={15} display={'inline'} />
              <Box width={7} />
              <Flex direction={'column'}>
                <Text type={'mobile_h3'}>{t('login.use_token.title_short', 'Log in with token')}</Text>
                <Text type={'mobile_dim'}>
                  {t('login.use_token.note_description', 'Make sure to plug your Usb key previously received')}
                </Text>
              </Flex>
            </OverlayCenter>
          </Overlay>
        </DataContainer>
      </ButtonContainer>
    </LoginSectionSmallContainer>
  );
};
