import styled from 'styled-components';
import { theme } from 'styles';
import { Box } from 'UI';

export const SCard = styled.div`
  position: relative;
  background-color: ${theme.color.white};
  padding: 15px 15px;
  transition: height 2s;
  width: 100%;
  border-radius: 14px;
  box-shadow: 1px 1px 1px 1px ${theme.color.grey85};
  z-index: 2;
`;
export const SBrandImageBox = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.45;
`;

export const SStockWrapper = styled.div`
  display: inline-block;
  background-color: ${theme.color.grey95};
  box-shadow: 0 4px 10px 0 ${theme.color.grey75};
  border-radius: 0 0 14px 14px;
  width: 100%;
  z-index: 1;
`;

export const SelectionCard = styled(Box)`
  display: inline-block;
  background-color: ${theme.color.brand_black};
  padding: 5px 15px 20px 15px;
  margin-bottom: -15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 0;
`;
