import { WsRequest } from '@1po/1po-bff-fe-spec/generated/common/WsRequest';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { WebSocketAction } from 'utils';

export const WS_NAMESPACE = `${APP_NAMESPACE}/WEBSOCKET`;

export const WS_CONNECT = `${WS_NAMESPACE}/CONNECT`;
export const WS_DISCONNECT = `${WS_NAMESPACE}/DISCONNECT`;
export const WS_INACTIVITY_DISCONNECT = `${WS_NAMESPACE}/INACTIVITY_DISCONNECT`;
export const WS_SEND_REQUEST = `${WS_NAMESPACE}/SEND_REQUEST`;

export interface WebSocketState {
  // connection to the backend is established
  connected: boolean;
  // list of requests which should be processed by backend
  requests: Map<string, WebSocketAction>;
  processedRequests: Map<string, Date>;
  countErrorNoConnectionToBESide: number;
}

export type { WsRequest, WsResponse };
