import { AuthConfig } from '@1po/1po-bff-fe-spec/generated/auth/response/AuthConfig';
import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const AUTH_NAMESPACE = `${APP_NAMESPACE}/AUTH`;

export const GET_AUTH_CONFIG_REQUEST_SAGA = `${AUTH_NAMESPACE}/GET_AUTH_CONFIG_REQUEST_SAGA`;
export const GET_AUTH_CONFIG_RESPONSE_SAGA = `${AUTH_NAMESPACE}/GET_AUTH_CONFIG_RESPONSE_SAGA`;

export interface AuthState {
  credentials: SearchData<AuthConfig>;
}

export const ARCA_LOGIN = 'arca';
export const RNET_LOGIN = 'rnet';

export const KEYCLOAK = 'KEYCLOAK';
export const OKTA = 'OKTA';

export const IDP = 'IDP';

export type ISSUER_TYPE = typeof OKTA | typeof IDP | typeof KEYCLOAK;

export const LOGIN_METHOD_LOCAL_PARAM = 'loginMethod';
export const CREDENTIALS_LOCAL_PARAM = 'credentials';

export const ISSUER_LOCAL_PARAM = 'issuerType';
export const SELECTED_PROFILE_PARAM = 'selected_profile';
