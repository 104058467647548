import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, MarginBox, Text } from 'UI';
import { textFormatter } from 'utils';

const TotalMarginLine = ({
  currency,
  totalMargin,
  totalMarginPercentage,
}: {
  currency?: string;
  totalMargin?: number;
  totalMarginPercentage?: string;
}) => {
  const { t } = useTranslation();
  const totalMarginFormatted = textFormatter.formatCurrency(totalMargin || 0.0, currency);

  return (
    <Flex>
      <Flex size={4}>
        <Text type={'section_bold'}>{t('common.total_margin', 'Total margin')}</Text>
      </Flex>
      <Flex />
      <Flex size={6}>
        <Flex justify={'flex-end'}>
          <Text type={'light_14_black_45'}>{t('common.price.vat_excl_price.short', 'VAT excl. :')}</Text>
        </Flex>
        <Flex justify={'flex-end'}>
          <Text type={'light_14_black_45'}>{totalMarginFormatted}</Text>
        </Flex>
      </Flex>
      <MarginBox mr={30} />
      <Flex size={4}>
        <Flex justify={'flex-start'} />
        <Flex justify={'flex-end'}>
          <Text type={'light_14_black_45'}>{totalMarginPercentage + '%'}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TotalMarginLine;
