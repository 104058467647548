import React from 'react';
import { ThemeFontKeyType } from 'styles';
import { Flex, MarginBox, MarginType, Text } from 'UI';

export const Section = ({ mt = 15, children }: { mt?: MarginType; children: React.ReactNode }) => {
  return (
    <Flex direction={'column'}>
      {children}
      <MarginBox mt={mt} />
    </Flex>
  );
};

export const SectionHeader = ({
  title,
  article,
  underline,
  type = 'text_bold',
  mb = 15,
}: {
  title: string;
  article?: string;
  underline?: boolean;
  type?: ThemeFontKeyType;
  mb?: MarginType;
}) => (
  <Flex direction={'column'}>
    <Flex direction={'row'}>
      {article && (
        <>
          <Text type={type} decoration={underline ? 'underline' : undefined} hoverUnderLine={underline} noWrap>
            {article}
          </Text>
          <MarginBox ml={15} />
        </>
      )}
      <Text type={type} decoration={underline ? 'underline' : undefined} hoverUnderLine={underline}>
        {title}
      </Text>
    </Flex>
    <MarginBox mb={mb} />
  </Flex>
);
