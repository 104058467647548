import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TrashAltIcon } from 'assets/icons';
import { FileUpload } from 'components/FileUpload';
import {
  EMPTY_FILE_ERROR,
  FILE,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  MAX_SIZE_ERROR,
  ModalStatusType,
  NO_FILE,
  SAME_FILE_ERROR,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import {
  deleteFileCrossSellingRequestSaga,
  getCrossSellingFileFetchStatus,
  getCrossSellingFileInformation,
  getCrossSellingFileInformationForRequest,
  getCrossSellingFileModalStatus,
  getCrossSellingFileUploadedRows,
  getCrossSellingFileUploadStatus,
  setCrossSellingFileInformationForRequest,
  setCrossSellingFileModalStatus,
  setCrossSellingFileUploadStatus,
  uploadFileCrossSellingRequestSaga,
} from 'domains/crossSelling/CrossSelling.store';
import {
  DELETING_FILE,
  FILE_FETCHED,
  FILE_UPLOADED,
  REPLACING_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Text } from 'UI';
import { FOUND } from 'utils';

const CrossSellingFileUpload = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fileInfoFetched = useSelector(getCrossSellingFileInformation);
  const fileInfoRequest = useSelector(getCrossSellingFileInformationForRequest);
  const fileStatus = useSelector(getCrossSellingFileUploadStatus);
  const modalStatus = useSelector(getCrossSellingFileModalStatus);
  const fileRows = useSelector(getCrossSellingFileUploadedRows);
  const searchStatus = useSelector(getCrossSellingFileFetchStatus);
  const setModalStatus = (status: ModalStatusType) => dispatch(setCrossSellingFileModalStatus(status));
  const enableDelete =
    modalStatus === FILE &&
    fileStatus !== UPLOADING_FILE &&
    fileStatus !== REPLACING_FILE &&
    fileStatus !== DELETING_FILE;
  const validateFile = (file: File) => {
    if (!file.name.endsWith('.csv')) {
      setModalStatus(FILE_FORMAT_ERROR);
      return false;
    } else if (file.size > 2000 * 1024) {
      setModalStatus(MAX_SIZE_ERROR);
      return false;
    } else {
      setModalStatus(FILE);
      return true;
    }
  };

  const handleDelete = () => {
    if (searchStatus === FOUND && fileStatus === FILE_UPLOADED) {
      setModalStatus(FILE);
      dispatch(setCrossSellingFileUploadStatus(FILE_FETCHED));
    } else if (fileRows.length > 0) dispatch(deleteFileCrossSellingRequestSaga());
    else {
      setModalStatus(NO_FILE);
      dispatch(setCrossSellingFileUploadStatus(NO_FILE));
    }
  };
  const uploadFile = (fileName: string, fileSize: number, fileBase64: string) => {
    dispatch(
      uploadFileCrossSellingRequestSaga({
        fileBase64,
      }),
    );
    dispatch(setCrossSellingFileInformationForRequest({ fileName }));
  };

  const handleNoFile = () => {
    setModalStatus(NO_FILE);
    dispatch(setCrossSellingFileUploadStatus(NO_FILE));
  };

  const getDescriptions = () => {
    switch (modalStatus) {
      case FILE_FORMAT_ERROR: {
        return [t('common.file_upload.error.format', 'Only CSV file is supported, please try again')];
      }
      case MAX_SIZE_ERROR: {
        return [
          t(
            'common.file_upload.error.max_size_error_second_line',
            'Support extension: CSV-Maximum file size: {{size}} {{unit}}',
            { size: 2, unit: 'MB' },
          ),
        ];
      }
      case FILE_STRUCTURE_ERROR: {
        return [t('common.file_upload.error.wrong_format_structure', 'File has wrong structure')];
      }
      case EMPTY_FILE_ERROR: {
        return [t('common.file_upload.error.empty_file_error', 'Your uploaded file is empty')];
      }
      case SAME_FILE_ERROR: {
        return [t('common.file_upload.error.same_file_error', 'This file is already published')];
      }
      default: {
        return [t('common.file_upload.extension.csv', 'Supported extension: CSV')];
      }
    }
  };
  return (
    <>
      <FileUpload
        template={{
          link: '/fileTemplates/cross_selling_template.csv',
          fileName: 'Cross selling.csv',
        }}
        handleFileBase64={uploadFile}
        validateFile={validateFile}
        handleNoFile={handleNoFile}
        status={modalStatus}
        descriptions={getDescriptions()}
        currentFile={
          modalStatus === FILE
            ? {
                fileType: 'CSV',
                fileName:
                  fileStatus === FILE_FETCHED || fileStatus === DELETING_FILE
                    ? fileInfoFetched.fileName
                    : fileInfoRequest.fileName,
                fileBase64: '',
              }
            : undefined
        }
        isProcessing={fileStatus === REPLACING_FILE || fileStatus === DELETING_FILE}
      />
      {enableDelete && (
        <MarginBox mt={15}>
          <Flex>
            <Icon IconComponent={TrashAltIcon} color={theme.color.red} size={16} mt={2} onClick={handleDelete} />
            <Box width={10} />
            <Text type={'light_14_bold_red'} cursor={'pointer'} onClick={handleDelete}>
              {t('common.file_upload.file_delete', 'Delete file')}
            </Text>
          </Flex>
        </MarginBox>
      )}
    </>
  );
};

export default CrossSellingFileUpload;
