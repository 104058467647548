import React, { FocusEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { getWebLanguage } from 'domains/user';

export interface DatePickerProps {
  value?: moment.Moment | null;
  onChange?: (value: moment.Moment | null, dateString: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  ellipsis?: boolean;
  bordered?: boolean;
  className?: string;
  disabledDate?: (currentDate: moment.Moment) => boolean;
  disabled?: boolean;
  displayStyle?: 'error';
}

const SAntDatepicker = styled(AntDatePicker)<{ $isError?: boolean }>`
  border: ${({ theme, $isError }) => ($isError ? `1px solid ${theme.color.red}` : '')};
  background-color: ${({ theme, $isError }) => ($isError ? theme.color.very_light_pink : '')};

  &:hover {
    border: ${({ theme, $isError }) => ($isError ? `1px solid ${theme.color.red}` : '')};
  }
`;

export function DatePicker(props: DatePickerProps) {
  const { antdLocale } = useSelector(getWebLanguage);
  return (
    <SAntDatepicker
      {...props}
      $isError={props.displayStyle === 'error'}
      format={antdLocale.DatePicker?.lang.dateFormat}
    />
  );
}
