/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IAM } from 'domains/catalog/Catalog.types';
import { ImageWithLoader } from 'UI';
import { ReferenceBrandContainer } from './IAMReferenceCard.styled';

const ReferenceImage = ({ brandImage, referenceImage }: { brandImage?: string; referenceImage?: string }) => {
  const { t } = useTranslation();
  const alt = t('catalog.iam.reference_card.reference_image', 'IAM reference preview image');
  if (!referenceImage) {
    return <ImageWithLoader alt={alt} imageUrl={brandImage} imageHeight={120} imageWidth={135} cursor={'auto'} />;
  }

  return (
    <>
      <ImageWithLoader
        alt={alt}
        imageUrl={referenceImage}
        imageHeight={120}
        imageWidth={135}
        cursor={'pointer'}
        catalogSource={IAM}
      />
      <ReferenceBrandContainer>
        <ImageWithLoader alt={alt} imageUrl={brandImage} imageHeight={30} />
      </ReferenceBrandContainer>
    </>
  );
};

export default ReferenceImage;
