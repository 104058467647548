import { DealerType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { TFunction } from 'i18next';
import { UserRole } from 'domains/user';
import { Country } from 'utils/i18n/Country';

export function getDealerTypes(): DealerType[] {
  return ['R1', 'R2', 'R3', 'R3_MOTRIO'];
}

export const getDealerTypeLabel = (dealerType: DealerType, t: TFunction): string => {
  switch (dealerType) {
    case 'R1':
      return t('backoffice.promotion.target_audience.r1', 'R1');
    case 'R2':
      return t('backoffice.promotion.target_audience.r2', 'R2');
    case 'R3':
      return t('backoffice.promotion.target_audience.r3', 'R3');
    case 'R3_MOTRIO':
      return t('backoffice.promotion.target_audience.r3_motrio', 'R3 Motrio');
    default:
      return '';
  }
};

export function getCountries(): { label: string; value: string }[] {
  return Country.listCountries().map((c) => {
    return { label: c.name, value: c.key };
  });
}

// todo: add order list?
export function getPages(t: TFunction, userRights: UserRole[]): { label: string; value: string }[] {
  return [
    {
      label: t('backoffice.information.create_information.targeted_pages.select.landing_page', 'Landing page'),
      value: 'LANDING_PAGE',
      isHidden: !userRights.includes(UserRole.CENTRAL_ADMIN),
    },
    {
      label: t('backoffice.information.create_information.targeted_pages.select.home_page', 'Home page'),
      value: 'HOME_PAGE',
    },
    {
      label: t('backoffice.information.create_information.targeted_pages.select.renault_catalog', 'Renault catalog'),
      value: 'RENAULT_CATALOG',
    },
    {
      label: t(
        'backoffice.information.create_information.targeted_pages.select.other_brands_catalog',
        "Other brand's catalog",
      ),
      value: 'OTHER_BRANDS_CATALOG',
    },
    {
      label: t('cart', 'Cart'),
      value: 'CART',
    },
    {
      label: t('backoffice.information.create_information.targeted_pages.select.accessories', 'Accessories'),
      value: 'ACCESSORIES',
    },
    {
      label: t('backoffice.information.create_information.targeted_pages.select.merchandising', 'Merchandising'),
      value: 'MERCHANDISING',
    },
    {
      label: t('catalog.universal_products', 'Universal products'),
      value: 'UNIVERSAL_PRODUCTS',
    },
    {
      label: t('backoffice.information.create_information.targeted_pages.select.tires', 'Tires'),
      value: 'TIRES',
    },
  ].filter((x) => !x.isHidden);
}
