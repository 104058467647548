import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowsAltIcon,
  CarIcon,
  CogIcon,
  CogsIcon,
  ColumnsIcon,
  CubeIcon,
  CubesIcon,
  DatabaseIcon,
  ExclamationTriangleIcon,
  GlobeIcon,
  LightbulbIcon,
  LockIcon,
  LowVisionIcon,
  SnowflakeIcon,
  TableIcon,
  WindowRestoreIcon,
} from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { useFetchVehicleTechnicalCriteria } from 'domains/catalog/Catalog.requests';
import {
  fetchGetVehicleCategoriesRequestSaga,
  getLastSearchedVehicleKey,
  getLastTechnicalCriteria,
  setSimplifiedApplicability,
} from 'domains/catalog/Catalog.store';
import { SHeaderApplicability } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteria.styled';
import {
  ItemElement,
  TechnicalCriteriaMenuAndContent,
} from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenuAndContent';
import { Flex, MarginBox, Text } from 'UI';
import { Switch } from 'UI/Switch';
import { FOUND, getData, hasData, SEARCH_STATUS } from 'utils';

export interface CriteriaI {
  simplifiedApplicability: boolean;
  items: ItemElement[];
  status: SEARCH_STATUS;
}

export function useGetCriteria(): CriteriaI {
  const { t } = useTranslation();
  const lastTechnicalCriteria = useSelector(getLastTechnicalCriteria);
  const criteria = getData(lastTechnicalCriteria);
  const arr: ItemElement[] = useMemo(
    () => [
      {
        label: t('catalog.details.technical_criteria.configuration', 'Configuration'),
        icon: CogIcon,
        type: 'CONFIGURATION',
        data: criteria?.groups.get('CONFIGURATION'),
      },
      {
        label: t('catalog.details.technical_criteria.body_type', 'Body type'),
        icon: CarIcon,
        type: 'BODY_TYPE',
        data: criteria?.groups.get('BODY_TYPE'),
      },
      {
        label: t('catalog.details.technical_criteria.main_engine', 'Main engine'),
        icon: CubeIcon,
        type: 'MAIN_ENGINE',
        data: criteria?.groups.get('MAIN_ENGINE'),
      },
      {
        label: t('catalog.details.technical_criteria.secondary_engine', 'Secondary engine'),
        icon: CubesIcon,
        type: 'SECONDARY_ENGINE',
        data: criteria?.groups.get('SECONDARY_ENGINE'),
      },
      {
        label: t('catalog.details.technical_criteria.transmission', 'Transmission'),
        icon: ArrowsAltIcon,
        type: 'TRANSMISSION',
        data: criteria?.groups.get('TRANSMISSION'),
      },
      {
        label: t('catalog.details.technical_criteria.traction_battery', 'Traction battery'),
        icon: DatabaseIcon,
        type: 'TRACTION_BATTERY',
        data: criteria?.groups.get('TRACTION_BATTERY'),
      },
      {
        label: t('catalog.details.technical_criteria.adaptation', 'Adaptation'),
        icon: GlobeIcon,
        type: 'ADAPTATION',
        data: criteria?.groups.get('ADAPTATION'),
      },
      {
        label: t('catalog.details.technical_criteria.axle_assemblies', 'Axle assemblies'),
        icon: CubesIcon,
        type: 'AXLE_ASSEMBLIES',
        data: criteria?.groups.get('AXLE_ASSEMBLIES'),
      },
      {
        label: t('catalog.details.technical_criteria.air_conditioning_heating', 'Air conditioning / Heating'),
        icon: SnowflakeIcon,
        type: 'AIR_CONDITIONING_HEATING',
        data: criteria?.groups.get('AIR_CONDITIONING_HEATING'),
      },
      {
        label: t('catalog.details.technical_criteria.dashboard_radio_navigation', 'Dashboard / Radio navigation'),
        icon: TableIcon,
        type: 'DASHBOARD_RADIO_NAVIGATION',
        data: criteria?.groups.get('DASHBOARD_RADIO_NAVIGATION'),
      },
      {
        label: t('catalog.details.technical_criteria.seats_upholstery', 'Seats / Upholstery'),
        icon: ColumnsIcon,
        type: 'SEATS_UPHOLSTERY',
        data: criteria?.groups.get('SEATS_UPHOLSTERY'),
      },
      {
        label: t('catalog.details.technical_criteria.lighting_screen_wipers', 'Lighting / Screen wipers'),
        icon: LightbulbIcon,
        type: 'LIGHTNING_SCREEN_WIPERS',
        data: criteria?.groups.get('LIGHTNING_SCREEN_WIPERS'),
      },
      {
        label: t('catalog.details.technical_criteria.windows_rear_view', 'Windows / Rear view'),
        icon: LowVisionIcon,
        type: 'WINDOWS_REAR_VIEW',
        data: criteria?.groups.get('WINDOWS_REAR_VIEW'),
      },
      {
        label: t('catalog.details.technical_criteria.safety', 'Safety'),
        icon: ExclamationTriangleIcon,
        type: 'SAFETY',
        data: criteria?.groups.get('SAFETY'),
      },
      {
        label: t('catalog.details.technical_criteria.locking_alarm', 'Locking / Alarm'),
        icon: LockIcon,
        type: 'LOCKING_ALARM',
        data: criteria?.groups.get('LOCKING_ALARM'),
      },
      {
        label: t('catalog.details.technical_criteria.interior_exterior_equipment', 'Interior and exterior equipment'),
        icon: CogsIcon,
        type: 'INTERIOR_EXTERIOR_EQUIPMENT',
        data: criteria?.groups.get('INTERIOR_EXTERIOR_EQUIPMENT'),
      },
      {
        label: t('common.others', 'Others'),
        icon: WindowRestoreIcon,
        type: 'OTHERS',
        data: criteria?.groups.get('OTHERS'),
      },
    ],
    [t, criteria],
  );
  return {
    simplifiedApplicability: criteria?.simplifiedApplicability ?? false,
    items: useMemo(() => arr.filter((i) => i.data !== undefined && i.data.length !== 0), [arr]),
    status: hasData(lastTechnicalCriteria) ? FOUND : lastTechnicalCriteria,
  };
}

export function TechnicalCriteria() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const criteria = useGetCriteria();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);

  useFetchVehicleTechnicalCriteria(criteria.items, false);

  const refreshCategories = useCallback(() => {
    dispatch(fetchGetVehicleCategoriesRequestSaga({ vehicleKey }));
  }, [dispatch, vehicleKey]);

  const handleSimplifiedApplicability = useCallback(
    (enabled: boolean) => {
      dispatch(setSimplifiedApplicability({ enabled }));
      refreshCategories();
    },
    [dispatch, refreshCategories],
  );

  return (
    <DataContainer
      data={criteria.status}
      ErrorState={() => (
        <ErrorWithLabel
          label={t(
            'catalog.details.backend_error',
            'Technical criteria temporarily unavailable, please try again later.',
          )}
        />
      )}
    >
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex>
          <Flex minWidth={300} maxWidth={300} justify={'space-between'} align={'center'}>
            <SHeaderApplicability>
              <Text type={'light_14_black_65'}>
                {t('catalog.parts.simplified_applicability', 'Simplified applicability')}
              </Text>
            </SHeaderApplicability>
            <Switch checked={criteria.simplifiedApplicability} onChange={handleSimplifiedApplicability} />
          </Flex>
        </Flex>
        <MarginBox mt={30} />
        <TechnicalCriteriaMenuAndContent items={criteria.items} refreshCategories={refreshCategories} />
      </Flex>
    </DataContainer>
  );
}
