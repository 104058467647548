import React from 'react';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { SelectedTireProps } from 'domains/tires/Tire.types';
import TireRow from 'pages/CatalogPage/DH/Tires/TireRow';

interface BasicMountProps extends SelectedTireProps {
  basicMount: VehicleTire;
}

const BasicMount = ({ basicMount, selectedTire, setSelectedTire }: BasicMountProps) => {
  return <TireRow tire={basicMount} selectedTire={selectedTire} setSelectedTire={setSelectedTire} />;
};
export default BasicMount;
