import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_TERMS_AND_CONDITIONS } from 'app/AppRouter';
import { NotFound } from 'app/NotFound';
import {
  TermsAndConditionsAT,
  TermsAndConditionsBE,
  TermsAndConditionsDE,
  TermsAndConditionsFR,
  TermsAndConditionsIT,
  TermsAndConditionsNL,
  TermsAndConditionsPL,
  TermsAndConditionsPT,
  TermsAndConditionsRO,
} from 'pages/TermsAndConditionsPage/versions';
import { Country } from 'utils/i18n/Country';

export const TermsAndConditionsPage = () => {
  return (
    <Switch>
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.AT.key)} component={TermsAndConditionsAT} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.DE.key)} component={TermsAndConditionsDE} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.FR.key)} component={TermsAndConditionsFR} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.IT.key)} component={TermsAndConditionsIT} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.NL.key)} component={TermsAndConditionsNL} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.PL.key)} component={TermsAndConditionsPL} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.RO.key)} component={TermsAndConditionsRO} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.BE.key)} component={TermsAndConditionsBE} />
      <Route path={ROUTER_TERMS_AND_CONDITIONS(Country.PT.key)} component={TermsAndConditionsPT} />
      <Route path={'/'} component={NotFound} />
    </Switch>
  );
};
