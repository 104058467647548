import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableDMSServices,
  getAvailableDMSServicesRequestSaga,
  getDealerType,
  getDmsWorkshopId,
  getTradingProfile,
} from 'domains/user';
import { LOADING } from 'utils';

export function useFetchAvailableDMSServices() {
  const dispatch = useDispatch();
  const availableServices = useSelector(getAvailableDMSServices);
  const dealerType = useSelector(getDealerType);
  const dealerId = useSelector(getTradingProfile)?.buyerId;
  const workshopId = useSelector(getDmsWorkshopId);

  useEffect(() => {
    if (!availableServices && availableServices !== LOADING && dealerType === 'R3_MOTRIO' && dealerId && workshopId) {
      dispatch(getAvailableDMSServicesRequestSaga({ workshopId }));
    }
  }, [dispatch, availableServices, dealerId, dealerType, workshopId]);
}
