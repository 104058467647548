import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TiresFrontAxleIcon, TiresRearAxleIcon } from 'assets/icons';
import TireReferencesPopup from 'components/TireReferencesPopup';
import { convertDHTireSizeToSearchQuery, mapDHTireUsageToSeason, toSearchParams } from 'domains/tires/Tire.mapper';
import { tireProductsSearchRequestSaga } from 'domains/tires/Tire.store';
import { getFavoriteBrandPreferences } from 'domains/user';
import { TireItemContainer } from 'pages/CatalogPage/DH/Tires/TireItem/TireItem.styled';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Flex, Icon, Pipeline, Text } from 'UI';

interface TireItemProps {
  id: string;
  position: 'FRONT' | 'REAR';
  tireSize: string;
  usage: string;
  isSelected: boolean;
  setSelectedTire: (tire: string | undefined) => void;
}

export const TireItem = ({ id, position, tireSize, usage, isSelected, setSelectedTire }: TireItemProps) => {
  const dispatch = useDispatch();
  const brands = tireBrandsData.map((brand) => brand.brandId);
  const season = mapDHTireUsageToSeason(usage);
  const query = convertDHTireSizeToSearchQuery(tireSize);
  const favoriteBrandsList = useSelector(getFavoriteBrandPreferences);
  const brandsFilter = tireBrandsData
    .filter((brand) => favoriteBrandsList.includes(brand.brand))
    .map((brand) => brand.brandId);

  const searchParamsBase64 = query ? toSearchParams(query, season, brands, '/').searchParamsBase64 : '';
  return (
    <TireReferencesPopup
      id={id}
      searchParamsBase64={searchParamsBase64}
      handleSearch={() =>
        query &&
        dispatch(
          tireProductsSearchRequestSaga({
            query,
            season: 'ALL' as Season,
            brands,
            chargeIndicatorSeparator: '/',
          }),
        )
      }
      brandsFilter={brandsFilter}
      isSelected={isSelected}
      setSelectedTire={setSelectedTire}
    >
      <TireItemContainer
        direction={'column'}
        align={'center'}
        minWidth={330}
        maxWidth={330}
        minHeight={55}
        maxHeight={55}
        selected={isSelected}
      >
        <Flex direction={'row'} align={'center'}>
          <Icon
            IconComponent={position === 'FRONT' ? TiresFrontAxleIcon : TiresRearAxleIcon}
            size={25}
            noPointer
            ml={20}
            mr={35}
          />
          <Text type={'light_14_black_85'} displayStyle={isSelected && 'link'}>
            {tireSize}
          </Text>
        </Flex>
        <Pipeline size={'100%'} horizontal />
      </TireItemContainer>
    </TireReferencesPopup>
  );
};
