import React, { ReactNode } from 'react';
import { Radio as AntRadio } from 'antd-v5';
import { Text } from 'UI';
import { SFlex } from 'UI/Radio/Radio.styled';

export interface RadioProps {
  value?: string;
  checked?: boolean;
  onChange?: (x: boolean) => void;
  label?: string | ReactNode;
  disable?: boolean;
}

export const Radio = ({ onChange, value, checked, label, disable }: RadioProps) => {
  return (
    <SFlex onClick={() => !disable && onChange && onChange(!checked)} disable={disable}>
      <AntRadio
        value={value}
        disabled={disable}
        onChange={(e) => (onChange ? onChange(e.target.checked) : undefined)}
        checked={checked}
      />
      {label && <div>{typeof label === 'string' ? <Text type={'text'}>{label}</Text> : label}</div>}
    </SFlex>
  );
};

export const RadioGroup = AntRadio.Group;
