import { GET_SSO_LINK_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { SSOLinkResponse } from '@1po/1po-bff-fe-spec/generated/sso/SSOLinkResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Init state
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { getDefaultSearchData, SearchData } from 'utils';
import { GET_SSO_LINK, SSO_NAMESPACE, SSOLinkState, SSOLinkType } from './SSOLink.types';

const initialState: SSOLinkState = {
  ssoLink: getDefaultSearchData(),
};

export const fetchSSOLinkRequest = createAction<SSOLinkType>(GET_SSO_LINK);

export const fetchSSOLinkResponse = createAction(GET_SSO_LINK_RESPONSE);

// Slice
const slice = createSlice({
  name: SSO_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setSSOLink: (state, { payload }: PayloadAction<SearchData<SSOLinkResponse>>) => {
      state.ssoLink = payload;
    },
    clearSSOLinkRequest: (state) => {
      state.ssoLink = getDefaultSearchData();
    },
  },
});

// Actions
export const { setInitialState, setSSOLink, clearSSOLinkRequest } = slice.actions;

// Getters/Selectors
export const getSSOLink = createSelector(
  (state: RootState) => state.sso.ssoLink,
  (ssoLink) => ssoLink,
);

// Export reducer
export default slice.reducer;
