import React, { useEffect } from 'react';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { ScrollContainer } from 'pages/CatalogPage/DH/Product/ProductThumbnailSection/ProductThumbnailSection.styled';
import { MarginBox } from 'UI';
import { ProductThumbnailCard } from '../ProductThumbnailCard/ProductThumbnailCard';

export const ProductThumbnailSection = ({
  imageKeys,
  setSelectedIndex,
  selectedIndex,
  catalogSource,
}: {
  imageKeys: string[];
  setSelectedIndex: (x: number) => void;
  selectedIndex: number;
  catalogSource: CatalogSource;
}) => {
  useEffect(() => {
    if (selectedIndex) {
      const selectedProduct = document.getElementsByClassName('product_id' + selectedIndex);
      selectedProduct[0]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedIndex]);

  return (
    <ScrollContainer>
      <MarginBox ml={5}>
        {Array.isArray(imageKeys) &&
          imageKeys?.map((image, index) => {
            return (
              image &&
              imageKeys.length > 0 && (
                <MarginBox
                  key={`product-${index}`}
                  mx={10 - ((Math.min(4, imageKeys?.length) ?? 1) - 1) * 4}
                  mt={index === 0 ? 0 : 10}
                  mb={10}
                >
                  <ProductThumbnailCard
                    imageKey={imageKeys[index]}
                    active={index === selectedIndex}
                    onClick={() => setSelectedIndex(index)}
                    index={index}
                    catalogSource={catalogSource}
                  />
                </MarginBox>
              )
            );
          })}
      </MarginBox>
    </ScrollContainer>
  );
};
