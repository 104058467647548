import React, { ReactNode } from 'react';
import { Text as PdfText, View } from '@react-pdf/renderer';
import { Text } from 'components/Pdf/PdfUI';
import { components, fonts, styles } from 'components/Pdf/PdfUI.styles';

export const TitleItem = ({ icon, label, text }: { icon: ReactNode; label: ReactNode; text: ReactNode }) => {
  return (
    <View style={[styles.row, { minHeight: 27, width: 180 }, components.dividerBottom, { marginRight: 5 }]}>
      <View style={{ minWidth: 30, minHeight: 25 }}>
        <View
          style={[
            styles.col,
            { minWidth: 20, minHeight: 23, justifyContent: 'center', alignItems: 'center', marginTop: 1 },
          ]}
        >
          {icon}
        </View>
      </View>
      <View style={styles.column}>
        <View style={[styles.col, { width: 50 }]}>
          <PdfText style={[fonts.h6, fonts.bold, { marginTop: 8 }]}>{label}</PdfText>
        </View>
      </View>
      <View style={{ maxWidth: 60 }}>
        <View style={[styles.col, { marginTop: 8 }]}>
          <Text>{text}</Text>
        </View>
      </View>
    </View>
  );
};
