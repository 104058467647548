import { GET_SSO_LINK_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { GET_SSO_LINK } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { SSOLinkRequest } from '@1po/1po-bff-fe-spec/generated/sso/SSOLinkRequest';
import { RequestResponseMappingManager } from 'domains/webSockets/WebSocket.requestsResponseMapping';
import { WebSocketAction } from 'utils';
import { wsSendQueryAction } from 'utils/domainStore/api';

RequestResponseMappingManager.addMapping(GET_SSO_LINK, GET_SSO_LINK_RESPONSE);

export function sendGetSSOLink(request: SSOLinkRequest): WebSocketAction {
  return wsSendQueryAction(GET_SSO_LINK, request);
}
