/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getUniversalProduct } from 'domains/catalog/Catalog.store';
import { getSparePartsView } from 'domains/user';
import { Box, CenteredSpin } from 'UI';
import { ProductPreview } from 'UI/ProductPreview';
import { ProductPreviewCard } from 'UI/ProductPreview/ProductPreview.styled';
import { getData } from 'utils';

export interface UniversalProductProps {
  referenceNumber: string;
}

const UniversalProduct = ({ referenceNumber }: UniversalProductProps) => {
  const { t } = useTranslation();
  const sparePartsView = useSelector(getSparePartsView);
  const universalProductReference = useSelector((state: RootState) => getUniversalProduct(state, referenceNumber));
  const universalProductReferenceData = getData(universalProductReference);

  return (
    <DataContainer
      data={universalProductReference}
      Loading={() => (
        <ProductPreviewCard direction={'column'} minWidth={300} maxWidth={300} minHeight={500} align={'center'}>
          <Box width={300} height={500}>
            <CenteredSpin />
          </Box>
        </ProductPreviewCard>
      )}
      ErrorState={() => (
        <ProductPreviewCard direction={'column'} minWidth={300} maxWidth={300} minHeight={500} align={'center'}>
          <ErrorWithLabel
            label={t(
              'catalog.universal_products.backend_error',
              'This Universal product is temporarily unavailable, please try again later.',
            )}
          />
        </ProductPreviewCard>
      )}
    >
      <ProductPreview
        imageUrl={universalProductReferenceData?.imageKey}
        productName={universalProductReferenceData?.productName}
        referenceNumber={referenceNumber}
        sparePartsView={sparePartsView}
      />
    </DataContainer>
  );
};

export default UniversalProduct;
