import styled from 'styled-components';
import { effect } from 'styles/effect';

export const SSearchHistoryItem = styled.div`
  cursor: pointer;
  background-color: white;
  ${effect.hover.dim}
`;

export const SSearchHistoryContent = styled.div`
  padding: 10px 10px 5px 10px;
  white-space: nowrap;
  min-width: 500px;
  overflow: auto;
  max-height: 312px;
`;
