import styled from 'styled-components';
import { useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';

export const DeleteIconBackground = styled.div`
  width: 45px;
  height: 45px;
  padding: 12px 0 0 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.grey95};
  cursor: pointer;
`;

const minWidth = {
  xs: 0,
  sm: 360,
  md: 766,
  lg: 950,
  xl: 1000,
  xxl: 1000,
};

export const ViewContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  min-width: ${() => useBreakpointSelectorFull()(minWidth) + 'px'};
`;
