import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from 'UI';

const SpecialInformation = ({ specialInformation }: { specialInformation: string[] }) => {
  const { t } = useTranslation();
  if (specialInformation.length === 0) return <></>;
  return (
    <Flex>
      <Flex direction={'column'}>
        <Text type={'h5_bold'}>{t('catalog.maintenance.special_information', 'Special information')}</Text>
        <Box height={17} />
        {specialInformation.map((operation) => {
          return (
            <Text key={operation} type={'text_dim'}>
              {operation}
            </Text>
          );
        })}
      </Flex>
      <Flex />
    </Flex>
  );
};

export default SpecialInformation;
