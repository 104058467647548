import React from 'react';
import { Breadcrumb as BreadcrumbAnt } from 'antd';
import { Link, sentenceCase, Text } from 'UI';

export const BREADCRUMB_HEIGHT = 55;

export interface BreadcrumbProps {
  items: {
    link: string;
    label: string;
  }[];
}

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
  const isLastItem = (i: number) => items.length === ++i;
  return (
    <BreadcrumbAnt separator={'>'}>
      {items.map(({ link, label }, index) => (
        <BreadcrumbAnt.Item key={`breadcrumb-${index}`}>
          {isLastItem(index) ? (
            <Text type={'text_dim'}>{sentenceCase(label)}</Text>
          ) : (
            <Link to={link}>
              <Text hoverUnderLine cursor={'pointer'} type={'dark_12_white_65'}>
                {sentenceCase(label)}
              </Text>
            </Link>
          )}
        </BreadcrumbAnt.Item>
      ))}
    </BreadcrumbAnt>
  );
};
