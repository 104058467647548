import React, { useEffect, useState } from 'react';
import { Input as AntdInput } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import { useInput, UseInputParams } from 'utils/hooks/useInput';

export interface TextAreaProps
  extends UseInputParams<string, HTMLTextAreaElement>,
    Omit<AntdTextAreaProps, 'onChange' | 'onPressEnter' | 'onBlur' | 'defaultValue' | 'onFocus'> {
  initialValue?: string;
  onPressEnter?: (value: string) => void;
  allowEnterNextLine?: boolean;
}

const STextArea = styled(AntdInput.TextArea)`
  cursor: auto;
`;

export const TextArea = ({
  initialValue,
  onChange,
  onChangeDebounced,
  onBlur,
  onBlurDebounced,
  onPressEnter,
  allowEnterNextLine = false,
  ...props
}: TextAreaProps) => {
  const inputs = useInput<string, HTMLTextAreaElement>({
    onChange,
    onChangeDebounced,
    onBlur,
    onBlurDebounced,
  });
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  useEffect(() => {
    if (!focus) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, setValue]);

  return (
    <STextArea
      rows={props.size === 'large' ? 5 : 2}
      {...props}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        inputs.onChange(e.target.value, e);
      }}
      onBlur={(e) => {
        setValue(e.target.value);
        setFocus(false);
        inputs.onBlur(e.target.value, e);
      }}
      onPressEnter={(e) => {
        if (onPressEnter) onPressEnter(e.currentTarget.value);
        else !allowEnterNextLine && e.currentTarget.blur();
      }}
      onFocus={() => setFocus(true)}
    />
  );
};
