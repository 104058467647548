import React from 'react';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataPT = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>RPARTSTORE</Text>
          <Text type={'h5_bold'}>NOTA INFORMATIVA SOBRE</Text>
          <Text type={'h5_bold'}>PROTEÇÃO DE DADOS PESSOAIS</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            O site RPARTSTORE, acessível em{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              {'https://rpartstore.renault.com'}
            </Link>{' '}
            (&quot;a Plataforma&quot;), foi criado para facilitar a consulta da documentação técnica pós-venda de peças
            e acessórios Renault (&quot;os Produtos&quot;), bem como para permitir a reunião de vendedores (&quot;os
            Vendedores&quot;) que pretendam propor a venda de Produtos através de um site aos compradores (&quot;os
            Compradores&quot;), de acordo com as CGV da Plataforma. Os Vendedores e os Compradores são designados
            conjuntamente por &quot;Utilizadores&quot;.
          </Paragraph>
          <Paragraph>
            A presente nota informativa sobre a proteção de dados tem por objetivo assegurar a transparência do
            tratamento dos dados pessoais efetuado no âmbito da sua utilização da Plataforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Para que são utilizados os seus dados e durante quanto tempo?'} />
          <Paragraph>
            Ao utilizar a Plataforma, os seus dados pessoais são tratados para as seguintes finalidades:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                <strong>Para gerir os seus direitos de acesso e autorização na Plataforma</strong> com base no nosso
                interesse legítimo em gerir a confidencialidade e segurança dos seus dados pessoais. Tratamos estes
                dados durante o período de tempo em que a sua conta de utilizador esteve aberta, a menos que tenha
                estado inativa.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Gerir a segurança e o bom funcionamento das aplicações e redes</strong> com base no nosso
                interesse legítimo em garantir a segurança informática da Plataforma. Tratamos os seus dados durante 30
                dias nas nossas bases de dados.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>
                  Para responder, se for caso disso, aos seus pedidos de exercício dos seus direitos relativamente aos
                  seus dados pessoais
                </strong>
                {', '}de acordo com as nossas obrigações legais e/ou regulamentares. Os seus dados pessoais são
                conservados pelo tempo necessário para responder ao seu pedido. No entanto, precisamos de conservar
                provas arquivadas relativas à nossa resposta ao exercício dos seus direitos (incluindo, quando
                aplicável, os dados pessoais que compilámos para este efeito) para podermos responder a qualquer ação
                legal, durante o período de prescrição estipulado pela legislação aplicável.
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            Além disso, a Plataforma utiliza cookies e outras tecnologias de rastreio para gerir o seu funcionamento e
            segurança, bem como para medir a audiência e o desempenho da Plataforma nas condições definidas na Política
            de Cookies relativa à Plataforma, que pode ser{' '}
            <Link to={ROUTER_COOKIES(Country.PT.key)} external newTab>
              Acesso aqui
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Também podemos obter informações adicionais sobre si através de outras empresas do nosso Grupo ou de
            parceiros, com o seu consentimento quando tal seja legalmente exigido.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Que dados pessoais são tratados?'} />
          <Paragraph>
            Os dados pessoais que recolhemos dependem da nossa interação com o utilizador e incluem dados relativos:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                À sua identidade e os seus dados de contacto (apelido, nome próprio, endereço postal profissional e
                endereço de correio eletrónico);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>Identificadores técnicos (número IPN);</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Dados de ligação (registos de atividade, cookies) de acordo com a Plataforma{' '}
                <Link to={ROUTER_COOKIES(Country.PT.key)} external newTab>
                  Política de Cookies
                </Link>
                .
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
        </Section>
        <Section>
          <SectionHeader title={'Quem trata os seus dados pessoais?'} />
          <Paragraph>
            As operações de tratamento acima referidas são realizadas pela Renault SAS, quando aplicável, em colaboração
            com a filial da Renault que opera no seu país (*). Além disso, os Vendedores, membros da rede principal
            desta última, responsáveis pelas atividades de distribuição, participam na gestão dos seus direitos de
            acesso e autorização na Plataforma, criando perfis de Utilizador nos diretórios internos e ferramentas de
            autenticação do Grupo Renault.
          </Paragraph>
          <Paragraph>
            <i>
              (*) Para conhecer a identidade jurídica da filial da Renault que opera no seu país, visite o{' '}
              <Link to={'https://www.renaultgroup.com/en/directory/'} external newTab>
                nosso diretório
              </Link>
              , selecione o seu país na lista e consulte as respetivas informações legais.
            </i>
          </Paragraph>
          <Paragraph>
            <i>
              A Renault SAS está localizada em França, 122-122 bis avenue du Général Leclerc – 92 100
              Boulogne-Billancourt. Os dados de contacto do seu encarregado da proteção de dados (EPD) são os seguintes:
              Renault SAS, Direction juridique – Délégué à la protection des données, 122-122 bis avenue du Général
              Leclerc – 92 100 Boulogne-Billancourt.
            </i>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Quem tem acesso aos seus dados pessoais?'} />
          <Paragraph>
            Como parte das nossas operações de tratamento de dados, os seus dados pessoais estão acessíveis ao pessoal
            interno devidamente autorizado e aos nossos prestadores de serviços terceiros de confiança, agindo como
            subcontratados sob as nossas instruções e apenas em nosso nome. Além disso, podemos comunicar os seus dados
            pessoais a terceiros para cumprir qualquer obrigação legal ou decisão administrativa ou judicial.
          </Paragraph>
          <Paragraph>
            Como alguns destes prestadores de serviços ou os seus subcontratados estão localizados em países fora da
            UE/EEE, concordamos em implementar salvaguardas adequadas através da assinatura de Cláusulas
            Contratuais-Tipo (CCP).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Quais são os seus direitos relativamente aos seus dados pessoais?'} />
          <Paragraph>
            Em conformidade com a regulamentação aplicável, em relação às atividades de tratamento descritas neste aviso
            informativo, tem ao seu dispor o:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                <strong>Direito de acesso:</strong> para obter informações sobre o tratamento dos seus dados pessoais
                (em particular, que dados são utilizados, para que finalidades, etc.) e uma cópia desses dados.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Direito de retificação:</strong> para obter a retificação dos seus dados pessoais se estes
                estiverem incorretos ou incompletos.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Direito de retirar o seu consentimento a qualquer momento</strong>, para os fins para os quais o
                mesmo foi obtido.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Direito ao apagamento:</strong> para obter o apagamento dos seus dados pessoais, sendo
                especificado que este direito pode ser limitado tendo em conta as nossas obrigações contratuais ou
                legais.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Direito de oposição:</strong> para se opor à utilização de alguns dos seus dados pessoais. Deve
                apresentar razões específicas relacionadas com a sua situação específica.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Direito à limitação do tratamento:</strong> para congelar a utilização dos seus dados, em
                especial se contestar a exatidão dos seus dados pessoais, se se tiver oposto ao tratamento dos seus
                dados pessoais ou se necessitar desses dados, por exemplo, para exercer ou defender um direito, e se
                quiser certificar-se de que esses dados não serão apagados (por exemplo, no caso de ser atingido o
                período de conservação).
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Direito de portabilidade dos seus dados pessoais</strong>, ou seja, em determinadas condições, o
                direito de receber os dados pessoais que nos forneceu num formato informático estruturado, correntemente
                utilizado, para que os possa transmitir a terceiros, se tal for tecnicamente possível.
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            Pode exercer todos os seus direitos enviando o seu pedido para:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            . Na ausência de uma resposta ou resposta satisfatória da Renault Portugal, S.A., pode apresentar uma
            reclamação junto da Comissão Nacional de Proteção de Dados (
            <Link to={'https://www.cnpd.pt/'} external newTab>
              https://www.cnpd.pt/
            </Link>
            ), relativa ao tratamento dos seus dados pessoais. Agradecemos que, antes de efetuar qualquer reclamação,
            nos contacte, para que tentemos resolver o seu problema em conjunto.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Versão atualizada: agosto de 2024</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
