/* eslint-disable max-len */
import { BasketReferenceType } from '@1po/1po-bff-fe-spec/generated/basket/request/model/BasketReferenceType';
import {
  RemoveExternalBasketReferencesDetail,
  RemoveVehicleReferencesDetail,
} from '@1po/1po-bff-fe-spec/generated/basket/request/RemoveReferences';
import { Origin, ReferenceSource } from '@1po/1po-bff-fe-spec/generated/basket/request/UpdateReferenceQuantity';
import { ExpiredReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/basket/response/BasketResponse';
import { ExternalBasketSection } from '@1po/1po-bff-fe-spec/generated/basket/response/model/ExternalBasketSection';
import { OtherSection } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { Reference } from '@1po/1po-bff-fe-spec/generated/basket/response/model/Reference';
import { ReferenceErrorRow } from '@1po/1po-bff-fe-spec/generated/basket/response/model/ReferenceErrorRow';
import { ReferenceType, VehicleSection } from '@1po/1po-bff-fe-spec/generated/basket/response/model/VehicleSection';
import {
  ADD_BASKET_OTHER_REFERENCE_RESPONSE,
  BASKET_ATTACH_OTHER_REFERENCE_CAR_NONCOMPATIBLE_RESPONSE,
  BASKET_ATTACH_OTHER_REFERENCE_CAR_RESPONSE,
  BASKET_INVALID_REFERENCE_QUANTITY_UPDATE_RESPONSE,
  GET_USER_BASKET_RESPONSE,
  UPLOAD_BASKET_REFERENCES_FILE_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/model/TireItem';
import { createAction, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import {
  ADD_OTHER_SECTION_REFERENCE,
  ADD_OTHER_SECTION_REFERENCES,
  ADD_REFERENCE_BY_REF_NUMBER,
  ADD_TIRE_REFERENCE,
  ADD_VEHICLE_REFERENCES,
  ATTACH_OTHER_NON_APPLICABLE_REFERENCE,
  ATTACH_OTHER_REFERENCE,
  BASKET_NAMESPACE,
  BasketActionStatus,
  BasketState,
  ExternalBasketSectionLocal,
  FETCH_BASKET,
  NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS,
  OtherReferenceAttachStatus,
  REFERENCE_QUANTITY_LIMIT,
  ReferenceLocal,
  REMOVE_REFERENCES,
  REMOVE_SINGLE_REFERENCE,
  REVERT_ATTACH_OTHER_REFERENCE,
  REVERT_LAST_REMOVAL,
  SET_ALL_REFERENCE_URGENCY_FLAGS,
  SET_ORDER_MARK,
  SET_REFERENCE_MARK,
  SET_REFERENCE_URGENCY_FLAG,
  UPDATE_REFERENCE_QUANTITY,
  UPLOAD_FILE_REFERENCES,
  VehicleLocal,
} from 'domains/basket/Basket.types';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { DHReferenceLocal, IAMReferenceLocal, PlateReference, ReferenceTradingDataDetail } from 'domains/references';
import { refWithoutDepositRef } from 'domains/references/DepositReference';
import { FOUND, SEARCH_STATUS } from 'utils';
import { getTradingDataMap } from './Basket.mapper';
import 'map.prototype.tojson';

export function isRefMKTP(reference?: ReferenceLocal): boolean {
  return reference?.referenceSource === 'MARKETPLACE';
}

function sumReferencesPrice(references?: Reference[], includeVat = false): number {
  if (!references || references?.length === 0) {
    return 0;
  }
  return references?.reduce((acc: number, next: Reference) => {
    return (
      acc +
      (includeVat
        ? next?.isUrgent && !isNaN(Number(next?.price?.vatIncludedUrgentDeliveryAdditionalPrice))
          ? Number(next?.price?.priceVatIncluded) + Number(next?.price?.vatIncludedUrgentDeliveryAdditionalPrice)
          : Number(next?.price?.priceVatIncluded)
        : next?.isUrgent && !isNaN(Number(next?.price?.vatExcludedUrgentDeliveryAdditionalPrice))
        ? Number(next?.price?.priceVatExcluded) + Number(next?.price?.vatExcludedUrgentDeliveryAdditionalPrice)
        : Number(next?.price?.priceVatExcluded)) *
        next.quantity
    );
  }, 0);
}

export const computeTotalPrice = (
  vehicles?: VehicleLocal[] | undefined,
  externalBaskets?: ExternalBasketSection[] | undefined,
  otherReferences?: Reference[] | undefined,
  includeVat = false,
): number => {
  const vehiclesTotalPrice = vehicles
    ? vehicles.reduce(
        (acc: number, vehicle: VehicleLocal) => acc + sumReferencesPrice(vehicle?.references, includeVat),
        0,
      )
    : 0;
  const externalsTotalPrice = externalBaskets
    ? externalBaskets.reduce(
        (acc: number, external: ExternalBasketSection) => acc + sumReferencesPrice(external?.references, includeVat),
        0,
      )
    : 0;

  const otherSectionTotalPrice = sumReferencesPrice(otherReferences, includeVat);
  return vehiclesTotalPrice + externalsTotalPrice + otherSectionTotalPrice;
};

function updateReferencesQuantity(references: Draft<Reference[]>, referenceNumber: string, newQuantity: number): void {
  const referenceIndex = references.findIndex((itemX) => {
    const item = itemX as Reference;
    return item.referenceNumber === referenceNumber;
  });
  if (referenceIndex !== -1) {
    references[referenceIndex].quantity = Math.min(Number(newQuantity), REFERENCE_QUANTITY_LIMIT);
  }
}

// Init state
const initialState: BasketState = {
  basketId: {
    searchStatus: undefined,
  },
  totalPriceVatIncluded: undefined,
  totalPriceVatExcluded: undefined,
  referenceTradingData: new Map<string, ReferenceTradingDataDetail>(),
  vehicles: [],
  externalBaskets: [],
  actionStatuses: new Map<string, BasketActionStatus>(),
  fileReferencesUploadStatus: { modalStatus: NO_FILE, isModalOpen: false, errorRows: [] },
  otherSection: { references: [], note: '' },
  otherReferenceAttachStatus: new Map<string, OtherReferenceAttachStatus>(),
  expiredDiscountsReferences: [],
};

// Saga actions
export const fetchBasketRequest = createAction(FETCH_BASKET);
export const fetchBasketResponse = createAction(GET_USER_BASKET_RESPONSE);
export const handleInvalidReferenceQuantityUpdate = createAction(BASKET_INVALID_REFERENCE_QUANTITY_UPDATE_RESPONSE);

export const addReferenceRequest = createAction<PlateReference>(ADD_VEHICLE_REFERENCES);
export const addTireRequest = createAction<TireItem>(ADD_TIRE_REFERENCE);
export const addOtherReferenceRequest = createAction<{
  reference: string;
  isDH: boolean;
}>(ADD_OTHER_SECTION_REFERENCE);
export const addOtherSectionReferenceRequest = createAction<{
  reference: DHReferenceLocal | IAMReferenceLocal | undefined;
}>(ADD_OTHER_SECTION_REFERENCES);
export const addReferenceByRefNumberRequest = createAction<{
  reference: string;
}>(ADD_REFERENCE_BY_REF_NUMBER);
export const addReferenceByRefNumberResponse = createAction(ADD_BASKET_OTHER_REFERENCE_RESPONSE);
export const removeReferencesRequest = createAction<{
  vehiclesWithReferences?: RemoveVehicleReferencesDetail[];
  externalBasketsWithReferences?: RemoveExternalBasketReferencesDetail[];
  otherReferences?: string[];
}>(REMOVE_REFERENCES);
export const removeSingleReferenceRequest = createAction<{
  basketReferenceType: BasketReferenceType;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  reference: string;
}>(REMOVE_SINGLE_REFERENCE);
export const revertLastRemovalRequest = createAction(REVERT_LAST_REMOVAL);
export const updateReferenceQuantityRequest = createAction<{
  basketReferenceType: BasketReferenceType;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  referenceNumber: string;
  newQuantity: number;
  origin?: Origin;
  supplierCode?: string;
  referenceSource?: ReferenceSource;
  referenceType: ReferenceType;
}>(UPDATE_REFERENCE_QUANTITY);
export const updateOrderMarkRequest = createAction<{
  basketReferenceType: BasketReferenceType;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  orderMark: string;
}>(SET_ORDER_MARK);
export const updateReferenceMarkRequest = createAction<{
  basketReferenceType: BasketReferenceType;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  referenceNumber: string;
  referenceMark: string;
}>(SET_REFERENCE_MARK);
export const uploadFileReferencesRequest = createAction<{
  fileName: string;
  fileBase64: string;
}>(UPLOAD_FILE_REFERENCES);
export const uploadFileReferencesResponse = createAction(UPLOAD_BASKET_REFERENCES_FILE_RESPONSE);
export const updateUrgencyFlagRequest = createAction<{
  basketReferenceType: BasketReferenceType;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  referenceNumber: string;
  isUrgent: boolean;
}>(SET_REFERENCE_URGENCY_FLAG);
export const updateAllUrgencyFlagsRequest = createAction<{
  basketReferenceType: BasketReferenceType;
  vehicleKey: string | undefined;
  externalBasketId: string | undefined;
  isUrgent: boolean;
}>(SET_ALL_REFERENCE_URGENCY_FLAGS);
export const attachOtherReferenceToVehicleRequest = createAction<{
  vehicleKey: string;
  referenceNumber: string;
}>(ATTACH_OTHER_REFERENCE);
export const attachOtherNonApplicableReferenceToVehicleRequest = createAction<{
  vehicleKey: string | undefined;
  referenceNumber: string;
}>(ATTACH_OTHER_NON_APPLICABLE_REFERENCE);
export const confirmMoveNonCompatibleOtherSectionReferenceToVehicleResponse = createAction(
  BASKET_ATTACH_OTHER_REFERENCE_CAR_NONCOMPATIBLE_RESPONSE,
);
export const otherSectionReferenceMovedToVehicleResponse = createAction(BASKET_ATTACH_OTHER_REFERENCE_CAR_RESPONSE);
export const revertMoveOtherReferenceToVehicle = createAction(REVERT_ATTACH_OTHER_REFERENCE);
export const notifyUserAboutExpiredDiscountsRequest = createAction(NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS);
// Slice
const slice = createSlice({
  name: BASKET_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setBasket: (
      state,
      {
        payload,
      }: PayloadAction<{
        basketId: string;
        vehicles?: VehicleSection[];
        otherSection?: OtherSection;
        rememberIsSelected?: boolean;
        expiredReferenceDiscounts?: ExpiredReferenceDiscount[];
        externalBaskets?: ExternalBasketSection[];
      }>,
    ) => {
      const {
        basketId,
        vehicles,
        otherSection,
        rememberIsSelected,
        expiredReferenceDiscounts,
        externalBaskets,
      } = payload;
      if (rememberIsSelected) {
        if (vehicles) {
          vehicles.forEach((vehicle) => {
            const localVehicleRaw = state.vehicles as VehicleLocal[] | undefined;
            const localVehicle = localVehicleRaw?.find(
              (v) => v.vehicleDetail.vehicleKey === vehicle.vehicleDetail.vehicleKey,
            );
            vehicle.references = vehicle.references.map((reference) => {
              const localRef = localVehicle?.references?.find((r) => r.referenceNumber === reference.referenceNumber);
              return { ...reference, isSelected: localRef?.isSelected ?? true };
            });
          });
        }
        if (otherSection) {
          otherSection.references = otherSection.references.map((reference) => {
            const localRef = state.otherSection?.references.find(
              (r) => r.referenceNumber === reference.referenceNumber,
            ) as ReferenceLocal;
            return { ...reference, isSelected: localRef?.isSelected ?? true };
          });
        }
        if (externalBaskets) {
          externalBaskets.forEach((exBasket) => {
            const localExtBasketRaw = state.externalBaskets as ExternalBasketSectionLocal[] | undefined;
            const localExtBasket = localExtBasketRaw?.find((b) => b.externalBasketId === exBasket.externalBasketId);
            exBasket.references = exBasket.references.map((reference) => {
              const localRef = localExtBasket?.references?.find((r) => r.referenceNumber === reference.referenceNumber);
              return { ...reference, isSelected: localRef?.isSelected ?? true };
            });
          });
        }
        state.expiredDiscountsReferences = expiredReferenceDiscounts ?? [];
      }
      state.vehicles = vehicles;
      if (otherSection) state.otherSection = otherSection;
      if (externalBaskets) state.externalBaskets = externalBaskets;
      state.basketId.data = basketId;
      state.basketId.searchStatus = FOUND;
      state.referenceTradingData = getTradingDataMap(vehicles, externalBaskets, otherSection);
    },
    updateTradingDataMap: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicles?: VehicleSection[];
        otherSection?: OtherSection;
        externalBaskets?: ExternalBasketSection[];
      }>,
    ) => {
      const { vehicles, otherSection, externalBaskets } = payload;
      state.referenceTradingData = getTradingDataMap(vehicles, externalBaskets, otherSection);
    },
    setTotalPrices: (
      state,
      {
        payload,
      }: PayloadAction<{
        vatIncluded: number;
        vatExcluded: number;
        totalDiscount: number;
      }>,
    ) => {
      state.totalPriceVatIncluded = payload.vatIncluded;
      state.totalPriceVatExcluded = payload.vatExcluded;
      state.totalDiscount = payload.totalDiscount;
    },
    addVehicleReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleDetail: VehicleDetail;
        references: Reference[];
      }>,
    ) => {
      const { vehicleDetail, references } = payload;
      const vehicleKey = vehicleDetail?.vehicleKey;
      const index = state.vehicles?.findIndex((searchedVehicle) => {
        return searchedVehicle?.vehicleDetail?.vehicleKey === vehicleKey;
      }) as number;

      if (index === -1) {
        const validatedReferences = references.map((ref) => ({
          ...ref,
          quantity: Math.min(ref.quantity, REFERENCE_QUANTITY_LIMIT),
        }));
        const newVehicle: VehicleLocal = {
          vehicleDetail,
          references: validatedReferences,
        };
        state.vehicles?.push(newVehicle);
      } else {
        if (state.vehicles) {
          const vehicle = state.vehicles[index];

          references?.forEach((ref) => {
            if (vehicle.references) {
              const referenceIndex = vehicle.references.findIndex(
                (item) => item.referenceNumber === ref.referenceNumber,
              );
              if (referenceIndex !== -1) {
                const oldQuantity = vehicle.references[referenceIndex].quantity ?? 0;
                vehicle.references[referenceIndex] = {
                  ...ref,
                  quantity: Math.min(ref.quantity + oldQuantity, REFERENCE_QUANTITY_LIMIT),
                };
              } else {
                vehicle.references.push(ref);
              }
            }
          });
        }
      }

      state.totalPriceVatIncluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        true,
      );
      state.totalPriceVatExcluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        false,
      );
    },
    addOtherReference: (
      state,
      {
        payload,
      }: PayloadAction<{
        reference: Reference;
      }>,
    ) => {
      const { reference } = payload;
      const otherReferences = state.otherSection.references;
      const referenceIndex = otherReferences.findIndex((item) => item.referenceNumber === reference.referenceNumber);

      if (referenceIndex !== -1) {
        const oldQuantity = otherReferences[referenceIndex].quantity;
        otherReferences[referenceIndex].quantity = Math.min(reference.quantity + oldQuantity, REFERENCE_QUANTITY_LIMIT);
      } else {
        otherReferences.push(reference);
      }

      state.totalPriceVatIncluded = computeTotalPrice(state.vehicles, state.externalBaskets, otherReferences, true);
      state.totalPriceVatExcluded = computeTotalPrice(state.vehicles, state.externalBaskets, otherReferences, false);
    },
    removeReference: (
      state,
      {
        payload,
      }: PayloadAction<{
        basketReferenceType: BasketReferenceType;
        reference: string;
        vehicleKey?: string;
        externalBasketId?: string;
      }>,
    ) => {
      const { reference, vehicleKey, basketReferenceType, externalBasketId } = payload;

      switch (basketReferenceType) {
        case 'VEHICLE': {
          if (vehicleKey) {
            const index = state.vehicles?.findIndex(({ vehicleDetail }) => vehicleDetail?.vehicleKey === vehicleKey);
            if (index === -1 || typeof index !== 'number') return;
            const vehicle = state.vehicles?.[index];
            if (!vehicle) return;

            vehicle.references = vehicle.references?.filter((ref) => reference !== ref.referenceNumber) ?? [];
            // remove vehicle from basket if the vehicle has no references
            state.vehicles = state.vehicles?.filter((v) => v?.references && v.references.length > 0);
          }
          break;
        }
        case 'OTHER': {
          if (!state.otherSection || !state.otherSection.references) return;
          state.otherSection.references = state.otherSection.references.filter(
            (ref) => reference !== ref.referenceNumber,
          );
          break;
        }
        case 'EXTERNAL_BASKET': {
          if (externalBasketId) {
            const index = state.externalBaskets?.findIndex(
              (exBasket) => exBasket?.externalBasketId === externalBasketId,
            );
            if (index === -1 || typeof index !== 'number') return;
            const exBasket = state.externalBaskets?.[index];
            if (!exBasket) return;

            exBasket.references = exBasket.references?.filter((ref) => reference !== ref.referenceNumber) ?? [];
            // remove exBasket from basket if the exBasket has no references
            state.vehicles = state.vehicles?.filter((v) => v?.references && v.references.length > 0);
          }
          break;
        }
      }

      state.totalPriceVatIncluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        true,
      );
      state.totalPriceVatExcluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        false,
      );
    },
    removeReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehiclesWithReferences?: RemoveVehicleReferencesDetail[];
        otherReferences?: string[];
        externalBasketsWithReferences?: RemoveExternalBasketReferencesDetail[];
      }>,
    ) => {
      const { vehiclesWithReferences, otherReferences, externalBasketsWithReferences } = payload;
      if (Array.isArray(vehiclesWithReferences)) {
        vehiclesWithReferences.forEach((vehicleWithReferences: RemoveVehicleReferencesDetail) => {
          const index = state.vehicles?.findIndex(
            ({ vehicleDetail }) => vehicleDetail?.vehicleKey === vehicleWithReferences.vehicleKey,
          );
          if (index === -1 || typeof index !== 'number') return;
          const vehicle = state.vehicles?.[index];
          if (!vehicle) return;

          vehicle.references = vehicle.references.filter(
            (ref) => !vehicleWithReferences.references.includes(ref.referenceNumber),
          );
          state.vehicles = state.vehicles?.filter((v) => v?.references && v.references.length > 0);
        });
      }

      if (Array.isArray(otherReferences) && state.otherSection?.references) {
        state.otherSection.references = state.otherSection.references.filter(
          (ref) => !otherReferences.includes(ref.referenceNumber),
        );
      }

      if (Array.isArray(externalBasketsWithReferences)) {
        externalBasketsWithReferences.forEach((extBasketDetail: RemoveExternalBasketReferencesDetail) => {
          const index = state.externalBaskets?.findIndex(
            (exBasket) => exBasket?.externalBasketId === extBasketDetail.externalBasketId,
          );
          if (index === -1 || typeof index !== 'number') return;
          const exBasket = state.externalBaskets?.[index];
          if (!exBasket) return;

          exBasket.references = exBasket.references.filter(
            (ref) => !extBasketDetail.references.includes(ref.referenceNumber),
          );
          state.externalBaskets = state.externalBaskets?.filter((v) => v?.references && v.references.length > 0);
        });
      }

      state.totalPriceVatIncluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        true,
      );
      state.totalPriceVatExcluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        false,
      );
    },
    setReferencesIsSelected: (
      state,
      {
        payload,
      }: PayloadAction<{
        isSelected: boolean;
        referenceIds: string[];
        vehicleKey?: string;
        externalBasketId?: string;
      }>,
    ) => {
      const { isSelected, referenceIds, vehicleKey, externalBasketId } = payload;
      if (vehicleKey) {
        const vehicles = state.vehicles as VehicleLocal[];
        state.vehicles = vehicles.map((v) => {
          if (v.vehicleDetail.vehicleKey === vehicleKey) {
            const newReferences = v.references?.map((ref) =>
              referenceIds.includes(ref.referenceNumber) ? { ...ref, isSelected } : ref,
            );
            return { ...v, references: newReferences };
          } else {
            return v;
          }
        });
      } else if (externalBasketId) {
        const externalBaskets = state.externalBaskets;
        state.externalBaskets = externalBaskets.map((exBasket) => {
          if (exBasket.externalBasketId === externalBasketId) {
            const newReferences = exBasket.references.map((ref) =>
              referenceIds.includes(ref.referenceNumber) ? { ...ref, isSelected } : ref,
            );
            return { ...exBasket, references: newReferences };
          } else {
            return exBasket;
          }
        });
      } else {
        if (!state.otherSection || !state.otherSection.references) return;
        state.otherSection.references = state.otherSection.references.map((ref) =>
          referenceIds.includes(ref.referenceNumber) ? { ...ref, isSelected } : ref,
        );
      }
    },
    updateReferenceQuantity: (
      state,
      {
        payload,
      }: PayloadAction<{
        referenceNumber: string;
        newQuantity: number;
        vehicleKey: string | undefined;
        externalBasketId: string | undefined;
      }>,
    ) => {
      const { referenceNumber, newQuantity, vehicleKey, externalBasketId } = payload;
      if (vehicleKey) {
        const index = state.vehicles?.findIndex(({ vehicleDetail }) => vehicleDetail?.vehicleKey === vehicleKey);
        if (index === -1 || typeof index !== 'number') return;
        const vehicle = state.vehicles?.[index];
        if (!vehicle || !vehicle.references) {
          return;
        }
        updateReferencesQuantity(vehicle.references, referenceNumber, newQuantity);
      } else if (externalBasketId) {
        const externalBasket = state.externalBaskets?.find((e) => e.externalBasketId === externalBasketId);
        if (!externalBasket) {
          return;
        }
        updateReferencesQuantity(externalBasket.references, referenceNumber, newQuantity);
      } else {
        if (!state.otherSection || !state.otherSection.references) {
          return;
        }
        updateReferencesQuantity(state.otherSection.references, referenceNumber, newQuantity);
      }

      state.totalPriceVatIncluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        true,
      );
      state.totalPriceVatExcluded = computeTotalPrice(
        state.vehicles,
        state.externalBaskets,
        state.otherSection?.references,
        false,
      );
    },
    updateReferenceUrgencyFlag: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        externalBasketId: string | undefined;
        referenceNumbers: string[];
        isUrgent: boolean;
      }>,
    ) => {
      const { vehicleKey, referenceNumbers, isUrgent, externalBasketId } = payload;
      if (vehicleKey) {
        const vehicles = state.vehicles as VehicleLocal[];
        state.vehicles = vehicles.map((veh) => {
          if (veh.vehicleDetail.vehicleKey === vehicleKey) {
            const newReferences = veh.references?.map((ref) =>
              referenceNumbers.includes(ref.referenceNumber) ? { ...ref, isUrgent } : ref,
            );
            return { ...veh, references: newReferences };
          } else {
            return veh;
          }
        });
      } else if (externalBasketId) {
        const extBasket = state.externalBaskets;
        state.externalBaskets = extBasket.map((eb) => {
          if (eb.externalBasketId === externalBasketId) {
            const newReferences = eb.references?.map((ref) =>
              referenceNumbers.includes(ref.referenceNumber) ? { ...ref, isUrgent } : ref,
            );
            return { ...eb, references: newReferences };
          } else {
            return eb;
          }
        });
      } else {
        if (!state.otherSection || !state.otherSection.references) return;
        state.otherSection.references = state.otherSection.references.map((ref) =>
          referenceNumbers.includes(ref.referenceNumber) ? { ...ref, isUrgent } : ref,
        );
      }
    },
    setActionStatus: (state, { payload }: PayloadAction<BasketActionStatus>) => {
      state.actionStatuses.set(payload.reference, payload);
    },
    clearActionStatuses: (state) => {
      state.actionStatuses.clear();
    },
    setFileReferencesUploadStatusModalStatus: (state, { payload }: PayloadAction<ModalStatusType>) => {
      state.fileReferencesUploadStatus.modalStatus = payload;
    },
    setFileReferencesUploadStatusIsModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.fileReferencesUploadStatus.isModalOpen = payload;
    },
    setFileReferencesUploadStatusErrorRows: (state, { payload }: PayloadAction<ReferenceErrorRow[]>) => {
      state.fileReferencesUploadStatus.errorRows = payload.sort((a, b) => (a.rowNumber > b.rowNumber ? 1 : -1));
    },
    setBasketIdStatus: (state, { payload }: PayloadAction<SEARCH_STATUS>) => {
      state.basketId.searchStatus = payload;
    },
    setOtherReferenceAttachStatus: (state, { payload }: PayloadAction<OtherReferenceAttachStatus>) => {
      state.otherReferenceAttachStatus.set(payload.reference, payload);
    },
    clearAllOtherReferenceAttachStatus: (state) => {
      state.otherReferenceAttachStatus.clear();
    },
    clearOtherReferenceAttachStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        // vehicleKey: string | undefined;
        referenceNumber: string;
      }>,
    ) => {
      const { referenceNumber } = payload;
      state.otherReferenceAttachStatus.delete(referenceNumber);
    },
    moveOtherReferenceToVehicle: (
      state,
      {
        payload,
      }: PayloadAction<{
        referenceNumber?: string;
        vehicleKey?: string;
        isCompatible?: boolean;
      }>,
    ) => {
      const { referenceNumber, vehicleKey, isCompatible } = payload;
      if (vehicleKey && referenceNumber) {
        //get other
        const referenceToMove = state.otherSection.references.filter((ref) => referenceNumber === ref.referenceNumber);
        //add to vehicle
        const index = state.vehicles?.findIndex(({ vehicleDetail }) => vehicleDetail?.vehicleKey === vehicleKey);
        //must be existing car in basket
        if (index === -1 || typeof index !== 'number') return;
        const vehicle = state.vehicles?.[index];
        if (!vehicle) return;

        referenceToMove?.forEach((ref) => {
          if (vehicle.references) {
            const referenceIndex = vehicle.references.findIndex((item) => item.referenceNumber === ref.referenceNumber);
            if (referenceIndex !== -1) {
              const oldQuantity = vehicle.references[referenceIndex].quantity ?? 0;
              vehicle.references[referenceIndex] = {
                ...ref,
                quantity: Math.min(ref.quantity + oldQuantity, REFERENCE_QUANTITY_LIMIT),
                isCompatible: vehicle.references[referenceIndex].isCompatible,
              };
            } else {
              ref.isCompatible = isCompatible;
              vehicle.references.push(ref);
            }
          }
        });

        //remove from other
        state.otherSection.references = state.otherSection.references.filter(
          (ref) => referenceNumber !== ref.referenceNumber,
        );
      }
    },
    clearExpiredDiscountsReferences: (state) => {
      state.expiredDiscountsReferences = [];
    },
  },
});
// Actions
export const {
  setInitialState,
  setBasket,
  setTotalPrices,
  addVehicleReferences,
  addOtherReference,
  removeReference,
  removeReferences,
  setReferencesIsSelected,
  updateReferenceQuantity,
  updateReferenceUrgencyFlag,
  setActionStatus,
  clearActionStatuses,
  setFileReferencesUploadStatusModalStatus,
  setFileReferencesUploadStatusIsModalOpen,
  setFileReferencesUploadStatusErrorRows,
  setBasketIdStatus,
  setOtherReferenceAttachStatus,
  clearOtherReferenceAttachStatus,
  moveOtherReferenceToVehicle,
  clearExpiredDiscountsReferences,
  updateTradingDataMap,
} = slice.actions;

// Getters/Selectors
export const getBasketVehicles = createSelector(
  (state: RootState) => state.basket.vehicles,
  (vehicles) => {
    return vehicles ?? [];
  },
);

export const getBasketOtherSection = createSelector(
  (state: RootState) => state.basket.otherSection,
  (otherSection) => otherSection,
);

export const getBasketExternalSection = createSelector(
  (state: RootState) => state.basket.externalBaskets,
  (externalBaskets) => externalBaskets,
);

export const getBasketReference = createSelector(
  getBasketVehicles,
  getBasketOtherSection,
  (
    _state: RootState,
    payload: {
      referenceNumber: string;
      vehicleKey?: string;
    },
  ) => payload,
  (vehicles, otherSection, payload) => {
    const { referenceNumber, vehicleKey } = payload;
    let allReferences = otherSection.references;
    if (vehicleKey !== undefined) {
      const vehicleReferences = vehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)?.references;
      if (vehicleReferences !== undefined) {
        allReferences = [...allReferences, ...vehicleReferences];
      }
    }
    return allReferences?.find((ref) => ref.referenceNumber === referenceNumber);
  },
);

export const getReferenceFromBasket = createSelector(
  getBasketVehicles,
  getBasketOtherSection,
  (_state: RootState, referenceNumber?: string) => referenceNumber,
  (vehicles, otherSection, referenceNumber) => {
    let allReferences = otherSection.references;
    const vehicleReferences = vehicles.flatMap((vehicle) => vehicle.references);
    if (vehicleReferences !== undefined) {
      allReferences = [...allReferences, ...vehicleReferences];
    }
    return allReferences?.find((ref) => ref.referenceNumber === referenceNumber);
  },
);

export const getBasketPrices = createSelector(
  (state: RootState) => state.basket.totalPriceVatIncluded,
  (state: RootState) => state.basket.totalPriceVatExcluded,
  (state: RootState) => state.basket.totalDiscount,
  (totalPriceVatIncluded, totalPriceVatExcluded, totalDiscount) => {
    return {
      totalPriceVatIncluded,
      totalPriceVatExcluded,
      totalDiscount,
    };
  },
);

export const getActionStatus = createSelector(
  (state: RootState) => state.basket.actionStatuses,
  (_state: RootState, reference: string | undefined) => reference,
  (actionStatuses, reference) => {
    return reference ? actionStatuses.get(reference) : undefined;
  },
);

export const getFileReferencesUploadStatusModalStatus = createSelector(
  (state: RootState) => state.basket.fileReferencesUploadStatus.modalStatus,
  (modalStatus) => modalStatus,
);

export const getFileReferencesUploadStatusIsModalOpen = createSelector(
  (state: RootState) => state.basket.fileReferencesUploadStatus.isModalOpen,
  (isModalOpen) => isModalOpen,
);

export const getFileReferencesUploadStatusErrorRows = createSelector(
  (state: RootState) => state.basket.fileReferencesUploadStatus.errorRows,
  (errorRows) => errorRows,
);

export const getReferenceQuantity = createSelector(
  (state: RootState) => state.basket.vehicles,
  (state: RootState) => state.basket.otherSection.references,
  getLastSearchedVehicleKey,
  (
    _state: RootState,
    payload: {
      refNumber?: string;
      isApplicableToCurrentVehicle?: boolean;
    },
  ) => payload,
  (vehicles, otherReferences, lastSearchedVehicleKey, payload) => {
    if (!payload.refNumber) return 0;
    const basketReferences =
      payload.isApplicableToCurrentVehicle && lastSearchedVehicleKey
        ? vehicles?.find((vehicle) => vehicle.vehicleDetail.vehicleKey === lastSearchedVehicleKey)?.references
        : otherReferences;
    return basketReferences?.find((ref) => ref.referenceNumber === payload.refNumber)?.quantity ?? 0;
  },
);

export const getBasketId = createSelector(
  (state: RootState) => state.basket.basketId.data,
  (data) => data,
);

export const getBasketIdStatus = createSelector(
  (state: RootState) => state.basket.basketId.searchStatus,
  (searchStatus) => searchStatus,
);

export const getOtherReferenceAttachStatus = createSelector(
  (state: RootState) => state.basket.otherReferenceAttachStatus,
  (_state: RootState, status: string) => status,
  (otherReferenceAttachStatuses, filterStatus) => {
    return [...otherReferenceAttachStatuses.values()].filter((status) => status.type === filterStatus);
  },
);

export const getBasketNumberOfItems = createSelector(
  (state: RootState) => state.basket?.otherSection,
  (state: RootState) => state.basket?.externalBaskets,
  (state: RootState) => state.basket?.vehicles,
  (otherSection, externalBaskets, vehicles) =>
    (refWithoutDepositRef(otherSection?.references).length ?? 0) +
    (externalBaskets?.reduce((acc, next) => acc + (refWithoutDepositRef(next?.references).length ?? 0), 0) ?? 0) +
    (vehicles?.reduce((acc, next) => acc + (refWithoutDepositRef(next?.references).length ?? 0), 0) ?? 0),
);

export const getBasketReferenceTradingData = createSelector(
  (state: RootState) => state.basket.referenceTradingData,
  (referenceTradingData) => referenceTradingData,
);

export const getExpiredDiscountsReferences = createSelector(
  (state: RootState) => state.basket.expiredDiscountsReferences,
  (expiredDiscountsReferences) => expiredDiscountsReferences,
);

export const getBasketHasSelectedMKTPReference = createSelector(
  (state: RootState) => state.basket?.vehicles,
  (state: RootState) => state.basket?.otherSection,
  (state: RootState) => state.basket?.externalBaskets,
  (vehicles, otherSection, externalBaskets) => {
    let ret = false;
    vehicles?.forEach((vehicle) => {
      vehicle.references.forEach((refNumber) => {
        if (isRefMKTP(refNumber) && refNumber.isSelected) {
          ret = true;
        }
      });
    });
    otherSection.references.forEach((refNumber) => {
      if (isRefMKTP(refNumber) && refNumber.isSelected) {
        ret = true;
      }
    });
    externalBaskets?.forEach((exBasket) => {
      exBasket.references.forEach((refNumber) => {
        if (isRefMKTP(refNumber) && refNumber.isSelected) {
          ret = true;
        }
      });
    });
    return ret;
  },
);

// Export reducer
export default slice.reducer;
