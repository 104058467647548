import { sha256 } from 'js-sha256';
import { env } from 'env';

const bffUrl = env.REACT_APP_BFF_URL;
const environment = env.REACT_APP_ENVIRONMENT;

export const generateApiKey = (): string => {
  //before change make sure that the value is the same as in com.renault.one_portal.web_bff.domain.auth.ApiKeyGenerator
  const saltedApiKey = '1po-fe-apikey-' + environment;
  return sha256(saltedApiKey);
};

export interface RestClientProps {
  endpoint: string;
  urlSearchParams?: URLSearchParams;
}

export const sendRequest = async <T,>({ endpoint, urlSearchParams }: RestClientProps): Promise<T> => {
  try {
    const headers = new Headers();
    headers.append('apikey', generateApiKey());
    const params = urlSearchParams ? `?${urlSearchParams}` : '';
    const response = await fetch(`${bffUrl}${endpoint}${params}`, {
      headers,
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${endpoint}. Status: ${response.status}`);
    }
    return (await response.json()) as T;
  } catch (error) {
    console.error('Error while sending request:', error);
    throw error;
  }
};
