/* eslint-disable max-len */
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import {
  GET_AVAILABLE_DMS_SERVICES_RESPONSE,
  GET_DELEGATION_CONNECT_AS_RESPONSE,
  GET_DELEGATION_DISCONNECT_FROM_RESPONSE,
  GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE,
  GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE,
  GET_USER_PROFILE_RESPONSE,
  GET_USER_SELLERS_PENDING_WARNING,
  GET_USER_SELLERS_RESPONSE,
  GET_VEHICLE_SEARCH_HISTORY_RESPONSE,
  NOTIFY_ABOUT_DELEGATION_DISCONNECT,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { AvailableDMSService } from '@1po/1po-bff-fe-spec/generated/dms/model/AvailableDMSService';

import { CommercialLink, Seller } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { CatalogDisplayType } from '@1po/1po-bff-fe-spec/generated/user/request/UpdateCatalogSettingsRequest';
import { DelegationBuyer } from '@1po/1po-bff-fe-spec/generated/user/response/model/DelegationBuyer';
import { DelegationSeller } from '@1po/1po-bff-fe-spec/generated/user/response/model/DelegationSeller';
import { PendingSellerWarning } from '@1po/1po-bff-fe-spec/generated/user/response/model/PendingSellerWarning';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { appTracker } from 'app/AppTracker';
import { SELECTED_PROFILE_PARAM } from 'domains/auth/Auth.types';
import {
  CAR_PARTS_TAB,
  IamCatalogBrandsType,
  IamCatalogTabsType,
  OTHER_BRAND_KEY,
} from 'domains/catalog/Catalog.types';
import { mapTradingProfile } from 'domains/user/User.mapper';
import {
  ADD_FAVORITE_TIRE_BRAND,
  ADD_VEHICLE_TO_SEARCH_HISTORY_REQUEST,
  CHANGE_DOCUMENT_LANGUAGE,
  CHANGE_WEB_LANGUAGE,
  CLEAR_VEHICLE_SEARCH_HISTORY_REQUEST,
  ClientView,
  CONFIGURE_WORKSHOP_ID,
  CONNECT_TO_DELEGATION_SESSION,
  DISCONNECT_FROM_DELEGATION_SESSION,
  DISCONNECT_USER,
  GET_AVAILABLE_DMS_SERVICES,
  GET_POSSIBLE_BUYERS_TO_DELEGATE,
  GET_POSSIBLE_SELLERS_TO_DELEGATE,
  GET_USER_PROFILE,
  GET_USER_SELLERS,
  GET_VEHICLE_SEARCH_HISTORY_REQUEST,
  LANGUAGE_STORE_KEY,
  LOAD_USER,
  LOGOUT_USER,
  RELOAD_USER_AFTER_RECONNECT,
  REMOVE_FAVORITE_TIRE_BRAND,
  REMOVE_VEHICLE_FROM_SEARCH_HISTORY_REQUEST,
  RESET_DELEGATION_SESSION,
  RESET_FAVORITE_TIRE_BRANDS_SELECTION,
  SparePartsViewType,
  StandardView,
  TokenProfile,
  UPDATE_DH_CATALOG_REFERENCE_VIEW,
  UPDATE_USER_PROFILE_LANGUAGES_REQUEST,
  UPDATE_USER_SELLER,
  User,
  USER_NAMESPACE,
  UserRole,
  UsersState,
} from 'domains/user/User.types';
import { DisplayModeType } from 'styles';
import { FOUND, getData, hasData, isLoading, LOADING, NO_DATA, NOT_FOUND, SEARCH_STATUS } from 'utils';
import { documentLanguages, Language, webLanguages } from 'utils/i18n/Language';
import { detectStoredLanguage, getWebLanguageFromBrowser } from 'utils/i18n/localeDetector';
import { storeSave } from 'utils/storage';

// For now we store language also in session storage and sync it with BE data
function storeSaveLanguages(state: UsersState) {
  storeSave(LANGUAGE_STORE_KEY, {
    web_lang: state.webLanguage.key,
    doc_main: state.documentLanguage.key,
    doc_fallback: state.documentLanguageFallback.key,
  });
}

const initDelegationSession = {
  searchStatus: undefined,
  data: undefined,
};

// Init state
export const initialState: UsersState = {
  tokenUser: {
    user: undefined,
    tokenExpired: false,
  },
  userRoles: [],
  userProfileSearchStatus: undefined,
  webLanguage:
    getWebLanguageFromBrowser() ||
    detectStoredLanguage(webLanguages, LANGUAGE_STORE_KEY, 'web_lang') ||
    Language.DEFAULT,
  documentLanguage: detectStoredLanguage(documentLanguages, LANGUAGE_STORE_KEY, 'doc_main') || Language.DEFAULT,
  documentLanguageFallback:
    detectStoredLanguage(documentLanguages, LANGUAGE_STORE_KEY, 'doc_fallback') || Language.DEFAULT,
  commercialLink: null,
  dmsConfig: undefined,
  favoriteTireBrandList: [],
  sellersToChoose: undefined,
  pendingSellerWarning: undefined,
  showSellerPopup: true,
  vehicleSearchHistory: undefined,
  sparePartsView: ClientView,
  referenceView: StandardView,
  iamCatalogBrandsView: OTHER_BRAND_KEY,
  iamCatalogTabsView: CAR_PARTS_TAB,
  dhCatalogTabsView: CAR_PARTS_TAB,
  delegation: {
    session: initDelegationSession,
    possibleSellers: {
      searchStatus: undefined,
      data: undefined,
    },
    possibleBuyers: new Map(),
  },
  isReload: false,
};

// Saga actions
export const setCurrentUserSaga = createAction(LOAD_USER);
export const getUserProfileRequestSaga = createAction(GET_USER_PROFILE);
export const getUserProfileResponseSaga = createAction(GET_USER_PROFILE_RESPONSE);
export const connectAsUserSaga = createAction<{
  buyerId: string;
  sellerId: string;
}>(CONNECT_TO_DELEGATION_SESSION);

export const connectAsUserResponseSaga = createAction(GET_DELEGATION_CONNECT_AS_RESPONSE);
export const disconnectFromConnectAsUserSaga = createAction(DISCONNECT_FROM_DELEGATION_SESSION);
export const disconnectFromConnectAsUserResponseSaga = createAction(GET_DELEGATION_DISCONNECT_FROM_RESPONSE);
export const notifyAboutDelegationDisconnectResponseSaga = createAction(NOTIFY_ABOUT_DELEGATION_DISCONNECT);
export const getPossibleSellersForDelegationSaga = createAction(GET_POSSIBLE_SELLERS_TO_DELEGATE);
export const getPossibleSellersForDelegationResponseSaga = createAction(GET_POSSIBLE_SELLERS_TO_DELEGATE_RESPONSE);
export const getPendingSellerWarningResponseSaga = createAction(GET_USER_SELLERS_PENDING_WARNING);
export const getPossibleBuyersForDelegationSaga = createAction<string>(GET_POSSIBLE_BUYERS_TO_DELEGATE);
export const resetDelegationSessionSaga = createAction(RESET_DELEGATION_SESSION);
export const getPossibleBuyersForDelegationResponseSaga = createAction(GET_POSSIBLE_BUYERS_TO_DELEGATE_RESPONSE);
export const updateUserProfileLanguagesRequestSaga = createAction<{
  webLanguage: Language;
  documentLanguage: Language;
  documentLanguageFallback: Language;
}>(UPDATE_USER_PROFILE_LANGUAGES_REQUEST);
export const logoutUser = createAction(LOGOUT_USER);
export const disconnectUser = createAction(DISCONNECT_USER);
export const changeWebLanguageSaga = createAction<Language>(CHANGE_WEB_LANGUAGE);
export const changeDocumentLanguageSaga = createAction<{
  documentLanguage: Language;
  documentLanguageFallback: Language;
}>(CHANGE_DOCUMENT_LANGUAGE);
export const configureWorkshopId = createAction<string>(CONFIGURE_WORKSHOP_ID);
export const getAvailableDMSServicesRequestSaga = createAction<{
  workshopId: string;
}>(GET_AVAILABLE_DMS_SERVICES);
export const getAvailableDMSServicesResponseSaga = createAction(GET_AVAILABLE_DMS_SERVICES_RESPONSE);

export const getUserSellersRequestSaga = createAction(GET_USER_SELLERS);
export const updateDhCatalogReferenceViewRequestSaga = createAction<CatalogDisplayType>(
  UPDATE_DH_CATALOG_REFERENCE_VIEW,
);

export const addFavoriteTireBrandRequestSaga = createAction<{ tireBrand: ReferenceBrandType }>(ADD_FAVORITE_TIRE_BRAND);
export const removeFavoriteTireBrandRequestSaga = createAction<{ tireBrand: ReferenceBrandType }>(
  REMOVE_FAVORITE_TIRE_BRAND,
);
export const resetFavoriteTireBrandRequestSaga = createAction(RESET_FAVORITE_TIRE_BRANDS_SELECTION);

export const updateUserSellerSaga = createAction<string>(UPDATE_USER_SELLER);
export const getUserSellersResponseSaga = createAction(GET_USER_SELLERS_RESPONSE);
export const fetchVehicleSearchHistorySubscriptionSaga = createAction(GET_VEHICLE_SEARCH_HISTORY_REQUEST);
export const fetchAddVehicleToSearchHistoryRequestSaga = createAction<{
  vehicle: VehicleDetail;
}>(ADD_VEHICLE_TO_SEARCH_HISTORY_REQUEST);
export const fetchRemoveVehicleFromSearchHistoryRequestSaga = createAction<{
  vehicleKey: string;
}>(REMOVE_VEHICLE_FROM_SEARCH_HISTORY_REQUEST);
export const fetchClearVehicleSearchHistoryRequestSaga = createAction(CLEAR_VEHICLE_SEARCH_HISTORY_REQUEST);
export const fetchVehicleSearchHistoryResponseSaga = createAction(GET_VEHICLE_SEARCH_HISTORY_RESPONSE);
export const reloadSubscriptionBeUserSaga = createAction(RELOAD_USER_AFTER_RECONNECT);
// Slice
const slice = createSlice({
  name: USER_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setResubscribeableFieldsUndefined: (state) => {
      state.userProfileSearchStatus = undefined;
      state.vehicleSearchHistory = undefined;
    },
    setUserIsLoading: (state) => {
      state.tokenUser.user = LOADING;
    },
    setTokenExpired: (state, action: PayloadAction<boolean>) => {
      state.tokenUser.tokenExpired = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<Array<UserRole>>) => {
      state.userRoles = action.payload;
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.tokenUser.user = action.payload;
      if (state.tokenUser.user) {
        appTracker.trackUser(state.tokenUser.user.id);
      }
    },
    setSelectedProfile: (state, action: PayloadAction<TokenProfile>) => {
      const { payload } = action;
      const user = state.tokenUser.user;
      if (!isLoading(user) && user !== undefined) {
        sessionStorage.setItem(SELECTED_PROFILE_PARAM, payload.id);
        state.tokenUser.user = {
          ...user,
          selectedProfile: payload,
        };
      }
    },
    setUserProfileSearchStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.userProfileSearchStatus = action.payload;
      if (action.payload === LOADING) {
        state.userRoles = LOADING;
      }
    },
    setUserSellersToChoose: (state, action: PayloadAction<Seller[] | NO_DATA>) => {
      state.sellersToChoose = action.payload;
    },
    setCommercialLink: (state, action: PayloadAction<CommercialLink>) => {
      appTracker.trackGarage(
        `${action.payload.name} - ${
          action.payload.seller?.buyerRelationToSeller || action.payload.isSeller ? 'R1' : 'R?'
        } - ${action.payload.buyerId ?? '????????'}`,
      );
      state.commercialLink = action.payload;
    },
    setWorkshopId: (state, action: PayloadAction<string>) => {
      const config = state.dmsConfig;
      state.dmsConfig = {
        ...config,
        workshopId: action.payload,
      };
    },
    setFavoriteTireBrandList: (state, action: PayloadAction<Array<ReferenceBrandType>>) => {
      state.favoriteTireBrandList = action.payload;
    },
    setIsReload: (state, action: PayloadAction<boolean>) => {
      state.isReload = action.payload;
    },
    setAvailableDMSServices: (state, action: PayloadAction<AvailableDMSService[] | NO_DATA>) => {
      const config = state.dmsConfig;
      state.dmsConfig = {
        ...config,
        availableServices: action.payload,
      };
    },
    setPossibleBuyersForSellerToDelegate: (
      state,
      {
        payload,
      }: PayloadAction<{
        sellerId: string;
        buyers: DelegationBuyer[];
      }>,
    ) => {
      state.delegation.possibleBuyers.set(payload.sellerId, {
        searchStatus: FOUND,
        data: payload.buyers,
      });
    },
    setPossibleBuyersForSellerToDelegateNoData: (
      state,
      {
        payload,
      }: PayloadAction<{
        sellerId: string;
        status: SEARCH_STATUS;
      }>,
    ) => {
      state.delegation.possibleBuyers.set(payload.sellerId, {
        searchStatus: payload.status,
      });
    },
    setPossibleSellersToDelegate: (
      state,
      {
        payload,
      }: PayloadAction<{
        sellers: DelegationSeller[];
      }>,
    ) => {
      state.delegation.possibleSellers = {
        searchStatus: FOUND,
        data: payload.sellers,
      };
    },
    setPendingSellerWarning: (
      state,
      {
        payload,
      }: PayloadAction<{
        pendingWarning: PendingSellerWarning;
      }>,
    ) => {
      state.pendingSellerWarning = payload.pendingWarning;
    },
    setPendingSellerWarningStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.pendingSellerWarning = action.payload;
    },
    setPossibleSellersToDelegateNoData: (state, { payload }: PayloadAction<NO_DATA>) => {
      state.delegation.possibleSellers = {
        searchStatus: payload,
      };
    },
    setDelegationSession: (
      state,
      action: PayloadAction<{
        commercialLink: CommercialLink;
        userRoles: UserRole[];
      }>,
    ) => {
      state.delegation.session = {
        data: {
          userRoles: action.payload.userRoles,
          commercialLink: action.payload.commercialLink,
        },
        searchStatus: FOUND,
      };
    },
    setDelegationSessionStatus: (state, action: PayloadAction<typeof LOADING | typeof NOT_FOUND>) => {
      state.delegation.session = {
        searchStatus: action.payload,
      };
    },
    setUserDisconnectFrom: (state) => {
      state.delegation.session = {
        searchStatus: NOT_FOUND,
        data: undefined,
      };
    },
    resetDelegationSession: (state) => {
      state.delegation.session = initDelegationSession;
    },
    setWebLanguage: (state, action: PayloadAction<Language>) => {
      state.webLanguage = action.payload;
      storeSaveLanguages(state);
    },
    setDocumentLanguages: (
      state,
      action: PayloadAction<{
        lang: Language;
        fallback: Language;
      }>,
    ) => {
      state.documentLanguage = action.payload.lang;
      state.documentLanguageFallback = action.payload.fallback;
      storeSaveLanguages(state);
    },
    setVehicleSearchHistory: (state, { payload }: PayloadAction<VehicleDetail[] | undefined>) => {
      state.vehicleSearchHistory = payload;
    },
    setVehicleSearchHistoryNoDataStatus: (state, { payload }: PayloadAction<NO_DATA>) => {
      state.vehicleSearchHistory = payload;
    },
    setSparePartsView: (state, { payload }: PayloadAction<SparePartsViewType>) => {
      state.sparePartsView = payload;
    },
    setCatalogReferenceView: (state, { payload }: PayloadAction<CatalogDisplayType>) => {
      state.referenceView = payload;
    },
    setIamCatalogBrandsView: (state, { payload }: PayloadAction<IamCatalogBrandsType>) => {
      state.iamCatalogBrandsView = payload;
    },
    setIamCatalogTabsView: (state, { payload }: PayloadAction<IamCatalogTabsType>) => {
      state.iamCatalogTabsView = payload;
    },
    setShowSellerPopupFalse: (state) => {
      state.showSellerPopup = false;
    },
    setDhCatalogTabsView: (state, { payload }: PayloadAction<IamCatalogTabsType>) => {
      state.dhCatalogTabsView = payload;
    },
  },
});

// Actions
export const {
  setInitialState,
  setResubscribeableFieldsUndefined,
  setTokenExpired,
  setDelegationSession,
  setUserDisconnectFrom,
  setUserIsLoading,
  setUserRoles,
  setUser,
  setCommercialLink,
  setWorkshopId,
  setFavoriteTireBrandList,
  setAvailableDMSServices,
  setUserSellersToChoose,
  setUserProfileSearchStatus,
  setIamCatalogBrandsView,
  setIamCatalogTabsView,
  setWebLanguage,
  setDocumentLanguages,
  setIsReload,
  resetDelegationSession,
  setVehicleSearchHistory,
  setDelegationSessionStatus,
  setVehicleSearchHistoryNoDataStatus,
  setSparePartsView,
  setCatalogReferenceView,
  setShowSellerPopupFalse,
  setSelectedProfile,
  setPossibleSellersToDelegate,
  setPendingSellerWarning,
  setPendingSellerWarningStatus,
  setPossibleBuyersForSellerToDelegateNoData,
  setPossibleBuyersForSellerToDelegate,
  setPossibleSellersToDelegateNoData,
  setDhCatalogTabsView,
} = slice.actions;

// Getters/Selectors
export const getIsLoadingUserToken = createSelector(
  (state: RootState) => state.user.tokenUser.user,
  (user) => isLoading(user),
);

export const getTokenUser = createSelector(
  (state: RootState) => state.user.tokenUser.user,
  (current) => getData(current),
);

export const getUserCommercialLink = createSelector(
  (state: RootState) => state.user.commercialLink,
  (commercialLink) => commercialLink,
);

export const getUserSellersToChoose = createSelector(
  (state: RootState) => state.user.sellersToChoose,
  (sellersToChoose) => sellersToChoose,
);

export const getShowSellerPopup = createSelector(
  (state: RootState) => state.user.showSellerPopup,
  (showSellerPopup) => showSellerPopup,
);

export const getTokenUserRights = createSelector(
  (state: RootState) => state.user.userRoles,
  (roles) => roles,
);

export const getWebLanguage = createSelector(
  (state: RootState) => state.user.webLanguage,
  (language) => language || Language.DEFAULT,
);

export const getUserProfileSearchStatus = createSelector(
  (state: RootState) => state.user.userProfileSearchStatus,
  (status) => status,
);

export const getUserSellerWarning = createSelector(
  (state: RootState) => state.user.pendingSellerWarning,
  (status) => status,
);

export const getTokenExpired = createSelector(
  (state: RootState) => state.user.tokenUser,
  (user) => hasData(user.user) && user.tokenExpired,
);
export const getDealerType = createSelector(getUserCommercialLink, (commercialLink) => {
  if (commercialLink?.seller?.buyerRelationToSeller) {
    return commercialLink.seller.buyerRelationToSeller;
  }
  return commercialLink?.isSeller === true ? 'R1' : undefined;
});

export const getPossibleDelegateSessionBuyers = createSelector(
  (state: RootState) => state.user.delegation.possibleBuyers,
  (_state: RootState, sellerId: string | undefined) => sellerId,
  (buyerMap, sellerId) => (sellerId ? buyerMap.get(sellerId) : undefined),
);

export const getDelegationSessionStatus = createSelector(
  (state: RootState) => state.user.delegation.session.searchStatus,
  (connectAsStatus) => connectAsStatus,
);

export const isDelegationSessionStatusLoading = createSelector(getDelegationSessionStatus, (status) =>
  isLoading(status),
);

export const getPossibleDelegateSessionSellers = createSelector(
  (state: RootState) => state.user.delegation.possibleSellers,
  (userConnectAsDealers) => getData(userConnectAsDealers),
);

export const getDelegationSession = createSelector(
  (state: RootState) => state.user.delegation.session.data,
  (session) => session,
);

export const getUserRights = createSelector(getTokenUserRights, getDelegationSession, (roles, connectedAs) =>
  connectedAs ? connectedAs.userRoles : roles,
);

export const getTradingProfile = createSelector(
  getTokenUser,
  getUserCommercialLink,
  getUserProfileSearchStatus,
  getDelegationSession,
  (user, commercialLink, searchStatus, connectedAs) => {
    if (searchStatus === FOUND && user) {
      return mapTradingProfile(user, commercialLink, connectedAs);
    }
    return undefined;
  },
);

export const getDocumentLanguage = createSelector(
  (state: RootState) => state.user.documentLanguage,
  (docLanguage) => docLanguage || Language.DEFAULT,
);

export const getDocumentLanguageFallback = createSelector(
  (state: RootState) => state.user.documentLanguageFallback,
  (docLanguageFallback) => docLanguageFallback || Language.DEFAULT,
);

export const getUserContext = createSelector(
  getWebLanguage,
  getDocumentLanguage,
  getDocumentLanguageFallback,
  getUserCommercialLink,
  getTokenUser,
  (webLanguage, docLanguage, fallbackLanguage, commercialLink, user) => {
    return {
      webLanguage: webLanguage.key,
      documentLanguage: docLanguage.getLanguage(),
      documentCountryLanguage: docLanguage.getCountryCode(),
      documentFallbackLanguage: fallbackLanguage.getLanguage(),
      documentFallbackCountryLanguage: fallbackLanguage.getCountryCode(),
      userCountry: user?.country ?? 'FR',
      r1Country: commercialLink?.countryCode ?? user?.country ?? 'FR',
    };
  },
);

export const getVehicleSearchHistory = createSelector(
  (state: RootState) => state.user.vehicleSearchHistory,
  (vehicleSearchHistory) => vehicleSearchHistory,
);

export const getSparePartsView = createSelector(
  (state: RootState) => state.user.sparePartsView,
  (sparePartsView) => sparePartsView,
);

export const getCatalogReferenceView = createSelector(
  (state: RootState) => state.user.referenceView,
  (referenceView) => referenceView,
);

export const getFavoriteBrandPreferences = createSelector(
  (state: RootState) => state.user.favoriteTireBrandList,
  (favoriteTireBrandList) => favoriteTireBrandList,
);

export const getIamCatalogBrandsView = createSelector(
  (state: RootState) => state.user.iamCatalogBrandsView,
  (iamCatalogBrandsView) => iamCatalogBrandsView,
);

export const getIamCatalogTabsView = createSelector(
  (state: RootState) => state.user.iamCatalogTabsView,
  (iamCatalogTabsView) => iamCatalogTabsView,
);

export const getDhCatalogTabsView = createSelector(
  (state: RootState) => state.user.dhCatalogTabsView,
  (dhCatalogTabsView) => dhCatalogTabsView,
);

export const getCurrency = createSelector(
  (state: RootState) => state.user.commercialLink?.currencyCode,
  (currency) => currency ?? 'EUR',
);

export const getUserCountry = createSelector(getTokenUser, (tokenUser) => tokenUser?.country);

export const getSelectedProfile = createSelector(getTokenUser, (user) => user?.selectedProfile);

// Can be used to load user customizable theme
export const getUserDisplayMode = createSelector(getUserCommercialLink, (commercialLink) => {
  const dealerType = commercialLink?.seller?.buyerRelationToSeller;
  const themeDisplayType = dealerType === 'R3_MOTRIO' ? 'R3_MOTRIO' : 'light';
  return themeDisplayType as DisplayModeType;
});

export const isTokenValid = createSelector(
  getTokenUser,
  (user) => user?.id !== undefined && user?.rights !== undefined && user.country !== undefined,
);

export const getDmsWorkshopId = createSelector(
  (state: RootState) => state.user.dmsConfig,
  (dmsConfig) => dmsConfig?.workshopId,
);

export const getIsReload = createSelector(
  (state: RootState) => state.user.isReload,
  (isReload) => isReload,
);

export const getAvailableDMSServices = createSelector(
  (state: RootState) => state.user.dmsConfig,
  (dmsConfig) => dmsConfig?.availableServices,
);

export const getAvailableDMSService = createSelector(
  (state: RootState) => state.user.dmsConfig,
  (_state: RootState, serviceToFind: AvailableDMSService) => serviceToFind,
  (dmsConfig, serviceToFind) => {
    if (dmsConfig?.availableServices === LOADING) return LOADING;
    return dmsConfig?.availableServices && hasData(dmsConfig.availableServices)
      ? dmsConfig.availableServices.includes(serviceToFind)
      : false;
  },
);

// Export reducer
export default slice.reducer;
