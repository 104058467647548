import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTER_HOME } from 'app/AppRouter';
import { ArrowLeftIcon } from 'assets/icons';
import { SButton } from 'UI/Button/BackButton.styled';
import { Icon } from 'UI/Icon';
import { Text } from 'UI/Text';
import { useSmall } from 'utils';

export const HomeButton = () => {
  const { t } = useTranslation();
  const small = useSmall();
  const history = useHistory();
  const handleClick = useCallback(() => history.push(ROUTER_HOME), [history]);

  return (
    <SButton direction={'row'} align={'center'} justify={'flex-start'} onClick={handleClick}>
      <Icon IconComponent={ArrowLeftIcon} size={14} mr={10} mb={1} />
      {!small && (
        <Text disableGutter type={'h6'} cursor={'pointer'}>
          {t('common.home', 'Home')}
        </Text>
      )}
    </SButton>
  );
};
