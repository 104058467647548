import { Menu } from 'antd';
import styled from 'styled-components';
import { SearchAutocompleteInput } from 'components/SearchAutocomplete/SearchAutocompleteInput';
import { commonSInput } from 'pages/EstimatePage/TableSection/TableSection.styled';
import { DisplayModeType, effect } from 'styles';

export const SearchContainer = styled.div`
  z-index: 9999;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 100px;
  max-width: 270px;
  max-height: 40px;
  user-select: none;
`;

export const SearchMenu = styled(Menu)`
  z-index: 9999;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grey90};
  margin: 0 21px 0 21px;
`;

export const SItem = styled(Menu.Item)`
  padding: 12px 12px;
  ${effect.hover.colorBrand}
  ${effect.focus.colorBrand}
`;

export const SSearchInput = styled(SearchAutocompleteInput)<{ displayMode: DisplayModeType; $isInvalid?: boolean }>`
  background-color: ${({ theme, displayMode }) => theme.displayMode[displayMode].searchBackgroundColor};
  ${commonSInput}
`;
