/* eslint-disable max-len */
import { GetMaintenanceValuesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_values/response/GetMaintenanceValuesResponse';
import { GET_MAINTENANCE_VALUES_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  GET_MAINTENANCE_VALUES_REQUEST,
  MAINTENANCE_VALUES_NAMESPACE,
  MaintenanceValuesState,
} from 'domains/maintenanceValues/MaintenanceValues.types';
import { NO_DATA, SEARCH_STATUS } from 'utils';

const initialState: MaintenanceValuesState = {
  maintenanceValues: undefined,
  valuesXml: {
    data: undefined,
    searchStatus: undefined,
  },
};
/* eslint-enable max-len */

// Saga actions
export const fetchMaintenanceValuesRequestSaga = createAction(GET_MAINTENANCE_VALUES_REQUEST);
export const fetchMaintenanceValuesResponseSaga = createAction(GET_MAINTENANCE_VALUES_RESPONSE);

// Slice
const slice = createSlice({
  name: MAINTENANCE_VALUES_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setMaintenanceValuesStatus: (state, { payload }: PayloadAction<{ status: NO_DATA }>) => {
      state.maintenanceValues = payload.status;
    },
    setMaintenanceValues: (state, { payload }: PayloadAction<GetMaintenanceValuesResponse>) => {
      state.maintenanceValues = payload;
    },
    setMaintenanceValuesXml: (state, { payload }: PayloadAction<string | undefined>) => {
      state.valuesXml.data = payload;
    },
    setMaintenanceValuesXmlStatus: (state, { payload }: PayloadAction<SEARCH_STATUS>) => {
      state.valuesXml.searchStatus = payload;
    },
  },
});

// Actions
export const {
  setInitialState,
  setMaintenanceValuesStatus,
  setMaintenanceValues,
  setMaintenanceValuesXml,
  setMaintenanceValuesXmlStatus,
} = slice.actions;

export const getMaintenanceValues = createSelector(
  (state: RootState) => state.maintenanceValues.maintenanceValues,
  (maintenanceValues) => maintenanceValues,
);

export const getMaintenanceValuesXml = createSelector(
  (state: RootState) => state.maintenanceValues.valuesXml,
  (valuesXml) => valuesXml,
);

// Export reducer
export default slice.reducer;
