import React from 'react';
import {
  OperationByServiceList,
  OperationByMileageOrTimeList,
} from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { MaintenanceDataProps } from 'pages/CatalogPage/DH/Maintenance/Maintenance';
import { ABServiceTable } from 'pages/CatalogPage/DH/Maintenance/Maintenance.styled';
import { Box, CenterFlex, Flex, MarginBox, Pipeline, Text } from 'UI';

const Title = ({ children }: { children: string }) => (
  <Text type={'h6'} disableGutter>
    {children}
  </Text>
);

export const splitOperations = (operations: OperationByServiceList[]) => {
  const middleIndex = Math.ceil(operations.length / 2);
  const table1Data = operations.slice().splice(0, middleIndex);
  const table2Data = operations.slice().splice(middleIndex);
  return [table1Data, table2Data];
};

const getColumns = (allServices: number[], operationByMileageOrTimeList: OperationByMileageOrTimeList[]) => {
  const servicesColumns = allServices.map((serviceColumnNumber) => {
    const serviceData = operationByMileageOrTimeList.find((o) => o.id === serviceColumnNumber);
    return {
      title: <Title>{serviceData?.label ?? ''}</Title>,
      key: serviceColumnNumber,
      width: 120,
      render: function renderCheck(service: OperationByServiceList) {
        return (
          <CenterFlex>
            {service.carriedOutByOperationByMileageOrTimeIdList.includes(serviceColumnNumber) && '✔'}
          </CenterFlex>
        );
      },
    };
  });
  return [
    {
      dataIndex: 'label',
      key: 'label',
      render: function renderLabel(label: string) {
        return (
          <Flex minHeight={50} align={'center'}>
            {label}
          </Flex>
        );
      },
    },
    ...servicesColumns,
  ];
};

const MultipleServices = ({
  operationByServiceList,
  idServices,
  operationByMileageOrTimeList,
}: {
  operationByMileageOrTimeList: OperationByMileageOrTimeList[];
  operationByServiceList: OperationByServiceList[];
  idServices: number[];
}) => {
  const [table1Data, table2Data] = splitOperations(operationByServiceList);
  const columns = getColumns(idServices, operationByMileageOrTimeList);
  return (
    <Flex direction={'row'}>
      <ABServiceTable columns={columns} dataSource={table1Data} pagination={false} rowKey={'label'} />
      <Box width={24} />
      {table2Data.length > 0 ? (
        <ABServiceTable columns={columns} dataSource={table2Data} pagination={false} rowKey={'label'} />
      ) : (
        <Flex />
      )}
    </Flex>
  );
};

const SingleServices = ({ operationByServiceList }: { operationByServiceList: OperationByServiceList[] }) => {
  const [table1Data, table2Data] = splitOperations(operationByServiceList);

  const SingleTable = ({ tableData }: { tableData: OperationByServiceList[] }) => {
    return (
      <Flex direction={'column'}>
        {tableData.map((service, index) => {
          return (
            <div key={`${service}_${index}`}>
              <MarginBox ml={15} mr={45}>
                <Flex minHeight={54} align={'center'}>
                  {service.label}
                  <Flex direction={'row-reverse'}>{'✔'}</Flex>
                </Flex>
              </MarginBox>
              <Flex direction={'column-reverse'}>
                <Pipeline size={'100%'} horizontal />
              </Flex>
            </div>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex direction={'row'}>
      <SingleTable tableData={table1Data} />
      <Box width={150} />
      {table2Data.length > 0 ? <SingleTable tableData={table2Data} /> : <Flex />}
    </Flex>
  );
};

const ServiceSection = ({ maintenancePlanData, getLabel }: MaintenanceDataProps) => {
  if (maintenancePlanData.operationByServiceList.length === 0) return <></>;

  return (
    <Flex direction={'column'}>
      <Text type={'h5_bold'}>{getLabel(28)}</Text>
      <Box height={30} />
      {maintenancePlanData.operationsByServiceIds.length > 0 ? (
        <MultipleServices
          operationByServiceList={maintenancePlanData.operationByServiceList}
          idServices={maintenancePlanData.operationsByServiceIds}
          operationByMileageOrTimeList={maintenancePlanData.operationByMileageOrTimeList}
        />
      ) : (
        <SingleServices operationByServiceList={maintenancePlanData.operationByServiceList} />
      )}
    </Flex>
  );
};
export default ServiceSection;
