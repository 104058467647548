import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_PROMOTION } from 'app/AppRouter';
import { ArrowsRightIcon, DotsIcon, HatIcon, ShapeIcon } from 'assets/icons';
import { getCurrentPromotionsData } from 'domains/promotion/Promotion.store';
import {
  IconPositionWrapper,
  SAdvertisementSpace,
} from 'pages/PromotionPage/CurrentPromotionsPageBanner/CurrentPromotionsPageBanner.styled';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, RedButton, Text } from 'UI';

const CurrentPromotionsPageBanner = ({ homepage = false }: { homepage?: boolean }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const promotionsData = useSelector(getCurrentPromotionsData);

  if (homepage && promotionsData.length === 0) {
    return <></>;
  }

  const handleRedirect = () => {
    if (homepage) {
      history.push(ROUTER_PROMOTION);
    }
  };

  return (
    <SAdvertisementSpace onClick={handleRedirect} minHeight={homepage ? 150 : 250} noPointer={!homepage}>
      <Flex direction={'row'} minHeight={'100%'}>
        <Flex align={'center'} maxWidth={220}>
          <MarginBox ml={60}>
            <Icon IconComponent={DotsIcon} width={160} height={100} noPointer={!homepage} />
          </MarginBox>
        </Flex>
        <Flex justify={'flex-start'} align={'center'} maxWidth={150}>
          <MarginBox ml={45}>
            <Icon IconComponent={ArrowsRightIcon} width={105} height={95} noPointer={!homepage} />
          </MarginBox>
        </Flex>
        <Flex align={'center'} maxWidth={homepage ? 300 : ''}>
          <MarginBox ml={45}>
            <Text type={homepage ? 'h1_brand' : 'h1_white'} ellipsis disableGutter>
              {t('promotions.current_promotions', 'Current promotions')}
            </Text>
          </MarginBox>
        </Flex>
        <IconPositionWrapper right={homepage ? '30%' : '35%'}>
          <Icon IconComponent={ShapeIcon} width={200} height={90} color={theme.color.blue_grey} noPointer={!homepage} />
        </IconPositionWrapper>
        <IconPositionWrapper right={homepage ? '10%' : '5%'}>
          <Icon
            IconComponent={HatIcon}
            width={homepage ? 180 : 300}
            height={homepage ? 30 : 60}
            color={theme.color.brand}
            noPointer={!homepage}
          />
        </IconPositionWrapper>
        {homepage && (
          <Flex justify={'flex-end'} align={'center'}>
            <Box width={300} height={50}>
              <RedButton size={'xlarge'} shape={'semiRound'}>
                <Text type={'heading_landing_md'} disableGutter ellipsis>
                  {t('promotions.see_best_offers', 'See our best offers')}
                </Text>
              </RedButton>
            </Box>
            <MarginBox mr={45} />
          </Flex>
        )}
      </Flex>
    </SAdvertisementSpace>
  );
};
export default CurrentPromotionsPageBanner;
