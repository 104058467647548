import React from 'react';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { CarIcon } from 'assets/icons';
import { VerticalCenter } from 'pages/CatalogPage/common/CatalogHeader/CatalogHeader.styled';
import { theme } from 'styles';
import { Box, CenterFlex, Icon, Image, URL } from 'UI';

export const DefaultCarImage = ({ isSmall }: { isSmall?: boolean }) => {
  const size = isSmall ? 50 : 70;
  return (
    <Box height={size} width={size} background={theme.color.grey95}>
      <CenterFlex>
        <Icon IconComponent={CarIcon} size={isSmall ? 30 : 40} color={theme.color.brand_black} noPointer />
      </CenterFlex>
    </Box>
  );
};

export const CarDetailHeaderImage = ({
  imageUrl,
  catalogSource,
  isSmall,
}: {
  imageUrl?: string;
  catalogSource: CatalogSource;
  isSmall: boolean;
}) => (
  <VerticalCenter isSmall={isSmall}>
    <Image
      src={imageUrl}
      type={URL}
      alt={'carPic'}
      width={isSmall ? 50 : 70}
      catalogSource={catalogSource}
      fallbackComponent={<DefaultCarImage isSmall={isSmall} />}
    />
  </VerticalCenter>
);
