import {
  ADD_ORDER_LIST_REFERENCES_URL,
  UPLOAD_ORDER_LIST_REFERENCES_FILE_URL,
} from '@1po/1po-bff-fe-spec/generated/order_list/OrderListURLs';
import { AddOrderListReferencesRequest } from '@1po/1po-bff-fe-spec/generated/order_list/request/AddOrderListReferencesRequest';
import { OrderListFileUploadRequest } from '@1po/1po-bff-fe-spec/generated/order_list/request/OrderListFileUploadRequest';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendAddOrderListReferences(request: AddOrderListReferencesRequest): WebSocketAction {
  return wsSendAction(ADD_ORDER_LIST_REFERENCES_URL, request);
}

export function sendOrderListReferencesFileUploadRequest(request: OrderListFileUploadRequest): WebSocketAction {
  return wsSendAction(UPLOAD_ORDER_LIST_REFERENCES_FILE_URL, request);
}
