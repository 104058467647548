import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudUploadAltIcon, FileExcelIcon } from 'assets/icons';
import { inputOnChange, onClick, onDragOver, onDrop, uploadFile } from 'components/FileUpload/FileUpload';
import { BodySmall } from 'components/FileUpload/FileUpload.styled';
import { FILE, ModalStatusType } from 'components/FileUpload/paramsByState';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Text } from 'UI';

export interface FileUploadSmallProps {
  fileName?: string;
  handleFileBase64: (fileName: string, fileSize: number, fileBase64: string) => void;
  handleFileInfo?: (fileName: string, fileSize: number) => void;
  validateFile: (file: File) => boolean;
  status: ModalStatusType;
  template?: {
    link: string;
    fileName: string;
  };
  disable?: boolean;
}

export function FileUploadStrip({
  fileName,
  handleFileBase64,
  handleFileInfo,
  validateFile,
  status,
  template,
  disable = false,
}: Readonly<FileUploadSmallProps>) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleNoFile = () => {};

  // Immediately upload file
  useEffect(() => {
    if (file) {
      const fileSize = uploadFile(file, handleFileBase64);
      handleFileInfo && handleFileInfo(file.name, fileSize ?? 0);
    }
    // eslint-disable-next-line
  }, [file]);

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    if (disable) return;
    onDrop(e, validateFile, handleNoFile, setFile);
  };

  function withFile() {
    return (
      <BodySmall onDrop={handleOnDrop} onDragOver={onDragOver}>
        <Flex align={'center'} justify={'center'} minHeight={50}>
          <Icon
            IconComponent={FileExcelIcon}
            size={24}
            display={'block'}
            color={disable ? theme.color.grey45 : theme.color.grass_green}
            noPointer
          />
          <MarginBox ml={5} />
          <Text type={disable ? 'light_14_bold_black_45' : 'section_bold'} disableGutter>
            {t('common.file_upload.file', 'File:')}
          </Text>
          <MarginBox ml={5} />
          <Text type={disable ? 'light_14_black_45' : 'section'} disableGutter>
            {file?.name ?? fileName}
          </Text>
          <Flex minWidth={15} />
          {!disable && (
            <Text
              type={'section'}
              displayStyle={'link'}
              decoration={'underline'}
              cursor={'pointer'}
              disableGutter
              onClick={() => onClick(fileInput)}
            >
              {t('common.file_upload.file.replace', 'Replace file')}
            </Text>
          )}
        </Flex>
      </BodySmall>
    );
  }

  function noFile() {
    return (
      <BodySmall onDrop={(e) => onDrop(e, validateFile, handleNoFile, setFile)} onDragOver={onDragOver}>
        <Flex direction={'column'} justify={'center'} align={'center'}>
          <Icon IconComponent={CloudUploadAltIcon} size={50} display={'block'} color={theme.color.shadow_3} noPointer />
          <Flex size={0} align={'center'}>
            <Icon IconComponent={FileExcelIcon} size={24} display={'block'} color={theme.color.grass_green} noPointer />
            <MarginBox ml={5} />
            <Text type={'section_bold'} disableGutter>
              {t('common.file_upload.file', 'File:')}
            </Text>
            <MarginBox ml={5} />
            <Text type={'section'} disableGutter>
              {t('common.file_upload.drag_and_drop_or', 'Drag and drop file to attach it, or')}
            </Text>
            <MarginBox ml={5} />
            <Text
              type={'section'}
              displayStyle={'link'}
              decoration={'underline'}
              cursor={'pointer'}
              disableGutter
              onClick={() => onClick(fileInput)}
            >
              {t('common.file_upload.upload', 'upload')}
            </Text>
          </Flex>
        </Flex>
        {template && (
          <>
            <MarginBox mt={5} />
            <Link to={template.link} external download={template.fileName}>
              <Text type={'text'} displayStyle={'link'} cursor={'pointer'} decoration={'underline'} disableGutter>
                {t('common.file_upload.download_template', 'Download template')}
              </Text>
            </Link>
          </>
        )}
      </BodySmall>
    );
  }

  const content = status === FILE ? withFile() : noFile();

  return (
    <>
      {content}
      <input
        type="file"
        id="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onInput={(e: ChangeEvent<HTMLInputElement>) => inputOnChange(e, validateFile, handleNoFile, setFile)}
      />
    </>
  );
}
