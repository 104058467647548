import React from 'react';
import { useBreakpoint } from 'utils/hooks/useBreakpoint';

function getViewComponent<T>(
  [ComponentSm, ComponentMd, ComponentLg]: [React.FC<T>, React.FC<T>, React.FC<T>],
  sm: boolean,
  md: boolean,
  lg: boolean,
) {
  if (lg) {
    return ComponentLg;
  } else if (md) {
    return ComponentMd;
  } else {
    return ComponentSm;
  }
}

export function useView<T>(
  [ComponentSm, ComponentMd, ComponentLg]: [React.FC<T>, React.FC<T>, React.FC<T>],
  props: T & JSX.IntrinsicAttributes,
) {
  const breakpoint = useBreakpoint();
  const lg = breakpoint > 3;
  const md = breakpoint > 1 && !lg;
  const sm = !md && !lg;

  const Component = getViewComponent([ComponentSm, ComponentMd, ComponentLg], sm, md, lg);

  return <Component {...props} />;
}
