import { css, Interpolation } from 'styled-components';

const dim = css`
  filter: brightness(0.9);
`;

const outline = css`
  outline: ${({ theme }) => theme.color.brand} solid 1px;
`;

const enlarge = css`
  transform: scale(1.1);
`;

const colorBrand = css`
  background-color: ${({ theme }) => theme.color.brand};
`;

const hoverEffect = (t: Interpolation<any>) => css`
  :hover {
    ${t}
  }
`;

const focusEffect = (t: Interpolation<any>) => css`
  :focus {
    ${t}
  }
`;

export const effect = {
  hover: {
    dim: hoverEffect(dim),
    outline: hoverEffect(outline),
    enlarge: hoverEffect(enlarge),
    colorBrand: hoverEffect(colorBrand),
  },
  focus: {
    dim: focusEffect(dim),
    outline: focusEffect(outline),
    enlarge: focusEffect(enlarge),
    colorBrand: focusEffect(colorBrand),
  },
};
