import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetExternalOrdersResponse';
import { RootState } from 'app/AppStore';
import { OrderItemLocal } from 'domains/order/Order.types';
import { InfiniteScrollable } from 'UI';
import { NO_DATA } from 'utils';
import {
  fetchDealerExternalOrdersRequestSaga,
  fetchDealerOrdersRequestSaga,
  fetchOrderByIdRequestSaga,
  getInProgressOrder,
  getInProgressOrdersHasMore,
  getInProgressOrdersIsLoading,
} from './Order.store';

export const useInfiniteScrollInProgressOrders = ({
  orders,
}: {
  orders: OrderItemLocal[];
}): InfiniteScrollable<OrderItemLocal> => {
  const dispatch = useDispatch();
  const hasMore = useSelector(getInProgressOrdersHasMore);
  const isLoading = useSelector(getInProgressOrdersIsLoading);

  function onLoadMore() {
    if (!isLoading) {
      dispatch(fetchDealerOrdersRequestSaga());
    }
  }

  return { loadMore: onLoadMore, hasMore, currentData: orders };
};

export const useFetchOrder = (orderId: string) => {
  const dispatch = useDispatch();
  const orderSearchData = useSelector((state: RootState) => getInProgressOrder(state, orderId));

  useEffect(() => {
    if (orderSearchData?.searchStatus !== undefined) {
      return;
    }
    dispatch(fetchOrderByIdRequestSaga(orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orderId]);
};

export const useFetchExternalOrders = (externalOrders: ExternalOrderItem[] | NO_DATA) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (externalOrders !== undefined) return;

    dispatch(fetchDealerExternalOrdersRequestSaga());
  }, [dispatch, externalOrders]);
};
