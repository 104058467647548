import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Seller } from '@1po/1po-bff-fe-spec/generated/user/model/Seller';
import { updateUserSellerSaga } from 'domains/user';
import { Box, Flex, MarginBox, Text } from 'UI';
import { BlackBox, SellerCardStyled } from './SellerCard.styled';

type SellerCardProps = {
  seller: Seller;
  activeSeller: boolean;
  setSelectedSeller: (seller: Seller) => void;
};

const SellerCard = ({ seller, activeSeller, setSelectedSeller }: SellerCardProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <SellerCardStyled
      key={seller.id}
      active={activeSeller}
      onClick={() => {
        if (!activeSeller) {
          dispatch(updateUserSellerSaga(seller.id));
          setSelectedSeller(seller);
        }
      }}
    >
      <Flex direction={'row'}>
        <Flex size={'none'}>
          <BlackBox>
            <Flex direction={'column'}>
              <Flex />
              <Box align={'center'}>
                <Text type={'h6_white'} cursor={'pointer'}>
                  Renault Group
                </Text>
              </Box>
              <Flex />
            </Flex>
          </BlackBox>
        </Flex>
        <Flex direction={'column'}>
          <MarginBox ml={22} mt={10}>
            <Flex direction={'row'}>
              <Text type={'h5_bold'} cursor={'pointer'}>
                {seller.name}
              </Text>
            </Flex>
            <Flex direction={'row'}>
              <Text type={'light_14_black_65'} cursor={'pointer'}>
                {t('common.id', 'ID')}: {seller.birCode}
              </Text>
            </Flex>
          </MarginBox>
        </Flex>
      </Flex>
    </SellerCardStyled>
  );
};

export default SellerCard;
