import { Tabs } from 'antd';
import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI/Flex';

export const STab = styled(Flex)<{ active: boolean; disabled: boolean; isActiveBlue: boolean }>`
  align-items: center;
  justify-content: center;
  background-color: ${({ active, isActiveBlue }) =>
    active ? (isActiveBlue ? theme.color.clear_blue : theme.color.white) : theme.color.grey95_light};
  height: 36px;
  margin: 0 1px 0 1px;
  border-radius: 3px 3px 0 0;
  border: solid 1px ${theme.color.grey95_light};
  border-bottom: none;

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const SViewTabs = styled(Tabs)`
  width: 100%;
  overflow: visible;

  .ant-tabs-nav-list {
    width: auto;
  }

  .ant-tabs-nav-wrap {
    justify-content: flex-end;

    .ant-tabs-tab {
      border-radius: 4px !important;

      &:hover:not(.ant-tabs-tab-active) {
        background-color: ${({ theme }) => theme.color.grey95};

        .ant-tabs-tab-btn {
          color: ${({ theme }) => theme.color.brand_black};
        }
      }
    }

    .ant-tabs-tab-active {
      background-color: ${({ theme }) => theme.color.brand_black};

      .ant-tabs-tab-btn {
        color: white;
      }
    }
  }
`;
