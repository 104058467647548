import styled from 'styled-components';
import { effect } from 'styles';
import { defaultBoxShadow, Flex } from 'UI';

export const SPromotionCard = styled(Flex)`
  position: relative;
  cursor: pointer;
  ${effect.hover.dim}
  ${defaultBoxShadow}
`;
export const PromotionBackgroundImage = styled.div<{ background: string }>`
  width: 100%;
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
`;

export const SeeMoreSection = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.color.shadow_9};
`;
