import React from 'react';
import { Trans } from 'react-i18next';
import { VehicleTireInformations } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { Box, Flex, MarginBox, Pipeline, Text } from 'UI';

const Informations = ({ informations }: { informations: VehicleTireInformations }) => {
  return (
    <Flex>
      <Box width={90}>
        <Text type={'text_semibold_black_85'}>
          <Trans i18nKey="catalog.vehicle.tires.informations.nrce">NRCE:</Trans>
        </Text>
        <MarginBox mt={15} />
        <Pipeline size={'100%'} horizontal />
      </Box>
      <Box width={240}>
        <Text type={'light_14_black_85'}>{informations?.nrce}</Text>
        <MarginBox mt={15} />
        <Pipeline size={'100%'} horizontal />
      </Box>
      <MarginBox mr={30} />
      <Box width={90}>
        <Text type={'text_semibold_black_85'}>
          <Trans i18nKey="catalog.vehicle.tires.informations.tvv">TVV:</Trans>
          <MarginBox mt={15} />
          <Pipeline size={'100%'} horizontal />
        </Text>
      </Box>
      <Box width={240}>
        <Text type={'light_14_black_85'}>{informations?.tvv}</Text>
        <MarginBox mt={15} />
        <Pipeline size={'100%'} horizontal />
      </Box>
    </Flex>
  );
};
export default Informations;
