import React, { ReactNode } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { theme as themeTokens, ThemeType } from 'styles';

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeType {}
}

export const Theme = ({ children }: { children: ReactNode }): JSX.Element => {
  const theme: DefaultTheme = {
    ...themeTokens,
  };
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
