import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTire';
import styled from 'styled-components';
import { LinkIcon } from 'assets/icons';
import { SelectedTireProps } from 'domains/tires/Tire.types';
import { IAMTireItem } from 'pages/CatalogPage/IAM/Tires/TireSizesTables/IAMTireItem';
import { theme } from 'styles';
import { Box, CenterFlex, Flex, Icon, Text } from 'UI';

const TireItemHeader = styled(Flex)<{
  $isHeader?: boolean;
}>`
  border-bottom: ${theme.color.grey_light} 1px solid;
  background-color: ${theme.color.grey95};
`;

interface DifferentSizedSectionProps extends SelectedTireProps {
  iamTires: VehicleTire[];
}

const DifferentSizedSection = ({ iamTires, selectedTire, setSelectedTire }: DifferentSizedSectionProps) => {
  const { t } = useTranslation();
  return (
    <Flex direction={'column'}>
      <Flex maxWidth={710} minHeight={55}>
        <TireItemHeader align={'center'} justify={'center'}>
          <Text type={'h6_black'} transform={'uppercase'}>
            {t('catalog.vehicle.tires.front', 'Front')}
          </Text>
        </TireItemHeader>
        <Box width={60} />
        <TireItemHeader align={'center'} justify={'center'}>
          <Text type={'h6_black'} transform={'uppercase'}>
            {t('catalog.vehicle.tires.back', 'Back')}
          </Text>
        </TireItemHeader>
      </Flex>
      {iamTires.map((iamTire, index) => {
        return (
          <Flex key={index} align={'center'} maxWidth={710}>
            <IAMTireItem
              id={iamTire.frontTireId ?? ''}
              rimSize={iamTire.frontRimSize}
              tireSize={iamTire.frontTyreSize}
              usage={iamTire.usage}
              isSelected={selectedTire === iamTire.frontTireId}
              setSelectedTire={setSelectedTire}
            />
            <Box width={60}>
              <CenterFlex>
                <Icon IconComponent={LinkIcon} size={15} noPointer />
              </CenterFlex>
            </Box>
            <IAMTireItem
              id={iamTire.rearTireId ?? ''}
              rimSize={iamTire.rearRimSize}
              tireSize={iamTire.rearTyreSize}
              usage={iamTire.usage}
              isSelected={selectedTire === iamTire.rearTireId}
              setSelectedTire={setSelectedTire}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default DifferentSizedSection;
