import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Moment } from 'moment';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { getSeenPopinsLocal, getViewsDataIndex } from 'domains/firstHelp/FirstHelp.api';
import {
  FIRST_HELP_NAMESPACE,
  FirstHelpState,
  PageType,
  PopinData,
  Popins,
  Streams,
} from 'domains/firstHelp/FirstHelp.types';

const MAX_AUTO_FIRST_HELP = 2;

// Init state
const initialState: FirstHelpState = {
  pageViews: new Map(),
  session: {
    id: uuidv4(),
    visitedStreamIds: new Set<string>(),
    hintSeenPages: new Set<string>(),
  },
  streams: new Map(),
  popins: new Map(),
  showFirstHelp: {
    showStreamId: undefined,
    showIndex: 0,
  },
  streamViewTimeStamp: undefined,
  currentPopinsViewed: new Set<string>(),
};

// Saga actions

// Slice
const slice = createSlice({
  name: FIRST_HELP_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    initializeStreamsPopins: (state, { payload }: PayloadAction<{ streams: Streams; popins: Popins }>) => {
      state.streams = payload.streams;
      state.popins = payload.popins;
    },
    initializeSeenPopinsFromLocalStorage: (state, { payload }: PayloadAction<{ userId: string }>) => {
      const userSeenStreamsLocalMap = getSeenPopinsLocal(payload.userId);
      if (userSeenStreamsLocalMap) {
        for (const popins of userSeenStreamsLocalMap.values()) {
          popins.forEach((popinId) => {
            const popin = state.popins.get(popinId);
            if (popin) {
              state.popins.set(popinId, { ...popin, seen: true });
            }
          });
        }
      }
    },
    setPageViews: (state, { payload }: PayloadAction<Map<PageType, number>>) => {
      state.pageViews = payload;
    },
    setShowHintPages: (state, { payload }: PayloadAction<PageType[]>) => {
      const pages = state.session.hintSeenPages;
      payload.forEach((p) => {
        pages.add(p);
      });
    },
    setShowFirstHelp: (state, { payload }: PayloadAction<PageType | undefined>) => {
      if (payload && payload !== 'close') {
        state.session.visitedStreamIds.add(payload);
      }
      state.showFirstHelp.showStreamId = payload;
      state.showFirstHelp.showIndex = 0;
    },
    setShowFirstHelpIndex: (state, { payload }: PayloadAction<number>) => {
      state.showFirstHelp.showIndex = payload;
    },
    disablePopins: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((popinId) => {
        const popin = state.popins.get(popinId);
        if (popin) {
          state.popins.set(popinId, { ...popin, disabled: true });
        }
      });
    },
    enablePopins: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((popinId) => {
        const popin = state.popins.get(popinId);
        if (popin) {
          state.popins.set(popinId, { ...popin, disabled: false });
        }
      });
    },
    setSeenPopins: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((popinId) => {
        const popin = state.popins.get(popinId);
        if (popin) {
          state.popins.set(popinId, { ...popin, seen: true });
        }
      });
    },
    setStreamViewTimeStamp: (state, { payload }: PayloadAction<Moment | undefined>) => {
      state.streamViewTimeStamp = payload;
    },
    addCurrentPopinViewed: (state, { payload }: PayloadAction<string>) => {
      state.currentPopinsViewed.add(payload);
    },
    resetCurrentStreamView: (state) => {
      state.currentPopinsViewed.clear();
      state.streamViewTimeStamp = undefined;
    },
  },
});

// Actions
export const {
  setInitialState,
  setPageViews,
  initializeStreamsPopins,
  initializeSeenPopinsFromLocalStorage,
  setShowFirstHelp,
  setShowFirstHelpIndex,
  disablePopins,
  enablePopins,
  setSeenPopins,
  setShowHintPages,
  setStreamViewTimeStamp,
  addCurrentPopinViewed,
  resetCurrentStreamView,
} = slice.actions;

// Getters/Selectors
export const getSessionId = createSelector(
  (state: RootState) => state.firstHelp.session.id,
  (id) => id,
);

export const getPopin = createSelector(
  (state: RootState) => state.firstHelp.popins,
  (_state: RootState, popinId: string) => popinId,
  (popins, popinId) => popins.get(popinId),
);

export const getIsStreamSeen = createSelector(
  (state: RootState) => state.firstHelp.popins,
  (_state: RootState, popinIds: string[]) => popinIds,
  (popins, popinIds) => {
    const popinsData = popinIds.map((popinId) => popins.get(popinId));
    return popinsData.every((p) => p?.seen || p?.disabled);
  },
);

export const getStream = createSelector(
  (state: RootState) => state.firstHelp.streams,
  (state: RootState) => state.firstHelp.popins,
  (state: RootState) => state.firstHelp.pageViews,
  (_state: RootState, streamsId: PageType) => streamsId,
  (streams, popins, pageViews, streamsId) => {
    const pageStreams = streams.get(streamsId);
    const streamPageViews = pageViews.get(streamsId) ?? 0;
    const index = getViewsDataIndex(streamPageViews);

    if (pageStreams) {
      for (let i = index; i >= 0; i--) {
        if (pageStreams[i]) {
          return pageStreams[i].filter((popin) => {
            const isStreamSeen = pageStreams[i].every(
              (popin) => popins.get(popin)?.seen || (!popins.get(popin)?.seen && popins.get(popin)?.disabled),
            );
            const selectedPopin: PopinData | undefined = popins.get(popin);
            const resolveStream = () => {
              if (isStreamSeen) {
                return true;
              }
              return !selectedPopin?.seen;
            };
            return !selectedPopin?.disabled && resolveStream();
          });
        }
      }
    }
    return undefined;
  },
);

export const getShowFirstHelp = createSelector(
  (state: RootState) => state.firstHelp.showFirstHelp,
  (showFirstHelp) => showFirstHelp,
);

export const getStreamViewTimeStamp = createSelector(
  (state: RootState) => state.firstHelp.streamViewTimeStamp,
  (streamViewTimeStamp) => streamViewTimeStamp,
);

export const getCurrentPopinsViewed = createSelector(
  (state: RootState) => state.firstHelp.currentPopinsViewed,
  (currentPopinsViewed) => currentPopinsViewed,
);

export const getAutoShowFirstHelp = createSelector(
  (state: RootState) => state.firstHelp.session.visitedStreamIds,
  (_state: RootState, streamsId: PageType) => streamsId,
  (visitedStreamIds, streamId) => visitedStreamIds.size < MAX_AUTO_FIRST_HELP && !visitedStreamIds.has(streamId),
);

export const getAutoShowFirstHelpHint = createSelector(
  (state: RootState) => state.firstHelp.session.hintSeenPages,
  (_state: RootState, payload: { streamsId: PageType; seenLocal: Set<string> }) => payload,
  (hintSeenPages, { streamsId, seenLocal }) =>
    hintSeenPages.size < MAX_AUTO_FIRST_HELP && !hintSeenPages.has(streamsId) && !seenLocal.has(streamsId),
);

export default slice.reducer;
