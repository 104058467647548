/* eslint-disable max-len */
import React from 'react';
import { ServiceProposalOperation } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalOperationsResponse';
import { List } from 'antd';
import { MaintenanceCard } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlan.styled';
import { SListItem } from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleCriterias/VehicleCriterias.styled';
import { theme } from 'styles';
import { CenterFlex, Flex, Icon, IconType, PaddingBox, Text } from 'UI';

const RevisionCard = ({
  icon,
  label,
  selected,
  onSelect,
  serviceData,
  loading,
}: {
  icon: IconType;
  label: string;
  selected: boolean;
  onSelect: () => void;
  serviceData?: ServiceProposalOperation;
  loading: boolean;
}) => {
  const formattedOperations = serviceData?.operations.map((o) => `${o.label} - ${o.repairLabel}`);

  return (
    <MaintenanceCard
      active={selected}
      onClick={formattedOperations && onSelect}
      maxWidth={800}
      minHeight={250}
      direction={'row'}
      align={'flex-start'}
      gap={15}
      padding={30}
      disabled={!formattedOperations}
    >
      <CenterFlex
        style={{
          width: '103px',
          height: '90px',
          backgroundColor: theme.color.ice_blue,
        }}
      >
        <Icon IconComponent={icon} size={48} color={theme.color.info} />
      </CenterFlex>
      <Flex direction={'column'}>
        <Text type={'h4_paragraph'}>{label}</Text>
        <PaddingBox pb={10} />
        <List
          dataSource={formattedOperations}
          loading={loading}
          renderItem={(o) => {
            return <SListItem>{o}</SListItem>;
          }}
        />
      </Flex>
    </MaintenanceCard>
  );
};

export default RevisionCard;
