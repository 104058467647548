import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { OrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { useTheme } from 'styled-components';
import { matchRoute, ROUTER_HOME, ROUTER_IN_PROGRESS_ORDERS, ROUTER_RETURN_ORDER } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CarIcon, ReplyAllIcon } from 'assets/icons';
import { useDisclosure } from 'components/Dialog';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import {
  getReferencesToReturn,
  isOrderIdInPendingReAddToBasketRequests,
  reAddOrderReferencesToBasketRequestSaga,
  resetReturnRequest,
} from 'domains/order/Order.store';
import { OrderItemLocal } from 'domains/order/Order.types';
import { getDealerType, getUserContext } from 'domains/user';
import { handlePrintDetail } from 'pages/MyOrdersPage/InProgressOrders/pdfPrint';
import { BillingAddressCard } from 'pages/MyOrdersPage/OrderCards/BillingAddressCard';
import { ClientInformationCard } from 'pages/MyOrdersPage/OrderCards/ClientInformationCard';
import { ContactCard } from 'pages/MyOrdersPage/OrderCards/ContactCard';
import { InformationCard } from 'pages/MyOrdersPage/OrderCards/InformationCard';
import { CardTitle, MiddleCardTitle, OrderDetailCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { OrderReferencesSection } from 'pages/MyOrdersPage/OrderReferencesSection/OrderReferencesSection';
import { OrderReturnSection } from 'pages/MyOrdersPage/OrderReturnSection/OrderReturnSection';
import ReturnDialog from 'pages/MyOrdersPage/OrderReturnSection/ReturnDialog';
import { useOrderReturnsEnabledByR1 } from 'pages/MyOrdersPage/useOrderReturnsEnabledByR1';
import {
  BlackButton,
  BlueButton,
  CartArrowDownButtonRound,
  Flex,
  Icon,
  LightGreyButton,
  MarginBox,
  Pipeline,
  PrintButtonRound,
  Space,
  SuccessResult,
  Text,
  WhiteButton,
} from 'UI';
import { textFormatter } from 'utils';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { SModal } from './InProgressOrders.styled';

const OrderVehicleCard = ({ order }: { order: OrderItem }) => {
  const { t } = useTranslation();
  const { vehicleDetail } = order;

  const dataHubVehicle = order.vehicleDetail?.dataHubVehicle;
  const iamVehicle = order.vehicleDetail?.iamVehicle;

  return (
    <OrderDetailCard data-cy={'section-vehicle'}>
      <CardTitle>
        <Flex>
          <MarginBox mt={2}>
            <Icon IconComponent={CarIcon} width={20} height={20} />
          </MarginBox>
          <MarginBox ml={10} />
          {vehicleDetail && (vehicleDetail.vrn || vehicleDetail.vin) && (
            <Text type={'h6'} dataCy={'vehicle-vrn'}>
              {vehicleDetail.vrn
                ? t('my_orders.details.vrn', 'VRN: {{vrn}}', { vrn: vehicleDetail.vrn })
                : vehicleDetail.vin}
            </Text>
          )}
        </Flex>
      </CardTitle>
      <Flex minHeight={order.orderMark ? 156 : 104} align={'center'}>
        <MarginBox ml={30} my={10}>
          {vehicleDetail && (
            <Flex direction={'column'}>
              {dataHubVehicle && (
                <>
                  <Text type={'text_bold'}>{dataHubVehicle.name}</Text>
                  <Text type={'light_14_black_65'}>{dataHubVehicle.modelType}</Text>
                </>
              )}
              {iamVehicle && dataHubVehicle === undefined && (
                <>
                  <Text type={'text_bold'}>{iamVehicle.name}</Text>
                  <Text type={'light_14_black_65'}>{iamVehicle.modelType}</Text>
                </>
              )}
              {dataHubVehicle && (
                <>
                  <Text type={'light_14_black_65'}>{dataHubVehicle.engine}</Text>
                  <Text type={'light_14_black_65'}>{dataHubVehicle.gearbox}</Text>
                </>
              )}
              {iamVehicle && dataHubVehicle === undefined && (
                <>
                  <Text type={'light_14_black_65'}>{iamVehicle.version}</Text>
                  {(iamVehicle.gearbox || iamVehicle.gearboxType) && (
                    <Text type={'light_14_black_65'}>{`${iamVehicle.gearboxType ?? ''}${
                      iamVehicle.gearboxType && iamVehicle.gearbox ? ' ' : ''
                    }${iamVehicle.gearbox ?? ''}`}</Text>
                  )}
                </>
              )}
            </Flex>
          )}
          {!vehicleDetail && (
            <Flex direction={'column'}>
              <Text type={'h6'}>{t('my_orders.details.reference_list', 'Reference list')}</Text>
              <Text type={'light_14_black_65'}>{t('order.order_detail.no_vehicle_context', 'No vehicle context')}</Text>
            </Flex>
          )}
        </MarginBox>
      </Flex>
    </OrderDetailCard>
  );
};

const TotalCard = ({ order }: { order: OrderItem }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <OrderDetailCard data-cy={'section-total'}>
      <Flex direction={'column'}>
        <MiddleCardTitle>
          <Text type={'h6'}>
            {t('my_orders.details.total', `Total: ({{items}} item)`, {
              items: order.orderedReferences.length,
              count: order.orderedReferences.length,
            })}
          </Text>
        </MiddleCardTitle>
        <MarginBox my={30} mx={15}>
          <Flex>
            <Text type={'text'} dataCy={'title-total-vat-excl'}>
              {t('my_orders.details.total_vat_excl', 'Total VAT.Excl:')}
            </Text>
            <Flex justify={'flex-end'} dataCy={'price-total-vat-excl'}>
              {textFormatter.formatCurrency(order.priceVatExcluded, order.currency)}
            </Flex>
          </Flex>
          {order.priceVat && (
            <>
              <MarginBox mt={15} />
              <Flex>
                <Text type={'text'} dataCy={'title-vat'}>
                  {t('my_orders.details.vat', 'VAT:')}
                </Text>
                <Flex justify={'flex-end'} dataCy={'price-vat'}>
                  {textFormatter.formatCurrency(order.priceVat, order.currency)}
                </Flex>
              </Flex>
              <MarginBox mt={15} />
            </>
          )}
          {order.priceVatIncluded && (
            <>
              <Pipeline horizontal color={theme.color.grey85} size={'100%'} />
              <MarginBox mt={15} />
              <Flex>
                <Text type={'h5_bold'} dataCy={'title-total-vat-incl'}>
                  {t('my_orders.details.total_vat_incl', 'Total VAT.Incl:')}
                </Text>
                <Flex justify={'flex-end'} dataCy={'price-total-vat-incl'}>
                  {textFormatter.formatCurrency(order.priceVatIncluded, order.currency)}
                </Flex>
              </Flex>
            </>
          )}
        </MarginBox>
      </Flex>
    </OrderDetailCard>
  );
};

const InProgressOrderDetails = ({ order }: { order: OrderItemLocal }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dealerType = useSelector(getDealerType);
  const userContext = useSelector(getUserContext);
  const disclosure = useDisclosure();
  const disclosureResult = useDisclosure();
  const { isOpen, onClose, onOpen } = disclosure;
  const { isOpen: isOpenResult, onClose: onCloseResult, onOpen: onOpenResult } = disclosureResult;

  const { orderId } = useParams<{ orderId: string }>();
  const history = useHistory();
  const pathname = history.location.pathname;
  const orderReturnEnabled = useOrderReturnsEnabledByR1(order?.userInfo?.sellerId, order?.userInfo?.repository);
  const [showReturnRequest, setShowReturnRequest] = useState(matchRoute(pathname, ROUTER_RETURN_ORDER));
  const orderNumber = Number(order.externalOrderId ?? order.internalOrderId);
  const displayOrderNumber =
    order.orderStatus === 'MAYBE_PLACED'
      ? t('my_orders.details.order_processing_after_timeout', 'Processing')
      : orderNumber;
  const enableReturnButton = order.orderedReferences.some(
    (orderedRef) =>
      (orderedRef.status === 'DELIVERED' || orderedRef.status === 'PARTIALLY_DELIVERED') &&
      (orderedRef.deliveredQuantity ?? 0) - (orderedRef.returnedQuantity ?? 0) > 0,
  );
  const referencesToReturn = useSelector((state: RootState) => getReferencesToReturn(state, orderId));
  const isReAddToBasketRequestPending = useSelector((state: RootState) =>
    isOrderIdInPendingReAddToBasketRequests(state, orderId),
  );
  const enableValidateRequestButton = Array.isArray(referencesToReturn) && referencesToReturn?.length > 0;

  useResetScroll();

  useEffect(() => {
    setShowReturnRequest(matchRoute(pathname, ROUTER_RETURN_ORDER));
  }, [pathname]);

  const handleReject = useCallback(() => {
    dispatch(resetReturnRequest(order.internalOrderId));
    onClose();
  }, [dispatch, onClose, order.internalOrderId]);

  const { userInfo } = order;
  const { garageInfo } = userInfo;
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;
  const areSubstitutes =
    order.orderedReferences.filter((r) => r.deliveredSubstitutedQuantity && r.deliveredSubstitutedQuantity > 0).length >
    0;

  const getOrderedBy = () => {
    if (userInfo.userGarageId && userInfo.userGarageId !== userInfo.buyerId) {
      return userInfo.userGarageType
        ? `${userInfo.userGarageType} - ${userInfo.userGarageName} + ${userInfo.userId}`
        : `${userInfo.userGarageName} + ${userInfo.userId}`;
    }
    return `${userInfo.firstName} ${userInfo.lastName}`;
  };

  const handleHome = () => {
    history.push(ROUTER_HOME);
  };

  const handleCancel = () => {
    onCloseResult();
  };

  return (
    <>
      {orderReturnEnabled && (
        <ReturnDialog
          handleReject={handleReject}
          isOpen={isOpen}
          onClose={onClose}
          userContext={userContext}
          referencesToReturn={Array.isArray(referencesToReturn) ? referencesToReturn : []}
          orderId={orderId}
          showResult={onOpenResult}
        />
      )}
      <SModal footer={''} width={800} open={isOpenResult} onCancel={onCloseResult}>
        <SuccessResult
          title={t(
            'my_orders.details.return_request.request_created_alert.title.success',
            'Your return has been created',
          )}
          subTitle={t(
            'my_orders.details.return_request.request_created_alert.description.success',
            'Your R1 will soon get back to you.',
          )}
          extra={
            <Space size={30}>
              <WhiteButton onClick={handleCancel}>{t('common.action.close', 'Close')}</WhiteButton>
              <BlackButton onClick={handleHome}>{t('common.home', 'Home')}</BlackButton>
            </Space>
          }
        />
      </SModal>
      <Flex direction={'column'}>
        <MarginBox mt={20} />
        <Flex>
          <Text type={'h1_banner_light'} dataCy={'abel-order-number'}>
            {t('my_orders.details.order_number', `Order n° {{orderId}}`, { orderId: displayOrderNumber })}
          </Text>
          <Flex justify={'flex-end'}>
            {order.orderStatus === 'NOT_PLACED_AFTER_TIMEOUT' && (
              <CartArrowDownButtonRound
                onClick={() => dispatch(reAddOrderReferencesToBasketRequestSaga(order.internalOrderId))}
                disabled={isReAddToBasketRequestPending}
              />
            )}
            <MarginBox ml={15} />
            <PrintButtonRound
              onClick={() => {
                handlePrintDetail([order], [orderId], dealerType, userContext.r1Country);
              }}
            />
            <MarginBox ml={15} />
          </Flex>
        </Flex>
        <MarginBox mt={15} />
        <Flex>
          <Flex size={3}>
            {order.userInfo.garageInfo && (
              <ClientInformationCard
                garage={order.userInfo.garageInfo}
                orderStatus={order.presentableOrderStatus}
                orderDate={order.orderDate}
                deliveryType={order.deliveryType}
                orderMark={order.orderMark}
              />
            )}
          </Flex>
          <MarginBox mr={30} />
          <Flex size={1}>
            <OrderVehicleCard order={order} />
          </Flex>
        </Flex>
        <MarginBox mt={45} />
        <Flex>
          <Text type={'h1_banner_light'}>{t('my_orders.details.references', `References`)}</Text>
          {orderReturnEnabled && (
            <Flex justify={'flex-end'}>
              {showReturnRequest ? (
                <BlueButton size={'middle'} onClick={() => onOpen()} disabled={!enableValidateRequestButton}>
                  <Text type={'text_white_bold'}>
                    {t('my_orders.details.return_request.validate_request', `Validate request`)}
                  </Text>
                </BlueButton>
              ) : (
                <LightGreyButton
                  size={'middle'}
                  onClick={() => {
                    history.push(`${ROUTER_IN_PROGRESS_ORDERS}/${order.internalOrderId}${ROUTER_RETURN_ORDER}`);
                  }}
                  disabled={!enableReturnButton}
                >
                  <Flex align={'center'}>
                    <Icon IconComponent={ReplyAllIcon} size={16} color={theme.color.red} mr={10} />
                    <Text type={'text_dim_bold'}>
                      {t('my_orders.details.return_request.return_articles', `Return articles`)}
                    </Text>
                  </Flex>
                </LightGreyButton>
              )}
            </Flex>
          )}
        </Flex>
        {showReturnRequest ? (
          <OrderReturnSection order={order} substituteInlineInfo={areSubstitutes ? false : undefined} />
        ) : (
          <OrderReferencesSection order={order} substituteInlineInfo={areSubstitutes ? false : undefined} />
        )}
        <MarginBox mt={90} />
        <Flex>
          <Flex direction={'column'} size={2}>
            <InformationCard
              orderedBy={getOrderedBy()}
              clientNumber={userInfo.sellerId}
              distributor={userInfo.sellerName}
              billingAccount={userInfo.clientCode}
            />
          </Flex>
          <MarginBox ml={30} />
          <Flex size={3}>
            <Flex direction={'column'}>
              {garageInfo && <ContactCard garageName={userInfo.userGarageName} address={address} />}
            </Flex>
          </Flex>
          <MarginBox ml={30} />
          <Flex size={3}>
            <Flex direction={'column'}>{address && <BillingAddressCard address={address} />}</Flex>
          </Flex>
        </Flex>
        <MarginBox mt={30} />
        <Flex>
          {order.instruction ? (
            <Flex size={4}>
              <MarginBox mt={30} />
              <Flex background={theme.color.grey95}>
                <MarginBox mt={15} mr={15} mb={35} ml={15}>
                  {t('my_orders.details.instructions', 'Instructions')}
                  <MarginBox mt={15}>{order.instruction}</MarginBox>
                </MarginBox>
              </Flex>
            </Flex>
          ) : (
            <Flex size={4} />
          )}
          <Flex size={1} />
          <MarginBox ml={60} />
          <Flex size={3}>
            <TotalCard order={order} />
          </Flex>
        </Flex>
        <MarginBox mt={30} />
      </Flex>
    </>
  );
};

export default InProgressOrderDetails;
