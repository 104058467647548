import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line max-len
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { DataContainer } from 'components/DataContainer';
import { useUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import ProductsContainer from 'pages/UniversalProductsPage/ProductsPage/ProductsContainer';
import { Text } from 'UI';
import { getData } from 'utils';
import { useResetScroll } from 'utils/hooks/useResetScroll';

const getCategoryByNodeId = (categories: UniversalProductsCategory[], nodeId: string) => {
  return categories.find((cat) => cat.nodeId === nodeId);
};

const ProductsPage = () => {
  const { level1, level2, level3 } = useParams<{ level1: string; level2: string; level3: string }>();
  const universalProductsTree = useUniversalProductsTree();
  const level3Category = useMemo(() => {
    const level1Category = getCategoryByNodeId(getData(universalProductsTree) ?? [], level1);
    const level2Category = getCategoryByNodeId(level1Category?.childCategories ?? [], level2);
    return getCategoryByNodeId(level2Category?.childCategories ?? [], level3);
  }, [level1, level2, level3, universalProductsTree]);

  useResetScroll();

  return (
    <DataContainer data={universalProductsTree}>
      <Text type={'h1_banner_light'}>{level3Category?.name}</Text>
      <ProductsContainer
        key={JSON.stringify(level3Category?.references ?? [])}
        references={level3Category?.references ?? []}
      />
    </DataContainer>
  );
};

export default ProductsPage;
