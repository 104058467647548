import styled from 'styled-components';
import { Flex } from 'UI';

export const ProfilePic = styled.div`
  width: 73px;
  height: 73px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.grey90};
`;

export const SFlex = styled(Flex)`
  :hover {
    background-color: ${({ theme }) => theme.color.grey95};
  }
`;

export const Filter = styled.input`
  border: 1px solid #e8e8e8;
  border-radius: 4px;
`;
