/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DocumentationAlertButton } from 'components/DocumentationAlertButton';
import { BANNER_HEIGHT } from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import { ReferenceUnavailable } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import StockDisplay from 'components/StockInfo';
import TireBasketPriceInfo from 'components/TireBasketPriceInfo';
import TiresRatingIcons from 'components/TireIcons/TireRatingIcons';
import { addOtherSectionReferenceRequest } from 'domains/basket/Basket.store';
import {
  addReferenceFromCatalogToCart,
  getLastSearchedVehicleKey,
  getSearchVehicleResult,
} from 'domains/catalog/Catalog.store';
import { DATAHUB, LIFECYCLE_W3, LIFECYCLE_W4, STANDARD, TIRE } from 'domains/catalog/Catalog.types';
import { convertReferenceToEstimateReference, convertTireToEstimateTire } from 'domains/estimate/Estimate.mapper';
import { addCatalogReference, addCatalogTire, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { getIsStockAvailable, getPrice, getStockInfo } from 'domains/references';
import { useFetchRepairPrices, useFetchReuseStocks } from 'domains/references/References.requests';
import { SPopover } from 'pages/CatalogPage/DH/Product/ProductDescription.styled';
import ElectronicPartsRepairLink from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ElectronicPartsRepairLink';
import Reuse from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/Reuse';
import { useMyStoreTireDiscount } from 'pages/MyStorePage/useMyStoreTireDiscount';
import { theme } from 'styles';
import { Box, Bubble, Flex, Lowercase, MarginBox, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { FOUND, getData } from 'utils';
import { TRACKING_EVENT_ADD_TO_ESTIMATE, TRACKING_EVENT_GO_TO_ESTIMATE } from 'utils/eventTracker/EventTracker.types';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { ProductDetailProps } from './ProductTab';

/**
 * ProductDescription component displays information such as Product title, price and stocks
 */

export const ProductDescription = ({
  refLocal,
  sparePartsView,
  seePlate,
  isVehicleCatalog,
  isUniversalProduct,
  isAvailableSoon,
  isNoLongerAvailable,
}: ProductDetailProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { referenceNumber, name, commercialName, reach, type, isApplicableToCurrentVehicle, tireDetails } = refLocal;
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicle = useSelector((state: RootState) => getSearchVehicleResult(state, vehicleKey));
  const price = getData(useSelector((state: RootState) => getPrice(state, referenceNumber)));
  const stock = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  const stockSearchStatus = stock?.searchStatus;
  const warehouses = stock?.data?.warehouses ?? [];
  const tireW3WithoutR1 =
    refLocal.type === TIRE &&
    refLocal.tireDetails?.lifeCycle === LIFECYCLE_W3 &&
    stockSearchStatus === FOUND &&
    warehouses.filter((w) => w.type === 'LOCAL').length === 0;
  const vehicleDetail = vehicle?.vehicleDetail;
  const tireNotAvailable = type === TIRE && refLocal.tireDetails?.lifeCycle === LIFECYCLE_W4;
  const availableInUserCountry =
    useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber)) &&
    !tireNotAvailable &&
    !tireW3WithoutR1;
  useFetchRepairPrices([referenceNumber], vehicleKey);
  useFetchReuseStocks([referenceNumber]);

  const myStoreTireDiscount = useMyStoreTireDiscount(tireDetails?.brandName, tireDetails?.baseDiameter);

  const handleAddToCartClick = () => {
    if (price?.garageView?.vatExcludedPrice && price.garageView?.vatIncludedPrice) {
      if (
        vehicleKey &&
        vehicleDetail &&
        isApplicableToCurrentVehicle &&
        vehicleDetail.catalogSource === 'DATAHUB' &&
        !isUniversalProduct
      ) {
        dispatch(addReferenceFromCatalogToCart({ referenceNumber }));
      } else {
        dispatch(
          addOtherSectionReferenceRequest({
            reference: refLocal,
          }),
        );
      }
      setTimeout(() => {
        const crossReferences = document.getElementById('product_cross_selling');
        if (crossReferences) {
          scrollToTopSmooth(crossReferences.offsetTop - HEADER_HEIGHT - BANNER_HEIGHT);
        }
      }, 50);
    }
  };

  const handleAddToEstimateClick = () => {
    if (price?.garageView?.vatExcludedPrice && (!isVehicleCatalog || (vehicleDetail && vehicleDetail.dataHubVehicle))) {
      notifyTop(
        'success',
        refLocal.linkedReferences && refLocal.linkedReferences.length > 0 ? (
          <>
            <Trans i18nKey={'catalog.reference_card.added_with_linked_references_to_estimate.description'}>
              {'Reference has been added to your estimate with linked references'}
            </Trans>
          </>
        ) : (
          <>
            <Trans i18nKey={'catalog.reference_card.added_to_estimate.description'}>
              {'Reference has been added to your estimate.'}
            </Trans>
          </>
        ),
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
            dispatch(setSelectedTab(EstimateTabName));
            history.push(ROUTER_ESTIMATE);
          }}
        >
          <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.see_estimate'}>
            {'See estimate'}
          </Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
      if (type === STANDARD) {
        dispatch(
          addCatalogReference({
            reference: convertReferenceToEstimateReference(
              referenceNumber,
              name ?? '',
              price,
              isApplicableToCurrentVehicle ?? true,
              'DATAHUB',
              undefined,
              undefined,
              undefined,
              undefined,
              refLocal.linkedReferences,
            ),
          }),
        );
        return;
      }
      if (refLocal.tireDetails) {
        const { profile, brandId, eprelUrl, designation } = refLocal.tireDetails;
        dispatch(
          addCatalogTire({
            reference: convertTireToEstimateTire(
              refLocal.referenceNumber,
              profile ?? '',
              price,
              true,
              1,
              brandId,
              eprelUrl,
              designation,
            ),
          }),
        );
      }
    }
  };

  return (
    <Flex direction={'column'}>
      <Box>
        <Flex align={'center'}>
          <Text type={'light_14_black_45'} dataCy={'reference-number'}>
            {referenceNumber}
          </Text>
          <MarginBox mr={10} />

          <CopyToClipboardButton
            textToCopy={referenceNumber}
            message={t(
              'catalog.reference_card.reference_number.copied_to_clipboard',
              'Reference number {{referenceNumber}} copied to clipboard',
              { referenceNumber },
            )}
          />
        </Flex>
      </Box>
      <Flex align={'center'} size={0}>
        <MarginBox my={5}>
          <Text
            type={availableInUserCountry ? 'h1_banner_light' : 'h1_banner_black_45'}
            dataCy={'product-designation'}
            disableGutter
          >
            {name}
          </Text>
        </MarginBox>
        {refLocal.brand === 'MOTRIO' && (
          <>
            <MarginBox mr={15} />
            <DocumentationAlertButton
              reference={refLocal}
              vehicleDetail={vehicleDetail}
              vehicleRegistrationNumber={vehicleDetail?.vrn ?? vehicleDetail?.vehicleKey}
              inverted
            />
          </>
        )}
      </Flex>
      <Flex size={0} align={'center'}>
        {commercialName && (
          <Text type={'h6_light'} disableGutter>
            {commercialName}
          </Text>
        )}
        <MarginBox mt={4} ml={commercialName ? 10 : 0}>
          {seePlate && seePlate}
        </MarginBox>
      </Flex>
      {reach && (
        <Flex size={0}>
          <Box height={10} />
          <SPopover
            trigger={'hover'}
            content={
              <Flex>
                <MarginBox mx={15} my={10}>
                  <Text type={'dark_14_white_100'}>{reach}</Text>
                </MarginBox>
              </Flex>
            }
            placement={'right'}
            color={theme.color.grey00}
            arrowPointAtCenter={true}
          >
            <Bubble disabled={!availableInUserCountry}>{t('catalog.product_header.reach', 'REACH')}</Bubble>
          </SPopover>
        </Flex>
      )}
      {tireDetails && (
        <Flex direction={'column'}>
          <Text type={availableInUserCountry ? 'light_14_black_65' : 'light_14_bold_black_45'} transform={'capitalize'}>
            <Lowercase>{tireDetails.profile}</Lowercase>
          </Text>
          <Text type={availableInUserCountry ? 'light_14_black_45' : 'light_14_bold_black_45'} transform={'capitalize'}>
            {t('catalog.tires.reference_card.brand', 'Brand:')} <Lowercase>{tireDetails.brandName}</Lowercase>
          </Text>
          <Text type={availableInUserCountry ? 'light_14_black_65' : 'light_14_bold_black_45'} transform={'capitalize'}>
            <Lowercase>
              {t('catalog.tires.reference_card.characteristics', 'Characteristics:')} {tireDetails.designation}
            </Lowercase>
          </Text>
          <MarginBox mt={10} />
          <TiresRatingIcons
            availableInUserCountry={availableInUserCountry}
            fuelConsumption={tireDetails.fuelConsumption}
            wetGrip={tireDetails.wetGrip}
            exteriorSound={tireDetails.exteriorSound}
          />
        </Flex>
      )}
      <MarginBox mt={25} />
      <Box>
        {availableInUserCountry && (
          <StockDisplay
            vehicleKey={vehicleDetail?.vehicleKey}
            referenceNumber={referenceNumber}
            isApplicableToCurrentVehicle={isApplicableToCurrentVehicle && vehicleDetail?.catalogSource === 'DATAHUB'}
          />
        )}
      </Box>
      {type !== TIRE && <Reuse referenceNumber={referenceNumber} />}
      <Flex />
      <MarginBox mt={15}>
        {availableInUserCountry ? (
          <>
            <Box width={15} />
            <ElectronicPartsRepairLink referenceNumber={referenceNumber} type={'text'} />
            <MarginBox mt={30}>
              <Flex>
                <ReferencePriceSection
                  referenceNumber={refLocal.referenceNumber}
                  sparePartsView={sparePartsView}
                  handleAddToCartClick={handleAddToCartClick}
                  vehicleKey={isUniversalProduct ? undefined : vehicleKey}
                  align={'left'}
                  isApplicableToCurrentVehicle={
                    isApplicableToCurrentVehicle && vehicleDetail?.catalogSource === 'DATAHUB'
                  }
                  catalogSource={DATAHUB}
                  referenceType={type}
                  clientDiscount={myStoreTireDiscount?.discount}
                  isAvailableSoon={isAvailableSoon}
                  isNoLongerAvailable={isNoLongerAvailable}
                />
                {!isNoLongerAvailable && !isAvailableSoon && (
                  <Flex align={'flex-end'}>
                    {price?.garageView?.vatExcludedPrice && (
                      <MarginBox ml={15}>
                        <AddToEstimateButtonAndDialog
                          handleAddToEstimateClick={handleAddToEstimateClick}
                          isVehicleCatalog={isVehicleCatalog}
                        />
                      </MarginBox>
                    )}
                  </Flex>
                )}
              </Flex>
              {type === TIRE && (
                <MarginBox mt={5}>
                  <TireBasketPriceInfo
                    referenceNumber={refLocal.referenceNumber}
                    sparePartsView={sparePartsView}
                    clientDiscount={myStoreTireDiscount?.discount}
                  />
                </MarginBox>
              )}
            </MarginBox>
          </>
        ) : (
          <ReferenceUnavailable size={'medium'} />
        )}
      </MarginBox>
      <Flex align={'flex-end'} />
    </Flex>
  );
};
