import React from 'react';
import { useTranslation } from 'react-i18next';
import { SRectangle } from 'app/App.styled';
import { DartIcon, ManWithMegaphoneIcon } from 'assets/icons';
import { Flex, Icon, MarginBox, Text } from 'UI';

export function NotFound() {
  const { t } = useTranslation();

  return (
    <Flex justify={'center'} align={'center'}>
      <MarginBox mt={50}>
        <Flex direction={'row'} justify={'flex-start'}>
          <Icon IconComponent={ManWithMegaphoneIcon} width={495} height={415} />
          <MarginBox ml={-190}>
            <Flex direction={'column'}>
              <SRectangle>
                <Text type={'h1_banner_light'}>{t('errors.not_found.header_top', '404')}</Text>
                <MarginBox mt={-16}>
                  <Text type={'h1_banner_light'}>{t('errors.not_found.header_bottom', 'Page not found')}</Text>
                  <Text type={'h6_black'}>
                    {t(
                      'errors.not_found.description',
                      "Oops, looks like the page you are looking for doesn't exist or an error occurred.",
                    )}
                  </Text>
                </MarginBox>
              </SRectangle>
              <MarginBox ml={150}>
                <Icon IconComponent={DartIcon} width={190} height={275} />
              </MarginBox>
            </Flex>
          </MarginBox>
        </Flex>
      </MarginBox>
    </Flex>
  );
}
