/* eslint-disable max-len */

import { GetIAMAvailableModulesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/available_modules/response/GetIAMAvailableModulesResponse';
import { ServiceOperation } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/model/ServiceCategory';
import { GetIAMFamilyReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { GetIAMServiceChecklistResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceChecklistResponse';
import {
  GetIAMServiceOperationsResponse,
  ServiceCategory,
} from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceOperationsResponse';
import { GetIAMServiceProposalOperationsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalOperationsResponse';
import { GetIAMServiceProposalResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceProposalResponse';
import moment from 'moment';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA, SearchData } from 'utils';

export const MAINTENANCE_PLAN_NAMESPACE = `${APP_NAMESPACE}/MAINTENANCE_PLAN`;

export const ADD_OPERATION = `${MAINTENANCE_PLAN_NAMESPACE}/ADD_OPERATION`;
export const REMOVE_OPERATION = `${MAINTENANCE_PLAN_NAMESPACE}/REMOVE_OPERATION`;
export const SET_OPERATIONS = `${MAINTENANCE_PLAN_NAMESPACE}/SET_OPERATIONS`;
export const SET_REFERENCES = `${MAINTENANCE_PLAN_NAMESPACE}/SET_REFERENCES`;
export const ADD_REFERENCES = `${MAINTENANCE_PLAN_NAMESPACE}/ADD_REFERENCES`;
export const REMOVE_REFERENCES = `${MAINTENANCE_PLAN_NAMESPACE}/REMOVE_REFERENCES`;
export const SET_PROPOSAL_REGISTRATION_DATE_INPUT = `${MAINTENANCE_PLAN_NAMESPACE}/SET_PROPOSAL_REGISTRATION_DATE_INPUT`;
export const SET_PROPOSAL_MILEAGE_INPUT = `${MAINTENANCE_PLAN_NAMESPACE}/SET_PROPOSAL_MILEAGE_INPUT`;
export const SET_PROPOSAL_OPTION_INPUT = `${MAINTENANCE_PLAN_NAMESPACE}/SET_PROPOSAL_OPTION_INPUT`;
export const GET_IAM_SERVICE_PROPOSALS_REQUEST = `${MAINTENANCE_PLAN_NAMESPACE}/GET_IAM_SERVICE_PROPOSALS_REQUEST`;
export const GET_IAM_SERVICE_PROPOSAL_OPERATIONS_REQUEST = `${MAINTENANCE_PLAN_NAMESPACE}/GET_IAM_SERVICE_PROPOSAL_OPERATIONS_REQUEST`;
export const GET_IAM_AVAILABLE_MODULES_REQUEST = `${MAINTENANCE_PLAN_NAMESPACE}/GET_IAM_AVAILABLE_MODULES_REQUEST`;
export const GET_IAM_SERVICE_CHECKLIST_REQUEST = `${MAINTENANCE_PLAN_NAMESPACE}/GET_IAM_SERVICE_CHECKLIST_REQUEST`;
export const GET_IAM_REPAIR_METHOD_DETAIL_REQUEST = `${MAINTENANCE_PLAN_NAMESPACE}/GET_IAM_REPAIR_METHOD_DETAIL_REQUEST`;

export interface MaintenancePlanState {
  vehicleKey: string | NO_DATA;
  vehicleCode: string | NO_DATA;
  serviceOperationsIAM: GetIAMServiceOperationsResponseLocal | NO_DATA;
  serviceOperationsFamiliesIAM: GetIAMFamilyReferencesResponse | NO_DATA;
  //<k - operation id, v - list of references with quantities>
  serviceOperationsReferences: Map<string, ServiceReferenceLocal[]>;
  availableModules: NO_DATA | GetIAMAvailableModulesResponse;
  registrationDate: NO_DATA | moment.Moment;
  serviceProposalsIAM: NO_DATA | GetIAMServiceProposalResponse;
  serviceProposalsOperationsIAM: NO_DATA | GetIAMServiceProposalOperationsResponse;
  mileage: NO_DATA | number;
  option: NO_DATA | string;
  serviceChecklist: SearchData<GetIAMServiceChecklistResponse> | undefined;
  //<k - categoryId, v - html page>
  repairMethodDetails: Map<string, SearchData<string> | undefined>;
}

export interface ServiceReferenceLocal {
  referenceNumber: string;
  quantity: number;
  family: string;
}

export interface ServiceOperationLocal extends ServiceOperation {
  selected?: boolean;
  subOperations?: ServiceCategoryLocal[];
}

export interface ServiceCategoryLocal extends ServiceCategory {
  operations: ServiceOperationLocal[];
}

export interface GetIAMServiceOperationsResponseLocal extends GetIAMServiceOperationsResponse {
  mainOperations?: ServiceCategoryLocal[];
  additionalOperations?: ServiceCategoryLocal[];
}
