import React from 'react';
import { View } from '@react-pdf/renderer';
import {
  BorderlessCard,
  Divider,
  ItemNameTitle,
  Section,
  Spacer,
  TextDark,
  TextMid,
  TextMidBold,
} from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';
import { textFormatter } from 'utils/format';

export interface TotalSectionData {
  totalSectionText: TotalSectionTexts;
  totalSectionValue: TotalSectionValues;
}

interface TotalSectionTexts {
  total: React.ReactNode;
  totalVatExcl: React.ReactNode;
  totalVat: React.ReactNode;
  totalVatIncl: React.ReactNode;
  totalDiscountVatExcl?: React.ReactNode;
}

export interface TotalSectionValues {
  itemsCount?: string;
  priceVatExcluded?: string;
  priceVat?: string;
  priceVatIncluded?: string;
  discountVatExcluded?: string;
  currency?: string;
}

export interface TotalSectionProps {
  data: TotalSectionData;
}

export const PdfTotalSection = ({ data }: TotalSectionProps) => {
  const { totalSectionText, totalSectionValue } = data;
  return (
    <BorderlessCard
      title={<ItemNameTitle>{totalSectionText.total}</ItemNameTitle>}
      height={'md'}
      width={136}
      alignTitleStart
    >
      <View style={[styles.col, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextMid>{totalSectionText.totalVatExcl}</TextMid>
            </View>
          </View>
          <View style={[styles.col]}>
            <TextDark>
              {textFormatter.formatCurrency(Number(totalSectionValue.priceVatExcluded), totalSectionValue.currency)}
            </TextDark>
          </View>
        </View>
        <Spacer />
        {totalSectionText.totalDiscountVatExcl && (
          <>
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={styles.col}>
                  <TextMid>{totalSectionText.totalDiscountVatExcl}</TextMid>
                </View>
              </View>
              <View style={[styles.col]}>
                <TextDark>
                  {textFormatter.formatCurrency(
                    Number(totalSectionValue.discountVatExcluded),
                    totalSectionValue.currency,
                  )}
                </TextDark>
              </View>
            </View>
            <Spacer />
          </>
        )}
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextMid>{totalSectionText.totalVat}</TextMid>
            </View>
          </View>
          <View style={[styles.col]}>
            <TextDark>
              {textFormatter.formatCurrency(Number(totalSectionValue.priceVat), totalSectionValue.currency)}
            </TextDark>
          </View>
        </View>
        <Spacer />
        <Divider />
        <Spacer />
        <View style={styles.row}>
          <View style={styles.column}>
            <View style={styles.col}>
              <TextMidBold>{totalSectionText.totalVatIncl}</TextMidBold>
            </View>
          </View>
          <View style={[styles.col]}>
            <Section>
              {textFormatter.formatCurrency(Number(totalSectionValue.priceVatIncluded), totalSectionValue.currency)}
            </Section>
          </View>
        </View>
      </View>
    </BorderlessCard>
  );
};
