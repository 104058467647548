import { BuildVersionsResponse } from '@1po/1po-bff-fe-spec/generated/common/BuildVersionsResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { ApplicationConfigResponse } from '@1po/1po-bff-fe-spec/generated/config/response/ApplicationConfigResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import * as actions from 'domains/appContext/AppContext.store';
import { setAppConfig, setBuildVersions } from 'domains/appContext/AppContext.store';
import { LOADING, sagaGuard } from 'utils';
import { sendAppConfigRequest, sendBuildVersionsRequest, sendModifyMockConfig } from './AppContext.api';

function* fetchBuildVersionsSaga(): SagaIterator {
  yield put(setBuildVersions(LOADING));
  yield put(sendBuildVersionsRequest());
}

function* fetchBuildVersionsResponseSaga(action: WsResponse<BuildVersionsResponse>): SagaIterator {
  const { buildVersions } = action.payload;
  yield put(setBuildVersions(buildVersions));
}

function* fetchAppConfigSaga(): SagaIterator {
  yield put(sendAppConfigRequest());
}

function* fetchAppConfigResponseSaga(action: WsResponse<ApplicationConfigResponse>): SagaIterator {
  yield put(setAppConfig(action.payload));
}

function* modifyMockConfig({ payload }: ReturnType<typeof actions.modifyMockConfig>): SagaIterator {
  yield put(sendModifyMockConfig(payload));
}

export function* AppContextSagas(): SagaIterator {
  yield takeEvery(actions.fetchBuildVersions.type, sagaGuard(fetchBuildVersionsSaga));
  yield takeEvery(actions.fetchBuildVersionsResponse.type, sagaGuard(fetchBuildVersionsResponseSaga));
  yield takeEvery(actions.fetchAppConfig.type, sagaGuard(fetchAppConfigSaga));
  yield takeEvery(actions.fetchAppConfigResponse.type, sagaGuard(fetchAppConfigResponseSaga));
  yield takeEvery(actions.modifyMockConfig.type, sagaGuard(modifyMockConfig));
}
