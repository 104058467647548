import React from 'react';
import { Card as AntCard } from 'antd';
import styled, { useTheme } from 'styled-components';
import { Flex, FlexJustifyType, MarginBox, Text } from 'UI';
import { BreakpointSelectable } from 'utils';

export interface CustomCardProps {
  children?: React.ReactNode;
  title: React.ReactNode;
  noPadding?: boolean;
  justify?: BreakpointSelectable<FlexJustifyType>;
  dataCy?: string;
}

export const DarkCard = ({ children, title, noPadding, justify, dataCy }: CustomCardProps) => {
  return (
    <Card
      isDark={true}
      title={
        <Flex justify={justify ?? 'center'}>
          <Text type={'card_title_dark'} dataCy={dataCy}>
            {title}
          </Text>
        </Flex>
      }
      noPadding={noPadding}
    >
      {children}
    </Card>
  );
};

export const LightCard = ({ children, title, dataCy }: CustomCardProps) => {
  return (
    <Card
      isDark={false}
      title={
        <Flex justify={'center'}>
          <Text type={'card_title_light'} dataCy={dataCy}>
            {title}
          </Text>
        </Flex>
      }
    >
      {children}
    </Card>
  );
};

interface SCardProps {
  background: string;
  $noPadding?: boolean;
}

const SCard = styled(AntCard)<SCardProps>`
  height: 100%;
  box-shadow: 0 4px 10px 0 ${({ theme }) => theme.color.shadow_2};

  .ant-card-head {
    height: 60px;
    background-color: ${({ background }) => background};
  }

  .ant-card-body {
    padding: ${({ $noPadding }) => ($noPadding ? 0 + 'px' : '')};
  }
`;

interface CardProps {
  children?: React.ReactNode;
  title: React.ReactNode;
  isDark: boolean;
  noPadding?: boolean;
}

const Card = ({ children, isDark, title, noPadding = false }: CardProps) => {
  const theme = useTheme();
  const backgroundCol = isDark ? theme.color.grey00 : theme.color.grey95;

  return (
    <SCard background={backgroundCol} $noPadding={noPadding} title={<MarginBox ml={10}>{title}</MarginBox>}>
      {children}
    </SCard>
  );
};
