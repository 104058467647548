import styled from 'styled-components';
import { Flex } from 'UI';

export const NotificationWrapper = styled(Flex)<{ backgroundColor?: string }>`
  width: 100%;
  min-height: 120px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.white};
  border: 3px solid ${({ theme }) => theme.color.yellow_light};
  padding: 15px 20px 15px 25px;
`;
