/* eslint-disable max-len */
import { GetLaborTimeIDsByGenericPartRequest } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/request/GetLaborTimeIDsByGenericPartRequest';
import { GetLaborTimesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/request/GetLaborTimesRequest';
import {
  GET_LABOR_TIME_IDS_BY_GENERIC_PART_URL,
  GET_LABOR_TIMES_URL,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendGetLaborTimesByGenericPart(request: GetLaborTimeIDsByGenericPartRequest): WebSocketAction {
  return wsSendAction(GET_LABOR_TIME_IDS_BY_GENERIC_PART_URL, request);
}

export function sendLaborTimesRequest(request: GetLaborTimesRequest): WebSocketAction {
  return wsSendAction(GET_LABOR_TIMES_URL, request);
}
