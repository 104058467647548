/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Reference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { getDHReferences } from 'domains/references';
import { useFetchCrossSellingReferences, useFetchReuseStocks } from 'domains/references/References.requests';
import { getSparePartsView } from 'domains/user';
import { Empty, Flex, MarginBox } from 'UI';
import { InfiniteScroll, useInfiniteScroll } from 'UI/InfiniteScroll';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { NO_DATA } from 'utils';
import { SearchResultReferenceContainer } from './SearchResultReferenceContainer';

const PAGING_SIZE = 15;

export interface SearchResultReferenceSectionProps {
  refNumbers: string[];
}

export function containsReferences(data: (Reference | NO_DATA)[]): data is Reference[] {
  return (data as Reference[])?.length > 0;
}

const SResultContainer = styled.div`
  text-align: left;
  display: block;
  margin: 10px 10px 10px 20px;
`;

export const SearchResultReferenceSection = ({ refNumbers }: SearchResultReferenceSectionProps) => {
  const sparePartsView = useSelector(getSparePartsView);
  const catalogVehicle = useSelector(getLastVehicleDetail);
  const vehicleBrand = catalogVehicle?.vehicleBrand ?? 'OTHER';
  const { hasMore, loadMore, currentData } = useInfiniteScroll<string>({
    data: refNumbers,
    chunkSize: PAGING_SIZE,
  });
  const references = useSelector((state: RootState) =>
    getDHReferences(state, { vehicleKey: catalogVehicle?.vehicleKey, referenceNumbers: currentData }),
  );
  const seePlateState = useState<string | undefined>(undefined);

  useFetchCrossSellingReferences(refNumbers, catalogVehicle?.vehicleKey);
  useFetchReuseStocks(refNumbers);

  if (!containsReferences(references)) {
    return <Empty />;
  }

  return (
    <SResultContainer>
      <Flex direction={'column'}>
        <ViewTabs />
        <MarginBox mt={30}>
          <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
            {references.map((ref, index) => (
              <SearchResultReferenceContainer
                key={`searched-ref-${index}`}
                referenceNumber={ref.referenceNumber}
                sparePartsView={sparePartsView}
                vehicleBrand={vehicleBrand}
                seePlateState={seePlateState}
              />
            ))}
          </InfiniteScroll>
        </MarginBox>
      </Flex>
    </SResultContainer>
  );
};
