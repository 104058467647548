import React from 'react';
import styled from 'styled-components';
import { theme } from 'styles';
import { CenterFlex } from '../Flex';

const SBubble = styled.div<{ bgColor: string; color: string; borderColor: string }>`
  height: 30px;
  width: 90px;
  border-radius: 16px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-family: NouvelR;
  border: ${({ borderColor }) => borderColor} solid 1px;
`;

export interface BubbleProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export const Bubble = ({ children, className, disabled }: BubbleProps) => {
  return (
    <SBubble
      className={className}
      bgColor={disabled ? theme.color.shadow_5 : theme.color.off_white}
      color={disabled ? theme.color.shadow_8 : theme.color.yellowish_orange}
      borderColor={disabled ? theme.color.shadow_6 : theme.color.yellow_light_light_120}
    >
      <CenterFlex>{children}</CenterFlex>
    </SBubble>
  );
};
