import styled from 'styled-components';
import { Flex, Text } from 'UI';

export const SText = styled(Text)`
  margin-left: 25px;
`;

export const SectionCard = styled(Flex)<{
  editMode?: boolean;
}>`
  margin-bottom: 32px;
  min-height: 135px;
  border: ${({ editMode }) => `solid 1px rgba(0, 0, 0, ${editMode ? '0' : '0.1'})`};
  border-radius: ${({ editMode }) => (editMode ? `0px` : '4px')};
  padding: 24px;
`;
