import { TFunction } from 'i18next';
import { DefaultTheme } from 'styled-components';
import { FileExcelIcon, FileTextIcon } from 'assets/icons';
import { ThemeDisplayStyleKeyType } from 'styles';
import { IconType } from 'UI';

export const NO_FILE = 'no_file';
export const FILE = 'file';
export const FILE_UPLOADED = 'file_uploaded';
export const UPLOADING_FILE = 'uploading_file';
export const CONTENT_LIMIT_ERROR = 'content_limit_error';
export const TECHNICAL_ERROR = 'technical_error';
export const FILE_FORMAT_ERROR = 'file_format_error';
export const MAX_SIZE_ERROR = 'max_size_error';
export const API_CALL_ERROR = 'api_call_error';
export const EMPTY_FILE_ERROR = 'empty_file_error';
export const SAME_FILE_ERROR = 'same_file_error';
export const FILE_STRUCTURE_ERROR = 'file_structure_error';

export type ModalStatusType =
  | typeof NO_FILE
  | typeof UPLOADING_FILE
  | typeof FILE_UPLOADED
  | typeof FILE
  | typeof CONTENT_LIMIT_ERROR
  | typeof TECHNICAL_ERROR
  | typeof FILE_FORMAT_ERROR
  | typeof MAX_SIZE_ERROR
  | typeof FILE_STRUCTURE_ERROR
  | typeof API_CALL_ERROR
  | typeof EMPTY_FILE_ERROR
  | typeof SAME_FILE_ERROR;

export interface ParamsByState {
  icon: IconType;
  iconColor: string;
  getText1Override?: (type: ModalStatusType) => string | undefined;
  text1: string;
  textType1: ThemeDisplayStyleKeyType | undefined;
  textType2: ThemeDisplayStyleKeyType | undefined;
  isImportDisabled: boolean;
  isCancelDisabled: boolean;
}

export function defaultParamsByState(
  theme: DefaultTheme,
  t: TFunction,
  getText1Override?: (type: ModalStatusType) => string | undefined,
): ParamsByState {
  return paramsByState(NO_FILE, '', theme, t, getText1Override);
}

export default function paramsByState(
  status: ModalStatusType,
  fileName: string,
  theme: DefaultTheme,
  t: TFunction,
  getText1Override?: (type: ModalStatusType) => string | undefined,
): ParamsByState {
  function getIcon() {
    switch (status) {
      case NO_FILE:
        return FileTextIcon;
      default:
        return FileExcelIcon;
    }
  }

  function getIconColor() {
    switch (status) {
      case NO_FILE:
        return theme.color.clear_blue;
      case FILE:
      case FILE_UPLOADED:
      case UPLOADING_FILE:
        return theme.color.brand_black;
      default:
        return theme.color.error;
    }
  }

  function getText1() {
    const overriddenText = getText1Override && getText1Override(status);
    if (overriddenText) {
      return overriddenText;
    }
    switch (status) {
      case FILE:
      case UPLOADING_FILE:
        return fileName;
      case TECHNICAL_ERROR:
        return t('common.file_upload.drag_n_drop.technical_error', 'Something went wrong');
      case FILE_FORMAT_ERROR:
        return t('common.file_upload.drag_n_drop.format_error', 'Wrong format');
      case EMPTY_FILE_ERROR:
        return t('common.file_upload.drag_n_drop.empty_file_error', 'Empty file');
      case SAME_FILE_ERROR:
        return t('common.file_upload.drag_n_drop.same_file_error', 'Same file');
      case MAX_SIZE_ERROR:
        return t('common.file_upload.drag_n_drop.max_size_error', 'File size exceeds 10KB');
      case FILE_STRUCTURE_ERROR:
        return t('common.file_upload.drag_n_drop.file_error.structure_error', "The file's structure is incorrect");
      case API_CALL_ERROR:
        return t('common.file_upload.drag_n_drop.api_call_error', 'No response from API');
      default:
        return t('common.file_upload.drag_n_drop', 'Click or drag your files here for upload');
    }
  }

  function gettextType1() {
    switch (status) {
      case NO_FILE:
      case FILE:
      case FILE_UPLOADED:
      case UPLOADING_FILE:
        return undefined;
      default:
        return 'error';
    }
  }

  function gettextType2() {
    switch (status) {
      case NO_FILE:
      case FILE:
      case FILE_UPLOADED:
      case UPLOADING_FILE:
        return undefined;
      default:
        return 'error';
    }
  }

  function getIsImportDisabled() {
    switch (status) {
      case FILE:
        return false;
      default:
        return true;
    }
  }

  function getIsCancelDisabled() {
    switch (status) {
      case UPLOADING_FILE:
        return true;
      default:
        return false;
    }
  }

  return {
    icon: getIcon(),
    iconColor: getIconColor(),
    text1: getText1(),
    textType1: gettextType1(),
    textType2: gettextType2(),
    isImportDisabled: getIsImportDisabled(),
    isCancelDisabled: getIsCancelDisabled(),
  };
}
