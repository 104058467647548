import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CFSectionLocal, CreatePromotionModel, isFlashPromotion } from 'domains/promotion/Promotion.types';
import { Flex, MarginBox, PlusCircleButton, Text } from 'UI';
import { ApplyDiscountCommercialFamily } from './ApplyDiscountCommercialFamily';
import { CommercialFamiliesInput } from './CommercialFamiliesInput';
import { AlreadyExistingCommercialFamilyCodeNotification } from './PromotionErrorNotifications';

export function CommercialFamilyDiscountRow({
  promotion,
  setPromotion,
  setCurrentStep,
  maxCommercialFamiliesGroups,
}: Readonly<{
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  setCurrentStep: (x: number) => void;
  maxCommercialFamiliesGroups: number;
}>) {
  const { t } = useTranslation();
  const [displayAddButton, setDisplayAddButton] = useState<boolean>(false);

  const commercialFamilyCodesSections = useMemo(() => promotion.commercialFamilyCodes ?? [{ items: [], discount: 0 }], [
    promotion,
  ]);

  const addLine = () => {
    if (promotion.commercialFamilyCodes && promotion.commercialFamilyCodes.length >= maxCommercialFamiliesGroups) {
      return;
    }
    setDisplayAddButton(false);
    setPromotion({
      ...promotion,
      commercialFamilyCodes: [...(promotion.commercialFamilyCodes ?? []), { items: [], discount: 0 }],
    });
  };

  const handleDisplayAddButton = useCallback(() => {
    setDisplayAddButton(
      commercialFamilyCodesSections.filter(
        (section: CFSectionLocal) => section.items.length === 0 && section.discount === 0,
      ).length === 0 && commercialFamilyCodesSections.length < maxCommercialFamiliesGroups,
    );
  }, [commercialFamilyCodesSections, maxCommercialFamiliesGroups]);

  const renderDiscount = !isFlashPromotion(promotion.promotionType) && promotion.promotionType !== 'BANNER';

  useEffect(() => {
    handleDisplayAddButton();
  }, [handleDisplayAddButton]);

  return (
    <>
      <AlreadyExistingCommercialFamilyCodeNotification
        promotion={promotion}
        setPromotion={setPromotion}
        setCurrentStep={setCurrentStep}
      />
      <Flex direction={'row'}>
        <CommercialFamiliesInput promotion={promotion} setPromotion={setPromotion} />
        <Flex />
        {renderDiscount && <ApplyDiscountCommercialFamily promotion={promotion} setPromotion={setPromotion} />}
      </Flex>
      {displayAddButton && (
        <>
          <MarginBox mt={30} />
          <PlusCircleButton onClick={addLine}>
            <Text type={'link_bold'} decoration={'underline'}>
              {t('backoffice.promotion.add_commercial_family', 'Add commercial family')}
            </Text>
          </PlusCircleButton>
        </>
      )}
    </>
  );
}
