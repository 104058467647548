/* eslint-disable max-len */
import { DeliveryLeadTime } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import { APP_NAMESPACE } from 'app/App.constants';

export const DELIVERY_LEAD_TIME_NAMESPACE = `${APP_NAMESPACE}/DELIVERY_LEAD_TIME`;
export const GET_DELIVERY_LEAD_TIME = `${DELIVERY_LEAD_TIME_NAMESPACE}/GET_DELIVERY_LEAD_TIME`;
export const UPDATE_DELIVERY_LEAD_TIME = `${DELIVERY_LEAD_TIME_NAMESPACE}/UPDATE_DELIVERY_LEAD_TIME`;

export interface DeliveryLeadTimeState {
  warehouses: DeliveryLeadTime[];
}

export const CENTRAL_DELIVERY = 'CENTRAL';
export const REGION_DELIVERY = 'REGION';
export const COUNTRY_DELIVERY = 'COUNTRY';
export type DELIVERY_LEAD_TIME_WAREHOUSE = typeof CENTRAL_DELIVERY | typeof COUNTRY_DELIVERY | typeof REGION_DELIVERY;
