/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { RootState } from 'app/AppStore';
import { AngleLeftIcon, AngleRightIcon } from 'assets/icons';
import { DiscountCorner, FlashCertificate } from 'components/Discount';
import { getIsStockAvailable } from 'domains/references';
import { GarageView, SparePartsViewType } from 'domains/user';
import { ProductImage } from 'pages/CatalogPage/DH/Product/ProductImageCard/ProductImage';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { TireBrandImage } from 'pages/TiresPage/TireReferencesSection/TireReferenceCard/TireBrandImage';
import { Flex, Icon, MarginBox } from 'UI';
import { CarBrandContainer, PromotionContainer, SProductCard, SProductImageContainer } from './ProductImageCard.styled';

export interface ProductDetailCardProps {
  productImageKey: string | undefined;
  referenceNumber: string;
  referenceBrand?: ReferenceBrandType;
  vehicleBrand: VehicleBrandType;
  tireBrandId?: string;
  browseLeft: () => void;
  browseRight: () => void;
  countOfImages: number;
  sparePartsView: SparePartsViewType;
  catalogSource: CatalogSource;
}

const ProductImageCard = ({
  productImageKey,
  referenceNumber,
  browseLeft,
  browseRight,
  vehicleBrand,
  referenceBrand,
  tireBrandId,
  countOfImages,
  sparePartsView,
  catalogSource,
}: ProductDetailCardProps) => {
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));

  const renderPromoCorner = () => {
    if (sparePartsView !== GarageView) {
      return null;
    }

    return (
      <>
        <FlashCertificate reference={referenceNumber} type={'product'} />
        <DiscountCorner reference={referenceNumber} type={'product'} />
      </>
    );
  };

  return (
    <SProductCard id={'product_image_header'}>
      <Flex direction={'row'}>
        {countOfImages > 1 && (
          <Flex direction={'column'} justify={'center'}>
            <MarginBox mx={10}>
              <Icon size={24} IconComponent={AngleLeftIcon} onClick={browseLeft} />
            </MarginBox>
          </Flex>
        )}
        <SProductImageContainer>
          <ProductImage
            imageUrl={productImageKey}
            catalogSource={catalogSource}
            alt={`reference-${referenceNumber}`}
            availableInUserCountry={availableInUserCountry}
          />
          <PromotionContainer>{renderPromoCorner()}</PromotionContainer>
          <CarBrandContainer>
            {tireBrandId ? (
              <TireBrandImage referenceBrandId={tireBrandId} width={150} height={45} />
            ) : (
              <BrandImage vehicleBrand={vehicleBrand} referenceBrand={referenceBrand} size={120} />
            )}
          </CarBrandContainer>
        </SProductImageContainer>
        {countOfImages > 1 && (
          <Flex direction={'column'} justify={'center'}>
            <MarginBox mx={10}>
              <Icon size={24} IconComponent={AngleRightIcon} onClick={browseRight} />
            </MarginBox>
          </Flex>
        )}
      </Flex>
    </SProductCard>
  );
};

export default ProductImageCard;
