import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_ORDERS, ROUTER_RETURN_ORDER } from 'app/AppRouter';
import { InProgressOrderDetailsWrapper } from 'pages/MyOrdersPage/InProgressOrders/InProgressOrderDetailsWrapper';
import { ARCHIVED, EXTERNAL, IN_PROGRESS } from 'pages/MyOrdersPage/Orders.type';
import { ArchivedOrders } from './ArchivedOrders/ArchivedOrders';
import ExternalOrderDetails from './ExternalOrders/ExternalOrderDetails';
import MyOrdersPage from './MyOrdersPage';

export const MyOrdersRouter = () => (
  <Switch>
    <Route path={`${ROUTER_ORDERS}/${IN_PROGRESS}/:orderId`} component={InProgressOrderDetailsWrapper} />
    <Route
      path={`${ROUTER_ORDERS}/${IN_PROGRESS}/:orderId${ROUTER_RETURN_ORDER}`}
      component={InProgressOrderDetailsWrapper}
    />
    <Route path={`${ROUTER_ORDERS}/${EXTERNAL}/:orderId`} component={ExternalOrderDetails} />
    <Route path={`${ROUTER_ORDERS}/${ARCHIVED}/:orderId`} component={ArchivedOrders} />
    <Route path={ROUTER_ORDERS} component={MyOrdersPage} />
  </Switch>
);
