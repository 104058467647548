import { Form, Input } from 'antd';
import styled from 'styled-components';

export const SInput = styled(Input)`
  width: 300px;
`;

export const SForm = styled(Form)`
  margin-top: -4px;
`;
