import React from 'react';
import { Table } from 'antd';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { ManageCookiesButton } from 'components/Page/Footer/Footer.styled';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Country } from 'utils/i18n/Country';

export const CookiesAT = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>COOKIE-RICHTLINIE</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          Der Schutz Ihrer Online-Daten hat für Renault Priorität. Unsere Website verwendet Technologien wie Cookies, um
          Ihnen ein hochwertiges, reibungsloses und personalisiertes Erlebnis zu bieten.
        </Paragraph>
        <Paragraph>
          Sie können Ihre Entscheidungen in Bezug auf Cookies jederzeit ändern, indem Sie unser Tool zur Verwaltung von
          Cookies verwenden:{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              MEINE ENTSCHEIDUNGEN VERWALTE
            </button>
          </ManageCookiesButton>
          .
        </Paragraph>
        <Section>
          <SectionHeader title={'1. WER SIND WIR?'} underline />
          <Paragraph>
            Willkommen auf der Website{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              {'https://rpartstore.renault.com'}
            </Link>
            , welche von Renault SAS veröffentlicht wird. Renault SAS ist der unabhängige Verantwortliche für die Daten,
            die durch Cookies und andere Tracker, die auf Ihrem Gerät gelesen oder platziert werden, gesammelt werden
            (im Folgenden „wir“, „uns“, „unser“).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. WAS SIND COOKIES?'} underline />
          <Paragraph>
            Ein Cookie ist eine kleine Computerdatei, ein Tracker, der beim Besuch einer Website, bei der Nutzung einer
            mobilen App, beim Lesen einer E-Mail, bei der Installation oder Nutzung von Software, unabhängig von der Art
            des verwendeten Endgeräts (Computer, Smartphone, digitales Lesegerät, Videospielkonsole mit
            Internetanschluss usw.), hinterlegt und gelesen wird.
          </Paragraph>
          <Paragraph>
            Beim Besuch einer Website können Cookies Informationen an den Browser des Nutzers senden und diesem
            ermöglichen, Informationen an die ursprüngliche Website zurückzusenden (z. B. eine Sitzungs-ID oder die
            gewählte Sprache).
          </Paragraph>
          <Paragraph>
            Cookies ermöglichen es uns nicht, Sie direkt zu identifizieren, sondern lediglich, spezifische Informationen
            über Ihr Gerät, Ihren Browser, Ihre Interaktionen mit der von Ihnen genutzten Website oder App und in
            einigen Fällen Ihren Standort zu sammeln.
          </Paragraph>
          <Paragraph>
            Die verwendeten Technologien können mehrere der von Ihnen verwendeten Geräte miteinander verknüpfen, um mit
            hinreichender Wahrscheinlichkeit feststellen zu können, dass diese Geräte Ihnen gehören.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. WOFÜR WERDEN COOKIES AUF DIESER WEBSITE VERWENDET?'} underline />
          <Paragraph>Diese Website verwendet verschiedene Arten von Cookies.</Paragraph>
          <SectionHeader title={'Technische Cookies'} mb={0} />
          <Paragraph>
            Zweck dieser Cookies ist es, die elektronische Kommunikation (Erkennung von Verbindungsfehlern,
            Identifizierung von Verbindungspunkten, Sicherheit der Website usw.) zu ermöglichen oder zu erleichtern oder
            Online-Kommunikationsdienste, die Sie ausdrücklich anfordern können (Präferenzen für die Bildschirmanzeige,
            Speicherung der in die Formulare eingegebenen Informationen, ggf. Warenkorb.), bereitzustellen.
          </Paragraph>
          <Paragraph>Diese Cookies sind für das reibungslose Funktionieren unserer Website erforderlich.</Paragraph>
          <Paragraph>
            Sie können der Verwendung dieser Cookies nicht widersprechen und Ihre Zustimmung ist nicht erforderlich, da
            die Dienste auf unserer Website auf diese Cookies angewiesen sind.
          </Paragraph>
          <SectionHeader title={'Analyse-Cookies'} mb={0} />
          <Paragraph>
            Diese Cookies dienen der Analyse des Verkehrs auf der Website, der Analyse des Nutzererlebnisses oder
            einfach der Sammlung aggregierter Signale zum Besuchsfluss auf der Website (z. B. gewählte Sprache, zuvor
            besuchte Seite, Zeitstempel des Besuchs, Wahl der Cookies).
          </Paragraph>
          <Paragraph>
            Diese Cookies können Daten verarbeiten, die sich auf die besuchten Seiten, die Verweildauer auf den
            besuchten Seiten, den von Ihnen zum Besuch dieser Seiten verwendeten Browser, Ihre Nutzung des Chats oder
            Chatbots auf unserer Website usw. beziehen.
          </Paragraph>
          <Paragraph>
            Die von diesen Cookies erfassten Daten können auch zur Erstellung von Algorithmen und Profilen verwendet
            werden, um Ihre potenziellen Interessen und Vorlieben für unsere Produkte und Dienstleistungen zu
            analysieren. Wir können diese Daten mit anderen Informationen kombinieren, z. B. mit Informationen aus
            unserer Kundendatenbank.
          </Paragraph>
          <Paragraph>
            Die Verwendung und Speicherung dieser Cookies unterliegen grundsätzlich Ihrer Zustimmung.
          </Paragraph>
          <Paragraph>
            Unter bestimmten Bedingungen können diese Cookies ohne Ihre Zustimmung platziert/gelesen werden, zum
            Beispiel wenn der Zweck der Cookies ausschließlich darin besteht, die von Ihnen besuchten Inhalte zu
            analysieren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'4. WELCHE COOKIES WERDEN AUF DIESER WEBSITE VERWENDET?'} underline />
          <Paragraph>
            Die beigefügte Tabelle enthält eine Liste aller von dieser Website gespeicherten und/oder verwendeten
            Cookies und deren Hauptmerkmale. Wann immer möglich, werden diese Cookies auf Servern im Europäischen
            Wirtschaftsraum (EWR) oder im Vereinigten Königreich gespeichert. Da jedoch einige unserer Dienstleister in
            Ländern außerhalb des EWR oder des Vereinigten Königreichs ansässig sind, werden Ihre personenbezogenen
            Daten in diesen Ländern verarbeitet, in denen möglicherweise andere Vorschriften für personenbezogene Daten
            gelten als in der Europäischen Union oder im Vereinigten Königreich. Ist das der Fall, (i) achten wir
            besonders darauf, dass diese Übermittlung in Übereinstimmung mit den geltenden Vorschriften erfolgt, (ii)
            treffen wir Vorkehrungen, um einen angemessenen Schutz Ihrer Daten zu gewährleisten (insbesondere durch
            Anwendung der Standardvertragsklauseln der Europäischen Kommission und/oder etwaiger
            Standardvertragsklauseln des Vereinigten Königreichs (soweit anwendbar)). Weitere Informationen zu den
            vereinbarten Standardvertragsklauseln erhalten Sie unter{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Unsere Website kann Cookies von Dritten (Kommunikationsagenturen, Analysedienste usw.) enthalten, die es
            ihnen ermöglichen, während der Lebensdauer der Cookies Informationen über die Navigation auf unserer Website
            zu sammeln. Die Speicherung und Verwendung dieser Cookies durch Dritte unterliegt den
            Datenschutzbestimmungen dieser Dritten. Wir informieren Sie über den Zweck der Cookies von Drittanbietern
            auf unserer Website sowie über die Mittel, die Ihnen zur Verfügung stehen, um Entscheidungen über diese
            Cookies zu treffen.
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Zweck',
                dataIndex: 'type',
                key: 'type',
              },
              { title: 'Partnereigentümer Cookie', dataIndex: 'site', key: 'site' },
              {
                title: 'Cookie Richtlinie',
                dataIndex: 'guideline',
                key: 'guideline',
                render: (guideline) => (
                  <Link to={guideline} external newTab>
                    Cookie Richtlinie
                  </Link>
                ),
              },
            ]}
            dataSource={[
              {
                id: 0,
                site: 'ONE TRUST',
                type: 'TECHNISCH',
                guideline: 'https://www.onetrust.com/de/cookie-policy/',
              },
              {
                id: 1,
                site: 'RENAULT',
                type: 'TECHNISCH',
                guideline: 'https://www.renault.fr/cookies.html',
              },
              {
                id: 2,
                site: 'Matomo',
                type: 'ANALYSE',
                guideline: 'https://matomo.org/privacy-policy/',
              },
              {
                id: 3,
                site: 'DYNATRACE',
                type: 'ANALYSE',
                guideline: 'https://www.dynatrace.com/company/trust-center/privacy/',
              },
              {
                id: 4,
                site: 'CONTENSQUARE',
                type: 'ANALYSE',
                guideline: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
              },
              {
                id: 5,
                site: 'GOOGLE',
                type: 'ANALYSE',
                guideline: 'https://policies.google.com/technologies/cookies?hl=de%20-%20how-google-uses-cookies',
              },
              {
                id: 6,
                site: 'YOUTUBE',
                type: 'SOZIALE MEDIEN',
                guideline: 'https://policies.google.com/technologies/cookies?hl=de%20-%20how-google-uses-cookies',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'5. WIE WIRD IHRE ZUSTIMMUNG EINGEHOLT?'} underline />
          <Paragraph>
            Wenn Sie unsere Website zum ersten Mal besuchen, werden Sie durch die Anzeige eines speziellen
            Informationsbanners aufgefordert, der Verwendung von Cookies zuzustimmen oder sie abzulehnen oder bestimmte
            Entscheidungen über die Verwendung von Cookies zu treffen.
          </Paragraph>
          <Paragraph>
            Sie können Ihre Zustimmung jederzeit widerrufen oder Ihre Entscheidungen (z. B. nur bestimmte Arten von
            Cookies zu akzeptieren) mit unserem speziellen Tool ändern.
          </Paragraph>
          <Paragraph>
            Bitte beachten Sie, dass Cookies, die für das Funktionieren der Website erforderlich sind, gemäß den
            Vorschriften nicht Ihrer Zustimmung unterliegen und daher in unserem Cookie-Management-Tool nicht
            deaktiviert werden können. Sie können deren Verwendung verhindern, indem Sie die Einstellungen Ihres
            Browsers ändern (siehe Abschnitt „Ablehnung von Cookies“ weiter unten), jedoch kann dann die Qualität Ihrer
            Erfahrung auf der Website darunter leiden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'6. WIE LANGE WIRD IHRE ZUSTIMMUNG GESPEICHERT?'} underline />
          <Paragraph>
            Wenn Sie Cookies auf unserer Website ganz oder teilweise akzeptieren oder ablehnen, wird Ihre Auswahl für
            einen Zeitraum von höchstens 6 Monaten gespeichert und aufbewahrt. Für diesen Zeitraum bewahren wir auch
            eine Aufzeichnung Ihrer Präferenzen auf. Das bedeutet, dass wir Sie bei einem späteren Besuch unserer
            Website während dieses Zeitraums nicht zu einer Handlung in Bezug auf Cookies auffordern werden, da wir Ihre
            ausgewählten Einstellungen gespeichert haben.
          </Paragraph>
          <Paragraph>
            Nach Ablauf dieser Frist werden wir Sie erneut bitten, der Verwendung von Cookies zuzustimmen oder sie
            abzulehnen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. WIE KANN ICH COOKIES VERWALTEN?'} underline />
          <Paragraph>
            <Text type={'text'}>Wie kann ich mein Widerspruchsrecht ausüben?</Text>
            <br />
            <Text type={'text'}>
              Wenn Sie nicht möchten, dass Cookies auf Ihrem Gerät gespeichert oder gelesen werden, wird ein
              Widerspruch-Cookie auf Ihrem Gerät gespeichert. Mit diesem halten wir fest, dass Sie der Verwendung von
              Cookies widersprochen haben. Wenn Sie dieses Widerspruch-Cookie löschen, können wir nicht mehr erkennen,
              dass Sie die Verwendung und Speicherung von Cookies abgelehnt haben.{' '}
            </Text>
          </Paragraph>
          <Paragraph>
            Sie haben mehrere Möglichkeiten zur Verwaltung von Cookies, einschließlich der Blockierung ihrer Verwendung
            und/oder ihre Löschung.
          </Paragraph>
          <Paragraph>
            Cookie-Manager
            <br />
            Es wird ein Cookie-Manager bereitgestellt, mit dem Sie die auf dieser Website gespeicherten und/oder
            verwendeten Cookies verwalten können. Über untenstehenden Link können Sie jederzeit auf den Konfigurator
            zugreifen und Ihre Einstellungen entsprechend der Art des Cookies ändern.
          </Paragraph>
          <Paragraph>
            Browser-Einstellungen
            <br />
            Sie können Cookies auch über Ihren Browser verwalten.
          </Paragraph>
          <Paragraph>
            Die meisten Browser sind standardmäßig so eingestellt, dass sie Cookies akzeptieren. Wenn Sie möchten,
            können Sie stattdessen auswählen, alle Cookies zu akzeptieren, Cookies systematisch abzulehnen oder
            auswählen, welche Cookies Sie akzeptieren, je nachdem, woher sie stammen. Sie können Cookies auch regelmäßig
            mittels Ihres Browsers von Ihrem Gerät löschen.
          </Paragraph>
          <Paragraph>
            Vergessen Sie jedoch nicht, alle Browser auf Ihren verschiedenen Geräten (Tablets, Smartphones, Computer
            usw.) entsprechend einzurichten.
          </Paragraph>
          <Paragraph>
            Jeder Browser hat eine andere Einstellung für die Verwaltung von Cookies und Ihre Wahlmöglichkeiten. Dies
            wird im Hilfemenü Ihres Browsers beschrieben, wo Sie z. B. erfahren, wie Sie Ihre Cookie-Einstellungen
            ändern können:
            <Ul>
              <Li>
                <Text type={'text'}>
                  für Internet Explorer™:{' '}
                  <Link to={'https://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies'} external newTab>
                    https://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Safari™:{' '}
                  <Link to={'https://support.apple.com/de-de/safari'} external newTab>
                    https://support.apple.com/de-de/safari
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Chrome™:{' '}
                  <Link
                    to={'https://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647'}
                    external
                    newTab
                  >
                    https://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Firefox™:{' '}
                  <Link to={'https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen'} external newTab>
                    https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Opera™:{' '}
                  <Link to={'https://help.opera.com/Windows/10.20/de/cookies.html'} external newTab>
                    https://help.opera.com/Windows/10.20/de/cookies.html
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Verlagsmodule für Einwände
            <br />
            Sie können einige Cookies von Drittanbietern deaktivieren, indem Sie direkt auf die Seite des Herausgebers
            des Cookies gehen. Wie folgt:{' '}
          </Paragraph>
          <Paragraph align={'start'}>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Um Google Analytics-Cookies zu deaktivieren, gehen Sie auf diese Seite:{' '}
                  <Link to={'https://tools.google.com/dlpage/gaoptout?hl=de'} external newTab>
                    https://tools.google.com/dlpage/gaoptout?hl=de
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Um intelliAd-Cookies zu deaktivieren, gehen Sie auf diese Seite:{' '}
                  <Link to={'https://login.intelliad.com/optout.php'} external newTab>
                    https://login.intelliad.com/optout.php
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Um Adobe-Cookies zu deaktivieren, gehen Sie auf diese Seite:{' '}
                  <Link to={'https://www.adobe.com/de/privacy/opt-out.html'} external newTab>
                    https://www.adobe.com/de/privacy/opt-out.html
                  </Link>
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. WIE KÖNNEN SIE IHRE ANDEREN RECHTE WAHRNEHMEN? '} underline />
          <Paragraph>
            Gemäß den geltenden Rechtsvorschriften zum Schutz personenbezogener Daten haben Sie das Recht auf Zugang,
            Berichtigung, Widerspruch, Einschränkung und Löschung Ihrer personenbezogenen Daten. Um Ihre anderen Rechte
            auszuüben, konsultieren Sie bitte unsere Informationen über den Schutz Ihrer personenbezogenen Daten, indem
            Sie auf folgenden{' '}
            <Link to={ROUTER_PERSONAL_DATA(Country.AT.key)} newTab>
              Link klicken
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'9. ÄNDERUNGEN'} underline />
          <Paragraph>
            Wir können diese Richtlinien jederzeit ändern. Wir werden Sie darüber informieren und/oder Ihre Einwilligung
            einholen, wann immer dies notwendig oder erforderlich ist. Wir empfehlen Ihnen daher, diese Richtlinie jedes
            Mal zu konsultieren, wenn Sie unsere Website besuchen, um sich die jeweils neueste Version anzeigen zu
            lassen.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Letzte Aktualisierung: Mai 2024</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
