import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Text } from 'UI';

const bgImage = css<{ image: string }>`
  background-image: url(${({ image }) => `/images/${image}`});
`;

export const SBackground = styled.div<{ image: string; isMainSearch: boolean }>`
  background-color: ${theme.color.grey95};
  ${({ isMainSearch }) => isMainSearch && bgImage}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: ${({ isMainSearch }) => (isMainSearch ? '800px' : '260px')};
`;

export const IconButtonLabel = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  flex: none;
`;
