import styled from 'styled-components';

export const InfoTitle = styled.div`
  font-size: 27px;
  font-weight: bold;
  margin-top: 23px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.ink_black};
`;

export const InfoText = styled.div`
  font-size: ${({ theme }) => theme.font.light_12_black_85.size};
  color: ${({ theme }) => theme.color.grey55};
  margin-top: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
`;
export const ItemWrapper = styled.div`
  width: 248px;
  margin: 20px auto 20px auto;
  text-align: center;
`;
