import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDisclosure } from 'components/Dialog';
import {
  getIAMServiceChecklistStatus,
  getLastSearchServiceOperationsData,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { BottomStickyBox } from 'pages/CatalogPage/IAM/MaintenancePlan/BottomStickyBox';
import OverviewTable from 'pages/CatalogPage/IAM/MaintenancePlan/Overview/OverviewTable';
import { Box, MarginBox, SpinDialog, Text } from 'UI';

const Overview = ({ handlePrint, handleValidate }: { handlePrint: () => void; handleValidate: () => void }) => {
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const state = useSelector(getIAMServiceChecklistStatus);
  const operationsData = useSelector(getLastSearchServiceOperationsData);
  const selectedMainOperations = operationsData?.mainOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected).length;
  const selectedAddOperations = operationsData?.additionalOperations
    ?.flatMap((cat) => cat.operations)
    .filter((op) => op.selected).length;
  const { onOpen, onClose } = disclosure;
  const isThereSomeSelectedOperations =
    (selectedAddOperations && selectedAddOperations > 0) ||
    ((selectedMainOperations && selectedMainOperations > 0) as boolean | undefined);
  useEffect(() => {
    if (state && state === 'loading' && isThereSomeSelectedOperations) {
      onOpen();
    }
    if (state && state !== 'loading') {
      onClose();
    }
  }, [state, onOpen, onClose, isThereSomeSelectedOperations]);

  return (
    <>
      <Text type={'h1_banner_light'}>
        <Trans i18nKey={'catalog.maintenance.overview.title'}>Overview</Trans>
      </Text>
      <Box>
        <Text type={'light_14_black_85'}>
          <Trans i18nKey={'catalog.maintenance.overview.description'}>
            Here is an overview of the recommended preventive maintenance procedures, helping you avoid future
            malfunctions and breakdowns.
          </Trans>
        </Text>
      </Box>
      <MarginBox mt={46} />
      <Text type={'h4_paragraph'}>
        <Trans i18nKey={'catalog.maintenance.operations.operations_list'}>Operations list</Trans>
      </Text>
      <MarginBox mt={16} />
      <OverviewTable />
      <MarginBox mt={16} />
      <BottomStickyBox
        text1={t('catalog.maintenance.new_maintenance_plan.validate_maintenance', 'Validate Maintenance')}
        text2={t(
          'catalog.maintenance.new_maintenance_plan.selected_operations_to_estimate',
          'Selected Operations and parts to your estimate.',
        )}
        callback={handleValidate}
        buttonLabel={t('catalog.maintenance.new_maintenance_plan.validate', 'Validate')}
        callback2={handlePrint}
        buttonLabel2={t('catalog.maintenance.new_maintenance_plan.checklist', 'Checklist')}
        buttonLabel2Enabled={isThereSomeSelectedOperations}
      />
      <SpinDialog
        title={t('catalog.maintenance.new_maintenance_plan.rendering_pdf_in_progress', 'Creating PDF')}
        size={'large'}
        disclosure={disclosure}
        handleConfirm={() => {
          onClose();
        }}
      />
    </>
  );
};

export default Overview;
