import { Steps } from 'antd';
import styled from 'styled-components';

export const SSteps = styled(Steps)`
  padding: 0 15px;
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${({ theme }) => theme.color.clear_blue};
    border-color: ${({ theme }) => theme.color.clear_blue};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.clear_blue};
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.color.clear_blue};
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.color.clear_blue};
  }
`;
