import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DMSImportIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { getEstimateById, getEstimateVehicle } from 'domains/estimate/Estimate.store';
import { getSparePartsView } from 'domains/user';
import ClientSection from 'pages/EstimatePage/ClientSection';
import DetailsSection from 'pages/EstimatePage/DetailsSection';
import EstimateModifyTabButtonsSection from 'pages/EstimatePage/EstimateModifyTabButtonsSection';
import { EstimateTableSection, PositionWrapper } from 'pages/EstimatePage/EstimatePage.styled';
import ObservationSection from 'pages/EstimatePage/ObservationSection';
import TotalSection from 'pages/EstimatePage/TotalSection';
import VehicleSection from 'pages/EstimatePage/VehicleSection';
import { theme } from 'styles';
import { CenteredSpin, Flex, Icon, MarginBox, notifyTop, Text } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { hasData, LOADING, useBreakpointSelector } from 'utils';
import DealerSection from './DealerSection';

const EMPTY_ESTIMATE_HEIGHT = 2400;

export interface EstimateModifyTabProps {
  estimateId: string;
  isDMSAvailable: boolean | typeof LOADING;
}

export interface PdfFilterProps {
  hideReferenceNumbers: boolean;
  hideDiscounts: boolean;
}

const EstimateModifyTab = ({ estimateId, isDMSAvailable }: EstimateModifyTabProps) => {
  const { t } = useTranslation();
  const breakpointSelector = useBreakpointSelector();
  const vehicle = useSelector((state: RootState) => getEstimateVehicle(state, estimateId));
  const estimate = useSelector((state: RootState) => getEstimateById(state, estimateId));
  const sparePartsView = useSelector(getSparePartsView);

  if (hasData(estimate) && estimate.isHidden) {
    notifyTop(
      'warning',
      <Trans i18nKey={'estimate.deleted'}>
        This estimate has been deleted by another user. Please select a different estimate.
      </Trans>,
      undefined,
      undefined,
    );
  }

  return (
    <DataContainer
      data={estimate}
      Loading={() => (
        <Flex minHeight={EMPTY_ESTIMATE_HEIGHT}>
          <CenteredSpin />
        </Flex>
      )}
    >
      <PositionWrapper mt={-100}>
        <EstimateModifyTabButtonsSection
          estimateId={estimateId}
          isDMSAvailable={isDMSAvailable}
          renderAddVehicle={true}
        />
      </PositionWrapper>
      {hasData(estimate) && estimate.dmsOrderNumber && (
        <Flex justify={'flex-end'}>
          <MarginBox mt={40} mr={30}>
            <Flex>
              <Icon IconComponent={DMSImportIcon} size={29} mr={10} color={theme.color.blue_dark_2} noPointer />
              <Text type={'light_14_bold_black_85'}>
                {t('estimate.dms.repair_order_number', 'Repair order number:')}
              </Text>
              <MarginBox mr={5} />
              <Text type={'light_14_black_85'}>{estimate.dmsOrderNumber}</Text>
            </Flex>
          </MarginBox>
        </Flex>
      )}
      <MarginBox my={70} />
      <Flex direction={breakpointSelector(['column', 'row'])} justify={'flex-start'}>
        {vehicle && (
          <Flex size={5} direction={'column'}>
            <VehicleSection vehicle={vehicle} />
          </Flex>
        )}
        <MarginBox ml={breakpointSelector([0, 30])} mt={breakpointSelector([30, 0])} />
        <Flex size={3} direction={'column'}>
          <ClientSection estimateId={estimateId} />
        </Flex>
        <MarginBox ml={breakpointSelector([0, 30])} mt={breakpointSelector([30, 0])} />
        <Flex size={3} direction={'column'}>
          <DealerSection />
        </Flex>
      </Flex>
      <MarginBox mt={45} />
      <ViewTabs />
      <Flex direction={'column'}>
        <EstimateTableSection type={'reference'} estimateId={estimateId} sparePartsView={sparePartsView} />
        <EstimateTableSection type={'labor_time'} estimateId={estimateId} />
        <EstimateTableSection type={'free_bundles'} estimateId={estimateId} />
        <EstimateTableSection type={'tire'} estimateId={estimateId} sparePartsView={sparePartsView} />
        <EstimateTableSection type={'other'} estimateId={estimateId} sparePartsView={sparePartsView} />
        <EstimateTableSection type={'waste_recycling'} estimateId={estimateId} />
        <MarginBox mt={80} />
        <Flex direction={breakpointSelector(['column', 'row'])}>
          <Flex size={3} direction={'column'}>
            <ObservationSection key={estimateId} estimateId={estimateId} />
          </Flex>
          <MarginBox ml={breakpointSelector([0, 30])} mt={breakpointSelector([30, 0])} />
          <Flex size={3} direction={'column'}>
            <DetailsSection estimateId={estimateId} sparePartsView={sparePartsView} />
          </Flex>
          <MarginBox ml={breakpointSelector([0, 30])} mt={breakpointSelector([30, 0])} />
          <Flex size={2} direction={'column'}>
            <TotalSection estimateId={estimateId} />
          </Flex>
        </Flex>
        <MarginBox mt={30} />
        <EstimateModifyTabButtonsSection estimateId={estimateId} isDMSAvailable={isDMSAvailable} />
        <MarginBox mt={30} />
      </Flex>
    </DataContainer>
  );
};

export default EstimateModifyTab;
