import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon, FilePdfIcon } from 'assets/icons';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import { theme } from 'styles';
import {
  ArrowLeftButtonRound,
  ArrowRightButtonRound,
  Box,
  CenterFlex,
  Flex,
  Icon,
  ImageWithLoader,
  Link,
  MarginBox,
  Modal,
  Text,
} from 'UI';
import { SBadge } from 'UI/Badge/Badge.styled';
import { DocumentIconWrapper } from './DocumentScrollModal.styled';

const VISIBLE_DOCUMENTS = 6;
const OFFSET = 1;

export type DocumentType = 'IMAGE' | 'PDF' | 'URL';

export interface Document {
  url: string;
  order?: number;
  documentType: DocumentType;
}

const DocumentIcon = ({ document, selected = false }: { document?: Document; selected?: boolean }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    switch (document?.documentType) {
      case 'IMAGE':
        return (
          <ImageWithLoader
            alt={t('common.image.it_should_be_here', 'Image should be here')}
            imageUrl={document.url}
            imageHeight={100}
            imageWidth={100}
          />
        );
      case 'PDF':
        return (
          <CenterFlex>
            <Icon IconComponent={FilePdfIcon} width={45} height={60} color={theme.color.red} />
          </CenterFlex>
        );
      case 'URL':
        return (
          <CenterFlex>
            <Icon IconComponent={ExternalLinkIcon} width={50} height={60} color={theme.color.red} />
          </CenterFlex>
        );
      default:
        return null;
    }
  };

  return (
    <DocumentIconWrapper selected={selected}>
      <Box width={100} height={100}>
        {renderContent()}
      </Box>
    </DocumentIconWrapper>
  );
};

const MainScrollImage = ({ document }: { document?: Document }) => {
  const imageHeight = 385;
  const imageWidth = 700;

  const renderContent = () => {
    switch (document?.documentType) {
      case 'IMAGE':
        return <ImageWithLoader imageHeight={imageHeight} alt={'main-scrollable-image'} imageUrl={document?.url} />;
      case 'PDF':
      case 'URL':
        return (
          <CenterFlex>
            <Box align={'center'}>
              <Link to={document?.url} external newTab>
                <Text type={'text'} displayStyle={'link'} cursor={'pointer'} hoverUnderLine>
                  {document?.url}
                </Text>
              </Link>
            </Box>
          </CenterFlex>
        );
      default:
        return null;
    }
  };
  return (
    <Box height={imageHeight} width={imageWidth}>
      {renderContent()}
    </Box>
  );
};

interface ImageScrollContentProps {
  documents: Document[];
}

function ImageScrollContent({ documents }: Readonly<ImageScrollContentProps>) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectedDocument = documents.at(selectedIndex);
  const [firstIndex, setFirstIndex] = useState<number>(0);
  const showArrows = documents.length > VISIBLE_DOCUMENTS;
  const browse = (offset: number) => {
    setSelectedIndex((index) => index + offset);
  };
  const next = () => {
    browse(OFFSET);
    const moveIconsRightOffset = selectedIndex + OFFSET + 1;
    if (moveIconsRightOffset > VISIBLE_DOCUMENTS) {
      setFirstIndex(moveIconsRightOffset - VISIBLE_DOCUMENTS);
    }
  };
  const prev = () => {
    browse(-OFFSET);
    const moveIconsLeftOffset = selectedIndex - OFFSET;
    if (moveIconsLeftOffset < firstIndex) {
      setFirstIndex((index) => index - OFFSET);
    }
  };
  const hasNext = showArrows && selectedIndex < documents.length - 1;
  const hasPrev = showArrows && selectedIndex > 0;

  const visibleDocuments = documents.slice(firstIndex, firstIndex + VISIBLE_DOCUMENTS);

  return (
    <Flex direction={'column'} align={'center'}>
      <Flex direction={'row'} align={'center'}>
        {showArrows && (
          <ArrowLeftButtonRound color={'brand_black'} onClick={prev} disabled={!hasPrev} disabledColor={'grey65'} />
        )}
        <MainScrollImage document={selectedDocument} />
        {showArrows && (
          <ArrowRightButtonRound color={'brand_black'} onClick={next} disabled={!hasNext} disabledColor={'grey65'} />
        )}
      </Flex>
      <MarginBox mt={30} />
      <Flex direction={'row'} align={'flex-end'} maxHeight={150}>
        {visibleDocuments.map((document, idx) => (
          <div
            key={`scrollable-image-icon-${idx}`}
            onClick={(e: any) => {
              setSelectedIndex(firstIndex + idx);
              e.stopPropagation();
            }}
          >
            <DocumentIcon document={document} selected={idx + firstIndex === selectedIndex} />
          </div>
        ))}
      </Flex>
    </Flex>
  );
}

interface DocumentScrollModalProps {
  documents: Document[];
  visible?: boolean;
  onVisibleChange?: (visible: boolean) => void;
  children?: ReactElement;
}

export function DocumentScrollModal({
  documents,
  visible,
  onVisibleChange,
  children,
}: Readonly<DocumentScrollModalProps>) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    visible && setIsModalVisible(visible);
  }, [visible]);

  useEffect(() => {
    onVisibleChange && onVisibleChange(isModalVisible);
  }, [isModalVisible, onVisibleChange]);

  if (!documents?.length) return <>{children}</>;

  return (
    <StopPropagation>
      <Modal
        title={
          <Flex direction={'row'} align={'center'}>
            <SBadge size={24} color={theme.color.clear_blue} offset={[15, 15]}>
              <Text type={'dark_14_white_bold'}>{documents.length}</Text>
            </SBadge>
            <MarginBox mr={20} />
            <Text type={'text'} displayStyle={'link'} transform={'capitalize'}>
              {`${t('common.elements', 'elements', {
                count: documents.length,
              })}`}
            </Text>
          </Flex>
        }
        open={isModalVisible}
        onCancel={(e: any) => {
          setIsModalVisible(false);
          e.stopPropagation();
        }}
        footer={null}
        width={850}
        closable
      >
        <Box height={550}>
          <ImageScrollContent documents={documents} />
        </Box>
      </Modal>
      <div
        onClick={(e: any) => {
          setIsModalVisible(true);
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </StopPropagation>
  );
}
