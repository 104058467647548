import { ClientLogRequest } from '@1po/1po-bff-fe-spec/generated/client_log/request/ClientLogRequest';
import { AnyAction } from 'redux';
import { putClientLogSaga } from 'domains/clientLog/ClientLog.store';
import getRemoteLogMetaData from 'utils/remoteLogger/remoteLogMetaData';

const metaData = getRemoteLogMetaData();

enum LogLevel {
  debug,
  info,
  warn,
  error,
}

type LogLevelStrings = keyof typeof LogLevel;

const logToConsole = (message: string, level: string) => {
  switch (level) {
    case 'debug': {
      console.debug(message);
      break;
    }
    case 'warn': {
      console.warn(message);
      break;
    }
    case 'error': {
      console.error(message);
      break;
    }
    default: {
      console.info(message);
    }
  }
};
const logger = {
  log: (level: LogLevelStrings, message: string): AnyAction => {
    const clientLogRequest: ClientLogRequest = {
      level,
      message,
      timestamp: new Date(),
      metaData,
    };
    logToConsole(message, level);
    return putClientLogSaga({ clientLogRequest });
  },
  debug: (message: string): AnyAction => {
    return logger.log('debug', message);
  },
  info: (message: string): AnyAction => {
    return logger.log('info', message);
  },
  warn: (message: string): AnyAction => {
    return logger.log('warn', message);
  },
  error: (message: string): AnyAction => {
    return logger.log('error', message);
  },
};

export default logger;
