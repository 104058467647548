/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BannerPromotionPublicationTableData } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/PublicationStep.types';
import { STable } from 'pages/EstimatePage/EstimatePage.styled';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { Flex, Text, WithTooltip } from 'UI';

export const TableBanner = ({ data }: { data: BannerPromotionPublicationTableData }) => {
  const { t } = useTranslation();
  const getDataColumns = () => {
    return [
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.promotion_title', 'Promotion title')),
        dataIndex: 'title',
        key: 'title',
        render: function renderTitle(title: string) {
          return (
            <Flex maxWidth={150} justify={'center'} align={'center'}>
              <WithTooltip title={title}>
                <Text type={'text'} ellipsis>
                  {title}
                </Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.period', 'Period')),
        dataIndex: 'period',
        key: 'period',
        render: function renderPeriod(period: { publicationStart: string; publicationEnd: string }) {
          const periodTooltipText = `${period.publicationStart} - ${period.publicationEnd}`;
          return (
            <Flex minWidth={90} direction={'column'} justify={'center'} align={'center'}>
              <WithTooltip title={periodTooltipText}>
                <Text type={'text'}>{period.publicationStart}</Text>
              </WithTooltip>
              <WithTooltip title={periodTooltipText}>
                <Text type={'text'}>{period.publicationEnd}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.commercial_family', 'Commercial family')),
        dataIndex: 'commercialFamilyCodes',
        key: 'commercialFamilyCodes',
        render: function renderCommercialFamily(commercialFamilyCodes: string) {
          return (
            <Flex maxWidth={150} justify={'center'} align={'center'}>
              <WithTooltip title={commercialFamilyCodes}>
                <Text type={'text'} ellipsis={true}>
                  {commercialFamilyCodes}
                </Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.references', 'References')),
        dataIndex: 'referencesTimeLimited',
        key: 'referencesTimeLimited',
        render: function renderReferences(references: string) {
          return (
            <Flex maxWidth={150} justify={'center'} align={'center'}>
              <WithTooltip title={references}>
                <Text type={'text'}> {references} </Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.target', 'Target')),
        dataIndex: 'targetedAudience',
        key: 'targetedAudience',
        render: function renderTarget(targetedAudience: { dealerTypes: string; fileName: string }) {
          const targetedAudienceToolTipTitle = `${targetedAudience.dealerTypes} ${targetedAudience.fileName}`;
          return (
            <Flex direction={'column'} justify={'center'} align={'center'}>
              <WithTooltip title={targetedAudienceToolTipTitle}>
                <Text type={'text'}>{targetedAudience.dealerTypes}</Text>
              </WithTooltip>
              <WithTooltip title={targetedAudienceToolTipTitle}>
                <Text type={'text'}>{targetedAudience.fileName}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
    ];
  };
  if (!data) {
    return null;
  }
  return (
    <STable
      key={'promotions_flash_time_limited_table'}
      dataSource={[data]}
      columns={getDataColumns()}
      pagination={false}
    />
  );
};
