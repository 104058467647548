import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_HOME } from 'app/AppRouter';
import { PowerOffIcon } from 'assets/icons';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { logoutUser } from 'domains/user';
import { BlackButton, Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';

const LogoutButton = ({
  small,
  iconColor,
  iconHoverFill,
}: {
  small?: boolean;
  iconColor?: string;
  iconHoverFill?: string;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const onLogoutHandler = () => {
    dispatch(logoutUser());
    history.push(ROUTER_HOME);
    scrollToTopSmooth();
  };

  return (
    <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_disconnect`} placement={'bottomLeft'}>
      {small ? (
        <WithTooltip title={t('common.action.log_out', 'Log out')} placement={'bottom'}>
          <Icon
            IconComponent={PowerOffIcon}
            onClick={onLogoutHandler}
            size={32}
            display={'block'}
            color={iconColor}
            hoverFill={iconHoverFill}
          />
        </WithTooltip>
      ) : (
        <BlackButton size={'large'} onClick={onLogoutHandler}>
          <Flex direction={'row'} justify={'center'} align={'center'}>
            <Icon IconComponent={PowerOffIcon} color={iconColor} size={24} />
            <MarginBox ml={15} />
            <Text type={'h4_white'}>{t('common.action.logout', 'Logout')}</Text>
          </Flex>
        </BlackButton>
      )}
    </FirstHelpPopin>
  );
};

export default LogoutButton;
