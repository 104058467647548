import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { PdfShortcutHeader } from 'components/Pdf/PrintPdfShortcut';
import { TechnicalCriteriaContent } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaContent';
import { ItemElement } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenuAndContent';
import { PrintButtonRound } from 'UI';
import { PdfHiddenWrapper, PdfWrapper, usePrintShortcut } from 'utils/hooks/usePrintShortcut';

const TechnicalCriteriaPrintPdf = ({ item }: { item?: ItemElement }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  usePrintShortcut(reactToPrintFn);

  return (
    <>
      <PrintButtonRound onClick={reactToPrintFn} />
      <PdfHiddenWrapper>
        <PdfWrapper direction={'column'} ref={contentRef} gap={15}>
          <PdfShortcutHeader title={t('catalog.details.vehicle_technical_details', 'Vehicle technical details')} />
          {item && <TechnicalCriteriaContent item={item} />}
        </PdfWrapper>
      </PdfHiddenWrapper>
    </>
  );
};

export default TechnicalCriteriaPrintPdf;
