import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthConfigRequestSaga, getCredentialsSearchState } from 'domains/auth/Auth.store';
import { ERROR } from 'utils';

export const useFetchAuthCredentials = () => {
  const searchStatus = useSelector(getCredentialsSearchState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!searchStatus) {
      dispatch(getAuthConfigRequestSaga());
    }
    if (searchStatus === ERROR) {
      setTimeout(() => {
        dispatch(getAuthConfigRequestSaga());
      }, 5000);
    }
  }, [dispatch, searchStatus]);
};
