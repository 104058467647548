import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectedIndexType } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/SparePartsSection';
import { Flex, MarginBox, Text } from 'UI';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import {
  BannerBackgroundContainer,
  BannerText,
  BannerWrapper,
  ShowMoreButtonContainer,
} from './MarketingProductsBanner.styled';

export const marketingProductsIndex = 'marketing-products';

const MarketingProductsBanner = ({
  setSelectedIndex,
}: {
  setSelectedIndex: (value: ((prevState: SelectedIndexType) => SelectedIndexType) | SelectedIndexType) => void;
}) => {
  const { t } = useTranslation();
  const offsetTop = useOffsetTop('RENAULT_CATALOG');

  const focusOnMarketingProducts = () => {
    const marketingProductsHeaderElement = document.getElementById('marketing-reference-index-header');
    if (marketingProductsHeaderElement) {
      setSelectedIndex(marketingProductsIndex);
      scrollToTopSmooth(marketingProductsHeaderElement.offsetTop - offsetTop);
    }
  };

  return (
    <BannerWrapper id={`marketing-reference-banner-wrapper`}>
      <BannerBackgroundContainer>
        <Flex align={'center'}>
          <BannerText>
            <MarginBox my={8} mx={12}>
              <Text type={'advertisement_text'} transform={'sentence-case'}>
                {t('catalog.plate.associated_products', 'Associated products')}
              </Text>
            </MarginBox>
          </BannerText>
          <Flex />
        </Flex>
      </BannerBackgroundContainer>
      <ShowMoreButtonContainer>
        <Flex direction={'row-reverse'} align={'center'}>
          <MarginBox mr={65}>
            <Text type={'text'} displayStyle={'link'} cursor={'pointer'} onClick={focusOnMarketingProducts}>
              {t('common.action.show_more', 'Show more')}
            </Text>
          </MarginBox>
        </Flex>
      </ShowMoreButtonContainer>
    </BannerWrapper>
  );
};

export default MarketingProductsBanner;
