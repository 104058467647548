import React from 'react';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataIT = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>RPARTSTORE</Text>
          <Text type={'h5_bold'}>INFORMATIVA SULLA</Text>
          <Text type={'h5_bold'}>PROTEZIONE DEI DATI PERSONALI</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Il sito web RPARTSTORE, accessibile all’indirizzo{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              {'https://rpartstore.renault.com'}
            </Link>{' '}
            (di seguito la “Piattaforma”), è stato creato per facilitare la consultazione della documentazione tecnica
            post-vendita dei ricambi originali e after-market Renault (di seguito i “Prodotti”) e per consentire
            l’incontro tra i venditori (di seguito i “Venditori”) che desiderano mettere in vendita i Prodotti
            attraverso un sito web e gli acquirenti (di seguito gli “Acquirenti”), conformemente alle Condizioni
            Generali di Utilizzo della Piattaforma. Venditori e Acquirenti sono insieme denominati “Utenti” e
            singolarmente “Utente”.
          </Paragraph>
          <Paragraph>
            Lo scopo della presente informativa sulla protezione dei dati personali è quello di garantire la trasparenza
            del trattamento dei dati personali effettuato nell’ambito dell’utilizzo della Piattaforma da parte degli
            Utenti.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            title={'PERCHÉ VENGONO TRATTATI I VOSTRI DATI PERSONALI E PER QUANTO TEMPO VENGONO CONSERVATI?'}
          />
          <Paragraph>
            Quando utilizzate la Piattaforma, i vostri dati personali vengono trattati per le seguenti finalità:
          </Paragraph>
          <Ul>
            <Li>
              <strong>per gestire i vostri diritti di accesso e autorizzazione all’accesso alla Piattaforma</strong>{' '}
              sulla base del nostro legittimo interesse a gestire la riservatezza e la sicurezza dei vostri dati
              personali. Per tale finalità, trattiamo e conserviamo i vostri dati per tutto il tempo in cui il vostro
              account Utente è attivo;
            </Li>
            <Li>
              <strong>per gestire la sicurezza e il corretto funzionamento delle applicazioni e delle reti</strong>{' '}
              sulla base del nostro legittimo interesse a garantire la sicurezza informatica della Piattaforma. Per tale
              finalità, conserviamo i vostri dati nei nostri database per 30 giorni dall’accesso dell’Utente alla
              Piattaforma;
            </Li>
            <Li>
              <strong>
                per rispondere, ove applicabile, alle vostre richieste di esercitare i vostri diritti in relazione ai
                vostri dati personali
              </strong>
              {', '}in conformità con i nostri obblighi legali e/o regolamentari. Per tale finalità i vostri dati
              personali vengono conservati per il tempo necessario a rispondere alla vostra richiesta.
            </Li>
          </Ul>
          <Paragraph>
            Inoltre, la Piattaforma utilizza cookie e altre tecnologie di tracciamento per gestire il suo funzionamento
            e la sua sicurezza, nonché per misurare l’audience e le prestazioni della Piattaforma alle condizioni
            stabilite Cookie Policy relativa alla Piattaforma,{' '}
            <Link to={ROUTER_COOKIES(Country.IT.key)} external newTab>
              accessibile qui
            </Link>
            .
          </Paragraph>
          <Paragraph>
            In ogni caso, i vostri dati personali verranno conservati per un periodo non superiore al conseguimento
            delle finalità per le quali sono trattati, nel rispetto del principio di cui all’art. 5 par. 1, lettera e)
            del Regolamento (UE) 2016/679, noto come Regolamento generale sulla protezione dei dati o “GDPR”, adottato
            il 27 aprile 2016;
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'QUALI DATI PERSONALI VENGONO ELABORATI?'} />
          <Paragraph>
            I dati personali che raccogliamo dipendono dalla nostra interazione con voi e comprendono dati relativi a:
          </Paragraph>
          <Ul>
            <Li>
              la vostra identità e i vostri dati di contatto (cognome, nome, indirizzo postale ed e-mail professionale);
            </Li>
            <Li>identificatori tecnici (numero IPN);</Li>
            <Li>
              <Text type={'text'}>
                dati di connessione (registri di attività, cookie) in conformità con la{' '}
                <Link to={ROUTER_COOKIES(Country.AT.key)} external newTab>
                  Cookie Policy
                </Link>{' '}
                della Piattaforma.
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
        </Section>
        <Section>
          <SectionHeader title={'CHI TRATTA I SUOI DATI PERSONALI?'} />
          <Paragraph>
            I dati personali degli Utenti sono trattati dalla casa madre Renault SAS, con sede legale in Francia,
            122-122 bis avenue du Général Leclerc – 92 100 Boulogne-Billancourt, la quale agisce in qualità di titolare
            autonomo del trattamento.
            <br />
            Nella gestione degli accessi alla Piattaforma da parte degli Utenti, Renault SAS tratta i vostri dati
            personali insieme Renault Italia S.p.A. con sede legale in Via Tiburtina n. 1159 – 00156 Roma, agendo quali
            contitolari del trattamento (*).
            <br />
            Inoltre, i Venditori, membri della rete primaria di Renault Italia S.p.A., incaricati delle attività di
            distribuzione, partecipano alla gestione dei diritti di accesso e di autorizzazione dell’utente sulla
            Piattaforma creando profili Utente negli elenchi interni del Gruppo Renault e negli strumenti di
            autenticazione.
          </Paragraph>
          <Paragraph>
            (*) Per conoscere l’identità giuridica dell’affiliato Renault operante nel proprio paese, si prega di
            visitare la nostra directory accessibile al seguente sito{' '}
            <Link to={'https://www.renaultgroup.com/en/directory/'} external newTab>
              https://www.renaultgroup.com/en/directory/
            </Link>
            , selezionare il proprio paese dall’elenco e consultare le informazioni legali.
          </Paragraph>
          <Paragraph>
            Renault SAS ha nominato il proprio Responsabile per la protezione dei dati personali (Data Protection
            Officer – DPO), al quale gli Utenti potranno rivolgersi per esercitare i propri diritti o per avere
            informazioni relative agli stessi e/o alla presente Informativa, mediante invio di una comunicazione ai
            seguenti indirizzi: Renault SAS, Direction juridique - Délégué à la protection des données, 122-122 bis
            avenue du Général Leclerc - 92 100 Boulogne-Billancourt e{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'CHI HA ACCESSO AI VOSTRI DATI PERSONALI?'} />
          <Paragraph>
            Nell’ambito delle operazioni di trattamento dei dati personali da parte di Renault SAS e Renault Italia
            S.p.A., i vostri dati personali sono accessibili solo al personale interno debitamente autorizzato e ai
            fornitori di servizi terzi di fiducia di queste ultime che agiscono in qualità di subappaltatori
            esclusivamente su istruzione e per conto delle stesse.
          </Paragraph>
          <Paragraph>
            Ove strettamente necessario, Renault SAS e/o Renault Italia S.p.A. può/possono comunicare i vostri dati
            personali a terzi per adempiere a qualsiasi obbligo legale o ad una decisione amministrativa o giudiziaria.
          </Paragraph>
          <Paragraph>
            Poiché alcuni di questi fornitori di servizi o dei loro subappaltatori sono situati in paesi al di fuori
            dell’UE/SEE i quali potrebbero avere normative sui dati personali diverse da quelle dell’Unione Europea,
            abbiamo accettato di implementare garanzie adeguate sottoscrivendo clausole contrattuali standard (SCC),
            così da garantire che tale trasferimento avvenga in conformità con la normativa applicabile in materia dei
            dati personali e mettendo in atto garanzie che assicurino un livello di protezione della vostra privacy e
            dei vostri diritti fondamenti equivalente a quello offerto dall’Unione Europea.
          </Paragraph>
          <Paragraph>
            Gli Utenti potranno ottenere ulteriori dettagli su tali trasferimenti (in particolare, la lista dei paesi e
            le già menzionate Clausole contrattuali standard della Commissione Europea) inviando una richiesta scritta
            all’indirizzo e-mail{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'QUALI SONO I VOSTRI DIRITTI?'} />
          <Paragraph>
            In conformità alla normativa applicabile in materia di protezione dei dati personali, in merito alle
            attività di trattamento descritte nella presente informativa, avete:
          </Paragraph>
          <Ul>
            <Li>
              <strong> Diritto di accesso</strong>: per ottenere informazioni sul trattamento dei vostri dati personali
              (in particolare, quali dati vengono utilizzati, per quale scopo, ecc.);
            </Li>
            <Li>
              <strong>Diritto alla cancellazione</strong>: per ottenere la cancellazione dei vostri dati personali, con
              la precisazione che questo diritto può essere limitato in considerazione degli obblighi contrattuali o
              legali di Renault SAS;
            </Li>
            <Li>
              <strong>Diritto di opposizione</strong>: per opporsi all’utilizzo di alcuni dei vostri dati personali. A
              tal fine, occorre riferire i motivi alla base dell’opposizione;
            </Li>
            <Li>
              <strong>Diritto alla limitazione del trattamento</strong>: per congelare l’uso dei vostri dati, in
              particolare quando:
              <Ul type={'circle'}>
                <Li>
                  contestate l’esattezza dei dati personali e volete limitarne l’uso per il periodo necessario al
                  titolare del trattamento a verificare l’esattezza di tali dati personali;
                </Li>
                <Li>
                  il trattamento è illecito e vi opponente alla cancellazione dei vostri dati personali chiedendo,
                  invece, che ne sia limitato l’utilizzo;
                </Li>
                <Li>
                  avete esercitato il diritto di opposizione di cui sopra e i vostri dati personali sono necessari per
                  l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria ovvero per l’accertamento
                  della legittimità del trattamento da parte del titolare.
                </Li>
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            Potete esercitare tutti i vostri diritti inviando la vostra richiesta a:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            . In assenza di una risposta o di un riscontro soddisfacente da parte di Renault SAS, è possibile presentare
            un reclamo presso l’autorità locale preposta alla protezione dei dati.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Versione aggiornata: gennaio 2024</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
