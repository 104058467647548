import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DHReferenceLocal, getSelectedTab, LocalTabType, setSelectedTab } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import { BorderedBoxMenu, STabs } from 'pages/CatalogPage/DH/Product/Product.styled';
import KitReferenceItem from 'pages/CatalogPage/DH/Product/ProductMenu/KitReferenceItem';
import LinkedProductItem from 'pages/CatalogPage/DH/Product/ProductMenu/LinkedProductItem';
import { ProductMenuItemProps, ProductMenuList } from 'pages/CatalogPage/DH/Product/ProductMenu/ProductMenuList';
import ReferenceCard from 'pages/CatalogPage/DH/Product/ProductMenu/ReferenceCard';
import ReplacingReferenceItem from 'pages/CatalogPage/DH/Product/ProductMenu/ReplacingReferenceItem';
import SubstituteReferenceItem from 'pages/CatalogPage/DH/Product/ProductMenu/SubstituteReferenceItem';
import { Divider, Flex, LinkRoundButton, MarginBox, Tab, Text } from 'UI';
import { getData } from 'utils';

interface TabElements {
  tabName: string;
  itemList: {
    listHeader: React.ReactNode;
    referenceNumbers: string[];
    menuItem: FunctionComponent<ProductMenuItemProps>;
  }[];
}

function useTypeSwitch(type: LocalTabType, refLocal: DHReferenceLocal): TabElements {
  const { t } = useTranslation();
  const reference = getData(refLocal);

  switch (type) {
    case 'equivalent_references':
      return {
        tabName: t('catalog.product.menu.equivalent_references', 'Equivalent references'),
        itemList: [
          {
            listHeader: (
              <Text type={'h5_bold'}>{t('catalog.product.menu.equivalent_references', 'Equivalent references')}</Text>
            ),
            referenceNumbers: reference?.alternativeReferences ?? [],
            menuItem: ReferenceCard,
          },
        ],
      };
    case 'substitute_references':
      return {
        tabName: t('catalog.product.menu.substitutions', 'Substitutions'),
        itemList: [
          {
            listHeader: (
              <Flex>
                <Text type={'h5_bold'}>
                  {t('catalog.product.menu.list.substituted_reference', 'Substituted Reference')}
                </Text>
                <MarginBox ml={15} />
                <Flex align={'center'}>
                  <LinkRoundButton noPointer />
                </Flex>
              </Flex>
            ),
            referenceNumbers: reference?.initialReferenceNumber
              ? [reference.initialReferenceNumber]
              : reference?.substitutedReference
              ? [reference?.referenceNumber]
              : [],
            menuItem: SubstituteReferenceItem,
          },
          {
            listHeader: t('catalog.product.menu.list.replacing_references', 'Replacing references'),
            referenceNumbers: reference?.initialReferenceNumber
              ? [reference.initialReferenceNumber]
              : reference?.replacingReferences && reference.replacingReferences.length > 0
              ? [reference?.referenceNumber]
              : [],
            menuItem: ReplacingReferenceItem,
          },
        ],
      };
    case 'linked_references':
      return {
        tabName: t('catalog.product.menu.linked_products', 'Linked products'),
        itemList: [
          {
            listHeader: <Text type={'h5_bold'}>{t('catalog.product.menu.linked_products', 'Linked products')}</Text>,
            referenceNumbers: [...(reference?.linkedReferences?.map((r) => r.referenceNumber) ?? [])]?.sort((a, b) =>
              a > b ? 1 : -1,
            ),
            menuItem: LinkedProductItem,
          },
        ],
      };
    case 'kit':
      return {
        tabName: t('catalog.product.menu.kit', 'Kit'),
        itemList: [
          {
            listHeader: <Text type={'h5_bold'}>{t('catalog.product.menu.kit', 'Kit')}</Text>,
            referenceNumbers: reference?.kitReferences?.map((r) => r.referenceNumber) ?? [],
            menuItem: KitReferenceItem,
          },
        ],
      };
    default:
      return {
        tabName: '',
        itemList: [],
      };
  }
}

function LocalTab(refLocal: DHReferenceLocal, sparePartsView: SparePartsViewType, type: LocalTabType) {
  const tabElements = useTypeSwitch(type, refLocal);
  const items = tabElements.itemList.filter((item) => item.referenceNumbers.length > 0);

  if (items.length === 0) return null;

  return (
    <Tab key={type} tab={tabElements.tabName}>
      <BorderedBoxMenu>
        {items.map((item, index) => (
          <React.Fragment key={`productMenuTab_${tabElements.tabName}_${index}`}>
            <Text type={'h5_bold'}>{item.listHeader}</Text>
            <MarginBox mb={15} />
            <ProductMenuList
              parentRef={refLocal}
              referenceNumbers={item.referenceNumbers}
              sparePartsView={sparePartsView}
              ProductMenuItem={item.menuItem}
            />
            {index + 1 < items.length && (
              <MarginBox mt={15}>
                <Divider />
              </MarginBox>
            )}
          </React.Fragment>
        ))}
      </BorderedBoxMenu>
    </Tab>
  );
}

export function ProductMenu({
  refLocal,
  sparePartsView,
}: {
  refLocal: DHReferenceLocal;
  sparePartsView: SparePartsViewType;
}) {
  const dispatch = useDispatch();
  const selectedTab = useSelector(getSelectedTab);

  return (
    <STabs
      type="card"
      size={'large'}
      activeKey={selectedTab}
      onChange={(value: LocalTabType) => {
        dispatch(setSelectedTab(value));
      }}
    >
      {LocalTab(refLocal, sparePartsView, 'equivalent_references')}
      {LocalTab(refLocal, sparePartsView, 'substitute_references')}
      {LocalTab(refLocal, sparePartsView, 'linked_references')}
      {LocalTab(refLocal, sparePartsView, 'kit')}
    </STabs>
  );
}
