import styled from 'styled-components';
import { theme } from 'styles';
import { Flex, Tabs } from 'UI';

export const borderSetting = `solid 1px ${theme.color.grey85}`;

export const BorderedBox = styled.div`
  border: ${borderSetting};
  border-radius: 2px;
  flex: 1;
`;

export const SListItem = styled.div`
  margin-bottom: 15px;
  box-shadow: 0 5px 12px 4px ${({ theme }) => theme.color.shadow_6}, 0 3px 6px 0 ${({ theme }) => theme.color.shadow_5},
    0 1px 2px -2px ${({ theme }) => theme.color.shadow_4};
  white-space: nowrap;
`;

export const SImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductImageBackground = styled.div<{ imageBase64?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, imageBase64 }) => (imageBase64 ? 'inherit' : theme.color.shadow_7)};
`;

export const SInfoBox = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.color.clear_blue};
  padding: 0 10px;
`;

export const BorderedBoxMenu = styled.div`
  border-bottom: ${borderSetting};
  border-left: ${borderSetting};
  border-right: ${borderSetting};
  flex: 1;
  padding: 30px;
`;

export const STabs = styled(Tabs)`
  flex: 1;

  .ant-tabs-nav {
    margin: 0;

    .ant-tabs-tab {
      border-radius: 2px 2px 0 0 !important;
      border: ${borderSetting} !important;
      flex: 1;
      justify-content: center;
    }
  }

  .ant-tabs-nav-wrap .ant-tabs-tab-active {
    background: white;

    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.color.clear_blue};
    }
  }

  .ant-tabs-nav-list {
    flex: 1;
  }

  .ant-tabs-nav-more {
    display: none;
  }
`;

export const ProductImageUnavailable = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.shadow_7};
`;
