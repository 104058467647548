import {
  ADD_EXTERNAL_BASKET_TO_USER_BASKET_RESPONSE,
  GET_EXTERNAL_BASKET_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { GetExternalBasketResponse } from '@1po/1po-bff-fe-spec/generated/external_basket/response/GetExternalBasketResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  ATTACH_EXTERNAL_BASKET,
  EXTERNAL_BASKET_CONTEXT_NAMESPACE,
  ExternalBasketState,
} from 'domains/externalBasket/ExternalBasket.types';

// Init state
const initialState: ExternalBasketState = {
  externalBaskets: [],
};

// Saga actions
export const fetchExternalBasketResponse = createAction(GET_EXTERNAL_BASKET_RESPONSE);
export const attachExternalBasketRequest = createAction<{ externalBasketId: string }>(ATTACH_EXTERNAL_BASKET);
export const attachExternalBasketResponse = createAction(ADD_EXTERNAL_BASKET_TO_USER_BASKET_RESPONSE);

// Slice
const slice = createSlice({
  name: EXTERNAL_BASKET_CONTEXT_NAMESPACE,
  initialState,
  reducers: {
    addExternalBasket: (state, { payload }: PayloadAction<GetExternalBasketResponse>) => {
      state.externalBaskets.push(payload);
    },
    removeExternalBasket: (state, { payload }: PayloadAction<{ externalBasketId: string }>) => {
      state.externalBaskets = state.externalBaskets.filter((b) => b.externalBasketId !== payload.externalBasketId);
    },
  },
});

// Actions
export const { addExternalBasket, removeExternalBasket } = slice.actions;

// Getters/Selectors
export const getExternalBaskets = createSelector(
  (state: RootState) => state,
  (buildVersions) => buildVersions,
);

export const getFirstExternalBasket = createSelector(
  (state: RootState) => state.externalBasket,
  (externalBasket) => {
    if (externalBasket.externalBaskets.length === 0) {
      return undefined;
    }
    return externalBasket.externalBaskets[0];
  },
);

// Export reducer
export default slice.reducer;
