import { useEffect, useRef } from 'react';

/**
 * @param onInactivity - function to call if no activity until timeout
 * @param timeoutMs - timeout in milliseconds
 */
export function useUserInactivity(onInactivity: () => void, timeoutMs: number) {
  const timerRef = useRef<number | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(onInactivity, timeoutMs);
  };

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    resetTimer();

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInactivity, timeoutMs]);
}
