import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import AppRouter from 'app/AppRouter';
import Loader from 'components/Loader';
import TranslationKeyFeature from 'composers/Features/TranslationKeyFeature';
import { getWebLanguage } from 'domains/user';
import { useFetchLocalUserToken } from 'domains/user/User.requests';
import { env } from 'env';
import { notifyTop } from 'UI/Notification/notification';
import { textFormatter } from 'utils/format';
import { AppTranslation, getFirstFallbackLanguage, hasTranslations, initializeTranslationService } from 'utils/i18n';
import { Language } from 'utils/i18n/Language';
import { logAndSetGTMLanguage } from 'utils/remoteLogger/remoteLogMetaData';

const AppRouterContainer = () => {
  const [initialized, setInitialized] = useState(false);
  const webLanguage = useSelector(getWebLanguage);

  useEffect(() => {
    textFormatter.setLocale(webLanguage.locale);
    AppTranslation.on('initialized', () => {
      logAndSetGTMLanguage(webLanguage);
    });
  }, [webLanguage]);

  useEffect(() => {
    initializeTranslationService(webLanguage.key);
    textFormatter.setLocale(webLanguage.locale);
    // Check if i18next instance is initialized manually (suspense doesn't work correctly)
    AppTranslation.on('initialized', () => {
      setInitialized(true);
      if (!hasTranslations(webLanguage.key)) {
        const fallbackLanguageName = Language.findInWebLang(getFirstFallbackLanguage())?.name;
        if (fallbackLanguageName) {
          notifyTop('warning', 'Translations missing', `Using ${fallbackLanguageName} as fallback language`);
        }
      }
      logAndSetGTMLanguage(webLanguage);
    });
  }, []); // eslint-disable-line

  useFetchLocalUserToken();

  return !initialized ? (
    <Loader />
  ) : (
    <ConfigProvider locale={webLanguage.antdLocale}>
      {env.REACT_APP_ENABLE_DISPLAY_TRANSLATION_KEYS === 'true' && <TranslationKeyFeature />}
      <AppRouter />
    </ConfigProvider>
  );
};

export default AppRouterContainer;
