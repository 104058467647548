import styled from 'styled-components';

export const SCriteriaItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 60px;
  ${({ theme, isActive }) => isActive && { backgroundColor: theme.color.grey95 }};
`;

export const SCriteriaItemArrow = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  transform: ${({ isActive }) => (isActive ? 'rotate(90deg)' : '')};
  transition: transform 500ms ease;
`;

export const SCriteriaItemLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-bottom: ${({ theme }) => `${theme.color.grey85} 1px solid`};
`;

export const SCriteriaRowBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  height: 100%;
  padding: 10px 0 10px 15px;
  border-bottom: ${({ theme }) => `${theme.color.grey95} 1px solid`};
  white-space: pre;
`;

export const SHeaderApplicability = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  white-space: nowrap;
  padding: 0 15px 0 15px;
  border-bottom: ${({ theme }) => `${theme.color.grey95} 1px solid`};
`;

export const STechnicalCriteriaSearch = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  background: ${({ theme }) => theme.color.grey95};
  border-radius: 20px;
  padding: 0 15px 0 20px;
  margin-right: 45px;
`;

export const SResetButton = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 45px;
`;
