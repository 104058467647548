import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import {
  fetchDHLaborTimesRequestSaga,
  fetchLaborTimeIdsByGenericPartsRequestSaga,
  getLaborTimeIdsForGenericPartSearchStatus,
  getLaborTimeSearchStatusesMapByIds,
} from 'domains/laborTime/LaborTime.store';
import { getDHReference } from 'domains/references';
import { getData } from 'utils';

export const useFetchLaborTimes = (vehicleKey: string | undefined, laborTimeIds: string[]) => {
  const dispatch = useDispatch();
  const statusMap = getData(
    useSelector((state: RootState) => getLaborTimeSearchStatusesMapByIds(state, { vehicleKey, laborTimeIds })),
  );
  const missingLaborTimeIds = Array.from(statusMap?.entries() ?? [])
    .filter(([_key, value]) => value === undefined)
    .map(([key, _value]) => key);

  useEffect(() => {
    if (vehicleKey && missingLaborTimeIds.length > 0) {
      dispatch(
        fetchDHLaborTimesRequestSaga({
          vehicleKey,
          laborTimeIds: missingLaborTimeIds,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vehicleKey, laborTimeIds]);
};

export const useFetchLaborTimeIdsByReferenceGenericPart = (referenceNumber: string, vehicleKey?: string): void => {
  const dispatch = useDispatch();
  const reference = getData(useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey })));
  const genericPart = reference?.genericPart;
  const laborTimeIdsSearchStatus = useSelector((state: RootState) =>
    getLaborTimeIdsForGenericPartSearchStatus(state, { vehicleKey, genericPart }),
  );

  useEffect(() => {
    if (vehicleKey && genericPart && !laborTimeIdsSearchStatus) {
      dispatch(
        fetchLaborTimeIdsByGenericPartsRequestSaga({
          genericPart,
          vehicleKey,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, genericPart]);
};
