import { R1_NETWORK_GET_URL, R1_NETWORK_UPDATE_URL } from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
// eslint-disable-next-line max-len
import { SaveReturnsFeatureSettingsRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/r1_network/request/SaveReturnsFeatureSettingsRequest';

import { BACKOFFICE_SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { RequestResponseMappingManager } from 'domains/webSockets/WebSocket.requestsResponseMapping';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

RequestResponseMappingManager.addMapping(R1_NETWORK_UPDATE_URL, BACKOFFICE_SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE);

export function sendSaveReturnsFeatureSettingsRequest(request: SaveReturnsFeatureSettingsRequest): WebSocketAction {
  return wsSendAction(R1_NETWORK_UPDATE_URL, request);
}

export function sendGetReturnsFeatureSettingsRequest(): WebSocketAction {
  return wsSendAction(R1_NETWORK_GET_URL, null);
}
