import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Section';
import { getSectionHeader } from 'pages/PromotionPage/PromotionPageView';
import { Box, CenterFlex, Grid, MarginBox, Panel, Pipeline, SectionCollapse, Text } from 'UI';

const FamiliesSection = ({ commercialFamilyCodes }: { commercialFamilyCodes: Section[] | undefined }) => {
  const { t } = useTranslation();
  if (!commercialFamilyCodes) return null;

  const defaultPanelsFamilies =
    commercialFamilyCodes.map((_familyCodes, index) => `commercialFamilyCodes-${index}`) ?? [];
  const renderFamiliesSection = (codes: string[]) => {
    return (
      <MarginBox mt={45}>
        <Grid columns={[4, 6]} gutter={2}>
          {codes.map((code: string, index) => (
            <CenterFlex key={`${code}${index}`}>
              <Box>
                <Box height={54} width={100}>
                  <CenterFlex>
                    <Text type={'light_14_black_65'}>{code}</Text>
                  </CenterFlex>
                </Box>
                <Pipeline size={100} horizontal />
              </Box>
            </CenterFlex>
          ))}
        </Grid>
      </MarginBox>
    );
  };

  return (
    <SectionCollapse position={'end'} defaultActiveKey={defaultPanelsFamilies}>
      {commercialFamilyCodes.map((familyCodes, index) => {
        return (
          <React.Fragment key={index}>
            <Panel
              key={`commercialFamilyCodes-${index}`}
              header={getSectionHeader(
                t('backoffice.promotion.commercial_family_header', 'Commercial Families in promotion'),
                familyCodes.discount,
              )}
            >
              {renderFamiliesSection(familyCodes.items)}
            </Panel>
            <Box height={45} />
          </React.Fragment>
        );
      })}
    </SectionCollapse>
  );
};

export default FamiliesSection;
