import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { ROUTER_BACKOFFICE, ROUTER_PROMOTION } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { TimesCircleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import {
  fetchDashboardPromotionsRequestSaga,
  getLoadedPromotions,
  resetLoadedPromotions,
  setLoadedPromotionsSearchStatus,
} from 'domains/promotion/Promotion.store';
import { PromotionFilterTypeOption, PromotionTabFilterParam } from 'domains/promotion/Promotion.types';
import { getUserRights, UserRole } from 'domains/user';
import { promotionFiltersData } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/Promotion/PromotionFilterDropdown';
import { PromotionCard } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionCard';
import { SFilterItem } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/PromotionTab/PromotionTab.styled';
import { theme } from 'styles';
import { BlackButton, Flex, Icon, InfiniteScroll, LightGreyButton, MarginBox, Text } from 'UI';
import { SELECT_ALL } from 'UI/DropdownMenu';
import { FOUND, getCondArrayItem, LOADING } from 'utils';

export interface PromotionTabProps {
  statusType: StatusType;
  promotionTypeFilterOptions: PromotionFilterTypeOption[];
  setPromotionTypeFilterOptions: (items: PromotionFilterTypeOption[]) => void;
}

const FilterTab = ({ label, active, onChange }: { label: string; active: boolean; onChange: () => void }) => {
  return (
    <>
      {active ? (
        <>
          <BlackButton shape={'round'} onClick={onChange} size={'large'} stretch={false}>
            {label}
          </BlackButton>
          <MarginBox mr={15} />
        </>
      ) : (
        <>
          <LightGreyButton shape={'round'} onClick={onChange} size={'large'} stretch={false}>
            {label}
          </LightGreyButton>
          <MarginBox mr={15} />
        </>
      )}
    </>
  );
};

export const PromotionTab = ({
  statusType,
  promotionTypeFilterOptions,
  setPromotionTypeFilterOptions,
}: PromotionTabProps) => {
  const params = new URLSearchParams(location.search);
  const previewParam = params.get(PromotionTabFilterParam);
  const history = useHistory();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loadedPromotions = useSelector((state: RootState) => getLoadedPromotions(state, statusType));
  const userRights = useSelector(getUserRights);
  const [activeFilters, setActiveFilters] = useState<string[]>(['myPromotions']);
  const isR1User = userRights.includes(UserRole.R1) && !userRights.includes(UserRole.COUNTRY_ADMIN);
  const isCountryAdmin = userRights.includes(UserRole.COUNTRY_ADMIN);

  useEffect(() => {
    if (statusType === 'PLANNED' && previewParam) {
      setActiveFilters((currentActiveFilters) => [...currentActiveFilters, previewParam]);
    }
    params.delete(PromotionTabFilterParam);
    history.push(`${ROUTER_BACKOFFICE}${ROUTER_PROMOTION}?${params}`);
    // eslint-disable-next-line
  }, [previewParam]);

  const r1filterData = [
    {
      id: 'myPromotions',
      label: t('promotion.my_promotions', 'My Promotions'),
    },
    ...getCondArrayItem(
      statusType !== 'SAVED' && {
        id: 'country',
        label: t('my_orders.details.country', 'Country'),
      },
    ),
  ];

  const countryAdminFilterData = [
    {
      id: 'myPromotions',
      label: t('promotion.my_promotions', 'My Promotions'),
    },
    {
      id: 'myDealers',
      label: t('promotion.my_dealers', 'My Dealers'),
    },
  ];

  const renderFilterElements = (
    filterData: any[],
    activeFilters: string[],
    handleFilterChange: (id: string) => void,
  ) => {
    return filterData.map((filter) => (
      <FilterTab
        key={filter.id}
        label={filter.label}
        active={activeFilters.includes(filter.id)}
        onChange={() => handleFilterChange(filter.id)}
      />
    ));
  };

  const renderSelectedFilters = (
    promotionFilters: PromotionFilterTypeOption[],
    onClickHandler: (id: string) => void,
  ) => {
    if (promotionFilters.length === 1 && promotionFilters[0] === SELECT_ALL) {
      return null;
    }
    const promotionFilterItems = promotionFiltersData(t).filter((item) => promotionFilters.includes(item.id));
    return promotionFilterItems.map((item) => (
      <SFilterItem key={item.id}>
        <Flex justify={'center'}>
          <Text type={'light_12'}>{item.label}</Text>
          <Icon
            IconComponent={TimesCircleIcon}
            size={12}
            ml={10}
            onClick={() => onClickHandler(item.id)}
            color={theme.color.grey_very_light_2}
            background={theme.color.grey65}
            round={true}
          />
        </Flex>
      </SFilterItem>
    ));
  };

  const handleFilterChange = (id: string) => {
    const newFilters = activeFilters.includes(id)
      ? activeFilters.filter((filter) => filter !== id)
      : [...activeFilters, id];

    setActiveFilters(newFilters.length === 0 ? activeFilters : newFilters);
  };
  const handleTypeFilterRemoval = (id: string) => {
    const updatedFilters = promotionTypeFilterOptions
      .filter((filter) => filter !== id)
      .filter((item) => item !== SELECT_ALL);
    setPromotionTypeFilterOptions(updatedFilters);
  };

  const requestPromotions = (shouldLoadMore?: boolean) => {
    dispatch(
      fetchDashboardPromotionsRequestSaga({
        getPromotions: {
          status: statusType,
          cursor: shouldLoadMore ? loadedPromotions.cursor : undefined,
        },
        activeFilters,
        promotionFilterTypes: promotionTypeFilterOptions,
      }),
    );
  };

  function loadMore() {
    if (loadedPromotions.status === LOADING) {
      return;
    }
    requestPromotions(true);
  }

  useEffect(() => {
    dispatch(resetLoadedPromotions(statusType));
    dispatch(setLoadedPromotionsSearchStatus({ statusType, searchStatus: LOADING }));
    requestPromotions();
    // eslint-disable-next-line
  }, [dispatch, activeFilters, promotionTypeFilterOptions]);

  const status = (loadedPromotions.data?.length ?? 0) === 0 ? loadedPromotions.status : FOUND;

  return (
    <>
      {isR1User && (
        <>
          <MarginBox mt={15} />
          <Flex direction={'row'}>{renderFilterElements(r1filterData, activeFilters, handleFilterChange)}</Flex>
          <MarginBox mt={15} />
          <Flex direction={'row'}>{renderSelectedFilters(promotionTypeFilterOptions, handleTypeFilterRemoval)}</Flex>
        </>
      )}
      {isCountryAdmin && (
        <>
          <MarginBox mt={15} />
          <Flex direction={'row'}>
            {renderFilterElements(countryAdminFilterData, activeFilters, handleFilterChange)}
          </Flex>
          <MarginBox mt={15} />
          <Flex direction={'row'}>{renderSelectedFilters(promotionTypeFilterOptions, handleTypeFilterRemoval)}</Flex>
        </>
      )}
      <Flex direction={'column'} minHeight={window.innerHeight}>
        <DataContainer
          data={status}
          NotFound={() => (
            <Flex justify={'center'}>
              <Text type={'light_14_bold_black_85'}>{t('promotion.no_promotions', 'No promotions')}</Text>
            </Flex>
          )}
        >
          <MarginBox mt={45} />
          <InfiniteScroll hasMore={loadedPromotions.hasNextPage} loadMore={loadMore}>
            <Flex direction={'row'} wrap={'wrap'}>
              {loadedPromotions.data.map((promotion) => (
                <MarginBox key={promotion.promotionId} mr={30} mb={60}>
                  <PromotionCard promotion={promotion} statusType={statusType} />
                </MarginBox>
              ))}
            </Flex>
          </InfiniteScroll>
        </DataContainer>
      </Flex>
    </>
  );
};
