import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd-v5';
import { FileAltIcon, GlobeIcon } from 'assets/icons';
import LanguageSelect from 'components/LanguageSelect';
import {
  changeDocumentLanguageSaga,
  changeWebLanguageSaga,
  getDocumentLanguage,
  getDocumentLanguageFallback,
  getWebLanguage,
  updateUserProfileLanguagesRequestSaga,
} from 'domains/user';
import { BlackButton, Flex, Grid, Icon, MarginBox, Text, WhiteButton, Space } from 'UI';
import { notifyTop } from 'UI/Notification/notification';
import { Language } from 'utils/i18n/Language';
import { SLanguageCard, SLanguageSelectPosition } from './LanguageSettings.styled';

const LanguageSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const webLang = useSelector(getWebLanguage);
  const docLangPrimary = useSelector(getDocumentLanguage);
  const docLangSecondary = useSelector(getDocumentLanguageFallback);

  const [selectedWebLang, setSelectedWebLang] = useState<Language>(webLang);
  const [selectedDocLang, setSelectedDocLang] = useState<Language>(docLangPrimary);
  const [selectedDocSecondaryLang, setSelectedDocSecondaryLang] = useState<Language>(docLangSecondary);

  const availableWebLanguages = Language.listWebLanguages();
  const availableDocLanguages = Language.listDocumentLanguages();

  useEffect(() => {
    setSelectedWebLang(webLang);
  }, [webLang]);

  useEffect(() => {
    setSelectedDocLang(docLangPrimary);
  }, [docLangPrimary]);

  useEffect(() => {
    setSelectedDocSecondaryLang(docLangSecondary);
  }, [docLangSecondary]);

  const handleSave = () => {
    if (hasChanges()) {
      if (!Language.languagesAreEqual(webLang, selectedWebLang)) {
        dispatch(changeWebLanguageSaga(selectedWebLang));
      }
      if (
        !Language.languagesAreEqual(docLangPrimary, selectedDocLang) ||
        !Language.languagesAreEqual(docLangSecondary, selectedDocSecondaryLang)
      ) {
        dispatch(
          changeDocumentLanguageSaga({
            documentLanguage: selectedDocLang,
            documentLanguageFallback: selectedDocSecondaryLang,
          }),
        );
      }
      dispatch(
        updateUserProfileLanguagesRequestSaga({
          webLanguage: selectedWebLang,
          documentLanguage: selectedDocLang,
          documentLanguageFallback: selectedDocSecondaryLang,
        }),
      );
      notifyTop('success', 'Saved successfully', '');
    }
  };

  const handleCancel = () => {
    if (hasChanges()) {
      // reset to original state
      setSelectedWebLang(webLang);
      setSelectedDocLang(docLangPrimary);
      setSelectedDocSecondaryLang(docLangSecondary);
      notifyTop('info', 'Changes reverted', '');
    }
  };

  const hasChanges = () => {
    return (
      webLang !== selectedWebLang || docLangPrimary !== selectedDocLang || docLangSecondary !== selectedDocSecondaryLang
    );
  };

  return (
    <MarginBox mt={25}>
      <Text type={'h1_banner_light'}>{t('profile.language', 'Language')}</Text>
      <Flex justify={'flex-start'} align={'flex-start'} direction={'column'}>
        <MarginBox mt={10}>
          <Text type={'light_14_black_65'}>
            <Trans i18nKey={'profile.language.description'}>
              Choose your preferred language for website interface and for documentation, in case the documentation in
              1st language is missing, please choose a secondary language.
            </Trans>
          </Text>
        </MarginBox>

        <MarginBox my={45}>
          <Grid
            columns={[1, 3]}
            gutter={[
              [30, 10],
              [10, 10],
            ]}
          >
            <SLanguageCard>
              <Row justify={'start'}>
                <Icon IconComponent={GlobeIcon} size={30} color={'black'} display={'inline'} ml={0} mr={15} noPointer />
                <Text type={'h5_bold'}>{t('profile.language.website', 'Website')}</Text>
              </Row>
              <SLanguageSelectPosition>
                <LanguageSelect
                  languageList={availableWebLanguages}
                  selected={selectedWebLang}
                  onSelect={setSelectedWebLang}
                />
              </SLanguageSelectPosition>
            </SLanguageCard>

            <SLanguageCard>
              <Row justify={'start'}>
                <Icon
                  IconComponent={FileAltIcon}
                  size={30}
                  color={'black'}
                  display={'inline'}
                  ml={0}
                  mr={15}
                  noPointer
                />
                <Text type={'h5_bold'}>{t('profile.language.documentation', 'Documentation')}</Text>
              </Row>
              <SLanguageSelectPosition>
                <LanguageSelect
                  languageList={availableDocLanguages}
                  selected={selectedDocLang}
                  onSelect={setSelectedDocLang}
                />
              </SLanguageSelectPosition>
            </SLanguageCard>
            <SLanguageCard>
              <Row justify={'start'}>
                <Icon
                  IconComponent={FileAltIcon}
                  size={30}
                  color={'black'}
                  display={'inline'}
                  ml={0}
                  mr={15}
                  noPointer
                />
                <Flex direction={'column'}>
                  <Text type={'h5_bold'}>{t('profile.language.documentation', 'Documentation')}</Text>
                  <Text type={'h5_bold'}>{t('profile.language.documentation.secondary', '(secondary)')}</Text>
                </Flex>
              </Row>
              <SLanguageSelectPosition>
                <LanguageSelect
                  languageList={availableDocLanguages}
                  selected={selectedDocSecondaryLang}
                  onSelect={setSelectedDocSecondaryLang}
                />
              </SLanguageSelectPosition>
            </SLanguageCard>
          </Grid>
        </MarginBox>

        <Space size={'large'}>
          <WhiteButton size={'middle'} onClick={handleCancel}>
            {t('common.action.cancel', 'Cancel')}
          </WhiteButton>
          <BlackButton size={'middle'} onClick={handleSave}>
            {t('common.action.save', 'Save')}
          </BlackButton>
        </Space>
      </Flex>
    </MarginBox>
  );
};

export default LanguageSettings;
