/* eslint-disable max-len */
import { TFunction } from 'i18next';
import {
  ROUTER_CART,
  ROUTER_CATALOG_DH_L1,
  ROUTER_CATALOG_DH_L3,
  ROUTER_HOME,
  ROUTER_IN_PROGRESS_ORDERS,
} from 'app/AppRouter';

// eslint-disable-next-line
export const getPopinRaw = (t: TFunction) => {
  return {
    [`${ROUTER_HOME}_disconnect`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.disconnect.title', 'Disconnect'),
      description: t('help.homepage.disconnect.description', 'Click on disconnect to log out from Rpart store.'),
    },
    [`${ROUTER_HOME}_sub_header`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.subheader_menu.title', 'Subheader menu'),
      description: t('help.homepage.subheader_menu.description', 'Access tires, universal products etc.'),
    },
    [`${ROUTER_HOME}_universal_products`]: {
      seen: false,
      disabled: false,
      title: t('catalog.universal_products', 'Universal products'),
      description: t('help.homepage.universal_products.description', 'Access categories of universal products here.'),
    },
    [`${ROUTER_HOME}_ref_search_field`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.reference_search.title', 'Reference search field'),
      description: t(
        'help.homepage.reference_search.description',
        'Search for a reference here. You will be able to search for keywords once you have identified a vehicle.',
      ),
    },
    [`${ROUTER_HOME}_veh_search_field`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.vehicle_search.title', 'Vehicle search field'),
      description: t(
        'help.homepage.vehicle_search.description',
        "Identify a vehicle here. If needed, select the flag corresponding to the vehicle's country.",
      ),
    },
    [`${ROUTER_HOME}_basket`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.basket.title', 'Basket'),
      description: t('help.homepage.basket.description', 'Access your basket here.'),
    },
    [`${ROUTER_HOME}_estimate`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.estimate.title', 'Estimate'),
      description: t('help.homepage.estimate.description', 'Access your estimate here.'),
    },
    [`${ROUTER_HOME}_menu`]: {
      seen: false,
      disabled: false,
      title: t('help.homepage.side_menu.title', 'Left side menu'),
      description: t(
        'help.homepage.side_menu.description',
        'Get access here to your profile, vehicle search history, orders and estimate',
      ),
    },
    [`${ROUTER_CART}_file_import`]: {
      seen: false,
      disabled: false,
      title: t('help.cart.file_import.title', 'File import'),
      description: t('help.cart.file_import.description', 'Click here to add references to the basket through a file.'),
    },
    [`${ROUTER_CART}_add_reference`]: {
      seen: false,
      disabled: false,
      title: t('help.cart.add_reference.title', 'Add a reference'),
      description: t(
        'help.cart.add_reference.description',
        'Via this search field you can add a reference to your basket. It will be put in the "Other articles" section.',
      ),
    },
    [`${ROUTER_CART}_add_comment`]: {
      seen: false,
      disabled: false,
      title: t('help.cart.add_comment.title', 'Add a comment'),
      description: t('help.cart.add_comment.description', 'Click here to add a comment to the reference.'),
    },
    [`${ROUTER_CART}_link_to_veh`]: {
      seen: false,
      disabled: false,
      title: t('help.cart.link_to_vehicle.title', 'Link a reference to a vehicle'),
      description: t(
        'help.cart.link_to_vehicle.description',
        'By clicking here, you can link the reference to an existing section in your basket.',
      ),
    },
    [`${ROUTER_IN_PROGRESS_ORDERS}_order_detail`]: {
      seen: false,
      disabled: false,
      title: t('help.order.detail.title', 'Order details'),
      description: t('help.order_detail.description', 'Click on an order to see its details.'),
    },
    [`${ROUTER_IN_PROGRESS_ORDERS}_print`]: {
      seen: false,
      disabled: false,
      title: t('help.order.print.title', 'Print'),
      description: t(
        'help.order.print.description',
        'Select one order to print the details, or several to print a list of orders.',
      ),
    },
    [`${ROUTER_IN_PROGRESS_ORDERS}_filters`]: {
      seen: false,
      disabled: false,
      title: t('help.order.filters.title', 'Filters'),
      description: t('help.order.filters.description', 'Use the filters to search for orders.'),
    },
    [`${ROUTER_CATALOG_DH_L1}_keyword_search`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l1.keywords_search.title', 'Now use keywords!'),
      description: t(
        'help.catalog.l1.keywords_search.description',
        'Now that you have identified a Renault / Dacia vehicle, you can also search for products using keywords.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L1}_catalog_menu`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l1.catalog_menu.title', 'Catalog menu'),
      description: t('help.catalog.l1.catalog_menu.description', 'Access here the catalog related to your vehicle'),
    },
    [`${ROUTER_CATALOG_DH_L1}_vehicle_details`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l1.vehicle_details.title', "Vehicle's details"),
      description: t(
        'help.catalog.l1.vehicle_details.description',
        "By clicking here, or on the vehicle's designation, you can access vehicle's details.",
      ),
    },
    [`${ROUTER_CATALOG_DH_L1}_exploded_views`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l1.exploded_views.title', 'Exploded views'),
      description: t(
        'help.catalog.l1.exploded_views.description',
        'These are exploded views. You can click on it to display levels 2 of the catalog, or click on a number on the exploded view to quickly access a level 2.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L1}_breadcrumb`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l1.breadcrumb.title', 'Breadcrumb'),
      description: t(
        'help.catalog.l1.breadcrumb.description',
        'Quickly navigate through the catalog by using the breadcrumb.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L3}_plate_detail`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.plate_detail.title', 'Plank details'),
      description: t(
        'help.catalog.l3.plate_detail.description',
        'Through these tabs, find items related to the displayed plank.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L3}_quick_search`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.quick_search.title', 'Quick search'),
      description: t(
        'help.catalog.l3.quick_search.description',
        'Click on an item in the list or a number in the plank to quickly access the related references.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L3}_prices`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.prices.title', 'Prices display'),
      description: t('help.catalog.l3.prices.description', 'You can choose to display clients prices here.'),
    },
    [`${ROUTER_CATALOG_DH_L3}_add_to_estimate`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.add_to_estimate.title', 'Add to estimate'),
      description: t('help.catalog.l3.add_to_estimate.description', 'Click here to add the item to the estimate.'),
    },
    [`${ROUTER_CATALOG_DH_L3}_labor_times`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.labor_times.title', 'Labor times'),
      description: t(
        'help.catalog.l3.labor_times.description',
        'Click here to see labor times related to the reference and add them to the estimate.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L3}_add_to_cart`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.add_to_cart.title', 'Add to cart'),
      description: t(
        'help.catalog.l3.add_to_cart.description',
        'Click here to add the reference to the basket and manage the quantity.',
      ),
    },
    [`${ROUTER_CATALOG_DH_L3}_plate_index`]: {
      seen: false,
      disabled: false,
      title: t('help.catalog.l3.plate_index.title', 'Spot the pieces in the plank'),
      description: t('help.catalog.l3.plate_index.description', 'Click on an index title to spot it in the plank.'),
    },
    close: {
      seen: false,
      disabled: false,
      title: t('help.close.title', 'Find help here'),
      description: t('help.close.description', "You can reactivate the page's helpers at any time here."),
    },
  };
};
