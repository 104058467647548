import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IAMTreeRepairMethod } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsTreeResponse';
import { v4 as uuidv4 } from 'uuid';
import { ExclamationTriangleIcon, EyeIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { useFetchIAMRepairMethodsTree } from 'domains/catalog/Catalog.requests';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { useIAMRepairMethodDetail } from 'pages/CatalogPage/IAM/RepairMethod/useIAMRepairMethodDetail';
import { STable } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
import { theme } from 'styles';
import { Container, Flex, Icon, MarginBox, Spin, Text } from 'UI';
import { ERROR, FOUND, getSearchData, isLoading, LOADING, NOT_FOUND, SearchData } from 'utils';

const TitleCell = (tr: string) => (
  <Text type={'h2'} disableGutter>
    {tr}
  </Text>
);

const DataCell = (text: string) => (
  <Text type={'text'} disableGutter>
    {text}
  </Text>
);

const openWindowWithHtml = (detail: SearchData<string> | undefined): void => {
  if (detail?.data && detail?.searchStatus === FOUND) {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.open();
      newWindow.document.write(detail.data);
      newWindow.document.close();
    }
  }
};

export const RepairMethod = () => {
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const repairMethods = useFetchIAMRepairMethodsTree(vehicleDetail);
  const repairMethodsData = getSearchData(repairMethods);
  const detail = useIAMRepairMethodDetail(categoryId);

  const clickDetail = (familyId: string) => {
    if (familyId === categoryId) {
      openWindowWithHtml(detail);
    } else {
      setCategoryId(familyId);
    }
  };

  useEffect(() => {
    openWindowWithHtml(detail);
  }, [categoryId, detail]);

  const renderStatusIcon = (method: IAMTreeRepairMethod) => {
    if (method.familyId === categoryId) {
      switch (detail?.searchStatus) {
        case LOADING:
          return <Spin size={'small'} />;
        case ERROR:
        case NOT_FOUND:
          return (
            <Icon
              IconComponent={ExclamationTriangleIcon}
              color={theme.color.warning}
              height={20}
              width={20}
              noPointer
            />
          );
        default:
          break;
      }
    }
    return (
      <Icon
        IconComponent={EyeIcon}
        color={theme.color.clear_blue}
        height={20}
        width={20}
        onClick={() => clickDetail(method.familyId)}
      />
    );
  };

  const tableColumns = () => [
    {
      title: TitleCell(t('catalog.repair_method.header.chapter', 'Chapter')),
      dataIndex: 'lvl1Label',
      key: 'lvl1Label',
      render: (text: string, _record: IAMTreeRepairMethod, index: number) => (
        <Text type={'text'} disableGutter>
          {index === 0 ||
          (repairMethodsData && repairMethodsData[index].lvl1Label !== repairMethodsData[index - 1].lvl1Label)
            ? text.toUpperCase()
            : ''}
        </Text>
      ),
    },
    {
      title: TitleCell(t('catalog.repair_method.header.section', 'Section')),
      dataIndex: 'lvl2Label',
      key: 'lvl2Label',
      render: DataCell,
    },
    {
      title: TitleCell(t('catalog.repair_method.header.pages', 'Pages')),
      dataIndex: 'lvl3Label',
      key: 'lvl3Label',
      render: DataCell,
    },
    {
      title: TitleCell(t('catalog.repair_method.header.methods', 'Methods')),
      key: 'lvl4Label',
      width: 250,
      render: (method: IAMTreeRepairMethod) => {
        return (
          <Flex key={uuidv4()} direction={'row'} justify={'space-between'} align={'center'}>
            <Text
              type={'text'}
              displayStyle={'link'}
              cursor={'pointer'}
              decoration={'underline'}
              onClick={() => clickDetail(method.familyId)}
            >
              {method.lvl4Label}
            </Text>
            {renderStatusIcon(method)}
          </Flex>
        );
      },
    },
  ];

  return (
    <Container>
      <MarginBox mt={15} />
      <Text type={'h3'}>{t('catalog.repair_method.title', 'Repair method')}</Text>
      <DataContainer data={repairMethods?.searchStatus}>
        <MarginBox mt={20} />
        <STable<IAMTreeRepairMethod>
          loading={isLoading(repairMethods?.searchStatus)}
          columns={tableColumns()}
          dataSource={repairMethodsData}
          pagination={false}
          size={'large'}
          bordered={true}
        />
      </DataContainer>
    </Container>
  );
};
