import React from 'react';
import { View } from '@react-pdf/renderer';
import { Card, Divider, ItemNameTitle, Spacer } from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';
import { OrderPdfText } from './MyOrdersDetailPdfPage';
import { RowBreakLine } from './RowBreakLine';

export const InformationCard = ({
  text,
  orderedBy,
  clientNumber,
  distributor,
  billingAccount,
}: {
  text: OrderPdfText;
  orderedBy: string | undefined;
  clientNumber: string | undefined;
  distributor: string | undefined;
  billingAccount: string | undefined;
}) => {
  const items = [
    {
      title: text.orderBy,
      data: orderedBy,
    },
    {
      title: text.clientNumber,
      data: clientNumber,
    },
    {
      title: text.distributor,
      data: distributor,
    },
    {
      title: text.billingAccount,
      data: billingAccount,
    },
  ];

  return (
    <Card title={<ItemNameTitle>{text.informations}</ItemNameTitle>} height={150} width={190} alignTitleStart>
      <View style={[styles.col, { margin: '10px 7px 10px 7px' }]}>
        {items.map((i) => (
          <>
            <RowBreakLine title={i.title} data={i.data} />
            <Spacer />
            <Divider />
            <Spacer />
          </>
        ))}
      </View>
    </Card>
  );
};
