import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputRef } from 'antd';
import { ArrowLeftIcon } from 'assets/icons';
import { Box, Flex, Icon, Input, MarginBox, Text, YellowButton } from 'UI';
import { useFocusOnMount } from 'utils';
import { STitleCard } from './CreateEstimateButton.styled';

export interface DMSImportButtonProps {
  importDMSOrder: (orderNumber: string) => void;
  setIsCreateEstimatePopoverVisible: Dispatch<SetStateAction<boolean>>;
  setIsDMSImportActive: Dispatch<SetStateAction<boolean>>;
}

const DMSImport = (props: DMSImportButtonProps) => {
  const { importDMSOrder, setIsCreateEstimatePopoverVisible, setIsDMSImportActive } = props;
  const [repairOrderNumber, setRepairOrderNumber] = useState('');
  const { t } = useTranslation();
  const orderNumberInputRef = useRef<InputRef>(null);
  useFocusOnMount<InputRef>(orderNumberInputRef);

  function handleClick() {
    importDMSOrder(repairOrderNumber);
    setIsDMSImportActive(false);
    setIsCreateEstimatePopoverVisible(false);
  }

  return (
    <>
      <STitleCard pointer onClick={() => setIsDMSImportActive(false)}>
        <Flex>
          <Icon IconComponent={ArrowLeftIcon} size={14} mr={10} mt={3} color={'white'} />
          <Text type={'h6_white'}>{t('estimate.dms.import_repair_order', 'Import repair order')}</Text>
        </Flex>
      </STitleCard>
      <MarginBox mt={15} />
      <Flex justify={'center'}>
        <Box width={240}>
          <Box height={40}>
            <Input
              initialValue={''}
              onChange={(val) => setRepairOrderNumber(val)}
              bordered
              onPressEnter={handleClick}
              passRef={orderNumberInputRef}
            />
          </Box>
          <MarginBox mt={10} />
          <Flex justify={'flex-end'}>
            <Box width={80}>
              <YellowButton
                stretch
                size={'small'}
                onClick={() => {
                  handleClick();
                }}
                disabled={repairOrderNumber === ''}
              >
                {t('common.import', 'Import')}
              </YellowButton>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default DMSImport;
