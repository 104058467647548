/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import {
  FILTER_DELIVERY,
  getFiltersMap,
  getOEMFiltersMap,
  getStockFilterOptions,
  RangeFacetLocal,
  TextFacetLocal,
} from 'components/Filter/Filter.types';
import Loader from 'components/Loader';
import { getIamCatalogFilters, getLastSearchedVehicleKey, resetIamTextFilter } from 'domains/catalog/Catalog.store';
import { IAMLaborTimesWrapper, OEM_BRAND_KEY, OTHER_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { getIAMReferences, getPricesMap, getStocksMap, IAMReferenceLocal } from 'domains/references';
import { useFetchPrices, useFetchReferencesStocks } from 'domains/references/References.requests';
import { getIamCatalogBrandsView, SparePartsViewType } from 'domains/user';
import { isConnected } from 'domains/webSockets/WebSocket.store';
import {
  getIamDeliveryFilterOptions,
  getIamReferencesByFilters,
  getUpdatedIamRangeFilters,
  getUpdatedIAMTextFilters,
} from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/IAMFilterUtils';
import IAMPlateReferenceCard from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard';
import {
  getFilteredMotrioReferences,
  getFilteredOtherBrandsReferences,
  shouldDisplayIAMReference,
  sortIAMReferences,
  useMotrioStockPricesLoading,
} from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainerUtils';
import { Box, CenterFlex, Empty, Flex, Text } from 'UI';
import { FOUND, isLoading, LOADING, NO_DATA, NOT_FOUND, SEARCH_STATUS } from 'utils';
/* eslint-enable max-len */

export const getIamContainerLoader = () => (
  <CenterFlex>
    <Loader height={'10vh'} />
  </CenterFlex>
);

export interface ReferencesCardsProps {
  sparePartsView: SparePartsViewType;
  laborTimesWrapper?: NO_DATA | IAMLaborTimesWrapper;
  setNumOfProducts?: (num: number) => void;
  textFilters?: TextFacetLocal[];
  rangeFilters?: RangeFacetLocal[];
  setTextFilters?: (t: TextFacetLocal[]) => void;
  setRangeFilters?: (r: RangeFacetLocal[]) => void;
  nodeId?: string;
  referenceNumbers: string[];
  selectedReferences?: string[];
  isMaintenance?: boolean;
  onSelect?: (referenceNumber: string) => void;
}

const ReferenceCardsContainer = ({
  sparePartsView,
  laborTimesWrapper,
  setNumOfProducts,
  textFilters,
  rangeFilters,
  setTextFilters,
  setRangeFilters,
  nodeId,
  referenceNumbers,
  selectedReferences,
  isMaintenance,
  onSelect,
}: ReferencesCardsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOEM = useSelector(getIamCatalogBrandsView) === OEM_BRAND_KEY;
  const iamFilters = useSelector(getIamCatalogFilters);
  const filtersMap = isOEM ? getOEMFiltersMap(t) : getFiltersMap(t);
  const filter = useMemo(() => {
    return isOEM ? iamFilters.oemFilters : iamFilters.otherBrandFilters;
  }, [isOEM, iamFilters]);
  const isWsConnected = useSelector(isConnected);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const references = useSelector((state: RootState) =>
    getIAMReferences(state, {
      referenceNumbers,
      vehicleKey,
    }),
  );
  const prices = useSelector((state: RootState) => getPricesMap(state, referenceNumbers ?? []));
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers ?? []));
  const motrioStocksPricesLoading = useMotrioStockPricesLoading(
    references.filter((r) => r.supplier === 'MOTRIO').map((ref) => ref.referenceNumber),
  );
  const stockStatuses = useMemo(() => [...stocks.values()].map((s) => s?.searchStatus), [stocks]);
  const [allReferences, setAllReferences] = useState<IAMReferenceLocal[]>([]);
  const [currentReferenceNumbers, setCurrentReferenceNumbers] = useState<string[] | undefined>(undefined);
  const [searchStatus, setSearchStatus] = useState<SEARCH_STATUS>(LOADING);
  const [someStockMissingFirstLoad, setSomeStockMissingFirstLoad] = useState(true);
  const someStockLoading = stockStatuses.some((sp) => isLoading(sp));
  const stockRequestDetails = useMemo(() => {
    return references.map((ref) => {
      return {
        referenceNumber: ref.referenceNumber,
        type: ref.type,
        quantity: 1,
        origin: ref.origin,
        supplierCode: ref.supplierCode,
        referenceSource: ref.referenceSource,
      };
    });
  }, [references]);

  useFetchReferencesStocks(referenceNumbers ?? [], stockRequestDetails);
  useFetchPrices(referenceNumbers ?? [], vehicleKey);

  useEffect(() => {
    if (someStockMissingFirstLoad && stockStatuses.length > 0 && allReferences.length > 0) {
      setSomeStockMissingFirstLoad(someStockLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockStatuses, isWsConnected]);

  useEffect(() => {
    if (!isWsConnected && allReferences.length > 0) {
      setSomeStockMissingFirstLoad(true);
    }
  }, [isWsConnected, allReferences.length]);

  /**
   * @filteredReferences: references that will be displayed in the result page
   * @filteredReferenceNumbers: array with reduces size compared to filteredReferences,
   *                            is stringified in useEffect dependency to prevent rerender while updating filters
   */
  const { filteredReferences } = useMemo(() => {
    const referencesFilteredManually = getIamReferencesByFilters(allReferences, stocks, filter, filtersMap, t);
    const motrioReferences = sortIAMReferences(
      getFilteredMotrioReferences(referencesFilteredManually, prices, stocks),
      stocks,
    );
    const otherBrandsReferences = sortIAMReferences(
      getFilteredOtherBrandsReferences(referencesFilteredManually),
      stocks,
    );
    const newReferencesArray = [...motrioReferences, ...otherBrandsReferences];
    return {
      filteredReferences: newReferencesArray,
      filteredReferenceNumbers: newReferencesArray.map((fr) => fr.referenceNumber),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReferences, filter, stocks, t]);

  const [showMoreReferences, setShowMoreReferences] = useState(filteredReferences.length > 3);
  const firstThreeReferences = filteredReferences.slice(0, 3);
  const displayedReferences = isMaintenance && !showMoreReferences ? firstThreeReferences : filteredReferences;

  useEffect(() => {
    if (isMaintenance || !textFilters || !setTextFilters || !rangeFilters || !setRangeFilters) {
      return;
    }
    const updatedTextFilters = getUpdatedIAMTextFilters(textFilters, stocks, filtersMap, filteredReferences, t);
    if (stockStatuses.length > 0 && isWsConnected) {
      const hasDeliveryFilter = !!updatedTextFilters.find((ft) => ft.id === FILTER_DELIVERY);
      const stocksFilterOptions = getStockFilterOptions('IAM', !someStockLoading);
      const deliveryFilterOptions = getIamDeliveryFilterOptions(stocksFilterOptions, stocks, filteredReferences, t);
      if (hasDeliveryFilter) {
        const updatedTextFiltersWithDelivery: TextFacetLocal[] = updatedTextFilters.map((item) =>
          item.id === FILTER_DELIVERY && deliveryFilterOptions ? deliveryFilterOptions : item,
        );
        const deliveryFilterSelection = filter.textFilters.get(FILTER_DELIVERY);
        const missingSelectedDeliveryOptions =
          deliveryFilterSelection?.filter((sel) => {
            const updatedDelivery = deliveryFilterOptions.items.find((item) => item.label === sel);
            return updatedDelivery === undefined || updatedDelivery.numberOfItems === 0;
          }) ?? [];
        if (missingSelectedDeliveryOptions.length === 1) {
          dispatch(
            resetIamTextFilter({ filterType: isOEM ? OEM_BRAND_KEY : OTHER_BRAND_KEY, filterOption: FILTER_DELIVERY }),
          );
        }
        setTextFilters(updatedTextFiltersWithDelivery);
      } else {
        setTextFilters(updatedTextFilters.concat(deliveryFilterOptions));
      }
    } else {
      setTextFilters(updatedTextFilters);
    }
    setRangeFilters(getUpdatedIamRangeFilters(rangeFilters, filtersMap, filteredReferences));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, someStockLoading, someStockMissingFirstLoad]);

  useEffect(() => {
    if (isMaintenance || !setNumOfProducts) {
      return;
    }
    setNumOfProducts?.(filteredReferences.length);
    // eslint-disable-next-line
  }, [filteredReferences.length]);

  useEffect(() => {
    if (referenceNumbers.length !== currentReferenceNumbers?.length) {
      setCurrentReferenceNumbers(referenceNumbers);
      return;
    }
    if (referenceNumbers.length === 0) {
      setSearchStatus(NOT_FOUND);
      return;
    }

    const referencesArray = references.filter((referenceToDisplay) => {
      const refNumber = referenceToDisplay.referenceNumber;
      return shouldDisplayIAMReference(referenceToDisplay, prices.get(refNumber), stocks.get(refNumber));
    });

    setSearchStatus(FOUND);
    setAllReferences(referencesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId, currentReferenceNumbers, motrioStocksPricesLoading, isOEM, isWsConnected, referenceNumbers]);

  return (
    <DataContainer data={searchStatus} Loading={getIamContainerLoader}>
      <Flex justify={'center'}>
        {displayedReferences.length > 0 ? (
          <Flex direction={'column'}>
            {displayedReferences.map((ref, index) => (
              <IAMPlateReferenceCard
                key={`iam-ref-${ref.referenceNumber}-${index}`}
                referenceNumber={ref.referenceNumber}
                sparePartsView={sparePartsView}
                laborTimesWrapper={laborTimesWrapper}
                isMaintenance={isMaintenance}
                selected={
                  selectedReferences?.find((val) => val === ref.referenceNumber) !== undefined ? true : undefined
                }
                onSelect={onSelect}
              />
            ))}
            {isMaintenance && !showMoreReferences && (
              <Text type={'link'} hoverUnderLine cursor={'pointer'} onClick={() => setShowMoreReferences(true)}>
                {t('common.action.show_more_references', 'Show more references')}
              </Text>
            )}
          </Flex>
        ) : (
          <Box height={'20vh'}>
            <Empty description={t('catalog.parts.filter.no_result', 'No result for the given category.')} />
          </Box>
        )}
      </Flex>
    </DataContainer>
  );
};

export default ReferenceCardsContainer;
