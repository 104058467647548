import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSubscribeExternalBasketRequest } from 'domains/externalBasket/ExternalBasket.api';
import { getTradingProfile, getUserContext } from 'domains/user';

export const useSubscribeExternalBasket = () => {
  const dispatch = useDispatch();
  const isTradingProfileComplete = useSelector(getTradingProfile)?.isComplete() ?? false;
  const userContext = useSelector(getUserContext);

  useEffect(() => {
    if (!isTradingProfileComplete) return;
    dispatch(sendSubscribeExternalBasketRequest({ userContext }));
  }, [dispatch, isTradingProfileComplete, userContext]);
};
