import React from 'react';
import { Trans } from 'react-i18next';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Document, Image, Page, pdf, Text as PdfText, View } from '@react-pdf/renderer';
import { v4 as uuidv4 } from 'uuid';
import { processPlateSvg, RenaultGroup, Rpartstore } from 'components/Pdf/PdfSvg';
import { ItemNameTitle, LeadText, PageNumber, Spacer, Table, VerticalSpacer } from 'components/Pdf/PdfUI';
import { fonts, styles } from 'components/Pdf/PdfUI.styles';
import { DHReferenceLocal } from 'domains/references';
import { theme } from 'styles';
import { sentenceCase } from 'UI';
import { getCondArrayItem } from 'utils';
import { textFormatter } from 'utils/format';

export interface RefLocal extends DHReferenceLocal {
  index: number;
  indexName: string;
}

export interface PlateItemData {
  plateId: string;
  plateReferences: RefLocal[];
  plateImage: string | undefined;
  signature?: string;
  imageUrl?: string;
}

export interface PrintPlatePdfData {
  date: Date;
  plates: PlateItemData[];
  printReferenceNumber: boolean;
  vehicleBrand: VehicleBrandType;
}
export const handlePrintPlate = async (pdfData: PrintPlatePdfData) => {
  const blob = await pdf(<PrintPlatePdf data={pdfData} />).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};

const Header = ({ data }: { data: PrintPlatePdfData }) => {
  const date = textFormatter.formatDate(data.date);
  return (
    <View>
      <View style={styles.row}>
        <View style={[styles.row]}>
          <RenaultGroup color={theme.color.brand_black} />
          <VerticalSpacer width={250} />
          <Rpartstore />
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.column}>
          <View style={styles.col}>
            <Spacer />
            <LeadText>
              <Trans i18nKey={'catalog.plate_pdf.print_date'} tOptions={{ date }}>
                Date: {{ date }}
              </Trans>
            </LeadText>
            <Spacer />
          </View>
        </View>
      </View>
    </View>
  );
};

const getItemColumns = ({
  printRefNumber,
  vehicleBrand,
}: {
  printRefNumber: boolean;
  vehicleBrand: VehicleBrandType;
}) => {
  return [
    {
      title: (
        <ItemNameTitle>
          <Trans i18nKey="catalog.plate_pdf.indexes">Indexes</Trans>
        </ItemNameTitle>
      ),
      width: 100,
      render: (item: RefLocal) => (
        <PdfText style={fonts.input_text}>
          {item.index}. {sentenceCase(item.indexName)}
        </PdfText>
      ),
    },
    ...getCondArrayItem(
      printRefNumber && {
        title: (
          <ItemNameTitle>
            <Trans i18nKey="catalog.plate_pdf.references">References</Trans>
          </ItemNameTitle>
        ),
        width: 100,
        render: (item: RefLocal) => <PdfText style={fonts.input_text}>{item.referenceNumber}</PdfText>,
      },
    ),
    {
      title: (
        <ItemNameTitle>
          <Trans i18nKey="catalog.plate_pdf.designation">Designation</Trans>
        </ItemNameTitle>
      ),
      width: 100,
      render: (item: RefLocal) => <PdfText style={fonts.input_text}>{sentenceCase(item.name ?? '')}</PdfText>,
    },
    {
      title: (
        <ItemNameTitle>
          <Trans i18nKey="catalog.plate_pdf.information">Information</Trans>
        </ItemNameTitle>
      ),
      width: printRefNumber ? 170 : 270,
      render: () => <></>,
      renderNode: (item: RefLocal) => (
        <View key={uuidv4()} style={[styles.column, { alignItems: 'center' }]}>
          {item.additionalInformation?.map((a) => (
            <PdfText style={fonts.input_text} key={uuidv4()}>
              {sentenceCase(a.title ?? '')}
              <VerticalSpacer width={5} />
              {sentenceCase(a.description ?? '')}
            </PdfText>
          ))}
        </View>
      ),
    },
    {
      title: (
        <ItemNameTitle>
          <Trans i18nKey="catalog.plate_pdf.brands">Brands</Trans>
        </ItemNameTitle>
      ),
      render: (item: RefLocal) => (
        <PdfText style={fonts.input_text}>
          <BrandText referenceBrand={item.brand} vehicleBrand={vehicleBrand} />
        </PdfText>
      ),
    },
  ];
};

const BrandText = ({
  referenceBrand,
  vehicleBrand,
}: {
  referenceBrand?: ReferenceBrandType;
  vehicleBrand: VehicleBrandType;
}) => {
  switch (referenceBrand) {
    case undefined:
    case 'CENTRAL': {
      return vehicleBrand === 'DACIA' ? 'Dacia' : 'Renault';
    }
    case 'STANDARD_EXCHANGE': {
      return 'STANDARD EXCHANGE';
    }
    case 'VALUE_PLUS': {
      return 'VALUE+';
    }
    case 'MOTRIO': {
      return 'MOTRIO';
    }
    case 'IXELL': {
      return 'IXELL';
    }
    case 'IXTAR': {
      return 'IXTAR';
    }
    default: {
      return '';
    }
  }
};
export const PrintPlatePdf = ({ data }: { data: PrintPlatePdfData }) => (
  <Document>
    <Page size={'A4'} style={styles.page} wrap={true}>
      <PageNumber />
      <Header data={data} />
      <Spacer />
      {data.plates.map((plate) => (
        <React.Fragment key={uuidv4()}>
          <View>
            {plate.plateImage && (
              <Image src={processPlateSvg(plate.plateImage)} style={{ width: '550px', margin: '0 0 0 0' }} />
            )}
            <LeadText>{plate.plateId}</LeadText>
          </View>
          <Spacer height={20} />
          {plate.plateReferences.length > 0 ? (
            <Table
              key={plate.plateId}
              data={plate.plateReferences}
              columns={getItemColumns({ printRefNumber: data.printReferenceNumber, vehicleBrand: data.vehicleBrand })}
            />
          ) : (
            <LeadText>
              <Trans i18nKey="catalog.parts.category.car_parts.results_not_found.detail">
                Sorry, no parts were found for this vehicle.
              </Trans>
            </LeadText>
          )}
          <Spacer height={60} />
        </React.Fragment>
      ))}
    </Page>
  </Document>
);
