import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrentPromotionsPageBanner from 'pages/PromotionPage/CurrentPromotionsPageBanner/CurrentPromotionsPageBanner';
import PromotionCardsContainer from 'pages/PromotionPage/PromotionCardsContainer';
import { Container, defaultPadding, emptyPadding, Flex, MarginBox, Text } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';

const PromotionPage = () => {
  const { t } = useTranslation();

  useResetScroll();

  return (
    <Container padding={emptyPadding} size={'xxl'}>
      <Flex direction={'column'}>
        <CurrentPromotionsPageBanner />
        <Container padding={defaultPadding} size={'xxl'}>
          <Flex direction={'column'} justify={'space-between'}>
            <Text type={'h1'}>{t('promotion.page.title.explore_best_offers', 'Explore our best offers')}</Text>
            <Flex maxWidth={600}>
              <Text type={'text'}>
                {t(
                  'promotion.page.subtitle',
                  // eslint-disable-next-line max-len
                  `Discover our current promotions, carefully curated to offer you the best savings on various car parts and accessories. Whether you're looking for replacement parts for routine maintenance or upgrading your vehicle's performance, we have you covered.`,
                )}
              </Text>
            </Flex>
          </Flex>
          <MarginBox mt={30} />
          <PromotionCardsContainer />
        </Container>
      </Flex>
    </Container>
  );
};

export default PromotionPage;
