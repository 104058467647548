import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { PromotionFilterTypeOption } from 'domains/promotion/Promotion.types';
import { DropdownMenu } from 'UI/DropdownMenu';

export const promotionFiltersData = (t: TFunction): { id: PromotionFilterTypeOption; label: string }[] => {
  return [
    {
      id: 'DISCOUNT',
      label: t('backoffice.promotion.discount', 'Discount'),
    },
    {
      id: 'PROMOTION_FLASH',
      label: t('backoffice.promotion.promotion_flash', 'Promotion flash'),
    },
    {
      id: 'BANNER',
      label: t('backoffice.promotion.banner', 'Banner'),
    },
  ];
};

const PromotionFilterDropdown = ({
  promotionTypeFilterOptions,
  setPromotionTypeFilterOptions,
}: {
  promotionTypeFilterOptions: PromotionFilterTypeOption[];
  setPromotionTypeFilterOptions: (filters: PromotionFilterTypeOption[]) => void;
}) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu
      label={t('backoffice.promotion.promotion_type', 'Promotion type')}
      items={promotionFiltersData(t)}
      value={promotionTypeFilterOptions}
      onChange={(items) => setPromotionTypeFilterOptions(items as PromotionFilterTypeOption[])}
      allowSelectAll
    />
  );
};

export default PromotionFilterDropdown;
