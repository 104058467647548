/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Loader from 'components/Loader';
import { useFetchFullPromotion } from 'domains/promotion/Promotion.requests';
import { getFullPromotion } from 'domains/promotion/Promotion.store';
import { useSetCopyPromoModel } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.hook';
import { PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Empty, Flex, Link, MarginBox, Text } from 'UI';
import { getData } from 'utils';
import CreatePromotion from '../CreatePromotion/CreatePromotion';

const getStatusErrorDisplay = (status: StatusType | undefined, t: TFunction) => {
  switch (status) {
    case 'PUBLISHED':
      return (
        <ErrorWithLabel
          label={t('backoffice.promotion.copy.status.published.error', 'Published promotion cannot be copied.')}
        />
      );
    case 'PLANNED':
      return (
        <ErrorWithLabel
          label={t('backoffice.promotion.copy.status.planned.error', 'Planned promotion cannot be copied.')}
        />
      );
    default:
      return <></>;
  }
};

const CopyPromotion = () => {
  const { t } = useTranslation();
  const { promotionId } = useParams<{ promotionId: string }>();
  const promotion = useSelector((state: RootState) => getFullPromotion(state, promotionId));
  const promotionData = getData(promotion);
  const promotionStatus = promotionData?.status;

  useFetchFullPromotion(promotionId);
  useSetCopyPromoModel(promotionId);

  return (
    <DataContainer
      data={promotion}
      ErrorState={() => (
        <ErrorWithLabel label={t('common.backend_error', 'Data temporarily unavailable, please try again later.')} />
      )}
      Loading={() => <Loader height={'50vh'} />}
      NotFound={() => <Empty />}
    >
      {promotionStatus === 'ARCHIVED' || promotionStatus === 'SAVED' ? (
        <CreatePromotion />
      ) : (
        <Flex direction={'column'} justify={'center'}>
          <MarginBox mt={90} />
          {getStatusErrorDisplay(promotionStatus, t)}
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Link to={`/${PROMOTION}/${promotionId}`}>
              <Text type={'text'} displayStyle={'link'} hoverUnderLine cursor={'pointer'}>
                {t('backoffice.promotion.see_promotion_page', 'See promotion page')}
              </Text>
            </Link>
          </Flex>
        </Flex>
      )}
    </DataContainer>
  );
};

export default CopyPromotion;
