import {
  BACKOFFICE_GET_RETURNS_FEATURE_SETTINGS_RESPONSE,
  BACKOFFICE_SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  GET_RETURNS_FEATURE_SETTINGS_REQUEST,
  R1_NETWORK_NAMESPACE,
  SAVE_RETURNS_FEATURE_SETTINGS_REQUEST,
} from 'domains/r1Network/R1Network.types';

export interface R1NetworkState {
  settings: ReturnsFeatureSettings;
}

export interface ReturnsFeatureSettings {
  email?: string;
  isEnabled: boolean;
}

const initialState: R1NetworkState = {
  settings: {
    isEnabled: false,
  },
};

// Saga actions
export const getReturnsFeatureSettingsRequestSaga = createAction(GET_RETURNS_FEATURE_SETTINGS_REQUEST);
export const getReturnsFeatureSettingsResponseSaga = createAction(BACKOFFICE_GET_RETURNS_FEATURE_SETTINGS_RESPONSE);
export const saveReturnsFeatureSettingsRequestSaga = createAction(SAVE_RETURNS_FEATURE_SETTINGS_REQUEST);
export const saveReturnsFeatureSettingsResponseSaga = createAction(BACKOFFICE_SAVE_RETURNS_FEATURE_SETTINGS_RESPONSE);

// Slice
const slice = createSlice({
  name: R1_NETWORK_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setReturnsFeatureSettings: (state, { payload }: PayloadAction<ReturnsFeatureSettings>) => {
      state.settings = { email: payload.email, isEnabled: payload.isEnabled ?? false };
    },
  },
});

export const { setInitialState, setReturnsFeatureSettings } = slice.actions;

// Getters/Selectors
export const getReturnsFeatureSettings = createSelector(
  (state: RootState) => state.r1Network.settings,
  (settings: ReturnsFeatureSettings) => settings,
);

export default slice.reducer;
