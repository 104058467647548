import styled from 'styled-components';
import { defaultBoxShadow, Flex } from 'UI';

export const TireItemContainer = styled(Flex)<{
  selected: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;

  ${({ selected }) => selected && defaultBoxShadow}
  :hover {
    ${defaultBoxShadow};
  }
`;
