import React from 'react';
import { BlueButton, Box, Flex, PaddingBox, StickyBoxBottom, Text, WhiteButton } from 'UI';
import { StickCard, StickyBoxContainer } from './MaintenancePlan.styled';

export const BottomStickyBox = ({
  text1,
  text2,
  callback,
  buttonLabel,
  callback2,
  buttonLabel2,
  buttonLabel2Enabled = true,
}: {
  text1: string;
  text2: string;
  callback: () => void;
  buttonLabel: string;
  callback2?: () => void;
  buttonLabel2?: string;
  buttonLabel2Enabled?: boolean;
}) => {
  return (
    <StickyBoxContainer>
      <StickyBoxBottom width={'100%'} offsetBottom={15} background={'white'} zIndex={501}>
        <StickCard>
          <Flex direction={'row'} align={'center'}>
            <Flex direction={'column'}>
              <Text type={'h4_paragraph'}>{text1}</Text>
              <PaddingBox pt={12} />
              <Text type={'light_14_black_75'}>{text2}</Text>
            </Flex>
            {buttonLabel2 && callback2 && (
              <Flex direction={'column'} justify={'center'} align={'flex-end'}>
                <PaddingBox pr={25}>
                  <Box width={170}>
                    <WhiteButton
                      shape={'round'}
                      size={'large'}
                      stretch
                      onClick={callback2}
                      disabled={!buttonLabel2Enabled}
                    >
                      {buttonLabel2}
                    </WhiteButton>
                  </Box>
                </PaddingBox>
              </Flex>
            )}
            <Flex direction={'column'} justify={'center'} align={'flex-end'} size={0}>
              <Box width={150}>
                <BlueButton shape={'round'} size={'large'} stretch onClick={callback}>
                  {buttonLabel}
                </BlueButton>
              </Box>
            </Flex>
          </Flex>
        </StickCard>
      </StickyBoxBottom>
    </StickyBoxContainer>
  );
};
