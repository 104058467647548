import React from 'react';
import { InformationBanner as InformationBannerType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import parse from 'html-react-parser';
import { SInformationSpace } from 'components/Page/Header/AdvertisementSpace/Banner.styled';
import { theme } from 'styles';
import { MarginBox, Text } from 'UI';

export const BANNER_HEIGHT = 70;

const InformationBanner = ({ banner }: { banner: InformationBannerType }) => {
  return (
    <SInformationSpace
      background={theme.color.clear_blue}
      minHeight={BANNER_HEIGHT}
      maxHeight={BANNER_HEIGHT}
      align={'center'}
      justify={'center'}
    >
      <MarginBox ml={3} />
      <Text type={'h6_white'}>{banner.title}</Text>
      <MarginBox ml={3} mr={7}>
        <Text type={'text_landing'} disableGutter ellipsis>
          {parse(banner.description ?? '')}
        </Text>
      </MarginBox>
    </SInformationSpace>
  );
};

export default InformationBanner;
