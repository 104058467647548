import React from 'react';
import { MarginBox, MinusCircleButton, PlusCircleButton, Text } from 'UI';
import { SDescriptions, ShowMoreButtonWrapper } from 'UI/Descriptions/Descriptions.styled';
import { DescriptionText } from 'UI/Descriptions/DescriptionText';

export const INITIAL_ITEM_COUNT = 6; // 3 rows of 2 columns
export interface Item {
  label: string;
  value: string;
}

export interface DescriptionsProps {
  title: string;
  items: Item[];
  handleShowMore: () => void;
  displayShowMoreButton: boolean;
  showMore: boolean;
}

export const Descriptions = ({ title, items, displayShowMoreButton, showMore, handleShowMore }: DescriptionsProps) => {
  return (
    <>
      <MarginBox mb={25}>
        <SDescriptions title={<Text type={'light_14_bold_black_65'}>{title}</Text>} size="small" column={2} bordered>
          {items.map(({ label, value }, index) => (
            <SDescriptions.Item key={`descriptions-${index}`} label={<DescriptionText text={label} title />}>
              <DescriptionText text={value} />
            </SDescriptions.Item>
          ))}
        </SDescriptions>
      </MarginBox>
      <ShowMoreButtonWrapper align={'center'} justify={'center'} maxHeight={0}>
        {displayShowMoreButton &&
          (showMore ? (
            <MinusCircleButton onClick={handleShowMore} color={'ink_black'} />
          ) : (
            <PlusCircleButton onClick={handleShowMore} color={'ink_black'} />
          ))}
      </ShowMoreButtonWrapper>
    </>
  );
};
