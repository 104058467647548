import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowAltCircleDownIcon } from 'assets/icons';
import LanguageMenu from 'components/LanguageMenu';
import OnePortalLogo from 'components/OnePortalLogo';
import InformationBanner from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Text } from 'UI';
import { LoginSectionProps } from './LoginSection';
import {
  InformationWrapper,
  InfoWrapper,
  LoginFormWrapper,
  ScrollButton,
  ScrollButtonWrapper,
  SInnerContainer,
  SOuterContainer,
  SWrapper,
} from './LoginSection.styled';
import LoginForm from '../LoginForm';

//TODO unify with MD and SM (login form has its own implementation of logins, which is not good)
export const LoginSectionLg = ({ informationBanner, scroll, scrollToPortfolio }: LoginSectionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <SOuterContainer>
        <SInnerContainer>
          <Flex direction={'column'} align={'flex-start'}>
            <InfoWrapper>
              <OnePortalLogo />
              <MarginBox mt={115} />
              <Text type={'heading_landing'} dataCy={'rpart-store-title'}>
                {t('section.landing.intro', 'Buy car parts for your vehicle')}
              </Text>
              <Text type={'text_landing'} dataCy={'rpart-store-description'}>
                <Trans i18nKey={'section.landing.intro.description'}>
                  Revolutionizing automobile aftermarket spare parts solution. Discover Renault Group official solution
                  , developed with and for automotive professionals, in order to optimize your daily work.
                </Trans>
              </Text>
            </InfoWrapper>
          </Flex>
          <Flex direction={'column'} align={'flex-end'}>
            <Box>
              <Box height={90}>
                <Flex justify={'flex-end'}>
                  <MarginBox mt={20} mr={68}>
                    <LanguageMenu />
                  </MarginBox>
                </Flex>
              </Box>
              <LoginFormWrapper>
                <LoginForm />
              </LoginFormWrapper>
            </Box>
          </Flex>
          {scroll === 0 && (
            <ScrollButtonWrapper offset={informationBanner ? 75 : 30}>
              <ScrollButton>
                <Icon
                  onClick={scrollToPortfolio}
                  IconComponent={ArrowAltCircleDownIcon}
                  color={theme.color.grey75}
                  hoverFill={theme.color.grey95}
                  size={48}
                />
              </ScrollButton>
            </ScrollButtonWrapper>
          )}
        </SInnerContainer>
      </SOuterContainer>
      {informationBanner && (
        <InformationWrapper>
          <InformationBanner banner={informationBanner} />
        </InformationWrapper>
      )}
    </SWrapper>
  );
};
