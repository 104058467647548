import { Card } from 'antd';
import styled from 'styled-components';

export const PartsListCard = styled(Card)<{ customHeight?: number }>`
  height: ${({ customHeight }) => customHeight ?? 574}px;
  width: 330px;
  text-align: center;
  border-radius: 0;
  padding: 0;

  .ant-card-body {
    height: calc(100% - 60px);
    padding: 0;
    overflow: auto;
  }

  .ant-card-head {
    height: 60px;
    background-color: ${({ theme }) => theme.color.clear_blue};
    border-radius: 0;
  }
`;

export const ListTitle = styled.div`
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
`;

export const ListItem = styled.div<{ $hovered: boolean }>`
  width: 100%;
  min-height: 46px;
  text-align: left;
  padding: 13px 0 0 24px;
  list-style-type: none;
  border: solid 1px ${({ theme }) => theme.color.grey90};
  background-color: ${({ $hovered, theme }) => ($hovered ? theme.color.white_dim : 'white')};

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.white_dim};
  }
`;
