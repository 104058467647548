import React from 'react';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { OrderItemLocal } from 'domains/order/Order.types';
import OrderReferenceCard from 'pages/MyOrdersPage/OrderReferencesSection/OrderReferenceCard';
import { Flex, MarginBox } from 'UI';

export const OrderReferencesSection = ({
  order,
  substituteInlineInfo,
}: {
  order: OrderItemLocal | ExternalOrderItem;
  substituteInlineInfo?: boolean;
}) => {
  return (
    <>
      <MarginBox mt={15} />
      <Flex direction={'column'}>
        {order.orderedReferences.map((reference) => {
          return (
            <OrderReferenceCard
              key={reference.referenceNumber}
              reference={reference}
              order={order}
              substituteInlineInfo={substituteInlineInfo}
            />
          );
        })}
      </Flex>
    </>
  );
};
