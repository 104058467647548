import {
  SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO,
  SEND_EMAIL_FROM_GARAGE,
  SEND_EMAIL_TO_ASSISTANCE,
  SEND_RETURN_REQUEST_EMAIL,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { SendDocumentationAlertMotrio } from '@1po/1po-bff-fe-spec/generated/email/request/SendDocumentationAlertMotrio';
import { SendEmailFromGarage } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailFromGarage';
import { SendEmailToAssistance } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { ReturnEmailRequest } from '@1po/1po-bff-fe-spec/generated/order/request/ReturnEmailRequest';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendEmailFromGarageRequest(request: SendEmailFromGarage): WebSocketAction {
  return wsSendAction(SEND_EMAIL_FROM_GARAGE, request);
}

export function sendEmailReturnRequestRequest(request: ReturnEmailRequest): WebSocketAction {
  return wsSendAction(SEND_RETURN_REQUEST_EMAIL, request);
}

export function sendEmailToAssistanceRequest(request: SendEmailToAssistance): WebSocketAction {
  return wsSendAction(SEND_EMAIL_TO_ASSISTANCE, request);
}

export function sendEmailDocumentationAlertMotrio(request: SendDocumentationAlertMotrio): WebSocketAction {
  return wsSendAction(SEND_EMAIL_DOCUMENTATION_ALERT_MOTRIO, request);
}
