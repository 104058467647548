import React from 'react';
import { Trans } from 'react-i18next';
import { OrderDeliveryType } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';

export const getDeliveryTypeLabel = (deliveryType: OrderDeliveryType) => {
  switch (deliveryType) {
    case 'DEALER_PICKUP':
      return <Trans i18nKey={'order.delivery_type.dealer_pickup'}>Dealer pickup</Trans>;
    case 'OWN_ADDRESS':
      return <Trans i18nKey={'order.delivery_type.delivery_to_address'}>Delivery to address</Trans>;
    default:
      return 'unknown';
  }
};
