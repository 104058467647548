import { Moment } from 'moment';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA } from 'utils';

export const SIGNATURE_NAMESPACE = `${APP_NAMESPACE}/SIGNATURE`;

export const GET_URL_SIGNATURE = `${SIGNATURE_NAMESPACE}/GET_URL_SIGNATURE_REQUEST`;

export interface UrlSignaturesState {
  datahubUrlSignature: string | NO_DATA;
  datahubSignatureExpiration: Moment | undefined;
}
