import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getDHReference } from 'domains/references';
import ReferenceCard from 'pages/CatalogPage/DH/Product/ProductMenu/ReferenceCard';
import { SubstituteReferenceItemProps } from 'pages/CatalogPage/DH/Product/ProductMenu/SubstituteReferenceItem';
import { getData, hasData } from 'utils';

const ReplacingReferenceItem = ({ referenceNumber, sparePartsView }: SubstituteReferenceItemProps) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const reference = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));
  const replacingReferences = getData(reference)?.replacingReferences?.map((ref) => ref);

  if (!replacingReferences || !hasData(reference)) {
    return null;
  }
  const replacingReferencesSorted = replacingReferences.sort((a, b) =>
    a.referenceNumber > b.referenceNumber ? 1 : -1,
  );
  const bestReplacingIndex = replacingReferencesSorted.findIndex(
    (element) => element.referenceNumber === reference.bestReplacingReference,
  );
  if (bestReplacingIndex > -1) {
    replacingReferencesSorted.unshift(replacingReferencesSorted.splice(bestReplacingIndex, 1)[0]);
  }

  return (
    <>
      {replacingReferencesSorted.map((subRef, index) => (
        <ReferenceCard
          key={index}
          referenceNumber={subRef.referenceNumber}
          sparePartsView={sparePartsView}
          isBestSupersession={reference.bestReplacingReference === subRef.referenceNumber}
        />
      ))}
    </>
  );
};

export default ReplacingReferenceItem;
