import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserCommercialLink, getUserSellersToChoose } from 'domains/user';
import { useFetchSellers } from 'domains/user/User.requests';
import { CenteredSpin, Grid, MarginBox, Text } from 'UI';
import { getData } from 'utils';
import { useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';
import SellerCard from './SellerCard';
import { DataContainer } from '../DataContainer';

const SellerSettings = () => {
  const { t } = useTranslation();
  const commercialLink = useSelector(getUserCommercialLink);
  const currentSeller = commercialLink?.seller;
  const sellersToChooseFrom = useSelector(getUserSellersToChoose);
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const [selectedSeller, setSelectedSeller] = useState(currentSeller);

  useFetchSellers();

  return (
    <>
      <MarginBox mt={60} ml={90}>
        <Text type={'h1'}>
          <strong>{t('profile.seller_selection', 'Please select your seller:')}</strong>
        </Text>
      </MarginBox>
      <MarginBox mt={60 - 24} ml={90}>
        <DataContainer data={sellersToChooseFrom} Loading={() => <CenteredSpin size={'large'} />}>
          <Grid
            columns={breakpointSelectorFull({
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 3,
            })}
          >
            {getData(sellersToChooseFrom)?.map((seller) => (
              <SellerCard
                key={seller.id}
                seller={seller}
                activeSeller={selectedSeller?.id === seller.id}
                setSelectedSeller={setSelectedSeller}
              />
            ))}
          </Grid>
        </DataContainer>
      </MarginBox>
      <MarginBox mt={500} />
    </>
  );
};

export default SellerSettings;
