/* eslint-disable max-len */
import { CurrentPromotionsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/CurrentPromotionsResponse';
import { DashboardPromotionsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/DashboardPromotionsResponse';
import { FullPromotionResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/FullPromotionResponse';
import { NumberOfNotYetAdjustedPromotionsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/NumberOfNotYetAdjustedPromotionsResponse';
import { OperationPromotionResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/OperationPromotionResponse';
import { PromotionBannersResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionBannersResponse';
import { PromotionBannersUpdateResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionBannersUpdateResponse';
import { PromotionReferencesFileUploadResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionReferencesFileUploadResponse';
import { PromotionResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionResponse';
import { PromotionTargetedAudienceFileUploadResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/PromotionTargetedAudienceFileUploadResponse';
import { ValidateProductsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/response/ValidateProductsResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { matchRoute, ROUTER_BACKOFFICE } from 'app/AppRouter';
import { redirect, RootState } from 'app/AppStore';
import {
  sendAdjustPromotionByR1Request,
  sendArchivePromotionRequest,
  sendDeletePromotionRequest,
  sendGetCountryAdminPromotionsRequest,
  sendGetCountryForR1PromotionsRequest,
  sendGetCurrentPromotionsRequest,
  sendGetDashboardPromotionsRequest,
  sendGetFullPromotionRequest,
  sendGetPromotionBannersRequest,
  sendGetPromotionRequest,
  sendGetR1PromotionsForCountryAdminRequest,
  sendGetR1UserPromotionsRequest,
  sendSaveBannerPromotionRequest,
  sendSaveDiscountPromotionRequest,
  sendSaveFlashQuantityLimitedPromotionRequest,
  sendSaveFlashTimeLimitedPromotionRequest,
  sendSubscribeCurrentPromotionsRequest,
  sendSubscribeToNumberOfNotYetAdjustedPromotionsRequest,
  sendUploadReferencesFileRequest,
  sendUploadTargetedAudienceRequest,
  sendValidateProducts,
} from 'domains/promotion/Promotion.api';
import * as actions from 'domains/promotion/Promotion.store';
import {
  findStatusTypeByPromotionID,
  getCreatePromotion,
  reloadCurrentPromotions,
  reloadPromotionBanners,
  removePromotionFromDashboard,
  resetLoadedPromotions,
  setCreatePromotionAlreadyPromotedCommercialFamilyCodes,
  setCreatePromotionAlreadyPromotedQuantityLimitedProduct,
  setCurrentPromotions,
  setCurrentPromotionsDataStatus,
  setDuplicatePromotionIds,
  setDuplicatePromotions,
  setFullPromotion,
  setFullPromotionState,
  setLoadedPromotions,
  setLoadedPromotionsSearchStatus,
  setPromotion,
  setPromotionBanners,
  setPromotionBannersStatus,
  setPromotionDetailDataStatus,
  setPromotionNotificationNumber,
  setPromotionUpdateBanners,
  setPublishedPromotionStatus,
  setUploadedReferencesFromFileRows,
  setUploadedTargetedAudienceFromFileRows,
  setValidatedReferencesForFileRows,
  unsetCurrentPromotion,
  unsetCurrentPromotions,
  unsetPromotionBanner,
  unsetSavedAndPlannedPromotion,
} from 'domains/promotion/Promotion.store';
import { COPY, EDIT, PROMOTION } from 'pages/BackOfficePage/BackOfficePage.type';
import { notifyNotImplemented, notifyTop } from 'UI/Notification/notification';
import { AppTranslation, FOUND, LOADING, sagaGuard, select } from 'utils';
import {
  toBannerMessage,
  toDiscountMessage,
  toFlashQuantityLimitedMessage,
  toFlashTimeLimitedMessage,
  toValidateFileMessage,
  toValidateProductsMessage,
} from './Promotion.mapper';
import { getTradingProfile, getUserContext, getUserCountry, getUserRights, UserRole } from '../user';

function* fetchSavePromotionRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchSavePromotionRequestSaga>): SagaIterator {
  let promotionToSave = yield* select(getCreatePromotion);

  const commercialFamilyCodes = promotionToSave.commercialFamilyCodes?.map((com) => {
    return { ...com, items: com.items.filter((i) => i.item.length > 0) };
  });

  const image = promotionToSave.containsCustomImage ? promotionToSave.image : undefined;
  promotionToSave = {
    ...promotionToSave,
    commercialFamilyCodes,
    image,
  };

  switch (promotionToSave.promotionType) {
    case 'DISCOUNT': {
      const promotionDiscount = toDiscountMessage(promotionToSave, payload.shouldPublish);
      yield put(sendSaveDiscountPromotionRequest(promotionDiscount));
      break;
    }
    case 'PROMOTION_FLASH_TIME_LIMITED': {
      const promotionFlashTimeLimited = toFlashTimeLimitedMessage(promotionToSave, payload.shouldPublish);
      yield put(sendSaveFlashTimeLimitedPromotionRequest(promotionFlashTimeLimited));
      break;
    }
    case 'PROMOTION_FLASH_QUANTITY_LIMITED': {
      const promotionFlashQuantityLimited = toFlashQuantityLimitedMessage(promotionToSave, payload.shouldPublish);
      yield put(sendSaveFlashQuantityLimitedPromotionRequest(promotionFlashQuantityLimited));
      break;
    }
    case 'BANNER': {
      const promotionBanner = toBannerMessage(promotionToSave, payload.shouldPublish);
      yield put(sendSaveBannerPromotionRequest(promotionBanner));
      break;
    }
    default: {
      yield call(notifyNotImplemented);
      return;
    }
  }
  if (payload.shouldPublish) {
    yield put(setPublishedPromotionStatus({ id: promotionToSave.promotionId, status: LOADING }));
  }
}

function* handleSavePromotionResponseSaga(action: WsResponse<OperationPromotionResponse>): SagaIterator {
  const { promotionId, result } = action.payload;
  if (result === 'SAVED') {
    yield call(
      notifyTop,
      'success',
      AppTranslation.t('backoffice.promotion.save.success', 'The promotion has been saved') as string,
      null,
    );
  }
  const statusType = yield* select((state: RootState) => findStatusTypeByPromotionID(state, promotionId));

  yield put(resetLoadedPromotions(statusType !== undefined ? statusType : 'SAVED'));
  if (statusType === 'PUBLISHED') {
    yield put(reloadPromotionBanners());
    yield put(reloadCurrentPromotions());
  }

  if (matchRoute(location.pathname, `${ROUTER_BACKOFFICE}/${PROMOTION}/${COPY}`)) {
    yield call(redirect, `${ROUTER_BACKOFFICE}/${PROMOTION}/${EDIT}/${promotionId}`);
  }
}

function* handlePublishPromotionResponseSaga(action: WsResponse<OperationPromotionResponse>): SagaIterator {
  const { result, promotionId } = action.payload;
  const id = promotionId.substring(promotionId.lastIndexOf('_') + 1);
  yield put(setPublishedPromotionStatus({ id, status: result === 'PUBLISHED' }));
  yield put(resetLoadedPromotions('PUBLISHED'));
  yield put(resetLoadedPromotions('PLANNED'));
}

function* adjustPromotionByR1RequestSaga({
  payload,
}: ReturnType<typeof actions.adjustPromotionByR1RequestSaga>): SagaIterator {
  yield put(sendAdjustPromotionByR1Request(payload));
  yield put(setPublishedPromotionStatus({ id: payload.promotionId, status: LOADING }));
}

function* fetchAdjustPromotionByR1ResponseSaga({
  payload,
}: ReturnType<typeof actions.fetchAdjustPromotionByR1ResponseSaga>): SagaIterator {
  yield put(setPublishedPromotionStatus({ id: payload.promotionId, status: payload.result }));
  if (payload.result) {
    yield call(
      notifyTop,
      'success',
      AppTranslation.t('backoffice.promotion.save.success', 'The promotion has been saved') as string,
      null,
    );
  } else {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t('backoffice.promotion.save.error', 'The promotion has not been saved') as string,
      null,
    );
  }
}

function* fetchPromotionArchiveRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchArchivePromotionRequestSaga>): SagaIterator {
  yield put(sendArchivePromotionRequest(payload));
}

function* fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga>): SagaIterator {
  yield put(setPromotionNotificationNumber(0));
  yield put(sendSubscribeToNumberOfNotYetAdjustedPromotionsRequest(payload));
}

function* fetchPromotionNotificationResponseSaga(
  action: WsResponse<NumberOfNotYetAdjustedPromotionsResponse>,
): SagaIterator {
  const { unadjustedPromoCount } = action.payload;
  yield put(setPromotionNotificationNumber(unadjustedPromoCount));
}

function* handleArchivePromotionResponseSaga(action: WsResponse<OperationPromotionResponse>): SagaIterator {
  const { result, promotionId } = action.payload;
  if (result === 'ARCHIVED') {
    const id = promotionId.substring(promotionId.lastIndexOf('_') + 1);
    yield put(unsetPromotionBanner(id));
    yield put(unsetCurrentPromotion(id));
    yield put(resetLoadedPromotions('ARCHIVED'));
    yield put(removePromotionFromDashboard({ status: 'PUBLISHED', promotionId: id }));
    yield call(
      notifyTop,
      'success',
      AppTranslation.t(
        'backoffice.promotion.stop_publication.success',
        'Publication of the promotion has been stopped.',
      ) as string,
      null,
    );
  } else {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t(
        'backoffice.promotion.stop_publication.error',
        'Publication of the promotion has not been stopped.',
      ) as string,
      null,
    );
  }
}

function* deletePromotionRequestSaga({ payload }: ReturnType<typeof actions.deletePromotionRequestSaga>): SagaIterator {
  yield put(sendDeletePromotionRequest(payload));
}

function* handleDeletePromotionResponseSaga(action: WsResponse<OperationPromotionResponse>): SagaIterator {
  const { result } = action.payload;
  if (result === 'DELETED') {
    yield call(
      notifyTop,
      'success',
      AppTranslation.t('backoffice.promotion.delete.success', 'Promotion has been deleted.') as string,
      null,
    );
  } else {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t('backoffice.promotion.delete.error', 'Deleting of promotion failed.') as string,
      null,
    );
  }
}

function* handleDeletePromotionSubscriptionResponseSaga(action: WsResponse<OperationPromotionResponse>): SagaIterator {
  const { result, promotionId } = action.payload;
  if (result === 'DELETED') {
    const id = promotionId.substring(promotionId.lastIndexOf('_') + 1);
    yield put(unsetSavedAndPlannedPromotion(id));
  }
}

function* fetchPromotionRequestSaga({ payload }: ReturnType<typeof actions.fetchPromotionRequestSaga>): SagaIterator {
  if (!payload.promotionId) return;
  yield put(setPromotionDetailDataStatus({ id: payload.promotionId, status: LOADING }));
  yield put(sendGetPromotionRequest(payload));
}

function* fetchPromotionResponseSaga(action: WsResponse<PromotionResponse>): SagaIterator {
  if ('created' in action.payload) return;
  const { promotion } = action.payload;
  yield put(setPromotion({ promotion }));
}

function* fetchCurrentPromotionsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchCurrentPromotionsRequestSaga>): SagaIterator {
  yield put(setCurrentPromotionsDataStatus({ status: LOADING }));
  yield put(sendGetCurrentPromotionsRequest(payload));
}

function* fetchCurrentPromotionsResponseSaga(action: WsResponse<CurrentPromotionsResponse>): SagaIterator {
  const { promotions } = action.payload;
  yield put(setCurrentPromotionsDataStatus({ status: FOUND }));
  if (promotions && promotions.length === 0) {
    yield put(unsetCurrentPromotions());
  } else {
    yield put(setCurrentPromotions(action.payload));
  }
}

function* subscribeCurrentPromotionsRequestSaga(): SagaIterator {
  const userContext = yield* select(getUserContext);
  yield put(sendSubscribeCurrentPromotionsRequest({ userContext }));
}

function* fetchUpdateCurrentPromotionsResponseSaga({
  payload,
}: ReturnType<typeof actions.fetchUpdateCurrentPromotionsResponseSaga>): SagaIterator {
  const { shouldUpdate } = payload;
  if (shouldUpdate) {
    yield put(unsetCurrentPromotions());
    yield put(actions.fetchCurrentPromotionsRequestSaga({}));
  }
}

function* fetchFullPromotionRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchFullPromotionRequestSaga>): SagaIterator {
  if (!payload.promotionId) return;
  yield put(setFullPromotionState({ id: payload.promotionId, status: LOADING }));
  yield put(sendGetFullPromotionRequest(payload));
}

function* fetchFullPromotionResponseSaga(action: WsResponse<FullPromotionResponse>): SagaIterator {
  if ('created' in action.payload) return;
  const { promotion } = action.payload;
  yield put(setFullPromotion({ promotion }));

  const statusType = yield* select((state: RootState) => findStatusTypeByPromotionID(state, promotion.promotionId));
  if (statusType && statusType !== promotion.status) {
    yield put(resetLoadedPromotions(promotion.status));
    yield put(removePromotionFromDashboard({ status: statusType, promotionId: promotion.promotionId }));
  }
}

function* fetchPromotionBannersRequestSaga(): SagaIterator {
  yield put(setPromotionBannersStatus(LOADING));
  yield put(sendGetPromotionBannersRequest());
}

function* fetchPromotionBannersResponseSaga(action: WsResponse<PromotionBannersResponse>): SagaIterator {
  yield put(setPromotionBanners(action.payload));
}

function* fetchPromotionBannersUpdateResponseSaga(action: WsResponse<PromotionBannersUpdateResponse>): SagaIterator {
  yield put(setPromotionUpdateBanners(action.payload));
}

function* uploadReferencesFileSaga({ payload }: ReturnType<typeof actions.uploadReferencesFileSaga>): SagaIterator {
  const { fileId, fileName, fileBase64, promotion } = payload;
  const message = toValidateFileMessage({ fileId, fileName, fileBase64, promotion });
  if (message) {
    yield put(sendUploadReferencesFileRequest(message));
  }
}

function* fetchReferencesFileUploadResponseSaga(
  action: WsResponse<PromotionReferencesFileUploadResponse>,
): SagaIterator {
  const { fileId, duplicatePromotions, correctRows, duplicateCodes, duplicates } = action.payload;
  yield put(setUploadedReferencesFromFileRows({ fileId, duplicates, correctRows }));
  yield put(setCreatePromotionAlreadyPromotedCommercialFamilyCodes(duplicateCodes ?? []));
  yield put(setDuplicatePromotions({ duplicatePromotions: duplicatePromotions ?? [] }));
  yield put(setDuplicatePromotionIds({ duplicatePromotionIds: duplicatePromotions?.map((p) => p.promotionId) ?? [] }));
  yield call(
    notifyTop,
    'success',
    AppTranslation.t('common.file_upload.success', 'Your file was successfully uploaded.') as string,
    null,
  );
}

function* validateProductsRequestSaga({
  payload,
}: ReturnType<typeof actions.validateProductsRequestSaga>): SagaIterator {
  const { promotion } = payload;
  const message = toValidateProductsMessage({ promotion });
  if (message) {
    yield put(sendValidateProducts(message));
  }
}

function* fetchProductsValidationResponseSaga(action: WsResponse<ValidateProductsResponse>): SagaIterator {
  const { promotionId, duplicateCodes, duplicateReferences, duplicatePromotions } = action.payload;
  const createPromotion = yield* select(getCreatePromotion);
  if (createPromotion.promotionId === promotionId) {
    yield put(setCreatePromotionAlreadyPromotedCommercialFamilyCodes(duplicateCodes ?? []));
    yield put(setDuplicatePromotions({ duplicatePromotions: duplicatePromotions ?? [] }));
    yield put(
      setDuplicatePromotionIds({ duplicatePromotionIds: duplicatePromotions?.map((p) => p.promotionId) ?? [] }),
    );
    if (createPromotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED') {
      yield put(
        setCreatePromotionAlreadyPromotedQuantityLimitedProduct({
          duplicate: duplicateReferences && duplicateReferences.length > 0 ? duplicateReferences[0] : undefined,
        }),
      );
    } else {
      yield put(setValidatedReferencesForFileRows({ duplicates: duplicateReferences ?? [] }));
    }
  }
}

function* uploadTargetedAudienceFileSaga({
  payload,
}: ReturnType<typeof actions.uploadReferencesFileSaga>): SagaIterator {
  yield put(sendUploadTargetedAudienceRequest(payload));
}

function* fetchTargetedAudienceFileUploadResponseSaga(
  action: WsResponse<PromotionTargetedAudienceFileUploadResponse>,
): SagaIterator {
  yield put(setUploadedTargetedAudienceFromFileRows(action.payload));
  yield call(
    notifyTop,
    'success',
    AppTranslation.t('common.file_upload.success', 'Your file was successfully uploaded.') as string,
    null,
  );
}

function* fetchDashboardPromotionsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchDashboardPromotionsRequestSaga>): SagaIterator {
  const { getPromotions, activeFilters, promotionFilterTypes } = payload;
  yield put(setLoadedPromotionsSearchStatus({ statusType: getPromotions.status, searchStatus: LOADING }));
  const userRights = yield* select(getUserRights);
  const userCountry = yield* select(getUserCountry);
  const tradingProfile = yield* select(getTradingProfile);

  let r1PromotionsRequestPayload;
  let countryAdminRequestPayload;

  if (
    userRights.includes(UserRole.R1) &&
    !userRights.includes(UserRole.COUNTRY_ADMIN) &&
    tradingProfile?.buyerId !== undefined
  ) {
    r1PromotionsRequestPayload = {
      ...getPromotions,
      dealerId: tradingProfile.buyerId,
      cursor: getPromotions.cursor,
      promotionFilterTypes,
    };
  }
  const countryForR1RequestPayload = {
    ...getPromotions,
    country: userCountry ?? '',
    dealerId: tradingProfile?.buyerId,
    promotionFilterTypes,
  };

  if (userRights.includes(UserRole.COUNTRY_ADMIN) && tradingProfile?.buyerId !== undefined) {
    countryAdminRequestPayload = {
      ...getPromotions,
      dealerId: tradingProfile.buyerId,
      cursor: getPromotions.cursor,
      country: userCountry ?? '',
      promotionFilterTypes,
    };
  }

  if (
    activeFilters.includes('myPromotions') &&
    (activeFilters.includes('country') || activeFilters.includes('myDealers'))
  ) {
    yield put(
      sendGetDashboardPromotionsRequest({
        ...getPromotions,
        cursor: getPromotions.cursor,
        promotionFilterTypes,
      }),
    );
  } else if (activeFilters.length && activeFilters.every((f) => f === 'country')) {
    if (userRights.includes(UserRole.R1) && userCountry) {
      yield put(sendGetCountryForR1PromotionsRequest(countryForR1RequestPayload));
    }
  } else if (
    userRights.includes(UserRole.COUNTRY_ADMIN) &&
    activeFilters.length &&
    activeFilters.every((f) => f === 'myPromotions') &&
    countryAdminRequestPayload
  ) {
    yield put(sendGetCountryAdminPromotionsRequest(countryAdminRequestPayload));
  } else if (
    userRights.includes(UserRole.COUNTRY_ADMIN) &&
    activeFilters.length &&
    activeFilters.every((f) => f === 'myDealers') &&
    countryAdminRequestPayload
  ) {
    yield put(sendGetR1PromotionsForCountryAdminRequest(countryAdminRequestPayload));
  } else if (userRights.includes(UserRole.R1) && r1PromotionsRequestPayload) {
    yield put(sendGetR1UserPromotionsRequest(r1PromotionsRequestPayload));
  }
}

function* handleDashboardPromotionsResponseSaga(action: WsResponse<DashboardPromotionsResponse>): SagaIterator {
  yield put(setLoadedPromotions(action.payload));
}

export function* PromotionSagas(): SagaIterator {
  yield takeEvery(actions.fetchSavePromotionRequestSaga.type, sagaGuard(fetchSavePromotionRequestSaga));
  yield takeEvery(actions.handleSavePromotionResponseSaga.type, sagaGuard(handleSavePromotionResponseSaga));
  yield takeEvery(actions.handlePublishPromotionResponseSaga.type, sagaGuard(handlePublishPromotionResponseSaga));
  yield takeEvery(actions.fetchArchivePromotionRequestSaga.type, sagaGuard(fetchPromotionArchiveRequestSaga));
  yield takeEvery(actions.handleArchivePromotionResponseSaga.type, sagaGuard(handleArchivePromotionResponseSaga));
  yield takeEvery(actions.deletePromotionRequestSaga.type, sagaGuard(deletePromotionRequestSaga));
  yield takeEvery(actions.handledDeletePromotionResponseSaga.type, sagaGuard(handleDeletePromotionResponseSaga));
  yield takeEvery(
    actions.handleDeletePromotionSubscriptionResponseSaga.type,
    sagaGuard(handleDeletePromotionSubscriptionResponseSaga),
  );
  yield takeEvery(actions.adjustPromotionByR1RequestSaga.type, sagaGuard(adjustPromotionByR1RequestSaga));
  yield takeEvery(actions.fetchAdjustPromotionByR1ResponseSaga.type, sagaGuard(fetchAdjustPromotionByR1ResponseSaga));
  yield takeEvery(actions.subscribeCurrentPromotionsRequestSaga.type, sagaGuard(subscribeCurrentPromotionsRequestSaga));
  yield takeEvery(
    actions.fetchUpdateCurrentPromotionsResponseSaga.type,
    sagaGuard(fetchUpdateCurrentPromotionsResponseSaga),
  );
  yield takeEvery(actions.fetchPromotionRequestSaga.type, sagaGuard(fetchPromotionRequestSaga));
  yield takeEvery(actions.fetchPromotionResponseSaga.type, sagaGuard(fetchPromotionResponseSaga));
  yield takeEvery(actions.fetchCurrentPromotionsRequestSaga.type, sagaGuard(fetchCurrentPromotionsRequestSaga));
  yield takeEvery(actions.fetchCurrentPromotionsResponseSaga.type, sagaGuard(fetchCurrentPromotionsResponseSaga));
  yield takeEvery(actions.fetchFullPromotionRequestSaga.type, sagaGuard(fetchFullPromotionRequestSaga));
  yield takeEvery(actions.fetchFullPromotionResponseSaga.type, sagaGuard(fetchFullPromotionResponseSaga));
  yield takeEvery(actions.fetchPromotionBannersRequestSaga.type, sagaGuard(fetchPromotionBannersRequestSaga));
  yield takeEvery(actions.fetchPromotionBannersResponseSaga.type, sagaGuard(fetchPromotionBannersResponseSaga));
  yield takeEvery(
    actions.fetchPromotionBannersUpdateResponseSaga.type,
    sagaGuard(fetchPromotionBannersUpdateResponseSaga),
  );
  yield takeEvery(actions.uploadReferencesFileSaga.type, sagaGuard(uploadReferencesFileSaga));
  yield takeEvery(actions.fetchReferencesFileUploadResponseSaga.type, sagaGuard(fetchReferencesFileUploadResponseSaga));
  yield takeEvery(actions.validateProductsRequestSaga.type, sagaGuard(validateProductsRequestSaga));
  yield takeEvery(actions.fetchProductsValidationResponseSaga.type, sagaGuard(fetchProductsValidationResponseSaga));
  yield takeEvery(actions.uploadTargetedAudienceFileSaga.type, sagaGuard(uploadTargetedAudienceFileSaga));
  yield takeEvery(
    actions.fetchTargetedAudienceFileUploadResponseSaga.type,
    sagaGuard(fetchTargetedAudienceFileUploadResponseSaga),
  );
  yield takeEvery(actions.fetchDashboardPromotionsRequestSaga.type, sagaGuard(fetchDashboardPromotionsRequestSaga));
  yield takeEvery(actions.handleDashboardPromotionsResponseSaga.type, sagaGuard(handleDashboardPromotionsResponseSaga));
  yield takeEvery(
    actions.fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga.type,
    sagaGuard(fetchSubscribeToNumberOfNotYetAdjustedPromotionsRequestSaga),
  );
  yield takeEvery(
    actions.fetchPromotionNotificationResponseSaga.type,
    sagaGuard(fetchPromotionNotificationResponseSaga),
  );
}
