import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { RootState } from 'app/AppStore';
import { getPricesMap, getStocksMap, IAMReferenceLocal, ReferencePriceType } from 'domains/references';
import {
  sortStocksBySearchStatus,
  sortStocksByWarehouse,
  stocksMapToArray,
} from 'domains/references/References.mapper';
import { isLoading, NO_DATA, NOT_FOUND, SearchData } from 'utils';

function sortIAMReferencesByReferenceNumbers(
  references: IAMReferenceLocal[],
  referenceNumbers: string[],
): IAMReferenceLocal[] {
  return references.sort(
    (a, b) => referenceNumbers.indexOf(a.referenceNumber) - referenceNumbers.indexOf(b.referenceNumber),
  );
}

export function sortIAMReferences(
  references: IAMReferenceLocal[],
  stockData: Map<string, SearchData<ReferenceStock> | undefined>,
): IAMReferenceLocal[] {
  const filteredStockData: Map<string, SearchData<ReferenceStock> | undefined> = new Map(
    [...stockData].filter(([k]) => references.find((r) => r.referenceNumber === k)),
  );
  const stocksArray = stocksMapToArray(filteredStockData);
  const sortedStocks = [...stocksArray].sort(sortStocksBySearchStatus).sort(sortStocksByWarehouse);

  return sortIAMReferencesByReferenceNumbers(
    references,
    sortedStocks.map((stock) => stock.data.reference),
  );
}

export const getFilteredMotrioReferences = (
  refs: IAMReferenceLocal[],
  prices: Map<string, NO_DATA | ReferencePriceType>,
  stocks: Map<string, SearchData<ReferenceStock> | undefined>,
): IAMReferenceLocal[] => {
  const filteredPrices: Map<string, NO_DATA | ReferencePriceType> = new Map(
    [...prices].filter(([k]) => refs.find((r) => r.referenceNumber === k)),
  );
  const filteredStocks: Map<string, SearchData<ReferenceStock> | undefined> = new Map(
    [...stocks].filter(([k]) => refs.find((r) => r.referenceNumber === k)),
  );
  const motrioReferences = refs.filter((r) => r.supplier === 'MOTRIO');
  return motrioReferences.filter((ref) => {
    return shouldDisplayIAMReference(
      ref,
      filteredPrices.get(ref.referenceNumber),
      filteredStocks.get(ref.referenceNumber),
    );
  });
};

export const getFilteredOtherBrandsReferences = (refs: IAMReferenceLocal[]): IAMReferenceLocal[] => {
  return refs.filter((r) => r.supplier !== 'MOTRIO');
};

export function shouldDisplayIAMReference(
  reference: IAMReferenceLocal,
  price: NO_DATA | ReferencePriceType,
  stock: SearchData<ReferenceStock> | undefined,
): boolean {
  if (reference.supplier === 'MOTRIO') {
    return stock?.searchStatus !== NOT_FOUND && price !== NOT_FOUND;
  }
  return true;
}

export const useMotrioStockPricesLoading = (referenceNumbers: string[]): boolean => {
  const motrioPrices = useSelector((state: RootState) => getPricesMap(state, referenceNumbers ?? []));
  const motrioStocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers ?? []));

  const motrioStocksPrices = useMemo(() => {
    return [...motrioPrices.values(), ...[...motrioStocks.values()].map((s) => s?.searchStatus)];
  }, [motrioPrices, motrioStocks]);

  return motrioStocksPrices.some((sp) => isLoading(sp));
};
