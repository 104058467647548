import React from 'react';
import { Switch as AntSwitch } from 'antd-v5';

export interface SwitchProps {
  checked?: boolean;
  onChange?: (x: boolean) => void;
  size?: 'small' | 'default';
  disabled?: boolean;
  dataCy?: string;
}

export const Switch = ({ checked, onChange, size, disabled, dataCy }: SwitchProps) => {
  return <AntSwitch checked={checked} onChange={onChange} size={size} disabled={disabled} data-cy={dataCy} />;
};
