import { GET_PICTURE_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { PictureResponse } from '@1po/1po-bff-fe-spec/generated/picture/PictureResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { GET_BASE64_IMAGE_FROM_URL, PICTURES_NAMESPACE, PicturesState } from 'domains/pictures/Pictures.types';
import { LOADING, NO_DATA, NOT_FOUND } from 'utils';

// Init state
const initialState: PicturesState = {
  pictures: new Map<string, string | NO_DATA>(),
};

// Saga actions
export const fetchPictureResponseSaga = createAction(GET_PICTURE_RESPONSE);
export const fetchBase64FromUrlRequestSaga = createAction<{ url: string }>(GET_BASE64_IMAGE_FROM_URL);

// Slice
const slice = createSlice({
  name: PICTURES_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setPicture: (state, { payload }: PayloadAction<PictureResponse>) => {
      state.pictures.set(payload.key, payload.imageBase64);
    },
    setPictureLoading: (state, { payload }: PayloadAction<string>) => {
      if (!state.pictures.has(payload)) {
        state.pictures.set(payload, LOADING);
      }
    },
    setPicturesLoading: (state, { payload }: PayloadAction<string[]>) => {
      payload.forEach((key) => {
        if (!state.pictures.has(key)) {
          state.pictures.set(key, LOADING);
        }
      });
    },
    setPictureNoDataStatus: (state, { payload }: PayloadAction<{ key: string; status: NO_DATA }>) => {
      state.pictures.set(payload.key, payload.status);
    },
  },
});

// Actions
export const {
  setInitialState,
  setPicture,
  setPictureLoading,
  setPicturesLoading,
  setPictureNoDataStatus,
} = slice.actions;

// Getters/Selectors
export const getPicture = createSelector(
  (state: RootState, id: string | undefined) => (id ? state.pictures.pictures.get(id) : undefined),
  (picture) => (picture !== NOT_FOUND ? picture : undefined),
  {
    memoizeOptions: {
      maxSize: 50,
    },
  },
);

export const getPictures = createSelector(
  (state: RootState) => state.pictures.pictures,
  (_state: RootState, ids: string[]) => ids,
  (pictures, ids) => {
    return ids.reduce((acc, next) => acc.set(next, pictures.get(next)), new Map<string, NO_DATA | string>());
  },
);

// Export reducer
export default slice.reducer;
