import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const activeCss = css`
  border-top: solid 3px ${theme.color.blue};
  background-color: ${theme.color.white};
  box-shadow: 0 1px 0 ${theme.color.white};
`;

export const MenuItemBox = styled(Flex)<{ isActive: boolean }>`
  padding: 0 32px 0 32px;

  ${({ isActive }) => isActive && activeCss};

  :hover {
    cursor: pointer;
    background-color: ${theme.color.white};
  }
`;

export const Header = styled(Flex)`
  border-bottom: solid 1px ${theme.color.grey85};
`;
