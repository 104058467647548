import styled from 'styled-components';
import { CleverInput } from 'UI';

export const SCleverInput = styled(CleverInput)`
  max-width: 160px;
`;

export const BrandCheckBox = styled.div<{ $isSelected?: boolean }>`
  width: 160px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.color.shadow_2};
  ${({ theme, $isSelected }) => $isSelected && `border: solid 4px ${theme.color.info_dark};`};
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }
`;
