import React from 'react';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/basket/response/model/VehicleSection';
import { ArrowUpRightIcon, CarIcon, ShoppingCartIcon, WrenchIcon } from 'assets/icons';
import { ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import {
  ReferencePicBackground,
  SectionPicBackground,
} from 'pages/CartPage/ValidationStep/VerificationCard/VerificationCard.styled';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { theme } from 'styles';
import { Icon, Image, URL } from 'UI';

const FallbackImage = () => (
  <ReferencePicBackground showBackground>
    <Icon IconComponent={WrenchIcon} size={50} color={theme.color.grey75} noPointer />
  </ReferencePicBackground>
);

export const ReferenceImage = ({
  reference,
  vehicleDetail,
}: {
  reference: ReferenceLocal | undefined;
  vehicleDetail: VehicleDetail | undefined;
}) => {
  if (reference?.imageUrl) {
    return (
      <Image
        alt={'checkout-reference-image'}
        maxWidth={128}
        maxHeight={94}
        src={reference.imageUrl}
        type={URL}
        catalogSource={reference.catalogSource}
        fallbackComponent={<FallbackImage />}
      />
    );
  }
  return (
    <BrandImage
      referenceBrand={reference?.brand}
      vehicleBrand={vehicleDetail?.vehicleBrand}
      width={128}
      height={94}
      noPointer
      fallbackComponent={<FallbackImage />}
    />
  );
};

export const SectionImage = ({
  vehicle,
  imageUrl,
  externalBasketId,
}: {
  vehicle: VehicleLocal | undefined;
  imageUrl: string | undefined;
  externalBasketId: string | undefined;
}) => {
  if (vehicle) {
    return (
      <Image
        src={imageUrl}
        type={URL}
        maxWidth={128}
        maxHeight={65}
        alt={`carPic_${vehicle?.vehicleDetail.vehicleKey}`}
        catalogSource={vehicle.vehicleDetail.catalogSource}
        fallbackComponent={
          <SectionPicBackground showBackground>
            <Icon IconComponent={CarIcon} size={30} color={theme.color.grey100} noPointer />
          </SectionPicBackground>
        }
      />
    );
  }
  return (
    <Icon
      IconComponent={externalBasketId ? ArrowUpRightIcon : ShoppingCartIcon}
      size={30}
      color={theme.color.grey100}
      noPointer
    />
  );
};
