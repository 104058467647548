/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { ReferenceField as TireReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateTire';
import { Tire } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Tire';
import { RootState } from 'app/AppStore';
import { UnlinkIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import {
  getCatalogTires,
  getPendingReferenceValidations,
  getTires,
  removeKnownReferenceValidation,
  removeTire,
  updateTire,
} from 'domains/estimate/Estimate.store';
import { DHReferenceLocal } from 'domains/references';
import { useFetchDiscounts } from 'domains/references/References.requests';
import { ClientView, getCurrency } from 'domains/user';
import { EstimateGarageMargin } from 'pages/EstimatePage/TableSection/EstimateGarageMargin';
import { EstimateGaragePrice } from 'pages/EstimatePage/TableSection/EstimateGaragePrice';
import { EstimateMarginAlert } from 'pages/EstimatePage/TableSection/EstimateMarginAlert';
import {
  DiscountInput,
  EstimatePriceInput,
  GetTitle,
  QuantityInput,
  TextInput,
  VatInput,
} from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';
import { getCondArrayItem, NO_DATA } from 'utils';
import { textFormatter } from 'utils/format';
import { STable, TooltipContent } from './TableSection.styled';
/* eslint-enable max-len */

export default function TireTable({ estimateId, sparePartsView }: Readonly<TableProps>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const data = useSelector((state: RootState) => getTires(state, estimateId));
  const catalogTires = useSelector((state: RootState) => getCatalogTires(state, estimateId));
  const validationRefs = useSelector(getPendingReferenceValidations);
  const disclosure = useDisclosure();
  const isClientView = sparePartsView === ClientView;
  const { onOpen } = disclosure;
  const [validationRow, setValidationRow] = useState<{ itemId: string; referenceNumber: string } | undefined>(
    undefined,
  );
  const [checkedReferences, setCheckedReferences] = useState(new Map<string, DHReferenceLocal | NO_DATA>());

  const removeCheckedReferences = (k: string) => {
    checkedReferences.delete(k);
    setCheckedReferences(new Map(checkedReferences));
  };

  const tradingData: ReferenceTradingDataRequestDetail[] = data.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      commercialFamily: '',
    };
  });

  useFetchDiscounts(tradingData);

  useEffect(() => {
    validationRefs
      .flatMap((refForValidation) => catalogTires.filter((refData) => refData.referenceNumber === refForValidation))
      .forEach((x) => {
        if (!x.isCompatible) {
          setValidationRow({ itemId: x.itemId, referenceNumber: x.referenceNumber });
          onOpen();
        }
        dispatch(removeKnownReferenceValidation(x.referenceNumber));
      });
  }, [validationRefs, dispatch, catalogTires, onOpen]);

  const handleUpdate = (itemId: string, field: TireReferenceField) => (newValue: string) => {
    dispatch(updateTire({ itemId, field, newValue }));
  };

  function continueAdding() {
    setValidationRow(undefined);
  }

  function removeRow(rowId: string) {
    const removedRef = catalogTires.filter((e) => e.itemId === rowId);
    if (removedRef && removedRef.length === 1 && removedRef[0].referenceNumber) {
      removeCheckedReferences(removedRef[0].referenceNumber);
    }
    dispatch(removeTire(rowId));
  }

  function revertRow() {
    if (validationRow) {
      const refItemToRevert = catalogTires.filter(
        (refData) => refData.referenceNumber === validationRow.referenceNumber,
      );
      if (refItemToRevert.length === 1) {
        const referenceToRevert = refItemToRevert[0];
        const originalQuantity = referenceToRevert.quantity >= 1 ? referenceToRevert.quantity - 1 : 0;
        if (originalQuantity === 0) {
          dispatch(removeTire(validationRow.itemId));
        } else {
          dispatch(updateTire({ itemId: validationRow.itemId, field: 'QUANTITY', newValue: String(originalQuantity) }));
        }
      }
      dispatch(removeKnownReferenceValidation(validationRow.referenceNumber));
      setValidationRow(undefined);
    }
  }

  const tableColumns = [
    {
      title: GetTitle(t('catalog.reference', 'Reference')),
      dataIndex: 'referenceNumber',
      width: '12vw',
      render: function rowSelector(referenceNumber: string, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        return (
          <Flex justify={'center'} align={'center'}>
            <TextInput
              value={referenceNumber}
              saveFunction={handleUpdate(row.itemId, 'REFERENCE_NUMBER')}
              removeRow={() => removeRow(row.itemId)}
              readOnly={!row.isCustom}
              minWidth={140}
              isInvalid={isClientView && isMarginNegativeOrZero}
            />
            {!row.isCompatible && (
              <WithTooltip
                title={t('estimate.reference.noncompatible', 'Not compatible with selected vehicle')}
                placement={'top'}
              >
                <TooltipContent>
                  <Flex size={0} align={'center'} justify={'center'}>
                    <Icon IconComponent={UnlinkIcon} size={16} color={'black'} display={'inline'} />
                  </Flex>
                </TooltipContent>
              </WithTooltip>
            )}
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      width: '25vw',
      render: function rowSelector(designation: string, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        const isInvalid = isClientView && isMarginNegativeOrZero;
        const tireBrand = tireBrandsData.find((tireBrand) => row.brand === tireBrand.brand);
        return (
          <>
            {row.isCustom ? (
              <TextInput value={designation} saveFunction={handleUpdate(row.itemId, 'NAME')} isInvalid={isInvalid} />
            ) : (
              <Flex direction={'column'}>
                <WithTooltip
                  title={`${tireBrand?.name ?? ''} ${row.tireDimensions ? `- ${row.tireDimensions}` : ''} ${
                    row.designation ?? ''
                  }`}
                >
                  <Flex direction={'row'}>
                    <Text type={isInvalid ? 'light_14_bold_red' : 'text_bold'}>{tireBrand?.name}</Text>
                    <MarginBox mr={3} />
                    {row.designation && (
                      <Text type={isInvalid ? 'light_14_red' : 'text'} ellipsis noWrap>{`- ${row.designation}`}</Text>
                    )}
                  </Flex>
                  {row.tireDimensions && <Text type={isInvalid ? 'light_14_red' : 'text'}>{row.tireDimensions}</Text>}
                </WithTooltip>
              </Flex>
            )}
          </>
        );
      },
    },
    {
      title: GetTitle(t('common.price.unit_price', 'Unit Price')),
      dataIndex: 'unitPrice',
      render: function rowSelector(unitPrice: string, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        return (
          <EstimatePriceInput
            value={unitPrice}
            saveFunction={handleUpdate(row.itemId, 'UNIT_PRICE')}
            readOnly={!row.isCustom}
            minWidth={125}
            isInvalid={isClientView && isMarginNegativeOrZero}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      width: '10vw',
      render: function rowSelector(quantity: number, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        return (
          <QuantityInput
            value={quantity}
            saveFunction={handleUpdate(row.itemId, 'QUANTITY')}
            removeRow={() => removeRow(row.itemId)}
            isInvalid={isClientView && isMarginNegativeOrZero}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.discount', 'Discount')),
      dataIndex: 'discount',
      render: function rowSelector(discount: string, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        return (
          <DiscountInput
            value={discount}
            saveFunction={handleUpdate(row.itemId, 'DISCOUNT')}
            discountType={row.discountType}
            saveDiscountType={handleUpdate(row.itemId, 'DISCOUNT_TYPE')}
            isInvalid={isClientView && isMarginNegativeOrZero}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      render: function rowSelector(priceVatExcluded: string, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        return (
          <Flex justify={'center'} align={'center'}>
            <Text type={isClientView && isMarginNegativeOrZero ? 'light_14_bold_red' : 'light_14'}>
              {textFormatter.formatCurrency(isNaN(Number(priceVatExcluded)) ? 0 : priceVatExcluded, currency)}
            </Text>
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      width: 130,
      render: function rowSelector(vat: number, row: Tire) {
        const isMarginNegativeOrZero = Number(row.margin) <= 0;
        return (
          <VatInput
            value={vat}
            saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')}
            readOnly={!row.isCustom}
            isInvalid={isClientView && isMarginNegativeOrZero}
          />
        );
      },
    },
    ...getCondArrayItem(
      isClientView && {
        title: null,
        dataIndex: '',
        width: 20,
        render: function rowSelector(row: Tire) {
          const isMarginNegativeOrZero = Number(row.margin) <= 0;
          return isMarginNegativeOrZero && <EstimateMarginAlert designation={row.designation} t={t} />;
        },
      },
    ),
  ];
  if (!isClientView) {
    tableColumns.push(
      {
        title: GetTitle(t('common.price.garage_price', 'Garage Price')),
        dataIndex: 'garagePrice',
        render: function rowSelector(_garagePrice: string, row: Tire) {
          const isMarginNegativeOrZero = Number(row.margin) <= 0;
          return (
            <EstimateGaragePrice
              row={row}
              currency={currency}
              handleUpdate={handleUpdate}
              isInvalid={isMarginNegativeOrZero}
            />
          );
        },
      },
      {
        title: GetTitle(t('common.price.margin', 'Margin')),
        dataIndex: 'margin',
        render: function rowSelector(_margin: string, row: Tire) {
          const isMarginNegativeOrZero = Number(row.margin) <= 0;
          return (
            <Flex justify={'space-between'} align={'center'} minWidth={70}>
              {isMarginNegativeOrZero ? (
                <EstimateMarginAlert designation={row.designation} t={t} />
              ) : (
                <Flex minWidth={20} />
              )}
              <EstimateGarageMargin
                estimateId={estimateId}
                row={row}
                currency={currency}
                isInvalid={isMarginNegativeOrZero}
              />
            </Flex>
          );
        },
      },
    );
  }

  if (data.length === 0) {
    return null;
  }
  return (
    <>
      <STable<Tire>
        columns={tableColumns}
        dataSource={data}
        pagination={false}
        rowKey={(row) => row.itemId}
        size={'large'}
      />
      <Dialog
        disclosure={disclosure}
        title={t('common.dialog.actionRequired', 'Action required')}
        description={[
          // tableElements.deleteAllDescription,
          t(
            'estimate.noncompatible.confirmationQuestion',
            // eslint-disable-next-line max-len
            'The reference that you are trying to add to your estimate is not compatible with the identified vehicle. Do you want to add it anyway?',
          ),
        ]}
        icon={UnlinkIcon}
        status={'info'}
        handleConfirm={continueAdding}
        handleCancel={revertRow}
      />
    </>
  );
}
