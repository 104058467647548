import { CheckoutOverallStatus } from '@1po/1po-bff-fe-spec/generated/order/response/CheckoutOrderResponse';
import styled from 'styled-components';
import { ThemeType } from 'styles';
import { Divider } from 'UI';

const getBorder = (status: CheckoutOverallStatus, theme: ThemeType) => {
  switch (status) {
    case 'PLACED':
      return theme.color.success;
    case 'R1_REVIEW':
      return theme.color.blue_light;
    case 'NOT_PLACED':
      return theme.color.error_light;
    case 'NOT_EVERYTHING_PLACED':
      return theme.color.yellow_light;
    default:
      return theme.color.brand_black;
  }
};

const getBackgroundColor = (status: CheckoutOverallStatus, theme: ThemeType) => {
  switch (status) {
    case 'PLACED':
      return theme.color.off_green;
    case 'R1_REVIEW':
      return theme.color.ice_blue;
    case 'NOT_PLACED':
      return theme.color.very_light_pink;
    case 'NOT_EVERYTHING_PLACED':
      return theme.color.off_white;
  }
};

export const ValidationStatusWrapper = styled.div<{ status: CheckoutOverallStatus }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ status, theme }) => getBackgroundColor(status, theme)};
  border: ${({ status, theme }) => getBorder(status, theme)} solid 1px;
  align-items: center;
  text-align: center;
  align-content: center;
`;

export const ConfirmationDivider = styled(Divider)`
  color: ${({ theme }) => theme.color.grey85};
  margin: 0 0 0 0;
`;

export const AlignLeft = styled.div`
  text-align: left;
`;

export const AlignRight = styled.div`
  text-align: right;
`;
