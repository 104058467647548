import { QueryResponseType } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { GetMyStoreBundlesResponse } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { UpdateMyStoreTireDiscount } from '@1po/1po-bff-fe-spec/generated/my_store/tires/request/UpdateMyStoreTireDiscountList';
import { GetMyStoreTiresResponse } from '@1po/1po-bff-fe-spec/generated/my_store/tires/response/GetMyStoreTiresResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { v4 } from 'uuid';
import {
  sendAddBundleRequest,
  sendAddBundlesSectionRequest,
  sendGetBundlesRequest,
  sendMoveBundleSectionRequest,
  sendRemoveBundleRequest,
  sendRenameBundlesSectionRequest,
  sendSubscribeTiresRequest,
  sendUpdateBundleCode,
  sendUpdateBundleDesignation,
  sendUpdateBundlePrice,
  sendUpdateMyStoreTireDiscountListRequest,
} from 'domains/myStore/MyStore.api';
import * as actions from 'domains/myStore/MyStore.store';
import {
  clearMyStoreTiresChanges,
  getBundlesSections,
  getMyStoreBundlesId,
  getMyStoreTiresId,
  reorderBundles,
  setBundlesSection,
  setBundlesStatus,
  setInitialState,
  setMyStoreTireBrands,
  setMyStoreTiresId,
  setMyStoreTiresSearchStatus,
} from 'domains/myStore/MyStore.store';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { notifyTop } from 'UI/Notification/notification';
import { AppTranslation, FOUND, LOADING, sagaGuard, select } from 'utils';

export function* initMyStoreSaga(): SagaIterator {
  yield put(setInitialState());
}

export function* getBundlesRequestSaga(): SagaIterator {
  yield put(setBundlesStatus(LOADING));
  yield put(sendGetBundlesRequest());
}

export function* getBundlesResponseSaga(action: WsResponse<GetMyStoreBundlesResponse>): SagaIterator {
  yield put(setBundlesSection(action.payload));
  yield put(setBundlesStatus(FOUND));
}

export function* moveBundlesSectionRequestSaga({
  payload,
}: ReturnType<typeof actions.moveBundlesSectionRequestSaga>): SagaIterator {
  const { active, over } = payload;
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  const bundles = yield* select(getBundlesSections);
  const index = bundles.sections.data?.findIndex((bundle) => bundle.id === over?.id) ?? -1;
  if (index > -1 && myStoreBundlesId) {
    yield put(reorderBundles(payload));
    yield put(sendMoveBundleSectionRequest({ myStoreBundlesId, sectionId: active.id.toString(), index }));
  }
}

export function* addBundlesSectionRequestSaga(): SagaIterator {
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  const sectionId = v4();
  if (myStoreBundlesId) {
    yield put(sendAddBundlesSectionRequest({ myStoreBundlesId, sectionId }));
  }
}

export function* renameBundlesSectionRequestSaga({
  payload,
}: ReturnType<typeof actions.renameBundlesSectionRequestSaga>): SagaIterator {
  const { sectionId, title } = payload;
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  if (myStoreBundlesId) {
    yield put(sendRenameBundlesSectionRequest({ myStoreBundlesId, sectionId, title }));
  }
}

export function* addBundleRequestSaga({ payload }: ReturnType<typeof actions.addBundleRequestSaga>): SagaIterator {
  const { sectionId } = payload;
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  const bundleId = v4();
  if (myStoreBundlesId) {
    yield put(sendAddBundleRequest({ myStoreBundlesId, sectionId, bundleId }));
  }
}

export function* removeBundleRequestSaga({
  payload,
}: ReturnType<typeof actions.removeBundleRequestSaga>): SagaIterator {
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  const { sectionId, bundleId } = payload;
  if (myStoreBundlesId) {
    yield put(sendRemoveBundleRequest({ myStoreBundlesId, sectionId, bundleId }));
  }
}

export function* updateBundleDesignationRequestSaga({
  payload,
}: ReturnType<typeof actions.updateBundleDesignationRequestSaga>): SagaIterator {
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  if (myStoreBundlesId) {
    yield put(sendUpdateBundleDesignation({ myStoreBundlesId, ...payload }));
  }
}

export function* updateBundleCodeRequestSaga({
  payload,
}: ReturnType<typeof actions.updateBundleCodeRequestSaga>): SagaIterator {
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  if (myStoreBundlesId) {
    yield put(sendUpdateBundleCode({ myStoreBundlesId, ...payload }));
  }
}

export function* updateBundlePriceRequestSaga({
  payload,
}: ReturnType<typeof actions.updateBundlePriceRequestSaga>): SagaIterator {
  const myStoreBundlesId = yield* select(getMyStoreBundlesId);
  if (myStoreBundlesId) {
    yield put(sendUpdateBundlePrice({ myStoreBundlesId, ...payload }));
  }
}

export function* subscribeTiresRequestSaga(): SagaIterator {
  yield put(setMyStoreTiresSearchStatus(LOADING));
  yield put(sendSubscribeTiresRequest());
}

export function* getTiresResponseSaga(action: WsResponse<GetMyStoreTiresResponse>): SagaIterator {
  function* reducersUpdateMyStoreTires() {
    const { myStoreTiresId, brands } = action.payload;
    yield put(setMyStoreTiresId({ myStoreTiresId }));
    yield put(setMyStoreTireBrands({ brands }));
    yield put(setMyStoreTiresSearchStatus(FOUND));
  }

  switch (action.queryResponseType) {
    case QueryResponseType.COMMAND_RESPONSE_ERROR:
    case QueryResponseType.COMMAND_RESPONSE_INVALID: {
      notifyTop(
        'error',
        AppTranslation.t('my_store.tires.command.response.invalid.title', 'Tires discounts not saved!'),
        AppTranslation.t('my_store.tires.command.response.invalid.message', 'Something went wrong'),
      );
      break;
    }
    case QueryResponseType.COMMAND_RESPONSE_NO_CHANGE: {
      yield put(clearMyStoreTiresChanges());
      break;
    }
    case QueryResponseType.COMMAND_RESPONSE_SUCCESS: {
      notifyTop(
        'success',
        AppTranslation.t('my_store.tires.command.response.success.title', 'Tires discounts saved'),
        AppTranslation.t('my_store.tires.command.response.success.message', 'Changes has been saved.'),
      );
      break;
    }
    case QueryResponseType.EVENT_UPDATE:
    case QueryResponseType.INIT:
    case QueryResponseType.COMMAND_LOOKUP_RESPONSE: {
      yield* reducersUpdateMyStoreTires();
      break;
    }
  }
}

export function* updateMyStoreTireDiscountListRequest({
  payload,
}: ReturnType<typeof actions.updateMyStoreTireDiscountListRequest>): SagaIterator {
  const myStoreTiresId = yield* select(getMyStoreTiresId);
  if (myStoreTiresId) {
    const updateMyStoreTireDiscountList: UpdateMyStoreTireDiscount[] = [];
    for (const value of payload.values()) {
      updateMyStoreTireDiscountList.push({
        myStoreTiresId,
        brandId: value.brandId,
        tireRimSizeType: value.tireRimSizeType,
        discount: value.discount,
      });
    }

    yield put(sendUpdateMyStoreTireDiscountListRequest({ myStoreTiresId, updateMyStoreTireDiscountList }));
  }
}

export function* MyStoreSagas(): SagaIterator {
  yield takeEvery(actions.initMyStoreRequest.type, sagaGuard(initMyStoreSaga));
  yield takeEvery(actions.getBundlesRequestSaga.type, sagaGuard(getBundlesRequestSaga));
  yield takeEvery(actions.getBundlesResponseSaga.type, sagaGuard(getBundlesResponseSaga));
  yield takeEvery(actions.moveBundlesSectionRequestSaga.type, sagaGuard(moveBundlesSectionRequestSaga));
  yield takeEvery(actions.addBundlesSectionRequestSaga.type, sagaGuard(addBundlesSectionRequestSaga));
  yield takeEvery(actions.renameBundlesSectionRequestSaga.type, sagaGuard(renameBundlesSectionRequestSaga));
  yield takeEvery(actions.addBundleRequestSaga.type, sagaGuard(addBundleRequestSaga));
  yield takeEvery(actions.removeBundleRequestSaga.type, sagaGuard(removeBundleRequestSaga));
  yield takeEvery(actions.updateBundleDesignationRequestSaga.type, sagaGuard(updateBundleDesignationRequestSaga));
  yield takeEvery(actions.updateBundleCodeRequestSaga.type, sagaGuard(updateBundleCodeRequestSaga));
  yield takeEvery(actions.updateBundlePriceRequestSaga.type, sagaGuard(updateBundlePriceRequestSaga));
  yield takeEvery(actions.subscribeTiresRequestSaga.type, sagaGuard(subscribeTiresRequestSaga));
  yield takeEvery(actions.getTiresResponseSaga.type, sagaGuard(getTiresResponseSaga));
  yield takeEvery(actions.updateMyStoreTireDiscountListRequest.type, sagaGuard(updateMyStoreTireDiscountListRequest));
}
