import React from 'react';
import { Table } from 'antd';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Country } from 'utils/i18n/Country';

export const CookiesPL = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>Wykorzystanie plików cookies.</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          Ta sekcja jest poświęcona naszej polityce zarządzania plikami cookies. Podczas wyświetlania naszych treści
          informacje o zachowaniach podczas nawigacji na urządzeniu końcowym (komputerze, tablecie, smartfonie itp.)
          mogą być zapisywane w plikach cookies instalowanych w urządzeniu użytkownika. Niniejsza polityka pozwoli Ci
          dowiedzieć się więcej na temat pochodzenia i używania danych nawigacyjnych oraz korzystania ze swoich praw, a
          w szczególności prawa do zgłoszenia sprzeciwu. Dotyczy ona także informacji, które zbieramy, kiedy wchodzisz
          na tę stronę poprzez reklamy, jakie umieszczamy na stronach i w aplikacjach podmiotów trzecich.
        </Paragraph>
        <Section>
          <SectionHeader title={'KIM JESTEŚMY?'} />
          <Paragraph>
            Witamy na stronie{' '}
            <Link to={'https://rpartstore.pl'} external newTab>
              rpartstore.pl
            </Link>
            , której wydawcą jest spółka RENAULT SAS z siedzibą pod adresem 13/15 quai le Gallo 92100
            Boulogne-Billancourt, wpisana do rejestru handlowego w Nanterre pod numerem 780129987.
          </Paragraph>
          <Paragraph>
            W celu zapewnienia ochrony Twoich danych, w tym również danych zbieranych za pośrednictwem plików cookies,
            powołaliśmy inspektora ochrony danych, z którym można się kontaktować pod adresem mailowym{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>{' '}
            oraz adresem pocztowym Renault SAS, Direction juridique – Délégué à la protection des données, 13/15 quai le
            Gallo 92100 Boulogne-Billancourt.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'CZYM SĄ PLIKI COOKIE?'} />
          <Paragraph>
            Pliki cookie to informacje umieszczane w urządzeniu końcowym użytkownika przez serwer odwiedzanej strony
            internetowej. Są używane przez stronę internetową w celu wysyłania informacji do przeglądarki użytkownika
            oraz umożliwienia tej przeglądarce odsyłanie informacji do strony wyjściowej (na przykład identyfikator
            sesji lub wybór języka).
          </Paragraph>
          <Paragraph>Tylko wystawca pliku cookie może odczytywać lub modyfikować zawarte w nim informacje.</Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'DO CZEGO WYKORZYSTYWANE SĄ PLIKI COOKIES NA STRONIE?'} />
          <Paragraph>
            Strona internetowa{' '}
            <Link to={'https://rpartstore.pl'} external newTab>
              rpartstore.pl
            </Link>{' '}
            używa różnego rodzaju plików cookies.
          </Paragraph>
          <Paragraph>
            Niektóre pliki cookies są używane wyłącznie w celu umożliwienia lub ułatwienia komunikacji elektronicznej
            (wykrywanie błędów połączenia, identyfikacja punktów połączeń, bezpieczeństwo strony internetowej itp.).
          </Paragraph>
          <Paragraph>
            Inne pliki cookies są niezbędne do świadczenia usług komunikacji online na wyraźne żądanie (preferencje
            wyświetlania na ekranie, zapamiętywanie informacji wprowadzonych w formularzach, ewentualnie koszyk
            zakupowy, dostęp do konta użytkownika itp.).
          </Paragraph>
          <Paragraph>
            Jeszcze inne są wykorzystywane w celu:
            <Ul>
              <Li>
                <Text type={'text'}>
                  analizowania częstotliwości i sposobu korzystania ze strony internetowej, aby poprawić doświadczenie
                  użytkownika;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  zapewnienia lepszego dopasowania reklam wyświetlanych na stronie internetowej;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  sprawienia, żeby strona była bardziej przyjazna dla użytkownika i interaktywna i umożliwiała
                  udostępnianie informacji w sieciach społecznościowych.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'JAKIE PLIKI COOKIE UŻYWANE SĄ NA STRONIE?'} />
          <Paragraph>
            Na stronie są używane następujące pliki cookie:{' '}
            <Ul>
              <Li>
                <Text type={'text'}>pliki cookie RENAULT SAS;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  pliki cookie podmiotów trzecich, których wyczerpującą listę ustala RENAULT SAS (agencje reklamowe,
                  firmy zajmujące się pomiarami oglądalności itp.).
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Pliki cookies RENAULT SAS'} />
          <Paragraph>
            Pliki cookies wykorzystywane przez narzędzia strony{' '}
            <Link to={'https://rpartstore.pl'} external newTab>
              rpartstore.pl
            </Link>{' '}
            należące do RENAUTL SAS są wymienione i opisane w tabeli poniżej:
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Nazwa',
                dataIndex: 'site',
                key: 'site',
                render: (name: string) => (
                  <Link to={name} external newTab>
                    {name.replace('https://', '')}
                  </Link>
                ),
              },
              { title: 'Cel pliku cookie', dataIndex: 'type', key: 'type' },
              {
                title: 'Czas przechowywania',
                dataIndex: 'time',
                key: 'time',
              },
              {
                title: 'Transfer poza kraje UE',
                dataIndex: 'transfer',
                key: 'transfer',
              },
            ]}
            dataSource={[
              {
                id: 'a',
                site: 'https://rpartstore.pl',
                type: 'Uwierzytelnienie internauty na stronie internetowej',
                time: '13 miesięcy',
                transfer: 'Nie',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'Pliki cookie podmiotów trzecich'} />
          <Paragraph>
            Nasza strona może zawierać pliki cookies podmiotów trzecich. Poniżej przedstawiamy informacje o tych
            plikach.
          </Paragraph>
          <SectionHeader title={'1.1.1 Pomiar oglądalności'} />
          <Paragraph>
            Usługa MATOMO pozwala zliczyć odwiedzających oraz ustalić, w jaki sposób używają strony internetowej. Jest
            to narzędzie statystyczne, które umożliwia RENAULT SAS ulepszanie swojej witryny z uwzględnieniem potrzeb
            odwiedzających.
          </Paragraph>
          <Paragraph>
            Dane generowane przez te pliki cookies dotyczą:
            <Ul>
              <Li>
                <Text type={'text'}>korzystania z witryny przez użytkownika – jakie strony odwiedza;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  adresu IP użytkownika w celu ustalenia miejscowości, z której nastąpiło połączenie. Ta informacja jest
                  anonimizowana natychmiast po ustaleniu lokalizacji i nie jest przekazywana do MATOMO.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>Tabela poniżej przedstawia używane pliki cookies:</Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Firma',
                dataIndex: 'firm',
                key: 'firm',
              },
              { title: 'Cel pliku cookie', dataIndex: 'utility', key: 'utility' },
              { title: 'Czas przechowywania', dataIndex: 'duration', key: 'duration' },
              { title: 'Transfer poza kraje UE', dataIndex: 'transfer', key: 'transfer' },
            ]}
            dataSource={[
              {
                id: 0,
                firm: 'Matomo',
                utility: 'Śledzenie przebiegu sesji/wizyty internauty na stronie.',
                duration: '13 miesięcy',
                transfer: 'Nie',
              },
              {
                id: 1,
                firm: 'Contentsquare',
                utility: 'Śledzenie przebiegu sesji/wizyty internauty na stronie.',
                duration: '13 miesięcy',
                transfer: 'Nie',
              },
              {
                id: 2,
                firm: 'Dynatrace',
                utility: 'Śledzenie przebiegu sesji/wizyty internauty na stronie.',
                duration: '13 miesięcy',
                transfer: 'Nie',
              },
              {
                id: 3,
                firm: 'Renault',
                utility: 'technika',
                duration: '13 miesięcy',
                transfer: 'Nie',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'W JAKI SPOSÓB Renault SAS UZYSKUJE MOJĄ ZGODĘ NA UŻYCIE PLIKÓW COOKIES?'} />
          <Paragraph>
            Podczas pierwszej wizyty na naszej stronie pytamy użytkownika, czy wyraża zgodę na użycie niektórych plików
            cookies, czy odmawia jej udzielenia.
          </Paragraph>
          <Paragraph>
            Jeżeli nie życzysz sobie, by pliki cookies były instalowane lub odczytywane na Twoim urządzeniu końcowym,
            jeden plik cookie z odmową zostanie umieszczony w Twoim urządzeniu, aby spółka Renault SAS zarejestrowała
            informację, że nie wyrażasz zgody na używanie ciasteczek. Jeśli usuniesz plik cookie odmowy, nie będzie już
            możliwe zidentyfikowanie Ciebie jako osoby, która nie zgodziła się na używanie plików cookies.
          </Paragraph>
          <Paragraph>
            Podobnie, kiedy zgodzisz się na korzystanie z plików cookies, zostanie zainstalowany jeden plik cookie ze
            zgodą.
          </Paragraph>
          <Paragraph>
            Pliki cookies zgody lub odmowy powinny pozostać na Twoim urządzeniu końcowym. W dowolnym czasie możesz
            zmienić swoją decyzję w sposób wskazany w następnej sekcji zatytułowanej „Zarządzanie plikami cookies”.
          </Paragraph>
          <Paragraph>
            Informujemy, że pliki cookies, których celem jest umożliwienie lub ułatwienie komunikacji, lub są niezbędne
            do świadczenia usługi, nie wymagają zgody użytkownika.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'W JAKI SPOSÓB ZARZĄDZAĆ PLIKAMI COOKIES?'} />
          <Paragraph>
            Użytkownik ma do wyboru kilka opcji zarządzania plikami cookies, a w szczególności ma możliwość ich
            usuwania.
          </Paragraph>
          <Paragraph>
            Możesz uniemożliwić wykorzystanie takich plików cookies, ale może to wpłynąć na jakość Twojego doświadczenia
            podczas przeglądania strony internetowej, utrudnić lub pogorszyć jakość korzystania z naszej strony
            internetowej.
          </Paragraph>
          <SectionHeader title={'Moduł zarządzania plikami cookies'} />
          <Paragraph>
            Udostępniamy ten moduł w celu umożliwienia Ci zarządzania plikami cookies używanymi przez stronę internetową{' '}
            <Link to={'https://rpartstore.pl'} external newTab>
              rpartstore.pl
            </Link>{' '}
            w przypadku których Twoja zgoda jest niezbędna.
          </Paragraph>
          <Paragraph>
            Klikając ten link możesz w dowolnym czasie uzyskać dostęp do modułu i zmienić swoje preferencje dotyczące
            poszczególnych plików cookies.
          </Paragraph>
          <SectionHeader title={'Ustawienia przeglądarki'} />
          <Paragraph>
            Użytkownik ma również możliwość zarządzania plikami cookies za pośrednictwem swojej przeglądarki
            internetowej.
          </Paragraph>
          <Paragraph>
            Wprawdzie większość przeglądarek ustawiona jest domyślnie na akceptowanie instalacji plików cookies,
            istnieje jednak możliwość, aby – w zależności od woli użytkownika – wybrać albo akceptowanie wszystkich
            plików cookies, albo ich systematyczne odrzucanie, albo wybranie tych, które akceptuje w zależności od ich
            wydawcy. Można również regularnie usuwać pliki cookies z urządzenia końcowego za pomocą przeglądarki
            internetowej.
          </Paragraph>
          <Paragraph>
            Należy jednak pamiętać o ustawieniu wszystkich przeglądarek w poszczególnych urządzeniach końcowych
            (tablety, smartfony, komputery itp.)
          </Paragraph>
          <Paragraph>
            Konfiguracja zarządzania plikami cookie i preferencjami użytkownika w każdej przeglądarce jest inna. Jest to
            opisane w menu pomocy przeglądarki, dzięki któremu można dowiedzieć się, jak zmienić preferencje dotyczące
            plików cookie. Na przykład:
            {/* eslint-disable */}
            <Ul>
              <Li>
                <Text type={'text'}>
                  Microsoft Edge™:{' '}
                  <Link
                    to={
                      'https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'
                    }
                    external
                    newTab
                  >
                    https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Safari™:{' '}
                  <Link to={'https://support.apple.com/pl-pl/guide/safari/sfri11471/mac'} external newTab>
                    https://support.apple.com/pl-pl/guide/safari/sfri11471/mac
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Chrome™:{' '}
                  <Link
                    to={'https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop'}
                    external
                    newTab
                  >
                    https://support.google.com/chrome/answer/95647?hl=pl&co=GENIE.Platform%3DDesktop
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Firefox™:{' '}
                  <Link
                    to={'https://support.mozilla.org/pl/kb/usuwanie-ciasteczek-i-danych-stron-firefox'}
                    external
                    newTab
                  >
                    https://support.mozilla.org/pl/kb/usuwanie-ciasteczek-i-danych-stron-firefox
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Opera™:{' '}
                  <Link to={'https://help.opera.com/pl/latest/web-preferences/#cookies'} external newTab>
                    https://help.opera.com/pl/latest/web-preferences/#cookies
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
            {/* eslint-enable */}
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Platformy świadczące usługi blokowania plików cookies'} />
          <Paragraph>
            Istnieje wiele platform prowadzonych przez firmy z branży reklamowej, oferujących również możliwość odmowy
            lub zgody w zakresie obsługi plików cookies używanych przez przedsiębiorstwa, które się tam zarejestrowały.
            Takie scentralizowane mechanizmy nie blokują wyświetlenia reklam, lecz tylko uniemożliwiają instalację
            plików cookies, które pozwalają na dostosowanie reklam do Twoich zainteresowań.
          </Paragraph>
          <Paragraph>
            Na przykład możesz wejść na stronę{' '}
            <Link to={'https://www.youronlinechoices.com'} external newTab>
              www.youronlinechoices.com
            </Link>
            , aby zabronić instalowania takich plików cookies na swoim urządzeniu końcowym. Ta strona została stworzona
            przez profesjonalistów z branży reklam cyfrowych, zrzeszonych w ramach europejskiego stowarzyszenia EDAA
            (European Digital Advertising Alliance) i jest zarządzana we Francji przez Interactive Advertising Bureau
            France.
          </Paragraph>
          <Paragraph>
            Można tam sprawdzić firmy zarejestrowane na platformie i oferujące możliwość odrzucenia lub zaakceptowania
            plików cookies używanych przez nie w celu dostosowania reklam, które mogą być wyświetlane na urządzeniu
            użytkownika w zależności od przeglądanych stron.
          </Paragraph>
          <Paragraph>
            Ta europejska platforma jest udostępniana setkom specjalistów z branży reklam internetowych. Jest
            scentralizowanym interfejsem umożliwiającym odmowę lub zgodę na pliki cookies, które mogą być wykorzystywane
            w celu dostosowania wyświetlanych reklam do przeglądanych stron.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'JEŻELI KORZYSTASZ Z URZĄDZENIA WSPÓLNIE Z INNYMI UŻYTKOWNIKAMI'} />
          <Paragraph>
            Jeśli urządzenie jest używane przez więcej niż jedną osobę i ma zainstalowaną więcej niż jedną przeglądarkę,
            wówczas nie możemy z całą pewnością zagwarantować, że usługi i reklamy wysyłane na takie urządzenie końcowe
            będą odpowiadały akurat Twoim preferencjom, a nie preferencjom innego użytkownika.
          </Paragraph>
          <Paragraph>
            W takiej sytuacji decyzja o udostępnianiu urządzenia końcowego innym użytkownikom oraz skonfigurowanie
            ustawień przeglądarki w odniesieniu do plików cookies zależy od Twojego wyboru i odbywa się na Twoją
            odpowiedzialność. Twoim obowiązkiem jest poinformowanie o tym innych osób.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'TWOJE PRAWA'} />
          <Paragraph>
            Zgodnie z obowiązującymi przepisami dotyczącymi ochrony danych osobowych masz prawo dostępu i sprostowania
            swoich danych, zgłoszenia sprzeciwu, ograniczenia przetwarzania, przenoszenia oraz usunięcia danych
            osobowych, które Ciebie dotyczą. W celu skorzystania z praw, jakie Ci przysługują, możesz zapoznać się z
            naszymi informacjami na temat ochrony danych po kliknięciu linku{' '}
            <Link to={ROUTER_PERSONAL_DATA(Country.PL.key)} newTab>
              Polityka ochrony danych osobowych
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'ZMIANY'} />
          <Paragraph>
            Co pewien czas możemy być zmuszeni do modyfikowania niniejszej polityki plików cookies. Gdy będzie to
            konieczne lub wymagane, powiadomimy Cię i/lub będziemy prosić o zgodę. Dlatego zachęcamy do regularnego
            sprawdzania polityki przy okazji odwiedzania portalu w celu zapoznania się ze zmianami.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Aktualizacja: 30 maja 2023 roku</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
