import React from 'react';
import { useTranslation } from 'react-i18next';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import {
  OrderDeliveryType,
  PresentableOrderStatusType,
} from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { useTheme } from 'styled-components';
import { CalendarAltIcon, HourglassHalfIcon, StickyNoteIcon, TruckIcon, UserIcon } from 'assets/icons';
import { getDeliveryTypeLabel } from 'domains/order/OrderDelivery';
import { OrderStatus } from 'domains/order/OrderStatus';
import { CardPanel, CardTitle, OrderDetailCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { Box, Flex, Icon, MarginBox, Pipeline, Text } from 'UI';
import { textFormatter } from 'utils';

export const ClientInformationCard = ({
  garage,
  orderStatus,
  orderDate,
  deliveryType,
  orderMark,
}: {
  garage: Garage;
  orderStatus: PresentableOrderStatusType;
  orderDate: string;
  deliveryType?: OrderDeliveryType;
  orderMark: string | undefined;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <OrderDetailCard data-cy={'section-client'}>
      <CardTitle>
        <Text type={'h6'}>{t('my_orders.details.client_information', 'Client information')}</Text>
      </CardTitle>
      <Flex>
        {garage && (
          <>
            <Flex>
              <CardPanel>
                <Icon
                  IconComponent={UserIcon}
                  height={16}
                  width={16}
                  color={theme.color.brand_black}
                  display={'inline'}
                  mt={4}
                />
                <MarginBox ml={24}>
                  <Text type={'text_bold'}>{t('my_orders.details.client', 'Client')}</Text>
                </MarginBox>
              </CardPanel>
              <CardPanel>
                <Text type={'light_14_black_65'} dataCy={'client-name'}>
                  {garage.name ?? ''}
                </Text>
              </CardPanel>
            </Flex>
            <Pipeline size={'100%'} />
          </>
        )}
        <Flex>
          <CardPanel>
            <Icon
              IconComponent={HourglassHalfIcon}
              height={16}
              width={16}
              color={theme.color.brand_black}
              display={'inline'}
              mt={4}
            />
            <MarginBox ml={24}>
              <Text type={'text_bold'}>{t('my_orders.details.status', 'Status')}</Text>
            </MarginBox>
          </CardPanel>
          <CardPanel>
            <Box>
              <OrderStatus orderStatus={orderStatus} />
            </Box>
          </CardPanel>
        </Flex>
      </Flex>
      <Pipeline size={'100%'} horizontal />
      <Flex>
        <Flex>
          <CardPanel>
            <Icon
              IconComponent={CalendarAltIcon}
              height={16}
              width={16}
              color={theme.color.brand_black}
              display={'inline'}
              mt={4}
            />
            <MarginBox ml={24}>
              <Text type={'text_bold'}>{t('order.order_detail.order_date', 'Order date:')}</Text>
            </MarginBox>
          </CardPanel>
          <CardPanel>
            <Text type={'light_14_black_65'}>
              {textFormatter.formatDate(new Date(orderDate), {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </Text>
          </CardPanel>
        </Flex>
        <Pipeline size={'100%'} />
        {deliveryType && (
          <Flex>
            <CardPanel>
              <Icon
                IconComponent={TruckIcon}
                height={16}
                width={16}
                color={theme.color.brand_black}
                display={'inline'}
                mt={4}
              />
              <MarginBox ml={24}>
                <Text type={'text_bold'}>{t('my_orders.details.delivery_method', 'Delivery method')}</Text>
              </MarginBox>
            </CardPanel>
            <CardPanel>
              <Text type={'light_14_black_65'}>{getDeliveryTypeLabel(deliveryType)}</Text>
            </CardPanel>
          </Flex>
        )}
      </Flex>
      {orderMark && (
        <>
          <Pipeline size={'100%'} horizontal />
          <Flex>
            <Flex>
              <CardPanel>
                <Icon
                  IconComponent={StickyNoteIcon}
                  height={16}
                  width={16}
                  color={theme.color.brand_black}
                  display={'inline'}
                  mt={4}
                />
                <MarginBox ml={24}>
                  <Text type={'text_bold'}>{t('order.order_detail.order_mark', 'Order mark')}</Text>
                </MarginBox>
              </CardPanel>
              <CardPanel>
                <Text type={'light_14_black_65'}>{orderMark}</Text>
              </CardPanel>
            </Flex>
            <Pipeline size={'100%'} />
            <Flex />
          </Flex>
        </>
      )}
    </OrderDetailCard>
  );
};
