/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ChecklistGroup } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/model/ChecklistGroup';
import { GetIAMServiceChecklistResponse } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMServiceChecklistResponse';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Document, Image as PdfImage, Page, pdf, Text as PdfText, View } from '@react-pdf/renderer';
import { PictureCarPlaceholder } from 'components/Pdf/PdfSvg';
import { Checkbox, LeadText, PageNumber, SpecialTable, Title } from 'components/Pdf/PdfUI';
import { fonts, styles } from 'components/Pdf/PdfUI.styles';
import { VehicleContext } from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleContext';
import { CenterFlex } from 'UI';
import { textFormatter } from 'utils';

export const printChecklist = async (
  checklist: GetIAMServiceChecklistResponse | undefined,
  vehicleDetail: VehicleDetail | undefined,
  carImageUrl: string | undefined,
) => {
  const pdfData: ChecklistPdfData = {
    date: new Date(),
    headline: checklist?.headline ?? '-',
    text: {
      title: <Trans i18nKey="catalog.maintenance.maintenance_plan_checklist.title">Maintenance plan list</Trans>,
      vehicle: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.vehicle">Vehicle</Trans>,
      date: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.date">Date</Trans>,
      ok: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.ok">Okay</Trans>,
      incorrect: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.incorrect">Incorrect</Trans>,
      solved: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.solved">Solved</Trans>,
      operationsList: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.operations_list">Operations list</Trans>,
    },
    checklist: checklist?.checklist ?? [],
  };
  const blob = await pdf(
    <ChecklistPdfPage data={pdfData} vehicle={vehicleDetail} carImageUrl={carImageUrl} />,
  ).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};

export const ChecklistPdfPage = ({
  data,
  vehicle,
  carImageUrl,
}: {
  data: ChecklistPdfData;
  vehicle: VehicleDetail | undefined;
  carImageUrl: string | undefined;
}) => (
  <Document>
    <Page size={'A4'} style={styles.page}>
      <PageNumber />
      <Header data={data} />
      <VehicleContext vehicleDetail={vehicle} carImageUrl={carImageUrl} />
      {data.checklist.map((group) => (
        <GroupTable
          key={group.name}
          checklist={group}
          ok={data.text.ok}
          incorrect={data.text.incorrect}
          solved={data.text.solved}
        />
      ))}
    </Page>
  </Document>
);

export const Header = ({ data }: { data: ChecklistPdfData }) => {
  const { t } = useTranslation();
  const title = t('catalog.maintenance.maintenance_plan_checklist.title', 'Maintenance plan list');
  const dateLabel = t('catalog.maintenance.new_maintenance_plan.date', 'Date');
  return (
    <View
      fixed
      style={{ height: 70 }}
      render={({ pageNumber }) =>
        pageNumber == 1 ? (
          <View>
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={styles.col}>
                  <Title>{title}</Title>
                </View>
              </View>
              <View style={[styles.column, { alignItems: 'flex-end' }]}>
                <View style={styles.col}>
                  <LeadText>
                    {dateLabel}
                    {`: ${textFormatter.formatDate(data.date)}`}
                  </LeadText>
                </View>
              </View>
            </View>
            <LeadText>{data.headline}</LeadText>
          </View>
        ) : (
          <View style={[styles.row, { height: 50 }]}>
            <View style={[styles.column]}>
              <View style={[styles.row, { height: 30, alignContent: 'center' }]}>
                <Title>{title}</Title>
              </View>
            </View>
            <View style={[styles.column, { alignItems: 'flex-end' }]}>
              <View style={[styles.col, { marginBottom: 30 }]}>
                <LeadText>
                  {dateLabel}
                  {`: ${textFormatter.formatDate(data.date)}`}
                </LeadText>
              </View>
            </View>
          </View>
        )
      }
    />
  );
};

const GroupTable = ({
  checklist,
  ok,
  incorrect,
  solved,
  rowscount,
}: {
  checklist: ChecklistGroup;
  ok: ReactNode;
  incorrect: ReactNode;
  solved: ReactNode;
  rowscount?: number[];
}) => {
  return (
    <SpecialTable
      rowscount={rowscount}
      data={checklist.checkItems}
      columns={[
        {
          title: checklist.name,
          render: () => <View style={{ height: 30 }}></View>,
          renderNode: (item) => (
            <View
              style={{
                marginLeft: 12,
                marginRight: 12,
                height: 30,
                maxHeight: 30,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PdfText style={fonts.input_text}>{item}</PdfText>
            </View>
          ),
          width: 400,
        },
        {
          title: ok,
          render: () => <></>,
          renderNode: () => <Checkbox></Checkbox>,
          width: 50,
        },
        {
          title: incorrect,
          render: () => <></>,
          renderNode: () => <Checkbox></Checkbox>,
          width: 50,
        },
        {
          title: solved,
          render: () => <></>,
          renderNode: () => <Checkbox></Checkbox>,
          width: 50,
        },
      ]}
    />
  );
};

export const CarDetailHeaderImage = ({ imageUrl, signature }: { imageUrl?: string; signature?: string }) => {
  const signedUrl = signature ? `${imageUrl}?${signature}` : imageUrl;
  return (
    <View style={{ width: 50, height: 50 }}>
      {imageUrl ? (
        <>
          <PdfImage
            src={{
              uri: signedUrl ?? '',
              method: 'GET',
              headers: {},
              body: '',
            }}
            style={{ width: '50px', height: '50px' }}
          />
        </>
      ) : (
        <DefaultCarImage />
      )}
    </View>
  );
};

const DefaultCarImage = () => {
  return (
    <CenterFlex>
      <PictureCarPlaceholder />
    </CenterFlex>
  );
};

export interface ChecklistPdfData {
  date: Date;
  checklist: ChecklistGroup[];
  headline: string;
  text: {
    title: ReactNode;
    vehicle: ReactNode;
    date: ReactNode;
    ok: ReactNode;
    incorrect: ReactNode;
    solved: ReactNode;
    operationsList: ReactNode;
  };
}
