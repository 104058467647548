/* eslint-disable max-len */
import { ElectronicPartsRepairRow } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRow';
import { ElectronicPartsRepairRowCorrect } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRowCorrect';
import { ElectronicPartsRepairRowError } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/model/ElectronicPartsRepairRowError';
import { APP_NAMESPACE } from 'app/App.constants';
import {
  API_CALL_ERROR,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  ModalStatusType,
  NO_FILE,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import { SEARCH_STATUS } from 'utils';

export const ELECTRONIC_PARTS_REPAIR_NAMESPACE = `${APP_NAMESPACE}/ELECTRONIC_PARTS_REPAIR`;

export const FETCHING_FILE = 'fetching_file';
export const REPLACING_FILE = 'replacing_file';
export const DELETING_FILE = 'deleting_file';
export const FILE_UPLOADED = 'file_uploaded';
export const FILE_REPLACED = 'file_replaced';
export const SAME_FILE = 'same_file';
export const FILE_FETCHED = 'file_fetched';
export const FILE_DELETED = 'file_deleted';

export const UPLOAD_FILE = `${ELECTRONIC_PARTS_REPAIR_NAMESPACE}/UPLOAD_FILE`;
export const REPLACE_FILE = `${ELECTRONIC_PARTS_REPAIR_NAMESPACE}/REPLACE_FILE`;
export const GET_FILE = `${ELECTRONIC_PARTS_REPAIR_NAMESPACE}/GET_FILE`;

export type ElectronicPartsFileStatus =
  | typeof FETCHING_FILE
  | typeof REPLACING_FILE
  | typeof NO_FILE
  | typeof UPLOADING_FILE
  | typeof FILE_UPLOADED
  | typeof FILE_FORMAT_ERROR
  | typeof FILE_STRUCTURE_ERROR
  | typeof FILE_REPLACED
  | typeof SAME_FILE
  | typeof FILE_FETCHED
  | typeof API_CALL_ERROR;

export interface ElectronicPartsRepairState {
  fileUploadStatus: ElectronicPartsFileUploadStatus;
  fileInformation: ElectronicPartsFileInformation;
  fileInformationForRequest: ElectronicPartsFileInformation;
  fileRows: ElectronicPartsRepairRow[];
  searchStatus: SEARCH_STATUS;
}

interface ElectronicPartsFileUploadStatus {
  fileStatus: ElectronicPartsFileStatus;
  correctRows: ElectronicPartsRepairRowCorrect[];
  errorRows: ElectronicPartsRepairRowError[];
  modalStatus: ModalStatusType;
  isModalOpen: boolean;
  hasFile: boolean;
}

export interface ElectronicPartsFileInformation {
  fileName: string;
  fileSize: number;
  uploadTime: string | undefined;
}
