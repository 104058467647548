import React, { ReactNode } from 'react';
import { Space as AntSpace } from 'antd-v5';

interface Spaceprops {
  children: ReactNode;
  direction?: 'vertical' | 'horizontal';
  size?: number | 'large' | 'middle' | 'small';
  wrap?: boolean;
}
export const Space = ({ children, direction, size, wrap }: Spaceprops) => {
  return (
    <AntSpace direction={direction} size={size} wrap={wrap}>
      {children}
    </AntSpace>
  );
};
