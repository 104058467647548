import { Table } from 'antd';
import styled from 'styled-components';
import { Flex } from 'UI';

export const STable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    background-color: white;
  }
` as typeof Table;

export const Scrollable = styled.div`
  height: 574px;
  overflow-y: auto;

  :hover {
    overflow-y: auto;
  }
`;

export const ScrollableFlex = styled(Flex)`
  height: 574px;
  overflow-y: auto;

  :hover {
    overflow-y: auto;
  }
`;

export const BorderedFlex = styled(Flex)`
  border: solid 1px ${({ theme }) => theme.color.grey_very_light_2};
`;
