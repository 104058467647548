/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFileElectricPartsRepairRequestSaga,
  getElectronicPartsRepairSearchStatus,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';

export const useFetchElectronicPartsRepair = () => {
  const dispatch = useDispatch();
  const status = useSelector(getElectronicPartsRepairSearchStatus);

  useEffect(() => {
    if (!status) {
      dispatch(fetchFileElectricPartsRepairRequestSaga());
    }
  }, [dispatch, status]);
};
