import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/common/StatusType';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { InlineNotification } from 'components/Notification/InlineNotification';
import {
  getDuplicatePromotions,
  hasDuplicateCommercialFamilyCodes,
  hasDuplicateReferences,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import { Flex, MarginBox, Text } from 'UI';
import { DuplicatePromotionRow } from './DuplicatePromotionRow';

const getMessageDescription = (t: TFunction, promotion: CreatePromotionModel, publishedPromotion: boolean): string => {
  if (hasDuplicateCommercialFamilyCodes(promotion)) {
    return `${t(
      'backoffice.promotion.commercial_family.already_promoted.error_message.description',
      'Commercial family already part of an existing promotion.',
    )} ${
      publishedPromotion
        ? t('backoffice.promotion.already_promoted.adjust_dates', 'Please adjust publication dates.')
        : t(
            'backoffice.promotion.already_promoted.adjust_dates_or_price',
            'Please adjust publication dates or price options.',
          )
    }`;
  }
  if (hasDuplicateReferences(promotion)) {
    return `${t(
      'backoffice.promotion.references.already_promoted.error_message.description',
      'Several references already part of an existing promotion.',
    )} ${
      publishedPromotion
        ? t('backoffice.promotion.already_promoted.adjust_dates', 'Please adjust publication dates.')
        : t(
            'backoffice.promotion.already_promoted.adjust_dates_or_price',
            'Please adjust publication dates or price options.',
          )
    }`;
  }
  return `${t(
    'backoffice.promotion.already_promoted.error_message.description',
    'Commercial family or reference already part of an existing promotion.',
  )} ${
    publishedPromotion
      ? t('backoffice.promotion.already_promoted.adjust_dates', 'Please adjust publication dates.')
      : t(
          'backoffice.promotion.already_promoted.adjust_dates_or_price',
          'Please adjust publication dates or price options.',
        )
  }`;
};

interface DuplicatePromotionsDisplayProps {
  promotion: CreatePromotionModel;
  setCurrentStep: (x: number) => void;
  promotionStatus: StatusType | undefined;
}

export const DuplicatePromotionsDisplay = ({
  promotion,
  setCurrentStep,
  promotionStatus,
}: DuplicatePromotionsDisplayProps) => {
  const { t } = useTranslation();
  const publishedPromotion = promotionStatus === 'PUBLISHED';
  const duplicatePromotions = useSelector((state: RootState) =>
    getDuplicatePromotions(state, promotion.duplicatePromotionIds),
  );

  if (promotion.duplicatePromotionIds.length === 0) {
    return <></>;
  }

  const handleAction = () => {
    setCurrentStep(2);
  };

  return (
    <>
      <MarginBox my={15}>
        <InlineNotification
          displayStyle={'error'}
          message={t(
            'backoffice.promotion.already_promoted.error_message.overlapping_date',
            'Overlapping date of existing promotion',
          )}
          description={getMessageDescription(t, promotion, publishedPromotion)}
          action={
            <>
              {!publishedPromotion && (
                <Flex onClick={handleAction} justify={'flex-end'}>
                  <Text type={'text'} displayStyle={'link'} decoration={'underline'} cursor={'pointer'}>
                    {t('backoffice.promotion.action.change_promoted_products', 'Change promoted products')}
                  </Text>
                </Flex>
              )}
            </>
          }
        />
      </MarginBox>
      {duplicatePromotions?.map((promo) => (
        <DuplicatePromotionRow key={promo.promotionId} promotion={promo} />
      ))}
      <MarginBox mt={30} />
    </>
  );
};
