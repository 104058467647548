import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { FileAltIcon } from 'assets/icons';
import { AddToEstimateButton, AddToEstimateDialog } from 'components/AddToEstimate';
import { useDisclosure } from 'components/Dialog';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { getCurrentEstimateId, getEstimateVehicleKey } from 'domains/estimate/Estimate.store';
import { BlackButton, Flex, Icon } from 'UI';

interface AddToEstimateButtonAndDialogProps {
  handleAddToEstimateClick: () => void;
  isVehicleCatalog?: boolean;
  size?: 'sm' | 'lg';
  disabled?: boolean;
  displayed?: boolean;
  buttonComponent?: 'icon' | 'button' | ReactNode;
}

export const AddToEstimateButtonAndDialog = ({
  handleAddToEstimateClick,
  isVehicleCatalog = false,
  size = 'sm',
  disabled = false,
  displayed = true,
  buttonComponent = 'icon',
}: Readonly<AddToEstimateButtonAndDialogProps>) => {
  const disclosure = useDisclosure();
  const { t } = useTranslation();
  const { onOpen } = disclosure;
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const estimateId = useSelector(getCurrentEstimateId);
  const estimateVehicleKey = useSelector((state: RootState) => getEstimateVehicleKey(state, estimateId));

  if (!displayed) {
    return <></>;
  }

  function handleAddToEstimateClickLocal() {
    onOpen();
  }

  const openInCatalog = isVehicleCatalog && estimateVehicleKey !== undefined;

  const renderButton = () => {
    if (buttonComponent === 'icon') {
      return (
        <AddToEstimateButton handleAddToEstimateClick={handleAddToEstimateClickLocal} size={size} disabled={disabled} />
      );
    } else if (buttonComponent === 'button') {
      return (
        <BlackButton onClick={handleAddToEstimateClickLocal} disabled={disabled}>
          <Flex gap={8} align={'center'}>
            <Icon IconComponent={FileAltIcon} size={20} />
            {t('catalog.action.add_to_estimate', 'Add to estimate')}
          </Flex>
        </BlackButton>
      );
    } else {
      return buttonComponent;
    }
  };

  return (
    <>
      <AddToEstimateDialog
        vehicleDetail={vehicleDetail}
        disclosure={disclosure}
        handleConfirm={handleAddToEstimateClick}
        openInCatalog={openInCatalog}
      />
      {renderButton()}
    </>
  );
};
