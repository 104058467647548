import React from 'react';
import { useTranslation } from 'react-i18next';
import { CrossSellingRow } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/model/CrossSellingRow';
import { SFlex } from 'pages/BackOfficePage/BackOfficeCategories/CrossSelling/CrossSelling.styled';
import { Flex, InfiniteScroll, MarginBox, Text, useInfiniteScroll } from 'UI';

const FileRows = ({ fileRows }: { fileRows: CrossSellingRow[] }) => {
  const { t } = useTranslation();
  const { hasMore, loadMore, currentData } = useInfiniteScroll<CrossSellingRow>({
    data: fileRows,
    chunkSize: 50,
  });
  if (!fileRows) return <></>;
  const Cell = ({ children }: { children: React.ReactNode }) => {
    return (
      <SFlex justify={'center'} align={'center'} minHeight={50}>
        {children}
      </SFlex>
    );
  };
  return (
    <Flex direction={'column'}>
      <MarginBox ml={100} mt={50}>
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          <Flex>
            <Cell>
              <Text type={'h6'}>{t('cross_selling.upload.file.source', 'Source reference')}</Text>
            </Cell>
            <Cell>
              <Text type={'h6'}>{t('cross_selling.upload.file.cross', 'Cross selling reference')}</Text>
            </Cell>
          </Flex>
          {currentData.map((row, index) => {
            return (
              <Flex key={`${row.sourceReference}-${index}`}>
                <Cell>
                  <Text type={'lead'}>{row.sourceReference}</Text>
                </Cell>
                <Cell>
                  <Text type={'lead'}>{row.crossSellingReference}</Text>
                </Cell>
              </Flex>
            );
          })}
        </InfiniteScroll>
      </MarginBox>
    </Flex>
  );
};

export default FileRows;
