import React, { ReactNode } from 'react';

const StopPropagation = ({ id, children }: { id?: string; children: ReactNode }) => {
  return (
    <div id={id} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
};

export default StopPropagation;
