import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { useFetchIAMTechnicalDataDetails } from 'domains/catalog/Catalog.requests';
import {
  getVehicleTechnicalDataCategoryL2List,
  getVehicleTechnicalDataCategoryL3Ids,
} from 'domains/catalog/Catalog.store';
import Filter from 'pages/CatalogPage/IAM/VehicleTechnicalData/Filter';
import { SFilterContainer } from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalData.styled';
import VehicleTechnicalDataL2 from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalDataL2';
import { Flex } from 'UI';

export const VehicleTechnicalDataSection = ({ categoryId }: { categoryId: string | undefined }) => {
  const categoryL2List = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL2List(state, categoryId));
  const categoryL2Labels = categoryL2List?.map((category) => category.label);
  const categoryL3Ids = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL3Ids(state, categoryId));

  useFetchIAMTechnicalDataDetails(categoryL3Ids ?? [], categoryId);

  return (
    <Flex direction={'row'}>
      <SFilterContainer>
        <Filter categories={categoryL2Labels} />
      </SFilterContainer>
      <Flex direction={'column'}>
        {categoryL2List?.map((l2) => {
          return (
            <VehicleTechnicalDataL2
              key={`vehicle-technical_data-category_l2-${l2.id}`}
              l3Categories={l2.l3List}
              categoryLabel={l2.label}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};
