import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { fetchChangeGarageEmailRequest } from 'domains/garage/Garage.store';
import { SInput, SForm } from 'pages/EstimatePage/SettingTab/ChangeEmail.styled';
import { Flex, MarginBox, Text, YellowButton } from 'UI';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';

export interface EmailFormProps {
  value?: string;
  setIsEmailEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

function EmailForm(props: EmailFormProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>(props.value ?? '');

  const handleOutsideClick = () => {
    props.setIsEmailEditable(false);
  };
  const ref = useOutsideClick<HTMLDivElement>(handleOutsideClick);

  const handleValidateEmail = () => {
    props.setIsEmailEditable(false);
    dispatch(fetchChangeGarageEmailRequest(value));
  };

  return (
    <SForm form={form}>
      <Flex ref={ref}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: t('estimate.settings.email.wrong_email_address', 'Wrong e-mail address') as string,
            },
          ]}
          initialValue={value}
        >
          <SInput bordered onChange={(e) => setValue(e.target.value)} />
        </Form.Item>
        <MarginBox ml={15} />
        <Form.Item shouldUpdate>
          {() => (
            <YellowButton
              stretch={false}
              onClick={handleValidateEmail}
              size={'middle'}
              disabled={
                !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
              }
            >
              {t('estimate.settings.email.validate', 'Validate')}
            </YellowButton>
          )}
        </Form.Item>
      </Flex>
    </SForm>
  );
}

export interface ChangeEmailProps {
  email?: string;
}

export function ChangeEmail(props: ChangeEmailProps) {
  const { t } = useTranslation();
  const [isEmailEditable, setIsEmailEditable] = useState<boolean>(false);

  const handleChangeEmail = () => {
    setIsEmailEditable(true);
  };

  return (
    <Flex direction={'column'} minHeight={56}>
      {isEmailEditable ? (
        <EmailForm value={props.email} setIsEmailEditable={setIsEmailEditable} />
      ) : props.email === '' || props.email === undefined ? (
        <Text type={'text_dim'} displayStyle={'link'} onClick={handleChangeEmail} hoverUnderLine cursor={'pointer'}>
          {t('estimate.settings.email.add_email_address', 'Add e-mail address')}
        </Text>
      ) : (
        <>
          <Text type={'text_dim'}>{props.email}</Text>
          <Text type={'text_dim'} displayStyle={'link'} onClick={handleChangeEmail} hoverUnderLine cursor={'pointer'}>
            {t('estimate.settings.email.change_email_address', 'Change e-mail address')}
          </Text>
        </>
      )}
    </Flex>
  );
}
