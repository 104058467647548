import React from 'react';
import { TFunction } from 'i18next';
import { ExclamationTriangleIcon } from 'assets/icons';
import { theme } from 'styles';
import { Icon, notifyWithButtons, WithTooltip } from 'UI';

export const EstimateMarginAlert = ({ designation, t }: { designation: string; t: TFunction }) => {
  const handleAlerOnClick = () => {
    notifyWithButtons({
      header: t('common.price.margin.notification.title', 'Attention needed on your Margins') ?? '',
      description: [
        t(
          'common.price.margin.notification.description',
          '{{designation}} has a negative or no margin, please adjust your entry.',
          { designation: designation ?? '[Designation]' },
        ),
      ],
      onPrimaryButtonClick: () => {
        return;
      },
      primaryButtonText: t('common.action.close', 'Close'),
    });
  };

  return (
    <WithTooltip title={t('common.price.negative_or_zero_margin', 'Negative or zero Margin')} placement={'top'}>
      <Icon
        IconComponent={ExclamationTriangleIcon}
        onClick={handleAlerOnClick}
        size={20}
        display={'block'}
        color={theme.color.red}
      />
    </WithTooltip>
  );
};
