import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DealerType,
  Information,
  StatusType,
  TextSection,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { TFunction } from 'i18next';
import { DotsMoreIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import Popover from 'components/Popover';
import { fetchInformationsRequestSaga } from 'domains/information/Information.store';
import { LoadedInformations, STATUS_ARCHIVED } from 'domains/information/Information.types';
import { getUserContext } from 'domains/user';
import { isFilled } from 'pages/BackOfficePage/BackOfficeCategories/Information/CreateInformation';
import { SText } from 'pages/BackOfficePage/BackOfficeCategories/Information/Information/Information.styled';
import InformationActions from 'pages/BackOfficePage/BackOfficeCategories/Information/Information/InformationActions';
import { STable } from 'pages/EstimatePage/EstimatePage.styled';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { theme } from 'styles';
import { CenterFlex, Flex, Icon, InfiniteScroll, Text } from 'UI';
import { FOUND, textFormatter } from 'utils';

const MAX_LANGUAGES_TITLE = 3;

const getDataColumns = (t: TFunction, tab: StatusType, webLanguage: string) => {
  const actionsColumn =
    tab !== STATUS_ARCHIVED
      ? {
          title: GetTitle(t('announcement.published.table.action', 'Action')),
          dataIndex: 'informationId',
          key: 'action',
          render: function renderActions(informationId: string) {
            return (
              <Popover
                content={<InformationActions tab={tab} informationId={informationId} />}
                trigger={'click'}
                placement={'bottomLeft'}
                showArrow={false}
              >
                <CenterFlex>
                  <Icon
                    IconComponent={DotsMoreIcon}
                    size={24}
                    color={theme.color.grey65}
                    hoverFill={theme.color.brand_black}
                  />
                </CenterFlex>
              </Popover>
            );
          },
        }
      : {};
  return [
    {
      title: GetTitle(t('announcement.published.table.title', 'Title')),
      dataIndex: 'textSections',
      key: 'textSections',
      render: function renderTitle(textSections: TextSection[]) {
        if (textSections.length === 0) {
          return <></>;
        }
        const selectedLanguage =
          textSections.find((section) => section.language?.toLowerCase() === webLanguage.toLowerCase()) ||
          textSections.find((section) => section.language?.toLowerCase() === 'en') ||
          textSections[0];
        const title = isFilled(selectedLanguage.title) ? selectedLanguage.title : selectedLanguage.description;
        const allLanguages =
          textSections.length > MAX_LANGUAGES_TITLE
            ? t('information.multilingual', 'ML')
            : textSections.map((section) => section.language?.toUpperCase()).join(', ');
        return (
          <Flex direction={'column'}>
            <Flex>
              <SText type={'text_bold'}>{title}</SText>
              <SText type={'text_bold'}>
                &nbsp;
                {`- (${allLanguages})`}
              </SText>
            </Flex>
          </Flex>
        );
      },
    },
    {
      title: GetTitle(t('announcement.published.table.audience', 'Audience')),
      dataIndex: 'targetAudience',
      key: 'targetAudience',
      render: function renderTargetAudience(targetAudience: DealerType[]) {
        return <>{targetAudience.join(' - ')}</>;
      },
    },
    {
      title: GetTitle(t('announcement.published.table.author', 'Author')),
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: GetTitle(t('announcement.published.table.date', 'Date')),
      key: 'date',
      render: function renderCreationDate(information: Information) {
        const startDate =
          information.publicationStart && textFormatter.formatDateNumeric(new Date(information.publicationStart));
        const endDate =
          information.publicationEnd && textFormatter.formatDateNumeric(new Date(information.publicationEnd));
        return (
          <Flex direction={'column'} align={'baseline'}>
            {startDate && (
              <Text type={'light_14_black_85'}>
                <Trans i18nKey={'announcement.published.table.date.from'} tOptions={{ startDate }}>
                  From: {{ startDate }}
                </Trans>
              </Text>
            )}
            {endDate && (
              <Text type={'light_14_black_85'}>
                <Trans i18nKey={'announcement.published.table.date.until'} tOptions={{ endDate }}>
                  Until: {{ endDate }}
                </Trans>
              </Text>
            )}
          </Flex>
        );
      },
    },
    actionsColumn,
  ];
};

interface InformationTableProps {
  informations: LoadedInformations;
  tab: StatusType;
}

const InformationTable = ({ informations, tab }: InformationTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webLanguage = useSelector(getUserContext).webLanguage;

  const getNoDataText = () => {
    switch (tab) {
      case 'PUBLISHED':
        return t('backoffice.information.no_publish_data', 'No published Information');
      case 'SAVED':
        return t('backoffice.information.no_saved_data', 'No saved Information');
      case 'PLANNED':
        return t('backoffice.information.no_planned_data', 'No planned Information');
      case 'ARCHIVED':
        return t('backoffice.information.no_archived_data', 'No archived Information');
      default:
        return '';
    }
  };

  const loadMore = () => {
    dispatch(fetchInformationsRequestSaga({ status: tab, cursor: informations.cursor }));
  };

  const status = (informations.data?.length ?? 0) === 0 ? informations.status : FOUND;

  return (
    <DataContainer
      data={status}
      NotFound={() => (
        <CenterFlex>
          <Text type={'h2'}>{getNoDataText()}</Text>
        </CenterFlex>
      )}
    >
      <InfiniteScroll loadMore={loadMore} hasMore={informations.hasNextPage}>
        <STable
          columns={getDataColumns(t, tab, webLanguage)}
          dataSource={informations.data}
          rowKey={'informationId'}
          pagination={false}
        />
      </InfiniteScroll>
    </DataContainer>
  );
};
export default InformationTable;
