const searchHistory = (): void => {
  history.back();
};

const cart = ({ cartState, prev }: { cartState: number; prev: () => void }): void => {
  if (cartState === 1) prev();
  else history.back();
};

export const goBack = {
  cart: (props: { cartState: number; prev: () => void }): void => cart(props),
  searchHistory,
};
