/* eslint-disable max-len */
import {
  LaborTime,
  LaborTimeFilterOption,
} from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetLaborTimesResponse';
import { TFunction } from 'i18next';
import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const LABOR_TIME_NAMESPACE = `${APP_NAMESPACE}/LABOR_TIME`;

export const GET_LABOR_TIMES_REQUEST = `${LABOR_TIME_NAMESPACE}/GET_LABOR_TIMES_REQUEST`;
export const GET_LABOR_TIMES_QUICK_ACCESS_REQUEST = `${LABOR_TIME_NAMESPACE}/GET_LABOR_TIMES_QUICK_ACCESS_REQUEST`;

export interface LaborTimeState {
  vehicleLaborTimes: Map<string, VehicleLaborTimes>;
}

export interface VehicleLaborTimes {
  genericPartToLaborTimeIds: Map<string, SearchData<string[]>>;
  laborTimes: Map<string, SearchData<LaborTimeLocal>>;
  elements: Map<string, LaborTimeFilterOption>;
  operations: Map<string, LaborTimeFilterOption>;
}

export interface LaborTimeLocal extends LaborTime {
  subtableLabel?: LaborSubtableLabel; // used for subtable header in the table
  isIncluded: boolean;
  isSubLaborTime: boolean;
  subtable: LaborTimeLocal[];
  uuid: string;
}

export type LaborSubtableLabel = 'included' | 'not_included' | 'additional';

export function getLaborSubtableLabelText(label: LaborSubtableLabel | undefined, t: TFunction): string {
  switch (label) {
    case 'included':
      return t('catalog.parts.category.car_parts.labor_time.included', 'Included:');
    case 'not_included':
      return t('catalog.parts.category.car_parts.labor_time.not_included', 'Not included:');
    case 'additional':
      return t('catalog.parts.category.car_parts.labor_time.additional', 'Additional:');
    default:
      return '';
  }
}
