import {
  INFORMATION_ARCHIVE_URL,
  INFORMATION_GET_BANNERS_URL,
  INFORMATION_GET_URL,
  INFORMATION_PUBLISH_URL,
  INFORMATION_SAVE_URL,
  INFORMATION_TEMPLATE_SAVE_URL,
  INFORMATIONS_GET_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import { ChangeInformationStatus } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/ChangeInformationStatus';
import { GetInformation } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformation';
import { GetInformationBanners } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformationBanners';
import { GetInformations } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformations';
import { PublishInformation } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/PublishInformation';
import { SaveInformation } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/SaveInformation';
import { SaveInformationTemplate } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/SaveInformationTemplate';

import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendGetInformationRequest(request: GetInformation): WebSocketAction {
  return wsSendAction(INFORMATION_GET_URL, request);
}

export function sendGetInformationsRequest(request: GetInformations): WebSocketAction {
  return wsSendAction(INFORMATIONS_GET_URL, request);
}

export function sendSaveInformationRequest(request: SaveInformation): WebSocketAction {
  return wsSendAction(INFORMATION_SAVE_URL, request);
}

export function sendArchiveInformationRequest(request: ChangeInformationStatus): WebSocketAction {
  return wsSendAction(INFORMATION_ARCHIVE_URL, request);
}

export function sendPublishInformationRequest(request: PublishInformation): WebSocketAction {
  return wsSendAction(INFORMATION_PUBLISH_URL, request);
}

export function sendGetInformationBannersRequest(request: GetInformationBanners): WebSocketAction {
  return wsSendAction(INFORMATION_GET_BANNERS_URL, request);
}

export function sendSaveInformationTemplateRequest(request: SaveInformationTemplate): WebSocketAction {
  return wsSendAction(INFORMATION_TEMPLATE_SAVE_URL, request);
}
