import React from 'react';
import { Table } from 'antd';
import { ManageCookiesButton } from 'components/Page/Footer/Footer.styled';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const CookiesRO = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>POLITICA PRIVIND MODULELE COOKIE</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          Protejarea datelor dvs. online este o prioritate pentru Renault. Site-ul nostru web utilizează tehnologii
          precum module cookie pentru a vă oferi o experiență de înaltă calitate, fluidă și personalizată.
        </Paragraph>
        <Paragraph>
          Puteți reveni în orice moment asupra alegerilor efectuate de dvs. privind modulele cookie utilizând
          instrumentul nostru de gestionare a modulelor cookie:{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              GESTIONEAZĂ ALEGERILE MELE
            </button>
          </ManageCookiesButton>
          .
        </Paragraph>
        <Section>
          <SectionHeader title={'1. CINE SUNTEM?'} underline />
          <Paragraph>
            Bun venit pe site-ul web{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              {'https://rpartstore.renault.com'}
            </Link>
            , publicat de Renault SAS, reprezentat in Romania de Renault Commercial Roumanie SRL, cu sediul social în
            București, sector 6, strada Preciziei nr. 3G, Clădirea A, parter, camera AP 10, CUI/CIF RO13943110. Renault
            SAS este operator de date independent în ceea ce privește datele colectate prin intermediul modulelor cookie
            și al altor dispozitive de urmărire citite sau plasate pe dispozitivul dvs. (în continuare „noi”, „nouă”,
            „nostru”).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. CE SUNT MODULELE COOKIE?'} underline />
          <Paragraph>
            Un modul cookie este un fișier informatic de mici dimensiuni, un dispozitiv de urmărire plasat și citit
            atunci când se vizitează un site, se utilizează o aplicație mobilă, se citește un e-mail, se instalează sau
            se utilizează un software, indiferent de tipul de terminal utilizat (computer, smartphone, cititor digital,
            consolă de jocuri video conectată la internet etc.).
          </Paragraph>
          <Paragraph>
            Atunci când se consultă un site, modulele cookie pot trimite informații către browserul utilizatorului și
            permit browserului să trimită informații înapoi către site-ul original (de exemplu, un ID de sesiune sau
            alegerea limbii).
          </Paragraph>
          <Paragraph>
            Modulele cookie nu ne permit să vă identificăm direct, ci doar să colectăm informații specifice despre
            dispozitivul dvs., despre browser, interacțiunile cu site-ul sau aplicația pe care îl/o utilizați și, în
            unele cazuri, localizarea dvs. geografică.
          </Paragraph>
          <Paragraph>
            Tehnologiile utilizate pot face legătura între mai multe dispozitive pe care le-ați folosit pentru a
            determina, cu o probabilitate suficientă, că aceste dispozitive vă aparțin.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. PENTRU CE SUNT UTILIZATE MODULELE COOKIE PE ACEST SITE?'} underline />
          <Paragraph>Acest site folosește diverse tipuri de module cookie.</Paragraph>
          <SectionHeader title={'Modul cookie tehnice'} mb={0} />
          <Paragraph>
            Aceste module cookie sunt menite să permită sau să faciliteze comunicarea electronică (detectarea erorilor
            de conectare, identificarea punctelor de conectare, securitatea site-ului web etc.) sau să ofere servicii de
            comunicare online pe care le-ați putea solicita în mod special (preferințe de afișare pe ecran, salvarea
            informațiilor introduse în formulare, coș de cumpărături (după caz), acces la contul dvs. de utilizator,
            chat sau chatbot pe site-ul nostru web etc.).
          </Paragraph>
          <Paragraph>Aceste modulele cookie sunt necesare pentru buna funcționare a site-ului nostru web.</Paragraph>
          <Paragraph>
            Nu vă puteți opune utilizării acestor module cookie și nu este necesar acordul dvs., deoarece serviciile de
            pe site-ul nostru web se bazează pe aceste module cookie.
          </Paragraph>
          <SectionHeader title={'Module cookie de analiză'} mb={0} />
          <Paragraph>
            Aceste module cookie sunt menite să analizeze traficul pe site-ul web, să analizeze experiența
            utilizatorului sau pur și simplu să colecteze semnale agregate despre fluxul de vizite pe site (de exemplu,
            alegerea limbii, pagina anterioară vizitată, marca de timp a vizitei, alegerea modulelor cookie).
          </Paragraph>
          <Paragraph>
            Aceste module cookie pot prelucra date referitoare la paginile vizitate, timpul petrecut pe paginile
            vizitate, browserul pe care îl utilizați pentru a vizita aceste pagini, utilizarea de către dvs. a chatului
            sau chatbotului pe site-ul nostru web etc.
          </Paragraph>
          <Paragraph>
            Datele colectate de aceste module cookie pot fi folosite și pentru a crea algoritmi și profiluri pentru a
            analiza interesele și preferințele dvs. potențiale față de produsele și serviciile noastre. Putem combina
            aceste date cu alte informații, cum ar fi informații din baza noastră de date cu clienții.
          </Paragraph>
          <Paragraph>
            Utilizarea și stocarea acestor module cookie fac în general obiectul consimțământului dvs.
          </Paragraph>
          <Paragraph>
            În anumite condiții, aceste module cookie pot fi plasate/citite fără acordul dvs., de exemplu dacă scopul
            modulelor cookie este doar de a analiza conținutul pe care l-ați vizitat.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'4. CE MODULE COOKIE SE FOLOSESC PE ACEST SITE?'} underline />
          <Paragraph>
            Lista de mai jos conține toate modulele cookie stocate și/sau utilizate de acest site și principalele
            caracteristici ale acestora. Ori de câte ori este posibil, aceste module cookie sunt găzduite pe servere
            situate în Spațiul Economic European (SEE) sau în Regatul Unit. Cu toate acestea, deoarece unii dintre
            prestatorii noștri de servicii sunt localizați în țări din afara SEE sau a Regatului Unit, datele dvs. cu
            caracter personal sunt prelucrate în aceste țări, care pot avea reglementări privind datele cu caracter
            personal diferite de cele din Uniunea Europeană. În acest caz, (i) acordăm o atenție deosebită asigurării
            faptului că acest transfer este efectuat în conformitate cu reglementările aplicabile și (ii) instituim
            garanții care asigură un nivel adecvat de protecție a datelor dvs. (în special prin utilizarea clauzelor
            contractuale standard ale Comisiei Europene și/sau orice clauze contractuale standard din Regatul Unit (după
            caz)).
          </Paragraph>
          <Paragraph>
            Site-ul nostru poate conține module cookie ale unor terți (agenție de comunicare, servicii analitice etc.),
            permițându-le acestora, pe durata de viață a modulelor cookie, să colecteze informații de navigare
            referitoare la site-ul nostru. Stocarea și utilizarea acestor module cookie de către terți este supusă
            politicilor de confidențialitate ale acestor terți. Vă informăm cu privire la scopul oricăror module cookie
            ale terților de pe site-ul nostru, precum și cu privire la mijloacele pe care le aveți la dispoziție pentru
            a face alegeri cu privire la aceste module cookie.
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Categorie',
                dataIndex: 'guideline',
                key: 'guideline',
                render: (guideline) => (
                  <Link to={guideline} external newTab>
                    EMIS DE RENAULT
                  </Link>
                ),
              },
              {
                title: 'Scop',
                dataIndex: 'type',
                key: 'type',
              },
              { title: 'Deținătorul modulului cookie', dataIndex: 'site', key: 'site' },
            ]}
            dataSource={[
              {
                id: 0,
                site: 'ONE TRUST',
                type: 'TEHNIC',
                guideline: 'https://www.onetrust.com/fr/cookie-policy/',
              },
              {
                id: 1,
                site: 'RENAULT',
                type: 'TEHNIC',
                guideline: 'https://www.renault.fr/cookies.html',
              },
              {
                id: 2,
                site: 'MATOMO',
                type: 'ANALIZĂ',
                guideline: 'https://matomo.org/privacy-policy/',
              },
              {
                id: 3,
                site: 'DYNATRACE',
                type: 'ANALIZĂ',
                guideline: 'https://www.dynatrace.com/company/trust-center/privacy/',
              },
              {
                id: 4,
                site: 'CONTENSQUARE',
                type: 'ANALIZĂ',
                guideline: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'5. CUM SE OBȚINE CONSIMȚĂMÂNTUL DVS.?'} underline />
          <Paragraph>
            Atunci când vizitați pentru prima dată site-ul nostru, vi se cere să acceptați sau să refuzați sau să faceți
            anumite alegeri cu privire la utilizarea modulelor cookie, prin afișarea unui banner informativ dedicat.
          </Paragraph>
          <Paragraph>
            Vă puteți retrage consimțământul în orice moment sau vă puteți modifica alegerile (de exemplu, acceptând
            doar anumite tipuri de module cookie) utilizând instrumentul nostru dedicat:{' '}
            <ManageCookiesButton $color={'brand_alt'}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                GESTIONEAZĂ ALEGERILE MELE
              </button>
            </ManageCookiesButton>
            .
          </Paragraph>
          <Paragraph>
            Vă rugăm să rețineți că, în conformitate cu reglementările, modulele cookie care sunt necesare pentru
            funcționarea site-ului nu fac obiectul consimțământului dvs. și, prin urmare, nu pot fi dezactivate în
            instrumentul nostru de gestionare a modulelor cookie. Puteți împiedica utilizarea acestora prin modificarea
            setărilor browserului dvs. (consultați secțiunea „Cum puteți refuza modulele cookie” de mai jos), însă
            calitatea experienței dvs. pe site poate avea de suferit.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'6.CÂT TIMP ESTE STOCAT CONSIMȚĂMÂNTUL DVS.?'} underline />
          <Paragraph>
            Atunci când acceptați sau refuzați toate sau o parte din modulele cookie de pe site-ul nostru, alegerea dvs.
            este salvată și stocată (și vom păstra o înregistrare a preferințelor dvs.) pentru o perioadă de cel mult 6
            luni. Aceasta înseamnă că, atunci când veți mai consulta site-ul nostru în această perioadă, nu vom solicita
            nicio acțiune din partea dvs. în ceea ce privește modulele cookie, deoarece vom avea o înregistrare a
            preferințelor dvs. stocate.
          </Paragraph>
          <Paragraph>
            După încheierea acestei perioade, vă vom cere din nou să acceptați sau să refuzați utilizarea modulelor
            cookie.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. CUM POT GESTIONA MODULELE COOKIE?'} underline />
          <Paragraph>
            Cum îmi pot exercita dreptul de a mă opune?
            <br />
            În cazul în care nu doriți ca modulele cookie să fie stocate sau citite pe dispozitivul dvs., un cookie de
            refuz va fi stocat pe echipamentul dvs., pentru a ne permite să înregistrăm faptul că v-ați opus utilizării
            modulelor cookie. Dacă ștergeți acest refuz de module cookie, nu vom mai putea recunoaște că ați respins
            utilizarea și stocarea modulelor cookie.
          </Paragraph>
          <Paragraph>
            Aveți la dispoziție mai multe opțiuni de gestionare a modulelor cookie, inclusiv blocarea utilizării
            acestora și/sau ștergerea lor.
          </Paragraph>
          <Paragraph>
            Manager de module cookie
            <br />
            Pentru a vă permite să gestionați modulele cookie stocate și/sau utilizate pe acest site, vă este pus la
            dispoziție un manager de module cookie. În orice moment, făcând clic pe linkul de mai jos, puteți accesa
            configuratorul și vă puteți modifica preferințele, în funcție de tipul de modul cookie în cauză.
          </Paragraph>
          <Paragraph>
            Setările browserului
            <br />
            De asemenea, puteți gestiona modulele cookie prin intermediul browserului dvs.
          </Paragraph>
          <Paragraph>
            Majoritatea browserelor sunt setate în mod implicit să accepte modulele cookie, dar, dacă doriți, puteți
            alege să acceptați toate modulele cookie, să refuzați sistematic modulele cookie sau să alegeți ce module
            cookie acceptați în funcție de proveniența acestora. De asemenea, puteți șterge în mod regulat modulele
            cookie de pe dispozitivul dvs. prin intermediul browserului.
          </Paragraph>
          <Paragraph>
            Totuși, nu uitați să configurați toate browserele de pe diferitele dvs. dispozitive (tablete,
            smartphone-uri, computere etc.).
          </Paragraph>
          <Paragraph>
            Fiecare browser are o configurație diferită pentru gestionarea modulelor cookie și a opțiunilor dvs. Se
            descrie în meniul de ajutor al browserului, unde veți afla cum să vă editați setările privind modulele
            cookie. De exemplu:
            <Ul>
              <Li>
                <Text type={'text'}>
                  pentru Internet Explorer™:{' '}
                  <Link to={'https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'} external newTab>
                    https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                  </Link>{' '}
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  pentru Safari™:{' '}
                  <Link to={'https://support.apple.com/fr-fr/safari'} external newTab>
                    https://support.apple.com/fr-fr/safari
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  pentru Chrome™:{' '}
                  <Link to={'https://support.google.com/chrome/answer/95647?hl=en&hlrm=en'} external newTab>
                    https://support.google.com/chrome/answer/95647?hl=en&hlrm=en
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  pentru Firefox™:{' '}
                  <Link
                    to={'https://support.mozilla.org/en-US/kb/clear-cookies-and-website-data-single-domain-android'}
                    external
                    newTab
                  >
                    https://support.mozilla.org/en-US/kb/clear-cookies-and-website-data-single-domain-android
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  pentru Opera™:{' '}
                  <Link to={'https://help.opera.com/Windows/10.20/fr/cookies.html'} external newTab>
                    https://help.opera.com/Windows/10.20/fr/cookies.html
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Modulele editorilor pentru exprimarea obiecțiilor
            <br />
            Puteți alege să dezactivați unele module cookie ale terților accesând direct pagina emitentului
            respectivului modul cookie. După cum urmează:
          </Paragraph>
          <Paragraph align={'start'}>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Pentru a dezactiva modulele cookie Google Analytics, accesați pagina:{' '}
                  <Link to={'https://tools.google.com/dlpage/gaoptout?hl=fr'} external newTab>
                    https://tools.google.com/dlpage/gaoptout?hl=fr
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Pentru a dezactiva modulele cookie intelliAd, accesați pagina:{' '}
                  <Link to={'https://login.intelliad.com/optout.php?locale=en'} external newTab>
                    https://login.intelliad.com/optout.php?locale=en
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Pentru a dezactiva modulele cookie Adobe, accesați pagina:{' '}
                  <Link to={'https://www.adobe.com/uk/privacy/opt-out.html'} external newTab>
                    https://www.adobe.com/uk/privacy/opt-out.html
                  </Link>
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Platforme pentru obiecții legate de marketing
            <br />
            Mai multe platforme profesionale de publicitate vă permit, de asemenea, să refuzați sau să acceptați module
            cookie utilizate de societățile care sunt membre ale acestor platforme. Aceste mecanisme centralizate nu
            împiedică afișarea reclamelor, ci doar blochează instalarea modulelor cookie, permițând ca reclamele să fie
            adaptate intereselor dvs.
          </Paragraph>
          <Paragraph>
            De exemplu, puteți accesa{' '}
            <Link to={'https://www.youronlinechoices.com/'} external newTab>
              www.youronlinechoices.com
            </Link>{' '}
            pentru a împiedica instalarea modulelor cookie pe dispozitivul dvs. Acest site este găzduit de
            profesioniștii în publicitate digitală ai asociației europene EDAA (European Digital Advertising Alliance –
            Alianța Europeană pentru Publicitate Digitală) și gestionat în Franța de Biroul Interactiv de Publicitate
            din Franța.
          </Paragraph>
          <Paragraph>
            Acest lucru vă permite să aflați ce societăți sunt înregistrate pe această platformă și cele care vă permit
            să refuzați sau să acceptați modulele cookie utilizate de aceste societăți pentru a personaliza reclamele
            care pot fi afișate pe dispozitivul dvs. pe baza informațiilor din browser:{' '}
            <Link to={'https://www.youronlinechoices.com/uk/your-ad-choices'} external newTab>
              https://www.youronlinechoices.com/uk/your-ad-choices
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Această platformă europeană este partajată de sute de profesioniști în publicitate pe internet și oferă o
            interfață centralizată pentru a refuza sau accepta module cookie care pot fi utilizate pentru a personaliza
            reclamele care pot fi afișate pe dispozitivul dvs. în funcție de experiența de navigare a dispozitivului
            dvs.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. CUM VĂ PUTEȚI EXERCITA DREPTURILE?'} underline />
          <Paragraph>
            În conformitate cu legislația aplicabilă privind protecția datelor cu caracter personal, aveți dreptul de a
            accesa, rectifica, de a vă opune, restricționa și șterge datele dvs. cu caracter personal. Pentru a vă
            exercita celelalte drepturi, vă rugăm să consultați informațiile noastre privind protecția datelor dvs. cu
            caracter personal, contactându-ne la adresa{' '}
            <Link to={'mailto:dpo@renault.com'} external>
              dpo@renault.com
            </Link>{' '}
            sau{' '}
            <Link to={'mailto:dpo.romania@renault.com'} external>
              dpo.romania@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'9. MODIFICĂRI'} underline />
          <Paragraph>
            Putem modifica această politică din când în când. Vă vom informa și/sau vă vom solicita consimțământul ori
            de câte ori este necesar. Prin urmare, vă recomandăm să consultați această politică de fiecare dată când
            vizitați site-ul nostru, pentru a vizualiza cea mai recentă versiune.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>
            <i>Ultima actualizare: Ianuarie 2024</i>
          </Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
