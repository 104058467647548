import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { getDealerType, getTokenUser, getUserRights, UserRole } from 'domains/user';
import { Tab, TabItems, Tabs, TabsProps } from 'UI';
import { doesDealerTypeMatch, doesUserBelongToCountry, hasUserAnyRight, LOADING } from 'utils';
import { Country } from 'utils/i18n/Country';

export interface PrivateTabsItems extends TabItems {
  requiredRights?: UserRole[];
  requiredCountries?: Country[];
  requiredDealerTypes?: DealerType[];
}

export interface PrivateTabsProps extends TabsProps {
  data: PrivateTabsItems[];
}

const PrivateTabs = ({ data, ...props }: PrivateTabsProps) => {
  const [tabState, setTabState] = useState('');
  const userRights = useSelector(getUserRights);
  const userCountry = Country.findByKey(useSelector(getTokenUser)?.country);
  const dealerType = useSelector(getDealerType);

  const tabsData = useMemo(() => {
    return data.filter((item) => {
      if (userRights !== LOADING) {
        const isAuthorizedUser = hasUserAnyRight(item.requiredRights ?? [], userRights);
        const isAuthorizedCountry = doesUserBelongToCountry(item.requiredCountries ?? [], userCountry);
        const dealerTypeMatch = doesDealerTypeMatch(item.requiredDealerTypes ?? [], dealerType);
        return isAuthorizedUser && isAuthorizedCountry && dealerTypeMatch;
      }
      return false;
    });
  }, [data, userRights, userCountry, dealerType]);

  const changeTabs = (activeKey: string) => {
    tabsData.find((item) => activeKey === item.key)?.additionalAction?.();
    setTabState(activeKey);
  };

  return (
    <>
      {tabsData.length > 0 ? (
        <Tabs activeKey={tabState} onChange={changeTabs} tabBarGutter={15} {...props}>
          {tabsData?.map((item) => (
            <Tab tab={item.label} key={item.key} data-cy={item.dataCy}>
              {item.component}
            </Tab>
          ))}
        </Tabs>
      ) : (
        <></>
      )}
    </>
  );
};

export default PrivateTabs;
