import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircleXMark } from 'assets/icons';
import { getLabel } from 'components/Filter/Filter';
import { TagWrapper } from 'components/Filter/Filter.styled';
import { FilterType, RANGE_FILTER_TYPE, TEXT_FILTER_TYPE } from 'components/Filter/Filter.types';
import { theme } from 'styles';
import { CenterFlex, Icon, Text } from 'UI';

export const FilterTag = ({
  type,
  id,
  item,
  handleCancelFilter,
  filterSectionType,
}: {
  type: typeof TEXT_FILTER_TYPE | typeof RANGE_FILTER_TYPE;
  id: string;
  item: string;
  handleCancelFilter: (type: typeof TEXT_FILTER_TYPE | typeof RANGE_FILTER_TYPE, id: string, item: string) => void;
  filterSectionType?: FilterType;
}) => {
  const { t } = useTranslation();

  const getTagLabel = () => {
    switch (type) {
      case TEXT_FILTER_TYPE:
        return getLabel(t, id, item, filterSectionType);
      case RANGE_FILTER_TYPE:
        return `${id} ${item}`;
      default:
        return undefined;
    }
  };

  return (
    <TagWrapper>
      <CenterFlex>
        <Text type={'dark_12_narrow_line_spacing'}>{getTagLabel()}</Text>
        <Icon
          IconComponent={CircleXMark}
          size={15}
          ml={7}
          mt={-2}
          onClick={() => handleCancelFilter(type, id, item)}
          color={theme.color.grey25}
        />
      </CenterFlex>
    </TagWrapper>
  );
};
