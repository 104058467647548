import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { SList, SModal, SVehicleItem } from 'components/SearchVehicleSelection/SearchVehicleSelection.styled';
import {
  mapVehiclesToSearchSelections,
  VehicleDetailForSearchSelection,
  VehicleDetailForSearchSelectionItem,
} from 'components/SearchVehicleSelection/SearchVehicleSelectionMapper';
import {
  fetchSwitchVehicleRequestSaga,
  getFoundVehiclesAreFromDifferentCountries,
  getMultipleVehicleFound,
  getQueryByRequestId,
} from 'domains/catalog/Catalog.store';
import { ThemeFontKeyType } from 'styles';
import { Flex, MarginBox, Text, YellowButton } from 'UI';

function DiffText({
  item,
  textType,
}: {
  item: VehicleDetailForSearchSelectionItem | undefined;
  textType?: ThemeFontKeyType;
}) {
  if (item === undefined) {
    return null;
  }
  return (
    <Text type={textType ?? 'light_14_black_65'} displayStyle={item.difference ? 'info' : undefined}>
      {item.value}&nbsp;
    </Text>
  );
}

function VehicleItem({ requestId, vehicle }: { requestId: string; vehicle: VehicleDetailForSearchSelection }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function handleClick() {
    dispatch(fetchSwitchVehicleRequestSaga({ requestId, vehicleDetail: vehicle.modelVehicleDetail }));
  }

  return (
    <SVehicleItem align={'center'} justify={'center'}>
      <Flex direction={'column'}>
        <Flex>
          <DiffText item={vehicle.brand} textType={'light_14_bold_black_65'} />
          <DiffText item={vehicle.model} textType={'light_14_bold_black_65'} />
        </Flex>

        <Flex>
          <DiffText item={vehicle.phase} />
          <DiffText item={vehicle.version} />
          <DiffText item={vehicle.power} />
          <DiffText item={vehicle.bodyType} />
          <DiffText item={vehicle.doors} />
          <DiffText item={vehicle.euroStandard} />
        </Flex>

        <Flex>
          <DiffText item={vehicle.vin} />
          <DiffText item={vehicle.vrn} />
        </Flex>
      </Flex>

      <YellowButton stretch={false} size={'middle'} onClick={handleClick}>
        {t('common.action.select', 'Select')}
      </YellowButton>
    </SVehicleItem>
  );
}

function VehicleList({ requestId, vehicles }: { requestId: string; vehicles: VehicleDetailForSearchSelection[] }) {
  return (
    <SList>
      {vehicles.map((v, key) => (
        <VehicleItem key={key} requestId={requestId ?? ''} vehicle={v} />
      ))}
    </SList>
  );
}

export function SearchVehicleSelection() {
  const { t } = useTranslation();
  const multipleVehicleFound = useSelector(getMultipleVehicleFound);
  const foundVehiclesAreFromDifferentCountries = useSelector(getFoundVehiclesAreFromDifferentCountries);
  const query = useSelector((state: RootState) => getQueryByRequestId(state, multipleVehicleFound?.requestId));
  const mappedVehicles = useMemo(() => mapVehiclesToSearchSelections(multipleVehicleFound?.vehicles ?? [], t), [
    multipleVehicleFound,
    t,
  ]);

  return (
    <SModal
      width={700}
      footer={''}
      closable={false}
      open={mappedVehicles.length > 0 && !foundVehiclesAreFromDifferentCountries}
    >
      <MarginBox ml={30}>
        <Text type={'light_14_bold_black_85'}>
          {t('catalog.search.count_multiple_vehicles_found', '{{count}} vehicles found for: {{query}}', {
            count: multipleVehicleFound?.vehicles.length,
            query: query,
          })}
        </Text>
      </MarginBox>
      <MarginBox mt={10} />
      <VehicleList requestId={multipleVehicleFound?.requestId ?? ''} vehicles={mappedVehicles} />
    </SModal>
  );
}
