import React from 'react';
import { Table } from 'antd';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataFR = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>NOTICE D’INFORMATION SUR LA PROTECTION DE VOS DONNÉES PERSONNELLES</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Le Groupe Renault, auquel appartient Renault SAS, veille en permanence au respect de la règlementation
            relative à la protection des données à caractère personnel (ci-après « données personnelles ») et le met au
            cœur de son éthique, tel que décrit dans sa Politique Groupe de protection des données personnelles
            accessible à l’adresse{' '}
            <Link
              to={'https://group.renault.com/nos-engagements/le-groupe-renault-et-vos-donnees-personnelles/'}
              external
              newTab
            >
              https://group.renault.com/nos-engagements/le-groupe-renault-et-vos-donnees-personnelles/
            </Link>
            . Le Groupe Renault estime en effet et que la protection des données personnelles est essentielle pour
            construire avec ses clients une relation de confiance.
          </Paragraph>
          <Paragraph>
            Renault SAS met à disposition le site internet <Text type={'text_bold'}>RPARTSTORE</Text>{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>{' '}
            (la « Plateforme ») afin de i) faciliter la consultation de documentation technique, offrir à la vente des
            pièces et des accessoire (les « Produits »), ii) remonter des incidents relatifs au fonctionnement de la
            Plateforme, et, iii) générer un contact commercial direct entre vendeur et acheteurs, professionnels du
            réseau Renault. Dans le cadre de la navigation et interaction avec la Plateforme, Renault en qualité de
            responsable de traitement, collecte des données personnelles directement auprès des utilisateurs de la
            Plateforme (« Utilisateurs »), ainsi que, le cas échéant, indirectement, des données personnelles relatives
            aux clients finaux des Utilisateurs (« Tiers Concernés »). Conforment aux Conditions Générales d’Utilisation
            de la Plateforme (ci-après désignées les « Conditions Générales »), Renault SAS entend assurer la plus
            grande transparence concernant les traitements de données personnelles qu’elle réalise via la Plateforme. La
            présente Notice a pour objectif d’informer les Utilisateurs représentants d’une filiale du Groupe Renault ou
            faisant partie du réseau Renault (« vous ») de manière détaillée des conditions de traitement de vos données
            personnelles et, le cas échéant, les données personnelles de Tiers Concernés, par Renault ainsi que les
            droits et options dont vous disposez pour contrôler vos données personnelles et protéger votre vie privée.
          </Paragraph>
          <Paragraph>
            Les termes en majuscule utilisés mais non définis dans la présente notice d’information sur la protection
            des données personnelles (ci-après la « Notice ») ont le sens qui leur est donné dans les Conditions
            Générales. Pour les besoins du présent document, « nous », « notre », « nos » ou « Renault » renvoie à
            Renault SAS. Pour les besoins de la présente Notice, Utilisateur et Tiers Concernés sont définis ensemble «
            Personnes Concernées »).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'1. QUI TRAITE VOS DONNÉES PERSONNELLES ?'} />
          <Paragraph>
            Renault SAS, maison-mère du Groupe Renault, située en France, au 122-122 bis avenue du Général Leclerc 92100
            Boulogne-Billancourt traite vos données personnelles pour les finalités listées ci-après (voir 3.{' '}
            <Link
              to={
                'https://www.renault.fr/donnees-personnelles.html#3-pour-quoi-vos-donnees-personnelles-sont-elles-utilisees'
              }
              external
              newTab
            >
              POURQUOI VOS DONNÉES PERSONNELLES SONT-ELLES UTILISÉES ?
            </Link>
            ) en qualité de responsable de traitement.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. QUELLES DONNÉES PERSONNELLES TRAITONS-NOUS ?'} />
          <Paragraph>
            Les « données personnelles » désignent toute information permettant de vous identifier soit directement
            (comme votre nom et/ou prénom), soit indirectement (par exemple l’identifiant composé d’une suite de
            caractères alphanumériques qui constituent votre IPN, lorsque vous êtes un Utilisateur représentant d’une
            filiale Renault ou fait partie du réseau Renault.
          </Paragraph>
          <Paragraph>
            De manière générale, nous nous engageons à collecter uniquement les données personnelles qui sont
            pertinentes et appropriées à chacune des finalités pour lesquelles nous traitons vos données personnelles.
          </Paragraph>
          <Paragraph>
            Les données personnelles que nous collectons dépendent de notre interaction avec vous et peuvent inclure des
            informations concernant :
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>les données relative à vos connexion / authentification sur la Plateforme ;</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                les données d’identification et vos coordonnées professionnelles (e-mail professionnel, nom, prénom) ;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                votre utilisation de la Plateforme et l’appareil que vous utilisez pour visiter la Plateforme, notamment
                logs (relatifs aux nombre de visites, page visitée, le navigateur web utilisé), adresse IP, fuseau
                horaire et cookies (pour plus d’informations référez-vous à la page « Cookies » de la Plateforme) ;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                les estimations réalisés via la Plateforme s’agissant de diagnostic, réparation ou recyclage («
                Opérations Techniques ») sur véhicule du Tiers Concerné, y compris les estimations n’ayant pas donné
                lieu à la soumission d’un bon de commande de Produits ;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                les données personnelles vous concernant et, éventuellement, celles relatives aux Tiers Concernés dans
                le cadre de la remontée des incidents relatifs au fonctionnement de la Plateforme ;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                les données personnelles vous concernant et, éventuellement celles relatives aux Tiers Concernés dans le
                cadre de bon de commande de Produits auprès du réseau Renault de distributeur R1 (les données collectées
                sont notamment les coordonnées professionnelles du contact en affaire destiné à réceptionner la
                livraison) ;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                les données relatives à la relation commerciale, le cas échéant, vos éventuelles demandes de support,
                réclamations et/ou exercices des droits sur vos données personnelles).
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
          <Paragraph>
            A ce titre, les données indispensables pour répondre à votre demande sont signalées dans les formulaires de
            collecte (notamment par des astérisques). Si vous ne souhaitez pas fournir les données obligatoires, nous ne
            pourrons probablement pas traiter votre demande ou vous fournir les services concernés.
          </Paragraph>
          <Paragraph>
            Conformément à nos Conditions Générales, les produits et services que nous proposons s’adressent à des
            personnes majeures. Les services proposés via la Plateforme ne sont pas destinés aux mineurs et nous ne
            collectons donc pas sciemment des données les concernant.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. POURQUOI VOS DONNÉES PERSONNELLES SONT-ELLES UTILISÉES ?'} />
          <Paragraph>
            Nous vous fournissons des explications sur le contexte de collecte de vos données personnelles, les bases
            légales nous autorisant à les traiter, les finalités pour lesquelles nous les traitons et la durée pendant
            laquelle nous les conservons.
          </Paragraph>
          <MarginBox mt={15} />
          <Section>
            <SectionHeader title={'3.1. LE CONTEXTE DE COLLECTE'} />
            <Paragraph>Nous collectons vos données personnelles notamment lorsque :</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Vous interagissez directement avec Renault via la Plateforme (remplissez un formulaire, prenez
                  contact, …)
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Vous visitez la Plateforme, qui peut utiliser des cookies ou autres traceurs,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Vous réalisez une remontée d’incident relatif au fonctionnement de la Plateforme, enregistrez une
                  Opération Technique ou éditez et, le cas échéant, envoyez un bon de commande de Produits.
                </Text>
              </Li>
            </Ul>
          </Section>
          <Section>
            <SectionHeader title={'3.2. OBJECTIFS DU TRAITEMENT DE DONNÉES PERSONNELLES'} />
            <Paragraph>
              Dans le cadre de nos relations, et suivant le contexte dans lequel vos données personnelles sont
              collectées, nous sommes amenés à utiliser vos données pour :
            </Paragraph>
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>A – Gérer nos premières interactions</Text>
              </Li>
              <Li>
                <Text type={'text'}>B – Vous fournir le service</Text>
              </Li>
              <Li>
                <Text type={'text'}>C – Conduire des études et des analyses</Text>
              </Li>
              <Li>
                <Text type={'text'}>D – Gérer nos opérations marketing</Text>
              </Li>
            </Ul>
            <MarginBox mt={10} />
            <Section>
              <SectionHeader title={'3.2.A. GÉRER NOS PREMIÈRES INTERACTIONS'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectif(s) poursuivi(s)',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-a',
                    width: '50%',
                  },
                  { title: 'Bases légales', dataIndex: 'legal', key: 'legal-3-2-a', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives:
                      'Suivi des visites sur la Plateforme et gestion de  son fonctionnement et de sa sécurité',
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est fondé sur votre consentement aux cookies « non essentiels » déposés/lus dans votre terminal (voir la page « Cookies » de la Plateforme), et sur notre intérêt légitime (vous fournir un Site et sécurisé)`,
                  },
                  {
                    id: '1',
                    objectives: 'La gestion de vos moyens d’authentification sur la Plateforme',
                    legal: 'Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous.',
                  },
                  {
                    id: '2',
                    objectives: 'Vous permettre de vous connecter à la Plateforme',
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous (à savoir les Conditions Générales)`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.B. VOUS FOURNIR LE SERVICE'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectif(s) poursuivi(s)',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-b',
                    width: '50%',
                  },
                  { title: 'Bases légales', dataIndex: 'legal', key: 'legal-3-2-b', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Vous permettre de réaliser les Opérations Techniques en lien notamment avec la réparation des véhicules`,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous (à savoir les Conditions Générales)`,
                  },
                  {
                    id: '1',
                    // eslint-disable-next-line max-len
                    objectives: `Vous permettre de consulter la documentation technique après-vente concernant les Produits RENAULT (telles que : les pièces de rechange, le temps de main d’œuvre, les méthodes de réparation et les diagnostique, tutoriels, sans que cette liste ne soit limitative) et/ou autres marques constructeurs `,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous (à savoir les Conditions Générales) `,
                  },
                  {
                    id: '2',
                    // eslint-disable-next-line max-len
                    objectives: `Vous permettre de réaliser une estimation du montant des Opérations Techniques et, le cas échéant d’émettre via la Plateforme un bon de commande de Produits`,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous (à savoir les Conditions Générales)`,
                  },
                  {
                    id: '3',
                    objectives: `Gérer notre relation commerciale `,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous (à savoir les Conditions Générales)`,
                  },
                  {
                    id: '4',
                    objectives: `Gestion de vos demandes de support et incident relatif au fonctionnement de la Plateforme`,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est justifié par l’exécution du contrat que vous avez conclu avec nous (à savoir les Conditions Générales)`,
                  },
                  {
                    id: '5',
                    // eslint-disable-next-line max-len
                    objectives: `Répondre à vos demandes éventuelles  d’exercice de droits en relation avec vos données personnelles (cf. la section 5. « Quels sont vos droits ? »)`,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est fondé sur nos obligations légales en matière de protection des données personnelles et peut impliquer une vérification de votre identité`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.C. CONDUIRE DES ÉTUDES ET DES ANALYSES'} />
              <Paragraph>
                Les études et analyses sont effectuées afin de mesurer notre performance, évaluer la qualité de nos
                produits et services et le niveau de la satisfaction client, et les améliorer continuellement.
              </Paragraph>
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectif(s) poursuivi(s)',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-c',
                    width: '50%',
                  },
                  { title: 'Bases légales', dataIndex: 'legal', key: 'legal-3-2-c', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `La gestion de nos enquêtes de satisfaction, y compris la réponse (vos avis), afin d’améliorer la satisfaction Utilisateurs concernant la Plateforme `,
                    legal: `Ce traitement est fondé sur notre intérêt légitime (améliorer nos produits et services) `,
                  },
                  {
                    id: '1',
                    objectives: `Assurer un suivi de nos activités (reporting) `,
                    // eslint-disable-next-line max-len
                    legal: `Ce traitement est fondé sur notre intérêt légitime (mesurer la performance de notre activité et améliorer nos produits et services) `,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.D. GÉRER NOS OPÉRATIONS MARKETING'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectif(s) poursuivi(s)',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-d',
                    width: '50%',
                  },
                  { title: 'Bases légales', dataIndex: 'legal', key: 'legal-3-2-d', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Envoi de campagnes publicitaires au réseau Renault (B2B) `,
                    legal: `Ce traitement est fondé sur notre intérêt légitime (faire connaître nos Produits) `,
                  },
                ]}
              />
              <MarginBox mt={15} />
              <Paragraph>
                Si nous sommes conduits à traiter vos données personnelles pour des finalités autres que celles listées
                dans le tableau ci-dessus, vous en serez informés préalablement et nous procéderons à toute démarche
                complémentaire éventuellement nécessaire pour assurer la conformité légale de tous les traitements
                réalisés.
              </Paragraph>
            </Section>
          </Section>
          <Section>
            <SectionHeader title={'3.3. LA DURÉE PENDANT LAQUELLE NOUS CONSERVONS VOS DONNÉES'} />
            <Paragraph>
              Conformément à la réglementation, nous nous engageons à ne conserver vos données personnelles que le temps
              nécessaire pour atteindre l’objectif poursuivi, pour répondre à vos besoins, ou pour satisfaire à nos
              obligations légales.
            </Paragraph>
            <Paragraph>
              Afin de déterminer cette durée, nous prenons en compte notamment les éléments suivants :
            </Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>Le temps nécessaire pour traiter votre demande ou votre réclamation,</Text>
              </Li>
              <Li>
                <Text type={'text'}>Votre intérêt pour nos services et la Plateforme,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  La nécessité de conserver un certain historique de vos interactions avec la Plateforme, cette durée
                  variant notamment selon que vous avez passé un Bon de Service ou non ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Nos obligations légales ou règlementaires</Text>
              </Li>
            </Ul>
            <Paragraph>En particulier :</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Les données personnelles collectées par les cookies dans le cadre de l’analyse de la performance des
                  contenus que vous consultez sur la Plateforme (mesure d’audience). Les traceurs/cookies « non
                  essentiels », tels que les cookies publicitaires sont conservés pendant 13 mois maximum à compter du
                  recueil de votre consentement. Les données personnelles collectées via les cookies seront traitées
                  pendant 25 mois, puis anonymisés. La durée de conservation de vos choix (consentement ou refus des
                  cookies dit « non essentiels » soumis à la collecte préalable du consentement) est de 6 mois à compter
                  du recueil de votre consentement. Pour plus d’information relatives à notre politique en matière de
                  cookies, référez-vous à la page « Cookies » de la Plateforme ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Les données personnelles traitées dans le cadre de la gestion du fonctionnement et de la sécurité du
                  Site et de nos bases de données client/prospect sont conservées pendant 6 mois (ex : logs, …) ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Les données personnelles traitées dans le cadre des historiques de vos Opérations Techniques réalisées
                  via la Plateforme et/ou les bon de commande envoyés sont conservées pendant 6 mois. Il est précisé que
                  la durée de conservation des estimations réalisées via la Plateforme par l’Utilisateur est de 3 mois.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Lorsque l’Utilisateur est un collaborateur de Renault ou un collaborateur d’un membre professionnel du
                  réseau Renault, ses données collectées via la Plateforme seront conservées pendant la durée de son
                  contrat de travail avec Renault ou, le cas échant, l’autorisation fournie par l’employeur de
                  l’Utilisateur pour l’accès à la Plateforme,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Les données personnelles relatives à vos demandes d’exercice de droits sont conservées pendant 3 ans à
                  compter de la clôture de votre demande, selon le droit exercé. Lorsque la collecte d’un justificatif
                  d’identité a été nécessaire celui-ci est supprimé dès que la vérification a été effectuée.
                </Text>
              </Li>
            </Ul>
            <Paragraph>
              Lorsque nous n’avons plus besoin d’utiliser vos données personnelles, elles sont supprimées de nos
              systèmes et de nos registres ou rendues anonymes afin de ne plus permettre de vous identifier.
            </Paragraph>
            <Paragraph>
              Cependant, nous pouvons être amenés à conserver, en archive, certaines de vos données personnelles en vue
              d’être en mesure de répondre à toute action en justice, et ce pendant la durée de prescription prévue par
              la législation applicable.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'4. QUI A ACCÈS A VOS DONNÉES PERSONNELLES ?'} />
          <Paragraph>
            Pour atteindre les finalités décrites ci-dessus et dans la seule mesure nécessaire à la poursuite de ces
            finalités :
          </Paragraph>
          <Ul>
            <Li>
              nous nous assurons que seules les personnes dûment habilitées au regard de leurs fonctions et de leurs
              missions à traiter vos données personnelles, soient autorisées à le faire.
            </Li>
            <Li>
              nous pouvons transmettre certaines de ces données aux destinataires suivants :
              <Ul type={'decimal'}>
                <MarginBox mt={10} />
                <Li>
                  aux membres du réseau Renault (i.e. les membres du réseau R1 auprès desquels l’Utilisateur est
                  habilité à passer un bon de commande de Produits), sous réserve de votre consentement lorsque celui-ci
                  est requis par la règlementation. En effet, Renault et le membres de son réseau ont conclu un accord
                  aux termes duquel ils s’engagent tous deux, en leur qualité de responsables de traitement disjoints, à
                  respecter leurs obligations en vertu des lois et règlements relatifs à la protection des données à
                  caractère personnel, y compris, entre autres, à respecter les principes suivants :{' '}
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>traiter les données à caractère personnel de manière licite et loyale,</Li>
                    <Li>s’assurer de l’exactitude et de la qualité des données,</Li>
                    <Li>
                      mettre en œuvre toutes les mesures techniques et organisationnelles appropriées pour garantir un
                      niveau de sécurité adapté au risque et coopérer en cas de violation des données,
                    </Li>
                    <Li>ne recruter que des sous-traitants dignes de confiance,</Li>
                    <Li>mettre en œuvre les garanties appropriées pour les transferts de données,</Li>
                    <Li>
                      respecter les droits des personnes concernées, notamment le droit à être informé des traitements
                      de données.
                    </Li>
                  </Ul>
                  <MarginBox mt={10} />
                  En ce qui concerne le dernier principe, les employés du réseau Renault sont informés que la présente
                  Notice est un complément de la notice d’information relative aux traitements des données qui vous a
                  été fournie, le cas échéant, par votre employeur en tant que professionnel appartenant au réseau
                  Renault.
                </Li>
                <MarginBox mt={10} />
                <Li>
                  des sous-traitants de données personnelles au sens de la réglementation applicable en matière de
                  données personnelles, pour accomplir des tâches. Nous veillons à sécuriser ces relations (contrats,
                  audits, garanties et tests de sécurité…) et à leur donner des instructions relatives aux tâches de que
                  nous leur confions, dont notamment :
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>L’hébergement,</Li>
                    <Li>
                      L’exploitation et la maintenance de la Plateforme et des bases de données collectées et/ou
                      traitées via la Plateforme,
                    </Li>
                    <Li>
                      Les opérations de prospection commerciale auprès des professionnels membres professionnels du
                      réseau Renault (B2B),
                    </Li>
                    <Li>
                      La gestion de la relation clientèle et du support concernant les incidents relatifs à la
                      Plateforme,
                    </Li>
                    <Li>La réalisation des mesures d’audience sur la Plateforme,</Li>
                    <Li>
                      La réalisation d’analyses pour mesurer votre satisfaction concernant les services de la Plateforme
                      et la connaissance des attentes de notre réseau.
                    </Li>
                    <MarginBox mt={7} />
                  </Ul>
                </Li>
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            Enfin, nous pouvons être amenés à communiquer vos données personnelles à des tiers afin de nous conformer à
            toute obligation légale ou décision administrative ou de justice.
          </Paragraph>
          <Paragraph>
            Renault peut partager vos données avec des tiers lors du transfert des obligations et des droits relatifs à
            la relation contractuelle entre vous et Renault à ces tiers dans le cas du transfert d’un secteur
            d’activité, d’une fusion par constitution d’une nouvelle société, d’une fusion par absorption, d’une
            scission ou de tout changement de contrôle qui concerne Renault. Avant un tel événement, Renault vous
            informera séparément sur les informations relatives au partage de vos données et obtiendra votre
            consentement lorsque cela est légalement nécessaire.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'5. QUELS SONT VOS DROITS ?'} />
          <Section>
            <SectionHeader title={'5.1. VOS DROITS'} />
            <Paragraph>
              Vous bénéficiez de plusieurs droits en application de la règlementation relative à la protection des
              données personnelles :
            </Paragraph>
            <Paragraph>
              Un droit d’opposition au traitement de vos données personnelles, sous réserve de justifier de raisons
              tenant à votre situation particulière, et un droit de demander la limitation du traitement de vos données
              personnelles, dans certains cas prévus par la règlementation.
            </Paragraph>
            <Paragraph>
              Un droit d’opposition à toute communication de notre part : vous pouvez à tout moment demander à ne plus
              recevoir nos communications.
            </Paragraph>
            <Paragraph>
              Un droit de retirer votre consentement à tout moment, pour les finalités pour lesquelles nous avons
              collecté votre consentement (voir ci-avant 3.2. LES OBJECTIFS DU TRAITEMENT DE DONNÉES PERSONNELLES).
            </Paragraph>
            <Paragraph>
              Un droit à l’information : vous avez le droit d’obtenir des informations claires, transparentes et
              compréhensibles sur la façon dont nous utilisons vos données personnelles et sur vos droits. Cette Notice
              en est un exemple.
            </Paragraph>
            <Paragraph>
              Un droit d’accès à vos données personnelles : vous avez le droit d’obtenir des informations concernant le
              traitement de vos données personnelles (notamment les données qui sont utilisées, pour quelles fins…)
              ainsi qu’une copie de celles-ci.
            </Paragraph>
            <Paragraph>
              Un droit de rectification : vous avez le droit de faire rectifier vos données si elles sont inexactes ou
              incomplètes, malgré nos efforts pour les tenir à jour, ce qui nous permettra de nous conformer à notre
              obligation d’avoir des données à jour vous concernant.
            </Paragraph>
            <Paragraph>
              Un droit à la portabilité de vos données, c’est-à-dire, sous certaines conditions, le droit de recevoir
              les données personnelles que vous nous avez fournies, dans un format informatique structuré, couramment
              utilisé, et à ce qu’elles soient transmises à un tiers si cela est techniquement possible.
            </Paragraph>
            <Paragraph>
              Un droit à l’effacement (ou droit à l’oubli) : vous avez le droit de faire effacer ou supprimer vos
              données. Ce droit peut être limité au regard de nos obligations contractuelles (contrat en cours) ou
              légales (prévention d’actions en justice notamment).
            </Paragraph>
            <Paragraph>
              En France, un droit de définir des directives, soit générales, soit particulières, à l’égard de certains
              traitements, pour la conservation, l’effacement et la communication de vos données personnelles en cas de
              décès. Vous pouvez modifier ou supprimer ces directives à tout moment. Vous pouvez nous faire part de ces
              directives particulières, en écrivant à l’adresse ci-dessous mentionnée.
            </Paragraph>
            <Paragraph>
              Enfin, vous disposez du droit d’introduire une réclamation auprès d’un autorité de contrôle de la
              protection des données personnelles, par exemple, en France, la Commission Nationale Informatique et
              Libertés (CNIL). Nous vous encourageons à prendre contact avec nous avant toute réclamation, pour que nous
              essayons de résoudre votre problème ensemble. Pour obtenir plus d’informations sur vos droits, suivez le
              lien :{' '}
              <Link to={'https://www.cnil.fr/professionnel'} external newTab>
                https://www.cnil.fr/professionnel
              </Link>
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'5.2. COMMENT LES EXERCER ?'} />
            <Paragraph>
              Pour exercer vos droits relatifs à vos données personnelles, vous pouvez nous adresser votre demande à
              tout moment par courrier à l’adresse postale suivante :
            </Paragraph>
            <Paragraph>
              Renault SAS, Direction juridique – Délégué à la protection des données, 122-122 bis avenue du Général
              Leclerc 92100 Boulogne-Billancourt, France.
            </Paragraph>
            <Paragraph>ou</Paragraph>
            <Paragraph>
              un courriel à l’adresse email suivante :{' '}
              <Link to={'mailto:dataprotection-com@renault.com'} external newTab>
                dataprotection-com@renault.com
              </Link>
              .
            </Paragraph>
            <Paragraph>
              Dans les deux hypothèses, afin de faciliter le traitement de votre demande, nous vous invitons à préciser
              que votre demande se relate à la Plateforme « RPARTSTORE ».
            </Paragraph>
            <Paragraph>
              En cas de doute sur concernant votre identité, un justificatif est susceptible de vous être demandé (i.e.
              la copie justificative de votre identité est traitée uniquement afin de pouvoir répondre à votre demande
              et supprimée immédiatement après la réalisation de la vérification).
            </Paragraph>
            <Paragraph>
              Nous mettons tout en œuvre pour vous répondre dans les meilleurs délais et au plus tard dans un délai d’un
              mois à compter de la réception de votre demande. Conformément à la réglementation applicable, nous nous
              réservons la possibilité de prolonger ce délai à trois mois si votre demande est particulièrement
              complexe.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'6. COMMENT SÉCURISE-T-ON VOS DONNÉES PERSONNELLES ?'} />
          <Paragraph>
            Vos données personnelles sont stockées sur des serveurs sécurisés. Nous mettons en place, et exigeons de nos
            sous-traitants et partenaires, des mesures appropriées de sécurité et de protection des données, en phase
            avec les dernières technologies.
          </Paragraph>
          <Paragraph>
            Lorsque le traitement d’une donnée personnelle implique son transfert, nous nous assurons que ce transfert
            s’effectue selon des conditions appropriées garantissant un niveau de protection, de sécurité et de
            confidentialité.
          </Paragraph>
          <Paragraph>
            Dans la mesure du possible, vos données sont traitées dans l’Espace Economique Européen (EEE). Toutefois,
            certains de nos prestataires de service ou leurs sous-traitants étant situés dans des pays en dehors de
            l’EEE, vos données personnelles sont traitées dans ces pays. Certains de ces pays peuvent avoir une
            règlementation sur les données personnelles différente de celle de l’Union Européenne. Dans un tel cas, nous
            apportons une attention particulière à ce que ce transfert soit effectué en conformité avec la
            règlementation applicable et mettons en place des garanties assurant un niveau de protection de votre vie
            privée et de vos droits fondamentaux équivalent à celui offert par l’Union Européenne (notamment par
            l’utilisation des Clauses Contractuelles Types de la Commission européenne). Sur simple demande formulée à
            l’adresse mentionnée dans la rubrique 5. « QUELS SONT VOS DROITS ? », nous pouvons vous fournir davantage
            d’informations sur ces transferts (notamment les clauses contractuelles types de la Commission européenne).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. LIEN VERS D’AUTRES INFORMATIONS'} />
          <Paragraph>
            Nous pouvons aussi collecter des informations techniques vous concernant de manière automatisée lorsque vous
            visitez l’un de nos sites ou utilisez l’une de nos applications mobiles, notamment votre adresse IP, vos
            paramètres de navigation, par le biais de traceurs (cookies).
          </Paragraph>
          <Paragraph>
            Pour plus d’informations, nous vous invitons à consulter la page «{' '}
            <Link to={ROUTER_COOKIES(Country.FR.key)} newTab>
              Cookies
            </Link>{' '}
            » de la Plateforme.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. MODIFICATION DES INFORMATIONS'} />
          <Paragraph>
            Nous pouvons être amenés à modifier occasionnellement la présente Notice. Lorsque cela est nécessaire ou
            requis, nous vous en informerons et/ou solliciterons votre accord. Nous vous invitons donc à la consulter
            lors de chaque visite afin de prendre connaissance de sa dernière version.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Mise à jour le 30 mai 2023</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
