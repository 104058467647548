import { useSortable } from '@dnd-kit/sortable';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { CSS } from '@dnd-kit/utilities';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { AngleDownIcon, AngleRightIcon, Dots4Icon, PlusCircleIcon } from 'assets/icons';
import {
  addBundleRequestSaga,
  getIsBundleSelected,
  getIsSectionSelected,
  renameBundlesSectionRequestSaga,
  setBundleSelection,
} from 'domains/myStore/MyStore.store';
import { SectionTitleType } from 'domains/myStore/MyStore.types';
import { SectionHeader } from 'pages/MyStorePage/Pages/Bundles/Bundles.styled';
import BundlesTable from 'pages/MyStorePage/Pages/Bundles/BundlesTable';
import { ValidatedTextInput } from 'pages/MyStorePage/Pages/Bundles/BundlesTable/BundlesInput';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Text, WhiteBlueButton, Checkbox } from 'UI';
import { useFocus } from 'utils';

export const SelectBundles = ({
  sectionId,
  bundleId,
  enabled,
}: {
  sectionId: string;
  bundleId?: string;
  enabled: boolean;
}) => {
  const dispatch = useDispatch();
  const isSectionSelected = useSelector((state: RootState) => getIsSectionSelected(state, sectionId));
  const isBundleSelected = useSelector((state: RootState) => getIsBundleSelected(state, bundleId));

  const handleChange = (v: boolean) => {
    dispatch(setBundleSelection({ sectionId, bundleId, isSelected: v }));
  };

  return (
    <Checkbox
      disabled={!enabled}
      checked={enabled && (bundleId ? isBundleSelected : isSectionSelected)}
      onChange={(v) => handleChange(v)}
    />
  );
};

const getSectionTitle = (t: TFunction, title: SectionTitleType) => {
  switch (title) {
    case 'TIRES':
      return t('catalog.tires', 'Tires');
    case 'BRAKING':
      return t('catalog.brakes_category.braking', 'Braking');
    case 'MAINTENANCE':
      return t('catalog.parts.category.maintenance', 'Maintenance');
    case 'OTHER':
      return t('common.other', 'Other');
    case '':
      return t('common.new_bundle', 'New bundle category');
    default:
      return title;
  }
};

const SectionTitle = ({ sectionId, title }: { sectionId: string; title: string }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [titleState, setTitleState] = useState(getSectionTitle(t, title));
  const inputRef = useRef(null);

  useFocus(inputRef, [isEditing]);

  useEffect(() => {
    setTitleState(getSectionTitle(t, title));
  }, [t, title]);

  const handleTitleChange = (title: string) => {
    dispatch(renameBundlesSectionRequestSaga({ sectionId, title }));
    setTitleState(title);
  };

  return (
    <>
      {isEditing ? (
        <Box width={'100%'}>
          <ValidatedTextInput
            noTooltip
            value={titleState}
            saveFunction={handleTitleChange}
            maxLength={window.innerWidth / 30}
            passRef={inputRef}
            validate={(v) => v.length > 0}
            onBlur={(v) => {
              setIsEditing(false);
              setTitleState(v);
            }}
            isLarge
          />
        </Box>
      ) : (
        <Text type={'h2'} disableGutter onClick={() => setIsEditing(true)}>
          {titleState}
        </Text>
      )}
    </>
  );
};

const BundlesSection = ({
  sectionId,
  title,
  bundles,
  isDragging,
  initiallyOpen = false,
}: {
  sectionId: string;
  title: string;
  bundles: MyStoreBundle[];
  isDragging: boolean;
  initiallyOpen: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: sectionId });

  const handleAddBundle = () => {
    dispatch(addBundleRequestSaga({ sectionId }));
  };

  return (
    <div
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
        backgroundColor: theme.color.white,
        position: 'relative',
        zIndex: isDragging ? 200 : 100,
      }}
    >
      <Flex direction={'column'} gap={16}>
        <Flex gap={15} align={'center'}>
          <div ref={setNodeRef} {...attributes} {...listeners}>
            <Icon IconComponent={Dots4Icon} size={16} color={'black'} />
          </div>

          <SectionHeader align={'center'} gap={16} isOpen={isOpen}>
            {!isOpen && (
              <>
                <SelectBundles sectionId={sectionId} enabled={true} />
                <Box height={48} width={1} background={theme.color.grey85} />
              </>
            )}
            <Flex direction={isOpen ? 'row' : 'column'} align={isOpen ? 'center' : 'initial'} gap={isOpen ? 8 : 0}>
              <SectionTitle sectionId={sectionId} title={title} />
              <MarginBox mt={5}>
                <Text type={'light_dimmer'} noWrap>
                  {bundles.length} bundles
                </Text>
              </MarginBox>
            </Flex>
            <Flex size={0} direction={'row-reverse'}>
              <Icon
                IconComponent={isOpen ? AngleDownIcon : AngleRightIcon}
                size={20}
                onClick={() => setIsOpen(!isOpen)}
              />
            </Flex>
          </SectionHeader>
        </Flex>
        {isOpen && (
          <>
            <BundlesTable sectionId={sectionId} bundles={bundles} />
            <Box>
              <WhiteBlueButton size={'large'} onClick={handleAddBundle}>
                <Flex gap={8} align={'center'}>
                  <Icon IconComponent={PlusCircleIcon} size={20} />
                  {t('my_store.bundles.actions.add_bundle', 'Add bundle')}
                </Flex>
              </WhiteBlueButton>
            </Box>
          </>
        )}
      </Flex>
    </div>
  );
};

export default BundlesSection;
