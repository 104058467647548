/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Reference as IAMReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Reference';
import { RootState } from 'app/AppStore';
import { ReactComponent as MotrioLogo } from 'assets/CarBrand/brand_motrio_mini.svg';
import { ReactComponent as ValuePlusLogo } from 'assets/CarBrand/brand_value_plus_mini.svg';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer } from 'components/DataContainer';
import { DocumentationAlertButton } from 'components/DocumentationAlertButton';
import { DocumentScrollModal } from 'components/DocumentScrollModal/DocumentScrollModal';
import ProductModal from 'components/ProductModal';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import StockDisplay from 'components/StockInfo';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { IAM, STANDARD } from 'domains/catalog/Catalog.types';
import { getIAMReference } from 'domains/references';
import { isRefMKTP } from 'domains/references/References.utils';
import { getDealerType, SparePartsViewType } from 'domains/user';
import {
  AdditionalInformationButton,
  ProductPictureButton,
} from 'pages/CatalogPage/common/ReferenceSection/ReferenceSectionButtons';
import {
  STANDARD_EXCHANGE_REFERENCE_PATTERN,
  SupersessionTooltip,
} from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/IAMReferenceCard';
import { SCard } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/IAMReferenceCard.styled';
import {
  Box,
  Checkbox,
  Descriptions,
  Flex,
  Icon,
  INITIAL_ITEM_COUNT,
  Item,
  LinkRoundButton,
  MarginBox,
  Text,
} from 'UI';
import { getData } from 'utils';

interface ReferenceCardProps {
  reference: IAMReference;
  rawLabels: string[];
  additionalInformationItems: Item[];
  sparePartsView: SparePartsViewType;
  handleAddToCart: () => void;
  selected?: boolean;
  onSelect?: (referenceNumber: string) => void;
}

const IAMReferenceCardCompact = ({
  reference,
  rawLabels,
  additionalInformationItems,
  sparePartsView,
  selected,
  onSelect,
  handleAddToCart,
}: ReferenceCardProps) => {
  const {
    referenceNumber,
    referenceSource,
    supplier,
    supplierCode,
    origin,
    documents,
    replacingReferences,
    substitutedReferences,
    brand,
  } = reference;
  const { t } = useTranslation();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const detailWithVehicle = useSelector((state: RootState) =>
    getIAMReference(state, { vehicleKey: vehicleDetail?.vehicleKey, referenceNumber }),
  );
  const detailWithoutVehicle = useSelector((state: RootState) =>
    getIAMReference(state, { vehicleKey: undefined, referenceNumber }),
  );
  const detail = detailWithVehicle ?? detailWithoutVehicle;
  const [documentsModalVisible, setDocumentsModalVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const isR1 = useSelector(getDealerType) === 'R1';
  const availableForOrder = vehicleDetail?.vehicleBrand === 'ALPINE' ? isR1 : true;

  const displayShowMoreButton = additionalInformationItems.length > INITIAL_ITEM_COUNT;
  const displaySupersessionTooltip =
    (replacingReferences && replacingReferences.length > 0) ||
    (substitutedReferences && substitutedReferences.length > 0);
  const initialItems = additionalInformationItems.slice(0, INITIAL_ITEM_COUNT);
  const [currentData, setCurrentData] = useState(initialItems);
  const displayName = reference.name ?? reference.designation;
  const translatedName = referenceNumber.startsWith(STANDARD_EXCHANGE_REFERENCE_PATTERN)
    ? `${displayName} [${t('catalog.iam.standard_exchange', 'STANDARD EXCHANGE')}]`
    : displayName;
  const translatedDisplayName = [translatedName, supplier, ...rawLabels].filter(Boolean).join(' / ');

  const handleShowMore = () => {
    setCurrentData(showMore ? initialItems : [...additionalInformationItems]);
    setShowMore((prevState) => !prevState);
  };

  const renderPriceSection = () => {
    return (
      <ReferencePriceSection
        referenceNumber={referenceNumber}
        sparePartsView={sparePartsView}
        handleAddToCartClick={handleAddToCart}
        vehicleKey={vehicleDetail?.vehicleKey}
        align={'left'}
        catalogSource={IAM}
        referenceType={STANDARD}
        availableForOrder={availableForOrder}
        narrow
        useCompactView
        origin={origin}
        referenceSource={referenceSource}
        supplierCode={supplierCode}
        isMKTP={isRefMKTP(getData(detail))}
      />
    );
  };

  const getReferenceLogo = () => {
    switch (supplier) {
      case 'MOTRIO':
        return <Icon IconComponent={MotrioLogo} width={70} />;
      case 'VALUE_PLUS':
        return <Icon IconComponent={ValuePlusLogo} width={70} />;
      default:
        return <></>;
    }
  };

  const [viewAdditionalInfo, setViewAdditionalInfo] = useState<boolean>(false);

  function handleInfoButtonClick() {
    setViewAdditionalInfo(!viewAdditionalInfo);
  }

  function handleProductPictureButtonClick() {
    setDocumentsModalVisible(!documentsModalVisible);
  }

  function handleCheckboxClick() {
    onSelect && onSelect(referenceNumber);
  }

  return (
    <DataContainer data={detail}>
      <SCard isCompact>
        <Flex direction={'row'} align={'center'} minHeight={40}>
          <Box width={24} />
          <Flex direction={'column'}>
            <Flex align={'center'} minWidth={600}>
              <Checkbox checked={selected} onChange={handleCheckboxClick} />
              <Box width={20} />
              <ProductModal
                triggerComponent={
                  <Text type={'text_dim_bold'} disableGutter hoverUnderLine cursor={'pointer'}>
                    {referenceNumber}
                  </Text>
                }
                referenceNumber={referenceNumber}
                isVehicleCatalog
              />
              <Box width={16} />
              <CopyToClipboardButton
                textToCopy={referenceNumber}
                message={t(
                  'catalog.reference_card.reference_number.copied_to_clipboard',
                  'Reference number {{referenceNumber}} copied to clipboard',
                  { referenceNumber },
                )}
                tooltip={t('catalog.reference_card.reference_number.copy', 'Copy reference')}
              />
              <Box width={16} />
              <Box width={24}>
                {displaySupersessionTooltip && (
                  <SupersessionTooltip>
                    <LinkRoundButton
                      onClick={() => {
                        handleInfoButtonClick();
                        displayShowMoreButton && handleShowMore();
                      }}
                    />
                  </SupersessionTooltip>
                )}
              </Box>
              <Box width={8} />
              <Box width={24}>
                {brand === 'MOTRIO' ||
                  (getData(supplier === 'MOTRIO') && (
                    <>
                      <MarginBox mr={1} />
                      <DocumentationAlertButton
                        reference={getData(detail)}
                        vehicleRegistrationNumber={vehicleDetail?.vrn ?? vehicleDetail?.vehicleKey}
                        vehicleDetail={vehicleDetail}
                      />
                    </>
                  ))}
              </Box>
              <Box width={40} />
              <Text type={'text_dim_bold'}>{translatedDisplayName}</Text>
            </Flex>
          </Flex>
          <Box align={'center'} width={70}>
            {getReferenceLogo()}
          </Box>
          <Flex />
          <Flex align={'center'} maxWidth={650}>
            <MarginBox mx={15}>
              <DataContainer data={additionalInformationItems} NotFound={() => <Box width={40} />}>
                {additionalInformationItems.length > 0 ? (
                  <AdditionalInformationButton isOpen={viewAdditionalInfo} handleClick={handleInfoButtonClick} />
                ) : (
                  <Box width={40} />
                )}
              </DataContainer>
            </MarginBox>
            <MarginBox mx={15}>
              {documents && documents.length > 0 ? (
                <ProductPictureButton isOpen={documentsModalVisible} handleClick={handleProductPictureButtonClick} />
              ) : (
                <Box width={40} />
              )}
            </MarginBox>
            <Box width={48} />
            <Box width={20}>
              <StockDisplay
                vehicleKey={vehicleDetail?.vehicleKey}
                referenceNumber={referenceNumber}
                isApplicableToCurrentVehicle
                small
                spinSize={'default'}
              />
            </Box>
            <Box width={48} />
            <Box width={350}>{renderPriceSection()}</Box>
          </Flex>
        </Flex>
        {viewAdditionalInfo && additionalInformationItems.length > 0 && (
          <Descriptions
            title={t('catalog.details.additional_information', 'Additional information')}
            items={currentData}
            displayShowMoreButton={displayShowMoreButton}
            showMore={showMore}
            handleShowMore={handleShowMore}
          />
        )}
        <DocumentScrollModal
          documents={documents ?? []}
          visible={documentsModalVisible}
          onVisibleChange={(visible) => setDocumentsModalVisible(visible)}
        >
          {undefined}
        </DocumentScrollModal>
      </SCard>
      <MarginBox mt={15} />
    </DataContainer>
  );
};

export default IAMReferenceCardCompact;
