import { useLocation } from 'react-router-dom';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { ROUTER_CATALOG_DH, ROUTER_CATALOG_IAM } from 'app/AppRouter';
import { IamCatalogBrandsType, OEM_BRAND_KEY, OTHER_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { ReferencePriceType } from 'domains/references';

export function getCatalogSourceUrl(source: CatalogSource): string {
  switch (source) {
    case 'DATAHUB':
      return ROUTER_CATALOG_DH;
    case 'IAM':
      return ROUTER_CATALOG_IAM;
  }
}

export function useCatalogSourceLocation(): CatalogSource {
  const path = useLocation().pathname;
  if (path.includes(ROUTER_CATALOG_DH)) {
    return 'DATAHUB';
  }
  if (path.includes(ROUTER_CATALOG_IAM)) {
    return 'IAM';
  }
  throw new Error('Catalog source in url must exist');
}

export function useIsDHCatalogSourceInUrl(): boolean {
  const path = useLocation().pathname;
  return path.includes(ROUTER_CATALOG_DH);
}

export function useGetCatalogSourceUrlFromLocation(): string {
  return getCatalogSourceUrl(useCatalogSourceLocation());
}

export function getDHCatalogSourcePaths(suffix: string): string[] {
  return [`${ROUTER_CATALOG_DH}${suffix}`];
}

export function getIAMCatalogSourcePaths(suffix: string): string[] {
  return [`${ROUTER_CATALOG_IAM}${suffix}`];
}

export function isDH(source: CatalogSource): boolean {
  return source === 'DATAHUB';
}

export const mapIamOEMPrice = (
  price: string | undefined,
  currency: string | undefined,
): ReferencePriceType | undefined => {
  if (!price || !currency || isNaN(Number(price))) {
    return undefined;
  }
  const priceInEuro = String(Number(price) / 100);
  return {
    currency,
    garageView: {
      vatExcludedPrice: priceInEuro,
      vatIncludedPrice: priceInEuro,
    },
    clientView: {
      recommendedPriceVatExcluded: priceInEuro,
      recommendedPriceVatIncluded: priceInEuro,
    },
  };
};

const catalogTypes = [OTHER_BRAND_KEY, OEM_BRAND_KEY] as const;

export const isValidIamCatalogType = (catalogType: string | null): catalogType is IamCatalogBrandsType =>
  catalogType !== null && (catalogTypes as readonly string[]).includes(catalogType);

export function formatCategoryLabelToDataCy(label: string, prefix?: string): string {
  const prefixString = prefix ? `${prefix}-` : '';
  return `${prefixString}${label
    .toLowerCase()
    .replaceAll('-', '')
    .replaceAll(/\s{1,2}/g, '-')}`;
}
