import React from 'react';
import { VehicleOriginalFactoryTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { SelectedTireProps } from 'domains/tires/Tire.types';
import TireCard from 'pages/CatalogPage/DH/Tires/TireCard';
import { Flex, MarginBox } from 'UI';

interface OriginalFactoryTiresProps extends SelectedTireProps {
  originalFactoryTires: VehicleOriginalFactoryTire[];
}

const OriginalFactoryTires = ({ originalFactoryTires, selectedTire, setSelectedTire }: OriginalFactoryTiresProps) => {
  return (
    <Flex justify={'flex-start'} wrap={'wrap'}>
      {originalFactoryTires.map((tire) => (
        <MarginBox key={`original-factory-tire-${tire.partNumber}`} mb={15} mr={15}>
          <TireCard tire={tire} isSelected={selectedTire === tire.partNumber} setSelectedTire={setSelectedTire} />
        </MarginBox>
      ))}
    </Flex>
  );
};
export default OriginalFactoryTires;
