import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilIcon } from 'assets/icons';
import { theme } from 'styles';
import { Box, Flex, Icon, PaddingBox, Text } from 'UI';

interface ModifyButton {
  editMode: boolean;
  handleEdit: (value: boolean) => void;
}

const ModifyButton = ({ editMode, handleEdit }: ModifyButton) => {
  const { t } = useTranslation();
  return (
    <Box onClick={() => handleEdit(!editMode)}>
      <PaddingBox pl={15}>
        <Flex direction={'row'} justify={'flex-end'} align={'center'}>
          <Text type={'h4_paragraph'} displayStyle={'link'} cursor={'pointer'}>
            {editMode ? t('common.action.close', 'Close') : t('common.action.modify', 'Modify')}
          </Text>
          <Icon IconComponent={PencilIcon} round size={20} color={theme.color.blue} />
        </Flex>
      </PaddingBox>
    </Box>
  );
};

export default ModifyButton;
