import React from 'react';
import { Table } from 'antd';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataNL = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>PRIVACYVERKLARING VOOR RPART STORE</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            De Renault Groep, waarvan Renault Nederland N.V. onderdeel uitmaakt, ziet er voortdurend op toe dat de
            regelgeving met betrekking tot de bescherming van persoonsgegevens (hierna &quot;Persoonsgegevens&quot;
            genoemd) wordt nageleefd, zoals beschreven in ons beleid voor de bescherming van persoonsgegevens dat
            beschikbaar is op{' '}
            <Link to={'https://www.renault.nl/bescherming-persoonsgegevens.html'} external newTab>
              https://www.renault.nl/bescherming-persoonsgegevens.html
            </Link>
            .
          </Paragraph>
          <Paragraph>
            Renault biedt de website RpartStore aan, te bereiken via{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>{' '}
            (het &quot;Platform&quot;) om i) het inzien van technische documentatie te vergemakkelijken,
            reserveonderdelen en accessoires voor voertuigen (de &quot;Producten&quot;) te koop aan te bieden, ii) de
            gebruiker in staat te stellen technische incidenten op het Platform te melden en iii) rechtstreeks
            commercieel contact te genereren tussen verkopers (professionals uit het Renaultnetwerk) en kopers
            (uitsluitend voor zakelijk gebruik). In het kader van het bezoeken van, en de interactie met, het Platform
            verzamelt Renault, als verantwoordelijke voor de verwerking, persoonsgegevens rechtstreeks van de gebruikers
            van het Platform (&quot;Gebruikers&quot;), evenals, indien van toepassing, indirect, persoonsgegevens met
            betrekking tot de eindklanten van de Gebruikers (&quot;Derde Betrokkene&quot;). In overeenstemming met de
            Algemene Voorwaarden voor het gebruik van het Platform (hierna de &quot;Algemene Voorwaarden&quot; genoemd),
            wil Renault de grootst mogelijke transparantie garanderen met betrekking tot de verwerking van
            persoonsgegevens via het Platform. Het doel van deze privacyverklaring is om Gebruikers te informeren over
            de voorwaarden waaronder Renault persoonsgegevens verwerkt van hen, dan wel hun respectieve
            vertegenwoordigers die natuurlijke personen zijn (&quot;u&quot;) en, indien van toepassing, de
            persoonsgegevens van Derde Betrokkenen, evenals de rechten en opties die Gebruikers hebben om
            persoonsgegevens te controleren en privacy te beschermen. Deze privacyverklaring is uitsluitend van
            toepassing op verwerking in verband met het Platform.
          </Paragraph>
          <Paragraph>
            Termen met een hoofdletter die in deze privacyverklaring worden gebruikt maar niet gedefinieerd, hebben de
            betekenis die eraan wordt gegeven in de Algemene Voorwaarden. In dit document verwijst &quot;wij&quot;,
            &quot;ons&quot;, &quot;onze&quot; of &quot;Renault&quot; naar Renault Nederland.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'1. WIE VERWERKT UW PERSOONSGEGEVENS?'} />
          <Paragraph>
            Renault Nederland N.V., gevestigd in Nederland aan de Boeingavenue 275 (1119 PD) te Schiphol-Rijk, verwerkt
            Persoonsgegevens voor de hieronder genoemde doeleinden (zie 3. Waarvoor worden Persoonsgegevens gebruikt?)
            als verantwoordelijke voor de verwerking.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. WELKE PERSOONSGEGEVENS VERWERKEN WIJ?'} />
          <Paragraph>
            &quot;Persoonsgegevens&quot; zijn alle gegevens die betrekking hebben op een natuurlijke persoon en kunnen
            worden gebruikt om direct (zoals uw voor- en/of achternaam) of indirect (bijvoorbeeld de combinatie van een
            postcode en huisnummer) te identificeren. Als de Gebruiker zelf geen natuurlijke persoon is, zijn
            Persoonsgegevens de gegevens die betrekking hebben op diens vertegenwoordigers die natuurlijke personen
            zijn.
          </Paragraph>
          <Paragraph>
            In het algemeen verbinden wij ons ertoe enkel de Persoonsgegevens te verzamelen die noodzakelijk zijn voor
            elk van de doeleinden waarvoor wij Persoonsgegevens nodig hebben.
          </Paragraph>
          <Paragraph>
            De Persoonsgegevens die we verzamelen zijn afhankelijk van onze interactie met de Gebruiker en kunnen
            informatie bevatten over:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>uw verbinding/verificatie op het Platform;</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                identificatiegegevens en uw professionele contactgegevens (zakelijke e-mail, naam, voornaam);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                uw gebruik van het Platform en het apparaat dat u gebruikt om het Platform te bezoeken, inclusief
                logbestanden (met betrekking tot het aantal bezoeken, bezochte pagina, gebruikte webbrowser), IP-adres,
                tijdzone en cookies (voor meer informatie verwijzen wij u naar de &quot;Cookies&quot; pagina van het
                Platform);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                schattingen die u via het Platform hebt gemaakt met betrekking tot diagnose, reparatie of recycling
                (&quot;Technische Operaties&quot;) van het voertuig van de Derde Betrokkene, inclusief schattingen die
                niet resulteren in de indiening van een Technische Operatie;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                een bestelling van Producten bij het Renault R1 distributienetwerk (de verzamelde gegevens zijn met name
                de professionele gegevens van de zakelijke contactpersoon die de levering zal ontvangen);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                de commerciële relatie (d.w.z. uw eventuele verzoeken om ondersteuning met betrekking tot het
                functioneren van het Platform, klachten en/of uitoefening van uw rechten op persoonsgegevens).
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
          <Paragraph>
            De gegevens die nodig zijn om te reageren op een verzoek of om te voldoen aan een contract of wettelijke
            verplichting worden vermeld in de verzamelformulieren. Als u de verplichte gegevens niet wilt verstrekken,
            kunnen we uw verzoek mogelijk niet verwerken of u de relevante diensten niet leveren.
          </Paragraph>
          <Paragraph>
            In overeenstemming met onze Algemene Voorwaarden is het Platform niet bedoeld voor minderjarigen en
            verzamelen we daarom niet bewust gegevens over hen. De producten en diensten die wij aanbieden zijn primair
            voor volwassenen. Daarom voeren we geen specifieke verwerking uit op minderjarigen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. WAARVOOR WORDEN PERSOONSGEGEVENS GEBRUIKT?'} />
          <Paragraph>
            We zullen u uitleg geven over de context van het verzamelen van Persoonsgegevens, de doeleinden waarvoor we
            ze verwerken en de duur dat we ze bewaren.
          </Paragraph>
          <MarginBox mt={15} />
          <Section>
            <SectionHeader title={'3.1 CONTEXT VAN VERZAMELING'} />
            <Paragraph>We verzamelen Persoonsgegevens in het bijzonder wanneer:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>u het Platform gebruikt;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  u rechtstreeks contact hebt met Renault via het Platform (een formulier invullen, contact opnemen);
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>u het Platform bezoekt, dat cookies of andere tracers kan gebruiken;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  u een verzoek om technische ondersteuning uitvoert met betrekking tot de werking van het Platform, een
                  Technische Operatie registreert of een technische handeling bewerkt en verstuurt of, indien nodig, een
                  Service Verzoek instelt.
                </Text>
              </Li>
            </Ul>
          </Section>
          <Section>
            <SectionHeader title={'3.2. DOELSTELLINGEN VAN DE VERWERKING VAN PERSOONSGEGEVENS'} />
            <Paragraph>
              Binnen de context van onze relatie en in overeenstemming met de context waarin uw Persoonsgegevens zijn
              verzameld, kunnen wij uw Persoonsgegevens gebruiken om:
            </Paragraph>
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>A - Onze interacties te beheren;</Text>
              </Li>
              <Li>
                <Text type={'text'}>B - Onze commerciële relatie beheren;</Text>
              </Li>
              <Li>
                <Text type={'text'}>C - Studies en analyses uit te voeren;</Text>
              </Li>
              <Li>
                <Text type={'text'}>D - Onze marketingactiviteiten te beheren;</Text>
              </Li>
            </Ul>
            <MarginBox mt={10} />
            <Section>
              <SectionHeader title={'3.2.A. ONZE INTERACTIES BEHEREN'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Doelstellingen',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-a',
                    width: '50%',
                  },
                  {
                    title: 'Rechtsgrond',
                    dataIndex: 'legal',
                    key: 'legal-3-2-a',
                    width: '50%',
                  },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: 'Bezoeken aan het Platform meten en het onderhoud en de beveiliging ervan beheren',
                    legal: (
                      <Text type={'text'}>
                        Deze verwerking is gebaseerd op uw toestemming voor het plaatsen/lezen van
                        &quot;niet-essentiële&quot; cookies in uw terminal (zie de &quot;Cookies&quot; pagina van het
                        Platform), of op ons gerechtvaardigd belang (om u een veilig Platform te bieden)
                      </Text>
                    ),
                  },
                  {
                    id: '1',
                    objectives: 'Het beheer van uw authenticatie op het Platform',
                    legal: (
                      <Text type={'text'}>
                        Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die de Gebruiker
                        met ons is aangegaan (beheerst door de Algemene Voorwaarden)
                      </Text>
                    ),
                  },
                  {
                    id: '2',
                    objectives: 'Om de Gebruiker verbinding te laten maken met het Platform',
                    legal: (
                      <Text type={'text'}>
                        Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die de Gebruiker
                        met ons is aangegaan (beheerst door de Algemene Voorwaarden)
                      </Text>
                    ),
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.B. ONZE COMMERCIËLE RELATIE BEHEREN'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Doelstellingen',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-b',
                    width: '50%',
                  },
                  { title: 'Rechtsgrond', dataIndex: 'legal', key: 'legal-3-2-b', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `Om de Gebruiker in staat te stellen Technische Operaties uit te voeren, met name in verband met de reparatie van de voertuigen`,
                    // eslint-disable-next-line max-len
                    legal: `Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die wij met de Gebruiker zijn aangegaan (beheerst door de Algemene Voorwaarden).`,
                  },
                  {
                    id: '1',
                    // eslint-disable-next-line max-len
                    objectives: `Om een Gebruiker in staat te stellen technische aftersales documentatie betreffende de producten van Renault (zoals: reserveonderdelen,  arbeidstijd, reparatiemethoden en diagnostiek, tutorials, zonder volledig te zijn) en/of merken van andere autofabrikanten in te zien`,
                    // eslint-disable-next-line max-len
                    legal: `Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die wij met de Gebruiker zijn aangegaan (beheerst door de Algemene Voorwaarden).`,
                  },
                  {
                    id: '2',
                    // eslint-disable-next-line max-len
                    objectives: `Om de Gebruiker in staat te stellen het bedrag verband houdende met de Technische Operaties en, indien nodig, om een Service Verzoek te doen via het Platform`,
                    // eslint-disable-next-line max-len
                    legal: `Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die wij met de Gebruiker zijn aangegaan (beheerst door de Algemene Voorwaarden).`,
                  },
                  {
                    id: '3',
                    objectives: `Onze commerciële relatie beheren`,
                    // eslint-disable-next-line max-len
                    legal: `Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die wij met de Gebruikers zijn aangegaan (beheerst door de Algemene Voorwaarden).`,
                  },
                  {
                    id: '4',
                    objectives: `Service Verzoeken met betrekking tot de werking van het Platform te beheren`,
                    // eslint-disable-next-line max-len
                    legal: `Deze verwerking wordt gerechtvaardigd door de uitvoering van de overeenkomst die wij met de Gebruiker zijn aangegaan (beheerst door de Algemene Voorwaarden).`,
                  },
                  {
                    id: '5',
                    // eslint-disable-next-line max-len
                    objectives: `Reageren op eventuele verzoeken om rechten uit te oefenen met betrekking tot uw persoonsgegevens betreffende een (vertegenwoordiger van een) Gebruiker (zie onderdeel 5 hierna over "uw rechten")`,
                    // eslint-disable-next-line max-len
                    legal: `Deze verwerking is gebaseerd op onze wettelijke verplichtingen en kan inhouden dat de identiteit van de verzoeker wordt gecontroleerd`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.C. STUDIES EN ANALYSES UITVOEREN'} />
              <Paragraph>
                Er worden onderzoeken en analyses uitgevoerd om onze prestaties te meten, de kwaliteit van onze
                producten en diensten en de mate van klanttevredenheid te beoordelen en deze voortdurend te verbeteren.
              </Paragraph>
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Doelstellingen',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-c',
                    width: '50%',
                  },
                  { title: 'Rechtsgrond', dataIndex: 'legal', key: 'legal-3-2-c', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `Beheer van onze tevredenheidsonderzoeken,inclusief het antwoord (d.w.z. uw mening),om de tevredenheid van Gebruikers met betrekking tot het Platform te meten`,
                    legal: `Deze verwerking is gebaseerd op onze gerechtvaardigde belangen (om de prestaties van onze activiteit te meten)`,
                  },
                  {
                    id: '1',
                    objectives: `Onze activiteiten controleren (verslaggeving)`,
                    legal: `Deze verwerking is gebaseerd op ons gerechtvaardigd belang (het verbeteren van onze producten en diensten)`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.D. ONZE MARKETINGACTIVITEITEN BEHEREN'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Doelstellingen',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-d',
                    width: '50%',
                  },
                  { title: 'Wettelijke basis', dataIndex: 'legal', key: 'legal-3-2-d', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Reclamecampagnes sturen naar het Renault-netwerk (B2B) / Gebruikers`,
                    legal: `Deze verwerking is gebaseerd op ons gerechtvaardigd belang (om onze producten bekend te maken)`,
                  },
                ]}
              />
              <MarginBox mt={15} />
              <Paragraph>
                Als we Persoonsgegevens moeten verwerken voor andere doeleinden dan de doeleinden die in de bovenstaande
                tabel worden genoemd, wordt u daarvan vooraf op de hoogte gesteld en nemen we alle aanvullende
                maatregelen die nodig kunnen zijn om ervoor te zorgen dat alle verwerkingen in overeenstemming zijn met
                de wet.
              </Paragraph>
            </Section>
          </Section>
          <Section>
            <SectionHeader title={'3.3 HOE LANG WE PERSOONSGEGEVENS BEWAREN'} />
            <Paragraph>
              In overeenstemming met toepasselijke wet- en regelgeving verplichten wij ons om Persoonsgegevens verwerkt
              in verband met het Platform alleen te bewaren voor de tijd die nodig is om het beoogde doel te bereiken,
              om aan uw behoeften te voldoen of om aan onze wettelijke verplichtingen te voldoen.
            </Paragraph>
            <Paragraph>Bij het bepalen van deze tijd houden we in het bijzonder rekening met het volgende:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>De tijd die nodig is om een verzoek of klacht te verwerken;</Text>
              </Li>
              <Li>
                <Text type={'text'}>uw interesse in ons Platform;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  de noodzaak om een bepaalde geschiedenis bij te houden van interacties met het Platform, deze duur
                  varieert in het bijzonder afhankelijk van het feit of u een Service Verzoek hebt ondertekend of niet;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Onze wettelijke of reglementaire verplichtingen.</Text>
              </Li>
            </Ul>
            <Paragraph>In het bijzonder:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Persoonsgegevens die via cookies worden verzameld in het kader van de analyse van de prestaties van de
                  inhoud die u op onze sites raadpleegt en de weergave van gerichte online reclame. Niet-essentiële
                  trackers/cookies, zoals reclamecookies, worden maximaal 13 maanden bewaard vanaf de datum van uw
                  toestemming (mits u uw toestemming niet voor die tijd intrekt). Persoonsgegevens die via cookies
                  worden verzameld, worden 25 maanden verwerkt en vervolgens geanonimiseerd. De bewaartermijn voor uw
                  keuzes (toestemming of weigering van zogenaamde niet-essentiële cookies onderhevig aan voorafgaande
                  verzameling van toestemming) is 6 maanden vanaf het moment van verzamelen van uw toestemming. Kijk
                  voor meer informatie op onze &quot;Cookie&quot; pagina;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  De Persoonsgegevens die worden verwerkt in het kader van het onderhoud en de beveiliging van het
                  Platform en van onze databases worden 6 maanden bewaard (bijv.: logs);
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  De Persoonsgegevens die worden verwerkt in het kader van de geschiedenis van uw Technische Operaties
                  via het Platform en/of een Service Verzoek dat u hebt verzonden, worden 6 maanden bewaard. Schattingen
                  die door de Gebruiker via het Platform zijn gemaakt, worden 3 maanden bewaard.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Wanneer de Gebruiker een werknemer is van een professioneel lid van het Renault-netwerk, worden zijn
                  of haar gegevens die via het Platform zijn verzameld bewaard voor de duur van zijn of haar
                  arbeidsovereenkomst met Renault of, indien van toepassing, de toestemming die de werkgever van de
                  Gebruiker heeft gegeven om toegang te krijgen tot het Platform;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Persoonsgegevens met betrekking tot verzoeken van een Betrokkene om zijn rechten uit te oefenen,
                  worden maximaal 3 jaar bewaard vanaf de afsluiting van uw verzoek, afhankelijk van het recht dat wordt
                  uitgeoefend. Wanneer het verzamelen van een identiteitsbewijs noodzakelijk was, worden deze gegevens
                  verwijderd zodra de verificatie is voltooid.
                </Text>
              </Li>
            </Ul>
            <Paragraph>
              Wanneer we uw persoonsgegevens niet langer nodig hebben, worden ze verwijderd uit onze systemen of anoniem
              gemaakt zodat we u niet langer kunnen identificeren.
            </Paragraph>
            <Paragraph>
              Het kan echter noodzakelijk zijn om sommige Persoonsgegevens te archiveren om te kunnen reageren op
              eventuele juridische procedures, gedurende de verjaringstermijn die is voorzien in de toepasselijke
              wetgeving.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'4. WIE HEEFT TOEGANG TOT UW PERSOONSGEGEVENS?'} />
          <Paragraph>
            Om de in deze privacyverklaring genoemde doeleinden te bereiken en alleen voor zover noodzakelijk voor het
            nastreven van deze doeleinden:
          </Paragraph>
          <Ul>
            <Li>
              zorgen wij ervoor dat alleen personen die naar behoren zijn gemachtigd om Persoonsgegevens te verwerken in
              overeenstemming met hun functies en taken, dit mogen doen.
            </Li>
            <Li>
              kunnen we sommige Persoonsgegevens doorgeven aan de volgende ontvangers:
              <Ul type={'circle'}>
                <MarginBox mt={10} />
                <Li>
                  aan de leden van het Renault-netwerk (d.w.z. de leden van het R1-netwerk bij wie de Gebruiker
                  gemachtigd is om een bestelling voor Producten te plaatsen), onder voorbehoud van uw toestemming
                  indien dit wettelijk vereist is.
                </Li>
                <Li>
                  Renault en zijn leden van het netwerk hebben namelijk een overeenkomst gesloten waarin zij zich er als
                  onafhankelijke verantwoordelijken voor de verwerking toe verbinden hun verplichtingen uit hoofde van
                  de wet- en regelgeving inzake de bescherming van persoonsgegevens na te komen, met inbegrip van onder
                  meer de volgende beginselen :
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>persoonsgegevens rechtmatig en eerlijk verwerken;</Li>
                    <Li>de nauwkeurigheid en kwaliteit van de gegevens te waarborgen;</Li>
                    <Li>
                      het implementeren van alle passende technische en organisatorische maatregelen om een
                      beveiligingsniveau te garanderen dat in verhouding staat tot het risico en om samen te werken in
                      het geval van een datalek;
                    </Li>
                    <Li>om alleen betrouwbare sub-verwerkers in te huren;</Li>
                    <Li>passende waarborgen te implementeren voor de overdracht van gegevens;</Li>
                    <Li>
                      de rechten van betrokkenen respecteren, waaronder het recht om geïnformeerd te worden over
                      gegevensverwerking.
                    </Li>
                  </Ul>
                  <MarginBox mt={10} />
                  Met betrekking tot het laatste principe worden werknemers van Gebruikers uit het Renault-netwerk
                  geïnformeerd dat deze privacyverklaring een aanvulling is op de informatie over gegevensverwerking die
                  u mogelijk is verstrekt door uw werkgever als professioneel onderdeel van het Renault-netwerk.
                </Li>
              </Ul>
            </Li>
            <Li>
              verwerkers van persoonsgegevens in de zin van de toepasselijke regelgeving inzake persoonsgegevens, om de
              hierna opgesomde taken uit te voeren. Wij dragen zorg voor het waarborgen van deze relaties (contracten,
              audits, garanties en veiligheidstests...) en geven hen instructies met betrekking tot de taken waarvan wij
              hen met name belasten:
              <Ul type={'circle'}>
                <MarginBox mt={7} />
                <Li>Hosting,</Li>
                <Li>
                  Beveiliging en onderhoud van het Platform en de via het Platform verzamelde en/of verwerkte databases,
                </Li>
                <Li>
                  Beheer van klantrelaties en technische ondersteuning met betrekking tot de werking van het Platform,
                </Li>
                <Li>zarządzanie relacjami z klientami i wsparcie w zakresie incydentów związanych z platformą,</Li>
                <Li>Publieksmetingen op het platform,</Li>
                <Li>Meting van de tevredenheid van de gebruiker over het platform.</Li>
                <MarginBox mt={7} />
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            We kunnen verplicht worden om Persoonsgegevens aan derden te verstrekken om te voldoen aan een wettelijke
            verplichting of administratieve of gerechtelijke beslissing.
          </Paragraph>
          <Paragraph>
            Renault kan Persoonsgegevens delen met derden bij het overdragen van verplichtingen en rechten met
            betrekking tot de contractuele relatie tussen u en Renault aan dergelijke derden in het geval van de
            overdracht van een bedrijfsonderdeel, een fusie door oprichting van een nieuw bedrijf, een splitsing of een
            wijziging van de zeggenschap die van invloed is op Renault. Voorafgaand aan een dergelijke gebeurtenis zal
            Renault u apart informeren over de informatie met betrekking tot het delen van uw gegevens en uw toestemming
            vragen indien dit wettelijk vereist is.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'5. WAT ZIJN UW RECHTEN ALS BETROKKENE?'} />
          <Section>
            <SectionHeader title={'5.1. UW RECHTEN'} underline />
            <Paragraph>
              U hebt als Betrokkene verschillende rechten onder de regelgeving voor de bescherming van Persoonsgegevens:
            </Paragraph>
            <Paragraph>
              Het recht om bezwaar te maken tegen de verwerking van uw Persoonsgegevens, mits u redenen hebt die verband
              houden met uw specifieke situatie, en het recht om te verzoeken om beperking van de verwerking van uw
              persoonsgegevens, in sommige gevallen zoals bepaald in de regelgeving.
            </Paragraph>
            <Paragraph>
              Het recht om bezwaar te maken tegen communicatie van ons: u kunt te allen tijde verzoeken om niet langer
              communicatie van ons te ontvangen.
            </Paragraph>
            <Paragraph>
              Het recht om uw toestemming te allen tijde in te trekken voor de doeleinden waarvoor wij uw toestemming
              hebben verkregen (zie hierboven 3.2. De doelstelling van de verwerking van uw Persoonsgegevens).
            </Paragraph>
            <Paragraph>
              Recht op informatie: u hebt recht op duidelijke, transparante en begrijpelijke informatie over hoe we uw
              Persoonsgegevens gebruiken en over uw rechten. Deze privacyverklaring is daar een voorbeeld van.
            </Paragraph>
            <Paragraph>
              Recht op toegang tot uw Persoonsgegevens: u hebt het recht om informatie te verkrijgen over de verwerking
              van uw Persoonsgegevens (met inbegrip van de gegevens die worden gebruikt, voor welke doeleinden, enz).
            </Paragraph>
            <Paragraph>
              Recht op rectificatie: u hebt het recht om uw Persoonsgegevens te corrigeren als deze onnauwkeurig of
              onvolledig zijn, ondanks onze inspanningen om ze actueel te houden, zodat wij kunnen voldoen aan onze
              verplichting om actuele gegevens over u te hebben.
            </Paragraph>
            <Paragraph>
              Een recht op overdraagbaarheid van uw gegevens, d.w.z., onder bepaalde voorwaarden, het recht om de
              Persoonsgegevens die u aan ons hebt verstrekt, te ontvangen in een gestructureerd, algemeen gebruikt
              computerformaat, en om deze door te geven aan een derde partij indien dit technisch mogelijk is.
            </Paragraph>
            <Paragraph>
              Recht op wissen (of recht om vergeten te worden): u hebt het recht om uw Persoonsgegevens te laten wissen
              of verwijderen. Dit recht kan worden beperkt in het licht van onze contractuele of wettelijke
              verplichtingen (lopend contract) (met name het voorkomen van gerechtelijke stappen).
            </Paragraph>
            <Paragraph>
              Tot slot hebt u het recht om een klacht in te dienen bij een toezichthoudende autoriteit voor de
              bescherming van persoonsgegevens in Nederland, de Autoriteit Persoonsgegevens. We raden u aan om contact
              met ons op te nemen voordat u een klacht indient, zodat we samen kunnen proberen uw probleem op te lossen.
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'5.2. HOE OEFENT U ALS BETROKKENE UW RECHTEN UIT?'} underline />
            <Paragraph>
              Om uw rechten uit te oefenen, kunt u uw verzoek op elk gewenst moment per post naar het volgende adres
              sturen
            </Paragraph>
            <Paragraph>
              Renault Nederland N.V., Juridische afdeling - Functionaris Gegevensbescherming, Boeingavenue 275, 1119 PD
              Schiphol-Rijk, Nederland
            </Paragraph>
            <Paragraph>of</Paragraph>
            <Paragraph>
              een e-mail naar het volgende adres:{' '}
              <Link to={'mailto:dataprotection-com@renault.com'} external newTab>
                dataprotection-com@renault.com
              </Link>
              .
            </Paragraph>
            <Paragraph>
              In beide gevallen dient u, om de verwerking van uw verzoek te vergemakkelijken, aan te geven dat uw
              verzoek betrekking heeft op het Platform &quot;<b>RpartStore</b>&quot;.
            </Paragraph>
            <Paragraph>
              Als wij niet zeker zijn van uw identiteit, kan u worden gevraagd om uw identiteit te bewijzen (d.w.z. de
              kopie van uw identiteit wordt uitsluitend verwerkt om te reageren op uw verzoek en wordt onmiddellijk na
              verificatie verwijderd).
            </Paragraph>
            <Paragraph>
              We zullen al het mogelijke doen om zo snel mogelijk op uw verzoek te reageren, maar niet later dan één
              maand na ontvangst van uw verzoek. In overeenstemming met de toepasselijke regelgeving behouden we ons het
              recht voor om deze periode te verlengen tot drie maanden als uw verzoek bijzonder complex is.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'6. HOE WORDEN UW PERSOONSGEGEVENS BEVEILIGD?'} />
          <Paragraph>
            Uw persoonsgegevens worden opgeslagen op beveiligde servers. We treffen passende maatregelen voor
            gegevensbeveiliging en -bescherming in overeenstemming met de nieuwste technologieën en eisen dit van onze
            verwerkers en partners.
          </Paragraph>
          <Paragraph>
            Wanneer de verwerking van Persoonsgegevens gepaard gaat met de overdracht van gegevens, zorgen we ervoor dat
            deze overdracht plaatsvindt onder passende voorwaarden die een adequaat niveau van bescherming, beveiliging
            en vertrouwelijkheid garanderen.
          </Paragraph>
          <Paragraph>
            Waar mogelijk worden Persoonsgegevens verwerkt in de Europese Economische Ruimte (EER). Aangezien sommige
            van onze dienstverleners of hun verwerkers echter gevestigd zijn in landen buiten de EER, kunnen uw
            persoonsgegevens in die landen worden verwerkt. In sommige van deze landen gelden mogelijk andere regels
            voor Persoonsgegevens dan in de Europese Unie. In een dergelijk geval (i) besteden we bijzondere aandacht om
            ervoor te zorgen dat deze overdracht wordt uitgevoerd in overeenstemming met de toepasselijke regelgeving en
            (ii) treffen we waarborgen die een adequaat niveau van bescherming van uw privacy en fundamentele rechten
            waarborgen (in het bijzonder door gebruik te maken van de modelcontractbepalingen van de Europese
            Commissie). Op eenvoudig verzoek, gericht aan het adres vermeld onder 5. &quot;Wat zijn uw rechten?&quot;,
            kunnen wij u meer informatie verstrekken over dergelijke overdrachten (in het bijzonder de
            modelcontractbepalingen van de Europese Commissie).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. OVERIGE INFORMATIE DIE U NODIG KUNT HEBBEN'} />
          <Paragraph>
            Wij kunnen ook op geautomatiseerde wijze technische informatie over u verzamelen wanneer u een van onze
            sites bezoekt of een van onze mobiele applicaties gebruikt, met name uw IP-adres, uw navigatieparameters,
            door middel van tracers (cookies).
          </Paragraph>
          <Paragraph>
            Voor meer informatie nodigen we u uit om de &quot;
            <Link to={ROUTER_COOKIES(Country.NL.key)} newTab>
              Cookies
            </Link>
            &quot; pagina van het Platform te raadplegen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. WIJZIGING VAN DEZE PRIVACYVERKLARING'} />
          <Paragraph>
            We kunnen deze privacyverklaring van tijd tot tijd wijzigen. Wij zullen u informeren en/of uw toestemming
            vragen wanneer dat nodig of vereist is. We raden u daarom aan deze privacyverklaring te raadplegen telkens
            wanneer u ons Platform bezoekt om de meest recente versie te bekijken.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Bijgewerkt op 23 oktober2023</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
