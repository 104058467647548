import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { RootState } from 'app/AppStore';
import { ShoppingCartIcon } from 'assets/icons';
import { getActionStatus, getFileReferencesUploadStatusErrorRows } from 'domains/basket/Basket.store';
import CartAddReference, { CartAddReferenceResult } from 'pages/CartPage/CartStep/CartAddReference';
import CartHeader from 'pages/CartPage/CartStep/CartHeader';
import { EmptyCartNotificationWrapper } from 'pages/CartPage/CartStep/EmptyCart/EmptyCart.styled';
import { FileReferencesRowsErrors } from 'pages/CartPage/CartStep/FileUpload';
import SearchBar from 'pages/HomePage/search/SearchBar';
import { Box, CenteredSpin, Flex, Icon, MarginBox, StickyBox, Text } from 'UI';
import { LOADING } from 'utils';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';

type EmptyCartProps = {
  addedOtherReference?: string;
  setAddedByReferenceBox: (referenceNumber: string | undefined) => void;
  numberOfRemovedReferences?: number;
};

const EmptyCart = ({ addedOtherReference, setAddedByReferenceBox }: EmptyCartProps) => {
  const theme = useTheme();
  const offsetTop = useOffsetTop('CART');
  const otherRefsActions = useSelector((state: RootState) => getActionStatus(state, addedOtherReference));
  const errorRows = useSelector(getFileReferencesUploadStatusErrorRows);
  const { t } = useTranslation();
  return (
    <>
      <StickyBox offsetTop={offsetTop} background={'white'}>
        <Box height={15} background={theme.color.white} />
        <MarginBox mx={15}>
          <CartHeader setAddedByReferenceBox={setAddedByReferenceBox} isCartEmpty={true} />
        </MarginBox>
      </StickyBox>
      <MarginBox mx={30}>
        <Flex direction={'column'} align={'center'}>
          <MarginBox mt={60} />
          <Icon IconComponent={ShoppingCartIcon} size={48} color={theme.color.grey35} noPointer />
          <MarginBox mt={15} />
          <Text type={'h2'} disableGutter>
            {`${t('cart.empty_cart', 'Your Cart is currently empty.')}`}
          </Text>
          <MarginBox mt={15} />
          <Text type={'section'} disableGutter>
            {`${t('cart.empty_cart.description', 'Identify a vehicle or add reference directly.')}`}
          </Text>
          <MarginBox mt={45} />
          <SearchBar />
          <MarginBox mt={25} />
          <Text type={'h3_dim'}>{t('common.or', 'Or')}</Text>
          <MarginBox mt={25}>
            {otherRefsActions && otherRefsActions.status === LOADING ? (
              <CenteredSpin size={'large'} />
            ) : (
              <CartAddReference setAddedByReferenceBox={setAddedByReferenceBox} />
            )}
          </MarginBox>
          <MarginBox mt={30} />
          {otherRefsActions && (
            <CartAddReferenceResult
              otherSection={undefined}
              addedOtherReference={addedOtherReference}
              otherReferencesAction={otherRefsActions}
              setExpanded={() => undefined}
              setHighlightedRef={() => undefined}
            />
          )}
          <EmptyCartNotificationWrapper>
            <FileReferencesRowsErrors errorRows={errorRows} />
          </EmptyCartNotificationWrapper>
          <MarginBox mt={300} />
        </Flex>
      </MarginBox>
    </>
  );
};

export default EmptyCart;
