import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterBy } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterBy';
import { FilterOperation } from '@1po/1po-bff-fe-spec/generated/common/filter_and_sort/FilterOperation';
import { FilterAndSortField } from '@1po/1po-bff-fe-spec/generated/order/request/model/FilterAndSortField';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Checkbox } from 'antd-v5';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { RootState } from 'app/AppStore';
import { CarIcon } from 'assets/icons';
import {
  fetchDealerOrdersRequestSaga,
  getInProgressOrdersFilter,
  resetInProgressOrdersFilters,
  resetInProgressOrdersForNewSearch,
  setActiveFilters,
  setInProgressOrdersFilter,
} from 'domains/order/Order.store';
import { SortKey } from 'domains/order/Order.types';
import { CarInput } from 'pages/MyOrdersPage/InProgressOrders/InProgressOrders.styled';
import {
  BlackButton,
  CollapseWithKey,
  Flex,
  Icon,
  Input,
  MarginBox,
  SelectOptionSingle,
  Text,
  CheckboxGroup,
} from 'UI';
import { Panel } from 'UI/Collapse';
import { RangeDatePicker, RangeValue } from 'UI/DatePicker/RangeDatePicker';
import { compareArrays, RefType } from 'utils';
import { MAX_DATE } from 'utils/date';

export const getRequestOptionTitle = (t: TFunction, value: string): string => {
  const option = requestOptions(t);

  const foundOption = option.findLast((opt) => opt.value === value);

  return foundOption ? foundOption.title : '';
};

export const requestOptions = (t: TFunction): SelectOptionSingle[] => {
  return [
    {
      value: 'TO_BE_VALIDATED',
      title: t('order.order_status.to_be_validated', 'To be validated'),
    },
    {
      value: 'PROCESSING',
      title: t('order.order_status.processing', 'Processing'),
    },
    {
      value: 'IN_PROGRESS',
      title: t('order.order_status.in_progress', 'In progress'),
    },
    {
      value: 'PARTIALLY_DELIVERED',
      title: t('order.order_status.partially_delivered', 'Partially delivered'),
    },
    {
      value: 'DELIVERED',
      title: t('order.order_status.delivered', 'Delivered'),
    },
    {
      value: 'CANCELLED',
      title: t('order.order_status.cancelled', 'Cancelled'),
    },
    {
      value: 'ERROR',
      title: t('order.order_status.error', 'Error'),
    },
  ];
};

function CollapseSection({ clickOnFilterButton }: { clickOnFilterButton: () => void }) {
  // ToDo - refactor this whole file
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const vehicleNameFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'VEHICLE'));
  const orderFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'ORDER'));
  const referenceFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'REFERENCE_NUMBER'));
  const orderMarkFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'ORDER_MARK'));
  const startDateFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'START_DATE'));
  const endDateFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'END_DATE'));
  const statusFilter = useSelector((state: RootState) => getInProgressOrdersFilter(state, 'STATUS'));
  const [vehicleNameValue, setVehicleNameValue] = useState<string>(vehicleNameFilter?.value ?? '');
  const [referenceValue, setReferenceValue] = useState<string>(referenceFilter?.value ?? '');
  const [orderValue, setOrderValue] = useState<string>(orderFilter?.value ?? '');
  const [orderMarkValue, setOrderMarkValue] = useState<string>(orderMarkFilter?.value ?? '');
  const [orderStatusValues, setOrderStatusValues] = useState<string[]>(statusFilter?.value ? [statusFilter.value] : []);
  const [activeKey, setActiveKey] = useState<string | string[]>(['panel-1']);
  const [selectedDatesValue, setSelectedDates] = useState<RangeValue | null>(null);

  useEffect(() => {
    if (!vehicleNameFilter?.value) {
      setVehicleNameValue('');
    }
  }, [vehicleNameFilter]);

  useEffect(() => {
    if (!orderFilter?.value) {
      setOrderValue('');
    }
  }, [orderFilter]);

  useEffect(() => {
    if (!referenceFilter?.value) {
      setReferenceValue('');
    }
  }, [referenceFilter]);

  useEffect(() => {
    if (!orderMarkFilter?.value) {
      setOrderMarkValue('');
    }
  }, [orderMarkFilter]);

  useEffect(() => {
    if (!statusFilter || !statusFilter.value) {
      if (orderStatusValues.length > 0) {
        setOrderStatusValues([]);
      }
      return;
    }

    const values = statusFilter.value.split('-');

    if (!compareArrays(values, orderStatusValues)) {
      setOrderStatusValues(values);
    }
  }, [orderStatusValues, statusFilter]);

  useEffect(() => {
    if (!startDateFilter?.value && !endDateFilter?.value) {
      setSelectedDates(null);
    }
  }, [startDateFilter, endDateFilter]);

  useEffect(() => {
    const defaultActiveKeys = ['panel-1'];
    referenceValue && defaultActiveKeys.push('panel-2');
    orderValue && defaultActiveKeys.push('panel-3');
    orderMarkValue && defaultActiveKeys.push('panel-4');

    if (startDateFilter?.value && endDateFilter?.value) {
      const startDate = moment(startDateFilter.value);
      const endDate = moment(endDateFilter.value);
      setSelectedDates([startDate, endDate]);
      defaultActiveKeys.push('panel-5');
    }

    setActiveKey(defaultActiveKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setFilter(key: SortKey, field: FilterAndSortField, value: string, filterOperation: FilterOperation) {
    dispatch(
      setInProgressOrdersFilter({ key, filter: new FilterBy<FilterAndSortField>(field, value, filterOperation) }),
    );
  }

  const onChange = (key: string | string[]) => {
    setActiveKey(key);
  };

  function resetFilters() {
    dispatch(resetInProgressOrdersFilters());
    setVehicleNameValue('');
    setOrderValue('');
    setOrderMarkValue('');
    setReferenceValue('');
    setSelectedDates(null);
    setOrderStatusValues([]);
    dispatch(resetInProgressOrdersForNewSearch());
    dispatch(fetchDealerOrdersRequestSaga());
  }

  return (
    <>
      <MarginBox mx={15} my={15}>
        <Flex justify={'space-between'}>
          <Text type={'text_dim'}>{t('order.in_progress.filter.filter_by', 'Filter by:')}</Text>
          <Text type={'link'} cursor={'pointer'} onClick={resetFilters}>
            {t('common.filter.clear_filters', 'Clear filters')}
          </Text>
        </Flex>
      </MarginBox>
      <CollapseWithKey position="end" noBorder activeKey={activeKey} onChange={onChange}>
        <Panel header={t('common.vehicle', 'Vehicle')} key={'panel-1'}>
          <CarInput
            placeholder={t('order.in_progress.filter.type_your_VIN', 'Type your VIN, VRN or vehicle model here...')}
            bordered={true}
            prefix={
              <Flex>
                <Icon IconComponent={CarIcon} size={14} color={theme.color['ink_black']} />
                <MarginBox mr={10} />
              </Flex>
            }
            value={vehicleNameValue}
            onChange={(value) => {
              const val = value.trim().replaceAll(/\s+|-/g, ' ');
              setFilter('VEHICLE', 'VEHICLE', val, 'CONTAINS');
              setVehicleNameValue(value);
            }}
            onPressEnter={clickOnFilterButton}
          />
        </Panel>
        <Panel header={t('order.in_progress.filter.reference_number', 'Reference number')} key={'panel-2'}>
          <Input
            bordered
            value={referenceValue}
            onChange={(value) => {
              const val = value.trim().replaceAll(/\s/g, '');
              setFilter('REFERENCE_NUMBER', 'REFERENCE_NUMBER', val, 'CONTAINS');
              setReferenceValue(value);
            }}
            onPressEnter={clickOnFilterButton}
          />
        </Panel>
        <Panel header={t('order.in_progress.filter.order_number', 'Order number')} key={'panel-3'}>
          <Input
            bordered
            value={orderValue}
            onChange={(value) => {
              const val = value.trim().replaceAll(/\s/g, '');
              setFilter('ORDER', 'ORDER', val, 'CONTAINS');
              setOrderValue(value);
            }}
            onPressEnter={clickOnFilterButton}
          />
        </Panel>
        <Panel header={t('order.in_progress.filter.order_mark', 'Order mark')} key={'panel-4'}>
          <Input
            bordered
            value={orderMarkValue}
            onChange={(value) => {
              const val = value.trim().replaceAll(/\s/g, '');
              setFilter('ORDER_MARK', 'ORDER_MARK', val, 'CONTAINS');
              setOrderMarkValue(value);
            }}
            onPressEnter={clickOnFilterButton}
          />
        </Panel>
        <Panel header={t('order.in_progress.filter.status', 'Status')} key={'panel-5'}>
          <CheckboxGroup
            value={orderStatusValues}
            onChange={(values: CheckboxValueType[]) => {
              const stringValues = values.map(String);
              setFilter('STATUS', 'STATUS', stringValues.join('-'), 'IN');
              setOrderStatusValues(stringValues);
            }}
          >
            <Flex direction={'column'}>
              {requestOptions(t).map((option) => (
                <MarginBox mb={15} key={option.value}>
                  <Checkbox value={option.value}>{option.title}</Checkbox>
                </MarginBox>
              ))}
            </Flex>
          </CheckboxGroup>
        </Panel>
        <Panel header={t('order.in_progress.filter.date', 'Date')} key={'panel-6'}>
          <RangeDatePicker
            picker={'date'}
            value={selectedDatesValue}
            onCalendarChange={(values) => {
              const startValue = values && values[0];
              const endValue = values && values[1];

              const isoStartDate = startValue ? startValue.startOf('day').toISOString() : undefined;
              const isoEndDate = endValue ? endValue.endOf('day').toISOString() : undefined;

              setFilter(
                'START_DATE',
                'DATE',
                isoStartDate ?? new Date(-MAX_DATE).toISOString(),
                'GREATER_THAN_OR_EQUAL',
              );
              setFilter('END_DATE', 'DATE', isoEndDate ?? new Date(MAX_DATE).toISOString(), 'LESS_THAN_OR_EQUAL');
              setSelectedDates(values);
            }}
          />
        </Panel>
      </CollapseWithKey>
    </>
  );
}

export function FilterOptions({ buttonRef }: { buttonRef: RefType<HTMLButtonElement> }) {
  // ToDo - refactor this whole file..
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function clickOnFilterButton() {
    dispatch(resetInProgressOrdersForNewSearch());
    dispatch(fetchDealerOrdersRequestSaga());
    dispatch(setActiveFilters());
    buttonRef.current.click();
  }

  return (
    <Flex direction={'column'} minWidth={300}>
      <CollapseSection clickOnFilterButton={clickOnFilterButton} />
      <MarginBox mx={32} my={15}>
        <Flex direction={'row'} justify={'flex-end'}>
          <BlackButton size={'middle'} onClick={clickOnFilterButton}>
            {t('order.in_progress.filter', 'Filter')}
          </BlackButton>
        </Flex>
      </MarginBox>
    </Flex>
  );
}
