import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CrossSellingRowError } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/model/CrossSellingRowError';
import { List } from 'antd';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import {
  getCrossSellingFileErrorRows,
  setCrossSellingFileModalStatus,
  setCrossSellingFileUploadStatus,
  setDeleteErrorRows,
} from 'domains/crossSelling/CrossSelling.store';
import { ErrorAlert, ErrorDetails } from 'pages/CartPage/CartStep/FileUpload/FileReferencesRowsErrors.styled';
import { BlackButton, Box, Flex, MarginBox, Text, WhiteButton } from 'UI';

const EList = ({ errorRows }: { errorRows: CrossSellingRowError[] }) => {
  const { t } = useTranslation();

  const getErrorText = (errorRow: CrossSellingRowError) => {
    switch (errorRow.errorCode) {
      case 'EMPTY_ROW':
        return t('cross_selling.upload.row_error.empty_row', 'Empty row');
      case 'NO_CROSS_REFERENCE':
        return t('cross_selling.upload.row_error.no_cross_reference', 'Row without cross reference');
      case 'NO_SOURCE_REFERENCE':
        return t('cross_selling.upload.row_error.no_source_reference', 'Row without source reference');
    }
  };

  return (
    <List
      dataSource={errorRows}
      renderItem={(item) => (
        <List.Item>
          <Text type={'text_bold'}>{`${t('common.row', 'Row')} ${item.rowNumber}`}</Text>
          <Box width={5} />
          <Text type={'light_14_black_65'}>{getErrorText(item)}</Text>
          <Flex />
        </List.Item>
      )}
      split={false}
    />
  );
};

const FileRowsErrors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorRows = useSelector(getCrossSellingFileErrorRows);
  return (
    <Flex direction={'column'} minWidth={300} align={'center'}>
      <MarginBox mt={64}>
        <Box width={900}>
          <ErrorAlert
            message={
              <Flex direction={'row'} justify={'space-between'}>
                <Text type={'light_14_black_65'}>
                  {t(
                    'common.file_upload.items_could_not_be_added',
                    'One or several items in your file need to be checked.',
                  )}
                </Text>
              </Flex>
            }
            type={'error'}
            showIcon
            closable
            onClose={() => undefined}
          />
          <ErrorDetails>
            <Box align={'left'}>
              <Text type={'text'} displayStyle={'error'}>
                {t('common.details', 'Details')}
              </Text>
              {errorRows.length > 0 && <EList errorRows={errorRows} />}
            </Box>
            <Flex justify={'flex-end'}>
              <WhiteButton stretch={false} size={'middle'} onClick={() => dispatch(setDeleteErrorRows())}>
                {t('common.ignore', 'Ignore')}
              </WhiteButton>
              <MarginBox ml={15} />
              <BlackButton
                stretch={false}
                size={'middle'}
                onClick={() => {
                  dispatch(setCrossSellingFileUploadStatus(NO_FILE));
                  dispatch(setCrossSellingFileModalStatus(NO_FILE));
                }}
              >
                {t('common.try_again', 'Try again')}
              </BlackButton>
            </Flex>
          </ErrorDetails>
        </Box>
      </MarginBox>
    </Flex>
  );
};

export default FileRowsErrors;
