import styled from 'styled-components';
import { theme } from 'styles';
import { useBreakpointSelector } from 'utils/hooks/useBreakpoint';

export const LOGIN_SECTION_CONTAINER_SIZE = theme.breakpoint.xxl;

export const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const SOuterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center bottom;
  background-image: url('/images/landing/background.jpg');
`;

export const SInnerContainer = styled.div`
  max-width: ${LOGIN_SECTION_CONTAINER_SIZE}px;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const InfoWrapper = styled.div`
  flex: 1;
  text-align: left;
  max-width: 700px;
  color: white;
  margin-top: ${() => useBreakpointSelector()([30, 30]) + 'px'};
  margin-left: ${() => useBreakpointSelector()([30, 139]) + 'px'};
  margin-right: 30px;
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: ${() => useBreakpointSelector()([24, 48]) + 'px'};
  margin-top: ${() => useBreakpointSelector()([60, 113]) + 'px'};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -1.2px;
  max-width: 500px;
`;

export const ScrollButtonWrapper = styled.div<{ offset: number }>`
  position: absolute;
  bottom: ${({ offset }) => `${offset}px`};
  left: 50%;
  text-align: right;
`;

export const ScrollButton = styled.div`
  cursor: pointer;
`;

export const LoginFormWrapper = styled.div`
  position: relative;
  margin: 0 38px auto 38px;
  max-width: 504px;
  z-index: 2;
`;

export const LoginButtonsWrapper = styled.div`
  max-width: 370px;
  display: inline-flex;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const InformationWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
`;
