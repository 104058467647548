import { Table } from 'antd';
import styled from 'styled-components';
import { defaultBoxShadow } from 'UI';

export const STable = styled(Table)`
  flex: 1;

  .highlighted-row {
    ${defaultBoxShadow}
  }

  thead > tr > .ant-table-cell {
    padding: 5px 5px 5px 10px;
  }

  tbody > tr > .ant-table-cell {
    padding: 3px 3px 3px 10px;
  }

  thead > tr > th {
    background-color: ${({ theme }) => theme.color.grey95};
  }
` as typeof Table;
