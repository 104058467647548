import styled from 'styled-components';
import { Flex } from 'UI';

export const CartNotificationResultWrapper = styled.div`
  width: 100%;
  margin-bottom: -20px;
`;

export const CartStepHeaderWrapper = styled(Flex)`
  width: 100%;
`;
