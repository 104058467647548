import React from 'react';
import { useSelector } from 'react-redux';
import { DataContainer } from 'components/DataContainer';
import { useFetchVehicleTechnicalCriteria } from 'domains/catalog/Catalog.requests';
import { getMaintenanceValues } from 'domains/maintenanceValues/MaintenanceValues.store';
import DITARenderer from 'pages/CatalogPage/DH/MaintenanceValues/DITARenderer';
import { useGetCriteria } from 'pages/CatalogPage/DH/VehicleTechnicalDetails/TechnicalCriteria';
import { getData } from 'utils';

const MaintenanceValues = () => {
  const maintenanceValues = useSelector(getMaintenanceValues);
  const maintenanceValuesData = getData(maintenanceValues);
  const criteria = useGetCriteria();
  useFetchVehicleTechnicalCriteria(criteria.items, false);

  return (
    <DataContainer data={maintenanceValues}>
      <DITARenderer xml={maintenanceValuesData?.contentPath ?? ''} />
    </DataContainer>
  );
};

export default MaintenanceValues;
