import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';
import { Flex, Icon, IconType, Link, MarginBox, Text } from 'UI';
import { Header, MenuItemBox } from '../MyStore.styled';
import { useMyStoreMenu } from '../useMyStoreMenu';

export const MY_STORE_HEADER_HEIGHT = 160;

const MenuItem = ({ title, icon, isActive }: { title: string; icon: IconType; isActive: boolean }) => {
  return (
    <MenuItemBox minHeight={54} maxHeight={54} isActive={isActive}>
      <Flex align={'center'}>
        <Icon IconComponent={icon} size={16} mr={10} />
        <Text type={'h5_light'}>{title}</Text>
      </Flex>
    </MenuItemBox>
  );
};

const HeaderMenu = () => {
  const { t } = useTranslation();
  const myStoreMenuData = useMyStoreMenu();

  const isActive = (link: string) => window.location.pathname.includes(link);

  return (
    <Header
      direction={'column'}
      background={theme.color.grey95}
      minHeight={MY_STORE_HEADER_HEIGHT}
      maxHeight={MY_STORE_HEADER_HEIGHT}
    >
      <MarginBox my={30} ml={50}>
        <Text type={'h1_black'} disableGutter>
          {t('my-store', 'My store')}
        </Text>
      </MarginBox>
      <Flex>
        <MarginBox ml={50} />
        {myStoreMenuData.map((m) => (
          <Link key={m.key} to={m.link}>
            <MenuItem title={m.title} isActive={isActive(m.link)} icon={m.icon} />
          </Link>
        ))}
      </Flex>
    </Header>
  );
};

export default HeaderMenu;
