import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActiveTabParam, PARAM_SPARE_PARTS } from 'domains/catalog/Catalog.types';
import { TabState } from 'pages/CatalogPage/IAM/SubcategorySection/SubcategorySectionHeader/SubcategorySectionHeader';

export const useSubcategorySectionLinkParams = (
  activeTab: string | null,
  setTabState: (tab: TabState) => void,
  params: URLSearchParams,
) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const tab = activeTab ? (activeTab as TabState) ?? PARAM_SPARE_PARTS : PARAM_SPARE_PARTS;
    params.set(ActiveTabParam, tab);
    history.replace(`${location.pathname}?${params}`);
    setTabState(tab);
  }, [activeTab, dispatch, history, params, setTabState]);
};
