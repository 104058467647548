import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { useFetchGarageInfo } from 'domains/garage/Garage.requests';
import {
  fetchIsReturnsFeatureEnabledForSellerRequestSaga,
  isReturnsFeatureEnabledForSeller,
} from 'domains/order/Order.store';
import { DealerRepository } from 'domains/user';
import { getData } from 'utils';
import CacheMap from 'utils/dataStructure/CacheMap';

// fetch cache is used because this hook is called multiple times - just prevent multiple http calls
const fetchCache = new CacheMap<string, boolean>(10000);

export const useOrderReturnsEnabledByR1 = (sellerId: string, repository: DealerRepository): boolean => {
  const dispatch = useDispatch();

  const garageInfoState = useFetchGarageInfo(sellerId, repository);
  const data = getData(garageInfoState);
  const r1code = data?.birCode ?? '';

  const orderReturnEnabledFromStore = useSelector((state: RootState) =>
    isReturnsFeatureEnabledForSeller(state, r1code),
  );
  useEffect(() => {
    if (sellerId === '' || sellerId === undefined || r1code === undefined || r1code === '') {
      return;
    }

    if (orderReturnEnabledFromStore === undefined && !fetchCache.has(r1code)) {
      fetchCache.set(r1code, true);
      dispatch(fetchIsReturnsFeatureEnabledForSellerRequestSaga(r1code));
    }
  }, [dispatch, orderReturnEnabledFromStore, r1code, sellerId]);
  return orderReturnEnabledFromStore ?? false;
};
