import styled from 'styled-components';

export const ScrollContainer = styled.div`
  width: 150px;
  height: 450px;
  overflow: hidden;

  :hover {
    overflow-y: auto;
  }
`;
