import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ROUTER_CART, ROUTER_CATALOG_DH, ROUTER_HOME, ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { CommentIcon } from 'assets/icons';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text } from 'UI';

const HelpIcon = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const firstHelpPages = [ROUTER_HOME, ROUTER_CART, ROUTER_IN_PROGRESS_ORDERS, ROUTER_CATALOG_DH];
  const getEnableSupport = () => {
    const isCatalogL3 = history.location.pathname.split('/').length >= 8;
    return isCatalogL3 || firstHelpPages.includes(history.location.pathname);
  };

  return (
    <Flex direction={'row'} align={'center'}>
      <MarginBox ml={21} mr={15}>
        <Icon IconComponent={CommentIcon} size={15} display={'block'} color={theme.color.brand_black} />
      </MarginBox>
      <MarginBox mr={10} mt={6}>
        {getEnableSupport() ? (
          <Text type={'section'} cursor={'pointer'}>
            {t('dynamic_help', 'Dynamic help')}
          </Text>
        ) : (
          <Text type={'section'} cursor={'pointer'} displayStyle={'disabled'}>
            {t('dynamic_help', 'Dynamic help')}
          </Text>
        )}
      </MarginBox>
    </Flex>
  );
};

export default HelpIcon;
