import styled from 'styled-components';
import { Box } from 'UI';

export const SPlateCard = styled.div<{ $loading: boolean }>`
  position: relative;
  text-align: center;
  box-shadow: 0 3px 6px ${({ theme }) => theme.color.shadow_3};
  border: none;
  border-radius: 0;
  display: flex;
  flex: 1;
  ${({ $loading }) => $loading && 'height: 100%;'};
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SBox = styled(Box)<{ maxWidth: number; isModal: boolean }>`
  max-width: ${({ maxWidth, isModal }) => !isModal && maxWidth}px;
`;
