/* eslint-disable max-len */
import { FullPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FullPromotion';
import {
  DiscountType,
  Promotion,
  TargetAudience,
} from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { Section } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Section';
import { ReferencesUploadRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/ReferencesUploadRequest';
import { SaveBannerPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveBannerPromotion';
import { SaveDiscountPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveDiscountPromotion';
import { SaveFlashQuantityLimitedPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveFlashQuantityLimitedPromotion';
import { SaveFlashTimeLimitedPromotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/SaveFlashTimeLimitedPromotion';
import { ValidateProductsRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/request/ValidateProductsRequest';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';
import { FILE, NO_FILE } from 'components/FileUpload/paramsByState';
import {
  CFSectionLocal,
  CreatePromotionModel,
  DealerTypeLocal,
  ReferencesSectionLocal,
  TargetAudienceLocal,
  TargetAudienceType,
  UploadedReferencesFromFile,
} from './Promotion.types';

const getCorrectTargetAudience = (
  targetAudience: TargetAudienceLocal,
  targetAudienceType: TargetAudienceType,
): TargetAudience => {
  if (targetAudienceType === 'NETWORK') {
    return {
      dealerTypes: targetAudience.dealerTypes?.map((item) => item.dealerType),
      dealers: undefined,
    };
  } else {
    return {
      dealerTypes: undefined,
      dealers: targetAudience.dealers,
    };
  }
};

function mapCommercialFamilyCodes(promotion: CreatePromotionModel): string[] | undefined {
  return promotion.commercialFamilyCodes?.reduce((acc: string[], section: CFSectionLocal) => {
    if ((promotion.promotionType !== 'BANNER' ? section.discount > 0 : true) && section.items.length > 0) {
      section.items.forEach((item) => acc.push(item.item));
    }
    return acc;
  }, []);
}

function mapReferences(uploadedReferencesFromFiles: UploadedReferencesFromFile[], shouldPublish: boolean): Section[] {
  return uploadedReferencesFromFiles
    ? uploadedReferencesFromFiles
        .filter((uploadedFile) => {
          const isValidRows = uploadedFile.rows && uploadedFile.rows.length > 0;
          const isValidDiscount = uploadedFile.discount && uploadedFile.discount !== 0;
          return shouldPublish ? isValidRows && isValidDiscount : isValidRows;
        })
        .map((uploadedFile) => {
          return {
            items: uploadedFile.rows ?? [],
            discount: uploadedFile.discount ?? 0,
          };
        })
    : [];
}

export const toDiscountMessage = (promotion: CreatePromotionModel, shouldPublish: boolean): SaveDiscountPromotion => {
  return {
    promotionId: promotion.promotionId,
    title: promotion.title,
    catchLine: promotion.catchLine ?? '',
    description: promotion?.description,
    image: promotion.image,
    publicationStart: promotion.publicationStart ? new Date(promotion.publicationStart).toISOString() : undefined,
    publicationEnd: promotion.publicationEnd ? new Date(promotion.publicationEnd).toISOString() : undefined,
    commercialFamilyCodes: promotion.commercialFamilyCodes
      ? promotion.commercialFamilyCodes.reduce((acc: Section[], section: CFSectionLocal) => {
          if (section.discount > 0 && section.items.length > 0) {
            acc.push({
              discount: section.discount,
              items: section.items.map((item) => item.item),
            });
          }
          return acc;
        }, [])
      : [],
    references: mapReferences(promotion.uploadedReferencesFromFiles, shouldPublish),
    targetAudience: getCorrectTargetAudience(promotion.targetAudience, promotion.targetAudienceType),
    discountType: promotion.discountType as DiscountType,
    referenceFileInfos: promotion.referenceFileInfos,
    additionalDiscount: promotion.additionalInformation?.discount,
    additionalImage: promotion.additionalInformation?.image,
    additionalTitle: promotion.additionalInformation?.title,
    additionalCatchLine: promotion.additionalInformation?.catchLine,
    additionalDescription: promotion.additionalInformation?.description,
    shouldPublish,
  };
};

export const toFlashQuantityLimitedMessage = (
  promotion: CreatePromotionModel,
  shouldPublish: boolean,
): SaveFlashQuantityLimitedPromotion => {
  const reference =
    promotion.references && promotion.references.length > 0 && promotion.references[0].items.length > 0
      ? [...promotion.references].slice(0, 1)[0].items[0].item
      : '';
  return {
    promotionId: promotion.promotionId,
    title: promotion.title,
    publicationStart: promotion.publicationStart ? new Date(promotion.publicationStart).toISOString() : undefined,
    publicationEnd: promotion.publicationEnd ? new Date(promotion.publicationEnd).toISOString() : undefined,
    discount: promotion.references ? [...promotion.references].slice(0, 1)[0].discount : 0,
    quantity: promotion.quantityLimitFlashPromotion,
    targetAudience: getCorrectTargetAudience(promotion.targetAudience, promotion.targetAudienceType),
    discountType: promotion.discountType as DiscountType,
    reference,
    shouldPublish,
  };
};

export const toFlashTimeLimitedMessage = (
  promotion: CreatePromotionModel,
  shouldPublish: boolean,
): SaveFlashTimeLimitedPromotion => {
  const references = promotion.uploadedReferencesFromFiles
    ?.filter(
      (uploadedFile) =>
        uploadedFile.rows && uploadedFile.rows.length > 0 && uploadedFile.discount && uploadedFile.discount !== 0,
    )
    .map((uploadedFile) => uploadedFile.rows)
    .reduce((prev, curr) => curr && prev?.concat(curr), []);

  const discountCommercialFamilyCodes = promotion?.commercialFamilyCodes && promotion.commercialFamilyCodes[0].discount;
  const discountReference =
    promotion?.references && promotion.references.length > 0 ? promotion.references[0].discount : 0;
  const uploadedReferencesInfo =
    promotion?.referenceFileInfos && promotion?.referenceFileInfos.length > 0
      ? promotion?.referenceFileInfos[0]
      : undefined;

  return {
    promotionId: promotion.promotionId,
    title: promotion.title,
    catchLine: promotion.catchLine ?? '',
    description: promotion?.description,
    image: promotion.image,
    publicationStart: promotion.publicationStart ? new Date(promotion.publicationStart).toISOString() : undefined,
    publicationEnd: promotion.publicationEnd ? new Date(promotion.publicationEnd).toISOString() : undefined,
    commercialFamilyCodes: mapCommercialFamilyCodes(promotion),
    discount: discountCommercialFamilyCodes ?? discountReference,
    targetAudience: getCorrectTargetAudience(promotion.targetAudience, promotion.targetAudienceType),
    discountType: promotion.discountType as DiscountType,
    referenceFileInfo: uploadedReferencesInfo,
    references,
    shouldPublish,
  };
};

export const toBannerMessage = (promotion: CreatePromotionModel, shouldPublish: boolean): SaveBannerPromotion => {
  const references = promotion.uploadedReferencesFromFiles
    ?.filter((uploadedFile) => uploadedFile.rows && uploadedFile.rows.length > 0)
    .map((uploadedFile) => uploadedFile.rows ?? [])
    .reduce((acc, next) => acc.concat(next), []);
  const uploadedReferencesInfo =
    promotion?.referenceFileInfos && promotion?.referenceFileInfos.length > 0
      ? promotion?.referenceFileInfos[0]
      : undefined;

  return {
    promotionId: promotion.promotionId,
    title: promotion.title,
    catchLine: promotion.catchLine ?? '',
    description: promotion?.description,
    image: promotion.image,
    publicationStart: promotion.publicationStart ? new Date(promotion.publicationStart).toISOString() : undefined,
    publicationEnd: promotion.publicationEnd ? new Date(promotion.publicationEnd).toISOString() : undefined,
    commercialFamilyCodes: mapCommercialFamilyCodes(promotion),
    targetAudience: getCorrectTargetAudience(promotion.targetAudience, promotion.targetAudienceType),
    referenceFileInfo: uploadedReferencesInfo,
    references,
    shouldPublish,
  };
};

export const toCreatePromotionModel = ({
  promotion,
  isPublished,
}: {
  promotion: FullPromotion;
  isPublished: boolean;
}): CreatePromotionModel => {
  const editing = isPublished;
  const isSaved = !isPublished;
  const containsCustomImage = !!promotion.banner?.key || promotion.promotionType === 'BANNER';
  const containsAdditionalImage = !!promotion.additionalBanner?.key;
  const commercialFamilyCodes: CFSectionLocal[] | undefined =
    promotion.commercialFamilyCodes && promotion.commercialFamilyCodes.length > 0
      ? promotion.commercialFamilyCodes.map((section) => {
          return {
            items: section.items.map((item) => {
              return {
                item,
                removable: isSaved,
              };
            }),
            discount: section.discount,
            editing,
          };
        })
      : undefined;

  const references = promotion.references
    ? promotion.references.map((line) => {
        return {
          items: line.items.map((item) => {
            return {
              item: item.referenceNumber,
              removable: false,
            };
          }),
          discount: line.discount,
          // status: line.items?.length > 0 ? FILE : NO_FILE,
        };
      })
    : undefined;

  const quantityLimitFlashPromotion =
    promotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED'
      ? promotion.references && promotion.references[0].items[0].quantity
      : undefined;

  const dealers = promotion.targetAudience.dealers;
  const dealersList = dealers && dealers.length > 0 ? dealers : undefined;
  const targetAudienceType: TargetAudienceType = dealersList ? 'DEALERS' : 'NETWORK';
  const targetAudience: TargetAudienceLocal = {
    dealers: promotion.targetAudience.dealers,
    dealerTypes: promotion.targetAudience.dealerTypes?.map((dealer) => {
      return {
        dealerType: dealer,
        removable: isSaved,
      } as DealerTypeLocal;
    }),
  };

  return {
    promotionId: promotion.promotionId,
    promotionType: promotion.promotionType,
    discountType: promotion.discountType,
    title: promotion.title,
    catchLine: promotion.catchLine,
    description: promotion.description,
    publicationStart: promotion.publicationStart,
    publicationEnd: promotion.publicationEnd,
    containsCustomImage,
    image: containsCustomImage ? promotion.banner : undefined,
    uploadedTargetedAudienceFromFile: {
      // todo file Id, filename not sent from BE,
      fileId: uuidV4(),
      rows: dealersList,
      status: dealersList ? FILE : NO_FILE,
      fileName: dealersList ? 'ID Tresor.csv' : undefined,
    },
    referenceFileInfos: promotion.referenceFileInfos,
    uploadedReferencesFromFiles:
      promotion.references && promotion.references.length !== 0
        ? promotion.references.map((line, index) => {
            const fileInfo = promotion?.referenceFileInfos ? promotion?.referenceFileInfos[index] : undefined;
            return {
              fileId: fileInfo?.fileId ?? uuidV4(),
              rows: line.items.map((item) => item.referenceNumber),
              fileName: fileInfo?.fileName,
              fileSize: fileInfo?.fileSize ?? 0,
              uploadTime: fileInfo?.created,
              status: line.items ? FILE : NO_FILE,
              discount: line.discount,
              editing,
            };
          })
        : [{ fileId: uuidV4(), rows: [], status: NO_FILE, discount: 0 }],
    additionalInformation: {
      containsAdditionalTitle: !!promotion.additionalTitle,
      containsAdditionalCatchLine: !!promotion.additionalCatchLine,
      containsAdditionalDescription: !!promotion.additionalDescription,
      title: promotion.additionalTitle,
      catchLine: promotion.additionalCatchLine,
      description: promotion.additionalDescription,
      image: containsAdditionalImage ? promotion.additionalBanner : undefined,
      discount: Number(promotion.additionalDiscount),
      containsAdditionalImage,
    },
    duplicatePromotionIds: [],
    targetAudience,
    targetAudienceType,
    quantityLimitFlashPromotion,
    commercialFamilyCodes,
    references,
    isSaved,
    editing,
  };
};

export const toCreatePromotionCopyModel = ({ promotion }: { promotion: FullPromotion }): CreatePromotionModel => {
  const createPromotionModel = toCreatePromotionModel({ promotion, isPublished: false });
  const currentDate = moment(new Date().toISOString()).startOf('day').format();

  return {
    ...createPromotionModel,
    promotionId: uuidV4(),
    publicationStart:
      promotion.status === 'SAVED' && promotion.publicationStart >= currentDate
        ? promotion.publicationStart
        : undefined,
    publicationEnd:
      promotion.status === 'SAVED' && promotion.publicationEnd >= currentDate ? promotion.publicationEnd : undefined,
    copyPromotion: true,
    isUpdated: false,
  };
};

export const toValidateProductsMessage = ({
  promotion,
}: {
  promotion: CreatePromotionModel;
}): ValidateProductsRequest | undefined => {
  if (promotion.promotionType === 'PROMOTION_FLASH') {
    return undefined;
  }
  const references =
    promotion.references?.reduce((acc: string[], section: ReferencesSectionLocal) => {
      section.items.forEach((item) => acc.push(item.item));
      return acc;
    }, []) ?? [];
  const fileReferences =
    promotion.uploadedReferencesFromFiles?.reduce((acc: string[], file: UploadedReferencesFromFile) => {
      if (file.rows && file.rows.length > 0) {
        acc.push(...file.rows);
      }
      if (file.alreadyPromoted && file.alreadyPromoted.length > 0) {
        acc.push(...file.alreadyPromoted);
      }
      return acc;
    }, []) ?? [];
  return {
    promotionId: promotion.promotionId,
    publicationStart: promotion.publicationStart ? new Date(promotion.publicationStart ?? '').toISOString() : '',
    publicationEnd: promotion.publicationEnd ? new Date(promotion.publicationEnd ?? '').toISOString() : '',
    promotionType: promotion.promotionType,
    referenceNumbers: [...references, ...fileReferences],
    commercialFamilyCodes:
      promotion.commercialFamilyCodes?.reduce((acc: string[], section: CFSectionLocal) => {
        section.items.forEach((item) => acc.push(item.item));
        return acc;
      }, []) ?? [],
  };
};
export const toValidateFileMessage = ({
  fileName,
  fileId,
  fileBase64,
  promotion,
}: {
  fileName?: string;
  fileId?: string;
  fileBase64: string;
  promotion: CreatePromotionModel;
}): ReferencesUploadRequest | undefined => {
  if (promotion.promotionType === 'PROMOTION_FLASH') {
    return undefined;
  }
  return {
    fileName,
    fileId,
    fileBase64,
    promotionId: promotion.promotionId,
    promotionType: promotion.promotionType,
    publicationStart: new Date(promotion.publicationStart ?? '').toISOString(),
    // TODO fix, allow nullable date
    publicationEnd: promotion.publicationEnd
      ? new Date(promotion.publicationEnd).toISOString()
      : new Date('2100-01-01').toISOString(),
    commercialFamilyCodes:
      promotion.commercialFamilyCodes?.reduce((acc: string[], section: CFSectionLocal) => {
        section.items.forEach((item) => acc.push(item.item));
        return acc;
      }, []) ?? [],
  };
};

export function getCorrectPromotionView(
  promotion: Promotion | undefined,
  fullPromotion: FullPromotion | undefined,
  displayPreview?: boolean,
): Promotion | undefined {
  if (!promotion) {
    return undefined;
  }
  if (!fullPromotion) {
    return promotion;
  }
  const additionalDiscount = Number(fullPromotion.additionalDiscount ?? 0);
  const promotionPreview = displayPreview
    ? {
        title: fullPromotion.additionalTitle ?? fullPromotion.title,
        catchLine: fullPromotion.additionalCatchLine ?? fullPromotion.catchLine,
        description: fullPromotion.additionalDescription ?? fullPromotion.additionalDescription,
        image: fullPromotion.additionalBanner ?? fullPromotion.banner,
        additionalDiscount: fullPromotion.adjustmentState?.applyDiscount ? 0 : additionalDiscount,
      }
    : {
        title: fullPromotion.title,
        catchLine: fullPromotion.catchLine,
        description: fullPromotion.description,
        image: fullPromotion.banner,
        additionalDiscount: fullPromotion.adjustmentState?.applyDiscount ? -additionalDiscount : 0,
      };

  return {
    ...promotion,
    title: displayPreview !== undefined ? promotionPreview.title ?? promotion.title : promotion.title,
    catchLine: displayPreview !== undefined ? promotionPreview.catchLine ?? promotion.catchLine : promotion.catchLine,
    description:
      displayPreview !== undefined ? promotionPreview.description ?? promotion.description : promotion.description,
    image: displayPreview !== undefined ? promotionPreview.image ?? promotion.image : promotion.image,
    commercialFamilyCodes: promotion.commercialFamilyCodes?.map((section) => {
      return {
        ...section,
        discount:
          displayPreview !== undefined ? promotionPreview.additionalDiscount + section.discount : section.discount,
      };
    }),
    references: promotion.references?.map((section) => {
      return {
        ...section,
        discount:
          displayPreview !== undefined ? promotionPreview.additionalDiscount + section.discount : section.discount,
      };
    }),
  };
}
