import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import moment from 'moment';
import { HourglassFullIcon, HourglassIcon } from 'assets/icons';
import { useFetchIAMServiceProposals } from 'domains/maintenancePlan/MaintenancePlan.requests';
import {
  fetchIAMServiceProposalOperationsRequestSaga,
  getMileageInput,
  getOptionInput,
  getRegistrationDateInput,
  getServiceProposal,
  getServiceProposalOperations,
  setMileageInputRequest,
  setOperationsRequest,
  setOptionInputRequest,
  setRegistrationDateInputRequest,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import RevisionCard from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleCriterias/RevisionCard';
import { RowDivider, SSelect } from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleCriterias/VehicleCriterias.styled';
import { BlueButton, Box, DatePicker, Flex, InputNumber, PaddingBox, Text } from 'UI';
import { getData, isLoading } from 'utils';
import { getStringDateFromMoment } from 'utils/date';

const isValidMileage = (value: unknown): boolean => typeof value === 'number' && !isNaN(value) && value > 0;

const VehicleCriterias = ({
  vehicleDetail,
  handleNext,
}: {
  vehicleDetail?: VehicleDetail;
  handleNext: (step: number) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const regDateStored = getData(useSelector(getRegistrationDateInput));
  const mileageStored = getData(useSelector(getMileageInput));
  const optionStored = getData(useSelector(getOptionInput));
  const registrationDate = new Date(vehicleDetail?.iamVehicle?.registrationDate ?? '');
  const versionCode = vehicleDetail?.iamVehicle?.versionCode;

  const [registrationDateEditable] = useState<boolean>(!moment(registrationDate).isValid());
  const [registrationDateInput, setRegistrationDateInput] = useState<moment.Moment | null>(
    moment(registrationDate).isValid() ? moment(registrationDate) : regDateStored ?? null,
  );
  useFetchIAMServiceProposals(vehicleDetail, getStringDateFromMoment(registrationDateInput));
  const [mileageInput, setMileageInput] = useState<number | undefined>(mileageStored);
  const [optionInput, setOptionInput] = useState<string | number | undefined>(optionStored);
  const [serviceLastCheck, setServiceLastCheck] = useState<boolean>(false);
  const [serviceNextCheck, setServiceNextCheck] = useState<boolean>(true);
  const proposals = useSelector(getServiceProposal);
  const proposalsData = getData(proposals);
  const proposalOperations = useSelector(getServiceProposalOperations);
  const proposalOperationsData = getData(proposalOperations);
  const proposalOptions = useMemo(
    () =>
      proposalsData?.proposals.map((p) => {
        return { title: p.label, value: p.id };
      }) ?? [],
    [proposalsData],
  );

  useEffect(() => {
    if (
      versionCode === undefined ||
      registrationDateInput === null ||
      !registrationDateInput.isValid() ||
      !isValidMileage(mileageInput) ||
      !optionInput
    )
      return;
    setServiceLastCheck(false);
    setServiceNextCheck(true);
    dispatch(
      fetchIAMServiceProposalOperationsRequestSaga({
        versionCode,
        proposalId: optionInput?.toString(),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        mileage: mileageInput.toString(),
        registrationDate: registrationDateInput.format('YYYY-MM-DD'),
      }),
    );
  }, [dispatch, registrationDateInput, mileageInput, optionInput, versionCode]);

  useEffect(() => {
    if (proposalOptions.length === 1) {
      setOptionInput(proposalOptions[0]?.value);
      dispatch(setOptionInputRequest({ option: proposalOptions[0]?.value?.toString() }));
    }
    if (
      optionInput &&
      proposalOptions.length > 1 &&
      !proposalOptions.find((pr) => pr.value === optionInput.toString())
    ) {
      setOptionInput(undefined);
      dispatch(setOptionInputRequest({ option: undefined }));
    }
  }, [dispatch, proposalOptions, optionInput]);

  const handleConfirm = () => {
    const operationsIds = proposalOperationsData?.proposalOperations
      .filter(
        (service) =>
          (service.label === 'NextService' && serviceNextCheck) ||
          (service.label === 'LastService' && serviceLastCheck),
      )
      .flat()
      .map((operation) => operation.operations)
      .flat()
      .map((o) => o.id);

    if (operationsIds !== undefined && operationsIds.length !== 0) {
      dispatch(setOperationsRequest({ operationsIds }));
    }
    handleNext(2);
  };

  const handleSelectLast = () => {
    setServiceLastCheck(!serviceLastCheck);
  };

  const handleSelectNext = () => {
    setServiceNextCheck(!serviceNextCheck);
  };

  return (
    <>
      <Text type={'h1_banner_light'}>
        <Trans i18nKey={'catalog.maintenance.vehicle_details.title'}>Vehicle details</Trans>
      </Text>
      <RowDivider direction={'row'} justify={'space-between'} align={'center'} gap={40}>
        <Flex direction={'column'} gap={15}>
          <Text type={'text_dim_bold'}>
            <Trans i18nKey={'maintenance_plan.first_registration'}>First registration</Trans>
          </Text>
          <DatePicker
            value={moment(registrationDateInput).isValid() ? moment(registrationDateInput) : null}
            disabled={!registrationDateEditable}
            onChange={(newValue) => {
              if (newValue && newValue.isValid()) {
                setRegistrationDateInput(newValue);
                dispatch(setRegistrationDateInputRequest({ registrationDate: newValue }));
              }
            }}
            placeholder={t('maintenance_plan.first_registration', 'First registration')}
          />
        </Flex>
        <Flex direction={'column'} gap={15}>
          <Text type={'text_dim_bold'}>
            <Trans i18nKey={'maintenance_plan.actual_mileage'}>Actual mileage (Km)</Trans>
          </Text>
          <InputNumber
            value={mileageInput}
            onBlur={(val) => {
              setMileageInput(Number(val));
              dispatch(setMileageInputRequest({ mileage: Number(val) }));
            }}
            bordered
            maxLength={10}
          />
        </Flex>
        <Flex direction={'column'} size={3} gap={15}>
          <Text type={'text_dim_bold'}>
            <Trans i18nKey={'maintenance_plan.options'}>Options</Trans>
          </Text>
          <SSelect
            value={optionInput}
            onChange={(request: string | number | undefined) => {
              if (request !== undefined) {
                setOptionInput(request);
                dispatch(setOptionInputRequest({ option: request.toString() }));
              }
            }}
            options={proposalOptions}
            bordered
          />
        </Flex>
      </RowDivider>
      {!!registrationDateInput && isValidMileage(mileageInput) && !!optionInput && (
        <>
          <PaddingBox pt={30} />
          <Text type={'h1_banner_light'}>
            <Trans i18nKey={'catalog.maintenance.vehicle_details.revisions.title'}>Select revisions periodicity</Trans>
          </Text>
          <PaddingBox pt={20} />
          <Flex direction={'row'} justify={'center'} gap={20}>
            <RevisionCard
              icon={HourglassFullIcon}
              selected={serviceLastCheck}
              onSelect={handleSelectLast}
              label={t('maintenance_plan.previous_revision', 'Previous revision')}
              serviceData={proposalOperationsData?.proposalOperations?.find((p) => p.label === 'LastService')}
              loading={isLoading(proposalOperations)}
            />
            <RevisionCard
              icon={HourglassIcon}
              selected={serviceNextCheck}
              onSelect={handleSelectNext}
              label={t('maintenance_plan.upcoming_revision', 'Upcoming revision')}
              serviceData={proposalOperationsData?.proposalOperations?.find((p) => p.label === 'NextService')}
              loading={isLoading(proposalOperations)}
            />
          </Flex>
          <Flex direction={'row'} justify={'center'}>
            <Flex direction={'column'} align={'flex-end'} maxWidth={1600}>
              <PaddingBox pt={45} />
              <Box width={150}>
                <BlueButton
                  shape={'round'}
                  size={'large'}
                  disabled={(!serviceLastCheck && !serviceNextCheck) || !proposalOperationsData?.proposalOperations}
                  onClick={handleConfirm}
                >
                  {t('catalog.maintenance.new_maintenance_plan.next', 'Next')}
                </BlueButton>
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default VehicleCriterias;
