import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTER_ASSISTANCE, ROUTER_ASSISTANCE_OTHER_BRANDS, ROUTER_ASSISTANCE_RENAULT_DACIA } from 'app/AppRouter';
import { getTokenUser, getUserCountry, getUserRights, UserRole } from 'domains/user';
import AssistancePageFooter from 'pages/AssistancePage/AssistanceLayout/AssistancePageFooter';
import AssistancePageHeader from 'pages/AssistancePage/AssistanceLayout/AssistancePageHeader';
import { assistancePadding, Container, emptyPadding, Flex, MarginBox, Text } from 'UI';
import ConnectionIssues from './AssistanceCategories/ConnectionIssues/ConnectionIssues';
import ContactUs from './AssistanceCategories/ContactUs/ContactUs';
import ContactUsHeader from './AssistanceCategories/ContactUsHeader/ContactUsHeader';
import MainMenu from './AssistanceCategories/MainMenu/MainMenu';
import ConnectionIssuesFooter from './AssistanceLayout/ConnectionIssuesFooter';
import AssistanceOtherBrands from './AssistanceOtherBrands';

const AssistancePage = () => {
  const { t } = useTranslation();
  const tokenUser = useSelector(getTokenUser);
  const userRights = useSelector(getUserRights);
  const userHasRights =
    userRights.includes(UserRole.R1) ||
    userRights.includes(UserRole.R2) ||
    userRights.includes(UserRole.R3) ||
    userRights.includes(UserRole.R3_MOTRIO);
  const userCountry = useSelector(getUserCountry);
  const isCountryAllowed = ['ES', 'FR', 'IT'].includes(userCountry ?? '');
  const isViewAllowed = userHasRights && isCountryAllowed;

  const renderAssistanceContent = () => {
    if (!tokenUser) {
      return (
        <Flex direction={'column'}>
          <ConnectionIssues />
          <MarginBox mt={150} />
          <ConnectionIssuesFooter />
        </Flex>
      );
    }
    return !isViewAllowed ? (
      <>
        <ContactUs title={t('assistance.contact_us', 'Contact us')} type={'default'} />
        <AssistancePageFooter type={'default'} />
      </>
    ) : (
      <>
        <Text type={'h1_black'}>{t('assistance.main_menu.title', 'Get in touch with us')}</Text>
        <Switch>
          <Route exact path={`${ROUTER_ASSISTANCE}`} render={() => <MainMenu />} />
          <Route
            exact
            path={`${ROUTER_ASSISTANCE_RENAULT_DACIA}`}
            render={() => (
              <>
                <ContactUsHeader
                  bannerImg={'assistance/assistance-renault-dacia-banner.webp'}
                  alt={'renault dacia cars banner'}
                  description={''}
                />
                <ContactUs title={t('assistance.renault_dacia.title', 'Renault Dacia')} type={'ren-dac'} />
                <AssistancePageFooter type={'ren-dac'} />
              </>
            )}
          />
          <Route
            path={`${ROUTER_ASSISTANCE_OTHER_BRANDS}`}
            render={() => (
              <>
                <ContactUsHeader
                  bannerImg={'assistance/assistance-other-brands-banner.webp'}
                  alt={'other brands cars banner'}
                  description={''}
                />
                <AssistanceOtherBrands />
              </>
            )}
          />
          <Route path={'*'}>
            <Redirect to={'/'} />
          </Route>
        </Switch>
      </>
    );
  };

  return (
    <Container padding={emptyPadding} size={'xxl'}>
      <AssistancePageHeader />
      <Container padding={assistancePadding}>{renderAssistanceContent()}</Container>
    </Container>
  );
};

export default AssistancePage;
