import { ChangeEvent, FocusEvent, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const ONCHANGE_DEBOUNCE_INTERVAL = 500;
export const ONBLUR_DEBOUNCE_INTERVAL = 400;

export interface UseInputParams<T, E> {
  onChange?: (val: T, e?: ChangeEvent<E>) => void;
  onChangeDebounced?: (val: T, e?: ChangeEvent<E>) => void;
  onBlur?: (val: T, e?: FocusEvent<E>) => void;
  onBlurDebounced?: (val: T, e?: FocusEvent<E>) => void;
  onFocus?: (val: T, e?: FocusEvent<E>) => void;
  maxValue?: number;
}

export function useInput<T, E extends HTMLElement>({
  onChange,
  onChangeDebounced,
  onBlur,
  onBlurDebounced,
  onFocus,
  maxValue,
}: UseInputParams<T, E>) {
  const handleChangeDebounced = useDebouncedCallback((debouncedVal: T, e?: ChangeEvent<E>) => {
    onChangeDebounced &&
      onChangeDebounced(
        maxValue !== undefined ? ((Number(debouncedVal) > maxValue ? maxValue : debouncedVal) as T) : debouncedVal,
        e,
      );
  }, ONCHANGE_DEBOUNCE_INTERVAL);

  const handleChange = useCallback(
    (val: T, e?: ChangeEvent<E>) => {
      onChange && onChange(val, e);
      handleChangeDebounced(val, e);
    },
    [onChange, handleChangeDebounced],
  );

  const handleBlurDebounced = useDebouncedCallback((debouncedVal: T, e?: FocusEvent<E>) => {
    onBlurDebounced && onBlurDebounced(debouncedVal, e);
  }, ONBLUR_DEBOUNCE_INTERVAL);

  const handleBlur = useCallback(
    (val: T, e?: FocusEvent<E>) => {
      onBlur && onBlur(val, e);
      handleBlurDebounced(val, e);
    },
    [onBlur, handleBlurDebounced],
  );

  const handleFocus = useCallback(
    (val: T, e?: FocusEvent<E>) => {
      onFocus && onFocus(val, e);
    },
    [onFocus],
  );

  return {
    onChange: (val: T, e: ChangeEvent<E>) => handleChange(val, e),
    onBlur: (val: T, e: FocusEvent<E>) => handleBlur(val, e),
    onFocus: (val: T, e: FocusEvent<E>) => handleFocus(val, e),
  };
}
