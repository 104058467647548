import { FileResponse } from '@1po/1po-bff-fe-spec/generated/files/FileResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const FILES_NAMESPACE = `${APP_NAMESPACE}/FILES`;

export interface FilesState {
  files: Map<string, SearchData<FileResponse>>; // Map<FILE ID, base64>
}

export const GET_FILE_REQUEST = `${FILES_NAMESPACE}/GET_FILE_REQUEST`;
