import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FamilyReferences } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { ChevronDownIcon, ChevronUpIcon, LevelUp } from 'assets/icons';
import { getTechnicityTier } from 'domains/catalog/Catalog.types';
import { buildOperationLabel } from 'domains/maintenancePlan/MaintenancePlan.mapper';
import {
  addReferencesRequest,
  getLastSearchServiceFamilies,
  getServiceOperationsReferences,
  removeOperationRequest,
  removeReferencesRequest,
  selectOperationRequest,
  setReferencesRequest,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { ServiceOperationLocal } from 'domains/maintenancePlan/MaintenancePlan.types';
import { SBadge } from 'pages/CatalogPage/IAM/MaintenancePlan/CustomBadge/CustomBadge.styled';
import Families from 'pages/CatalogPage/IAM/MaintenancePlan/Families';
import { BadgeFlag } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlan.styled';
import { SPanel } from 'pages/CatalogPage/IAM/MaintenancePlan/ServiceOperations/Operation.styled';
import { theme } from 'styles';
import { Box, Checkbox, CollapseWithKey, Flex, Icon, MarginBox, notifyTop, Text, WithTooltip } from 'UI';
import { getData } from 'utils';
import { OFFSET_PADDING, useOffsetTopVehicleCatalog } from 'utils/hooks/useOffsetTop';

const RenderOperation = ({
  operation,
  isMain,
  categoryLabel,
}: {
  operation: ServiceOperationLocal;
  isMain: boolean;
  categoryLabel: string;
}) => {
  const selectedReferencesByOperation = useSelector(getServiceOperationsReferences);
  const selectedReferencesByOperationData = getData(selectedReferencesByOperation);
  const referencesFromStore = selectedReferencesByOperationData?.get(operation.id)?.reduce((acc, item) => {
    const referenceNumbers = acc.get(item.family) || [];
    referenceNumbers.push(item.referenceNumber);
    acc.set(item.family, referenceNumbers);
    return acc;
  }, new Map<string, string[]>());
  const dispatch = useDispatch();
  const panelIdPrefix = 'panel-operation--';
  const { t } = useTranslation();
  const familiesData = useSelector(getLastSearchServiceFamilies);
  const [activeKey, setActiveKey] = useState<string[]>(operation.selected ? [panelIdPrefix + operation.id] : ['']);
  const [selectedReferences, setSelectedReferences] = useState<Map<string, string[]>>(
    referencesFromStore ?? new Map<string, string[]>(),
  );

  const familyCount =
    operation.familyIds
      ?.map((familyId) => getData(familiesData)?.families?.find((familyRef) => familyRef.id === familyId))
      .filter((familyRef): familyRef is FamilyReferences => familyRef !== undefined).length ??
    operation.familyIds?.length;

  useEffect(() => {
    if (operation.selected) {
      setActiveKey([panelIdPrefix + operation.id]);
    } else {
      setActiveKey([]);
    }
  }, [operation.selected, operation.id]);

  function handleChange(id: string, checked: boolean) {
    if (!checked) {
      setActiveKey([]);
    }
    if (checked) {
      dispatch(selectOperationRequest({ operationId: id }));
    } else {
      dispatch(removeOperationRequest({ operationId: id }));
    }
  }

  function isSelected(): boolean {
    return !!operation.selected;
  }

  const handleCollapseChange2 = (key: string | string[]) => {
    setActiveKey(typeof key === 'string' ? [key] : key);
  };

  const offsetTop = useOffsetTopVehicleCatalog('OTHER_BRANDS_CATALOG');

  function onSelectReferences(operationId: string, family: string, references: string[], scrollToId: string) {
    updateSelectedReferences(operationId, family, references, true);
    const divElementAbove = document.getElementById(scrollToId);
    if (divElementAbove) {
      const rect = divElementAbove.getBoundingClientRect();
      const relativeDivOffset = rect.top + window.scrollY;
      const absoluteDivOffset = relativeDivOffset - offsetTop - OFFSET_PADDING;
      window.scrollTo({
        top: absoluteDivOffset,
        behavior: 'smooth',
      });
    }
  }

  const updateSelectedReferences = (
    operationId: string,
    family: string,
    references: string[],
    limitCount: boolean,
  ): void => {
    setSelectedReferences((prevState) => {
      const updatedMap = new Map(prevState);
      if (updatedMap.has(family)) {
        const existingValues = updatedMap.get(family) as string[];
        if (existingValues.includes(references[0])) {
          dispatch(removeReferencesRequest({ operationId, references }));
          updatedMap.set(
            family,
            existingValues.filter((v) => v !== references[0]),
          );
        } else {
          dispatch(addReferencesRequest({ operationId, family, references }));
          if (limitCount) {
            updatedMap.set(family, [references[0]]);
            dispatch(setReferencesRequest({ operationId, family, references }));
          } else {
            updatedMap.set(family, [...existingValues, ...references]);
          }
        }
      } else {
        dispatch(addReferencesRequest({ operationId, family, references }));
        updatedMap.set(family, references);
      }

      return updatedMap;
    });
  };

  const scrollOperationId = 'scroll-operation-';

  return (
    <MarginBox mt={15}>
      <div id={scrollOperationId + operation.id} />
      <CollapseWithKey
        iconInactive={
          <Flex>
            <Icon IconComponent={ChevronDownIcon} height={15} width={15} />
          </Flex>
        }
        iconActive={
          <Flex>
            <Icon IconComponent={ChevronUpIcon} height={15} width={15} />
          </Flex>
        }
        noBorder
        position={'end'}
        noShadow
        noMargin
        key={'service-operation-collapse-' + operation.id}
        activeKey={activeKey}
        onChange={handleCollapseChange2}
      >
        <SPanel
          id={'panel_operation--' + operation.id}
          collapsible={isSelected() ? 'header' : 'disabled'}
          showArrow={isSelected() && !!familyCount}
          header={
            <Flex direction={'row'} justify={'space-between'}>
              <Flex justify={'flex-start'} size={1}>
                <Checkbox
                  onChange={(v) => {
                    handleChange(operation.id, v);
                    if (v) {
                      notifyTop(
                        'success',
                        t(
                          'catalog.maintenance.operations.notification.operation_selected',
                          'Operation has been selected',
                        ),
                        '',
                      );
                      setActiveKey([panelIdPrefix + operation.id]);
                    } else {
                      notifyTop(
                        'success',
                        t(
                          'catalog.maintenance.operations.notification.operation_unselected',
                          'Operation has been removed from your selection',
                        ),
                        '',
                      );
                      setActiveKey([]);
                    }
                  }}
                  checked={isSelected()}
                  label={
                    <WithTooltip title={categoryLabel}>
                      <Text type={'h6_black'}> {buildOperationLabel(operation)}</Text>
                    </WithTooltip>
                  }
                />
              </Flex>
              <Flex justify={'flex-end'} size={0}>
                <Box width={150}>
                  {isMain ? (
                    <BadgeFlag bgColor={'#efdf00'} color={'#000000'}>
                      <Text type={'text_dim_bold'}>
                        {t('catalog.maintenance.new_maintenance_plan.main_operation', 'Main')}
                      </Text>
                    </BadgeFlag>
                  ) : (
                    <BadgeFlag bgColor={'#5822f6'} color={'#ffffff'}>
                      {t('catalog.maintenance.new_maintenance_plan.additional_operation', 'Additional')}
                    </BadgeFlag>
                  )}
                </Box>
                <MarginBox mr={25} />
              </Flex>
              <Flex justify={'flex-end'} size={0}>
                <Box width={150}>{`${getTechnicityTier(operation.technicity)} – ${operation.time}`}</Box>
              </Flex>
              <Flex justify={'flex-end'} size={0} minWidth={150}>
                <Flex direction={'row'} justify={'flex-end'}>
                  <MarginBox mr={5} mt={2}>
                    {familyCount && familyCount > 0 ? (
                      <Text type={'text_dim_bold'}>
                        {Array.from(selectedReferences.values()).filter((r) => r.length > 0).length}/
                      </Text>
                    ) : (
                      <></>
                    )}
                  </MarginBox>
                  <WithTooltip
                    title={t('catalog.maintenance.new_maintenance_plan.product_families', 'Product families')}
                  >
                    <SBadge count={familyCount} checked={false} disabled={false} title={' '} offset={[0, 0]} />
                  </WithTooltip>
                  <MarginBox mr={25} />
                </Flex>
              </Flex>
            </Flex>
          }
          key={panelIdPrefix + operation.id}
        >
          <Flex direction={'column'} key={operation.id}>
            <MarginBox mt={0} mb={0}>
              <MarginBox mt={0}>
                <Flex direction={'row'}>
                  <Families
                    familyIds={operation.familyIds}
                    familiesData={familiesData}
                    selectedReferences={selectedReferences}
                    onSelectReferences={(family: string, references: string[]) => {
                      onSelectReferences(operation.id, family, references, scrollOperationId + operation.id);
                    }}
                  />
                </Flex>
              </MarginBox>
            </MarginBox>
            <MarginBox ml={27} mt={5}>
              {operation.subOperations?.map((subOperation) => (
                <Flex direction={'column'} key={subOperation.id}>
                  <div>operations</div>
                  <Flex direction={'row'}>
                    <MarginBox ml={0} mr={5}>
                      <Icon IconComponent={LevelUp} size={24} color={theme.color.info} />
                    </MarginBox>
                    <Text type={'text_dim_bold'}>{subOperation.label}</Text>
                  </Flex>
                  <Flex direction={'column'}>
                    {subOperation.operations?.map((op) => (
                      <Flex direction={'column'} key={'flex-label-' + op.id}>
                        <div>sub operations</div>
                        <MarginBox ml={50} mt={10} mb={15}>
                          <Text type={'light_14_black_65'} key={'label- ' + op.id}>
                            {op.label} - {op.repairLabel}
                          </Text>
                        </MarginBox>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              ))}
            </MarginBox>
          </Flex>
        </SPanel>
      </CollapseWithKey>
    </MarginBox>
  );
};

export default RenderOperation;
