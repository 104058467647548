import React from 'react';
import { LeadText, Title } from 'components/Pdf/PdfUI';
import { ExternalOrderPdfData, OrderPdfData } from './MyOrdersDetailPdfPage';

export const Header = ({ data }: { data: OrderPdfData | ExternalOrderPdfData }) => (
  <>
    <Title>{data.text.title}</Title>
    <LeadText>{data.text.date}</LeadText>
  </>
);
