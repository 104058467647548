import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IAMVehicleVersion } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleVersionsResponse';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import {
  clearIAMVehicleVersions,
  fetchSearchIAMBMMVehicleModelsSaga,
  fetchSearchIAMBMMVehicleVersionsSaga,
} from 'domains/catalog/Catalog.store';
import { IAM, IAMVehicleBrandLocal, IAMVehicleModelLocal, VehicleBrandLocal } from 'domains/catalog/Catalog.types';
import {
  FormControl,
  SearchByModelAcceptButton,
  SearchByModelOptions,
  SearchByModelState,
  SearchByModelStateValue,
  SelectStep,
} from 'pages/HomePage/search/SearchByModel';
import {
  Box,
  CenteredSpin,
  Flex,
  Image,
  MarginBox,
  Select,
  SelectOption,
  SelectOptionGroup,
  SelectOptionSingle,
  Text,
  URL,
} from 'UI';
import { getData, hasData, isLoading, NO_DATA } from 'utils';

const OtherBrandContainer = styled.div`
  padding: 15px 15px;
  border: 1px solid ${({ theme }) => theme.color.grey85};
  overflow-y: auto;
  height: 262px;
`;

const formMapping: Array<keyof SearchByModelStateValue> = ['brand', 'model', 'version'];

const getNameFromModel = (model: IAMVehicleModelLocal) =>
  `${model.name} - ${model.productionStartDate ? ` ${model.productionStartDate} ${getDateRangeSeparator()} ` : ''}${
    model.productionStartDate && model.productionEndDate ? model.productionEndDate : ''
  }`;

const getDateRangeSeparator = () => '/';

const getModelDates = (model: IAMVehicleModelLocal) =>
  `${model.productionStartDate ? ` ${model.productionStartDate} ${getDateRangeSeparator()} ` : ''}${
    model.productionStartDate && model.productionEndDate ? model.productionEndDate : ''
  }`;

const getNameFromVersion = (v: IAMVehicleVersion, t: TFunction) =>
  `${v.powerHp || v.powerKw ? ' ' : ''}${v.name} (${v.powerKw ? `${v.powerKw}KW` : ''}${
    v.powerHp || v.powerKw ? '/' : ''
  }${v.powerHp ? `${v.powerHp}` + t('common.horse_power', 'HP') : ''})`;

const getEngines = (v: IAMVehicleVersion) => `${v.engineCodes.join(', ')}`;

const getOptions = (
  items: Array<{ name?: string; code: string } | { title: string; options: SelectOptionSingle[] }> | NO_DATA,
) =>
  items && typeof items !== 'string'
    ? items.map((item) => ('code' in item ? { title: item?.name ?? item.code, value: item.code } : item))
    : items;

const getModelOptions = (
  searchByModelOptions: SearchByModelOptions,
  searchByModelState: SearchByModelState,
  t: TFunction,
) => (formIndex: number): SelectOption[] | NO_DATA => {
  if (searchByModelOptions && typeof searchByModelOptions.brands !== 'string') {
    if (formIndex === 0) {
      return getOptions(searchByModelOptions.brands);
    }
    const brand = (searchByModelOptions?.brands?.filter((x) => x.source === 'IAM') as IAMVehicleBrandLocal[]).find(
      (x) => x.code === searchByModelState?.brand,
    );

    const models = Array.isArray(brand?.models)
      ? brand?.models?.map((m) => ({
          code: m.code,
          name: getNameFromModel(m),
        }))
      : brand?.models;

    if (formIndex === 1) {
      return getOptions(models);
    }

    const model = Array.isArray(models) && models?.find(({ code }) => code === searchByModelState?.model);
    // select groups
    const versions = Array.isArray(brand?.groups)
      ? brand?.groups?.map((g) => ({
          title: g.title,
          options: g.versions.map((v) => ({
            title: getNameFromVersion(v, t),
            value: v.code,
          })),
        }))
      : brand?.groups;
    if (formIndex === 2 && model) {
      return getOptions(versions);
    }
  }
  return [];
};

const getModelStateTitle = (
  brand: false | IAMVehicleBrandLocal,
  model: false | IAMVehicleModelLocal | undefined,
  version: false | IAMVehicleVersion | undefined,
) => {
  const brandLabel = !brand ? ' ' : brand.brandType;
  const modelLabel = !model ? ' ' : model.name;
  const versionLabel = !version ? ' ' : version.name;
  return `${brandLabel} ${modelLabel} ${versionLabel}`;
};

const getModelStateIndex = (searchByModelState: SearchByModelState) =>
  formMapping.reduce(
    (acc, _next, index) =>
      acc === 0 && searchByModelState[formMapping[formMapping.length - 1 - index]] ? formMapping.length - index : acc,
    0,
  );

const OtherBrandRightColumn = ({
  brand,
  formControl,
}: {
  formControl: FormControl;
  brand: IAMVehicleBrandLocal | false;
}) => {
  const { t } = useTranslation();
  const brandName = brand && 'name' in brand && brand.name;
  const model =
    brand && 'models' in brand && Array.isArray(brand?.models)
      ? brand?.models?.find((m) => m.code === formControl.formState.model)
      : undefined;
  const groups = model && brand && 'groups' in brand && brand?.groups && Array.isArray(brand.groups) && brand.groups;
  const version =
    Array.isArray(groups) &&
    groups
      ?.map((g) => g.versions)
      .flat()
      .find((g) => g.code === formControl.formState.version);

  const brandInfo = [
    version && getNameFromVersion(version, t),
    model && version && getModelDates(model),
    version && getEngines(version),
  ];

  const brandPicture = brand ? brand.imageKey : undefined;

  return (
    <OtherBrandContainer>
      <>
        <Flex justify={'center'}>
          <Image
            alt={'Brand logo'}
            height={80}
            src={brandPicture}
            type={URL}
            catalogSource={IAM}
            fallbackComponent={<></>}
          />
        </Flex>
        <Flex>
          <Box align={'center'} width={253}>
            <MarginBox mt={15}>
              <Text type={'h5_bold'}>
                {brandName} {model?.name}
              </Text>
            </MarginBox>
            <MarginBox mt={15} />
            <Box>
              {brandInfo.map((info, i) => (
                <Flex key={`info-${i}`}>
                  <Text type={'text'}>{info}</Text>
                </Flex>
              ))}
            </Box>
          </Box>
        </Flex>
      </>
    </OtherBrandContainer>
  );
};

export const SearchByModelIAM = ({
  brands,
  formControl,
  pendingQuery,
  setPendingQuery,
  setFormStateTitle,
  formStateTitle,
}: {
  brands: Array<VehicleBrandLocal | IAMVehicleBrandLocal> | NO_DATA;
  formControl: FormControl;
  pendingQuery: string | undefined;
  setPendingQuery: (x: string | undefined) => void;
  formStateTitle: string;
  setFormStateTitle: (x: string) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formState = formControl.formState;
  const formOptions = { brands: getData(brands) } as SearchByModelOptions;
  const formStateIndex = useMemo(() => getModelStateIndex(formState), [formState]);
  const isFormCompleted = formStateIndex === formMapping.length;
  const requestId = `${formState.brand}_${formState.model}_${formState.version}`;
  // eslint-disable-next-line max-len
  const options = formMapping.map((_propName, index) => getModelOptions(formOptions, formState, t)(index));
  const [hasVersions, setHasVersions] = useState(false);

  const brandsDep = hasData(brands) ? brands.reduce((acc, next) => `${acc}-${next}`, '') : brands;
  const brand = hasData(brands) && (brands?.find((b) => b.code === formState?.brand) as IAMVehicleBrandLocal);
  const model =
    brand && hasData(brand) && hasData(brand?.models) && brand?.models?.find((x) => x.code === formState?.model);
  const groups = model && brand && 'groups' in brand && brand?.groups && Array.isArray(brand.groups) && brand.groups;
  const version =
    Array.isArray(groups) &&
    groups
      ?.map((g) => g.versions)
      .flat()
      .find((g) => g.code === formControl.formState.version);
  const formStateTitleNew = getModelStateTitle(brand, model, version);

  useEffect(() => {
    if (formStateIndex === 1 && hasData(brands) && brand && !model) {
      const brandCode = formState.brand;

      if (brandCode) {
        dispatch(fetchSearchIAMBMMVehicleModelsSaga({ brandCode }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsDep, dispatch, formState, formStateIndex]);

  useEffect(() => {
    if (formStateIndex === 2 && formState.brand && brand && hasData(brand) && hasData(brand?.groups)) {
      dispatch(clearIAMVehicleVersions({ brandCode: formState.brand }));
      setHasVersions(false);
      formControl.setFormState({ ...formState, version: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formState.brand, formState.model, formStateIndex]);

  useEffect(() => {
    if (brand && hasData(brand) && !hasData(brand?.groups) && !hasVersions) {
      const brandCode = formState.brand;
      const modelCode = formState.model;
      if (brandCode && modelCode) {
        dispatch(fetchSearchIAMBMMVehicleVersionsSaga({ modelCode }));
        setHasVersions(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands, dispatch, formState, formStateIndex, brand]);

  useEffect(() => {
    if (formStateTitleNew !== formStateTitle) {
      setFormStateTitle(formStateTitleNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStateTitle, formStateTitleNew]);

  useEffect(() => {
    if (formStateIndex < 2 && hasVersions) {
      setHasVersions(false);
    }
  }, [formStateIndex, hasVersions]);
  return (
    <>
      {options && (hasData(options[0]) || hasData(options[1]) || hasData(options[2])) && (
        <Flex direction={'column'} maxHeight={262} maxWidth={300}>
          <Flex>
            {Array.isArray(options[0]) && options[0]?.length > 0 ? (
              <>
                {isLoading(options[0]) ? (
                  <CenteredSpin />
                ) : (
                  <SelectStep
                    placeholder={t('catalog.parts.search.by_model.brand', 'Brand')}
                    formIndex={0}
                    formControl={formControl}
                    options={options[0] as SelectOptionSingle[]}
                    formMapping={formMapping}
                    formStateIndex={formStateIndex}
                  />
                )}
              </>
            ) : (
              <Select
                onChange={() => undefined}
                placeholder={t('catalog.parts.search.by_model.brand', 'Brand')}
                bordered
                search
                alignLeft
                size={'middle'}
              />
            )}
          </Flex>
          <Box height={15} />
          <Flex>
            <DataContainer
              data={options[1]}
              Loading={() => (
                <MarginBox ml={120}>
                  <CenteredSpin />
                </MarginBox>
              )}
              ErrorState={() => (
                <ErrorWithLabel
                  label={t(
                    'catalog.parts.search.by_model.backend_error',
                    'Model data temporarily unavailable, please try again later.',
                  )}
                  narrow
                />
              )}
            >
              {options[1] ? (
                <SelectStep
                  placeholder={t('catalog.parts.search.by_model.model', 'Model')}
                  formIndex={1}
                  formControl={formControl}
                  options={options[1] as SelectOptionSingle[]}
                  formMapping={formMapping}
                  formStateIndex={formStateIndex}
                />
              ) : (
                <Select
                  onChange={() => undefined}
                  placeholder={t('catalog.parts.search.by_model.model', 'Model')}
                  disabled={1 > formStateIndex}
                  bordered
                  search
                  alignLeft
                  size={'middle'}
                />
              )}
            </DataContainer>
          </Flex>
          <Box height={15} />
          <Flex>
            <DataContainer
              data={options[2]}
              Loading={() => (
                <MarginBox ml={120}>
                  <CenteredSpin />
                </MarginBox>
              )}
              ErrorState={() => (
                <ErrorWithLabel
                  label={t(
                    'catalog.parts.search.by_model.version.backend_error',
                    'Version data temporarily unavailable, please try again later.',
                  )}
                  narrow
                />
              )}
            >
              {options[2] && !(Array.isArray(options[2]) && options[2]?.length < 1) ? (
                <SelectStep
                  placeholder={t('catalog.parts.search.by_model.version', 'Version')}
                  formIndex={2}
                  formControl={formControl}
                  options={options[2] as SelectOptionGroup[]}
                  formMapping={formMapping}
                  formStateIndex={formStateIndex}
                />
              ) : (
                <Select
                  placeholder={t('catalog.parts.search.by_model.version', 'Version')}
                  disabled={2 > formStateIndex}
                  bordered
                  search
                  alignLeft
                  size={'middle'}
                  onChange={() => undefined}
                />
              )}
            </DataContainer>
          </Flex>
          <Box height={30} />
          <SearchByModelAcceptButton
            catalogSource={'IAM'}
            setPendingQuery={setPendingQuery}
            pendingQuery={pendingQuery}
            isFormCompleted={isFormCompleted}
            formControl={formControl}
            requestId={requestId}
          />
          <Box height={55} />
        </Flex>
      )}
      <Box width={30} />
      {hasData(brands) && (
        <Flex direction={'column'}>
          <OtherBrandRightColumn formControl={formControl} brand={brand} />
        </Flex>
      )}
    </>
  );
};
