import React from 'react';
import styled from 'styled-components';

export type MarginType = number | string;

const SMarginBox = styled.div<{
  ml: MarginType;
  mr: MarginType;
  mt: MarginType;
  mb: MarginType;
}>`
  margin-left: ${({ ml }) => (typeof ml === 'string' ? ml : `${ml}px`)};
  margin-right: ${({ mr }) => (typeof mr === 'string' ? mr : `${mr}px`)};
  margin-top: ${({ mt }) => (typeof mt === 'string' ? mt : `${mt}px`)};
  margin-bottom: ${({ mb }) => (typeof mb === 'string' ? mb : `${mb}px`)};
`;

export interface MarginBoxProps {
  className?: string;
  children?: React.ReactNode;
  ml?: MarginType;
  mr?: MarginType;
  mt?: MarginType;
  mb?: MarginType;
  mx?: MarginType;
  my?: MarginType;
}

export const MarginBox = ({ className, children, mb = 0, ml = 0, mr = 0, mt = 0, mx, my }: MarginBoxProps) => {
  ml = mx ? mx : ml;
  mr = mx ? mx : mr;

  mb = my ? my : mb;
  mt = my ? my : mt;

  return (
    <SMarginBox className={className} mb={mb} ml={ml} mr={mr} mt={mt}>
      {children}
    </SMarginBox>
  );
};
