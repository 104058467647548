/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import {
  getCreateInformation,
  getCreateInformationTemplate,
  setCreateInformation,
  setCreateInformationTemplate,
} from 'domains/information/Information.store';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { UserRole } from 'domains/user';
import { CREATE_NEW, CREATE_NEW_TEMPLATE, INFORMATION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Flex, MarginBox, Text, Checkbox } from 'UI';
import { getData } from 'utils';

export const CreateTemplateSection = ({
  userRights,
  isInTemplate,
}: {
  userRights: UserRole[] | 'loading';
  isInTemplate: boolean;
}) => {
  const { t } = useTranslation();
  const isCentralAdmin = (userRights: UserRole[]): boolean => userRights.includes(UserRole.CENTRAL_ADMIN);
  const canBeTemplate = isCentralAdmin(getData(userRights) ?? []);
  const informationTemplate: CreateInformationTemplateModel = useSelector(getCreateInformationTemplate);
  const information: CreateInformationModel = useSelector(getCreateInformation);
  const dispatch = useDispatch();
  const history = useHistory();

  return canBeTemplate ? (
    <Flex direction={'column'} maxWidth={520}>
      <MarginBox mt={40} />
      <Text type={'h2'}>{t('backoffice.information.create_information_template.information', 'Information')}</Text>
      <MarginBox mt={15} />
      <Text type={'section'} disableGutter>
        {t(
          'backoffice.information.create_information_template.description',
          'Publish communication directly on the website, with targeted options. If you would like to store this announce to pre-saved section, make sure to check the box below.',
        )}
      </Text>
      <MarginBox mt={12} />
      <Checkbox
        checked={isInTemplate}
        onChange={(v) => {
          //only for new, for edit should be change disabled
          const checked = v;

          if (checked) {
            history.push(`${ROUTER_BACKOFFICE}/${INFORMATION}/${CREATE_NEW_TEMPLATE}`);
            //when changing checkbox, copy actual content only from textSection, targetCountries, targetAudience, targetPages between stores info and template
            const { textSection, targetCountries, targetAudience, targetPages } = information;
            dispatch(
              setCreateInformationTemplate({
                ...informationTemplate,
                textSection,
                targetCountries,
                targetAudience,
                targetPages,
              }),
            );
          } else {
            history.push(`${ROUTER_BACKOFFICE}/${INFORMATION}/${CREATE_NEW}`);
            const { textSection, targetCountries, targetAudience, targetPages } = informationTemplate;
            dispatch(
              setCreateInformation({ ...information, textSection, targetCountries, targetAudience, targetPages }),
            );
          }
        }}
        label={
          <Text type={'light_14_black_65'}>
            {t('backoffice.promotion.create_template.add_announce_to_templates', 'Add this announce to templates')}
          </Text>
        }
      />
      <MarginBox mt={45} />
    </Flex>
  ) : (
    <></>
  );
};
