import React from 'react';
import { Table } from 'antd';
import { Container, Flex, Link, MarginBox, Text } from 'UI';

export const CookiesFR = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <MarginBox mt={15} />
      <Flex justify={'center'}>
        <Text type={'h5_bold'}>UTILISATION DES COOKIES</Text>
      </Flex>
      <MarginBox mt={30} />
      <Text type={'text'}>
        Cette rubrique est consacrée à notre politique de gestion des cookies. Lors de l&apos;affichage de nos contenus,
        des informations relatives à la navigation de votre terminal (ordinateur, tablette, smartphone, etc.) sont
        susceptibles d&apos;être enregistrées dans des fichiers &quot;Cookies&quot; installés sur votre terminal. La
        présente politique vous permet d’en savoir plus sur l’origine et l&apos;usage de ces informations de navigation
        et de vos droits les concernant, en particulier votre droit d’opposition. Cette politique concerne également les
        informations que nous collectons lorsque vous accédez à ce site par l’intermédiaire de contenus publicitaires
        que nous diffusons sur des sites et applications de tiers.
      </Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>1. QUI SOMMES-NOUS ?</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Bienvenue sur le site{' '}
        <Link to={'https://Rpartstore.fr'} external newTab>
          Rpartstore.fr,
        </Link>{' '}
        édité par RENAULT SAS dont le siège social est situé 13/15 quai le Gallo 92100 Boulogne-Billancourt,
        immatriculée au registre du commerce et des sociétés de Nanterre sous le numéro 780129987.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Pour assurer le respect de vos données personnelles, dont celles collectées par l’intermédiaire de cookies, nous
        disposons d’un Délégué à la Protection des Données, dont les coordonnées sont{' '}
        <Link to={'mailto:dpo@renault.com'} external>
          dpo@renault.com
        </Link>{' '}
        et Renault SAS, Direction juridique – Délégué à la protection des données, 13/15 quai le Gallo 92100
        Boulogne-Billancourt.
      </Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>2. QU’EST-CE QU’UN COOKIE ?</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Les cookies sont des informations déposées dans l’équipement terminal d’un internaute par le serveur du site
        internet visité. Ils sont utilisés par le site internet pour envoyer des informations au navigateur de
        l’internaute et permettre à ce navigateur de renvoyer des informations au site d’origine (par exemple un
        identifiant de session ou le choix d’une langue).
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Seul l’émetteur d’un cookie peut lire ou modifier les informations qui y sont contenues.
      </Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>3. A QUOI SERVENT LES COOKIES UTILISES SUR LE SITE ?</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Le site internet{' '}
        <Link to={'https://Rpartstore.fr'} external newTab>
          Rpartstore.fr,
        </Link>{' '}
        utilise différents types de cookies.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Certains des cookies utilisés ont pour finalité exclusive de permettre ou faciliter la communication par voie
        électronique (détection des erreurs de connexion, identification des points de connexion, sécurité du site
        internet…).
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        D’autres sont strictement nécessaires à la fourniture de services de communication en ligne à votre demande
        expresse (préférences d’affichage à l’écran, mémorisation des informations renseignées dans les formulaires,
        panier d’achat [si c’est le cas], accès à votre compte utilisateur…).
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>D’autres encore sont utilisés dans le but :</Text>
      <MarginBox mt={15} />
      <ul>
        <li>
          <Text type={'text'}>
            d’analyser la fréquentation et l’utilisation qui est faite du site, pour améliorer votre expérience de
            navigation ;
          </Text>
        </li>
        <li>
          <Text type={'text'}>d’améliorer la pertinence des annonces publicitaires diffusées sur le site ;</Text>
        </li>
        <li>
          <Text type={'text'}>
            de rendre le site plus convivial et interactif, en vous permettant d’interagir avec des réseaux sociaux.
          </Text>
        </li>
      </ul>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>4. QUELS SONT LES COOKIES UTILISES SUR LE SITE ?</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Les cookies utilisés sur le site sont :</Text>
      <MarginBox mt={15} />
      <ul>
        <li>
          <Text type={'text'}>les cookies de RENAULT SAS ;</Text>
        </li>
        <li>
          <Text type={'text'}>
            les cookies de tiers limitativement choisis par RENAULT SAS (agence de communication, sociétés de mesure
            d’audience…).
          </Text>
        </li>
      </ul>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>4.1 Cookie de RENAULT SAS</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Les cookies déposés par les outils du site{' '}
        <Link to={'https://Rpartstore.fr'} external newTab>
          Rpartstore.fr
        </Link>{' '}
        sont détaillés dans le tableau ci-dessous :
      </Text>
      <MarginBox mt={30} />
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        columns={[
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (name) => (
              <Link to={'https://Rpartstore.fr'} external newTab>
                {name}
              </Link>
            ),
          },
          { title: 'Utilité', dataIndex: 'utility', key: 'utility' },
          { title: 'Durée de conservation', dataIndex: 'duration', key: 'duration' },
          { title: 'Transfert hors-UE', dataIndex: 'transfer', key: 'transfer' },
        ]}
        dataSource={[
          {
            id: 0,
            name: 'Rpartstore.fr',
            utility: 'Authentification de l’internaute sur le site internet',
            duration: '13 mois',
            transfer: 'Non',
          },
        ]}
      />
      <MarginBox mt={30} />
      <Text type={'text_bold'}>4.2 Cookies de tiers</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Aucune fonctionnalité de ce site ne s’appuie sur des services proposés par des sites tiers.
      </Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>4.2.1 Mesure d’audience</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Le service MATOMO permet de comptabiliser les visiteurs et identifier la manière dont ils utilisent le site. Il
        s’agit d’un outil statistique qui permet à RENAULT SAS d’améliorer son site internet en prenant en compte les
        besoins des visiteurs.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Les données générées par ces cookies concernent : </Text>
      <MarginBox mt={15} />
      <ul>
        <li>
          <Text type={'text'}>votre utilisation du site, tel que les pages que vous avez visitées ;</Text>
        </li>
        <li>
          <Text type={'text'}>
            votre adresse IP afin de déterminer la ville de connexion. Cette donnée est immédiatement anonymisée après
            localisation et n’est pas communiquée à MATOMO
          </Text>
        </li>
      </ul>
      <MarginBox mt={15} />
      <Text type={'text'}>Les cookies utilisés sont détaillés dans le tableau ci-dessous :</Text>
      <MarginBox mt={30} />
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        columns={[
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (name) => (
              <Link to={name} external newTab>
                {name}
              </Link>
            ),
          },
          { title: 'Société', dataIndex: 'society', key: 'society' },
          { title: 'Utilité', dataIndex: 'utility', key: 'utility' },
          { title: 'Durée de conservation', dataIndex: 'duration', key: 'duration' },
          { title: 'Transfert hors-UE', dataIndex: 'transfer', key: 'transfer' },
        ]}
        dataSource={[
          {
            id: 0,
            name: 'https://piwik.ram.aws.renault.com/',
            society: 'Matomo',
            utility: "Suivre la session de visite de l'internaute.",
            duration: '13 mois',
            transfer: 'Non',
          },
        ]}
      />
      <MarginBox mt={15} />
      <Text type={'text'}>Contentsquare</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Google Analytics</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Dynatrace</Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>4.2.2 Adaptation des messages publicitaires</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Lorsque nous vous envoyons des messages promotionnels ou diffusons de tels messages sur le site, notre objectif
        est de vous envoyer les publicités et offres les plus pertinentes possibles, adaptées à vos besoins ou
        réellement susceptibles de vous intéresser. En effet, grâce à vos données personnelles (celles que nous
        collectons de manière automatisée lors de vos visites sur nos sites internet ou de votre utilisation de nos
        applications mobiles (cookies notamment) lesquelles peuvent etre recoupées avec des données de nos fichiers
        clients, soit que vous nous avez fourni directement soit que nous avons obtenues de nos partenaires), nous
        élaborons un profil qui nous permet d’évaluer et de prédire au plus juste vos préférences personnelles et/ou
        intérêts pour nos produits et services. Mieux vous connaître nous permet de vous proposer des produits ou
        services plus adaptés et de mieux vous satisfaire.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Les données générées par ces cookies concernent :</Text>
      <MarginBox mt={15} />
      <ul>
        <li>
          <Text type={'text'}>le fait que vous avez visité notre site internet ;</Text>
        </li>
        <li>
          <Text type={'text'}>les pages que vous avez visualisées.</Text>
        </li>
      </ul>
      <MarginBox mt={15} />
      <Text type={'text'}>[A COMPLETER SI NECESSAIRE]</Text>
      <MarginBox mt={30} />
      <Table
        pagination={false}
        rowKey={(record) => record.id}
        columns={[
          { title: 'Nom', dataIndex: 'name', key: 'name' },
          { title: 'Société', dataIndex: 'society', key: 'society' },
          { title: 'Utilité', dataIndex: 'utility', key: 'utility' },
          { title: 'Durée de conservation', dataIndex: 'duration', key: 'duration' },
          { title: 'Transfert hors-UE', dataIndex: 'transfer', key: 'transfer' },
        ]}
        dataSource={[
          {
            id: 0,
            name: 'A',
            society: 'B',
            utility: 'Proposer à l’internaute les publicités les plus pertinentes',
            duration: '13 mois',
            transfer: 'Non',
          },
        ]}
      />
      <MarginBox mt={30} />
      <Text type={'text_bold'}>5. COMMENT Renault SAS RECUEILLE MON CONSENTEMENT POUR LES COOKIES ?</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Lors de votre première visite sur notre site, il vous est proposé d’accepter ou de refuser l’utilisation de
        certains cookies.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Si vous ne souhaitez pas que des cookies soient installés ou lus sur votre équipement terminal, un cookie de
        refus sera déposé sur votre équipement, afin que Renault SAS enregistre l’information selon laquelle vous vous
        êtes opposé à l’utilisation de cookies. Si vous supprimez ce cookie de refus, il ne sera plus possible de vous
        identifier comme ayant refusé l’utilisation de cookies.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        De même, lorsque vous acceptez le recours aux cookies, un cookie de consentement est installé.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Les cookies de consentement ou de refus doivent rester sur votre équipement terminal. Vous pouvez à tout moment
        modifier vos souhaits comme indiqué à la section suivante intitulée « Gestion des cookies ».
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Nous vous informons que les cookies ayant pour finalité de permettre ou faciliter la communication ou nécessaire
        à la fourniture d’un service que vous avez demandé ne sont pas soumis à votre consentement et vous ne pouvez
        donc pas vous y opposer.
      </Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>6. COMMENT GERER LES COOKIES ?</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Vous disposez de plusieurs options pour gérer les cookies, et notamment les supprimer.</Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>6.1 Module de gestion des cookies</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Un module est mis à votre disposition pour vous permettre de gérer les cookies utilisés par le site{' '}
        <Link to={'https://Rpartstore.fr'} external newTab>
          Rpartstore.fr
        </Link>{' '}
        et pour lesquels votre consentement est nécessaire.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        A tout moment, en cliquant sur ce lien vous pourrez accéder au module et modifier vos préférences, en fonction
        du type de cookies.
      </Text>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>6.2 Paramétrages du navigateur</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Vous avez également la possibilité de gérer le dépôt des cookies par l’intermédiaire de votre navigateur.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Si la plupart des navigateurs sont paramétrés par défaut et acceptent l’installation de cookies, vous avez la
        possibilité, si vous le souhaitez, de choisir d’accepter tous les cookies, de les rejeter systématiquement ou
        encore de choisir ceux que vous acceptez selon leur émetteur. Vous pouvez également régulièrement supprimer les
        cookies de votre terminal via votre navigateur.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        N’oubliez pas cependant de paramétrer l’ensemble des navigateurs de vos différents terminaux (tablettes,
        smartphones, ordinateurs…).
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est
        décrite dans le menu d&apos;aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier
        vos souhaits en matière de cookies. A titre d’exemple :
      </Text>
      <MarginBox mt={15} />
      <ul>
        <li>
          <Text type={'text'}>
            pour Internet Explorer™ :{' '}
            <Link external newTab to={'https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies'}>
              https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
            </Link>{' '}
            ;
          </Text>
        </li>
        <li>
          <Text type={'text'}>
            pour Safari™ :{' '}
            <Link external newTab to={'https://support.apple.com/fr-fr/safari'}>
              https://support.apple.com/fr-fr/safari
            </Link>{' '}
            ;
          </Text>
        </li>
        <li>
          <Text type={'text'}>
            pour Chrome™:{' '}
            <Link external newTab to={'https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647'}>
              https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
            </Link>{' '}
            ;
          </Text>
        </li>
        <li>
          <Text type={'text'}>
            pour Firefox™ :{' '}
            <Link
              external
              newTab
              to={'https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies'}
            >
              https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies
            </Link>{' '}
            ;
          </Text>
        </li>
        <li>
          <Text type={'text'}>
            pour Opera™ :{' '}
            <Link external newTab to={'https://help.opera.com/Windows/10.20/fr/cookies.html'}>
              https://help.opera.com/Windows/10.20/fr/cookies.html.
            </Link>
          </Text>
        </li>
      </ul>
      <MarginBox mt={30} />
      <Text type={'text_bold'}>6.3 Modules d’opposition d’éditeurs</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Vous pouvez choisir de désactiver certains cookies tiers, en vous rendant directement sur la page de l’émetteur.
        Ainsi :
      </Text>
      <MarginBox mt={15} />
      <ul>
        <li>
          <Text type={'text'}>pour désactiver les cookies Matomo, rendez vous sur la page : </Text>
        </li>
        <li>
          <Text type={'text'}>
            pour désactiver les cookies Flash, rendez vous sur la page :{' '}
            <Link external newTab to={'https://www.adobe.com/fr/'}>
              https://www.adobe.com/fr/.
            </Link>
          </Text>
        </li>
      </ul>
      <MarginBox mt={15} />
      <Text type={'text'}>Contentsquare</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Google Analytics</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>Dynatrace</Text>

      <MarginBox mt={30} />
      <Text type={'text_bold'}>6.4 Plateformes d’opposition</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Plusieurs plateformes de professionnels de la publicité vous offrent également la possibilité de refuser ou
        d’accepter des cookies utilisés par les sociétés qui en sont adhérentes. Ces mécanismes centralisés ne bloquent
        pas l’affichage des publicités mais empêchent seulement l’installation de cookies permettant d’adapter les
        publicités à vos centres d’intérêts.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Vous pouvez par exemple vous rendre sur le site{' '}
        <Link external newTab to={'https://www.youronlinechoices.com'}>
          www.youronlinechoices.com
        </Link>{' '}
        afin d’interdire l’installation de ces cookies sur votre terminal. Ce site est proposé par les professionnels de
        la publicité digitale regroupés au sein de l’association européenne EDAA (European Digital Advertising Alliance)
        et géré en France par l’Interactive Advertising Bureau France.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Vous pourrez ainsi connaître les entreprises inscrites à cette plate-forme et qui vous offrent la possibilité de
        refuser ou d&apos;accepter les cookies utilisés par ces entreprises pour adapter à vos informations de
        navigation les publicités susceptibles d&apos;être affichées sur votre terminal :{' '}
        <Link external newTab to={'https://www.youronlinechoices.com/fr/controler-ses-cookies/'}>
          https://www.youronlinechoices.com/fr/controler-ses-cookies/.
        </Link>
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Cette plate-forme européenne est partagée par des centaines de professionnels de la publicité sur Internet et
        constitue une interface centralisée vous permettant d&apos;exprimer votre refus ou votre acceptation des cookies
        susceptibles d&apos;être utilisés afin d&apos;adapter à la navigation de votre terminal les publicités
        susceptibles d&apos;y être affichées.
      </Text>

      <MarginBox mt={30} />
      <Text type={'text_bold'}>
        7. SI VOUS PARTAGEZ L&apos;UTILISATION DE VOTRE TERMINAL AVEC D&apos;AUTRES PERSONNES
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Si votre terminal est utilisé par plusieurs personnes et lorsqu’un même terminal dispose de plusieurs logiciels
        de navigation, nous ne pouvons pas nous assurer de manière certaine que les services et publicités destinés à
        votre terminal correspondent bien à votre propre utilisation de ce terminal et non à celle d&apos;un autre
        utilisateur de ce terminal.
      </Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Le cas échéant, le partage avec d’autres personnes de l’utilisation de votre terminal et la configuration des
        paramètres de votre navigateur à l&apos;égard des cookies, relèvent de votre libre choix et de votre
        responsabilité. Il vous appartient en particulier d’en informer la personne.
      </Text>

      <MarginBox mt={30} />
      <Text type={'text_bold'}>8. VOS DROITS</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Conformément à législation relative à la protection des données à caractère personnel applicable, vous
        bénéficiez d’un droit d’accès, de rectification, d’opposition, de limitation et d’effacement des données à
        caractère personnel qui vous concernent. La manière d’exercer votre droit d’opposition est décrite au point 6.
        Pour exercer vos autres droits, vous pouvez consulter notre information sur la protection de vos données
        personnelles en cliquant sur <u>Politique des Données Personnelles</u>
      </Text>

      <MarginBox mt={30} />
      <Text type={'text_bold'}>9. MODIFICATION</Text>
      <MarginBox mt={15} />
      <Text type={'text'}>
        Nous pouvons être amenés à modifier occasionnellement la présente politique. Lorsque cela est nécessaire ou
        requis, nous vous en informerons et / ou solliciterons votre accord. Nous vous invitons donc à la consulter lors
        de chaque visite afin de prendre connaissance.
      </Text>
      <Flex justify={'flex-end'}>
        <Text type={'text'}>Mise à jour le 30 mai 2023</Text>
      </Flex>
      <MarginBox mt={90} />
    </Container>
  );
};
