import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Vehicle';
import { VehicleTires } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { TFunction } from 'i18next';
import { ROUTER_CATALOG_TIRES, ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import {
  BatteryIcon,
  BrakeIcon,
  CandlesIcon,
  EnigmeIcon,
  FilterIcon,
  OilIcon,
  TireIcon,
  TowbarIcon,
} from 'assets/icons';
import { getExplodedIAMTree, getExplodedTree, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getIAMTires, getVehicleTires } from 'domains/tires/Tire.store';
import { IAMVehicleTires } from 'domains/tires/Tire.types';
import { getUserRights, UserRole } from 'domains/user';
import { IconType } from 'UI';
import { getCondArrayItem, getData, hasData, hasUserAnyRight, NO_DATA } from 'utils';

export type LinkArray = [string, string, string];

interface QuickAccessLinks {
  key: string;
  title: string;
  icon: IconType;
  items?: QuickAccessItem[];
  link?: string;
  linkDh?: LinkArray;
  linkIam?: LinkArray;
}

export interface QuickAccessItem {
  key: string;
  label: string;
  linkDh: LinkArray;
  linkIam: LinkArray;
  link?: string;
}

export const EMPTY_LINK: LinkArray = ['-', '-', '-'];

const getQuickAccessCategories = (
  t: TFunction,
  tires: NO_DATA | VehicleTires,
  iamTires: NO_DATA | IAMVehicleTires,
  vehicle: VehicleDetail,
  userRights: UserRole[] | undefined,
): QuickAccessLinks[] => {
  const isDhCatalog = vehicle.catalogSource === 'DATAHUB';
  const isDhTires = isDhCatalog && hasData(tires);
  const isIamTires =
    vehicle.catalogSource === 'IAM' &&
    hasData(iamTires) &&
    hasUserAnyRight(
      [UserRole.IAM_ACCESS, UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
      userRights,
    );

  const getBatteryLinks = (catalogSource: CatalogSource): LinkArray => {
    switch (catalogSource) {
      case 'IAM':
        return ['100010', '990353', '1'];
      case 'DATAHUB':
        return ['N-21901', 'N-21904', 'N-21913'];
      default:
        return EMPTY_LINK;
    }
  };

  const getTowbarLinks = (catalogSource: CatalogSource): LinkArray => {
    switch (catalogSource) {
      case 'IAM':
        return EMPTY_LINK;
      case 'DATAHUB':
        return ['N-21397', 'N-29250', 'N-29267'];
      default:
        return EMPTY_LINK;
    }
  };

  const getOilFilterSubcategory = (): QuickAccessItem => {
    return {
      key: 'oil_filter',
      label: t('catalog.filter_category.oil_filter', 'Oil filter'),
      linkIam: ['100005', '100259', '7'],
      linkDh: ['N-21947', 'N-21948', 'N-21968'],
    };
  };

  return [
    {
      key: 'filter',
      title: t('catalog.reference.category.filter', 'Filter'),
      icon: FilterIcon,
      items: [
        getOilFilterSubcategory(),
        {
          key: 'air_filter',
          label: t('catalog.filter_category.air_filter', 'Air filter'),
          linkIam: ['100005', '100260', '8'],
          linkDh: ['N-21947', 'N-21952', 'N-21982'],
        },
        {
          key: 'fuel_filter',
          label: t('catalog.filter_category.fuel_filter', 'Fuel filter'),
          linkIam: ['100005', '100261', '9'],
          linkDh: ['N-21947', 'N-21955', 'N-21990'],
        },
        {
          key: 'cabin_filter',
          label: t('catalog.filter_category.cabin_filter', 'Cabin filter'),
          linkIam: ['100005', '100346', '424'],
          linkDh: ['N-21871', 'N-21872', 'N-21875'],
        },
      ],
    },
    {
      key: 'oil',
      title: t('catalog.oil_category.title', 'Oil'),
      icon: OilIcon,
      items: [
        getOilFilterSubcategory(),
        {
          key: 'oil',
          label: t('catalog.oil_category.title', 'Oil'),
          linkIam: ['100019', '100597', '3224'],
          linkDh: ['N-21446', 'N-21450', 'N-21481'],
        },
        {
          key: 'seal_housing',
          label: t('catalog.oil_category.seal_oil_housing', 'Seal, Oil Filter Housing'),
          linkIam: ['100005', '100262', '591'],
          linkDh: EMPTY_LINK,
        },
      ],
    },
    {
      key: 'tires',
      title: t('catalog.tires', 'Tires'),
      icon: TireIcon,
      items: [
        ...getCondArrayItem(
          (isDhTires || isIamTires) && {
            key: 'tires',
            label: t('catalog.tires', 'Tires'),
            linkIam: EMPTY_LINK,
            linkDh: EMPTY_LINK,
            link: `${getCatalogSourceUrl(vehicle.catalogSource)}/${vehicle.vehicleKey}${ROUTER_CATALOG_TIRES}`,
          },
        ),
        {
          key: 'wheel',
          label: t('catalog.wheel', 'Wheel'),
          linkIam: EMPTY_LINK,
          linkDh: ['N-21921', 'N-21922', 'N-21926'],
        },
      ],
    },
    {
      key: 'battery',
      title: t('catalog.battery', 'Battery'),
      icon: BatteryIcon,
      link: `${getCatalogSourceUrl(vehicle.catalogSource)}/${
        vehicle.vehicleKey
      }${ROUTER_CATALOG_VEHICLE}/${getBatteryLinks(vehicle.catalogSource).join('/')}`,
      linkDh: getBatteryLinks('DATAHUB'),
      linkIam: getBatteryLinks('IAM'),
    },
    {
      key: 'ignition',
      title: t('catalog.ignition', 'Ignition'),
      icon: CandlesIcon,
      items: [
        {
          key: 'glow_plug',
          label: t('catalog.ignition_category.glow_plug', 'Glow plug'),
          linkIam: ['100019', '990150', '243'],
          linkDh: ['N-21947', 'N-21956', 'N-21998'],
        },
        {
          key: 'spark_plug',
          label: t('catalog.ignition_category.spark_plug', 'Spark plug'),
          linkIam: ['100019', '990150', '686'],
          linkDh: ['N-21947', 'N-21956', 'N-21996'],
        },
        {
          key: 'ignition_coil_unit',
          label: t('catalog.ignition_category.ignition_coil_unit', 'Ignition Coil Unit'),
          linkIam: ['100019', '990150', '2284'],
          linkDh: EMPTY_LINK,
        },
      ],
    },
    {
      key: 'brakes',
      title: t('catalog.brakes_category.title', 'Brakes'),
      icon: BrakeIcon,
      items: [
        {
          key: 'brakes',
          label: t('catalog.brakes_category.brake_pad_set', 'Brake Pad Set'),
          linkIam: ['100006', '100626', '402'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'brake_disk',
          label: t('catalog.brakes_category.brake_disk', 'Brake disk'),
          linkIam: ['100006', '100626', '82'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'front_brakes',
          label: t('catalog.brakes_category.front_brakes', 'Front brakes'),
          linkIam: EMPTY_LINK,
          linkDh: ['N-21921', 'N-21924', 'N-21941'],
        },
        {
          key: 'rear_brakes',
          label: t('catalog.brakes_category.rear_brakes', 'Rear brakes'),
          linkIam: EMPTY_LINK,
          linkDh: ['N-21921', 'N-21924', 'N-21942'],
        },
      ],
    },
    {
      key: 'timing_accessory_belt',
      title: t('catalog.timing_belt_category.title', 'Timing - Accessory belt'),
      icon: EnigmeIcon,
      items: [
        {
          key: 'distribution_kit_with_pump',
          label: t('catalog.timing_belt_category.distribution_kit_with_pump', 'Distribution Kit With Water Pump'),
          linkIam: ['100019', '994302', '3096'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'accessory_belt_kit_with_pump',
          label: t('catalog.timing_belt_category.accessory_belt_kit_with_pump', 'Accessory Belt Kit With Water Pump'),
          linkIam: ['100019', '994302', '4172'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'accessory_belt_tensioner',
          label: t('catalog.timing_belt_category.accessory_belt_tensioner', 'Accessory Belt Tensioner'),
          linkIam: ['100019', '994302', '541'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'accessory_belt_kit',
          label: t('catalog.timing_belt_category.accessory_belt_kit', 'Accessory Belt Kit'),
          linkIam: ['100019', '994302', '542'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'accessory_belt',
          label: t('catalog.timing_belt_category.accessory_belt', 'Accessory Belt'),
          linkIam: ['100019', '994302', '305'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'timing_kit',
          label: t('catalog.timing_belt_category.timing_kit', 'Timing Kit'),
          linkIam: ['100019', '994302', '307'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'timing_belt',
          label: t('catalog.timing_belt_category.timing_belt', 'Timing Belt'),
          linkIam: ['100019', '994302', '306'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'tensioner_pulley',
          label: t('catalog.timing_belt_category.tensioner_pulley', 'Tensioner pulley, timing belt'),
          linkIam: ['100019', '994302', '308'],
          linkDh: EMPTY_LINK,
        },
        {
          key: 'water_pump',
          label: t('catalog.timing_belt_category.water_pump', 'Water Pump'),
          linkIam: ['100019', '994302', '1260'],
          linkDh: ['N-21947', 'N-21948', 'N-21966'],
        },
        {
          key: 'tensioner_belt',
          label: t('catalog.timing_belt_category.tensioner_belt', 'Tensioner - belt'),
          linkIam: EMPTY_LINK,
          linkDh: ['N-21947', 'N-21958', 'N-22005'],
        },
        {
          key: 'timing_camshaft',
          label: t('catalog.timing_belt_category.timing_camshaft', 'Timing - camshaft - valve'),
          linkIam: EMPTY_LINK,
          linkDh: ['N-21947', 'N-21948', 'N-21963'],
        },
      ],
    },
    {
      key: 'towbar',
      title: t('catalog.towbar', 'Towbar'),
      icon: TowbarIcon,
      link: isDhCatalog
        ? `${getCatalogSourceUrl(vehicle.catalogSource)}/${
            vehicle.vehicleKey
          }${ROUTER_CATALOG_VEHICLE}/${getTowbarLinks(vehicle.catalogSource).join('/')}`
        : undefined,
      linkDh: getTowbarLinks(vehicle.catalogSource),
    },
  ];
};

export const useQuickAccessLinks = (): QuickAccessLinks[] => {
  const { t } = useTranslation();
  const vehicle = useSelector(getLastVehicleDetail);
  const tires = useSelector(getVehicleTires);
  const iamTires = useSelector(getIAMTires);
  const userRights = getData(useSelector(getUserRights));
  const isIAM = vehicle?.catalogSource === 'IAM';
  const iamTree = useSelector((state: RootState) => getExplodedIAMTree(state, vehicle?.vehicleKey));
  const dhTree = useSelector((state: RootState) => getExplodedTree(state, vehicle?.vehicleKey));

  if (!vehicle) {
    return [];
  }

  const getNodes = (categoryItem: QuickAccessItem | QuickAccessLinks): [string, string, string] => {
    return isIAM ? categoryItem?.linkIam ?? EMPTY_LINK : categoryItem?.linkDh ?? EMPTY_LINK;
  };

  // Return only categories present in current category tree
  const isInTree = (categoryItem: QuickAccessItem | QuickAccessLinks) => {
    const [nodeId1, nodeId2, nodeId3] = getNodes(categoryItem);
    const tree = isIAM ? getData(iamTree)?.data : getData(dhTree)?.data;
    const category = tree
      ?.find((t1) => t1.nodeId === nodeId1)
      ?.items.find((t2) => t2.nodeId === nodeId2)
      ?.items.find((t3) => t3.nodeId === nodeId3);
    if (category || categoryItem.link) {
      return categoryItem;
    }
    return undefined;
  };

  return getQuickAccessCategories(t, tires, iamTires, vehicle, userRights)
    .map((c) => {
      return {
        ...c,
        items: c.items?.filter((categoryLink) => isInTree(categoryLink)),
      };
    })
    .filter((c) => (c.items && c.items.length > 0) || isInTree(c));
};
