import React, { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ImageIcon } from 'assets/icons';
import { Body, SFileUpload } from 'components/FileUpload/FileUpload.styled';
import paramsByState, {
  defaultParamsByState,
  ModalStatusType,
  NO_FILE,
  ParamsByState,
} from 'components/FileUpload/paramsByState';
import { Flex, Icon, Link, MarginBox, Text, WithTooltip } from 'UI';
import { getBase64 } from 'utils';

export interface FileUploadProps {
  currentFile?: {
    fileType: 'IMAGE' | 'CSV';
    fileName: string;
    fileBase64: string;
  };
  handleFileBase64: (fileName: string, fileSize: number, fileBase64: string) => void;
  validateFile: (file: File) => boolean;
  handleNoFile: () => void;
  status: ModalStatusType;
  descriptions: string[];
  template?: {
    link: string;
    fileName: string;
  };
  getText1Override?: (status: ModalStatusType) => string | undefined;
  isProcessing?: boolean;
}

export function checkAndSetFile(
  files: FileList | null,
  validateFile: (file: File) => boolean,
  handleNoFile: () => void,
  setFile: (file: File) => void,
) {
  if (files && files.length > 0) {
    if (validateFile(files[0])) {
      setFile(files[0]);
    }
  } else {
    handleNoFile();
  }
}

export function onClick(fileInput: React.RefObject<HTMLInputElement>) {
  fileInput.current?.click();
}

export function uploadFile(
  file: File | null,
  handleFileBase64: (fileName: string, fileSize: number, fileBase64: string) => void,
) {
  if (file != null) {
    getBase64(file).then((data) => handleFileBase64(file.name, file.size, data ?? ''));
  }
  return file?.size;
}

export function onDrop(
  e: DragEvent<HTMLDivElement>,
  validateFile: (file: File) => boolean,
  handleNoFile: () => void,
  setFile: (file: File) => void,
) {
  e.preventDefault();
  e.stopPropagation();
  checkAndSetFile(e.dataTransfer.files, validateFile, handleNoFile, setFile);
  e.dataTransfer.clearData();
}

export function onDragOver(e: DragEvent<HTMLDivElement>) {
  e.preventDefault();
  e.stopPropagation();
}

export function inputOnChange(
  e: ChangeEvent<HTMLInputElement>,
  validateFile: (file: File) => boolean,
  handleNoFile: () => void,
  setFile: (file: File) => void,
) {
  checkAndSetFile(e.target.files, validateFile, handleNoFile, setFile);
  e.target.value = '';
}

export const FileUpload = ({
  currentFile,
  handleFileBase64,
  template,
  validateFile,
  handleNoFile,
  status,
  descriptions,
  getText1Override,
  isProcessing = false,
}: FileUploadProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [state, setState] = useState<ParamsByState>(defaultParamsByState(theme, t, getText1Override));
  const fileInput = useRef<HTMLInputElement>(null);

  const setFileAndUpload = (fileToUpload: File | null) => {
    setFile(fileToUpload);
    if (fileToUpload && validateFile(fileToUpload)) {
      uploadFile(fileToUpload, handleFileBase64);
    }
  };

  const updateState = (newStatus: ModalStatusType, newFile: File | null) => {
    const correctFile = newStatus === NO_FILE ? null : newFile;
    setState(paramsByState(newStatus, correctFile ? correctFile.name : '', theme, t, getText1Override));
  };

  useEffect(() => {
    updateState(status, file);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, file]);

  if (currentFile) {
    const downloadImage = () => {
      const imageLink = document.createElement('a');
      const extension = currentFile.fileName.split('.').pop();
      imageLink.href = `data:image/${extension};base64,` + currentFile.fileBase64;
      imageLink.download = currentFile.fileName;
      imageLink.click();
    };

    return (
      <Body minWidth={400} maxWidth={400} cursor={'inherit'}>
        <MarginBox my={30} mx={15}>
          <Flex minWidth={340} direction={'row'} align={'center'}>
            <Icon IconComponent={ImageIcon} color={theme.color.info} noPointer />
            <MarginBox mr={15} />
            <Text type={'link'}>{t('common.file_upload.file', 'File:')}</Text>
            <MarginBox mr={5} />
            <WithTooltip title={currentFile.fileName}>
              <Text type={'light_14_black_45'} onClick={downloadImage} cursor={'pointer'} ellipsis>
                {currentFile.fileName}
              </Text>
            </WithTooltip>
            {!isProcessing && (
              <Flex justify={'flex-end'}>
                <MarginBox mr={5} />
                <Text type={'link'} onClick={handleNoFile} cursor={'pointer'}>
                  {t('common.file_upload.file.replace', 'Replace file')}
                </Text>
              </Flex>
            )}
          </Flex>
        </MarginBox>
      </Body>
    );
  }

  return (
    <SFileUpload>
      <Flex direction={'column'} maxWidth={700} minHeight={425}>
        <MarginBox>
          <Text type={'h1'}>{t('common.file_upload', 'File upload')}</Text>
        </MarginBox>
        <Body
          onClick={() => onClick(fileInput)}
          onDrop={(e) => onDrop(e, validateFile, handleNoFile, setFileAndUpload)}
          onDragOver={onDragOver}
          height={200}
          cursor={'pointer'}
        >
          <MarginBox mt={50}>
            <Flex direction={'column'} justify={'center'} align={'center'}>
              <>
                <Icon IconComponent={state.icon} size={50} display={'block'} color={state.iconColor} />
                <MarginBox mt={10}>
                  <Text type={'lead'} displayStyle={state.textType1}>
                    {state.text1}
                  </Text>
                </MarginBox>
              </>
              {descriptions.length > 0 &&
                descriptions.map((desc, i) => (
                  <Text
                    type={'light_14_black_85'}
                    displayStyle={state.textType2}
                    key={'fileupload_modal_description_' + i}
                  >
                    {desc}
                  </Text>
                ))}
            </Flex>
          </MarginBox>
        </Body>
        <MarginBox mt={33}>
          <Flex justify={'space-between'} align={'baseline'}>
            {template && (
              <Link to={template.link} external download={template.fileName}>
                <Text type={'text'} displayStyle={'link'} cursor={'pointer'} decoration={'underline'}>
                  {t('common.file_upload.download_template', 'Download template')}
                </Text>
              </Link>
            )}
          </Flex>
        </MarginBox>
      </Flex>
      <input
        type="file"
        id="file"
        ref={fileInput}
        style={{ display: 'none' }}
        onInput={(e: ChangeEvent<HTMLInputElement>) => {
          inputOnChange(e, validateFile, handleNoFile, setFileAndUpload);
        }}
      />
    </SFileUpload>
  );
};
