import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ROUTER_CART, ROUTER_HOME } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { getShowFirstHelp, getStream } from 'domains/firstHelp/FirstHelp.store';

const ShadowHelp = styled.div<{ header: boolean }>`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: ${({ header }) => (header ? '90px' : '100vmax')};
  background-color: black;
  opacity: 60%;
`;

const FirstHelpShadow = ({ header }: { header: boolean }) => {
  const showFirstHelp = useSelector(getShowFirstHelp);
  const stream = useSelector((state: RootState) => getStream(state, showFirstHelp.showStreamId ?? 'close'));

  const getPopinCovers = () => {
    switch (header) {
      case true:
        return [`${ROUTER_CART}_file_import`, `${ROUTER_CART}_add_reference`, `${ROUTER_HOME}_menu`];
      case false:
        return [`${ROUTER_HOME}_menu`];
    }
  };

  if (!stream || !getPopinCovers().includes(stream[showFirstHelp.showIndex])) {
    return <></>;
  }
  return <ShadowHelp header={header} />;
};

export default FirstHelpShadow;
