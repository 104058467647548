import React from 'react';
import { Trans } from 'react-i18next';
import { Box, Flex, MarginBox, PUBLIC, Text } from 'UI';

const Banner = () => {
  return (
    <Box
      width={'100%'}
      height={200}
      backgroundImage={'universalProducts/universal-products-banner.jpg'}
      backgroundImageType={PUBLIC}
    >
      <MarginBox ml={80}>
        <Flex direction={'column'} justify={'center'} minHeight={200} maxWidth={500}>
          <Text type={'h1_banner_dark'} disableGutter>
            <Trans i18nKey={'catalog.universal_products.banner.title'}>Discover our new catalog</Trans>
          </Text>
          <Text type={'text_white'}>
            <Trans i18nKey={'catalog.universal_products.banner.subtitle'}>
              A more complete offer, as well as a new organization of our products.
            </Trans>
          </Text>
        </Flex>
      </MarginBox>
    </Box>
  );
};

export default Banner;
