import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsReload } from '../domains/user';

//This component exists only to initialize isReload in User store
//window.performance cannot be mocked and does not exist in JSDOM which make it impossible to use in tested components
const ReloadInit = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const navEntries = window.performance.getEntriesByType('navigation');
    const isReloaded = navEntries.length > 0 ? (navEntries[0] as PerformanceNavigationTiming).type === 'reload' : false;
    dispatch(setIsReload(isReloaded));
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default ReloadInit;
