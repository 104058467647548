import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { UrlSignatureResponse } from '@1po/1po-bff-fe-spec/generated/url/UrlSignatureResponse';
import { delay, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { LOADING, NOT_FOUND, sagaGuard, select } from 'utils';
import { sendGetSignature } from './UrlSignatures.api';
import * as actions from './UrlSignatures.store';
import { getSignature, setSignature } from './UrlSignatures.store';

export function* fetchUrlSignature(): SagaIterator {
  const currentSignature = yield* select((state: RootState) => getSignature(state));

  if (currentSignature !== LOADING) {
    yield put(setSignature(LOADING));
    yield put(sendGetSignature());
  }
}

export function* fetchUrlSignatureResponse(action: WsResponse<UrlSignatureResponse>): SagaIterator {
  const currentSignature = yield* select((state: RootState) => getSignature(state));

  const signature = action.payload.suffix ?? NOT_FOUND;
  yield put(setSignature(signature));

  // do retry in case of NOT_FOUND
  if (signature === NOT_FOUND && currentSignature !== NOT_FOUND) {
    // Wait for 1 seconds before retrying
    yield delay(1000);
    yield put(actions.fetchUrlSignature());
  }
}

export function* UrlSignaturesSagas(): SagaIterator {
  yield takeEvery(actions.fetchUrlSignature.type, sagaGuard(fetchUrlSignature));
  yield takeEvery(actions.fetchUrlSignatureResponse.type, sagaGuard(fetchUrlSignatureResponse));
}
