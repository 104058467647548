import React from 'react';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { View } from '@react-pdf/renderer';
import { Card, Divider, ItemNameTitle, Spacer } from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { Country } from 'utils/i18n/Country';
import { OrderPdfText } from './MyOrdersDetailPdfPage';
import { RowBreakLine } from './RowBreakLine';

export const BillingAddressCard = ({ text, garageInfo }: { text: OrderPdfText; garageInfo: Garage | undefined }) => {
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;
  const items = [
    {
      title: text.billingAddress,
      data: address?.address1,
    },
    {
      title: text.areaCode,
      data: address?.zipCode,
    },
    {
      title: text.city,
      data: address?.city,
    },
    {
      title: text.country,
      data: address?.countryCode && Country.findByKey(address.countryCode)?.name,
    },
  ];
  return (
    <Card title={<ItemNameTitle>{text.billingAddressSection}</ItemNameTitle>} height={150} width={190} alignTitleStart>
      <View style={[styles.col, { margin: '10px 7px 10px 7px' }]}>
        {items.map((i) => (
          <>
            <RowBreakLine title={i.title} data={i.data} />
            <Spacer />
            <Divider />
            <Spacer />
          </>
        ))}
      </View>
    </Card>
  );
};
