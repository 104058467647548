/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Reference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { ClockFullIcon } from 'assets/icons';
import { getTokenUser, getUserContext } from 'domains/user';
import { theme } from 'styles';
import { Box, Flex, Icon, LinkButton, Text } from 'UI';

/**
 * DetailSection component displays additional and detailed text information about the Product
 */
const DetailSection = ({
  title,
  descriptions,
  saleSpeeches,
  sectionKey,
}: {
  title: string;
  descriptions: string[] | undefined;
  saleSpeeches: string[] | undefined;
  sectionKey: string;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment key={sectionKey}>
      {((descriptions && descriptions.length > 0) || (saleSpeeches && saleSpeeches.length > 0)) && (
        <>
          <Box height={15} key={`details-${sectionKey}-margin`} />
          <Text key={'section-title'} type={'card_title_light'}>
            {title}
          </Text>
          {descriptions &&
            descriptions.map((detail, index) => (
              <React.Fragment key={`description-${index}`}>
                <Box height={15} />
                <Text key={`${index}-title`} type={'h6'}>
                  {t('catalog.product.details.description', 'Description')}
                </Text>
                <Text key={`${index}-value`} type={'heading_black_content'}>
                  {detail}
                </Text>
              </React.Fragment>
            ))}
          {saleSpeeches &&
            saleSpeeches.map((detail, index) => (
              <React.Fragment key={`sale-speech-${index}`}>
                <Box height={15} />
                <Text key={`${index}-title`} type={'h6'}>
                  {t('catalog.product.details.sale_speech', 'Sale speech')}
                </Text>
                <Text key={`${index}-value`} type={'heading_black_content'}>
                  {detail}
                </Text>
              </React.Fragment>
            ))}
        </>
      )}
    </React.Fragment>
  );
};
export const ProductDetails = ({ reference }: { reference: Reference }) => {
  const { t } = useTranslation();
  const userContext = useSelector(getUserContext);
  const user = useSelector(getTokenUser);

  const {
    additionalInformation,
    characteristics: { unitsOfSales, technicalCharacteristics } = {},
    descriptions,
    saleSpeeches,
    includeFDSDocument,
    referenceNumber,
  } = reference;

  const safetyLink = useMemo(() => {
    const safety = {
      partNumber: referenceNumber,
      languageCode: userContext.documentLanguage ?? userContext.documentFallbackLanguage,
      userLanguage: userContext.documentLanguage ?? userContext.documentFallbackLanguage,
      email: user?.email || '',
      IPN: user?.id,
    };
    return (
      includeFDSDocument &&
      safety.IPN &&
      safety.languageCode &&
      safety.partNumber &&
      'https://www.quickfds.com/cmt_bin/wfds-afflist?' +
        new URLSearchParams([
          ['NSoc', 'Renault'],
          ['NCli', safety.IPN],
          ['EMai', safety.email],
          ['View', 'Pdf'],
          ['Lang', safety.languageCode?.toUpperCase()],
          ['NFou', `RENAULT_PI.${safety.userLanguage?.toUpperCase()}`],
          ['CCli', safety.IPN],
          ['NPro', safety.partNumber],
          ['Plus', ''],
          ['SPdf', ''],
          ['AffOne', 'N'],
        ]).toString()
    );
  }, [includeFDSDocument, referenceNumber, user, userContext]);

  return (
    <Flex direction={'column'}>
      {reference.assemblyTime && (
        <Flex direction={'row-reverse'}>
          <Text type={'link_12'}>
            {t('catalog.details.assemblyTime', 'Assembly time: {{assemblyTime}}h', {
              assemblyTime: reference.assemblyTime,
            })}
          </Text>
          <Icon mt={-1} mr={3} IconComponent={ClockFullIcon} size={20} color={theme.color.blue} />
        </Flex>
      )}
      <Text type={'h2'}>{t('common.details', 'Details')}</Text>
      <React.Fragment key="additional-information">
        {additionalInformation && additionalInformation.length > 0 && (
          <>
            <Box height={15} key={'additional-info-margin'} />
            <Text type={'card_title_light'}>
              {t('catalog.details.additional_information', 'Additional information')}
            </Text>
            {additionalInformation &&
              additionalInformation.map(({ title, description }, index) => (
                <React.Fragment key={`additional-info-${index}`}>
                  <Text type={'h6'} key={`additional-info-${index}-title`} transform={'capitalize'}>
                    {title ? title?.toLowerCase() : ''}
                  </Text>
                  <Text
                    type={'heading_black_content'}
                    key={`additional-info-${index}-description`}
                    transform={'capitalize'}
                  >
                    {description?.toLowerCase()}
                  </Text>
                </React.Fragment>
              ))}
          </>
        )}
      </React.Fragment>
      <React.Fragment key="characteristics">
        {((unitsOfSales && unitsOfSales.length > 0) ||
          (technicalCharacteristics && technicalCharacteristics.length > 0)) && (
          <>
            <Box height={15} key={'characteristics-margin'} />
            <Text type={'card_title_light'}>{t('catalog.product.details.characteristics', 'Characteristics')}</Text>
            {unitsOfSales &&
              unitsOfSales.map((value, index) => (
                <React.Fragment key={`value-sales-${index}`}>
                  <Text type={'heading_black_content'}>
                    {t('catalog.product.details.units_of_sales', 'Units of sales: {{value}}', { value })}
                  </Text>
                </React.Fragment>
              ))}
            {technicalCharacteristics &&
              technicalCharacteristics.map((value, index) => (
                <React.Fragment key={`value-technical-${index}`}>
                  <Text key={`value-technical-${index}-description`} type={'heading_black_content'}>
                    {value}
                  </Text>
                </React.Fragment>
              ))}
          </>
        )}
      </React.Fragment>
      <DetailSection
        title={t('catalog.product.details.family', 'Family')}
        descriptions={descriptions?.familyDetails}
        saleSpeeches={saleSpeeches?.familyDetails}
        sectionKey={'family'}
      />
      <DetailSection
        title={t('catalog.product.details.model', 'Model')}
        descriptions={descriptions?.modelDetails}
        saleSpeeches={saleSpeeches?.modelDetails}
        sectionKey={'model'}
      />
      <DetailSection
        title={t('catalog.product.details.part', 'Part')}
        descriptions={descriptions?.partDetails}
        saleSpeeches={saleSpeeches?.partDetails}
        sectionKey={'part'}
      />
      {includeFDSDocument && safetyLink && (
        <>
          <Box height={30} key={'safety-link-margin'} />
          <Flex>
            <LinkButton download={'Safety sheet'} link={safetyLink}>
              {t('catalog.product.details.safety_sheet', 'Safety sheet')}
            </LinkButton>
          </Flex>
        </>
      )}
    </Flex>
  );
};
