import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReturnReasonType } from '@1po/1po-bff-fe-spec/generated/order/request/ReturnEmailRequest';
import { TFunction } from 'i18next';
import { OrderReferenceItemLocal, SubstituteReferenceLocal } from 'domains/order/Order.types';
import { Box, Select, Text } from 'UI';

export const requestReasonOptions = (t: TFunction): { value: ReturnReasonType; title: string }[] => {
  return [
    {
      value: 'PART_ORDERED_BY_ERROR',
      title: t('order.order_detail.return_request.part_ordered_by_error', 'Part ordered by error'),
    },
    {
      value: 'DELIVERY_TIME_TOO_LONG',
      title: t('order.order_detail.return_request.delivery_time_too_long', 'Delivery time too long'),
    },
    {
      value: 'END_CUSTOMER_DID_NOT_PURCHASE',
      title: t(
        'order.order_detail.return_request.end_customer_did_not_purchase',
        'End customer did not purchase the part',
      ),
    },
    {
      value: 'PART_NOT_ORDERED',
      title: t('order.order_detail.return_request.part_not_ordered', 'Part not ordered'),
    },
    {
      value: 'DUPLICATE_PART_RECEIVED',
      title: t('order.order_detail.return_request.duplicate_part_received', 'Duplicate part received'),
    },
    {
      value: 'NOT_COMPATIBLE_PART',
      title: t('order.order_detail.return_request.not_compatible_part', 'Not compatible part'),
    },
  ];
};

const RequestReasonSelect = ({
  reference,
  updateReturn,
}: {
  reference: OrderReferenceItemLocal | SubstituteReferenceLocal;
  updateReturn: (updatedReference: OrderReferenceItemLocal | SubstituteReferenceLocal) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box width={250}>
      <Select
        placeholder={t('order.order_detail.return_request.select_a_reason', 'Select a reason')}
        options={requestReasonOptions(t)}
        onChange={(value) => {
          updateReturn({ ...reference, requestReason: value as ReturnReasonType });
        }}
        bordered
        size={'large'}
        value={reference.requestReason}
        notFoundContent={
          <Text type={'light_14_black_25'}>
            {t('order.order_detail.return_request.select_a_reason_no_request_type', 'Please select request type.')}
          </Text>
        }
      />
    </Box>
  );
};

export default RequestReasonSelect;
