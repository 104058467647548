import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { RootState } from 'app/AppStore';
import { PdfShortcutHeader } from 'components/Pdf/PrintPdfShortcut';
import {
  getLastSearchVehicleTechnicalData,
  getVehicleTechnicalDataCategoryL2List,
} from 'domains/catalog/Catalog.store';
import VehicleTechnicalDataL2 from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalDataL2';
import { Flex, PrintButtonRound, Text } from 'UI';
import { PdfHiddenWrapper, PdfWrapper, usePrintShortcut } from 'utils/hooks/usePrintShortcut';

const VehicleTechnicalDataPrintPdf = ({ categoryId }: { categoryId: string | undefined }) => {
  const { t } = useTranslation();
  const categoryL2List = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL2List(state, categoryId));
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const technicalData = useSelector((state: RootState) => getLastSearchVehicleTechnicalData(state));
  const category = technicalData?.find((d) => d.id === categoryId);

  usePrintShortcut(reactToPrintFn);

  return (
    <>
      <PrintButtonRound onClick={reactToPrintFn} />
      <PdfHiddenWrapper>
        <PdfWrapper direction={'column'} ref={contentRef} gap={15}>
          <PdfShortcutHeader title={t('catalog.parts.category.technical_data', 'Technical data')} />
          <Text type={'h6'}>{category?.label ?? ''}</Text>
          <Flex direction={'column'}>
            {categoryL2List?.map((l2) => {
              return (
                <VehicleTechnicalDataL2
                  key={`vehicle-technical_data-category_l2-${l2.id}`}
                  l3Categories={l2.l3List}
                  categoryLabel={l2.label}
                />
              );
            })}
          </Flex>
        </PdfWrapper>
      </PdfHiddenWrapper>
    </>
  );
};

export default VehicleTechnicalDataPrintPdf;
