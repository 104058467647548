import React, { useState } from 'react';
import { DataContainer } from 'components/DataContainer';
import { ArrowLeftButtonRound, ArrowRightButtonRound, Box, CenteredSpin, CenterFlex, Grid, MarginBox } from 'UI';
import { useBreakpointSelectorFull } from 'utils';
import { Tutorial } from './Tutorial';
import { TutorialData } from '../Tutorial';

export const BrowseList = ({
  data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  expandable,
  Empty,
}: {
  data: TutorialData[];
  expandable?: boolean;
  Empty: () => JSX.Element;
}) => {
  const breakpointSelectorFull = useBreakpointSelectorFull();

  // paging
  const size = 4;
  const [cursor, setCursor] = useState(0);
  const currentData = data.slice(cursor, cursor + Math.min(size, data.length));
  const browse = (offset: number) => setCursor(cursor + offset);
  const next = () => browse(1);
  const prev = () => browse(-1);
  const hasNext = cursor + size < data.length;
  const hasPrev = cursor > 0;

  return (
    <MarginBox mt={45}>
      <CenterFlex>
        <DataContainer data={currentData} Loading={() => <CenteredSpin size={'large'} />}>
          {data.length > 0 ? (
            <CenterFlex>
              <MarginBox mr={30}>
                <Box height={50} width={50}>
                  <ArrowLeftButtonRound onClick={prev} disabled={!hasPrev} />
                </Box>
              </MarginBox>
              <Grid
                columns={breakpointSelectorFull({
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 2,
                  xxl: 4,
                })}
                gutter={[
                  [0, 15],
                  [30, 30],
                ]}
              >
                {currentData.map((item, index) => (
                  <CenterFlex key={`tutorial-${index}`}>
                    <Tutorial tutorial={item} />
                  </CenterFlex>
                ))}
              </Grid>
              <MarginBox ml={30}>
                <Box height={50} width={50}>
                  <ArrowRightButtonRound onClick={next} disabled={!hasNext} />
                </Box>
              </MarginBox>
            </CenterFlex>
          ) : (
            <Empty />
          )}
        </DataContainer>
      </CenterFlex>
    </MarginBox>
  );
};
