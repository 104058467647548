import React from 'react';
import { Trans } from 'react-i18next';
import {
  OrderStatusType,
  ReferenceStatusType,
} from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import styled from 'styled-components';
import { Ban, HourglassStartIcon, TruckIcon } from 'assets/icons';
import { Status } from 'components/Pdf/PdfUI';
import { theme, ThemeColorKeyType } from 'styles';
import { Box, Flex, Icon, IconType, Progress, ProgressStatusType, Text } from 'UI';

export interface OrderReferenceStatusProps {
  referenceStatus: ReferenceStatusType;
  orderStatus: OrderStatusType;
}

export const getReferenceStatusLabel = (status: ReferenceStatusType, orderStatus: OrderStatusType) => {
  switch (orderStatus) {
    case 'MAYBE_PLACED':
      return <Trans i18nKey={'my_orders.details.order_processing_after_timeout'}>Processing</Trans>;
    case 'CREATED':
    case 'CONFIRMED_BY_R1':
    case 'TO_BE_CONFIRMED_BY_R1':
    case 'TO_BE_VALIDATED':
      return <Trans i18nKey={'order.order_status.to_be_validated'}>To be validated</Trans>;
    case 'TECHNICAL_ERROR':
    case 'NOT_PLACED':
    case 'NOT_PLACED_DUE_FUNCTIONAL_REJECTION':
    case 'NOT_VALID':
    case 'NOT_PLACED_DUE_TECHNICAL_REJECTION':
    case 'NOT_PLACED_AFTER_TIMEOUT':
      return <Trans i18nKey={'order.order_status.error'}>Error</Trans>;
  }

  switch (status) {
    case 'TO_BE_VALIDATED':
      return <Trans i18nKey={'order.order_status.to_be_validated'}>To be validated</Trans>;
    case 'DELIVERED':
      return <Trans i18nKey={'order.order_status.delivered'}>Delivered</Trans>;
    case 'PARTIALLY_DELIVERED':
      return <Trans i18nKey={'order.order_status.partially_delivered'}>Partially delivered</Trans>;
    case 'CANCELLED':
      return <Trans i18nKey={'order.order_status.cancelled'}>Cancelled</Trans>;
    case 'IN_PROGRESS':
    default:
      return <Trans i18nKey={'order.order_status.in_progress'}>In progress</Trans>;
  }
};

const getOrderReferenceColor = (status: ReferenceStatusType, orderStatus: OrderStatusType): ThemeColorKeyType => {
  switch (orderStatus) {
    case 'MAYBE_PLACED':
      return 'brand_black';
    case 'CREATED':
    case 'CONFIRMED_BY_R1':
    case 'TO_BE_CONFIRMED_BY_R1':
    case 'TO_BE_VALIDATED':
      return 'grey60';
    case 'TECHNICAL_ERROR':
    case 'NOT_PLACED':
    case 'NOT_PLACED_DUE_FUNCTIONAL_REJECTION':
    case 'NOT_PLACED_AFTER_TIMEOUT':
    case 'NOT_PLACED_DUE_TECHNICAL_REJECTION':
    case 'NOT_VALID':
      return 'error';
  }

  switch (status) {
    case 'IN_PROGRESS':
      return 'info';
    case 'DELIVERED':
      return 'selected';
    case 'PARTIALLY_DELIVERED':
      return 'warning';
    case 'CANCELLED':
      return 'error';
    case 'TO_BE_VALIDATED':
      return 'grey60';
    default:
      return 'brand_black';
  }
};

const getStatusProgress = (status: ReferenceStatusType, orderStatus: OrderStatusType): number => {
  switch (orderStatus) {
    case 'MAYBE_PLACED':
    case 'CREATED':
    case 'CONFIRMED_BY_R1':
    case 'TO_BE_CONFIRMED_BY_R1':
    case 'TO_BE_VALIDATED':
      return 25;
    case 'TECHNICAL_ERROR':
    case 'NOT_PLACED':
    case 'NOT_PLACED_DUE_FUNCTIONAL_REJECTION':
    case 'NOT_PLACED_AFTER_TIMEOUT':
    case 'NOT_PLACED_DUE_TECHNICAL_REJECTION':
    case 'NOT_VALID':
      return 100;
  }

  switch (status) {
    case 'IN_PROGRESS':
      return 50;
    case 'PARTIALLY_DELIVERED':
    case 'DELIVERED':
    case 'CANCELLED':
      return 100;
    case 'TO_BE_VALIDATED':
    default:
      return 25;
  }
};

const getOrderReferenceIcon = (status: ReferenceStatusType, orderStatus: OrderStatusType): IconType => {
  switch (orderStatus) {
    case 'MAYBE_PLACED':
    case 'CREATED':
    case 'CONFIRMED_BY_R1':
    case 'TO_BE_CONFIRMED_BY_R1':
    case 'TO_BE_VALIDATED':
      return HourglassStartIcon;
    case 'TECHNICAL_ERROR':
    case 'NOT_PLACED':
    case 'NOT_PLACED_DUE_FUNCTIONAL_REJECTION':
    case 'NOT_PLACED_AFTER_TIMEOUT':
    case 'NOT_PLACED_DUE_TECHNICAL_REJECTION':
    case 'NOT_VALID':
      return Ban;
  }

  switch (status) {
    case 'CANCELLED':
      return Ban;
    case 'TO_BE_VALIDATED':
      return HourglassStartIcon;
    case 'DELIVERED':
    case 'PARTIALLY_DELIVERED':
    case 'IN_PROGRESS':
    default:
      return TruckIcon;
  }
};

const getReferenceProgressStatusType = (
  status: ReferenceStatusType,
  orderStatus: OrderStatusType,
): ProgressStatusType => {
  switch (orderStatus) {
    case 'TECHNICAL_ERROR':
    case 'NOT_PLACED':
    case 'NOT_PLACED_DUE_FUNCTIONAL_REJECTION':
    case 'NOT_PLACED_AFTER_TIMEOUT':
    case 'NOT_PLACED_DUE_TECHNICAL_REJECTION':
    case 'NOT_VALID':
      return 'error';
  }

  switch (status) {
    case 'PARTIALLY_DELIVERED':
    case 'DELIVERED':
      return 'success';
    case 'CANCELLED':
      return 'error';
    case 'TO_BE_VALIDATED':
    case 'IN_PROGRESS':
    default:
      return 'info';
  }
};

export const OrderReferenceStatusPdf = ({ referenceStatus, orderStatus }: OrderReferenceStatusProps) => (
  <Status color={theme.color['brand_black']}>{getReferenceStatusLabel(referenceStatus, orderStatus)}</Status>
);

const ReferenceStatusText = styled(Text)<{ $color: ThemeColorKeyType }>`
  color: ${({ $color }) => theme.color[$color]};
`;

export const OrderReferenceStatus = ({ referenceStatus, orderStatus }: OrderReferenceStatusProps) => {
  const color = getOrderReferenceColor(referenceStatus, orderStatus);
  return (
    <Flex direction={'column'} maxWidth={300} align={'flex-end'}>
      <Box width={140}>
        <Progress
          percent={getStatusProgress(referenceStatus, orderStatus)}
          color={color}
          status={getReferenceProgressStatusType(referenceStatus, orderStatus)}
          showIcon
        />
      </Box>
      <Flex direction={'row'}>
        <Icon
          IconComponent={getOrderReferenceIcon(referenceStatus, orderStatus)}
          size={20}
          color={theme.color[color]}
          display={'block'}
          noPointer
        />
        <Box width={18} />
        <ReferenceStatusText type={'light_14'} $color={color}>
          {getReferenceStatusLabel(referenceStatus, orderStatus)}
        </ReferenceStatusText>
      </Flex>
    </Flex>
  );
};
