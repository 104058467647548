import { SSOLinkResponse } from '@1po/1po-bff-fe-spec/generated/sso/SSOLinkResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const SSO_NAMESPACE = `${APP_NAMESPACE}/SSO`;

export const GET_SSO_LINK = `${SSO_NAMESPACE}/GET_SSO_LINK_REQUEST`;

export const CLARION = 'CLARION';
export const IDIR = 'IDIR';

export type SSOLinkType = typeof CLARION | typeof IDIR;

export interface SSOLinkState {
  ssoLink: SearchData<SSOLinkResponse>;
}
