import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import {
  findStatusTypeByPromotionID,
  removeUploadedFileInfo,
  setUploadedReferencesDiscount,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionModel, UploadedReferencesFromFile } from 'domains/promotion/Promotion.types';
import { Flex, InputNumber, MarginBox, Text } from 'UI';
import { hasData } from 'utils';
import { unblockRemovedReferenceInFirstFoundFile } from './PromotionErrorNotifications';
import { RemovePromotedProductsButton } from './RemovePromotedProductsButton';

export function ApplyDiscountReferences({
  uploadedReferences,
  promotion,
  setPromotion,
  index,
}: {
  uploadedReferences: UploadedReferencesFromFile;
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  index: number;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const promotionStatus = useSelector((state: RootState) =>
    findStatusTypeByPromotionID(state, hasData(promotion) ? promotion.promotionId : undefined),
  );

  const isEditing = promotion.editing;
  const isSavedOrPlanned = promotionStatus === 'SAVED' || promotionStatus === 'PLANNED';
  const isPublished = promotionStatus === 'PUBLISHED';
  const isReferenceEditing = uploadedReferences.editing;

  const shouldRenderRemoveBtn =
    !isEditing || (isSavedOrPlanned && isEditing) || (isPublished && isEditing && !isReferenceEditing);

  const handleRemoveReferences = () => {
    if (!promotion.uploadedReferencesFromFiles || index === undefined) {
      return;
    }

    const updatedReferences = [...promotion.uploadedReferencesFromFiles];
    const fileId = updatedReferences[index].fileId;

    const removedReferences = updatedReferences[index]?.rows ?? [];
    removedReferences.forEach((r) => unblockRemovedReferenceInFirstFoundFile(r, index, updatedReferences));

    if (index === 0 && updatedReferences.length === 1) {
      updatedReferences[index] = {
        fileId: uuidV4(),
        rows: [],
        status: NO_FILE,
        discount: 0,
      };
    } else {
      updatedReferences.splice(index, 1);
    }

    setPromotion({
      ...promotion,
      uploadedReferencesFromFiles: updatedReferences,
    });

    if (!fileId) {
      return;
    }

    dispatch(removeUploadedFileInfo({ fileId }));
  };

  return (
    <>
      <Flex direction={'column'} minWidth={210} maxWidth={210}>
        <MarginBox mt={-80} />
        <Text type={'h5_bold'}>{t('backoffice.promotion.apply_discount', 'Apply discount')}</Text>
        <MarginBox mb={20} />
        <React.Fragment key={`reference-file-discount-item-${uploadedReferences.fileId}`}>
          <Flex direction={'row'} align={'flex-start'}>
            <Flex direction={'row'} align={'center'}>
              <Flex minWidth={115} maxWidth={115}>
                <InputNumber
                  disabled={uploadedReferences.editing}
                  key={`reference-file-discount-${uploadedReferences.fileId}`}
                  value={uploadedReferences.discount !== 0 ? uploadedReferences.discount : undefined}
                  bordered
                  placeholder={'-'}
                  addonAfter={'%'}
                  min={0}
                  max={100}
                  onChangeDebounced={(discount) => {
                    dispatch(
                      setUploadedReferencesDiscount({
                        fileId: uploadedReferences.fileId ?? '',
                        discount: Number(discount) || 0,
                      }),
                    );
                  }}
                />
              </Flex>
              {shouldRenderRemoveBtn ? (
                <RemovePromotedProductsButton index={index} onDeleteClicked={handleRemoveReferences} />
              ) : (
                <MarginBox ml={60} />
              )}
            </Flex>
          </Flex>
        </React.Fragment>
      </Flex>
    </>
  );
}
