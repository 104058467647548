import { GET_AUTH_CONFIG } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import * as actions from 'domains/auth/Auth.store';
import { setCredentials, setCredentialsSearchStatus } from 'domains/auth/Auth.store';
import { setErrorNoConnectionToBESide } from 'domains/webSockets/WebSocket.store';
import { ERROR, LOADING, sagaGuard } from 'utils';
import { sendRequest } from 'utils/restClient';

export function* getAuthConfigResponseSaga({
  payload,
}: ReturnType<typeof actions.getAuthConfigResponseSaga>): SagaIterator {
  yield put(setCredentials(payload));
  yield put(setErrorNoConnectionToBESide({ connectionUnavailable: false }));
}

export function* getAuthConfigRequestSaga(): SagaIterator {
  yield put(setCredentialsSearchStatus(LOADING));
  try {
    const response = yield call(sendRequest, { endpoint: GET_AUTH_CONFIG });
    yield put(actions.getAuthConfigResponseSaga(response));
  } catch (error) {
    console.error('Failed to fetch credentials from backend', error);
    yield put(setCredentialsSearchStatus(ERROR));
    yield put(setErrorNoConnectionToBESide({ connectionUnavailable: true }));
  }
}

export function* AuthSagas(): SagaIterator {
  yield takeEvery(actions.getAuthConfigRequestSaga.type, sagaGuard(getAuthConfigRequestSaga));
  yield takeEvery(actions.getAuthConfigResponseSaga.type, sagaGuard(getAuthConfigResponseSaga));
}
