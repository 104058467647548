/* eslint-disable max-len */
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon } from 'assets/icons';
import { isQueryReferenceNumber } from 'components/Page/Header/TopHeader/Search/Search';
import {
  fetchSavePromotionRequestSaga,
  findStatusTypeByPromotionID,
  getCreatePromotion,
  getPublishedStatus,
  setCreatePromotion,
  setInitialCreatePromotion,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionModel, PromotionTypeLocal } from 'domains/promotion/Promotion.types';
import {
  SaveButtonWrapper,
  SButton,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/CreatePromotion.styled';
import { DescriptionStep } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/DescriptionStep';
import { DetailsStep } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/DetailsStep/DetailsStep';
import { PriceOptionsStep } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PriceOptionStep/PriceOptionsStep';
import {
  containsAlreadyExistingCommercialFamilyCodes,
  containsAlreadyExistingReferences,
  containsAlreadyLocallyPromotedFamilyCodes,
  containsAlreadyPromotedProduct,
} from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PriceOptionStep/PromotionErrorNotifications';
import { PublicationStep } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/PublicationStep/PublicationStep';
import { TargetStep } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/TargetStep';
import { Icon, StepData, Steps } from 'UI';
import { hasData } from 'utils';

const totalSteps = 4;

const getStepsData = (promotionType: PromotionTypeLocal): StepData[] => {
  return [
    { title: <Trans i18nKey={'common.details'}>Details</Trans> },
    { title: <Trans i18nKey={'common.description'}>Description</Trans> },
    {
      title:
        promotionType === 'BANNER' ? (
          <Trans i18nKey={'backoffice.promotion.products'}>Products</Trans>
        ) : (
          <Trans i18nKey={'backoffice.promotion.price_options'}>Price options</Trans>
        ),
    },
    { title: <Trans i18nKey={'backoffice.promotion.target'}>Target</Trans> },
    {
      title: <Trans i18nKey={'backoffice.promotion.publication'}>Publication</Trans>,
      icon: <Icon IconComponent={CheckCircleIcon} display={'flex'} />,
    },
  ];
};

export const isFilled = (s?: string): boolean => (s?.length ?? 0) > 0;

const isSaveButtonDisabled = (promotion: CreatePromotionModel) => {
  if (!promotion) {
    return false;
  }
  if (promotion.editing && promotion.promotionType !== 'PROMOTION_FLASH_QUANTITY_LIMITED') {
    return !promotion.publicationEnd;
  }
  const containsPromotedCFCodes =
    promotion.promotionType === 'BANNER'
      ? false
      : containsAlreadyExistingCommercialFamilyCodes(promotion.commercialFamilyCodes);
  const containsPromotedReferences = containsAlreadyExistingReferences(promotion.uploadedReferencesFromFiles);
  const containsPromotedProduct = containsAlreadyPromotedProduct(promotion.references);
  if (containsPromotedCFCodes || containsPromotedReferences || containsPromotedProduct) {
    return true;
  }

  const isNoImageSelected =
    promotion.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED' ? false : !promotion.containsCustomImage;
  const isCustomImageFilled = promotion.containsCustomImage && Boolean(promotion.image);
  const isQuantityFilled =
    promotion.quantityLimitFlashPromotion !== undefined && promotion.quantityLimitFlashPromotion > 0;
  const anyFieldChanged = promotion.isUpdated === false;

  return (
    !(
      isFilled(promotion.publicationStart) ||
      isCustomImageFilled ||
      isNoImageSelected ||
      isQuantityFilled ||
      promotion.duplicatePromotionIds.length > 0
    ) || anyFieldChanged
  );
};

const CreatePromotion = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const promotion = useSelector(getCreatePromotion);
  const [currentStep, setCurrentStep] = useState(0);
  const publishedStatus = useSelector((state: RootState) => getPublishedStatus(state, promotion.promotionId));
  const promotionStatus = useSelector((state: RootState) =>
    findStatusTypeByPromotionID(state, hasData(promotion) ? promotion.promotionId : undefined),
  );
  const isSaveButtonDisplayed = (promotionStatus === 'SAVED' || !promotionStatus) && currentStep !== 4;
  const setPromotion = (promotionToCreate: CreatePromotionModel) => {
    if (promotion.isUpdated === false) {
      dispatch(setCreatePromotion({ ...promotionToCreate, isUpdated: true }));
    } else {
      dispatch(setCreatePromotion(promotionToCreate));
    }
  };

  const onSave = () => {
    if (promotion.promotionType !== 'PROMOTION_FLASH') {
      dispatch(setCreatePromotion({ ...promotion, isSaved: true }));
      dispatch(fetchSavePromotionRequestSaga({ shouldPublish: false }));
    }
  };

  const validateStep = () => {
    const {
      title,
      catchLine,
      promotionType,
      containsCustomImage,
      publicationStart,
      publicationEnd,
      quantityLimitFlashPromotion,
      image,
      targetAudience,
      discountType = '',
      commercialFamilyCodes = [],
      uploadedReferencesFromFiles = [],
      references = [],
      targetAudienceType,
      additionalInformation,
      duplicatePromotionIds,
    } = promotion;

    switch (currentStep) {
      //Details step
      case 0: {
        const startDate = moment(publicationStart);
        const endDate = moment(publicationEnd);

        const isCustomImageFilled = containsCustomImage && Boolean(image);
        const isTemplateFilled = !containsCustomImage || isCustomImageFilled;
        const isValidStartEndDate = isFilled(publicationEnd) ? endDate >= startDate : true;
        const isValidDateRange = endDate.diff(startDate, 'd') <= 10;
        const isDateFilled = isFilled(publicationStart) && isFilled(publicationEnd) && endDate >= startDate;

        switch (promotionType) {
          case 'DISCOUNT':
            return isFilled(promotionType) && isDateFilled && isTemplateFilled;
          case 'PROMOTION_FLASH_TIME_LIMITED':
            return isFilled(promotionType) && isDateFilled && isValidDateRange && isTemplateFilled;
          case 'PROMOTION_FLASH_QUANTITY_LIMITED':
            return (
              isFilled(promotionType) &&
              isFilled(publicationStart) &&
              isValidStartEndDate &&
              quantityLimitFlashPromotion !== undefined &&
              quantityLimitFlashPromotion > 0
            );
          case 'BANNER':
            return isFilled(promotionType) && isDateFilled && isCustomImageFilled;
          default:
            return false;
        }
      }
      //Description step
      case 1:
        switch (promotionType) {
          case 'DISCOUNT':
          case 'PROMOTION_FLASH_TIME_LIMITED':
          case 'BANNER':
            return isFilled(title) && isFilled(catchLine);
          case 'PROMOTION_FLASH_QUANTITY_LIMITED':
            return isFilled(title);
          default:
            return false;
        }
      //Price options step
      case 2: {
        const isReferenceFilled = references.length > 0 && references[0].items.length > 0;
        const isReferenceDiscountFilled = references.length > 0 && references[0].discount > 0;
        const isCFCodesDiscountFilled = commercialFamilyCodes.length > 0 && commercialFamilyCodes[0].discount > 0;

        const isProductSectionFilled =
          uploadedReferencesFromFiles.some((d) => d.rows && d.rows.length > 0) ||
          commercialFamilyCodes.some((section) => section.items.some((code) => code.item.length > 0));

        const areReferencesInValid = uploadedReferencesFromFiles.some((line) => {
          const isFileFilled = line.rows ? line.rows.length > 0 : false;
          const isDiscountFilled = line.discount > 0;
          return isFileFilled !== isDiscountFilled;
        });
        const areCFCodesInValid = commercialFamilyCodes.some((line) => {
          const isCFCodeFilled = line.items.length > 0;
          const isDiscountFilled = line.discount > 0;
          return isCFCodeFilled !== isDiscountFilled;
        });

        const duplicatePromotionsCheck = duplicatePromotionIds.length === 0;

        const containsLocallyPromotedCFCodes = containsAlreadyLocallyPromotedFamilyCodes(commercialFamilyCodes);
        const areProductsValid = !containsLocallyPromotedCFCodes && duplicatePromotionsCheck;

        const isCorrectReferenceNumber = isQueryReferenceNumber(
          references && references.length > 0 && references[0].items.length > 0 ? references[0].items[0].item : '',
        );
        const isAdditionalFieldFilled = !!(
          additionalInformation.title ||
          additionalInformation.catchLine ||
          additionalInformation.description ||
          additionalInformation.image
        );
        const isAdditionalDiscountFilled = isAdditionalFieldFilled ? !!additionalInformation.discount : true;

        const collectDiscounts = [
          ...commercialFamilyCodes.map((c) => c.discount),
          ...uploadedReferencesFromFiles.map((f) => f.discount),
        ];
        const isOver100 = Math.max(...collectDiscounts) + (additionalInformation.discount ?? 0) > 100;

        switch (promotionType) {
          case 'DISCOUNT':
            return (
              isFilled(discountType) &&
              !(areCFCodesInValid || areReferencesInValid) &&
              isProductSectionFilled &&
              areProductsValid &&
              isAdditionalDiscountFilled &&
              !isOver100
            );
          case 'PROMOTION_FLASH_TIME_LIMITED':
            return (
              isFilled(discountType) &&
              isProductSectionFilled &&
              (isReferenceDiscountFilled || isCFCodesDiscountFilled) &&
              areProductsValid
            );
          case 'PROMOTION_FLASH_QUANTITY_LIMITED':
            return (
              isFilled(discountType) &&
              isReferenceFilled &&
              isReferenceDiscountFilled &&
              isCorrectReferenceNumber &&
              areProductsValid
            );
          case 'BANNER':
            return true;
          default:
            return false;
        }
      }
      //Target step
      case 3: {
        const { dealerTypes = [], dealers = [] } = targetAudience;
        if (targetAudienceType === 'NETWORK') {
          return dealerTypes.length > 0;
        } else {
          return dealers.length > 0;
        }
      }
      default:
        return false;
    }
  };

  return (
    <>
      <SaveButtonWrapper>
        <Steps
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          data={getStepsData(promotion.promotionType)}
          clickable={!publishedStatus}
        />
        {isSaveButtonDisplayed && (
          <SButton onClick={onSave} disabled={isSaveButtonDisabled(promotion)}>
            {t('common.action.save', 'Save')}
          </SButton>
        )}
      </SaveButtonWrapper>
      {currentStep === 0 && (
        <DetailsStep
          promotion={promotion}
          setPromotion={setPromotion}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={totalSteps}
          validateStep={validateStep}
          promotionStatus={promotionStatus}
        />
      )}
      {currentStep === 1 && (
        <DescriptionStep
          promotion={promotion}
          setPromotion={setPromotion}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={totalSteps}
          validateStep={validateStep}
        />
      )}
      {currentStep === 2 && (
        <PriceOptionsStep
          promotion={promotion}
          setPromotion={setPromotion}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={totalSteps}
          validateStep={validateStep}
        />
      )}
      {currentStep === 3 && (
        <TargetStep
          promotion={promotion}
          setPromotion={setPromotion}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={totalSteps}
          validateStep={validateStep}
        />
      )}
      {currentStep === 4 && (
        <PublicationStep
          promotion={promotion}
          setPromotion={setPromotion}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={totalSteps}
          validateStep={validateStep}
          createNext={() => {
            dispatch(setInitialCreatePromotion());
            setCurrentStep(0);
          }}
        />
      )}
    </>
  );
};

export default CreatePromotion;
