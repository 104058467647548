import { useSelector } from 'react-redux';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { RootState } from 'app/AppStore';
import { calculatePriceAfterDiscount } from 'components/Discount';
import { TotalSectionValues } from 'components/Pdf/PdfTotalSection';
import {
  computeTotalPrice,
  getBasketExternalSection,
  getBasketOtherSection,
  getBasketVehicles,
} from 'domains/basket/Basket.store';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { getAllDeliveryLeadTime } from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { getDiscounts, getLoadedPrices, getStocksMap, ReferencePriceType } from 'domains/references';
import { getCurrency, getDealerType, getUserContext } from 'domains/user';
import {
  CartListPdfData,
  CartPdfReference,
  Discount,
  ExternalBasketSectionPdfData,
  OtherSectionPdfData,
  VehicleSectionPdfData,
} from 'pages/CartPage/CartPdf/CartPdf.types';
import { calculateTotalPrice, getReferenceNumbers } from 'pages/CartPage/CartStep/CartStep';
import { hasData, NO_DATA, SearchData } from 'utils';

const getDiscount = (prices: ReferencePriceType | undefined, discount: ReferenceDiscount | undefined): Discount => {
  return {
    groupDiscount: Number(prices?.garageView?.discountRateUrgent ?? 0),
    groupDiscountUrgent: Number(prices?.garageView?.discountRate ?? 0),
    promotionDiscount: discount?.discount,
    discountType: discount?.discountType,
  };
};

const remapReferencesWithDiscountedPrices = (
  references: ReferenceLocal[],
  discounts: Map<string, ReferenceDiscount>,
  loadedPrices: {
    prices: NO_DATA | ReferencePriceType;
    reference: string;
  }[],
): CartPdfReference[] => {
  return references.map((ref) => {
    const promotionDiscount = discounts.get(ref.referenceNumber);
    const price = loadedPrices.find((p) => p.reference === ref.referenceNumber);
    if (!hasData(price?.prices)) {
      return ref;
    }
    const refWithDiscounts: CartPdfReference = {
      ...ref,
      discount: getDiscount(price?.prices, promotionDiscount),
    };

    const discountedPriceVatExcluded = calculatePriceAfterDiscount(promotionDiscount, price?.prices, false);
    const discountedPriceVatIncluded = calculatePriceAfterDiscount(promotionDiscount, price?.prices, true);

    return {
      ...refWithDiscounts,
      price: {
        ...refWithDiscounts.price,
        priceVatExcluded: String(discountedPriceVatExcluded),
        priceVatIncluded: String(discountedPriceVatIncluded),
        priceTotalVatExcluded: String(Number(price?.prices?.clientView?.recommendedPriceVatExcluded) * ref.quantity),
        discountedPriceTotalVatExcluded: String(discountedPriceVatExcluded * ref.quantity),
        discountedPriceTotalVatExcludedUrgent: String(discountedPriceVatIncluded * ref.quantity),
      },
    };
  });
};

export function useCartPdfData(): CartListPdfData | undefined {
  const vehiclesSection = useSelector(getBasketVehicles);
  const otherSection = useSelector(getBasketOtherSection);
  const externalSection = useSelector(getBasketExternalSection);
  const currency = useSelector(getCurrency);
  const dealerType = useSelector(getDealerType);
  const userContext = useSelector(getUserContext);

  const allReferences = getReferenceNumbers(vehiclesSection, otherSection.references, externalSection);

  const discounts = useSelector((state: RootState) => getDiscounts(state, allReferences));
  const loadedPrices = useSelector((state: RootState) => getLoadedPrices(state, allReferences));
  const allStocks = useSelector((state: RootState) => getStocksMap(state, allReferences));
  const allDeliveryLeadTime = useSelector((state: RootState) => getAllDeliveryLeadTime(state));

  const discountedVehiclesReferences: VehicleLocal[] = vehiclesSection.map((r) => {
    const discountedReferences = remapReferencesWithDiscountedPrices(r.references, discounts, loadedPrices);
    return { ...r, references: discountedReferences };
  });
  const discountedExternalBasketReferences: ExternalBasketSectionLocal[] = externalSection.map((r) => {
    const discountedReferences = remapReferencesWithDiscountedPrices(r.references, discounts, loadedPrices);
    return { ...r, references: discountedReferences };
  });
  const discountedOtherReferences: ReferenceLocal[] = remapReferencesWithDiscountedPrices(
    otherSection.references,
    discounts,
    loadedPrices,
  );

  const remapReferencesWithStocks = (
    references: ReferenceLocal[],
    stocks: Map<string, SearchData<ReferenceStock> | undefined>,
  ): CartPdfReference[] => {
    return references.map((ref) => {
      if (!stocks) {
        return ref;
      }
      const referenceStock = stocks.get(ref.referenceNumber)?.data;
      if (!referenceStock) {
        return ref;
      }
      return { ...ref, stock: referenceStock } as CartPdfReference;
    });
  };

  const vehiclesReferencesWithStocks: VehicleLocal[] = discountedVehiclesReferences.map((discountedVehicle) => {
    return { ...discountedVehicle, references: remapReferencesWithStocks(discountedVehicle.references, allStocks) };
  });

  const externalBasketReferencesWithStocks: ExternalBasketSectionLocal[] = discountedExternalBasketReferences.map(
    (discountedExternalBasket) => {
      return {
        ...discountedExternalBasket,
        references: remapReferencesWithStocks(discountedExternalBasket.references, allStocks),
      };
    },
  );

  const otherReferencesWithStocks: CartPdfReference[] = remapReferencesWithStocks(discountedOtherReferences, allStocks);

  const totalPriceVatIncluded = computeTotalPrice(
    vehiclesReferencesWithStocks,
    externalBasketReferencesWithStocks,
    otherReferencesWithStocks,
    true,
  );
  const totalPriceVatExcludedNoDiscount = computeTotalPrice(
    vehiclesSection,
    externalSection,
    otherSection.references,
    false,
  );
  const totalPriceVatExcluded = computeTotalPrice(
    vehiclesReferencesWithStocks,
    externalBasketReferencesWithStocks,
    otherReferencesWithStocks,
    false,
  );

  const vehiclesSectionData: VehicleSectionPdfData[] = vehiclesReferencesWithStocks.map((vehicle) => {
    const totalPriceForVehicle = calculateTotalPrice(vehicle?.references, discounts, loadedPrices);
    return {
      vehicleDetail: vehicle.vehicleDetail,
      orderMark: vehicle.orderMark,
      sectionData: {
        sectionTotalVatExcl: totalPriceForVehicle,
        references: vehicle.references,
      },
    };
  });

  const externalBasketSectionData: ExternalBasketSectionPdfData[] = externalBasketReferencesWithStocks.map(
    (externalBasket) => {
      const totalPriceForExternalBasket = calculateTotalPrice(externalBasket?.references, discounts, loadedPrices);
      return {
        externalBasketId: externalBasket.externalBasketId,
        sectionData: {
          sectionTotalVatExcl: totalPriceForExternalBasket,
          references: externalBasket.references,
        },
      };
    },
  );

  const otherItemsSectionData: OtherSectionPdfData = {
    orderMark: otherSection.orderMark,
    sectionData: {
      sectionTotalVatExcl: calculateTotalPrice(otherReferencesWithStocks, discounts, loadedPrices),
      references: otherReferencesWithStocks,
    },
  };

  const cartTotalDiscount = totalPriceVatExcludedNoDiscount - totalPriceVatExcluded;

  const cartTotalVat = (totalPriceVatIncluded ?? 0) - (totalPriceVatExcluded ?? 0);

  const cartItemsCount =
    vehiclesSection?.reduce(
      (acc: number, next: VehicleLocal) => (next?.references ? acc + next.references.length : acc),
      0,
    ) +
    externalSection?.reduce(
      (acc: number, next: ExternalBasketSectionLocal) => (next?.references ? acc + next.references.length : acc),
      0,
    ) +
    (otherSection.references?.length || 0);

  const cartTotalPdf: TotalSectionValues = {
    itemsCount: cartItemsCount.toString(),
    priceVatExcluded: totalPriceVatExcluded.toString(),
    priceVat: cartTotalVat.toString(),
    priceVatIncluded: totalPriceVatIncluded.toString(),
    discountVatExcluded: cartTotalDiscount.toString(),
    currency,
  };

  return {
    vehicleSection: vehiclesSectionData,
    externalBasketSection: externalBasketSectionData,
    otherItemsSection: otherItemsSectionData,
    otherData: { totalBasketValues: cartTotalPdf, allDeliveryLeadTime, dealerType, r1Country: userContext.r1Country },
  };
}
