import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTokenUser } from 'domains/user';
import { BackButton, Flex, MarginBox, Text } from 'UI';
import { SBackground } from '../Assistance.styled';

const AssistancePageHeader = () => {
  const { t } = useTranslation();
  const tokenUser = useSelector(getTokenUser);

  return (
    <SBackground image={'assistance/assistance-header.webp'}>
      <Flex align={'center'}>
        <MarginBox mt={45} ml={135}>
          {!tokenUser && <BackButton />}
          <MarginBox mt={tokenUser ? 56 : 30} />
          <Text type={'h1'}>{t('assistance', 'Assistance')}</Text>
        </MarginBox>
      </Flex>
    </SBackground>
  );
};

export default AssistancePageHeader;
