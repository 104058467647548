import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import {
  CreateInformation,
  CreateInformationTemplate,
} from 'pages/BackOfficePage/BackOfficeCategories/Information/CreateInformation';
import { Information } from 'pages/BackOfficePage/BackOfficeCategories/Information/Information';
import {
  CREATE_NEW,
  CREATE_NEW_TEMPLATE,
  EDIT,
  EDIT_TEMPLATE,
  INFORMATION,
} from 'pages/BackOfficePage/BackOfficePage.type';
import { EditInformation } from './EditInformation';

export function InformationBase() {
  return (
    <Switch>
      <Route exact path={`${ROUTER_BACKOFFICE}/${INFORMATION}/${CREATE_NEW}`} render={() => <CreateInformation />} />
      <Route
        exact
        path={`${ROUTER_BACKOFFICE}/${INFORMATION}/${CREATE_NEW_TEMPLATE}`}
        render={() => <CreateInformationTemplate />}
      />
      <Route
        exact
        path={`${ROUTER_BACKOFFICE}/${INFORMATION}/${EDIT}/:informationId`}
        render={() => <EditInformation />}
      />
      <Route
        exact
        path={`${ROUTER_BACKOFFICE}/${INFORMATION}/${EDIT_TEMPLATE}/:informationId`}
        render={() => <EditInformation />}
      />
      <Route exact path={'*'} render={() => <Information />} />
    </Switch>
  );
}
