/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_CART, ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { TimesCircleIcon } from 'assets/icons';
import { AddToEstimateButtonAndDialog } from 'components/AddToEstimate/AddToEstimateButtonAndDialog';
import BestSeller from 'components/BestSeller';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { ErrorWithLabel } from 'components/DataContainer';
import { DiscountCorner } from 'components/Discount';
import ProductModal from 'components/ProductModal';
import { ReferencePriceSection } from 'components/ReferencePriceSection';
import StockDisplay from 'components/StockInfo';
import { addReferenceByRefNumberRequest } from 'domains/basket/Basket.store';
import { DATAHUB, STANDARD } from 'domains/catalog/Catalog.types';
import { convertReferenceToEstimateReference } from 'domains/estimate/Estimate.mapper';
import { addCatalogReference, setSelectedTab } from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { getIsStockAvailable, getPrice, getStockInfo } from 'domains/references';
import { GarageView, SparePartsViewType } from 'domains/user';
import { theme } from 'styles';
import {
  Box,
  capitalize,
  CenterFlex,
  Flex,
  Icon,
  Image,
  Label,
  MarginBox,
  NotificationLink,
  notifyTop,
  Text,
  URL,
} from 'UI';
import { AddToEstimatePositionWrapper, ProductPreviewCard } from 'UI/ProductPreview/ProductPreview.styled';
import { getData, hasData, isError } from 'utils';
import {
  TRACKING_EVENT_ADD_TO_ESTIMATE,
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_GO_TO_ESTIMATE,
} from 'utils/eventTracker/EventTracker.types';

export interface ProductPreviewProps {
  imageUrl: string | undefined;
  productName: string | undefined;
  referenceNumber: string | undefined;
  sparePartsView: SparePartsViewType;
  isApplicableToCurrentVehicle?: boolean;
}

export const ProductPreview = ({
  imageUrl,
  productName,
  referenceNumber,
  sparePartsView,
  isApplicableToCurrentVehicle,
}: ProductPreviewProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));
  const stockInfo = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));

  if (!referenceNumber) {
    return null;
  }

  const handleAddToCartClick = () => {
    if (referenceNumber) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.universal_products.reference_card.added_to_basket.description'}>
          {'Universal product has been added to your cart'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
            history.push(ROUTER_CART);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
        </NotificationLink>,
      );
      dispatch(
        addReferenceByRefNumberRequest({
          reference: referenceNumber,
        }),
      );
    }
  };

  const handleAddToEstimateClick = () => {
    if (hasData(price)) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.reference_card.added_to_estimate.description'}>
          {'Reference has been added to your estimate.'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_ESTIMATE);
            dispatch(setSelectedTab(EstimateTabName));
            history.push(ROUTER_ESTIMATE);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_estimate.go_to_estimate'}>{'Go to estimate'}</Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_TO_ESTIMATE);
      dispatch(
        addCatalogReference({
          reference: convertReferenceToEstimateReference(referenceNumber, productName ?? '', price, true, 'DATAHUB'),
        }),
      );
    }
  };

  return (
    <>
      {sparePartsView === GarageView && <DiscountCorner reference={referenceNumber} type={'catalog'} />}
      <ProductPreviewCard direction={'column'} minWidth={300} maxWidth={300} minHeight={500} align={'center'}>
        {getData(price)?.garageView?.vatExcludedPrice && (
          <AddToEstimatePositionWrapper>
            <AddToEstimateButtonAndDialog handleAddToEstimateClick={handleAddToEstimateClick} size={'lg'} />
          </AddToEstimatePositionWrapper>
        )}
        <Flex direction={'column'}>
          <Box width={250} height={250}>
            {availableInUserCountry ? (
              <CenterFlex>
                <Image
                  src={imageUrl}
                  type={URL}
                  alt={'Universal product'}
                  maxWidth={250}
                  maxHeight={250}
                  fallbackComponent={
                    <Image src={'placeholder.png'} alt={'Universal product'} width={250} height={250} />
                  }
                />
              </CenterFlex>
            ) : (
              <CenterFlex direction={'column'} background={theme.color.shadow_7}>
                <Icon IconComponent={TimesCircleIcon} color={theme.color.lipstick} width={45} height={45} />
                <MarginBox mb={15} />
                <Box width={162}>
                  <Text type={'light_14_black_75'}>
                    <Trans i18nKey={'catalog.reference.unavailable'}>
                      Looks like this item is not available in your country.
                    </Trans>
                  </Text>
                </Box>
              </CenterFlex>
            )}
          </Box>
          <BestSeller isBestseller={false} />
          <MarginBox mt={16}>
            <Box height={22}>
              <Label width={210}>
                <ProductModal
                  triggerComponent={
                    <Text
                      type={availableInUserCountry ? 'section_bold' : 'light_14_bold_black_45'}
                      ellipsis
                      hoverUnderLine
                      cursor={'pointer'}
                    >
                      {capitalize(productName?.toLowerCase() ?? '')}
                    </Text>
                  }
                  referenceNumber={referenceNumber}
                  isUniversalProduct
                />
              </Label>
            </Box>
          </MarginBox>
          <Flex direction={'row'} align={'center'}>
            <Box height={22}>
              <ProductModal
                triggerComponent={
                  <Text
                    type={availableInUserCountry ? 'text_dim' : 'light_14_black_45'}
                    hoverUnderLine
                    cursor={'pointer'}
                  >
                    <Trans i18nKey={'catalog.reference.referenceNumber.short'} tOptions={{ referenceNumber }}>
                      Ref: {{ referenceNumber }}
                    </Trans>
                  </Text>
                }
                referenceNumber={referenceNumber}
                isUniversalProduct
              />
            </Box>
            <MarginBox mr={10} />
            <CopyToClipboardButton
              textToCopy={referenceNumber}
              message={t(
                'catalog.reference_card.reference_number.copied_to_clipboard',
                'Reference number {{referenceNumber}} copied to clipboard',
                { referenceNumber },
              )}
            />
          </Flex>
          <Flex direction={'column'} maxWidth={250}>
            {isError(stockInfo) && isError(price) ? (
              <ErrorWithLabel
                label={t(
                  'common.price_and_stock_backend_error',
                  'Price and stocks are temporarily unavailable, please try again later.',
                )}
                narrow
              />
            ) : (
              <>
                <StockDisplay
                  referenceNumber={referenceNumber}
                  isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
                  vehicleKey={undefined}
                  narrow
                />
                <Flex>
                  <ReferencePriceSection
                    align={'left'}
                    referenceNumber={referenceNumber}
                    handleAddToCartClick={handleAddToCartClick}
                    vehicleKey={undefined}
                    sparePartsView={sparePartsView}
                    catalogSource={DATAHUB}
                    referenceType={STANDARD}
                    narrow
                    withIcon
                    buttonSize={'large'}
                    buttonShape={'round'}
                  />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </ProductPreviewCard>
    </>
  );
};
