// for now only support IAM vehicles
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/basket/request/AddReferences';
import { TFunction } from 'i18next';

// For now only IAM vehicles are supported
export interface VehicleDetailForSearchSelection {
  modelVehicleDetail: VehicleDetail;
  vin: VehicleDetailForSearchSelectionItem | undefined;
  vrn: VehicleDetailForSearchSelectionItem | undefined;
  brand: VehicleDetailForSearchSelectionItem | undefined;
  model: VehicleDetailForSearchSelectionItem | undefined;
  phase: VehicleDetailForSearchSelectionItem | undefined;
  version: VehicleDetailForSearchSelectionItem | undefined;
  power: VehicleDetailForSearchSelectionItem | undefined;
  bodyType: VehicleDetailForSearchSelectionItem | undefined;
  doors: VehicleDetailForSearchSelectionItem | undefined;
  euroStandard: VehicleDetailForSearchSelectionItem | undefined;
}

export interface VehicleDetailForSearchSelectionItem {
  value: string;
  difference: boolean;
}

export function mapVehiclesToSearchSelections(
  vehicles: VehicleDetail[],
  t: TFunction,
): VehicleDetailForSearchSelection[] {
  return vehicles.map((vehicle) => {
    return {
      modelVehicleDetail: vehicle,
      vin: vehicle.vin
        ? {
            value: t('catalog.iam.vehicle.vin', 'VIN: {{vin}}', { vin: vehicle.vin }),
            difference: false,
          }
        : undefined,
      vrn: vehicle.vrn
        ? {
            value: t('catalog.iam.vehicle.vrn', 'VRN: {{vrn}}', { vrn: vehicle.vrn }),
            difference: vehicles.some((v) => v.vin === vehicle.vin && v.vrn != vehicle.vrn),
          }
        : undefined,
      brand: vehicle.vehicleBrand
        ? {
            value: vehicle.vehicleBrand,
            difference: vehicles.some((v) => v.vin === vehicle.vin && v.vehicleBrand != vehicle.vehicleBrand),
          }
        : undefined,
      model: vehicle.model
        ? {
            value: vehicle.model,
            difference: vehicles.some((v) => v.vin === vehicle.vin && v.model != vehicle.model),
          }
        : undefined,
      phase: vehicle.iamVehicle?.phase
        ? {
            value: t('catalog.iam.vehicle.phase', 'Phase {{phase}}', { phase: vehicle.iamVehicle.phase }),
            difference: vehicles.some((v) => v.vin === vehicle.vin && v.iamVehicle?.phase != vehicle.iamVehicle?.phase),
          }
        : undefined,
      version: vehicle.iamVehicle?.version
        ? {
            value: vehicle.iamVehicle.version,
            difference: vehicles.some(
              (v) => v.vin === vehicle.vin && v.iamVehicle?.version != vehicle.iamVehicle?.version,
            ),
          }
        : undefined,
      power:
        vehicle.iamVehicle?.kwPower && vehicle.iamVehicle?.hpPower
          ? {
              value: `(${t('catalog.iam.vehicle.kwPower', '{{kwPower}}KW', {
                kwPower: vehicle.iamVehicle.kwPower,
              })}/${t('catalog.iam.vehicle.hpPower', '{{hpPower}}HP', { hpPower: vehicle.iamVehicle.hpPower })})`,
              difference: vehicles.some(
                (v) =>
                  v.vin === vehicle.vin &&
                  (v.iamVehicle?.kwPower != vehicle.iamVehicle?.kwPower ||
                    v.iamVehicle?.hpPower != vehicle.iamVehicle?.hpPower),
              ),
            }
          : undefined,
      bodyType: vehicle.iamVehicle?.bodyType
        ? {
            value: vehicle.iamVehicle.bodyType,
            difference: vehicles.some(
              (v) => v.vin === vehicle.vin && v.iamVehicle?.bodyType != vehicle.iamVehicle?.bodyType,
            ),
          }
        : undefined,
      doors: vehicle.iamVehicle?.doors
        ? {
            value: t('catalog.iam.vehicle.doors', '{{numberOfDoors}} Doors', {
              numberOfDoors: vehicle.iamVehicle.doors,
            }),
            difference: vehicles.some((v) => v.vin === vehicle.vin && v.iamVehicle?.doors != vehicle.iamVehicle?.doors),
          }
        : undefined,
      euroStandard: vehicle.iamVehicle?.euroStandard
        ? {
            value: vehicle.iamVehicle.euroStandard,
            difference: vehicles.some(
              (v) => v.vin === vehicle.vin && v.iamVehicle?.euroStandard != vehicle.iamVehicle?.euroStandard,
            ),
          }
        : undefined,
    };
  });
}
