import React, { ReactNode, useRef } from 'react';
import { Checkbox as AntCheckbox, CheckboxRef } from 'antd-v5';
import { ThemeFontKeyType } from 'styles';
import { MarginBox, Text } from 'UI';
import { SFlex } from 'UI/Radio/Radio.styled';

export interface CheckBoxProps {
  value?: string;
  checked?: boolean;
  onChange?: (x: boolean) => void;
  label?: string | ReactNode;
  disabled?: boolean;
  type?: ThemeFontKeyType;
  dataCy?: string;
}

export const Checkbox = ({ value, onChange, checked, label, disabled, type, dataCy }: CheckBoxProps) => {
  const ref = useRef<CheckboxRef>(null);

  const handleClick = () => {
    if (disabled || !onChange) return;
    onChange(!checked);
  };

  return (
    <SFlex onClick={handleClick} disable={disabled}>
      <AntCheckbox value={value} disabled={disabled} checked={checked} ref={ref} data-cy={dataCy} />
      {label && (
        <MarginBox ml={8}>
          <Text type={type ?? 'text'}>{label}</Text>
        </MarginBox>
      )}
    </SFlex>
  );
};

export const CheckboxGroup = AntCheckbox.Group;
