import styled from 'styled-components';

export const DiscountPositionWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  margin-top: -2px;
  margin-left: -2px;
`;

export const FlashPositionWrapper = styled.div<{ offsetY: number }>`
  position: absolute;
  z-index: 100;
  margin-top: ${({ offsetY }) => `${offsetY}px`};
`;

export const CornerLabel = styled.div<{ offsetY: number }>`
  margin-top: ${({ offsetY }) => `${offsetY}px`};
  margin-left: -5px;
  transform: rotate(-50deg);
`;

export const FlashTextWrapper = styled.div`
  height: 40px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: ${({ theme }) => theme.color.error};
  border-radius: 11px;
`;
