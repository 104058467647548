import { List } from 'antd';
import { ListProps } from 'antd/lib/list';
import styled, { css } from 'styled-components';

export const SFileUpload = styled.div`
  width: 701px;
  height: 425px;
  padding: 36px 45px 48px;
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
  background-color: var(--white);
`;

const BodyCommon = css`
  border: 1px dashed ${({ theme }) => theme.color.grey85};
  border-radius: 5px;
`;

export const Body = styled.div<{
  cursor: 'pointer' | 'inherit';
  maxWidth?: number;
  minWidth?: number;
  height?: number;
}>`
  ${BodyCommon};
  cursor: ${({ cursor }) => cursor};
  height: ${({ height }) => height}px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  min-width: ${({ minWidth }) => minWidth}px;
`;

export const BodySmall = styled.div`
  ${BodyCommon};
  padding: 10px 15px;
  white-space: nowrap;
  max-width: fit-content;
`;

export const SPreviewList: typeof List = styled(List)<ListProps<string>>`
  max-width: 200px;
` as any;
