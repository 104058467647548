import React, { useMemo } from 'react';
import {
  FamilyReferences,
  GetIAMFamilyReferencesResponse,
} from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/response/GetIAMFamilyReferencesResponse';
import { DataContainer } from 'components/DataContainer';
import Loader from 'components/Loader';
import Family from 'pages/CatalogPage/IAM/MaintenancePlan/Families/Family';
import { Flex } from 'UI';
import { getData, NO_DATA } from 'utils';

const Families = ({
  familyIds,
  familiesData,
  onSelectReferences,
  selectedReferences,
}: {
  familyIds?: string[];
  familiesData: NO_DATA | GetIAMFamilyReferencesResponse;
  onSelectReferences: (family: string, references: string[]) => void;
  selectedReferences: Map<string, string[]>;
}) => {
  const familiesWithReferences = useMemo(
    () =>
      familyIds
        ?.map((familyId) => getData(familiesData)?.families?.find((familyRef) => familyRef.id === familyId))
        .filter((familyRef): familyRef is FamilyReferences => familyRef !== undefined),
    [familyIds, familiesData],
  );

  const getFamilyReferences = (familyId: string) =>
    familiesWithReferences?.find((familyReferences) => familyReferences.id === familyId)?.references;

  return (
    <DataContainer
      data={familiesData}
      Loading={() => <Loader height={'400px'} />}
      NotFound={() => <></>}
      ErrorState={() => <></>}
    >
      <Flex direction={'column'}>
        {familiesWithReferences && familiesWithReferences.length > 0
          ? familiesWithReferences.map((family) => {
              return (
                <Family
                  key={family.id}
                  familyId={family.id}
                  familyLabel={family.label}
                  onSelectReferences={onSelectReferences}
                  selectedReferences={selectedReferences.get(family.id)}
                  references={getFamilyReferences(family.id)}
                />
              );
            })
          : null}
      </Flex>
    </DataContainer>
  );
};

export default Families;
