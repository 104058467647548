import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { PlateImage } from 'domains/catalog/Catalog.types';
import { CenteredSpin } from 'UI';
import { SSvgFlex, SvgDiv } from './PlateDetailCardImage.styled';

export const PlateDetailCardImage = ({
  plateImage,
  image,
  svgElementPlateId,
}: {
  plateImage: PlateImage;
  image: ReactNode;
  svgElementPlateId: string;
}) => {
  const { t } = useTranslation();
  return (
    <DataContainer
      data={image}
      NotFound={() => (
        <ErrorWithLabel
          label={t(
            'catalog.plate.backend_error.detail',
            'Plate preview is temporarily unavailable, please try again later.',
          )}
        />
      )}
      Skeleton={() => (
        <>
          {plateImage.imageKey === 'not_found' ? (
            <ErrorWithLabel
              label={t(
                'catalog.plate.backend_error.detail',
                'Plate preview is temporarily unavailable, please try again later.',
              )}
            />
          ) : (
            <CenteredSpin />
          )}
        </>
      )}
      Loading={() => <CenteredSpin />}
      ErrorState={() => (
        <ErrorWithLabel
          label={t(
            'catalog.plate.backend_error.detail',
            'Plate preview is temporarily unavailable, please try again later.',
          )}
        />
      )}
    >
      <SSvgFlex>
        <SvgDiv id={`${svgElementPlateId}${plateImage.plateId}`}>{image}</SvgDiv>
      </SSvgFlex>
    </DataContainer>
  );
};
