import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EnvelopeIcon, FileAltIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { Dialog, useDisclosure } from 'components/Dialog';
import {
  getEmailFromGarageData,
  sendEmailFromGarageRequestSaga,
  setEmailFromGarageData,
} from 'domains/email/Email.store';
import { Flex, Icon, Input, MarginBox, Space, Text, TextArea } from 'UI';
import { getData, hasData } from 'utils';
import { AttachmentPreview } from './SendEmailDialog.styled';

interface SendEmailDialogProps {
  title: string;
  triggerElement: {
    element: React.ReactNode;
    disabled: boolean;
  };
  additionalOptions?: React.ReactNode;
}

//todo handle multiple recipients and attachments if ever described by business
const SendEmailDialog = ({ title, triggerElement, additionalOptions }: SendEmailDialogProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const emailData = useSelector(getEmailFromGarageData);
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;
  const recipient = emailData.recipients[0];
  const [email, setEmail] = useState(recipient);
  const [subject, setSubject] = useState(emailData.subject);
  const [body, setBody] = useState(emailData.body);

  useEffect(() => {
    setEmail(recipient);
  }, [recipient]);

  useEffect(() => {
    setSubject(emailData.subject);
  }, [emailData.subject]);

  useEffect(() => {
    setBody(emailData.body);
  }, [emailData.body]);

  const onOpenAttachment = async (index: number) => {
    if (emailData.attachments && hasData(emailData.attachments) && emailData.attachments[index]) {
      const data = await fetch(`data:application/pdf;base64,${emailData.attachments[index].fileBase64}`);
      const blob = await data.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        if (url) {
          window.open(url);
        }
      }
    }
  };

  return (
    <>
      <div onClick={triggerElement.disabled ? undefined : onOpen}>{triggerElement.element}</div>
      <Dialog
        width={1000}
        disclosure={disclosure}
        title={title}
        icon={EnvelopeIcon}
        handleConfirm={() => dispatch(sendEmailFromGarageRequestSaga())}
        buttonsText={{ confirm: t('common.action.send', 'Send') }}
        content={
          <Flex direction={'column'} minWidth={900}>
            <Space direction={'vertical'} size={15}>
              {additionalOptions}
              <Input
                placeholder={t('email.client_email', `Client's email`)}
                bordered
                value={email}
                onChange={(value) => dispatch(setEmailFromGarageData({ ...emailData, recipients: [value] }))}
              />
              <Input
                placeholder={t('common.email.subject', 'Subject')}
                bordered
                value={subject}
                initialValue={emailData.subject}
                onChange={(value) => dispatch(setEmailFromGarageData({ ...emailData, subject: value }))}
              />
              <MarginBox mt={15} />
              <TextArea
                size={'large'}
                rows={10}
                allowEnterNextLine
                value={body}
                initialValue={emailData.body}
                onChange={(value) => dispatch(setEmailFromGarageData({ ...emailData, body: value }))}
              />
              <Flex>
                <DataContainer data={emailData.attachments} NotFound={() => <></>}>
                  {getData(emailData.attachments)?.map((attachment, index) => {
                    return (
                      <AttachmentPreview key={`attachment_${index}`}>
                        <Flex align={'center'} minHeight={35}>
                          <Space direction={'horizontal'} size={8}>
                            <MarginBox mt={5}>
                              <Icon IconComponent={FileAltIcon} size={18} color={'red'} />
                            </MarginBox>
                            <Text type={'h6_black'}>{t('common.file_upload.file', 'File:')}</Text>
                            <Text
                              type={'lead'}
                              hoverUnderLine
                              cursor={'pointer'}
                              onClick={() => onOpenAttachment(index)}
                            >
                              {attachment.fileName}
                            </Text>
                          </Space>
                        </Flex>
                      </AttachmentPreview>
                    );
                  })}
                </DataContainer>
              </Flex>
            </Space>
          </Flex>
        }
      />
    </>
  );
};

export default SendEmailDialog;
