import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { fetchAppConfig, getAppConfig } from 'domains/appContext/AppContext.store';
import { getDealerType, getUserCountry, getUserProfileSearchStatus, UserRole } from 'domains/user';
import { FOUND } from 'utils';
import { Country } from 'utils/i18n/Country';

export const useFetchAppConfig = () => {
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const appConfigStatus = useSelector(getAppConfig).searchStatus;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!appConfigStatus && searchStatus === FOUND) {
      dispatch(fetchAppConfig());
    }
  }, [dispatch, searchStatus, appConfigStatus]);
};

export const useIsOilChunkUser = () => {
  const userCountry = useSelector(getUserCountry);
  const dealerType = useSelector(getDealerType);
  const allowedDealerTypes: DealerType[] = [UserRole.R1, UserRole.R2, UserRole.R3_MOTRIO];
  return userCountry === Country.FR.key && dealerType && allowedDealerTypes.includes(dealerType);
};
