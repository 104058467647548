import styled, { createGlobalStyle, css } from 'styled-components';
import '../assets/fonts/font.css';

const scrollbar = css`
  &::-webkit-scrollbar {
    background-color: transparent;
    height: 8px;
    width: 8px;
  }

  &:hover::-webkit-scrollbar {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.grey85};
    border-radius: 16px;
    border: 4px solid transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  body {
    overflow: auto;
  }

  .ant-layout-sider {
    overflow: auto;
  }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0;
    font-family: NouvelR, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${scrollbar}
  .ant-tooltip {
    position: relative;
    z-index: 10000;
  }

  #ot-sdk-btn-floating {
    display: none;
    animation: none;
  }
`;

export const SRectangle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 250px;
  width: 500px;
  padding: 50px 20px 105px 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.white};
  border: solid 1px;
  border: ${({ theme }) => `1px solid ${theme.color.brand_black}`};
`;
