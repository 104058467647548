import styled from 'styled-components';
import { useBreakpointSelector } from 'utils/hooks/useBreakpoint';

type BlockWrapperProps = {
  textColor: string;
  left: boolean;
};

export const BlockWrapper = styled.div<BlockWrapperProps>`
  color: ${(props) => props.textColor};
  display: flex;
  padding: 90px 24px 90px 24px;
  flex-direction: ${(props) =>
    useBreakpointSelector()(props.left ? ['column', 'row'] : ['column-reverse', 'row-reverse'])};
`;

export const InfoWrapper = styled.div<{ left: boolean }>`
  text-align: left;
  margin: auto ${({ left }) => (left ? 0 : 'auto')} auto ${({ left }) => (left ? 'auto' : 0)};
  max-width: 700px;
`;

export const InfoTitle = styled.div`
  font-size: ${() => useBreakpointSelector()([24, 48]) + 'px'};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -1.2px;
`;

export const InfoText = styled.div`
  font-size: ${() => useBreakpointSelector()([14, 18]) + 'px'};
  max-width: 500px;
  margin-top: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.18px;
`;

export const InfoButton = styled.div`
  margin-top: 30px;
  width: ${() => useBreakpointSelector()([120, 200]) + 'px'};
`;
