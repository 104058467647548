import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FlashPromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FlashPromotionBanner';
import moment from 'moment/moment';
import { ROUTER_PROMOTION } from 'app/AppRouter';
import { SAdvertisementSpace } from 'components/Page/Header/AdvertisementSpace/Banner.styled';
import LeftBannerSide from 'components/Page/Header/AdvertisementSpace/LeftBannerSide';
import RightBannerSide from 'components/Page/Header/AdvertisementSpace/RightBannerSide';
import { Text, YellowButton } from 'UI';

const FlashTimeLimitedBanner = ({ banner }: { banner: FlashPromotionBanner }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const getTimeLeft = (units: 'd' | 'h' | 'm') => moment(banner.publicationEnd).diff(moment(), units);

  const daysLeft = getTimeLeft('d');
  const hoursLeft = getTimeLeft('h');
  const minutesLeft = getTimeLeft('m') % 60;

  const getUnitsText = () => {
    if (daysLeft > 0)
      return (
        <Trans count={daysLeft} i18nKey={'backoffice.promotion.banner.days_left'}>
          {'Only {{count}} day left.'}
        </Trans>
      );
    if (hoursLeft > 0)
      return (
        <Trans i18nKey={'backoffice.promotion.banner.hours_left'} tOptions={{ hoursLeft, minutesLeft }}>
          {'Only {{hoursLeft}}h : {{minutesLeft}}m left.'}
        </Trans>
      );
    return (
      <Trans i18nKey={'backoffice.promotion.banner.minutes_left'} tOptions={{ minutesLeft }}>
        {'Only {{minutesLeft}}m left.'}
      </Trans>
    );
  };

  return (
    <SAdvertisementSpace onClick={() => history.push(`${ROUTER_PROMOTION}/${banner.promotionId}`)}>
      <LeftBannerSide title={banner.title} discount={banner.discount}>
        {getUnitsText()}
      </LeftBannerSide>
      <RightBannerSide>
        <YellowButton size={'middle'}>
          <Text type={'text_dim_bold'}>{t('catalog.reference_card.additional_info.see_more', 'See more')}</Text>
        </YellowButton>
      </RightBannerSide>
    </SAdvertisementSpace>
  );
};
export default FlashTimeLimitedBanner;
