import styled from 'styled-components';
import { Flex } from 'UI';

export const SvgDiv = styled.div`
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  .highlightedIndexLine {
    stroke: ${({ theme }) => theme.color.highlight};
    cursor: pointer;
  }

  .hiddenIndexLine {
    display: none;
  }

  .invisible-index {
    fill-opacity: 0;
    stroke: none;
    cursor: pointer;
  }

  .invisible-line {
    stroke-opacity: 0;
    cursor: pointer;
  }
`;

export const SSvgFlex = styled(Flex)`
  .invisible-index {
    font-size: 11px;
  }

  .invisible-line {
    stroke-width: 5px;
  }
`;
