import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTER_CATALOG_DH_L3 } from 'app/AppRouter';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { ActiveTabParam, PARAM_LABOR_TIME, PARAM_SPARE_PARTS, PreferredTabParam } from 'domains/catalog/Catalog.types';
import { usePreferredTabHook } from 'pages/CatalogPage/common/SubcategorySection/SubcategorySectionHeader.hook';
import { Flex, LinkTabs, MarginBox, sentenceCase, Text } from 'UI';

export type TabState = 'spare_parts' | 'labor_time' | 'repair_methods';

export const SubcategorySectionHeader = ({
  tabState,
  setTabState,
  disabledTabs,
  label,
}: {
  tabState: TabState;
  setTabState: React.Dispatch<React.SetStateAction<TabState>>;
  disabledTabs: TabState[];
  label: string | undefined;
}) => {
  const { t } = useTranslation();

  usePreferredTabHook(tabState, setTabState, disabledTabs, getParamState);

  function getLabel(tab: TabState) {
    switch (tab) {
      case PARAM_SPARE_PARTS:
        return t('catalog.parts.category.car_parts.parts', 'Spare parts');
      case PARAM_LABOR_TIME:
        return t('catalog.parts.category.car_parts.labor_time', 'Labor time');
      default:
        return;
    }
  }

  function getParamState(tab: TabState) {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set(ActiveTabParam, tab);
    urlParams.set(PreferredTabParam, tab);
    return urlParams;
  }

  const tabItems = [
    {
      label: getLabel(PARAM_SPARE_PARTS),
      link: '',
      uparam: getParamState(PARAM_SPARE_PARTS),
      key: 0,
      isDisabled: disabledTabs.some((n) => n === PARAM_SPARE_PARTS),
      dataCy: 'button-spare-parts',
    },
    {
      label: getLabel(PARAM_LABOR_TIME),
      link: '',
      uparam: getParamState(PARAM_LABOR_TIME),
      key: 1,
      isDisabled: disabledTabs.some((n) => n === PARAM_LABOR_TIME),
      dataCy: 'button-labor-time',
    },
  ];

  return (
    <Flex wrap={'wrap'} align={'center'} justify={'space-between'} size={1}>
      <MarginBox mt={10}>
        <Flex size={'none'} direction={'column'}>
          <Text type={'h1_banner_light'}>{sentenceCase(label ?? '')}</Text>
        </Flex>
      </MarginBox>
      <FirstHelpPopin
        streamId={ROUTER_CATALOG_DH_L3}
        popinId={`${ROUTER_CATALOG_DH_L3}_plate_detail`}
        placement={'bottomLeft'}
      >
        <Flex size={'auto'} justify={'flex-end'} direction={'row'}>
          <LinkTabs items={tabItems} variant={'white'} />
        </Flex>
      </FirstHelpPopin>
    </Flex>
  );
};
