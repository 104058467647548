import styled, { css } from 'styled-components';
import { theme } from 'styles';

const activeCss = css`
  span {
    color: ${theme.color.white};
  }
  background-color: ${theme.color.blue_dark_3};
`;

export const MenuItem = styled.div<{ active?: boolean }>`
  border-radius: 8px;
  padding: 10px 8px 10px 8px;

  ${({ active }) => active && activeCss};
  :hover {
    cursor: pointer;
    ${activeCss};
  }
`;

export const CategoryCard = styled.div`
  background-color: ${theme.color.grey90};
  padding: 16px 20px 16px 20px;
  border-radius: 10px;
  min-width: 250px;
  max-width: 300px;
`;
