import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceErrorRow } from '@1po/1po-bff-fe-spec/generated/basket/response/model/ReferenceErrorRow';
import { List } from 'antd';
import { CartReferenceErrorRow } from 'domains/basket/Basket.types';
import { Box, Flex, Text } from 'UI';
import { ErrorAlert, ErrorDetails, ScrollContainer, SDivider } from './FileReferencesRowsErrors.styled';

interface EListI {
  data: CartReferenceErrorRow[];
}

interface SplitListI {
  leftList: CartReferenceErrorRow[];
  rightList: CartReferenceErrorRow[];
}

interface FileReferencesRowsErrorsProps {
  errorRows: CartReferenceErrorRow[];
}

export default function FileReferencesRowsErrors({ errorRows }: Readonly<FileReferencesRowsErrorsProps>) {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [splitList, setSplitList] = useState<SplitListI>({ leftList: [], rightList: [] });

  function EList(props: EListI) {
    return (
      <List
        dataSource={props.data}
        renderItem={(item) => (
          <List.Item>
            {item.rowNumber && (
              <>
                <Text type={'text_bold'}>{t('common.line', 'Line') + ' ' + item.rowNumber}</Text>
                <Box width={5} />
              </>
            )}
            <Text type={'light_14_black_65'}> {item.referenceNumber}:</Text>
            <Box width={5} />
            <Text type={'light_14_black_65'}> {translateErrorCode(item.errorCode)}</Text>
            <Flex />
          </List.Item>
        )}
        split={false}
      />
    );
  }

  function translateErrorCode(code: ReferenceErrorRow['errorCode']): string {
    switch (code) {
      case 'UNKNOWN_REFERENCE':
        return t('cart.file_upload.row.unknown_reference', 'Unknown reference');
      case 'NOT_SOLD':
        return t('cart.file_upload.row.product_no_longer_sold', 'Product no longer sold');
      case 'TECHNICAL_ERROR':
        return t('cart.file_upload.row.unknown_reference', 'Unknown reference');
      case 'INVALID_QUANTITY':
        return t('cart.file_upload.row.invalid_quantity', 'Invalid quantity');
      case 'QUANTITY_EXCEEDS_999':
        return t('cart.file_upload.row.quantity_exceeds_999', 'Quantity exceeds 999');
      case 'OUT_OF_STOCK':
        return t('cart.file_upload.row.out_of_stock', 'Product is out of stock');
      case 'PRICE_NOT_AVAILABLE':
        return t('cart.file_upload.row.price_unavailable', 'Product price unavailable');
      default:
        return '';
    }
  }

  useEffect(() => {
    const half = Math.ceil(errorRows.length / 2);
    setSplitList({
      leftList: errorRows.slice(0, half),
      rightList: errorRows.slice(half),
    });
  }, [errorRows]);

  if (errorRows.length === 0) {
    return null;
  }

  return (
    <Flex direction={'column'} align={'flex-start'} justify={'center'}>
      <ErrorAlert
        message={
          <Flex direction={'row'} justify={'space-between'}>
            <Text type={'light_14_black_65'}>
              {t('cart.file_upload.items_could_not_be_added', "One or several items couldn't be added to your cart")}
            </Text>
            <Flex minWidth={100} justify={'flex-end'}>
              <Text type={'text'} displayStyle={'link'} cursor={'pointer'} onClick={() => setIsHidden(!isHidden)}>
                {isHidden
                  ? t('cart.file_upload.see_details', 'See details')
                  : t('cart.file_upload.close_details', 'Close details')}
              </Text>
            </Flex>
          </Flex>
        }
        type={'error'}
        showIcon
      />
      <ScrollContainer>
        <ErrorDetails hidden={isHidden}>
          <Box align={'left'}>
            <Text type={'text'} displayStyle={'error'}>
              {t('common.details', 'Details')}
            </Text>
            <Flex>
              <Flex>
                <EList data={splitList.leftList} />
              </Flex>
              {splitList.rightList.length > 0 && (
                <>
                  <SDivider type={'vertical'} />
                  <Flex>
                    <EList data={splitList.rightList} />
                  </Flex>
                </>
              )}
            </Flex>
          </Box>
        </ErrorDetails>
      </ScrollContainer>
    </Flex>
  );
}
