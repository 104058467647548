import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const SListItem = styled(Flex)`
  position: relative;
  margin-bottom: 15px;
  box-shadow: 0 5px 12px 4px ${theme.color.shadow_6}, 0 3px 6px 0 ${theme.color.shadow_5},
    0 1px 2px -2px ${theme.color.shadow_4};
  flex-wrap: nowrap;
  width: 100%;
`;
