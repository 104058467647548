import styled from 'styled-components';
import { Flex, Text } from 'UI';

export const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.color.grey95};
  height: 54px;
  width: 100%;
`;

export const SectionContent = styled(Flex)`
  width: 100%;
`;

export const ServicesContent = styled.div`
  background-color: ${({ theme }) => theme.color.grey_very_light_2};
  margin: 0px -30px;
  padding: 45px 30px;
`;

export const SText = styled(Text)`
  text-align: center;
`;
