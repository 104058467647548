import { Menu } from 'antd';
import styled from 'styled-components';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { DisplayModeType, effect } from 'styles';

export const SearchContainer = styled.div`
  margin-top: 25px;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 200px;
  user-select: none;
`;

export const SearchMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grey90};
  margin: 0 21px 0 21px;
`;

export const SItem = styled(Menu.Item)`
  padding: 12px 12px;
  ${effect.hover.colorBrand}
  ${effect.focus.colorBrand}
`;

export const SSearchInput = styled(SearchInput)<{ displayMode: DisplayModeType }>`
  background-color: ${({ theme, displayMode }) => theme.displayMode[displayMode].searchBackgroundColor};
`;
