import storage from 'local-storage-fallback';
import { APP_NAMESPACE } from 'app/App.constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function storeSave(key: string, data: any): void {
  if (!storage || !window.JSON || !key) {
    return;
  }
  storage.setItem(`${APP_NAMESPACE}_${key}`, JSON.stringify(data));
}

export function storeLoadAndClear(key: string): any {
  const item = storeLoad(key);
  if (item != null) {
    storeClear(key);
  }
  return item;
}

export function storeLoad(key: string): any {
  if (!storage || !window.JSON || !key) {
    return null;
  }

  const item = storage.getItem(`${APP_NAMESPACE}_${key}`);
  return item ? JSON.parse(item) : null;
}

export function storeClear(key: string): void {
  if (!storage || !window.JSON || !key) {
    return;
  }
  storage.removeItem(`${APP_NAMESPACE}_${key}`);
}
