import styled from 'styled-components';
import { Flex } from 'UI';

export const SAdvertisementSpace = styled(Flex)<{ noPointer: boolean }>`
  position: relative;
  width: 100%;
  height: 250px;
  background-color: ${({ theme }) => theme.color.promotion_cyan_dark};
  cursor: ${({ noPointer }) => (noPointer ? 'initial' : 'pointer')};
`;

export const IconPositionWrapper = styled(Flex)<{ right: string }>`
  position: absolute;
  bottom: 0;
  right: ${({ right }) => right};
`;
