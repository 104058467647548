import styled from 'styled-components';
import { effect } from 'styles';
import { Box, defaultBoxShadow, Flex, Image } from 'UI';

export const SCarPartGroupsSection = styled(Flex)`
  row-gap: 80px;
  column-gap: 30px;
  justify-content: center;
`;

export const CarPartGroupItemImg = styled(Image)`
  border-radius: 10px;
  object-fit: contain;
  background: white;
  ${defaultBoxShadow};
`;

export const SCarPartGroupsItem = styled(Box)`
  cursor: pointer;
  ${effect.hover.dim};
`;
