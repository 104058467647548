import React from 'react';
import PrivateComponent from 'composers/PrivateComponent';
import { PromotionTypeLocal } from 'domains/promotion/Promotion.types';
import { UserRole } from 'domains/user';
import { Flex, MarginBox, MinusCircleButton, PlusCircleButton, Text } from 'UI';
import { AddIconPosition, RelativeFlex } from './CreatePromotion.styled';

interface AdditionalPromotionComponentProps {
  promotionType: PromotionTypeLocal;
  defaultComponent: () => JSX.Element;
  additionalComponent: () => JSX.Element;
  handleAddAdditionalComponent: () => void;
  handleRemoveAdditionalComponent: () => void;
  showAdditionalComponent: boolean;
  additionalTitle?: string;
}

export const AdditionalPromotionComponent = ({
  promotionType,
  defaultComponent,
  handleAddAdditionalComponent,
  handleRemoveAdditionalComponent,
  additionalComponent,
  showAdditionalComponent,
  additionalTitle,
}: AdditionalPromotionComponentProps) => {
  return (
    <Flex direction={'column'}>
      <RelativeFlex direction={'row'}>
        {defaultComponent()}
        {!showAdditionalComponent && promotionType === 'DISCOUNT' && (
          <AddIconPosition>
            <MarginBox ml={15} />
            <PrivateComponent
              render={() => <PlusCircleButton onClick={() => handleAddAdditionalComponent()} />}
              requiredRights={[UserRole.COUNTRY_ADMIN]}
            />
          </AddIconPosition>
        )}
      </RelativeFlex>
      <PrivateComponent
        render={() => (
          <>
            {showAdditionalComponent && promotionType === 'DISCOUNT' && (
              <Flex direction={'column'}>
                <MarginBox mt={30} />
                {additionalTitle && (
                  <Flex>
                    <Text type={'h2'}>{additionalTitle}</Text>
                    <MarginBox mt={15} />
                  </Flex>
                )}
                <Flex direction={'row'}>
                  {additionalComponent()}
                  <MarginBox ml={15} />
                  <MinusCircleButton color={'red'} onClick={() => handleRemoveAdditionalComponent()} />
                </Flex>
              </Flex>
            )}
          </>
        )}
        requiredRights={[UserRole.COUNTRY_ADMIN]}
      />
    </Flex>
  );
};
