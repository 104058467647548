import React, { FunctionComponent } from 'react';
import { Text, TextProps } from 'UI/Text/TextSchema';
import { WithTooltip } from 'UI/Tooltip';

function BaseText(props: Readonly<TextWithPopupProps>) {
  return <Text {...props}>{props.children}</Text>;
}

function NarrowText(props: Readonly<TextWithPopupProps>) {
  return (
    <WithTooltip title={props.label}>
      <Text {...props} ellipsis>
        {props.children}
      </Text>
    </WithTooltip>
  );
}

interface TextWithPopupProps extends TextProps {
  label: string;
  narrow: boolean;
}

export const TextWithPopup = (props: Readonly<TextWithPopupProps>) => {
  const PopupText: FunctionComponent<TextWithPopupProps> = props.narrow ? NarrowText : BaseText;
  return <PopupText {...props} />;
};
