/* eslint-disable max-len */
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from 'app/AppStore';
import { ExternalLinkIcon, InfoCircleIcon, RecycleIcon } from 'assets/icons';
import { InfoDialog, useDisclosure } from 'components/Dialog';
import { getReuseLink } from 'domains/appContext/AppContext.store';
import { getReuseStocks } from 'domains/references';
import { theme } from 'styles';
import { BlueButton, Flex, Icon, Link, MarginBox, Text, WithTooltip } from 'UI';
import { getSearchData } from 'utils';

const ButtonReuse = styled.div`
  display: flex;
  max-width: 250px;
  height: 30px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: solid 1px #c7c7c7;
  align-items: center;
  background-color: ${({ theme }) => theme.color.grey_04};
  cursor: pointer;
`;

const Reuse = ({ referenceNumber, compactView = false }: { referenceNumber: string; compactView?: boolean }) => {
  const { t } = useTranslation();
  const reuseReferenceSearchData = useSelector((state: RootState) => getReuseStocks(state, referenceNumber));
  const disclosure = useDisclosure();
  const { onOpen, onClose } = disclosure;
  const reuseUrl = useSelector(getReuseLink);

  const reuseReference = getSearchData(reuseReferenceSearchData);

  if (!reuseReference) {
    return null;
  }

  const ecoQty = reuseReference.eco?.totalQuantity ?? 0;
  const mediumQty = reuseReference.medium?.totalQuantity ?? 0;
  const premiumQty = reuseReference.premium?.totalQuantity ?? 0;
  const totalQty = ecoQty + mediumQty + premiumQty;

  if (totalQty === 0 || !reuseUrl) {
    return null;
  }

  const renderTooltipContent = () => {
    return (
      <Flex direction={'column'}>
        <Text type={'h5_light_white'} dataCy={'parts-available'}>
          {t('catalog.reuse.total_parts_available', '{{ count }} Parts available', {
            count: totalQty,
          })}
        </Text>
        {ecoQty !== 0 && (
          <Text type={'light_14_white'}>
            {t('catalog.reuse.eco', 'Eco ({{ ecoQty }}) from {{ ecoPrice }}€', {
              ecoQty,
              ecoPrice: reuseReference.eco.minPrice,
            })}
          </Text>
        )}
        {mediumQty !== 0 && (
          <Text type={'light_14_white'}>
            {t('catalog.reuse.medium', 'Medium ({{ mediumQty }}) from {{ mediumPrice }}€', {
              mediumQty,
              mediumPrice: reuseReference.medium.minPrice,
            })}
          </Text>
        )}
        {premiumQty !== 0 && (
          <Text type={'light_14_white'}>
            {t('catalog.reuse.premium', 'Premium ({{ premiumQty }}) from {{ premiumPrice }}€', {
              premiumQty,
              premiumPrice: reuseReference.premium.minPrice,
            })}
          </Text>
        )}
      </Flex>
    );
  };

  return (
    <>
      <InfoDialog
        title={t('common', 'Redirection')}
        handleConfirm={() => undefined}
        disclosure={disclosure}
        icon={InfoCircleIcon}
        content={
          <Flex direction={'column'} gap={20}>
            <MarginBox mt={15} />
            <Text type={'text_dim'}>
              {t('catalog.reuse_offers.copy_and_redirect1', 'You are now being redirected to our partner website.')}
            </Text>
            <Text type={'text_dim'}>
              {t(
                'catalog.reuse_offers.copy_and_redirect2',
                'Reference number has been copied in the clipboard, click on "Continue" to proceed and access our REuse offers.',
              )}
            </Text>
            <MarginBox mt={15} />
            <Flex justify={'flex-end'}>
              <Link to={reuseUrl} external newTab>
                <BlueButton onClick={onClose}>{t('common.action.continue', 'Continue')}</BlueButton>
              </Link>
            </Flex>
          </Flex>
        }
      />
      <Flex maxWidth={250}>
        <WithTooltip title={renderTooltipContent()} placement={'right'}>
          <CopyToClipboard text={referenceNumber} onCopy={onOpen}>
            {compactView ? (
              <Icon
                IconComponent={RecycleIcon}
                size={16}
                ml={10}
                mr={5}
                color={theme.color.selected}
                data-cy={'button-reuse-compact'}
              />
            ) : (
              <ButtonReuse data-cy={'button-reuse'}>
                <Icon IconComponent={RecycleIcon} size={16} ml={10} mr={5} color={theme.color.selected} />
                <Text type={'mobile_h3'} ellipsis noWrap>
                  {t('catalog.reuse_offers', 'REuse offers')}
                </Text>
                <Icon
                  IconComponent={ExternalLinkIcon}
                  size={16}
                  ml={5}
                  mb={3}
                  mr={10}
                  color={theme.color.brand_black}
                />
              </ButtonReuse>
            )}
          </CopyToClipboard>
        </WithTooltip>
      </Flex>
    </>
  );
};

export default Reuse;
