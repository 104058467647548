import React from 'react';
import { Table } from 'antd';
import { ManageCookiesButton } from 'components/Page/Footer/Footer.styled';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const CookiesPT = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <MarginBox mt={15} />
      <Flex justify={'center'}>
        <Text type={'h5_bold'}>POLÍTICA DE COOKIES</Text>
      </Flex>
      <MarginBox mt={30} />
      <Paragraph>
        A proteção dos seus dados online é uma prioridade para a Renault. O nosso website utiliza tecnologias como
        cookies para lhe proporcionar uma experiência de alta qualidade, fluida e personalizada.
      </Paragraph>
      <Paragraph>
        Poderá reconsiderar, a qualquer momento, as suas escolhas em relação aos cookies, utilizando a nossa ferramenta
        de gestão de cookies:{' '}
        <ManageCookiesButton $color={'brand_alt'}>
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            GERIR AS MINHAS ESCOLHAS
          </button>
        </ManageCookiesButton>
        .
      </Paragraph>
      <Section>
        <SectionHeader title={'1. QUEM SOMOS?'} />
        <Paragraph>
          Bem-vindo ao site{' '}
          <Link to={'https://rpartstore.renault.com'} external newTab>
            https://rpartstore.renault.com,
          </Link>{' '}
          publicado pela Renault SAS. A Renault SAS é responsável independente pelo tratamento dos dados recolhidos
          pelos cookies e outros rastreadores lidos ou colocados no seu dispositivo (doravante &quot;nós&quot;,
          &quot;nosso&quot;, &quot;nossos&quot;).
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'2. O QUE SÃO COOKIES?'} />
        <Paragraph>
          Um cookie consiste num pequeno ficheiro informático, um rastreador, depositado e lido aquando da consulta de
          um website, no uso de uma aplicação para dispositivos móveis, da leitura de um email, da instalação ou
          utilização de um software, independentemente do tipo de equipamento utilizado (computador, smartphone, leitor
          de livros digitais, consola de videojogos ligada à Internet, etc.).
        </Paragraph>
        <Paragraph>
          Quando consulta um website, os cookies podem enviar informações ao navegador do internauta e permitir que esse
          navegador envie informações ao website de origem (por exemplo, o ID da sessão ou a escolha de um idioma).
        </Paragraph>
        <Paragraph>
          Os cookies não permitem identificá-lo diretamente, mas apenas recolher informações específicas sobre o seu
          dispositivo, navegador, interações com o website ou a aplicação que está a usar e, em alguns casos, a sua
          localização geográfica.
        </Paragraph>
        <Paragraph>
          As tecnologias usadas podem conectar vários dos dispositivos que possa ter usado para determinar, com
          probabilidade suficiente, que esses dispositivos efetivamente lhe pertencem.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'3. PARA QUE SÃO UTILIZADOS OS COOKIES NESTE WEBSITE?'} />
        <Paragraph>Este website utiliza diferentes tipos de cookies.</Paragraph>
        <SectionHeader title={'Cookies de funcionamento'} />
        <Paragraph>
          Estes cookies têm por objetivo permitir ou facilitar a comunicação por via eletrónica (deteção dos erros de
          ligação, identificação dos pontos de ligação, segurança do website, etc.) ou fornecer serviços de comunicação
          online por sua solicitação expressa (preferências de exibição no ecrã, memorização das informações
          introduzidas nos formulários, carrinho de compras (se aplicável), acesso à sua conta de utilizador, etc.).
        </Paragraph>
        <Paragraph>Estes cookies são necessários para o bom funcionamento do nosso website.</Paragraph>
        <Paragraph>
          A utilização destes cookies não pode ser recusada e o seu consentimento não é necessário, dado que os serviços
          no nosso website dependem deste tipo de cookies.
        </Paragraph>
        <SectionHeader title={'Cookies analíticos'} />
        <Paragraph>
          Estes cookies destinam-se a analisar o tráfego do sítio Web, a analisar a experiência do utilizador ou
          simplesmente a recolher sinais agregados sobre o fluxo de visitas no sítio (por exemplo, escolha da língua,
          página anterior visitada, carimbo de data/hora da visita, escolha de cookies).
        </Paragraph>
        <Paragraph>
          Estes cookies podem tratar dados relativos às páginas visitadas, ao tempo passado nas páginas visitadas, ao
          navegador que utiliza para visitar essas páginas, à utilização do chat ou do chatbot no nosso sítio Web, etc.
        </Paragraph>
        <Paragraph>
          Os dados recolhidos por estes cookies podem também ser utilizados para criar algoritmos e perfis para analisar
          os seus potenciais interesses e preferências relativamente aos nossos produtos e serviços. Podemos combinar
          estes dados com outras informações, tais como informações da nossa base de dados de clientes.
        </Paragraph>
        <Paragraph>
          A utilização e o armazenamento destes cookies estão geralmente sujeitos ao seu consentimento.
        </Paragraph>
        <Paragraph>
          Em determinadas condições, estes cookies podem ser colocados/lidos sem o seu consentimento, por exemplo, se o
          objetivo dos cookies for apenas analisar os conteúdos que visitou.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'4. QUAIS SÃO COOKIES UTILIZADOS NO NOSSO WEBSITE?'} />
        <Paragraph>
          A tabela abaixo permite-lhe visualizar a lista de todos os parceiros de cookies utilizados por este website.
          Na medida do possível, estes cookies são alojados em servidores localizados no Espaço Económico Europeu (EEE).
          Todavia, caso algum dos nossos prestadores de serviços esteja localizado num país fora do EEE, os seus dados
          são tratados nesse país, que poderá ter uma legislação diferente da legislação da União Europeia aplicável em
          matéria de dados pessoais. Nesse caso, temos especial atenção para que essa transferência seja realizada em
          conformidade com a legislação aplicável e instituímos garantias que asseguram um nível de proteção da sua
          privacidade e dos seus direitos fundamentais equivalente ao proporcionado pela União Europeia (nomeadamente,
          através da utilização de cláusulas contratuais-tipo da Comissão Europeia).
        </Paragraph>
        <Paragraph>
          O nosso website pode conter cookies emitidos por terceiros (agência de comunicação, empresa de medições de
          audiências, etc.) e que lhes permitem, durante o prazo de validade desses cookies, recolher informações de
          navegação relativas aos equipamentos que consultam o nosso website. A emissão e a utilização de cookies por
          terceiros estão sujeitas às políticas de proteção de privacidade dos terceiros em causa. O nosso website
          contém informações sobre a finalidade dos cookies de terceiros.
        </Paragraph>
        <Table
          pagination={false}
          rowKey={(record) => record.id}
          columns={[
            {
              title: 'Categoria',
              dataIndex: 'guideline',
              key: 'guideline',
              render: (guideline) => (
                <Link to={guideline} external newTab>
                  EMITIDO POR RENAULT
                </Link>
              ),
            },
            { title: 'Finalidade', dataIndex: 'type', key: 'type' },
            {
              title: 'Proprietário do cookie',
              dataIndex: 'site',
              key: 'site',
            },
          ]}
          dataSource={[
            {
              id: 0,
              site: 'ONE TRUST',
              type: 'FUNCIONAL',
              guideline: 'https://www.onetrust.com/fr/cookie-policy/',
            },
            {
              id: 1,
              site: 'RENAULT',
              type: 'FUNCIONAL',
              guideline: 'https://www.renault.fr/cookies.html',
            },
            {
              id: 2,
              site: 'MATOMO',
              type: 'ANÁLISE',
              guideline: 'https://matomo.org/privacy-policy/',
            },
            {
              id: 3,
              site: 'DYNATRACE',
              type: 'ANÁLISE',
              guideline: 'https://www.dynatrace.com/company/trust-center/privacy/',
            },
            {
              id: 4,
              site: 'CONTENSQUARE',
              type: 'ANÁLISE',
              guideline: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
            },
          ]}
        />
      </Section>
      <Section>
        <SectionHeader title={'5. COMO É RECOLHIDO O SEU CONSENTIMENTO?'} />
        <Paragraph>
          Ao visitar pela primeira vez o nosso website, é-lhe proposto que aceite, recuse ou configure as suas escolhas
          relativas à utilização de cookies, através da exibição de uma barra de notificação específica. Ao aceitar
          expressamente esta opção, os cookies serão aplicados em sua navegação. Depois de autorizar o uso de cookies, o
          usuário pode sempre desativá-los total ou parcialmente.
        </Paragraph>
        <Paragraph>
          Pode retirar o seu consentimento a qualquer momento ou alterar as suas escolhas (por exemplo, aceitando apenas
          determinados tipos de cookies) com a nossa ferramenta dedicada:{' '}
          <ManageCookiesButton $color={'brand_alt'}>
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              GERIR AS MINHAS ESCOLHAS
            </button>
          </ManageCookiesButton>
          .
        </Paragraph>
        <Paragraph>
          Informamos que, de acordo com a legislação aplicável, os cookies necessários para o funcionamento do website
          não estão sujeitos ao seu consentimento e, portanto, não podem ser desativados na nossa ferramenta de gestão
          de cookies. Pode, eventualmente, impedir a sua utilização alterando as definições do seu navegador (tal como
          abaixo indicado na rubrica «como recusar cookies»), mas a qualidade da sua experiência no website poderá ser
          prejudicada.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'6. POR QUANTO TEMPO É ARMAZENADO O SEU CONSENTIMENTO?'} />
        <Paragraph>
          Quando aceita ou recusa total ou parcialmente os cookies no nosso website, a sua escolha é guardada por um
          período máximo de 6 (seis) meses. Isto significa que quando aceder posteriormente ao nosso website, durante
          este período, não solicitaremos qualquer ação da sua parte relacionada com os cookies, uma vez que teremos um
          registo das suas preferências guardadas.
        </Paragraph>
        <Paragraph>
          Decorrido este período, perguntar-lhe-emos novamente se aceita ou recusa ou ser-lhe-á solicitado que configure
          as suas escolhas sobre a utilização de cookies.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'7. COMO PODE GERIR OS COOKIES?'} />
        <SectionHeader title={'Como pode recusar os cookies?'} underline />
        <Paragraph>
          Se não desejar que sejam utilizados e/ou colocados cookies no seu dispositivo através deste website, será
          armazenado no seu equipamento um cookie de recusa, para que possamos registar a informação de que se opôs à
          utilização, parcial ou total, de cookies. Se eliminar este cookie de recusa, deixará de ser possível
          reconhecer que recusou a utilização de cookies (o que significa que necessitará de definir novamente as suas
          preferências, da próxima vez que visitar o nosso website).
        </Paragraph>
        <Paragraph>
          Dispõe de várias opções para a gestão dos cookies e, nomeadamente, pode opor-se à respetiva utilização e/ou
          eliminá-los.{' '}
        </Paragraph>
        <SectionHeader title={'Ferramenta de gestão das escolhas'} underline />
        <Paragraph>
          Colocamos à sua disposição uma ferramenta de gestão das escolhas dos cookies para lhe permitir gerir os
          cookies utilizados e/ou colocados no presente website. A qualquer momento, se clicar na hiperligação abaixo,
          pode aceder ao configurador e alterar as suas preferências, em função do tipo de cookie em causa.
        </Paragraph>
        <SectionHeader title={'Parametrizações do navegador'} underline />
        <Paragraph>Tem igualmente a possibilidade de gerir os cookies através do seu navegador.</Paragraph>
        <Paragraph>
          Embora a maioria dos navegadores esteja parametrizada para, por defeito, aceitar a colocação de cookies, o
          utilizador pode, se assim o desejar, optar por aceitar todos os cookies, rejeitá-los sistematicamente ou,
          ainda, escolher aqueles que aceita consoante o respetivo emissor. Também pode eliminar regularmente os cookies
          do seu equipamento através do seu navegador.
        </Paragraph>
        <Paragraph>
          No entanto, não se esqueça de parametrizar todos os navegadores dos seus diversos equipamentos (tablets,
          smartphones, computadores, etc.).
        </Paragraph>
        <Paragraph>
          Cada navegador tem uma configuração diferente para gerenciar cookies e suas escolhas. É descrito no menu de
          ajuda do seu navegador, que lhe dirá como editar as suas definições de cookies Por exemplo:
        </Paragraph>
        <Paragraph align={'start'}>
          <Ul>
            <Li>
              para o Internet Explorer™:{' '}
              <Link external newTab to={'https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies '}>
                https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
              </Link>{' '}
              ;
            </Li>
            <Li>
              para o Safari™:{' '}
              <Link external newTab to={' https://support.apple.com/fr-fr/safari'}>
                https://support.apple.com/fr-fr/safari
              </Link>{' '}
              ;
            </Li>
            <Li>
              para o Chrome™:{' '}
              <Link external newTab to={'https://support.google.com/chrome/answer/95647?hl=en&hlrm=en'}>
                https://support.google.com/chrome/answer/95647?hl=en&hlrm=en
              </Link>{' '}
              ;
            </Li>
            <Li>
              para o Firefox™:{' '}
              <Link
                external
                newTab
                to={
                  'https://support.mozilla.org/pt-PT/kb/ativar-desativar-cookies-websites-utilizam-monitorizar-preferencias'
                }
              >
                https://support.mozilla.org/pt-PT/kb/ativar-desativar-cookies-websites-utilizam-monitorizar-preferencias
              </Link>{' '}
              ;
            </Li>
            <Li>
              para o Opera™:{' '}
              <Link external newTab to={'https://help.opera.com/Windows/10.20/fr/cookies.html'}>
                https://help.opera.com/Windows/10.20/fr/cookies.html.
              </Link>
            </Li>
          </Ul>
        </Paragraph>
        <SectionHeader title={'Módulos de oposição de editores'} underline />
        <Paragraph>
          Pode optar por desativar determinados cookies de terceiros, acedendo diretamente à página do emissor. Assim:
        </Paragraph>
        <Paragraph align={'start'}>
          <Ul>
            <Li>
              Para desativar os cookies do Google Analytics, aceda à página:{' '}
              <Link external newTab to={'https://tools.google.com/dlpage/gaoptout?hl=fr'}>
                https://tools.google.com/dlpage/gaoptout?hl=fr
              </Link>{' '}
              ;
            </Li>
            <Li>
              Para desativar os cookies intelliAd, aceda à página:{' '}
              <Link external newTab to={'https://login.intelliad.com/optout.php'}>
                https://login.intelliad.com/optout.php
              </Link>{' '}
              ;
            </Li>
            <Li>
              Para desativar os cookies da Adobe, aceda à página:{' '}
              <Link external newTab to={'https://www.adobe.com/uk/privacy/opt-out.html'}>
                https://www.adobe.com/uk/privacy/opt-out.html
              </Link>{' '}
              ;
            </Li>
          </Ul>
        </Paragraph>
        <SectionHeader title={'Plataformas para oposição de marketing'} underline />
        <Paragraph>
          Várias plataformas profissionais de publicidade permitem-lhe igualmente recusar ou aceitar os cookies
          utilizados pelas empresas que são membros dessas plataformas. Estes mecanismos centralizados não impedem a
          apresentação de anúncios, mas apenas bloqueiam a instalação de cookies que permitem que os anúncios sejam
          adaptados aos seus interesses.
        </Paragraph>
        <Paragraph>
          Por exemplo, pode aceder ao Website www.youronlinechoices.com para impedir a instalação de cookies no seu
          dispositivo. Este site é alojado pelos profissionais da publicidade digital da associação europeia EDAA
          (European Digital Advertising Alliance) e gerido em Portugal pela Auto Regulação Publicitária.
        </Paragraph>
        <Paragraph>
          Este permite-lhe saber quais as empresas registadas nesta plataforma e que lhe permitem recusar ou aceitar os
          cookies utilizados por estas empresas para personalizar os anúncios que podem ser apresentados no seu
          dispositivo com base nas informações do seu navegador:{' '}
          <Link to={'https://www.youronlinechoices.com/pt/your-ad-choices'} external newTab>
            https://www.youronlinechoices.com/pt/your-ad-choices
          </Link>{' '}
        </Paragraph>
        <Paragraph>
          Esta plataforma europeia é partilhada por centenas de profissionais de publicidade na Internet e fornece uma
          interface centralizada para recusar ou aceitar cookies que podem ser utilizados para personalizar os anúncios
          que podem ser apresentados no seu dispositivo com base na experiência de navegação do seu dispositivo.
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'8. COMO PODE EXERCER OS SEUS DEMAIS DIREITOS?'} />
        <Paragraph>
          De acordo com a legislação aplicável sobre proteção de dados de carácter pessoal, beneficia do direito de
          acesso, retificação, oposição, limitação, portabilidade e eliminação dos dados pessoais que lhe digam
          respeito, bem como definição de diretrizes em caso de morte. Para exercer estes direitos, pode consultar as
          nossas informações sobre a proteção dos seus dados pessoais, contactando-nos aqui{' '}
          <Link to={'mailto:dpo@renault.com'} external>
            dpo@renault.com
          </Link>
          .
        </Paragraph>
      </Section>
      <Section>
        <SectionHeader title={'9. ALTERAÇÕES'} />
        <Paragraph>
          Ocasionalmente, poderemos ter de alterar a presente política. Sempre que seja necessário ou exigido,
          informá-lo-emos e/ou solicitaremos o seu consentimento. Assim, convidamo-lo a consultar as informações a cada
          visita, para conhecer a sua mais recente versão.
        </Paragraph>
      </Section>
      <Flex justify={'flex-end'}>
        <Text type={'text'}>Última atualização: agosto de 2024</Text>
      </Flex>
      <MarginBox mt={90} />
    </Container>
  );
};
