import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, MarginBox, Text } from 'UI';
import { textFormatter } from 'utils/format';

export interface DetailsSectionLineProps {
  sectionName: string;
  totalVatExcl?: number;
  vat?: number;
  currency?: string;
}

const DetailsSectionLine = ({ sectionName, totalVatExcl, vat, currency }: DetailsSectionLineProps) => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Flex size={4}>
        <Text type={'section_bold'}>{sectionName}</Text>
      </Flex>
      <Flex />
      <Flex size={6}>
        <Flex justify={'flex-end'}>
          <Text type={'light_14_black_45'}>{t('common.price.vat_excl_price.short', 'VAT excl. :')}</Text>
        </Flex>
        <Flex justify={'flex-end'}>
          <Text type={'light_14_black_45'}>{textFormatter.formatCurrency(totalVatExcl ?? 0, currency)}</Text>
        </Flex>
      </Flex>
      <MarginBox mr={30} />
      <Flex size={4}>
        <Flex justify={'flex-start'}>
          <Text type={'light_14_black_45'}>{t('common.price.vat', 'VAT:')}</Text>
        </Flex>
        <Flex justify={'flex-end'}>
          <Text type={'light_14_black_45'}>{textFormatter.formatCurrency(vat ?? 0, currency)}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DetailsSectionLine;
