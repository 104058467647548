import { allLanguages, Language, webLanguages } from 'utils/i18n/Language';
import { storeLoad } from 'utils/storage';

export function detectStoredLanguage(
  languages: Array<Language>,
  storeKey: string,
  storeProperty: string,
): Language | null {
  const storedLang = storeLoad(storeKey);
  return storedLang?.[storeProperty] && Language.findByKey(languages, storedLang?.[storeProperty]);
}

export function getBrowserLocale(): string {
  const browserLanguage =
    navigator?.languages[0] || // Chrome / Firefox
    navigator?.language; // All browsers; // IE <= 10

  // format for i18next locale
  const locale = browserLanguage.replace('_', '-');
  const localeParts = locale.split('-');

  const knownLanguage =
    localeParts.length === 1
      ? Language.findByKey(allLanguages, localeParts.at(0) ?? '')
      : Language.findByLocale(allLanguages, locale);

  return knownLanguage ? knownLanguage.locale : locale;
}

export function getBrowserLanguage(): Language | undefined {
  return Language.findByLocale(allLanguages, getBrowserLocale());
}

export function getWebLanguageFromBrowser(): Language | undefined {
  return Language.findByLocale(webLanguages, getBrowserLocale());
}
