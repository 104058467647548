/* eslint-disable max-len */
import { GetMaintenanceValuesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_values/request/GetMaintenanceValuesRequest';
import { GET_MAINTENANCE_VALUES_URL } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

/* eslint-enable max-len */

export function sendMaintenanceValuesRequest(request: GetMaintenanceValuesRequest): WebSocketAction {
  return wsSendAction(GET_MAINTENANCE_VALUES_URL, request);
}
