import styled from 'styled-components';

export const SubstituteTitle = styled.div`
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.color.clear_blue};
  padding: 10px 25px 10px 25px;
  margin: 0 0 15px 0;
`;

export const SubstituteProducts = styled.div`
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 10px 25px 10px 25px;
  margin: -15px 0 5px 0;
`;

export const SubstituteFoot = styled.div`
  margin: 30px 0 19px 0;
`;
