import React from 'react';
import { RefText } from 'pages/TermsAndConditionsPage/versions/TermsAndConditions.styled';
import { Container, Divider, Flex, Image, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';

export const TermsAndConditionsPL = () => {
  const focusOnRef = (refId: string) => {
    const ref = document.getElementById(refId);
    scrollToTopSmooth(ref?.offsetTop);
  };
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>OGÓLNE WARUNKI UŻYTKOWANIA</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Niniejsze Ogólne warunki korzystania z platformy internetowej (w dalszej części „Warunki Ogólne”) regulują
            zasady dostępu oraz korzystania z serwisu RPARTSTORE udostępnionego przez spółkę Renault SAS z kapitałem
            zakładowym w wysokości 533 941 113 EUR, z siedzibą pod adresem 122–122 bis avenue du Général Leclerc – 92100
            Boulogne-Billancourt, wpisaną do rejestru handlowego w Nanterre pod numerem 780 129 987 (zwaną dalej „
            <b>RENAULT</b>”).
          </Paragraph>
          <Paragraph>
            Użytkownik ma obowiązek uważnie zapoznać się z niniejszymi warunkami zamieszczonymi na stronie internetowej
            przed ich zaakceptowaniem i uzyskaniem dostępu do usług.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 1.'} title={'Oznaczenie platformy internetowej'} />
          <Paragraph>
            Strona internetowa RPARTSTORE (zwana dalej „<b>Platformą</b>”) została założona w celu ułatwienia
            nawiązywania kontaktów między sprzedającymi (w dalszej części „<b>Sprzedający</b>”), którzy chcą za
            pośrednictwem Platformy oferować do sprzedaży części zamienne i akcesoria (w dalszej części „<b>Produkty</b>
            ”) przeznaczone dla kupujących (w dalszej części „<b>Kupujący</b>”), zgodnie z niniejszymi Ogólnymi
            warunkami korzystania z platformy (w dalszej części „<b>Warunki Ogólne</b>”).
          </Paragraph>
          <Paragraph>
            Usługi w ramach Platformy są dostępne pod następującym adresem internetowym:{' '}
            <Link to={'https://rpartstore.pl'} external newTab>
              rpartstore.pl
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 2.'} title={'Zasady ogólne'} />
          <Paragraph>
            Platforma działa jako techniczny pośrednik pomiędzy stronami. Oferuje Sprzedającym i Kupującym („
            <b>Użytkownicy</b>”) usługi techniczne w zakresie przechowywania i przesyłu danych w rozumieniu przepisów
            prawa.
          </Paragraph>
          <Paragraph>
            Rola Platformy jest czysto techniczna i ogranicza się do ułatwienia nawiązywania kontaktów między
            Sprzedającymi a Kupującymi.
          </Paragraph>
          <Paragraph>
            RENAULT nie udziela żadnych gwarancji ani nie ponosi żadnej odpowiedzialności (z wyłączeniem
            odpowiedzialności nałożonej na nią bezpośrednio przez przepisy prawa) z tytułu zawieranych umów, transakcji,
            jakość handlową (w tym ich oznakowanie), bezpieczeństwo czy też zgodność z prawem Produktów, prezentowania
            ofert lub informacji różnego rodzaju, które mogą mieć miejsce za pośrednictwem Platformy.
          </Paragraph>
          <Paragraph>
            W żadnym razie RENAULT nie występuje jako sprzedający ani odsprzedający Produkty prezentowane przez
            Sprzedających za pośrednictwem Platformy.
          </Paragraph>
          <Paragraph>
            Transakcje dotyczące Produktów oferowanych przez Sprzedającego są zawsze zawierane bezpośrednio pomiędzy
            Sprzedającym a Kupującym, poza Platformą. Sprzedający może po prostu generować kontakty handlowe (
            <i>lead form</i>) za pośrednictwem Platformy. Cenę końcową Produktów Sprzedający podaje poza Platformą.
          </Paragraph>
          <Paragraph>Sprzedający są przedsiębiorcami w rozumieniu przepisów prawa.</Paragraph>
          <Paragraph>
            RENAULT, w ramach Platformy, nie stosuje plasowania Produktów ani Sprzedających. Produkty są wyświetlane
            zgodnie z okresem ich utworzenia. Kupujący ma możliwość samodzielnego filtrowania według dostępnych w
            Platformie kategorii Produktów.
          </Paragraph>
          <Paragraph>
            REANULT, jako dostawca Platformy, ponosi odpowiedzialność za prawidłowe funkcjonowanie Platformy oraz usług,
            które RENAULT świadczy na rzecz Użytkowników.
          </Paragraph>
          <Paragraph>
            W pozostałym zakresie odpowiedzialność w stosunku do Kupujących ponosi Sprzedający. Sprzedający ponosi
            odpowiedzialność w szczególności za:
            <Ul type={'lower-alpha'}>
              <Li>
                <Text type={'text'}>
                  prawidłowość i zgodność z obowiązującymi przepisami prawa informacji o Produktach, które sprzedaje za
                  pośrednictwem Platformy, w tym w zakresie komunikowania cen,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  zapewnienie odpowiedniej jakości handlowej i bezpieczeństwa Produktów, które sprzedaje za
                  pośrednictwem Platformy,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>spełnianie wymogów wskazanych w obowiązujących przepisach prawa,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  spełnienia wymogów wynikających z ustawy o prawach konsumenta, w tym w szczególności w zakresie
                  prawidłowego informowania konsumentów o przysługujących im uprawnieniach wynikających z tej ustawy.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 3.'} title={'Przedmiot warunków ogólnych'} />
          <Paragraph>
            Niniejsze Warunki Ogólne mają na celu określenie zasad oraz warunków dostępu i korzystania z Platformy przez
            Użytkowników.
          </Paragraph>
          <Paragraph>
            Celem Platformy jest tworzenie bezpośredniego kontaktu internetowego pomiędzy Sprzedającymi a Kupującymi,
            aby nawiązywali między sobą relacje handlowe.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 4.'} title={'Warunki dostępu do Platformy dla Kupujących '} />
          <Paragraph>
            Uzyskanie dostępu do Platformy wymaga następujących czynności:
            <Ul>
              <Li>
                <Text type={'text'}>
                  złożenie wniosku przez Sprzedającego o przystąpienie Kupującego za pośrednictwem strony rejestracji
                  obsługującej wnioski o przystąpienie
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>zatwierdzenie przez Sprzedającego dostępu Kupującego do Platformy</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Strona rejestracji Mini-Site jest aplikacją e-business umożliwiającą Sprzedającym samodzielne składanie
            wniosków o przystąpienie do RPARTSTORE dla swoich klientów – Kupujących. Aplikacja jest dostępna z
            RENAULTNET oraz na stronie RPARTSTORE.
          </Paragraph>
          <Paragraph>
            Dostęp do strony rejestracji jest udzielany Sprzedającemu, na zasadach określonych w odrębnych
            porozumieniach Sprzedających z Renault.
          </Paragraph>
          <Paragraph>
            Strona rejestracji Mini-Site pozwala przyspieszyć proces przystąpienia, a także umożliwia Sprzedającym
            składanie wniosków o przystąpienie dla swoich klientów, bez względu na status użytkownika w bazie TRESOR
            oraz w RPARTSTORE (Klient nie figuruje w bazie TRESOR, ponowne przystąpienie, nowy użytkownik).
          </Paragraph>
          <Paragraph>
            Sprzedający może składać wnioski o przystąpienie dla swoich klientów zgodnie z postanowieniami paragrafu 4
            powyżej. Jeżeli jego klient nie zostanie zidentyfikowany w bazie TRESOR, wniosek o utworzenie/zmianę
            zostanie automatycznie wysłany do odpowiedniego administratora bazy TRESOR.
          </Paragraph>
          <Paragraph>
            Sprzedający może zmienić dane kontaktowe klienta oraz śledzić postępy rozpatrywania złożonych wniosków o
            przystąpienie. Nie wolno mu modyfikować wniosku o przystąpienie, którego rozpatrywanie już się rozpoczęło,
            ale może zażądać zakończenia postępowania.
          </Paragraph>
          <Paragraph>
            Do korzystania z Platformy niezbędne jest posiadanie przez Użytkownika komputera lub innego urządzenia
            końcowego (tablet, smartfon itd.) z dostępem do sieci Internet, dowolnej przeglądarki internetowej z obsługą
            JavaScript oraz aktywnego konta e-mail przez Użytkownika.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 5.'} title={'Produkty dopuszczone do sprzedaży na Platformie'} />
          <Paragraph>
            Kupujący nie mogą prosić o ofertę ani wymagać od Sprzedawców oferowania za pośrednictwem Platformy produktów
            innych niż te, które Platforma wyświetla.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 6.'} title={'Założenie konta Kupującego – Poufny charakter loginów i haseł'} />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Etapy:
            </Text>
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  Rozpatrzenie wniosku rejestracji nowego klienta przez Sprzedającego w aplikacji Mini-site trwa od 24
                  do 72 godzin.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Następnie Kupujący otrzyma mailem informacje (login/hasło) niezbędne do zalogowania się do RPARTSTORE.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Sprzedający otrzyma również wiadomość z informacją, że od tej pory jego klient będzie miał dostęp do
                  RPARTSTORE.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>Konto kupującego zakładane jest na dane wskazane Sprzedającemu.</Paragraph>
          <Paragraph>
            Kupujący zobowiązuje się zachować w tajemnicy swoje loginy i hasła oraz nie ujawniać ich nikomu w żadnej
            formie, a w przypadku nielegalnego lub nieuprawnionego użycia zobowiązuje się unieważnić podjęte przez
            podmiot trzeci czynności i bezzwłocznie poinformować o wszystkim Sprzedającego . Kupujący został
            poinformowany, że ujawnienie jego loginu i hasła osobie trzeciej odbywa się na jego wyłączną
            odpowiedzialność.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 7.'} title={'Kontakty spersonalizowane'} />
          <Paragraph>
            Kupujący nie mogą zwracać się o ofertę do wszystkich Sprzedających działających na Platformie.
          </Paragraph>
          <Paragraph>
            Kupujący może korzystać z ofert i nawiązywać kontakty handlowe za pośrednictwem Platformy z tym
            Sprzedającym, który go zgłosił na Platformę i z którym wiążą go stosunki handlowe także w zwykłej formie
            komunikacji.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 8.'} title={'Płatności'} />
          <Paragraph>
            Za pośrednictwem Platformy można prowadzić wyłącznie kontakty handlowe (rezerwacje Produktów), jednakże
            żadne płatności nie będą się odbywać za pośrednictwem Platformy.
          </Paragraph>
          <Paragraph>
            Korzystanie z Platformy jest nieodpłatne, ale może wiązać się z potrzebą nawiązania połączenia
            internetowego, które z kolei może być związane z koniecznością poniesienia kosztów połączenia z siecią
            Internet (opłata za przesyłanie danych), zgodnie ze stawką ustaloną przez operatora sieci dostarczającego tę
            usługę.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 9.'} title={'Dostępność usług Platformy'} />
          <Paragraph>
            W granicach właściwych przepisów prawa RENAULT nie udziela żadnej gwarancji – ani wprost, ani w sposób
            dorozumiany – w odniesieniu do szybkości i/lub wydajności Platformy, ani za użytek, jaki Klient zrobi z
            danych i/lub informacji dostępnych za pośrednictwem Platformy.
          </Paragraph>
          <Paragraph>
            W szczególności RENAULT nie gwarantuje, że Platforma nie będzie narażona na uszkodzenia lub ataki, zwłaszcza
            ataki wirusów, które mogą spowodować nieprawidłowe działanie, przerwy w świadczeniu usług, awarie albo
            utratę danych lub informacji.
          </Paragraph>
          <Paragraph>
            W każdym czasie dostęp do Platformy może zostać chwilowo i okresowo wstrzymany lub przerwany z powodu
            problemów technicznych. Nasi technicy dołożą starań, aby usunąć je możliwie jak najszybciej.
          </Paragraph>
          <Paragraph>
            RENAULT nie ponosi żadnej odpowiedzialności w przypadku:
            <Ul>
              <Li>
                <Text type={'text'}>nieprzestrzegania przez Użytkownika niniejszych Warunków Ogólnych;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  nieprawidłowego działania Platformy z przyczyn wywołanych umyślnie lub nieumyślnie, leżących po
                  stronie Użytkownika lub osoby trzeciej, lub w wyniku złośliwego działania //ataku hackerskiego albo
                  nieprawidłowego działania oprogramowania, smartfona, tabletu PC lub jakiegokolwiek innego
                  interfejsu/produktu należącego do Użytkownika;
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Ponieważ dostęp do Platformy może być zapewniony przez sieci łączności, Użytkownik uznaje ponadto, że
            RENAULT nie może w żadnym razie ponosić odpowiedzialności w przypadku nieprawidłowego działania wspomnianych
            sieci łączności Użytkownika.
          </Paragraph>
          <Paragraph>
            Użytkownik zobowiązuje się, że podczas korzystania z Platformy będzie postępował zgodnie z niniejszymi
            Warunkami Ogólnymi i przestrzegał obowiązujących przepisów.
          </Paragraph>
          <Paragraph>
            Platforma umożliwia Kupującemu:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>korzystanie z założonego konta,</Text>
              </Li>
              <Li>
                <Text type={'text'}>przygotowywanie kosztorysów potrzebnych części,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  składanie zamówień u Sprzedawców, którzy wskazali Kupującego jako swojego klienta,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>przeglądanie historii zamówień i wyszukiwania.</Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 10.'} title={'Używanie Platformy'} />
          <Paragraph>
            Kupujący powinien w każdych okolicznościach powstrzymać się od wszelkich działań, które mogłyby wpłynąć
            negatywnie na działanie Platformy, na jej renomę i na renomę świadczonych przez nią usług oraz na renomę i
            działalność Renault Group.
          </Paragraph>
          <Paragraph>Użytkowników obowiązuje bezwzględny zakaz dostarczania treści o charakterze bezprawnym.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 11.'} title={'Intuitu personae'} />
          <Paragraph>
            Platforma założona przez Renault Group, przyczynia się do budowania wizerunku, renomy oraz rozpoznawalności
            Renault Group. Relacje pomiędzy Platformą a jej Użytkownikami opierają się zatem na zasadzie szczególnego
            osobistego zaufania.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 12.'} title={'Rezygnacja, blokada dostępu lub usunięcie z Platformy'} />
          <Text type={'text_bold'}>a) Rezygnacja</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Każdy Kupujący może w każdym czasie zawiadomić Platformę za pośrednictwem Sprzedającego, do którego jest
            przypisany, że nie będzie już korzystał z jej usług.
          </Paragraph>
          <Paragraph>
            Kupujący będzie musiał samodzielnie wywiązywać się z zobowiązań, zaciągniętych wobec Sprzedawców przed datą
            rezygnacji z uczestnictwa w Platformie.
          </Paragraph>
          <Text type={'text_bold'}>b) Blokada dostępu</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Platforma będzie mogła czasowo zablokować dostęp Kupującemu na okres nie dłuższy niż 6 miesięcy w
            następujących przypadkach:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  poważne podejrzenie o zachowania godzące w Platformę, naruszające niniejsze Warunki Ogólne,
                  obowiązujące przepisy prawa;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>używanie konta Kupującego przez osoby z zewnątrz, niezwiązane z Kupującym;</Text>
              </Li>
              <Li>
                <Text type={'text'}>niedopełnienie obowiązku nieujawniania loginów i haseł;</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            W tym czasie Kupujący oraz Platforma podejmą w dobrej wierze starania w celu wyeliminowania stwierdzonych
            nieprawidłowości.
          </Paragraph>
          <Text type={'text_bold'}>c) Usunięcie z Platformy</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Platforma będzie mogła ostatecznie usunąć Kupującego w następujących przypadkach:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  opuszczenie – niezależnie od przyczyny – sieci Renault administrowanej na Platformie;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  dopuszczenie się zachowań, o których mowa z punkcie b - 1) niniejszego paragrafu. Platforma może
                  wówczas wybrać między blokadą dostępu a natychmiastowym usunięciem, w zależności od wagi naruszenia;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  ponowne dopuszczenie się zachowań, o których mowa z punkcie b°) od 2) do 3) niniejszego paragrafu;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>powtarzające się, uzasadnione skargi ze strony Sprzedających.</Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 13.'} title={'Transakcje handlowe – Tajemnica przedsiębiorstwa'} />
          <Paragraph>
            Platforma nie może w żaden sposób pozwolić jakimkolwiek osobom trzecim (konkurencja, wspólnicy, partnerzy
            itp.) na uzyskanie wiedzy na temat wymiany informacji, negocjacji, umów i porozumień zawieranych lub
            prowadzonych za jej pośrednictwem. Platforma w każdej sytuacji będzie przestrzegać zasad ochrony poufności.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 14.'} title={'Promocja i reklama'} />
          <Paragraph>
            Kupujący zobowiązuje się nie używać Platformy, bezpośrednio ani pośrednio, w jakiejkolwiek formie, w celu
            promowania swoich produktów lub usług ani produktów lub usług innych podmiotów.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 15.'} title={'Oferty konkurencyjne – Brak wyłączności'} />
          <Paragraph>
            Przystąpienie do Platformy nie prowadzi do powstania po stronie Kupujących obowiązku korzystania z niej.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 16.'} title={'UMOWA O ŚWIADCZENIE USŁUG DROGĄ ELEKTRONICZNĄ'} />
          <Paragraph>
            Umowa o świadczenie usług drogą elektroniczną zawierana jest z chwilą skorzystania z usług udostępnionych na
            Platformie i trwa do czasu ich realizacji.
          </Paragraph>
          <Paragraph>
            Użytkownik będący konsumentem
            <RefText id={'individual-ref'} onClick={() => focusOnRef('individual')}>
              &sup1;
            </RefText>
            , który korzysta z usług Platformy, ma prawo do odstąpienia od zawartej umowy w terminie 14 dni od jej
            zawarcia bez podania jakiejkolwiek przyczyny. Do zachowania powyższego terminu wystarczy wysłanie
            jednoznacznego oświadczenia przed jego upływem.
          </Paragraph>
          <Paragraph>
            W celu odstąpienia od umowy Użytkownik może wykorzystać Formularz odstąpienia stanowiący załącznik nr 2 do
            ustawy z dnia 30 maja 2014 r. o prawach konsumenta (t.j. Dz. U. z 2020 r. poz. 287 ze zm.).
          </Paragraph>
          <Paragraph>
            Prawo odstąpienia nie przysługuje Użytkownikowi będącemu Konsumentem w odniesieniu do Umowy, jeżeli RENAULT
            wykonał w pełni Usługę za wyraźną zgodą Konsumenta, który został poinformowany przed rozpoczęciem
            świadczenia, że po spełnieniu świadczenia przez RENAULT utraci prawo odstąpienia od Umowy oraz umów o
            dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia
            rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu
            go przez RENAULT o utracie prawa odstąpienia od umowy, a także w przypadkach wskazanych w art. 38 ustawy z
            dnia 30 maja 2014 r. o prawach konsumenta (t.j. Dz. U. z 2020 r. poz. 287 z późn. zm.).
          </Paragraph>
          <Divider />
          <Paragraph type={'light_12'}>
            <RefText id={'individual'} onClick={() => focusOnRef('individual-ref')}>
              &sup1;
            </RefText>{' '}
            Osoba fizyczna dokonująca z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością
            gospodarczą lub zawodową oraz osoba fizyczna korzystająca z usług, które to usługi bezpośrednio związane są
            z jej działalnością gospodarczą, ale z treści stosunku wynika, że nie posiada ona dla tej osoby charakteru
            zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej
            udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 17.'} title={'ZMIANY W WARUNKACH OGÓLNYCH'} />
          <Paragraph>
            Platforma zastrzega sobie możliwość wprowadzania zmian do Warunków Ogólnych. Każda zmiana wywołuje skutek w
            dniu wskazanym w wiadomości dotyczącej wprowadzenia zmiany, przekazanej na koncie Kupującego, nie krótszym
            niż 14 dni od przesłania powiadomienia.
          </Paragraph>
          <Paragraph>
            Zmiany nie mają zastosowania do transakcji będących w toku realizacji w dniu wejścia w życie zmian (zmiany
            pozostają bez wpływu na prawa nabyte Użytkowników).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 18.'} title={'Wykorzystanie danych osobowych zbieranych przez Platformę '} />
          <Paragraph>
            W ramach korzystania z Platformy Twoje dane osobowe są przetwarzane przez RENAULT na warunkach
            wyszczególnionych w Nocie Informacyjnej dostępnej pod adresem:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 19.'} title={'Siła wyższa'} />
          <Paragraph>
            Żadne zdarzenie mające cechy siły wyższej, a którego konsekwencją będzie nieprawidłowe działanie Platformy,
            nie może powodować powstania odpowiedzialności po stronie RENAULT.
          </Paragraph>
          <Paragraph>
            Kupujący zgadza się również, że za działanie siły wyższej będą umownie uznawane zdarzenia z przyczyn
            zewnętrznych, których dany podmiot nie był w stanie przewidzieć ani im zapobiec, będących poza sferą jego
            kontroli, w tym w szczególności spowodowanych przez wojnę, pożar, powódź oraz inne katastrofy, ataki, które
            powstały bez udziału tego podmiotu.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 20.'} title={'Zachowanie poufności i dowody'} />
          <Paragraph>
            RENAULT wdraża odpowiednie środki techniczne w celu zapewnienia poufności danych i informacji przesyłanych
            za pośrednictwem Platformy lub negocjacji prowadzonych za pośrednictwem Platformy, w taki sposób, by nie
            były one przekazywane podmiotom zewnętrznym niezwiązanym z usługami Platformy.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 21.'} title={'Własność intelektualna'} />
          <Paragraph>
            Wszystkie elementy, takie jak logotypy (tekst), obrazy, elementy graficzne lub dźwiękowe, oprogramowanie,
            układ strony, bazy danych Platformy, nazwy firm, znaki towarowe, wzory i modele są objęte ochroną w postaci
            praw własności intelektualnej. Użytkownikom nie wolno dopuszczać się żadnych czynów naruszających
            którekolwiek z tych praw.
          </Paragraph>
          <Paragraph>
            Kupujący jest uprawniony do umieszczania na swoich dokumentach handlowych informacji o swoim statusie
            Kupującego zarejestrowanego na Platformie, pod warunkiem uprzedniego przedstawienia RENAULT przedmiotowych
            dokumentów.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 22.'} title={'Odpowiedzialność Kupującego'} />
          <Paragraph>
            Kupujący ponosi pełną odpowiedzialność za składane zamówienia na Produkty wyświetlane na Platformie.
          </Paragraph>
          <Paragraph>
            Kupujący ponosi również pełną odpowiedzialność za korzystanie ze swojego konta oraz za informacje przekazane
            Sprzedającym.
          </Paragraph>
          <Paragraph>
            Kupujący ponosi wyłączną odpowiedzialność za działania podejmowane w charakterze Kupującego.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 23.'} title={'Prawo właściwe – Rozwiązywanie sporów'} />
          <Paragraph>Niniejsze Warunki Ogólne podlegają wyłącznie prawu polskiemu.</Paragraph>
          <Paragraph>
            Wszelkie spory, niezależnie od powodu, czy to z tytułu odpowiedzialności kontraktowej czy deliktowej, mające
            przyczynę lub źródło w ważności, interpretacji, wykonaniu niniejszych Warunków Ogólnych lub, generalnie, w
            usługach oferowanych przez Platformę, lub w stosunkach między Sprzedającymi a Platformą, należą do
            właściwości właściwych sądów powszechnych.
          </Paragraph>
          <Paragraph>
            RENAULT informuje, że nie korzysta z polubownych metod rozwiązywania sporów konsumenckich.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'§ 24.'} title={'Reklamacje'} />
          <Paragraph>
            Użytkownicy mogą składać reklamacje dotyczące niewykonania lub nienależytego wykonania usług świadczonych za
            pomocą Platformy.
          </Paragraph>
          <Paragraph>
            Reklamacja może być złożona do RENAULT pisemnie na adres siedziby RENAULT Polska sp. z o.o. (ul. Marynarska
            13, 02-674 Warszawa), za pośrednictwem formularza dostępnego na:{' '}
            <Link to={'https://www.renault.pl/kontakt/reklamacja.html'} external newTab>
              https://www.renault.pl/kontakt/reklamacja.html
            </Link>{' '}
            lub na Infolinii RENAULT Polska sp. z o.o. pod numerem: 800-900-803, o ile nie stoją temu na przeszkodzie
            techniczne możliwości.
          </Paragraph>
          <Paragraph>
            W reklamacji zaleca się zawrzeć:
            <Ul type={'lower-alpha'}>
              <Li>
                <Text type={'text'}>
                  imię i nazwisko oraz adres zamieszkania Użytkownika albo nazwę oraz siedzibę Użytkownika,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>określenie przedmiotu reklamacji oraz ewentualnie reklamowanego okresu;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  przedstawienie okoliczności uzasadniających reklamację oraz załączenie wszelkich dokumentów oraz
                  informacji wykazujących zasadność roszczeń reklamacyjnych;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  podpis Użytkownika albo jego reprezentanta - w przypadku reklamacji złożonej w formie pisemnej. W
                  przypadku gdy reklamacja podpisywana jest przez reprezentanta Użytkownika, reprezentant ten obowiązany
                  jest wykazać swoje umocowanie przy wykorzystaniu stosownych dokumentów.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            W przypadku, gdy złożona reklamacja nie spełnia warunków określonych powyżej, RENAULT, o ile uzna, że jest
            to konieczne, wzywa Użytkownika do jej uzupełnienia, określając termin i zakres tego uzupełnienia.
          </Paragraph>
          <Paragraph>
            RENAULT udziela odpowiedzi na reklamację drogą elektroniczną w terminie 14 dni od dnia jej złożenia.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Aktualizacja: 30 maja 2023 roku</Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'}>ZAŁĄCZNIK I</Text>
        <MarginBox mt={15} />
        <Text type={'h5_bold'}>INFORMACJE DLA KUPUJĄCYCH – PROCEDURA UZYSKANIA DOSTĘPU</Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-PL.png'} alt={'information-diagram-polish'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
