import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { getCatalogIAMExplodedTree, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { MarginBox, PUBLIC, Text, URL } from 'UI';
import { getData } from 'utils';
import { CarPartGroupItemImg, SCarPartGroupsItem, SCarPartGroupsSection } from './CarPartGroupsSection.styled';

const CarPartGroupsSection = () => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleExplodedView = getData(useSelector((state: RootState) => getCatalogIAMExplodedTree(state, vehicleKey)));

  return (
    <>
      <SCarPartGroupsSection wrap={'wrap'}>
        {vehicleExplodedView?.data?.map((i) => (
          <CarPartGroupsItem item={i} key={i.nodeId} />
        ))}
      </SCarPartGroupsSection>
      <MarginBox mt={30} />
    </>
  );
};

export const carPartGroupsItemBaseUrlStart = 'https://provider.medias.parts-io.com/medias/images/genarts/';
export const carPartGroupsItemBaseUrlEnd = '.jpg';
export const carPartGroupsItemDefaultPicture = 'catalog/iam/default_iam_category.jpg';

interface CarPartGroupsItemProps {
  item: VehicleIAMPartCategoryTreeItem;
}

function CarPartGroupsItem({ item }: Readonly<CarPartGroupsItemProps>) {
  const history = useHistory();
  const pictureHeight = 300;
  const pictureWidth = 450;
  const params = new URLSearchParams(location.search);

  return (
    <SCarPartGroupsItem onClick={() => history.push(`.${ROUTER_CATALOG_VEHICLE}/${item.nodeId}?${params}`)}>
      <CarPartGroupItemImg
        type={URL}
        alt={'img'}
        src={carPartGroupsItemBaseUrlStart + item.nodeId + carPartGroupsItemBaseUrlEnd}
        fallbackComponent={
          <CarPartGroupItemImg
            type={PUBLIC}
            alt={'img'}
            src={carPartGroupsItemDefaultPicture}
            height={pictureHeight}
            width={pictureWidth}
          />
        }
        height={pictureHeight}
        width={pictureWidth}
      />
      <MarginBox mt={5} />
      <Text type={'h4_paragraph_title'} cursor={'pointer'} transform={'sentence-case'}>
        {item.label}
      </Text>
    </SCarPartGroupsItem>
  );
}

export default CarPartGroupsSection;
