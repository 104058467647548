import styled from 'styled-components';
import { Box, defaultBoxShadow, Flex } from 'UI';

export const BlackHalfCard = styled.button`
  height: 40px;
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.color.brand_black};
  border: none;
  padding: 7px 15px 7px 15px;
`;

export const SMenu = styled(Flex)`
  margin-top: 10px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 10px;
  ${defaultBoxShadow}
`;

export const SItem = styled.div<{ active?: boolean }>`
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  background-color: ${({ theme, active }) => (active ? theme.color.grey85 : theme.color.white)};

  :hover {
    background-color: ${({ theme }) => theme.color.grey95};
  }
`;

export const SBox = styled(Box)`
  overflow: hidden;
  text-overflow: ellipsis;
`;
