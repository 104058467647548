import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import { PlusIcon } from 'assets/icons';
import { initialCreateInformation, setCreateInformation } from 'domains/information/Information.store';
import { STATUS_ARCHIVED, STATUS_PLANNED, STATUS_PUBLISHED, STATUS_SAVED } from 'domains/information/Information.types';
import InformationTab from 'pages/BackOfficePage/BackOfficeCategories/Information/Information/InformationTab';
import { CREATE_NEW, INFORMATION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Flex, Icon, MarginBox, Tab, Tabs, TabsTitle, Text, YellowButton } from 'UI';

export const Information = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  function onCreateClicked() {
    dispatch(setCreateInformation(initialCreateInformation()));
    history.push(`${ROUTER_BACKOFFICE}/${INFORMATION}/${CREATE_NEW}`);
  }

  return (
    <Flex direction={'column'}>
      <Flex direction={'row-reverse'}>
        <YellowButton onClick={onCreateClicked}>
          <Flex align={'center'}>
            <Icon IconComponent={PlusIcon} width={15} height={15} />
            <MarginBox ml={10} />
            <Text type={'text'}>{t('announcement.actions.create_new', 'Create new')}</Text>
          </Flex>
        </YellowButton>
      </Flex>
      <Tabs>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.published', 'Published')}</TabsTitle>} key={'published'}>
          <InformationTab status={STATUS_PUBLISHED} />
        </Tab>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.planned', 'Planned')}</TabsTitle>} key={'planned'}>
          <InformationTab status={STATUS_PLANNED} />
        </Tab>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.saved', 'Saved')}</TabsTitle>} key={'saved'}>
          <InformationTab status={STATUS_SAVED} />
        </Tab>
        <Tab tab={<TabsTitle>{t('backoffice.assistance.archived', 'Archived')}</TabsTitle>} key={'archived'}>
          <InformationTab status={STATUS_ARCHIVED} />
        </Tab>
      </Tabs>
    </Flex>
  );
};
