import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import FetchBackend from 'i18next-fetch-backend';
import { TRANSLATIONS_PATH } from 'domains/user';
import { textFormatter } from 'utils/format';
import { fallbackLanguages, Language } from 'utils/i18n/Language';

export const defaultNamespace = 'translation';

export const AppTranslation = i18next;

export function hasTranslations(lang: string): boolean {
  const translations = AppTranslation.getResourceBundle(lang, defaultNamespace);
  return !!translations;
}

export function getFirstFallbackLanguage(): string {
  const foundFallback = AppTranslation.languages.find((lang) => {
    return hasTranslations(lang);
  });
  return foundFallback || AppTranslation.languages[AppTranslation.languages.length - 1];
}

export async function changeLanguage(lang: string): Promise<string> {
  if (!AppTranslation.isInitialized) {
    throw Error('AppTranslation: not initialized');
  } else {
    return AppTranslation.changeLanguage(lang)
      .then(() => {
        if (hasTranslations(lang)) {
          return Promise.resolve(lang);
        }

        const fallbackLanguage = getFirstFallbackLanguage();
        console.log(`AppTranslation: translations not found. Used fallback language ${fallbackLanguage}`);
        return Promise.resolve(fallbackLanguage);
      })
      .catch((reason) => {
        const errorMsg = 'AppTranslation: language change failed';
        console.error(errorMsg, reason);
        return Promise.reject(reason);
      });
  }
}

const TRANSLATIONS_DEBUG_OUTPUT = false;

export function initializeTranslationService(lang: string): void {
  // We don't use LanguageDetector as i18next instance is not source of lang information
  AppTranslation.use(initReactI18next)
    .use(FetchBackend)
    .init({
      debug: TRANSLATIONS_DEBUG_OUTPUT,
      lng: lang,
      defaultNS: defaultNamespace,
      fallbackLng: {
        ...fallbackLanguages,
        default: [Language.DEFAULT.key],
      },
      returnEmptyString: false,
      returnObjects: false,
      returnNull: false,
      saveMissing: false,
      backend: {
        loadPath: (l: string) => {
          // check the domain if needed
          const baseUrl = window.location.origin;
          return `${baseUrl}${TRANSLATIONS_PATH}/${l.toLowerCase()}.json`;
        },
        allowMultiLoading: false,
      },
      interpolation: {
        escapeValue: false,
        format: (value, format, _lng, options) => {
          // Format by type
          if (value instanceof Date) {
            switch (format) {
              case 'date_long':
                return textFormatter.formatDateLong(value);
              case 'date_short':
                return textFormatter.formatDateShort(value);
              default:
                return textFormatter.formatDate(value);
            }
          }

          // Custom format
          if (format) {
            switch (format) {
              case 'uppercase':
                return value.toUpperCase();
              case 'price':
                return textFormatter.formatCurrency(value, options?.currency);
              case 'number':
                return textFormatter.formatNumber(value, options?.currency);
              case 'amount_day':
                return textFormatter.formatAmount(value, 'day');
              case 'amount_hour':
                return textFormatter.formatAmount(value, 'hour');
              case 'date_relative_day':
                return textFormatter.formatDateRelative(value, 'day');
              case 'date_relative_hour':
                return textFormatter.formatDateRelative(value, 'hour');
              case 'percent':
                return textFormatter.formatPercent(value);
              default:
                console.warn(`AppTranslation: unsupported interpolation format ${format}`);
            }
          }
          return value;
        },
      },
      react: {
        bindI18n: 'loaded languageChanged',
        useSuspense: false,
      },
    });
}
