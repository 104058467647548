import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_BACKOFFICE, ROUTER_PROMOTION } from 'app/AppRouter';
import { getShowNotification, setShowPromotionNotification } from 'domains/promotion/Promotion.store';
import { PromotionTabFilterParam, PromotionTabParam, PromotionTabPlanned } from 'domains/promotion/Promotion.types';
import { notifyWithButtons } from 'UI';

const usePromotionNotification = () => {
  const showNotification = useSelector(getShowNotification);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const resetShowNotification = () => dispatch(setShowPromotionNotification(false));

  const handleClick = () => {
    const uparam = new URLSearchParams();
    uparam.set(PromotionTabParam, PromotionTabPlanned);
    uparam.set(PromotionTabFilterParam, 'country');
    resetShowNotification();
    history.push(`${ROUTER_BACKOFFICE}${ROUTER_PROMOTION}?${uparam}`);
  };

  useEffect(() => {
    if (showNotification) {
      notifyWithButtons({
        header: t('promotion.notification.title', 'New promotion created by your Country'),
        description: [
          t('promotion.notification.description1', 'A new promotion has been created by your country.'),
          t('promotion.notification.description2', 'Go to your back office for more details.'),
        ],
        primaryButtonText: t('promotion.notification.see_promotion', 'See promotion'),
        onPrimaryButtonClick: handleClick,
        onClose: resetShowNotification,
      });
    }
    // eslint-disable-next-line
  }, [showNotification]);
};

export default usePromotionNotification;
