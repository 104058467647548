import { Layout } from 'antd';
import styled from 'styled-components';

const { Content } = Layout;

export const SContent = styled(Content)<{ $windowHeight: number }>`
  width: 100%;
  min-height: ${({ $windowHeight }) => $windowHeight - 90 - 50 - 1 + 'px'};
`;

export const FirstHelpBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${window.outerHeight}px;
  z-index: 1000;
  opacity: 0;
`;
