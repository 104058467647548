import styled from 'styled-components';
import { defaultBoxShadow, Flex, Form } from 'UI';

export const SFlex = styled(Flex)`
  ${defaultBoxShadow};
  border-radius: 8px;
`;

export const SItem = styled(Form.Item)`
  margin-bottom: 30px;
`;
