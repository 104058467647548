import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from 'assets/icons';
import { AddToEstimateIcon } from 'components/AddToEstimate/Button/AddToEstimateButton.styled';
import PrivateComponent from 'composers/PrivateComponent';
import { UserRole } from 'domains/user';
import { theme } from 'styles';
import { BadgeComponent, BoxSizeType, FileAltButton, Icon, WithTooltip } from 'UI';

export const AddToEstimateButton = ({
  handleAddToEstimateClick,
  size,
  disabled,
}: {
  disabled?: boolean;
  handleAddToEstimateClick: () => void;
  size: 'sm' | 'lg';
}) => {
  const { t } = useTranslation();

  const getSizes = (): {
    badgeSize: number;
    badgeOffset: [number | string, number | string];
    iconSize: number;
    buttonSize: number;
    boxSize: BoxSizeType | undefined;
  } => {
    switch (size) {
      case 'sm':
        return {
          badgeSize: 12,
          badgeOffset: [20, 20],
          iconSize: 10,
          buttonSize: 16,
          boxSize: undefined,
        };
      case 'lg':
        return {
          badgeSize: 16,
          badgeOffset: [28, 25],
          iconSize: 14,
          buttonSize: 22,
          boxSize: 'smd',
        };
    }
  };

  return (
    <PrivateComponent
      render={() => (
        <WithTooltip title={t('catalog.action.add_to_estimate', 'Add to estimate')} placement={'top'}>
          <AddToEstimateIcon>
            <BadgeComponent
              size={getSizes().badgeSize}
              offset={getSizes().badgeOffset}
              color={'none'}
              Component={
                <Icon
                  disabled={disabled}
                  IconComponent={PlusIcon}
                  size={getSizes().iconSize}
                  color={theme.color.blue}
                  hoverFill={theme.color.blue_dark}
                  onClick={handleAddToEstimateClick}
                />
              }
            >
              <FileAltButton
                disabled={disabled}
                color={'brand_black'}
                bgColor={'grey95'}
                size={getSizes().buttonSize}
                onClick={handleAddToEstimateClick}
                boxSize={getSizes().boxSize}
                dataCy={'button-add-to-estimate'}
              />
            </BadgeComponent>
          </AddToEstimateIcon>
        </WithTooltip>
      )}
      requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
    />
  );
};
