import React from 'react';
import { useSelector } from 'react-redux';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { DataContainer } from 'components/DataContainer';
import { useFetchExternalOrders } from 'domains/order/Order.requests';
import { getExternalOrders } from 'domains/order/Order.store';
import OrdersTable from 'pages/MyOrdersPage/ExternalOrders/OrdersTable';
import { Flex, InfiniteScroll, MarginBox, useInfiniteScroll } from 'UI';
import { getData } from 'utils';

const PAGING_SIZE = 20;

export function ExternalOrders() {
  const externalOrders = useSelector(getExternalOrders);
  const ordersData = getData(externalOrders) ?? [];

  useFetchExternalOrders(externalOrders);

  const { hasMore, loadMore, currentData } = useInfiniteScroll<ExternalOrderItem>({
    data: ordersData,
    chunkSize: PAGING_SIZE,
  });

  return (
    <Flex direction={'column'}>
      <MarginBox mt={15} />
      <DataContainer data={externalOrders}>
        <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
          <OrdersTable orders={currentData} />
        </InfiniteScroll>
      </DataContainer>
    </Flex>
  );
}
