import React from 'react';
import { FilterTag } from 'components/Filter/FilterTag';
import { Flex } from 'UI';
import { Filters, FiltersRange, FilterType, RANGE_FILTER_TYPE, TEXT_FILTER_TYPE } from './Filter.types';

interface FilterTagsType {
  filters: Filters;
  setTextFilters: (id: string, item: string) => void;
  setRangeFilters: (id: string, range: FiltersRange | undefined) => void;
  filterSectionType?: FilterType;
}

const FilterTags = ({ filters, setTextFilters, setRangeFilters, filterSectionType }: FilterTagsType) => {
  const textFilters = [...filters.textFilters.keys()];
  const rangeFilters = [...filters.rangeFilters.keys()];

  const handleCancelFilter = (type: typeof TEXT_FILTER_TYPE | typeof RANGE_FILTER_TYPE, id: string, item: string) => {
    switch (type) {
      case TEXT_FILTER_TYPE:
        setTextFilters(id, item);
        break;
      case RANGE_FILTER_TYPE:
        setRangeFilters(id, undefined);
        break;
    }
  };

  return (
    <Flex wrap={'wrap'} gap={15}>
      {textFilters.map((key, index) => {
        const filter = filters.textFilters.get(key);
        return (
          <React.Fragment key={key + index}>
            {filter?.map((f) => (
              <FilterTag
                key={f}
                type={TEXT_FILTER_TYPE}
                filterSectionType={filterSectionType}
                id={key}
                item={f}
                handleCancelFilter={handleCancelFilter}
              />
            ))}
          </React.Fragment>
        );
      })}
      {rangeFilters.map((key, index) => {
        const filter = filters.rangeFilters.get(key);
        return (
          <FilterTag
            key={key + index}
            type={RANGE_FILTER_TYPE}
            id={key}
            item={` (${filter?.min} - ${filter?.max})`}
            handleCancelFilter={handleCancelFilter}
          />
        );
      })}
    </Flex>
  );
};

export default FilterTags;
