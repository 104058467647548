import { Badge, Card } from 'antd';
import styled from 'styled-components';

export const SubcategoryCard = styled(Card)`
  text-align: center;
  height: 100%;
  width: 330px;
  box-shadow: 0 3px 6px ${({ theme }) => theme.color.shadow_3};
  border: none;
  border-radius: 0;
  overflow: hidden;

  .ant-card-body {
    padding: 0;
    overflow: auto;
    min-height: 200px;
    height: 100%;
    max-height: 470px;
  }

  .ant-card-head {
    background-color: black;
    border-radius: 0;
  }

  margin: auto;

  :hover {
    cursor: pointer;
  }
`;

export const PlateCardEmpty = styled.div`
  text-align: center;
  border: none;
  border-radius: 0;
  width: 290px;
  height: 200px;
  margin: auto;
`;

export const SPlateCard = styled.div`
  text-align: center;
  box-shadow: 0 3px 6px ${({ theme }) => theme.color.shadow_3};
  border: none;
  border-radius: 0;
  width: 290px;
  height: 200px;
  margin: auto;

  :hover {
    cursor: pointer;
    box-shadow: 0 4px 6px ${({ theme }) => theme.color.shadow_8};
  }
`;

export const SBadge = styled(Badge)`
  cursor: pointer;
`;
