import React from 'react';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Box } from 'UI/Box';
import { CenterFlex } from 'UI/Flex';
import { BASE64, Image, URL } from 'UI/Image/Image';

export type Cursor = 'initial' | 'pointer' | 'auto';

export const ScalableImage = ({
  height,
  width,
  base64,
  imageUrl,
  catalogSource,
  fallbackSrc,
  fallbackComponent,
  alt,
  cursor,
}: {
  height?: number;
  width?: number;
  base64?: string;
  imageUrl?: string;
  catalogSource?: CatalogSource;
  fallbackSrc?: string;
  fallbackComponent?: React.ReactNode;
  alt: string;
  cursor: Cursor;
}) => {
  const getFallbackImage = () => {
    switch (true) {
      case Boolean(base64):
        return <Image src={base64} type={BASE64} alt={alt} maxWidth={width} maxHeight={height} cursor={cursor} />;
      case Boolean(imageUrl):
        return (
          <Image
            src={imageUrl}
            type={URL}
            catalogSource={catalogSource}
            fallbackComponent={fallbackComponent}
            alt={alt}
            maxWidth={width}
            maxHeight={height}
            cursor={cursor}
          />
        );
      default:
        return (
          <Image
            src={fallbackSrc}
            fallbackComponent={fallbackComponent}
            alt={alt}
            width={width}
            height={height}
            cursor={cursor}
          />
        );
    }
  };
  return (
    <Box width={width} height={height}>
      <CenterFlex>{getFallbackImage()}</CenterFlex>
    </Box>
  );
};
