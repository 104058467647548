import React, { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { AngleRightIcon } from 'assets/icons';
import { isPasswordRetrievalAllowed } from 'components/Login/LoginForm/ForgottenPassword';
import { getCredentials } from 'domains/auth/Auth.store';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Panel, SectionCollapse, Text } from 'UI';
import { MenuItem } from './ConnectionIssues.styled';

interface MenuItems {
  id: number;
  title: string;
  component: ReactNode;
}

const SLink = ({
  to,
  children,
  newTab,
  external,
}: {
  to: string;
  children?: ReactNode;
  newTab?: boolean;
  external?: boolean;
}) => {
  return (
    <Link to={to} newTab={newTab} external={external}>
      <Text type={'link'} hoverUnderLine>
        {children}
      </Text>
    </Link>
  );
};

const Menu = ({
  activeId,
  items,
  setActiveId,
}: {
  activeId: number;
  items: MenuItems[];
  setActiveId: (id: number) => void;
}) => {
  return (
    <Flex direction={'column'}>
      {items.map((i) => (
        <MenuItem key={i.id} align={'center'} active={i.id === activeId} onClick={() => setActiveId(i.id)}>
          <Flex>
            <Text type={'h4_paragraph'} disableGutter>
              {i.title}
            </Text>
          </Flex>
          <Flex direction={'row-reverse'} maxWidth={20}>
            <Icon IconComponent={AngleRightIcon} size={20} />
          </Flex>
        </MenuItem>
      ))}
    </Flex>
  );
};

const AssistanceContent = ({ title, content }: { title: string; content: ReactNode }) => {
  return (
    <Flex direction={'column'}>
      <Text type={'h2'} disableGutter>
        {title}
      </Text>
      <MarginBox mt={40} />
      {content}
    </Flex>
  );
};

const PasswordRecoveryContent = () => {
  const { t } = useTranslation();
  const showLink = isPasswordRetrievalAllowed();
  const credentials = useSelector(getCredentials);
  return (
    <>
      <Text type={'text_dim'}>
        {t(
          'assistance.password_recovery.subtitle',
          `If you forgot your password. Please, click on the link below and follow the steps to reset your password.`,
        )}
      </Text>
      <MarginBox mt={20} />
      {showLink && credentials?.changePasswordLink && (
        <SLink to={credentials?.changePasswordLink} external newTab>
          {t('login.forgot_password', 'Forgot password')}
        </SLink>
      )}
    </>
  );
};

interface CollapsContentData {
  title: string;
  subtitle: string;
  innerContent: ReactNode;
}

const CollapsContent = ({ data }: { data: CollapsContentData[] }) => {
  return (
    <>
      {data.map((d) => {
        return (
          <SectionCollapse key={d.title} position={'end'}>
            <Panel
              header={
                <Flex minHeight={50} justify={'center'} direction={'column'}>
                  <Text type={'highlight'} disableGutter>
                    {d.title}
                  </Text>
                  <Text type={'section'} disableGutter>
                    {d.subtitle}
                  </Text>
                </Flex>
              }
              key={d.title}
            >
              <Flex background={theme.color.grey95} minHeight={15} />
              <MarginBox my={20}>{d.innerContent}</MarginBox>
            </Panel>
          </SectionCollapse>
        );
      })}
    </>
  );
};

const getBusinessOrAgentData = (t: TFunction) => {
  return [
    {
      title: t('assistance.dealer_or_agent.cant_log_in.title', `I can't log in?`),
      subtitle: t(
        'assistance.dealer_or_agent.cant_log_in.subtitle',
        `If you can't sign in to your Rpartstore account `,
      ),
      innerContent: (
        <Trans i18nKey={'assistance.dealer_or_agent.cant_log_in.description'}>
          Check the version of your <strong>RNFI Master (Token)</strong> and update it
          <strong> (IS administrators or DMS editors)</strong> .
        </Trans>
      ),
    },
    {
      title: t('assistance.dealer_or_agent.up_to_date.title', 'Are all my computers up to date?'),
      subtitle: t('assistance.dealer_or_agent.up_to_date.subtitle', 'Software and hardware updates'),
      innerContent: (
        <Trans i18nKey={'assistance.dealer_or_agent.up_to_date.description'}>
          <strong>DMS editors</strong> are continuing to update <strong>Master RNFI + Settings Chrome</strong> for all
          agent computers. This update is for connection and navigation on <strong>ALL Renault applications</strong>.
        </Trans>
      ),
    },
  ];
};

const getMraMotrioData = (t: TFunction) => {
  return [
    {
      title: t('assistance.mra_or_motrio.no_longer_know_login.title', 'I no longer know my Login/Password?'),
      subtitle: t('assistance.mra_or_motrio.no_longer_know_login.subtitle', 'I forgot my password'),
      innerContent: (
        <Trans i18nKey={'assistance.mra_or_motrio.no_longer_know_login.description'}>
          <strong>Only the helpdesk who can be emailed at</strong> <i>&quot;Helpdesk email&quot; </i>
          <strong>can assist, please provide your</strong> Full name, Email address, Customer account number, and Dealer
        </Trans>
      ),
    },
    {
      title: t('assistance.mra_or_motrio.cant_log_in.title', 'I can not log in ?'),
      subtitle: t('assistance.mra_or_motrio.cant_log_in.subtitle', 'I can not access to my account'),
      innerContent: (
        <Trans i18nKey={'assistance.mra_or_motrio.cant_log_in.description'}>
          <strong>A conflict</strong> on the user station <strong>between old and new URL </strong>
          <SLink to={'https://rpartstore.renault.com'} newTab external>
            https://rpartstore.renault.com
          </SLink>{' '}
          has been identified hence the need
          <strong> to empty the user&apos;s cache and delete the old URL and various shortcuts</strong> remaining one
          <strong> prerequisite</strong> to ensure a safe connection.
        </Trans>
      ),
    },
    {
      title: t('assistance.mra_or_motrio.user_role_error.title', 'I have user role access error ?'),
      subtitle: t('assistance.mra_or_motrio.user_role_error.subtitle', 'Access error'),
      innerContent: (
        <Trans i18nKey={'assistance.mra_or_motrio.user_role_error.description'}>
          <strong>Your garage does not have the access right -</strong> Please contact the helpdesk at &quot;Help desk
          phone number&quot;
        </Trans>
      ),
    },
    {
      title: t('assistance.mra_or_motrio.idp_error.title', 'I have an IDP error ?'),
      subtitle: t('assistance.mra_or_motrio.idp_error.subtitle', 'IDP error'),
      innerContent: (
        <Trans i18nKey={'assistance.mra_or_motrio.idp_error.description'}>
          Please try to connect using Okta connection
        </Trans>
      ),
    },
  ];
};

const ConnectionIssues = () => {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState(0);
  const section1Title = t('assistance.dealer_or_agent.title', 'Are you a Renault Dealer or an Agent ?');
  const section2Title = t('assistance.mra_or_motrio.title', 'Are you an MRA or Motrio repairer?');
  const menuCategories = [
    {
      id: 0,
      title: t('login.forgot_password', 'Forgot password'),
      component: (
        <AssistanceContent
          title={t('assistance.password_recovery.title', 'Password recovery')}
          content={<PasswordRecoveryContent />}
        />
      ),
    },
    {
      id: 1,
      title: section1Title,
      component: (
        <AssistanceContent title={section1Title} content={<CollapsContent data={getBusinessOrAgentData(t)} />} />
      ),
    },
    {
      id: 2,
      title: section2Title,
      component: <AssistanceContent title={section2Title} content={<CollapsContent data={getMraMotrioData(t)} />} />,
    },
  ];

  return (
    <Flex>
      <Flex maxWidth={400}>
        <Menu activeId={activeId} items={menuCategories} setActiveId={setActiveId} />
      </Flex>
      <MarginBox ml={45} />
      {menuCategories[activeId]?.component}
    </Flex>
  );
};

export default ConnectionIssues;
