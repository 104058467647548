import React from 'react';
import { useTranslation } from 'react-i18next';
import { CatalogLaborTimeIcon, CatalogReferenceIcon, CatalogReferenceLaborTimeIcon } from 'assets/icons';
import { ErrorWithLabel } from 'components/DataContainer';
import { CenteredSpin, CenterFlex, Icon, Image, MarginBox } from 'UI';
import { ERROR, LOADING, NO_DATA } from 'utils';
import { SPlateCard } from '../Subcategory.styled';

const PlateNoDataCard = ({
  status,
  hasReferences,
  hasLaborTimes,
  hasReferencesAndLaborTime,
}: {
  status: NO_DATA;
  hasReferences: boolean | undefined;
  hasLaborTimes: boolean | undefined;
  hasReferencesAndLaborTime: boolean | undefined;
}) => {
  const { t } = useTranslation();
  const renderNoDataType = () => {
    switch (status) {
      case ERROR:
        return <ErrorWithLabel label={t('catalog.plate.backend_error', 'Plate temporarily unavailable')} />;
      case LOADING:
        return <CenteredSpin />;
      default:
        if (hasReferencesAndLaborTime) {
          return (
            <CenterFlex>
              <Icon IconComponent={CatalogReferenceLaborTimeIcon} width={110} height={110} />
            </CenterFlex>
          );
        }
        if (hasReferences && !hasReferencesAndLaborTime) {
          return (
            <CenterFlex>
              <Icon IconComponent={CatalogReferenceIcon} width={110} height={110} />
            </CenterFlex>
          );
        }
        if (hasLaborTimes && !hasReferencesAndLaborTime) {
          return (
            <CenterFlex>
              <Icon IconComponent={CatalogLaborTimeIcon} width={110} height={110} />
            </CenterFlex>
          );
        }
        return <Image alt={'plate-placeholder'} src={'catalog/plate-placeholder.png'} />;
    }
  };
  return (
    <MarginBox mx={10} my={10}>
      <SPlateCard>{renderNoDataType()}</SPlateCard>
    </MarginBox>
  );
};

export default PlateNoDataCard;
