import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AngleDownIcon, AngleRightIcon } from 'assets/icons';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import {
  getExternalSectionValidation,
  getOtherSectionValidation,
  getVehiclesValidation,
  resetValidationDeliveryAddressSaga,
  resetValidationPaymentMethodSaga,
} from 'domains/orderValidation';
import { SectionCard } from 'pages/CartPage/ValidationStep/DeliveryAddressSection.styled';
import VerificationCard from 'pages/CartPage/ValidationStep/VerificationCard/VerificationCard';
import { theme } from 'styles';
import { Box, Divider, Flex, Icon, MarginBox, PaddingBox, SelectOptionSingle, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';

interface VerificationSectionProps {
  vehicles: VehicleLocal[];
  billingOptions?: SelectOptionSingle[];
  deliveryOptions?: SelectOptionSingle[];
  otherReferences: ReferenceLocal[];
  externalBaskets: ExternalBasketSectionLocal[];
  allowPaymentChanges: boolean;
  allowAddressChanges: boolean;
}

const VerificationSection = ({
  vehicles,
  billingOptions,
  deliveryOptions,
  otherReferences,
  externalBaskets,
  allowPaymentChanges,
  allowAddressChanges,
}: VerificationSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehiclesValidation = useSelector(getVehiclesValidation);
  const otherSectionValidation = useSelector(getOtherSectionValidation);
  const externalSectionValidation = useSelector(getExternalSectionValidation);
  const [collapsedSection, setCollapsedSection] = useState<boolean>(false);

  const getAlertMessage = (vehicle: VehicleLocal | undefined, messageType: 'DELIVERY' | 'PAYMENT') => {
    if (!vehicle) {
      switch (messageType) {
        case 'DELIVERY':
          return t(
            'order.verification.deliveryAddressUpdate.otherArticles',
            'Your delivery address has been updated for other articles',
          );
        case 'PAYMENT':
          return t(
            'order.verification.paymentMethodUpdate.otherArticles',
            'Your billing account has been updated for other articles',
          );
      }
    }
    const vehicleDetail = vehicle.vehicleDetail;
    if (vehicleDetail.vin) {
      switch (messageType) {
        case 'DELIVERY':
          return t(
            'order.verification.deliveryAddressUpdate.vin',
            'Your delivery address has been updated for vehicle VIN: {{updatedVehicle}}',
            { updatedVehicle: vehicleDetail.vin },
          );
        case 'PAYMENT':
          return t(
            'order.verification.paymentMethodUpdate.vin',
            'Your billing account has been updated for vehicle VIN: {{updatedVehicle}}',
            { updatedVehicle: vehicleDetail.vin },
          );
      }
    }
    if (vehicleDetail.dataHubVehicle || vehicleDetail.vehicleBrand) {
      const updatedVehicle = vehicleDetail.dataHubVehicle
        ? `${vehicleDetail.dataHubVehicle.name} ${vehicleDetail.dataHubVehicle.modelType}`
        : `${vehicleDetail.vehicleBrand ?? ''} ${vehicleDetail.model ?? ''} ${vehicleDetail.iamVehicle?.phase ?? ''}`;
      switch (messageType) {
        case 'DELIVERY':
          return t(
            'order.verification.deliveryAddressUpdate.bmm',
            'Your delivery address has been updated for vehicle: {{updatedVehicle}}',
            { updatedVehicle },
          );
        case 'PAYMENT':
          return t(
            'order.verification.paymentMethodUpdate.bmm',
            'Your billing account has been updated for vehicle: {{updatedVehicle}}',
            { updatedVehicle },
          );
      }
    }
    return '';
  };

  const handlePaymentMethodUpdate = (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => {
    notifyTop(
      'success',
      getAlertMessage(vehicle, 'PAYMENT'),
      undefined,
      <NotificationLink
        onClick={() => {
          dispatch(
            resetValidationPaymentMethodSaga({
              vehicleKey: vehicle ? vehicle.vehicleDetail.vehicleKey : undefined,
              externalBasketId,
            }),
          );
        }}
      >
        {t('common.action.cancel', 'Cancel')}
      </NotificationLink>,
    );
  };
  const handleDeliveryAddressUpdate = (vehicle: VehicleLocal | undefined, externalBasketId: string | undefined) => {
    notifyTop(
      'success',
      getAlertMessage(vehicle, 'DELIVERY'),
      undefined,
      <NotificationLink
        onClick={() => {
          dispatch(
            resetValidationDeliveryAddressSaga({
              vehicleKey: vehicle ? vehicle.vehicleDetail.vehicleKey : undefined,
              externalBasketId,
            }),
          );
        }}
      >
        {t('common.action.cancel', 'Cancel')}
      </NotificationLink>,
    );
  };

  return (
    <Flex direction={'column'} dataCy={'section-verification-order-validation'}>
      <Divider style={{ margin: '0px', visibility: collapsedSection ? 'hidden' : 'visible' }} />
      <SectionCard direction={'row'} align={'center'} editMode={!collapsedSection}>
        <Flex direction={'column'} alignSelf={'flex-start'}>
          <Text type={'h4_paragraph'}>{t('order.verification.title', '3. Verification and order methods')}</Text>
          <Box height={10} />
          <Text type={'light_14_black_65'}>
            {t(
              'order.verification.description',
              'Customize the delivery and payment options for your baskets and add ordering instructions.',
            )}
          </Text>
        </Flex>
        <PaddingBox pl={15}>
          <Flex direction={'row'} justify={'flex-end'} align={'center'}>
            <Icon
              IconComponent={collapsedSection ? AngleRightIcon : AngleDownIcon}
              round
              size={35}
              color={theme.color.blue}
              onClick={() => setCollapsedSection(!collapsedSection)}
            />
          </Flex>
        </PaddingBox>
      </SectionCard>
      {!collapsedSection && (
        <MarginBox mt={-32}>
          {vehicles?.map((vehicle: VehicleLocal) => (
            <VerificationCard
              key={vehicle.vehicleDetail.vehicleKey}
              vehicle={vehicle}
              billingOptions={billingOptions}
              deliveryOptions={deliveryOptions}
              handlePaymentMethodUpdate={handlePaymentMethodUpdate}
              handleDeliveryAddressUpdate={handleDeliveryAddressUpdate}
              allowPaymentChanges={allowPaymentChanges}
              allowAddressChanges={allowAddressChanges}
              validation={vehiclesValidation.find(
                (validationVehicle) => vehicle.vehicleDetail.vehicleKey === validationVehicle.vehicleKey,
              )}
            />
          ))}
          {externalBaskets.map((ext) => (
            <VerificationCard
              key={ext.externalBasketId}
              externalBasket={ext}
              references={ext.references}
              billingOptions={billingOptions}
              deliveryOptions={deliveryOptions}
              handlePaymentMethodUpdate={handlePaymentMethodUpdate}
              handleDeliveryAddressUpdate={handleDeliveryAddressUpdate}
              allowPaymentChanges={allowPaymentChanges}
              allowAddressChanges={allowAddressChanges}
              validation={externalSectionValidation.find(
                (validationExternalBasket) => ext.externalBasketId === validationExternalBasket.externalBasketId,
              )}
            />
          ))}
          {otherReferences && otherReferences.length > 0 && (
            <VerificationCard
              references={otherReferences}
              billingOptions={billingOptions}
              deliveryOptions={deliveryOptions}
              handlePaymentMethodUpdate={handlePaymentMethodUpdate}
              handleDeliveryAddressUpdate={handleDeliveryAddressUpdate}
              allowPaymentChanges={allowPaymentChanges}
              allowAddressChanges={allowAddressChanges}
              validation={otherSectionValidation}
            />
          )}
        </MarginBox>
      )}
    </Flex>
  );
};

export default VerificationSection;
