import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { RootState } from 'app/AppStore';
import { EyeIcon } from 'assets/icons';
import { DataContainer, EMPTY_FRAGMENT } from 'components/DataContainer';
import { useDecodeImage } from 'domains/catalog/Catalog.hooks';
import { findPlateUrlInVehicleTree, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { DATAHUB, HighlightIndex, PARAM_STANDARD, PlateViewModeParam } from 'domains/catalog/Catalog.types';
import { useGetCatalogSourceUrlFromLocation } from 'domains/catalog/Catalog.utils';
import { useFetchUrlImage } from 'domains/pictures/Pictures.requests';
import { getPicture } from 'domains/pictures/Pictures.store';
import { useFetchPlatesForReference } from 'domains/references/References.requests';
import { Box, CenteredSpin, defaultBoxShadow, Flex, Icon, Link, MarginBox, Text } from 'UI';
import { svgElementPlateId } from 'utils/svg/common';
import { manipulateLinesInSvg } from 'utils/svg/manipulatePlateSvg';
import { SSvgFlex, SvgDiv } from '../../PlateDetailCardImage/PlateDetailCardImage.styled';
import { EmptyOrErrorPlate } from '../../PlateThumbnailSection/EmptyOrErrorPlate/EmptyOrErrorPlate';

const SContainer = styled.div`
  cursor: pointer;
  border-radius: 12px;

  :hover {
    background-color: ${({ theme }) => theme.color.grey85};
  }

  padding: 0 5px 0 5px;
`;

export const FloatingContainer = styled.div<{ reverse?: boolean }>`
  display: flex;
  overflow: hidden;
  max-width: 900px;
  background-color: white;
  padding: 5px 5px 5px 10px;
  overflow-x: auto;
  z-index: 801;
  position: absolute;
  border-radius: 8px;
  margin-left: ${({ reverse }) => (reverse ? -580 : 0)}px;
  ${defaultBoxShadow}
`;

export const LinkToPlate = ({ link, children }: { link: string | undefined; children?: ReactNode }) =>
  link ? <Link to={link}>{children}</Link> : <>{children}</>;

export const FloatingImage = ({
  id,
  plateId,
  counter,
  index,
  onClick,
}: {
  id: string;
  plateId: string;
  counter: string;
  index: number | undefined;
  onClick?: () => void;
}) => {
  const catalogSourceUrl = useGetCatalogSourceUrlFromLocation();
  const imageBase64 = useSelector((state: RootState) => getPicture(state, id));
  const svgElement = useDecodeImage(imageBase64);
  const platePath = useSelector((state: RootState) => findPlateUrlInVehicleTree(state, plateId));
  const [highlightedIndex, setHighlightedIndex] = useState<string | null>(null);
  useFetchUrlImage(id, DATAHUB);
  const link = platePath
    ? `${catalogSourceUrl}/${platePath}` + (index ? `&${HighlightIndex}=${index?.toString()}` : '')
    : undefined;

  useEffect(() => {
    manipulateLinesInSvg(`${plateId}-${index}-${counter}`, undefined, highlightedIndex, svgElementPlateId, false);
  }, [highlightedIndex, imageBase64, index, plateId, setHighlightedIndex, counter]);

  useEffect(() => {
    setTimeout(() => {
      if (index) {
        setHighlightedIndex(String(index));
      }
    }, 500);
  });

  return (
    <Box
      maxHeight={600}
      width={600}
      align={'center'}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <DataContainer
        data={svgElement}
        Skeleton={() => (
          <LinkToPlate link={link}>
            <Box height={300}>
              <CenteredSpin />
            </Box>
          </LinkToPlate>
        )}
      >
        <LinkToPlate link={link}>
          {svgElement && typeof svgElement !== 'string' && svgElement.type === 'error' ? (
            <Box height={300}>
              <EmptyOrErrorPlate />
            </Box>
          ) : (
            <SSvgFlex>
              <SvgDiv id={`${svgElementPlateId}${plateId}-${index}-${counter}`}>{svgElement}</SvgDiv>
            </SSvgFlex>
          )}
        </LinkToPlate>
      </DataContainer>
    </Box>
  );
};

export const SeePlateLink = ({
  referenceNumber,
  seePlateState,
  onClick,
  fromDetail = false,
}: {
  referenceNumber: string;
  seePlateState: [string | undefined, (x: string | undefined) => void];
  onClick?: () => void;
  fromDetail?: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const catalogSourceUrl = useGetCatalogSourceUrlFromLocation();
  const [getSeePlate, setSeePlate] = seePlateState;
  const showPlate = getSeePlate === referenceNumber;
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const plateLinesSearchData = useFetchPlatesForReference(referenceNumber, vehicleKey);
  const plateLines = plateLinesSearchData.data;
  const hasAnyPlate = (plateLines?.length ?? 0) > 0;
  const hasMultiplePlates = (plateLines?.length ?? 0) > 1;
  const firstPlateIndex = plateLines?.[0]?.index;
  const firstPlateId = plateLines?.[0]?.vehiclePlate?.id;

  const firstPlatePath = useSelector((state: RootState) =>
    findPlateUrlInVehicleTree(state, hasAnyPlate && firstPlateId ? firstPlateId : ''),
  );
  const firstPlateLink = firstPlatePath
    ? `${catalogSourceUrl}/${firstPlatePath}` +
      (firstPlateIndex ? `&${HighlightIndex}=${firstPlateIndex?.toString()}` : '') +
      `&${PlateViewModeParam}=${PARAM_STANDARD}`
    : undefined;

  const handleClick = useCallback(() => {
    onClick && onClick();
    if (hasAnyPlate && firstPlateLink) {
      history.push(firstPlateLink);
    }
  }, [hasAnyPlate, firstPlateLink, history, onClick]);
  const handleMouseEnter = useCallback(() => {
    if (!hasMultiplePlates) {
      setSeePlate(referenceNumber);
    } else {
      setSeePlate(!showPlate ? referenceNumber : undefined);
    }
  }, [showPlate, hasMultiplePlates, referenceNumber, setSeePlate]);
  const handleMouseLeave = useCallback(() => {
    setSeePlate(undefined);
  }, [setSeePlate]);

  return (
    <DataContainer
      data={plateLinesSearchData?.searchStatus}
      NotFound={EMPTY_FRAGMENT}
      Loading={() => <CenteredSpin size={'small'} />}
    >
      {hasAnyPlate && (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <SContainer onClick={handleClick}>
            <Flex direction={'row'}>
              <Icon IconComponent={EyeIcon} color={showPlate ? 'black' : 'grey'} height={20} width={20} />
              <MarginBox mx={3}>
                <Text
                  type={'light_12'}
                  displayStyle={showPlate ? undefined : 'link'}
                  cursor={'pointer'}
                  dataCy={'link-see-plan'}
                >
                  {t('catalog.reference_card.action.see_plate', 'See plate')}
                </Text>
              </MarginBox>
            </Flex>
          </SContainer>
          {showPlate && (
            <FloatingContainer reverse={fromDetail}>
              <DataContainer
                data={plateLines}
                Loading={() => (
                  <Box width={fromDetail ? 660 : 400} height={fromDetail ? 660 : 400}>
                    <CenteredSpin />
                  </Box>
                )}
              >
                {plateLines?.map((plateLine, i) => (
                  <Box width={660} key={`plate-image-${plateLine.vehiclePlate?.id ?? uuid()}`}>
                    {plateLine.vehiclePlate?.imageUrl && plateLine.vehiclePlate?.id && (
                      <FloatingImage
                        id={plateLine.vehiclePlate?.imageUrl}
                        plateId={plateLine.vehiclePlate?.id}
                        key={`picture-${plateLine.vehiclePlate?.id ?? uuid()}`}
                        index={plateLine.index}
                        onClick={() => onClick && onClick()}
                        counter={i.toString()}
                      />
                    )}
                  </Box>
                ))}
              </DataContainer>
            </FloatingContainer>
          )}
        </div>
      )}
    </DataContainer>
  );
};
