import styled from 'styled-components';
import { theme } from 'styles';
import { Radio } from 'UI';

export const WarrantyRequestFlex = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px;
  border-radius: 16px;
  border: solid 1px ${theme.color.brand_black};
  width: 300px;
  position: relative;
  cursor: pointer;
`;

export const WarrantyRequestRadio = styled(Radio)`
  position: absolute;
  right: 11px;
`;
