import styled from 'styled-components';
import { Text } from 'UI';

export const SEmail = styled(Text)`
  height: 54px;
`;

export const BrandCheckBox = styled.div<{ $selected: boolean }>`
  width: 120px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.color.shadow_2};
  ${({ theme, $selected }) => $selected && `border: solid 3px ${theme.color.clear_blue};`};
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }
`;
