/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { DHReferenceLocal } from 'domains/references';
import { SparePartsViewType } from 'domains/user';
import ProductImageCard from 'pages/CatalogPage/DH/Product/ProductImageCard/ProductImageCard';
import { ProductThumbnailSection } from 'pages/CatalogPage/DH/Product/ProductThumbnailSection';
import { Flex } from 'UI';
import { getData } from 'utils';

export interface ProductDetailSectionProps {
  refLocal: DHReferenceLocal;
  sparePartsView: SparePartsViewType;
}

const ProductImageSection = ({ refLocal, sparePartsView }: ProductDetailSectionProps) => {
  const mod = (n: number, m: number) => ((n % m) + m) % m;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const reference = getData(refLocal);
  const referenceNumber = reference?.referenceNumber ?? '';
  const productImageKeys = reference && 'imageKeys' in reference ? reference?.imageKeys : undefined;
  const productImageKey = productImageKeys ? productImageKeys[selectedIndex] : '';
  const referenceBrand = reference?.brand;
  const tireBrandId = refLocal.tireDetails?.brandId;
  const vehicleBrand = useSelector(getLastVehicleDetail)?.vehicleBrand ?? 'OTHER';
  const catalogSource = reference?.source === 'dh' ? 'DATAHUB' : 'IAM';

  const browse = (offset: number) => {
    if (Array.isArray(productImageKeys)) {
      const newIndex = mod(selectedIndex + offset, productImageKeys.length);
      setSelectedIndex(newIndex);
    }
  };
  return (
    <Flex direction={'row'}>
      {productImageKeys && productImageKeys.length > 1 && (
        <ProductThumbnailSection
          imageKeys={productImageKeys}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          catalogSource={catalogSource}
        />
      )}
      <Flex justify={'flex-end'}>
        <ProductImageCard
          sparePartsView={sparePartsView}
          productImageKey={productImageKey}
          referenceNumber={referenceNumber}
          referenceBrand={referenceBrand}
          tireBrandId={tireBrandId}
          vehicleBrand={vehicleBrand}
          browseLeft={() => browse(-1)}
          browseRight={() => browse(1)}
          countOfImages={productImageKeys?.length ?? 0}
          catalogSource={catalogSource}
        />
      </Flex>
    </Flex>
  );
};

export default ProductImageSection;
