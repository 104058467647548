import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { API_CALL_ERROR, FILE, SAME_FILE_ERROR, UPLOADING_FILE } from 'components/FileUpload/paramsByState';
import { useFetchCrossSelling } from 'domains/crossSelling/CrossSelling.requests';
import {
  getCrossSellingFileCorrectRows,
  getCrossSellingFileErrorRows,
  getCrossSellingFileModalStatus,
  getCrossSellingFileUploadedRows,
  getCrossSellingFileUploadStatus,
  getProcessFileProgress,
  replaceFileCrossSellingRequestSaga,
  setCrossSellingFileModalStatus,
  setCrossSellingFileUploadStatus,
} from 'domains/crossSelling/CrossSelling.store';
import { FILE_PROGRESS_FINISHED } from 'domains/crossSelling/CrossSelling.types';
import {
  DELETING_FILE,
  FETCHING_FILE,
  FILE_REPLACED,
  FILE_UPLOADED,
  REPLACING_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import CrossSellingFileUpload from 'pages/BackOfficePage/BackOfficeCategories/CrossSelling/CrossSellingFileUpload';
import FileRows from 'pages/BackOfficePage/BackOfficeCategories/CrossSelling/FileRows';
import FileRowsErrors from 'pages/BackOfficePage/BackOfficeCategories/CrossSelling/FileRowsErrors';
import PublishResult from 'pages/BackOfficePage/BackOfficeCategories/CrossSelling/PublishResult';
import { theme } from 'styles';
import { Box, CenteredSpin, Flex, MarginBox, Progress, Text, YellowButton } from 'UI';

const CrossSelling = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileStatus = useSelector(getCrossSellingFileUploadStatus);
  const modalStatus = useSelector(getCrossSellingFileModalStatus);
  const correctRows = useSelector(getCrossSellingFileCorrectRows);
  const errorRows = useSelector(getCrossSellingFileErrorRows);
  const fileRows = useSelector(getCrossSellingFileUploadedRows);
  const fileProgress = useSelector(getProcessFileProgress);
  const isPublishable = fileStatus === FILE_UPLOADED && correctRows.length > 0;
  const showRows = modalStatus === FILE && fileStatus !== UPLOADING_FILE && fileStatus !== REPLACING_FILE;

  const getFileProgress = () => {
    switch (fileStatus) {
      case REPLACING_FILE:
      case DELETING_FILE:
      case UPLOADING_FILE:
        return (
          <Box width={400}>
            {fileProgress === FILE_PROGRESS_FINISHED ? (
              <CenteredSpin />
            ) : (
              <Progress percent={fileProgress} strokeColor={theme.color.brand} />
            )}
          </Box>
        );

      default:
        return <></>;
    }
  };

  useFetchCrossSelling();

  useEffect(() => {
    if (fileStatus === FILE_REPLACED) dispatch(setCrossSellingFileUploadStatus(FILE));
    if (modalStatus === SAME_FILE_ERROR) dispatch(setCrossSellingFileModalStatus(FILE));
    //eslint-disable-next-line
  }, []);

  const renderUploadState = () => {
    switch (fileStatus) {
      case FETCHING_FILE:
        return (
          <MarginBox mt={64}>
            <CenteredSpin size={'large'} />
          </MarginBox>
        );
      case FILE_UPLOADED:
        if (errorRows.length > 0) {
          return <FileRowsErrors />;
        } else {
          return renderFileUpload();
        }
      case FILE_REPLACED:
        return <PublishResult />;
      case API_CALL_ERROR:
        return (
          <Text type={'text_dim_bold'}>
            {t('common.file_upload.drag_n_drop.api_call_error', 'No response from API')}
          </Text>
        );
      case REPLACING_FILE:
      case DELETING_FILE:
      case UPLOADING_FILE:
      default:
        return (
          <>
            {renderFileUpload()}
            {showRows && <FileRows fileRows={fileRows} />}
          </>
        );
    }
  };

  const handlePublish = () => {
    dispatch(replaceFileCrossSellingRequestSaga());
  };

  const renderFileUpload = () => {
    return (
      <Flex direction={'column'} align={'center'}>
        <MarginBox mt={64}>
          <Text type={'h2'}>{t('cross_selling.upload.title', 'Upload cross selling list')}</Text>
          <MarginBox mt={15} />
          <Box width={600}>
            <Text type={'text_dim'}>
              {t('cross_selling.upload.description', 'Maximum file size : 2 MB - (Accepted formats : .csv)')}
            </Text>
          </Box>
          <MarginBox mt={35} />
          {getFileProgress()}
          <CrossSellingFileUpload />
          <MarginBox mt={35} />
          {isPublishable && (
            <YellowButton stretch={false} onClick={handlePublish}>
              {t('common.publish', 'Publish')}
            </YellowButton>
          )}
        </MarginBox>
      </Flex>
    );
  };

  return renderUploadState();
};

export default CrossSelling;
