import React from 'react';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { SelectedTireProps } from 'domains/tires/Tire.types';
import TireItem from 'pages/CatalogPage/DH/Tires/TireItem';
import { Flex, MarginBox } from 'UI';

interface TireRowProps extends SelectedTireProps {
  tire: VehicleTire;
}

const TireRow = ({ tire, selectedTire, setSelectedTire }: TireRowProps) => {
  return (
    <>
      <Flex justify={'flex-start'}>
        <TireItem
          position={'FRONT'}
          id={tire.frontTireId ?? ''}
          tireSize={tire.front}
          usage={tire.usage}
          isSelected={selectedTire === tire.frontTireId}
          setSelectedTire={setSelectedTire}
        />
        <MarginBox mr={30} />
        <TireItem
          position={'REAR'}
          id={tire.rearTireId ?? ''}
          tireSize={tire.rear}
          usage={tire.usage}
          isSelected={selectedTire === tire.rearTireId}
          setSelectedTire={setSelectedTire}
        />
      </Flex>
      <MarginBox mb={15} />
    </>
  );
};
export default TireRow;
