import styled from 'styled-components';

export const STitleCard = styled.div<{ pointer?: boolean }>`
  background: ${({ theme }) => theme.color.blue_dark};
  width: 260px;
  height: 50px;
  padding: 12px 55px 12px 30px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
`;

export const SCard = styled.div`
  width: 260px;
  height: 50px;
  padding: 12px 55px 12px 30px;
  &:hover:not(.active) {
    background-color: ${({ theme }) => theme.color.grey95};
  }
  cursor: pointer;
`;
