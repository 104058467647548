import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedProfile, getTokenUser, getUserCommercialLink, getUserRights } from 'domains/user';
import { MarginBox } from 'UI';
import { getData } from 'utils';

const UserInfoTemp = () => {
  const { t } = useTranslation();
  const userInfo = useSelector(getTokenUser);
  const commercialLink = useSelector(getUserCommercialLink);
  const userRights = useSelector(getUserRights);
  const selectedProfile = useSelector(getSelectedProfile);
  const rights = getData(userRights) ?? [];
  return (
    <MarginBox mt={50} ml={-15}>
      <ul>
        <li data-cy={'user-id'}>
          {t('profile.user_info.id', 'User id:')}
          <strong> {userInfo?.id}</strong>
        </li>
        {selectedProfile?.id !== userInfo?.id && (
          <li>
            {t('profile.user_info.twin_user.id', 'Twin user id:')}
            <strong> {selectedProfile?.id}</strong>
          </li>
        )}
        <li>
          {t('profile.user_info.email', 'Email:')}
          <strong> {userInfo?.email}</strong>
        </li>
        <li data-cy={'first-name'}>
          {t('profile.user_info.first_name', 'First name:')}
          <strong> {userInfo?.firstName}</strong>
        </li>
        <li data-cy={'last-name'}>
          {t('profile.user_info.last_name', 'Last name:')}
          <strong> {userInfo?.lastName}</strong>
        </li>
        <li>
          {t('profile.user_info.rights', 'Rights:')}
          <strong> {rights?.join(', ')}</strong>
        </li>
        <li>
          {t('profile.user_info.locale', 'Locale:')}
          <strong> {userInfo?.locale}</strong>
        </li>
        <li>
          {t('profile.user_info.token.country', 'Token country:')}
          <strong> {userInfo?.country}</strong>
        </li>
        <li>
          {t('profile.user_info.token.garage.id', 'Token garage id:')}
          <strong> {selectedProfile?.garageId}</strong>
        </li>
        <li>
          {t('profile.user_info.token.repository', 'Token repository:')}
          <strong> {selectedProfile?.repository}</strong>
        </li>
        <li>
          {t('profile.user_info.commercial_link.country', 'Commercial link country:')}
          <strong> {commercialLink?.countryCode}</strong>
        </li>
        <li>
          {t('profile.user_info.commercial_link.tresor_code', 'Commercial link tresor code:')}
          <strong> {commercialLink?.tresorCode ?? ''}</strong>
        </li>
        <li>
          {t('profile.user_info.commercial_link.bir_code', 'Commercial link bir code:')}
          <strong> {commercialLink?.birCode ?? ''}</strong>
        </li>
        <li>
          {t('profile.user_info.commercial_link.garage_name', 'Commercial link garage name:')}
          <strong> {commercialLink?.name}</strong>
        </li>
        <li>
          {t('profile.user_info.commercial_link.repository', 'Commercial link repository:')}
          <strong> {commercialLink?.repository}</strong>
        </li>
        <li>
          {t('profile.user_info.dealer.tresor_code', 'Dealer tresor code:')}
          <strong> {commercialLink?.seller?.tresorCode ?? ''}</strong>
        </li>
        <li>
          {t('profile.user_info.dealer.bir_code', 'Dealer bir code:')}
          <strong> {commercialLink?.seller?.birCode ?? ''}</strong>
        </li>
        <li data-cy={'dealer-name'}>
          {t('profile.user_info.dealer.name', 'Dealer name:')}
          <strong> {commercialLink?.seller?.name ?? ''}</strong>
        </li>
        <li>
          {t('profile.user_info.garage.relation_to_dealer', 'Garage relation to dealer:')}
          <strong> {commercialLink?.seller?.buyerRelationToSeller ?? ''}</strong>
        </li>
      </ul>
    </MarginBox>
  );
};

export default UserInfoTemp;
