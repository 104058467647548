import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DealerType } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { TFunction } from 'i18next';
import { FileUploadPreview, FileUploadStrip } from 'components/FileUpload';
import {
  getUploadedTargetedAudienceFromFile,
  setUploadedTargetedAudienceFromFileSizeAndName,
  uploadTargetedAudienceFileSaga,
} from 'domains/promotion/Promotion.store';
import { CreatePromotionStepProps, DealerTypeLocal } from 'domains/promotion/Promotion.types';
import { getDealerType } from 'domains/user';
import { validateFile } from 'pages/BackOfficePage/BackOfficeCategories/Promotion/CreatePromotion/common';
import { Flex, MarginBox, Radio, StepButtons, Text, Checkbox } from 'UI';
import { useBreakpointSelectorFull } from 'utils';

const getDealerTypeLabel = (dealerType: DealerType, t: TFunction) => {
  switch (dealerType) {
    case 'R1':
      return t('backoffice.promotion.target_audience.r1', 'R1');
    case 'R2':
      return t('backoffice.promotion.target_audience.r2', 'R2');
    case 'R3':
      return t('backoffice.promotion.target_audience.r3', 'R3');
    case 'R3_MOTRIO':
      return t('backoffice.promotion.target_audience.r3_motrio', 'R3 Motrio');
    default:
      return '';
  }
};

export const TargetStep = ({
  promotion,
  setPromotion,
  currentStep,
  setCurrentStep,
  totalSteps,
  validateStep,
}: CreatePromotionStepProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uploadedTargetedAudience = useSelector(getUploadedTargetedAudienceFromFile);
  const userProfileType = useSelector(getDealerType);
  const dealerTypes: DealerType[] =
    userProfileType === 'R1' ? ['R2', 'R3', 'R3_MOTRIO'] : ['R1', 'R2', 'R3', 'R3_MOTRIO'];
  const itemCount = useBreakpointSelectorFull()({ xs: 40, sm: 40, md: 50, lg: 50, xl: 48, xxl: 80 });

  function uploadFile(fileName: string, fileSize: number, fileBase64: string) {
    dispatch(uploadTargetedAudienceFileSaga({ fileBase64 }));
    dispatch(setUploadedTargetedAudienceFromFileSizeAndName({ fileName, fileSize }));
  }

  return (
    <>
      <MarginBox mt={45} />
      <Text type={'h2'}>{t('backoffice.promotion.target_audience', 'Target audience')}</Text>
      <Text type={'text'}>
        {t(
          'backoffice.promotion.target_audience.description',
          'Please identify the set of customers you intend to focus on and serve the marketing content.',
        )}
      </Text>
      <MarginBox mt={15} />
      <Flex direction={'row'}>
        <Radio
          disable={promotion.editing}
          onChange={() => {
            setPromotion({
              ...promotion,
              targetAudienceType: 'NETWORK',
            });
          }}
          checked={promotion.targetAudienceType === 'NETWORK'}
          label={t('backoffice.promotion.target.select_network', 'Select network')}
        />
        <MarginBox ml={60} />
        <Radio
          disable={promotion.editing}
          onChange={() => {
            setPromotion({
              ...promotion,
              targetAudienceType: 'DEALERS',
            });
          }}
          checked={promotion.targetAudienceType === 'DEALERS'}
          label={t('backoffice.promotion.target.upload_dealer_list', 'Upload dealer list')}
        />
      </Flex>
      <MarginBox mt={30} />
      {promotion.targetAudienceType === 'NETWORK' ? (
        <Flex direction={'row'}>
          {dealerTypes.map((dealer, i) => {
            const promotionDealer = promotion.targetAudience.dealerTypes?.find((d) => d.dealerType === dealer);
            const isChecked = promotion.targetAudience.dealerTypes?.map((d) => d.dealerType).includes(dealer);
            const isRemovable = promotionDealer ? promotionDealer.removable : true;
            return (
              <Flex key={'promotion_dealer_checkbox_' + i}>
                <Checkbox
                  checked={isChecked}
                  disabled={!isRemovable}
                  onChange={(v) => {
                    const checked = v;
                    const currentDealers = promotion.targetAudience.dealerTypes ?? [];
                    if (checked) {
                      setPromotion({
                        ...promotion,
                        targetAudience: {
                          ...promotion.targetAudience,
                          dealerTypes: [
                            ...currentDealers,
                            {
                              dealerType: dealer,
                              removable: isRemovable,
                            } as DealerTypeLocal,
                          ],
                        },
                      });
                    } else {
                      setPromotion({
                        ...promotion,
                        targetAudience: {
                          ...promotion.targetAudience,
                          dealerTypes: currentDealers.filter((d) => d.dealerType !== dealer),
                        },
                      });
                    }
                  }}
                  label={<Text type={'light_14_black_65'}>{getDealerTypeLabel(dealer, t)}</Text>}
                />
              </Flex>
            );
          })}
          <Flex size={4} />
        </Flex>
      ) : (
        <Flex direction={'column'}>
          <Text type={'h5_bold'}>{t('backoffice.promotion.upload_contact_list', 'Upload contact list')}</Text>
          <MarginBox mt={15} />
          <FileUploadStrip
            handleFileBase64={uploadFile}
            validateFile={validateFile(t)}
            status={uploadedTargetedAudience.status}
            fileName={uploadedTargetedAudience.fileName}
            template={{
              link: '/fileTemplates/promotion_targeted_audience.csv',
              fileName: 'ID Tresor.csv',
            }}
            disable={promotion.editing}
          />
          <MarginBox mt={15} />
          <FileUploadPreview
            fileSize={uploadedTargetedAudience.fileSize}
            fileName={uploadedTargetedAudience.fileName}
            columnName={t('backoffice.promotion.id_tresor', 'ID Tresor')}
            data={uploadedTargetedAudience.rows}
            loadMoreBy={itemCount}
            promotion={promotion}
            setPromotion={setPromotion}
            fileId={uploadedTargetedAudience.fileId}
            dealers={true}
            setCurrentStep={() => undefined}
          />
        </Flex>
      )}
      <MarginBox mt={30} />
      <Flex>
        <StepButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalItems={totalSteps}
          nextEnabled={validateStep()}
        />
      </Flex>
    </>
  );
};
