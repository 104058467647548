import { ServiceOperationLocal } from 'domains/maintenancePlan/MaintenancePlan.types';

export function concatArrays(
  array1: ServiceOperationLocal[] | undefined,
  array2: ServiceOperationLocal[] | undefined,
): ServiceOperationLocal[] {
  if (Array.isArray(array1) && Array.isArray(array2)) {
    return array1.concat(array2);
  } else if (Array.isArray(array1)) {
    return array1;
  } else if (Array.isArray(array2)) {
    return array2;
  } else {
    return [];
  }
}
