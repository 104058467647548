import styled from 'styled-components';
import { Box, Flex } from 'UI';
import { theme } from '../../styles';

export const SBackground = styled.div<{ image: string }>`
  background-image: url(${({ image }) => `/images/${image}`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
`;

export const Footer = styled(Flex)`
  position: relative;
`;

export const Content = styled(Flex)`
  position: absolute;
  left: 10%;
  height: 100%;
`;

export const STextWrapper = styled.div`
  padding: 15px 0 30px 30px;
`;

export const SBox = styled(Box)<{ isActive: boolean }>`
  box-shadow: 0 4px 10px 0 ${({ theme }) => theme.color.shadow_2};
  ${({ isActive }) => isActive && `border: solid 2px ${theme.color.blue}`};
  border-radius: 4px;
`;
