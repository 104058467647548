import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderListReferenceRowError } from '@1po/1po-bff-fe-spec/generated/order_list/model/OrderListReferenceRowError';
import { List } from 'antd';
import { TFunction } from 'i18next';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import {
  getOrderListFileUploadErrorRows,
  setOrderListFileModalStatus,
  setOrderListFileReferencesIsModalOpen,
} from 'domains/orderList/OrderList.store';
import { ErrorAlert, ErrorDetails } from 'pages/CartPage/CartStep/FileUpload/FileReferencesRowsErrors.styled';
import { BlackButton, Box, Flex, MarginBox, Text } from 'UI';

type rowError = OrderListReferenceRowError['errorCode'];

interface EListI {
  data: OrderListReferenceRowError[];
}

function EList(props: EListI) {
  const { t } = useTranslation();
  return (
    <List
      dataSource={props.data}
      renderItem={(item) => (
        <List.Item>
          <Text type={'text_bold'}>{`${t('common.row', 'Row')} ${item.rowNumber}`}</Text>
          <Box width={5} />
          <Text type={'light_14_black_65'}> {translateErrorCode(item.errorCode, t)}</Text>
          <Flex />
        </List.Item>
      )}
      split={false}
    />
  );
}

function translateErrorCode(code: rowError, t: TFunction): string | null {
  switch (code) {
    case 'NO_REFERENCE':
      return t('order_list.file_upload.row.no_reference', 'Row without reference');
    case 'UNKNOWN_REFERENCE':
      return t('order_list.file_upload.row.unknown_reference', 'Row with unknown reference');
  }
  return null;
}

export default function OrderListReferencesRowsErrors() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const errorRows = useSelector(getOrderListFileUploadErrorRows);

  function handleTryAgain() {
    dispatch(setOrderListFileReferencesIsModalOpen(true));
    dispatch(setOrderListFileModalStatus(NO_FILE));
  }

  return (
    <Flex direction={'column'} minWidth={300} align={'center'}>
      <MarginBox mt={64}>
        <Box width={900}>
          <ErrorAlert
            message={
              <Flex direction={'row'} justify={'space-between'}>
                <Text type={'light_14_black_65'}>
                  {t(
                    'common.file_upload.items_could_not_be_added',
                    'One or several items in your file need to be checked.',
                  )}
                </Text>
              </Flex>
            }
            type={'error'}
            showIcon
          />
          <ErrorDetails>
            <Box align={'left'}>
              <Text type={'text'} displayStyle={'error'}>
                {t('common.details', 'Details')}
              </Text>
              {errorRows.length > 0 && <EList data={errorRows} />}
            </Box>
            <Flex justify={'flex-end'}>
              <MarginBox ml={15} />
              <BlackButton stretch={false} size={'middle'} onClick={() => handleTryAgain()}>
                {t('common.try_again', 'Try again')}
              </BlackButton>
            </Flex>
          </ErrorDetails>
        </Box>
      </MarginBox>
    </Flex>
  );
}
