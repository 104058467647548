/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Language as TargetLanguage,
  TextSection,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import LanguageSelect from 'components/LanguageSelect';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { MarginBox, Text } from 'UI';
import { Language, webLanguages } from 'utils/i18n/Language';
import { LanguageTextSection } from './LanguageTextSection';

function getSelectedLanguages(textSection: TextSection[] | undefined) {
  return (
    textSection?.reduce((result: Language[], section) => {
      if (section.language) {
        const lang = Language.findByKey(webLanguages, section.language);
        if (lang) {
          result.push(lang);
        }
      }
      return result;
    }, []) ?? []
  );
}

export const TextSectionWrapper = ({
  information,
  setInformation,
}: {
  information: CreateInformationModel | CreateInformationTemplateModel;
  setInformation: (informationToCreate: CreateInformationModel | CreateInformationTemplateModel) => void;
}) => {
  const { t } = useTranslation();
  const languagesOptions = Language.listWebLanguages();
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>(getSelectedLanguages(information.textSection));

  useEffect(() => {
    setSelectedLanguages(getSelectedLanguages(information.textSection));
  }, [setSelectedLanguages, information.textSection]);

  return (
    <>
      <Text type={'h2'}>
        {t('backoffice.information.create_information.information_language', 'Information language')}
      </Text>
      <MarginBox mt={15} />
      <LanguageSelect
        languageList={languagesOptions}
        selected={selectedLanguages}
        onSelect={(l) => {
          setSelectedLanguages((old) => [...old, l]);
          setInformation({
            ...information,
            textSection:
              information.textSection?.length > 0
                ? [...information.textSection, { language: l.key as TargetLanguage }]
                : [{ language: l.key as TargetLanguage }],
          });
        }}
        onDeselect={(l) => {
          setSelectedLanguages((old) => old.filter((o) => o.key !== l.key));
          setInformation({
            ...information,
            textSection: information.textSection?.filter((section) => section.language !== l.key),
          });
        }}
      />
      <MarginBox mt={30} />
      <LanguageTextSection information={information} setInformation={setInformation} />
    </>
  );
};
