import React from 'react';
import Banner from 'pages/UniversalProductsPage/Banner';
import Header from 'pages/UniversalProductsPage/Header';
import Menu from 'pages/UniversalProductsPage/Menu';
import { Flex } from 'UI';

const LandingPage = () => {
  return (
    <Flex direction={'column'} gap={30}>
      <Header />
      <Banner />
      <Menu />
    </Flex>
  );
};
export default LandingPage;
