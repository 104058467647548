import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { CardFlex, StepLineFlex } from 'components/MotrioWarranty/step/WarrantyStepper.styled';
import { theme } from 'styles';
import { MarginBox, StatusDot, StepData, Text } from 'UI';

export function getStepperData(t: TFunction): StepData[] {
  return [
    {
      title: t('motrio.warranty.step.type_of_guarantee', 'Type of guarantee'),
    },
    {
      title: t('motrio.warranty.step.documents', 'Documents'),
    },
    {
      title: t('motrio.warranty.step.guaranteed_diagnostic_sheet', 'Guaranteed diagnostic sheet'),
    },
  ];
}

interface WarrantyStepperProps {
  currentStep: number;
  setCurrentStep?: (x: number) => void;
}

export function WarrantyStepper(props: WarrantyStepperProps) {
  const { t } = useTranslation();
  const stepperData = getStepperData(t);

  return (
    <CardFlex direction={'column'} size={0} background={theme.color.white}>
      <Text type={'h6'} disableGutter>
        {t('motrio.warranty.stepper.my_steps', 'My steps')}
      </Text>
      <MarginBox mt={15} />
      <Text type={'light_14_black_85'}>
        {t('motrio.warranty.stepper.my_steps.description', 'Select the step you want to return to')}
      </Text>
      <MarginBox mt={15} />
      {stepperData.map((d, key) => (
        <div key={key}>
          <StepLineFlex>
            <StatusDot color={key === props.currentStep ? 'clear_blue' : 'disabled'} />
            <MarginBox ml={18} />
            <Text type={key === props.currentStep ? 'link' : 'text_disabled'} noWrap>
              {d.title}
            </Text>
          </StepLineFlex>
          <MarginBox mt={18} />
        </div>
      ))}
    </CardFlex>
  );
}
