import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowsRightIcon, DotsIcon, ShapeIcon } from 'assets/icons';
import { SDiscount } from 'components/Page/Header/AdvertisementSpace/Banner.styled';
import { BANNER_HEIGHT } from 'components/Page/Header/AdvertisementSpace/InformationBanner';
import { Flex, Icon, MarginBox, Text } from 'UI';
import { textFormatter, useBreakpointSelectorFull, useLarge } from 'utils';

const LeftBannerSide = ({ title, discount, children }: { title: string; discount: number; children: ReactNode }) => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const large = useLarge();

  const showDecorations = breakpointSelectorFull({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: title.length < 45,
    xxl: true,
  });

  return (
    <>
      {showDecorations && (
        <MarginBox mt={20} ml={60}>
          <Icon IconComponent={DotsIcon} width={70} height={44} />
        </MarginBox>
      )}
      {showDecorations && (
        <MarginBox mt={5} ml={30}>
          <Icon IconComponent={ArrowsRightIcon} width={55} height={35} />
        </MarginBox>
      )}
      <MarginBox ml={large ? 30 : 10}>
        <Flex maxHeight={BANNER_HEIGHT}>
          <Text type={large ? 'h4_brand' : 'h6_brand'} ellipsis>
            {title}
          </Text>
        </Flex>
      </MarginBox>
      {showDecorations && (
        <MarginBox mb={-20} ml={12}>
          <Icon IconComponent={ShapeIcon} width={125} height={55} />
        </MarginBox>
      )}
      <MarginBox ml={large ? 30 : 10}>
        <SDiscount>
          <Text type={'h4_white'}>
            {t('backoffice.promotion.banner.discount', 'Get {{ discount }}% off', {
              discount: textFormatter.formatNumber(discount),
            })}
          </Text>
        </SDiscount>
      </MarginBox>
      <MarginBox mx={large ? 30 : 10}>
        <Text type={'h3_white'}>{children}</Text>
      </MarginBox>
    </>
  );
};
export default LeftBannerSide;
