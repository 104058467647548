/* eslint-disable max-len */
import { GetIAMAvailableModulesRequest } from '@1po/1po-bff-fe-spec/generated/catalog/available_modules/requerst/GetIAMAvailableModulesRequest';
import { GetIAMRepairMethodDetailRequest } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/request/GetIAMRepairMethodDetailRequest';
import { GetIAMServiceChecklistRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceChecklistRequest';
import { GetIAMServiceProposalOperationsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceProposalOperationsRequest';
import { GetIAMServiceProposalRequest } from '@1po/1po-bff-fe-spec/generated/catalog/service_operations/request/GetIAMServiceProposalRequest';
import {
  GET_IAM_AVAILABLE_MODULES_TREE_URL,
  GET_IAM_REPAIR_METHOD_DETAIL_URL,
  GET_IAM_SERVICE_CHECKLIST_URL,
  GET_IAM_SERVICE_PROPOSAL_OPERATIONS_URL,
  GET_IAM_SERVICE_PROPOSAL_URL,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendIAMServiceProposalsRequest(request: GetIAMServiceProposalRequest): WebSocketAction {
  return wsSendAction(GET_IAM_SERVICE_PROPOSAL_URL, request);
}

export function sendIAMServiceProposalOperationsRequest(
  request: GetIAMServiceProposalOperationsRequest,
): WebSocketAction {
  return wsSendAction(GET_IAM_SERVICE_PROPOSAL_OPERATIONS_URL, request);
}

export function sendIAMAvailableModulesRequest(request: GetIAMAvailableModulesRequest): WebSocketAction {
  return wsSendAction(GET_IAM_AVAILABLE_MODULES_TREE_URL, request);
}

export function sendIAMServiceChecklistRequest(request: GetIAMServiceChecklistRequest): WebSocketAction {
  return wsSendAction(GET_IAM_SERVICE_CHECKLIST_URL, request);
}

export function sendIAMRepairMethodDetailRequest(request: GetIAMRepairMethodDetailRequest): WebSocketAction {
  return wsSendAction(GET_IAM_REPAIR_METHOD_DETAIL_URL, request);
}
