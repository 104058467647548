import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { trackAppEvent } from 'app/AppTracker';
import { ClientView, GarageView, getSparePartsView, setSparePartsView } from 'domains/user';
import { TRACKING_EVENT_CLIENT_VIEW, TRACKING_EVENT_GARAGE_VIEW } from 'utils/eventTracker/EventTracker.types';
import { Tab, TabsTitle } from './Tabs';
import { SViewTabs } from './Tabs.styled';

const ViewTabs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sparePartsView = useSelector(getSparePartsView);

  const handleOnChange = (value: string) => {
    if (value === GarageView && sparePartsView !== GarageView) {
      trackAppEvent(TRACKING_EVENT_GARAGE_VIEW);
    }
    if (value === ClientView && sparePartsView !== ClientView) {
      trackAppEvent(TRACKING_EVENT_CLIENT_VIEW);
    }
    dispatch(setSparePartsView(value));
  };

  return (
    <SViewTabs type={'card'} activeKey={sparePartsView} onChange={handleOnChange}>
      <Tab
        tab={<TabsTitle data-cy={'button-garage-view'}>{t('common.view.garage_view', 'Garage View')}</TabsTitle>}
        key={GarageView}
      />
      <Tab
        tab={<TabsTitle data-cy={'button-client-view'}>{t('common.view.client_view', 'Client View')}</TabsTitle>}
        key={ClientView}
      />
    </SViewTabs>
  );
};

export default ViewTabs;
