import styled from 'styled-components';

export const IconWrapper = styled.span`
  display: flex;
  margin: 0 8px 0 8px;
`;

export const DarkHalfCard = styled.div`
  height: 40px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.color.brand_black};
  padding: 7px 15px 7px 15px;
`;
