import { Table } from 'antd';
import styled, { css } from 'styled-components';
import Popover from 'components/Popover';
import { theme } from 'styles';
import { CleverInput, defaultBoxShadow, Flex } from 'UI';

const disableCss = css`
  color: ${theme.color.grey75};
  fill: ${theme.color.grey75};
`;

export const STable = styled(Table)`
  flex: 1;

  .disabled-row {
    pointer-events: none;

    span {
      ${disableCss};
    }

    input {
      ${disableCss};
    }

    svg {
      ${disableCss};
    }

    #trash_button_select svg {
      fill: ${theme.color.red};
    }

    #trash_button_select {
      pointer-events: initial;
    }
  }

  .highlighted-row {
    ${defaultBoxShadow}
  }

  thead > tr > th {
    text-align: center;
    background-color: ${({ theme }) => theme.color.grey95};
  }
` as typeof Table;

export const RefInput = styled(CleverInput)`
  min-width: 360px;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex: 1;
`;

export const SPopover = styled(Popover)`
  padding: 0;

  .ant-popover-arrow {
    display: none;
  }
`;

export const OverlayBox = styled.div`
  margin-left: 16px;

  .ant-checkbox {
    z-index: 1 !important;
  }
`;

const attachToVehicleCSS = css`
  flex: 0 0 auto;
  padding: 0 24px 0 24px;
  height: 45px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey95};
`;

export const AttachToVehicleHeader = styled(Flex)`
  ${attachToVehicleCSS}
`;

export const AttachToVehicleContent = styled(Flex)`
  ${attachToVehicleCSS};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }
`;

export const ExternalStrip = styled.div`
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: ${theme.color.brand_black};
  top: 0;
  left: 0;
`;

export const TireNameContainer = styled(Flex)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.brand_black};
  }
`;
