import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Popover as AntdPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { theme } from 'styles';
import { Flex, Text } from 'UI';
import { CloseIconWrapper, SPopover } from './Popover.styled';

export function AntdPopoverWrapper({ className, children, ...props }: PopoverProps) {
  return (
    <AntdPopover {...props} overlayClassName={className}>
      {children}
    </AntdPopover>
  );
}

const Title = ({ title, setIsVisible }: { title?: string; setIsVisible: (visible: boolean) => void }) => {
  if (!title) {
    return null;
  }
  return (
    <Flex justify={'space-between'} align={'center'}>
      <Text type={'popover_title'}>{title}</Text>
      <CloseIconWrapper onClick={() => setIsVisible(false)}>
        <CloseOutlined />
      </CloseIconWrapper>
    </Flex>
  );
};

export interface PopoverPropsLocal extends PopoverProps {
  title?: string;
  padding?: number;
  setVisible?: (visible: boolean) => void;
  onVisibleChange?: (visible: boolean) => void;
  arrowColor?: string;
}

export default function Popover({
  id,
  title,
  padding,
  children,
  visible,
  onVisibleChange,
  showArrow,
  arrowColor = theme.color.white,
  zIndex,
  ...props
}: PopoverPropsLocal) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [lastId, setLastId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (lastId !== id) {
      setIsVisible(false);
      setLastId(id);
    }
  }, [id, lastId]);

  useEffect(() => {
    if (visible !== undefined) {
      setIsVisible(visible);
    }
  }, [id, visible]);

  function handleVisibleChange(newValue: boolean) {
    setIsVisible(newValue);
    onVisibleChange !== undefined && onVisibleChange(newValue);
  }

  return (
    <SPopover
      {...props}
      title={title && <Title title={title} setIsVisible={setIsVisible} />}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      padding={padding}
      showArrow={showArrow}
      arrowColor={arrowColor}
      zIndex={zIndex}
    >
      <div style={{ display: 'flex' }}>{children}</div>
    </SPopover>
  );
}
