import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { VehicleExplodedViewDetail } from 'domains/catalog/Catalog.types';
import { Box, sentenceCase, Text } from 'UI';
import { ListItem, ListTitle, PartsListCard } from './CategoriesMenu.styled';

interface CategoriesMenuProps {
  setHighlightedPartNodeId: (index: string | null) => void;
  enlarged: boolean;
  highlightedPartNodeId: string | null;
  pathname: string;
  details: VehicleExplodedViewDetail[] | undefined;
}

const CategoriesMenu = ({
  enlarged,
  highlightedPartNodeId,
  setHighlightedPartNodeId,
  pathname,
  details,
}: CategoriesMenuProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  return enlarged ? (
    <>
      <Box width={30} />
      <PartsListCard
        title={
          <ListTitle>{t('catalog.parts.category.car_parts.available_categories', 'Available categories')}</ListTitle>
        }
      >
        {details?.map(({ index, label, nodeId: partNodeId }) => (
          <ListItem
            key={`parts-list-item-${partNodeId}`}
            id={`parts-list-item-${partNodeId}`}
            $hovered={highlightedPartNodeId === partNodeId}
            onMouseOver={() => {
              setHighlightedPartNodeId(partNodeId);
            }}
            onMouseLeave={() => {
              setHighlightedPartNodeId(null);
            }}
            onClick={() => {
              history.push({
                pathname: `${pathname}/${partNodeId}`,
                search: location.search,
              });
            }}
          >
            <Text type={'light_14_black_85'} cursor={'pointer'}>
              {`${index}. ${sentenceCase(label)}`}
            </Text>
          </ListItem>
        ))}
      </PartsListCard>
    </>
  ) : null;
};

export default CategoriesMenu;
