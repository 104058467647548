import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { LaborTime as EstimateLaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/LaborTime';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getLastSearchedVehicleKey, getSearchVehicleResult } from 'domains/catalog/Catalog.store';
import {
  convertCatalogTestLaborTimesToEstimateLaborTimes,
  convertDHLaborTimeToEstimateLaborTime,
} from 'domains/estimate/Estimate.mapper';
import {
  addCatalogLaborTime,
  getCatalogLaborTimes,
  getCurrentEstimateId,
  getEstimateSettings,
  setSelectedTab,
  updateLaborTime,
} from 'domains/estimate/Estimate.store';
import { EstimateTabName } from 'domains/estimate/Estimate.types';
import { useFetchLaborTimes } from 'domains/laborTime/LaborTime.requests';
import { getLaborTimesByIds, getLaborTimeSearchStatusesMapByIds } from 'domains/laborTime/LaborTime.store';
import { LaborTimeLocal } from 'domains/laborTime/LaborTime.types';
import { getCurrency } from 'domains/user';
import {
  EstimateLaborTimeDetail,
  ExpandIcon,
  tableColumns,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSection';
import { STable } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
// eslint-disable-next-line max-len
import { SReferenceCardLaborTimesWrapper } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCard.styled';
import { CenteredSpin, MarginBox, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { FOUND, getArrayWithoutUndefinedMembers, getData, hasData, LOADING } from 'utils';

const ReferenceCardLaborTimesWrapper = ({
  referenceNumber,
  laborTimeIds,
  isProduct,
  isSquared = false,
}: {
  referenceNumber: string;
  laborTimeIds: string[];
  isProduct?: boolean;
  isSquared?: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useParams<{
    query: string;
  }>();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const { t } = useTranslation();
  const currency = useSelector(getCurrency);
  const estimateSettings = useSelector(getEstimateSettings);
  const vehicle = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const vehicleDetail = vehicle?.vehicleDetail;

  const laborTimes = useSelector((state: RootState) => getLaborTimesByIds(state, { vehicleKey, laborTimeIds }));
  useFetchLaborTimes(vehicleKey, laborTimeIds);
  const laborTimesSearchStatusMap = useSelector((state: RootState) =>
    getLaborTimeSearchStatusesMapByIds(state, {
      vehicleKey,
      laborTimeIds,
    }),
  );

  const laborTimeSearchStatus = laborTimeIds.reduce((acc, next) => {
    const currStatus = laborTimesSearchStatusMap?.get(next);
    return currStatus === LOADING || acc === LOADING ? LOADING : FOUND;
  }, FOUND);

  const foundLaborTimes = getArrayWithoutUndefinedMembers((laborTimes ?? []).map((lt) => lt?.data));

  const estimateId = useSelector(getCurrentEstimateId);
  const currentLaborTimes = useSelector((state: RootState) => getCatalogLaborTimes(state, estimateId));
  const currentQuantityMap = currentLaborTimes.reduce(
    (acc: Map<string, EstimateLaborTimeDetail>, next: EstimateLaborTime) =>
      acc.set(next.code, { itemId: next.itemId, quantity: next.quantity }),
    new Map<string, EstimateLaborTimeDetail>(),
  );
  const [estimateNotificationConfig, setEstimateNotificationConfig] = useState({
    containsTests: false,
  });

  const isApplicabilityAvailable = laborTimes?.find((lt) => lt?.data && lt.data.applicability.length > 0) !== undefined;

  const addLaborTimeToEstimate = (laborTimeLocal: LaborTimeLocal, testLaborTimeToQuantityMap: Map<string, number>) => {
    if (vehicleDetail && vehicleDetail.dataHubVehicle && hasData(estimateSettings)) {
      setEstimateNotificationConfig({ containsTests: testLaborTimeToQuantityMap.size > 0 });
      notifyTop(
        'success',
        <>
          {estimateNotificationConfig.containsTests ? (
            <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.added_operation_time_with_test'}>
              {"Operation's labor time has been added to your estimate with required test labor time."}
            </Trans>
          ) : (
            <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.added_operation_time'}>
              {"Operation's labor time has been added to your estimate."}
            </Trans>
          )}
        </>,
        undefined,
        <NotificationLink
          onClick={() => {
            dispatch(setSelectedTab(EstimateTabName));
            history.push(ROUTER_ESTIMATE);
          }}
        >
          <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.see_estimate'}>
            {'See estimate'}
          </Trans>
        </NotificationLink>,
      );
      dispatch(
        addCatalogLaborTime({
          laborTime: convertDHLaborTimeToEstimateLaborTime(laborTimeLocal, estimateSettings),
          testLaborTimes: convertCatalogTestLaborTimesToEstimateLaborTimes(
            laborTimeLocal.subLaborTimes?.testLaborTimes ?? [],
            testLaborTimeToQuantityMap,
            estimateSettings,
          ),
        }),
      );
    }
  };

  const updateLaborTimeQuantity = (itemId: string, newQuantity: number) => {
    dispatch(
      updateLaborTime({
        itemId,
        newValue: String(newQuantity),
        field: 'QUANTITY',
      }),
    );
  };

  const columns = tableColumns(
    t,
    currentQuantityMap,
    addLaborTimeToEstimate,
    updateLaborTimeQuantity,
    isApplicabilityAvailable,
    currency,
    getData(estimateSettings),
    history,
  );

  return (
    <SReferenceCardLaborTimesWrapper direction={'column'} isProduct={isProduct} isSquared={isSquared}>
      <MarginBox mx={15} my={10}>
        <Text type={'h3'}>
          <Trans i18nkey={'catalog.parts.category.car_parts.labor_time'}>{'Labor time'}</Trans>
        </Text>
      </MarginBox>
      <DataContainer
        data={laborTimeSearchStatus}
        Loading={() => (
          <MarginBox mb={15}>
            <CenteredSpin />
          </MarginBox>
        )}
        ErrorState={() => (
          <ErrorWithLabel
            label={t(
              'common.labor_times.backend_error',
              'Labor times temporarily unavailable, please try again later.',
            )}
          />
        )}
      >
        {laborTimeIds && (
          <STable<LaborTimeLocal>
            columns={columns}
            dataSource={foundLaborTimes}
            pagination={false}
            rowKey={(row) => `${referenceNumber}_${row.uuid}`}
            expandable={{
              expandIcon: ExpandIcon,
              childrenColumnName: 'subtable',
              indentSize: 0,
            }}
            size={'large'}
            showHeader={false}
            bordered={true}
          />
        )}
        <MarginBox mt={15} />
      </DataContainer>
    </SReferenceCardLaborTimesWrapper>
  );
};

export default ReferenceCardLaborTimesWrapper;
