/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { STable } from 'pages/EstimatePage/EstimatePage.styled';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { Flex, Text, WithTooltip } from 'UI';
import { DiscountPromotionPublicationTableData } from './PublicationStep.types';

export const TableDiscount = ({ data }: { data: DiscountPromotionPublicationTableData }) => {
  const { t } = useTranslation();

  const expandedRowRender = () => {
    const expandedCommercialFamilyColumns = () => {
      return [
        {
          title: GetTitle(t('backoffice.promotion.publication_preview.commercial_family', 'Commercial family')),
          dataIndex: 'items',
          key: 'items',
          width: '50%',
          render: function renderCommercialFamily(commercialFamilyCodes: string) {
            return (
              <Flex justify={'center'} align={'center'}>
                <WithTooltip title={commercialFamilyCodes}>
                  <Text type={'text'} ellipsis={true}>
                    {commercialFamilyCodes}
                  </Text>
                </WithTooltip>
              </Flex>
            );
          },
        },
        {
          title: GetTitle(t('backoffice.promotion.publication_preview.discount', 'Discount')),
          dataIndex: 'discountCommercialFamily',
          key: 'discountCommercialFamily',
          width: '50%',
          render: function renderDiscount(discount: string) {
            const discountText = `${discount}%`;
            return (
              <Flex justify={'center'} align={'center'}>
                <WithTooltip title={discountText}>
                  <Text type={'text'}> {discountText} </Text>
                </WithTooltip>
              </Flex>
            );
          },
        },
      ];
    };
    const expandedReferencesColumns = () => {
      return [
        {
          title: GetTitle(t('backoffice.promotion.publication_preview.references', 'References')),
          dataIndex: 'fileName',
          key: 'fileName',
          width: '50%',
          render: function renderReferences(references: string) {
            return (
              <Flex justify={'center'} align={'center'}>
                <WithTooltip title={references}>
                  <Text type={'text'}>{references}</Text>
                </WithTooltip>
              </Flex>
            );
          },
        },
        {
          title: GetTitle(t('backoffice.promotion.publication_preview.discount', 'Discount')),
          dataIndex: 'discountReferences',
          key: 'discountReferences',
          width: '50%',
          render: function renderDiscount(discount: string) {
            const discountText = `${discount}%`;
            return (
              <Flex justify={'center'} align={'center'}>
                <WithTooltip title={discountText}>
                  <Text type={'text'}> {discountText} </Text>
                </WithTooltip>
              </Flex>
            );
          },
        },
      ];
    };

    const countValidReferenceFiles = data.referencesFiles.reduce((acc, item) => {
      return item.fileName ? acc + 1 : acc;
    }, 0);

    return (
      <Flex direction={'column'}>
        {data.commercialFamilyCodes.length > 0 && (
          <STable
            key={'subTableCommercialFamilyCodes'}
            columns={expandedCommercialFamilyColumns()}
            dataSource={data.commercialFamilyCodes}
            pagination={false}
          />
        )}
        {countValidReferenceFiles > 0 && (
          <STable
            key={'subTableReferences'}
            columns={expandedReferencesColumns()}
            dataSource={data.referencesFiles}
            pagination={false}
          />
        )}
      </Flex>
    );
  };
  const getDataColumns = () => {
    return [
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.promotion_title', 'Promotion title')),
        dataIndex: 'title',
        key: 'title',
        width: 205,
        render: function renderTitle(title: string) {
          return (
            <Flex justify={'center'} align={'center'}>
              <WithTooltip title={title}>
                <Text type={'text'} ellipsis>
                  {title}
                </Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.price_option', 'Price option')),
        dataIndex: 'discountType',
        key: 'discountType',
        render: function renderDiscountType(discountType: string) {
          const discountTypeText = t(
            'backoffice.promotion.publication_preview.discount_type',
            'Based on {{discountType}}',
            { discountType },
          );
          return (
            <Flex minWidth={120} justify={'center'} align={'center'}>
              <WithTooltip title={discountTypeText}>
                <Text type={'text'}> {discountTypeText} </Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.publication_preview.period', 'Period')),
        dataIndex: 'period',
        key: 'period',
        width: 90,
        render: function renderPeriod(period: { publicationStart: string; publicationEnd: string }) {
          const periodTooltipText = `${period.publicationStart} - ${period.publicationEnd}`;
          return (
            <Flex minWidth={90} direction={'column'} justify={'center'} align={'center'}>
              <WithTooltip title={periodTooltipText}>
                <Text type={'text'}>{period.publicationStart}</Text>
              </WithTooltip>
              <WithTooltip title={periodTooltipText}>
                <Text type={'text'}>{period.publicationEnd}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
      {
        title: GetTitle(t('backoffice.promotion.target', 'Target')),
        dataIndex: 'targetedAudience',
        key: 'targetedAudience',
        render: function renderTarget(targetedAudience: { dealerTypes: string; fileName: string }) {
          const targetedAudienceToolTipTitle = `${targetedAudience.dealerTypes} ${targetedAudience.fileName}`;
          return (
            <Flex direction={'column'} justify={'center'} align={'center'}>
              <WithTooltip title={targetedAudienceToolTipTitle}>
                <Text type={'text'}>{targetedAudience.dealerTypes}</Text>
              </WithTooltip>
              <WithTooltip title={targetedAudienceToolTipTitle}>
                <Text type={'text'}>{targetedAudience.fileName}</Text>
              </WithTooltip>
            </Flex>
          );
        },
      },
    ];
  };
  if (!data) {
    return <></>;
  }
  return (
    <STable
      key={'promotions_discount_table'}
      dataSource={[data]}
      columns={getDataColumns()}
      pagination={false}
      expandable={{
        expandIcon: () => <></>,
        expandedRowRender,
        defaultExpandAllRows: true,
        indentSize: 0,
      }}
    />
  );
};
