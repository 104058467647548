import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyStoreTiresEnabled } from 'domains/appContext/AppContext.store';
import {
  getBundlesRequestSaga,
  getBundlesSections,
  getMyStoreTires,
  subscribeTiresRequestSaga,
} from 'domains/myStore/MyStore.store';
import { getUserRights, UserRole } from 'domains/user';
import { getData, hasUserAnyRight } from 'utils';

export const useFetchMyStoreBundles = (): void => {
  const dispatch = useDispatch();
  const bundles = useSelector(getBundlesSections);
  useEffect(() => {
    if (!bundles.sections.searchStatus) {
      dispatch(getBundlesRequestSaga());
    }
  }, [bundles, dispatch]);
};

export const useFetchMyStoreTires = (): void => {
  const dispatch = useDispatch();
  const myStoreTires = useSelector(getMyStoreTires);
  const userRights = useSelector(getUserRights);
  const myStoreTiresEnabled = useSelector(getMyStoreTiresEnabled);

  useEffect(() => {
    const isAuthorized = hasUserAnyRight([UserRole.R2, UserRole.R3, UserRole.R3_MOTRIO], getData(userRights));

    if (myStoreTiresEnabled && isAuthorized && !myStoreTires.brands.searchStatus) {
      dispatch(subscribeTiresRequestSaga());
    }
  }, [dispatch, myStoreTiresEnabled, myStoreTires, userRights]);
};
