/* eslint-disable max-len */
import {
  ADD_REFERENCE_BY_REF_NUMBER_URL,
  ADD_REFERENCES_URL,
  ATTACH_OTHER_REFERENCE_CANCEL_URL,
  ATTACH_OTHER_REFERENCE_CONFIRM_URL,
  ATTACH_OTHER_REFERENCE_START_URL,
  GET_URL,
  NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS,
  REMOVE_REFERENCE_URL,
  REMOVE_REFERENCES_URL,
  REVERT_LAST_REMOVAL_URL,
  UPDATE_ALL_REFERENCE_URGENCY_FLAGS_URL,
  UPDATE_ORDER_MARK_URL,
  UPDATE_REFERENCE_MARK_URL,
  UPDATE_REFERENCE_QUANTITY_URL,
  UPDATE_REFERENCE_URGENCY_FLAG_URL,
  UPLOAD_REFERENCES_FILE_URL,
} from '@1po/1po-bff-fe-spec/generated/basket/BasketURLs';
import { AddReferenceByRefNumber } from '@1po/1po-bff-fe-spec/generated/basket/request/AddReferenceByRefNumber';
import { AddReferences } from '@1po/1po-bff-fe-spec/generated/basket/request/AddReferences';
import { MoveOtherSectionReferenceToVehicle } from '@1po/1po-bff-fe-spec/generated/basket/request/MoveOtherSectionReferenceToVehicle';
import { NotifyUserAboutExpiredDiscounts } from '@1po/1po-bff-fe-spec/generated/basket/request/NotifyUserAboutExpiredDiscounts';
import { RemoveReferences } from '@1po/1po-bff-fe-spec/generated/basket/request/RemoveReferences';
import { RemoveSingleReference } from '@1po/1po-bff-fe-spec/generated/basket/request/RemoveSingleReference';
import { RevertLastReferencesRemoval } from '@1po/1po-bff-fe-spec/generated/basket/request/RevertLastReferencesRemoval';
import { RevertMoveOtherSectionReferenceToVehicle } from '@1po/1po-bff-fe-spec/generated/basket/request/RevertMoveOtherSectionReferenceToVehicle';
import { UpdateAllUrgencyFlags } from '@1po/1po-bff-fe-spec/generated/basket/request/UpdateAllUrgencyFlags';
import { UpdateOrderMark } from '@1po/1po-bff-fe-spec/generated/basket/request/UpdateOrderMark';
import { UpdateReferenceMark } from '@1po/1po-bff-fe-spec/generated/basket/request/UpdateReferenceMark';
import { UpdateReferenceQuantity } from '@1po/1po-bff-fe-spec/generated/basket/request/UpdateReferenceQuantity';
import { UpdateUrgencyFlag } from '@1po/1po-bff-fe-spec/generated/basket/request/UpdateUrgencyFlag';
import { UploadReferencesFile } from '@1po/1po-bff-fe-spec/generated/basket/request/UploadReferencesFile';
import { UserContext } from '@1po/1po-bff-fe-spec/generated/catalog/user_context/UserContext';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendAction } from 'utils/domainStore/api';

export function sendGetBasket(): WebSocketAction {
  return wsSendAction(GET_URL, null);
}

export function sendAddReferences(request: AddReferences): WebSocketAction {
  return wsSendAction(ADD_REFERENCES_URL, request);
}

export function sendAddReferenceByRefNumber(
  basketId: string | undefined,
  reference: string,
  userContext: UserContext,
): WebSocketAction {
  const request: AddReferenceByRefNumber = {
    basketId,
    referenceNumber: reference.trim(),
    userContext,
  };
  return wsSendAction(ADD_REFERENCE_BY_REF_NUMBER_URL, request);
}

export function sendRemoveReferencesRequest(payload: RemoveReferences): WebSocketAction {
  return wsSendAction(REMOVE_REFERENCES_URL, payload);
}

export function sendUpdateOrderMark(payload: UpdateOrderMark): WebSocketAction {
  return wsSendAction(UPDATE_ORDER_MARK_URL, payload);
}

export function sendSetReferenceMark(payload: UpdateReferenceMark): WebSocketAction {
  return wsSendAction(UPDATE_REFERENCE_MARK_URL, payload);
}

export function sendUpdateReferenceQuantity(payload: UpdateReferenceQuantity): WebSocketAction {
  return wsSendAction(UPDATE_REFERENCE_QUANTITY_URL, payload);
}

export function sendUploadReferencesFileRequest(payload: UploadReferencesFile): WebSocketAction {
  return wsSendAction(UPLOAD_REFERENCES_FILE_URL, payload);
}

export function sendUpdateUrgencyFlag(payload: UpdateUrgencyFlag): WebSocketAction {
  return wsSendAction(UPDATE_REFERENCE_URGENCY_FLAG_URL, payload);
}

export function sendUpdateAllUrgencyFlags(payload: UpdateAllUrgencyFlags): WebSocketAction {
  return wsSendAction(UPDATE_ALL_REFERENCE_URGENCY_FLAGS_URL, payload);
}

export function sendRevertLastRemoval(payload: RevertLastReferencesRemoval): WebSocketAction {
  return wsSendAction(REVERT_LAST_REMOVAL_URL, payload);
}

export function sendRemoveSingleReference(payload: RemoveSingleReference): WebSocketAction {
  return wsSendAction(REMOVE_REFERENCE_URL, payload);
}

export function attachOtherReferenceToVehicle(
  basketId: string,
  targetVehicleKey: string,
  otherSectionReference: string,
  userContext: UserContext,
): WebSocketAction {
  const request: MoveOtherSectionReferenceToVehicle = {
    userContext,
    otherSectionReference,
    targetVehicleKey,
    basketId,
  };
  return wsSendAction(ATTACH_OTHER_REFERENCE_START_URL, request);
}

export function sendRevertAttachOtherReference(payload: RevertMoveOtherSectionReferenceToVehicle): WebSocketAction {
  return wsSendAction(ATTACH_OTHER_REFERENCE_CANCEL_URL, payload);
}

export function attachOtherNonApplicableReferenceToVehicle(
  basketId: string,
  targetVehicleKey: string,
  otherSectionReference: string,
  userContext: UserContext,
): WebSocketAction {
  const request: MoveOtherSectionReferenceToVehicle = {
    userContext,
    otherSectionReference,
    targetVehicleKey,
    basketId,
  };
  return wsSendAction(ATTACH_OTHER_REFERENCE_CONFIRM_URL, request);
}

export function notifyUserAboutExpiredDiscounts(payload: NotifyUserAboutExpiredDiscounts): WebSocketAction {
  return wsSendAction(NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS, payload);
}
