import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCredentials } from 'domains/auth/Auth.store';
import { Link, Text, WhiteButton } from 'UI';
import { Country } from 'utils/i18n/Country';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';

export const isPasswordRetrievalAllowed = () => {
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  return Country.listPasswordRetrievalCountries().find((l) => l.key === browserCountry);
};

const WrapperComponent = ({ narrow, children }: { narrow: boolean; children: ReactNode }) =>
  narrow ? (
    <Text type={'light_14'} displayStyle={'link'} cursor={'pointer'} hoverUnderLine noWrap>
      {children}
    </Text>
  ) : (
    <WhiteButton stretch>
      <Text type={'text'}>{children}</Text>
    </WhiteButton>
  );

const ForgottenPassword = ({ narrow = false }: { narrow?: boolean }) => {
  const { t } = useTranslation();
  const credentials = useSelector(getCredentials);

  if (!credentials) {
    return <></>;
  }

  return (
    <Link to={credentials?.changePasswordLink} external newTab>
      <WrapperComponent narrow={narrow}>{t('login.forgot_password', 'Forgot password')}</WrapperComponent>
    </Link>
  );
};

export default ForgottenPassword;
