/* eslint-disable max-len */
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { AddOrderListReferencesResponse } from '@1po/1po-bff-fe-spec/generated/order_list/response/AddOrderListReferencesResponse';
import { OrderListReferencesFileUploadResponse } from '@1po/1po-bff-fe-spec/generated/order_list/response/OrderListReferencesFileUploadResponse';

import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { getBasketId } from 'domains/basket/Basket.store';
import { CartReferenceErrorRow } from 'domains/basket/Basket.types';
import { sendAddOrderListReferences, sendOrderListReferencesFileUploadRequest } from 'domains/orderList/OrderList.api';
import { getOrderListReferences } from 'domains/orderList/OrderList.mapper';
import * as actions from 'domains/orderList/OrderList.store';
import {
  getCreateOrderList,
  resetOrderListReferences,
  setCreateOrderListResponseData,
  setCreateOrderListSearchStatus,
  setOrderListFileErrorRows,
  setOrderListFileModalStatus,
  setOrderListFileReferencesIsModalOpen,
  setOrderListFileUploadStatus,
  setOrderListReferencesFromFile,
} from 'domains/orderList/OrderList.store';
import { getUserContext } from 'domains/user';
import { notifyTop } from 'UI';
import { AppTranslation, LOADING, sagaGuard, select } from 'utils';
import { UPLOADING_FILE } from '../../components/FileUpload/paramsByState';
import { FILE_UPLOADED } from '../electronicPartsRepair/ElectronicPartsRepair.types';

/* eslint-enable max-len */

export function* addReferenceListToCartRequest(): SagaIterator {
  const basketId = yield* select(getBasketId);
  const userContext = yield* select(getUserContext);
  const orderList = yield* select(getCreateOrderList);

  yield put(setCreateOrderListSearchStatus({ searchStatus: LOADING }));
  yield put(
    sendAddOrderListReferences({
      basketId,
      userContext,
      references: getOrderListReferences(orderList),
    }),
  );
}

export function* addReferenceListToCartResponse(action: WsResponse<AddOrderListReferencesResponse>): SagaIterator {
  const { errorRows } = action.payload;
  const orderList = yield* select(getCreateOrderList);
  const uploadedReferences = getOrderListReferences(orderList).length - errorRows.length;
  yield put(setCreateOrderListResponseData({ data: errorRows.map((row) => row as CartReferenceErrorRow) }));
  yield call(
    notifyTop,
    uploadedReferences > 0 ? 'success' : 'error',
    AppTranslation.t('order_list.add_to_cart.success', '{{ count }} references have been added to your cart.', {
      count: uploadedReferences,
    }) as string,
    null,
  );
  yield put(resetOrderListReferences());
}

export function* uploadFileOrderListSaga({ payload }: ReturnType<typeof actions.uploadFileOrderList>): SagaIterator {
  yield put(setOrderListFileModalStatus(UPLOADING_FILE));
  const userContext = yield* select(getUserContext);
  yield put(sendOrderListReferencesFileUploadRequest({ ...payload, userContext }));
}

export function* uploadFileOrderListReferencesResponse(
  action: WsResponse<OrderListReferencesFileUploadResponse>,
): SagaIterator {
  const { correctRows, errorRows } = action.payload;

  yield put(setOrderListReferencesFromFile({ references: correctRows }));
  yield put(setOrderListFileErrorRows(errorRows));
  yield put(setOrderListFileReferencesIsModalOpen(false));
  yield put(setOrderListFileModalStatus(FILE_UPLOADED));
  yield put(setOrderListFileUploadStatus(FILE_UPLOADED));
}

export function* OrderListSagas(): SagaIterator {
  yield takeEvery(actions.addReferenceListToCartRequest.type, sagaGuard(addReferenceListToCartRequest));
  yield takeEvery(actions.addReferenceListToCartResponse.type, sagaGuard(addReferenceListToCartResponse));
  yield takeEvery(actions.uploadFileOrderList.type, sagaGuard(uploadFileOrderListSaga));
  yield takeEvery(actions.uploadFileOrderListReferencesResponse.type, sagaGuard(uploadFileOrderListReferencesResponse));
}
