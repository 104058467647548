import { ListItemProps } from 'antd/lib/list';
import styled from 'styled-components';
import { Flex, Select } from 'UI';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const SSelect: new <T>() => Select<T> = styled(Select)`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
` as typeof Select;

export const RowDivider = styled(Flex)`
  width: 100%;
  padding: 20px 10px 30px 10px;
  border-bottom: ${({ theme }) => `${theme.color.grey95} 1px solid`};
`;

export const SListItem = styled.div<ListItemProps>`
  line-height: 30px;
  padding-left: 0px;
  margin-right: 15px;
  margin-top: 0px;

  ::before {
    content: '•';
    padding-right: 8px;
  }
`;
