/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Technicity } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/Technicity';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { Settings as EstimateSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Settings';
import { Document, Page, pdf, Text as PdfText, View } from '@react-pdf/renderer';
import { Column, LeadText, PageNumber, Subtitle, Text, Title } from 'components/Pdf/PdfUI';
import { components, fonts, styles } from 'components/Pdf/PdfUI.styles';
import { getTechnicityTier } from 'domains/catalog/Catalog.types';
import {
  GetIAMServiceOperationsResponseLocal,
  ServiceCategoryLocal,
  ServiceReferenceLocal,
} from 'domains/maintenancePlan/MaintenancePlan.types';
import { IAMReferenceLocal, ReferencePriceType } from 'domains/references';
import { getLaborTimeRate, MECHANICS } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import { VehicleContext } from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleContext';
import { theme } from 'styles';
import { textFormatter } from 'utils';

export const printMaintenancePlan = async (
  operationsData: GetIAMServiceOperationsResponseLocal | undefined,
  selectedReferencesByOperation: Map<string, ServiceReferenceLocal[]>,
  referencesPrices: Map<string, undefined | ReferencePriceType>,
  referencesData: IAMReferenceLocal[],
  estimateSettings: EstimateSettings | undefined,
  vehicleDetail: VehicleDetail | undefined,
  carImageUrl: string | undefined,
  currency: string,
) => {
  const pdfData: MaintenancePlanPdfData = {
    operationsData,
    selectedReferencesByOperation,
    referencesPrices,
    referencesData,
    estimateSettings,
    currency,
    date: new Date(),
    text: {
      title: <Trans i18nKey="catalog.maintenance.maintenance_plan_checklist.title">Maintenance plan list</Trans>,
      vehicle: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.vehicle">Vehicle</Trans>,
      date: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.date">Date</Trans>,
      operationsList: <Trans i18nKey="catalog.maintenance.new_maintenance_plan.operations_list">Operations list</Trans>,
    },
  };
  const blob = await pdf(
    <MaintenancePlanPdfPage data={pdfData} vehicle={vehicleDetail} carImageUrl={carImageUrl} />,
  ).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};

export const MaintenancePlanPdfPage = ({
  data,
  vehicle,
  carImageUrl,
}: {
  data: MaintenancePlanPdfData;
  vehicle: VehicleDetail | undefined;
  carImageUrl: string | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size={'A4'} style={styles.page}>
        <PageNumber />
        <Header data={data} />
        <VehicleContext vehicleDetail={vehicle} carImageUrl={carImageUrl} />
        {(data.operationsData?.mainOperations?.length ?? 0 > 0) &&
          data.operationsData?.mainOperations?.some((item) => item.operations.some((o) => o.selected)) && (
            <>
              <View style={{ marginTop: 10 }} />
              <Subtitle>{t('catalog.maintenance.operations.main', 'Main operations')}</Subtitle>
            </>
          )}
        {data.operationsData?.mainOperations?.map((o) => (
          <OperationBox key={o.id} operation={o} data={data} />
        ))}
        {(data.operationsData?.additionalOperations?.length ?? 0 > 0) &&
          data.operationsData?.additionalOperations?.some((item) => item.operations.some((o) => o.selected)) && (
            <>
              <View style={{ marginTop: 10 }} />
              <Subtitle>{t('catalog.maintenance.operations.additional', 'Additional operations')}</Subtitle>
            </>
          )}
        {data.operationsData?.additionalOperations?.map((o) => (
          <OperationBox key={o.id} operation={o} data={data} />
        ))}
      </Page>
    </Document>
  );
};

const TechnicityPrice = ({
  technicity,
  estimateSettings,
  time,
  currency,
}: {
  technicity: Technicity | undefined;
  estimateSettings: Settings | undefined;
  time?: number;
  currency: string | undefined;
}) => {
  // use default value 0 for pdf, when values not set in estimate settings - gear with link is visible in the overview
  const setTechnicity = getLaborTimeRate(MECHANICS, technicity, estimateSettings?.laborPriceList)?.price ?? '0';

  return (
    setTechnicity &&
    !isNaN(parseFloat(setTechnicity)) &&
    time &&
    textFormatter.formatCurrency(parseFloat(setTechnicity) * time, currency)
  );
};

const ALIGN_ITEMS_START = 'flex-start';
const OperationBox = ({ operation, data }: { operation: ServiceCategoryLocal; data: MaintenancePlanPdfData }) => {
  const { t } = useTranslation();
  const refPrices = data.referencesPrices;
  const refs = data.selectedReferencesByOperation;
  const currency = data.currency;

  return (
    <>
      <View
        style={{
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          alignItems: ALIGN_ITEMS_START,
          justifyContent: ALIGN_ITEMS_START,
        }}
      >
        {operation.operations
          ?.filter((o) => o.selected)
          .map((o) => (
            <>
              <View
                wrap={false}
                key={o.id}
                style={{
                  width: '100%',
                  marginRight: 0,
                  paddingLeft: 12,
                  marginTop: 16,
                  height: 40,
                  minHeight: 40,
                  alignItems: ALIGN_ITEMS_START,
                  justifyContent: ALIGN_ITEMS_START,
                  borderColor: theme.color.grey30_light_130,
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderRadius: 4,
                }}
              >
                <View style={[styles.row, { minHeight: 27 }, { marginRight: 20 }]}>
                  <View style={[styles.column, { minWidth: 350, maxWidth: 350 }]}>
                    <PdfText key={o.id} style={[fonts.itemNameTitle, { paddingTop: 13 }]}>
                      • {o.label} - {o.repairLabel}
                    </PdfText>
                  </View>
                  <View style={[styles.column, { paddingTop: 0 }]}>
                    <View style={[styles.col, { width: 60 }]}>
                      <PdfText style={[fonts.input_text, fonts.bold, { marginTop: 6 }]}>
                        {t('catalog.maintenance.maintenance_plan.labor_time', 'Labor time')}
                      </PdfText>
                    </View>
                    <View
                      style={[
                        styles.col,
                        {
                          width: 60,
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      ]}
                    >
                      <PdfText style={[fonts.lead_text, { marginTop: 2 }]}>{o.time}</PdfText>
                    </View>
                  </View>
                  <View style={styles.column}>
                    <View style={[styles.col, { width: 60 }]}>
                      <PdfText style={[fonts.input_text, fonts.bold, { marginTop: 6 }]}>
                        {t('catalog.maintenance.maintenance_plan.technicity', 'Technicity')}
                      </PdfText>
                    </View>
                    <View
                      style={[
                        styles.col,
                        {
                          width: 60,
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      ]}
                    >
                      <PdfText style={[fonts.lead_text, { marginTop: 2 }]}>{getTechnicityTier(o.technicity)}</PdfText>
                    </View>
                  </View>
                  <View style={styles.column}>
                    <View style={[styles.col, { width: 60 }]}>
                      <PdfText style={[fonts.input_text, fonts.bold, { marginTop: 6 }]}>
                        {t('catalog.maintenance.maintenance_plan.price_vat_excluded', 'Price VAT Exclu.')}
                      </PdfText>
                    </View>
                    <View
                      style={[
                        styles.col,
                        {
                          width: 60,
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      ]}
                    >
                      <PdfText style={[fonts.lead_text, { marginTop: 2 }]}>
                        <TechnicityPrice
                          technicity={o.technicity}
                          estimateSettings={data.estimateSettings}
                          currency={data.currency}
                          time={o.time}
                        />
                      </PdfText>
                    </View>
                  </View>
                </View>
              </View>
              {refs.get(o.id) && (
                <ReferenceTable
                  data={(refs.get(o.id) ?? []).map((r) => {
                    return {
                      ...r,
                      description:
                        data.referencesData.find((item) => item.referenceNumber === r.referenceNumber)?.designation ??
                        '',
                      price: refPrices.get(r.referenceNumber)?.clientView?.recommendedPriceVatExcluded ?? '',
                    } as RefRow;
                  })}
                  columns={[
                    {
                      title: t('catalog.maintenance.maintenance_plan.references', 'References'),
                      render: () => <View style={{ height: 30 }}></View>,
                      renderNode: (item) => (
                        <View
                          style={{
                            marginLeft: 12,
                            height: 30,
                            maxHeight: 30,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <PdfText
                            style={fonts.tableHeadline}
                          >{`${item.referenceNumber} - ${item.description}`}</PdfText>
                        </View>
                      ),
                      width: 420,
                    },
                    {
                      title: t('catalog.maintenance.maintenance_plan.reference_quantity', 'Qty'),
                      render: () => <></>,
                      renderNode: (item) => (
                        <PdfText style={[fonts.tableHeadline, { marginLeft: 20 }]}>{item.quantity}</PdfText>
                      ),
                      width: 40,
                    },
                    {
                      title: t('catalog.maintenance.maintenance_plan.price_vat_excluded', 'Price VAT Exclu.'),
                      render: () => <></>,
                      renderNode: (item) => (
                        <View style={[{ width: 80, alignItems: 'flex-end', marginRight: 22 }]}>
                          <PdfText style={fonts.tableHeadline}>
                            {textFormatter.formatCurrency(parseFloat(item.price), currency)}
                          </PdfText>
                        </View>
                      ),
                      width: 90,
                    },
                  ]}
                />
              )}
            </>
          ))}
      </View>
    </>
  );
};

export const Header = ({ data }: { data: MaintenancePlanPdfData }) => {
  const { t } = useTranslation();
  const title = t('catalog.maintenance.maintenance_plan_list.title', 'Maintenance plan list');
  const dateLabel = t('catalog.maintenance.new_maintenance_plan.date', 'Date');
  return (
    <View
      fixed
      style={{ height: 70 }}
      render={({ pageNumber }) =>
        pageNumber == 1 ? (
          <View>
            <View style={styles.column}>
              <View style={styles.col}>
                <Title>{title}</Title>
              </View>
            </View>
            <View style={[styles.column, { alignItems: ALIGN_ITEMS_START }]}>
              <View style={styles.col}>
                <LeadText>
                  {dateLabel}
                  {`: ${textFormatter.formatDate(data.date)}`}
                </LeadText>
              </View>
            </View>
          </View>
        ) : (
          <View style={[styles.row, { height: 50 }]}>
            <View style={[styles.column]}>
              <View style={[styles.row, { height: 30, alignContent: 'center' }]}>
                <Title>{title}</Title>
              </View>
            </View>
            <View style={[styles.column, { alignItems: 'flex-end' }]}>
              <View style={[styles.col, { marginBottom: 30 }]}>
                <LeadText>
                  {dateLabel}
                  {`: ${textFormatter.formatDate(data.date)}`}
                </LeadText>
              </View>
            </View>
          </View>
        )
      }
    />
  );
};

export interface MaintenancePlanPdfData {
  date: Date;
  operationsData: GetIAMServiceOperationsResponseLocal | undefined;
  selectedReferencesByOperation: Map<string, ServiceReferenceLocal[]>;
  referencesPrices: Map<string, undefined | ReferencePriceType>;
  referencesData: IAMReferenceLocal[];
  estimateSettings: EstimateSettings | undefined;
  currency: string;
  text: {
    title: ReactNode;
    vehicle: ReactNode;
    date: ReactNode;
    operationsList: ReactNode;
  };
}

export interface RefRow {
  referenceNumber: string;
  description: string;
  quantity: number;
  price: string;
  family: string;
}

export function ReferenceTable<T>({
  data,
  columns,
}: {
  data: T[];
  columns: {
    title: string | ReactNode;
    render: (item: T) => ReactNode;
    width?: number;
    renderNode?: (item: T) => ReactNode;
  }[];
}) {
  function getHeader() {
    return (
      <>
        <View style={{ height: 15 }} />
        <View style={[styles.row, { height: 30 }]}>
          {columns.map((column, columnIndex) => (
            <Column key={`header-col-${columnIndex}`} width={column.width}>
              <View
                style={column.width ? [components.header_cell_fixed] : [components.header_cell_fixed]}
                key={`column-${columnIndex}`}
              >
                <View style={[styles.row, { height: 30, alignContent: 'center', marginTop: 5 }]}>
                  <PdfText style={fonts.itemNameTitle}>{column.title}</PdfText>
                </View>
              </View>
            </Column>
          ))}
        </View>
      </>
    );
  }

  return (
    <View style={[styles.col, { width: 555 }]} wrap={data.length > 10}>
      {getHeader()}
      {data.map((item, index) => (
        <View key={`col-row-${index}`}>
          <View wrap={false} style={[styles.row]}>
            {columns.map((column, columnIndex) => (
              <Column key={`col-item-${columnIndex}`} width={column.width}>
                <View
                  style={
                    column.width
                      ? [
                          components.cell_left,
                          components.cell_left_fixed,
                          { maxWidth: column.width },
                          index % 2 == 1 ? components.dark_cell : components.white_cell,
                        ]
                      : [
                          components.cell_left,
                          components.cell_left_flex,
                          index % 2 == 1 ? components.dark_cell : components.white_cell,
                        ]
                  }
                  key={`column-${columnIndex}`}
                >
                  <View style={{ height: 30 }}>
                    <Text>{column.render(item)}</Text>
                  </View>
                  {column.renderNode && column.renderNode(item)}
                </View>
                <View style={[components.divider]} />
              </Column>
            ))}
          </View>
        </View>
      ))}
    </View>
  );
}
