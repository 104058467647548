import React from 'react';
import { useSelector } from 'react-redux';
import { BarsIcon } from 'assets/icons';
import FirstHelpShadow from 'components/Help/FirstHelpShadow';
import LogoutButton from 'components/LogoutButton';
import IconMenu from 'components/Page/Header/TopHeader/IconMenu/IconMenu';
import LinkTabs from 'components/Page/Header/TopHeader/Links/LinkTabs';
import RpartStoreLogo from 'components/Page/Header/TopHeader/Logos/RpartStoreLogo';
import { AntHeader, PageHeaderDiv, SLayoutCorner } from 'components/Page/Header/TopHeader/TopHeader.styled';
import UserInfo from 'components/Page/Header/TopHeader/UserInfo/UserInfo';
import { EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import { getTokenUser, getUserDisplayMode } from 'domains/user';
import { theme } from 'styles';
import { Flex, Icon, MarginBox } from 'UI';
import { useExtraLarge, useLarge } from 'utils';
import Search from './Search';

export const HEADER_HEIGHT = 90;

export interface HeaderProps {
  switchMenu: () => void;
  sidebarWidth: number;
}

const TopHeader = ({ switchMenu, sidebarWidth }: HeaderProps) => {
  const userDisplayMode = useSelector(getUserDisplayMode);
  const iconColor = theme.color.white;
  const iconHoverFill = theme.color.white85;
  const user = useSelector(getTokenUser);

  const params = new URLSearchParams(location.search);
  params.set(EstimateTabParam, EstimateTabName);

  const large = useLarge();
  const extraLarge = useExtraLarge();

  return (
    <AntHeader height={HEADER_HEIGHT}>
      <FirstHelpShadow header />
      <Flex />
      <PageHeaderDiv height={HEADER_HEIGHT} displayMode={userDisplayMode}>
        <MarginBox ml={30} mr={extraLarge ? 24 : 0}>
          <Flex align={'center'} gap={10}>
            <SLayoutCorner basis={sidebarWidth}>
              <Icon
                IconComponent={BarsIcon}
                onClick={switchMenu}
                size={32}
                display={'block'}
                dataCy={'hamburger-menu'}
              />
            </SLayoutCorner>
            {large && <RpartStoreLogo />}
          </Flex>
        </MarginBox>
        {extraLarge && <LinkTabs />}
        <Flex minWidth={5} />
        <Flex minWidth={extraLarge ? 350 : 300} maxWidth={extraLarge ? 350 : 300}>
          <Search />
        </Flex>
        <Flex minWidth={5} />
        <Flex size={0} justify={'flex-end'}>
          <IconMenu iconColor={iconColor} iconHoverFill={iconHoverFill} />
          <MarginBox my={'auto'} mx={7}>
            <UserInfo />
          </MarginBox>
          <MarginBox my={'auto'} mx={7}>
            <LogoutButton small iconColor={iconColor} iconHoverFill={iconHoverFill} />
          </MarginBox>
          <div hidden className={'userIdentification'}>
            {user?.id} - {user?.firstName} {user?.lastName}
          </div>
        </Flex>
      </PageHeaderDiv>
    </AntHeader>
  );
};

export default TopHeader;
