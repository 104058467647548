import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getPopinRaw } from 'components/Help/firstHelpData';
import { stringToMaps } from 'components/Help/PageViewCounter/PageViewCounter';
import { getFirstHelpEnabled } from 'domains/appContext/AppContext.store';
import { getInitStreams, getSeenHintsLocal } from 'domains/firstHelp/FirstHelp.api';
import {
  getAutoShowFirstHelpHint,
  initializeSeenPopinsFromLocalStorage,
  initializeStreamsPopins,
  setPageViews,
  setShowFirstHelp,
} from 'domains/firstHelp/FirstHelp.store';
import { PAGE_VIEWS_LOCAL, PageType } from 'domains/firstHelp/FirstHelp.types';
import {
  getDelegationSession,
  getDelegationSessionStatus,
  getTokenUser,
  getUserRights,
  getWebLanguage,
  UserRole,
} from 'domains/user';
import { hasUserAnyRight, isLoading } from 'utils';

export const useInitFirstHelpData = (): void => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userId = useSelector(getTokenUser)?.id ?? '';
  const streams = getInitStreams();
  const popins = new Map(Object.entries(getPopinRaw(t)));
  const webLanguage = useSelector(getWebLanguage);
  const viewsLocalStorage = localStorage.getItem(PAGE_VIEWS_LOCAL);
  const usersViewsMap = stringToMaps(viewsLocalStorage);
  const currentUserViews = usersViewsMap.get(userId) ?? new Map();

  useEffect(() => {
    dispatch(initializeStreamsPopins({ streams, popins }));
    dispatch(initializeSeenPopinsFromLocalStorage({ userId }));
    dispatch(setPageViews(currentUserViews));
    // eslint-disable-next-line
  }, [userId, webLanguage]);
};

export const useAutomaticFirstHelp = (page: PageType, isAnyLoading: boolean): void => {
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const firstHelpEnabled = useSelector(getFirstHelpEnabled);
  const connectedAs = useSelector(getDelegationSession);
  const userId = useSelector(getTokenUser)?.id ?? '';
  const seenLocal = getSeenHintsLocal(userId);
  const showAutoFirstHelpHint = useSelector((state: RootState) =>
    getAutoShowFirstHelpHint(state, { streamsId: page, seenLocal }),
  );
  const requiredRights = [UserRole.COMMAND, UserRole.CONNECT_COMMANDE];
  const isAuthorizedUser = !isLoading(userRights) && hasUserAnyRight(requiredRights, userRights);
  const connectAsStatus = useSelector(getDelegationSessionStatus);

  useEffect(() => {
    if (!firstHelpEnabled) {
      return;
    }
    if (
      showAutoFirstHelpHint &&
      !isAnyLoading &&
      isAuthorizedUser &&
      !connectedAs &&
      connectAsStatus &&
      !isLoading(connectAsStatus)
    ) {
      setTimeout(() => {
        dispatch(setShowFirstHelp('close'));
      }, 50);
    }
    // eslint-disable-next-line
  }, [dispatch, isAnyLoading, connectAsStatus, showAutoFirstHelpHint]);
};
