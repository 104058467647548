import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line max-len
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { MenuItem } from 'pages/UniversalProductsPage/Menu/Menu.styled';
import { Box, Flex, Text } from 'UI';

interface MainCategoriesProps {
  openCategoryId: string;
  setOpenCategoryId: (id: string) => void;
  universalProductsTree: UniversalProductsCategory[];
}

const MainCategories = ({ universalProductsTree, openCategoryId, setOpenCategoryId }: MainCategoriesProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Flex direction={'column'} gap={8}>
        <Text type={'h2'} disableGutter noWrap>
          {t('common.all_categories', 'All categories')}
        </Text>
        {universalProductsTree.map((category) => {
          return (
            <MenuItem
              key={category.nodeId}
              active={openCategoryId === category.nodeId}
              onClick={() => {
                setOpenCategoryId(category.nodeId);
              }}
            >
              <Text type={'h6'} disableGutter noWrap>
                {category.name}
              </Text>
            </MenuItem>
          );
        })}
      </Flex>
    </Box>
  );
};

export default MainCategories;
