import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { DMSImportIcon, FileAltIcon } from 'assets/icons';
import { Box, Flex, Icon, Spin, Text } from 'UI';
import { isLoading } from 'utils';
import { SCard, STitleCard } from './CreateEstimateButton.styled';
import DMSImport from './DMSImport';

export interface CreateEstimateButtonProps {
  createEstimate: () => void;
  importDMSOrder: (orderNumber: string) => void;
  isDMSAvailable: boolean | 'loading';
  setIsCreateEstimatePopoverVisible: Dispatch<SetStateAction<boolean>>;
  isDMSImportActive: boolean;
  setIsDMSImportActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEstimateButton = (props: CreateEstimateButtonProps) => {
  const {
    createEstimate,
    isDMSAvailable,
    importDMSOrder,
    setIsCreateEstimatePopoverVisible,
    isDMSImportActive,
    setIsDMSImportActive,
  } = props;
  const { t } = useTranslation();

  return (
    <Box width={260} height={155}>
      {isDMSImportActive ? (
        <DMSImport
          importDMSOrder={importDMSOrder}
          setIsDMSImportActive={setIsDMSImportActive}
          setIsCreateEstimatePopoverVisible={setIsCreateEstimatePopoverVisible}
        />
      ) : (
        <>
          <STitleCard>
            <Text type={'h6_white'}>{t('estimate.new', 'New estimate')}</Text>
          </STitleCard>
          <SCard onClick={() => setIsDMSImportActive(true)}>
            <Flex>
              {isLoading(isDMSAvailable) ? (
                <Spin />
              ) : (
                <>
                  <Icon IconComponent={DMSImportIcon} size={29} mb={0} mr={15} ml={-2} color={'brand_black'} />
                  <Text type={'light_14_bold_black_85'}>
                    {t('estimate.dms.import_repair_order', 'Import repair order')}
                  </Text>
                </>
              )}
            </Flex>
          </SCard>
          <SCard onClick={createEstimate}>
            <Flex>
              <Icon IconComponent={FileAltIcon} size={24} mb={3} mr={15} ml={0} color={'brand_black'} />
              <Text type={'light_14_bold_black_85'}>{t('estimate.new', 'New estimate')}</Text>
            </Flex>
          </SCard>
        </>
      )}
    </Box>
  );
};

export default CreateEstimateButton;
