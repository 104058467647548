import React, { useEffect } from 'react';
import { getUserManager } from 'domains/auth/Auth.util';

const SilentRenew = (): JSX.Element => {
  useEffect(() => {
    getUserManager()
      .signinSilentCallback()
      .catch((e) => {
        console.error('Unable to silent renew', e);
      });
  }, []);
  return <></>;
};

export default SilentRenew;
