import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { getUserCountry } from 'domains/user';
import Section from 'pages/LandingPage/Sections/Section';
import { Link } from 'UI';
import { useLarge } from 'utils';
import { Country } from 'utils/i18n/Country';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';

const FILLER_IMAGE = 'landing/world-map.png';

export interface SectionContentType {
  image?: string;
  title: string;
  description: JSX.Element;
  action?: string;
  actionColor?: 'yellow' | 'black';
  link?: string;
  background: string;
  backgroundImage?: string;
  textColor?: string;
  alt: string;
}

const Sections = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const large = useLarge();

  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const country = userCountry?.toLowerCase() ?? browserCountry?.toLowerCase() ?? Country.FR.key;

  const sectionContent: Array<SectionContentType> = [
    {
      background: theme.color.brand_black,
      backgroundImage: large ? 'landing/join-network.jpg' : undefined,
      title: t('section.home.network', 'Join Renault network'),
      description: (
        <Trans i18nKey={'section.home.network.description'}>
          Get the right auto parts, right now at oneportal.com. Over 50 million car parts delivered from your favorite
          partners. Save Your Money. Enjoy customized discount. Access to a large documentation catalog.
        </Trans>
      ),
      textColor: theme.color.white,
      actionColor: 'yellow',
      action: t('section.home.network.action', 'Get Started'),
      link: 'about:blank',
      alt: 'Car maintenance',
    },
    {
      background: theme.color.white,
      image: FILLER_IMAGE,
      title: t('section.home.near_you', 'Car parts near to you'),
      description: (
        <Trans i18nKey={'section.home.near_you.description'}>
          Find Renault Garage and our partners near you. Works in close partnership with many major automotive
          suppliers. Over XXXX professionals worldwide.
        </Trans>
      ),
      actionColor: 'black',
      action: t('section.home.near_you.action', 'Locate'),
      link: 'about:blank',
      alt: 'Car parts near you',
    },
    {
      background: theme.color.brand_black,
      image: FILLER_IMAGE,
      title: t('section.home.parts.title', 'Title'),
      description: (
        <Trans i18nKey={'section.home.parts.description'}>
          Get the right auto parts, right now at oneportal.com. Over 50 million car parts delivered from your favorite
          discount auto parts store. Save Your Money. Enjoy customized discount. Access to a large documentation
          catalog.
        </Trans>
      ),
      textColor: theme.color.white,
      alt: 'Auto Parts',
    },
    {
      background: theme.color.white,
      image: FILLER_IMAGE,
      title: t('section.home.maintenance', 'Schedule car maintenance'),
      description: (
        <Trans i18nKey={'section.home.maintenance.description'}>
          From changing your customers oil to changing their timing belt, discover our built-in tools to help you in
          your garage daily life. Access to a large list of the most common car maintenance task, recorded service
          history.
        </Trans>
      ),
      textColor: theme.color.brand_black,
      alt: 'Car maintenance',
    },
    {
      background: theme.color.white,
      image: 'landing/data_protection.webp',
      title: t('section.home.data_protection.title', 'Exercise your rights'),
      description: (
        <>
          <Trans i18nKey={'section.home.data_protection.statement'}>
            When you connect via the RPartStore portal (&quot;the Platform&quot;), Renault s.a.s, where applicable in
            collaboration with the affiliated company operating in your country, processes your personal data for the
            purposes of managing your access and authorization rights on the Platform and the security and proper
            functioning of the Platform. To find out more, in particular about your rights under the applicable
            regulations and how to exercise them (right of access, to rectification, to erasure, to object, etc.), see
            the Information Notice on
          </Trans>{' '}
          <Link to={ROUTER_PERSONAL_DATA(country)} newTab>
            <Trans i18nKey={'section.home.data_protection.statement.link'}>Personal Data Protection</Trans>
          </Link>
          .
        </>
      ),
      textColor: theme.color.brand_black,
      alt: 'Data protection',
    },
  ];

  return (
    <>
      {sectionContent.map(
        (
          { title, description, background, backgroundImage, textColor, image, action, actionColor, link, alt },
          index,
        ) => (
          <Section
            key={index}
            title={title}
            description={description}
            background={background}
            backgroundImage={backgroundImage}
            textColor={textColor}
            image={image}
            action={action}
            actionColor={actionColor}
            link={link}
            left={index % 2 === 0}
            alt={alt}
          />
        ),
      )}
    </>
  );
};
export default Sections;
