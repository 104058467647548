import React, { useState } from 'react';
import { DataContainer } from 'components/DataContainer';
import { useUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import MainCategories from 'pages/UniversalProductsPage/Menu/MainCategories';
import SubCategories from 'pages/UniversalProductsPage/Menu/SubCategories';
import { Flex } from 'UI';
import { getData } from 'utils';

const DEFAULT_ID = 'N-33909';

const Menu = () => {
  const universalProductsTree = useUniversalProductsTree();
  const [openCategoryId, setOpenCategoryId] = useState<string>(DEFAULT_ID);

  const getLevel2Categories = () => {
    const mainCategory = getData(universalProductsTree)?.find((up) => up.nodeId === openCategoryId);
    return mainCategory?.childCategories ?? [];
  };

  return (
    <DataContainer data={universalProductsTree}>
      <Flex gap={30}>
        <MainCategories
          universalProductsTree={getData(universalProductsTree) ?? []}
          openCategoryId={openCategoryId}
          setOpenCategoryId={setOpenCategoryId}
        />
        <SubCategories openCategoryId={openCategoryId} level2Categories={getLevel2Categories()} />
      </Flex>
    </DataContainer>
  );
};

export default Menu;
