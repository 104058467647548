import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { CloseOutlined } from '@ant-design/icons';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { CarIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { VehicleCountry } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { fetchRemoveVehicleFromSearchHistoryRequestSaga, getVehicleSearchHistory } from 'domains/user';
import { theme } from 'styles';
import {
  BlackButton,
  Box,
  CenteredSpin,
  CenterFlex,
  Flex,
  GreyButton,
  Icon,
  Lowercase,
  MarginBox,
  ScalableImage,
  Text,
} from 'UI';
import VehicleFlag from 'UI/Icon/VehicleFlag';
import { TextWithPopup } from 'UI/Text/TextWithPopup';
import { getData, useExtraLarge, useLarge } from 'utils';
import { ButtonsWrapper, CloseIconWrapper, SSearchHistoryItem } from './SearchHistoryList.styled';

function SearchHistoryItem({ vehicle }: { vehicle: VehicleDetail }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const lastSearchedVehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleImage = vehicle.imageUrl;
  const isSelected = lastSearchedVehicleKey === vehicle.vehicleKey;
  const xLarge = useExtraLarge();
  const large = useLarge();

  function selectVehicle() {
    if (vehicle.country) {
      params.set(VehicleCountry, vehicle.country);
    }
    history.push(
      `${getCatalogSourceUrl(vehicle.catalogSource)}/${vehicle.vehicleKey}${ROUTER_CATALOG_VEHICLE}?${params}`,
    );
  }

  function removeVehicle() {
    dispatch(
      fetchRemoveVehicleFromSearchHistoryRequestSaga({
        vehicleKey: vehicle.vehicleKey,
      }),
    );
  }

  const getBody = () => {
    if (vehicle.catalogSource === 'DATAHUB') {
      return vehicle.dataHubVehicle?.modelType;
    }

    if (vehicle.iamVehicle?.bodyType && vehicle.iamVehicle?.doors) {
      return `${vehicle.iamVehicle?.bodyType} ${
        vehicle.iamVehicle?.doors
          ? t('catalog.iam.vehicle.doors', '{{numberOfDoors}} Doors', {
              numberOfDoors: vehicle.iamVehicle.doors,
            })
          : ''
      }`;
    }
    return undefined;
  };

  const getGearbox = () => {
    if (vehicle.catalogSource === 'DATAHUB') {
      return vehicle.dataHubVehicle?.gearbox;
    }

    const values = [vehicle.iamVehicle?.gearboxCode, vehicle.iamVehicle?.gearboxType, vehicle.iamVehicle?.gearbox];
    const gearboxText = values.filter((a) => a).join(' - ');
    if (gearboxText?.length > 0) {
      return `${gearboxText} - ${t('catalog.vehicle.speed', 'speed')} ${t(
        'catalog.vehicle.gearbox',
        'Gearbox',
      ).toLowerCase()}`;
    }

    return undefined;
  };

  const body = getBody();
  const gearbox = getGearbox();

  return (
    <SSearchHistoryItem>
      <Flex>
        <Box height={90} width={90}>
          <ScalableImage
            height={90}
            width={90}
            imageUrl={vehicleImage}
            catalogSource={vehicle.catalogSource}
            fallbackSrc={''}
            fallbackComponent={
              <Box height={90} width={90} background={theme.color.grey95}>
                <CenterFlex>
                  <Icon IconComponent={CarIcon} size={40} color={theme.color.brand_black} noPointer />
                </CenterFlex>
              </Box>
            }
            alt={'Car preview'}
            cursor={'auto'}
          />
        </Box>
        <MarginBox mr={15} />
        <Flex direction={'column'} align={'flex-start'}>
          <Flex align={'center'}>
            <Text type={'h5_bold'}>
              {vehicle.catalogSource === 'DATAHUB'
                ? vehicle.dataHubVehicle?.name
                : `${vehicle.vehicleBrand ?? ''} ${vehicle.model ?? ''} ${vehicle.iamVehicle?.phase ?? ''}`}
            </Text>
            <MarginBox mr={10} />
            {vehicle.catalogSource === 'DATAHUB' ? (
              <VehicleFlag vehicle={vehicle} />
            ) : (
              (vehicle.vin || vehicle.vrn) && <VehicleFlag vehicle={vehicle} />
            )}
            <MarginBox mr={10} />
            <Text type={'text_dim'}>
              (
              {vehicle.catalogSource === 'DATAHUB' ? vehicle.dataHubVehicle?.engine : vehicle.iamVehicle?.version ?? ''}
              )
            </Text>
          </Flex>
          <Flex direction={large ? 'row' : 'column'}>
            {vehicle.vin && (
              <Flex direction={'row'}>
                <Flex direction={'column'}>
                  <Text type={'text_dim_bold'}>{t('catalog.vehicle.vin', 'VIN')}</Text>
                  <Text type={'text_dim_bold'}>{t('catalog.vehicle.vrn', 'VRN')}</Text>
                </Flex>
                <MarginBox mr={15} />
                <Flex direction={'column'}>
                  <Text type={'text_dim'}>{vehicle.vin}</Text>
                  <Text type={'text_dim'}>{vehicle.vrn}</Text>
                </Flex>
                <MarginBox mr={30} />
              </Flex>
            )}
            {!vehicle.vin && vehicle.vrn && (
              <Flex direction={'row'}>
                <Flex direction={'column'}>
                  <Text type={'text_dim_bold'}>{t('catalog.vehicle.vrn', 'VRN')}</Text>
                </Flex>
                <MarginBox mr={15} />
                <Flex direction={'column'}>
                  <Text type={'text_dim'}>{vehicle.vrn}</Text>
                </Flex>
                <MarginBox mr={30} />
              </Flex>
            )}
            <Flex direction={'row'}>
              <Flex direction={'column'}>
                {gearbox && <Text type={'text_dim_bold'}>{t('catalog.vehicle.gearbox', 'Gearbox')}</Text>}
                {body && <Text type={'text_dim_bold'}>{t('common.body', 'Body')}</Text>}
              </Flex>
              <MarginBox mr={15} />
              <Flex direction={'column'}>
                {gearbox && (
                  <Flex maxWidth={!xLarge && large ? 150 : ''}>
                    <TextWithPopup
                      type={'text_dim'}
                      transform={'capitalize'}
                      label={gearbox.toLowerCase()}
                      narrow={!vehicle.vin && !vehicle.vrn ? false : !xLarge && large}
                    >
                      <Lowercase>{gearbox}</Lowercase>
                    </TextWithPopup>
                  </Flex>
                )}
                {body && (
                  <Text type={'text_dim'} transform={'capitalize'}>
                    <Lowercase>{body}</Lowercase>
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <MarginBox mr={15} />
      <ButtonsWrapper>
        <CloseIconWrapper onClick={removeVehicle}>
          <CloseOutlined />
        </CloseIconWrapper>
        <BlackButton stretch={false} size={'middle'} onClick={selectVehicle} disabled={isSelected} alignSelfFlexEnd>
          {isSelected ? t('catalog.vehicle.current_vehicle', 'Current vehicle') : t('common.action.select', 'Select')}
        </BlackButton>
      </ButtonsWrapper>
    </SSearchHistoryItem>
  );
}

export default function SearchHistoryList() {
  const { t } = useTranslation();
  const vehicleSearchHistory = useSelector(getVehicleSearchHistory);
  const [showMore, setShowMore] = useState<boolean>(false);

  function onShowMoreClick() {
    setShowMore(true);
  }

  return (
    <DataContainer
      data={vehicleSearchHistory}
      Loading={() => <CenteredSpin size={'large'} />}
      NotFound={() => (
        <Text type={'section_bold'}>{t('catalog.history.is_empty', 'Your search history is empty')}</Text>
      )}
    >
      <Flex direction={'column'}>
        {getData(vehicleSearchHistory)
          ?.slice(0, showMore ? 20 : 4)
          .map((v) => (
            <SearchHistoryItem key={v.vehicleKey} vehicle={v} />
          ))}
        {!showMore && (getData(vehicleSearchHistory)?.length ?? 0) > 4 && (
          <>
            <Flex justify={'center'}>
              <GreyButton stretch={false} size={'middle'} onClick={onShowMoreClick}>
                {t('common.load_more', 'Load more')}
              </GreyButton>
            </Flex>
            <MarginBox mt={60} />
          </>
        )}
      </Flex>
    </DataContainer>
  );
}
