import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { SRectangle } from 'app/App.styled';
import { ClockIcon, ManInFrontOfComputerIcon } from 'assets/icons';
import LoginForm from 'components/Login/LoginForm';
import LogoutButton from 'components/LogoutButton';
import { getTokenExpired, getTokenUser, REDIRECT_URL_STORE_KEY, setIsReload } from 'domains/user';
import { getErrorNoConnectionToBESide } from 'domains/webSockets/WebSocket.store';
import { theme } from 'styles';
import { Box, Flex, Icon, Li, MarginBox, Modal, Text, Ul } from 'UI';
import { storeSave } from 'utils';
import { useView } from 'utils/hooks/useView';

export const InvalidUserToken = () => {
  const { t } = useTranslation();
  const tokenUser = useSelector(getTokenUser);

  const AttributeRow = ({ title, value, required }: { title: string; value?: string; required?: boolean }) => {
    const isInvalidValue = required && (value === undefined || value.length === 0);
    return (
      <Li>
        <Flex direction={'row'}>
          <Text type={isInvalidValue ? 'link_14_red' : 'text'}>{`${title}:`}</Text>
          <MarginBox ml={5} />
          {isInvalidValue ? (
            <Text type={'link_14_red'}>{t('errors.undefined_attribute', 'Attribute is required, but missing.')}</Text>
          ) : (
            <Text type={'value_bold'}>{`${value}`}</Text>
          )}
        </Flex>
      </Li>
    );
  };
  const renderUserTokenDetails = () => {
    if (!tokenUser) {
      return <MarginBox mt={15} />;
    }
    return (
      <Flex direction={'column'} justify={'flex-start'}>
        <Ul>
          <AttributeRow title={'ID'} value={tokenUser?.id} required />
          <AttributeRow title={'Email'} value={tokenUser?.email} />
          <AttributeRow title={'First name'} value={tokenUser?.firstName} />
          <AttributeRow title={'Last name'} value={tokenUser?.lastName} />
          <AttributeRow title={'Locale'} value={tokenUser?.locale} />
          <AttributeRow title={'Token country'} value={tokenUser?.country} required />
          <AttributeRow title={'Token garage id'} value={tokenUser?.selectedProfile?.id} />
          <AttributeRow title={'Token garage repository'} value={tokenUser?.selectedProfile?.repository} />
        </Ul>
        <MarginBox mt={15} />
      </Flex>
    );
  };

  return (
    <Modal width={700} footer={''} open>
      <Flex direction={'column'} align={'center'} justify={'center'}>
        <Text type={'h1_lead'}>
          {t(
            'errors.invalid_user_token',
            'Your user token contains invalid attributes. Please contact system administrator.',
          )}
        </Text>
        {renderUserTokenDetails()}
        <LogoutButton />
      </Flex>
    </Modal>
  );
};

export function ExpiredTokenPopup() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenExpired = useSelector(getTokenExpired);

  const saveRedirectUrl = () => {
    const redirectUrl = location.pathname + location.search;
    if (redirectUrl !== '/') {
      storeSave(REDIRECT_URL_STORE_KEY, redirectUrl);
    }
  };

  useEffect(() => {
    if (tokenExpired) {
      saveRedirectUrl();
      dispatch(setIsReload(false));
    }
    // eslint-disable-next-line
  }, [tokenExpired]);

  return (
    <Modal width={700} footer={''} open={tokenExpired}>
      <Flex align={'center'} direction={'row'} justify={'center'}>
        <Box width={500} align={'center'}>
          <Text type={'h1_lead'}>{t('errors.inactivity', 'Your session has expired due to inactivity')}</Text>
          <Box height={10} />
          <Icon IconComponent={ClockIcon} color={theme.color.info} />
          <Box height={20} />
          <LoginForm returnToHistory={true} />
          <Box height={20} />
        </Box>
      </Flex>
    </Modal>
  );
}

export function NoConnectionToBEPopupLarge() {
  const { t } = useTranslation();
  const isNotConnectedToBE = useSelector(getErrorNoConnectionToBESide);
  const tokenExpired = useSelector(getTokenExpired);

  return (
    <Modal width={990} footer={''} open={!tokenExpired && isNotConnectedToBE}>
      <Flex direction={'row'} justify={'flex-start'}>
        <Icon IconComponent={ManInFrontOfComputerIcon} width={625} height={415} noPointer />
        <MarginBox ml={-190}>
          <SRectangle>
            <Text type={'h1_banner_light'}>
              {t('errors.connection_to_backend.header', 'We are currently facing some issue')}
            </Text>
            <Text type={'h6_black'}>
              {t('errors.connection_to_backend.description_top', 'Our teams are working on it.')}
            </Text>
            <Text type={'h6_black'}>
              {t('errors.connection_to_backend.description_bottom', 'Thank you for your patience.')}
            </Text>
          </SRectangle>
        </MarginBox>
      </Flex>
    </Modal>
  );
}

export function NoConnectionToBEPopupSmall() {
  const { t } = useTranslation();
  const isNotConnectedToBE = useSelector(getErrorNoConnectionToBESide);
  const tokenExpired = useSelector(getTokenExpired);

  return (
    <Modal width={600} footer={''} open={!tokenExpired && isNotConnectedToBE}>
      <Flex direction={'row'} justify={'flex-start'}>
        <SRectangle>
          <Text type={'h2'}>{t('errors.connection_to_backend.header', 'We are currently facing some issue')}</Text>
          <Text type={'h6_black'}>
            {t('errors.connection_to_backend.description_top', 'Our teams are working on it.')}
          </Text>
          <Text type={'h6_black'}>
            {t('errors.connection_to_backend.description_bottom', 'Thank you for your patience.')}
          </Text>
        </SRectangle>
      </Flex>
    </Modal>
  );
}

export function NoConnectionToBEPopup() {
  return (
    <Layout>
      <Layout.Content>
        {useView([NoConnectionToBEPopupSmall, NoConnectionToBEPopupSmall, NoConnectionToBEPopupLarge], {})}
      </Layout.Content>
    </Layout>
  );
}
