import React from 'react';
import { Trans } from 'react-i18next';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { ExternalOrderItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/ExternalOrderItem';
import { Seller } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { pdf } from '@react-pdf/renderer';
import { textFormatter } from 'utils';
import { ExternalOrderPdfData } from '../../MyOrdersDetailPdf';
import { MyExternalOrdersDetailPdfPage } from '../../MyOrdersDetailPdf/MyExternalOrderDetailPdf';

export const handlePrintDetail = async (order: ExternalOrderItem | undefined, garageInfo: Garage, seller: Seller) => {
  if (!order) {
    return;
  }

  const orderNumber = order.orderId;
  const items = order.orderedReferences.length;
  const date = textFormatter.formatDate(new Date());

  const pdfData: ExternalOrderPdfData = {
    text: {
      title: <Trans i18nKey="my_orders.external.details">External order details</Trans>,
      orderNumber: <Trans i18nKey="my_orders.pdf.order_nummber">Order n°: {{ orderNumber }}</Trans>,
      date: <Trans i18nKey={'estimate.pdf.date'}>Date: {{ date }}</Trans>,
      clientInformation: <Trans i18nKey="my_orders.pdf.clientInformation">Client information</Trans>,
      informations: <Trans i18nKey="my_orders.pdf.informations">Informations</Trans>,
      contactSection: <Trans i18nKey="my_orders.pdf.contact">Contact</Trans>,
      billingAddressSection: <Trans i18nKey="my_orders.pdf.billing">Billing address</Trans>,
      instructions: <Trans i18nKey="my_orders.pdf.instructions">Instructions</Trans>,
      client: <Trans i18nKey="my_orders.pdf.client">Client</Trans>,
      orderDate: <Trans i18nKey="my_orders.pdf.order_date">Order Date</Trans>,
      orderLine: <Trans i18nKey="my_orders.pdf.order_mark">Order Mark</Trans>,
      status: <Trans i18nKey="my_orders.pdf.detail_status">Status</Trans>,
      deliveryMode: <Trans i18nKey="my_orders.pdf.detail_delivery_mode">Delivery mode</Trans>,
      total: (
        <Trans count={items} i18nKey="my_orders.details.total">
          Total: ({{ items }} item)
        </Trans>
      ),
      vatExcl: (vat_exclude_price) => (
        <Trans i18nKey="common.price.vat_exclude_price">{{ vat_exclude_price }} VAT. Excl</Trans>
      ),
      discount: (discount) => <Trans i18nKey="my_orders.pdf.price.discount">{{ discount }}% Off</Trans>,
      totalVatExcl: <Trans i18nKey="common.pdf.total_exclulded_vat">Total VAT excl.:</Trans>,
      totalVat: <Trans i18nKey="my_orders.details.vat">VAT:</Trans>,
      totalVatIncl: <Trans i18nKey="my_orders.pdf.detail_total_included_vat">Total VAT.incl.:</Trans>,
      clientPrice: <Trans i18nKey="my_orders.pdf.client_price">Client price: </Trans>,
      clientPriceVatIncl: (price) => (
        <Trans i18nKey="my_orders.pdf.client_price_VAT_included">{{ price }} VAT. Incl</Trans>
      ),
      references: <Trans i18nKey="my_orders.pdf.references">References</Trans>,
      orderBy: <Trans i18nKey="my_orders.pdf.order_by">Order by</Trans>,
      clientNumber: <Trans i18nKey="my_orders.pdf.client_number">Client number</Trans>,
      distributor: <Trans i18nKey="my_orders.pdf.distributor">Distributor</Trans>,
      billingAccount: <Trans i18nKey="my_orders.pdf.billing_account">Billing account</Trans>,
      contact: <Trans i18nKey="my_orders.pdf.contact">Contact</Trans>,
      phone: <Trans i18nKey="my_orders.pdf.phone">Phone</Trans>,
      email: <Trans i18nKey="my_orders.pdf.email">E-mail@</Trans>,
      billingAddress: <Trans i18nKey="my_orders.pdf.billing_address">Address</Trans>,
      areaCode: <Trans i18nKey="my_orders.pdf.area_code">Area code</Trans>,
      city: <Trans i18nKey="my_orders.pdf.city">City</Trans>,
      country: <Trans i18nKey="my_orders.pdf.country">Country</Trans>,
      vrn: <Trans i18nKey="my_orders.pdf.vrn">VRN: </Trans>,
      referenceList: <Trans i18nKey={'my_orders.pdf.reference_list'}>Reference list</Trans>,
      noVehicleContext: <Trans i18nKey={'my_orders.order_detail.no_vehicle_context'}>No vehicle context</Trans>,
      urgent: <Trans i18nKey={'order.urgent'}>Urgent</Trans>,
      referenceMark: <Trans i18nKey={'order.referenceMark'}>Reference mark: </Trans>,
      stocks: <Trans i18nKey={'common.stock'}>Stock: </Trans>,
      ref: <Trans i18nKey={'my_orders.pdf.reference_number'}>Ref: </Trans>,
      qty: <Trans i18nKey="common.quantity.short">Qty: </Trans>,
      shipped: <Trans i18nKey="my_orders.pdf.references_shipped">shipped</Trans>,
      returnedArticles: (returnCount) => (
        <Trans i18nKey="my_orders.pdf.return_status">Returned articles: {{ returnCount }}</Trans>
      ),
    },
    order: order,
    garageInfo: garageInfo,
    seller: seller,
  };

  const blob = await pdf(<MyExternalOrdersDetailPdfPage data={pdfData} />).toBlob();
  const url = URL.createObjectURL(blob);
  if (url) {
    window.open(url);
  }
};
