import React from 'react';
import { useTranslation } from 'react-i18next';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment/moment';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { DatePicker, Flex, MarginBox, Text } from 'UI';
import { getMomentDate, getStringDateFromMoment } from 'utils/date';

export const PublicationDate = ({
  information,
  setInformation,
  disable = false,
}: {
  information: CreateInformationModel | CreateInformationTemplateModel;
  setInformation: (informationToCreate: CreateInformationModel | CreateInformationTemplateModel) => void;
  disable?: boolean;
}) => {
  const { t } = useTranslation();
  const disableStartDatePicker = information.status === 'PUBLISHED';

  const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
    if (disable) {
      return true;
    }
    const momentToCompare = moment().subtract(1, 'd');
    return current && current < momentToCompare.endOf('day');
  };
  const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
    if (disable || (information as CreateInformationModel).publicationStart === undefined) {
      return true;
    }
    const startDate = moment((information as CreateInformationModel).publicationStart);
    return current && current < startDate.subtract(1, 'd').endOf('day');
  };

  return (
    <>
      <Text type={'h2'}>{t('backoffice.publication_date', 'Publication date')}</Text>
      <Text type={'section'} disableGutter>
        {t(
          'backoffice.information.create_information.publication_date.description',
          'Select desired publication date, your content will then be post online.',
        )}
      </Text>
      <MarginBox mt={15} />
      <Flex>
        <DatePicker
          value={getMomentDate((information as CreateInformationModel).publicationStart)}
          onChange={(newValue) => {
            const endMoment = getMomentDate((information as CreateInformationModel).publicationEnd);
            const isEndDateStillValid = endMoment && newValue ? endMoment >= newValue : false;
            const newStart = getStringDateFromMoment(newValue);
            setInformation({
              ...information,
              publicationStart: newStart,
              publicationEnd: isEndDateStillValid ? (information as CreateInformationModel).publicationEnd : undefined,
            });
          }}
          placeholder={t('common.date.starting', 'Starting')}
          disabledDate={disabledStartDate}
          disabled={disableStartDatePicker || disable}
          ellipsis
        />
        <MarginBox ml={45} />
        <DatePicker
          value={getMomentDate((information as CreateInformationModel).publicationEnd)}
          onChange={(newValue) =>
            setInformation({
              ...information,
              publicationEnd: getStringDateFromMoment(newValue),
            })
          }
          placeholder={t('common.date.until', 'Until')}
          disabledDate={disabledEndDate}
          disabled={disable}
          ellipsis
        />
      </Flex>
    </>
  );
};
