import styled from 'styled-components';
import { InputNumber } from 'UI';

export const QtyButton = styled.div<{ disabled?: boolean }>`
  background-color: ${({ theme, disabled }) => (disabled ? theme.color.grey85 : theme.color.grey55)};
  width: 40px;
  height: 30px;
  user-select: none;
  border-radius: 3px;

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    filter: ${({ disabled }) => (disabled ? 'none' : 'brightness(0.9)')};
    outline: ${({ theme, disabled }) => (disabled ? 'none' : theme.color.brand + 'solid 1px')};
  }
`;

export const QtyInput = styled(InputNumber)`
  width: 80px;
  height: 30px;
  z-index: 50;
  padding: 0 2px 0 2px;
  text-align: center;
  margin-left: 10px;
`;
