import { OrderListReferenceRow } from '@1po/1po-bff-fe-spec/generated/order_list/model/OrderListReferenceRow';
import { OrderListReferenceRowCorrect } from '@1po/1po-bff-fe-spec/generated/order_list/model/OrderListReferenceRowCorrect';
import { OrderListReferenceRowError } from '@1po/1po-bff-fe-spec/generated/order_list/model/OrderListReferenceRowError';
import { APP_NAMESPACE } from 'app/App.constants';
import {
  API_CALL_ERROR,
  FILE_FORMAT_ERROR,
  FILE_STRUCTURE_ERROR,
  FILE_UPLOADED,
  ModalStatusType,
  NO_FILE,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import { CartReferenceErrorRow } from 'domains/basket/Basket.types';
import { SEARCH_STATUS, SearchData } from 'utils';

export const ORDER_LIST_NAMESPACE = `${APP_NAMESPACE}/ORDER_LIST`;

export const ADD_ORDER_LIST_REFERENCES_TO_CART = `${ORDER_LIST_NAMESPACE}/ADD_ORDER_LIST_REFERENCES_TO_CART`;
export const ADD_ORDER_LIST_REFERENCES_TO_CART_ERROR = `${ORDER_LIST_NAMESPACE}/ADD_ORDER_LIST_REFERENCES_TO_CART_ERROR`;

export const UPLOAD_FILE_ORDER_LIST_REFERENCES = `${ORDER_LIST_NAMESPACE}/UPLOAD_FILE_ORDER_LIST_REFERENCES`;

export type OrderListFileStatus =
  | typeof NO_FILE
  | typeof UPLOADING_FILE
  | typeof FILE_UPLOADED
  | typeof FILE_FORMAT_ERROR
  | typeof FILE_STRUCTURE_ERROR
  | typeof API_CALL_ERROR;

export interface CreateOrderList {
  id: string;
  name: string | undefined;
  references: OrderListReferenceLocal[];
  searchStatus: SearchData<CartReferenceErrorRow[]>;
}

export interface OrderListReferenceLocal {
  index: number;
  referenceNumber: string | undefined;
  quantity: number | undefined;
}

export interface OrderListStore {
  orderListMap: Map<string, CreateOrderList>;
  createNewOrderList: CreateOrderList;
  orderListResponseMap: Map<string, SearchData<CartReferenceErrorRow[]>>;
  fileUploadStatus: OrderListFileUploadStatus;
  fileInformation: OrderListFileInformation;
  fileInformationForRequest: OrderListFileInformation;
  fileRows: OrderListReferenceRow[];
  fileSearchStatus: SEARCH_STATUS;
}

export interface OrderListFileUploadStatus {
  fileStatus: OrderListFileStatus;
  correctRows: OrderListReferenceRowCorrect[];
  errorRows: OrderListReferenceRowError[];
  modalStatus: ModalStatusType;
  isModalOpen: boolean;
  hasFile: boolean;
}

export interface OrderListFileInformation {
  fileName: string;
  fileSize: number;
  uploadTime: string | undefined;
}
