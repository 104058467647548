import { Moment } from 'moment';
import moment from 'moment/moment';

export const MAX_DATE = 8640000000000000;

export const getMomentDate = (stringDate: string | undefined): Moment | undefined => {
  if (stringDate === undefined) {
    return undefined;
  }
  const date = moment(new Date(stringDate), true);
  return date.isValid() ? date : undefined;
};

export const getStringDateFromMoment = (momentDate: Moment | null | undefined): string | undefined =>
  momentDate?.isValid() ? momentDate?.format('YYYY-MM-DD') : undefined;

export const getStringDateAndTimeFromMoment = (momentDate: Moment | null | undefined): string | undefined =>
  momentDate?.isValid() ? momentDate?.format('YYYY-MM-DD HH:mm:ss') : undefined;

export const getDateFromString = (stringDate: string): string | undefined =>
  getStringDateFromMoment(getMomentDate(stringDate));

/**
 * @param { Moment | null | undefined} momentDate Input date for subtraction
 * This function mutates the input parameter momentDate
 */
export const subtractDayFromMoment = (momentDate: Moment | null | undefined): Moment | null | undefined =>
  momentDate?.isValid() ? momentDate?.subtract(1, 'd') : undefined;

/**
 * @param { Moment | null | undefined} momentDate Input date for addition
 * This function mutates the input parameter momentDate
 */
export const addDayToMoment = (momentDate: Moment | null | undefined): Moment | null | undefined =>
  momentDate?.isValid() ? momentDate?.add(1, 'd') : undefined;

export const getStringMinDate = (): string => moment(new Date(-MAX_DATE).toISOString()).format('YYYY-MM-DD');
export const getStringMaxDate = (): string => moment(new Date(MAX_DATE).toISOString()).format('YYYY-MM-DD');
