import React from 'react';
import { Container, Flex, Image, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const TermsAndConditionsDE = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>ALLGEMEINEN GESCHÄFTSBEDINGUNGEN</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Diese Allgemeinen Geschäftsbedingungen (im Folgenden &quot;AGB&quot;) regeln den Zugang zu und die Nutzung
            des RPARTSTORE-Dienstes, der von Renault, SAS mit einem Kapital von 533.941.113 €, mit Sitz in 122-122 bis
            avenue du Général Leclerc - 92100 Boulogne-Billancourt, eingetragen im Handelsregister von Nanterre unter
            der Nummer 780 129 987 (im Folgenden &quot;RENAULT&quot;) (NACH LAND ANZUPASSEN) zur Verfügung gestellt
            wird.
          </Paragraph>
          <Paragraph>
            DER NUTZER MUSS DIESE ALLGEMEINEN GESCHÄFTSBEDINGUNGEN SORGFÄLTIG LESEN, BEVOR ER SIE AKZEPTIERT UND AUF DIE
            DIENSTE ZUGREIFT.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 1.'} title={'Bezeichnung der Plattform'} />
          <Paragraph>
            Die RPARTSTORE-Website (im Folgenden die <strong>&quot;Plattform&quot;</strong> genannt) wurde eingerichtet,
            um den Kontakt zwischen <strong>Verkäufern (R1-Primärnetz)</strong> (im Folgenden die{' '}
            <strong>&quot;Verkäufer&quot;</strong> genannt) zu erleichtern, die den{' '}
            <strong>Käufern (R2-Sekundärnetz/R3-freie Werkstätten)</strong> (im Folgenden die{' '}
            <strong>&quot;Nutzer&quot;</strong>) Teile und Zubehör (im Folgenden die{' '}
            <strong>&quot;Produkte&quot;</strong> genannt) über eine Website zum Verkauf anbieten möchten, in
            Übereinstimmung mit diesen Allgemeinen Geschäftsbedingungen (im Folgenden <strong>&quot;AGB&quot;</strong>{' '}
            genannt).
          </Paragraph>
          <Paragraph>
            Die Dienste der Plattform können unter der folgenden E-Mail-Adresse abgerufen werden:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 2.'} title={'Hosting-Dienst'} />
          <Paragraph>
            Die Plattform fungiert als technischer Vermittler. Sie bietet Verkäufern und Käufern (&quot;Nutzer&quot;)
            technische Datenspeicherungs- und Transportdienstleistungen im Sinne der Vorschriften an.
          </Paragraph>
          <Paragraph>
            Die Rolle der Plattform ist rein technischer Natur und beschränkt sich darauf, den Kontakt zwischen
            Verkäufern und Käufern zu erleichtern.
          </Paragraph>
          <Paragraph>
            Die Plattform übernimmt keine Garantie oder Haftung in Bezug auf Vereinbarungen, Austausch, Angebote oder
            sonstige Mitteilungen, die über sie stattfinden können.
          </Paragraph>
          <Paragraph>
            Die Plattform ist in keiner Weise ein Verkäufer oder Wiederverkäufer der Produkte, die von den Verkäufern
            über die Plattform angeboten werden.
          </Paragraph>
          <Paragraph>
            Transaktionen im Zusammenhang mit Produkten, die von Verkäufern angeboten werden, werden immer direkt
            zwischen Verkäufern und Nutzern außerhalb der Plattform abgeschlossen. Der Verkäufer kann über die Plattform
            einfach Geschäftskontakte (<i>Lead-Formular</i>) generieren. Der Endpreis der Produkte wird vom Verkäufer
            außerhalb der Plattform angegeben.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 3.'} title={'Zweck der Allgemeinen Geschäftsbedingungen'} />
          <Paragraph>
            Der Zweck dieser Allgemeinen Geschäftsbedingungen besteht darin, die Bedingungen für den Zugang zur
            Plattform und deren Nutzung durch die Nutzer festzulegen.
          </Paragraph>
          <Paragraph>
            Der Zweck der Plattform besteht darin, eine direkte digitale Beziehung zwischen Verkäufern und Nutzern
            aufzubauen, um kommerzielle Kontakte zwischen ihnen zu generieren.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 4.'} title={'Bedingungen für den Zugang zur Plattform für Nutzer'} />
          <Paragraph>
            Der Zugang zur Plattform impliziert:
            <Ul>
              <Li>
                <Text type={'text'}>
                  Verkäufer, die Nutzer auffordern, über die Mini-Website für die Mitgliedschaft beizutreten
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Validierung des Zugangs der Nutzer zur Plattform durch Verkäufer</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Die Mitgliedschafts-Mini-Site ist eine E-Business-Anwendung, die es Verkäufern ermöglicht, eine
            RPARTSTORE-Mitgliedschaft für ihre Käuferkunden zu beantragen. Die Anwendung ist über RENAULT.NET und
            RPARTSTORE zugänglich.
          </Paragraph>
          <Paragraph>
            Der Zugang zur Mini-Website für die Mitgliedschaft wird vom Callcenter unter der folgenden Nummer gewährt:
            0180 520 23 23 ; an den Verkäufer, der dies unter der Bedingung beantragt, dass er im Renault-Netzwerk
            individuell referenziert wird.
          </Paragraph>
          <Paragraph>
            Die Mitgliedschafts-Minisite beschleunigt den Mitgliedschaftsprozess und ermöglicht es Verkäufern auch,
            Mitgliedschaftsanträge für ihre Kunden zu stellen, unabhängig von der Art des Benutzers in „TRESOR“
            (Benutzerdatenbank) und RPARTSTORE (Kunde unbekannt in „TRESOR“, neue Mitgliedschaft, neuer Benutzer).
          </Paragraph>
          <Paragraph>
            Der Verkäufer kann für seine Kunden Mitgliedschaftsanträge gemäß den Bestimmungen des vorstehenden Artikels
            4 stellen. Wenn der Kunde nicht in der TRESOR-Datenbank identifiziert wird, wird automatisch eine Anfrage
            zur Erstellung/Änderung an den zuständigen TRESOR-Administrator gesendet.
          </Paragraph>
          <Paragraph>
            Der Verkäufer kann die Daten des Kunden ändern und den Fortschritt seiner Mitgliedschaftsanträge überwachen.
            Er kann eine geöffnete Anwendung nicht ändern, aber er kann beantragen, dass sie geschlossen wird. Das
            Callcenter kümmert sich dann um die endgültige Schließung.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 5.'} title={'Produkte die auf der Plattform zugelassen sind'} />
          <Paragraph>
            Die Plattform wählt die Arten von Produkten aus, die bestellt werden können, Nutzer dürfen Verkäufer nicht
            auffordern oder verlangen, über die Plattform andere Produkte anzubieten als die, die zuvor von der
            Plattform ausgewählt wurden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Artikel 6.'}
            title={'Erstellung eines Käuferbereichs - Vertraulichkeit von Identifikatoren und Passwörtern'}
          />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              Die Schritte:
            </Text>
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  Erhalt einer E-Mail des Verkäufers, in der bestätigt wird, dass die Anfrage berücksichtigt wurde. In
                  der E-Mail wird auch die Bearbeitungszeit für die Anfrage angegeben, die zwischen 24 und 72 Stunden
                  variiert.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Der Käufer erhält dann per E-Mail die notwendigen Informationen (ID / Passwort), um sich mit
                  RPARTSTORE zu verbinden.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Der Verkäufer erhält auch eine Nachricht, die ihn darüber informiert, dass sein Kunde nun Zugang zu
                  RPARTSTORE hat.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Im Falle einer technischen Störung auf Anfrage wird der Verkäufer per E-Mail benachrichtigt.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Die Nutzung des Käuferbereichs ist streng auf den vom Verkäufer benannten Benutzer beschränkt.
          </Paragraph>
          <Paragraph>
            Der Nutzer verpflichtet sich, seine Codes geheim zu halten und sie in keiner Form oder an irgendeine Person
            weiterzugeben und sie im Falle einer betrügerischen oder unbefugten Verwendung zu löschen und den
            Sachverhalt unverzüglich dem Kundendienst auf 0180 520 23 23 zu melden. Der Nutzer wird darauf hingewiesen,
            dass jede Weitergabe seiner Codes an Dritte in seiner alleinigen Verantwortung liegt.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 7.'} title={'Personalisierte Beziehung'} />
          <Paragraph>Nutzer können nicht alle Verkäufer anwerben, die auf der Plattform zugelassen sind.</Paragraph>
          <Paragraph>
            Auf der Plattform findet der Nutzer den Verkäufer, der dem üblichen physischen Kanal entspricht; der
            Anschluss des Nutzers ändert sich nicht.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 8.'} title={'Zahlung'} />
          <Paragraph>Es erfolgt keine Zahlung über die Plattform.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 9.'} title={'Verfügbarkeit von Plattform-Diensten'} />
          <Paragraph>
            Im Rahmen der geltenden Gesetze und Vorschriften lehnt RENAULT jede Verantwortung für die Nutzung der
            Plattform durch den Nutzer ab und gibt keine ausdrückliche oder stillschweigende Garantie für die
            Geschwindigkeit und/oder Leistung der Website oder für die Nutzung der über die Plattform zugänglichen Daten
            und/oder Informationen durch den Kunden.
          </Paragraph>
          <Paragraph>
            Insbesondere garantiert RENAULT nicht, dass die Plattform frei von Fehlern, Mängeln oder Auslassungen ist
            oder dass sie keinen Schäden oder Angriffen, insbesondere Viren, ausgesetzt ist, die zu Fehlfunktionen,
            Betriebsunterbrechungen, Ausfällen oder Daten- oder Informationsverlusten führen könnten.
          </Paragraph>
          <Paragraph>
            Der Zugriff auf die Plattform kann aus technischen Gründen vorübergehend und regelmäßig ausgesetzt werden,
            was die technischen Teams versuchen werden, so schnell wie möglich zu beheben.
          </Paragraph>
          <Paragraph>
            RENAULT wird von jeglicher Verantwortung freigesprochen:
            <Ul>
              <Li>
                <Text type={'text'}>
                  Im Falle der Nichteinhaltung der Bestimmungen dieser Allgemeinen Geschäftsbedingungen durch den
                  Benutzer;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Im Falle einer Fehlfunktion der Plattform, die entweder auf eine freiwillige oder unfreiwillige
                  Ursache zurückzuführen ist, die dem Benutzer oder einem Dritten zuzurechnen ist, oder auf eine
                  böswillige Handlung oder auf eine Fehlfunktion von Software, Smartphones, Tablet-PCs oder anderen
                  Schnittstellen/Produkten des Benutzers;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Im Falle von Gesetzes- oder Regulierungsänderungen, die sich auf die Plattform auswirken.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Darüber hinaus erkennt der Nutzer an, dass die Plattform über Kommunikationsnetze bereitgestellt werden kann
            und dass RENAULT unter keinen Umständen im Falle einer Fehlfunktion dieser Kommunikationsnetze haftbar
            gemacht werden kann.
          </Paragraph>
          <Paragraph>
            Wenn Nutzer die Plattform nutzen, verpflichten sie sich, dies in Übereinstimmung mit diesen Allgemeinen
            Geschäftsbedingungen und in Übereinstimmung mit den geltenden Vorschriften zu tun.
          </Paragraph>
          <Paragraph>
            Der Nutzer und der Verkäufer tragen die volle Verantwortung für die Nutzung der Website und für jede
            Verwendung der über die Website erhaltenen Daten/Informationen, auch wenn sie nicht die Person sind, die
            diese Daten/Informationen verwendet.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 10.'} title={'Nicht-schädliche Nutzung der Plattform'} />
          <Paragraph>
            Der Nutzer muss unter allen Umständen jede Aktivität unterlassen, die geeignet ist, die Tätigkeit der
            Plattform zu beeinträchtigen, ihren Ruf, den Ruf der von ihr erbrachten Dienstleistungen sowie den Ruf und
            die Aktivitäten der Renault-Gruppe zu schädigen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 11.'} title={'Intuitu personae'} />
          <Paragraph>
            Die von der Renault-Gruppe eingerichtete Plattform trägt zum Image, zum Ruf und zur Bekanntheit der
            Renault-Gruppe bei. Die Beziehung zwischen der Plattform und ihren Nutzern basiert daher auf &quot;intuitu
            personae&quot;.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 12.'} title={'Rücktritt, Sperrung oder Ausschluss von der Plattform'} />
          <Text type={'text_bold'}>a) Rücktritt</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Jeder Nutzer kann die Plattform jederzeit über seine R1 über seine Entscheidung informieren, seine Dienste
            nicht mehr zu nutzen.
          </Paragraph>
          <Paragraph>
            Der Nutzer muss die Verpflichtungen, die er vor dem Datum der Beendigung seiner Teilnahme an der Plattform
            mit den Verkäufern eingegangen ist, weiterhin erfüllen.
          </Paragraph>
          <Text type={'text_bold'}>b) Sperrung</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Die Plattform kann den Zugang eines Nutzers in den folgenden Fällen vorübergehend für einen Zeitraum von
            maximal 6 Monaten sperren:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  schwerwiegende Vermutung eines Verhaltens, das die Plattform, diese AGB, das geltende Recht oder das
                  Image und den Ruf der Renault-Gruppe untergräbt;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>keine Benachrichtigung über Änderungen an deklarierten Gegenständen ;</Text>
              </Li>
              <Li>
                <Text type={'text'}>Nutzung des Käuferbereichs durch andere Personen als den Nutzer;</Text>
              </Li>
              <Li>
                <Text type={'text'}>Fahrlässigkeit bei der Geheimhaltungspflicht von Logins und Passwörtern;</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Während dieses Zeitraums werden sich der Käufer und die Plattform nach Treu und Glauben bemühen, das
            festgestellte Verhalten zu korrigieren.
          </Paragraph>
          <Text type={'text_bold'}>c) Ausschluss</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Die Plattform kann einen Nutzer dauerhaft ausschließen, wenn:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  Verlassen des Renault-Netzwerks, das aus irgendeinem Grund auf der Plattform zugelassen ist;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Verhaltensweisen gemäß den Nummern b - 1) dieses Artikels. Die Plattform wird daher die Wahl zwischen
                  Sperrung und sofortigem Ausschluss haben;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Falschdarstellung, egal wie schwerwiegend, denn Falschdarstellung ist ein Vertrauensbruch;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Wiederholung des unter den Punkten b°) 2) bis 4) genannten Verhaltens;</Text>
              </Li>
              <Li>
                <Text type={'text'}>wiederholte Beschwerden von Verkäufern;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  jede Änderung der persönlichen Situation des Nutzers, die geeignet ist, die intuitu personae zu
                  betreffen (insbesondere: direkter oder indirekter Kontrollwechsel, wesentliche Änderung der Produktion
                  oder Zertifizierungen, Insolvenzverfahren gemäß den geltenden Gesetzen).
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 13.'} title={'Handelsgeschäfte - Geschäftsgeheimnis'} />
          <Paragraph>
            Die Plattform darf Dritten (Wettbewerbern, Partnern usw.) in keiner Weise Kenntnis von den über die
            Plattform durchgeführten oder abgeschlossenen Austauschen, Verhandlungen, Verträgen und Vereinbarungen
            gewähren. Die Plattform wird ihr Datenschutzprogramm jederzeit einhalten.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 14.'} title={'Werbeaktionen und Werbung'} />
          <Paragraph>
            Der Nutzer verpflichtet sich, die Plattform weder direkt noch indirekt zu nutzen, um direkt oder indirekt in
            irgendeiner Form für seine Produkte oder Dienstleistungen oder die einer anderen Person zu werben.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 15.'} title={'Konkurrierende Angebote - Keine Exklusivität'} />
          <Paragraph>
            Die Mitgliedschaft auf der Plattform begründet keine Verpflichtung für Nutzer, diese zu verwenden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 16.'} title={'Änderung der AGB'} />
          <Paragraph>
            Die Plattform behält sich das Recht vor, die AGB zu ändern. Jede Änderung tritt an dem Datum in Kraft, das
            in der Benachrichtigung angegeben ist, die den Nutzern auf ihren Flächen mitgeteilt wird.
          </Paragraph>
          <Paragraph>
            Die Änderungen gelten nicht für Transaktionen, die zum Zeitpunkt ihres Inkrafttretens in Bearbeitung sind.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Artikel 17.'}
            title={'Verwendung personenbezogener Daten, die von der Plattform erhoben werden'}
          />
          <Paragraph>
            In Bezug auf die Nutzung der Plattform werden Ihre personenbezogenen Daten von RENAULT gemäß der
            Datenschutzrichtlinie verarbeitet, die über den folgenden Link zugänglich ist:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 18.'} title={'Höhere Gewalt'} />
          <Paragraph>
            Die Plattform haftet nicht für Ereignisse höherer Gewalt, die zu einer Fehlfunktion der Plattform führen.
          </Paragraph>
          <Paragraph>
            Der Nutzer erklärt sich auch damit einverstanden, zu berücksichtigen, dass zu den vertraglichen Merkmalen
            höherer Gewalt insbesondere Stromausfälle, Ausfälle des Internetnetzes, Computerstörungen (&quot;Bugs&quot;,
            Viren, Malware, Eindringlinge) gehören, auch wenn ihre technische Ursache in der Gestaltung des
            Informationsdienstes liegt.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 19.'} title={'Vertraulichkeit und Beweismittel'} />
          <Paragraph>
            Die Plattform setzt geeignete technische Mittel ein, um die Vertraulichkeit der über sie übermittelten
            Informationen und Daten oder der über sie geführten Verhandlungen zu gewährleisten, so dass sie nicht an
            Personen außerhalb ihrer Dienste weitergegeben werden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 20.'} title={'Geistiges Eigentum'} />
          <Paragraph>
            Alle Elemente wie Texte, Logos, Bilder, Grafik- oder Tonelemente, Software, Layout, Plattformdatenbanken,
            Firmennamen, Marken, Designs und Modelle sind durch geistige Eigentumsrechte geschützt. Verkäufer müssen
            jede Handlung unterlassen, die diese Rechte in irgendeiner Weise verletzt.
          </Paragraph>
          <Paragraph>
            Für die Nutzung der Plattform ist der Nutzer berechtigt, die ihm zur Verfügung stehenden Marken und
            geistigen Eigentumsrechte sowie seinen Handelsnamen oder Firmennamen zu nutzen.
          </Paragraph>
          <Paragraph>
            Der Nutzer ist berechtigt, seinen Status als Käufer, auf den sich die Plattform bezieht, in seinen
            Geschäftsunterlagen anzugeben, sofern er die entsprechenden Dokumente zuvor bei der Plattform eingereicht
            hat.
          </Paragraph>
          <Paragraph>
            Den Nutzern wird eine beschränkte, nicht ausschließliche, nicht übertragbare, nicht unterlizenzierbare
            Lizenz zur Nutzung der Software und der Patente gewährt, die ihnen die Nutzung der Plattform ermöglichen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 21.'} title={'Haftung des Nutzers'} />
          <Paragraph>
            Der Nutzer trägt die alleinige Verantwortung für die Bestellung der auf der Plattform zum Verkauf
            angebotenen Produkte und Zubehörteile.
          </Paragraph>
          <Paragraph>
            Sie sind auch allein verantwortlich für die Nutzung ihres Käuferbereichs und die Informationen, die sie den
            Verkäufern zur Verfügung stellen.
          </Paragraph>
          <Paragraph>
            Im Allgemeinen haftet sie allein als Käufer, und die Plattform kann in ihrer Eigenschaft als Nutzer nicht
            haftbar gemacht werden.
          </Paragraph>
          <Paragraph>
            Für den Fall, dass ein Verkäufer oder eine andere Person rechtliche Schritte gegen die Plattform wegen
            Tatsachen im Zusammenhang mit seiner Tätigkeit als Nutzer einleiten sollte, verpflichtet sich der Verkäufer,
            die Plattform von einer Verurteilung freizustellen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artikel 22.'} title={'Anwendbares Recht - Streitigkeiten'} />
          <Paragraph>Diese AGB unterliegen deutschem Recht.</Paragraph>
          <Paragraph>
            Alle Streitigkeiten, unabhängig von der Ursache, vertraglich oder deliktisch, die ihre Ursache oder ihren
            Ursprung in der Gültigkeit, Auslegung, Ausführung dieser AGB oder allgemeiner in den von der Plattform
            angebotenen Dienstleistungen oder in den Beziehungen zwischen den Verkäufern und der Plattform finden
            würden, fallen in die Zuständigkeit der Gerichte von Köln.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Aktualisiert am 19. Juni 2023</Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'}>ANHANG I</Text>
        <MarginBox mt={15} />
        <Text type={'h5_bold'}>KÄUFERINFORMATIONEN - ZUGANGSVERFAHREN</Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-DE.png'} alt={'information-diagram-german'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
