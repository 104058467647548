/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { getDHReference } from 'domains/references';
import { ListItem } from 'pages/CatalogPage/DH/CategoriesMenu/CategoriesMenu.styled';
import { sentenceCase, Text } from 'UI';
import { getData } from 'utils';
import { OFFSET_LINK_TABS, OFFSET_SUB_HEADER, OFFSET_VEHICLE_INFO, useOffsetTop } from 'utils/hooks/useOffsetTop';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { IndexType } from 'utils/svg/common';

export interface PlateIndexListProps {
  detail: PlateReferencesDetail;
  vehicleKey: string | undefined;
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  highlightedIndex: IndexType;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
}

const PlateIndexItem = ({
  detail,
  vehicleKey,
  setSelectedIndex,
  highlightedIndex,
  setHighlightedIndex,
}: PlateIndexListProps) => {
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: detail.referenceNumber, vehicleKey }),
  );

  return (
    <ListItem
      onMouseOver={() => {
        setHighlightedIndex(detail.index.toString());
      }}
      onMouseLeave={() => setHighlightedIndex(null)}
      onClick={() => {
        const referenceElement = document.getElementById(`plate-reference-index-${detail.index}`);
        if (referenceElement) {
          setSelectedIndex(detail.index.toString());
          scrollToTopSmooth(
            referenceElement.offsetTop - offsetTop - 5 - OFFSET_SUB_HEADER - OFFSET_LINK_TABS - OFFSET_VEHICLE_INFO,
          );
        }
      }}
      key={`part-list-reference-${detail.index}`}
      id={`part-list-reference-${detail.index}`}
      $hovered={highlightedIndex === detail.index.toString()}
    >
      <Text type={'light_14_black_85'} cursor={'pointer'}>
        {`${detail.index}. ${sentenceCase(getData(reference)?.name ?? '')}`}
      </Text>
    </ListItem>
  );
};

export default PlateIndexItem;
