/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { ROUTER_ACCOUNT, ROUTER_BACKOFFICE, ROUTER_MY_STORE_BUNDLES } from 'app/AppRouter';
import { CogIcon, MyStoreIcon, RandomIcon, ReplyIcon, SolidKeyIcon, UserCircleIcon } from 'assets/icons';
import DelegateSessionConnectionBox from 'components/Page/Header/TopHeader/Profile/DelegateSessionConnectionBox/DelegateSessionConnectionBox';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import PrivateComponent from 'composers/PrivateComponent';
import { getAdminPortalLink, getMyStoreEnabled, getUserDelegationEnabled } from 'domains/appContext/AppContext.store';
import {
  disconnectFromConnectAsUserSaga,
  getDealerType,
  getDelegationSession,
  getTokenUser,
  getUserRights,
  isDelegationSessionStatusLoading,
  UserRole,
} from 'domains/user';
import { getBackOfficeMenuItems } from 'pages/BackOfficePage/BackOfficeLayout/BackOfficeMenu';
import { theme } from 'styles';
import { Box, Flex, Icon, IconType, Link, MarginBox, Spin, StatusDot, Text } from 'UI';
import { hasData } from 'utils';
import { ProfilePic } from './ProfileCard.styled';
import { SMenuItem } from '../TopHeader.styled';

interface ProfileCardProps {
  setProfileVisible: (visible: boolean) => void;
}

interface MenuItemProps {
  label: string;
  link: string;
  external: boolean;
  newTab?: boolean;
  icon: any;
  requiredRights: UserRole[];
}

export interface ProfileCardItemProps extends MenuItemProps {
  setProfileVisible: (visible: boolean) => void;
}

const ProfileCardWrapper = styled(Flex)`
  overflow: scroll;
`;

const ProfileCard = ({ setProfileVisible }: ProfileCardProps) => {
  const theme = useTheme();
  return (
    <ProfileCardWrapper
      direction={'column'}
      minWidth={390}
      maxWidth={390}
      maxHeight={window.innerHeight - HEADER_HEIGHT}
    >
      <MarginBox mt={15} />
      <UserSection />
      <Flex minHeight={8} maxHeight={8} background={theme.color.grey95} />
      <LinkSection setProfileVisible={setProfileVisible} />
    </ProfileCardWrapper>
  );
};

const UserSection = () => {
  const userInfo = useSelector(getTokenUser);
  const delegationSession = useSelector(getDelegationSession);
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);

  const { t } = useTranslation();

  return (
    <Flex direction={'column'} align={'center'}>
      <ProfilePic />
      {isDelegationLoading && <Spin size={'small'} />}
      <Flex>
        <StatusDot color={'selected'} />
        <Box width={7} />
        <Text type={'light_12_black_45'}>{t('header.connected_as ', 'Connected as')}</Text>
      </Flex>
      <Text type={'section_bold'}>{`${userInfo?.firstName} ${userInfo?.lastName}`}</Text>
      {hasData(delegationSession) && (
        <>
          <Text type={'section_bold'}>
            {`${delegationSession.commercialLink.name ?? ''} ${delegationSession.commercialLink.buyerId}`}
          </Text>
          <Text type={'section'}>
            {`${delegationSession.commercialLink.seller?.name ?? ''} ${delegationSession.commercialLink.seller?.id}`}
          </Text>
        </>
      )}
    </Flex>
  );
};

const DefaultMenuItemRenderer = (menuItem: ProfileCardItemProps) => {
  return (
    <PrivateComponent
      key={menuItem.label}
      render={() => (
        <div onClick={() => menuItem.setProfileVisible(false)}>
          <Link to={menuItem.link} external={menuItem.external} newTab={menuItem.newTab}>
            <SMenuItem minHeight={50} align={'center'}>
              <MarginBox ml={21} mr={16}>
                <Icon
                  IconComponent={menuItem.icon as IconType}
                  size={32}
                  display={'inline'}
                  color={theme.color.grey00}
                />
              </MarginBox>
              <Text type={'section'} cursor={'pointer'}>
                {menuItem.label}
              </Text>
            </SMenuItem>
          </Link>
        </div>
      )}
      requiredRights={menuItem.requiredRights ?? []}
    />
  );
};

const DelegateSessionConnectionWrapper = (menuItem: ProfileCardItemProps) => {
  const isR1 = useSelector(getDealerType) === 'R1';
  const userDelegationEnabled = useSelector(getUserDelegationEnabled);

  if (!userDelegationEnabled || !isR1) {
    return <></>;
  }
  return (
    <PrivateComponent
      key={menuItem.label}
      render={() => (
        <DelegateSessionConnectionBox
          setProfileVisible={menuItem.setProfileVisible}
          link={menuItem.link}
          external={menuItem.external}
          icon={menuItem.icon}
          requiredRights={menuItem.requiredRights}
          label={menuItem.label}
        />
      )}
      requiredRights={menuItem.requiredRights ?? []}
    />
  );
};

const ConnectAsDisconnectButton = (menuItem: ProfileCardItemProps) => {
  const dispatch = useDispatch();
  const handleDisconnectFrom = () => {
    dispatch(disconnectFromConnectAsUserSaga());
  };
  return (
    <PrivateComponent
      key={menuItem.label}
      render={() => (
        <div
          onClick={() => {
            handleDisconnectFrom();
            menuItem.setProfileVisible(false);
          }}
        >
          <SMenuItem minHeight={50} align={'center'}>
            <MarginBox ml={21} mr={16}>
              <Icon
                IconComponent={menuItem.icon as IconType}
                size={32}
                display={'inline'}
                color={theme.color.clear_blue}
              />
            </MarginBox>
            <Text type={'section'} cursor={'pointer'}>
              {menuItem.label}
            </Text>
          </SMenuItem>
        </div>
      )}
      requiredRights={menuItem.requiredRights ?? []}
    />
  );
};

const LinkSection = ({ setProfileVisible }: ProfileCardProps) => {
  const { t } = useTranslation();
  const connectedAs = useSelector(getDelegationSession);
  const userRights = useSelector(getUserRights);
  const myStoreEnabled = useSelector(getMyStoreEnabled);
  const adminPortalUrl = useSelector(getAdminPortalLink);

  return (
    <Flex direction={'column'}>
      <DefaultMenuItemRenderer
        setProfileVisible={setProfileVisible}
        link={ROUTER_ACCOUNT}
        external={false}
        icon={UserCircleIcon}
        requiredRights={[]}
        label={t('common.user_profile', 'User profile')}
      />
      <DelegateSessionConnectionWrapper
        setProfileVisible={setProfileVisible}
        link={''}
        external={false}
        icon={RandomIcon}
        requiredRights={[UserRole.CONNECT]}
        label={t('connect_as', 'Connect as')}
      />
      {myStoreEnabled && (
        <DefaultMenuItemRenderer
          setProfileVisible={setProfileVisible}
          link={ROUTER_MY_STORE_BUNDLES}
          external={false}
          icon={MyStoreIcon}
          requiredRights={[UserRole.R2, UserRole.R3, UserRole.R3_MOTRIO]}
          label={t('my-store', 'My store')}
        />
      )}
      {adminPortalUrl && (
        <DefaultMenuItemRenderer
          setProfileVisible={setProfileVisible}
          link={adminPortalUrl}
          external
          newTab
          icon={SolidKeyIcon}
          requiredRights={[UserRole.R1]}
          label={t('admin_portal', 'Admin portal')}
        />
      )}
      <DefaultMenuItemRenderer
        setProfileVisible={setProfileVisible}
        link={`${ROUTER_BACKOFFICE}/${getBackOfficeMenuItems(userRights, t)[0]?.link}`}
        external={false}
        icon={CogIcon}
        requiredRights={[UserRole.CENTRAL_ADMIN, UserRole.COUNTRY_ADMIN, UserRole.R1]}
        label={t('backoffice', 'Back office')}
      />
      {connectedAs != null && (
        <ConnectAsDisconnectButton
          setProfileVisible={setProfileVisible}
          link={''}
          external={false}
          icon={ReplyIcon}
          requiredRights={[UserRole.CONNECT]}
          label={t('common.action.back_to_my_profile', 'Back to my profile')}
        />
      )}
    </Flex>
  );
};

export default ProfileCard;
