import { Table } from 'antd';
import styled from 'styled-components';

export const SOrdersTable = styled(Table)`
  flex: 1;
  border: ${({ theme }) => theme.color.grey85} 8px;

  thead > tr > th {
    text-align: center;
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.color.grey85} 8px;
  }

  tbody > tr > td {
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => theme.color.grey85} 8px;
  }

  :active {
    background-color: ${({ theme }) => theme.color.grey95} 8px;
  }

  .ant-table-row {
    :hover {
      cursor: pointer;
    }
  }

  .ant-table-expanded-row > td > div > div > div > div > div > div > div > table > tbody > tr {
    :hover {
      cursor: default;
    }
  }

  table > tbody > tr > td > div > div > div > div > div > div > table > thead > tr > th {
    background-color: ${({ theme }) => theme.color.shadow_10};
  }
` as typeof Table;
