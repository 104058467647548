import React from 'react';
import { useSelector } from 'react-redux';
import { IAMFulltextSearchResult } from 'domains/catalog/Catalog.types';
import { getSparePartsView } from 'domains/user';
import SearchResultSection from 'pages/CatalogPage/IAM/FullTextSearchResult/SearchResultSection';
import { Container, defaultPadding, InfiniteScroll, MarginBox, useInfiniteScroll } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { useResetScrollSharp } from 'utils/hooks/useResetScroll';

const PAGING_SIZE = 10;

interface SearchResultContainerProps {
  searchResult: IAMFulltextSearchResult[];
}

const SearchResultContainer = ({ searchResult }: SearchResultContainerProps) => {
  const sparePartsView = useSelector(getSparePartsView);

  const { currentData, hasMore, loadMore } = useInfiniteScroll<IAMFulltextSearchResult>({
    data: searchResult ?? [],
    chunkSize: PAGING_SIZE,
  });

  useResetScrollSharp();

  return (
    <Container padding={defaultPadding}>
      <MarginBox mt={60}>
        <ViewTabs />
        <MarginBox mt={30}>
          <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
            {currentData.map((group: IAMFulltextSearchResult, index: number) => (
              <SearchResultSection key={index} searchResultGroup={group} sparePartsView={sparePartsView} />
            ))}
          </InfiniteScroll>
        </MarginBox>
      </MarginBox>
    </Container>
  );
};

export default SearchResultContainer;
