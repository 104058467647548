import React from 'react';
import { Trans } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { Document, Page, View } from '@react-pdf/renderer';
import { PdfStockTextWithDeliveryType } from 'components/Pdf/PdfStockDisplay';
import { PdfTotalSection, TotalSectionData } from 'components/Pdf/PdfTotalSection';
import {
  Flex,
  ItemNameTitle,
  LeadText,
  PageNumber,
  Spacer,
  TextMid,
  TextMidBold,
  TextMidLinethrough,
  Title,
} from 'components/Pdf/PdfUI';
import { components, styles } from 'components/Pdf/PdfUI.styles';
import { ReferenceLocal } from 'domains/basket/Basket.types';
import { CartListPdfData, CartPdfReference } from 'pages/CartPage/CartPdf/CartPdf.types';
import { textFormatter } from 'utils/format';

const Header = () => {
  const formattedDate = textFormatter.formatDate(new Date());
  return (
    <TwoColumnsLayout
      leftColumn={
        <Title>
          <Trans i18nKey="cart.pdf.list.title">My cart</Trans>
        </Title>
      }
      rightColumn={
        <LeadText>
          <Trans i18nKey="common.pdf.date" tOptions={{ date: formattedDate }}>
            Date: {{ formattedDate }}
          </Trans>
        </LeadText>
      }
    />
  );
};

interface ItemHeaderProps {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}

const TwoColumnsLayout = ({ leftColumn, rightColumn }: ItemHeaderProps) => (
  <View style={[styles.row, { alignItems: 'flex-end', justifyContent: 'space-between' }]}>
    {leftColumn}
    {rightColumn}
  </View>
);

const VehicleContext = ({ vehicleDetail }: { vehicleDetail: VehicleDetail }) => {
  const vehicle = vehicleDetail.catalogSource === 'DATAHUB' ? vehicleDetail.dataHubVehicle : vehicleDetail.iamVehicle;
  return (
    <Flex>
      <ItemNameTitle>{`${vehicle?.name} ${vehicle?.modelType} - ${vehicleDetail.vrn}`}</ItemNameTitle>
    </Flex>
  );
};

const SectionTotal = ({
  totalVatExclValue,
  totalVatExclCurrency,
}: {
  totalVatExclValue?: number;
  totalVatExclCurrency?: string;
}) => {
  const totalVatExclFormatted = textFormatter.formatCurrency(Number(totalVatExclValue), totalVatExclCurrency);
  return (
    <Flex>
      <ItemNameTitle>
        <Trans i18nKey="common.pdf.total_exclulded_vat">Total VAT excl.:</Trans>
        {` ${totalVatExclFormatted}`}
      </ItemNameTitle>
    </Flex>
  );
};

const ReferencesList = ({ references, data }: { references: ReferenceLocal[]; data: CartListPdfData }) => {
  return references.map((reference) => {
    return <ReferenceCard key={reference.referenceNumber} reference={reference} data={data} />;
  });
};

const ReferenceCard = ({ reference, data }: { reference: CartPdfReference; data: CartListPdfData }) => {
  const { price, stock, discount: referenceDiscount } = reference;
  const { otherData } = data;
  const groupDiscountText = referenceDiscount?.groupDiscount?.toString();
  const promotionDiscountText = referenceDiscount?.promotionDiscount?.toString();
  const totalDiscountText = (
    (referenceDiscount?.groupDiscount ?? 0) + (referenceDiscount?.promotionDiscount ?? 0)
  ).toString();
  const referenceNumber = reference.referenceNumber;
  const { allDeliveryLeadTime, dealerType, r1Country } = otherData;

  const renderStocks = () => {
    return (
      <View style={styles.col}>
        {stock?.warehouses.map((warehouse, index) => {
          const warehouseDeliveryLeadTime = allDeliveryLeadTime?.find(
            (deliveryLeadTime) => deliveryLeadTime.warehouse === warehouse.type,
          );
          return (
            <PdfStockTextWithDeliveryType
              key={`${referenceNumber}_${index}`}
              narrow={false}
              stock={stock}
              quantity={warehouse.confirmedQuantity}
              type={warehouse.type}
              index={index}
              deliveryLeadTime={warehouseDeliveryLeadTime}
              dealerType={dealerType}
              r1Country={r1Country}
              isMKTP={reference.referenceSource === 'MARKETPLACE'}
            />
          );
        })}
      </View>
    );
  };

  const getDiscountPCL = (discount?: string) => (
    <Trans i18nKey="cart.pdf.reference.discount.pcl">{{ discount }}% Off</Trans>
  );
  const getDiscountPNMRA = (groupDiscount?: string, promotionDiscount?: string) => (
    <Trans i18nKey="cart.pdf.reference.discount.pnmra">
      *Group Discount: {{ groupDiscount }}% and an additional promotionDiscount of {{ promotionDiscount }}%
    </Trans>
  );

  if (!price) {
    return null;
  }

  return (
    <View>
      <View
        style={[
          styles.row,
          {
            width: '560px',
            height: '30px',
          },
        ]}
      >
        <View
          style={[
            styles.col,
            {
              width: '250px',
              gap: '1px',
            },
          ]}
        >
          <View style={styles.row}>
            <TextMidBold>
              <Trans i18nKey={'common.reference_number.short'}>Ref: {{ referenceNumber }}</Trans>
            </TextMidBold>
            <TextMid>{` - ${reference.name}`}</TextMid>
          </View>
          <View>
            <View style={styles.row}>
              <TextMidBold>
                <Trans i18nKey={'common.stock'}>Stock: </Trans>
              </TextMidBold>
              {renderStocks()}
            </View>
          </View>
          <View style={[styles.row]}>
            <TextMidBold>
              <Trans i18nKey="common.quantity.short">Qty: </Trans>
            </TextMidBold>
            <TextMid>{`${reference.quantity}`}</TextMid>
          </View>
        </View>
        <View
          style={[
            styles.col,
            {
              width: '100px',
              gap: '1px',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            },
          ]}
        >
          {reference.note && (
            <View style={styles.row} wrap={true}>
              <TextMidBold>
                <Trans i18nKey={'order.referenceMark'}>Reference mark: </Trans>
              </TextMidBold>
              <TextMid>{reference.note}</TextMid>
            </View>
          )}
        </View>
        <View
          style={[
            styles.col,
            {
              width: '250px',
              margin: '0 5px 0 5px',
            },
          ]}
        >
          {reference.price && (
            <View style={[styles.row, { justifyContent: 'flex-end' }]}>
              <TextMidLinethrough>
                {textFormatter.formatCurrency(
                  Number(reference.price.priceTotalVatExcluded),
                  data.otherData.totalBasketValues.currency,
                )}
                <Trans i18nKey="common.price.vat_exclude_price.short">VAT. Excl</Trans>
              </TextMidLinethrough>
            </View>
          )}
          {reference.price && (
            <View style={[styles.row, { justifyContent: 'flex-end' }]}>
              <TextMidBold>
                {textFormatter.formatCurrency(
                  Number(reference.price.discountedPriceTotalVatExcluded),
                  data.otherData.totalBasketValues.currency,
                )}
                <Trans i18nKey="common.price.vat_exclude_price.short">VAT. Excl</Trans>
              </TextMidBold>
            </View>
          )}
          {referenceDiscount &&
            (referenceDiscount.discountType === 'PNMRA' ? (
              <View style={[styles.row, { justifyContent: 'flex-end' }]} wrap={true}>
                <TextMid>{getDiscountPNMRA(groupDiscountText, promotionDiscountText)}</TextMid>
              </View>
            ) : (
              <View style={[styles.row, { justifyContent: 'flex-end' }]} wrap={true}>
                <TextMid>{getDiscountPCL(totalDiscountText)}</TextMid>
              </View>
            ))}
        </View>
      </View>

      <View
        style={[{ height: '5px', width: '550px', marginBottom: '5px', marginLeft: '5px' }, components.dividerBottom]}
      />
    </View>
  );
};

export const CartListPdfPage = ({ data }: { data: CartListPdfData }) => {
  const { otherData, vehicleSection, externalBasketSection, otherItemsSection } = data;
  const getTotalItems = (itemsCount?: string) => (
    <Trans i18nKey="common.pdf.total_items">Total: {{ itemsCount }} items</Trans>
  );
  const totalSectionData: TotalSectionData = {
    totalSectionText: {
      total: getTotalItems(otherData.totalBasketValues.itemsCount),
      totalVatExcl: <Trans i18nKey="common.pdf.total_exclulded_vat">Total VAT excl.:</Trans>,
      totalVat: <Trans i18nKey="my_orders.details.vat">VAT:</Trans>,
      totalVatIncl: <Trans i18nKey="common.pdf.total_included_vat">Total VAT.incl.:</Trans>,
      totalDiscountVatExcl: <Trans i18nKey="common.pdf.total_discount_exclulded_vat">Total discount VAT. Excl:</Trans>,
    },
    totalSectionValue: {
      priceVatExcluded: otherData.totalBasketValues.priceVatExcluded,
      priceVat: otherData.totalBasketValues.priceVat,
      priceVatIncluded: otherData.totalBasketValues.priceVatIncluded,
      discountVatExcluded: otherData.totalBasketValues.discountVatExcluded,
      currency: otherData.totalBasketValues.currency,
    },
  };

  return (
    <Document>
      <Page size={'A4'} style={styles.page} wrap={true}>
        <PageNumber />
        <Header />
        <Spacer height={16} />
        {vehicleSection?.map((vehicle) => {
          const { vehicleDetail, orderMark, sectionData } = vehicle;
          return (
            <View key={vehicleDetail.vrn ?? vehicleDetail.vin} wrap={false}>
              <TwoColumnsLayout
                leftColumn={<VehicleContext vehicleDetail={vehicleDetail} />}
                rightColumn={
                  <SectionTotal
                    totalVatExclValue={vehicle.sectionData.sectionTotalVatExcl}
                    totalVatExclCurrency={otherData.totalBasketValues.currency}
                  />
                }
              />
              <Spacer height={2} />
              <View style={styles.row}>
                <TextMidBold>
                  <Trans i18nKey={'common.vehicle.ordermark'}>Ordermark: </Trans>
                </TextMidBold>
                <TextMid>{orderMark}</TextMid>
              </View>
              <Spacer height={6} />
              <ReferencesList references={sectionData.references} data={data} />
              <Spacer height={12} />
            </View>
          );
        })}
        {externalBasketSection?.map((externalBasket) => {
          const basketId = externalBasket.externalBasketId;
          return (
            <View key={basketId} wrap={false}>
              <TwoColumnsLayout
                leftColumn={
                  <ItemNameTitle>
                    <Trans i18nKey="cart.pdf.list.external_basket">External basket {{ basketId }}</Trans>
                  </ItemNameTitle>
                }
                rightColumn={
                  <SectionTotal
                    totalVatExclValue={externalBasket.sectionData.sectionTotalVatExcl}
                    totalVatExclCurrency={otherData.totalBasketValues.currency}
                  />
                }
              />

              <Spacer height={6} />
              <ReferencesList references={externalBasket.sectionData.references} data={data} />
              <Spacer height={12} />
            </View>
          );
        })}
        {otherItemsSection && (
          <View wrap={false}>
            <TwoColumnsLayout
              leftColumn={
                <ItemNameTitle>
                  <Trans i18nKey="cart.pdf.list.other_articles">Other articles</Trans>
                </ItemNameTitle>
              }
              rightColumn={
                <SectionTotal
                  totalVatExclValue={otherItemsSection.sectionData.sectionTotalVatExcl}
                  totalVatExclCurrency={otherData.totalBasketValues.currency}
                />
              }
            />

            <Spacer height={6} />
            <ReferencesList references={otherItemsSection.sectionData.references} data={data} />
            <Spacer height={12} />
          </View>
        )}
        <Spacer height={20} />
        <View style={[styles.row_reverse, { marginLeft: '100px' }]}>{<PdfTotalSection data={totalSectionData} />}</View>
      </Page>
    </Document>
  );
};
