import styled from 'styled-components';
import { BadgePropsExtended, ForwardedBadge } from './CustomBadge';

export const SBadge = styled(ForwardedBadge)<BadgePropsExtended>`
  .ant-badge-count {
    width: 25px;
    height: 25px;
    line-height: 25px;
    box-shadow: 0 0 0 1px
      ${({ checked, disabled, theme }) =>
        checked ? theme.color.blue : disabled ? theme.color.grey85 : theme.color.blue};
    border-radius: 25px;
    color: ${({ checked, disabled, theme }) =>
      checked ? theme.color.blue : disabled ? theme.color.grey85 : theme.color.white};
    font-family: NouvelR;
    font-size: 14px;
    font-weight: 400;
    background-color: ${({ checked, disabled, theme }) =>
      checked ? theme.color.white : disabled ? theme.color.white : theme.color.blue};
  }

  cursor: pointer;
`;
