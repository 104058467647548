import styled from 'styled-components';

export const BannerWrapper = styled.div`
  border: solid 1px ${({ theme }) => theme.color.white_dim}
  flex: 1;
  display: flex;
`;

export const BannerBackgroundContainer = styled.div`
  background-image: url('/images/catalog/marketing-references-banner.jpg');
  background-size: cover;
  background-position: left center;
  height: 90px;
  flex: 1 169px;
  display: flex;
`;

export const BannerText = styled.div`
  background-color: ${({ theme }) => theme.color.grey45};
  color: ${({ theme }) => theme.color.white};
  height: 35px;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ShowMoreButtonContainer = styled.div`
  display: flex;
  width: 360px;
`;
