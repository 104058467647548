import React from 'react';
import styled from 'styled-components';

export type PaddingType = number | string;

const SPadding = styled.div<{
  pl: PaddingType;
  pr: PaddingType;
  pt: PaddingType;
  pb: PaddingType;
}>`
  padding-left: ${({ pl }) => (typeof pl === 'string' ? pl : `${pl}px`)};
  padding-right: ${({ pr }) => (typeof pr === 'string' ? pr : `${pr}px`)};
  padding-top: ${({ pt }) => (typeof pt === 'string' ? pt : `${pt}px`)};
  padding-bottom: ${({ pb }) => (typeof pb === 'string' ? pb : `${pb}px`)};
`;

export interface PaddingProps {
  className?: string;
  children?: React.ReactNode;
  pl?: PaddingType;
  pr?: PaddingType;
  pt?: PaddingType;
  pb?: PaddingType;
  px?: PaddingType;
  py?: PaddingType;
}

export const PaddingBox = ({ className, children, pb = 0, pl = 0, pr = 0, pt = 0, px, py }: PaddingProps) => {
  pl = px ?? pl;
  pr = px ?? pr;

  pb = py ?? pb;
  pt = py ?? pt;

  return (
    <SPadding className={className} pb={pb} pl={pl} pr={pr} pt={pt}>
      {children}
    </SPadding>
  );
};
