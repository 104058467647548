import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CarIcon } from 'assets/icons';
import { VehicleCountry } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getVehicleSearchHistory } from 'domains/user';
import { theme } from 'styles';
import { Box, CenteredSpin, CenterFlex, Flex, Icon, Image, MarginBox, Pipeline, Text, URL } from 'UI';
import VehicleFlag from 'UI/Icon/VehicleFlag';
import { hasData, isLoading } from 'utils';
import { SSearchHistoryContent, SSearchHistoryItem } from './SearchHistoryContent.styled';

function SearchHistoryItem({ vehicle }: { vehicle: VehicleDetail }) {
  const { t } = useTranslation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  function selectVehicle() {
    //FIXME split IAM/DH
    if (vehicle.country) {
      params.set(VehicleCountry, vehicle.country);
    }
    history.push(
      `${getCatalogSourceUrl(vehicle.catalogSource)}/${vehicle.vehicleKey}${ROUTER_CATALOG_VEHICLE}?${params}`,
    );
  }

  const getEngine = () => {
    switch (vehicle.catalogSource) {
      case 'DATAHUB':
        return vehicle.dataHubVehicle?.engine;
      case 'IAM':
        return vehicle.iamVehicle?.engineCodes;
    }
  };

  const getName = () => {
    switch (vehicle.catalogSource) {
      case 'DATAHUB':
        return vehicle.dataHubVehicle?.name;
      case 'IAM':
        return `${vehicle.vehicleBrand ?? ''} ${vehicle.model ?? ''}`;
    }
  };

  const DefaultVehicleImage = () => (
    <Box height={65} width={65} background={theme.color.grey95}>
      <CenterFlex>
        <Icon IconComponent={CarIcon} size={30} color={theme.color.brand_black} noPointer />
      </CenterFlex>
    </Box>
  );

  return (
    <SSearchHistoryItem onClick={selectVehicle}>
      <Flex align={'center'}>
        <Image
          src={vehicle.imageUrl}
          type={URL}
          alt={'car-preview'}
          width={65}
          catalogSource={vehicle.catalogSource}
          fallbackComponent={<DefaultVehicleImage />}
        />
        <MarginBox mr={10} />
        <Flex direction={'column'}>
          <Flex align={'center'}>
            <Text type={'text_dim'} cursor={'pointer'}>
              {getName()}
            </Text>
            <MarginBox mr={6} />
            <VehicleFlag vehicle={vehicle} />
            <MarginBox mr={6} />
            {vehicle.vrn && (
              <Text type={'light_12_medium_black_85'} cursor={'pointer'}>
                {vehicle.vrn}
              </Text>
            )}
          </Flex>

          <Flex direction={'column'}>
            {vehicle.vin && (
              <Flex>
                <Text type={'light_12_black_65'} cursor={'pointer'}>
                  {t('catalog.vehicle.vin', 'VIN')}:
                </Text>
                <MarginBox mr={5} />
                <Text type={'light_12_black_65'} cursor={'pointer'}>
                  {vehicle.vin}
                </Text>
              </Flex>
            )}

            {(vehicle.iamVehicle?.engineCodes || vehicle.dataHubVehicle?.engine) && (
              <Flex>
                <Text type={'light_12_black_65'} cursor={'pointer'}>
                  {t('catalog.vehicle.engine', 'Engine')}:
                </Text>
                <MarginBox mr={5} />
                <Text type={'light_12_black_65'} cursor={'pointer'}>
                  {getEngine()}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <MarginBox mt={5} />
      <Pipeline size={0} offsetRatio={100} horizontal={true} />
      <MarginBox mt={5} />
    </SSearchHistoryItem>
  );
}

export default function SearchHistoryContent() {
  const { t } = useTranslation();
  const vehicleSearchHistory = useSelector(getVehicleSearchHistory);
  const lastSearchedVehicleKey = useSelector((state: RootState) => state.catalog.lastSearchedVehicleKey);

  if (!hasData(vehicleSearchHistory) || vehicleSearchHistory?.length < 2) {
    return (
      <SSearchHistoryContent>
        {isLoading(vehicleSearchHistory) ? (
          <CenteredSpin />
        ) : (
          <Text type={'section_bold'}>{t('catalog.history.is_empty', 'Your search history is empty')}</Text>
        )}
      </SSearchHistoryContent>
    );
  }

  return (
    <SSearchHistoryContent>
      {vehicleSearchHistory
        .filter((item) => item.vehicleKey !== lastSearchedVehicleKey)
        .map((v) => (
          <SearchHistoryItem key={v.vehicleKey} vehicle={v} />
        ))}
    </SSearchHistoryContent>
  );
}
