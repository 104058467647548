import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROUTER_MY_STORE_BUNDLES, ROUTER_MY_STORE_TIRES } from 'app/AppRouter';
import { OutlineContentIcon, TireHollowIcon } from 'assets/icons';
import { getMyStoreTiresEnabled } from 'domains/appContext/AppContext.store';
import Bundles from 'pages/MyStorePage/Pages/Bundles';
import Tires from 'pages/MyStorePage/Pages/Tires/Tires';
import { IconType } from 'UI';

export const useMyStoreMenu = (): {
  component: React.ReactNode;
  icon: IconType;
  key: string;
  link: string;
  title: string;
}[] => {
  const { t } = useTranslation();
  const myStoreTiresEnabled = useSelector(getMyStoreTiresEnabled);
  return [
    {
      key: 'bundles',
      icon: OutlineContentIcon,
      title: t('my_store.bundles.title', 'Bundles'),
      component: <Bundles />,
      link: ROUTER_MY_STORE_BUNDLES,
    },
    {
      key: 'tires',
      icon: TireHollowIcon,
      title: t('catalog.tires', 'Tires'),
      component: <Tires />,
      link: ROUTER_MY_STORE_TIRES,
    },
  ].filter((item) => (item.key !== 'tires' ? true : !!myStoreTiresEnabled));
};
