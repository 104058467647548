import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { StepProps, Steps as AntSteps } from 'antd';
import styled from 'styled-components';
import { Box, WhiteButton, YellowButton } from 'UI';

const { Step: AntStep } = AntSteps;

const SSteps = styled(AntSteps)`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${({ theme }) => theme.color.clear_blue};
    border-color: ${({ theme }) => theme.color.clear_blue};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.clear_blue};
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ theme }) => theme.color.clear_blue};
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${({ theme }) => theme.color.clear_blue};
  }
`;

export type StepData = { title: ReactNode; icon?: ReactNode; disabled?: boolean };

export interface StepsProps {
  currentStep: number;
  setCurrentStep: (x: number) => void;
  clickable?: boolean;
  prefix?: string;
  data: StepData[];
}

export const Steps = ({ currentStep, data, setCurrentStep, clickable, prefix }: StepsProps) => {
  return (
    <SSteps current={currentStep}>
      {data.map((step, index) => (
        <Step
          key={`${prefix ?? 'step'}-${index}`}
          onClick={() => !step.disabled && clickable && currentStep > index && setCurrentStep(index)}
          {...step}
        />
      ))}
    </SSteps>
  );
};

const SStep = styled(AntStep)<{ current?: boolean }>`
  cursor: ${({ current, disabled }) => (current || disabled ? 'default' : 'pointer')};

  :hover {
    font-weight: ${({ current, disabled }) => (current || disabled ? 'normal' : 'bold')};
  }
`;

const Step = (props: StepProps) => {
  return <SStep onClick={() => undefined} {...props} />;
};

export interface StepButtonsProps {
  currentStep: number;
  setCurrentStep: (x: number) => void;
  totalItems: number;
  nextEnabled?: boolean;
  previousEnabled?: boolean;
  stretch?: boolean;
}

export const StepButtons = ({
  currentStep,
  setCurrentStep,
  totalItems,
  nextEnabled = true,
  previousEnabled = true,
  stretch = false,
}: StepButtonsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {currentStep > 0 && (
        <>
          <WhiteButton stretch={stretch} onClick={() => setCurrentStep(currentStep - 1)} disabled={!previousEnabled}>
            {t('common.action.previous', 'Previous')}
          </WhiteButton>
          <Box width={20} />
        </>
      )}
      {currentStep < totalItems && (
        <YellowButton stretch={stretch} onClick={() => setCurrentStep(currentStep + 1)} disabled={!nextEnabled}>
          {t('common.action.next', 'Next')}
        </YellowButton>
      )}
    </>
  );
};
