import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { AngleLeftIcon, AngleRightIcon, ExpandIcon, PrintIcon } from 'assets/icons';
import { useDecodePlateImage } from 'domains/catalog/Catalog.hooks';
import { DATAHUB, PlateImage, PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { useFetchUrlImage } from 'domains/pictures/Pictures.requests';
import { getPicture } from 'domains/pictures/Pictures.store';
import PlateDetailCardImage from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateDetailCardImage';
import { Flex, Icon, IconButton, MarginBox, Text } from 'UI';
import { NO_DATA } from 'utils';
import { OFFSET_LINK_TABS, OFFSET_SUB_HEADER, OFFSET_VEHICLE_INFO, useOffsetTop } from 'utils/hooks/useOffsetTop';
import { IndexType, svgTextPosition, useDuplicateSvgLinesAndNumber } from 'utils/svg/common';
import { useAddListenersToPlateSvg, useManipulateLinesInSvg } from 'utils/svg/manipulatePlateSvg';
import { SBox, SPlateCard } from './PlateDetailCard.styled';

export interface PlateDetailCardProps {
  plate: PlateImage;
  platesCount?: number;
  browseLeft: () => void;
  browseRight: () => void;
  plateReferences: PlateReferencesDetail[] | NO_DATA;
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  highlightedIndex: IndexType;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  setIsPlateModalOpen?: (v: boolean) => void;
  svgElementPlateId: string;
  isModal?: boolean;
  onPrintPlate?: () => void;
}

const PlateDetailCard = ({
  plate,
  platesCount,
  browseLeft,
  browseRight,
  plateReferences,
  setSelectedIndex,
  highlightedIndex,
  setHighlightedIndex,
  setIsPlateModalOpen,
  svgElementPlateId,
  isModal = false,
  onPrintPlate,
}: PlateDetailCardProps) => {
  const plateImageBase64 = useSelector((state: RootState) => getPicture(state, plate.imageKey));
  const svgElement = useDecodePlateImage(plate, plateImageBase64);
  const hasMultiplePlates = platesCount ? platesCount > 1 : false;
  const scrollOffset = useOffsetTop('RENAULT_CATALOG') + 5 + OFFSET_SUB_HEADER + OFFSET_LINK_TABS + OFFSET_VEHICLE_INFO;
  useDuplicateSvgLinesAndNumber(
    svgElement,
    svgElementPlateId,
    plate.plateId,
    svgTextPosition,
    false,
    plateReferences?.length ?? 0,
  );
  useFetchUrlImage(plate.imageKey, DATAHUB);
  const svgManipulated = useManipulateLinesInSvg(
    plate.plateId,
    plateReferences,
    highlightedIndex,
    svgElement,
    svgElementPlateId,
  );
  useAddListenersToPlateSvg(
    plate.plateId,
    setHighlightedIndex,
    setSelectedIndex,
    scrollOffset,
    svgElement,
    svgElementPlateId,
    svgManipulated,
  );

  const handleExpand = () => {
    setIsPlateModalOpen && setIsPlateModalOpen(true);
  };

  const handlePrint = () => {
    onPrintPlate && onPrintPlate();
  };

  return (
    <SBox width={'100%'} height={'100%'} maxWidth={(window.innerHeight - scrollOffset) * 1.2} isModal={isModal}>
      <SPlateCard id={'plate_detail_svg'} $loading={!svgElement} data-cy={'image-plate'}>
        <Flex direction={'column'} minHeight={'100%'}>
          <Flex align={'center'}>
            {hasMultiplePlates ? (
              <MarginBox mx={15}>
                <Icon size={25} IconComponent={AngleLeftIcon} onClick={browseLeft} />
              </MarginBox>
            ) : (
              <MarginBox mr={40} />
            )}
            <PlateDetailCardImage plateImage={plate} image={svgElement} svgElementPlateId={svgElementPlateId} />
            {hasMultiplePlates ? (
              <MarginBox mx={15}>
                <Icon size={25} IconComponent={AngleRightIcon} onClick={browseRight} />
              </MarginBox>
            ) : (
              <MarginBox mr={40} />
            )}
          </Flex>
          <Flex direction={'column-reverse'} maxHeight={30}>
            <Flex align={'center'}>
              <MarginBox ml={20}>
                <Text type={'light_dimmer'}>{plate.plateId}</Text>
              </MarginBox>
              {setIsPlateModalOpen && (
                <Flex direction={'row-reverse'}>
                  <MarginBox mr={20}>
                    <IconButton
                      IconComponent={ExpandIcon}
                      size={20}
                      bgColor={'grey95'}
                      round
                      onClick={handleExpand}
                      disabled={svgElement === 'error'}
                    />
                  </MarginBox>
                  <MarginBox mr={20}>
                    <IconButton
                      IconComponent={PrintIcon}
                      size={20}
                      bgColor={'grey95'}
                      round
                      onClick={handlePrint}
                      disabled={svgElement === 'error'}
                    />
                  </MarginBox>
                </Flex>
              )}
            </Flex>
          </Flex>
          <MarginBox mt={15} />
        </Flex>
      </SPlateCard>
    </SBox>
  );
};

export default PlateDetailCard;
