import React from 'react';
import { Trans } from 'react-i18next';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import { Flex, InputNumber, MarginBox, Text } from 'UI';

export const FlashPromotionDiscount = ({
  promotion,
  setPromotion,
}: {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
}) => {
  const discountCommercialFamilyCodes =
    promotion.commercialFamilyCodes && promotion.commercialFamilyCodes.length > 0
      ? promotion.commercialFamilyCodes[0].discount
      : undefined;
  const flashPromotionDiscount =
    promotion.references && promotion.references.length > 0
      ? promotion.references[0].discount
      : discountCommercialFamilyCodes;

  return (
    <Flex direction={'column'} minWidth={200} maxWidth={200}>
      <Text type={'h5_bold'}>
        <Trans i18nKey={'backoffice.promotion.apply_discount'}>Apply discount</Trans>
      </Text>
      <MarginBox mt={20} />
      <Flex minWidth={115} maxWidth={115}>
        <InputNumber
          disabled={promotion.editing}
          placeholder={'-'}
          addonAfter={'%'}
          min={0}
          max={100}
          value={flashPromotionDiscount !== 0 ? flashPromotionDiscount : undefined}
          onChangeDebounced={(discount) => {
            const newCommercialFamilyCodes = promotion.commercialFamilyCodes?.map((c) => ({
              ...c,
              discount: Number(discount) || 0,
            }));
            const newReferences = promotion.references?.map((c) => ({
              ...c,
              discount: Number(discount) || 0,
            }));
            const newReferencesFromFile = promotion.uploadedReferencesFromFiles?.map((c) => ({
              ...c,
              discount: Number(discount) || 0,
            }));
            setPromotion({
              ...promotion,
              commercialFamilyCodes: newCommercialFamilyCodes ?? [{ items: [], discount: Number(discount) || 0 }],
              references: newReferences ?? [{ items: [], discount: Number(discount) || 0 }],
              uploadedReferencesFromFiles: newReferencesFromFile ?? [{ items: [], discount: Number(discount) || 0 }],
            });
          }}
        />
      </Flex>
      <MarginBox mt={30} />
    </Flex>
  );
};
