import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import {
  getEstimateFreeBundlesSectionPriceVatExcl,
  getEstimateFreeBundlesSectionVat,
  getEstimateLaborTimeSectionPriceVatExcl,
  getEstimateLaborTimeSectionVat,
  getEstimateOtherItemSectionPriceVatExcl,
  getEstimateOtherItemSectionVat,
  getEstimateReferenceDiscountSumMargin,
  getEstimateReferenceSectionPriceVatExcl,
  getEstimateReferenceSectionVat,
  getEstimateTireSectionPriceVatExcl,
  getEstimateTireSectionVat,
  getEstimateWasteRecyclingSectionPriceVatExcl,
  getEstimateWasteRecyclingSectionVat,
  getTotalMargin,
  getTotalMarginPercentage,
} from 'domains/estimate/Estimate.store';
import { GarageView, getCurrency } from 'domains/user';
import TotalMarginLine from 'pages/EstimatePage/DetailsSection/TotalMarginLine';
import { LightCard, MarginBox } from 'UI';
import { textFormatter } from 'utils';
import DetailsSectionLine from './DetailsSectionLine';

export interface DetailsSectionProps {
  estimateId: string;
  sparePartsView?: string;
}

const DetailsSection = ({ estimateId, sparePartsView }: DetailsSectionProps) => {
  const { t } = useTranslation();
  const referencesPriceVatExcl = Number(
    useSelector((state: RootState) => getEstimateReferenceSectionPriceVatExcl(state, estimateId) ?? 0),
  );
  const referencesVat = useSelector((state: RootState) => getEstimateReferenceSectionVat(state, estimateId));

  const laborTimePriceVatExcl = useSelector((state: RootState) =>
    getEstimateLaborTimeSectionPriceVatExcl(state, estimateId),
  );
  const laborTimeVat = useSelector((state: RootState) => getEstimateLaborTimeSectionVat(state, estimateId));

  const freeBundlesPriceVatExcl = useSelector((state: RootState) =>
    getEstimateFreeBundlesSectionPriceVatExcl(state, estimateId),
  );
  const freeBundlesVat = useSelector((state: RootState) => getEstimateFreeBundlesSectionVat(state, estimateId));

  const tiresPriceVatExcl = Number(
    useSelector((state: RootState) => getEstimateTireSectionPriceVatExcl(state, estimateId)) ?? 0,
  );
  const tiresVat = useSelector((state: RootState) => getEstimateTireSectionVat(state, estimateId));

  const otherItemPriceVatExcl = Number(
    useSelector((state: RootState) => getEstimateOtherItemSectionPriceVatExcl(state, estimateId)) ?? 0,
  );
  const otherItemVat = useSelector((state: RootState) => getEstimateOtherItemSectionVat(state, estimateId));

  const wasteRecyclingPriceVatExcl = Number(
    useSelector((state: RootState) => getEstimateWasteRecyclingSectionPriceVatExcl(state, estimateId)) ?? 0,
  );
  const wasteRecyclingVat = useSelector((state: RootState) => getEstimateWasteRecyclingSectionVat(state, estimateId));

  const totalMargin = Number(useSelector((state: RootState) => getTotalMargin(state, estimateId)) ?? 0);
  const totalMarginPercentage = Number(
    useSelector((state: RootState) => getTotalMarginPercentage(state, estimateId)) ?? 0,
  );
  const currency = useSelector(getCurrency);
  const sumDiscountMargins = useSelector((state: RootState) =>
    getEstimateReferenceDiscountSumMargin(state, estimateId),
  );
  const totalMarginDiscounts = sumDiscountMargins ? totalMargin + sumDiscountMargins : totalMargin;
  const totalPriceExcludingLaborTime = referencesPriceVatExcl + tiresPriceVatExcl + otherItemPriceVatExcl;
  const totalMarginPercentagesDiscounts = sumDiscountMargins
    ? (totalMarginDiscounts / totalPriceExcludingLaborTime) * 100
    : totalMarginPercentage;

  return (
    <LightCard title={t('order.details', 'Order details')}>
      <DetailsSectionLine
        sectionName={t('catalog.reference_plural', 'References')}
        currency={currency}
        totalVatExcl={Number(referencesPriceVatExcl)}
        vat={Number(referencesVat)}
      />
      <MarginBox mt={15} />
      <DetailsSectionLine
        sectionName={t('catalog.parts.category.car_parts.labor_time', 'Labor time')}
        currency={currency}
        totalVatExcl={Number(laborTimePriceVatExcl)}
        vat={Number(laborTimeVat)}
      />
      <MarginBox mt={15} />
      <DetailsSectionLine
        sectionName={t('estimate.bundles.title', 'Bundles')}
        currency={currency}
        totalVatExcl={Number(freeBundlesPriceVatExcl)}
        vat={Number(freeBundlesVat)}
      />
      <MarginBox mt={15} />
      <DetailsSectionLine
        sectionName={t('catalog.tires', 'Tires')}
        currency={currency}
        totalVatExcl={Number(tiresPriceVatExcl)}
        vat={Number(tiresVat)}
      />
      <MarginBox mt={15} />
      <DetailsSectionLine
        sectionName={t('common.other', 'Other')}
        currency={currency}
        totalVatExcl={Number(otherItemPriceVatExcl)}
        vat={Number(otherItemVat)}
      />
      <MarginBox mt={15} />
      <DetailsSectionLine
        sectionName={t('catalog.waste_recycling', 'Waste recycling')}
        currency={currency}
        totalVatExcl={Number(wasteRecyclingPriceVatExcl)}
        vat={Number(wasteRecyclingVat)}
      />
      <MarginBox mt={15} />
      {sparePartsView === GarageView && (
        <TotalMarginLine
          currency={currency}
          totalMargin={totalMarginDiscounts}
          totalMarginPercentage={textFormatter.formatNumber(totalMarginPercentagesDiscounts, 2)}
        />
      )}
    </LightCard>
  );
};

export default DetailsSection;
