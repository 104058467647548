export function getBase64(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader?.result?.toString().replace(/^.*,/, ''));
    };
    reader.onerror = (error) => reject(error);
  });
}
