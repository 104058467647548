import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Crop, makeAspectCrop, ReactCrop } from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { Image as PromoImage } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import { RootState } from 'app/AppStore';
import { FileUpload } from 'components/FileUpload';
import { FILE, FILE_FORMAT_ERROR, MAX_SIZE_ERROR, ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import { getPicture } from 'domains/pictures/Pictures.store';
import { getPublishedStatus, setInitialCreatePromotion } from 'domains/promotion/Promotion.store';
import { Box, Flex, Image as Img } from 'UI';
import { hasData } from 'utils';
import './CropShadow.css';

export const imageFileRegex = /^[^\s]+\.((jpe?g)|(png))$/i;

interface PromotionVisualProps {
  promotionId: string;
  editing: boolean;
  image: PromoImage | undefined;
  handleSetFileBase64: (fileName: string, imageBase64: string) => void;
  handleSetNoFile: () => void;
}

export const PromotionVisual = ({
  promotionId,
  editing,
  image,
  handleSetFileBase64,
  handleSetNoFile,
}: PromotionVisualProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileStatus, setFileStatus] = useState<ModalStatusType>(image ? FILE : NO_FILE);
  const publishedStatus = useSelector((state: RootState) => getPublishedStatus(state, promotionId));
  const savedImageBase64 = useSelector((state: RootState) => getPicture(state, image?.key));
  const [uploadedImgBase64, setUploadedImgBase64] = useState<string>();
  const [crop, setCrop] = useState<Crop>();

  useEffect(() => {
    if (uploadedImgBase64 && image?.name) {
      const fileFormat = image.name.split('.').pop() ?? '';
      const img = new Image();
      img.src = `data:image/${fileFormat};base64,${uploadedImgBase64}`;
      img.onload = () => {
        const defaultCrop = makeAspectCrop({ unit: '%', width: 100 }, 3, img.naturalWidth, img.naturalHeight);
        setCrop(defaultCrop);
      };
    }
    //eslint-disable-next-line
  }, [uploadedImgBase64]);

  useEffect(() => {
    if (hasData(publishedStatus) && !editing) {
      setFileStatus(NO_FILE);
      dispatch(setInitialCreatePromotion());
    }
  }, [dispatch, publishedStatus, editing]);

  useEffect(() => {
    setFileStatus(image ? FILE : NO_FILE);
  }, [dispatch, image]);
  const validateFile = (file: File) => {
    if (!imageFileRegex.exec(file.name)) {
      setFileStatus(FILE_FORMAT_ERROR);
      return false;
    } else if (file.size > 2 * 1024 * 1024) {
      setFileStatus(MAX_SIZE_ERROR);
      return false;
    } else {
      return true;
    }
  };
  const handleFileBase64 = (fileName: string, _fileSize: number, imageBase64: string) => {
    setFileStatus(FILE);
    handleSetFileBase64(fileName, imageBase64);
    setUploadedImgBase64(imageBase64);
  };
  const handleNoFile = () => {
    setUploadedImgBase64(undefined);
    setFileStatus(NO_FILE);
    handleSetNoFile();
  };
  const descriptions = [t('common.file_upload.image.description', 'Supported extension: Jpg, Png. File size max 2MB.')];
  const getText1Override = () =>
    fileStatus === MAX_SIZE_ERROR ? t('common.file_upload.image.max_size_error', 'File size exceeds 2 MB') : '';

  const handleSaveCrop = () => {
    if (image?.name && uploadedImgBase64 && crop) {
      const fileFormat = image.name.split('.').pop() ?? '';
      const img = new Image();
      img.src = `data:image/${fileFormat};base64,${uploadedImgBase64}`;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const { width: cWidth, height: cHeight, x: cX, y: cY } = crop;
        const canvasWidth = (cWidth / 100) * img.width;
        const canvasHeight = (cHeight / 100) * img.height;
        const canvasImgX = (cX / 100) * img.width;
        const canvasImgY = (cY / 100) * img.height;
        const staticWidth = 1800;
        const staticHeight = 600;
        canvas.width = staticWidth;
        canvas.height = staticHeight;
        const context = canvas.getContext('2d');
        context?.drawImage(img, canvasImgX, canvasImgY, canvasWidth, canvasHeight, 0, 0, staticWidth, staticHeight);
        const croppedBase64 = canvas.toDataURL().split(',').pop() ?? '';
        handleSetFileBase64(image.name ?? '', croppedBase64);
      };
    }
  };

  return (
    <Flex direction={'column'}>
      <FileUpload
        handleFileBase64={handleFileBase64}
        validateFile={validateFile}
        handleNoFile={handleNoFile}
        status={fileStatus}
        descriptions={descriptions}
        getText1Override={getText1Override}
        currentFile={
          fileStatus !== NO_FILE && image
            ? {
                fileType: 'IMAGE',
                fileName: image.name ?? image.key ?? '',
                fileBase64: image.base64 ?? savedImageBase64 ?? '',
              }
            : undefined
        }
      />
      {uploadedImgBase64 && (
        <Box>
          <ReactCrop
            crop={crop}
            onChange={(_, percentageCrop) => setCrop(percentageCrop)}
            aspect={3}
            onComplete={handleSaveCrop}
          >
            <Img alt={'image_crop'} src={uploadedImgBase64} type={'base64'} />
          </ReactCrop>
        </Box>
      )}
    </Flex>
  );
};
