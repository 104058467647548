import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionId, setPageViews } from 'domains/firstHelp/FirstHelp.store';
import { PAGE_VIEWS_LOCAL, PageType } from 'domains/firstHelp/FirstHelp.types';
import { getTokenUser } from 'domains/user';

const mapsToString = (map: Map<string, Map<PageType, number>>): string => {
  const mapString: Map<string, string> = new Map();
  map.forEach((value, key) => {
    mapString.set(key, JSON.stringify(Array.from(value.entries())));
  });
  return JSON.stringify(Array.from(mapString.entries()));
};

export const stringToMaps = (str: string | null): Map<string, Map<PageType, number>> => {
  if (!str) {
    return new Map();
  }
  const mapString = new Map<string, string>(JSON.parse(str));
  const map = new Map();
  mapString.forEach((value, key) => {
    map.set(key, new Map<PageType, number>(JSON.parse(value)));
  });
  return map;
};

const PageViewCounter = ({ page }: { page: PageType }) => {
  const dispatch = useDispatch();
  const sessionId = useSelector(getSessionId);
  const userId = useSelector(getTokenUser)?.id ?? '';

  useEffect(() => {
    const viewsLocalStorage = localStorage.getItem(PAGE_VIEWS_LOCAL);
    const usersViewsMap = stringToMaps(viewsLocalStorage);
    const currentUserViews = usersViewsMap.get(userId) ?? new Map();
    const thisPageCount = currentUserViews.get(page);
    currentUserViews.set(page, (thisPageCount ?? 0) + 1);
    dispatch(setPageViews(currentUserViews));
    usersViewsMap.set(userId, currentUserViews);
    localStorage.setItem(PAGE_VIEWS_LOCAL, mapsToString(usersViewsMap));
  }, [sessionId, page, dispatch, userId]);

  return <></>;
};

export default PageViewCounter;
