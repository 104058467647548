import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { fetchIAMRepairMethodDetail, getIAMRepairMethodDetail } from 'domains/maintenancePlan/MaintenancePlan.store';
import { SearchData } from 'utils';
import CacheMap from 'utils/dataStructure/CacheMap';

// fetch cache is used because this hook is called multiple times - just prevent multiple http calls
const fetchCache = new CacheMap<string, boolean>(10000);

export const useIAMRepairMethodDetail = (categoryId?: string): SearchData<string> | undefined => {
  const dispatch = useDispatch();
  const repairMethodDetail = useSelector((state: RootState) => getIAMRepairMethodDetail(state, categoryId));

  useEffect(() => {
    if (categoryId === undefined || categoryId === '') {
      return;
    }
    if (repairMethodDetail === undefined && !fetchCache.has(categoryId)) {
      fetchCache.set(categoryId, true);
      dispatch(fetchIAMRepairMethodDetail({ categoryId }));
    }
  }, [dispatch, repairMethodDetail, categoryId]);

  return repairMethodDetail;
};
