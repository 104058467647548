import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { findStatusTypeByPromotionID, validateProductsRequestSaga } from 'domains/promotion/Promotion.store';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import { Flex, InputNumber, MarginBox, Text } from 'UI';
import { hasData } from 'utils';
import { RemovePromotedProductsButton } from './RemovePromotedProductsButton';

export function ApplyDiscountCommercialFamily({
  promotion,
  setPromotion,
}: {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commercialFamilyCodesSections = useMemo(() => promotion.commercialFamilyCodes ?? [{ items: [], discount: 0 }], [
    promotion,
  ]);

  const promotionStatus = useSelector((state: RootState) =>
    findStatusTypeByPromotionID(state, hasData(promotion) ? promotion.promotionId : undefined),
  );

  const handleRemoveCommercialFamilies = (index?: number) => {
    if (!promotion.commercialFamilyCodes || index === undefined) {
      return;
    }

    const updatedCommercialFamilyCodes = [...promotion.commercialFamilyCodes];

    if (index === 0 && updatedCommercialFamilyCodes.length === 1) {
      updatedCommercialFamilyCodes[index] = {
        items: [],
        discount: 0,
      };
    } else {
      updatedCommercialFamilyCodes.splice(index, 1);
    }
    const updatedPromotion = {
      ...promotion,
      commercialFamilyCodes: updatedCommercialFamilyCodes,
    };
    setPromotion(updatedPromotion);
    dispatch(validateProductsRequestSaga({ promotion: updatedPromotion }));
  };

  return (
    <Flex direction={'column'} minWidth={210} maxWidth={210}>
      <>
        <Text type={'h5_bold'}>{t('backoffice.promotion.apply_discount', 'Apply discount')}</Text>
        {commercialFamilyCodesSections &&
          commercialFamilyCodesSections?.map((section, index) => {
            const isEditing = promotion.editing;
            const isSavedOrPlanned = promotionStatus === 'SAVED' || promotionStatus === 'PLANNED';
            const isPublished = promotionStatus === 'PUBLISHED';
            const isSectionEditing = section.editing;

            const shouldRenderRemoveBtn =
              !isEditing || (isSavedOrPlanned && isEditing) || (isPublished && isEditing && !isSectionEditing);

            return (
              <React.Fragment key={'promotion_commercial_family_codes_discount_' + index}>
                <MarginBox mt={20} />
                <Flex direction={'row'} align={'center'}>
                  <Flex minWidth={115} maxWidth={115}>
                    <InputNumber
                      disabled={section.editing}
                      key={'promotion_commercial_family_codes_discounts_input_' + index}
                      value={section.discount !== 0 ? section.discount : undefined}
                      onChangeDebounced={(discount) => {
                        const newCommercialFamilyCodes = promotion.commercialFamilyCodes?.map((c, i) =>
                          i === index ? { ...c, discount: Number(discount) || 0 } : c,
                        );
                        setPromotion({
                          ...promotion,
                          commercialFamilyCodes: newCommercialFamilyCodes ?? [
                            { items: [], discount: Number(discount) || 0 },
                          ],
                        });
                      }}
                      bordered
                      placeholder={'-'}
                      addonAfter={'%'}
                      min={0}
                      max={100}
                    />
                  </Flex>
                  {shouldRenderRemoveBtn ? (
                    <RemovePromotedProductsButton index={index} onDeleteClicked={handleRemoveCommercialFamilies} />
                  ) : (
                    <MarginBox ml={60} />
                  )}
                </Flex>
              </React.Fragment>
            );
          })}
      </>
    </Flex>
  );
}
