import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationTriangleIcon } from 'assets/icons';
import { ErrorWithLabel } from 'components/DataContainer';
import { theme } from 'styles';
import { Box, Flex, Icon } from 'UI';
import { SAlert } from './ReferencePriceSection.styled';

export const PRICE_WRAPPER_MAX_WIDTH_NARROW = 195;

export const PriceErrorDisplay = ({ narrow }: { narrow?: boolean }) => {
  const { t } = useTranslation();
  return (
    <Flex maxWidth={narrow ? PRICE_WRAPPER_MAX_WIDTH_NARROW : 230}>
      {narrow ? (
        <ErrorWithLabel
          label={t('common.price.backend_error', 'Price temporarily unavailable, please try again later.')}
          narrow
        />
      ) : (
        <SAlert
          icon={
            <Box>
              <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.warning} size={24} noPointer />
            </Box>
          }
          type={'info'}
          message={t('common.price.backend_error.message', 'Price temporarily unavailable.')}
          description={t('common.price.backend_error.description', 'Please try again later.')}
          showIcon
        />
      )}
    </Flex>
  );
};
