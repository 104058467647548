import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchVariantOption, SearchVariantType, SelectSearchVariant } from 'pages/HomePage/search/SearchBar';
import { Box, Flex, FloatingCard, FloatingContent, Input, Pipeline } from 'UI';
import { rollDownEffect } from 'utils';

export const SearchByHistory = ({
  searchVariant,
  setSearchVariant,
  searchVariantOptions,
}: {
  searchVariant: SearchVariantType;
  setSearchVariant: (x: SearchVariantType) => void;
  searchVariantOptions: SearchVariantOption[];
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    rollDownEffect('history-card', 'history-content');
  }, [searchVariant]);

  return (
    <FloatingCard id={'history-card'}>
      <FloatingContent id={'history-content'}>
        <Flex>
          <Box width={1} />
          <SelectSearchVariant
            searchVariant={searchVariant}
            setSearchVariant={setSearchVariant}
            searchVariantOptions={searchVariantOptions}
          />
          <Pipeline size={50} offsetRatio={8} />
          <Flex size={9}>
            <Input
              onChange={() => undefined}
              value={'Not implemented Yet'}
              placeholder={t('catalog.parts.search.by_model.hint', 'Information filled progressively...')}
              readOnly
              size={'large'}
            />
          </Flex>
        </Flex>
        <Pipeline size={'100%'} horizontal />
      </FloatingContent>
    </FloatingCard>
  );
};
