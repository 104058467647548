/* eslint-disable max-len */
import { GetMaintenanceValuesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_values/response/GetMaintenanceValuesResponse';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA, SEARCH_STATUS } from 'utils';

export const MAINTENANCE_VALUES_NAMESPACE = `${APP_NAMESPACE}/MAINTENANCE_VALUES`;
export const GET_MAINTENANCE_VALUES_REQUEST = `${MAINTENANCE_VALUES_NAMESPACE}/GET_MAINTENANCE_VALUES_REQUEST`;

export interface MaintenanceValuesState {
  maintenanceValues: GetMaintenanceValuesResponse | NO_DATA;
  valuesXml: ValuesXml;
}

interface ValuesXml {
  data: string | undefined;
  searchStatus: SEARCH_STATUS;
}
