import { Table } from 'antd';
import styled from 'styled-components';

export const STable = styled(Table)`
  flex: 1;
  thead > tr > th {
    text-align: center;
    white-space: nowrap;
    font-size: 20px;
    color: ${({ theme }) => theme.color.grey10};
    font-weight: 600;
    border: solid 1px ${({ theme }) => theme.color.grey85};
    background-color: ${({ theme }) => theme.color.grey95};
  }
  tbody > tr.ant-table-row {
    border: solid 1px ${({ theme }) => theme.color.grey85};

    :hover {
      box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
    }
  }
  tbody > tr.ant-table-expanded-row > td {
    padding: 0 0 0 0;
  }
  tbody > tr.ant-table-row > td.ant-table-cell {
    border-top: solid 1px ${({ theme }) => theme.color.grey85};
    border-bottom: solid 1px ${({ theme }) => theme.color.grey85};
    height: 75px;
  }
  border: solid 1px ${({ theme }) => theme.color.grey85};
` as typeof Table;
