import styled from 'styled-components';

export const CheckoutCardWrapper = styled.div`
  min-width: 330px;
  max-width: 380px;
`;

export const Card = styled.div<{ dataCy?: string }>`
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  background-color: ${({ theme }) => theme.color.grey100};
`;

export const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
