/* eslint-disable max-len */
import { ElectronicPartsRepairFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/response/ElectronicPartsRepairFileResponse';
import { ElectronicPartsRepairReplaceReferencesFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/response/ElectronicPartsRepairReplaceReferencesFileResponse';
import { ElectronicPartsRepairUploadReferencesFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/electronic_parts_repair/response/ElectronicPartsRepairUploadReferencesFileResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { NO_FILE, UPLOADING_FILE } from 'components/FileUpload/paramsByState';
import {
  sendElectronicPartsRepairFileRequest,
  sendElectronicPartsRepairReplaceRequest,
  sendGetElectronicPartsFileRequest,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.api';
import * as actions from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import {
  getElectronicPartsRepairFileInformationForRequest,
  getElectronicPartsRepairFileUploadStatus,
  setElectronicPartsRepairFileCorrectRows,
  setElectronicPartsRepairFileErrorRows,
  setElectronicPartsRepairFileHasFile,
  setElectronicPartsRepairFileIsModalOpen,
  setElectronicPartsRepairFileModalStatus,
  setElectronicPartsRepairFileResponse,
  setElectronicPartsRepairFileUploadStatus,
  setElectronicPartsRepairSearchStatus,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.store';
import {
  FETCHING_FILE,
  FILE_FETCHED,
  FILE_REPLACED,
  FILE_UPLOADED,
  REPLACING_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { FOUND, LOADING, sagaGuard, select } from 'utils';

export function* fetchFileElectricPartsRepairRequestSaga(): SagaIterator {
  yield put(setElectronicPartsRepairSearchStatus(LOADING));
  yield put(setElectronicPartsRepairFileUploadStatus(FETCHING_FILE));
  yield put(sendGetElectronicPartsFileRequest());
}

export function* fetchFileElectricPartsRepairResponseSaga(
  action: WsResponse<ElectronicPartsRepairFileResponse>,
): SagaIterator {
  const status = yield* select(getElectronicPartsRepairFileUploadStatus);
  if (status === FETCHING_FILE) {
    yield put(setElectronicPartsRepairFileUploadStatus(FILE_FETCHED));
    yield put(setElectronicPartsRepairFileHasFile(true));
  } else {
    yield put(setElectronicPartsRepairFileUploadStatus(FILE_REPLACED));
  }
  yield put(setElectronicPartsRepairFileResponse(action.payload));
  yield put(setElectronicPartsRepairSearchStatus(FOUND));
}

export function* uploadFileElectricPartsRepairSaga({
  payload,
}: ReturnType<typeof actions.uploadFileElectricPartsRepairSaga>): SagaIterator {
  yield put(setElectronicPartsRepairFileModalStatus(UPLOADING_FILE));
  yield put(sendElectronicPartsRepairFileRequest({ ...payload }));
}

export function* replaceFileElectricPartsRepairSaga({
  payload,
}: ReturnType<typeof actions.replaceFileElectricPartsRepairSaga>): SagaIterator {
  yield put(setElectronicPartsRepairFileUploadStatus(FETCHING_FILE));
  yield put(sendElectronicPartsRepairReplaceRequest({ ...payload }));
}

export function* fetchUploadFileElectronicPartsRepairResponseSaga(
  action: WsResponse<ElectronicPartsRepairUploadReferencesFileResponse>,
): SagaIterator {
  const { correctRows, errorRows } = action.payload;
  yield put(setElectronicPartsRepairFileCorrectRows(correctRows));
  yield put(setElectronicPartsRepairFileErrorRows(errorRows));
  yield put(setElectronicPartsRepairFileUploadStatus(FILE_UPLOADED));

  if (!errorRows || errorRows.length === 0) {
    const fileInformation = yield* select(getElectronicPartsRepairFileInformationForRequest);
    const payload = {
      correctRows,
      fileName: fileInformation.fileName,
      fileSize: fileInformation.fileSize,
    };
    yield put(actions.replaceFileElectricPartsRepairSaga(payload));
  }

  yield put(setElectronicPartsRepairFileModalStatus(NO_FILE));
  yield put(setElectronicPartsRepairFileIsModalOpen(false));
}

export function* fetchReplaceFileElectronicPartsRepairResponseSaga(
  _action: WsResponse<ElectronicPartsRepairReplaceReferencesFileResponse>,
): SagaIterator {
  yield put(setElectronicPartsRepairFileUploadStatus(REPLACING_FILE));
  yield put(setElectronicPartsRepairFileModalStatus(NO_FILE));
  yield put(setElectronicPartsRepairFileIsModalOpen(false));
}

export function* ElectronicPartsRepairSagas(): SagaIterator {
  yield takeEvery(actions.uploadFileElectricPartsRepairSaga.type, sagaGuard(uploadFileElectricPartsRepairSaga));
  yield takeEvery(actions.replaceFileElectricPartsRepairSaga.type, sagaGuard(replaceFileElectricPartsRepairSaga));
  yield takeEvery(
    actions.fetchFileElectricPartsRepairResponseSaga.type,
    sagaGuard(fetchFileElectricPartsRepairResponseSaga),
  );
  yield takeEvery(
    actions.fetchFileElectricPartsRepairRequestSaga.type,
    sagaGuard(fetchFileElectricPartsRepairRequestSaga),
  );
  yield takeEvery(
    actions.fetchUploadFileElectronicPartsRepairResponseSaga.type,
    sagaGuard(fetchUploadFileElectronicPartsRepairResponseSaga),
  );
  yield takeEvery(
    actions.fetchReplaceFileElectronicPartsRepairResponse.type,
    sagaGuard(fetchReplaceFileElectronicPartsRepairResponseSaga),
  );
}
