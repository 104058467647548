import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClientField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateClient';
import { RootState } from 'app/AppStore';
import { getEstimateClient, updateClient } from 'domains/estimate/Estimate.store';
import { Flex, LightCard, MarginBox } from 'UI';
import { SInput } from './ClientSection.styled';

export interface ClientSectionProps {
  estimateId: string;
}

const ClientSection = ({ estimateId }: ClientSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const client = useSelector((state: RootState) => getEstimateClient(state, estimateId));
  const name = client?.name || '';
  const phoneNumber = client?.phoneNumber || '';
  const email = client?.email || '';

  const handleUpdate = (field: ClientField) => (newValue: string) => {
    dispatch(updateClient({ field, newValue }));
  };

  return (
    <LightCard title={t('estimate.client_contact', 'Client contact')}>
      <Flex direction={'column'}>
        <SInput
          id={'estimate-client-contact-id'}
          name={'estimate-client-contact-name'}
          autoComplete={'on'}
          initialValue={name}
          onChangeDebounced={handleUpdate('NAME')}
          placeholder={t('common.name', 'Name')}
          bordered
          ellipsis
        />
        <MarginBox mt={15} />
        <SInput
          initialValue={phoneNumber}
          onChangeDebounced={handleUpdate('PHONE')}
          placeholder={t('common.phone_number', 'Phone number')}
          bordered
          ellipsis
        />
        <MarginBox mt={15} />
        <SInput
          initialValue={email}
          onChangeDebounced={handleUpdate('EMAIL')}
          placeholder={t('common.email', 'E-mail address')}
          bordered
          ellipsis
        />
      </Flex>
    </LightCard>
  );
};

export default ClientSection;
