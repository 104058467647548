import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { defaultBoxShadow, Flex, MarginBox, NotificationDisplayStyle, notificationStyle, Text } from 'UI';

const NotificationWrapper = styled(Flex)<{ backgroundColor?: string }>`
  width: 100%;
  min-height: 80px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${defaultBoxShadow};
  padding: 15px 20px 15px 25px;
  text-align: left;
`;

export const InlineNotification = ({
  displayStyle,
  message,
  description,
  additionalInformation,
  action = <></>,
}: {
  displayStyle: NotificationDisplayStyle;
  message?: string;
  description?: string;
  additionalInformation?: ReactNode;
  action?: ReactNode;
}) => {
  const customStyle = notificationStyle()[displayStyle];
  return (
    <Flex direction={'column'}>
      <NotificationWrapper
        direction={'row'}
        justify={'flex-start'}
        align={'center'}
        backgroundColor={customStyle.style.backgroundColor}
      >
        {customStyle.icon}
        <MarginBox ml={15} />
        <Flex direction={'column'} justify={'center'} align={'flex-start'} size={4}>
          <Text type={'text_bold'}>{message}</Text>
          <Text type={'text'}>{description}</Text>
          {additionalInformation && additionalInformation}
        </Flex>
        <Flex justify={'flex-end'} size={1}>
          {action}
        </Flex>
      </NotificationWrapper>
      <MarginBox mt={15} />
    </Flex>
  );
};
