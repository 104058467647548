import React from 'react';
import OrderListRouter from 'pages/OrderListPage/OrderListRouter';
import { Container, defaultPadding } from 'UI';

const OrderListPage = () => {
  return (
    <Container padding={defaultPadding} size={'xxl'}>
      <OrderListRouter />
    </Container>
  );
};

export default OrderListPage;
