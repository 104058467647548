import React from 'react';
import { useSelector } from 'react-redux';
import { MarketingReferencesWrapper, PlateReferencesDetail, VehicleItemState } from 'domains/catalog/Catalog.types';
import { getSparePartsView } from 'domains/user';
import ReferenceCardsContainer from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer';
import { SelectedIndexType } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/SparePartsSection';
import { Flex } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { NO_DATA } from 'utils';
import { IndexType } from 'utils/svg/common';

export interface ReferencesSectionProps {
  vehicleResult: VehicleItemState | undefined;
  plateReferences: PlateReferencesDetail[] | NO_DATA;
  marketingReferencesWrapper?: MarketingReferencesWrapper;
  selectedIndex: SelectedIndexType;
  setSelectedIndex: (value: ((prevState: SelectedIndexType) => SelectedIndexType) | SelectedIndexType) => void;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  plateId?: string;
  nodeId?: string;
}

const ReferencesSection = ({
  vehicleResult,
  plateReferences,
  marketingReferencesWrapper,
  selectedIndex,
  setSelectedIndex,
  setHighlightedIndex,
  plateId,
  nodeId,
}: ReferencesSectionProps) => {
  const sparePartsView = useSelector(getSparePartsView);
  const vehicleBrand = vehicleResult?.vehicleDetail?.vehicleBrand ?? 'OTHER';

  return (
    <Flex direction={'column'}>
      <ViewTabs />
      <ReferenceCardsContainer
        references={plateReferences}
        vehicleBrand={vehicleBrand}
        sparePartsView={sparePartsView}
        marketingReferencesWrapper={marketingReferencesWrapper}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        setHighlightedIndex={setHighlightedIndex}
        plateId={plateId}
        nodeId={nodeId}
      />
    </Flex>
  );
};

export default ReferencesSection;
