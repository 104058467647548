import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StickyBoxCustom } from 'UI';
import { OFFSET_HEADER } from 'utils/hooks/useOffsetTop';
import HeaderMenu from './HeaderMenu';
import { useMyStoreMenu } from './useMyStoreMenu';

const MyStorePage = () => {
  const myStoreMenuData = useMyStoreMenu();
  return (
    <>
      <StickyBoxCustom offsetTop={OFFSET_HEADER} zIndex={300}>
        <HeaderMenu />
      </StickyBoxCustom>
      <Switch>
        {myStoreMenuData.map((ms) => (
          <Route key={ms.key} path={ms.link} render={() => ms.component} />
        ))}
      </Switch>
    </>
  );
};

export default MyStorePage;
