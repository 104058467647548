import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { getEstimateSettings } from 'domains/estimate/Estimate.store';
import { getUserGarageInfo } from 'domains/garage/Garage.store';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { Box, DarkCard, Flex, MarginBox, Pipeline, Text } from 'UI';
import { getData, hasData, useBreakpointSelector } from 'utils';

const DealerSection = () => {
  const { t } = useTranslation();
  const { color } = useTheme();
  const garageInfo = getData(useSelector(getUserGarageInfo));
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;
  const settings = useSelector(getEstimateSettings);
  const breakpointSelector = useBreakpointSelector();

  return (
    <DataContainer
      data={garageInfo}
      ErrorState={() => (
        <DarkCard title={''}>
          <Flex
            minHeight={breakpointSelector([0, 250])}
            maxHeight={breakpointSelector([150, 250])}
            align={'center'}
            justify={'center'}
          >
            <ErrorWithLabel
              label={t(
                'estimate.garage_info.backend_error',
                'Garage information temporary unavailable, please try again later.',
              )}
            />
          </Flex>
        </DarkCard>
      )}
    >
      <DarkCard noPadding title={(garageInfo && garageInfo?.name) ?? ''}>
        <Flex direction={'column'}>
          <Box height={47}>
            <MarginBox ml={24} mt={12}>
              <Text type={'light_14_black_65'}>
                {t('estimate.garage_info.institution_number', 'Institution number:')}
                {garageInfo && ` ${garageInfo.registrationNumber ?? ''}`}
              </Text>
            </MarginBox>
          </Box>
          <Pipeline size={'100%'} horizontal color={color.grey95} />
          <Box height={47}>
            <MarginBox ml={24} mt={12}>
              <Text type={'light_14_black_65'}>
                {t('estimate.garage_info.vat_intra', 'VAT intra:')}
                <DataContainer data={settings} NotFound={() => <></>}>
                  {hasData(settings) && settings.intraCommunityVat && ` ${settings.intraCommunityVat}`}
                </DataContainer>
              </Text>
            </MarginBox>
          </Box>
          <Pipeline size={'100%'} horizontal color={color.grey95} />
          <Box height={47}>
            <MarginBox ml={24} mt={12}>
              <Text type={'light_14_black_65'}>
                {t('estimate.garage_info.tel', 'Tel:')} {address && `${address.phone ?? ''}`}
              </Text>
            </MarginBox>
          </Box>
          <Pipeline size={'100%'} horizontal color={color.grey95} />
          <Box height={47}>
            <MarginBox ml={24} mt={12}>
              <Text type={'light_14_black_65'}>
                {t('estimate.fax', 'Fax:')} {address && ` ${address.fax ?? ''}`}
              </Text>
            </MarginBox>
          </Box>
          <Pipeline size={'100%'} horizontal color={color.grey95} />
          <Box height={47}>
            <MarginBox ml={24} mt={12}>
              <Text type={'light_14_black_65'}>{(address && address.email) ?? ''}</Text>
            </MarginBox>
          </Box>
        </Flex>
      </DarkCard>
    </DataContainer>
  );
};

export default DealerSection;
