import React from 'react';
import { useTranslation } from 'react-i18next';
import ClaimFormModal from 'pages/MyOrdersPage/OrderClaims/ClaimFormModal';
import { BlackButton, Flex, Image } from 'UI';

const OrderClaims = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Flex direction={'column'} align={'center'} gap={30}>
      <ClaimFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Image alt={'empty_placeholder'} src={'placeholder_empty.png'} width={375} />
      <BlackButton onClick={() => setIsOpen(true)}>{t('order.claim.initiate_claim', 'Initiate claim')}</BlackButton>
    </Flex>
  );
};

export default OrderClaims;
