import styled from 'styled-components';
import { defaultBoxShadow, Flex } from 'UI';

export const SOrderCard = styled(Flex)<{ expanded: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 15px;
  ${({ expanded }) => expanded && defaultBoxShadow};

  :hover {
    background-color: ${({ theme }) => theme.color.grey_very_light_2};
  }
`;

export const SCardCheckbox = styled(Flex)`
  position: absolute;
  top: 0;
  right: 2px;
`;

export const OrderDetailCard = styled.div<{ maxHeight?: boolean }>`
  border-radius: 16px 16px 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.grey85};
  background-color: ${({ theme }) => theme.color.white};
  height: ${({ maxHeight }) => (maxHeight ? '100%' : 'inherit')};
  width: 100%;
`;

export const CardTitle = styled.div`
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 10px 25px 10px 25px;
`;

export const MiddleCardTitle = styled.div`
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.color.grey95};
  padding: 10px 25px 10px 25px;
`;

export const CardPanel = styled.div`
  width: 207px;
  height: 100%;
  padding: 15px 25px 15px 25px;
  display: flex;
  align-content: center;
`;
