import React from 'react';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { View } from '@react-pdf/renderer';
import { Card, Divider, ItemNameTitle, Spacer } from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { OrderPdfText } from './MyOrdersDetailPdfPage';
import { RowBreakLine } from './RowBreakLine';

export const ContactCard = ({ text, garageInfo }: { text: OrderPdfText; garageInfo: Garage | undefined }) => {
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;

  const items = [
    {
      title: text.contact,
      data: garageInfo?.name,
    },
    {
      title: text.phone,
      data: address?.phone,
    },
    {
      title: text.email,
      data: address?.email,
    },
  ];

  return (
    <Card title={<ItemNameTitle>{text.contactSection}</ItemNameTitle>} height={150} width={190} alignTitleStart>
      <View style={[styles.col, { margin: '10px 7px 10px 7px' }]}>
        {items.map((i) => (
          <>
            <RowBreakLine title={i.title} data={i.data} />
            <Spacer />
            <Divider />
            <Spacer />
          </>
        ))}
      </View>
    </Card>
  );
};
