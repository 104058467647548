import React from 'react';
import { useSelector } from 'react-redux';
import { HeaderTranslationList } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { pdf } from '@react-pdf/renderer';
import { getLastVehicleDetail, getMaintenancePlan } from 'domains/catalog/Catalog.store';
import { MaintenancePlanLocal } from 'domains/catalog/Catalog.types';
import { useFetchSignature } from 'domains/pictures/Pictures.requests';
import FluidsSection from 'pages/CatalogPage/DH/Maintenance/FluidsSection';
import MaintenanceHeader from 'pages/CatalogPage/DH/Maintenance/MaintenanceHeader';
import { MaintenancePdf } from 'pages/CatalogPage/DH/Maintenance/MaintenancePdf';
import MileageTimeSection from 'pages/CatalogPage/DH/Maintenance/MileageTimeSection';
import ServiceSection from 'pages/CatalogPage/DH/Maintenance/ServiceSection';
import SpecialInformation from 'pages/CatalogPage/DH/Maintenance/SpecialInformation';
import { Container, Space } from 'UI';
import { getData } from 'utils';

export interface MaintenanceDataProps {
  maintenancePlanData: MaintenancePlanLocal;
  getLabel: (id: number) => string;
}

const Maintenance = () => {
  const maintenancePlanData = getData(useSelector(getMaintenancePlan));
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const dhSignature = useFetchSignature('DATAHUB');
  if (!maintenancePlanData) return <></>;

  const getLabel = (id: number) => {
    const label = maintenancePlanData.headerTranslationsList.find((l: HeaderTranslationList) => l.id === id);
    return label?.label ?? '';
  };

  const getPdfBlob = async () => {
    return pdf(
      <>
        <MaintenancePdf data={maintenancePlanData} vehicleDetail={vehicleDetail} signature={dhSignature} />
      </>,
    ).toBlob();
  };

  const handlePrint = async () => {
    const pdfBlob = await getPdfBlob();

    const url = URL.createObjectURL(pdfBlob);
    if (url) {
      window.open(url);
    }
  };

  return (
    <Container>
      <MaintenanceHeader handlePrint={handlePrint} />
      <Space direction={'vertical'} size={60}>
        <MileageTimeSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} />
        <ServiceSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} />
        <SpecialInformation specialInformation={maintenancePlanData.specialInformationList} />
        <FluidsSection maintenancePlanData={maintenancePlanData} getLabel={getLabel} />
      </Space>
    </Container>
  );
};

export default Maintenance;
