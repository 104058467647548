import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrashAltIcon } from 'assets/icons';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Text } from 'UI';

interface RemovePromotedProductsButtonProps {
  index?: number;
  onDeleteClicked: (index?: number) => void;
}

export function RemovePromotedProductsButton({ index, onDeleteClicked }: RemovePromotedProductsButtonProps) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const handleRemovePromotedProducts = () => {
    onDeleteClicked(index);
  };

  const handleIconMouseEnter = () => {
    setIsHovered(true);
  };

  const handleIconMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Flex direction={'row'} minWidth={60} maxWidth={60} align={'center'}>
        <MarginBox ml={20} />
        <Icon
          IconComponent={TrashAltIcon}
          color={theme.color.red}
          size={16}
          mt={3}
          onClick={handleRemovePromotedProducts}
          onMouseEnter={handleIconMouseEnter}
          onMouseLeave={handleIconMouseLeave}
        />
        <MarginBox mt={5} ml={15}>
          {isHovered && (
            <Text type={'link_14_red'} cursor={'pointer'} decoration={'underline'}>
              {t('common.delete', 'Delete')}
            </Text>
          )}
        </MarginBox>
      </Flex>
    </>
  );
}
