import { useSelector } from 'react-redux';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { CommercialLink } from '@1po/1po-bff-fe-spec/generated/user/model/CommercialLink';
import { getEmailDocumentationAlertMotrioData } from 'domains/email/Email.store';
import { SendDocumentationAlertMotrioLocal } from 'domains/email/Email.types';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { ReferenceLocal } from 'domains/references';
import { getUserCommercialLink } from 'domains/user';

type RefLocType = ReferenceLocal | OrderReferenceItem | undefined;

export const refDescription = (reference: RefLocType): string | undefined =>
  reference === undefined ? '' : getRefDesignation(reference);

export const refBrand = (reference: RefLocType): string | undefined =>
  reference === undefined ? '' : getRefBrandOrSupplier(reference);

function getRefDesignation(reference: ReferenceLocal | OrderReferenceItem) {
  return 'designation' in reference ? reference.designation : reference?.name;
}

function getRefBrandOrSupplier(reference: ReferenceLocal | OrderReferenceItem) {
  return reference.brand ?? ('supplier' in reference ? reference.supplier : '');
}

function getCountryCode(garageInfo: Garage | undefined) {
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;
  return address?.countryCode ?? garageInfo?.countryCode ?? '';
}

function getEmail(garageInfo: Garage | undefined, emailData: SendDocumentationAlertMotrioLocal) {
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;
  return emailData.email ?? address?.email ?? '';
}

function getSocialReason(garageInfo: Garage | undefined, emailData: SendDocumentationAlertMotrioLocal) {
  return emailData.socialReason ?? garageInfo?.name ?? '';
}

function getCompanyRegistrationNumber(
  garageInfo: Garage | undefined,
  emailData: SendDocumentationAlertMotrioLocal,
  commercialLink: CommercialLink | null,
) {
  const regNum = commercialLink?.repository === 'TRESOR' ? commercialLink?.tresorCode : commercialLink?.birCode ?? '';
  return emailData.companyRegistrationNumber ?? garageInfo?.registrationNumber ?? regNum;
}

function getVehicleEngineCode(vehicleDetail: VehicleDetail | undefined) {
  return vehicleDetail?.dataHubVehicle?.engine ?? vehicleDetail?.iamVehicle?.engineCodes?.join(' ') ?? '';
}

function getVehicleVersion(vehicleDetail: VehicleDetail | undefined) {
  return vehicleDetail?.dataHubVehicle?.name ?? vehicleDetail?.iamVehicle?.version ?? '';
}

export function useFillDocumentationAlertState(
  garageInfo: Garage | undefined,
  reference: RefLocType,
  vehicleRegistrationNumber: string | undefined,
  vehicleDetail: VehicleDetail | undefined,
): SendDocumentationAlertMotrioLocal {
  const emailData = useSelector(getEmailDocumentationAlertMotrioData);
  const commercialLink = useSelector(getUserCommercialLink);
  const countryCode = getCountryCode(garageInfo);
  const email = getEmail(garageInfo, emailData);
  const socialReason = getSocialReason(garageInfo, emailData);
  const subject = `RPartStore Doc Alert [${countryCode}] : `;
  const referenceDescription = refDescription(reference);
  const referenceBrand = refBrand(reference);
  const companyRegistrationNumber = getCompanyRegistrationNumber(garageInfo, emailData, commercialLink);
  const vehicleEngineCode = getVehicleEngineCode(vehicleDetail);
  const vehicleVersion = getVehicleVersion(vehicleDetail);

  return {
    ...emailData,
    companyRegistrationNumber,
    email,
    socialReason,
    subject,
    countryCode,
    companyRegistrationNumberR1: commercialLink?.seller?.tresorCode ?? '',
    socialReasonR1: commercialLink?.seller?.name ?? '',
    referenceDescription,
    referenceBrand,
    referenceNumber: reference?.referenceNumber ?? '',
    vehicleBrand: vehicleDetail?.vehicleBrand ?? '',
    vehicleModel: vehicleDetail?.model ?? '',
    vehicleVin: vehicleDetail?.vin ?? '',
    vehicleVersion,
    vehicleRegistrationNumber: vehicleRegistrationNumber ?? '',
    vehicleEngineCode,
    vehicleCountryCode: vehicleDetail?.country ?? '',
  };
}
