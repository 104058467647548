import {
  GET_APPLICATION_CONFIG,
  GET_BUILD_VERSIONS_URL,
  MODIFY_MOCK_CONFIG,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { ModifyMockConfigRequest } from '@1po/1po-bff-fe-spec/generated/config/request/ModifyMockConfigRequest';
import { WebSocketAction } from 'utils/domainStore';
import { wsSendAction } from 'utils/domainStore/api';

export function sendBuildVersionsRequest(): WebSocketAction {
  return wsSendAction(GET_BUILD_VERSIONS_URL, {});
}

export function sendAppConfigRequest(): WebSocketAction {
  return wsSendAction(GET_APPLICATION_CONFIG, {});
}

export function sendModifyMockConfig(config: ModifyMockConfigRequest): WebSocketAction {
  return wsSendAction(MODIFY_MOCK_CONFIG, config);
}
