import { useSelector } from 'react-redux';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { getEmailToAssistanceData } from 'domains/email/Email.store';
import { SendEmailToAssistanceLocal } from 'domains/email/Email.types';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { getUserCommercialLink } from 'domains/user';

export function useEmailToAssistanceState(garageInfo: Garage | undefined): SendEmailToAssistanceLocal {
  const emailToAssistance = useSelector(getEmailToAssistanceData);
  const commercialLink = useSelector(getUserCommercialLink);
  const address = garageInfo ? getFirstValidAddress(garageInfo) : undefined;

  return {
    ...emailToAssistance,
    companyRegistrationNumber:
      emailToAssistance.companyRegistrationNumber?.length > 0
        ? emailToAssistance.companyRegistrationNumber
        : commercialLink?.tresorCode ?? '',
    email: emailToAssistance.email?.length > 0 ? emailToAssistance.email : address?.email ?? '',
    socialReason: emailToAssistance.socialReason?.length > 0 ? emailToAssistance.socialReason : garageInfo?.name ?? '',
  };
}
