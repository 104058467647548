import {
  DESTINATION_QUEUE_ERROR,
  DESTINATION_QUEUE_MAIN,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { Client, Frame } from '@stomp/stompjs';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { getToken } from 'domains/auth/Auth.util';
import {
  getSelectedProfile,
  getUserProfileRequestSaga,
  reloadSubscriptionBeUserSaga,
  setTokenExpired,
} from 'domains/user/User.store';
import { checkAddSetIsExpired } from 'domains/webSockets/utils/utils';
import { setConnected, setErrorNoConnectionToBESide } from 'domains/webSockets/WebSocket.store';
import feVersion from 'VERSION.json';
import { onMessageReceived, resendAllPendingRequests } from './messageHandlers';

export const beforeConnect = async (
  stompClient: Client,
  dispatch: Dispatch,
  getState: () => RootState,
): Promise<void> => {
  console.log('STOMP - before connect');
  const token = await getToken();
  const profile = getSelectedProfile(getState());
  const tabVisible = document.visibilityState === 'visible';
  if (!token || !profile || !tabVisible) {
    await stompClient.deactivate();
    setTimeout(() => {
      stompClient.activate();
    }, 500);
    return;
  }
  stompClient.configure({
    connectHeaders: {
      'trace-id': uuidv4(),
      'x-auth-token': token,
      'selected-profile': profile.id,
      'app-version': feVersion.version,
    },
  });
  const isExpired = await checkAddSetIsExpired(dispatch);
  if (isExpired) {
    dispatch(setConnected(false));
    await stompClient.deactivate();
  }
};

export const onConnect = (stompClient: Client, dispatch: Dispatch, getState: () => RootState, frame: Frame): void => {
  console.log('STOMP - connection success', frame);
  dispatch(setConnected(true));
  dispatch(reloadSubscriptionBeUserSaga());
  dispatch(getUserProfileRequestSaga());
  dispatch(setErrorNoConnectionToBESide({ connectionUnavailable: false }));
  dispatch(setTokenExpired(false));
  stompClient.subscribe(`/user/${DESTINATION_QUEUE_MAIN}`, (message: Frame) => {
    onMessageReceived(message, dispatch, false, getState);
  });
  stompClient.subscribe(`/user/${DESTINATION_QUEUE_ERROR}`, (message: Frame) => {
    onMessageReceived(message, dispatch, true, getState);
  });
  resendAllPendingRequests(dispatch, getState);
};

export const onDisconnect = (dispatch: Dispatch, frame: Frame): void => {
  console.warn('STOMP - disconnected', frame);
  dispatch(setConnected(false));
};

export const onStompError = (dispatch: Dispatch, frame: Frame): void => {
  console.error('STOMP - error', frame);
  if (frame.headers['message']?.includes('Incompatible version')) {
    window.location.reload();
  }
  dispatch(setConnected(false));
};

export const onWebSocketError = (dispatch: Dispatch, event: Event): void => {
  console.error('WebSocket - error', event);
  dispatch(setErrorNoConnectionToBESide({ connectionUnavailable: false }));
};
