import React from 'react';
import { FuelEfficiencyTag } from 'components/Filter/Filter.styled';

function getSize(label: string) {
  switch (label) {
    case 'A':
      return 60;
    case 'B':
      return 70;
    case 'C':
      return 80;
    case 'D':
      return 90;
    case 'E':
      return 100;
    case 'F':
      return 110;
    case 'G':
      return 120;
    default:
      return 60;
  }
}

function getColor(label: string) {
  switch (label) {
    case 'A':
      return '#008000';
    case 'B':
      return '#4caf50';
    case 'C':
      return '#ffeb3b';
    case 'D':
      return '#ffc107';
    case 'E':
      return '#ff0000';
    case 'F':
      return '#ff0000';
    case 'G':
      return '#a00000';
    default:
      return 'grey';
  }
}

export const FilterFuelEfficiencyTag = ({ children, label }: { children?: React.ReactNode; label: string }) => {
  return (
    <FuelEfficiencyTag size={getSize(label)} color={getColor(label)}>
      {children}
    </FuelEfficiencyTag>
  );
};
