import React from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import parse from 'html-react-parser';
import moment from 'moment/moment';
import styled from 'styled-components';
import { BullHornIcon, CalendarAltIcon } from 'assets/icons';
import FamiliesSection from 'pages/PromotionPage/FamiliesSection';
import ReferenceSection from 'pages/PromotionPage/ReferenceSection';
import { Banner, Box, Container, Empty, Flex, Icon, MarginBox, Text } from 'UI';
import { getData, hasData, textFormatter } from 'utils';
import { getMomentDate } from 'utils/date';

const Overflow = styled.div`
  overflow-wrap: anywhere;
`;

export const getSectionHeader = (header: string, discount: number | undefined) => {
  return (
    <Flex>
      <Flex justify={'flex-start'}>
        <Text type={'h2'} disableGutter>
          {header}
        </Text>
      </Flex>
      {discount && (
        <Flex justify={'flex-end'}>
          <Text type={'h2'} disableGutter>
            {`${discount}%`}
          </Text>
          <Box width={32} />
        </Flex>
      )}
    </Flex>
  );
};

export const PromotionPageView = ({
  promotion,
  addToCartDisabled,
}: {
  promotion: Promotion | undefined;
  addToCartDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const hasPublicationEnd = getData(promotion)?.publicationEnd !== undefined;

  const getInfoLabel = () => {
    const startDate = moment(getData(promotion)?.publicationStart);
    const endDate = moment(getData(promotion)?.publicationEnd).subtract(1, 'd');
    const effectiveEndDate = getMomentDate(promotion?.effectiveEndDate);
    const showDate =
      (promotion?.publicationEnd === promotion?.effectiveEndDate ? endDate : effectiveEndDate) ?? endDate;
    switch (getData(promotion)?.promotionType) {
      case 'DISCOUNT':
      case 'BANNER':
        return `${t('backoffice.promotion.from_date_to_date', 'From {{promotionStart}} to {{promotionEnd}}', {
          promotionStart: textFormatter.formatDateShort(startDate.toDate()),
          promotionEnd: textFormatter.formatDateShort(showDate.toDate()),
        })}`;
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
        if (
          !hasData(promotion) ||
          hasPublicationEnd ||
          promotion.references === undefined ||
          promotion.references[0].items === undefined
        ) {
          return `${t('common.date.until', 'Until')} ${showDate.format('MMMM Do')}`;
        }
        return `${t('backoffice.promotion.units.left', 'Units left')}`;
      case 'PROMOTION_FLASH_TIME_LIMITED':
      default:
        return `${t('common.date.until', 'Until')} ${showDate.format('MMMM Do')}`;
    }
  };

  const getRemainingQuantity = () => {
    if (
      !hasData(promotion) ||
      hasPublicationEnd ||
      promotion.references === undefined ||
      promotion.references[0].items === undefined
    ) {
      return undefined;
    } else {
      return promotion.references[0].items[0].quantity;
    }
  };

  const renderIcon = () => {
    if (getData(promotion)?.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED' && !hasPublicationEnd) {
      return BullHornIcon;
    } else {
      return CalendarAltIcon;
    }
  };

  if (!promotion) {
    return <Empty />;
  }

  return (
    <Flex direction={'column'}>
      {promotion.imageUrl && <Banner image={promotion.imageUrl} dataType={'URL'} onlyImage />}
      <Container>
        <Overflow>
          <Flex wrap={'wrap'}>
            <Flex direction={'row'} justify={'flex-start'} align={'center'}>
              <Text type={'h1'}>{promotion.title}</Text>
            </Flex>
            <Icon IconComponent={renderIcon()} size={27.5} mt={-3} noPointer />
            {getData(promotion)?.promotionType === 'PROMOTION_FLASH_QUANTITY_LIMITED' && !hasPublicationEnd && (
              <>
                <MarginBox ml={15} />
                <Text type={'text_bold'} ellipsis>
                  {getRemainingQuantity()}
                </Text>
                <MarginBox ml={5} />
              </>
            )}
            <Text type={'section'} ellipsis>
              {getInfoLabel()}
            </Text>
          </Flex>
        </Overflow>
        <Overflow>
          <Text type={'h1_banner_light'}>{promotion.catchLine}</Text>
        </Overflow>
        <Flex maxWidth={1000}>
          <Text type={'section'}>{parse(promotion.description ?? '')}</Text>
        </Flex>
        <Box height={32} />
        <FamiliesSection commercialFamilyCodes={promotion.commercialFamilyCodes} />
        <ReferenceSection
          references={promotion.references}
          promotionType={promotion.promotionType}
          endDate={promotion.publicationEnd}
          addToCartDisabled={addToCartDisabled}
        />
      </Container>
    </Flex>
  );
};
