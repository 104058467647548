import React from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player/youtube';
import { getWebLanguage } from 'domains/user';
import { Language } from 'utils/i18n/Language';

function getVideoUrl(webLanguage: Language): string | undefined {
  switch (webLanguage) {
    case Language.german:
      return 'https://youtu.be/E6F51_hxHUI';
    default:
      return undefined;
  }
}

export function Teaser() {
  const webLanguage = useSelector(getWebLanguage);
  const url = getVideoUrl(webLanguage);

  if (url === undefined) return null;

  return <ReactPlayer width={'100%'} height={'640px'} url={url} playing controls muted />;
}
