import React from 'react';
import { theme } from 'styles';
import { Box, Flex, MarginBox, PaddingBox } from 'UI';
import { SBox } from './IndexHeader.styled';

export interface IndexHeaderProps {
  id?: string;
  isSelected?: boolean;
  children?: React.ReactNode;
}

export const IndexHeader = ({ id, isSelected, children }: IndexHeaderProps) => {
  return (
    <Flex id={id}>
      <MarginBox mb={-15}>
        <SBox>
          <Box background={isSelected ? theme.color.clear_blue : theme.color.white} height={50}>
            <PaddingBox px={10} pt={5}>
              {children}
            </PaddingBox>
          </Box>
        </SBox>
      </MarginBox>
    </Flex>
  );
};
