/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploadModal } from 'components/FileUpload';
import {
  CONTENT_LIMIT_ERROR,
  FILE,
  FILE_FORMAT_ERROR,
  MAX_SIZE_ERROR,
  ModalStatusType,
  NO_FILE,
  UPLOADING_FILE,
} from 'components/FileUpload/paramsByState';
import {
  getFileReferencesUploadStatusIsModalOpen,
  getFileReferencesUploadStatusModalStatus,
  setFileReferencesUploadStatusIsModalOpen,
  setFileReferencesUploadStatusModalStatus,
  uploadFileReferencesRequest,
} from 'domains/basket/Basket.store';

export default function FileReferencesUpload() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isOpen = useSelector(getFileReferencesUploadStatusIsModalOpen);
  const status = useSelector(getFileReferencesUploadStatusModalStatus);

  function validateFile(file: File) {
    if (!file.name.endsWith('.csv')) {
      dispatch(setFileReferencesUploadStatusModalStatus(FILE_FORMAT_ERROR));
      return false;
    } else if (file.size > 10 * 1024) {
      dispatch(setFileReferencesUploadStatusModalStatus(MAX_SIZE_ERROR));
      return false;
    } else {
      dispatch(setFileReferencesUploadStatusModalStatus(FILE));
      return true;
    }
  }

  const getText1Override = (modalStatus: ModalStatusType) => {
    if (modalStatus === CONTENT_LIMIT_ERROR) {
      return t('cart.file_upload.drag_n_drop.max_references_error', 'More than 99 references');
    }
    return undefined;
  };

  const handleNoFile = () => dispatch(setFileReferencesUploadStatusModalStatus(NO_FILE));
  const handleCancel = () => dispatch(setFileReferencesUploadStatusIsModalOpen(false));

  const uploadFile = (fileName: string, _fileSize: number, fileBase64: string) => {
    const payload = {
      fileName,
      fileBase64,
    };
    dispatch(uploadFileReferencesRequest(payload));
    dispatch(setFileReferencesUploadStatusModalStatus(UPLOADING_FILE));
  };

  const descriptions = [
    t('cart.file_upload.drag_n_drop.support_extension', 'Support extension: CSV - Maximum file size: 10KB'),
    t('cart.file_upload.drag_n_drop.max_references', '99 references maximum'),
  ];

  return (
    <FileUploadModal
      template={{ link: '/fileTemplates/importFile.csv', fileName: 'Basket references upload - template.csv' }}
      handleFileBase64={uploadFile}
      validateFile={validateFile}
      handleNoFile={handleNoFile}
      handleCancel={handleCancel}
      isOpen={isOpen}
      status={status}
      descriptions={descriptions}
      getText1Override={getText1Override}
    />
  );
}
