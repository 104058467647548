import styled from 'styled-components';
import { InputNumber } from 'UI';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const InputArrowsWrapper = styled.div`
  user-select: none;
  position: absolute;
  right: 2px;
`;
export const SInputNumber = styled(InputNumber)<{ mini: boolean; medium: boolean }>`
  position: relative;
  width: 70px;
  ${({ mini }) => mini && 'height: 30px'};
  ${({ medium }) => medium && 'height: 40px'};
`;
