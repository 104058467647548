import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ROUTER_ASSISTANCE,
  ROUTER_CART,
  ROUTER_CATALOG_DH,
  ROUTER_CATALOG_DH_L3,
  ROUTER_HOME,
  ROUTER_IN_PROGRESS_ORDERS,
} from 'app/AppRouter';
import { QuestionCircleIcon } from 'assets/icons';
import HelpIcon from 'components/Help/HelpIcon';
import {
  emailToAssistanceInitialState,
  getEmailToAssistanceDataStatus,
  setEmailToAssistanceData,
  setEmailToAssistanceDataStatus,
} from 'domains/email/Email.store';
import { FILLING, SENT } from 'domains/email/Email.types';
import { getShowFirstHelp, setShowFirstHelp } from 'domains/firstHelp/FirstHelp.store';
import { PageType } from 'domains/firstHelp/FirstHelp.types';
import { theme } from 'styles';
import { Flex, Icon, Link, MarginBox, Text } from 'UI';
import { ERROR } from 'utils';
import { SMenuItem } from '../TopHeader.styled';

export const SupportMenu = ({ setSupportVisible }: { setSupportVisible: (visible: boolean) => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const showFirstHelp = useSelector(getShowFirstHelp).showStreamId;
  const emailToAssistanceStatus = useSelector(getEmailToAssistanceDataStatus);

  const handleLinkToAssistance = () => {
    if (emailToAssistanceStatus === ERROR) {
      dispatch(setEmailToAssistanceDataStatus(FILLING));
    }
    if (emailToAssistanceStatus === SENT) {
      dispatch(setEmailToAssistanceData(emailToAssistanceInitialState));
    }
  };

  const handleShowFirstHelp = () => {
    const setOnOff = (page: PageType) => (!showFirstHelp ? page : undefined);
    const isCatalogRoute = ROUTER_CATALOG_DH === history.location.pathname.slice(0, ROUTER_CATALOG_DH.length);
    const isCatalogL3 = isCatalogRoute && history.location.pathname.split('/').length >= 8;
    const firstHelpPages: PageType[] = [ROUTER_HOME, ROUTER_CART, ROUTER_IN_PROGRESS_ORDERS, ROUTER_CATALOG_DH];
    firstHelpPages.forEach((page) => {
      if (page === history.location.pathname) {
        setSupportVisible(false);
        dispatch(setShowFirstHelp(setOnOff(page)));
      } else if (isCatalogL3) {
        setSupportVisible(false);
        dispatch(setShowFirstHelp(setOnOff(ROUTER_CATALOG_DH_L3)));
      }
    });
  };

  const handleHelpCenterClick = () => {
    handleLinkToAssistance();
    setSupportVisible(false);
  };

  return (
    <Flex direction={'column'} minWidth={200}>
      <Link to={ROUTER_ASSISTANCE}>
        <Flex onClick={handleHelpCenterClick}>
          <SMenuItem minHeight={50} align={'center'} direction={'row'}>
            <MarginBox ml={21} mr={15}>
              <Icon IconComponent={QuestionCircleIcon} size={15} display={'block'} color={theme.color.brand_black} />
            </MarginBox>
            <MarginBox mt={6}>
              <Text type={'section'}>{t('help_center', 'Help center')}</Text>
            </MarginBox>
          </SMenuItem>
        </Flex>
      </Link>
      <Flex onClick={handleShowFirstHelp}>
        <SMenuItem minHeight={50} align={'center'}>
          <HelpIcon />
        </SMenuItem>
      </Flex>
    </Flex>
  );
};
