import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserCountry, getWebLanguage } from 'domains/user';
import {
  AssistanceAdditionalInfo,
  assistanceContactForConnectionIssues,
} from 'pages/AssistancePage/AssistanceLayout/AssistancePageFooter';
import { Image, Text } from 'UI';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { Content, Footer } from '../Assistance.styled';

const ConnectionIssuesFooter = () => {
  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const webLangCountry = useSelector(getWebLanguage)?.country?.key;
  const country = userCountry ?? webLangCountry ?? browserCountry;
  const assistanceContact = assistanceContactForConnectionIssues[country ?? ''];

  const { t } = useTranslation();

  return (
    <Footer justify={'center'}>
      <Image alt={'assistance/assistance-footer.webp'} src={'assistance/assistance-footer.webp'} width={'100%'} />
      <Content direction={'column'} justify={'center'}>
        <Text type={'h1_banner_dark'}>{t('assistance.footer.title', 'Get in touch with our Techline')}</Text>
        <Text type={'card_title_dark'}>{assistanceContact?.phone ?? ''}</Text>
        <Text type={'card_title_dark'}>{assistanceContact?.email ?? ''}</Text>
        <AssistanceAdditionalInfo
          textType={'card_title_dark'}
          tariff={assistanceContact?.tariff}
          openingHours={assistanceContact?.openingHours}
        />
      </Content>
    </Footer>
  );
};

export default ConnectionIssuesFooter;
