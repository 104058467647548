/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DealerType,
  TargetPage,
  TextSection,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import {
  fetchPublishInformationRequestSaga,
  fetchSaveInformationRequestSaga,
  fetchSaveInformationTemplateRequestSaga,
  getCreateInformation,
  getCreateInformationTemplate,
  setCreateInformation,
  setCreateInformationTemplate,
} from 'domains/information/Information.store';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { getUserCountry, getUserRights, UserRole } from 'domains/user';
import { CreateTemplateSection } from 'pages/BackOfficePage/BackOfficeCategories/Information/CreateInformation/InformationTemplate';
import { INFORMATION } from 'pages/BackOfficePage/BackOfficePage.type';
import { Flex, MarginBox, WhiteButton, WithLink, YellowButton } from 'UI';
import { getData } from 'utils';
import { Country } from 'utils/i18n/Country';
import { PublicationDate } from './PublicationDate';
import { TargetAudience } from './TargetAudience';
import { TargetCountries } from './TargetCountries';
import { TargetPages } from './TargetPages';
import { TextSectionWrapper } from './TextSection';

export const isFilled = (s?: string | string[]): boolean => (s?.length ?? 0) > 0;
export const isSectionFilled = (s?: TextSection[] | DealerType[] | TargetPage[]): boolean => (s?.length ?? 0) > 0;
export const isTextSectionFilled = (s?: TextSection[]): boolean =>
  (s?.length ?? 0) > 0 && s?.filter((t) => t.language && t.description && t.title).length === s?.length;

export const isCountryAdmin = (userRights: UserRole[]): boolean =>
  userRights.includes(UserRole.COUNTRY_ADMIN) && !userRights.includes(UserRole.CENTRAL_ADMIN);

const targetCountriesFilledByUser = (userRights: UserRole[], targetCountries?: string[]): boolean => {
  if (isCountryAdmin(userRights)) {
    return false;
  }
  return isFilled(targetCountries);
};

function notEmpty(information: CreateInformationModel, userRights: UserRole[]): boolean {
  if (!information) {
    return false;
  }
  return (
    isSectionFilled(information.textSection) ||
    targetCountriesFilledByUser(userRights, information.targetCountries) ||
    isSectionFilled(information.targetPages) ||
    isSectionFilled(information.targetAudience) ||
    information.publicationStart !== undefined ||
    information.publicationEnd !== undefined
  );
}

const canBePublished = (information: CreateInformationModel): boolean => {
  if (!information) {
    return false;
  }

  const textSectionsIsValid =
    information.textSection.length > 0 &&
    information.textSection.every(
      (section) => (isFilled(section.language) && isFilled(section.title)) || isFilled(section.description),
    );

  return (
    textSectionsIsValid &&
    isFilled(information.targetCountries) &&
    isFilled(information.targetPages) &&
    isFilled(information.targetAudience) &&
    information.publicationStart !== undefined &&
    information.publicationEnd !== undefined
  );
};

export function arraysEqual<T>(newArray: Array<T> | undefined, oldArray: Array<T> | undefined): boolean {
  if (newArray === oldArray) {
    return true;
  }
  if (newArray == null || oldArray == null) {
    return false;
  }
  if (newArray.length !== oldArray.length) {
    return false;
  }

  return newArray.reduce((allElementsEqual, textSection) => allElementsEqual && oldArray.includes(textSection), true);
}

export function textSectionsEqual(
  newSections: TextSection[] | undefined,
  oldSections: TextSection[] | undefined,
): boolean {
  if (newSections === oldSections) {
    return true;
  }
  if (newSections == null || oldSections == null) {
    return false;
  }
  if (newSections.length !== oldSections.length) {
    return false;
  }

  return newSections.reduce(
    (allElementsEqual, newSection) =>
      allElementsEqual &&
      oldSections.some(
        (oldSection) =>
          oldSection.title === newSection.title &&
          oldSection.language === newSection.language &&
          oldSection.description === newSection.description,
      ),
    true,
  );
}

function isCurrentInformationChanged(
  information: CreateInformationModel,
  oldInformation: CreateInformationModel | undefined,
): boolean {
  if (!oldInformation) return true;

  return (
    !textSectionsEqual(information.textSection, oldInformation.textSection) ||
    !arraysEqual(information.targetAudience, oldInformation.targetAudience) ||
    !arraysEqual(information.targetCountries, oldInformation.targetCountries) ||
    !arraysEqual(information.targetPages, oldInformation.targetPages) ||
    information.publicationStart !== oldInformation.publicationStart ||
    information.publicationEnd !== oldInformation.publicationEnd
  );
}

export const CreateInformation = ({
  oldInformationState,
}: {
  oldInformationState?: CreateInformationModel | undefined;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const userCountry = Country.findByKey(useSelector(getUserCountry));
  const information: CreateInformationModel = useSelector(getCreateInformation);
  const displaySaveButton = information.status === undefined || information.status === 'SAVED';
  const isSaveButtonEnabled =
    information.status === undefined
      ? notEmpty(information, getData(userRights) ?? [])
      : isCurrentInformationChanged(information, oldInformationState);
  const isPublishButtonEnabled =
    canBePublished(information) && isCurrentInformationChanged(information, oldInformationState);

  const setInformation = (informationToCreate: CreateInformationModel) => {
    if (isCountryAdmin(getData(userRights) ?? [])) {
      dispatch(setCreateInformation({ ...informationToCreate, targetCountries: [userCountry?.key] }));
      return;
    }
    dispatch(setCreateInformation({ ...informationToCreate }));
  };

  const handlePublish = () => {
    dispatch(fetchPublishInformationRequestSaga());
  };

  const onSave = () => {
    dispatch(setCreateInformation({ ...information, status: 'SAVED' }));
    dispatch(fetchSaveInformationRequestSaga());
  };

  return (
    <>
      <CreateTemplateSection userRights={userRights} isInTemplate={false} />
      <TextSectionWrapper information={information} setInformation={setInformation} />
      <MarginBox mt={30} />
      <TargetAudience information={information} setInformation={setInformation} />
      <MarginBox mt={45} />
      <Flex direction={'row'}>
        <TargetCountries information={information} setInformation={setInformation} />
        <MarginBox ml={100} />
        <TargetPages information={information} setInformation={setInformation} />
      </Flex>
      <MarginBox mt={45} />
      <PublicationDate information={information} setInformation={setInformation} />
      <MarginBox mt={45} />
      <Flex>
        {displaySaveButton && (
          <>
            <WhiteButton stretch={false} onClick={onSave} disabled={!isSaveButtonEnabled}>
              {t('common.action.save', 'Save')}
            </WhiteButton>
            <MarginBox ml={30} />
          </>
        )}
        <WithLink to={`${ROUTER_BACKOFFICE}/${INFORMATION}`}>
          <YellowButton
            stretch={false}
            onClick={() => {
              handlePublish();
            }}
            disabled={!isPublishButtonEnabled}
          >
            {t('common.publish', 'Publish')}
          </YellowButton>
        </WithLink>
      </Flex>
    </>
  );
};

function isMandatoryFilled(templateModel: CreateInformationTemplateModel, userRights: UserRole[]): boolean {
  if (!templateModel) {
    return false;
  }
  return (
    isTextSectionFilled(templateModel.textSection) &&
    targetCountriesFilledByUser(userRights, templateModel.targetCountries)
  );
}

export const CreateInformationTemplate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const userCountry = Country.findByKey(useSelector(getUserCountry));
  const informationTemplate: CreateInformationTemplateModel = useSelector(getCreateInformationTemplate);
  const isSaveButtonEnabled = isMandatoryFilled(informationTemplate, getData(userRights) ?? []);

  const setInformationTemplate = (informationToCreate: CreateInformationTemplateModel) => {
    if (isCountryAdmin(getData(userRights) ?? [])) {
      dispatch(setCreateInformationTemplate({ ...informationToCreate, targetCountries: [userCountry?.key] }));
      return;
    }
    dispatch(setCreateInformationTemplate({ ...informationToCreate }));
  };

  const onSaveTemplate = () => {
    dispatch(setCreateInformationTemplate({ ...informationTemplate }));
    dispatch(fetchSaveInformationTemplateRequestSaga());
  };

  return (
    <>
      <CreateTemplateSection userRights={userRights} isInTemplate={true} />
      <TextSectionWrapper information={informationTemplate} setInformation={setInformationTemplate} />
      <MarginBox mt={30} />
      <TargetAudience information={informationTemplate} setInformation={setInformationTemplate} />
      <MarginBox mt={45} />
      <Flex direction={'row'}>
        <TargetCountries information={informationTemplate} setInformation={setInformationTemplate} />
        <MarginBox ml={100} />
        <TargetPages information={informationTemplate} setInformation={setInformationTemplate} />
      </Flex>
      <MarginBox mt={45} />
      <PublicationDate information={informationTemplate} setInformation={setInformationTemplate} disable={true} />
      <MarginBox mt={45} />
      <Flex>
        <WhiteButton stretch={false} onClick={onSaveTemplate} disabled={!isSaveButtonEnabled}>
          {t('common.action.save', 'Save')}
        </WhiteButton>
        <MarginBox ml={30} />
        <YellowButton stretch={false} disabled={true}>
          {t('common.publish', 'Publish')}
        </YellowButton>
      </Flex>
    </>
  );
};
