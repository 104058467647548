import { Dropdown as AntDropdown } from 'antd';
import styled from 'styled-components';
import { theme } from 'styles';

export const DropdownMenu = styled.div<{
  type: 'brand' | 'season';
  translateX: number;
  translateY: number;
}>`
  width: ${({ type }) => type === 'season' && '190px'};
  ${({ type }) => type === 'brand' && 'height: 400px'};
  box-shadow: 0 4px 10px 0 ${({ theme }) => theme.color.shadow_2};
  overflow-y: scroll;
  background: white;
  margin-top: ${({ translateY }) => `${translateY}px`};
  padding: 15px;
  display: flex;
  flex-direction: column;
  transform: translateX(${({ translateX }) => `${translateX}px`});
`;

export const SAntDropdown = styled(AntDropdown)<{ bordered: boolean; mainSearch?: boolean }>`
  width: ${({ mainSearch }) => (mainSearch ? 180 : 215)}px;
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid ${theme.color.grey85};
  background-color: ${theme.color.white};
  border-radius: 3px;
`;

export const BrandMenuItem = styled.div`
  display: flex;
  height: 35px;
  width: 215px;
  align-items: center;
  cursor: pointer;

  img {
    object-fit: contain;
  }
`;
