import React from 'react';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataAT = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>RPARTSTORE</Text>
          <Text type={'h5_bold'}>INFORMATION ZUM </Text>
          <Text type={'h5_bold'}>SCHUTZ PERSONENBEZOGENER DATEN</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            Die über{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              {'https://rpartstore.renault.com'}
            </Link>{' '}
            zugängliche RPARTSTORE-Website („die Plattform“) wurde eingerichtet, um das Nachschlagen in der technischen
            Dokumentation für Renault-Teile und -Zubehör („die Produkte“) zu ermöglichen sowie um Verkäufer („die
            Verkäufer“), die Produkte verkaufen möchten, über eine Website mit Käufern („die Käufer“) gemäß den AGB der
            Plattform zusammenzubringen. Verkäufer und Käufer werden zusammen als „Nutzer“ bezeichnet.
          </Paragraph>
          <Paragraph>
            Diese Datenschutzinformation dient der Sicherstellung der Transparenz hinsichtlich der Verarbeitung
            personenbezogener Daten im Rahmen Ihrer Nutzung der Plattform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Wofür werden Ihre Daten verwendet und wie lange?'} />
          <Paragraph>
            Bei der Nutzung der Plattform werden Ihre personenbezogenen Daten zu den folgenden Zwecken verarbeitet:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                <strong>Zur Verwaltung Ihrer Zugangsrechte und Berechtigungen auf der Plattform</strong> auf Grundlage
                unseres berechtigten Interesses an der Steuerung der Vertraulichkeit und Sicherheit Ihrer
                personenbezogenen Daten. Wir verarbeiten diese Daten so lange, wie Ihr Benutzerkonto besteht und nicht
                inaktiv ist.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>
                  Zur Verwaltung der Sicherheit und des ordnungsgemäßen Funktionierens von Anwendungen und Netzen
                </strong>{' '}
                auf Grundlage unseres berechtigten Interesses, die IT-Sicherheit der Plattform zu gewährleisten. Wir
                verarbeiten Ihre Daten für 30 Tage in unseren Datenbanken.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>
                  Um gegebenenfalls auf Ihre Anfragen zur Ausübung Ihrer Rechte in Bezug auf Ihre personenbezogenen
                  Daten zu reagieren
                </strong>
                {', '}in Übereinstimmung mit unseren rechtlichen und/oder regulatorischen Verpflichtungen. Ihre
                persönlichen Daten werden so lange aufbewahrt, wie es für die Beantwortung Ihrer Anfrage erforderlich
                ist. Wir müssen jedoch für die Dauer der in den geltenden Rechtsvorschriften vorgesehenen
                Verjährungsfrist die Nachweise zu unserer Antwort hinsichtlich der Ausübung Ihrer Rechte (einschließlich
                der von uns zu diesem Zweck ggf. erhobenen personenbezogenen Daten) archivieren, um auf etwaige Klagen
                reagieren zu können.
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            Darüber hinaus verwendet die Plattform Cookies und andere Tracking-Technologien, um ihren Betrieb und ihre
            Sicherheit zu managen sowie die Besucherzahlen und die Leistung der Plattform unter den Bedingungen zu
            messen, die in der entsprechenden Cookie-Richtlinie festgelegt sind. Diese kann{' '}
            <Link to={ROUTER_COOKIES(Country.AT.key)} external newTab>
              hier aufgerufen werden
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Welche personenbezogenen Daten werden verarbeitet?'} />
          <Paragraph>
            Die personenbezogenen Daten, die wir sammeln, basieren auf unserer Interaktion mit Ihnen und können Daten in
            Bezug auf folgende Angaben enthalten:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                Ihre Identität und Ihre Kontaktdaten (Name, Vorname, berufliche Postanschrift und E-Mail-Adresse)
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>Technische Identifikatoren (IPN-Nummer)</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Verbindungsdaten (Aktivitätsprotokolle, Cookies) in Übereinstimmung mit{' '}
                <Link to={ROUTER_COOKIES(Country.AT.key)} external newTab>
                  Cookie-Richtlinie
                </Link>{' '}
                der Plattform
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
        </Section>
        <Section>
          <SectionHeader title={'Wer verarbeitet Ihre personenbezogenen Daten?'} />
          <Paragraph>
            Die oben aufgeführten Verarbeitungen werden von Renault SAS durchgeführt, gegebenenfalls in Zusammenarbeit
            mit der in Ihrem Land tätigen Renault-Niederlassung (*). Darüber hinaus beteiligen sich die für die
            Vertriebsaktivitäten zuständigen Verkäufer, welche Mitglieder des primären Netzwerks der Niederlassung sind,
            an der Verwaltung Ihrer Zugangs- und Autorisierungsrechte auf der Plattform, indem sie Benutzerprofile in
            den internen Verzeichnissen und Authentifizierungsinstrumenten der Renault-Gruppe erstellen.
          </Paragraph>
          <Paragraph>
            <i>
              (*) Um die rechtliche Identität der in Ihrem Land tätigen Renault-Niederlassung zu erfahren, besuchen Sie
              bitte Verbindungsdaten (Aktivitätsprotokolle, Cookies) in Übereinstimmung mit{' '}
              <Link to={'https://www.renaultgroup.com/en/directory/'} external newTab>
                unser Verzeichnis
              </Link>
              , wählen Sie Ihr Land aus der Liste aus und konsultieren Sie das Impressum.
            </i>
          </Paragraph>
          <Paragraph>
            <i>
              Renault SAS hat ihren Sitz in Frankreich, 122-122 bis avenue du Général Leclerc – 92 100
              Boulogne-Billancourt. Die Kontaktdaten des Datenschutzbeauftragten (DSB) lauten wie folgt: Renault SAS,
              Direction juridique – Délégué à la protection des données, 122-122 bis avenue du Général Leclerc – 92 100
              Boulogne-Billancourt, Frankreich.
            </i>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Wer hat Zugang zu Ihren personenbezogenen Daten?'} />
          <Paragraph>
            Im Rahmen unserer Datenverarbeitung sind Ihre personenbezogenen Daten nur ordnungsgemäß befugten internen
            Mitarbeitern und unseren vertrauenswürdigen externen Dienstleistern zugänglich, die als Unterauftragnehmer
            nur auf unsere Anweisung und in unserem Namen handeln. Darüber hinaus können wir Ihre personenbezogenen
            Daten an Dritte weitergeben, um einer gesetzlichen Verpflichtung oder einer behördlichen oder gerichtlichen
            Entscheidung nachzukommen.
          </Paragraph>
          <Paragraph>
            Da einige dieser Dienstleister oder deren Unterauftragnehmer in Ländern außerhalb der EU/des EWR ansässig
            sind, haben wir uns durch die Unterzeichnung von Standardvertragsklauseln zur Umsetzung angemessener
            Schutzmaßnahmen verpflichtet. Weitere Informationen zu den vereinbarten Standardvertragsklauseln erhalten
            Sie unter{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Welche Rechte haben Sie in Bezug auf Ihre personenbezogenen Daten?'} />
          <Paragraph>
            Gemäß den geltenden Vorschriften haben Sie folgende Rechte in Bezug auf die in diesem Schreiben
            beschriebenen Verarbeitungen:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>
                <strong>Auskunftsrecht:</strong> Sie haben das Recht, Informationen über die Verarbeitung Ihrer
                personenbezogenen Daten (insb. welche Daten verwendet werden, zu welchem Zweck usw.) und eine Kopie
                dieser Daten zu erhalten.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Recht auf Berichtigung:</strong> Sie können die Berichtigung Ihrer personenbezogenen Daten
                verlangen, wenn diese inkorrekt oder unvollständig sind.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Recht auf Löschung:</strong> Sie können die Löschung Ihrer personenbezogenen Daten erwirken,
                wobei dieses Recht angesichts vertraglicher oder gesetzlicher Verpflichtungen eingeschränkt sein kann.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Widerspruchsrecht:</strong> Sie können der Verwendung einiger Ihrer personenbezogenen Daten
                widersprechen. Dabei müssen Sie konkrete Gründe für Ihre besondere Situation anführen.
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                <strong>Recht auf Einschränkung der Verarbeitung:</strong> Sie können die Verwendung Ihrer Daten
                stoppen, insbesondere wenn Sie die Richtigkeit Ihrer personenbezogenen Daten bestreiten, gegen die
                Verarbeitung Ihrer personenbezogenen Daten Widerspruch eingelegt haben oder diese Daten benötigen (z.B.,
                um ein Recht auszuüben oder zu verteidigen) und Sie sicherstellen möchten, dass diese Daten nicht
                gelöscht werden (z. B. wenn die Aufbewahrungsfrist erreicht ist).
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            Zur Ausübung sämtlicher Rechte können Sie eine Anfrage an folgende Adresse senden: dpo@renault.com. Wenn Sie
            glauben, dass die Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, haben Sie das Recht sich bei
            ihrer zuständigen Aufsichtsbehörde zu beschweren.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Version aktualisiert: Mai 2024</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
