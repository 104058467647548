import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { ArrowsAltIcon, CogIcon, CubeIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { useFetchVehicleTechnicalCriteria } from 'domains/catalog/Catalog.requests';
import { getLastIAMTechnicalCriteria } from 'domains/catalog/Catalog.store';
import {
  ItemElement,
  TechnicalCriteriaMenuAndContent,
} from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenuAndContent';
import { MarginBox } from 'UI';
import { FOUND, getData, hasData, SEARCH_STATUS } from 'utils';

function joinStrings(arr: string[] | undefined): string | undefined {
  if (arr === undefined) return undefined;
  return arr.join('\n');
}

function startAndEndDate(start: string | undefined, end: string | undefined): string | undefined {
  if (start === undefined || end === undefined) return undefined;
  return addDashToDate(start) + ' / ' + addDashToDate(end);
}

function addDashToDate(date: string) {
  if (date.length >= 4 && date.at(3) !== '-') {
    return date.slice(0, 4) + '-' + date.slice(4);
  }
  return date;
}

export interface CriteriaI {
  items: ItemElement[];
  status: SEARCH_STATUS;
}

const getBinaryValuesTranslation = (value: string | undefined, t: TFunction): string | undefined => {
  if (!value) {
    return undefined;
  }
  switch (value) {
    case '0':
      return t('catalog.details.technical_criteria.no', 'No');
    case '1':
      return t('catalog.details.technical_criteria.yes', 'Yes');
    default:
      return undefined;
  }
};

export function useGetItems(): CriteriaI {
  const { t } = useTranslation();
  const lastTechnicalCriteria = getData(useSelector(getLastIAMTechnicalCriteria));
  const techCrit = getData(lastTechnicalCriteria);
  const arr = useMemo(
    (): ItemElement[] => [
      {
        label: t('catalog.details.technical_criteria.version', 'Version'),
        icon: CogIcon,
        type: 'CONFIGURATION',
        data: [
          {
            title: t('catalog.details.technical_criteria.version.brand', 'Brand'),
            description: techCrit?.version.brand,
          },
          {
            title: t('catalog.details.technical_criteria.version.family', 'Family'),
            description: techCrit?.version.family,
          },
          {
            title: t('catalog.details.technical_criteria.version.version', 'Version'),
            description: techCrit?.version.version,
          },
          {
            title: t('catalog.details.technical_criteria.version.firstRegistrationDate', 'First registration date'),
            description: techCrit?.version.firstRegistrationDate,
          },
          {
            title: t('catalog.details.technical_criteria.version.body_type', 'Body type'),
            description:
              techCrit?.version.doors &&
              techCrit?.version.bodyType +
                ' ' +
                t('catalog.parts.banner.doors', '{{doors}} doors', { doors: techCrit?.version.doors }),
          },
          {
            title: t('catalog.details.technical_criteria.version.generation', 'Generation'),
            description: techCrit?.version.generation,
          },
          {
            title: t('catalog.details.technical_criteria.version.phase', 'Phase'),
            description: techCrit?.version.phase,
          },
          {
            title: t('catalog.details.technical_criteria.version.series', 'Series'),
            description: techCrit?.version.series,
          },
          {
            title: t('catalog.details.technical_criteria.version.color', 'Color'),
            description: techCrit?.version.color,
          },
          {
            title: t('catalog.details.technical_criteria.version.seats', 'Seats'),
            description: techCrit?.version.seats,
          },
          {
            title: t(
              'catalog.details.technical_criteria.version.vehicle_manufacture_period',
              'Vehicle manufacture period',
            ),
            description: startAndEndDate(
              techCrit?.version.manufacturePeriodStart,
              techCrit?.version.manufacturePeriodEnd,
            ),
          },
          {
            title: t('catalog.details.technical_criteria.version.tires', 'Tires'),
            description: joinStrings(techCrit?.version.tyreSize),
          },
        ].filter((i) => i.description !== undefined),
      },
      {
        label: t('catalog.details.technical_criteria.main_engine', 'Main engine'),
        icon: CubeIcon,
        type: 'MAIN_ENGINE',
        data: [
          {
            title: t('catalog.details.technical_criteria.main_engine.energy', 'Energy'),
            description: joinStrings(techCrit?.mainEngine.energy),
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.engine_type', 'Engine type'),
            description: joinStrings(techCrit?.mainEngine.engineType),
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.particle_filter ', 'Particle filter'),
            description: getBinaryValuesTranslation(techCrit?.mainEngine.particleFilter, t),
          },
          {
            title: t(
              'catalog.details.technical_criteria.main_engine.emission_control_standard',
              'Emission Control Standard',
            ),
            description: techCrit?.mainEngine.emissionControlStandard,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.stop_and_start', 'Stop and start'),
            description: getBinaryValuesTranslation(techCrit?.mainEngine.stopAndStart, t),
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.starter_battery_power', 'Starter Battery Power'),
            description: techCrit?.mainEngine.starterBatteryPower && techCrit?.mainEngine.starterBatteryPower + ' A',
          },
          {
            title: t(
              'catalog.details.technical_criteria.main_engine.starter_battery_capacity',
              'Starter Battery Capacity',
            ),
            description:
              techCrit?.mainEngine.starterBatteryCapacity && techCrit?.mainEngine.starterBatteryCapacity + ' Ah',
          },
          {
            title: t(
              'catalog.details.technical_criteria.main_engine.starter_battery_dimensions',
              'Starter Battery Dimensions',
            ),
            description: techCrit?.mainEngine.starterBatteryDimensions,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.injection', 'Injection'),
            description:
              techCrit?.mainEngine.injectionType &&
              techCrit?.mainEngine.injectionSystem &&
              techCrit?.mainEngine.injectionType + ' ' + techCrit?.mainEngine.injectionSystem,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.dual_mass_flywheel', 'Dual Mass Flywheel'),
            description: getBinaryValuesTranslation(techCrit?.mainEngine.dualMassFlywheel, t),
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.crit_air', "Crit'Air"),
            description: techCrit?.mainEngine.critAir,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.displacement', 'Displacement'),
            description: techCrit?.mainEngine.displacement,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.power_in_din', 'Power in DIN'),
            description:
              techCrit?.mainEngine.powerKw &&
              techCrit?.mainEngine.powerHp &&
              t('catalog.iam.vehicle.kwPower', '{{kwPower}}KW', { kwPower: techCrit?.mainEngine.powerKw }) +
                t('catalog.iam.vehicle.hpPower', '{{hpPower}}HP', { hpPower: techCrit?.mainEngine.powerHp }),
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.combined_horsepower', 'Combined horsepower'),
            description: techCrit?.mainEngine.combinedHorsepower,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.valves_number ', 'Valves number'),
            description: techCrit?.mainEngine.valvesNumber,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.cylinders_number', 'Cylinders number'),
            description: techCrit?.mainEngine.cylindersNumber,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.cylinders_arrangement', 'Cylinders Arrangement'),
            description: techCrit?.mainEngine.cylindersArrangement,
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.turbo', 'Turbo'),
            description: getBinaryValuesTranslation(techCrit?.mainEngine.turbo, t),
          },
          {
            title: t('catalog.details.technical_criteria.main_engine.co2', 'CO2'),
            description: techCrit?.mainEngine.co2,
          },
        ].filter((i) => i.description !== undefined),
      },
      {
        label: t('catalog.details.technical_criteria.transmission', 'Transmission'),
        icon: ArrowsAltIcon,
        type: 'TRANSMISSION',
        data: [
          {
            title: t('catalog.details.technical_criteria.transmission.drive_type', 'Drive'),
            description: joinStrings(techCrit?.transmission.driveType),
          },
          {
            title: t('catalog.details.technical_criteria.transmission.timing_belt_type', 'Timing Belt type'),
            description: techCrit?.transmission.transmission,
          },
          {
            title: t(
              'catalog.details.technical_criteria.transmission.gearbox_type_number_of_gears',
              'Gearbox type/Number of gears',
            ),
            description:
              techCrit?.transmission.gearbox &&
              techCrit?.transmission.gearboxType &&
              t(
                'catalog.details.technical_criteria.transmission.gearbox_type_number_of_gears.value',
                '{{ gearbox }} -speed {{ gearboxType }}',
                { gearbox: techCrit?.transmission.gearbox, gearboxType: techCrit?.transmission.gearboxType },
              ),
          },
          {
            title: t('catalog.details.technical_criteria.transmission.gearbox_code', 'Gearbox code'),
            description: techCrit?.transmission.gearboxCode,
          },
          {
            title: t('catalog.details.technical_criteria.transmission.rear_braking_system', 'Rear braking system'),
            description: techCrit?.transmission.rearBrakingSystem,
          },
        ].filter((i) => i.description !== undefined),
      },
    ],
    [t, techCrit],
  );

  return {
    items: useMemo(() => arr.filter((i) => i.data !== undefined && i.data.length !== 0), [arr]),
    status: hasData(lastTechnicalCriteria) ? FOUND : lastTechnicalCriteria,
  };
}

export function TechnicalCriteria() {
  const { t } = useTranslation();
  const criteria = useGetItems();

  useFetchVehicleTechnicalCriteria(criteria.items, true);

  return (
    <DataContainer
      data={criteria.status}
      ErrorState={() => (
        <ErrorWithLabel
          label={t(
            'catalog.details.backend_error',
            'Technical criteria temporarily unavailable, please try again later.',
          )}
        />
      )}
    >
      <>
        <MarginBox mt={15} />
        <TechnicalCriteriaMenuAndContent items={criteria.items} />
      </>
    </DataContainer>
  );
}
