import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Dropdown as AntDropdown, InputRef, Menu, MenuProps } from 'antd';
import { ROUTER_CATALOG_VEHICLE, ROUTER_HOME } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { GlobeIcon, SearchIcon, TimesCircleIcon } from 'assets/icons';
import { FirstHelpPopin } from 'components/Help/FirstHelpPopin';
import { fetchVehicleByVINVRN } from 'domains/catalog/Catalog.requests';
import { getLastVehicle, getSearchVehicleResult, wasQueryRequested } from 'domains/catalog/Catalog.store';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { DEFAULT_COUNTRY, getUserCountry } from 'domains/user';
import {
  FlexMenuContainer,
  MagnifierButton,
  SContainer,
  SContainerError,
} from 'pages/HomePage/search/SearchByVINVRN.styled';
import { theme } from 'styles';
import { Box, CenterFlex, Flex, Icon, Input, MarginBox, Pipeline, Spin, Text } from 'UI';
import {
  ERROR,
  focusRef,
  FOUND,
  hasData,
  NOT_FOUND,
  RefType,
  SEARCH_STATUS,
  useFocus,
  useFocusOnMount,
  useLarge,
} from 'utils';
import { TRACKING_EVENT_VIN_VRN_SEARCH } from 'utils/eventTracker/EventTracker.types';
import { Country } from 'utils/i18n/Country';
import { SearchQueryType, SearchVariantOption, SearchVariantType, SelectSearchVariant } from './SearchBar';

const SearchByVINVRNError = ({
  search,
  setSearch,
  setIsError,
  userCountry,
  searchInputRef,
  searchStatus,
}: {
  search: SearchQueryType;
  setSearch: (search: SearchQueryType) => void;
  setIsError: (error: boolean) => void;
  userCountry: string | undefined;
  searchInputRef: RefType<InputRef>;
  searchStatus: SEARCH_STATUS;
}) => {
  const large = useLarge();
  const { t } = useTranslation();

  return large ? (
    <SContainerError align={'center'}>
      <Box align={'center'} width={60}>
        <CenterFlex>
          <Icon IconComponent={TimesCircleIcon} width={14} height={14} color={'red'} display={'inline'} noPointer />
        </CenterFlex>
      </Box>
      <Flex justify={'center'} align={'center'}>
        <Text type={'light_14_black_65'} ellipsis>
          {searchStatus === NOT_FOUND
            ? t(
                'catalog.parts.search.by_vin_or_vrn.not_found',
                'Sorry, we could not find any vehicle associated, please verify your input.',
              )
            : t(
                'catalog.parts.search.by_vin_or_vrn.error',
                'Sorry, we could not find any vehicle associated, please try again later.',
              )}
        </Text>
        <MarginBox mr={15} />
      </Flex>
      <Flex justify={'flex-end'} align={'center'} maxWidth={130}>
        <Text
          type={'link'}
          onClick={() => {
            setSearch({
              ...search,
              pendingQuery: undefined,
              country: Country.findByKey(userCountry) ?? DEFAULT_COUNTRY,
            });
            setIsError(false);
            focusRef(searchInputRef);
          }}
          cursor={'pointer'}
          hoverUnderLine
        >
          {t('common.try_again', 'Try again')}
        </Text>
        <MarginBox mr={15} />
      </Flex>
    </SContainerError>
  ) : (
    <Box>
      <SContainerError small align={'center'}>
        <MarginBox ml={10} />
        <MarginBox mt={4}>
          <Icon IconComponent={TimesCircleIcon} width={14} height={14} color={'red'} display={'inline'} />
        </MarginBox>
        <MarginBox ml={8} />
        <Text type={'light_12_red'}>
          {t('catalog.parts.search.by_vin_or_vrn.not_found_mobile', 'Sorry, we could not find any vehicle.')}
        </Text>
        <MarginBox mr={10} />
      </SContainerError>
      <Flex direction={'row'} wrap={'nowrap'}>
        <MarginBox ml={32} />
        <Text type={'light_12_red'}>
          {t('catalog.parts.search.by_vin_or_vrn.verify_your_input_and', 'Please verify your input and')}
        </Text>
        <MarginBox ml={5} />
        <Text
          type={'link_12'}
          onClick={() => {
            setSearch({
              query: '',
              pendingQuery: undefined,
              country: Country.findByKey(userCountry) ?? DEFAULT_COUNTRY,
            });
            setIsError(false);
          }}
          cursor={'pointer'}
          hoverUnderLine
        >
          {t('common.try_again', 'Try again')}
        </Text>
        <Text type={'link_12'}>{`.`}</Text>
      </Flex>
    </Box>
  );
};

export const SearchByVinVrnInputId = 'search-vin_vrn-input';
export const SearchByVinVrnInputName = 'search-vin_vrn-input-name';

const countryOptions = Country.SearchCountryList;

export function SearchByVINVRN({
  searchVariant,
  setSearchVariant,
  searchVariantOptions,
  visible,
  onFound,
}: Readonly<{
  searchVariant: SearchVariantType;
  setSearchVariant: (x: SearchVariantType) => void;
  searchVariantOptions: SearchVariantOption[];
  visible?: boolean;
  onFound?: (vehicleDetail: VehicleDetail) => void;
}>) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const userCountry = useSelector(getUserCountry);
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [search, setSearch] = useState<SearchQueryType>({
    query: '',
    pendingQuery: undefined,
    country: Country.findByKey(userCountry) ?? DEFAULT_COUNTRY,
  });
  const [countrySearch, setCountrySearch] = useState('');
  const [countryMenuOpen, setCountryMenuOpen] = useState(false);
  const lastVehicle = useSelector(getLastVehicle);
  const searchVehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, search.pendingQuery));
  const isQueryRequested = useSelector((state: RootState) => wasQueryRequested(state, search.pendingQuery));
  const received = lastVehicle?.vehicleDetail?.vehicleIdentifiedBy === search.pendingQuery;
  const searchCatalogSource = received ? lastVehicle?.vehicleDetail?.catalogSource : undefined;
  const searchStatus = received ? lastVehicle?.searchVehicleStatus : searchVehicleResult?.searchVehicleStatus;
  const [isError, setIsError] = useState(false);

  const searchVinVrnRef = useRef<InputRef>(null);
  const searchCountryRef = useRef<InputRef>(null);
  useFocusOnMount<InputRef>(searchVinVrnRef);
  useFocus<InputRef>(searchCountryRef, [countryMenuOpen]);
  useFocus<InputRef>(searchVinVrnRef, [!!visible]);

  const onSearch = () => {
    trackAppEvent(TRACKING_EVENT_VIN_VRN_SEARCH);

    fetchVehicleByVINVRN(
      dispatch,
      search.query,
      search.country.key,
      true,
      undefined, // search in all sources
      undefined,
      (vin) => setSearch({ ...search, pendingQuery: vin }),
      () => setSearch({ ...search, pendingQuery: undefined }),
    );
  };
  const onCountryChange = (menuInfo: { key?: string }) => {
    setSearch({ ...search, country: Country.findByKey(menuInfo.key) ?? DEFAULT_COUNTRY });
    setCountryMenuOpen(false);
  };

  const filterCountryByInput = (country: Country, countrySearchInput: string) =>
    countrySearchInput.length === 0 || country?.name.toLowerCase().includes(countrySearchInput.toLowerCase());

  useEffect(() => {
    if (search.pendingQuery) {
      if (searchStatus === FOUND && hasData(searchCatalogSource)) {
        if (onFound && lastVehicle?.vehicleDetail !== undefined) {
          onFound(lastVehicle.vehicleDetail);
        } else {
          history.push(
            `${getCatalogSourceUrl(searchCatalogSource)}/${
              lastVehicle?.vehicleDetail?.vehicleKey
            }${ROUTER_CATALOG_VEHICLE}?${params}`,
          );
        }
        setSearch({ ...search, pendingQuery: undefined });
      } else if (searchStatus === NOT_FOUND || searchStatus === ERROR) {
        setIsError(true);
        setSearch({ ...search, pendingQuery: undefined });
      }
    }
  }, [
    search.pendingQuery,
    history,
    searchStatus,
    search,
    setSearch,
    params,
    searchCatalogSource,
    lastVehicle?.vehicleDetail?.vehicleKey,
    onFound,
    lastVehicle?.vehicleDetail,
  ]);

  const CountryMenu = () => {
    type MenuItem = Required<MenuProps>['items'][number];

    function getItem(
      label: React.ReactNode,
      key: React.Key,
      icon?: React.ReactNode,
      children?: MenuItem[],
      type?: 'group',
    ): MenuItem {
      return {
        key,
        icon,
        children,
        label,
        type,
      } as MenuItem;
    }

    const items = countryOptions
      .filter((country) => filterCountryByInput(country, countrySearch))
      .map((country) =>
        getItem(
          <Text type={'light_12_black_85'} cursor={'pointer'}>
            {country?.name}
          </Text>,
          country?.key,
          <Icon IconComponent={country.icon} width={15} height={15} color={'black'} mr={15} round />,
        ),
      );
    return (
      <FlexMenuContainer direction={'column'}>
        <MarginBox ml={10} mt={5}>
          <Flex align={'center'} direction={'row'} background={theme.color.white}>
            <Icon IconComponent={SearchIcon} width={15} height={15} />
            <MarginBox ml={5}>
              <Pipeline size={17} />
            </MarginBox>
            <Input
              value={countrySearch}
              onChange={setCountrySearch}
              placeholder={'Search for your country here'}
              size={'small'}
              passRef={searchCountryRef}
            />
          </Flex>
        </MarginBox>
        <Menu items={items} onClick={onCountryChange} selectedKeys={[search.country.key]} />
      </FlexMenuContainer>
    );
  };
  return (
    <FirstHelpPopin streamId={ROUTER_HOME} popinId={`${ROUTER_HOME}_veh_search_field`} placement={'bottom'}>
      {isError ? (
        <SearchByVINVRNError
          search={search}
          setSearch={setSearch}
          setIsError={setIsError}
          userCountry={userCountry}
          searchInputRef={searchVinVrnRef}
          searchStatus={searchStatus}
        />
      ) : (
        <SContainer>
          <Flex>
            <Box width={60}>
              <AntDropdown
                overlay={CountryMenu}
                trigger={['click']}
                visible={countryMenuOpen}
                onVisibleChange={(isVisible) => setCountryMenuOpen(isVisible)}
              >
                <a className="ant-dropdown-link">
                  <MarginBox ml={19} mt={11}>
                    <Icon IconComponent={search.country?.icon ?? GlobeIcon} size={27} color={'black'} round />
                  </MarginBox>
                </a>
              </AntDropdown>
            </Box>
            <Pipeline size={48} />
            <SelectSearchVariant
              searchVariant={searchVariant}
              setSearchVariant={setSearchVariant}
              searchVariantOptions={searchVariantOptions}
            />
            <Pipeline size={50} offsetRatio={8} />
            <Flex size={'1 11'}>
              <Input
                id={SearchByVinVrnInputId}
                name={SearchByVinVrnInputName}
                autoComplete={'section-vin-vrn-search'}
                onChange={(value) => setSearch({ ...search, query: value, pendingQuery: undefined })}
                initialValue={search.query}
                placeholder={t(
                  'catalog.parts.search.by_vin_or_vrn.placeholder',
                  'Type your VIN or VRN to identify your vehicle',
                )}
                onPressEnter={onSearch}
                passRef={searchVinVrnRef}
                dataCy={'vin search'}
              />
            </Flex>
            <Box width={60} height={50}>
              {isQueryRequested ? (
                <MarginBox my={13} ml={28}>
                  <Spin size={'small'} />
                </MarginBox>
              ) : (
                <MagnifierButton onClick={onSearch} dataCy={'button-search'}>
                  <MarginBox mt={6} ml={-3}>
                    <Icon IconComponent={SearchIcon} size={27} color={'black'} />
                  </MarginBox>
                </MagnifierButton>
              )}
            </Box>
          </Flex>
        </SContainer>
      )}
    </FirstHelpPopin>
  );
}
