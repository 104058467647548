import styled from 'styled-components';

export const Wrapper = styled.div`
  max-height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  flex: 0;
  border-radius: 20px;
  text-align: left;
  padding: 0 15px 0 15px;
`;
