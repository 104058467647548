import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SSOLinkResponse } from '@1po/1po-bff-fe-spec/generated/sso/SSOLinkResponse';
import { clearSSOLinkRequest, fetchSSOLinkRequest, getSSOLink } from 'domains/ssoLink/SSOLink.store';
import { SSOLinkType } from 'domains/ssoLink/SSOLink.types';
import { Flex } from 'UI';
import { getSearchData, hasData, isLoading } from 'utils';

interface SSOLinkProps {
  renderLinkComponent: () => ReactNode;
  renderLoadingComponent?: () => ReactNode;
  ssoType: SSOLinkType;
}

export const SSOLink = ({ renderLinkComponent, renderLoadingComponent, ssoType }: SSOLinkProps) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);

  const ssoLink = useSelector(getSSOLink);
  const loadingComponent = renderLoadingComponent || renderLinkComponent;

  const handlePostRedirect = () => {
    setIsActive(true);
    dispatch(fetchSSOLinkRequest(ssoType));
  };

  useEffect(() => {
    if (!hasData(ssoLink.data) || !isActive) {
      return;
    }

    const linkData = getSearchData(ssoLink);

    const form = document.createElement('form');
    form.method = 'POST';
    form.target = '_blank';
    form.action = linkData?.url ?? '';

    try {
      if (linkData as SSOLinkResponse) {
        const inputLoginData = document.createElement('input');
        inputLoginData.type = 'hidden';
        inputLoginData.name = 'login_data';
        inputLoginData.value = linkData?.login_data ?? '';
        form.appendChild(inputLoginData);

        const inputLoginIv = document.createElement('input');
        inputLoginIv.type = 'hidden';
        inputLoginIv.name = 'login_iv';
        inputLoginIv.value = linkData?.login_iv ?? '';
        form.appendChild(inputLoginIv);

        document.body.appendChild(form);
        form.submit();
      }
    } finally {
      form.parentNode && document.body.removeChild(form);
      setIsActive(false);
      dispatch(clearSSOLinkRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoLink.searchStatus, isActive]);

  return (
    <>
      {isLoading(ssoLink.searchStatus) && isActive ? (
        loadingComponent()
      ) : (
        <Flex onClick={handlePostRedirect}>{renderLinkComponent()}</Flex>
      )}
    </>
  );
};
