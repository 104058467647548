import styled from 'styled-components';
import { effect } from 'styles/effect';
import { Box, Text } from 'UI';

export const ActionBox = styled(Box)`
  display: flex;
  cursor: pointer;
`;

export const ClickBox = styled.div`
  ${effect.hover.enlarge}
`;

export const TabTitle = styled.div`
  width: 100%;
  min-height: 120px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.grey_very_dark};
  display: block;
  padding-top: 30px;
  padding-left: 15px;
  text-align: center;
  text-transform: capitalize;
`;

export const SubTitleText = styled(Text)`
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.grey35}
  text-align: center;
`;

export const SSearchShortcutWrapper = styled.div`
  padding: 15px 15px 18px 30px;
`;
