import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { Input, InputProps } from 'UI';
import { ONCHANGE_DEBOUNCE_INTERVAL } from 'utils/hooks/useInput';

export interface AutocompleteSearchInputProps extends InputProps {
  name?: string;
}

const Wrapper = styled.div`
  max-height: 31px;
  min-height: 31px;
  display: flex;
  align-items: center;
  flex: 0;
  border-radius: 3px;
  text-align: left;
  padding: 0 15px 0 15px;
`;
export function SearchAutocompleteInput({
  id,
  name,
  value,
  onChange,
  onChangeDebounced,
  placeholder,
  color,
  onPressEnter,
  passRef,
  onKeyPress,
  onBlur,
  className,
  autoComplete,
}: Readonly<AutocompleteSearchInputProps>) {
  const handleChangeDebounced = useDebouncedCallback((debouncedVal: string, e?: ChangeEvent<HTMLInputElement>) => {
    onChangeDebounced && onChangeDebounced(debouncedVal, e);
  }, ONCHANGE_DEBOUNCE_INTERVAL);

  function onChangeLocal(val: string, e?: ChangeEvent<HTMLInputElement>) {
    onChange && onChange(val, e);
    handleChangeDebounced(val.trim(), e);
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Wrapper className={className}>
        <Input
          id={id}
          name={name}
          autoComplete={autoComplete}
          value={value}
          onChange={onChangeLocal}
          placeholder={placeholder}
          color={color}
          onPressEnter={onPressEnter}
          passRef={passRef}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          ellipsis
          dataCy={'search-bar'}
        />
      </Wrapper>
    </form>
  );
}
