import { WS_DESTINATION_PREFIX } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { Client } from '@stomp/stompjs';
import { Dispatch } from 'redux';
import { RootState } from 'app/AppStore';
import { WebSocketAction } from 'utils/domainStore';
import { checkRequestTimeout } from './utils';

export const sendMessage = (
  stompClient: Client | null,
  request: WebSocketAction,
  getState: () => RootState,
  dispatch: Dispatch,
): void => {
  if (!stompClient) {
    throw new Error('stompClient was not initialized');
  }

  const { traceId, payload, url } = request;
  const data = JSON.stringify({ payload });

  try {
    stompClient.publish({
      destination: WS_DESTINATION_PREFIX + url,
      body: data,
      headers: {
        'trace-id': traceId,
      },
    });
  } catch (e) {
    console.error(`Error observed during stomp client publish. Unable to send data to ${url} with data ${data}`, e);
  }

  if (request.timeout) {
    setTimeout(() => {
      checkRequestTimeout(traceId, getState, dispatch);
    }, request.timeout);
  }
};
