import React from 'react';
import { Icon, IconType } from 'UI';
import { InfoText, InfoTitle, ItemWrapper } from './PortfolioItem.styled';

interface ItemProps {
  InfoIcon: IconType;
  infoTitle: string;
  infoText: string | JSX.Element;
}

const PortfolioItem = ({ InfoIcon, infoTitle, infoText }: ItemProps): JSX.Element => {
  return (
    <ItemWrapper>
      <Icon IconComponent={InfoIcon} size={37} />
      <InfoTitle>{infoTitle}</InfoTitle>
      <InfoText>{infoText}</InfoText>
    </ItemWrapper>
  );
};
export default PortfolioItem;
