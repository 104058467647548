import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { MyStoreSection } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { MyStoreTireDiscount } from '@1po/1po-bff-fe-spec/generated/my_store/tires/model/MyStoreTireDiscount';
import { MyStoreTireRimSizeType } from '@1po/1po-bff-fe-spec/generated/my_store/tires/model/MyStoreTireRimSizeType';
import { APP_NAMESPACE } from 'app/App.constants';
import { SearchData } from 'utils';

export const MY_STORE_NAMESPACE = `${APP_NAMESPACE}/MY_STORE`;

export const INIT_MY_STORE = `${MY_STORE_NAMESPACE}/INIT_MY_STORE`;

// BUNDLES
export const GET_BUNDLES_REQUEST = `${MY_STORE_NAMESPACE}/GET_BUNDLES_REQUEST`;
export const MOVE_BUNDLES_SECTION_REQUEST = `${MY_STORE_NAMESPACE}/MOVE_BUNDLES_SECTION_REQUEST`;
export const RENAME_BUNDLES_SECTION_REQUEST = `${MY_STORE_NAMESPACE}/RENAME_BUNDLES_SECTION_REQUEST`;
export const ADD_BUNDLES_SECTION_REQUEST = `${MY_STORE_NAMESPACE}/ADD_BUNDLES_SECTION_REQUEST`;
export const ADD_BUNDLE_REQUEST = `${MY_STORE_NAMESPACE}/ADD_BUNDLE_REQUEST`;
export const REMOVE_BUNDLE_REQUEST = `${MY_STORE_NAMESPACE}/REMOVE_BUNDLE_REQUEST`;
export const UPDATE_BUNDLE_DESIGNATION_REQUEST = `${MY_STORE_NAMESPACE}/UPDATE_BUNDLE_DESIGNATION_REQUEST`;
export const UPDATE_BUNDLE_PRICE_REQUEST = `${MY_STORE_NAMESPACE}/UPDATE_BUNDLE_PRICE_REQUEST`;
export const UPDATE_BUNDLE_CODE_REQUEST = `${MY_STORE_NAMESPACE}/UPDATE_BUNDLE_CODE_REQUEST`;

// TIRES
export const GET_TIRES_REQUEST = `${MY_STORE_NAMESPACE}/GET_TIRES_REQUEST`;
export const UPDATE_TIRE_DISCOUNT_LIST_REQUEST = `${MY_STORE_NAMESPACE}/UPDATE_TIRE_DISCOUNT_LIST_REQUEST`;

export interface MyStoreState {
  bundles: MyStoreBundles;
  tires: MyStoreTires;
  tiresDiscountChanges: MyStoreTireRequestData[];
}

export type SectionTitleType = 'TIRES' | 'BRAKING' | 'MAINTENANCE' | 'OTHERS' | string;

export interface MyStoreBundles {
  myStoreBundlesId?: string;
  sections: SearchData<MyStoreSection[]>;
  selectedBundleIds: Set<string>;
}

export interface BundleUpdateType {
  sectionId: string;
  bundleId: string;
}

export interface MyStoreTireRequestData {
  brandId: ReferenceBrandType;
  tireRimSizeType: MyStoreTireRimSizeType;
  discount: number | undefined;
}

export interface MyStoreTireBrandLocal {
  brand: ReferenceBrandType;
  discounts: Map<MyStoreTireRimSizeType, MyStoreTireDiscount>;
}

export interface MyStoreTires {
  myStoreTiresId?: string;
  brands: SearchData<Map<ReferenceBrandType, MyStoreTireBrandLocal>>;
}
