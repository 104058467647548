import { useSelector } from 'react-redux';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/catalog/references/common/model/ReferenceBrandType';
import { RootState } from 'app/AppStore';
import { getMyStoreTiresDiscount } from 'domains/myStore/MyStore.store';
import { getTireDiameter } from 'pages/TiresPage/TireReferencesSection/TireReferenceCard/TireReferenceCardUtils';

export function useMyStoreTireDiscount(brandName?: ReferenceBrandType, tireDiameter?: number) {
  const tireRimSize = getTireDiameter(tireDiameter);
  return useSelector((state: RootState) => {
    if (brandName && tireRimSize) {
      return getMyStoreTiresDiscount(state, {
        brand: brandName,
        tireRimSizeType: tireRimSize,
      });
    }
    return undefined;
  });
}
