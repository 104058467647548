/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import Loader from 'components/Loader';
import { getCorrectPromotionView } from 'domains/promotion/Promotion.mapper';
import { useFetchPromotionDetails } from 'domains/promotion/Promotion.requests';
import { getCurrentPromotionDetails, getFullPromotion } from 'domains/promotion/Promotion.store';
import { PromotionAdditionalDiscountPreviewParam } from 'domains/promotion/Promotion.types';
import { getData, hasData } from 'utils';
import { PromotionPageView } from './PromotionPageView';

const PromotionDetailPage = () => {
  const { promotionId } = useParams<{ promotionId: string }>();
  const promotionData = useSelector((state: RootState) => getCurrentPromotionDetails(state, promotionId));
  const promotion = getData(promotionData);
  const fullPromotionData = useSelector((state: RootState) => getFullPromotion(state, promotionId));
  const fullPromotion = getData(fullPromotionData);

  const params = new URLSearchParams(location.search);
  const previewParam = params.get(PromotionAdditionalDiscountPreviewParam);
  const displayPromotionPreview = previewParam !== null ? previewParam === 'true' : undefined;
  const adjustmentState = promotion?.isAdjusted ?? fullPromotion?.adjustmentState?.applyDiscount;
  const addToCartDisabled =
    displayPromotionPreview !== undefined && adjustmentState !== undefined
      ? adjustmentState !== displayPromotionPreview
      : false;

  useFetchPromotionDetails(promotionId, hasData(promotion));

  return (
    <DataContainer data={promotion} Loading={() => <Loader height={'50vh'} />}>
      <PromotionPageView
        promotion={getCorrectPromotionView(promotion, fullPromotion, displayPromotionPreview)}
        addToCartDisabled={addToCartDisabled}
      />
    </DataContainer>
  );
};

export default PromotionDetailPage;
