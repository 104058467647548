import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getDHReference } from 'domains/references';
import { Text } from 'UI';
import { hasData } from 'utils';

interface ExternalBasketDialogReferenceNameProps {
  referenceNumber: string;
}

export function ExternalBasketDialogReferenceName(props: ExternalBasketDialogReferenceNameProps) {
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: props.referenceNumber, vehicleKey: undefined }),
  );

  if (!hasData(reference)) return <></>;

  return <Text type={'text_dim'}>{reference?.name}</Text>;
}
