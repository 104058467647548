import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  ROUTER_ASSISTANCE_OTHER_BRANDS,
  ROUTER_CAR_PARTS,
  ROUTER_MEMBERSHIP_AND_SUPPORT,
  ROUTER_TECHNICAL_DOCUMENTATION,
} from 'app/AppRouter';
import AssistancePageFooter from 'pages/AssistancePage/AssistanceLayout/AssistancePageFooter';
import { Flex, Image, Link, MarginBox, Text } from 'UI';
import { SBox, STextWrapper } from './Assistance.styled';
import ContactUs from './AssistanceCategories/ContactUs/ContactUs';

const Tab = ({ title, icon, link }: { title: string; icon: ReactNode; link: string }) => {
  const history = useHistory();
  return (
    <Link to={link}>
      <SBox width={350} height={200} isActive={history.location.pathname.includes(link)}>
        {icon}
        <STextWrapper>
          <Text type={'h2'}>{title}</Text>
        </STextWrapper>
      </SBox>
    </Link>
  );
};

const AssistanceOtherBrands = () => {
  const { t } = useTranslation();
  const tabsData = [
    {
      key: 0,
      icon: <Image src={`assistance/assistance-car-parts-icon.webp`} alt={`assistance car parts icon`} />,
      title: t('assistance.contact_us.other_brands.car_parts', 'Car parts'),
      link: `${ROUTER_ASSISTANCE_OTHER_BRANDS}${ROUTER_CAR_PARTS}`,
    },
    {
      key: 1,
      icon: (
        <Image
          src={`assistance/assistance-technical-documentation-icon.webp`}
          alt={`assistance technical documentation icon`}
        />
      ),
      title: t('assistance.contact_us.other_brands.technical_documentation', 'Technical documentation'),
      link: `${ROUTER_ASSISTANCE_OTHER_BRANDS}${ROUTER_TECHNICAL_DOCUMENTATION}`,
    },
    {
      key: 2,
      icon: (
        <Image
          src={`assistance/assistance-membership-and-support-icon.webp`}
          alt={`assistance membership and support icon`}
        />
      ),
      title: t('assistance.contact_us.other_brands.membership_and_support', 'Membership and support'),
      link: `${ROUTER_ASSISTANCE_OTHER_BRANDS}${ROUTER_MEMBERSHIP_AND_SUPPORT}`,
    },
  ];

  return (
    <>
      <Text type={'h1_banner_light'} id={'assistance-contact_us'}>
        {t('assistance.other_brands.title', 'Other brands')}
      </Text>
      <MarginBox mt={30} />
      <Flex justify={'space-between'}>
        {tabsData.map((tab) => (
          <Tab key={tab.key} title={tab.title} icon={tab.icon} link={tab.link} />
        ))}
      </Flex>
      <MarginBox mt={30} />
      <Switch>
        <Route exact path={`${ROUTER_ASSISTANCE_OTHER_BRANDS}`} />
        <Route
          exact
          path={`${ROUTER_ASSISTANCE_OTHER_BRANDS}${ROUTER_CAR_PARTS}`}
          render={() => (
            <>
              <ContactUs title={t('assistance.contact_us.other_brands.car_parts', 'Car parts')} type={'car-parts'} />
              <AssistancePageFooter type={'car-parts'} />
            </>
          )}
        />
        <Route
          exact
          path={`${ROUTER_ASSISTANCE_OTHER_BRANDS}${ROUTER_TECHNICAL_DOCUMENTATION}`}
          render={() => (
            <>
              <ContactUs
                title={t('assistance.contact_us.other_brands.technical_documentation', 'Technical documentation')}
                type={'technical-documentation'}
              />
              <AssistancePageFooter type={'technical-documentation'} />
            </>
          )}
        />
        <Route
          exact
          path={`${ROUTER_ASSISTANCE_OTHER_BRANDS}${ROUTER_MEMBERSHIP_AND_SUPPORT}`}
          render={() => (
            <>
              <ContactUs
                title={t('assistance.contact_us.other_brands.membership_and_support', 'Membership and support')}
                type={'membership-and-support'}
              />
              <AssistancePageFooter type={'membership-and-support'} />
            </>
          )}
        />
        <Route path={'*'}>
          <Redirect to={'/'} />
        </Route>
      </Switch>
    </>
  );
};

export default AssistanceOtherBrands;
