import React from 'react';
import { useSelector } from 'react-redux';
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { CATALOG, ROUTER_CATALOG_VEHICLE, ROUTER_HOME, ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CarSmileIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { MenuItem } from 'components/Page/PageSideBar/PageSideBar.styled';
import { TextWrapper } from 'components/ProfileInfo/ProfileInfo';
import TreeMenu from 'components/TreeMenu';
import { TreeType } from 'components/TreeMenu/TreeMenu';
import { getExplodedIAMTree, getExplodedOEMTree, getExplodedTree } from 'domains/catalog/Catalog.store';
import { OEM_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { getIamCatalogBrandsView } from 'domains/user';
import { Flex, Icon, Link, MarginBox, Text, WithTooltip } from 'UI';

const getTitle = (vehicleDetail: VehicleDetail) => {
  const isIAM = vehicleDetail.catalogSource === 'IAM';
  const VIN_LENGTH = 17;
  if (isIAM) {
    return `${vehicleDetail.model} ${vehicleDetail.iamVehicle?.version ?? ''}`;
  }

  if (vehicleDetail.vehicleIdentifiedBy.length > VIN_LENGTH) {
    return `${vehicleDetail.model} ${vehicleDetail.dataHubVehicle?.engine ?? ''}`;
  }
  return vehicleDetail.vehicleIdentifiedBy;
};

const VehicleHeader = ({ vehicleDetail }: { vehicleDetail: VehicleDetail }) => {
  return (
    <TextWrapper gap={5}>
      <Icon IconComponent={CarSmileIcon} size={24} ml={10} />
      <Text className={'menu-item-title'} type={'text_dim'} transform={'capitalize'} noWrap ellipsis>
        {getTitle(vehicleDetail)}
      </Text>
    </TextWrapper>
  );
};

const SideBarCarCatalog = ({ vehicleDetail }: { vehicleDetail: VehicleDetail }) => {
  const isIAM = vehicleDetail.catalogSource === 'IAM';
  const iamCatalogView = useSelector(getIamCatalogBrandsView);
  const isOEM = iamCatalogView === OEM_BRAND_KEY;
  const params = new URLSearchParams(location.search);

  const tree = useSelector((state: RootState) => {
    if (isIAM && isOEM) {
      return getExplodedOEMTree(state, vehicleDetail.vehicleKey);
    }
    if (isIAM) {
      return getExplodedIAMTree(state, vehicleDetail.vehicleKey);
    }
    return getExplodedTree(state, vehicleDetail.vehicleKey);
  });

  const getCatalogLink = () => {
    return vehicleDetail
      ? `${getCatalogSourceUrl(vehicleDetail.catalogSource)}/${vehicleDetail.vehicleKey}${ROUTER_CATALOG_VEHICLE}`
      : ROUTER_HOME;
  };

  const getCatalogLinkWithParams = () => (vehicleDetail ? `${getCatalogLink()}?${params}` : getCatalogLink());

  const handleLvl3Items = (lvl3Items?: VehicleIAMPartCategoryTreeItem[]) => {
    return (
      lvl3Items?.map((lvl3: VehicleIAMPartCategoryTreeItem) => {
        return {
          nodeId: lvl3.nodeId,
          label: lvl3.label,
          childCategories: [],
        };
      }) ?? []
    );
  };

  const handleLvl2Items = (lvl2Items?: VehicleIAMPartCategoryTreeItem[]) => {
    return (
      lvl2Items?.map((lvl2: VehicleIAMPartCategoryTreeItem) => {
        return {
          nodeId: lvl2.nodeId,
          label: lvl2.label,
          childCategories: handleLvl3Items(lvl2.items),
        };
      }) ?? []
    );
  };

  const handleLvl1Items = (lvl1Items?: VehicleIAMPartCategoryTreeItem[]) => {
    return (
      lvl1Items?.map((lvl1: VehicleIAMPartCategoryTreeItem) => {
        return {
          nodeId: lvl1.nodeId,
          label: lvl1.label,
          childCategories: handleLvl2Items(lvl1.items),
        };
      }) ?? []
    );
  };

  const getTree = (): TreeType[] => handleLvl1Items(tree?.data);

  return (
    <DataContainer data={tree?.searchStatus}>
      <Flex direction={'column'}>
        <WithTooltip title={getTitle(vehicleDetail)} placement={'right'}>
          <div>
            <Link to={getCatalogLinkWithParams()} dataCy={'vin-banner'}>
              <MenuItem $active={location.pathname.includes(CATALOG)}>
                <VehicleHeader vehicleDetail={vehicleDetail} />
              </MenuItem>
            </Link>
          </div>
        </WithTooltip>
        {!location.pathname.includes(ROUTER_UNIVERSAL_PRODUCTS) && (
          <>
            <MarginBox mt={25} />
            <TreeMenu tree={getTree()} baseUrl={getCatalogLink()} disableLevel2Click={isIAM} />
          </>
        )}
      </Flex>
    </DataContainer>
  );
};

export default SideBarCarCatalog;
