/* eslint-disable max-len */
import { CrossSellingFileProgressResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/response/CrossSellingFileProgressResponse';
import { CrossSellingFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/response/CrossSellingFileResponse';
import { CrossSellingUploadFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/response/CrossSellingUploadFileResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { FILE, NO_FILE, UPLOADING_FILE } from 'components/FileUpload/paramsByState';
import {
  sendCrossSellingFileRequest,
  sendCrossSellingReplaceRequest,
  sendDeleteCrossSellingFileRequest,
  sendGetCrossSellingFileRequest,
} from 'domains/crossSelling/CrossSelling.api';
import * as actions from 'domains/crossSelling/CrossSelling.store';
import {
  getCrossSellingFileCorrectRows,
  getCrossSellingFileInformationForRequest,
  getCrossSellingFileUploadStatus,
  setCrossSellingFileModalStatus,
  setCrossSellingFileResponse,
  setCrossSellingFileUploadResponse,
  setCrossSellingFileUploadStatus,
  setCrossSellingSearchFileStatus,
  setFileProcessPercent,
} from 'domains/crossSelling/CrossSelling.store';
import {
  DELETING_FILE,
  FETCHING_FILE,
  FILE_DELETED,
  FILE_FETCHED,
  FILE_REPLACED,
  FILE_UPLOADED,
  REPLACING_FILE,
} from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { notifyTop } from 'UI/Notification/notification';
import { AppTranslation, FOUND, LOADING, NOT_FOUND, sagaGuard, select } from 'utils';
import { MAX_FILE_ROWS } from './CrossSelling.types';

export function* fetchFileCrossSellingRequestSaga(): SagaIterator {
  yield put(setCrossSellingFileUploadStatus(FETCHING_FILE));
  yield put(setCrossSellingSearchFileStatus(LOADING));
  yield put(sendGetCrossSellingFileRequest());
}

export function* fetchFileCrossSellingResponseSaga(action: WsResponse<CrossSellingFileResponse>): SagaIterator {
  const status = yield* select(getCrossSellingFileUploadStatus);
  yield put(setCrossSellingSearchFileStatus(FOUND));
  yield put(setCrossSellingFileResponse(action.payload));
  switch (status) {
    case FETCHING_FILE:
      yield put(setCrossSellingFileUploadStatus(FILE_FETCHED));
      break;
    case REPLACING_FILE:
      yield put(setCrossSellingFileUploadStatus(FILE_REPLACED));
      break;
  }
  yield put(setCrossSellingFileModalStatus(FILE));
}

export function* uploadFileCrossSellingRequestSaga({
  payload,
}: ReturnType<typeof actions.uploadFileCrossSellingRequestSaga>): SagaIterator {
  yield put(setCrossSellingFileUploadStatus(UPLOADING_FILE));
  yield put(setFileProcessPercent(0));
  yield put(sendCrossSellingFileRequest({ ...payload }));
}

export function* uploadFileCrossSellingResponseSaga(action: WsResponse<CrossSellingUploadFileResponse>): SagaIterator {
  notifyTop(
    'success',
    AppTranslation.t('cross_selling.upload.result.success.notification.title', 'Cross selling'),
    AppTranslation.t(
      'cross_selling.upload.result.success.notification.description',
      'Your file was successfully uploaded',
    ),
  );
  yield put(setCrossSellingFileUploadResponse(action.payload));
  yield put(setCrossSellingFileUploadStatus(FILE_UPLOADED));
  yield put(setCrossSellingFileModalStatus(FILE));
}

export function* replaceFileCrossSellingRequestSaga(): SagaIterator {
  const fileInfo = yield* select(getCrossSellingFileInformationForRequest);
  const correctRows = yield* select(getCrossSellingFileCorrectRows);
  const sourceSum = new Set([...correctRows.map((row) => row.sourceReference)]);
  if (sourceSum.size > MAX_FILE_ROWS)
    notifyTop(
      'warning',
      AppTranslation.t('cross_selling.upload.big_file_warning.title', 'Large file'),
      AppTranslation.t('cross_selling.upload.big_file_warning.description', 'File processing might take a while'),
    );
  const { fileName } = fileInfo;
  yield put(setCrossSellingFileUploadStatus(REPLACING_FILE));
  yield put(setFileProcessPercent(0));
  yield put(sendCrossSellingReplaceRequest({ fileName, correctRows }));
}

export function* deleteFileCrossSellingRequestSaga(): SagaIterator {
  yield put(setCrossSellingFileUploadStatus(DELETING_FILE));
  yield put(setFileProcessPercent(0));
  yield put(sendDeleteCrossSellingFileRequest());
}

export function* deleteFileCrossSellingResponseSaga(): SagaIterator {
  yield put(setCrossSellingFileResponse({ fileName: '', fileRows: [] }));
  yield put(setCrossSellingSearchFileStatus(NOT_FOUND));
  yield put(setCrossSellingFileUploadStatus(FILE_DELETED));
  yield put(setCrossSellingFileModalStatus(NO_FILE));
}

export function* fetchCrossSellingFileProcessingResponse(
  action: WsResponse<CrossSellingFileProgressResponse>,
): SagaIterator {
  yield put(setFileProcessPercent(action.payload.progress));
}

export function* CrossSellingSagas(): SagaIterator {
  yield takeEvery(actions.uploadFileCrossSellingRequestSaga.type, sagaGuard(uploadFileCrossSellingRequestSaga));
  yield takeEvery(actions.uploadFileCrossSellingResponseSaga.type, sagaGuard(uploadFileCrossSellingResponseSaga));
  yield takeEvery(actions.replaceFileCrossSellingRequestSaga.type, sagaGuard(replaceFileCrossSellingRequestSaga));
  yield takeEvery(actions.fetchFileCrossSellingRequestSaga.type, sagaGuard(fetchFileCrossSellingRequestSaga));
  yield takeEvery(actions.fetchFileCrossSellingResponseSaga.type, sagaGuard(fetchFileCrossSellingResponseSaga));
  yield takeEvery(actions.deleteFileCrossSellingRequestSaga.type, sagaGuard(deleteFileCrossSellingRequestSaga));
  yield takeEvery(actions.deleteFileCrossSellingResponseSaga.type, sagaGuard(deleteFileCrossSellingResponseSaga));
  yield takeEvery(
    actions.fetchCrossSellingFileProcessingResponse.type,
    sagaGuard(fetchCrossSellingFileProcessingResponse),
  );
}
