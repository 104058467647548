import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { fileDownloadRequestSaga, getFile } from 'domains/downloadFiles/Files.store';
import { NativeBadge, Spin } from 'UI';
import { ERROR, FOUND, LOADING, NOT_FOUND } from 'utils';

export interface DownloadFileButtonProps {
  fileKey: string;
  children: React.ReactNode;
}

const DownloadFileWrapper = ({ fileKey, children }: DownloadFileButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const exportedFile = useSelector((state: RootState) => getFile(state, fileKey));

  function dispatchLoadingFile() {
    dispatch(fileDownloadRequestSaga({ fileKey }));
  }

  const downloadButton = (
    <span
      onKeyDown={(event) => event.key === 'Enter' && dispatchLoadingFile()}
      onClick={(e) => {
        e.stopPropagation();
        dispatchLoadingFile();
      }}
    >
      {children}
    </span>
  );

  if (exportedFile?.searchStatus === undefined) {
    return <>{downloadButton}</>;
  } else if (exportedFile?.searchStatus === LOADING) {
    return (
      <NativeBadge
        status={'default'}
        count={t('common.download_button.downloading', `Downloading`)}
        color={'lightgray'}
      >
        <Spin>
          <span>{children}</span>
        </Spin>
      </NativeBadge>
    );
  } else if (exportedFile?.searchStatus === FOUND) {
    return (
      <NativeBadge
        status={'success'}
        dot={true}
        color={'lime'}
        title={t('common.download_button.already_downloaded', `Already downloaded as {{fileName}}`, {
          fileName: exportedFile?.data?.fileName,
        })}
      >
        {downloadButton}
      </NativeBadge>
    );
  } else if (exportedFile?.searchStatus === ERROR) {
    return (
      <NativeBadge color={'red'} status={'error'} count={t('common.download_button.error', `Error`)}>
        {downloadButton}
      </NativeBadge>
    );
  } else if (exportedFile?.searchStatus === NOT_FOUND) {
    return (
      <NativeBadge color={'red'} status={'error'} count={t('common.download_button.file_not_found', `Not found`)}>
        {downloadButton}
      </NativeBadge>
    );
  } else {
    return <span>Downloading ...</span>;
  }
};

export default DownloadFileWrapper;
