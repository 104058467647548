import React, { useState } from 'react';
import { DatePicker, DatePickerProps } from './DatePicker';
import { FloatingLabel } from '../FloatingLabel';

export interface FloatDatePickerProps extends DatePickerProps {
  // if not filled, placeholder is used
  floatLabel?: string;
  className?: string;
}

export function FloatDatePicker(props: FloatDatePickerProps) {
  const [focus, setFocus] = useState(false);
  const displayFloatingLabel = focus || props.value !== null;
  return (
    <FloatingLabel
      label={props.floatLabel ?? props.placeholder}
      displayFloatingLabel={displayFloatingLabel}
      setFocus={setFocus}
    >
      <DatePicker {...props} placeholder={focus ? '' : props.placeholder} />
    </FloatingLabel>
  );
}
