/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { PlateIdParam } from 'domains/catalog/Catalog.types';
import { getDHReference } from 'domains/references';
import { useFetchCrossSellingReferences } from 'domains/references/References.requests';
import { getSparePartsView } from 'domains/user';
import { Box, CenteredSpin, MarginBox } from 'UI';
import { notifyTop } from 'UI/Notification/notification';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { getData, hasData } from 'utils';
import { ProductTab } from './ProductTab';

const spinner = () => (
  <Box height={'20vh'}>
    <CenteredSpin size={'large'} />
  </Box>
);

interface ProductProps {
  referenceNumber: string;
  isPopin?: boolean;
  seePlate?: React.ReactNode;
  scrollToSubstitutes?: boolean;
  isVehicleCatalog?: boolean;
  isUniversalProduct?: boolean;
}

export const Product = ({
  referenceNumber,
  isPopin,
  seePlate,
  isVehicleCatalog,
  scrollToSubstitutes,
  isUniversalProduct,
}: ProductProps) => {
  const { query } = useParams<{
    query: string;
  }>();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const reference = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));
  const sparePartsView = useSelector(getSparePartsView);
  const [firstView, setFirstView] = useState(false);
  const params = new URLSearchParams(location.search);
  const plateId = params.get(PlateIdParam);
  const applicability = getData(reference)?.isApplicableToCurrentVehicle;

  useEffect(() => {
    if (query && applicability === false && !firstView) {
      notifyTop(
        'warning',
        <Trans i18nKey={'common.warning'}>Warning</Trans>,
        <Trans i18nKey={'catalog.search.incompatible_reference_notification.description'}>
          This reference is not compatible with the identified vehicle.
        </Trans>,
      );
      setFirstView(true);
    }
  }, [query, applicability, firstView]);

  useFetchSingleReference(referenceNumber, plateId, vehicleKey);
  useFetchCrossSellingReferences([referenceNumber], vehicleKey);

  return (
    <MarginBox mt={30}>
      <DataContainer data={reference} Loading={spinner}>
        {hasData(reference) && (
          <>
            <ViewTabs />
            <MarginBox mx={30} my={30}>
              <ProductTab
                sparePartsView={sparePartsView}
                refLocal={reference}
                query={query}
                isPopin={isPopin}
                seePlate={seePlate}
                isVehicleCatalog={isVehicleCatalog}
                scrollToSubstitutes={scrollToSubstitutes}
                isUniversalProduct={isUniversalProduct}
              />
            </MarginBox>
          </>
        )}
      </DataContainer>
    </MarginBox>
  );
};
