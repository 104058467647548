import React from 'react';

export interface ErrorBoundaryDisplayProps {
  error: string | null;
  stack?: string | null;
  title?: string;
  label?: string;
}

export const ErrorBoundaryDisplay = ({
  error,
  stack,
  title = 'Oops! Something went wrong!',
  label = 'Errors',
}: ErrorBoundaryDisplayProps) => {
  return (
    <div className="error">
      {title}
      {label}: {error}
      {stack && <div className="errorStack">{stack}</div>}
    </div>
  );
};
