/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { hasData, NO_DATA } from 'utils/dataStatus';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { getElementAndSiblings, getSvgTextElementsFromDocument, IndexType, svgTextPosition } from 'utils/svg/common';

const addOrRemoveClass = (element: Element, className: string, add: boolean) => {
  if (add) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
};

const toggleClassOnSiblings = (indexElement: Element, className: string, add: boolean) => {
  const siblings = getElementAndSiblings(indexElement);
  siblings.forEach((element) => {
    addOrRemoveClass(element, className, add);
  });
};

const resolveHighlight = (indexElement: Element, highlightedIndex: IndexType) => {
  const isHighlighted = highlightedIndex === indexElement.innerHTML;
  toggleClassOnSiblings(indexElement, 'highlightedIndexLine', isHighlighted);
};

export const useAddListenersToPlateSvg = (
  plateId: string,
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  scrollOffset: number,
  svgElement: string | JSX.Element | undefined,
  svgElementPlateId: string,
  svgManipulated: boolean,
): void => {
  useEffect(() => {
    const svgTextElement = getSvgTextElementsFromDocument(svgElementPlateId, plateId, svgTextPosition);
    svgTextElement?.forEach((indexElement) => {
      if (!svgManipulated) {
        toggleClassOnSiblings(indexElement, 'hiddenIndexLine', true);
      }
      addListeners(indexElement, setHighlightedIndex, setSelectedIndex, scrollOffset);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plateId, svgElement, svgElementPlateId]);
};

function addListeners(
  indexElement: Element,
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  setSelectedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void,
  scrollOffset: number,
): () => void {
  const indexNumber = indexElement.innerHTML;

  const clickOnIndex = () => {
    const targetElement = document.getElementById('plate-reference-index-' + indexNumber);
    if (targetElement) {
      setSelectedIndex(indexNumber);
      scrollToTopSmooth(targetElement.offsetTop - scrollOffset);
    }
  };

  const mouseOnIndex = () => {
    document.getElementById(`part-list-reference-${indexNumber}`)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
    setHighlightedIndex(indexNumber);
  };

  const mouseOffIndex = () => {
    setHighlightedIndex(null);
  };

  getElementAndSiblings(indexElement).forEach((e) => {
    e.addEventListener('click', clickOnIndex as EventListener);
    e.addEventListener('mouseover', mouseOnIndex as EventListener);
    e.addEventListener('mouseleave', mouseOffIndex);
  });

  return () => {
    getElementAndSiblings(indexElement).forEach((e) => {
      e.removeEventListener('click', clickOnIndex as EventListener);
      e.removeEventListener('mouseover', mouseOnIndex as EventListener);
      e.removeEventListener('mouseleave', mouseOffIndex);
    });
  };
}

const getIndexes = (
  plateReferences: PlateReferencesDetail[] | NO_DATA,
  hideElementsByDefault: boolean,
): Set<number> | undefined => {
  if (hasData(plateReferences)) {
    return new Set(plateReferences.map((p) => p.index));
  }
  return hideElementsByDefault ? new Set() : undefined;
};

export const useManipulateLinesInSvg = (
  plateId: string,
  plateReferences: PlateReferencesDetail[] | NO_DATA,
  highlightedIndex: IndexType,
  svgElement: string | JSX.Element | undefined,
  svgElementPlateId: string,
): boolean => {
  const [configured, setConfigured] = useState(false);
  const numberOfPlateReferneces = hasData(plateReferences) ? plateReferences.length : 0;
  useEffect(() => {
    const configuredWithReferences = manipulateLinesInSvg(
      plateId,
      plateReferences,
      highlightedIndex,
      svgElementPlateId,
      true,
    );
    setConfigured(configuredWithReferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedIndex, numberOfPlateReferneces, plateId, svgElement, svgElementPlateId]);
  return configured;
};

export const manipulateLinesInSvg = (
  plateId: string,
  plateReferences: PlateReferencesDetail[] | NO_DATA,
  highlightedIndex: IndexType,
  svgElementPlateId: string,
  hideElementsByDefault: boolean,
): boolean => {
  const indexSet = getIndexes(plateReferences, hideElementsByDefault);
  if (!indexSet) {
    return false;
  }
  const elements = Array.from(getSvgTextElementsFromDocument(svgElementPlateId, plateId, svgTextPosition) || []);

  // Batch DOM updates using requestAnimationFrame
  requestAnimationFrame(() => {
    elements.forEach((indexElement) => {
      const indexNumber = Number(indexElement.innerHTML);

      const isHidden = !indexSet.has(indexNumber);
      toggleClassOnSiblings(indexElement, 'hiddenIndexLine', isHidden);

      if (!isHidden) {
        resolveHighlight(indexElement, highlightedIndex);
      }
    });
  });
  return true;
};
