/* eslint-disable max-len */
import { CrossSellingFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/response/CrossSellingFileResponse';
import { CrossSellingUploadFileResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/cross_selling/response/CrossSellingUploadFileResponse';
import {
  BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_RESPONSE,
  BACKOFFICE_CROSS_SELLING_GET_FILE_RESPONSE,
  BACKOFFICE_CROSS_SELLING_DELETE_FILE_RESPONSE,
  BACKOFFICE_CROSS_SELLING_GET_FILE_PROGRESS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import {
  CROSS_SELLING_NAMESPACE,
  CrossSellingFileInformation,
  CrossSellingState,
  UPLOAD_FILE,
  REPLACE_FILE,
  CrossSellingFileStatus,
  GET_FILE,
  DELETE_FILE,
} from 'domains/crossSelling/CrossSelling.types';
import { FETCHING_FILE } from 'domains/electronicPartsRepair/ElectronicPartsRepair.types';
import { SEARCH_STATUS } from 'utils';

// Init state
const initialState: CrossSellingState = {
  fileUploadStatus: {
    fileStatus: FETCHING_FILE,
    correctRows: [],
    errorRows: [],
    modalStatus: NO_FILE,
    processFileProgress: 0,
  },
  fileRows: [],
  fileInformation: { fileName: '' },
  fileInformationForRequest: { fileName: '' },
  searchStatus: undefined,
};

// Saga actions
export const uploadFileCrossSellingRequestSaga = createAction<{
  fileBase64: string;
}>(UPLOAD_FILE);
export const uploadFileCrossSellingResponseSaga = createAction(BACKOFFICE_CROSS_SELLING_UPLOAD_FILE_RESPONSE);
export const replaceFileCrossSellingRequestSaga = createAction(REPLACE_FILE);
export const fetchFileCrossSellingRequestSaga = createAction(GET_FILE);
export const fetchFileCrossSellingResponseSaga = createAction(BACKOFFICE_CROSS_SELLING_GET_FILE_RESPONSE);
export const deleteFileCrossSellingRequestSaga = createAction(DELETE_FILE);
export const deleteFileCrossSellingResponseSaga = createAction(BACKOFFICE_CROSS_SELLING_DELETE_FILE_RESPONSE);
export const fetchCrossSellingFileProcessingResponse = createAction(
  BACKOFFICE_CROSS_SELLING_GET_FILE_PROGRESS_RESPONSE,
);

// Slice
const slice = createSlice({
  name: CROSS_SELLING_NAMESPACE,
  initialState,
  reducers: {
    setCrossSellingFileModalStatus: (state, { payload }: PayloadAction<ModalStatusType>) => {
      state.fileUploadStatus.modalStatus = payload;
    },
    setCrossSellingFileUploadStatus: (state, { payload }: PayloadAction<CrossSellingFileStatus>) => {
      state.fileUploadStatus.fileStatus = payload;
    },
    setCrossSellingFileUploadResponse: (state, { payload }: PayloadAction<CrossSellingUploadFileResponse>) => {
      state.fileUploadStatus.correctRows = payload.correctRows;
      state.fileUploadStatus.errorRows = payload.errorRows;
    },
    setCrossSellingFileInformationForRequest: (state, { payload }: PayloadAction<CrossSellingFileInformation>) => {
      state.fileInformationForRequest = payload;
    },
    setCrossSellingFileResponse: (state, { payload }: PayloadAction<CrossSellingFileResponse>) => {
      const { fileName, fileRows } = payload;
      state.fileInformation = {
        fileName,
      };
      state.fileRows = fileRows;
    },
    setCrossSellingSearchFileStatus: (state, { payload }: PayloadAction<SEARCH_STATUS>) => {
      state.searchStatus = payload;
    },
    setDeleteErrorRows: (state) => {
      state.fileUploadStatus.errorRows = [];
    },
    setFileProcessPercent: (state, { payload }: PayloadAction<number>) => {
      state.fileUploadStatus.processFileProgress = payload;
    },
  },
});

export const {
  setCrossSellingFileModalStatus,
  setCrossSellingFileUploadResponse,
  setCrossSellingFileInformationForRequest,
  setCrossSellingFileUploadStatus,
  setCrossSellingFileResponse,
  setCrossSellingSearchFileStatus,
  setDeleteErrorRows,
  setFileProcessPercent,
} = slice.actions;

export const getCrossSellingFileModalStatus = createSelector(
  (state: RootState) => state.crossSelling.fileUploadStatus.modalStatus,
  (modalStatus) => modalStatus,
);
export const getCrossSellingFileUploadStatus = createSelector(
  (state: RootState) => state.crossSelling.fileUploadStatus.fileStatus,
  (fileStatus) => fileStatus,
);

export const getCrossSellingFileInformationForRequest = createSelector(
  (state: RootState) => state.crossSelling.fileInformationForRequest,
  (fileInformation) => fileInformation,
);

export const getCrossSellingFileInformation = createSelector(
  (state: RootState) => state.crossSelling.fileInformation,
  (fileInformation) => fileInformation,
);

export const getCrossSellingFileErrorRows = createSelector(
  (state: RootState) => state.crossSelling.fileUploadStatus.errorRows,
  (errorRows) => errorRows,
);

export const getCrossSellingFileCorrectRows = createSelector(
  (state: RootState) => state.crossSelling.fileUploadStatus.correctRows,
  (correctRows) => correctRows,
);

export const getCrossSellingFileUploadedRows = createSelector(
  (state: RootState) => state.crossSelling.fileRows,
  (fileRows) => fileRows,
);

export const getCrossSellingFileFetchStatus = createSelector(
  (state: RootState) => state.crossSelling.searchStatus,
  (searchStatus) => searchStatus,
);

export const getProcessFileProgress = createSelector(
  (state: RootState) => state.crossSelling.fileUploadStatus.processFileProgress,
  (processFileProgress) => processFileProgress,
);

export default slice.reducer;
