import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getTechnicalCriteriaPdfType } from 'domains/catalog/Catalog.store';
import TechnicalCriteriaPrintPdf from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaPrintPdf';
import { TechnicalCriteria, useGetCriteria } from 'pages/CatalogPage/DH/VehicleTechnicalDetails/TechnicalCriteria';
import { Flex, MarginBox, Tab, Tabs, Text } from 'UI';

const STabs = styled(Tabs)`
  .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue};
    border-radius: 0 0 0 0;
  }
  .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue};
  }
`;

export function VehicleTechnicalDetails() {
  const { t } = useTranslation();
  const type = useSelector(getTechnicalCriteriaPdfType);
  const criteria = useGetCriteria();
  const getItem = () => {
    return criteria.items.find((c) => c.type === type);
  };
  return (
    <>
      <MarginBox mt={30} />
      <Flex justify={'space-between'}>
        <Text type={'h1_banner_light'}>
          {t('catalog.details.vehicle_technical_details', 'Vehicle technical details')}
        </Text>
        <TechnicalCriteriaPrintPdf item={getItem()} />
      </Flex>
      <STabs type={'card'}>
        <Tab key={'1'} tab={t('catalog.details.technical_criteria', 'Technical criteria')}>
          <TechnicalCriteria />
        </Tab>
      </STabs>
    </>
  );
}
