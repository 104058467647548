import React, { FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InputRef, Menu } from 'antd';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  addBundleFromAutocomplete,
  getBundleFulltextAutocomplete,
  sendAutocompleteBundlesRequest,
} from 'domains/estimate/Estimate.store';
import { BundleSearchParams } from 'domains/estimate/Estimate.types';
import { getUserDisplayMode } from 'domains/user';
import FreeBundleAutocompleteMenu from 'pages/EstimatePage/TableSection/FreeBundleAutocompleteMenu';
import { isEmpty } from 'pages/EstimatePage/TableSection/tableComponents';
import { getData, hasData, useFocus } from 'utils';
import { SearchContainer, SSearchInput } from './FreeBundleAutocomplete.styled';

const FreeBundleAutocomplete = ({
  value,
  saveFunction,
  rowId,
}: {
  value: string;
  saveFunction: (value: string) => void;
  rowId: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const userDisplayMode = useSelector(getUserDisplayMode);
  const inputReference = React.useRef<InputRef>(null);
  const menuReference = useRef<Menu>(null);

  const [bundleSearch, setBundleSearch] = useState<BundleSearchParams>({
    query: '',
    pending: false,
    isValid: false,
    open: false,
    focusMenu: undefined,
    searchId: undefined,
  });

  const [bundleInputValue, setBundleInputValue] = useState(value);
  const results = useSelector((state: RootState) => getBundleFulltextAutocomplete(state));
  const resultsLength = getData(results)?.length ?? 0;
  useFocus(inputReference);

  useEffect(() => {
    if (bundleSearch && !bundleSearch.pending && bundleSearch.query && bundleSearch.isValid) {
      dispatch(sendAutocompleteBundlesRequest({ queryString: bundleSearch.query }));
      setBundleSearch({ ...bundleSearch, pending: true });
    }
  }, [dispatch, bundleSearch]);

  const SearchInputID = 'search-bundle-fulltext-id';
  const SearchInputName = 'search-bundle-fulltext-name';

  const onDebouncedChange = useCallback(
    (val: string) => {
      setBundleSearch(
        val?.length > 2
          ? {
              ...bundleSearch,
              query: val.toLowerCase(),
              pending: false,
              isValid: true,
              open: true,
              focusMenu: undefined,
            }
          : {
              ...bundleSearch,
              query: '',
              pending: false,
              isValid: false,
              open: false,
              focusMenu: undefined,
            },
      );
    },
    [bundleSearch],
  );

  const handleClickSelection = useCallback(
    (label: string, id: string, code: string, price: string) => () => {
      if (!label) {
        return;
      }
      const searchId = uuidv4();
      setBundleInputValue(label);
      setBundleSearch({
        query: label,
        pending: false,
        isValid: true,
        open: false,
        focusMenu: undefined,
        searchId,
      });
      const bundle = {
        id,
        code,
        designation: label,
        price,
      };
      dispatch(addBundleFromAutocomplete({ bundle, itemId: rowId }));
    },
    [dispatch, rowId],
  );

  const handleQueryInputValue = handleClickSelection(bundleInputValue.trim(), '', '', '');

  const handleMove = useCallback(
    (offset: number) => {
      if (bundleSearch.open) {
        setBundleSearch({
          ...bundleSearch,
          focusMenu: bundleSearch?.focusMenu ? (bundleSearch.focusMenu + offset) % resultsLength : 0,
        });
      }
    },
    [resultsLength, bundleSearch],
  );

  const handleDown = useCallback(() => handleMove(1), [handleMove]);
  const handleUp = useCallback(() => handleMove(-1), [handleMove]);

  const handleKeyPress = useCallback(
    (event: { key: string }) => {
      if (event.key === 'Escape') {
        setBundleSearch({ ...bundleSearch, open: false, focusMenu: 0 });
      }
      if (event.key === 'ArrowUp') {
        handleUp();
      }
      if (event.key === 'ArrowDown') {
        handleDown();
      }
    },
    [handleDown, handleUp, bundleSearch, setBundleSearch],
  );

  const handleBlurInput = (_val: string | undefined, e?: FocusEvent) => {
    if (bundleSearch?.open && bundleSearch.focusMenu === undefined && !e?.relatedTarget?.id.includes('search-menu')) {
      setBundleSearch({ ...bundleSearch, open: false });
      saveFunction(bundleSearch.query ?? '');
    }
  };

  return (
    <SearchContainer>
      <SSearchInput
        $isInvalid={isEmpty(value)}
        id={SearchInputID}
        name={SearchInputName}
        autoComplete={`on section-${SearchInputName}`}
        value={bundleInputValue}
        onChange={(val) => setBundleInputValue(val)}
        onChangeDebounced={onDebouncedChange}
        onPressEnter={handleQueryInputValue}
        onKeyPress={handleKeyPress}
        onBlur={handleBlurInput}
        displayMode={userDisplayMode}
        color={theme.displayMode[userDisplayMode].searchFontColor}
        passRef={inputReference}
        placeholder={t('estimate.bundles.search_by_description', 'Search bundles')}
      />
      {hasData(results) && results?.length > 0 && bundleSearch?.open && (
        <FreeBundleAutocompleteMenu
          results={results}
          search={bundleSearch}
          setSearch={setBundleSearch}
          onClick={handleClickSelection}
          inputValue={bundleInputValue}
          menuRef={menuReference}
        />
      )}
    </SearchContainer>
  );
};

export default FreeBundleAutocomplete;
