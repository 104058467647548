// eslint-disable-next-line
import { History } from 'history';

declare global {
  interface Window {
    _uxa: any;
  }
}

export default class ContentsquareReact {
  private previousPath: any;
  private unlistenFromHistory: any;

  constructor() {
    this.previousPath = null;
    this.initContentsquare();
  }

  static isNotTracked(text: string): boolean {
    return text.search('code|idp|oauth2') > -1;
  }

  initContentsquare(): any {
    if (typeof window !== 'undefined') {
      window._uxa = window._uxa || []; // eslint-disable-line  no-underscore-dangle
      let loc = window.location.pathname + window.location.hash.replace('#', '?__');
      if (ContentsquareReact.isNotTracked(loc)) {
        //override auth urls - change value before ?
        loc = '/idp';
        //change value after ?
        window._uxa.push(['setQuery', '?auth=yes']);
      }
      window._uxa.push(['setPath', loc]);
      const mt = document.createElement('script');
      mt.type = 'text/javascript';
      mt.async = true;
      mt.src = 'https://t.contentsquare.net/uxa/3ac679436e913.js';
      document.getElementsByTagName('head')[0].appendChild(mt);
      window._uxa.push(['setQuery', '']);
    }

    return {
      push: ContentsquareReact.push,
      track: this.track,
      connectToHistory: this.connectToHistory,
      disconnectFromHistory: this.disconnectFromHistory,
    };
  }

  static push(args: string[]): any {
    window._uxa.push(args); // eslint-disable-line  no-underscore-dangle
  }

  /**
   * @param {History} history
   * @param {Boolean} trackAtConnect
   */
  connectToHistory(history: History | any): any {
    const initialLocation =
      typeof history.getCurrentLocation === 'undefined' ? history.location : history.getCurrentLocation();
    const initialPath = initialLocation.path || (initialLocation.pathname + initialLocation.search).replace(/^\//, '');

    this.track(initialPath);

    this.unlistenFromHistory = history.listen((loc: any) => {
      // History version 5 sendes update as a object
      if (loc['location'] !== null) {
        if (loc.location) {
          this.track(loc.location);
        } else {
          this.track(loc.pathname + loc.search);
        }
      } else {
        this.track(loc);
      }
    });

    return history;
  }

  disconnectFromHistory(): any {
    if (this.unlistenFromHistory) {
      this.unlistenFromHistory();
      return true;
    }
    return false;
  }

  track(loc: string | { path: string; pathname: string; search: string } | Location): any {
    if (typeof window === 'undefined') {
      return;
    }
    if (loc === undefined || loc === '') {
      return;
    }

    let currentPath = '';
    if (typeof loc === 'string') {
      currentPath = loc;
    } else {
      currentPath = loc.pathname + loc.search;
    }

    currentPath = currentPath.replace(/^\//, '');

    if (currentPath === '') {
      // empty string - url is not tracked by artificial pageview
      currentPath = '/';
    }

    if (ContentsquareReact.isNotTracked(currentPath)) {
      return;
    }
    if (this.previousPath === currentPath) {
      return;
    }
    //save actual url also with search param
    this.previousPath = currentPath;

    ContentsquareReact.push(['trackPageview', currentPath]);
  }
}
