import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getTokenUser, getUserCommercialLink } from 'domains/user';
import { Box, Flex, Image, Text, WithTooltip } from 'UI';

const SFlex = styled(Flex)`
  padding: 0 20px 0 20px;
`;

export const TextWrapper = styled(Flex)`
  overflow: hidden;
`;

const getRandomAvatar = () => `avatars/profile_${(window.crypto.getRandomValues(new Uint8Array(1))[0] % 8) + 1}.png`;

const ProfileInfo = ({ mini }: { mini: boolean }) => {
  const [avatar, setAvatar] = useState(getRandomAvatar);
  const userInfo = useSelector(getTokenUser);
  const commercialLink = useSelector(getUserCommercialLink);
  const height = 70;
  const width = mini ? 90 : 250;

  useEffect(() => {
    setAvatar(getRandomAvatar());
  }, [userInfo]);

  return (
    <SFlex
      maxHeight={height}
      minHeight={height}
      justify={mini ? 'center' : 'flex-start'}
      align={'center'}
      minWidth={width}
      maxWidth={width}
      gap={12}
    >
      <Box width={44}>
        <Image alt={'avatar'} src={avatar} width={44} />
      </Box>
      {!mini && (
        <TextWrapper direction={'column'}>
          <WithTooltip title={commercialLink?.name ?? ''} placement={'right'}>
            <Text type={'light_10_black_65'} noWrap ellipsis>
              {commercialLink?.name ?? ''}
            </Text>
          </WithTooltip>
          <Text type={'text_dim'}>{userInfo?.id ?? ''}</Text>
        </TextWrapper>
      )}
    </SFlex>
  );
};

export default ProfileInfo;
