import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Popover } from 'antd';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { ArrowLeftIcon } from 'assets/icons';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { VehicleTabContentBase } from 'pages/CatalogPage/common';
import { SButton } from 'UI/Button/BackButton.styled';
import { Icon } from 'UI/Icon';
import { Text } from 'UI/Text';
import { isQueryBMM, useSmall } from 'utils';
import { Flex } from '../Flex';

export const CurrentVehicleButton = ({ currentVehicle }: { currentVehicle: VehicleDetail }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const small = useSmall();
  const isBMM = isQueryBMM(currentVehicle.vehicleKey);

  const renderContent = () => {
    return (
      <Flex>
        <VehicleTabContentBase
          result={currentVehicle}
          vehicleImage={currentVehicle.imageUrl}
          isBMM={isBMM}
          isPopover={true}
        />
      </Flex>
    );
  };

  const handleClick = () => {
    history.push(
      `${getCatalogSourceUrl(currentVehicle.catalogSource)}/${currentVehicle.vehicleKey}${ROUTER_CATALOG_VEHICLE}`,
    );
  };

  return (
    <SButton direction={'row'} align={'center'} justify={'flex-start'} onClick={handleClick}>
      <Popover placement={'right'} content={renderContent()}>
        <Icon IconComponent={ArrowLeftIcon} size={14} mr={10} mb={1} />
        {!small && (
          <Text disableGutter type={'h6'} cursor={'pointer'}>
            {t('common.action.back_to_current_vehicle', 'Back to current vehicle')}
          </Text>
        )}
      </Popover>
    </SButton>
  );
};
