import React, { useState } from 'react';
import { Select as AntSelect } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

export type SelectOptionSingle = {
  value: string;
  title: string;
};

export type SelectOptionGroup = { title: string; options: SelectOptionSingle[] };

export type SelectOption = SelectOptionSingle | SelectOptionGroup;

export function isOptionGroup(selectOption: SelectOption): selectOption is SelectOptionGroup {
  return 'options' in selectOption;
}

const renderSingleOption = (item: SelectOptionSingle) => (
  <AntSelect.Option key={item.value} value={item.value}>
    {item.title}
  </AntSelect.Option>
);

export interface SelectProps<T> {
  options?: SelectOption[];
  initialValue?: T;
  value?: T;
  onChange: (value: T, option?: any) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: 'middle' | 'large';
  search?: boolean;
  bordered?: boolean;
  alignLeft?: boolean;
  passRef?: React.Ref<RefSelectProps>;
  allowClear?: boolean;
  onClear?: () => void;
  openDropdown?: boolean;
  autoOpen?: boolean;
  notFoundContent?: React.ReactNode;
  className?: string;
  styleLess?: boolean;
  mode?: 'multiple' | 'tags';
}

export function Select<T>({
  placeholder,
  onChange,
  options,
  initialValue,
  bordered = false,
  disabled = false,
  value,
  search,
  alignLeft = false,
  size = 'middle',
  onClear,
  passRef,
  allowClear = false,
  autoOpen,
  notFoundContent,
  className,
  styleLess,
  mode,
}: SelectProps<T>) {
  // TODO style padding using Less
  const [open, setOpen] = useState(false);

  return (
    <AntSelect
      getPopupContainer={(trigger) => trigger.parentElement ?? trigger}
      mode={mode}
      className={className}
      onChange={
        autoOpen
          ? (val) => {
              onChange(val);
              setOpen(false);
            }
          : onChange
      }
      placeholder={placeholder}
      defaultValue={initialValue}
      style={
        styleLess
          ? {
              width: '100%',
              textAlign: alignLeft ? 'left' : undefined,
              marginTop: 0,
              marginBottom: 0,
            }
          : {
              width: '100%',
              textAlign: alignLeft ? 'left' : undefined,
              marginTop: `${bordered ? 4 : 5}px`,
              marginBottom: '1px',
            }
      }
      size={size}
      bordered={bordered}
      disabled={disabled}
      value={value}
      showSearch={search}
      optionFilterProp={'children'}
      filterOption={(input, option) => {
        if (input && option && option.children) {
          return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
      }}
      allowClear={allowClear}
      onClear={onClear}
      ref={passRef}
      open={autoOpen ? open : undefined}
      onFocus={
        autoOpen
          ? () => {
              setOpen(true);
            }
          : undefined
      }
      onBlur={autoOpen ? () => setOpen(false) : undefined}
      onClick={
        autoOpen && open && options && options.length === 1 && value
          ? () => {
              onChange(value);
              setOpen(false);
            }
          : undefined
      }
      notFoundContent={notFoundContent}
      virtual={false}
    >
      {options?.map((item) =>
        isOptionGroup(item) ? (
          <AntSelect.OptGroup label={item.title} key={item.title}>
            {item.options.map(renderSingleOption)}
          </AntSelect.OptGroup>
        ) : (
          renderSingleOption(item)
        ),
      )}
    </AntSelect>
  );
}
