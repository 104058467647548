/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFileCrossSellingRequestSaga,
  getCrossSellingFileFetchStatus,
} from 'domains/crossSelling/CrossSelling.store';

export const useFetchCrossSelling = (): void => {
  const dispatch = useDispatch();
  const status = useSelector(getCrossSellingFileFetchStatus);

  useEffect(() => {
    if (!status) dispatch(fetchFileCrossSellingRequestSaga());
  }, [dispatch, status]);
};
