import { PopoverProps } from 'antd/lib/popover';
import styled from 'styled-components';
import { AntdPopoverWrapper } from './Popover';

export const SPopover = styled(AntdPopoverWrapper)<{ padding?: number; arrowColor?: string } & PopoverProps>`
  .ant-popover-arrow-content,
  .ant-popover-arrow-content::before {
    background: ${({ theme, arrowColor }) => arrowColor ?? theme.color.brand_black};
  }

  .ant-popover-inner {
    box-shadow: 0 5px 10px 0 ${({ theme }) => theme.color.grey50};
    border-radius: 4px;
  }

  .ant-popover-title {
    background: ${({ theme }) => theme.color.brand_black};
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    padding: 11px 15px 11px 15px;
  }

  .ant-popover-inner-content {
    padding: ${({ padding = 0 }) => padding + 'px'};
  }
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  color: white;
`;
