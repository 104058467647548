import React from 'react';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { SeasonsIcon, SnowflakeIcon, SunIcon } from 'assets/icons';
import { SUMMER, SUMMER_WINTER, WINTER } from 'domains/tires/Tire.types';
import { theme } from 'styles';
import { Icon } from 'UI';

export const TireSeasonIcon = ({ season }: { season: Season | undefined }) => {
  switch (season) {
    case SUMMER:
      return <Icon size={18} IconComponent={SunIcon} color={theme.color.brand_black} noPointer />;
    case WINTER:
      return <Icon size={18} IconComponent={SnowflakeIcon} color={theme.color.brand_black} noPointer />;
    case SUMMER_WINTER:
      return <Icon size={18} IconComponent={SeasonsIcon} color={theme.color.brand_black} noPointer />;
    default:
      return <></>;
  }
};
