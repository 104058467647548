import React from 'react';
import { Trans } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { Document, Page, Text as PdfText, View } from '@react-pdf/renderer';
import {
  Flex,
  LeadText,
  NewLineSplit,
  PageNumber,
  Spacer,
  Table,
  Text,
  Title,
  VerticalSpacer,
} from 'components/Pdf/PdfUI';
import { styles } from 'components/Pdf/PdfUI.styles';
import { getDeliveryTypeLabel } from 'domains/order/OrderDelivery';
import { OrderStatusPdf } from 'domains/order/OrderStatus';
import { textFormatter } from 'utils/format';
import { OrdersListPdfData } from './MyOrdersListPdfPage';

const Header = ({ data }: { data: OrdersListPdfData }) => (
  <View>
    <Title>{data.text.title}</Title>
    <LeadText>{`Date: ${textFormatter.formatDate(data.date)}`}</LeadText>
  </View>
);

const VehicleContext = ({ vehicleDetail }: { vehicleDetail: VehicleDetail | undefined }) => {
  if (vehicleDetail) {
    return (
      <Flex>
        <PdfText>
          {vehicleDetail.vin
            ? vehicleDetail.dataHubVehicle?.name
            : `${vehicleDetail.dataHubVehicle?.name} ${vehicleDetail.dataHubVehicle?.modelType}`}
        </PdfText>
        <NewLineSplit />
        <PdfText>
          <Trans i18nKey={'catalog.vehicle.vin'}>VIN</Trans>: {vehicleDetail.vin ? vehicleDetail.vin : '-'}
        </PdfText>
      </Flex>
    );
  }
  return <Trans i18nKey={'order.order_detail.no_vehicle_context'}>No vehicle context</Trans>;
};

export const MyOrdersListPdfPage = ({ data }: { data: OrdersListPdfData }) => (
  <Document>
    <Page size={'A4'} style={styles.page} wrap={false}>
      <PageNumber />
      <Header data={data} />
      <Spacer />
      <Table
        rowscount={[22, 24]}
        data={data.selectedOrders}
        columns={[
          {
            title: data.text.orderNumber,
            render: (item) => (
              <View>
                <PdfText>
                  {item.orderStatus === 'MAYBE_PLACED' ? (
                    <Trans i18nKey="my_orders.details.order_processing_after_timeout">Processing</Trans>
                  ) : (
                    Number(item.externalOrderId ?? item.internalOrderId)
                  )}
                </PdfText>
                <NewLineSplit />
                <PdfText>
                  <Trans count={item.orderedReferences.length} i18nKey="my_orders.pdf.number_of_references_in_order">
                    {'{{count}} references'}
                  </Trans>
                </PdfText>
              </View>
            ),
            width: 72,
          },
          {
            title: data.text.vehicle,
            render: (item) => <VehicleContext vehicleDetail={item.vehicleDetail} />,
            width: 72,
          },
          {
            title: data.text.orderDate,
            render: (item) => {
              const date = new Date(item.orderDate);
              const language = window.navigator.language;
              return (
                <Flex>
                  <Text>{date.toLocaleDateString(language, { day: 'numeric', month: 'short', year: 'numeric' })}</Text>
                  <NewLineSplit />
                  <Text>
                    {date.toLocaleTimeString(language, { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' })}
                  </Text>
                </Flex>
              );
            },
            width: 66,
          },
          {
            title: data.text.price,
            render: (item) => (
              <Flex>
                <Text>{textFormatter.formatCurrency(item.priceVatExcluded, item.currency)}</Text>
                <VerticalSpacer />
                <Text>
                  <Trans i18nKey={'my_orders.pdf.vat_excluded'}>VAT. Excl</Trans>
                </Text>
              </Flex>
            ),
            width: 69,
          },
          {
            title: data.text.delivery,
            render: (item) => <Text>{getDeliveryTypeLabel(item.deliveryType)}</Text>,
            width: 69,
          },
          {
            title: data.text.status,
            render: (item) => <OrderStatusPdf orderStatus={item.presentableOrderStatus} />,
            width: 69,
          },
          {
            title: data.text.orderBy,
            render: (item) => `${item.userInfo.firstName} ${item.userInfo.lastName}`,
            width: 69,
          },
          {
            title: data.text.orderLine,
            render: (item) => item.orderMark ?? '',
            width: 69,
          },
        ]}
      />
    </Page>
  </Document>
);
