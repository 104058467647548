import React from 'react';
import { Table } from 'antd';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Country } from 'utils/i18n/Country';

export const CookiesDE = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <Flex direction={'column'}>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Text type={'h5_bold'}>COOKIES-RICHTLINIEN</Text>
          </Flex>
          <MarginBox mt={30} />
        </Flex>
        <Paragraph>
          Unsere Websites und Apps verwenden Technologien wie Cookies, um Ihnen ein hochwertiges, flüssiges und
          personalisiertes Erlebnis zu bieten. Die Informationen in dieser Richtlinie ergänzen die Informationen zum
          Schutz personenbezogener Daten.
        </Paragraph>
        <Paragraph>
          Der Schutz Ihrer Online-Daten ist für Renault eine Priorität. Sie können Ihre Entscheidungen in Bezug auf
          Cookies jederzeit ändern, indem Sie unser Tool zur Verwaltung von Cookies nutzen: MEINE WAHLEN VERWALTEN.
        </Paragraph>
        <Section>
          <SectionHeader title={'WER SIND WIR?'} />
          <Paragraph>
            Renault Deutschland AG und Renault SAS sind die unabhängigen Inhaber der Daten, die durch die Cookies und
            andere Tracker, die auf Ihrem Gerät gelesen oder platziert werden, gesammelt werden (im Folgenden
            &quot;wir&quot;, &quot;uns&quot;, &quot;unser&quot;).
          </Paragraph>
          <Paragraph>
            Um mehr zu erfahren, laden wir Sie ein, die Informationen zum Schutz personenbezogener Daten zu lesen{' '}
            <Link to={ROUTER_PERSONAL_DATA(Country.DE.key)} newTab>
              {`https://rpartstore.renault.com${ROUTER_PERSONAL_DATA(Country.DE.key)}`}
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WAS SIND COOKIES?'} />
          <Paragraph>
            Ein Cookie ist eine kleine Computerdatei, ein Tracker, der beim Besuch einer Website, bei der Nutzung einer
            mobilen App, beim Lesen einer E-Mail, bei der Installation oder Nutzung von Software, unabhängig von der Art
            des verwendeten Endgeräts (Computer, Smartphone, digitales Lesegerät, Videospielkonsole mit
            Internetanschluss usw.), hinterlegt und gelesen wird.
          </Paragraph>
          <Paragraph>
            Beim Besuch einer Website können Cookies Informationen an den Browser des Nutzers senden und es dem Browser
            ermöglichen, Informationen an die ursprüngliche Website zurückzusenden (z. B. eine Sitzungs-ID oder die Wahl
            der Sprache).
          </Paragraph>
          <Paragraph>
            Cookies ermöglichen es uns nicht, Sie direkt zu identifizieren, sondern nur, spezifische Informationen über
            Ihr Gerät, Ihren Browser, Ihre Interaktionen mit der von Ihnen verwendeten Website oder App und in einigen
            Fällen Ihre geografische Position zu sammeln.
          </Paragraph>
          <Paragraph>
            Die verwendeten Technologien können mehrere der von Ihnen verwendeten Geräte miteinander verbinden, um mit
            hinreichender Wahrscheinlichkeit festzustellen, dass diese Geräte Ihnen gehören.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WOFÜR WERDEN COOKIES AUF DIESER WEBSITE VERWENDET?'} />
          <Paragraph>Diese Websiteverwendet verschiedene Arten von Cookies.</Paragraph>
          <Section>
            <SectionHeader title={'Technische Cookies'} underline />
            <Paragraph>
              Diese Cookies dienen der Ermöglichung oder Erleichterung der elektronischen Kommunikation (Erkennung von
              Verbindungsfehlern, Identifizierung von Verbindungspunkten, Sicherheit der Website usw.) oder der
              Bereitstellung von Online-Kommunikationsdiensten, die Sie möglicherweise ausdrücklich anfordern
              (Präferenzen für die Bildschirmanzeige, Speicherung der in die Formulare eingegebenen Informationen,
              Einkaufswagen (falls zutreffend), Zugang zu Ihrem Benutzerkonto usw.).
            </Paragraph>
            <Paragraph>Diese Cookies sind für das ordnungsgemäße Funktionieren unserer Website erforderlich.</Paragraph>
            <Paragraph>
              Sie können der Verwendung dieser Cookies nicht widersprechen und Ihre Zustimmung ist nicht erforderlich,
              da die Dienste auf unserer Website auf diese Cookies angewiesen sind.
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'Analytische Cookies'} />
            <Paragraph>
              Diese Cookies dienen der Analyse des Website-Verkehrs, der Analyse des Nutzererlebnisses oder einfach der
              Sammlung aggregierter Signale über den Besuchsfluss auf der Website (z. B. Wahl der Sprache, zuvor
              besuchte Seite, Zeitstempel des Besuchs, Wahl der Cookies).
            </Paragraph>
            <Paragraph>
              Diese Cookies können Daten verarbeiten, die sich auf die besuchten Seiten, die Verweildauer auf den
              besuchten Seiten, den von Ihnen zum Besuch dieser Seiten verwendeten Browser, Ihre Nutzung des Chats oder
              Chatbots auf unserer Website usw. beziehen.
            </Paragraph>
            <Paragraph>
              Die von diesen Cookies gesammelten Daten können auch verwendet werden, um Algorithmen und Profile zu
              erstellen, um Ihre potenziellen Interessen und Präferenzen für unsere Produkte und Dienstleistungen zu
              analysieren. Wir können diese Daten mit anderen Informationen kombinieren, z. B. mit Informationen aus
              unserer Kundendatenbank (weitere Informationen zur Profilerstellung und wie Sie einer solchen
              Profilerstellung widersprechen können, finden Sie in unseren Informationen zum Schutz personenbezogener
              Daten{' '}
              <Link to={ROUTER_PERSONAL_DATA(Country.DE.key)} newTab>
                {`https://rpartstore.renault.com${ROUTER_PERSONAL_DATA(Country.DE.key)}`}
              </Link>
              ).
            </Paragraph>
            <Paragraph>
              Die Verwendung und Speicherung dieser Cookies unterliegen grundsätzlich Ihrer Einwilligung.
            </Paragraph>
            <Paragraph>
              Unter bestimmten Bedingungen können einige Technologien nur technische Signale sammeln, ohne Sie
              persönlich zu identifizieren oder Ihren Browserverlauf zu verfolgen, ohne dass Ihre Zustimmung
              erforderlich ist, da sie nur zur Erstellung anonymer Statistiken zur Messung des Publikums verwendet
              werden.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'WELCHE COOKIES WERDEN AUF DIESER WEBSITE VERWENDET?'} />
          <Paragraph>
            Die beigefügte Tabelle enthält eine Liste aller von dieser Website gespeicherten und/oder verwendeten
            Cookies und deren Hauptmerkmale. Wann immer möglich, werden diese Cookies auf Servern im Europäischen
            Wirtschaftsraum (EWR) gehostet. Da jedoch einige unserer Dienstleister in Ländern außerhalb des EWR ansässig
            sind, werden Ihre personenbezogenen Daten in diesen Ländern verarbeitet, in denen möglicherweise andere
            Vorschriften für personenbezogene Daten gelten als in der Europäischen Union. In einem solchen Fall (i)
            achten wir genau darauf, dass diese Übermittlung im Einklang mit den geltenden Vorschriften erfolgt, und
            (ii) treffen wir Vorkehrungen, die ein angemessenes Schutzniveau für Ihre Daten gewährleisten (insbesondere
            durch Verwendung der Standardvertragsklauseln der Europäischen Kommission).
          </Paragraph>
          <Paragraph>
            Unsere Website kann Cookies von Dritten (Kommunikationsagenturen, Analysedienste usw.) enthalten, die es
            ihnen ermöglichen, während der Lebensdauer der Cookies Informationen über die Navigation auf unserer Website
            zu sammeln. Die Speicherung und Verwendung dieser Cookies durch Dritte unterliegt den Datenschutzrichtlinien
            dieser Dritten. Wir informieren Sie über den Zweck der Cookies von Dritten auf unserer Website sowie über
            die Mittel, die Ihnen zur Verfügung stehen, um Entscheidungen über diese Cookies zu treffen.
          </Paragraph>
          <Table
            pagination={false}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Partnereigentümer des Cookies',
                dataIndex: 'site',
                key: 'site',
              },
              { title: 'Zweck DES COOKIES', dataIndex: 'type', key: 'type' },
              {
                title: 'Cookie-Richtlinie',
                dataIndex: 'guideline',
                key: 'guideline',
                render: (guideline) => (
                  <Link to={guideline} external newTab>
                    Cookie-Richtlinie
                  </Link>
                ),
              },
            ]}
            dataSource={[
              {
                id: 0,
                site: 'Content square',
                type: 'Analytik',
                guideline: 'https://contentsquare.com/fr-fr/privacy-center/cookie-policy/',
              },
              {
                id: 1,
                site: 'Dynatrace',
                type: 'Analytik',
                guideline: 'https://www.dynatrace.com/company/trust-center/privacy/',
              },
              {
                id: 2,
                site: 'Matomo',
                type: 'Analytik',
                guideline: 'https://matomo.org/privacy-policy/',
              },
              {
                id: 3,
                site: 'Renault',
                type: 'Technisch ',
                guideline: 'https://www.renault.fr/cookies.html',
              },
            ]}
          />
        </Section>
        <Section>
          <SectionHeader title={'WIE WIRD IHRE EINWILLIGUNG EINGEHOLT?'} />
          <Paragraph>
            Wenn Sie unsere Website zum ersten Mal besuchen, werden Sie aufgefordert, der Verwendung von Cookies
            zuzustimmen oder sie abzulehnen oder bestimmte Entscheidungen über die Verwendung von Cookies zu treffen,
            indem ein spezielles Informationsbanner angezeigt wird.
          </Paragraph>
          <Paragraph>
            Sie können Ihre Einwilligung jederzeit widerrufen oder Ihre Auswahl ändern (z.B., indem Sie nur bestimmte
            Arten von Cookies akzeptieren) mit unserem speziellen Tool.
          </Paragraph>
          <Paragraph>
            Bitte beachten Sie, dass Cookies, die für das Funktionieren der Website erforderlich sind, gemäß den
            Vorschriften nicht Ihrer Zustimmung unterliegen und daher in unserem Cookie-Management-Tool nicht
            deaktiviert werden können. Sie können ihre Verwendung verhindern, indem Sie die Einstellungen Ihres Browsers
            ändern (indem Sie den Abschnitt &quot;Ablehnung von Cookies&quot; unten befolgen), aber die Qualität Ihrer
            Erfahrung auf der Website kann darunter leiden.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WIE LANGE WIRD IHRE EINWILLIGUNG GESPEICHERT?'} />
          <Paragraph>
            Wenn Sie Cookies auf unserer Website ganz oder teilweise akzeptieren oder ablehnen, wird Ihre Entscheidung
            für einen Zeitraum von höchstens 6 Monaten gespeichert und aufbewahrt (und wir werden Ihre Präferenzen
            aufzeichnen). Das bedeutet, dass wir Sie bei einem späteren Besuch unserer Website während dieses Zeitraums
            nicht zu einer Handlung in Bezug auf Cookies auffordern werden, da wir eine Aufzeichnung Ihrer gespeicherten
            Einstellungen haben.
          </Paragraph>
          <Paragraph>
            Nach Ablauf dieser Frist werden wir Sie erneut bitten, der Verwendung von Cookies zuzustimmen oder sie
            abzulehnen.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WIE KÖNNEN SIE COOKIES VERWALTEN?'} />
          <SectionHeader title={'Wie lehnen Sie Cookies ab?'} underline />
          <Paragraph>
            Wenn Sie nicht möchten, dass Cookies auf Ihrem Gerät gespeichert oder gelesen werden, wird ein
            Ablehnungs-Cookie auf Ihrem Gerät gespeichert, damit wir die Tatsache festhalten können, dass Sie der
            Verwendung von Cookies widersprochen haben. Wenn Sie dieses Ablehnungs-Cookie löschen, können wir nicht mehr
            erkennen, dass Sie der Verwendung und Speicherung von Cookies widersprochen haben (was bedeutet, dass Sie
            Ihre Einstellungen beim nächsten Besuch unserer Website erneut vornehmen müssen).
          </Paragraph>
          <Paragraph>
            Sie haben mehrere Möglichkeiten, Cookies zu verwalten, einschließlich der Blockierung ihrer Verwendung
            und/oder ihrer Löschung.
          </Paragraph>
          <SectionHeader title={'Cookie-Management-Tool'} underline />
          <Paragraph>
            Es wird ein Cookie-Tool bereitgestellt, mit dem Sie Ihre Cookie-Präferenzen verwalten können, um die auf
            dieser Website gespeicherten und/oder verwendeten Cookies zu verwalten. Mit einem Klick auf den
            untenstehenden Link können Sie jederzeit auf den Konfigurator zugreifen und Ihre Präferenzen je nach Art des
            Cookies ändern.
          </Paragraph>
        </Section>
        <Section>
          <Flex align={'center'}>
            <SectionHeader title={'VERWALTEN SIE DIE COOKIES'} />
          </Flex>
          <SectionHeader title={'Browser-Einstellungen'} underline />
          <Paragraph>Sie können Cookies auch über Ihren Browser verwalten.</Paragraph>
          <Paragraph>
            Die meisten Browser sind standardmäßig so eingestellt, dass sie Cookies akzeptieren, aber wenn Sie möchten,
            können Sie wählen, ob Sie alle Cookies akzeptieren, Cookies systematisch ablehnen oder welche Cookies Sie
            akzeptieren, je nachdem, woher sie stammen. Sie können Cookies auch regelmäßig über Ihren Browser von Ihrem
            Gerät löschen.
          </Paragraph>
          <Paragraph>
            Vergessen Sie jedoch nicht, alle Browser auf Ihren verschiedenen Geräten (Tablets, Smartphones, Computer
            usw.) einzurichten.
          </Paragraph>
          <Paragraph>
            Jeder Browser hat ein anderes Setup für die Verwaltung von Cookies und Ihre Auswahlmöglichkeiten. Es wird im
            Hilfemenü Ihres Browsers beschrieben, in dem Sie erfahren, wie Sie Ihre Cookie-Einstellungen ändern können.
            Zum Beispiel:
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>
                  für Internet Explorer™:{' '}
                  <Link to={'https://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies'} external newTab>
                    https://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Safari™:{' '}
                  <Link to={'https://support.apple.com/de-de/safari'} external newTab>
                    https://support.apple.com/de-de/safari
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Chrome™:{' '}
                  <Link
                    to={'https://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647'}
                    external
                    newTab
                  >
                    https://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Firefox™:{' '}
                  <Link to={'https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen'} external newTab>
                    https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
                  </Link>
                  ;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  für Opera™:{' '}
                  <Link to={'https://help.opera.com/Windows/10.20/de/cookies.html'} external newTab>
                    https://help.opera.com/Windows/10.20/de/cookies.html
                  </Link>
                  .
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'Verlagsmodule für Einwände'} underline />
          <Paragraph>
            Sie können einige Cookies von Drittanbietern deaktivieren, indem Sie direkt auf die Seite des Ausstellers
            des Cookies gehen. Auf diese Weise:
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>
                  Um Google Analytics-Cookies zu deaktivieren, gehen Sie auf die Seite,{' '}
                  <Link to={'https://tools.google.com/dlpage/gaoptout?hl=de'} external newTab>
                    https://tools.google.com/dlpage/gaoptout?hl=de
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Um intelliAd-Cookies zu deaktivieren, gehen Sie auf die Seite:{' '}
                  <Link to={'https://login.intelliad.com/optout.php'} external newTab>
                    https://login.intelliad.com/optout.php
                  </Link>
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Um Adobe-Cookies zu deaktivieren, gehen Sie auf die Seite:{' '}
                  <Link to={'https://www.adobe.com/de/privacy/opt-out.html'} external newTab>
                    https://www.adobe.com/de/privacy/opt-out.html
                  </Link>
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'WIE KÖNNEN SIE IHRE ANDEREN RECHTE AUSÜBEN?'} />
          <Paragraph>
            In Übereinstimmung mit den geltenden Gesetzen zum Schutz personenbezogener Daten haben Sie das Recht auf
            Zugang, Berichtigung, Widerspruch, Einschränkung und Löschung Ihrer personenbezogenen Daten. Um Ihre anderen
            Rechte auszuüben, konsultieren Sie bitte unsere Informationen zum Schutz Ihrer personenbezogenen Daten,
            indem Sie auf den folgenden{' '}
            <Link to={ROUTER_PERSONAL_DATA(Country.DE.key)} newTab>
              Link klicken
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'ÄNDERUNGEN'} />
          <Paragraph>
            Wir können diese Richtlinie von Zeit zu Zeit ändern. Wir werden Sie informieren und/oder Ihre Zustimmung
            einholen, wann immer dies erforderlich oder erforderlich ist. Wir empfehlen Ihnen daher, diese Richtlinie
            bei jedem Besuch unserer Website zu konsultieren, um die neueste Version zu überprüfen.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Aktualisiert am 19. Juni 2023</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
