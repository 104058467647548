import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { useTheme } from 'styled-components';
import { Ban } from 'assets/icons';
import { Flex, Icon, Text } from 'UI';
import { ERROR, LOADING, SearchData } from 'utils';

export function isEmptyStock(stock: SearchData<ReferenceStock> | undefined): boolean {
  if (stock === undefined || stock?.searchStatus === LOADING || stock?.searchStatus === ERROR) {
    return false;
  }
  const length = stock?.data?.warehouses?.length;
  return length !== undefined ? length === 0 : true;
}

export function EmptyStock() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Flex align={'center'} gap={5}>
      <Icon IconComponent={Ban} size={20} color={theme.color.error} noPointer />
      <Text type={'light_14_black_65'} displayStyle={'error'}>
        {t('catalog.reference.stock.out_of_stock', 'Out of stock:')}
      </Text>
      <Text type={'light_14_black_65'}>
        {t('catalog.reference.stock.out_of_stock.part_unavailable', 'Part actually unavailable')}
      </Text>
    </Flex>
  );
}
