import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { BusinessError } from '@1po/1po-bff-fe-spec/generated/error/BusinessError';
import { FileResponse } from '@1po/1po-bff-fe-spec/generated/files/FileResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import * as actions from 'domains/downloadFiles/Files.store';
import { setDownloadingFile, setFile, setTimeoutDownloadingFile } from 'domains/downloadFiles/Files.store';
import { notifyTop } from 'UI';
import { AppTranslation, decodeBase64, ELEVEN_SEC, mapQueryWsResponseToSearchData, sagaGuard, SearchData } from 'utils';

const fileTimeouts: Record<string, NodeJS.Timeout> = {};

function notificationSuccess(response: SearchData<FileResponse>) {
  notifyTop(
    'success',
    AppTranslation.t('common.file_saga.file_has_been_downloaded.title', 'File has been downloaded'),
    AppTranslation.t(
      'common.file_saga.file_with_name_has_been_downloaded.description',
      'Check your downloaded file {{fileName}}',
      {
        fileName: response?.data?.fileName,
      },
    ),
  );
}

function notificationError() {
  notifyTop(
    'error',
    AppTranslation.t('common.file_saga.download_error.title', 'Error while downloading file'),
    AppTranslation.t('common.file_saga.download_error.description', `Unable to download file. Please, try it again`),
  );
}

/**
 * Sets a timeout for the file download operation.
 */
function* setDownloadingFileTimeout(action: { fileKey: string; timeout: number }): SagaIterator {
  const { fileKey, timeout = 5000 } = action; // Apply default value here

  yield call(
    () =>
      new Promise((resolve) => {
        const timeoutId = setTimeout(resolve, timeout);
        fileTimeouts[fileKey] = timeoutId; // Register the timeout
      }),
  );

  cancelTimeout(fileKey);
  yield put(setTimeoutDownloadingFile(fileKey));
}

/**
 * Cancels the timeout for a given fileKey.
 * @param fileKey - The key representing the file whose timeout should be cleared.
 */
function cancelTimeout(fileKey: string): void {
  const timeoutId = fileTimeouts[fileKey];
  if (timeoutId) {
    clearTimeout(timeoutId);
    delete fileTimeouts[fileKey]; // Remove from the map after clearing
  }
}

function* fileDownloadRequestSaga({ payload }: ReturnType<typeof actions.fileDownloadRequestSaga>): SagaIterator {
  const { fileKey, timeout = ELEVEN_SEC } = payload; // Apply default value here
  yield put(setDownloadingFile(fileKey));
  yield call(setDownloadingFileTimeout, { fileKey, timeout });
}

function decodeAndDownloadFileToBrowser(action: WsResponse<FileResponse>) {
  const base64Data = action.payload.fileBase64;
  const arrayBuffer = base64ToArrayBuffer(base64Data);

  const blob = new Blob([arrayBuffer], { type: action.payload.contentType });
  const downloadUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = action.payload.fileName;
  link.click();
  link.remove();

  URL.revokeObjectURL(downloadUrl);
}

export function* fileDownloadResponseSaga(action: WsResponse<FileResponse>): SagaIterator {
  const response = mapQueryWsResponseToSearchData(action);
  yield put(setFile(response));
  cancelTimeout(action.payload.key);

  if (response.searchStatus === 'found' && response?.data?.contentType.startsWith('application')) {
    decodeAndDownloadFileToBrowser(action);
    notificationSuccess(response);
  }
}

export function* fileDownloadInProgressResponseSaga(): SagaIterator {
  notifyTop(
    'info',
    AppTranslation.t('common.file_saga.download_in_progress.title', 'Download file is in progress'),
    AppTranslation.t('common.file_saga.download_in_progress.description', `Please wait`),
  );
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  yield call(() => {});
}

export function* fileDownloadErrorResponseSaga(action: WsResponse<BusinessError>): SagaIterator {
  const fileKey = action.payload.errorDetail?.find(({ name }) => name === 'FILE_KEY')?.value ?? '';
  cancelTimeout(fileKey);
  notificationError();
  yield put(setTimeoutDownloadingFile(fileKey));
}

function base64ToArrayBuffer(base64: string): Uint8Array {
  const binaryString = decodeBase64(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function* FilesSagas(): SagaIterator {
  yield takeEvery(actions.fileDownloadResponseSaga.type, sagaGuard(fileDownloadResponseSaga));
  yield takeEvery(actions.fileDownloadErrorResponseSaga.type, sagaGuard(fileDownloadErrorResponseSaga));
  yield takeEvery(actions.fileDownloadInProgressResponseSaga.type, sagaGuard(fileDownloadInProgressResponseSaga));
  yield takeEvery(actions.fileDownloadRequestSaga.type, sagaGuard(fileDownloadRequestSaga));
}
