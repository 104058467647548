import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { useFetchTextSearch } from 'domains/catalog/Catalog.requests';
import { getFulltextSearchResult, getLastVehicleDetail, getSearchStatus } from 'domains/catalog/Catalog.store';
import { FulltextSearchResult } from 'domains/catalog/Catalog.types';
import { CenteredSpin, MarginBox } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { SearchResultReferenceSection } from './SearchResultReferenceSection';

export function containsData(data?: FulltextSearchResult) {
  return data && data.refNumbers.length > 0;
}

const FullTextSearchResult = () => {
  const { query, label } = useParams<{
    query: string;
    label: string;
  }>();

  const searchResult = useSelector((state: RootState) => getFulltextSearchResult(state));
  const searchStatus = useSelector((state: RootState) => getSearchStatus(state));
  const vehicleDetail = useSelector(getLastVehicleDetail);

  useResetScroll();

  useFetchTextSearch(label, query, searchResult, searchStatus, vehicleDetail?.catalogSource);

  return (
    <DataContainer
      data={searchStatus?.status}
      Loading={() => (
        <MarginBox mt={20}>
          <CenteredSpin />
        </MarginBox>
      )}
    >
      {searchResult && containsData(searchResult) && (
        <SearchResultReferenceSection refNumbers={searchResult.refNumbers} />
      )}
      <MarginBox mt={500} />
    </DataContainer>
  );
};

export default FullTextSearchResult;
