import React from 'react';
import { capitalize, Text } from 'UI/Text';
import { WithTooltip } from 'UI/Tooltip';

export const DescriptionText = ({ text, title }: { text: string; title?: boolean }) => {
  return (
    <WithTooltip title={capitalize(text)} placement={'top'}>
      <Text type={title ? 'text_dim' : 'text_dim_bold'} ellipsis>
        {capitalize(text)}
      </Text>
    </WithTooltip>
  );
};
