import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Technicity } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/LaborTime';
import { LaborTimeFilterOption } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetIAMLaborTimesResponse';
import { LaborTime as EstimateLaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/LaborTime';
import { Settings as EstimateSettings } from '@1po/1po-bff-fe-spec/generated/estimate/response/model/Settings';
import { Popover } from 'antd';
import { History } from 'history';
import { TFunction } from 'i18next';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CogIcon, DotCircleIcon, MinusSquareIcon, PlusSquareIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { getSearchVehicleResult } from 'domains/catalog/Catalog.store';
import {
  FilterByElementOperationState,
  FilterDefault,
  getIAMLaborSubtableLabelText,
  getTechnicityTier,
  IAMLaborTimeLocal,
  IAMLaborTimesWrapper,
} from 'domains/catalog/Catalog.types';
import { convertIAMLaborTimeToEstimateLaborTime } from 'domains/estimate/Estimate.mapper';
import {
  addCatalogLaborTime,
  getCatalogLaborTimes,
  getCurrentEstimateId,
  getEstimateSettings,
  setSelectedTab,
  updateLaborTime,
} from 'domains/estimate/Estimate.store';
import { EstimateTabName, EstimateTabParam, FocusSetting, SettingsTabName } from 'domains/estimate/Estimate.types';
import { getCurrency } from 'domains/user';
import { getLaborTimeRate, MECHANICS } from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/utils';
import { IconWrapper, STable } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
import { IAMEstimateColumn } from 'pages/CatalogPage/IAM/SubcategorySection/LaborTimeSection/LaborTimeSectionColumn';
import { SETTINGS_RATES } from 'pages/EstimatePage/SettingTab/SettingsTab';
import { ExpandedLaborTimeDotCircleWrapper } from 'pages/EstimatePage/TableSection/TableSection.styled';
import { theme, ThemeColorKeyType } from 'styles';
import { Box, capitalize, CenteredSpin, ErrorAlert, Flex, Icon, MarginBox, Select, SelectOptionSingle, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { getData, hasData, isLoading, NO_DATA, textFormatter } from 'utils';

export interface IAMEstimateLaborTimeDetail {
  itemId: string;
  quantity: number;
}

export function GetTitle(tr: string) {
  return (
    <Text disableGutter type={'h2'}>
      {tr}
    </Text>
  );
}

type colorType = 'RED' | 'BLUE';

function colorSwitch(color: colorType): { font: string; circle: ThemeColorKeyType } {
  if (color === 'RED') {
    return {
      font: 'light_14_bold_red',
      circle: 'error',
    };
  } else {
    return {
      font: 'link_bold',
      circle: 'clear_blue',
    };
  }
}

function showExpandIcon(row: IAMLaborTimeLocal): boolean {
  const sub = row.subtable;
  return !(sub === undefined || sub.length === 0);
}

function ExpandIcon({
  expanded,
  record,
  onExpand,
}: Readonly<{ expanded: boolean; record: IAMLaborTimeLocal; onExpand: any }>) {
  const { t } = useTranslation();
  const colors = colorSwitch(record.subtableLabel === 'included' ? 'BLUE' : 'RED');
  if (record.subtableLabel) {
    return (
      <Flex direction={'row'}>
        <MarginBox mr={10} />
        <ExpandedLaborTimeDotCircleWrapper>
          <Icon IconComponent={DotCircleIcon} size={8} color={theme.color[colors.circle]} noPointer />
        </ExpandedLaborTimeDotCircleWrapper>
        <Text type={colors.font}>{getIAMLaborSubtableLabelText(record.subtableLabel, t)}</Text>
      </Flex>
    );
  }
  return (
    <Flex justify={'flex-start'} align={'center'}>
      {showExpandIcon(record) ? (
        <Icon
          IconComponent={expanded ? MinusSquareIcon : PlusSquareIcon}
          color={expanded ? 'white' : 'black'}
          background={expanded ? 'black' : 'white'}
          onClick={(e) => onExpand(record, e)}
          size={18}
          display={'block'}
          mr={10}
        />
      ) : (
        <MarginBox mr={30} />
      )}
      <span className={'tableDivider'}></span>
      <Text type={'h2'} disableGutter>
        {record.id}
      </Text>
    </Flex>
  );
}

const convertToSelectOption = (options: LaborTimeFilterOption[] | undefined): SelectOptionSingle[] =>
  options?.map((option) => ({ value: option.code, title: capitalize(option.label) })) ?? [];

function isSubTableLabelCell(row: IAMLaborTimeLocal) {
  return { colSpan: row.subtableLabel ? 0 : 1 };
}

const tableColumns = (
  t: TFunction,
  laborTimeToQuantity: Map<string, IAMEstimateLaborTimeDetail>,
  addLaborTimeToEstimate: (laborTimeLocal: IAMLaborTimeLocal) => void,
  updateLaborTimeQuantity: (itemId: string, newQuantity: number) => void,
  currency: string,
  estimateSettings: EstimateSettings | undefined,
  history: History,
) => {
  return [
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.code', 'Code')),
      dataIndex: 'id',
      key: 'id',
      width: 150,
      render: function rowSelector() {
        return <></>;
      },
      onCell: function onCell(row: IAMLaborTimeLocal) {
        return { colSpan: row.subtableLabel ? 8 : 1 };
      },
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.designation', 'Designation')),
      dataIndex: 'designation',
      key: 'designation',
      render: function rowSelector(designation: string) {
        return (
          <Flex justify={'space-between'} align={'center'}>
            <Text type={'text'} disableGutter>
              {designation}
            </Text>
          </Flex>
        );
      },
      onCell: isSubTableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.details', 'Details')),
      dataIndex: 'description',
      key: 'description',
      render: function rowSelector(description: string) {
        return (
          <Flex justify={'space-between'} align={'center'}>
            <Text type={'text'} disableGutter>
              {description}
            </Text>
          </Flex>
        );
      },
      onCell: isSubTableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.time', 'Time')),
      dataIndex: 'time',
      key: 'time',
      render: function rowSelector(time: string) {
        return (
          <Flex justify={'space-evenly'} align={'center'}>
            <Text type={'highlight'} disableGutter>
              {time}
            </Text>
          </Flex>
        );
      },
      onCell: isSubTableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.technicity', 'Technicity')),
      dataIndex: 'technicity',
      key: 'technicity',
      render: function rowSelector(technicity: Technicity, row: IAMLaborTimeLocal) {
        const laborTimeRate = getLaborTimeRate(MECHANICS, row.technicity, estimateSettings?.laborPriceList)?.price;
        return (
          <Flex justify={'center'}>
            <Text type={'lead_dim'}>{getTechnicityTier(technicity) + `-${laborTimeRate ?? '?'}`}</Text>
          </Flex>
        );
      },
      onCell: isSubTableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.priceVATExcl', 'Price VAT.Excl')),
      dataIndex: 'priceVatExcluded',
      key: 'priceVatExcluded',
      render: function rowSelector(_priceVatExcluded: number, row: IAMLaborTimeLocal) {
        const laborTimeRate = getLaborTimeRate(MECHANICS, row.technicity, estimateSettings?.laborPriceList)?.price;
        return (
          <Flex justify={'center'}>
            {laborTimeRate ? (
              !isNaN(parseFloat(laborTimeRate)) && (
                <Text type={'highlight'} disableGutter>
                  {textFormatter.formatCurrency(parseFloat(laborTimeRate) * row.time, currency)}
                </Text>
              )
            ) : (
              <Popover
                content={`${t(
                  'catalog.parts.category.car_parts.labor_time.notification.set_technicity',
                  'Set the hourly rate of technicity',
                )}`}
                placement={'bottomLeft'}
                trigger="hover"
              >
                <IconWrapper>
                  <Icon
                    IconComponent={CogIcon}
                    color={theme.color.clear_blue}
                    height={22}
                    width={23}
                    onClick={() => {
                      const params = new URLSearchParams();
                      params.set(EstimateTabParam, SettingsTabName);
                      params.set(FocusSetting, SETTINGS_RATES);
                      history.push(`${ROUTER_ESTIMATE}?${params}`);
                    }}
                  />
                </IconWrapper>
              </Popover>
            )}
          </Flex>
        );
      },
      onCell: isSubTableLabelCell,
    },
    {
      title: GetTitle(t('catalog.parts.category.car_parts.labor_time.header.estimate', 'Estimate')),
      dataIndex: 'estimate',
      key: 'estimate',
      render: function rowSelector(_estimate: boolean, row: IAMLaborTimeLocal) {
        const technicity = getLaborTimeRate(MECHANICS, row.technicity, estimateSettings?.laborPriceList)
          ?.technicityLevel;
        return (
          <IAMEstimateColumn
            row={row}
            laborTimeToQuantity={laborTimeToQuantity}
            updateLaborTimeQuantity={updateLaborTimeQuantity}
            technicity={technicity}
            addLaborTimeToEstimate={addLaborTimeToEstimate}
          />
        );
      },
      onCell: isSubTableLabelCell,
    },
  ];
};

interface LaborTimeSectionProps {
  query: string;
  nodeId: string;
  laborTimesWrapper: NO_DATA | IAMLaborTimesWrapper;
}

const LaborTimeSection = ({ query, nodeId, laborTimesWrapper }: LaborTimeSectionProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const laborTimes = getData(laborTimesWrapper)?.laborTimes;
  const elementsRaw = convertToSelectOption(getData(laborTimesWrapper)?.elements);
  const operationsRaw = convertToSelectOption(getData(laborTimesWrapper)?.operations);
  const estimateSettings = useSelector(getEstimateSettings);
  const estimateId = useSelector(getCurrentEstimateId);
  const vehicle = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const vehicleDetail = vehicle?.vehicleDetail;
  const vehicleKey = vehicle?.vehicleDetail?.vehicleKey;

  const [filterState, setFilterState] = useState<FilterByElementOperationState>(FilterDefault);
  useEffect(() => {
    setFilterState(FilterDefault);
  }, [vehicleKey, nodeId]);

  const currentLaborTimes = useSelector((state: RootState) => getCatalogLaborTimes(state, estimateId));
  const currency = useSelector(getCurrency);
  const currentQuantityMap = currentLaborTimes.reduce(
    (acc: Map<string, IAMEstimateLaborTimeDetail>, next: EstimateLaborTime) =>
      acc.set(next.code, { itemId: next.itemId, quantity: next.quantity }),
    new Map<string, IAMEstimateLaborTimeDetail>(),
  );
  const [, setEstimateNotificationConfig] = useState({
    containsTests: false,
  });

  const updateLaborTimeQuantity = (itemId: string, newQuantity: number) => {
    dispatch(
      updateLaborTime({
        itemId,
        newValue: String(newQuantity),
        field: 'QUANTITY',
      }),
    );
  };

  const addLaborTimeToEstimate = (laborTimeLocal: IAMLaborTimeLocal) => {
    if (vehicleDetail?.iamVehicle && hasData(estimateSettings)) {
      setEstimateNotificationConfig({ containsTests: false });
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.added_operation_time'}>
          {"Operation's labor time has been added to your estimate."}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            dispatch(setSelectedTab(EstimateTabName));
            history.push(ROUTER_ESTIMATE);
          }}
        >
          <Trans i18nKey={'catalog.parts.category.car_parts.labor_time.notification.see_estimate'}>
            {'See estimate'}
          </Trans>
        </NotificationLink>,
      );
      dispatch(
        addCatalogLaborTime({
          laborTime: convertIAMLaborTimeToEstimateLaborTime(laborTimeLocal, estimateSettings),
          testLaborTimes: [],
        }),
      );
    }
  };

  const columns = tableColumns(
    t,
    currentQuantityMap,
    addLaborTimeToEstimate,
    updateLaborTimeQuantity,
    currency,
    getData(estimateSettings),
    history,
  );

  // 30 minimum, 8px per char
  const getFilterWidth = (filterArray: SelectOptionSingle[]) =>
    Math.max(...filterArray.map((e) => e.title.length), 30) * 8;
  const elements = [
    {
      value: FilterDefault.filterElement,
      title: t('catalog.parts.category.car_parts.labor_time.all_elements', 'All elements'),
    },
  ].concat(elementsRaw);
  const operations = [
    {
      value: FilterDefault.filterOperations,
      title: t('catalog.parts.category.car_parts.labor_time.all_operations', 'All operations'),
    },
  ].concat(operationsRaw);
  const operationsWidth = getFilterWidth(operations);
  const elementsWidth = getFilterWidth(elements);

  const filterDatasource = () => {
    const filteredByElements =
      filterState.filterElement === FilterDefault.filterElement
        ? laborTimes ?? []
        : laborTimes?.filter((laborTime) => laborTime.element === filterState.filterElement) ?? [];

    return filterState.filterOperations === FilterDefault.filterOperations
      ? filteredByElements
      : filteredByElements.filter((laborTime) => laborTime.operation === filterState.filterOperations);
  };

  const dataSource = filterDatasource();

  if (!laborTimes) {
    return <CenteredSpin />;
  }
  return (
    <>
      <Text type={'section_bold'}>
        {t('catalog.parts.category.car_parts.labor_time', 'Labor time')}
        {` – `}
        <Trans count={dataSource.length} i18nKey={'catalog.parts.category.car_parts.labor_time.items_found'}>
          {'{{count}} items found'}
        </Trans>
      </Text>
      <MarginBox mt={45} />
      <Flex size={'auto'} justify={'flex-start'} direction={'row'} align={'center'}>
        <Box width={operationsWidth}>
          <Select
            options={operations}
            onChange={(value) => {
              setFilterState({ ...filterState, filterOperations: String(value) });
            }}
            bordered
            size={'middle'}
            value={filterState.filterOperations}
            initialValue={FilterDefault.filterOperations}
            disabled={!hasData(laborTimesWrapper)}
          />
        </Box>
        <MarginBox ml={30} />
        <Box width={elementsWidth}>
          <Select
            options={elements}
            onChange={(value) => {
              setFilterState({ ...filterState, filterElement: String(value) });
            }}
            bordered
            size={'middle'}
            initialValue={FilterDefault.filterElement}
            value={filterState.filterElement}
            disabled={!hasData(laborTimesWrapper)}
          />
        </Box>
        <MarginBox ml={30} />
      </Flex>
      <DataContainer
        data={laborTimesWrapper}
        NotFound={() => (
          <MarginBox my={20}>
            <Box height={40}>
              <ErrorAlert
                message={t(
                  'catalog.parts.category.car_parts.labor_time.notification.noMatchFound',
                  'Sorry, no match found for your research',
                )}
              />
            </Box>
          </MarginBox>
        )}
      >
        <MarginBox mt={20} />
        <STable<IAMLaborTimeLocal>
          loading={isLoading(laborTimesWrapper)}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey={(row) => row.nodeId}
          expandable={{
            expandIcon: ExpandIcon,
            childrenColumnName: 'subtable',
            indentSize: 0,
          }}
          size={'large'}
          bordered={true}
        />
      </DataContainer>
    </>
  );
};

export default LaborTimeSection;
