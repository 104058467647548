import React from 'react';
import { useSelector } from 'react-redux';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment/moment';
import { getWebLanguage } from 'domains/user';

const { RangePicker } = AntDatePicker;

export type RangeValue = [moment.Moment | null, moment.Moment | null] | null;

export interface RangeDatePickerProps {
  value?: RangeValue;
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  onChange?: (values: RangeValue, dateStrings: [string, string]) => void;
  onCalendarChange?: (values: RangeValue) => void;
}

export const RangeDatePicker = (props: RangeDatePickerProps) => {
  const { antdLocale } = useSelector(getWebLanguage);
  return <RangePicker {...props} format={antdLocale.DatePicker?.lang.dateFormat} />;
};
