import styled from 'styled-components';
import { Flex, YellowButton } from 'UI';

export const SContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 690px;
  justify-content: center;
  max-width: 690px;
  height: 50px;
  border-radius: 25px;
  border: solid 1px ${({ theme }) => theme.color.silver_2};
`;

export const SContainerError = styled(Flex)<{ small?: boolean }>`
  z-index: 100;
  flex-basis: 690px;
  flex-wrap: wrap;
  max-width: 690px;
  height: ${({ small }) => (small ? 30 : 50)}px;
  border-radius: 25px;
  border: solid 1px ${({ theme }) => theme.color.pink_bright};
  background-color: ${({ theme }) => theme.color.very_light_pink};
`;

export const MagnifierButton = styled(YellowButton)`
  width: 60px;
  height: 50px;
  margin-right: -5px;
  margin-top: -1px;
  margin-left: 2px;
  border-radius: 0 25px 25px 0;
`;

export const FlexMenuContainer = styled(Flex)`
  border-radius: 3px;
  box-shadow: 0 5px 14px 4px ${({ theme }) => theme.color.shadow_10}, 0 2px 8px 0 ${({ theme }) => theme.color.shadow_6},
    0 2px 3px -2px ${({ theme }) => theme.color.shadow_5};
  .ant-dropdown-menu {
    box-shadow: none;
  }
`;
