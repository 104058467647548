import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isQueryReferenceNumber, referenceRegex } from 'components/Page/Header/TopHeader/Search/Search';
import { validateProductsRequestSaga } from 'domains/promotion/Promotion.store';
import { CreatePromotionModel } from 'domains/promotion/Promotion.types';
import { Flex, Form, Input, MarginBox, Text } from 'UI';
import { AlreadyPromotedProductNotification } from './PromotionErrorNotifications';

interface ByReferencesProps {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  setCurrentStep: (x: number) => void;
}

export function AddPromotedReference({ promotion, setPromotion, setCurrentStep }: Readonly<ByReferencesProps>) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const referenceValidationMessage = t('common.form.validation.reference', 'Please type a valid reference number.');

  return (
    <Flex direction={'column'}>
      <Flex size={4}>
        <Text type={'h5_bold'}>{t('backoffice.promotion.by_promoted_reference', 'Add promoted reference')}</Text>
      </Flex>
      <MarginBox mt={30} />
      <AlreadyPromotedProductNotification promotion={promotion} setCurrentStep={setCurrentStep} />
      <Flex maxWidth={150}>
        <Form>
          <Form.Item
            name={'product'}
            rules={[
              {
                pattern: referenceRegex,
                message: referenceValidationMessage,
              },
            ]}
          >
            <Input
              disabled={promotion.editing}
              placeholder={''}
              bordered
              initialValue={
                promotion.references && promotion.references.length > 0 && promotion.references[0].items.length > 0
                  ? promotion.references[0].items[0].item
                  : ''
              }
              onInput={(event) => {
                const target = event.target as HTMLInputElement;
                target.value = target.value?.toUpperCase();
              }}
              onChange={(newReference) => {
                const reference = newReference.trim();
                const updatePromotion: CreatePromotionModel = {
                  ...promotion,
                  references: [
                    {
                      items: [{ item: reference, removable: false }],
                      discount:
                        promotion.references && promotion.references.length > 0 ? promotion.references[0].discount : 0,
                    },
                  ],
                };
                setPromotion(updatePromotion);
                if (isQueryReferenceNumber(reference)) {
                  dispatch(validateProductsRequestSaga({ promotion: updatePromotion }));
                }
              }}
            />
          </Form.Item>
        </Form>
      </Flex>
    </Flex>
  );
}
