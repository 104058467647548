import React, { ReactNode } from 'react';
import { Tooltip } from 'antd-v5';

interface TooltipProps {
  title: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'topLeft' | 'bottomLeft' | 'topRight' | 'bottomRight';
  children: ReactNode;
}

export const WithTooltip = ({ title, children, placement }: TooltipProps): ReactNode => {
  return (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  );
};
