import React, { useCallback } from 'react';
import { FullText } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/model/FullText';
import { SearchParams } from 'domains/catalog/Catalog.types';
import { capitalize, Pipeline, Text } from 'UI';
import { SearchMenu, SItem } from './Search.styled';

interface AutocompleteMenuProps {
  results: FullText[];
  menuRef: any;
  inputValue: string;
  onClick: (label: string) => () => void;
  search: SearchParams;
  setSearch: React.Dispatch<React.SetStateAction<SearchParams>>;
}

const AutocompleteMenu = ({ results, menuRef, inputValue, onClick, search, setSearch }: AutocompleteMenuProps) => {
  const valueToMatch = inputValue.replace(/[^a-zA-Z/s ]/g, '');
  const matcher = new RegExp(`(?=${valueToMatch})|(?<=${valueToMatch})`, 'gi');

  const handleBlurMenu = (e: React.FocusEvent<HTMLLIElement>) => {
    if (
      search &&
      search.focusMenu !== undefined &&
      !(e?.relatedTarget && e?.relatedTarget.id.includes('search-menu'))
    ) {
      setSearch({ ...search, open: false, focusMenu: undefined });
    }
  };

  const handleEscPress = useCallback(
    (event: { key: string }) => {
      if (event.key === 'Escape') {
        setSearch({ ...search, open: false, focusMenu: 0 });
      }
    },
    [search, setSearch],
  );

  return (
    <SearchMenu ref={menuRef} onKeyDown={handleEscPress}>
      {results.map((fulltext, index) => (
        <React.Fragment key={`keywords-${index}`}>
          <SItem
            onClick={onClick(fulltext.label)}
            key={`menu-item-${index}`}
            id={`search-menu-${index}`}
            onBlur={handleBlurMenu}
          >
            {fulltext.label.split(matcher)?.map((segment, idx) => (
              <Text
                key={`segment-${idx}`}
                type={segment !== inputValue ? 'search_result_highlighted' : 'search_result_base'}
                cursor={'pointer'}
              >
                {idx === 0 ? capitalize(segment) : segment}
              </Text>
            ))}
          </SItem>
          {index + 1 !== results.length && <Pipeline horizontal size={'100%'} />}
        </React.Fragment>
      ))}
    </SearchMenu>
  );
};
export default AutocompleteMenu;
