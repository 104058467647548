import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { NotFound } from 'app/NotFound';
import {
  PersonalDataAT,
  PersonalDataBE,
  PersonalDataDE,
  PersonalDataFR,
  PersonalDataGB,
  PersonalDataIT,
  PersonalDataNL,
  PersonalDataPL,
  PersonalDataPT,
  PersonalDataRO,
} from 'pages/PersonalDataPage/versions';
import { Country } from 'utils/i18n/Country';

export const PersonalDataPage = () => {
  return (
    <Switch>
      <Route path={ROUTER_PERSONAL_DATA(Country.AT.key)} component={PersonalDataAT} />
      <Route path={ROUTER_PERSONAL_DATA(Country.DE.key)} component={PersonalDataDE} />
      <Route path={ROUTER_PERSONAL_DATA(Country.FR.key)} component={PersonalDataFR} />
      <Route path={ROUTER_PERSONAL_DATA(Country.IT.key)} component={PersonalDataIT} />
      <Route path={ROUTER_PERSONAL_DATA(Country.GB.key)} component={PersonalDataGB} />
      <Route path={ROUTER_PERSONAL_DATA(Country.NL.key)} component={PersonalDataNL} />
      <Route path={ROUTER_PERSONAL_DATA(Country.PL.key)} component={PersonalDataPL} />
      <Route path={ROUTER_PERSONAL_DATA(Country.RO.key)} component={PersonalDataRO} />
      <Route path={ROUTER_PERSONAL_DATA(Country.BE.key)} component={PersonalDataBE} />
      <Route path={ROUTER_PERSONAL_DATA(Country.PT.key)} component={PersonalDataPT} />
      <Route path={'/'} component={NotFound} />
    </Switch>
  );
};
