import React from 'react';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { GlobeIcon } from 'assets/icons';
import { Icon } from 'UI/Icon/Icon';
import { isQueryBMM } from 'utils';
import { Country } from 'utils/i18n/Country';

interface VehicleFlagProps {
  vehicle: VehicleDetail;
  size?: number;
}

const VehicleFlag = ({ vehicle, size = 16 }: VehicleFlagProps) => {
  const countryIcon = Country.findByKey(vehicle.country)?.icon;

  if (isQueryBMM(vehicle.vehicleKey)) {
    return null;
  }
  return <Icon IconComponent={countryIcon ?? GlobeIcon} size={size} noPointer />;
};

export default VehicleFlag;
