import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { getFulltextSearchData } from 'domains/tires/Tire.store';
import { SItem, SSearchResults } from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresFulltextSearchResults.styled';
import { Flex, Text } from 'UI';

export interface TiresFulltextSearchResultsProps {
  search: string;
  onClickOnSelectedValue: (value: string) => void;
}

// eslint-disable-next-line react/display-name
export const TiresFulltextSearchResults = memo((props: TiresFulltextSearchResultsProps) => {
  const data = useSelector(getFulltextSearchData).data;
  const searchedValues = props.search.trim().toUpperCase().split(' ');
  const regex = new RegExp(`(${searchedValues.join('|')})`, 'gi');

  if (data === undefined || data.length === 0 || props.search.length < 3) return null;

  const oneThirdSize = Math.ceil(data.length / 3);
  const arr1 = data.slice(0, oneThirdSize);
  const arr2 = data.slice(oneThirdSize, oneThirdSize * 2);
  const arr3 = data.slice(oneThirdSize * 2, oneThirdSize * 3);

  return (
    <SSearchResults>
      <OneThirdList
        data={arr1}
        {...props}
        onClickOnSelectedValue={props.onClickOnSelectedValue}
        searchedValues={searchedValues}
        regex={regex}
      />
      <OneThirdList
        data={arr2}
        {...props}
        onClickOnSelectedValue={props.onClickOnSelectedValue}
        searchedValues={searchedValues}
        regex={regex}
      />
      <OneThirdList
        data={arr3}
        {...props}
        onClickOnSelectedValue={props.onClickOnSelectedValue}
        searchedValues={searchedValues}
        regex={regex}
      />
    </SSearchResults>
  );
});

interface OneThirdListProps {
  data: string[];
  searchedValues: string[];
  regex: RegExp;
  onClickOnSelectedValue: (value: string) => void;
}

function OneThirdList(props: OneThirdListProps) {
  return (
    <Flex direction={'column'}>
      {props.data.map((r) => (
        <Item key={r} text={r} {...props} />
      ))}
    </Flex>
  );
}

interface ItemProps {
  text: string;
  searchedValues: string[];
  regex: RegExp;
  onClickOnSelectedValue: (value: string) => void;
}

function Item(props: ItemProps) {
  return (
    <SItem onClick={() => props.onClickOnSelectedValue(props.text)}>
      {props.text.split(props.regex)?.map((segment, idx) => (
        <Text
          key={`segment-${idx}`}
          type={props.searchedValues.includes(segment) ? 'search_result_highlighted' : 'search_result_base'}
        >
          {segment}
        </Text>
      ))}
    </SItem>
  );
}
