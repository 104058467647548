import React from 'react';
import { SectionContentType } from 'pages/LandingPage/Sections/Sections';
import { BlackButton, Box, Flex, Image, MarginBox, PUBLIC, YellowButton } from 'UI';
import { BlockWrapper, InfoButton, InfoText, InfoTitle, InfoWrapper } from './Section.styled';

interface SectionProps extends SectionContentType {
  left: boolean;
}

const Section = ({
  image,
  title,
  description,
  action,
  actionColor,
  background,
  backgroundImage,
  textColor = 'initial',
  left,
  alt,
}: SectionProps): JSX.Element => {
  return (
    <Box backgroundImage={backgroundImage} backgroundImageType={PUBLIC} background={background}>
      <BlockWrapper textColor={textColor} left={left}>
        <Flex>
          <InfoWrapper left={left}>
            <InfoTitle>{title}</InfoTitle>
            <InfoText>{description}</InfoText>
            <InfoButton>
              {action &&
                (actionColor === 'yellow' ? (
                  <YellowButton size={'large'}>{action}</YellowButton>
                ) : (
                  <BlackButton size={'large'}>{action}</BlackButton>
                ))}
            </InfoButton>
          </InfoWrapper>
        </Flex>
        <Box width={30} />
        <Flex>
          <MarginBox ml={left ? 0 : 'auto'} mr={left ? 'auto' : 0}>
            {image && <Image src={image} alt={alt} />}
          </MarginBox>
        </Flex>
      </BlockWrapper>
    </Box>
  );
};

export default Section;
