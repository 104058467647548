import React from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTire';
import { SelectedTireProps } from 'domains/tires/Tire.types';
import { IAMTireItem } from 'pages/CatalogPage/IAM/Tires/TireSizesTables/IAMTireItem';
import { Flex, MarginBox, Text } from 'UI';
import { useBreakpointSelectorFull } from 'utils';

interface SameSizedSectionProps extends SelectedTireProps {
  iamTires: VehicleTire[];
}

const SameSizedSection = ({ iamTires, selectedTire, setSelectedTire }: SameSizedSectionProps) => {
  const { t } = useTranslation();
  const size = useBreakpointSelectorFull()({ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '33%', xxl: '25%' });

  return (
    <Flex direction={'column'}>
      <MarginBox mt={30} />
      <Text type={'h1_banner_light'}>{t('catalog.vehicle.tires.same.sizes', 'Same tires sizes FRT/BK')}</Text>
      <MarginBox mt={30} />
      <Flex direction={'row'} wrap={'wrap'}>
        {iamTires.map((iamTire, index) => (
          <Flex key={index} align={'flex-start'} maxWidth={size} minWidth={size}>
            <MarginBox mr={15}>
              <IAMTireItem
                id={iamTire.frontTireId ?? ''}
                rimSize={iamTire.frontRimSize}
                tireSize={iamTire.frontTyreSize}
                usage={iamTire.usage}
                sameSizeSection
                isSelected={selectedTire === iamTire.frontTireId}
                setSelectedTire={setSelectedTire}
              />
            </MarginBox>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default SameSizedSection;
