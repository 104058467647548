import styled from 'styled-components';
import { effect } from 'styles/effect';

export const STestIcon = styled.div<{ testWasAdded?: boolean }>`
  border: solid 1px ${({ theme, testWasAdded }) => (testWasAdded ? theme.color.grass_green : theme.color.clear_blue)};
  border-radius: 30px;
  padding: 0 15px 0 15px;
  color: ${({ theme, testWasAdded }) => (testWasAdded ? theme.color.grass_green : theme.color.clear_blue)};
  cursor: pointer;
  background: white;
  text-align: center;
  ${effect.hover.dim}
`;
