import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionCard } from 'pages/CartPage/ValidationStep/DeliveryAddressSection.styled';
import ModifyButton from 'pages/CartPage/ValidationStep/ModifyButton';
import { Box, Flex, Radio, RadioGroup, Select, SelectOptionSingle, Space, Text } from 'UI';

interface BillingAccountSectionProps {
  updateBillingAccount: (value: string) => void;
  billingOptions?: SelectOptionSingle[];
  selectedBillingAccount?: string;
}

const BillingAccountSection = ({
  updateBillingAccount,
  billingOptions,
  selectedBillingAccount,
}: BillingAccountSectionProps) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setEditMode(false);
  }, [selectedBillingAccount]);

  return (
    <Flex direction={'column'} dataCy={'section-payment-method'}>
      <SectionCard direction={'row'} align={'center'} editMode={editMode}>
        <Flex direction={'column'} alignSelf={'flex-start'}>
          <Text type={'h4_paragraph'}>{t('order.validation.payment.title', '2. Payment methods')}</Text>
          <Box height={10} />
          {!editMode && (
            <Text type={'light_14_black_85'}>
              {`${t('order.validation.payment.pay_with', 'Pay with:')} ${selectedBillingAccount}`}
            </Text>
          )}
          <Text type={'light_14_black_65'}>
            {t(
              'order.validation.payment.description',
              'The selected payment options will apply by default to your entire order.',
            )}
          </Text>
          {editMode && (
            <>
              <Box height={15} />
              <RadioGroup>
                <Space direction={'vertical'}>
                  <Radio
                    checked
                    label={
                      <Text type={'light_14_black_65'}>
                        {t('order.validation.payment.options.account', 'Account billing')}
                      </Text>
                    }
                  />
                  <Box width={250}>
                    <Select
                      onChange={(value) => {
                        updateBillingAccount(String(value));
                      }}
                      initialValue={selectedBillingAccount}
                      placeholder={t('order.validation.payment.options.placeholder', 'Select billing account')}
                      options={billingOptions}
                      bordered
                      disabled={billingOptions && billingOptions.length <= 1}
                    />
                  </Box>
                </Space>
              </RadioGroup>
            </>
          )}
        </Flex>
        {billingOptions && billingOptions.length > 1 && <ModifyButton editMode={editMode} handleEdit={setEditMode} />}
      </SectionCard>
    </Flex>
  );
};

export default BillingAccountSection;
