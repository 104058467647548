import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_CART, ROUTER_CART_CONFIRMATION } from 'app/AppRouter';
import { CartStepper, NAVIGATE_FROM_CART } from 'pages/CartPage/CartPage';
import { Container, emptyPadding, indentPadding } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import ValidationStep from './ValidationStep';

export const NAVIGATE_FROM_CAR_DETAIL = 'navigateFromCartDetail';

const CartDetailsPage = () => {
  const history = useHistory();
  const next = useCallback(() => {
    localStorage.setItem(NAVIGATE_FROM_CAR_DETAIL, 'true');
    history.push(ROUTER_CART_CONFIRMATION);
  }, [history]);

  useEffect(() => {
    if ('true' !== localStorage.getItem(NAVIGATE_FROM_CART)) {
      history.push(ROUTER_CART);
    } else {
      localStorage.removeItem(NAVIGATE_FROM_CART);
    }
  }, [history]);

  useResetScroll();

  return (
    <Container padding={emptyPadding}>
      <>
        <CartStepper step={1} />
        <Container padding={indentPadding}>
          <ValidationStep next={next} />
        </Container>
      </>
    </Container>
  );
};

export default CartDetailsPage;
