/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import { History } from 'history';
import { ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { getElementAndSiblingsById, getSvgTextElementsFromDocument, IndexType } from 'utils/svg/common';

export const svgElementId = 'svg-car-part-group-';
export const svgTextPosition = 'svg g text';

const addClassIntoElementWithLines = (elementId: string, className: string): void => {
  getElementAndSiblingsById(elementId).forEach((element) => element.classList.add(className));
};

const removeClassFromElementWithLines = (elementId: string, className: string): void => {
  getElementAndSiblingsById(elementId).forEach((element) => element.classList.remove(className));
};

const resolveHighlight = (textElement: Element, highlightedId: string | null) => {
  if (highlightedId === textElement.id) {
    addClassIntoElementWithLines(textElement.id, 'highlightedIndexLine');
  } else {
    removeClassFromElementWithLines(textElement.id, 'highlightedIndexLine');
  }
};

function getEventPartNodeId(event: HTMLElement | null, nodeId: string): string {
  const partNodeId = event?.id?.replaceAll(`${nodeId}_`, '') ?? '';
  return partNodeId.split('_')?.[0];
}

export const useAddListenersToCategorySvg = (
  nodeId: string,
  isEnlarged: boolean,
  pathname: string,
  history: History<unknown>,
  setHighlightedPartNodeId: React.Dispatch<React.SetStateAction<IndexType>>,
  svgElement: string | JSX.Element | undefined,
): void => {
  useEffect(() => {
    const clickOnIndex = (event: MouseEvent) => {
      const partNodeId = getEventPartNodeId(event?.target as HTMLElement, nodeId);
      const clearPath =
        `/${pathname?.split('/')?.slice(-1)[0]}` === ROUTER_CATALOG_VEHICLE
          ? pathname
          : pathname.substring(0, pathname.lastIndexOf('/'));
      history.push({
        pathname: `${clearPath}/${nodeId}/${partNodeId}`,
        search: location.search,
      });
    };

    const mouseOnIndex = (event: MouseEvent) => {
      const partNodeId = getEventPartNodeId(event?.target as HTMLElement, nodeId);
      document
        .getElementById(`parts-list-item-${partNodeId}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setHighlightedPartNodeId(partNodeId);
      document
        .getElementById('popover-' + nodeId)
        ?.setAttribute('style', `display: block; top: ${event.clientY - 42}px; left: ${event.clientX}px;`);
    };

    const mouseOffIndex = () => {
      setHighlightedPartNodeId(null);
      document.getElementById('popover-' + nodeId)?.setAttribute('style', `display: none;`);
    };

    getSvgTextElementsFromDocument(svgElementId, nodeId, svgTextPosition)?.forEach((textElement) => {
      getElementAndSiblingsById(textElement.id).forEach((p) => {
        p.addEventListener('click', clickOnIndex as EventListener);
        p.addEventListener('mouseover', mouseOnIndex as EventListener);
        p.addEventListener('mouseleave', mouseOffIndex);
      });
    });

    return () => {
      getSvgTextElementsFromDocument(svgElementId, nodeId, svgTextPosition)?.forEach((textElement) => {
        getElementAndSiblingsById(textElement.id).forEach((p) => {
          p.removeEventListener('click', clickOnIndex as EventListener);
          p.removeEventListener('mouseover', mouseOnIndex as EventListener);
          p.removeEventListener('mouseleave', mouseOffIndex);
        });
      });
    };
  }, [nodeId, isEnlarged, pathname, history, setHighlightedPartNodeId, svgElement]);
};

export const manipulateLinesInSvg = (nodeId: string, highlightedPartNodeId: IndexType): void => {
  getSvgTextElementsFromDocument(svgElementId, nodeId, svgTextPosition)?.forEach((textElement) => {
    resolveHighlight(textElement, `${nodeId}_${highlightedPartNodeId}`);
  });
};

const removeIndexWithLines = (indexElement: Element): void => {
  indexElement.nextElementSibling?.nextElementSibling?.nextElementSibling?.nextElementSibling?.remove();
  indexElement.nextElementSibling?.nextElementSibling?.nextElementSibling?.remove();
  indexElement.nextElementSibling?.nextElementSibling?.remove();
  indexElement.nextElementSibling?.remove();
  indexElement.remove();
};

export const useRemoveMissingCategoriesInSvg = (
  svgElement: string | JSX.Element | undefined,
  elementId: string,
  details: VehiclePartCategoryTreeItem[],
): void => {
  const [lastPlateId, setLastPlateId] = useState('');
  useEffect(() => {
    if (elementId !== lastPlateId && typeof svgElement === 'object') {
      const existingIndexes = details.map((detail) => detail.index.toString());
      getSvgTextElementsFromDocument(svgElementId, elementId, svgTextPosition)?.forEach((textElement) => {
        const index = textElement.textContent;
        if (index !== null && !existingIndexes.includes(index)) {
          removeIndexWithLines(textElement);
        }
      });
      setLastPlateId(elementId);
    }
  }, [elementId, lastPlateId, svgElement, details]);
};
