import { Card } from 'antd';
import styled from 'styled-components';
import { defaultBoxShadow, Text } from 'UI';

export const CarPartGroupCard = styled(Card)<{ width: number }>`
  ${defaultBoxShadow};
  max-width: ${({ width }) => `${width}px`};
  text-align: center;
  height: 100%;
  margin: 0;
  border: none;
  border-radius: 0;

  .ant-card-body {
    padding: 0;
    height: 100%;
  }

  .ant-card-head {
    height: 60px;
    background-color: black;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-card-head-wrapper,
  .ant-card-head-title {
    min-width: 0;
  }

  .ant-card-head {
    cursor: pointer;
  }

  .highlightedIndexLine {
    font-size: 30px;
  }

  .invisible-index {
    font-size: 27px;
  }

  .invisible-line {
    stroke-width: 6px;
  }
`;

export const SRoutineVehicleMaintenanceCard = styled(CarPartGroupCard)`
  :hover {
    cursor: default;
  }

  overflow: hidden;
`;

export const SText = styled(Text)`
  color: white;
`;

export const PopoverDiv = styled.div`
  position: fixed;
  display: none;
  padding: 6px 20px 0 20px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.color.blue_pale};
  z-index: 111;
  background-color: white;
  min-height: 32px;
  box-shadow: 0 5px 12px 4px ${({ theme }) => theme.color.shadow_6}, 0 3px 6px 0 ${({ theme }) => theme.color.shadow_5},
    0 1px 2px -2px ${({ theme }) => theme.color.shadow_4};

  a {
    color: ${({ theme }) => theme.color.blue_pale};
  }
`;
