import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { REFERENCE_QUANTITY_LIMIT } from 'domains/basket/Basket.types';
import { DeleteAltButton, Flex, MarginBox, MinusButton, PlusButton } from 'UI';
import { ONCHANGE_DEBOUNCE_INTERVAL } from 'utils/hooks/useInput';
import { QtyButton, QtyInput } from './QuantityModule.styled';

export type QuantityModuleUpdateComponentType = 'TRASH' | 'BUTTON' | 'INPUT';
const min750ChunkValue = 750;

interface QuantityModuleProps {
  value: number;
  onChange: (val: number, type?: QuantityModuleUpdateComponentType) => void;
  showDelete?: boolean;
  precisionMin?: number;
  precisionMax?: number;
  noDebounce?: boolean;
  disabled?: boolean;
  maxValue?: number;
  onMaxValueOverflow?: () => void;
  chunk?: number;
  isMin750Chunk?: boolean;
  size?: 'small' | 'middle' | 'large';
}

function QuantityModuleLocal({
  value,
  onChange,
  showDelete,
  precisionMin,
  precisionMax,
  noDebounce = false,
  disabled = false,
  maxValue,
  onMaxValueOverflow,
  chunk = 1,
  isMin750Chunk = false,
  size = 'middle',
}: Readonly<QuantityModuleProps>) {
  const [inputValue, setInputValue] = useState(value);
  const [inputKey, setInputKey] = useState(0);
  const debouncedOnChange = useDebouncedCallback(onChange, ONCHANGE_DEBOUNCE_INTERVAL);
  const maxValueLocal = maxValue ?? REFERENCE_QUANTITY_LIMIT;
  const [isInteracting, setIsInteracting] = useState(false); // Track if the user is interacting
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to the timeout

  const getChunk = () => (isMin750Chunk ? min750ChunkValue : chunk);

  const clearUpdateTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  // Schedule the update after user interaction
  const scheduleUpdate = useCallback(() => {
    clearUpdateTimeout(); // Clear any previous timeout

    timeoutRef.current = setTimeout(() => {
      if (!isInteracting) {
        setInputValue(value);
      }
      setIsInteracting(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!isInteracting) {
      setInputValue(value);
    } else {
      scheduleUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInteracting]);

  // Handle immediate updates from external value changes
  useEffect(() => {
    const isMaxValueOverflow = value > maxValueLocal;

    if (!isInteracting) {
      setInputValue(isMaxValueOverflow ? maxValueLocal : value);
    } else {
      scheduleUpdate();
    }

    isMaxValueOverflow && setInputValue(isMaxValueOverflow ? maxValueLocal : value);
    if (isMaxValueOverflow && onMaxValueOverflow) {
      onMaxValueOverflow();
    }
    return () => clearUpdateTimeout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, maxValueLocal, onMaxValueOverflow]);

  const handleChange = useCallback(
    (val: number, type?: QuantityModuleUpdateComponentType, noDebounceLocal = false) => {
      setInputValue(val);
      noDebounce || noDebounceLocal ? onChange(val, type) : debouncedOnChange(val, type);
      setIsInteracting(true);
      scheduleUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noDebounce, onChange, debouncedOnChange],
  );

  const handleClickDeleteButton = () => {
    const call = showDelete ? inputValue > 0 : inputValue > 1;
    call && handleChange(0, 'TRASH', true);
  };

  const handleClickMinusButton = () => {
    const call = showDelete ? inputValue > 0 : inputValue > 1;
    call && handleChange(inputValue - chunk, 'BUTTON', false);
  };

  const handleClickPlusButton = () => {
    inputValue < maxValueLocal && handleChange(inputValue + chunk, 'BUTTON', false);
  };

  const handleOnBlur = (val: number | undefined) => {
    if (val === undefined || val === inputValue) {
      return;
    }
    const newValue =
      precisionMax && precisionMax > 0
        ? calculateRoundedValue(val, precisionMax)
        : calculateChunkedValue(val, getChunk());

    if (newValue === inputValue) {
      setInputKey((prev) => prev + 1);
    }
    handleChange(newValue, 'INPUT', true);

    // Mark as no longer interacting (if blur happens)
    setIsInteracting(false);
    clearUpdateTimeout(); // Clear timeout when the user blurs the input
  };

  const calculateRoundedValue = (val: number, precision: number): number => {
    const decimalFactor = Math.pow(10, precision);
    return Math.round(val * decimalFactor) / decimalFactor;
  };

  const calculateChunkedValue = (val: number, chunkModifier: number): number =>
    Math.floor(val / chunkModifier) * chunkModifier || chunkModifier;

  // Cleanup the timeout on component unmount
  useEffect(() => {
    return () => {
      clearUpdateTimeout(); // Clear the timeout when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex size={0} minWidth={size === 'large' ? 250 : undefined} justify={size === 'large' ? 'center' : undefined}>
      <QtyButton>
        {showDelete && inputValue <= getChunk() ? (
          <DeleteAltButton
            onClick={handleClickDeleteButton}
            disabled={disabled || showDelete ? false : inputValue === getChunk()}
          />
        ) : (
          <MinusButton
            onClick={handleClickMinusButton}
            disabled={disabled || showDelete ? false : inputValue === getChunk()}
          />
        )}
      </QtyButton>
      <QtyInput
        key={`input-key-${inputKey}`}
        value={inputValue}
        bordered
        precisionMin={precisionMin}
        precisionMax={precisionMax}
        min={0}
        max={maxValueLocal}
        onBlur={handleOnBlur}
        readOnly={disabled}
      />
      <MarginBox ml={10}>
        <QtyButton>
          <PlusButton
            color={'brand_black'}
            onClick={handleClickPlusButton}
            disabled={disabled || inputValue >= maxValueLocal}
            dataCy={'increase-quantity-button'}
          />
        </QtyButton>
      </MarginBox>
    </Flex>
  );
}

export const QuantityModule = React.memo(QuantityModuleLocal);
