import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { TimesCircleIcon } from 'assets/icons';
import { resetReturnRequest } from 'domains/order/Order.store';
import { OrderItemLocal } from 'domains/order/Order.types';
import OrderReferenceReturnCard from 'pages/MyOrdersPage/OrderReturnSection/OrderReferenceReturnCard';
import { ReturnBackground } from 'pages/MyOrdersPage/OrderReturnSection/OrderReturnSection.styled';
import { useOrderReturnsEnabledByR1 } from 'pages/MyOrdersPage/useOrderReturnsEnabledByR1';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Text } from 'UI';

export const OrderReturnSection = ({
  order,
  substituteInlineInfo,
}: {
  order: OrderItemLocal;
  substituteInlineInfo?: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const orderReturnEnabled = useOrderReturnsEnabledByR1(order?.userInfo?.sellerId, order?.userInfo?.repository);

  function cancelReturn() {
    history.push(`${ROUTER_IN_PROGRESS_ORDERS}/${order.internalOrderId}`);
    dispatch(resetReturnRequest(order.internalOrderId));
  }

  if (!orderReturnEnabled) {
    return <></>;
  }

  return (
    <>
      <Box width={600}>
        <Text type={'light_14_black_65'}>
          {t(
            'order.order_detail.return_request.description',
            // eslint-disable-next-line max-len
            "We know that sometimes things don't go as planned. Rest assured, as long as an item is still in its original condition, you can make returns free of charge, subject to compliance with the conditions of use.",
          )}
        </Text>
      </Box>
      <MarginBox mt={45} />
      <ReturnBackground>
        <Flex>
          <Text type={'h4_paragraph'}> {t('order.order_detail.return_request.title', 'Request return')}</Text>
          <Flex justify={'flex-end'} align={'center'}>
            <Text type={'link_14_red'} cursor={'pointer'} decoration={'underline'} onClick={cancelReturn}>
              {t('order.order_detail.return_request.cancel_request', 'Cancel request')}
            </Text>
            <MarginBox mr={15} />
            <Icon IconComponent={TimesCircleIcon} color={theme.color.grey35} size={16} onClick={cancelReturn} />
          </Flex>
        </Flex>
        <MarginBox mt={55} />
        <Flex direction={'column'}>
          {order.orderedReferences.map((reference, index) => {
            return (
              <OrderReferenceReturnCard
                key={reference.referenceNumber}
                index={index}
                reference={reference}
                order={order}
                substituteInlineInfo={substituteInlineInfo}
              />
            );
          })}
        </Flex>
      </ReturnBackground>
    </>
  );
};
