import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { EstimateBase } from './EstimateBase';

export const EstimateRouter = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTER_ESTIMATE}`} render={() => <EstimateBase />} />
    </Switch>
  );
};
