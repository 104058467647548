import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { SendEmailFromGarage } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailFromGarage';
import { ReturnEmailResponse } from '@1po/1po-bff-fe-spec/generated/order/response/ReturnEmailResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import {
  sendEmailDocumentationAlertMotrio,
  sendEmailFromGarageRequest,
  sendEmailReturnRequestRequest,
  sendEmailToAssistanceRequest,
} from 'domains/email/Email.api';
import { sendEmailToAssistanceMapper } from 'domains/email/Email.mapper';
import { updateReturnedReferenceQty } from 'domains/order/Order.store';
import { notifyTop } from 'UI/Notification/notification';
import { AppTranslation, isLoading, LOADING, sagaGuard, select } from 'utils';
import * as actions from './Email.store';
import {
  getEmailDocumentationAlertMotrioData,
  getEmailFromGarageData,
  getEmailToAssistanceData,
  setEmailDocumentationAlertMotrioDataStatus,
  setEmailToAssistanceDataStatus,
} from './Email.store';
import { SENT } from './Email.types';

function* sendEmailFromGarageRequestSaga(): SagaIterator {
  const emailData = yield* select(getEmailFromGarageData);
  if (!isLoading(emailData.attachments)) {
    yield put(sendEmailFromGarageRequest(emailData as SendEmailFromGarage));
  }
}

function* sendEmailReturnRequestRequestSaga({
  payload,
}: ReturnType<typeof actions.sendEmailReturnRequestRequestSaga>): SagaIterator {
  yield put(sendEmailReturnRequestRequest(payload));
}

function* sendEmailReturnRequestResponseSaga(action: WsResponse<ReturnEmailResponse>): SagaIterator {
  yield put(
    updateReturnedReferenceQty({
      orderId: action.payload.orderId,
      references: action.payload.referencesToReturn,
    }),
  );
  yield call(notifyTop, 'success', AppTranslation.t('email.send.success', 'Email sent'), null);
}

function* sendEmailFromGarageResponseSaga(): SagaIterator {
  yield call(notifyTop, 'success', AppTranslation.t('email.send.success', 'Email sent'), null);
}

function* sendEmailToAssistanceRequestSaga({
  payload,
}: ReturnType<typeof actions.sendEmailToAssistanceRequestSaga>): SagaIterator {
  const emailData = sendEmailToAssistanceMapper(yield* select(getEmailToAssistanceData), payload);
  if (emailData) {
    yield put(setEmailToAssistanceDataStatus(LOADING));
    yield put(sendEmailToAssistanceRequest(emailData));
  }
}

function* sendEmailToAssistanceResponseSaga(): SagaIterator {
  yield put(setEmailToAssistanceDataStatus(SENT));
}

function* sendEmailDocumentationAlertMotrioRequestSaga(): SagaIterator {
  const emailData = yield* select(getEmailDocumentationAlertMotrioData);
  if (
    emailData &&
    emailData.request !== undefined &&
    emailData.email !== undefined &&
    emailData.socialReason !== undefined &&
    emailData.companyRegistrationNumber !== undefined &&
    emailData.vehicleRegistrationNumber !== undefined
  ) {
    yield put(setEmailDocumentationAlertMotrioDataStatus(LOADING));
    yield put(
      sendEmailDocumentationAlertMotrio({
        ...emailData,
        request: emailData.request,
        email: emailData.email,
        socialReason: emailData.socialReason,
        companyRegistrationNumber: emailData.companyRegistrationNumber,
        vehicleRegistrationNumber: emailData.vehicleRegistrationNumber,
      }),
    );
  }
}

function* sendEmailDocumentationAlertMotrioResponseSaga(): SagaIterator {
  yield put(setEmailDocumentationAlertMotrioDataStatus(SENT));
}

export function* EmailSagas(): SagaIterator {
  yield takeEvery(actions.sendEmailFromGarageRequestSaga.type, sagaGuard(sendEmailFromGarageRequestSaga));
  yield takeEvery(actions.sendEmailReturnRequestRequestSaga.type, sagaGuard(sendEmailReturnRequestRequestSaga));
  yield takeEvery(actions.sendEmailFromGarageResponseSaga.type, sagaGuard(sendEmailFromGarageResponseSaga));
  yield takeEvery(actions.sendEmailReturnRequestResponseSaga.type, sagaGuard(sendEmailReturnRequestResponseSaga));
  yield takeEvery(actions.sendEmailToAssistanceRequestSaga.type, sagaGuard(sendEmailToAssistanceRequestSaga));
  yield takeEvery(actions.sendEmailToAssistanceResponseSaga.type, sagaGuard(sendEmailToAssistanceResponseSaga));
  yield takeEvery(
    actions.sendEmailDocumentationAlertMotrioRequestSaga.type,
    sagaGuard(sendEmailDocumentationAlertMotrioRequestSaga),
  );
  yield takeEvery(
    actions.sendEmailDocumentationAlertMotrioResponseSaga.type,
    sagaGuard(sendEmailDocumentationAlertMotrioResponseSaga),
  );
}
