import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { useTheme } from 'styled-components';
import { TruckIcon } from 'assets/icons';
import { Flex, Icon, Text } from 'UI';
import { getData, NO_DATA } from 'utils';

export function isDelayedStock(stock: NO_DATA | ReferenceStock): boolean {
  const stockData = getData(stock);
  const stockDelayed = stockData?.warehouses.find((s) => s.type === 'DELAYED');
  return stockData?.warehouses.length === 1 && stockDelayed !== undefined;
}

export function DelayedStock() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Flex dataCy={'order-stock'}>
      <Icon IconComponent={TruckIcon} size={20} mt={4} mr={5} display={'block'} color={theme.color.warning} noPointer />
      <Text type={'light_14_black_65'} displayStyle={'warning'}>
        {t('common.restocking', 'Restocking')}
      </Text>
    </Flex>
  );
}
