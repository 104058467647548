/* eslint-disable max-len */
import { Essay } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/model/LaborTime';
import { TFunction } from 'i18next';

export function hasTest(essay: Essay | undefined): boolean {
  if (essay === undefined) return false;
  switch (essay) {
    case 'BASIC_ROAD_TEST':
    case 'EXTENDED_ROAD_TEST':
    case 'PLACE_ON_BENCH':
    case 'REDUCED_TEST':
      return true;
    case 'NO_TEST':
      return false;
  }
}

export function getTestCode(essay: Essay | undefined): string {
  if (essay === undefined) return 'undefined';
  switch (essay) {
    case 'BASIC_ROAD_TEST':
      return '0129';
    case 'EXTENDED_ROAD_TEST':
      return '0130';
    case 'PLACE_ON_BENCH':
      return '';
    case 'REDUCED_TEST':
      return '0145';
    case 'NO_TEST':
      return 'no_code';
  }
}

export function getTestText(essay: Essay | undefined, t: TFunction): string {
  if (essay === undefined) return 'undefined';
  switch (essay) {
    case 'BASIC_ROAD_TEST':
      return t('catalog.parts.category.car_parts.labor_time.test_code.basic_road_test', 'Basic road test');
    case 'EXTENDED_ROAD_TEST':
      return t('catalog.parts.category.car_parts.labor_time.test_code.extended_road_test', 'Extended road test');
    case 'PLACE_ON_BENCH':
      return t('catalog.parts.category.car_parts.labor_time.test_code.place_on_test_bench', 'Place on test bench');
    case 'REDUCED_TEST':
      return t(
        'catalog.parts.category.car_parts.labor_time.test_code.operating_or_reduced_test',
        'Operational or reduced test',
      );
    case 'NO_TEST':
      return 'no_text';
  }
}
