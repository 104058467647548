/* eslint-disable max-len */
import { GARAGE_EMAIL_UPDATE, GET_GARAGE_INFO_RESPONSE } from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { Garage } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { getFirstValidAddress } from 'domains/garage/Garage.utils';
import { DealerRepository, getTradingProfile } from 'domains/user';
import { getData, NO_DATA } from 'utils';
import { CHANGE_GARAGE_EMAIL, GARAGE_NAMESPACE, GarageState, GET_GARAGE_INFO } from './Garage.types';

const initialState: GarageState = {
  garages: new Map<string, Garage | NO_DATA>(),
};

export const fetchGarageInfoRequest = createAction<{ garageId: string; repository: DealerRepository }>(GET_GARAGE_INFO);
export const fetchGarageInfoResponse = createAction(GET_GARAGE_INFO_RESPONSE);
export const fetchChangeGarageEmailRequest = createAction<string>(CHANGE_GARAGE_EMAIL);
export const fetchGarageEmailUpdate = createAction<string>(GARAGE_EMAIL_UPDATE);

// Slice
const slice = createSlice({
  name: GARAGE_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setGarage: (state, action: PayloadAction<{ garageId: string; garage: Garage | NO_DATA }>) => {
      const { payload } = action;
      state.garages.set(payload.garageId, payload.garage);
    },
    setGarageNoDataStatus: (state, action: PayloadAction<{ garageId: string; status: NO_DATA }>) => {
      const { garageId, status } = action.payload;
      state.garages.set(garageId, status);
    },
    setGarageEmail: (state, action: PayloadAction<{ garageId: string; email: string }>) => {
      const { garageId, email } = action.payload;
      const garage = getData(state.garages.get(garageId));
      if (garage === undefined) return;
      const address = getFirstValidAddress(garage);
      if (address) {
        address.email = email;
      }
    },
  },
});

//Actions
export const { setInitialState, setGarage, setGarageNoDataStatus, setGarageEmail } = slice.actions;

// Getters/Selectors
export const getUserGarageInfo = createSelector(
  (state: RootState) => state.garage.garages,
  getTradingProfile,
  (garages, tradingProfile): Garage | NO_DATA => {
    return garages.get(tradingProfile?.buyerId ?? '');
  },
);

export const getGarageInfo = createSelector(
  (state: RootState) => state.garage.garages,
  (_state: RootState, garageId: string) => garageId,
  (garages, garageId) => garages.get(garageId),
);

// Export reducer
export default slice.reducer;
