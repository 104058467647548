import React from 'react';
import { useTranslation } from 'react-i18next';
import TextEditor from 'components/TextEditor';
import { CreatePromotionStepProps } from 'domains/promotion/Promotion.types';
import { Flex, Input, MarginBox, StepButtons, Text, Space } from 'UI';
import { AdditionalPromotionComponent } from './AdditionalPromotionComponent';

export const DescriptionStep = ({
  promotion,
  setPromotion,
  currentStep,
  setCurrentStep,
  totalSteps,
  validateStep,
}: CreatePromotionStepProps) => {
  const { t } = useTranslation();
  const placeholder = t('common.input.text.placeholder', 'Type your text here');

  const renderTitle = () => {
    return (
      <Flex direction={'column'}>
        <Text type={'h2'}>{t('backoffice.promotion', 'Promotion title*')}</Text>
        <AdditionalPromotionComponent
          promotionType={promotion.promotionType}
          defaultComponent={() => (
            <Flex maxWidth={450}>
              <Input
                initialValue={promotion.title}
                onChange={(val) => setPromotion({ ...promotion, title: val })}
                placeholder={placeholder}
                bordered
                maxLength={63}
              />
            </Flex>
          )}
          additionalComponent={() => (
            <Flex maxWidth={450}>
              <Input
                initialValue={promotion.additionalInformation?.title}
                onChange={(val) =>
                  setPromotion({
                    ...promotion,
                    additionalInformation: { ...promotion.additionalInformation, title: val },
                  })
                }
                placeholder={placeholder}
                bordered
                maxLength={63}
              />
            </Flex>
          )}
          handleAddAdditionalComponent={() =>
            setPromotion({
              ...promotion,
              additionalInformation: { ...promotion.additionalInformation, containsAdditionalTitle: true },
            })
          }
          handleRemoveAdditionalComponent={() =>
            setPromotion({
              ...promotion,
              additionalInformation: {
                ...promotion.additionalInformation,
                containsAdditionalTitle: false,
                title: undefined,
              },
            })
          }
          showAdditionalComponent={promotion.additionalInformation.containsAdditionalTitle}
        />
      </Flex>
    );
  };

  const renderCatchLine = () => {
    return (
      <Flex direction={'column'}>
        <Text type={'h2'}>{t('backoffice.promotion.catch_line', 'Catch line*')}</Text>
        <AdditionalPromotionComponent
          promotionType={promotion.promotionType}
          defaultComponent={() => (
            <Flex maxWidth={450}>
              <Input
                initialValue={promotion.catchLine}
                onChange={(val) => setPromotion({ ...promotion, catchLine: val })}
                placeholder={placeholder}
                bordered
                maxLength={108}
              />
            </Flex>
          )}
          additionalComponent={() => (
            <Flex maxWidth={450}>
              <Input
                initialValue={promotion.additionalInformation?.catchLine}
                onChange={(val) =>
                  setPromotion({
                    ...promotion,
                    additionalInformation: { ...promotion.additionalInformation, catchLine: val },
                  })
                }
                placeholder={placeholder}
                bordered
                maxLength={108}
              />
            </Flex>
          )}
          handleAddAdditionalComponent={() =>
            setPromotion({
              ...promotion,
              additionalInformation: { ...promotion.additionalInformation, containsAdditionalCatchLine: true },
            })
          }
          handleRemoveAdditionalComponent={() =>
            setPromotion({
              ...promotion,
              additionalInformation: {
                ...promotion.additionalInformation,
                containsAdditionalCatchLine: false,
                catchLine: undefined,
              },
            })
          }
          showAdditionalComponent={promotion.additionalInformation.containsAdditionalCatchLine}
        />
      </Flex>
    );
  };

  const renderDescription = () => {
    return (
      <Flex direction={'column'}>
        <Text type={'h2'}>{t('backoffice.promotion.description', 'Description')}</Text>
        <AdditionalPromotionComponent
          promotionType={promotion.promotionType}
          defaultComponent={() => (
            <Flex maxWidth={450}>
              <TextEditor
                width={450}
                value={promotion.description}
                onChange={(val) => setPromotion({ ...promotion, description: val })}
                placeholder={placeholder}
                preserveWhitespace
              />
            </Flex>
          )}
          additionalComponent={() => (
            <Flex maxWidth={450}>
              <TextEditor
                width={450}
                value={promotion.additionalInformation?.description}
                onChange={(val) =>
                  setPromotion({
                    ...promotion,
                    additionalInformation: { ...promotion.additionalInformation, description: val },
                  })
                }
                placeholder={placeholder}
                preserveWhitespace
              />
            </Flex>
          )}
          handleAddAdditionalComponent={() =>
            setPromotion({
              ...promotion,
              additionalInformation: { ...promotion.additionalInformation, containsAdditionalDescription: true },
            })
          }
          handleRemoveAdditionalComponent={() =>
            setPromotion({
              ...promotion,
              additionalInformation: {
                ...promotion.additionalInformation,
                containsAdditionalDescription: false,
                description: undefined,
              },
            })
          }
          showAdditionalComponent={promotion.additionalInformation.containsAdditionalDescription}
        />
        <MarginBox mt={15} />
      </Flex>
    );
  };

  const getDescriptionItems = () => {
    switch (promotion.promotionType) {
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
        return <>{renderTitle()}</>;
      case 'DISCOUNT':
      case 'PROMOTION_FLASH_TIME_LIMITED':
      case 'BANNER':
      default:
        return (
          <>
            {renderTitle()}
            {renderCatchLine()}
            {renderDescription()}
          </>
        );
    }
  };

  return (
    <Space direction={'vertical'} size={45}>
      <MarginBox />
      {getDescriptionItems()}
      <Flex>
        <StepButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalItems={totalSteps}
          nextEnabled={validateStep()}
        />
      </Flex>
    </Space>
  );
};
