import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WasteRecyclingField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateWasteRecycling';
import { WasteRecycling } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { RootState } from 'app/AppStore';
import { getWasteRecycling, removeWasteRecycling, updateWasteRecycling } from 'domains/estimate/Estimate.store';
import { EstimatePriceInput, GetTitle, TextInput, VatInput } from 'pages/EstimatePage/TableSection/tableComponents';
import { TableProps } from 'pages/EstimatePage/TableSection/TableSection';
import { STable } from './TableSection.styled';

export default function WasteRecyclingTable({ estimateId }: TableProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector((state: RootState) => getWasteRecycling(state, estimateId));

  function removeRow(rowId: string) {
    dispatch(removeWasteRecycling(rowId));
  }

  const handleUpdate = (itemId: string, field: WasteRecyclingField) => (newValue: string) => {
    dispatch(updateWasteRecycling({ itemId, field, newValue }));
  };

  const tableColumns = [
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'designation',
      render: function rowSelector(designation: string, row: WasteRecycling) {
        return (
          <TextInput
            value={designation}
            saveFunction={handleUpdate(row.itemId, 'DESIGNATION')}
            removeRow={() => removeRow(row.itemId)}
          />
        );
      },
    },
    {
      title: GetTitle(t('common.price.vat_excl_price', 'VAT excl.Price')),
      dataIndex: 'priceVatExcluded',
      render: function rowSelector(priceVatExcluded: string, row: WasteRecycling) {
        return (
          <EstimatePriceInput value={priceVatExcluded} saveFunction={handleUpdate(row.itemId, 'VAT_EXCL_PRICE')} />
        );
      },
      width: 250,
    },
    {
      title: GetTitle(t('common.price.vat.title', 'VAT')),
      dataIndex: 'vatPercentage',
      render: function rowSelector(vat: number, row: WasteRecycling) {
        return <VatInput value={vat} saveFunction={handleUpdate(row.itemId, 'VAT_PERCENTAGE')} />;
      },
      width: 250,
    },
  ];

  if (data.length === 0) {
    return null;
  }
  return (
    <STable<WasteRecycling>
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      rowKey={(row) => row.itemId}
      size={'large'}
    />
  );
}
