import React from 'react';
import { Trans } from 'react-i18next';
import { FolderOpenIcon } from 'assets/icons';
import { Box, EmptyDialog, Flex, MarginBox, Text, WhiteButton } from 'UI';
import { notifyNotImplemented } from 'UI/Notification/notification';
import { textFormatter } from 'utils';
import { BrowseList } from './BrowseList';
import { DetailButton, DetailDot, Overlay, SVideoItem } from './Tutorial.styled';

export interface TutorialData {
  title: string;
  published?: Date;
  author: string;
  section: string;
  category: string;
  img?: string;
}

export interface TutorialProps {
  tutorial: TutorialData;
}

export const Tutorial = ({ tutorial }: TutorialProps) => {
  const { title, published, author, section, category } = tutorial;

  return (
    <Box>
      <SVideoItem onClick={notifyNotImplemented}>
        <DetailButton>
          <DetailDot />
          <DetailDot />
          <DetailDot />
        </DetailButton>
        <Overlay>
          <MarginBox mx={30} my={40}>
            <Text type={'h2_white'} cursor={'pointer'}>
              {author}
            </Text>
            <Text type={'h5_light_white'} cursor={'pointer'}>
              {`${section}/${category}`}
            </Text>
          </MarginBox>
        </Overlay>
      </SVideoItem>
      <MarginBox mt={10}>
        <Box height={22}>
          <Text type={'section_bold'}>{title}</Text>
        </Box>
        <Box height={22}>
          {published && (
            <Text type={'description'}>{`Published on: ${textFormatter.formatDateNumeric(published)}`}</Text>
          )}
        </Box>
      </MarginBox>
    </Box>
  );
};

export const TutorialList = ({ data }: { data: TutorialData[] }) => (
  <>
    <BrowseList
      data={data}
      Empty={() => (
        <EmptyDialog
          title={
            <Trans i18nKey="backoffice.assistance.tutorial.is_empty_title">
              {"You don't have any tutorials in your folders"}
            </Trans>
          }
          description={
            <Trans i18nKey="backoffice.assistance.tutorial.is_empty_description">
              Tutorials shared with you will appear here.
            </Trans>
          }
          image={<FolderOpenIcon />}
        />
      )}
      expandable
    />
    <MarginBox mt={45}>
      <Flex justify={'center'}>
        <Box width={160}>
          <WhiteButton onClick={notifyNotImplemented}>
            <Trans i18nKey="common.action.show_more">Show more</Trans>
          </WhiteButton>
        </Box>
      </Flex>
    </MarginBox>
  </>
);

export const RecentList = ({ data }: { data: TutorialData[] }) => (
  <BrowseList
    data={data}
    Empty={() => (
      <EmptyDialog
        title={
          <Trans i18nKey="backoffice.assistance.recent.is_empty_title">
            {"You don't have any tutorials or FAQs in your folders"}
          </Trans>
        }
        description={
          <Trans i18nKey="backoffice.assistance.recent.is_empty_description">
            Tutorials and FAQs shared with you will appear here.
          </Trans>
        }
        image={<FolderOpenIcon />}
      />
    )}
  />
);
