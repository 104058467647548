import { TFunction } from 'i18next';
import { notifyTop } from 'UI/Notification/notification';

type ErrorType = 'reference' | 'fullText';

const getErrorMessage = (type: ErrorType, t: TFunction): string => {
  switch (type) {
    case 'reference':
      return t('catalog.search.unknown_reference_notification', 'No result. Please type a valid reference number.');
    case 'fullText':
      return t('catalog.search.keyword_search_failed_notification.description', 'No result found for your search');
    default:
      return '';
  }
};

export const notifySearchError = ({ type, t }: { type: ErrorType; t: TFunction }) => {
  notifyTop('error', getErrorMessage(type, t), undefined);
};
