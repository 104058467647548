import React from 'react';

import { ErrorBoundaryDisplay, ErrorBoundaryDisplayProps } from 'composers/ErrorBoundary/ErrorBoundaryDisplay';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  reportError?: (error: string, info: string, label?: string) => void;
  displayError?: React.FunctionComponent<ErrorBoundaryDisplayProps> | null;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string | null;
  errorStack: string | null;
}

/**
 * Since React 16, errors that were not caught by any error boundary
 * will result in unmounting of the whole React component tree.
 * ErrorBoundary avoid catch every error inside of it.
 *
 * Error boundaries do not catch errors inside event handlers.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static readonly defaultProps: Partial<ErrorBoundaryProps> = {
    displayError: ErrorBoundaryDisplay,
  };

  public state: ErrorBoundaryState = {
    hasError: false,
    errorMessage: null,
    errorStack: null,
  };

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const { reportError } = this.props;

    const errorMessage = error?.toString();
    const errorStack = info.componentStack ?? '';

    if (reportError) {
      reportError(errorMessage, errorStack, 'ERROR');
    }

    this.setState({ hasError: true, errorMessage, errorStack });
  }

  private renderError() {
    const { displayError: DisplayErrorComponent } = this.props;
    const { errorMessage, errorStack } = this.state;

    if (!DisplayErrorComponent) {
      return null;
    }

    return <DisplayErrorComponent error={errorMessage} stack={errorStack} />;
  }

  public render() {
    const { hasError } = this.state;
    if (hasError) {
      return this.renderError();
    }

    const { children } = this.props;
    return children;
  }
}
