import {
  DealerType,
  Information,
  StatusType,
  TargetCountry,
  TargetPage,
  TextSection,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { InformationBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA, SEARCH_STATUS } from 'utils';

export const INFORMATION_NAMESPACE = `${APP_NAMESPACE}/INFORMATION`;
export const SAVE_INFORMATION_REQUEST = `${INFORMATION_NAMESPACE}/SAVE_INFORMATION_REQUEST`;
export const PUBLISH_INFORMATION_REQUEST = `${INFORMATION_NAMESPACE}/PUBLISH_INFORMATION_REQUEST`;
export const ARCHIVE_INFORMATION_REQUEST = `${INFORMATION_NAMESPACE}/ARCHIVE_INFORMATION_REQUEST`;
export const GET_INFORMATION_REQUEST = `${INFORMATION_NAMESPACE}/GET_INFORMATION_REQUEST`;
export const GET_INFORMATIONS_REQUEST = `${INFORMATION_NAMESPACE}/GET_INFORMATIONS_REQUEST`;
export const GET_INFORMATION_BANNERS_REQUEST = `${INFORMATION_NAMESPACE}/GET_INFORMATION_BANNERS_REQUEST`;
export const SAVE_INFORMATION_TEMPLATE_REQUEST = `${INFORMATION_NAMESPACE}/SAVE_INFORMATION_TEMPLATE_REQUEST`;

export const STATUS_SAVED = 'SAVED';
export const STATUS_PLANNED = 'PLANNED';
export const STATUS_PUBLISHED = 'PUBLISHED';
export const STATUS_ARCHIVED = 'ARCHIVED';

export interface CreateInformationModel {
  informationId: string;
  createdBy?: string;
  created?: string;
  status?: StatusType;
  textSection: TextSection[];
  targetAudience?: DealerType[];
  targetCountries?: TargetCountry[];
  targetPages?: TargetPage[];
  publicationStart?: string;
  publicationEnd?: string;
}

export interface CreateInformationTemplateModel {
  informationId: string;
  createdBy?: string;
  created?: string;
  status?: StatusType;
  textSection: TextSection[];
  targetAudience?: DealerType[];
  targetCountries?: TargetCountry[];
  targetPages?: TargetPage[];
}

export interface InformationState {
  publishedResponseMap: Map<string, boolean | NO_DATA>;
  createInformation: CreateInformationModel;
  informationDetails: Map<string, Information | NO_DATA>;
  loadedInformations: {
    published: LoadedInformations;
    planned: LoadedInformations;
    saved: LoadedInformations;
    archived: LoadedInformations;
  };
  informationBanners: InformationBanner[] | NO_DATA;
  createInformationTemplate: CreateInformationTemplateModel;
}

export interface LoadedInformations {
  // ToDo - remove unnecessary data
  data: Information[];
  cursor: string | undefined;
  hasNextPage: boolean;
  status: SEARCH_STATUS;
}
