import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Text } from 'UI';

export const SText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
`;

const hoverStyle = css`
  span {
    color: ${theme.color.red};
  }
`;

export const ActionWrapper = styled.div<{ hoverRed?: boolean }>`
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;

  :hover {
    background-color: ${theme.color.grey95};
    cursor: pointer;

    ${({ hoverRed }) => hoverRed && hoverStyle}
  }
`;
