import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ASSISTANCE, ROUTER_HOME } from 'app/AppRouter';
import { OktaLogoIcon, RenaultLogoIcon, RenaultNetIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import ForgottenPassword, { isPasswordRetrievalAllowed } from 'components/Login/LoginForm/ForgottenPassword';
import { getCredentials, getCredentialsSearchState } from 'domains/auth/Auth.store';
import {
  ARCA_LOGIN,
  CREDENTIALS_LOCAL_PARAM,
  IDP,
  ISSUER_LOCAL_PARAM,
  KEYCLOAK,
  LOGIN_METHOD_LOCAL_PARAM,
  OKTA,
  RNET_LOGIN,
} from 'domains/auth/Auth.types';
import { authenticateAndRedirect } from 'domains/auth/Auth.util';
import { getTokenUser } from 'domains/user';
import { CenteredSpin, Flex, Icon, IconType, Link, MarginBox, Text, WhiteButton, YellowButton } from 'UI';
import { LoginFormWrapper, SLoginButtonWrapper, SText } from './LoginForm.styled';

interface LoginButtonContentProps {
  icon?: IconType;
  width: number;
  title: string;
  textType: string;
}

const LoginButtonContent = ({ icon, width, title, textType }: LoginButtonContentProps) => (
  <Flex direction={'row'} justify={'center'}>
    <Flex size={3} align={'center'} justify={'flex-start'} maxHeight={50}>
      {icon && <Icon IconComponent={icon} width={width} />}
    </Flex>
    <Flex size={7} align={'center'} justify={'flex-start'} minWidth={260} maxWidth={260}>
      <SText type={textType} ellipsis>
        {title}
      </SText>
    </Flex>
  </Flex>
);

export interface LoginFormProps {
  returnToHistory?: boolean;
}

const LoginForm = ({ returnToHistory }: LoginFormProps) => {
  const user = useSelector(getTokenUser);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const credentials = useSelector(getCredentials);
  const credentialsSearchStatus = useSelector(getCredentialsSearchState);
  const [showOtherLoginOptions, setShowOtherLoginOptions] = useState<boolean>(false);

  const onLoginHandler = () => {
    sessionStorage.setItem(CREDENTIALS_LOCAL_PARAM, JSON.stringify(credentials));

    if (user) {
      if (returnToHistory) {
        authenticateAndRedirect(dispatch, history);
      } else {
        history.push(ROUTER_HOME);
      }
    } else {
      authenticateAndRedirect(dispatch, history);
    }
  };

  function handleChangeOtherLoginOptions() {
    setShowOtherLoginOptions((prev) => !prev);
  }

  return (
    <LoginFormWrapper data-cy={'login-window'}>
      <Flex direction={'column'} minWidth={400} minHeight={400}>
        <MarginBox mt={45} />
        <Flex justify={'center'} maxHeight={70}>
          <Text type={'h1'}>{t('login.title', 'Log in')}</Text>
        </Flex>
        <MarginBox mt={30} />
        <DataContainer
          data={credentialsSearchStatus}
          Loading={() => (
            <Flex>
              <CenteredSpin size={'large'} />
            </Flex>
          )}
          ErrorState={() => (
            <Flex>
              <ErrorWithLabel />
            </Flex>
          )}
        >
          <MarginBox mt={10} />
          <SLoginButtonWrapper maxHeight={50}>
            <YellowButton
              size={'xlarge'}
              shape={'semiRound'}
              wrap={'wrap'}
              stretch
              onClick={() => {
                sessionStorage.setItem(ISSUER_LOCAL_PARAM, OKTA);
                onLoginHandler();
              }}
            >
              <LoginButtonContent
                icon={OktaLogoIcon}
                width={70}
                title={t('login.okta_connection.description', 'Continue with Okta connection')}
                textType={'text_dim'}
              />
            </YellowButton>
          </SLoginButtonWrapper>
          <MarginBox mt={10} />
          <Flex justify={'center'}>
            <Text
              type={'link'}
              onClick={handleChangeOtherLoginOptions}
              cursor={'pointer'}
              hoverUnderLine
              dataCy={'other-login-options-link'}
            >
              <MarginBox mx={15} my={7}>
                {showOtherLoginOptions
                  ? t('login.other_login_options.button.description.hide', 'Hide Other Options')
                  : t('login.other_login_options.button.description.show', 'See other login options')}
              </MarginBox>
            </Text>
          </Flex>
          <MarginBox mt={10} />
          {showOtherLoginOptions && (
            <SLoginButtonWrapper>
              <WhiteButton
                size={'xlarge'}
                shape={'semiRound'}
                wrap={'wrap'}
                stretch
                onClick={() => {
                  sessionStorage.setItem(LOGIN_METHOD_LOCAL_PARAM, RNET_LOGIN);
                  sessionStorage.setItem(ISSUER_LOCAL_PARAM, IDP);
                  onLoginHandler();
                }}
              >
                <LoginButtonContent
                  icon={RenaultNetIcon}
                  width={94}
                  title={t('login.rnet_connection.button.description', 'Continue with Rnet')}
                  textType={'text_dim_bold'}
                />
              </WhiteButton>
            </SLoginButtonWrapper>
          )}
          {showOtherLoginOptions && (
            <MarginBox mt={30}>
              <SLoginButtonWrapper>
                <WhiteButton
                  size={'xlarge'}
                  shape={'semiRound'}
                  wrap={'wrap'}
                  stretch
                  onClick={() => {
                    sessionStorage.setItem(LOGIN_METHOD_LOCAL_PARAM, ARCA_LOGIN);
                    sessionStorage.setItem(ISSUER_LOCAL_PARAM, IDP);
                    onLoginHandler();
                  }}
                >
                  <LoginButtonContent
                    icon={RenaultLogoIcon}
                    width={70}
                    title={t('login.arca_connection.button.description', 'Continue with Arca for Renault group')}
                    textType={'text_dim'}
                  />
                </WhiteButton>
              </SLoginButtonWrapper>
            </MarginBox>
          )}
          {credentials?.keycloakIssuer && credentials?.keycloakClientId && showOtherLoginOptions && (
            <>
              <MarginBox mt={30} />
              <WhiteButton
                dataCy={'keycloak-login'}
                size={'xlarge'}
                shape={'semiRound'}
                wrap={'wrap'}
                onClick={() => {
                  sessionStorage.setItem(ISSUER_LOCAL_PARAM, KEYCLOAK);
                  onLoginHandler();
                }}
              >
                <Flex justify={'center'}>
                  <Text type={'text_dim'}>{'Keycloak login'}</Text>
                </Flex>
              </WhiteButton>
              <MarginBox mt={30} />
            </>
          )}
        </DataContainer>
        <Flex />
        <Flex direction={'row'} maxHeight={44}>
          <Flex direction={'column'}>
            <Text type={'text_dim'}>{t('login.having_connection_issue ', 'Having connection issue?')}</Text>
            <Link to={ROUTER_ASSISTANCE}>
              <Text type={'link'} hoverUnderLine>
                {t('login.conection_issues', 'Connection issues')}
              </Text>
            </Link>
          </Flex>
          {isPasswordRetrievalAllowed() ? (
            <Flex align={'flex-start'} justify={'flex-end'}>
              <ForgottenPassword narrow />
            </Flex>
          ) : (
            <MarginBox mt={10} />
          )}
        </Flex>
        <MarginBox mt={30} />
      </Flex>
    </LoginFormWrapper>
  );
};
export default LoginForm;
