import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { theme } from 'styles';

interface TextEditorProps {
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: string;
  preserveWhitespace?: boolean;
  width?: number;
}

const SReactQuill = styled(ReactQuill)<{ width: number }>`
  width: ${({ width }) => `${width}px`};
`;

const TextEditor = ({
  value = '',
  onChange,
  placeholder,
  preserveWhitespace = false,
  width = 200,
}: TextEditorProps) => {
  const [val, setVal] = useState(value);

  const modules = {
    toolbar: [
      ['bold', 'underline'],
      [
        {
          color: [
            theme.color.brand_black,
            theme.color.brand,
            theme.color.white,
            theme.color.grey80,
            theme.color.grey60,
            theme.color.grey40,
            theme.color.grey20,
            theme.color.clear_blue,
            theme.color.ice_blue,
            theme.color.grass_green,
            theme.color.off_white,
            theme.color.warning,
            theme.color.very_light_pink,
            theme.color.lipstick,
          ],
        },
      ],
      ['link'],
    ],
  };

  return (
    <SReactQuill
      width={width}
      value={val}
      placeholder={placeholder}
      onChange={(e) => {
        setVal(e);
        onChange?.(e);
      }}
      modules={modules}
      preserveWhitespace={preserveWhitespace}
    />
  );
};

export default TextEditor;
