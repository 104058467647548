import { SendEmailToAssistance } from '@1po/1po-bff-fe-spec/generated/email/request/SendEmailToAssistance';
import { SendEmailToAssistanceLocal } from 'domains/email/Email.types';
import { ContactFormType } from 'pages/AssistancePage/AssistanceCategories/ContactUs/ContactUs';
import { getData, isLoading } from 'utils';

export function sendEmailToAssistanceMapper(
  email: SendEmailToAssistanceLocal,
  type: ContactFormType,
): SendEmailToAssistance | undefined {
  const attachments = getData(email.attachments);
  if (isLoading(email.attachments) || (!email.request && type !== 'car-parts') || !attachments) {
    return undefined;
  }
  return {
    email: email.email,
    subject: email.subject,
    socialReason: email.socialReason,
    body: email.body,
    companyRegistrationNumber: email.companyRegistrationNumber,
    request: email.request,
    attachments,
    vrn: email.vrn,
    sendCopy: email.sendCopy,
    urlLink: email.urlLink,
  };
}
