import React, { ReactNode } from 'react';
import { HeaderWrapper } from 'pages/MyStorePage/Pages/Bundles/Bundles.styled';
import { Flex, StickyBoxCustom } from 'UI';
import { OFFSET_HEADER } from 'utils/hooks/useOffsetTop';
import { MY_STORE_HEADER_HEIGHT } from './HeaderMenu/HeaderMenu';

const MyStoreHeader = ({ leftSection, rightSection }: { leftSection?: ReactNode; rightSection?: ReactNode }) => {
  return (
    <StickyBoxCustom offsetTop={OFFSET_HEADER + MY_STORE_HEADER_HEIGHT} zIndex={300}>
      <HeaderWrapper justify={'space-between'}>
        <Flex direction={'column'} justify={'flex-start'}>
          {leftSection && leftSection}
        </Flex>
        <Flex justify={'flex-end'} gap={10} align={'flex-start'}>
          {rightSection && rightSection}
        </Flex>
      </HeaderWrapper>
    </StickyBoxCustom>
  );
};

export default MyStoreHeader;
