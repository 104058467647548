import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrency } from 'domains/user';
import { TextInputProps, TextInputWithReadOnly } from 'pages/EstimatePage/TableSection/tableComponents';
import { TooltipContent } from 'pages/EstimatePage/TableSection/TableSection.styled';
import { SValidatedPriceInput, SValidatedTextInput } from 'pages/MyStorePage/Pages/Bundles/Bundles.styled';
import { Flex, WithTooltip } from 'UI';

export const ValidatedTextInput = ({
  id,
  value,
  name,
  passRef,
  maxLength,
  autoComplete,
  saveFunction,
  minWidth,
  validate,
  onBlur,
  isLarge = false,
  noTooltip,
}: Readonly<TextInputProps> & {
  validate?: (v: string) => boolean;
  isLarge?: boolean;
  noTooltip?: boolean;
}) => {
  const [localVal, setLocalVal] = useState(value ?? '');
  const [isValid, setIsValid] = useState<boolean>((validate && validate(localVal)) ?? true);

  useEffect(() => {
    validate && setIsValid(validate(localVal));
  }, [localVal, validate]);

  useEffect(() => {
    setLocalVal(value ?? '');
  }, [value]);

  return (
    <Flex justify={'center'} align={'center'} minWidth={minWidth}>
      <WithTooltip title={!noTooltip && value} placement={'top'}>
        <TooltipContent>
          <SValidatedTextInput
            id={id}
            name={name}
            autoComplete={autoComplete}
            value={localVal}
            passRef={passRef}
            maxLength={maxLength}
            onChange={(v) => setLocalVal(v)}
            onChangeDebounced={(v) => {
              isValid && saveFunction(v);
            }}
            bordered={!value || value.length === 0}
            ellipsis
            isValid={isValid}
            onBlur={(v) => {
              !isValid && setLocalVal(value ?? '');
              isValid && saveFunction(v);
              onBlur && onBlur(v);
            }}
            isLarge={isLarge}
          />
        </TooltipContent>
      </WithTooltip>
    </Flex>
  );
};

export const ValidatedPriceInput = ({
  value,
  saveFunction,
  minWidth,
  validate,
}: Readonly<TextInputWithReadOnly> & { validate?: (v: string) => boolean }) => {
  const currency = useSelector(getCurrency);
  const [localVal, setLocalVal] = useState(value ?? '');
  const [isValid, setIsValid] = useState<boolean>((validate && validate(localVal)) ?? true);

  useEffect(() => {
    validate && setIsValid(validate(localVal));
  }, [localVal, validate]);

  useEffect(() => {
    setLocalVal(value ?? '');
  }, [value]);

  return (
    <Flex justify={'center'} align={'center'} minWidth={minWidth ?? 80}>
      <WithTooltip title={value} placement={'top'}>
        <TooltipContent>
          <SValidatedPriceInput
            currency={currency}
            value={value}
            onChange={setLocalVal}
            onChangeDebounced={(v) => {
              isValid && v && saveFunction(v);
            }}
            onBlur={(v) => {
              !isValid && setLocalVal(value ?? '');
              v && isValid && saveFunction(v);
            }}
            onBlurDebounced={(v) => {
              isValid && v && saveFunction(v);
            }}
            isValid={isValid}
            bordered
            forceBlur
          />
        </TooltipContent>
      </WithTooltip>
    </Flex>
  );
};
