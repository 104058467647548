import { Table } from 'antd';
import styled from 'styled-components';

export const STable = styled(Table)`
  flex: 1;

  tr > td {
    text-align: left;
    background-color: ${({ theme }) => theme.color.white};
  }
  tbody > tr {
    box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
  }
` as typeof Table;
