import React from 'react';
import { Trans } from 'react-i18next';
import { notifyTop } from 'UI/Notification/notification';

export const validateVINVRNGeneral = (query: string) => {
  if (!query) {
    notifyTop(
      'error',
      <Trans i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn'}>Cannot search for VIN/VRN</Trans>,
      <Trans
        i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn.description1'}
        tOptions={{ length: `${query.length}` }}
      >
        No VIN/VRN Selected
      </Trans>,
    );
    return false;
  }

  return true;
};

export const isVIN = (query: string) => query.length > 14;
export const isVRN = (query: string) => query.length > 4 && query.length < 15;

export const normalize = (query: string) =>
  query
    .replace(/\s/g, '')
    .replace(/[^0-9a-z]/gi, '')
    .toUpperCase();

export const normalizeVIN = (query: string) =>
  query.replace(/\s/g, '').toUpperCase().replace(/I/gi, '1').replace(/O/gi, '0').replace(/Q/gi, '0');

export const validateVIN = (query: string) => {
  if (query.length !== 17) {
    notifyTop(
      'error',
      <Trans i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn'}>Cannot search for VIN/VRN</Trans>,
      <Trans
        i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn.description2'}
        tOptions={{ length: `${query.length}` }}
      >
        {"Query doesn't look like a VIN: query has {{ length }} characters, VIN should have 17 alphanumeric characters"}
      </Trans>,
    );
    return false;
  }
  // requested in https://jira.dt.renault.com/browse/ONEPORTAL-3849
  // if (!query.match(/[a-hj-npr-z0-9]{11}[0-9]{6}/i)) {
  //   notify(
  //     'error',
  //     <Trans i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn'}>Cannot search for VIN/VRN</Trans>,
  //     <Trans i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn.description3'}>
  //       {"Query doesn't look like a VIN: invalid format, VIN should contain up to 11 letters/numbers and 6 numbers"}
  //     </Trans>,
  //   );
  //   return false;
  // }

  return true;
};

export const normalizeVRN = (query: string) => query;

export const validateVRN = (query: string) => {
  if (query.length < 4 || query.length > 15) {
    notifyTop(
      'error',
      <Trans i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn'}>Cannot search for VIN/VRN</Trans>,
      <Trans
        i18nKey={'catalog.vehicle.search_vehicle.error.vin_vrn.description4'}
        tOptions={{ length: `${query.length}` }}
      >
        {"Query doesn't look like a VRN: query has {{ length }} characters"}
      </Trans>,
    );
    return false;
  }

  return true;
};

// IAM example BMM:  121_10585_59068
// DH example BMM: APL03_X55_U55_M9T_HG1
// not BMM, for missing VIN, VRN_CC ,example xxxxxx_NL
export const isQueryBMM = (query: string) =>
  !!query && query.split('_').length > 2 && !isQueryIAMVIN(query) && !isQueryIAMVRN(query);

export const isQueryIAMVIN = (query: string) => !!query && query.split('_').length > 1 && isVIN(query.split('_')[0]);

export const isQueryIAMVRN = (query: string) =>
  !!query && query.split('_').length > 1 && isVRN(query.split('_')[0]) && query.length <= 13;
