import styled from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const BlockFlex = styled(Flex)`
  border-radius: 16px;
  border: solid 1px ${theme.color.grey_light};
`;

export const BlockHeader = styled(Flex)`
  padding: 8px 15px;
  background: ${theme.color.grey_04};
  border-radius: 16px 16px 0 0;
`;

export const BlockBody = styled(Flex)`
  padding: 8px 15px;
`;
