import styled from 'styled-components';
import { defaultBoxShadow, Flex } from 'UI';

export const ReferenceUnavailableWrapperSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.color.shadow_5};
  box-shadow: 0 5px 14px 4px ${({ theme }) => theme.color.shadow_10}, 0 2px 8px 0 ${({ theme }) => theme.color.shadow_6},
    0 2px 3px -2px ${({ theme }) => theme.color.shadow_5};
  border-radius: 4px;
`;

export const ReferenceUnavailableWrapperMedium = styled(ReferenceUnavailableWrapperSmall)`
  height: 80px;
  box-shadow: 0 9px 28px 8px ${({ theme }) => theme.color.shadow_10},
    0 6px 16px 0 ${({ theme }) => theme.color.shadow_6}, 0 3px 6px -4px ${({ theme }) => theme.color.shadow_5};
  border-radius: 8px;
`;

export const ReferenceUnavailableForOrderWrapper = styled(Flex)<{ isProduct?: boolean }>`
  position: relative;
  border-radius: 0 0 14px 14px;
  background-color: ${({ theme }) => theme.color.ice_blue};
  border: 2px solid ${({ theme }) => theme.color.blue_light};
  ${defaultBoxShadow};
`;
