import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { GetExternalBasketResponse } from '@1po/1po-bff-fe-spec/generated/external_basket/response/GetExternalBasketResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import * as actions from 'domains/externalBasket/ExternalBasket.store';
import { addExternalBasket, removeExternalBasket } from 'domains/externalBasket/ExternalBasket.store';
import { getUserContext } from 'domains/user';
import { notifyTop } from 'UI';
import { AppTranslation, sagaGuard, select } from 'utils';
import { sendAttachExternalBasketToUserBasket } from './ExternalBasket.api';

function* fetchExternalBasketResponse(action: WsResponse<GetExternalBasketResponse>): SagaIterator {
  const payload = action.payload;
  yield put(addExternalBasket(payload));
}

function* attachExternalBasketRequest({
  payload,
}: ReturnType<typeof actions.attachExternalBasketRequest>): SagaIterator {
  const { externalBasketId } = payload;
  const userContext = yield* select(getUserContext);
  yield put(sendAttachExternalBasketToUserBasket({ externalBasketId, userContext }));
  yield put(removeExternalBasket({ externalBasketId }));
}

function* attachExternalBasketResponse(action: WsResponse<boolean>): SagaIterator {
  const success = action.payload;
  const message = success
    ? AppTranslation.t('cart.external_basket.attach.result.success.title', 'External basket linked to your account')
    : AppTranslation.t(
        'cart.external_basket.attach.result.failed.title',
        'Failed to link the external basket with your account',
      );
  yield call(notifyTop, success ? 'success' : 'error', message, null);
}

export function* ExternalBasketSagas(): SagaIterator {
  yield takeEvery(actions.fetchExternalBasketResponse.type, sagaGuard(fetchExternalBasketResponse));
  yield takeEvery(actions.attachExternalBasketRequest.type, sagaGuard(attachExternalBasketRequest));
  yield takeEvery(actions.attachExternalBasketResponse.type, sagaGuard(attachExternalBasketResponse));
}
