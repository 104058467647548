import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_ORDER_LIST } from 'app/AppRouter';
import { CREATE_NEW } from 'pages/BackOfficePage/BackOfficePage.type';
import CreateOrderListPage from 'pages/OrderListPage/CreateOrderListPage';

const OrderListRouter = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTER_ORDER_LIST}/${CREATE_NEW}`} render={() => <CreateOrderListPage />} />
    </Switch>
  );
};

export default OrderListRouter;
