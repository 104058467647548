import styled from 'styled-components';
import { Flex } from 'UI';

export const ReferenceCardWrapper = styled.div<{ type: 'catalog' | 'product' }>`
  display: flex;
  width: ${({ type }) => (type === 'catalog' ? '250px' : '210px')};
  background-color: ${({ theme, type }) => (type === 'catalog' ? theme.color.grey95 : '')};
  margin: auto;
  border-radius: 6px;
`;

export const SFlex = styled(Flex)`
  max-width: 167px;
  padding: 5px;
`;
