import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTechnicalCriteriaPdfType } from 'domains/catalog/Catalog.store';
import TechnicalCriteriaPrintPdf from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaPrintPdf';
import { TechnicalCriteria, useGetItems } from 'pages/CatalogPage/IAM/VehicleTechnicalDetails/TechnicalCriteria';
import { Flex, MarginBox, Tab, Tabs, Text } from 'UI';

export function VehicleTechnicalDetails() {
  const { t } = useTranslation();
  const type = useSelector(getTechnicalCriteriaPdfType);
  const criteria = useGetItems();

  const getItem = () => {
    return criteria.items.find((c) => c.type === type);
  };

  return (
    <>
      <MarginBox mt={15} />
      <Flex justify={'space-between'}>
        <Text type={'h1_banner_light'}>
          {t('catalog.details.vehicle_technical_details', 'Vehicle technical details')}
        </Text>
        <TechnicalCriteriaPrintPdf item={getItem()} />
      </Flex>
      <Tabs type={'card'}>
        <Tab key={'1'} tab={t('catalog.details.technical_criteria', 'Technical criteria')}>
          <TechnicalCriteria />
        </Tab>
      </Tabs>
    </>
  );
}
