import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { ROUTER_COOKIES, ROUTER_PERSONAL_DATA, ROUTER_TERMS_AND_CONDITIONS } from 'app/AppRouter';
import { DataContainer } from 'components/DataContainer';
import { fetchBuildVersions, getBuildVersions } from 'domains/appContext/AppContext.store';
import { getCredentials } from 'domains/auth/Auth.store';
import { getUserCountry, getUserProfileSearchStatus } from 'domains/user';
import { env } from 'env';
import { Flex, Link, MarginBox, sentenceCase, Text } from 'UI';
import { Modal } from 'UI/Modal';
import { FOUND, hasData, useSmall } from 'utils';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import feVersion from 'VERSION.json';
import { ManageCookiesButton, PageFooterVersionWrapper, PageFooterWrapper } from './Footer.styled';

const { Footer: AntFooter } = Layout;

export const PageFooter = ({ year, country }: { year: number; country: string | undefined }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const small = useSmall();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const versions = useSelector(getBuildVersions);
  const searchStatus = useSelector(getUserProfileSearchStatus);
  const credentials = useSelector(getCredentials);

  function onClickVersion(): void {
    if (searchStatus === FOUND) {
      dispatch(fetchBuildVersions());
    }
    setIsModalVisible(true);
  }

  return (
    <AntFooter>
      <PageFooterWrapper direction={'row'} align={'center'} basis={9}>
        <Flex direction={'row'} justify={small ? 'center' : 'flex-end'} align={'center'} wrap={'wrap'} size={4}>
          <Text type={'dark_14_white_100'} noWrap>
            &copy; RPartStore {year}
          </Text>
          <MarginBox mr={15} />
          <PageFooterVersionWrapper onClick={onClickVersion}>
            <Text type={'dark_14_white_100'} noWrap hoverUnderLine>{`Version ${feVersion.version}`}</Text>
          </PageFooterVersionWrapper>
        </Flex>
        <MarginBox ml={30} />
        <Flex direction={'row'} justify={small ? 'center' : 'flex-start'} align={'center'} wrap={'wrap'} size={5}>
          <MarginBox mx={7}>
            <Link to={ROUTER_COOKIES(country)} external newTab>
              <Text type={'dark_14_white_100'} noWrap hoverUnderLine>
                {t('footer.cookies', 'Cookies')}
              </Text>
            </Link>
          </MarginBox>
          {credentials?.cookiesManagementEnabled && (
            <MarginBox mx={7}>
              <ManageCookiesButton $color={'white'}>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                  {sentenceCase(t('footer.manage_cookies', 'Manage cookies'))}
                </button>
              </ManageCookiesButton>
            </MarginBox>
          )}
          <MarginBox mx={7}>
            <Link to={ROUTER_PERSONAL_DATA(country)} external newTab>
              <Text type={'dark_14_white_100'} noWrap hoverUnderLine>
                {t('footer.personal_data', 'Personal data')}
              </Text>
            </Link>
          </MarginBox>
          <MarginBox mx={7}>
            <Link to={ROUTER_TERMS_AND_CONDITIONS(country)} external newTab>
              <Text type={'dark_14_white_100'} noWrap hoverUnderLine>
                {t('footer.legal_information', 'Legal information')}
              </Text>
            </Link>
          </MarginBox>
        </Flex>
      </PageFooterWrapper>
      <Modal
        title={
          <MarginBox mx={20} mt={20}>
            <Text type={'h1_banner_light'}>RPartStore {feVersion.version}</Text>
          </MarginBox>
        }
        open={isModalVisible}
        closable
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Flex direction={'column'}>
          <Flex direction={'column'}>
            <Flex>{feVersion.name}</Flex>
            <Flex>Build version: {env.REACT_APP_BUILD_VERSION}</Flex>
            <Flex>Build time: {feVersion.buildDate}</Flex>
          </Flex>
          <DataContainer data={versions}>
            {hasData(versions) &&
              versions.map((v, index) => (
                <Flex key={`version-${index}`} direction={'column'}>
                  <MarginBox mt={10} />
                  <Flex>Name: {v.name}</Flex>
                  <Flex>{`Build version: ${v.buildVersion}`}</Flex>
                  <Flex>{`Build time: ${v.time}`}</Flex>
                </Flex>
              ))}
          </DataContainer>
        </Flex>
      </Modal>
    </AntFooter>
  );
};

export const Footer = () => {
  const year = new Date().getFullYear();

  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const country = userCountry?.toLowerCase() ?? browserCountry?.toLowerCase();

  return <PageFooter year={year} country={country} />;
};
