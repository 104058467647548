import React, { FocusEvent } from 'react';
import { textFormatter } from 'utils/format';
import { InputNumber, InputNumberProps } from './InputNumber';

export interface InputPriceProps
  extends Omit<InputNumberProps, 'value' | 'initialValue' | 'onBlur' | 'onBlurDebounced' | 'onChangeDebounced'> {
  currency: string;
  value?: string;
  initialValue?: string;
  onBlur?: (val?: string, e?: FocusEvent<HTMLInputElement>) => void;
  onBlurDebounced?: (val?: string, e?: FocusEvent<HTMLInputElement>) => void;
  onChangeDebounced?: (val?: string, e?: React.ChangeEvent<HTMLInputElement> | undefined) => void;
}

export function InputPrice({ currency, ...props }: Readonly<InputPriceProps>) {
  const currencySymbol = textFormatter.getCurrencySymbol(currency);
  const currencyPlacement = textFormatter.getCurrencyPlacement(currency);
  return (
    <InputNumber
      bordered
      {...props}
      precisionMax={2}
      value={props.value ? Number(props.value) : undefined}
      initialValue={props.initialValue ? Number(props.initialValue) : undefined}
      onBlur={(val, e) => (props.onBlur ? props.onBlur(val?.toString(), e) : undefined)}
      onBlurDebounced={(val, e) => (props.onBlurDebounced ? props.onBlurDebounced(val?.toString(), e) : undefined)}
      onChangeDebounced={(val, e) =>
        props.onChangeDebounced ? props.onChangeDebounced(val?.toString(), e) : undefined
      }
      min={0}
      minLength={0}
      maxLength={17}
      addonBefore={currencyPlacement === 'before' && currencySymbol}
      addonAfter={currencyPlacement === 'after' && currencySymbol}
    />
  );
}
