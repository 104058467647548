import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeliveryLeadTime } from 'domains/deliveryLeadTime/DeliveryLeadTime.store';
import { getUserRights, isDelegationSessionStatusLoading, UserRole } from 'domains/user';
import { getData, hasUserAnyRight } from 'utils';

export const useFetchDeliveryLeadTime = (): void => {
  const dispatch = useDispatch();
  const userRights = useSelector(getUserRights);
  const isDelegationLoading = useSelector(isDelegationSessionStatusLoading);
  const hasRights = !hasUserAnyRight([UserRole.COMMAND, UserRole.CONNECT_COMMANDE], getData(userRights) ?? []);

  useEffect(() => {
    if (hasRights || isDelegationLoading) {
      return;
    }

    dispatch(fetchDeliveryLeadTime());
  }, [dispatch, hasRights, isDelegationLoading]);
};
