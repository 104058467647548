import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ReferenceStock,
  WarehouseStockDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { useTheme } from 'styled-components';
import { RootState } from 'app/AppStore';
import { PackageSeamIcon, StopwatchIconLarge, TruckOnTimeIcon } from 'assets/icons';
import { DataContainer, EMPTY_FRAGMENT, ErrorWithLabel } from 'components/DataContainer';
import { getStockInfo } from 'domains/references';
import { Flex, Grid, Icon, MarginBox, Text } from 'UI';
import { textFormatter, useBreakpointSelectorFull } from 'utils';

interface TireStockDetailProps {
  referenceNumber: string;
}

const TireStockDetailData = ({ stock }: { stock: ReferenceStock }) => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const theme = useTheme();

  const getTitleText = (w: WarehouseStockDetail) => {
    switch (w.type) {
      case 'LOCAL':
        return t('catalog.tire.stock.detail.local.title', '{{count}} in Stock at your R1', {
          count: w.availableQuantity,
        });
      case 'MANUFACTURER':
        return t('catalog.tire.stock.detail.manufacturer.title', 'Manufacturer Stock');
      case 'OTHER':
        return t('catalog.tire.stock.detail.other.title', 'Other stock');
      default:
        return <></>;
    }
  };

  const getDeliveryText = (w: WarehouseStockDetail) => {
    const expectedDeliveryDate = w.expectedDeliveryDate
      ? textFormatter.formatDateShort(new Date(w.expectedDeliveryDate))
      : undefined;
    switch (w.type) {
      case 'LOCAL':
        return (
          <>
            <Icon IconComponent={TruckOnTimeIcon} size={15} color={theme.color.grass_green} round noPointer />
            <MarginBox mr={5} />
            <Text disableGutter type={'text'}>
              {t('catalog.tire.stock.detail.local.delivery_detail', 'Day to day delivery')}
            </Text>
          </>
        );
      case 'MANUFACTURER':
        return (
          <>
            <Icon IconComponent={TruckOnTimeIcon} size={15} color={theme.color.cyan} round noPointer />
            <MarginBox mr={5} />
            <Text disableGutter type={'text'}>
              {t('catalog.tire.stock.detail.manufacturer.delivery_detail', '{{count}} shipped by {{date}}', {
                count: w.confirmedQuantity,
                date: expectedDeliveryDate,
              })}
            </Text>
          </>
        );
      case 'OTHER':
        return (
          <>
            <Icon IconComponent={TruckOnTimeIcon} size={15} color={theme.color.warning} round noPointer />
            <MarginBox mr={5} />
            <Text disableGutter type={'text'}>
              {t('catalog.tire.stock.detail.other.delivery_detail', 'Contact your dealer')}
            </Text>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Grid gutter={20} columns={breakpointSelectorFull({ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 })}>
      <Flex align={'center'}>
        <Text disableGutter type={'h2'}>
          {t('catalog.tire.stock.title', 'Stocks')}
        </Text>
      </Flex>
      {stock.warehouses.map((w, index) => (
        <Flex direction={'column'} key={`tireStockDetail_${w.type}_${index}`}>
          <Flex align={'center'}>
            <Icon IconComponent={PackageSeamIcon} size={15} round noPointer />
            <MarginBox mr={5} />
            <Text disableGutter type={'text'}>
              {getTitleText(w)}
            </Text>
          </Flex>
          <Flex align={'center'}>{getDeliveryText(w)}</Flex>
          <MarginBox mb={10} />
        </Flex>
      ))}
    </Grid>
  );
};
export const TireStockDetail = ({ referenceNumber }: TireStockDetailProps) => {
  const stock = useSelector((state: RootState) => getStockInfo(state, referenceNumber));
  const { t } = useTranslation();
  const renderErrorState = useCallback(
    () => (
      <ErrorWithLabel
        label={t('common.stock.backend_error', 'Stocks temporarily unavailable, please try again later.')}
        narrow
      />
    ),
    [t],
  );

  return (
    <DataContainer data={stock?.searchStatus} ErrorState={renderErrorState} NotFound={EMPTY_FRAGMENT}>
      {stock?.data && <TireStockDetailData stock={stock.data} />}
    </DataContainer>
  );
};

export const W1TireStockDetail = () => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const theme = useTheme();

  return (
    <Grid gutter={20} columns={breakpointSelectorFull({ xs: 1, sm: 2, md: 3, lg: 4, xl: 4, xxl: 6 })}>
      <Flex align={'center'}>
        <Text disableGutter type={'h2'}>
          {t('catalog.tire.stock.title', 'Stocks')}
        </Text>
      </Flex>
      <Flex direction={'column'}>
        <Flex align={'center'}>
          <Icon IconComponent={PackageSeamIcon} size={15} round noPointer />
          <MarginBox mr={5} />
          <Text disableGutter type={'text'}>
            {t('catalog.tires.reference.available_soon', 'Available soon')}
          </Text>
        </Flex>
        <Flex align={'center'}>
          <>
            <Icon IconComponent={StopwatchIconLarge} size={15} color={theme.color.brand_black} round noPointer />
            <MarginBox mr={5} />
            <Text disableGutter type={'text'}>
              {t('catalog.tire.stock.detail.other.delivery_detail', 'Contact your dealer')}
            </Text>
          </>
        </Flex>
        <MarginBox mb={10} />
      </Flex>
    </Grid>
  );
};
