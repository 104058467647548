import styled from 'styled-components';
import { Flex } from 'UI';

export const SAdvertisementSpace = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  background-color: ${({ theme }) => theme.color.cyan};
  cursor: pointer;
  overflow: hidden;
`;

export const SDiscount = styled.div`
  padding: 4px 10px 4px 10px;
  background-color: ${({ theme }) => theme.color.error};
  border-radius: 5px;
  white-space: nowrap;
`;

export const SInformationSpace = styled(Flex)`
  overflow: hidden;
`;
