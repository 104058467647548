import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SearchVehicleResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchVehicleResponse';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import { CarIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { VehicleItemHeader } from 'components/SearchVehicleCountrySelection/SearchVehicleCountrySelection.styled';
import {
  fetchSwitchVehicleRequestSaga,
  getFoundVehiclesAreFromDifferentCountries,
  getMultipleVehicleFound,
  getQueryByRequestId,
  resetVehicleSearchStatus,
  setFoundVehiclesAreFromDifferentCountries,
  setMultipleVehicleFound,
} from 'domains/catalog/Catalog.store';
import { VehicleTabContentBase, VehicleTabTitle } from 'pages/CatalogPage/common';
import { Flex, MarginBox, Radio, RadioGroup } from 'UI';

export function VehicleItem({ v }: { v: VehicleDetail }) {
  return (
    <>
      <Flex>
        <Flex direction={'column'}>
          <VehicleItemHeader>
            <VehicleTabTitle result={v} isBMM={false} countryName={v.country} />
          </VehicleItemHeader>
          <MarginBox mt={15} />
          <VehicleTabContentBase result={v} vehicleImage={v.imageUrl} isBMM={false} />
        </Flex>
      </Flex>
      <MarginBox mt={30} />
    </>
  );
}

function VehicleList({
  response,
  selectedVehicle,
  setSelectedVehicle,
}: {
  response: SearchVehicleResponse;
  selectedVehicle: string;
  setSelectedVehicle: Dispatch<SetStateAction<string>>;
}) {
  const showRadioButton = response.vehicles.length > 1;

  useEffect(() => {
    const defaultVehicle = response.vehicles.at(0);
    if (defaultVehicle !== undefined && !showRadioButton) {
      setSelectedVehicle(defaultVehicle.vehicleKey);
    }
  }, [response.vehicles, setSelectedVehicle, showRadioButton]);

  return (
    <RadioGroup value={selectedVehicle}>
      {response.vehicles.map((v) => (
        <React.Fragment key={v.vehicleKey}>
          {showRadioButton ? (
            <Radio
              value={v.vehicleKey}
              onChange={() => setSelectedVehicle(v.vehicleKey)}
              label={<VehicleItem v={v} />}
            />
          ) : (
            <VehicleItem v={v} />
          )}
        </React.Fragment>
      ))}
    </RadioGroup>
  );
}

function getDescription(t: TFunction, response: SearchVehicleResponse, query: string): string {
  if (response.vehicles.length > 1) {
    return t(
      'catalog.search.found_vehicles_from_different_countries',
      // eslint-disable-next-line max-len
      'Please note that the vehicle identified does not match with the country you have selected for your search {{query}}. Please, select correct vehicle.',
      { query },
    );
  } else {
    return t(
      'catalog.search.found_vehicle_from_different_country',
      // eslint-disable-next-line max-len
      'Please note that the vehicle identified does not match with the country you have selected, would you like to browse the catalog anyway?',
    );
  }
}

export function SearchVehicleCountrySelection() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;
  const foundVehiclesAreFromDifferentCountries = useSelector(getFoundVehiclesAreFromDifferentCountries);
  const multipleVehicleFound = useSelector(getMultipleVehicleFound);
  const query = useSelector((state: RootState) => getQueryByRequestId(state, multipleVehicleFound?.requestId));
  const [openOnce, setOpenOnce] = useState(true);
  const [selectedVehicle, setSelectedVehicle] = useState<string>('');

  function handleConfirm() {
    const vehicleDetail = multipleVehicleFound?.vehicles.find((v) => v.vehicleKey === selectedVehicle);

    if (multipleVehicleFound !== undefined && vehicleDetail !== undefined) {
      dispatch(fetchSwitchVehicleRequestSaga({ requestId: multipleVehicleFound.requestId, vehicleDetail }));
      setOpenOnce(true);
    }
  }

  function handleCancel() {
    if (multipleVehicleFound !== undefined && query !== undefined) {
      dispatch(resetVehicleSearchStatus({ requestId: multipleVehicleFound.requestId, query }));
    }
    dispatch(setMultipleVehicleFound(undefined));
    dispatch(setFoundVehiclesAreFromDifferentCountries(undefined));
    setOpenOnce(true);
  }

  if (foundVehiclesAreFromDifferentCountries === true && openOnce) {
    onOpen();
    setOpenOnce(false);
  }

  if (multipleVehicleFound === undefined) return null;

  return (
    <Dialog
      disclosure={disclosure}
      title={t('common.dialog.actionRequired', 'Action required')}
      description={[getDescription(t, multipleVehicleFound, query ?? '')]}
      content={
        <VehicleList
          response={multipleVehicleFound}
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
        />
      }
      icon={CarIcon}
      status={'info'}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
      buttonsText={{
        confirm: multipleVehicleFound.vehicles.length > 1 ? t('common.action.select', 'Select') : undefined,
      }}
      width={1000}
      isConfirmButtonDisabled={selectedVehicle === ''}
    />
  );
}
