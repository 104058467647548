import { Table } from 'antd';
import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { Flex } from 'UI';

export const FluidHeader = styled.div`
  background-color: ${theme.color.grey95};
  height: 54px;
  width: 100%;
`;

const tableCss = css`
  flex: 1;

  thead > tr > th {
    text-align: center;
    background-color: ${({ theme }) => theme.color.grey95};
  }
`;

export const MilageIntervalTable = styled(Table)`
  ${tableCss};

  thead .whiteBg {
    background-color: ${theme.color.white};
  }
` as typeof Table;

export const ABServiceTable = styled(Table)`
  ${tableCss};

  tbody > tr > td {
    padding: 7px;
  }

  thead > tr > th:nth-child(1) {
    background-color: ${theme.color.white};
    border-bottom: none;
  }
` as typeof Table;

export const EngineTable = styled(Table)`
  ${tableCss};

  thead > tr > th {
    padding: 10px;
  }
` as typeof Table;

export const SFlex = styled(Flex)`
  padding: 10px;
`;
