import styled from 'styled-components';
import { Text } from 'UI';

export const SSearchHistoryCube = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 140px;
  width: 160px;
  margin: 0 5px 0 5px;
`;

export const SearchHistoryLabel = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  flex: none;
`;

export const HighlightBorder = styled.div`
  border: solid 2px ${({ theme }) => theme.color.selected};
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-bottom: 15px;
`;

export const HighlightDot = styled.div`
  border-radius: 50%;
  height: 8px;
  position: absolute;
  left: 71px;
  top: 9px;
  width: 8px;
  background-color: ${({ theme }) => theme.color.selected};
  border: solid 1px ${({ theme }) => theme.color.grey100};
  z-index: 1;
`;
