import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TrashAltIcon } from 'assets/icons';
import { Dialog, useDisclosure } from 'components/Dialog';
import { fetchClearVehicleSearchHistoryRequestSaga, getVehicleSearchHistory } from 'domains/user';
import { Box, Container, Flex, Text, TrashAltButton } from 'UI';
import { hasData } from 'utils';
import SearchHistoryList from './SearchHistoryList';
import { ViewContentWrapper } from './SearchHistoryPage.styled';

export default function SearchHistoryPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vehicleSearchHistory = useSelector(getVehicleSearchHistory);
  const disclosure = useDisclosure();
  const { onOpen } = disclosure;

  function removeAllVehicles() {
    onOpen();
  }

  const handleConfirm = () => {
    if (!hasData(vehicleSearchHistory)) return;
    dispatch(fetchClearVehicleSearchHistoryRequestSaga());
  };

  return (
    <>
      <Container>
        <Flex>
          <Flex />
          <ViewContentWrapper>
            <Flex direction={'column'}>
              <Box height={32} />
              <Flex justify={'space-between'} align={'center'}>
                <Text type={'h1_banner_light'}>{t('catalog.history', 'Search History')}</Text>
                {hasData(vehicleSearchHistory) && (
                  <TrashAltButton size={22} boxSize={'md'} onClick={removeAllVehicles} />
                )}
              </Flex>
              <Box height={30} />
              <SearchHistoryList />
            </Flex>
          </ViewContentWrapper>
          <Flex />
        </Flex>
      </Container>
      <Dialog
        disclosure={disclosure}
        title={t('catalog.history.dialog.delete_history', 'Delete history')}
        description={[
          t(
            'catalog.history.dialog.delete_history.description',
            'This action will delete your entire car search history.',
          ),
          t('common.dialog.confirmationQuestion', 'Would you like to confirm?'),
        ]}
        icon={TrashAltIcon}
        status={'info'}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
