/* eslint-disable max-len */
import React from 'react';
import {
  SLaborTimeFilterButton,
  SWhiteOutlinedButton,
} from 'pages/CatalogPage/DH/SubcategorySection/LaborTimeSection/LaborTimeSection.styled';
import { Text } from 'UI';

export const LaborTimeQuickFilter = ({
  filterLabel,
  activeFilters,
  changeQuickFilterState,
}: {
  filterLabel: string;
  activeFilters: string[];
  changeQuickFilterState: (filterLabel: string) => void;
}) => {
  const active = activeFilters.includes(filterLabel);
  return active ? (
    <SLaborTimeFilterButton
      className={active ? 'active' : ''}
      disabled={false}
      size={'large'}
      shape={'round'}
      stretch={false}
      onClick={() => changeQuickFilterState(filterLabel)}
    >
      <Text className={'labor-filter-text'} type={'light_14_black_85'} cursor={'pointer'}>
        {filterLabel}
      </Text>
    </SLaborTimeFilterButton>
  ) : (
    <SWhiteOutlinedButton
      className={active ? 'active' : ''}
      disabled={false}
      size={'large'}
      shape={'round'}
      stretch={false}
      onClick={() => changeQuickFilterState(filterLabel)}
    >
      <Text className={'labor-filter-text'} type={'light_14_black_85'} cursor={'pointer'}>
        {filterLabel}
      </Text>
    </SWhiteOutlinedButton>
  );
};
