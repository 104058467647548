import { Card } from 'antd';
import styled from 'styled-components';

export const SLanguageCard = styled(Card)`
  text-align: left;
  height: 158px;
  width: 290px;
  background-color: ${({ theme }) => theme.color.grey95};
  border-radius: 16px;
  box-shadow: 0 3px 6px -4px ${({ theme }) => theme.color.shadow_5};
`;

export const SLanguageSelectPosition = styled.div`
  position: absolute;
  bottom: 20%;
`;
