import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { InvalidUserToken } from 'app/DisconnectPopup';
import Loader from 'components/Loader';
import {
  getTokenUser,
  getUserProfileSearchStatus,
  getUserRights,
  isTokenValid,
  REDIRECT_URL_STORE_KEY,
  UserRole,
} from 'domains/user';
import { ERROR, isLoading } from 'utils';
import { Country } from 'utils/i18n/Country';
import { doesUserBelongToCountry, hasUserAnyRight } from 'utils/rights';
import { storeSave } from 'utils/storage';

export interface PrivateRouteProps extends RouteProps {
  render: () => JSX.Element;
  fallback?: () => JSX.Element;
  requiredRights?: UserRole[];
  requiredCountries?: Country[];
}

const PrivateRoute = ({
  render,
  requiredRights = [],
  requiredCountries = [],
  fallback = () => <Redirect to="/" />,
  ...props
}: PrivateRouteProps) => {
  const userRights = useSelector(getUserRights);
  const profilSearchStatus = useSelector(getUserProfileSearchStatus);
  const tokenUser = useSelector(getTokenUser);
  const location = useLocation();
  const userCountry = Country.findByKey(tokenUser?.country);
  const tokenValid = useSelector(isTokenValid);
  const renderOnFallback = () => {
    const redirectUrl = location.pathname + location.search;
    if (redirectUrl !== '/') {
      storeSave(REDIRECT_URL_STORE_KEY, redirectUrl);
    }
    return fallback();
  };

  if ((profilSearchStatus === undefined && tokenUser) || isLoading(profilSearchStatus) || isLoading(userRights)) {
    return <Loader />;
  }
  if (tokenUser && !tokenValid) {
    return <InvalidUserToken />;
  }

  return (
    <Route
      {...props}
      render={() => {
        if (!tokenUser || profilSearchStatus === ERROR) {
          return renderOnFallback();
        }
        const isAuthorized = hasUserAnyRight(requiredRights, userRights);
        const isAuthorizedCountry = doesUserBelongToCountry(requiredCountries, userCountry);
        return isAuthorized && isAuthorizedCountry ? render() : renderOnFallback();
      }}
    />
  );
};

export default PrivateRoute;
