import React, { ReactNode } from 'react';
import { Badge, BadgeProps } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import { LiteralUnion } from 'antd/lib/_util/type';

export type BadgePropsExtended = {
  textcolor?: LiteralUnion<PresetColorType, string>;
  bordercolor?: LiteralUnion<PresetColorType, string>;
  checked?: boolean;
  disabled?: boolean;
  checkIcon?: ReactNode;
} & BadgeProps;

export const ForwardedBadge = React.forwardRef<HTMLDivElement, BadgePropsExtended>((props, ref) => (
  <div ref={ref}>
    <Badge {...props} />
  </div>
));

ForwardedBadge.displayName = 'ForwardedBadge';
