import React, { ReactNode, useEffect, useState } from 'react';
import InfiniteScrollImpl from 'react-infinite-scroller';
import { CenteredSpin } from 'UI/Spin';

interface InfiniteScrollBase {
  loadMore: () => void;
  hasMore: boolean | undefined;
}

export interface InfiniteScrollable<T> extends InfiniteScrollBase {
  currentData: T[];
}

// This useInfiniteScroll is for local data. When FE have/gets all the answers
export function useInfiniteScroll<T>({ data, chunkSize }: { data: T[]; chunkSize: number }): InfiniteScrollable<T> {
  const [cursor, setCursor] = useState(0);
  useEffect(() => {
    resetCursor();
  }, []);
  const currentData = data.slice(0, chunkSize * (cursor + 1));
  const hasMore = cursor + 1 < Math.ceil(data.length / chunkSize);
  const loadMore = () => {
    setCursor(cursor + 1);
  };
  const resetCursor = () => {
    setCursor(0);
  };
  return { hasMore, loadMore, currentData };
}

export interface InfiniteScrollProps extends InfiniteScrollBase {
  children?: ReactNode;
  getScrollParent?: () => HTMLElement | null;
  useWindow?: boolean;
  dataCy?: string;
}

export const InfiniteScroll = ({
  loadMore,
  hasMore,
  getScrollParent,
  useWindow,
  children,
  dataCy,
}: InfiniteScrollProps) => {
  return (
    <InfiniteScrollImpl
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasMore}
      loader={<CenteredSpin key={0} />}
      getScrollParent={getScrollParent}
      useWindow={useWindow}
      data-cy={dataCy}
    >
      {children}
    </InfiniteScrollImpl>
  );
};
