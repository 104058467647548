import styled from 'styled-components';
import { Box } from 'UI';

export const ScrollContainerPlates = styled.div`
  width: 159px;
  height: 574px;
  overflow: hidden;

  :hover {
    overflow-y: auto;
  }
`;

export const SBox = styled(Box)`
  height: 574px;
`;
