import React from 'react';
import { tireBrandsData, TireBrandType } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Image } from 'UI';

export const TireBrandImage = ({
  referenceBrandId,
  width = 100,
  height = 30,
}: {
  referenceBrandId?: string;
  width?: number;
  height?: number;
}) => {
  const getIcon = () => {
    const brandData = tireBrandsData.find((b) => b.brandId === referenceBrandId);
    const undefinedBrand = { brandId: '', image: '', name: '', selected: false } as TireBrandType;
    const brandDataF = brandData ?? undefinedBrand;
    return brandDataF.image;
  };
  return <Image src={getIcon()} alt={'brand_image'} height={height} maxWidth={width} />;
};
