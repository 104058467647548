import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { fetchBase64FromUrlRequestSaga, getPictures } from 'domains/pictures/Pictures.store';

export const useFetchEprelBase64 = (urls: string[]): void => {
  const eprelsLocal = useSelector((state: RootState) => getPictures(state, urls));
  const dispatch = useDispatch();
  useEffect(() => {
    urls.forEach((url) => {
      const eprelLocal = eprelsLocal.get(url);
      if (url && eprelLocal === undefined) {
        dispatch(fetchBase64FromUrlRequestSaga({ url }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, urls]);
};
