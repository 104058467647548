import { GET_URL_SIGNATURE } from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import moment, { Moment } from 'moment';
import { WebSocketAction } from 'utils';
import { wsSendAction } from 'utils/domainStore/api';

export function sendGetSignature(): WebSocketAction {
  return wsSendAction(GET_URL_SIGNATURE, null);
}

export const isSignatureExpired = (signatureExpiration: Moment | undefined): boolean => {
  const now = moment();
  return !signatureExpiration || signatureExpiration?.diff(now) < 0;
};
