/* eslint-disable max-len */
import { TimeType } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/model/TimeType';
import { DeliveryLeadTimeModifyRequest } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/request/DeliveryLeadTimeModifyRequest';
import {
  DeliveryLeadTime,
  DeliveryLeadTimeResponse,
  Warehouse,
} from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import { DeliveryLeadTimeUpdateResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeUpdateResponse';
import {
  BACKOFFICE_DELIVERY_LEAD_TIME_RESPONSE,
  BACKOFFICE_DELIVERY_LEAD_TIME_UPDATE_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import {
  DELIVERY_LEAD_TIME_NAMESPACE,
  DeliveryLeadTimeState,
  GET_DELIVERY_LEAD_TIME,
  UPDATE_DELIVERY_LEAD_TIME,
} from 'domains/deliveryLeadTime/DeliveryLeadTime.types';

// Init state
const initialState: DeliveryLeadTimeState = {
  warehouses: [],
};

// Saga actions
export const fetchDeliveryLeadTime = createAction(GET_DELIVERY_LEAD_TIME);
export const updateDeliveryLeadTime = createAction<DeliveryLeadTimeModifyRequest>(UPDATE_DELIVERY_LEAD_TIME);
export const fetchDeliveryLeadTimeResponse = createAction(BACKOFFICE_DELIVERY_LEAD_TIME_RESPONSE);
export const fetchDeliveryLeadTimeUpdateResponse = createAction(BACKOFFICE_DELIVERY_LEAD_TIME_UPDATE_RESPONSE);

// Slice
const slice = createSlice({
  name: DELIVERY_LEAD_TIME_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setDeliveryLeadTime: (state, { payload }: PayloadAction<DeliveryLeadTimeResponse>) => {
      state.warehouses = payload.warehouses;
    },
    setDeliveryLeadTimeUpdate: (state, { payload }: PayloadAction<DeliveryLeadTimeUpdateResponse>) => {
      const warehouse: DeliveryLeadTime = state.warehouses.find((w) => w.warehouse === payload.warehouse) ?? {
        warehouse: payload.warehouse,
        isDisabled: true,
        time: 'HOURS',
        from: 0,
        to: 0,
      };
      switch (payload.field) {
        case 'IS_DISABLED':
          warehouse.isDisabled = JSON.parse(payload.value);
          break;
        case 'TIME':
          warehouse.time = payload.value as TimeType;
          break;
        case 'FROM':
          warehouse.from = Number(payload.value);
          break;
        case 'TO':
          warehouse.to = Number(payload.value);
          break;
      }
    },
  },
});

// Actions
export const { setInitialState, setDeliveryLeadTime, setDeliveryLeadTimeUpdate } = slice.actions;

// Getters/Selectors
export const getDeliveryLeadTime = createSelector(
  (state: RootState) => state.deliveryLeadTime.warehouses,
  (_state: RootState, type: Warehouse) => type,
  (warehouses, type) => warehouses.find((w: DeliveryLeadTime) => w.warehouse === type),
);

export const getAllDeliveryLeadTime = createSelector(
  (state: RootState) => state.deliveryLeadTime.warehouses,
  (warehouses) => warehouses,
);

export default slice.reducer;
