import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TargetPage } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { Select } from 'antd';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { getUserRights } from 'domains/user';
import { Flex, Text } from 'UI';
import { getData } from 'utils';
import { getPages } from '../Options';

const ALL_PAGES = 'ALL_PAGES';
export const TargetPages = ({
  information,
  setInformation,
}: {
  information: CreateInformationModel | CreateInformationTemplateModel;
  setInformation: (informationToCreate: CreateInformationModel | CreateInformationTemplateModel) => void;
}) => {
  const { t } = useTranslation();
  const userRights = useSelector(getUserRights);
  const pagesDropdown = getPages(t, getData(userRights) ?? []);
  const allPages: TargetPage[] = useMemo(
    () => getPages(t, getData(userRights) ?? []).map((page) => page.value as TargetPage),
    [t, userRights],
  );

  const buildTargetPagesOptions = () => {
    const pageOptions = pagesDropdown.map((page) => (
      <Select.Option key={page.value} value={page.value}>
        {page.label}
      </Select.Option>
    ));
    const selectAllOption = (
      <Select.Option key={ALL_PAGES} value={ALL_PAGES}>
        {t('backoffice.information.create_information.targeted_pages.select.all_pages', 'All pages')}
      </Select.Option>
    );
    return [selectAllOption, ...pageOptions];
  };
  return (
    <Flex direction={'column'} size={0} minWidth={270}>
      <Text type={'h2'}>{t('backoffice.information.create_information.targeted_pages', 'Targeted pages')}</Text>
      <Select
        mode={'multiple'}
        value={information?.targetPages}
        onChange={(pages: string[]) => {
          if (pages.includes(ALL_PAGES)) {
            setInformation({ ...information, targetPages: allPages });
            return;
          }
          setInformation({ ...information, targetPages: pages as TargetPage[] });
        }}
        filterOption={(input, option) => {
          if (input && option && option.children) {
            return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        placeholder={t('backoffice.information.create_information.targeted_pages.select', 'Select targeted pages')}
        defaultOpen={false}
        bordered
        allowClear
      >
        {buildTargetPagesOptions()}
      </Select>
    </Flex>
  );
};
