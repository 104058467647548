import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTER_ASSISTANCE_OTHER_BRANDS, ROUTER_ASSISTANCE_RENAULT_DACIA } from 'app/AppRouter';
import { Flex, Link } from 'UI';
import ContactUsHeader from '../ContactUsHeader/ContactUsHeader';
import MenuCard from '../MenuCard/MenuCard';

const MainMenu = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContactUsHeader
        description={t(
          'assistance.main_menu.subtitle',
          'Need help or seeking further information about Renault Dacia or other brands vehicles? ' +
            'Please select one of the options to get in touch with our team.',
        )}
      />
      <Flex direction={'row'} justify={'space-between'}>
        <Link to={`${ROUTER_ASSISTANCE_RENAULT_DACIA}`}>
          <MenuCard
            cardTitle={t('assistance.renault_dacia.title', 'Renault Dacia')}
            cardSubtitle={t('assistance.menu_card.subtitle.renault_dacia', 'Get support for Renault Dacia vehicles')}
            bannerImg={'assistance/assistance-renault-dacia-menu-card-banner.webp'}
          />
        </Link>
        <Link to={`${ROUTER_ASSISTANCE_OTHER_BRANDS}`}>
          <MenuCard
            cardTitle={t('assistance.other_brands.title', 'Other brands')}
            cardSubtitle={t('assistance.menu_card.subtitle.other_brands', 'Get support for other brands vehicles')}
            bannerImg={'assistance/assistance-other-brands-menu-card-banner.webp'}
          />
        </Link>
      </Flex>
    </>
  );
};

export default MainMenu;
