import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'UI';

const FilterQuantity = ({ numberOfProducts }: { numberOfProducts: number }) => {
  const { t } = useTranslation();
  return (
    <Text type={'light_14_bold_black_45'}>
      {t('common.num_of_products', '{{numberOfProducts}} products', { numberOfProducts })}
    </Text>
  );
};

export default FilterQuantity;
