/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { decodeCategoryImage } from 'domains/catalog/Catalog.hooks';
import { getPicture } from 'domains/pictures/Pictures.store';
import { ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID } from 'pages/CatalogPage/DH/CarPartGroupsSection/CarPartGroupItem/RoutineVehicleMaintenanceGroupCard';
import { SvgDiv } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateDetailCardImage/PlateDetailCardImage.styled';
import { Box, CenteredSpin, CenterFlex, Image } from 'UI';
import { svgElementId } from 'utils/svg/manipulateCategorySvg';
/* eslint-enable max-len */

export const STORED_CATEGORY_SVG = [
  ROUTINE_VEHICLE_MAINTENANCE_GROUP_NODE_ID,
  'N-21581',
  'N-21643',
  'N-21657',
  'N-21871',
  'N-21883',
  'N-21901',
  'N-21921',
  'N-21947',
  'N-22009',
];

type CarPartSvgElement = string | JSX.Element | undefined;

export const useGetCarPartSvgElement = ({
  nodeId,
  svgImageKey,
}: {
  nodeId: string;
  svgImageKey?: string;
}): CarPartSvgElement => {
  const [svg, setSvg] = useState<CarPartSvgElement>(undefined);
  const imageBase64 = useSelector((state: RootState) => getPicture(state, svgImageKey));

  useEffect(() => {
    async function getCarPartSvg() {
      if (STORED_CATEGORY_SVG.includes(nodeId)) {
        const moduleName = nodeId.replace('-', '');
        await import(`./CarPartSvg/${moduleName}`).then((module) => setSvg(module[moduleName]));
      } else {
        const decodedCategoryImage = decodeCategoryImage(imageBase64);
        setSvg(decodedCategoryImage);
      }
    }

    getCarPartSvg().catch();
  }, [nodeId, imageBase64]);

  return svg;
};

interface CarPartGroupSvgProps {
  svgElement: CarPartSvgElement;
  nodeId: string;
  width: number;
  height: number;
}

export const CarPartGroupSvg = ({ svgElement, nodeId, width, height }: CarPartGroupSvgProps) => {
  const { t } = useTranslation();

  return (
    <Box width={width} height={height}>
      <DataContainer
        data={svgElement}
        Loading={() => (
          <Box width={width} height={height}>
            <CenteredSpin />
          </Box>
        )}
        NotFound={() => <></>}
        Skeleton={() => (
          <Box width={width} height={height}>
            <CenterFlex id={`${svgElementId}${nodeId}`}>
              <Image alt={'CarParts'} src={'catalog/default-car-parts.jpg'} />
            </CenterFlex>
          </Box>
        )}
        ErrorState={() => (
          <Box width={width} height={height}>
            <CenterFlex id={`${svgElementId}${nodeId}`}>
              <ErrorWithLabel
                label={t('common.picture.backend_error', 'Picture temporarily unavailable, please try again later.')}
              />
            </CenterFlex>
          </Box>
        )}
      >
        <SvgDiv id={`${svgElementId}${nodeId}`}>{svgElement}</SvgDiv>
      </DataContainer>
    </Box>
  );
};
