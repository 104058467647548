import styled from 'styled-components';

export const VehicleItemHeader = styled.div`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  max-width: fit-content;
  min-width: 1px;
  height: 40px;
  padding: 15px;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 2px -2px rgba(0, 0, 0, 0.16);
  background-color: black;
`;
