import { QueryResponseType, WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { t } from 'i18next';
import { ReferencePriceType } from 'domains/references';
import { ClientView, GarageView, SparePartsViewType } from 'domains/user';
import { notifyTop } from 'UI';

export const LOADING = 'loading';
export const ERROR = 'error';
export const FOUND = 'found';
export const NOT_FOUND = 'not_found';

export type NO_DATA = undefined | typeof LOADING | typeof NOT_FOUND | typeof ERROR;
export type SEARCH_STATUS = typeof LOADING | typeof NOT_FOUND | typeof FOUND | typeof ERROR | undefined;
export type CURSOR_PAGING_STATUS = typeof LOADING | typeof FOUND;

export interface SearchData<T, E = any> {
  searchStatus: SEARCH_STATUS;
  data?: T;
  errorDetail?: E;
}

export function getDefaultSearchData<T, E>(): SearchData<T, E> {
  return {
    searchStatus: undefined,
    data: undefined,
    errorDetail: undefined,
  };
}

export function hasData<T>(value: T | NO_DATA): value is T {
  return !isLoading(value) && !isError(value) && value !== NOT_FOUND && value !== undefined;
}

export function getData<T>(value: T | NO_DATA): T | undefined {
  return hasData(value) ? value : undefined;
}

export function getSearchData<T>(value: SearchData<T> | undefined): T | undefined {
  return hasData(value?.searchStatus) && value?.data ? value.data : undefined;
}

export function isLoading<T>(value: T | NO_DATA): value is typeof LOADING {
  return value === LOADING;
}

export function isNotFound<T>(value: T | NO_DATA): value is typeof NOT_FOUND {
  return value === NOT_FOUND;
}

export function isError<T>(value: T | NO_DATA): value is typeof ERROR {
  return value === ERROR;
}

export function isLoaded<T>(value: T | NO_DATA): value is T {
  return value !== undefined && value !== LOADING;
}

export function hasPrice(
  sparePartsView: SparePartsViewType,
  price: ReferencePriceType | undefined,
): price is ReferencePriceType {
  return (
    (sparePartsView === ClientView && price?.clientView?.recommendedPriceVatIncluded !== undefined) ||
    (sparePartsView === GarageView && price?.garageView?.vatExcludedPrice !== undefined)
  );
}

export function mapQueryWsResponseToSearchData<T>(action: WsResponse<T>): SearchData<T> {
  //todo this will need improvement over some time
  switch (action.queryResponseType) {
    case QueryResponseType.INIT:
    case QueryResponseType.QUERY_SUCCESS_RESPONSE: {
      return { searchStatus: FOUND, data: action.payload, errorDetail: action?.errorDetail };
    }
    case QueryResponseType.QUERY_NO_DATA_RESPONSE: {
      return { searchStatus: NOT_FOUND, data: action.payload, errorDetail: action?.errorDetail };
    }
    case QueryResponseType.QUERY_ERROR_RESPONSE: // todo timeout case
    case QueryResponseType.QUERY_INVALID_RESPONSE: {
      return { searchStatus: ERROR, data: action.payload, errorDetail: action?.errorDetail };
    }
    default: {
      notifyTop(
        'error',
        t('response.unhandledState', 'Unhandled state for {{responseType}} and {{type}}', {
          responseType: action.queryResponseType,
          type: action.type,
        }),
      );
      return { searchStatus: ERROR, data: undefined, errorDetail: undefined };
    }
  }
}

export function getCondArrayItem<T>(data: T | false | undefined): T[] {
  return data ? [data] : [];
}
