import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA } from 'utils';

export const PICTURES_NAMESPACE = `${APP_NAMESPACE}/PICTURES`;

export const GET_IMAGE = `${PICTURES_NAMESPACE}/GET_IMAGE`;

export const GET_BASE64_IMAGE_FROM_URL = `${PICTURES_NAMESPACE}/GET_BASE64_IMAGE_FROM_URL`;

export interface PicturesState {
  pictures: Map<string, string | NO_DATA>; // Map<picture ID, base64>
}
