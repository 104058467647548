import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { CleverInput, Flex, InputPrice } from 'UI';

export const HeaderWrapper = styled(Flex)`
  flex: 0 0 auto;
  padding: 10px 50px 10px 50px;
  background-color: ${theme.color.white};
  border-bottom: solid 1px ${theme.color.grey85};
`;

const sectionCss = css`
  border: solid 1px ${theme.color.grey85};
  border-radius: 8px;
  padding: 0 16px 0 22px;
  position: relative;
  overflow: hidden;
  line-height: 20px;
  background-color: ${theme.color.white};

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    background: ${theme.color.clear_blue};
  }
`;

export const SectionHeader = styled(Flex)<{ isOpen: boolean }>`
  flex: 1 0 auto;
  height: 66px;
  padding: 0 16px 0 0;
  ${({ isOpen }) => !isOpen && sectionCss};
`;

const invalidCssColors = css`
  border: 1px solid ${theme.color.red};
  background-color: ${theme.color.very_light_pink};
`;

const invalidCss = css<{ currency?: string }>`
  ${({ currency }) => !currency && invalidCssColors};
  .ant-input-wrapper * {
    ${invalidCssColors};
    color: ${theme.color.red};
  }
  input {
    background-color: ${theme.color.very_light_pink};
  }
  :focus {
    ${invalidCssColors};
  }
  :hover {
    ${({ currency }) => !currency && invalidCssColors};
  }
`;

const largeFont = css`
  font-size: 24px;
  font-weight: 600;
  height: 28px;
`;

export const SValidatedTextInput = styled(CleverInput)<{ isValid?: boolean; isLarge?: boolean }>`
  :focus {
    border: 1px solid ${({ theme }) => theme.color.brand_light};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.brand_background};
    background-color: ${theme.color.white};
  }
  ${({ isValid }) => !isValid && invalidCss};
  ${({ isLarge }) => isLarge && largeFont};
`;

export const SValidatedPriceInput = styled(InputPrice)<{ isValid?: boolean }>`
  :focus {
    border: 1px solid ${({ theme }) => theme.color.brand_light};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.brand_background};
    background-color: ${theme.color.white};
  }
  ${({ isValid }) => !isValid && invalidCss};
`;
