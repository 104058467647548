import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUnadjustedPromoCount } from 'domains/promotion/Promotion.store';
import { getUserRights, UserRole } from 'domains/user';
import { theme } from 'styles';
import { Text } from 'UI';

const Wrapper = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: ${theme.color.red};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdjustmentNumber = () => {
  const unadjustedPromoCount = useSelector(getUnadjustedPromoCount) ?? 0;
  const userRights = useSelector(getUserRights);
  if (!userRights.includes(UserRole.R1) || unadjustedPromoCount < 1) {
    return <></>;
  }
  return (
    <Wrapper>
      <Text type={'dark_12_white_100'} disableGutter>
        {unadjustedPromoCount}
      </Text>
    </Wrapper>
  );
};

export default AdjustmentNumber;
