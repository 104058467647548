/*eslint-disable max-len*/

import React from 'react';
import { Container, Flex, Image, Li, Link, MarginBox, Ol, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';

export const TermsAndConditionsPT = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>TERMOS E CONDIÇÕES DE UTILIZAÇÃO</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Os presentes Termos e Condições Gerais de Utilização (doravante &quot;CGU&quot;) regulam o acesso e a
            utilização do serviço RPARTSTORE, disponibilizado pela Renault, SAS com um capital de €533.941.113, com sede
            social em 122-122 bis avenue du Général Leclerc - 92100 Boulogne-Billancourt, registada no Registo Comercial
            de Nanterre sob o número 780 129 987 (doravante &quot;RENAULT&quot;) e gerida localmente pela RENAULT
            PORTUGAL S.A., com sede em Lagoas Park, Edifício 15, piso 2, 2740-262 Porto Salvo, matriculada na
            Conservatória do Registo Comercial sob o NIPC 500970602, com o capital social integralmente realizado de €
            38.462.920.
          </Paragraph>
          <Paragraph>
            <SectionHeader
              title={
                'O UTILIZADOR DEVE LER ATENTAMENTE AS PRESENTES CONDIÇÕES GERAIS ANTES DE AS ACEITAR E DE ACEDER AOS SERVIÇOS.'
              }
              underline
            />
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 1.'} title={'Designação da plataforma'} />
          <Paragraph>
            O website RPARTSTORE (doravante designado por &quot;<b>Plataforma</b>&quot;) foi criado para facilitar o
            contacto entre vendedores (doravante designados por &quot;<b>Vendedores</b>&quot;) que pretendam vender
            peças e acessórios (doravante designados por &quot;<b>Produtos</b>&quot;) aos compradores (doravante
            designados por &quot;<b>Compradores</b>&quot;), através de um website de acordo com estes termos e condições
            gerais de utilização.
          </Paragraph>
          <Paragraph>
            Os serviços da Plataforma podem ser acedidos através do seguinte link:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 2.'} title={'Qualidade do Serviço'} />
          <Paragraph>
            A Plataforma atua como intermediário técnico. Oferece aos Vendedores e aos Compradores (&quot;
            <b>Utilizadores</b>&quot;) serviços técnicos de armazenamento e transporte de dados ao abrigo da
            regulamentação.
          </Paragraph>
          <Paragraph>
            O papel da Plataforma é puramente técnico e limita-se a facilitar o contacto entre os Vendedores e os
            Compradores.
          </Paragraph>
          <Paragraph>
            A Plataforma não assume qualquer garantia ou responsabilidade relativamente a quaisquer acordos, trocas,
            ofertas ou comunicações diversas que possam ter lugar através da mesma.
          </Paragraph>
          <Paragraph>
            A Plataforma não é, de forma alguma, vendedora ou revendedora dos Produtos oferecidos pelos Vendedores
            através da Plataforma.
          </Paragraph>
          <Paragraph>
            As transações relativas aos Produtos oferecidos pelos Vendedores são sempre concluídas diretamente entre
            Vendedores e Compradores, fora da Plataforma. O Vendedor pode simplesmente gerar contactos comerciais (
            <i>formulário de lead</i>) através da Plataforma. O preço final dos Produtos é dado pelo Vendedor fora da
            Plataforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 3.'} title={'Objetivo das condições gerais de utilização'} />
          <Paragraph>
            As presentes condições gerais de utilização têm por objetivo definir os termos e condições de acesso e de
            utilização da Plataforma pelos Utilizadores.
          </Paragraph>
          <Paragraph>
            O objetivo da Plataforma é criar uma relação digital direta entre Vendedores e Compradores, de forma a gerar
            contactos comerciais entre eles.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 4.'} title={'Condições de acesso dos Compradores à Plataforma'} />
          <Paragraph>
            O acesso à Plataforma implica:
            <Ul>
              <Li>
                <Text type={'text'}>
                  Que os Vendedores solicitem a adesão dos Compradores através do respetivo minisite;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>A validação pelos Vendedores do acesso dos Compradores à Plataforma.</Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            O minisite é uma aplicação de e-business que permite aos Vendedores solicitar a adesão à RPARTSTORE dos seus
            clientes Compradores. A aplicação é acessível a partir da RENAULTNET e RPARTSTORE.
          </Paragraph>
          <Paragraph>
            O acesso ao minisite de adesão é concedido pelo centro de atendimento telefónico através do seguinte número:
            218361030ao Vendedor que o solicite, desde que esteja referenciado individualmente na rede Renault.
          </Paragraph>
          <Paragraph>
            O minisite de adesão acelera o processo de adesão e permite igualmente ao Vendedor efetuar pedidos de adesão
            para os seus clientes, independentemente da natureza do utilizador na TRESOR e na RPARTSTORE (cliente
            desconhecido na TRESOR, nova adesão, novo utilizador).
          </Paragraph>
          <Paragraph>
            O Vendedor pode efetuar pedidos de adesão para os seus clientes em conformidade com o disposto no artigo 4
            acima. Se o cliente não estiver identificado na base de dados TRESOR, será automaticamente enviado um pedido
            de criação/alteração ao administrador TRESOR competente.
          </Paragraph>
          <Paragraph>
            O Vendedor pode modificar os dados do cliente e acompanhar a evolução dos seus pedidos de adesão. Não pode
            modificar um pedido aberto, mas pode solicitar o seu encerramento. O centro de atendimento telefónico
            encarregar-se-á do encerramento final
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 5.'} title={'Produtos admitidos na Plataforma'} />
          <Paragraph>
            A Plataforma seleciona os tipos de produtos que podem aí ser encomendados, sendo que os Compradores não
            podem solicitar ou exigir que os Vendedores ofereçam, através da Plataforma, produtos diferentes dos
            previamente selecionados nesta.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 6.'} title={'Classificação'} />
          <Paragraph>
            A visualização das ofertas não está sujeita a nenhum critério de classificação pré-determinado. Apenas os
            critérios de classificação por ordem crescente ou decrescente de preço podem ser escolhidos pelo Comprador e
            determinarão, se for caso disso, uma ordem de classificação.
          </Paragraph>
          <Paragraph>
            Relativamente à visualização da oferta, a RENAULT pode determinar uma ordem de classificação por defeito com
            base numa das seguintes opções:
            <Ul>
              <Li>
                Idade
                <Ul type={'circle'}>
                  <Li>Da mais antiga para a mais recente;</Li>
                  <Li>Da mais recente para a mais antiga.</Li>
                </Ul>
              </Li>
              <Li>Preço: Aumento do preço</Li>
              <Li>
                Relevância:
                <Ul type={'circle'}>
                  <Li>Aleatório</Li>
                  <Li>Marcas</Li>
                  <Li>Características técnicas</Li>
                </Ul>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            A RENAULT reserva-se no direito de alterar a opção de classificação escolhida a qualquer momento. O
            Comprador pode alterar esta classificação por defeito utilizando uma das opções abaixo:
            <Ul>
              <Li>Preço ascendente</Li>
              <Li>Preço decrescente</Li>
              <Li>Idade ascendente: da mais recente à mais antiga</Li>
              <Li>Idade decrescente: da mais antiga para a mais recente</Li>
              <Li>Prazo de entrega ascendente</Li>
              <Li>Proximidade (visível quando os utilizadores pesquisam por localização)</Li>
              <Li>Relevância:</Li>
              <Ul type={'circle'}>
                <Li>
                  <Text type={'text'}>Aleatório</Text>
                </Li>
                <Li>
                  <Text type={'text'}>Marcas</Text>
                </Li>
              </Ul>
            </Ul>
          </Paragraph>
        </Section>

        <Section>
          <SectionHeader
            article={'Artigo 7.'}
            title={'Criação de um Espaço do Comprador - Confidencialidade dos identificadores e das passwords'}
          />
          <Paragraph>
            <Text type={'text'} decoration={'underline'}>
              As etapas:
            </Text>
            <Ul type={'decimal'}>
              <Li>
                Receção de um e-mail do Vendedor a confirmar que o pedido foi registado. A mensagem de correio
                eletrónico indicará igualmente o prazo de tratamento do pedido, que varia entre 24 e 72 horas.
              </Li>
              <Li>
                O Comprador receberá por e-mail as informações necessárias (ID / Password) para se ligar à RPARTSTORE.
              </Li>
              <Li>
                O Vendedor receberá também uma mensagem informando que o seu cliente agora tem acesso à RPARTSTORE.
              </Li>
              <Li>No caso de um incidente técnico sobre o pedido, o Vendedor será notificado por e-mail.</Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            A utilização do Espaço do Comprador é estritamente limitada ao utilizador designado pelo Vendedor.
          </Paragraph>
          <Paragraph>
            O Comprador compromete-se a manter os seus códigos secretos e a não os divulgar sob qualquer forma ou a
            qualquer pessoa e, em caso de utilização fraudulenta ou não autorizada, a anulá-los e a comunicar os factos
            sem demora ao serviço de apoio ao cliente em 218361030 . O Comprador é informado de que qualquer divulgação
            dos seus códigos a terceiros é da sua exclusiva responsabilidade.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 8.'} title={'Relação Personalizada'} />
          <Paragraph>O Comprador não pode solicitar todos os Vendedores admitidos na Plataforma.</Paragraph>
          <Paragraph>
            Na Plataforma, o Comprador encontrará o Vendedor correspondente ao seu canal físico habitual; a vinculação
            do Comprador não mudará.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 9.'} title={'Pagamento'} />
          <Paragraph>
            Fica entendido que só podem ser efetuados contactos comerciais através da Plataforma e que não será efetuado
            qualquer pagamento através da Plataforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 10.'} title={'Disponibilidade dos serviços da Plataforma'} />
          <Paragraph>
            Dentro dos limites da lei e regulamentos aplicáveis, a RENAULT declina qualquer responsabilidade pela
            utilização da Plataforma pelo Utilizador e não dá qualquer garantia, expressa ou implícita, quanto à
            velocidade e/ou desempenho do Site ou quanto à utilização feita pelo Comprador dos dados e/ou informações
            acessíveis através da Plataforma.
          </Paragraph>
          <Paragraph>
            Em particular, a RENAULT não garante que a Plataforma esteja isenta de erros, defeitos ou omissões, ou que
            não esteja sujeita a danos ou ataques, nomeadamente de vírus informáticos, que possam provocar mau
            funcionamento, interrupções de serviço, avarias ou perdas de dados ou informações.
          </Paragraph>
          <Paragraph>
            O acesso à Plataforma pode ser temporária e periodicamente suspenso por razões técnicas, que as equipas
            técnicas se esforçarão por resolver o mais rapidamente possível.
          </Paragraph>
          <Paragraph>
            A RENAULT fica exonerada de qualquer responsabilidade:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  Em caso de incumprimento pelo Utilizador das estipulações das presentes Condições Gerais de
                  Utilização;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Em caso de mau funcionamento da Plataforma resultante quer de uma causa voluntária ou involuntária
                  imputável ao Utilizador ou a um terceiro, quer de um ato malicioso, quer de um mau funcionamento dos
                  softwares, smartphones, tablet, PCs ou qualquer outra interface/produto pertencente ao Utilizador;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Em caso de alterações legislativas ou regulamentares que afetem a Plataforma.</Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            Além disso, o Utilizador reconhece que a Plataforma pode ser alimentada por redes de comunicação e que a
            RENAULT não pode, em caso algum, ser responsabilizada em caso de mau funcionamento das referidas redes de
            comunicação.
          </Paragraph>
          <Paragraph>
            Ao utilizar a Plataforma, o Utilizador compromete-se a fazê-lo em conformidade com as presentes Condições
            Gerais de Utilização e no respeito da regulamentação em vigor.
          </Paragraph>
          <Paragraph>
            O Comprador e o Vendedor são inteiramente responsáveis pela utilização do Site e por qualquer utilização dos
            dados/informações recebidos através do Site, mesmo que não sejam a pessoa que utiliza estes
            dados/informações.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 11.'} title={'Utilização não prejudicial da Plataforma'} />
          <Paragraph>
            O Comprador deve abster-se, em todas as circunstâncias, de qualquer atividade suscetível de comprometer a
            atividade da Plataforma, de prejudicar a sua reputação, a reputação dos serviços que presta e a reputação e
            atividades do Grupo Renault.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 12.'} title={'Intuitu personae'} />
          <Paragraph>
            A Plataforma, criada pelo Grupo Renault, contribui para a imagem, reputação e visibilidade do Grupo Renault.
            A relação entre a Plataforma e os seus Utilizadores baseia-se, portanto, em &quot;intuitu personae&quot;.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 13.'} title={'Desistência, bloqueio ou exclusão da Plataforma'} />
          <Text type={'text_bold'} decoration={'underline'}>
            Desistência
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            Qualquer Comprador pode, a qualquer momento, notificar a Plataforma, através do seu R1, da sua decisão de
            deixar de utilizar os seus serviços.
          </Paragraph>
          <Paragraph>
            O Comprador deve continuar a cumprir as obrigações que assumiu com os Vendedores antes da data em que a sua
            participação na Plataforma cessar.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Bloqueio
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            A Plataforma pode tomar quaisquer medidas adequadas e proporcionais que considere necessárias, consistindo
            em restringir o acesso, desclassificar ou suspender (a seguir designadas por “Medidas de Restrição”) a
            totalidade ou parte das informações/elementos colocados em linha pelo Concessionário, mediante notificação
            prévia ou, o mais tardar, no dia da aplicação da Medida de Restrição, decidida com base nos seguintes
            motivos:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>
                  presunção séria de comportamento abusivo que prejudique ou viole a plataforma, estes CGU, a legislação
                  em vigor ou a imagem e reputação do Grupo Renault;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>utilização do Espaço do Comprador por outras pessoas que não o Comprador;</Text>
              </Li>
              <Li>
                <Text type={'text'}>negligência ou incumprimento da obrigação de confidencialidade;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  conhecimento pela Plataforma da existência de conteúdos ilícitos ou alegadamente ilícitos,
                  nomeadamente na sequência da receção de uma notificação ou de uma injunção para agir contra conteúdos
                  ilícitos emitida por uma autoridade competente.
                </Text>
              </Li>
            </Ol>
          </Paragraph>
          <Paragraph>
            A Medida de Restrição é tomada por um período proporcional aos factos denunciados, não podendo exceder um
            período de 6 (seis) meses.
          </Paragraph>
          <Paragraph>
            A Medida de Restrição adotada pela Plataforma pode ser contestada através do sistema interno de reclamações.
            Se o comprador não conseguir remediar os factos denunciados, a Plataforma pode excluir definitivamente o
            Comprador nas condições abaixo indicadas.
          </Paragraph>
          <Text type={'text_bold'} decoration={'underline'}>
            Exclusão
          </Text>
          <MarginBox mt={5} />
          <Paragraph>
            A Plataforma pode excluir definitivamente um Comprador no caso de:
            <Ol type={'i'}>
              <Li>
                <Text type={'text'}>abandono da rede Renault admitida na Plataforma, seja qual for o motivo;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  comportamentos referidos na alínea b) - 1) do presente artigo. A Plataforma terá, portanto, uma opção
                  entre o bloqueio e a exclusão imediata;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  deturpação, independentemente da sua gravidade, uma vez que a deturpação constitui uma quebra de
                  confiança;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>repetição dos comportamentos referidos nas alíneas b) - 2) a 4) acima;</Text>
              </Li>
              <Li>
                <Text type={'text'}>reclamações repetidas dos Vendedores;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  qualquer alteração na situação pessoal do Comprador que seja suscetível de afetar o intuitu personae
                  (nomeadamente: alteração direta ou indireta do controlo, alteração significativa da produção ou das
                  certificações, processo de insolvência em conformidade com a legislação em vigor).
                </Text>
              </Li>
            </Ol>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 14.'} title={'Transações comerciais - Sigilo comercial'} />
          <Paragraph>
            A Plataforma não pode, de forma alguma, permitir que terceiros (concorrentes, associados, parceiros, etc.)
            tenham conhecimento das trocas, negociações, contratos e acordos realizados ou celebrados através da
            Plataforma. A Plataforma respeitará sempre o seu programa de confidencialidade.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 15.'} title={'Promoções e publicidade'} />
          <Paragraph>
            O Comprador compromete-se a não utilizar a Plataforma, direta ou indiretamente, para promover, direta ou
            indiretamente, sob qualquer forma, os seus produtos ou serviços, ou os de qualquer outra pessoa.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 16.'} title={'Ofertas concorrentes - Sem exclusividade'} />
          <Paragraph>A adesão à Plataforma não cria qualquer obrigação para os Compradores de a utilizarem.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 17.'} title={'Alterações das CGU'} />
          <Paragraph>
            A Plataforma reserva-se no direito de alterar as CGU. Qualquer alteração entrará em vigor na data indicada
            na notificação enviada aos Compradores nos seus espaços.
          </Paragraph>
          <Paragraph>
            As alterações não serão aplicáveis às transações em curso na data da sua entrada em vigor.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 18.'} title={'Utilização dos dados pessoais recolhidos pela Plataforma'} />
          <Paragraph>
            No que diz respeito à utilização da Plataforma, os seus dados pessoais são tratados pela RENAULT de acordo
            com a Política de Privacidade acessível através do seguinte link:{' '}
            <Link to={'https://rpartstore.renault.com/personal-data/fr'} external newTab>
              https://rpartstore.renault.com/personal-data/fr
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 19.'} title={'Força maior'} />
          <Paragraph>
            A Plataforma não pode ser considerada responsável por qualquer evento de força maior que resulte num mau
            funcionamento da Plataforma.
          </Paragraph>
          <Paragraph>
            Qualquer evento com caraterísticas de força maior, tal como definido pela legislação aplicável, e que tenha
            consequências sobre as obrigações contratuais de qualquer das Partes não pode implicar a sua
            responsabilidade.
          </Paragraph>
          <Paragraph>
            O Comprador aceita igualmente considerar que as caraterísticas contratuais de força maior incluirão,
            nomeadamente, cortes de energia, falhas na rede de Internet, avarias informáticas (“bugs”, vírus, malware,
            intrusões), mesmo que a sua origem técnica esteja na conceção do serviço de informação.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 20.'} title={'Confidencialidade e prova'} />
          <Paragraph>
            A Plataforma implementa os meios técnicos adequados para assegurar a confidencialidade das informações e dos
            dados ali transmitidos, ou das negociações efetuadas, a fim de evitar qualquer divulgação a terceiros.
          </Paragraph>
          <Paragraph>
            Estes dados serão, no entanto, considerados como elementos de prova válidos e admissíveis entre os
            Comerciantes e os Clientes, bem como entre estes últimos e a Plataforma.
          </Paragraph>
          <Paragraph>Estes dados serão conservados em conformidade com as disposições legais.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 21.'} title={'Propriedade intelectual'} />
          <Paragraph>
            Todos os elementos que compõem a Plataforma (tais como textos, logótipos, imagens, elementos gráficos ou
            sonoros, software, layout, bases de dados, código fonte, etc.), bem como as denominações sociais, marcas,
            desenhos e sinais distintivos (a seguir designados por “Conteúdos”) estão protegidos por direitos de
            propriedade intelectual. Os Conteúdos são propriedade exclusiva da RENAULT e/ou de terceiros que autorizaram
            a sua utilização.
          </Paragraph>
          <Paragraph>
            O Utilizador beneficia de uma licença simples, limitada, não exclusiva, intransmissível, não sublicenciada,
            revogável, para utilizar os Conteúdos no contexto estrito da utilização da Plataforma, conforme aqui
            descrito. Qualquer outra utilização, total ou parcial, dos Conteúdos, nomeadamente a reprodução,
            representação, adaptação, modificação, descompilação por qualquer processo e em qualquer suporte, incluindo
            a fusão com outros programas informáticos, sem a autorização prévia por escrito da RENAULT é estritamente
            proibida e constitui uma infração.
          </Paragraph>
          <Paragraph>
            O Utilizador compromete-se a informar a RENAULT, o mais rapidamente possível, caso tenha conhecimento de
            qualquer ato praticado em violação das disposições do presente artigo.
          </Paragraph>
          <Paragraph>
            No âmbito estrito da utilização da Plataforma, o Utilizador está autorizado a utilizar as marcas e os
            direitos de propriedade intelectual de que é titular ou dos quais adquiriu legalmente os direitos, bem como
            a sua firma ou denominação social. O Utilizador garante que possui os direitos que permitem à RENAULT
            reproduzir na Plataforma os elementos fornecidos pelo Utilizador. A este respeito, o Utilizador garante a
            RENAULT contra qualquer reclamação, pedido, ação ou recurso de terceiros relacionados com a utilização dos
            referidos elementos e suporta todas as despesas e danos que daí possam resultar, nomeadamente, em
            consequência de uma decisão judicial, mesmo não definitiva, ou de um acordo.
          </Paragraph>
          <Paragraph>
            O Utilizador está autorizado a mencionar nos seus documentos comerciais o seu estatuto de Utilizador
            referenciado pela Plataforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 22.'} title={'Responsabilidade'} />
          <Paragraph>
            O Vendedor reconhece que a RENAULT, na sua qualidade de anfitriã, não é parte de qualquer acordo e/ou
            contrato celebrado entre o Concessionário e o Cliente, na sequência da publicação dos Veículos na
            Plataforma.
          </Paragraph>
          <Paragraph>
            O Vendedor é o único responsável pela apresentação ou descrição dos Veículos que propõe, da afixação dos
            seus preços, de qualquer comunicação e publicidade que possa efetuar; por conseguinte, a Plataforma não pode
            ser responsabilizada por quaisquer erros ou omissões cometidas pelo Vendedor na descrição das peças e
            acessórios e/ou na indicação dos preços que são publicados na Plataforma.
          </Paragraph>
          <Paragraph>
            O Vendedor é o único responsável pela aplicação correta das disposições legais e, em particular, pela
            negociação, celebração e execução dos contratos relativos às peças e acessórios propostos online na
            Plataforma e pela gestão das reclamações, recursos, litígios que o Comprador possa iniciar contra ele,
            nomeadamente em caso de não pagamento.
          </Paragraph>
          <Paragraph>
            De uma forma mais geral, o Vendedor é o único responsável perante os Compradores e terceiros na sua
            qualidade de Vendedor e compromete-se a não responsabilizar a RENAULT por qualquer demanda relacionada com a
            compra e venda de peças e acessórios e suportará todos os custos e danos que possam resultar a este título,
            pagando todas as perdas e danos, sanções pecuniárias compulsórias, despesas de processo e todas as somas de
            qualquer natureza que possam ser imputadas à RENAULT, para que a RENAULT não possa em caso algum ser
            responsabilizada seja de que forma for.
          </Paragraph>
          <Paragraph>
            No caso de um Comprador, ou qualquer outra pessoa, intentar uma ação judicial contra a Plataforma por factos
            relacionados com a atividade do Vendedor, este último compromete-se a indemnizar a Plataforma por qualquer
            sentença.
          </Paragraph>
          <Paragraph>O Vendedor é também o único responsável pela utilização do seu Espaço Vendedor.</Paragraph>
          <Paragraph>
            O Comprador é o único responsável pela encomenda dos produtos e acessórios propostos para venda na
            Plataforma.
          </Paragraph>
          <Paragraph>
            É também o único responsável pela utilização do seu Espaço do Comprador e das informações fornecidas aos
            Vendedores.
          </Paragraph>
          <Paragraph>
            De uma forma mais geral, é o único responsável enquanto Comprador, não podendo a Plataforma ser
            responsabilizada na sua qualidade de Comprador.
          </Paragraph>
          <Paragraph>
            No caso de um Vendedor, ou qualquer outra pessoa, intentar uma ação judicial contra a Plataforma por factos
            relacionados com a sua atividade de Comprador, o Vendedor compromete-se a indemnizar a Plataforma por
            qualquer condenação.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 23.'} title={'Reclamações - notificaçõe'} />
          <Paragraph type={'text_bold'}>
            <i>a) Sistema interno de reclamações</i>
          </Paragraph>
          <Paragraph align={'start'}>
            A Plataforma criou um sistema interno de reclamações sobre os seguintes assuntos:
            <Ul>
              <Li>
                Alegadas falhas da Plataforma relacionadas com a prestação de serviços de intermediação online e que
                afetam o Utilizador reclamante;
              </Li>
              <Li>
                Questões tecnológicas diretamente relacionadas com a prestação de serviços de intermediação e que afetam
                o Utilizador reclamante;
              </Li>
              <Li>
                Medidas restritivas tomadas pela Plataforma ou o seu comportamento diretamente relacionado com a
                prestação de serviços de intermediação online, e que afetam o Utilizador reclamante.
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            No que diz respeito às Medidas Restritivas tomadas pela Plataforma na sequência de uma notificação ou com
            base no facto de as informações/elementos fornecidos pelo Utilizador serem considerados ilícitos ou
            incompatíveis com as CGU, o Utilizador pode apresentar uma reclamação durante um período de 6 meses após a
            notificação da referida Medida.
          </Paragraph>
          <Paragraph>
            A Plataforma compromete-se a tratar as reclamações num prazo compatível com a complexidade e a importância
            do problema levantado e a enviar uma resposta ao Utilizador que apresenta a reclamação.
          </Paragraph>
          <Paragraph>
            As decisões tomadas no âmbito do sistema interno de reclamações da Plataforma são suscetíveis de recurso
            extrajudicial nos termos do artigo 25º ou de recurso judicial.
          </Paragraph>
          <Paragraph type={'text_bold'}>
            <i>b) Notificações</i>
          </Paragraph>
          <Paragraph>
            <Text type={'text'}>
              O Utilizador pode efetuar qualquer notificação precisa e fundamentada por meios eletrónicos, a fim de
              indicar a presença na Plataforma de conteúdos que considere ilícitos. Estas notificações serão verificadas
              pela Plataforma, que se reserva no direito de decidir se aceita ou não esta qualificação e de adotar as
              medidas de restrição adequadas. A decisão tomada pela Plataforma será comunicada ao queixoso, caso este se
              tenha identificado, e poderá ser objeto de recurso, nomeadamente através do sistema interno de queixas.
              Se, por razões técnicas ou operacionais, a Plataforma não puder tomar medidas diretas, informará o
              queixoso.
            </Text>
          </Paragraph>
          <Paragraph>
            Um único ponto de contacto é o seguinte:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 24.'} title={'Mediação'} />
          <Paragraph type={'text_bold'}>
            <i>a) Litígios entre Utilizadores – Disponibilização de um serviço de mediação</i>
          </Paragraph>
          <Paragraph type={'text'}>
            A Plataforma nomeia no seu website um Mediador independente, ao qual os Utilizadores podem decidir, de comum
            acordo, submeter o seu litígio. Este encaminhamento implica a suspensão das obrigações decorrentes das CGU,
            das Condições Gerais de Venda de peças e acessórios ou de quaisquer outros contratos celebrados entre os
            Utilizadores. O mediador deverá tomar uma decisão no prazo de um mês a contar da data de apresentação do
            litígio, com a possibilidade de prorrogação por um mês, se o mediador assim o entender, ou por um período
            mais longo, se as partes estiverem de acordo.
          </Paragraph>
          <Paragraph type={'text_bold'}>
            <i>b) Litígios relacionados com os serviços prestados pela Plataforma</i>
          </Paragraph>
          <Paragraph type={'text'}>
            O mediador referido na alínea a) supra poderá igualmente ser contactado por qualquer utilizador da
            Plataforma nas condições acima descritas. A Plataforma aceita antecipadamente submeter-se à mediação.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Artigo 25.'} title={'Lei aplicável - Litígios'} />
          <Paragraph>
            Estas CGU regem-se pela lei francesa e serão interpretados e aplicados em conformidade com a mesma, exceto
            no caso de o Utilizador ser um consumidor e a lei local for obrigatoriamente aplicável.
          </Paragraph>
          <Paragraph>
            Qualquer litígio relativo aos presentes T&C deve, primeiro, ser objeto de uma tentativa de resolução
            amigável entre as partes antes de ser submetido aos tribunais judiciais competentes.
          </Paragraph>
          <Paragraph>
            No caso da lei local ser obrigatoriamente aplicável, qualquer litígio relativo aos T&C deverá ser objeto,
            primeiramente, de uma tentativa de resolução amigável entre as partes antes de ser submetido aos tribunais
            judiciais competentes. O cliente consumidor tem ainda a possibilidade de recorrer a uma das entidades de
            resolução alternativa de litígios de consumo com competência territorial quanto ao local da celebração do
            contrato, elencadas no mapa detalhado disponível para consulta disponível em{' '}
            <Link
              to={
                'https://www.consumidor.gov.pt/parceiros/sistema-de-defesa-do-consumidor/entidades-de-resolucao-alternativa-de-litigios-de-consumo/ral-mapa-e-lista-de-entidades.aspx'
              }
              external
              newTab
            >
              https://www.consumidor.gov.pt/parceiros/sistema-de-defesa-do-consumidor/entidades-de-resolucao-alternativa-de-litigios-de-consumo/ral-mapa-e-lista-de-entidades.aspx
            </Link>
            .
          </Paragraph>
          <Paragraph>
            O Cliente consumidor poderá, alternativamente decidir recorrer ao tribunal arbitral Centro de Arbitragem de
            Litígios Civis, Comerciais e Administrativos. Só os litígios de reduzido valor económico estão sujeitos à
            arbitragem necessária.
          </Paragraph>
          <Paragraph>
            Se o Cliente residir no espaço da União Europeia, tem também a possibilidade, nomeadamente e principalmente
            para as reclamações relacionadas com uma compra online, de recorrer à plataforma de Resolução de Litígios em
            Linha no caso dos serviços fornecidos pelas empresas da União Europeia (a «Plataforma ODR»), disponibilizada
            para todos os cidadãos europeus pela Comissão Europeia, através do link{' '}
            <Link to={'https://ec.europa.eu/consumers/odr'} external newTab>
              https://ec.europa.eu/consumers/odr
            </Link>
            .
          </Paragraph>
        </Section>
        <MarginBox mt={60} />
        <SectionHeader title={'ANEXO I'} underline />
        <SectionHeader title={'INFORMAÇÃO DOS COMPRADORES - PROCEDIMENTO DE ACESSO'} underline />
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-FR.jpg'} alt={'information-diagram-france'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
