import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TargetCountry } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { Select } from 'antd';
import PrivateComponent from 'composers/PrivateComponent';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { getUserCountry, getUserRights, UserRole } from 'domains/user';
import { Flex, Input, Text } from 'UI';
import { Country } from 'utils/i18n/Country';
import { getCountries } from '../Options';

export const TargetCountries = ({
  information,
  setInformation,
}: {
  information: CreateInformationModel | CreateInformationTemplateModel;
  setInformation: (informationToCreate: CreateInformationModel | CreateInformationTemplateModel) => void;
}) => {
  const { t } = useTranslation();
  const userRights = useSelector(getUserRights);
  const userCountry = Country.findByKey(useSelector(getUserCountry));

  const countriesOptions = getCountries();

  return (
    <Flex direction={'column'} size={0} minWidth={270}>
      <Text type={'h2'}>{t('backoffice.information.create_information.targeted_countries', 'Targeted countries')}</Text>
      <PrivateComponent
        render={() => (
          <Select
            mode={'multiple'}
            value={information?.targetCountries}
            onChange={(country: TargetCountry[]) => {
              setInformation({
                ...information,
                targetCountries: country,
              });
            }}
            options={countriesOptions}
            filterOption={(input, option) => {
              if (input && option && option.label) {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              return false;
            }}
            placeholder={t(
              'backoffice.information.create_information.targeted_countries.select',
              'Select targeted countries',
            )}
            defaultOpen={false}
            bordered
            allowClear
          />
        )}
        requiredRights={[UserRole.CENTRAL_ADMIN]}
      />
      {!userRights.includes(UserRole.CENTRAL_ADMIN) && (
        <PrivateComponent
          render={() => (
            <Flex minWidth={270} minHeight={32} maxHeight={32}>
              <Input value={userCountry?.name} bordered readOnly />
            </Flex>
          )}
          requiredRights={[UserRole.COUNTRY_ADMIN]}
        />
      )}
    </Flex>
  );
};
