import { ReferenceLocal } from 'domains/basket/Basket.types';

export const STANDARD_EXCHANGE_REFERENCE_PATTERN = '81008';
export const STANDARD_EXCHANGE_REFERENCE_DESPOSIT_PATTERN = '81009';

/**
 * Deposit ref for exadis ref.
 * @param reference
 */
export function isRefNumberDeposit(reference?: string): boolean {
  return reference?.startsWith('81009') ?? false;
}

/**
 * Exadis ref.
 * @param reference
 */
export function isRefNumberDepositMain(reference?: string): boolean {
  return reference?.startsWith('81008') ?? false;
}

export function getDepositMainReferenceNumber(exadisReference: string): string {
  return exadisReference.replace(STANDARD_EXCHANGE_REFERENCE_PATTERN, STANDARD_EXCHANGE_REFERENCE_DESPOSIT_PATTERN);
}

//select also deposit ref
export function checkSelectedHandler(
  selectedReference: string,
  references: ReferenceLocal[] | undefined,
): ReferenceLocal[] | undefined {
  const isSelectedRefExadis = isRefNumberDepositMain(selectedReference);
  //not exadis ref
  if (!isSelectedRefExadis) {
    return references?.map((ref) =>
      ref.referenceNumber === selectedReference ? { ...ref, isSelected: !ref.isSelected } : ref,
    );
  }

  //exadis ref
  const depositRefNumber = getDepositMainReferenceNumber(selectedReference);
  return references?.map((ref) =>
    ref.referenceNumber === selectedReference || ref.referenceNumber === depositRefNumber
      ? { ...ref, isSelected: !ref.isSelected }
      : ref,
  );
}

export function refWithoutDepositRef(references: ReferenceLocal[] | undefined): ReferenceLocal[] {
  return (references ?? []).filter((value) => !isRefNumberDeposit(value.referenceNumber));
}
