export const rollDownEffect = (cardElementId: string, contentElementId: string, large?: boolean): void => {
  const element = document.getElementById(cardElementId);
  const content = document.getElementById(contentElementId);
  if (element && content) {
    setTimeout(() => {
      element.setAttribute('style', `height: ${large ? 360 : 250}px;`);
    }, 5);
    setTimeout(() => {
      content.setAttribute('style', 'display: block;');
    }, 205);
  }
};
