import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Init state
import moment from 'moment';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { hasData, NO_DATA } from 'utils';
import { GET_URL_SIGNATURE, SIGNATURE_NAMESPACE, UrlSignaturesState } from './UrlSignatures.types';

const initialState: UrlSignaturesState = {
  datahubUrlSignature: undefined,
  datahubSignatureExpiration: undefined,
};

export const fetchUrlSignature = createAction(GET_URL_SIGNATURE);

export const fetchUrlSignatureResponse = createAction('1PO/URL/URL_SIGNATURE_RESPONSE');

// Slice
const slice = createSlice({
  name: SIGNATURE_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setSignature: (state, { payload }: PayloadAction<string | NO_DATA>) => {
      if (hasData(payload)) {
        const expiresStr = 'Expires=';
        const strOffset = expiresStr.length;
        const expires = payload.substring(payload.indexOf(expiresStr) + strOffset, 10 + strOffset);
        state.datahubSignatureExpiration = moment.unix(Number(expires));
      } else {
        state.datahubSignatureExpiration = undefined;
      }
      state.datahubUrlSignature = payload;
    },
  },
});

// Actions
export const { setInitialState, setSignature } = slice.actions;

// Getters/Selectors
export const getSignature = createSelector(
  (state: RootState) => state.urlSignature.datahubUrlSignature,
  (datahubUrlSignature) => datahubUrlSignature,
);

export const getSignatureExpiration = createSelector(
  (state: RootState) => state.urlSignature.datahubSignatureExpiration,
  (signatureExpiration) => signatureExpiration,
);

// Export reducer
export default slice.reducer;
