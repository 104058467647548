import React from 'react';
import { Image, Link, PUBLIC } from 'UI';

const RpartStoreLogo = () => {
  return (
    <Link to={'/'}>
      <Image
        type={PUBLIC}
        src={'startup-logo-rpartstore.png'}
        alt={'startup-logo-rpartstore'}
        width={120}
        dataCy={'rpart-store-logo'}
      />
    </Link>
  );
};

export default RpartStoreLogo;
