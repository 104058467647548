import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PARAM_LABOR_TIME, PARAM_REPAIR_METHODS, PARAM_SPARE_PARTS } from 'domains/catalog/Catalog.types';
import { getIAMAvailableModules } from 'domains/maintenancePlan/MaintenancePlan.store';
import { getUserRights, UserRole } from 'domains/user';
import { Flex, LinkTabs, Lowercase, Text } from 'UI';
import { getData, hasUserAnyRight } from 'utils';

export type TabState = 'spare_parts' | 'labor_time' | 'repair_methods';

export const SubcategorySectionHeader = ({
  disabledTabs,
  label,
  getParamState,
}: {
  disabledTabs: TabState[];
  label: string | undefined;
  getParamState: (tabState: TabState) => URLSearchParams;
}) => {
  const { t } = useTranslation();
  const userRights = getData(useSelector(getUserRights));
  const availableDataModule = getData(useSelector(getIAMAvailableModules));

  function getLabel(tab: TabState) {
    switch (tab) {
      case 'labor_time':
        return t('catalog.parts.category.car_parts.labor_time', 'Labor time');
      case 'repair_methods':
        return t('catalog.parts.category.car_parts.repair_method', 'Repair method');
      case 'spare_parts':
      default:
        return t('catalog.parts.category.car_parts.parts', 'Spare parts');
    }
  }

  const tabItems = [
    {
      label: getLabel(PARAM_SPARE_PARTS),
      link: '',
      uparam: getParamState(PARAM_SPARE_PARTS),
      key: 0,
      isDisabled: disabledTabs.some((n) => n === PARAM_SPARE_PARTS),
      requiredRights: [],
      hidden: false,
    },
    {
      label: getLabel(PARAM_LABOR_TIME),
      link: '',
      uparam: getParamState(PARAM_LABOR_TIME),
      key: 1,
      isDisabled: disabledTabs.some((n) => n === PARAM_LABOR_TIME),
      requiredRights: [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
      hidden: false,
    },
    {
      label: getLabel(PARAM_REPAIR_METHODS),
      link: '',
      uparam: getParamState(PARAM_REPAIR_METHODS),
      key: 2,
      isDisabled: disabledTabs.some((n) => n === PARAM_REPAIR_METHODS),
      requiredRights: [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
      hidden: !availableDataModule?.availableData.find((d) => d.name === 'manuals')?.dataAvailable,
    },
  ].filter((tab) => hasUserAnyRight(tab.requiredRights, userRights) && !tab.hidden);

  return (
    <Flex direction={'row'} align={'center'} wrap={'wrap'}>
      <Flex size={'none'} direction={'column'}>
        <Text type={'h1_banner_light'} transform={'capitalize'}>
          <Lowercase>{label}</Lowercase>
        </Text>
      </Flex>
      <Flex size={'auto'} justify={'flex-end'} direction={'row'}>
        <LinkTabs items={tabItems} variant={'white'} />
      </Flex>
    </Flex>
  );
};
