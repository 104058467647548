import { Collapse as CollapseAnt } from 'antd';
import styled from 'styled-components';
import { theme } from 'styles';
import { defaultBoxShadow, Flex } from 'UI';

export const SCollapse = styled(CollapseAnt)<{
  noBorder?: boolean;
  noMargin?: boolean;
  noShadow?: boolean;
}>`
  border: ${({ noBorder }) => (noBorder ? 'none' : undefined)};
  background-color: ${({ theme }) => theme.color.white};

  .ant-collapse-header {
    box-shadow: ${({ theme, noShadow }) => (noShadow ? 'none' : `0 5px 12px 4px ${theme.color.shadow_1}`)};
    background-color: ${theme.color.grey95};
  }

  .ant-collapse-item {
    border-bottom: ${({ noBorder }) => (noBorder ? 'none' : undefined)};
    margin-bottom: 30px;
  }

  .ant-collapse-content {
    padding: ${({ noMargin }) => (noMargin ? '0 0 0 0' : '16px 16px 16px 16px')};
  }

  .ant-collapse-content-active,
  .ant-collapse-content-inactive {
    padding: 0;
  }

  .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: ${({ noMargin }) => (noMargin ? '0 0 0 0' : '16px 16px 0px 16px')};
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 16px;
  }
`;

export const SCard = styled.div<{ selected: boolean }>`
  background-color: ${theme.color.white};
  width: 575px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.color.grey85};
  cursor: pointer;

  ${({ selected }) => selected && defaultBoxShadow}
  :hover {
    ${defaultBoxShadow}
  }
`;

export const TiresContainer = styled(Flex)`
  position: relative;
`;

export const CatalogTireItemContainer = styled(Flex)<{
  selected: boolean;
  sameSizeSection?: boolean;
}>`
  ${({ sameSizeSection }) => !sameSizeSection && `border-bottom: ${theme.color.grey_light} 1px solid`};
  cursor: pointer;
  border-radius: 4px;

  ${({ selected }) => selected && defaultBoxShadow}
  :hover {
    ${defaultBoxShadow};
  }
`;
