import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { NO_FILE } from 'components/FileUpload/paramsByState';
import { InlineNotification } from 'components/Notification/InlineNotification';
import { removeUploadedFileInfo } from 'domains/promotion/Promotion.store';
import {
  CFSectionLocal,
  CreatePromotionModel,
  ReferencesSectionLocal,
  UploadedReferencesFromFile,
} from 'domains/promotion/Promotion.types';
import { Box, Flex, MarginBox, Text } from 'UI';
import { useBreakpointSelectorFull } from 'utils';
import { STagReferencesError } from '../CreatePromotion.styled';

export function containsAlreadyExistingCommercialFamilyCodes(commercialFamilyCodes?: CFSectionLocal[]) {
  return commercialFamilyCodes
    ? commercialFamilyCodes.some((section) => section.items.some((item) => item.removable && item.alreadyPromoted))
    : false;
}

export function containsAlreadyLocallyPromotedFamilyCodes(commercialFamilyCodes?: CFSectionLocal[]) {
  return commercialFamilyCodes
    ? commercialFamilyCodes.some((section) =>
        section.items.some((item) => item.removable && item.alreadyLocallyPromoted),
      )
    : false;
}

export function hasLocalCommercialFamiliesCodeDuplicates(code: string, commercialFamilyCodes?: CFSectionLocal[]) {
  if (!commercialFamilyCodes) {
    return false;
  }
  return !commercialFamilyCodes?.every((section) => {
    return !section.items.find((item) => item.item === code);
  });
}

export function containsAlreadyPromotedProduct(references?: ReferencesSectionLocal[]) {
  return (
    (references && references.length > 0 && references[0].items.length > 0 && references[0].items[0].alreadyPromoted) ??
    false
  );
}

export function containsAlreadyExistingReferences(uploadedReferencesFromFiles: UploadedReferencesFromFile[]) {
  return uploadedReferencesFromFiles.some((d) => d.alreadyPromoted && d.alreadyPromoted.length > 0);
}

export const AlreadyExistingCommercialFamilyCodeNotification = ({
  promotion,
  setCurrentStep,
  setPromotion,
}: {
  promotion: CreatePromotionModel;
  setCurrentStep: (x: number) => void;
  setPromotion: (x: CreatePromotionModel) => void;
}) => {
  const { t } = useTranslation();

  if (promotion.promotionType === 'BANNER') {
    return <></>;
  }

  const handleAction = () => {
    setCurrentStep(0);
  };

  const handleDeleteAll = () => {
    const commercialFamilyCodes = promotion.commercialFamilyCodes;
    const notPromotedCodes: CFSectionLocal[] | undefined = commercialFamilyCodes?.map((section) => {
      return {
        ...section,
        items: section.items.filter(
          (item) => !(item.removable && (item.alreadyPromoted || item.alreadyLocallyPromoted)),
        ),
      };
    });
    setPromotion({ ...promotion, commercialFamilyCodes: notPromotedCodes });
  };

  return (
    <>
      {containsAlreadyExistingCommercialFamilyCodes(promotion.commercialFamilyCodes) ? (
        <Flex>
          <InlineNotification
            displayStyle={'error'}
            message={t(
              'backoffice.promotion.already_promoted.error_message.overlapping_date',
              'Overlapping date of existing promotion',
            )}
            description={t(
              'backoffice.promotion.commercial_family.already_promoted.error_message.description',
              'Commercial family already part of an existing promotion.',
            )}
            action={
              <Flex onClick={handleAction} justify={'flex-end'}>
                <Text type={'text'} displayStyle={'link'} decoration={'underline'} cursor={'pointer'}>
                  {t('backoffice.promotion.already_promoted.change_dates', 'Change dates')}
                </Text>
              </Flex>
            }
          />
        </Flex>
      ) : (
        <>
          {containsAlreadyLocallyPromotedFamilyCodes(promotion.commercialFamilyCodes) && (
            <InlineNotification
              displayStyle={'error'}
              description={t(
                'backoffice.promotion.commercial_family.already_promoted.multiple_times.error_message',
                'Commercial family has been added multiple times.',
              )}
              action={
                <Flex onClick={handleDeleteAll} justify={'flex-end'}>
                  <Text type={'text'} displayStyle={'error'} decoration={'underline'} cursor={'pointer'}>
                    {t('common.action.delete_all', 'Delete all')}
                  </Text>
                </Flex>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export const unblockRemovedReferenceInFirstFoundFile = (
  removedReference: string,
  indexToSearchFrom: number,
  updatedFiles: UploadedReferencesFromFile[],
) => {
  // if reference is removed, unblock this reference in first following file
  for (let i = indexToSearchFrom + 1; i < updatedFiles.length; i++) {
    const x = updatedFiles[i];
    const indexOfRemovedReference = x.alreadyLocallyPromoted?.indexOf(removedReference) ?? -1;
    if (indexOfRemovedReference > -1 && x.alreadyLocallyPromoted) {
      const newAlreadyLocallyPromoted = [...x.alreadyLocallyPromoted];
      newAlreadyLocallyPromoted.splice(indexOfRemovedReference, 1);
      updatedFiles[i] = {
        ...x,
        alreadyLocallyPromoted: newAlreadyLocallyPromoted,
        rows: [...(x.rows ?? []), removedReference],
      };
      return;
    }
  }
};

interface AlreadyExistingReferencesNotificationProps {
  promotion: CreatePromotionModel;
  setPromotion: (x: CreatePromotionModel) => void;
  duplicateItems: string[];
  fileId?: string;
  setCurrentStep: (x: number) => void;
}

export const AlreadyExistingReferencesNotification = ({
  promotion,
  setPromotion,
  duplicateItems,
  fileId,
  setCurrentStep,
}: AlreadyExistingReferencesNotificationProps) => {
  const { t } = useTranslation();
  const width = useBreakpointSelectorFull()({ xs: 750, sm: 750, md: 750, lg: 750, xl: 900, xxl: 1050 });
  const dispatch = useDispatch();

  const handleRemoveReference = (data: string[], index: number) => {
    const updatedFiles = [...promotion.uploadedReferencesFromFiles];
    const fileToUpdateIndex = updatedFiles.findIndex((file) => file.fileId === fileId);

    if (fileToUpdateIndex === -1) {
      return;
    }

    let fileToUpdate = updatedFiles[fileToUpdateIndex];
    const updatedReferences = [...data];
    updatedReferences.splice(index, 1);

    if (updatedReferences.length === 0 && (fileToUpdate.rows?.length ?? 0) === 0) {
      fileToUpdate = {
        fileId: uuidV4(),
        rows: [],
        status: NO_FILE,
        discount: 0,
      };
    }

    updatedFiles[fileToUpdateIndex] = {
      ...fileToUpdate,
      // locally
      alreadyPromoted: (fileToUpdate.alreadyPromoted ?? []).filter((r) => updatedReferences.includes(r)),
      alreadyLocallyPromoted: (fileToUpdate.alreadyLocallyPromoted ?? []).filter((r) => updatedReferences.includes(r)),
    };

    setPromotion({
      ...promotion,
      uploadedReferencesFromFiles: updatedFiles,
    });

    if (updatedReferences.length === 0 && (fileToUpdate.rows?.length ?? 0) === 0 && fileId) {
      dispatch(removeUploadedFileInfo({ fileId }));
    }
  };

  const handleAction = () => {
    setCurrentStep(0);
  };

  if (!duplicateItems || duplicateItems.length === 0) {
    return <></>;
  }

  return (
    <>
      <Flex direction={'row'}>
        <Flex direction={'column'} size={8}>
          <Text type={'h5_bold'}>{t('promotion.creation.needs_your_attention', 'Need your attention')}</Text>
          <MarginBox mt={40} />
        </Flex>
        <MarginBox mr={60} />
      </Flex>
      <InlineNotification
        displayStyle={'error'}
        message={t(
          'backoffice.promotion.already_promoted.error_message.overlapping_date',
          'Overlapping date of existing promotion',
        )}
        description={t(
          'backoffice.promotion.references.already_promoted.error_message.description',
          'Several references already part of an existing promotion. Please adjust publication dates.',
          { count: duplicateItems.length },
        )}
        action={
          promotion.promotionType !== 'PROMOTION_FLASH_QUANTITY_LIMITED' && (
            <Flex onClick={handleAction}>
              <Text type={'text'} displayStyle={'link'} decoration={'underline'} cursor={'pointer'}>
                {t('backoffice.promotion.already_promoted.change_dates', 'Change dates')}
              </Text>
            </Flex>
          )
        }
      />
      <MarginBox mt={30} />
      <Box width={width}>
        {duplicateItems.map((item, index) => {
          const handleRemoveItem = () => {
            if (index === undefined) {
              return;
            }
            handleRemoveReference(duplicateItems, index);
          };

          return (
            <React.Fragment key={`Reference_${item}`}>
              <STagReferencesError key={`Reference_${item}`} closable={true} onClose={() => handleRemoveItem()}>
                <Text type={'link_12_red'} key={`Reference_${item}`}>
                  {item}
                </Text>
              </STagReferencesError>
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
};

export const AlreadyPromotedProductNotification = ({
  promotion,
  setCurrentStep,
}: {
  promotion: CreatePromotionModel;
  setCurrentStep: (x: number) => void;
}) => {
  const { t } = useTranslation();
  const handleAction = () => {
    setCurrentStep(0);
  };

  return (
    <>
      {containsAlreadyPromotedProduct(promotion.references) && (
        <InlineNotification
          displayStyle={'error'}
          message={t(
            'backoffice.promotion.already_promoted.error_message.overlapping_date',
            'Overlapping date of existing promotion',
          )}
          description={t(
            'backoffice.promotion.references.already_promoted.error_message.description',
            'Several references already part of an existing promotion. Please adjust publication dates.',
            { count: 1 },
          )}
          action={
            <div onClick={handleAction}>
              <Text type={'text'} displayStyle={'link'} decoration={'underline'} cursor={'pointer'}>
                {t('backoffice.promotion.already_promoted.change_dates', 'Change dates')}
              </Text>
            </div>
          }
        />
      )}
    </>
  );
};
