import styled from 'styled-components';

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0;

  :hover {
    opacity: 0.4;
  }
`;

export const SVideoItem = styled.div`
  height: 150px;
  width: 210px;
  position: relative;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.grey_very_light_2};
  background-size: cover;
  background-position: center;
  background-image: url('/images/placeholder.png');
  cursor: pointer;
`;

export const DetailDot = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 1px 0 1px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.grey55};
`;

export const DetailButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: white;
  z-index: 1;

  :hover {
    background-color: ${({ theme }) => theme.color.grey85};
  }
`;
