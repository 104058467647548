import {
  PROMOTION_SAVE_BANNER_URL,
  PROMOTION_SAVE_DISCOUNT_URL,
  PROMOTION_SAVE_FLASH_QUANTITY_LIMITED_URL,
  PROMOTION_SAVE_FLASH_TIME_LIMITED_URL,
} from '@1po/1po-bff-fe-spec/generated/backoffice/BackofficeURLs';
import {
  GET_IAM_TECHNICAL_DATA_DETAILS_URL,
  GET_TIRE_BRAND_CATEGORIES,
} from '@1po/1po-bff-fe-spec/generated/common/WebsocketConstants';
import { ADD_ORDER_LIST_REFERENCES_URL } from '@1po/1po-bff-fe-spec/generated/order_list/OrderListURLs';
import { Dispatch } from 'redux';
import {
  fetchGetTireBrandCategoriesErrorSaga,
  fetchPromotionPublishErrorSaga,
  fetchTechnicalDataDetailsErrorSaga,
  fetchUploadOrderListReferencesResponseNotReceivedErrorSaga,
} from 'domains/errors/Errors.store';
import { WebSocketAction } from 'utils';

export function handleTimedOutRequest(request: WebSocketAction | undefined, dispatch: Dispatch): void {
  if (!request) return;
  const { url, payload } = request;

  switch (url) {
    case GET_IAM_TECHNICAL_DATA_DETAILS_URL:
      if (
        Object.hasOwn(payload, 'vehicleKey') &&
        Object.hasOwn(payload, 'versionCode') &&
        Object.hasOwn(payload, 'categoryIds')
      ) {
        const { vehicleKey, versionCode, categoryIds } = payload;
        dispatch(fetchTechnicalDataDetailsErrorSaga({ vehicleCode: versionCode, vehicleKey, categoryIds }));
      }
      return;
    case PROMOTION_SAVE_DISCOUNT_URL:
    case PROMOTION_SAVE_FLASH_TIME_LIMITED_URL:
    case PROMOTION_SAVE_FLASH_QUANTITY_LIMITED_URL:
    case PROMOTION_SAVE_BANNER_URL:
      if (Object.hasOwn(payload, 'promotionId')) {
        const { promotionId } = payload;
        dispatch(fetchPromotionPublishErrorSaga({ id: promotionId }));
      }
      return;
    case ADD_ORDER_LIST_REFERENCES_URL:
      dispatch(fetchUploadOrderListReferencesResponseNotReceivedErrorSaga());
      return;
    case GET_TIRE_BRAND_CATEGORIES:
      dispatch(fetchGetTireBrandCategoriesErrorSaga());
      return;
    default:
      return;
  }
}
