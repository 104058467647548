import styled from 'styled-components';
import { Flex } from 'UI';

export const SSearchHistoryItem = styled(Flex)`
  padding: 15px;
  margin-bottom: 30px;
  border: 1px solid ${({ theme }) => theme.color.grey85};
  white-space: nowrap;
  text-align: left;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
`;
