/* eslint-disable max-len */
import React from 'react';
import { DATAHUB, PlateImage } from 'domains/catalog/Catalog.types';
import EmptyOrErrorPlate from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateThumbnailSection/EmptyOrErrorPlate';
import { StackedCards } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PlateThumbnailSection/PlateThumbnailCard/PlateThumbnailCard';
import { theme } from 'styles';
import { Flex, ImageWithLoader, MarginBox } from 'UI';
import { SBadge, SPlateCard } from '../Subcategory.styled';

const PlatePreviewCard = ({
  relatedPlateIds,
  plate,
  onClick,
}: {
  relatedPlateIds: string[];
  plate: PlateImage;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => {
  return (
    <div onClick={onClick}>
      <MarginBox mx={10 - ((Math.min(4, relatedPlateIds?.length) ?? 1) - 1) * 4} my={10}>
        <div onClick={(e) => onClick(e)}>
          <SBadge
            count={relatedPlateIds.length === 1 ? 0 : relatedPlateIds.length}
            offset={[-(12 + 10), 9 + 10]}
            color={theme.color.blue_dark_2}
            showZero={false}
          >
            <Flex>
              <StackedCards count={relatedPlateIds?.length} />
              <SPlateCard>
                <ImageWithLoader
                  imageUrl={plate.imageKey}
                  alt={`plate-${plate.plateId}`}
                  imageHeight={195}
                  imageWidth={220}
                  catalogSource={DATAHUB}
                  fallbackComponent={<EmptyOrErrorPlate />}
                />
              </SPlateCard>
            </Flex>
          </SBadge>
        </div>
      </MarginBox>
    </div>
  );
};

export default PlatePreviewCard;
