import React from 'react';
import { ROUTER_PERSONAL_DATA } from 'app/AppRouter';
import { Container, Flex, Image, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { Country } from 'utils/i18n/Country';

export const TermsAndConditionsIT = () => {
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex justify={'center'}>
          <Text type={'h5_bold'}>CONDIZIONI GENERALI DI UTILIZZO DEL SERVIZIO RPARSTORE</Text>
        </Flex>
        <MarginBox mt={30} />
        <Section>
          <Paragraph>
            Le presenti Condizioni Generali di Utilizzo (di seguito “<strong>CGU</strong>”) disciplinano l’accesso e
            l’utilizzo del servizio RPARTSTORE, messo a disposizione da Renault Italia S.p.A., con sede legale in Roma,
            Via Tiburtina n. 1159, numero di iscrizione al Registro delle Imprese di Roma e Codice Fiscale 00291240638,
            P. IVA 05811161008 (di seguito “<strong>RENAULT</strong>”).
          </Paragraph>
          <Paragraph>
            L’UTENTE DEVE LEGGERE ATTENTAMENTE LE PRESENTI CGU PRIMA DI ACCETTARLE E ACCEDERE AI SERVIZI.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 1'} title={'Denominazione della Piattaforma'} />
          <Paragraph>
            Il sito web RPARTSTORE (di seguito la “<strong>Piattaforma</strong>”) è stato creato per facilitare il
            contatto tra i venditori (di seguito congiuntamente i “<strong>Venditori</strong>” e singolarmente il “
            <strong>Venditore</strong>”) che desiderano vendere parti di ricambio originali e after-market RENAULT (di
            seguito i “<strong>Prodotti</strong>”) tramite un sito web agli acquirenti (di seguito congiuntamente gli “
            <strong>Acquirenti</strong>” e singolarmente l’”<strong>Acquirente</strong>”), in conformità con le presenti
            CGU.
          </Paragraph>
          <Paragraph>
            I servizi della Piattaforma sono accessibili al seguente link:{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 2'} title={"Qualità dell'hosting"} />
          <Paragraph>
            La Piattaforma agisce come intermediario tecnico. Offre a Venditori e Acquirenti (di seguito congiuntamente
            “<strong>Utenti</strong>” e singolarmente “<strong>Utente</strong>”) servizi tecnici di archiviazione e
            trasporto di dati ai sensi della normativa applicabile in materia di protezione dei dati personali.
          </Paragraph>
          <Paragraph>
            Il ruolo della Piattaforma è puramente tecnico e si limita a facilitare il contatto tra Venditori e
            Acquirenti.
          </Paragraph>
          <Paragraph>
            La Piattaforma non si assume alcuna garanzia o responsabilità in merito ad accordi, scambi, offerte o
            comunicazioni che possono avvenire attraverso di essa.
          </Paragraph>
          <Paragraph>
            La Piattaforma non è in alcun modo un venditore o rivenditore dei Prodotti sulla stessa offerti dai
            Venditori.
          </Paragraph>
          <Paragraph>
            Le transazioni relative ai Prodotti offerti dai Venditori sono sempre concluse direttamente tra Venditori e
            Acquirenti, al di fuori della Piattaforma. Attraverso quest’ultima il Venditore può semplicemente generare
            contatti commerciali (leadform). Il prezzo finale dei Prodotti è indicato dal Venditore al di fuori della
            Piattaforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 3'} title={'Scopo dei termini e delle condizioni generali di utilizzo'} />
          <Paragraph>
            Le presenti CGU hanno lo scopo di definire i termini e le condizioni di accesso e di utilizzo della
            Piattaforma da parte degli Utenti.
          </Paragraph>
          <Paragraph>
            Lo scopo della Piattaforma è quello di creare una relazione digitale diretta tra Venditori e Acquirenti dei
            Prodotti al fine di generare contatti commerciali tra di loro.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 4'} title={'Condizioni di accesso alla Piattaforma per gli Acquirenti'} />
          <Paragraph>
            L’accesso alla Piattaforma implica:
            <Ul>
              <Li>
                <Text type={'text'}>
                  la preventiva richiesta alla RENAULT da parte del Venditore di autorizzazione dei propri Acquirenti
                  all’accesso. Per ciascun Acquirente il Venditore deve mandare una singola richiesta e lo deve fare
                  tramite il mini-sito di adesione di seguito descritto;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  la validazione della suddetta richiesta da parte di RENAULT e la successiva convalida dell’accesso
                  alla Piattaforma da parte del Venditore in favore del proprio Acquirente.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Il mini-sito di adesione è un’applicazione di e-business che consente ai Venditori di richiedere a RENAULT
            l’autorizzazione all’accesso alla Piattaforma da parte dei propri Acquirenti. L’applicazione è accessibile
            dal sito RENAULTNET{' '}
            <Link to={'https://contactclient.renaultparts.info/eairp/login.aspx'} external newTab>
              https://contactclient.renaultparts.info/eairp/login.aspx
            </Link>{' '}
            e dalla Piattaforma.
          </Paragraph>
          <Paragraph>
            I dati personali dell’Acquirente necessari per la richiesta di adesione al mini-sito sono trattati da
            Renault SAS, con sede legale in Francia, 122-122 bis avenue du Général Leclerc – 92 100
            Boulogne-Billancourt, insieme a Renault Italia S.p.A. con sede legale in Via Tiburtina n. 1159 – 00156 Roma,
            che agiscono in qualità di contitolari del trattamento, secondo quanto meglio dettagliato nell’Informativa
            sulla protezione dei dati personali della Piattaforma.
          </Paragraph>
          <Paragraph>
            Per consentire al nuovo Acquirente di accedere alla Piattaforma il Venditore deve richiedere, inoltre, a
            RENAULT la creazione del codice TRESOR (portale dedicato ai Membri della Rete RENAULT). Una volta validata
            la richiesta da parte di RENAULT, il service desk della Piattaforma fornirà il predetto codice, unitamente
            alle credenziali di accesso al nuovo Acquirente a mezzo e-mail all’indirizzo di quest’ultimo fornito dal
            Venditore in sede di richiesta.
          </Paragraph>
          <Paragraph>
            Il mini-sito di adesione accelera il processo di affiliazione dei nuovi Acquirenti indipendentemente dalla
            natura dell’Utente in TRESOR (Membri della Rete Secondaria o In fase Terziaria RENAULT) e sulla Piattaforma.
          </Paragraph>
          <Paragraph>
            Il Venditore può effettuare richieste di adesione per i propri Acquirenti in conformità alle disposizioni di
            cui al presente Articolo 4. Se l’Acquirente non è identificato nel database di TRESOR, una richiesta di
            creazione/modifica verrà automaticamente inviata all’amministratore TRESOR competente.
          </Paragraph>
          <Paragraph>
            Il Venditore può modificare i dati del proprio Acquirente e monitorare lo stato di avanzamento delle sue
            richieste di adesione. Non può modificare una domanda aperta, ma può chiederne la chiusura. Il call center
            si occuperà della chiusura definitiva.
          </Paragraph>
          <Paragraph>
            Per maggiori dettagli sulle funzionalità della procedura di accesso consultare l’<strong>Allegato I</strong>{' '}
            alle presenti CGU.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 5'} title={'Prodotti ammessi alla Piattaforma'} />
          <Paragraph>
            La Piattaforma seleziona i Prodotti che possono essere ordinati attraverso di essa. Gli Acquirenti non
            possono richiedere o imporre ai Venditori di offrire, attraverso la Piattaforma, prodotti diversi da quelli
            precedentemente selezionati dalla Piattaforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 6'} title={'Modalità di classificazione '} />
          <Paragraph>
            La visualizzazione delle offerte non è soggetta ad alcun criterio di classificazione predeterminato. Solo i
            criteri di classificazione del prezzo in ordine crescente o decrescente possono essere scelti
            dall’Acquirente, ove possibile.
          </Paragraph>
          <Paragraph>
            Per quanto riguarda la visualizzazione dell’offerta, RENAULT può determinare un ordine di classificazione
            predefinito basato su una delle seguenti opzioni:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>età (dal più datato al più recente o viceversa);</Text>
            </Li>
            <Li>
              <Text type={'text'}>prezzo crescente;</Text>
            </Li>
            <Li>
              <Text type={'text'}>rilevanza dell’offerta (causale, marchi o attributi tecnici).</Text>
            </Li>
          </Ul>
          <Paragraph>
            RENAULT si riserva il diritto di modificare in qualsiasi momento l’ordine di classificazione scelto.
            L’Acquirente può modificare questa classificazione predefinita utilizzando una delle opzioni seguenti:
            <Ul>
              <Li>prezzo crescente</Li>
              <Li>prezzo decrescente;</Li>
              <Li>età ascendente/decrescente: dal più recente al più datato/viceversa;</Li>
              <Li>tempistiche di consegna ascendente;</Li>
              <Li>prossimità (visibile quando gli Acquirenti effettuano una ricerca per località);</Li>
              <Li> Rilevanza (causale o marchio).</Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader
            article={'Articolo 7'}
            title={'Creazione di uno spazio per l’Acquirente - Riservatezza dei dati identificativi e delle password'}
          />
          <Text type={'text'}>
            Di seguito le fasi per la creazione di uno spazio per l’Acquirente sulla Piattaforma a cura del Venditore:
          </Text>
          <Ul type={'decimal'}>
            <Li>
              <Text type={'text'}>
                Ricezione di un’e-mail da parte del Venditore che conferma che la richiesta è stata presa in
                considerazione. L’e-mail indicherà anche il tempo di elaborazione della richiesta, che varia tra le 24 e
                le 72 ore;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                L’Acquirente riceverà via e-mail le informazioni necessarie (ID / Password) per collegarsi alla
                Piattaforma;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                Anche il Venditore riceverà un messaggio che lo informa che il suo Acquirente ha ora accesso alla
                Piattaforma;
                <br />
                In caso di incidente tecnico sulla richiesta, il Venditore sarà avvisato via e-mail.
              </Text>
            </Li>
          </Ul>
          <Paragraph>
            L’utilizzo dell’Area Acquirenti è strettamente limitato all’Utente designato dal Venditore.
          </Paragraph>
          <Paragraph>
            L’Acquirente si impegna a mantenere segreti i propri codici e a non divulgarli in nessuna forma e a nessuna
            persona e, in caso di utilizzo fraudolento o non autorizzato, a cancellarli e a segnalare senza indugio i
            fatti al servizio clienti chiamando il call center della Piattaforma al numero 0238591091. L’Acquirente è
            informato che è responsabile di qualsiasi divulgazione dei propri codici a terzi.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 8'} title={'Rapporto personalizzato'} />
          <Paragraph>Gli Acquirenti non possono rivolgersi a tutti i Venditori ammessi alla Piattaforma.</Paragraph>
          <Paragraph>
            Sulla Piattaforma, l’Acquirente troverà il Venditore di riferimento corrispondente al canale fisico
            abituale; il vincolo dell’Acquirente non cambierà.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 9'} title={'Pagamento'} />
          <Paragraph>
            Resta inteso che solo i contatti commerciali possono essere effettuati tramite la Piattaforma e che nessun
            pagamento sarà effettuato tramite la stessa.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 10'} title={'Disponibilità dei servizi della Piattaforma'} />
          <Paragraph>
            Nei limiti delle leggi e dei regolamenti applicabili, RENAULT è scaricata di ogni responsabilità per
            l’utilizzo della Piattaforma da parte dell’Utente e non fornisce alcuna garanzia, espressa o implicita, in
            merito alla velocità e/o alle prestazioni della Piattaforma o all’utilizzo da parte dell’Acquirente dei dati
            e/o delle informazioni accessibili tramite la Piattaforma.
          </Paragraph>
          <Paragraph>
            In particolare, RENAULT non garantisce che la Piattaforma sia priva di errori, difetti o omissioni, o che
            non sia soggetta a danni o attacchi, in particolare virus, che potrebbero causare malfunzionamenti,
            interruzioni del servizio, guasti o perdite di dati o informazioni.
          </Paragraph>
          <Paragraph>
            L’accesso alla Piattaforma può essere temporaneamente e periodicamente sospeso per motivi tecnici.
          </Paragraph>
          <Paragraph>
            I Team tecnici faranno in modo che tali sospensioni del servizio durino il più breve tempo possibile o,
            comunque, il tempo strettamente necessario alla risoluzione del problema o alle operazioni di manutenzione.
          </Paragraph>
          <Paragraph align={'start'}>
            RENAULT sarà esonerata da ogni responsabilità:
            <Ul>
              <Li>
                <Text type={'text'}>
                  in caso di mancato rispetto da parte dell’Utente delle clausole delle presenti CGU;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  in caso di malfunzionamento della Piattaforma derivante da una causa volontaria o involontaria
                  imputabile all’Utente o a terzi, o da un atto doloso, o dal malfunzionamento di software, smartphone,
                  tablet PC o qualsiasi altra interfaccia/prodotto appartenente all’Utente;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  in caso di modifiche legislative o regolamentari che interessino la Piattaforma.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Quando gli Utenti utilizzano la Piattaforma, si impegnano a farlo nel rispetto delle presenti CGU e nel
            rispetto della normativa vigente.
          </Paragraph>
          <Paragraph>
            L’Acquirente e il Venditore sono interamente responsabili dell’uso della Piattaforma e di qualsiasi uso dei
            dati/informazioni ricevuti tramite la stessa, anche quando non sono la persona che utilizza tali
            dati/informazioni.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 11'} title={'Uso non dannoso della Piattaforma'} />
          <Paragraph>
            L’Acquirente deve astenersi in ogni circostanza da qualsiasi attività che possa compromettere l’attività
            della Piattaforma, danneggiare la sua reputazione, nonché la reputazione dei servizi che fornisce e delle
            attività del Gruppo Renault.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 12'} title={'Intuitu personae'} />
          <Paragraph>
            La Piattaforma, creata dal Gruppo Renault, contribuisce all’immagine, alla reputazione e alla notorietà del
            Gruppo Renault. Il rapporto tra la Piattaforma e i suoi Utenti si basa pertanto sull’“intuitu personae”.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 13'} title={'Ritiro, blocco o esclusione dalla Piattaforma'} />
          <Text type={'text'}>a) Ritiro</Text>
          <MarginBox mt={5} />
          <Paragraph>
            Ogni Acquirente può in qualsiasi momento notificare alla Piattaforma, tramite il suo Venditore R1, la sua
            decisione di non utilizzare più i suoi servizi.
          </Paragraph>
          <Paragraph>
            L’Acquirente deve continuare ad adempiere agli obblighi assunti con i Venditori prima della data di
            cessazione della sua partecipazione alla Piattaforma.
          </Paragraph>
          <Text type={'text'}>b) Blocco</Text>
          <MarginBox mt={5} />
          <Paragraph>
            La Piattaforma può adottare tutte le misure appropriate e proporzionate che ritiene necessarie, consistenti
            nella limitazione dell’accesso, nella retrocessione o nella sospensione (di seguito denominate
            congiuntamente le “<strong>Misure di restrizione</strong>” e singolarmente “
            <strong>Misura di restrizione</strong>”) di tutte o parte delle informazioni e degli elementi caricati
            online dal Venditore, previa notifica all’Acquirente e al Venditore sulla base dei seguenti motivi:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>
                  grave presunzione di comportamento illecito che danneggi o violi la Piattaforma, le presenti CGU, la
                  legge in vigore o l’immagine e la reputazione del Gruppo Renault;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>utilizzo dell’Area Acquisti da parte di persone diverse dall’Acquirente;</Text>
              </Li>
              <Li>
                <Text type={'text'}>negligenza o mancato rispetto dell’obbligo di riservatezza;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  conoscenza da parte della Piattaforma dell’esistenza di contenuti illeciti o presunti tali, in
                  particolare a seguito del ricevimento di una notifica o di una intimazione ad agire contro i contenuti
                  illeciti emessa da un’autorità competente.
                </Text>
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Nei casi di urgenza, la notifica può avvenire anche il giorno in cui è stata applicata la Misura di
            restrizione.
          </Paragraph>
          <Paragraph>
            La misura di restrizione è adottata per un periodo proporzionato ai fatti contestati e, comunque, non
            superiore a sei (6) mesi.
          </Paragraph>
          <Paragraph>
            La misura restrittiva adottata dalla Piattaforma può essere contestata utilizzando il sistema di reclami
            interno. Se l’Acquirente non è in grado di porre rimedio ai fatti contestati, la Piattaforma può escluderlo
            definitivamente alle condizioni di seguito indicate.
          </Paragraph>
          <Text type={'text_bold'}>c) Esclusione</Text>
          <MarginBox mt={5} />
          <Paragraph>
            La Piattaforma può escludere definitivamente un Acquirente nel caso in cui:
            <Ul type={'decimal'}>
              <Li>
                <Text type={'text'}>abbandoni la rete Renault ammessa alla Piattaforma per qualsiasi motivo;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  comportamento di cui al punto b – 1) del presente Articolo 13. La Piattaforma potrà quindi scegliere
                  tra il blocco e l’esclusione immediata;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  false dichiarazioni, per quanto gravi, perché le false dichiarazioni costituiscono una violazione
                  della fiducia;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>ripetizione dei comportamenti di cui ai punti da b) 2) a 4);</Text>
              </Li>
              <Li>
                <Text type={'text'}>ripetuti reclami da parte dei Venditori;</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  qualsiasi cambiamento nella situazione personale dell’Acquirente che possa influire sull”intuitu
                  personae” (in particolare: cambiamento diretto o indiretto del controllo, cambiamento significativo
                  nella produzione o nelle certificazioni, procedure di insolvenza in conformità alle leggi vigenti).
                </Text>
              </Li>
            </Ul>
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 14'} title={'Transazioni commerciali – Segreto commerciale'} />
          <Paragraph>
            La Piattaforma non può in alcun modo consentire a terzi (concorrenti, associati, partner, ecc.) di venire a
            conoscenza degli scambi, delle trattative, dei contratti e degli accordi effettuati o stipulati tramite la
            Piattaforma. La Piattaforma rispetterà in ogni momento il suo programma di riservatezza.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 15'} title={'Promozioni e pubblicità '} />
          <Paragraph>
            L’Acquirente si impegna a non utilizzare la Piattaforma per promuovere direttamente o indirettamente, in
            qualsiasi forma, i propri prodotti o servizi o quelli di qualsiasi altra persona.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 16'} title={'Offerte concorrenti – Nessuna esclusività'} />
          <Paragraph>
            L’adesione alla Piattaforma non comporta alcun obbligo di utilizzo da parte degli Acquirenti.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 17'} title={'Modifica delle CGU'} />
          <Paragraph>
            La Piattaforma si riserva il diritto di modificare le presenti CGU. Qualsiasi modifica entrerà in vigore
            alla data indicata nella notifica inviata agli Acquirenti nella propria area riservata.
          </Paragraph>
          <Paragraph>
            Le modifiche non si applicheranno alle transazioni in corso alla data della loro entrata in vigore.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 18'} title={'Utilizzo dei dati personali raccolti dalla Piattaforma'} />
          <Paragraph>
            Per quanto riguarda l’utilizzo della Piattaforma, i dati personali degli Utenti sono trattati da RENAULT in
            conformità con l’Informativa sulla protezione dei dati personali della Piattaforma, accessibile al seguente
            link:{' '}
            <Link to={ROUTER_PERSONAL_DATA(Country.IT.key)} newTab>
              https://rpartstore.renault.com/personal-data/it
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 19'} title={'Forza maggiore'} />
          <Paragraph>
            La Piattaforma non potrà essere ritenuta responsabile per qualsiasi evento di forza maggiore che comporti un
            suo malfunzionamento.
          </Paragraph>
          <Paragraph>
            Devono considerarsi eventi di forza maggiore le interruzioni di corrente, i guasti alla rete Internet, i
            malfunzionamenti informatici (“bug”, virus, malware, intrusioni), anche se la loro origine tecnica risiede
            nella progettazione del servizio di informazione.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 20'} title={'Riservatezza e prove'} />
          <Paragraph>
            La Piattaforma adotta adeguate misure tecniche a garantire la riservatezza di tutte le informazioni e dei
            dati trasmessi attraverso di essa, nonché delle vendite effettuate per il suo tramite, al fine di limitare
            qualsiasi divulgazione a terzi estranei.
          </Paragraph>
          <Paragraph>
            Tali dati saranno comunque considerati come prove valide e ammissibili tra Venditori e Acquirenti, nonché
            tra questi ultimi e la Piattaforma.
          </Paragraph>
          <Paragraph>Tali dati saranno conservati in conformità alle disposizioni di legge.</Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 21'} title={'Proprietà intellettuale'} />
          <Paragraph>
            Tutti gli elementi che compongono la Piattaforma (come testi, loghi, immagini, elementi grafici o sonori,
            software, layout, database, codice sorgente, ecc.) nonché le denominazioni sociali, i marchi, i disegni e i
            segni distintivi (di seguito il “<strong>Contenuto</strong>”) sono protetti da diritti di proprietà
            intellettuale. Il Contenuto è di proprietà esclusiva di RENAULT e/o di terzi che ne hanno autorizzato l’uso.
          </Paragraph>
          <Paragraph>
            Gli Utenti beneficiano di una licenza semplice, limitata, non esclusiva, non trasferibile, non sub
            licenziata e revocabile per l’utilizzo del Contenuto nello stretto contesto dell’uso della Piattaforma come
            descritto nel presente documento. Qualsiasi altro utilizzo, totale o parziale, del Contenuto, in particolare
            la riproduzione, la rappresentazione, l’adattamento, la modifica, la decompilazione con qualsiasi
            procedimento e su qualsiasi supporto, compresa la fusione con altri programmi informatici, senza la previa
            autorizzazione scritta di RENAULT è severamente vietato e costituisce una violazione.
          </Paragraph>
          <Paragraph>
            L’Utente si impegna a informare RENAULT nel più breve tempo possibile qualora sia a conoscenza di qualsiasi
            atto compiuto in violazione delle disposizioni del presente articolo.
          </Paragraph>
          <Paragraph>
            Nell’ambito dell’utilizzo della Piattaforma, l’Utente è autorizzato a utilizzare i marchi e i diritti di
            proprietà intellettuale di cui è titolare o di cui ha acquisito legalmente i diritti, nonché il suo nome
            commerciale o la sua denominazione legale. L’Utente garantisce di disporre dei diritti che consentono a
            RENAULT di riprodurre sulla Piattaforma gli elementi forniti dall’Utente. A tal proposito, l’Utente
            garantisce RENAULT contro qualsiasi reclamo, richiesta, azione o rimedio derivante da terzi in relazione
            all’utilizzo di tali elementi e si farà carico di tutti i costi e i danni che potrebbero derivarne, in
            particolare, a seguito di una decisione giudiziaria, anche non definitiva, o di un accordo transattivo.
          </Paragraph>
          <Paragraph>
            L’Utente è autorizzato a menzionare sui propri documenti commerciali il suo status di Utente referenziato
            dalla Piattaforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 22'} title={'Responsabilità'} />
          <Paragraph>
            Il Venditore riconosce che RENAULT, in qualità di host, non è parte di alcun accordo e/o contratto concluso
            tra il Venditore medesimo e l’Acquirente, a seguito della pubblicazione dei Prodotti sulla Piattaforma.
          </Paragraph>
          <Paragraph>
            Il Venditore è l’unico responsabile della presentazione o della descrizione dei Veicoli che offre,
            dell’esposizione dei loro prezzi, di qualsiasi comunicazione e pubblicità che possa fare; di conseguenza, la
            Piattaforma non può essere ritenuta responsabile di eventuali errori od omissioni commessi dal Venditore
            nella descrizione dei Prodotti e/o nell’indicazione dei prezzi che sono pubblicati sulla Piattaforma.
          </Paragraph>
          <Paragraph>
            Il Venditore è l’unico responsabile della corretta applicazione delle disposizioni del Codice del Commercio
            e in particolare della negoziazione, della conclusione e dell’esecuzione dei contratti relativi ai Prodotti
            offerti online sulla Piattaforma e della gestione di eventuali reclami, ricorsi, controversie che
            l’Acquirente può avviare nei suoi confronti, in particolare in caso di mancato pagamento.
          </Paragraph>
          <Paragraph>
            Più in generale, il Venditore è l’unico responsabile nei confronti degli Acquirenti e dei terzi in qualità
            di Venditore e garantisce RENAULT contro ogni possibile rivalsa al riguardo e si farà carico di tutti i
            costi e i danni che ne possono derivare, in particolare a seguito di una decisione giudiziaria, anche non
            definitiva, o di un accordo transattivo.
          </Paragraph>
          <Paragraph>
            Nel caso in cui un Acquirente, o qualsiasi altra persona, agisca in giudizio contro la Piattaforma per fatti
            relativi all’attività del Venditore, quest’ultimo si impegna a tenere indenne la Piattaforma.
          </Paragraph>
          <Paragraph>Il Venditore è inoltre l’unico responsabile dell’utilizzo della propria Area Venditore.</Paragraph>
          <Paragraph>
            L’Acquirente è l’unico responsabile dell’ordine effettuato per i Prodotti messi in vendita sulla
            Piattaforma.
          </Paragraph>
          <Paragraph>
            È inoltre l’unico responsabile dell’utilizzo della propria Area Acquisti e delle informazioni fornite ai
            Venditori.
          </Paragraph>
          <Paragraph>
            Più in generale, è l’unico responsabile in qualità di Acquirente e né la Piattaforma né RENAULT possono
            essere ritenute in alcun modo responsabili.
          </Paragraph>
          <Paragraph>
            Nel caso in cui un Venditore, o qualsiasi altra persona, agisca in giudizio contro la Piattaforma per fatti
            relativi alla sua attività di Acquirente, il Venditore si impegna a tenere indenne la Piattaforma.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 23'} title={'Reclami – Notifica'} />
          <Paragraph>a) Sistema interno di reclami</Paragraph>
          <Paragraph align={'start'}>
            La Piattaforma ha istituito un sistema di reclami interni sui seguenti argomenti:
            <Ul>
              <Li>
                presunte inadempienze della Piattaforma relative alla fornitura di servizi di intermediazione online e
                che interessano l’Utente reclamante;
              </Li>
              <Li>
                questioni tecnologiche direttamente collegate alla fornitura di servizi di intermediazione e che
                interessano l’Utente reclamante;
              </Li>
              <Li>
                Misure di restrizione adottate dalla Piattaforma o dal suo comportamento direttamente collegate alla
                fornitura di servizi di intermediazione online e che riguardano l’Utente reclamante.
              </Li>
            </Ul>
          </Paragraph>
          <Paragraph>
            Per quanto riguarda le Misure di restrizione adottate dalla Piattaforma a seguito di notifica o sulla base
            del fatto che le informazioni e gli elementi forniti dall’Utente sono considerati illeciti o incompatibili
            con le presenti CGU, l’Utente può presentare un reclamo entro sei (6) mesi dalla notifica di tale misura.
          </Paragraph>
          <Paragraph>
            La Piattaforma si impegna a trattare i reclami in tempi compatibili con la complessità e l’importanza della
            questione sollevata e a inviare una risposta all’Utente che ha presentato il reclamo.
          </Paragraph>
          <Paragraph>
            Le decisioni prese nell’ambito del sistema interno di reclami messo a disposizione dalla Piattaforma sono
            soggette al rimedio extragiudiziale della mediazione, ai sensi del successivo Articolo 24, ovvero, nei casi
            previsti dalla legge (a titolo esemplificativo, violazione della normativa in materia di protezione dei dati
            personali, presenza sulla Piattaforma di contenuti che configurano un illecito civile, penale o
            amministrativo) possono essere impugnate dinanzi all’autorità giudiziaria competente.
          </Paragraph>
          <Paragraph>b) Notifica</Paragraph>
          <Paragraph>
            L’Utente può segnalare, a mezzo e-mail all’indirizzo di seguito indicato, la presenza sulla Piattaforma di
            contenuti illeciti. Tali segnalazioni saranno oggetto di accertamento a cura della Piattaforma, che si
            riserva il diritto adottare le opportune Misure di restrizione. La decisione presa dalla Piattaforma sarà
            comunicata all’Utente reclamante, qualora si sia identificato, e potrà essere impugnata tramite il sistema
            di reclami interno. Se, per motivi tecnici o operativi, la Piattaforma non è in grado di intervenire
            direttamente, ne informerà l’Utente reclamante.
          </Paragraph>
          <Paragraph>
            L’indirizzo e-mail messo a disposizione degli Utenti è il seguente:{' '}
            <Link to={'mailto:dpo@renault.com'} external newTab>
              dpo@renault.com
            </Link>
            .
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 24'} title={'Mediazione'} />
          <Paragraph>a) Controversie tra Utenti - Fornitura di un servizio di mediazione</Paragraph>
          <Paragraph>
            La Piattaforma nomina sul proprio sito web un mediatore indipendente, al quale gli Utenti possono decidere
            di comune accordo di sottoporre la propria controversia. Tale rinvio comporta la sospensione degli obblighi
            derivanti dalle presenti CGU, dalle Condizioni generali di vendita dei Prodotti o da qualsiasi altro
            contratto sottoscritto tra gli Utenti. Il mediatore è tenuto a prendere una decisione entro un mese dal
            deferimento della controversia, con la possibilità di proroga di un ulteriore mese, a sua discrezione, o di
            un periodo più lungo laddove via sia il consenso delle parti.
          </Paragraph>
          <Paragraph>b) Controversie relative ai servizi forniti dalla Piattaforma</Paragraph>
          <Paragraph>
            Il mediatore di cui al punto a) può essere contattato anche da qualsiasi Utente, alle condizioni di cui
            sopra, nel caso in cui l’oggetto della controversia riguardi un servizio fornito dalla Piattaforma. La
            Piattaforma accetta preventivamente di sottoporsi alla mediazione.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader article={'Articolo 25'} title={'Legge applicabile - Controversie'} />
          <Paragraph>Le presenti CGU sono disciplinate dalla legge italiana.</Paragraph>
          <Paragraph>
            In caso di controversia in relazione alle presenti CGU, sarà competente il Foro di Roma.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Versione aggiornata: gennaio 2024</Text>
        </Flex>
        <MarginBox mt={60} />
        <Text type={'h5_bold'} decoration={'underline'}>
          ALLEGATO I
        </Text>
        <MarginBox mt={15} />
        <Text type={'h5_bold'} decoration={'underline'}>
          INFORMAZIONI SUGLI ACQUIRENTI – PROCEDURA DI ACCESSO
        </Text>
        <MarginBox mt={30} />
        <Image src={'terms-and-conditions/terms-and-conditions-IT.png'} alt={'information-diagram-italy'} />
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
