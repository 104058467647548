import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { CardFlex, PaddingFlex } from 'components/MotrioWarranty/MotrioWarranty.styled';
import { TypeOfGuaranteeStep } from 'components/MotrioWarranty/step/TypeOfGuaranteeStep/TypeOfGuaranteeStep';
import { getStepperData, WarrantyStepper } from 'components/MotrioWarranty/step/WarrantyStepper';
import { theme } from 'styles';
import { Flex, MarginBox, Pipeline, Steps } from 'UI';

interface MotrioWarrantyProps {
  reference: OrderReferenceItem;
  vehicleDetail?: VehicleDetail;
}

export function MotrioWarranty(props: MotrioWarrantyProps) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const stepData = getStepperData(t);

  return (
    <PaddingFlex>
      <CardFlex background={theme.color.white} direction={'column'}>
        <MarginBox mt={15} />
        <Steps currentStep={currentStep} setCurrentStep={setCurrentStep} data={stepData} clickable />
        <MarginBox mt={15} />
        <Pipeline horizontal size={'100%'} />
        <MarginBox mt={32} />
        {currentStep === 0 && <TypeOfGuaranteeStep reference={props.reference} vehicleDetail={props.vehicleDetail} />}
        {currentStep === 1 && <></>}
        {currentStep === 2 && <></>}
      </CardFlex>
      <MarginBox ml={15} />
      <Flex size={0} direction={'column'}>
        <WarrantyStepper currentStep={currentStep} />
        <Flex />
      </Flex>
    </PaddingFlex>
  );
}
