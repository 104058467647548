import React from 'react';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { SelectedTireProps } from 'domains/tires/Tire.types';
import TireRow from 'pages/CatalogPage/DH/Tires/TireRow';

interface ApprovedTiresProps extends SelectedTireProps {
  approvedTires: VehicleTire[];
}

const ApprovedTires = ({ approvedTires, selectedTire, setSelectedTire }: ApprovedTiresProps) => {
  return (
    <>
      {approvedTires.map((tire, index) => (
        <TireRow
          tire={tire}
          key={`approved-tire-${index}`}
          selectedTire={selectedTire}
          setSelectedTire={setSelectedTire}
        />
      ))}
    </>
  );
};
export default ApprovedTires;
