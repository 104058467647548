import React from 'react';
import { useSelector } from 'react-redux';
import { StatusType } from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/GetInformations';
import { RootState } from 'app/AppStore';
import { useFetchInformations } from 'domains/information/Information.requests';
import { getLoadedInformations } from 'domains/information/Information.store';
import InformationTable from 'pages/BackOfficePage/BackOfficeCategories/Information/Information/InformationTable';
import { Box } from 'UI';

const InformationTab = ({ status }: { status: StatusType }) => {
  const publishedInformation = useSelector((state: RootState) => getLoadedInformations(state, status));
  useFetchInformations({ status });

  return (
    <>
      <Box height={45} />
      <InformationTable informations={publishedInformation} tab={status} />
    </>
  );
};

export default InformationTab;
