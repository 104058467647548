import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Image, MarginBox, Text } from 'UI';

const ContactUsHeader = ({
  bannerImg,
  alt,
  description,
}: {
  bannerImg?: string;
  alt?: string;
  description?: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Box width={600}>
        {!description && (
          <Text type={'text_dim'}>
            {t(
              'assistance.contact_us.subtitle',
              'If you have any questions about the availability or compatibility of car parts, ' +
                'contact our team and we will get back to you promptly. Please provide as much information as possible. ' +
                'All (*) marked fields are mandatory.',
            )}
          </Text>
        )}
        <Text type={'text_dim'}>{description}</Text>
      </Box>
      <MarginBox mb={45} />
      {bannerImg && alt && <Image alt={alt} src={bannerImg} />}
      <MarginBox mb={30} />
    </>
  );
};

export default ContactUsHeader;
