import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { CircleXMark, SearchIcon } from 'assets/icons';
import Loader from 'components/Loader';
import { MagnifierButton, SInput } from 'pages/UniversalProductsPage/ProductsPage/ProductsSearch.styled';
import { Flex, Icon, MarginBox } from 'UI';

const ProductsSearch = ({
  isSearchFilterActive,
  isAnyReferenceLoading,
  handleSearch,
  cancelSearch,
}: {
  isSearchFilterActive: boolean;
  isAnyReferenceLoading: boolean;
  handleSearch: (searchQuery: string) => void;
  cancelSearch: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');

  function onSearchCanceled() {
    setSearchQuery('');
    cancelSearch();
  }

  useEffect(() => {
    if (searchQuery === '' && isSearchFilterActive) {
      cancelSearch();
    }
  }, [searchQuery, cancelSearch, isSearchFilterActive]);

  return (
    <SInput
      placeholder={t('catalog.universal_products.search.placeholder', 'Search for items and brands')}
      value={searchQuery}
      bordered={true}
      onChange={setSearchQuery}
      onPressEnter={() => handleSearch(searchQuery)}
      color={theme.color.grey55}
      suffix={
        isAnyReferenceLoading ? (
          <Flex>
            <Loader />
          </Flex>
        ) : (
          <Flex>
            {isSearchFilterActive && (
              <MarginBox mt={12} mr={10}>
                <Icon IconComponent={CircleXMark} size={14} onClick={onSearchCanceled} />
              </MarginBox>
            )}
            <MagnifierButton>
              <Icon
                ml={-8}
                onClick={() => handleSearch(searchQuery)}
                IconComponent={SearchIcon}
                size={25}
                color={theme.color.white}
              />
            </MagnifierButton>
          </Flex>
        )
      }
    />
  );
};

export default ProductsSearch;
