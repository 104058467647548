import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import * as actions from 'domains/orderValidation/OrderValidation.store';
import {
  resetOrderValidationDeliveryAddress,
  resetOrderValidationInstructions,
  resetOrderValidationPaymentMethod,
  setDefaultDeliveryAddress,
  setDefaultPaymentMethod,
  setOrderValidationDeliveryAddress,
  setOrderValidationInstructions,
  setOrderValidationPaymentMethod,
} from 'domains/orderValidation/OrderValidation.store';
import { sagaGuard } from 'utils';

export function* setValidationInstructionsSaga({
  payload,
}: ReturnType<typeof actions.setValidationInstructionsSaga>): SagaIterator {
  yield put(setOrderValidationInstructions(payload));
}

export function* setValidationDeliveryAddressSaga({
  payload,
}: ReturnType<typeof actions.setValidationDeliveryAddressSaga>): SagaIterator {
  yield put(setOrderValidationDeliveryAddress(payload));
}

export function* setValidationPaymentMethodSaga({
  payload,
}: ReturnType<typeof actions.setValidationPaymentMethodSaga>): SagaIterator {
  yield put(setOrderValidationPaymentMethod(payload));
}

export function* resetValidationDeliveryAddressSaga({
  payload,
}: ReturnType<typeof actions.resetValidationDeliveryAddressSaga>): SagaIterator {
  yield put(resetOrderValidationDeliveryAddress(payload));
}

export function* resetValidationInstructionsSaga({
  payload,
}: ReturnType<typeof actions.resetValidationInstructionsSaga>): SagaIterator {
  yield put(resetOrderValidationInstructions(payload));
}

export function* resetValidationPaymentMethodSaga({
  payload,
}: ReturnType<typeof actions.resetValidationPaymentMethodSaga>): SagaIterator {
  yield put(resetOrderValidationPaymentMethod(payload));
}

export function* setDefaultValidationDeliveryAddressSaga({
  payload,
}: ReturnType<typeof actions.setDefaultValidationDeliveryAddressSaga>): SagaIterator {
  yield put(setDefaultDeliveryAddress({ deliveryAddress: payload }));
}

export function* setDefaultValidationPaymentMethodSaga({
  payload,
}: ReturnType<typeof actions.setDefaultValidationPaymentMethodSaga>): SagaIterator {
  yield put(setDefaultPaymentMethod({ paymentMethod: payload }));
}

export function* OrderValidationSagas(): SagaIterator {
  yield takeEvery(actions.setValidationInstructionsSaga.type, sagaGuard(setValidationInstructionsSaga));
  yield takeEvery(actions.setValidationDeliveryAddressSaga.type, sagaGuard(setValidationDeliveryAddressSaga));
  yield takeEvery(actions.setValidationPaymentMethodSaga.type, sagaGuard(setValidationPaymentMethodSaga));
  yield takeEvery(actions.resetValidationDeliveryAddressSaga.type, sagaGuard(resetValidationDeliveryAddressSaga));
  yield takeEvery(actions.resetValidationInstructionsSaga.type, sagaGuard(resetValidationInstructionsSaga));
  yield takeEvery(actions.resetValidationPaymentMethodSaga.type, sagaGuard(resetValidationPaymentMethodSaga));
  yield takeEvery(
    actions.setDefaultValidationDeliveryAddressSaga.type,
    sagaGuard(setDefaultValidationDeliveryAddressSaga),
  );
  yield takeEvery(actions.setDefaultValidationPaymentMethodSaga.type, sagaGuard(setDefaultValidationPaymentMethodSaga));
}
