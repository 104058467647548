import React from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line max-len
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { ROUTER_UNIVERSAL_PRODUCTS } from 'app/AppRouter';
import { DataContainer } from 'components/DataContainer';
import TreeMenu from 'components/TreeMenu';
import { TreeType } from 'components/TreeMenu/TreeMenu';
import { useUniversalProductsTree } from 'domains/catalog/Catalog.requests';
import { getData } from 'utils';

const SideBarUniversalProductsCatalog = () => {
  const universalProductsTree = useUniversalProductsTree();
  const history = useHistory();

  const handleLvl3Items = (lvl3Items?: UniversalProductsCategory[]) => {
    return (
      lvl3Items?.map((lvl3: UniversalProductsCategory) => {
        return {
          nodeId: lvl3.nodeId,
          label: lvl3.name,
          childCategories: [],
        };
      }) ?? []
    );
  };

  const handleLvl2Items = (lvl2Items?: UniversalProductsCategory[]) => {
    return (
      lvl2Items?.map((lvl2: UniversalProductsCategory) => {
        return {
          nodeId: lvl2.nodeId,
          label: lvl2.name,
          childCategories: handleLvl3Items(lvl2.childCategories),
        };
      }) ?? []
    );
  };

  const handleLvl1Items = (lvl1Items?: UniversalProductsCategory[]) => {
    return (
      lvl1Items?.map((lvl1: UniversalProductsCategory) => {
        return {
          nodeId: lvl1.nodeId,
          label: lvl1.name,
          childCategories: handleLvl2Items(lvl1.childCategories),
        };
      }) ?? []
    );
  };

  const getTree = (): TreeType[] => handleLvl1Items(getData(universalProductsTree));

  if (!history.location.pathname.includes(ROUTER_UNIVERSAL_PRODUCTS)) {
    return null;
  }

  return (
    <DataContainer data={universalProductsTree}>
      <TreeMenu tree={getTree()} baseUrl={ROUTER_UNIVERSAL_PRODUCTS} disableLevel1Click disableLevel2Click />
    </DataContainer>
  );
};

export default SideBarUniversalProductsCatalog;
