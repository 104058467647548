import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import {
  getEstimateObservations,
  getEstimateSequenceNumber,
  getEstimateSettings,
  updateObservations,
} from 'domains/estimate/Estimate.store';
import { LightCard, TextArea } from 'UI';
import { getData } from 'utils';

export interface ObservationSectionProps {
  estimateId: string;
}

const ObservationSection = ({ estimateId }: ObservationSectionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const estimateObservations = useSelector((state: RootState) => getEstimateObservations(state, estimateId));
  const sequenceNumber = useSelector((state: RootState) => getEstimateSequenceNumber(state, estimateId));
  const settings = useSelector(getEstimateSettings);
  const settingsObservations = getData(settings)?.observations;

  const observationsToDisplay = sequenceNumber ? estimateObservations : settingsObservations;

  return (
    <LightCard title={t('estimate.observation.title', 'Observation')}>
      <TextArea
        onChangeDebounced={(val) => dispatch(updateObservations(val))}
        placeholder={t('estimate.observation.description', 'Type your observation here...')}
        size={'large'}
        initialValue={observationsToDisplay ?? ''}
        maxLength={180}
        style={{ resize: 'none' }}
      />
    </LightCard>
  );
};

export default ObservationSection;
