import {
  GET_FILE_ERROR_RESPONSE,
  GET_FILE_IN_PROGRESS_RESPONSE,
  GET_FILE_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { FileResponse } from '@1po/1po-bff-fe-spec/generated/files/FileResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { FILES_NAMESPACE, FilesState, GET_FILE_REQUEST } from 'domains/downloadFiles/Files.types';
import { ERROR, LOADING, SearchData } from 'utils';

export const fileDownloadResponseSaga = createAction(GET_FILE_RESPONSE);
export const fileDownloadErrorResponseSaga = createAction(GET_FILE_ERROR_RESPONSE);
export const fileDownloadInProgressResponseSaga = createAction(GET_FILE_IN_PROGRESS_RESPONSE);
export const fileDownloadRequestSaga = createAction<{ fileKey: string; timeout?: number }>(GET_FILE_REQUEST);

// Init state
const initialState: FilesState = {
  files: new Map<string, SearchData<FileResponse>>(),
};

// Slice
const slice = createSlice({
  name: FILES_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setFile: (state, { payload }: PayloadAction<SearchData<FileResponse>>) => {
      const data = payload?.data as FileResponse;
      state.files.set(data.key, payload);
    },
    setDownloadingFile: (state, { payload }: PayloadAction<string>) => {
      state.files.set(payload, { searchStatus: LOADING });
    },
    setTimeoutDownloadingFile: (state, { payload }: PayloadAction<string>) => {
      state.files.set(payload, { searchStatus: ERROR });
    },
  },
});

// Actions
export const { setInitialState, setFile, setDownloadingFile, setTimeoutDownloadingFile } = slice.actions;

// Getters/Selectors
export const getFile = createSelector(
  (state: RootState, key: string) => state.files.files.get(key),
  (file) => file,
  {
    memoizeOptions: {
      maxSize: 50,
    },
  },
);

// Export reducer
export default slice.reducer;
