import React from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '@1po/1po-bff-fe-spec/generated/garage/Garage';
import { CardTitle, OrderDetailCard } from 'pages/MyOrdersPage/OrderCards/OrderCard.styled';
import { Flex, MarginBox, Pipeline, Text } from 'UI';

export const ContactCard = ({
  garageName,
  address,
}: {
  garageName: string | undefined;
  address: Address | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <OrderDetailCard maxHeight data-cy={'section-contact'}>
      <CardTitle>
        <Text type={'h6'}>{t('my_orders.details.contact', 'Contact')}</Text>
      </CardTitle>
      {garageName && (
        <MarginBox mx={25} my={15}>
          <Flex>
            <Flex>
              <Text type={'text_bold'}>{t('my_orders.details.contact', 'Contact')}</Text>
            </Flex>
            <Flex>
              <Text type={'light_14_black_65'}>{garageName}</Text>
            </Flex>
          </Flex>
        </MarginBox>
      )}
      <Pipeline size={'100%'} horizontal />
      {address?.phone && (
        <>
          <MarginBox mx={25} my={15}>
            <Flex>
              <Flex>
                <Text type={'text_bold'}>{t('my_orders.details.phone_number', 'Phone number')}</Text>
              </Flex>
              <Flex>
                <Text type={'light_14_black_65'}>{address.phone}</Text>
              </Flex>
            </Flex>
          </MarginBox>
          <Pipeline size={'100%'} horizontal />
        </>
      )}
      <MarginBox mx={25} my={15}>
        <Flex>
          <Flex>
            <Text type={'text_bold'}>{t('my_orders.details.email', 'E-mail')}</Text>
          </Flex>
          <Flex>
            <Text type={'light_14_black_65'}>{address?.email ?? ''}</Text>
          </Flex>
        </Flex>
      </MarginBox>
    </OrderDetailCard>
  );
};
