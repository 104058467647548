import React from 'react';
import { Table } from 'antd';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { Container, Flex, Li, Link, MarginBox, Paragraph, Section, SectionHeader, Text, Ul } from 'UI';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const PersonalDataGB = () => {
  useResetScroll();
  return (
    <Container size={'md'} alignText={'justify'}>
      <Flex direction={'column'}>
        <MarginBox mt={15} />
        <Flex direction={'column'} align={'center'}>
          <Text type={'h5_bold'}>INFORMATION NOTICE ON PERSONAL DATA PROTECTION</Text>
          <MarginBox mt={30} />
        </Flex>
        <Section>
          <Paragraph>
            The Renault Group, to which Renault SAS belongs, constantly monitors compliance with regulations on the
            protection of personal data (hereinafter &quot;personal data&quot;) as described in its Group Personal Data
            Protection Policy available at{' '}
            <Link
              to={'https://www.renaultgroup.com/en/our-commitments/the-groupe-renault-and-your-personal-data/'}
              external
              newTab
            >
              https://www.renaultgroup.com/en/our-commitments/the-groupe-renault-and-your-personal-data/
            </Link>
            . Indeed, Groupe Renault believes that the protection of personal data is essential to building a
            relationship of confidence with its customers.
          </Paragraph>
          <Paragraph>
            Renault SAS provides the <Text type={'text_bold'}>RPARTSTORE</Text> website{' '}
            <Link to={'https://rpartstore.renault.com'} external newTab>
              https://rpartstore.renault.com
            </Link>{' '}
            (the &quot;Platform&quot;) in order to i) facilitate the consultation of after-sales technical
            documentation, provide to sale vehicles’ spare parts and accessories (the &quot;Products&quot;), ii) allow
            User to raise Platform’s technical incidents and, iii) generate direct commercial contact between sellers
            and buyers, professionals from the Renault network. In the context of browsing and interacting with the
            Platform, Renault, as data controller, collects personal data directly from the users of the Platform
            (&quot;Users&quot;), as well as, where applicable, indirectly, personal data relating to the end customers
            of the Users (&quot;Third Data Subject&quot;). In accordance with the General Terms and Conditions of Use of
            the Platform (hereinafter referred to as the &quot;General Terms and Conditions&quot;), Renault SAS intends
            to ensure the greatest transparency concerning the processing of personal data that it carries out via the
            Platform. The purpose of this Notice is to inform Users who are representatives of a subsidiary of the
            Renault Group or who are part of the Renault network (&quot;you&quot;) in detail of the conditions under
            which Renault processes personal data and, where applicable, the personal data of Concerned Third Parties,
            as well as the rights and options you have to control your personal data and protect your privacy.
          </Paragraph>
          <Paragraph>
            Capitalized terms used but not defined in this Privacy Notice (hereinafter the &quot;Notice&quot;) shall
            have the meaning given to them in the General Terms and Conditions. For the purposes of this document,
            &quot;we&quot;, &quot;us&quot;, &quot;our&quot; or &quot;Renault&quot; refers to Renault SAS. For the
            purposes of this Notice, User and Third Data Subjects are defined together as &quot;Data Subjects&quot;).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'1. WHO PROCESSES YOUR PERSONAL DATA?'} />
          <Paragraph>
            Renault SAS, the parent company of the Renault Group, located in France at 122-122 bis avenue du Général
            Leclerc 92100 Boulogne-Billancourt, processes your personal data for the purposes listed below (see 3. WHY
            IS YOUR PERSONAL DATA USED?) as data controller.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'2. WHAT PERSONAL DATA DO WE PROCESS?'} />
          <Paragraph>
            &quot;Personal Data&quot; means any information that that can be used to identify you either directly (such
            as your first and/or last name) or indirectly (for example, the identifier consisting of a sequence of
            alphanumeric characters that constitute you IPN, when you are a User representing a Renault subsidiary or
            are part of the Renault network).
          </Paragraph>
          <Paragraph>
            Generally speaking, we undertake to collect only the personal data necessary for each of the purposes for
            which we process your personal data.
          </Paragraph>
          <Paragraph>
            The personal data we collect depends on our interaction with you and may include information about:
          </Paragraph>
          <Ul>
            <Li>
              <Text type={'text'}>your connection / authentication on the Platform;</Text>
            </Li>
            <Li>
              <Text type={'text'}>
                identification data and your professional contact information (professional e-mail, name, first name);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                your use of the Platform and the device you use to visit the Platform, including logs (relating to the
                number of visits, page visited, web browser used), IP address, time zone and cookies (for more
                information please refer to the Platform&apos;s &quot;Cookies&quot; page);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                estimation you made via the Platform in relation to diagnosis, repair or recycling (&quot;Technical
                Operations&quot;) on the Third Data Subject&apos;s vehicle, including estimations that do not result in
                the submission of a Service Order;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                your personal data and, if applicable, the personal data of the Third Data Subjects in the context of a
                raise of technical incident regarding the Platform;
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                your personal data and, if applicable, the personal data of the Third Data Subjects in the context of a
                Service Order allowing you to place an order for Products with the Renault distributor network R1 (the
                data collected are in particular the professional details of the business contact intended to receive
                the delivery);
              </Text>
            </Li>
            <Li>
              <Text type={'text'}>
                personal data related to the commercial relationship (i.e. your possible requests for support regarding
                the Platform functioning, complaints and/or exercise of your personal data rights).
              </Text>
            </Li>
          </Ul>
          <MarginBox mt={15} />
          <Paragraph>
            As such, the data required to respond to your request or meet the needs of a contract or legal obligation is
            pointed out in the collection forms. If you do not wish to provide the mandatory data, we may not be able to
            process your request or provide you with the relevant services.
          </Paragraph>
          <Paragraph>
            In accordance with our Terms and Conditions, the Platform is not intended for minors and we therefore do not
            knowingly collect data about them. The products and services that we offer are primarily for adults.
            Therefore, we do not carry out any specific processing on minors.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'3. WHAT IS YOUR PERSONAL DATA USED FOR?'} />
          <Paragraph>
            We will provide you with explanations relating to the context of collection of your personal data, the
            purposes for which we process them and the duration for which we retained them.
          </Paragraph>
          <MarginBox mt={15} />
          <Section>
            <SectionHeader title={'3.1 CONTEXT OF COLLECTION'} />
            <Paragraph>We collect your personal data in particular when:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  You interact directly with Renault via the Platform (fill in a form, make contact, ...)
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>You visit the Platform, which may use cookies or other tracers,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  You perform a demand of technical support regarding the Platform’s functioning, record a Technical
                  Operation or edit and, if necessary, send a Service Order.
                </Text>
              </Li>
            </Ul>
          </Section>
          <Section>
            <SectionHeader title={'3.2. OBJECTIVES OF PERSONAL DATA PROCESSING'} />
            <Paragraph>
              Within the context of our relationship, and in line with the context in which your personal data is
              collected, we may use your data to:
            </Paragraph>
            <Ul type={'none'}>
              <Li>
                <Text type={'text'}>A - Manage our initial interactions </Text>
              </Li>
              <Li>
                <Text type={'text'}>B - Provide you with the Platform’ service</Text>
              </Li>
              <Li>
                <Text type={'text'}>C - Conduct studies and analyses</Text>
              </Li>
              <Li>
                <Text type={'text'}>D - Manage our marketing operations</Text>
              </Li>
            </Ul>
            <MarginBox mt={10} />
            <Section>
              <SectionHeader title={'3.2.A. MANAGE OUR INITIAL INTERACTIONS'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectives',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-a',
                    width: '50%',
                  },
                  { title: 'Legal basis', dataIndex: 'legal', key: 'legal-3-2-a', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: 'Measure visits to the Platform and manage its maintenance and security',
                    // eslint-disable-next-line max-len
                    legal: `This processing is based on your consent to the "non-essential" cookies deposited/read in your terminal (see the Platform's "Cookies" page), and on our legitimate interest (to provide you with a secure Platform)`,
                  },
                  {
                    id: '1',
                    objectives: 'The management of your authentication on the Platform',
                    // eslint-disable-next-line max-len
                    legal: `This processing is justified by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                  {
                    id: '2',
                    objectives: 'To allow you to connect to the Platform to the Platform ',
                    // eslint-disable-next-line max-len
                    legal: `This processing is justified by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.B. MANAGE OUR COMMERCIAL RELATIONSHIP'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectives',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-b',
                    width: '50%',
                  },
                  { title: 'Legal basis', dataIndex: 'legal', key: 'legal-3-2-b', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `To enable you to carry out Technical Operations, in particular in connection with the vehicles’ repair`,
                    // eslint-disable-next-line max-len
                    legal: `This processing is justiﬁed by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                  {
                    id: '1',
                    // eslint-disable-next-line max-len
                    objectives: `Allow you to consult the after-sales technical documentation concerning RENAULT’s Products (such as: spare parts, labor time, repair methods and diagnostics, tutorials, without being exhaustive) and/or other car manufacturer’ brands`,
                    // eslint-disable-next-line max-len
                    legal: `This processing is justiﬁed by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                  {
                    id: '2',
                    // eslint-disable-next-line max-len
                    objectives: `Allow you to estimate of the amount of the Technical Operations and, if necessary, to issue a Service Order via the Platform`,
                    // eslint-disable-next-line max-len
                    legal: `This processing is justiﬁed by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                  {
                    id: '3',
                    objectives: `Manage our commercial relationship`,
                    // eslint-disable-next-line max-len
                    legal: `This processing is justiﬁed by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                  {
                    id: '4',
                    objectives: `Manage your support requests support requests regarding the functioning of the Platform`,
                    // eslint-disable-next-line max-len
                    legal: `This processing is justiﬁed by the execution of the contract you have concluded with us (i.e. the General Terms and Conditions)`,
                  },
                  {
                    id: '5',
                    // eslint-disable-next-line max-len
                    objectives: `Responding to your possible requests to exercise rights in relation to your personal data (see the section 5 hereafter on "your rights")`,
                    // eslint-disable-next-line max-len
                    legal: `This processing is based on our legal obligations and may involve checking your identity`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.C. CONDUCT STUDIES AND ANALYSIS'} />
              <Paragraph>
                Studies and analyses are conducted to measure our performance, assess the quality of our products and
                services and the level of customer satisfaction, and to continuously improve them.
              </Paragraph>
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectives',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-c',
                    width: '50%',
                  },
                  { title: 'Legal basis', dataIndex: 'legal', key: 'legal-3-2-c', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    // eslint-disable-next-line max-len
                    objectives: `Management of our satisfaction surveys, including the response (i.e. your opinions), in order to improve the satisfaction of Users regarding the Platform `,
                    legal: `This processing is based on our legitimate interests (to measure the performance of our activity)`,
                  },
                  {
                    id: '1',
                    objectives: `Monitor our activities (reporting)`,
                    legal: `This processing is based on our legitimate interest (improving our products and services)`,
                  },
                ]}
              />
            </Section>
            <Section>
              <SectionHeader title={'3.2.D. MANAGE OUR MARKETING OPERATIONS'} />
              <Table
                pagination={false}
                rowKey={(record) => record.id}
                columns={[
                  {
                    title: 'Objectives',
                    dataIndex: 'objectives',
                    key: 'objectives-3-2-d',
                    width: '50%',
                  },
                  { title: 'Legal basis', dataIndex: 'legal', key: 'legal-3-2-d', width: '50%' },
                ]}
                dataSource={[
                  {
                    id: '0',
                    objectives: `Sending advertising campaigns to the Renault network (B2B)`,
                    legal: `This processing is based on our legitimate interest (to make our products known)`,
                  },
                ]}
              />
              <MarginBox mt={15} />
              <Paragraph>
                If we need to process your personal data for purposes other than those listed in the table above, you
                will be informed in advance and we will take any additional actions that may be necessary to ensure the
                legal compliance of all processing.
              </Paragraph>
            </Section>
          </Section>
          <Section>
            <SectionHeader title={'3.3 THE LENGTH OF TIME FOR WHICH WE KEEP YOUR DATA'} />
            <Paragraph>
              In accordance with the regulations, we are committed to keeping your personal data only for the time
              necessary to achieve the objective pursued, to meet your needs, or to meet our legal obligations.
            </Paragraph>
            <Paragraph>In determining this time, we take into account, in particular, the following:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>The time required to process your request or complaint,</Text>
              </Li>
              <Li>
                <Text type={'text'}>Your interest in our Platform,</Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  The need to keep a certain history of your interactions with the Platform, this duration varying in
                  particular according to whether you have signed a Service Order or not;
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>Our legal or regulatory obligations </Text>
              </Li>
            </Ul>
            <Paragraph>In particular:</Paragraph>
            <Ul>
              <Li>
                <Text type={'text'}>
                  Personal data collected by cookies in the context of analyzing the performance of the content you
                  consult on our sites and the display of targeted online advertising. Non-essential&quot;
                  tracers/cookies, such as advertising cookies, are kept for a maximum of 13 months from the date of
                  your consent. Personal data collected via cookies will be processed for 25 months and then anonymized.
                  The retention period for your choices (consent or refusal of so-called &quot;non-essential&quot;
                  cookies subject to prior collection of consent) is 6 months from the time of collection of your
                  consent. For more information, please check on our “Cookie” page
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  The personal data processed within the framework of the maintenance and the security of the Platform
                  and of our databases are kept for 6 months (e.g.: logs, ...);
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  The personal data processed as part of the history of your Technical Operations carried out via the
                  Platform and/or the Service Order you have sent are kept for 6 months. It is specified that
                  estimations made via the Platform by the User will be kept for 3 months.
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Where the User is a Renault’s employee or an employee of a professional member of the Renault&apos;s
                  network, his or her data collected via the Platform will be kept for the duration of his or her
                  employment contract with Renault or, where applicable, the authorization provided by the User&apos;s
                  employer to access the Platform,
                </Text>
              </Li>
              <Li>
                <Text type={'text'}>
                  Personal data relating to your requests to exercise your rights are kept for a maximum of 3 years from
                  the closing of your request, depending on the right exercised. When the collection of a proof of
                  identity was necessary, it is deleted as soon as the verification has been completed.
                </Text>
              </Li>
            </Ul>
            <Paragraph>
              When we no longer need to use your personal data, it is deleted from our systems and records or made
              anonymous so that we can no longer identify you.
            </Paragraph>
            <Paragraph>
              However, it may be necessary to archive some of your personal data in order to be able to respond to any
              legal proceedings, throughout the statute of limitations provided for in the applicable legislation.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'4. WHO HAS ACCESS TO YOUR PERSONAL DATA?'} />
          <Paragraph>
            In order to achieve the purposes described above and only to the extent necessary for the pursuit of these
            purposes:
          </Paragraph>
          <Ul>
            <Li>
              we ensure that only those persons who are duly authorized to process your personal data in accordance with
              their functions and duties are allowed to do so.
            </Li>
            <Li>
              we may transmit some of the personal data to the following recipients:
              <Ul type={'decimal'}>
                <MarginBox mt={10} />
                <Li>
                  to the members of the Renault’s network (i.e. the members of the R1 network with whom the User is
                  authorized to place an order for Products), subject to your consent where required by regulation.
                  Indeed, Renault and its network members have entered into an agreement under which they both
                  undertake, as independent data controllers, to comply with their obligations under the laws and
                  regulations relating to the protection of personal data, including, inter alia, the following
                  principles:
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>process personal data lawfully and fairly,</Li>
                    <Li>ensure the accuracy and quality of the data, </Li>
                    <Li>
                      implementing all appropriate technical and organizational measures to ensure a level of security
                      appropriate to the risk and cooperating in the event of a data breach,
                    </Li>
                    <Li>to hire only trustworthy sub-processors,</Li>
                    <Li>implement appropriate safeguards for data transfers,</Li>
                    <Li>respect data subjects’ rights, including the right to be informed about data processing.</Li>
                  </Ul>
                  <MarginBox mt={10} />
                  With respect to the last principle, employees of the Renault network are informed that this Notice is
                  a supplement to the information notice relating to data processing that may have been provided to you
                  by your employer as a professional part to the Renault’s network.
                </Li>
                <MarginBox mt={10} />
                <Li>
                  personal data processors within the meaning of the applicable regulations on personal data, to perform
                  the tasks listed hereafter. We take care to secure these relationships (contracts, audits, guarantees
                  and security tests...) and to give them instructions relating to the tasks of that we entrust to them,
                  in particular:
                  <Ul>
                    <MarginBox mt={7} />
                    <Li>Hosting,</Li>
                    <Li>
                      Securitization and maintenance of the Platform and the databases collected and/or processed via
                      the Platform,
                    </Li>
                    <Li>Commercial prospection operations to professional members of the Renault network (B2B),</Li>
                    <Li>
                      Management of customer relations and technical support regarding the functioning of the Platform,
                    </Li>
                    <Li>Audience measurements on the Platform,</Li>
                    <Li>Measurement of User’s satisfaction concerning the Platform.</Li>
                    <MarginBox mt={7} />
                  </Ul>
                </Li>
              </Ul>
            </Li>
          </Ul>
          <Paragraph>
            We may be required to communicate your personal data to third parties in order to comply with a legal
            obligation or administrative or judicial decision.{' '}
          </Paragraph>
          <Paragraph>
            Renault may share your data with third parties when transferring obligations and rights relating to the
            contractual relationship between you and Renault to such third parties in the event of the transfer of a
            business segment, a merger by incorporation of a new company, a demerger or any change of control that
            affects Renault. Prior to such an event, Renault will separately inform you about the information relating
            to the sharing of your data and obtain your consent where legally required.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'5. WHAT ARE YOUR RIGHTS?'} />
          <Section>
            <SectionHeader title={'5.1. YOUR RIGHTS'} />
            <Paragraph>You have several rights under personal data protection regulations:</Paragraph>
            <Paragraph>
              A right to object to the processing of your personal data, provided you have grounds connected with your
              particular situation, and the right to request the limitation of your personal data processing, in some
              cases as provided for in regulations.
            </Paragraph>
            <Paragraph>
              A right to object to any communication from us: you may at any time request to no longer receive our
              communications from us.
            </Paragraph>
            <Paragraph>
              A right to withdraw your consent at any time, for the ﬁnalities for which we have collected your consent
              (see above 3.2. THE PURPOSES OF PERSONAL DATA PROCESSING).
            </Paragraph>
            <Paragraph>
              A right to information: you have the right to obtain clear, transparent and understandable information
              about how we use your personal data and about your rights. This Notice is an example of this.
            </Paragraph>
            <Paragraph>
              A right of access to your personal data: you have the right to obtain information concerning the
              processing of your personal data (including the data that is used, for what purposes, etc.) and a copy
              thereof.
            </Paragraph>
            <Paragraph>
              A right of rectification: you have the right to correct your data if it is inaccurate or incomplete,
              despite our efforts to keep it up to date, enabling us to comply with our obligation to have up-to-date
              data about you.
            </Paragraph>
            <Paragraph>
              A right to the portability of your data, i.e., under certain conditions, the right to receive the personal
              data you have provided to us, in a structured, commonly used computerised format, and for it to be
              transmitted to a third party if technically possible.
            </Paragraph>
            <Paragraph>
              A right to erasure (or right to be forgotten): you have the right to have your data erased or deleted.
              This right may be limited in light of our contractual or legal obligations (contract in progress)
              (prevention of legal action in particular).
            </Paragraph>
            <Paragraph>
              In France, you have the right to define either general or specific guidelines regarding certain types of
              processing, with respect to the retention, deletion and communication of your personal data in the event
              of your death. You may change or delete these guidelines at any time. You may share these special
              guidelines with us by writing to the address below.
            </Paragraph>
            <Paragraph>
              Finally, you have the right to lodge a complaint with a personal data protection supervisory authority,
              for example, in France, the Commission Nationale Informatique et Libertés (CNIL). We encourage you to
              contact us before any complaint, so that we can try to resolve your problem together.{' '}
            </Paragraph>
          </Section>
          <Section>
            <SectionHeader title={'5.2. HOW DO I EXERCISE THEM?'} />
            <Paragraph>
              To exercise any of your rights, you may send your request at any time by mail to the following address
            </Paragraph>
            <Paragraph>
              Renault SAS, Legal Department - Data Protection Officer, 122-122 bis avenue du Général Leclerc 92100
              Boulogne-Billancourt, France
            </Paragraph>
            <Paragraph>or</Paragraph>
            <Paragraph>
              an e-mail to the following address:{' '}
              <Link to={'mailto:dataprotection-com@renault.com'} external newTab>
                dataprotection-com@renault.com
              </Link>
              .
            </Paragraph>
            <Paragraph>
              In both cases, in order to facilitate the processing of your request, please specify that your request
              relates to the Platform « RPARTSTORE ».
            </Paragraph>
            <Paragraph>
              If you are unsure of your identity, you may be asked to provide proof of your identity (i.e. the copy of
              your identity is processed solely for the purpose of responding to your request and is deleted immediately
              after verification).
            </Paragraph>
            <Paragraph>
              We will make every effort to respond to your request as soon as possible and no later than one month after
              receipt of your request. In accordance with applicable regulations, we reserve the right to extend this
              period to three months if your request is particularly complex.
            </Paragraph>
          </Section>
        </Section>
        <Section>
          <SectionHeader title={'6. HOW IS YOUR PERSONAL DATA KEPT SECURE?'} />
          <Paragraph>
            Your personal data is stored on secure servers. We put in place, and require from our processors and
            partners, appropriate data security and data protection measures in line with the latest technologies.
          </Paragraph>
          <Paragraph>
            When personal data processing involves transferring data, we ensure that this transfer is carried out under
            appropriate conditions that ensure an adequate level of protection, security and confidentiality.
          </Paragraph>
          <Paragraph>
            Whenever possible, your data is processed in the European Economic Area (EEA). However, as some of our
            service providers or their processors are located in countries outside of the EEA, your personal data may be
            processed in those countries. Some of these countries may have different personal data regulations from
            those of the European Union. In such a case, (i) we pay particular attention to ensuring that this transfer
            is carried out in accordance with the applicable regulations and (ii) we put in place safeguards ensuring an
            adequate level of protection of your privacy and fundamental rights (in particular by using the European
            Commission&apos;s standard contractual clauses). Upon simple request sent to the address indicated in the 5.
            &quot;What are your rights?&quot; section, we can provide you with more information on such transfers (in
            particular the European Commission’s standard contractual clauses).
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'7. OTHER INFORMATION YOU MAY NEED'} />
          <Paragraph>
            We may also collect technical information about you in an automated way when you visit one of our sites or
            use one of our mobile applications, in particular your IP address, your navigation parameters, by means of
            tracers (cookies).
          </Paragraph>
          <Paragraph>
            For more information, we invite you to consult the &quot;
            <Link to={ROUTER_COOKIES(Country.GB.key)} newTab>
              Cookies
            </Link>
            &quot; page of the Platform.
          </Paragraph>
        </Section>
        <Section>
          <SectionHeader title={'8. MODIFICATION OF THIS NOTICE'} />
          <Paragraph>
            We may change this Notice from time to time. We will inform you and/or seek your consent whenever necessary
            or required. We therefore recommend that you consult this Notice each time you visit our Platform in order
            to review the latest version.
          </Paragraph>
        </Section>
        <Flex justify={'flex-end'}>
          <Text type={'text'}>Updated on 30th May 2023</Text>
        </Flex>
        <MarginBox mt={90} />
      </Flex>
    </Container>
  );
};
