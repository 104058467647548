import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Steps } from 'antd';
import { ROUTER_CART, ROUTER_CART_DETAILS } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import Loader from 'components/Loader';
import {
  clearOtherReferenceAttachStatus,
  getBasketExternalSection,
  getBasketId,
  getBasketIdStatus,
  getBasketOtherSection,
  getBasketVehicles,
  getOtherReferenceAttachStatus,
  revertLastRemovalRequest,
  revertMoveOtherReferenceToVehicle,
} from 'domains/basket/Basket.store';
import { OtherReferenceAttachStatus, REF_ATTACH_OK } from 'domains/basket/Basket.types';
import { disableEnablePopins } from 'domains/firstHelp/FirstHelp.api';
import { useAutomaticFirstHelp } from 'domains/firstHelp/FirstHelp.requests';
import { getBannersCount } from 'domains/information/Information.store';
import { Container, emptyPadding, Flex, StickyBox } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { hasData, isLoading } from 'utils';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { SSteps } from './CartPage.styled';
import CartStep from './CartStep';
import EmptyCart from './CartStep/EmptyCart';

const { Step } = Steps;
export const CART_STEPS_HEIGHT = 60;
export const NAVIGATE_FROM_CART = 'navigateFromCart';

export interface CartAndValidationStepProps {
  next: () => void;
  isBanners?: boolean;
}

type TimeoutType = ReturnType<typeof setTimeout>;

export const CartStepper = ({ step }: { step: number }) => {
  const { t } = useTranslation();
  const offsetTop = useOffsetTop('CART');
  return (
    <StickyBox offsetTop={offsetTop} background={'white'} zIndex={501}>
      <Flex size={0} direction={'row'} align={'center'} justify={'center'} minHeight={CART_STEPS_HEIGHT}>
        <SSteps current={step}>
          <Step key={'step-0'} title={t('cart', 'Cart')} />
          <Step key={'step-1'} title={t('order.details', 'Order details')} />
          <Step key={'step-2'} title={''} />
        </SSteps>
      </Flex>
    </StickyBox>
  );
};
const CartPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const basketIdStatus = useSelector(getBasketIdStatus);
  const basketId = useSelector(getBasketId);
  const vehicles = useSelector(getBasketVehicles);
  const externals = useSelector(getBasketExternalSection);
  const otherSection = useSelector(getBasketOtherSection);
  const dataMissing = !vehicles?.length && !externals?.length && !otherSection?.references?.length;
  const isBanners = useSelector((state: RootState) => getBannersCount(state, 'CART')) > 0;
  const next = useCallback(() => {
    localStorage.setItem(NAVIGATE_FROM_CART, 'true');
    history.push(ROUTER_CART_DETAILS);
  }, [history]);
  const [addedOtherReference, setAddedOtherReference] = useState<string | undefined>(undefined);
  const [numberOfRemovedReferences, setNumberOfRemovedReferences] = useState<number | undefined>(undefined);
  const [revertTimeout, setRevertTimeout] = useState<TimeoutType>();
  const [revertAttachTimeout, setRevertAttachTimeout] = useState<TimeoutType>();
  const [numberOfAttachedReferences, setNumberOfAttachedReferences] = useState<number | undefined>(undefined);
  const otherRefAttachedOKStatus = useSelector((state: RootState) =>
    getOtherReferenceAttachStatus(state, REF_ATTACH_OK),
  );
  useAutomaticFirstHelp(ROUTER_CART, isLoading(basketId));

  useEffect(() => {
    const isBasketVehicles = vehicles.length > 0;
    const isBasketOthers = otherSection.references.length > 0;
    disableEnablePopins(dispatch, !isBasketVehicles && !isBasketOthers, [
      `${ROUTER_CART}_add_comment`,
      `${ROUTER_CART}_link_to_veh`,
    ]);
    disableEnablePopins(dispatch, !isBasketOthers || (isBasketOthers && !isBasketVehicles), [
      `${ROUTER_CART}_link_to_veh`,
    ]);
  }, [dispatch, vehicles.length, otherSection.references.length]);

  const setAddedByReferenceBox = useCallback((referenceNumber?: string) => {
    setAddedOtherReference(referenceNumber);
  }, []);

  const revertLastRemoval = (numberOfReferences: number) => {
    if (revertTimeout) {
      clearTimeout(revertTimeout);
    }
    if (numberOfReferences && numberOfReferences > 0) {
      dispatch(revertLastRemovalRequest());
      setNumberOfRemovedReferences(undefined);
    }
  };

  const revertAttachReference = useCallback(() => {
    if (revertAttachTimeout) {
      clearTimeout(revertAttachTimeout);
    }
    if (otherRefAttachedOKStatus.length > 0) {
      dispatch(revertMoveOtherReferenceToVehicle());
      setNumberOfAttachedReferences(undefined);
    }
  }, [dispatch, otherRefAttachedOKStatus.length, revertAttachTimeout]);

  const markRemovedReferences = useCallback((numberOfReferences: number) => {
    setNumberOfRemovedReferences(numberOfReferences);
    const timeout = setTimeout(() => {
      setNumberOfRemovedReferences(undefined);
    }, 5000);
    setRevertTimeout(timeout);
  }, []);

  const markAttachedOtherReferences = (statusesOk: OtherReferenceAttachStatus[]) => {
    setNumberOfAttachedReferences(statusesOk.length);
    const timeout = setTimeout(() => {
      setNumberOfAttachedReferences(undefined);
      statusesOk.forEach((status) => {
        dispatch(clearOtherReferenceAttachStatus({ referenceNumber: status.reference }));
      });
    }, 5000);
    setRevertAttachTimeout(timeout);
  };

  useResetScroll();

  useEffect(() => {
    if (otherRefAttachedOKStatus.length > 0) {
      markAttachedOtherReferences(otherRefAttachedOKStatus);
    }
    // eslint-disable-next-line
  }, [otherRefAttachedOKStatus]);

  useEffect(() => {
    if (otherRefAttachedOKStatus.length > 0) {
      notifyTop(
        'success',
        <>{`${t('cart.action.moveOtherReference', 'Your reference has been attached to the VIN:')} ${
          otherRefAttachedOKStatus[otherRefAttachedOKStatus.length - 1]?.vehicleKey
        } `}</>,
        undefined,
        <NotificationLink
          onClick={() => {
            revertAttachReference();
          }}
        >
          {t('cart.action.cancel', 'Cancel')}
        </NotificationLink>,
      );
    }
    // eslint-disable-next-line
  }, [otherRefAttachedOKStatus.length]);

  useEffect(() => {
    if (numberOfRemovedReferences) {
      notifyTop(
        'success',
        <Trans count={numberOfRemovedReferences} i18nKey={'cart.action.references_removed_notification'}>
          {'{{count}} reference has been removed from your cart.'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            revertLastRemoval(numberOfRemovedReferences);
          }}
        >
          {t('cart.action.cancel', 'Cancel')}
        </NotificationLink>,
      );
    }
    /* eslint-disable-next-line */
  }, [numberOfRemovedReferences]);

  return (
    <Container padding={emptyPadding}>
      {dataMissing || !hasData(basketId) ? (
        <Flex direction={'column'}>
          {isLoading(basketIdStatus) ? (
            <Loader />
          ) : (
            <EmptyCart
              addedOtherReference={addedOtherReference}
              setAddedByReferenceBox={setAddedByReferenceBox}
              numberOfRemovedReferences={numberOfRemovedReferences}
            />
          )}
        </Flex>
      ) : (
        <>
          <CartStepper step={0} />
          <Container padding={emptyPadding}>
            <CartStep
              next={next}
              setAddedByReferenceBox={setAddedByReferenceBox}
              addedOtherReference={addedOtherReference}
              markRemovedReferences={markRemovedReferences}
              numberOfAttachedReferences={numberOfAttachedReferences}
              revertAttachOtherReference={revertAttachReference}
              isBanners={isBanners}
            />
          </Container>
        </>
      )}
    </Container>
  );
};

export default CartPage;
