import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserRights } from 'domains/user';
import { getBackOfficeMenuItems } from 'pages/BackOfficePage/BackOfficeLayout/BackOfficeMenu';
import { theme } from 'styles';
import { Box, MarginBox, Text } from 'UI';

const BackOfficeHeader = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const userRights = useSelector(getUserRights);

  const activeMenuItem = getBackOfficeMenuItems(userRights, t).find((item) =>
    history.location.pathname.includes(item.link),
  );
  const subLabel = activeMenuItem?.subPages?.find((item) => history.location.pathname.includes(item.link))?.label;
  const titleLabel = subLabel ?? activeMenuItem?.label;

  return (
    <Box background={theme.color.blue_grey} height={200}>
      <MarginBox mt={82} ml={375}>
        <Text type={'h1'}>{titleLabel}</Text>
      </MarginBox>
    </Box>
  );
};

export default BackOfficeHeader;
