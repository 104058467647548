import styled from 'styled-components';
import { theme } from 'styles';
import { defaultBoxShadow, Flex } from 'UI';

export const SBrandsSwitch = styled(Flex)`
  padding: 15px 0;
  margin-bottom: 15px;
`;

export const SSwitchButton = styled(Flex)<{ isSelected: boolean }>`
  ${defaultBoxShadow};
  cursor: pointer;
  padding: 15px 40px 15px 15px;
  border-radius: 10px;

  background: ${({ isSelected }) => (isSelected ? theme.color.grey_light : 'none')};

  :hover {
    background-color: ${theme.color.grey_light};
  }
`;
