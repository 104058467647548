import React from 'react';
import styled from 'styled-components';
import { ThemeFontKeyType } from 'styles';
import { Text } from 'UI/index';

type TextAlignType = 'start' | 'end' | 'justify' | 'inherit';

const SParagraph = styled.div<{ align: TextAlignType }>`
  text-align: ${({ align }) => align};
  margin-bottom: 15px;
`;

export const Paragraph = ({
  type = 'text',
  align = 'inherit',
  children,
}: {
  type?: ThemeFontKeyType;
  align?: TextAlignType;
  children: React.ReactNode;
}) => (
  <SParagraph align={align}>
    <Text type={type}>{children}</Text>
  </SParagraph>
);
