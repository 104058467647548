import styled from 'styled-components';
import { Flex } from 'UI';

export const ShortStockDescription = styled(Flex)`
  white-space: nowrap;
`;

export const StatusCircle = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

export const PaddingBox = styled.div`
  padding: 5px;
`;

export const OneBox = styled.div`
  max-width: 142px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
