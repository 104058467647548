import React from 'react';
import { useTranslation } from 'react-i18next';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TFunction } from 'i18next';
import { DownOutlinedIcon, SeasonsIcon, SnowflakeIcon, SunIcon } from 'assets/icons';
import { ALL, SUMMER, SUMMER_WINTER, WINTER } from 'domains/tires/Tire.types';
import { DropdownMenu, SAntDropdown } from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresSearchbar.styled';
import { CenterFlex, Flex, Icon, IconType, MarginBox, Text, Checkbox } from 'UI';

export interface TireSeasonSelectionProps {
  season: Season | undefined;
  setSeason: (season: Season | undefined) => void;
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  showValue?: boolean;
  bordered?: boolean;
  translateX?: number;
  translateY?: number;
  mainSearch?: boolean;
}

const getSeasonIcon = (season: Season | undefined): IconType => {
  switch (season) {
    case 'SUMMER':
      return SunIcon;
    case 'WINTER':
      return SnowflakeIcon;
    default:
      return SeasonsIcon;
  }
};

export const getSeasonLabel = (t: TFunction, season: Season | undefined): string => {
  switch (season) {
    case 'SUMMER_WINTER':
      return t('catalog.tires.all_seasons', 'ALL SEASONS');
    case 'SUMMER':
      return t('catalog.tires.summer', 'SUMMER');
    case 'WINTER':
      return t('catalog.tires.winter', 'WINTER');
    default:
      return t('catalog.all', 'ALL');
  }
};

export const TireSeasonSelection = ({
  season,
  setSeason,
  menuOpen,
  setMenuOpen,
  showValue = false,
  bordered = false,
  translateX = 0,
  translateY = 0,
  mainSearch = false,
}: TireSeasonSelectionProps) => {
  const { t } = useTranslation();

  const handleSetSeason = (val: Season) => (_selected: boolean): void => {
    setSeason(val);
  };

  const Menu = () => (
    <DropdownMenu type={'season'} translateX={translateX} translateY={translateY}>
      <Flex direction={'column'}>
        <MarginBox mb={10}>
          <Checkbox
            type={'light_14_black_65'}
            checked={season === ALL}
            onChange={handleSetSeason(ALL)}
            label={getSeasonLabel(t, 'ALL')}
          />
        </MarginBox>
        <MarginBox mb={10}>
          <Checkbox
            type={'light_14_black_65'}
            checked={season === SUMMER_WINTER}
            onChange={handleSetSeason(SUMMER_WINTER)}
            label={getSeasonLabel(t, 'SUMMER_WINTER')}
          />
        </MarginBox>
        <MarginBox mb={10}>
          <Checkbox
            type={'light_14_black_65'}
            checked={season === SUMMER}
            onChange={handleSetSeason(SUMMER)}
            label={getSeasonLabel(t, 'SUMMER')}
          />
        </MarginBox>
        <MarginBox>
          <Checkbox
            type={'light_14_black_65'}
            checked={season === WINTER}
            onChange={handleSetSeason(WINTER)}
            label={getSeasonLabel(t, 'WINTER')}
          />
        </MarginBox>
      </Flex>
    </DropdownMenu>
  );

  return (
    <SAntDropdown
      overlay={Menu}
      trigger={['click']}
      visible={menuOpen}
      onVisibleChange={(visible) => setMenuOpen(visible)}
      bordered={bordered}
      mainSearch={mainSearch}
    >
      <a className={'ant-dropdown-link'}>
        <CenterFlex>
          <MarginBox mr={15} />
          <Icon IconComponent={getSeasonIcon(season)} size={24} color={'black'} />
          {showValue && (
            <>
              <MarginBox mr={15} />
              <Text type={'lead_dim'}>{getSeasonLabel(t, season)}</Text>
            </>
          )}
          <Flex />
          <MarginBox mr={10} mt={5}>
            <Icon IconComponent={DownOutlinedIcon} mt={5} size={18} color={'black'} />
          </MarginBox>
        </CenterFlex>
      </a>
    </SAntDropdown>
  );
};
