import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTER_COOKIES } from 'app/AppRouter';
import { NotFound } from 'app/NotFound';
import {
  CookiesAT,
  CookiesBE,
  CookiesDE,
  CookiesFR,
  CookiesIT,
  CookiesNL,
  CookiesPL,
  CookiesPT,
  CookiesRO,
} from 'pages/CookiesPage/versions';
import { Country } from 'utils/i18n/Country';

export const CookiesPage = () => {
  return (
    <Switch>
      <Route path={ROUTER_COOKIES(Country.AT.key)} component={CookiesAT} />
      <Route path={ROUTER_COOKIES(Country.DE.key)} component={CookiesDE} />
      <Route path={ROUTER_COOKIES(Country.FR.key)} component={CookiesFR} />
      <Route path={ROUTER_COOKIES(Country.IT.key)} component={CookiesIT} />
      <Route path={ROUTER_COOKIES(Country.NL.key)} component={CookiesNL} />
      <Route path={ROUTER_COOKIES(Country.PL.key)} component={CookiesPL} />
      <Route path={ROUTER_COOKIES(Country.RO.key)} component={CookiesRO} />
      <Route path={ROUTER_COOKIES(Country.PT.key)} component={CookiesPT} />
      <Route path={ROUTER_COOKIES(Country.BE.key)} component={CookiesBE} />
      <Route path={'/'} component={NotFound} />
    </Switch>
  );
};
