/* eslint-disable max-len */

import {
  CommercialLink,
  SearchUserProfileResponse,
} from '@1po/1po-bff-fe-spec/generated/user/response/SearchUserProfileResponse';
import { AnyAction } from 'redux';
import { DelegationSession, TradingProfile, User, UserProfile, UserRole } from 'domains/user';
import { hasData } from 'utils';
import { documentLanguages, Language, webLanguages } from 'utils/i18n/Language';

export const mapTradingProfile = (
  user: User,
  commercialLink?: CommercialLink | null,
  connectedAs?: DelegationSession | null,
): TradingProfile => {
  const currentCommercialLink =
    hasData(connectedAs) && connectedAs !== null ? connectedAs?.commercialLink : commercialLink;
  const buyerId = currentCommercialLink?.tresorCode ?? currentCommercialLink?.birCode ?? '';
  const sellerId = currentCommercialLink?.seller?.id;
  const repository =
    currentCommercialLink?.repository ?? user.selectedProfile?.repository ?? user.repository ?? 'TRESOR';
  return new TradingProfile(buyerId, sellerId, repository);
};

export function mapUserProfileResponse(action: AnyAction): UserProfile | undefined {
  const response: SearchUserProfileResponse = action.payload;
  if (!response) {
    return undefined;
  }

  const { webLanguage, documentLanguage, documentLanguageFallback, commercialLink } = response;
  return {
    webLanguage: webLanguage ? Language.findByKey(webLanguages, webLanguage) : undefined,
    documentLanguage: documentLanguage ? Language.findByKey(documentLanguages, documentLanguage) : undefined,
    documentLanguageFallback: documentLanguageFallback
      ? Language.findByKey(documentLanguages, documentLanguageFallback)
      : undefined,
    commercialLink,
  };
}

const isValidRole = (role: string): role is UserRole => Object.values(UserRole).includes(role as UserRole);

export const mapRoles = (roles: string[]): UserRole[] =>
  roles.filter((role) => isValidRole(role)).map((role) => role as UserRole);

const isUserAttributeInvalid = (attibute: string | string[] | undefined) => {
  return attibute === undefined || attibute.length === 0;
};

export const isUserTokenInvalid = (user?: User): boolean => {
  return (
    !user ||
    isUserAttributeInvalid(user.id) ||
    isUserAttributeInvalid(user.country) ||
    isUserAttributeInvalid(user.rights)
  );
};
