import React from 'react';
import { CircleXMark } from 'assets/icons';
import SubCategoryCard from 'components/TreeMenu/SubCategoryCard';
import { TreeType } from 'components/TreeMenu/TreeMenu';
import { formatCategoryLabelToDataCy } from 'domains/catalog/Catalog.utils';
import { theme } from 'styles';
import { Flex, Icon, Text } from 'UI';

const SubCategoriesMenu = ({
  level1Label,
  level1NodeId,
  treeData,
  setActiveCategory,
  baseUrl,
  disableLevel2Click,
}: {
  level1Label: string;
  level1NodeId: string;
  treeData: TreeType[];
  setActiveCategory: (s: string) => void;
  baseUrl: string;
  disableLevel2Click?: boolean;
}) => {
  return (
    <Flex direction={'column'} gap={32} size={0}>
      <Flex justify={'space-between'} size={0}>
        <Text type={'h2'} transform={'uppercase'} dataCy={formatCategoryLabelToDataCy(level1Label, 'tab')}>
          {level1Label}
        </Text>
        <Icon IconComponent={CircleXMark} size={32} color={theme.color.grey15} onClick={() => setActiveCategory('')} />
      </Flex>
      <Flex wrap={'wrap'} gap={32}>
        {treeData.map((level2) => (
          <SubCategoryCard
            baseUrl={baseUrl}
            key={level2.nodeId}
            level2Label={level2.label}
            level2NodeId={level2.nodeId}
            items={level2.childCategories ?? []}
            level1NodeId={level1NodeId}
            setActiveCategory={setActiveCategory}
            disableLevel2Click={disableLevel2Click}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default SubCategoriesMenu;
